import { Component, OnInit } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { User } from '../_models';
import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from '../_services';
import { Access } from '../_models/access';
//import { AgmInfoWindow } from '@agm/core';
import { ModalService } from '../_services/modal.service';
import { ConsumermodalService } from '../_services/consumermodal.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
declare const $: any;

// import { GuidedTour, Orientation } from 'ngx-guided-tour/lib/guided-tour.constants'
// import { GuidedTourService }  from 'ngx-guided-tour/lib/guided-tour.service';

@Component({
  selector: 'app-mobilemapview',
  templateUrl: './mobilemapview.component.html',
  styleUrls: [
    './mobilemapview.component.scss',
    './assets-paper/scss/paper-dashboard.scss']
})
export class MobilemapviewComponent implements OnInit {

  loadingMessage: any;
  loading: boolean = true;
  responseData: any;
  addAuthorityForm: UntypedFormGroup;

  public today = new Date();
  selectedSubDivision: any;
 
  totalMeters = 0;
  currentUser: User;
  currentUserSubscription: Subscription;
  getThrough: Access;
  messageResult=false;

  mapData =
    {
      default: {
        pin: {
          lat: 28.363609,
          lng: 76.938518,
        }
       /*locations: [
          {
            lat: 30.799527,
            lng: 76.916459,
            label: 'A',
            draggable: true
          },
          {
            lat: 30.803289,
            lng: 76.915882,
            label: 'B',
            draggable: false
          },
          {
            lat: 30.803214,
            lng: 76.921783,
            label: 'C',
            draggable: true
          }
        ]*/
      }
      /*panchkula: {
        pin: {
          lat: 30.720180,
          lng: 76.835448,
        },
        locations: [
          {
            lat: 30.720180,
            lng: 76.835448,
            label: 'Ab',
            draggable: true
          },
          {
            lat: 30.722015,
            lng: 76.834772,
            label: 'Bb',
            draggable: false
          },
          {
            lat: 30.720748,
            lng: 76.842527,
            label: 'Cb',
            draggable: true
          }
        ]
      },
      ambala: {
        pin: {
          lat: 30.374910,
          lng: 76.812818,
        },
        locations: [
          {
            lat: 30.374910,
            lng: 76.812818,
            label: 'A',
            draggable: true
          },
          {
            lat: 30.368389,
            lng: 76.730122,
            label: 'B',
            draggable: false
          },
          {
            lat: 30.464857,
            lng: 76.798030,
            label: 'C',
            draggable: true
          }
        ]
      }*/
    }
  coords = [
    [20.593684,78.96288]
  ];
  levelSearch:string = '';
  authToken:string = '';
  latitude:string = '';
  longitude:string = '';
  public totlaMeters: any;
    markers: marker[] = [];
    zoom: number = 8;
    // initial center position for the map
    lat: number;
    lng: number;
    userType:  boolean = true;
    public subDivisionName: any;
    subdivisionId: any = 0;
    submitted: boolean = false;
    message: string = '';
    isLoading: boolean = false;
    siteUrl = this.genReqs.apiPath();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private conmodalService: ConsumermodalService,
    private modalService: ModalService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.mapDefault(30.368389,76.798030);
   
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.subdivisionId = params['subDivisionId'];
      this.authToken = params['authToken'];
      this.latitude = params['latitude'];
      this.longitude = params['longitude'];
    });  
      //console.log("Hello");
    this.addAuthorityForm = this.formBuilder.group({
      latitude: [{value: this.latitude, disabled: true}, Validators.required],
      longitude:  [{value: this.longitude, disabled: true}, Validators.required],
      radious: [1, Validators.required],
    });
    this.getMapData();
    //console.log("---------"+this.siteUrl);
  }
  get aD() { return this.addAuthorityForm.controls; }
  onSubmitAddAuthority() {
    if (this.addAuthorityForm.invalid) {
        this.submitted = true;
        return;
    }
    this.message = '';
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('latitude', this.aD.latitude.value);
    body.set('longitude', this.aD.longitude.value);
    body.set('radious', this.aD.radious.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/addNewAuth', body).subscribe((result) => {
        this.responseData = result;
        this.isLoading = false;
        if (this.responseData.success) {
            this.isLoading = false;
            this.message = this.responseData.data.message;
        }
    },(err) =>{
        if (err.status === 401) {
            this.authenticationService.logout();
        }
        this.message = 'Unable to process yours request!';
    });
  }

  getMapData(addNew:boolean=false) {
    this.loading = true;
    this.markers= [];
    this.totlaMeters=0;
    this.coords = [[20.593684,78.96288]];
    let body = new URLSearchParams();
    body.set('subDivisionId', this.subdivisionId);
    body.set('latitude', this.latitude);
    body.set('longitude', this.longitude);
    body.set('radious', this.aD.radious.value);
    body.set('authDisplay', '0');
    body.set('filter', this.levelSearch);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/deshboadGoogleApiData', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      if (this.responseData.success) {
        setTimeout(() => {
          this.subDivisionName=this.responseData.data.name;
          this.totlaMeters=this.responseData.data.totalMeters;
          this.markers = this.responseData.data.location;
          this.zoom = 10;
          //console.log("this.responseData.data.location",this.responseData.data.location);
        
          //console.log("before map",this.responseData.data.location);
          if(this.responseData.data.location){
            this.coords.push(this.responseData.data.location);
            //console.log("before dtaa", this.responseData.data.location);
            /*for (let i = 0; i < this.responseData.data.location.length; i++) {
              this.coords.push([
                this.responseData.data.location[i]['lat'],
                this.responseData.data.location[i]['lng'],
                this.responseData.data.location[i]['icon'],
                this.responseData.data.location[i]['Sub_Devision_Name'],
                this.responseData.data.location[i]['consName'],
                this.responseData.data.location[i]['flatId'],
                this.responseData.data.location[i]['label'],
                this.responseData.data.location[i]['rtc'],
                this.responseData.data.location[i]['recharge'],
                this.responseData.data.location[i]['id'],
                this.responseData.data.location[i]['Cons_Account_No'],
              ]);
            }*/
            //console.log("After dtaa", this.coords);
            this.recenterMap(
              this.responseData.data.pin.lat,
              this.responseData.data.pin.lng
            );
          }
         
        }, 0);
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  filterData(val: string) {
    this.submitted=true;
    if(val=='1'){
        this.getMapData(false);
    }else{
        this.getMapData(true);
    }
}


  downloadAsPDF2() {
    let pdfUrl = this.siteUrl+'/reports/mapData?subdivisionId='+this.subdivisionId
    +'&latitude='+this.latitude
    +'&longitude='+this.longitude
    +'&radious='+this.aD.radious.value
    +'&authDisplay=0'
    +'&filter='+this.levelSearch
    +'&authToken='+this.authToken
    +'&pdf=1';
    window.open(pdfUrl);
}

csvNavigate() {
        let pdfUrl = this.siteUrl+'/reports/mapData?subdivisionId='+this.subdivisionId
        +'&latitude='+this.latitude
        +'&longitude='+this.longitude
        +'&radious='+this.aD.radious.value
        +'&authDisplay=0'
        +'&filter='+this.levelSearch
        +'&authToken='+this.authToken
        +'&pdf=2';
        window.open(pdfUrl);
}

  onLevelSearchChange(search) {
    this.levelSearch = search;
    if (this.levelSearch.length == 7) {
      this.getMapData(false);
    }else if (this.levelSearch.length == 8) {
      this.getMapData(false);
    }else if (this.levelSearch.length == 10) {
      this.getMapData(false);
    }else if (this.levelSearch.length == 11) {
      this.getMapData(false);
    }else if (this.levelSearch.length == 12) {
      this.getMapData(false);
    }else if (this.levelSearch.length == 0) {
      this.getMapData(false);
    }
  }

  updateCoordValue() {
    //console.log("in function",this.coords);
    return this.coords;
  }
  
  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
  }

  mapMarks(location: string) {
    if (this.mapData.hasOwnProperty(location)) {
      this.markers = this.mapData[location].locations;
      this.recenterMap(
        this.mapData[location].pin.lat,
        this.mapData[location].pin.lng);
    } else {
      this.mapDefault('30.6057029','76.8561353');
    }
  }

  recenterMap = (lt, lg) => {
    this.lat = lt;
    this.lng = lg;
  }

  mapDefault(lat,lng) {
    //this.markers = this.mapData.default.locations;
    this.zoom = 8;
    // initial center position for the map
    //console.log("map data",lat,lng);
    this.lat =parseFloat(lat);
    this.lng =parseFloat(lng);
  }

  /**to get the meter list on click on Dcu */

}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

