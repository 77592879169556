import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-create-schedular',
  templateUrl: './create-schedular.component.html',
  styleUrls: ['./create-schedular.component.scss']
})
export class CreateSchedularComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  pickedRange: Date[] = null;
  subDivisionId:any;
  //
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;

  

  subDevision: any = [];
  meterCommands = [{
      "meterid": 0,
      "subDivisionId": 0,
      "meterCmdCode": "null",
      "meterCmdName": 'Please select command',
      "updationDateTime": null
    }]

    meterIdNo = [{
        "muID": 0,
        "mID": null
    }]
    
    scheExec = [ {
      "execUID": 1,
      "subDivisionId": 2,
      "execName": "Every Minute",
      "serverDateTime": "2020-05-12 17:50:28"
    },
    {
      "execUID": 2,
      "subDivisionId": 2,
      "execName": "Every 30 Minuts",
      "serverDateTime": "2020-05-12 17:50:29"
    },
    {
      "execUID": 3,
      "subDivisionId": 2,
      "execName": "Every Hour",
      "serverDateTime": "2020-05-12 17:50:29"
    },
    {
      "execUID": 4,
      "subDivisionId": 2,
      "execName": "Every Day",
      "serverDateTime": "2020-05-12 17:50:29"
    },
    {
      "execUID": 5,
      "subDivisionId": 2,
      "execName": "Every Week",
      "serverDateTime": "2020-05-12 17:50:29"
    },
    {
      "execUID": 6,
      "subDivisionId": 2,
      "execName": "Every Month",
      "serverDateTime": "2020-05-12 17:50:30"
    },
    {
      "execUID": 7,
      "subDivisionId": 2,
      "execName": "Quaterly",
      "serverDateTime": "2020-05-12 17:50:30"
    },
    {
      "execUID": 8,
      "subDivisionId": 2,
      "execName": "Yearly",
      "serverDateTime": "2020-05-12 17:50:31"
    }];

    hours = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    minute = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];
    startDate = '';
    endDate = '';
    nowDate = new Date();

    startHour='';
    startMinute='';
    
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    ){
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }

    

    ngOnInit() { 
      this.addForm = this.formBuilder.group({
        schedularName: ['', Validators.required],
        commanType: ['', Validators.required],
        //meters: ['', Validators.required],
        //schedularStartEndDate: ['', Validators.required],
        //schedularStartEndTime: ['', Validators.required],
        schedularDate: [this.nowDate, Validators.required],
        schedularFrequency: ['', Validators.required],
        activeStatus: ['', Validators.required],
        startHour: ['', Validators.required],
        //endHour: ['', Validators.required],
        startMinute: ['', Validators.required],
        //endMinute: ['', Validators.required]
      });
      this.getMeterCommandListing();
      this.schedularFrequencyFun();
    }

    ngAfterViewInit() {
       //this.getMeterCommandListing();
    }
    ngOnDestroy() {
      //this.modalService.dismissAll();
    }

    onCmdChange(e) {
     // console.log("hello",this.aF.commanType.value);
      this.hours=[''];
      this.minute=[''];
     
      this.scheExec = [{
          "execUID": 0,
          "subDivisionId": 0,
          "execName": "",
          "serverDateTime": ""
      }];
     
      if(this.aF.commanType.value=='46'){
          this.scheExec= [
              {
                "execUID": 4,
                "subDivisionId": 2,
                "execName": "Every Day",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 5,
                "subDivisionId": 2,
                "execName": "Every Week",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 6,
                "subDivisionId": 2,
                "execName": "Every Month",
                "serverDateTime": "2020-05-12 17:50:30"
              }
            ];
          this.hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
          this.minute = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      }else{
        this.schedularFrequencyFun();
          /*this.scheExec= [
              {
                "execUID": 1,
                "subDivisionId": 2,
                "execName": "Every Minute",
                "serverDateTime": "2020-05-12 17:50:28"
              },
              {
                "execUID": 2,
                "subDivisionId": 2,
                "execName": "Every 30 Minuts",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 3,
                "subDivisionId": 2,
                "execName": "Every Hour",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 4,
                "subDivisionId": 2,
                "execName": "Every Day",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 5,
                "subDivisionId": 2,
                "execName": "Every Week",
                "serverDateTime": "2020-05-12 17:50:29"
              },
              {
                "execUID": 6,
                "subDivisionId": 2,
                "execName": "Every Month",
                "serverDateTime": "2020-05-12 17:50:30"
              },
              {
                "execUID": 7,
                "subDivisionId": 2,
                "execName": "Quaterly",
                "serverDateTime": "2020-05-12 17:50:30"
              },
              {
                "execUID": 8,
                "subDivisionId": 2,
                "execName": "Yearly",
                "serverDateTime": "2020-05-12 17:50:31"
              }
            ];*/
          this.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
          this.minute = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      }
  }

    getDateFromRange(pickedRange: Date[]) {
        let smonth = pickedRange[0].getMonth()+1; //always move 1 value up
        let sday = pickedRange[0].getDate();
        let syear = pickedRange[0].getFullYear();
        this.startDate = syear+'-'+smonth+'-'+sday;
        //let emonth = pickedRange[1].getMonth()+1; //always move 1 value up
        //let eday = pickedRange[1].getDate();
        //let eyear = pickedRange[0].getFullYear();
        //this.endDate = eyear+'-'+emonth+'-'+eday;
      }

      getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
           // console.log("date",eday);
            if(eday==29 || eday==30 || eday==31){
              this.startDate ='';
              //this.aF.schedularDate.setValue("");
              
              this.aF.schedularDate.reset();
             
            }else{
              this.startDate = eyear + '-' + emonth + '-' + eday;
            }
           
        }
    }
     
    makeDateFinal (){
        this.startHour = this.aF.startHour.value
        //let endHour = this.aF.endHour.value
        this.startMinute = this.aF.startMinute.value
        //let endMinute = this.aF.endMinute.value
        if(this.startDate==''){
          this.startDate = '';
        }else{
          this.startDate = this.startDate+' '+this.startHour+':'+this.startMinute+':00';
        }
        //this.endDate = this.endDate+' '+endHour+':'+endMinute+':00';
       
    }
  
    get aF() { return this.addForm.controls; }
     onSubmitAddForm(){
       //console.log("1");
         this.makeDateFinal();
         //console.log("2");
        if (this.addForm.invalid ) {
            this.submitted = true;
          return;
        }

        if (this.startDate =='' ) {
          this.aF.schedularDate.setValue(this.nowDate);
          alert("Do not select 29,30 or 31 dates. Please choose another one.");
          //this.aF.schedularDate.reset();
          return;
        }

        this.isLoading = true;
        //console.log("3");
        const formData = new FormData();
        formData.append('subDivisionId', this.subDivisionId);
        //console.log("4",this.subDivisionId);
        formData.append('schedularName', this.aF.schedularName.value);
        //console.log("5",this.aF.schedularName.value);
        formData.append('commanType', this.aF.commanType.value);
        //console.log("6",this.aF.commanType.value);
        //formData.append('meters', this.aF.meters.value);
        formData.append('schedularStartTime', this.startDate);
        //console.log("7",this.startDate);
        formData.append('schedularEndTime', this.endDate);
        //console.log("8",this.endDate);
        formData.append('schedularFrequency', this.aF.schedularFrequency.value);
        //console.log("9",this.aF.schedularFrequency.value);
        formData.append('activeStatus', this.aF.activeStatus.value);
        //console.log("10",this.aF.activeStatus.value);
        formData.append('authToken', this.currentUser.authToken);
        this.genReqs.formDataPost('/addSchedular', formData).subscribe((result)=>{
          this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            //console.log("11",this.responseData);
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.responseMessage = 'Unable to process yours request!';
        });
      }

      getMeterCommandListing() {
        let body = new URLSearchParams();
        body.set('meterSubDivision', this.subDivisionId);
        body.set('offSet', '0');
        body.set('limit', '99999999');
        body.set('filter', '');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getMeterCmd', body).subscribe((result)=>{
          this.responseData = result;
          if(this.responseData.success){
            this.meterCommands = this.responseData.data;
           // this.meterList();
            
          }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        });
      }

      meterList() {
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('dcuId', '0');
        body.set('authToken', this.currentUser.authToken);
        body.set('offSet','0');
        body.set('limit', '99999999');
        this.genReqs.postReq('/getMeters', body).subscribe((result)=>{
          this.responseData = result;
          if(this.responseData.success){
            this.meterIdNo = this.responseData.data;
            //this.schedularFrequency();
          }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
      });
    }

    schedularFrequencyFun() {
        let body = new URLSearchParams();
        body.set('execSubDivision', this.subDivisionId);
        body.set('execTypeId', '0');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getSchedExecType', body).subscribe((result)=>{
          this.responseData = result;
          if(this.responseData.success){
            this.scheExec = this.responseData.data;
          }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
      });
    }

    

}
