import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-addconsumer',
  templateUrl: './addconsumer.component.html',
  styleUrls: ['./addconsumer.component.scss']
})
export class AddconsumerComponent implements OnInit {
  llebData = "LL EB (0.05kW-15.00kW)";
  lldgData = "LL DG (0.05kW-15.00kW)";
  ebLimit = 15;
  dgLimit = 15;
  getThrough: Access;
  faSpinner = faSpinner;
  filterFlat: any;
  latiTude: any;
  longiTude: any;
  filterMeter: any;
  filterUser: any;
  filterIp: any;
  messageNew = '';
  meterTypeData = '0';
  isLoading = false;
  flatStatus = false;
  thumb = true;
  loadingMessage: any;
  enableIpField = true;
  authorityDOB = "0000-00-00";
  addForm: UntypedFormGroup;
  responseMessage: string = '';
  message: string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  AddProcess: boolean = false;
  loginType: boolean = false;
  submitted: boolean = false;
  conCategory: any = [
    { 'id': 1, 'Name': 'D(Lpp)' },
    { 'id': 2, 'Name': 'C(Ltp)(ia)' },
    { 'id': 3, 'Name': 'C(Ltp)(ib)' },
    { 'id': 4, 'Name': 'Rate D(3)' },
    { 'id': 5, 'Name': 'Rate Puppt(LT)' },
  ];

  activeStatus: any = [
    { 'id': 'N', 'Name': 'In-Active' },
    { 'id': 'Y', 'Name': 'Active' }
  ];

  simType: any = [
    { 'id': 0, 'name': '' }
  ];
  mType: any = [
    { 'id': '1', 'Name': 'Single Phase' },
    { 'id': '3', 'Name': 'Three Phase' },
    { 'id': '2', 'Name': 'LTCT Meters' }
  ];

  comm: any = [
    { 'id': '1', 'Name': 'GPRS Mode' },
    { 'id': '2', 'Name': 'RF Mode' }
  ];

  firmWareVersion: any = [
    { 'id': 0, 'Name': '' },
    { 'id': 1, 'Name': '' }
  ];
  area: any = [
    { 'id': 0, 'Name': '' },
    { 'id': 1, 'Name': '' }
  ];
  groupData: any = [{}];
  towerData: any = [{
    'towerId': 0,
    'subDivId': 0,
    'dgId': 0,
    'dgName': "dgName",
    'towerName': "Select Tower"
  }];
  dgData: any = [{
    'dgId': 0,
    'subDivId': 0,
    'dgName': 'Select DG'
  }];

  nowDate = new Date();
  constructor(private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    this.getPosition();
    if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
      this.loginType=true;
    }
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
    if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
      this.addForm = this.formBuilder.group({
        flatNo: ['', Validators.required],
        flatArea: [''],
        dgNameData: [''],
        towerNameData: [''],
        groupNameData: [''],
        consFullName: ['', Validators.required],
        userName: ['', Validators.required],
        consPassword: ['', Validators.required],
        consEmailId: ['', [Validators.required, Validators.email]],
        consLandlineNo: [''],
        consMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
        consHomeAddressline1: ['', Validators.required],
        consHomeAddressline2: [''],
        consOfficeAddressline1: [''],
        consOfficeAddressline2: [''],
        latitude: [''],
        longitude: [''],
        authorityDOB: [this.nowDate, Validators.required],
        conActiveStatus: ['', Validators.required],
        meterSerialNo: ['', Validators.required],
        loadLimitEB: ['', [Validators.min(0.05), Validators.max(65.00)]],
        meterIpAddress: [''],
        meterPortNo: [''],
        hesId: [''],
        meterType: ['', Validators.required],
        meterFirmwareCodeVersion: ['', Validators.required],
        commMode: ['', Validators.required],
        //meterInstalletionDateTime: ['', Validators.required],
        gprsSimNo: [''],
        gprsSimContactNo: [''],
        gprsSimType: [''],
        category: ['']
      });
  }else{
    this.addForm = this.formBuilder.group({
      flatNo: ['', Validators.required],
      flatArea: ['', Validators.required],
      dgNameData: ['', Validators.required],
      towerNameData: ['', Validators.required],
      groupNameData: ['', Validators.required],
      consFullName: ['', Validators.required],
      userName: ['', Validators.required],
      consPassword: ['', Validators.required],
      consEmailId: ['', [Validators.required, Validators.email]],
      consLandlineNo: [''],
      consMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
      consHomeAddressline1: ['', Validators.required],
      consHomeAddressline2: [''],
      consOfficeAddressline1: [''],
      consOfficeAddressline2: [''],
      latitude: [''],
      longitude: [''],
      authorityDOB: [this.nowDate, Validators.required],
      conActiveStatus: ['', Validators.required],
      meterSerialNo: ['', Validators.required],
      loadLimitEB: ['', [Validators.min(0.05), Validators.max(65.00)]],
      loadLimitDG: ['', [Validators.min(0.05), Validators.max(65.00)]],
      meterIpAddress: [''],
      meterPortNo: [''],
      hesId: [''],
      meterPortNo1: [{ value: '', disabled: true }],
      meterType: ['', Validators.required],
      dgFreeUnits: ['', Validators.required],
      meterFirmwareCodeVersion: ['', Validators.required],
      commMode: ['', Validators.required],
      //meterInstalletionDateTime: ['', Validators.required],
      gprsSimNo: [''],
      gprsSimContactNo: [''],
      gprsSimType: ['']
    });
  }
    this.getDgData();
    if(this.loginType){
      this.getTowerData();
    }
    this.getSimType();
    this.meterFrimwareVersion();
    this.flatArea();
    /*this.getPosition().then(pos=>
      {
         console.log(`Positon: ${pos.lng} ${pos.lat}`);
      });*/
      
  }

  getSimType() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSimTypeData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.simType = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }


  ngAfterViewInit() {

  }
  ngOnDestroy() {
    //this.modalService.dismissAll();
  }

  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57)){
      return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
    }else{
      alert("Can't use special charactors!");
      return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
    }
  }
  
  getPosition(): Promise<any>
  {
    if (navigator.geolocation) {
      return new Promise((resolve,reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
            //enableHighAccuracy: true
           
            this.aF.latitude.setValue(resp.coords.latitude);
            this.aF.longitude.setValue(resp.coords.longitude);
            //console.log("LatLong Values",resp.coords.latitude," ",resp.coords.longitude," ",resp.coords.accuracy);
            resolve({lng: resp.coords.longitude, lat: resp.coords.latitude, enableHighAccuracy: true,timeout: 5000,maximumAge: 0});
           
          },
          err => {
            reject(err);
            if (err.code == 0) {
              console.log("Unknown Error");
            }
            if (err.code == 1) {
              console.log("Access denied by user");
               
            }
            if (err.code == 2) {
              console.log("Position unavailable");
              
            }
            if (err.code == 3) {
              console.log(" Timed out");
              
            }
          });
      });
    }

  }

  


  onMeterTypeChange(e) {
    if (this.aF.meterType.value == '1') {
      this.ebLimit = 15;
      this.dgLimit = 15;
      this.llebData = "LL EB (0.05kW-15.00kW)";
      this.lldgData = "LL DG (0.05kW-15.00kW)";
      // this.aF.loadLimitEB.reset();
      //this.aF.loadLimitDG.reset();
    }
    if (this.aF.meterType.value == '3') {
      this.ebLimit = 65;
      this.dgLimit = 65;
      this.llebData = "LL EB (0.05kW-65.00kW)";
      this.lldgData = "LL DG (0.05kW-65.00kW)";
      //this.aF.loadLimitEB.reset();
      //this.aF.loadLimitDG.reset();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onCommTypeChange(e) {
    if (this.aF.commMode.value == '1') {
      this.enableIpField = true;
      this.aF.hesId.setValue('00000000-0000-0000-0000-000000000000');
      //alert(this.enableIpField);
      //this.aF.meterIpAddress.setValue('');
      //this.aF.meterPortNo.setValue(0);
    }
    if (this.aF.commMode.value == '2') {
      this.enableIpField = false;
      this.aF.meterIpAddress.setValue('');
      this.aF.meterPortNo.setValue(0);
      //alert(this.enableIpField);
      //this.aF.meterIpAddress.disable;
    }
  }



  get aF() { return this.addForm.controls; }
  onSubmitAddForm() {
    //alert("Hello1");
    if (this.aF.meterType.value == '1') {
      if (this.aF.loadLimitEB.value > 15) {
        alert("Single phase Grid Supply Load Limit must be less than or equal to 15 kW");
        this.aF.loadLimitEB.reset();
        return;
      }
      if(!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
        if (this.aF.loadLimitDG.value > 15) {
          alert("Single phase DG Supply Load Limit must be less than or equal to 15 kW");
          this.aF.loadLimitDG.reset();
          return;
        }
      }
    } else {
      if (this.aF.loadLimitEB.value > 65) {
        alert("Three phase Grid Supply Load Limit must be less than or equal to 65 kW");
        this.aF.loadLimitEB.reset();
        return;
      }
      if(!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
        if (this.aF.loadLimitDG.value > 65) {
          alert("Three phase DG Supply Load Limit must be less than or equal to 65 kW");
          this.aF.loadLimitDG.reset();
          return;
        }
      }
    }
    //alert("Hello2");
    if (this.addForm.invalid) {
      this.submitted = true;
      return;
    }
    //alert("Hello3");
    this.isLoading = true;
    this.message = '';
    let body = new URLSearchParams();
    body.set('subDivId', this.currentUser.data.userData.subdivision);
    body.set('flatNo', this.aF.flatNo.value);
    body.set('dgId', this.aF.dgNameData.value);
    body.set('towerId', this.aF.towerNameData.value);
    body.set('groupId', this.aF.groupNameData.value);
    body.set('consFullName', this.aF.consFullName.value);
    body.set('userName', this.aF.userName.value);
    body.set('consPassword', this.aF.consPassword.value);
    body.set('consEmailId', this.aF.consEmailId.value);
    body.set('consLandlineNo', this.aF.consLandlineNo.value);
    body.set('consMobileNo', this.aF.consMobileNo.value);
    body.set('consHomeAddressline1', this.aF.consHomeAddressline1.value);
    body.set('consHomeAddressline2', this.aF.consHomeAddressline2.value);
    body.set('consOfficeAddressline1', this.aF.consOfficeAddressline1.value);
    body.set('consOfficeAddressline2', this.aF.consOfficeAddressline2.value);
    body.set('latitude', this.aF.latitude.value);
    body.set('longitude', this.aF.longitude.value);
    body.set('conActiveStatus', this.aF.conActiveStatus.value);
    body.set('consDOB', this.authorityDOB);
    body.set('flatArea', this.aF.flatArea.value);
    //body.set('meterInstalletionDateTime', this.aF.meterInstalletionDateTime.value);
    if (this.aF.meterSerialNo.value == '') {
      body.set('meterSerialNo', '');
      body.set('loadLimitEB', '');
      body.set('loadLimitDG', '');
      body.set('meterIpAddress', '');
      body.set('meterPortNo', '');
      body.set('gprsSimNo', '');
      body.set('gprsSimContactNo', '');
      body.set('hesId', '00000000-0000-0000-0000-000000000000');
      body.set('meterType', '');
    } else {
      body.set('meterSerialNo', this.aF.meterSerialNo.value);
      body.set('loadLimitEB', this.aF.loadLimitEB.value);
      if(!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
        body.set('loadLimitDG', this.aF.loadLimitDG.value);
        body.set('category','');
      }else{
        body.set('loadLimitDG','');
        body.set('category',this.aF.category.value);
      }
      body.set('meterIpAddress', this.aF.meterIpAddress.value);
      body.set('meterPortNo', this.aF.meterPortNo.value);
      body.set('gprsSimNo', this.aF.gprsSimNo.value);
      body.set('gprsSimContactNo', this.aF.gprsSimContactNo.value);
      body.set('hesId', this.aF.hesId.value);
      body.set('meterType', this.aF.meterType.value);
    }
    body.set('commMode', this.aF.commMode.value);
    if(!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
      body.set('dgFreeUnits', this.aF.dgFreeUnits.value);
    }else{
      body.set('dgFreeUnits','');
    }
    body.set('gprsSimType', this.aF.gprsSimType.value);
    body.set('meterFirmwareCodeVersion', this.aF.meterFirmwareCodeVersion.value);
    body.set('authToken', this.currentUser.authToken);

    this.AddProcess = true;
    this.genReqs.postReq('/addConsumer', body).subscribe((result) => {

      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if (this.message.includes("Already") || this.message.includes("already") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful") || this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")) {
          if (this.message.includes("Meter Id Already Exist")) {
            this.thumb = false;
            this.aF.meterSerialNo.reset();
          } else if (this.message.includes("Meter Ip Already Exist")) {
            this.thumb = false;
            this.aF.meterIpAddress.reset();
          } else if (this.message.includes("Flat Already Exist")) {
            this.thumb = false;
            this.aF.flatNo.reset();
          } else if (this.message.includes("HES Already Exist")) {
            this.thumb = false;
            this.aF.hesId.reset();
          } else if (this.message.includes("SIM No Already Exist")) {
            this.thumb = false;
            this.aF.gprsSimNo.reset();
          } else if (this.message.includes("SIM Contact No Already Exist")) {
            this.thumb = false;
            this.aF.gprsSimContactNo.reset();
          } else if (this.message.includes("Consumer Already Exist.") || this.message.includes("Cons Name Already Exist")) {
            this.thumb = false;
            this.aF.userName.reset();
          }
          this.thumb = false;
        } else {
          this.thumb = true;
        }
      } else {
        this.thumb = false;
      }


    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.AddProcess = false;
      this.message = 'Unable to process yours request!';
    });
  }
  

  meterFrimwareVersion() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '0');
    body.set('versionId', '');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getMeterFirmwareVersion', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.firmWareVersion = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  flatArea() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '0');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getFlatArea', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.area = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }



  validateFieldsFlat(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterFlat);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Flat")) {
          //this.thumb = false;
          //this.aF.flatNo.reset();
          this.aF.flatNo.setErrors({'uniqueFlat': true});
        }else{
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  validateFieldsUser(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterUser);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Cons")) {
          this.aF.userName.setErrors({'uniqueUser': true});
          //this.thumb = false;
          //this.aF.userName.reset();
        } else {
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  validateFieldsMeter(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterMeter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Meter")) {
          this.aF.meterSerialNo.setErrors({'uniqueMeter': true});
          //this.thumb = false;
          //this.aF.meterSerialNo.reset();
        } else {
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  validateFieldsIp(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterIp);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Ip")) {
          this.aF.meterIpAddress.setErrors({'uniqueIp': true});
          //this.thumb = false;
          //this.aF.meterIpAddress.reset();
        } else {
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  onValidateFields(search) {
    this.filterFlat = search;
    this.validateFieldsFlat(false);
  }

  onValidateFieldsMeter(search) {
    this.filterMeter = search;
    this.validateFieldsMeter(false);
  }

  onValidateFieldsIp(search) {
    this.filterIp = search;
    this.validateFieldsIp(false);
  }

  onValidateFieldsUser(search) {
    this.filterUser = search;
    this.validateFieldsUser(false);
  }


  getDgData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDGData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.dgData = this.responseData.data
        //console.log("dgData", this.dgData);
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getTowerData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', '0');
    body.set('dgId', this.dgData[0].dgId);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.towerData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }


  onDGChange(e) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', '0');
    body.set('dgId', this.aF.dgNameData.value);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.towerData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }


  onTowerChange(e) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', this.aF.towerNameData.value);
    body.set('dgId', this.aF.dgNameData.value);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    //console.log("dgtowerload",this.currentUser.data.userData.subdivision," ",this.aF.dgNameData.value);
    //this.selectedTower = e.value;
    this.genReqs.postReq('/getGroupDataNew', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.groupData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  getDOB(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.authorityDOB = eyear + '-' + emonth + '-' + eday;
    }
  }

  
}
