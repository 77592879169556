import { Component, OnInit } from '@angular/core';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { Access } from '../_models/access';
import { Router } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from '../_services';
import { GridlistService } from '../_services/gridlist.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deshboardauth5',
  templateUrl: './deshboardauth5.component.html',
  styleUrls: ['./deshboardauth5.component.scss']
})

export class Deshboardauth5Component implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getGothrough : Access;
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  circleId:any = 0;
  breakpoint: number;
  closeResult: string;
  addForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      
      this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
  }

  ngOnInit() {
      this.countryId = this.currentUser.data.userData.Country || 0;
      this.stateId = this.currentUser.data.userData.State || 0;
      this.utilityId = this.currentUser.data.userData.utility || 0;
      this.circleId = this.currentUser.data.userData.circle || 0;
      this.authenticationService.getDeshboardRoute();
      this.getAccesslevels();
      this.reCountCols(window.innerWidth);
  }

      ngAfterViewInit() {
        this.addForm = this.formBuilder.group({
          Code: ['', Validators.required],
          Name: ['', Validators.required],
          Image: [null, Validators.required]
        });
        this.editForm = this.formBuilder.group({
          Code: ['', Validators.required],
          Name: ['', Validators.required],
          Image: ['',],
          Id: ['']
        });
      }

      ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
      }

      onResize(event) {
        this.reCountCols(event.target.innerWidth);
      }

      reCountCols(width:number) {
        this.breakpoint = this.gridlistService.reCountCols(width);
      }

  getAccesslevels() {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLabels', body).subscribe((result)=>{
      this.responseData = result;
      this.loadingUtilitys = false;
      if(this.responseData.success){
        this.allUsersLablesData = this.responseData.data;
        this.allutilitys = this.allUsersLablesData.allutilitys;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  goToDivisionView(division_id) {
    this.router.navigate(['/deshauthsubdivision'], {
       queryParams: 
       { 
         'country': this.genReqs.encryptData(this.countryId), 
         'state': this.genReqs.encryptData(this.stateId),
         'utility': this.genReqs.encryptData(this.utilityId),
         'circle': this.genReqs.encryptData(this.circleId),
         'division': this.genReqs.encryptData(division_id),
        }
      });
  }

  /**to get the meter list on click on Dcu */
  countryEditView(row, content) {
    this.responseMessage = '';
    this.eF.Code.setValue(row.divisionName);
    this.eF.Name.setValue(row.divisionName);
    this.eF.Id.setValue(row.division_id);
    this.EditName = row.divisionName;
    this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

        /*
    forms images pickers
  */
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    this.aF.Image.setValue(file);
  }
}

  onFileSelectEdit(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.eF.Image.setValue(file);
    }
  }

   /*
  add utility form submition here
  */
 get aF() { return this.addForm.controls; }
 onSubmitAddForm(){
    if (this.addForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('divisionImage', this.aF.Image.value);
    formData.append('countryId', this.countryId);
    formData.append('stateId', this.stateId);
    formData.append('circleId', this.circleId);
    formData.append('utilityId', this.utilityId);
    formData.append('divisionCode', this.aF.Code.value);
    formData.append('divisionName', this.aF.Name.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/addDivision', formData).subscribe(
      (res) => {
        this.responseMessage = res.message;
        if (res.success) {
          this.addForm.reset();
          this.getAccesslevels();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

  get eF() { return this.editForm.controls; }
  onSubmitEditForm() {
    //addCountry
      if (this.editForm.invalid) {
        return;
      }
      const formData = new FormData();
      if (this.eF.Image.value != '') {
        formData.append('divisionImage', this.eF.Image.value);
      }
      formData.append('countryId', this.countryId);
      formData.append('stateId', this.stateId);
      formData.append('circleId', this.circleId);
      formData.append('utilityId', this.utilityId);
      formData.append('divisionId', this.eF.Id.value);
      formData.append('divisionCode', this.eF.Code.value);
      formData.append('divisionName', this.eF.Name.value);
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/editDivision', formData).subscribe(
        (res) => {
          this.responseMessage = res.message;
          if (res.success) {
            this.editForm.reset();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
  }

}
