import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-meterconfighappyhours',
  templateUrl: './meterconfighappyhours.component.html',
  styleUrls: ['./meterconfighappyhours.component.scss']
})
export class MeterconfighappyhoursComponent implements OnInit {
  faSpinner = faSpinner;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;
  submitted: boolean = false;
  tagValue = 'col-sm-8 text-left';
  cmdformData: any = 0;
  configForm: UntypedFormGroup;
  demoNumber = 5 ;
  counter = Array;
  subDivisionId: any = 0;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  @Input() meterDetail:any = {
    mID: "All",
    dID:0,
    sdID:0
  };
  numberReturn(length){
    return new Array(length);
  }

  smOnHour: any = 
  [
      {'id':'00'},  {'id':'01'}, {'id':'02'}, {'id':'03'},{'id':'04'},{'id':'05'},{'id':'06'},{'id':'07'},{'id':'08'},{'id':'09'},{'id':'10'},
      {'id':'11'}, {'id':'12'}, {'id':'13'},{'id':'14'},{'id':'15'},{'id':'16'},{'id':'17'},{'id':'18'},{'id':'19'},{'id':'20'},
      {'id':'21'}, {'id':'22'}, {'id':'23'}
];

smOnMinute: any = 
[
  {'id':'00'}, {'id':'01'}, {'id':'02'},{'id':'03'},{'id':'04'},{'id':'05'},{'id':'06'},{'id':'07'},{'id':'08'},{'id':'09'},{'id':'10'},
  {'id':'11'}, {'id':'12'}, {'id':'13'},{'id':'14'},{'id':'15'},{'id':'16'},{'id':'17'},{'id':'18'},{'id':'19'},{'id':'20'},
  {'id':'21'}, {'id':'22'}, {'id':'23'},{'id':'24'},{'id':'25'},{'id':'26'},{'id':'27'},{'id':'28'},{'id':'29'},{'id':'30'},
  {'id':'31'}, {'id':'32'}, {'id':'33'},{'id':'34'},{'id':'35'},{'id':'36'},{'id':'37'},{'id':'38'},{'id':'39'},{'id':'40'},
  {'id':'41'}, {'id':'42'}, {'id':'43'},{'id':'44'},{'id':'45'},{'id':'46'},{'id':'47'},{'id':'48'},{'id':'49'},{'id':'50'},
  {'id':'51'}, {'id':'52'}, {'id':'53'},{'id':'54'},{'id':'55'},{'id':'56'},{'id':'57'},{'id':'58'},{'id':'59'}
];

startDate: any = 
  [
    {'id':1}, {'id':2}, {'id':3},{'id':4},{'id':5},{'id':6},{'id':7},{'id':8},{'id':9},{'id':10},
    {'id':11}, {'id':12}, {'id':13},{'id':14},{'id':15},{'id':16},{'id':17},{'id':18},{'id':19},{'id':20},
    {'id':21}, {'id':22}, {'id':23},{'id':24},{'id':25},{'id':26},{'id':27},{'id':28},{'id':29},{'id':30},
    {'id':31}
];

startMonth: any = 
  [
    {'id':1}, {'id':2}, {'id':3},{'id':4},{'id':5},{'id':6},{'id':7},{'id':8},{'id':9},{'id':10},
    {'id':11}, {'id':12}
];

weekay: any = 
  [
    {'id':1,'Name':'Monday'}, 
    {'id':2,'Name':'Tuesday'}, 
    {'id':3,'Name':'Wednesday'},
    {'id':4,'Name':'Thursday'},
    {'id':5,'Name':'Friday'},
    {'id':6,'Name':'Saturday'},
    {'id':7,'Name':'Sunday'}
];


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.configForm = this.formBuilder.group({
      startHour: ['', Validators.required],
      startMinute: ['', Validators.required],
      endHour: ['', Validators.required],
      endMinute: ['', Validators.required],
      holidayMonth: ['', Validators.required],
      holidayDate: ['', Validators.required],
      weekDay: ['', Validators.required],
    });
    if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
      this.getCurrentMeterConfig();
      this.cmdformData=1;
      this.tagValue = 'col-sm-10 text-left';
    }
  }

  get cF() { return this.configForm.controls; }

  getCurrentMeterConfig() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/getHappyHoursData', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
           // console.log(this.responseData.data);
            this.cF.startHour.setValue(this.responseData.data.startHour);
            this.cF.startMinute.setValue(this.responseData.data.startMinute);
            this.cF.endHour.setValue(this.responseData.data.endHour);
            this.cF.endMinute.setValue(this.responseData.data.endMinute);
            this.cF.holidayMonth.setValue(this.responseData.data.holidayMonth);
            this.cF.holidayDate.setValue(this.responseData.data.holidayDate);
            this.cF.weekDay.setValue(this.responseData.data.weekDay);

            //##to do fill form data from response data with setvalue
        } else {
           // console.log(this.responseData.message);//this.responseData.isresponse;
        }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }

  onSubmitConfigMeter() {
    if (this.configForm.invalid) {
      this.submitted = true;
      return;
    }

    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);
    //formData.append('configSubDivisionId', this.meterDetail.sdID);
    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }

    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
    formData.append('startHour', this.cF.startHour.value);
    formData.append('startMinute', this.cF.startMinute.value);
    formData.append('endHour', this.cF.endHour.value);
    formData.append('endMinute', this.cF.endMinute.value);
    formData.append('holidayMonth', this.cF.holidayMonth.value);
    formData.append('holidayDate', this.cF.holidayDate.value);
    formData.append('weekDay', this.cF.weekDay.value);
    formData.append('cmdformData',this.cmdformData);
    formData.append('configurationOn', '0');
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configHappyHours', formData).subscribe((result) => {
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }

  onSubmitConfigPendingMeter() {
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);
    //formData.append('configSubDivisionId', this.meterDetail.sdID);
    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }

    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
    formData.append('startHour', this.cF.startHour.value);
    formData.append('startMinute', this.cF.startMinute.value);
    formData.append('endHour', this.cF.endHour.value);
    formData.append('endMinute', this.cF.endMinute.value);
    formData.append('holidayMonth', this.cF.holidayMonth.value);
    formData.append('holidayDate', this.cF.holidayDate.value);
    formData.append('weekDay', this.cF.weekDay.value);
    formData.append('cmdformData',this.cmdformData);
    formData.append('configurationOn', '1');
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configHappyHours', formData).subscribe((result) => {
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }


}
