<div class="container-fluid innerpage_top innerpage_top_graphic">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH">
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="text hidden-sm xs-dn col-md-8">
                    <a href="#/deshauth" mat-icon>
                     <span>Home</span>
                    </a> >
                    <a href="javascript:void(0)" (click)="backToCountryView()">
                      <span>{{bCumCountry}}</span>
                    </a> >
                      <a href="javascript:void(0)"  (click)="backToUtilityView()">
                        <span>{{bCumState}}</span>
                      </a> >
                      <a href="javascript:void(0)">
                        <span>{{bCumUtlity}}</span>
                      </a>
                  </div>
              </div>
                <div class="col-md-7 col-sm-12">
                  <div class="flex_auth">
                    <div class="authsearch">
                      <div class="search">
                        <input class="search-txt searchplaceholder"  type="text" placeholder="Search by Username/Email/Mobile No"
                        value="{{levelSearch}}"  
                        (input)="onLevelSearchChange($event.target.value)" autocomplete="off"
                        autocomplete="off">
                        <div class="search-btn nobg">
                            <i class="fa fa-search"></i>
                        </div>
                          </div>
                      </div>
                      <div class="">
                        <span class="toolbar-space"></span>
                          <div>
                              <button  type="button" mat-mini-fab (click)="open(addcountry)" class="editbordr_circlttop">
                                <i class="fa fa-plus"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container-fluid inner-page-content">
<!--<div class="page-content-wrapper" style="margin-top:0;">
    <div class="page-content-wrapper-inner">
      <div class="content-viewport">
          <div class="row py-3 headerArea commonClass">
              <div class="col-12">
                <h2 class="hAtitle mt-0 mb-1 col-12">Utility : {{bCumUtlity}}</h2>-->
                    <!--<ol class="breadcrumb">
                      <li class="breadcrumb-item" *ngIf="bCumCountry != ''" >
                        <a href="#"  class="btn-primary" mat-icon>
                          <fa name="home"></fa>
                        </a>
                      </li>
                      <li class="breadcrumb-item" *ngIf="bCumCountry != '' && isCountry()" >
                        <a href="javascript:void(0)"  class="btn-primary" (click)="backToCountryView()">
                          <span>{{bCumCountry}}</span>
                        </a>
                      </li>
                      <li class="breadcrumb-item" *ngIf="bCumState != '' && isState()">
                          <a href="javascript:void(0)"  (click)="backToUtilityView()" class="btn-primary">
                            <span>{{bCumState}}</span>
                          </a>
                      </li>
                      <li class="breadcrumb-item" *ngIf="bCumUtlity != '' && isUtility()">
                          <a href="javascript:void(0)" class="btn-primary">
                            <span>{{bCumUtlity}}</span>
                          </a>
                      </li>
                    </ol>-->
              <!--</div>
          </div>

          <div class="form-row">
            <div class="col-md-8 text-left">
                    <a href="#" mat-icon>
                      <i matprefix class="icons d-c3">
                        home
                    </i><span>Home</span>
                    </a>->
                    <a href="javascript:void(0)" (click)="backToCountryView()">
                      <i matprefix  class="icons d-c4">
                        flag
                    </i><span>{{bCumCountry}}</span>
                    </a>->
                    
                      <a href="javascript:void(0)"  (click)="backToUtilityView()">
                        <i matprefix  class="icons d-c5">
                          flag
                      </i><span>{{bCumState}}</span>
                      </a>->
                 
                      <a href="javascript:void(0)">
                        <i matprefix  class="icons d-c6">
                          flag
                      </i><span>{{bCumUtlity}}</span>
                      </a>
            </div>
            <div class="col-md-4 text-right">-->
              <!--<span matPrefix>
                <mat-icon matSuffix class="d-c9">search</mat-icon>
              </span>
              <input type="text" matInput placeholder="search" value="{{levelSearch}}"
                (input)="onLevelSearchChange($event.target.value)" autocomplete="off">-->
            
                <!--<button mat-icon-button color="primary" (click)="open(addcountry)">
                  <mat-icon>add</mat-icon>
                </button>
            </div>
    </div>
        <div class="row inner-page-content">-->
           
              <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys" >
                <fa name="spinner" animation="spin" size="4x"></fa>
              </ng-container>
              <div class="row t-3 mb-3"  *ngIf="allutilitys">
                <div class="col-md-2" *ngFor="let label of allutilitys; index as i;">
                  <div class="border_cstm"> 
                    <div>
                      <div class="spacer">
                        <div class="avatar-circlee">
                          <div class="circular">
                        <img src="{{label.img_address}}" *ngIf="label.img_address"/>
                        <fa *ngIf="!label.img_address"  name="folder" style="color:#84ccff;" mat-card-lg-image></fa>
                      </div>
                    </div>
                          <!--<mat-icon mat-button class="pointer cardAction" (click)="countryEditView(label, listmodify)">more_vert</mat-icon>-->
                          <mat-icon mat-button class="pointer cardAction d-c2 ditbordr" class="editbordr" [matMenuTriggerFor]="dcuListMenu" >menu_open</mat-icon>
                          <mat-menu #dcuListMenu="matMenu" xPosition="before" class="authorityListMenu 
                          editbordrr gridinnerpagee mat-menu-panellall">
                            <mat-list role="list" class="editbordrr">
                              <mat-list-item role="listitem">
                                <button type="button" mat-button (click)="countryEditView(label, listmodify)" class="btn-block text-left">
                                  <mat-icon class="d-c13 authall">edit</mat-icon> Modify
                                </button>
                              </mat-list-item>
                              <!--<mat-list-item role="listitem">
                                <button type="button" mat-button (click)="addAuthority(label)" class="btn-block text-left">
                                  <mat-icon matPrefix class="d-c14">supervised_user_circle</mat-icon> Add Authority
                                </button>
                              </mat-list-item>-->
                              <mat-list-item role="listitem">
                                <button type="button" mat-button (click)="removelevel(label)" class="btn-block text-left">
                                  <mat-icon class="d-c5">delete</mat-icon> Delete
                                </button>
                              </mat-list-item>
                            </mat-list>
                          </mat-menu>
                        </div>
                        <mat-card-footer (click)="goToCircleView(label.circle_id)">
                            <div style="WIDTH: 100%;" class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;" mat-button>
                                <div class="fullbutton">
                                    <span >{{label.circleName}}</span>
                                    <mat-icon class="icons">keyboard_arrow_right</mat-icon>
                                </div>
                              </div>
                        </mat-card-footer>
                    </div>
                  </div>
               </div>
           
            <ng-container *ngIf="!allutilitys" col-sm-3>
              {{messageUtilitys}}
            </ng-container>
        
          <!--<mat-card-footer>
              <mat-paginator #meterpaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                [length]="allLevelTotalRecord" (page)="changePage($event)" showFirstLastButtons>
              </mat-paginator>
              <mat-paginator [length]="length" [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)"
                  showFirstLastButtons>
              </mat-paginator>
          </mat-card-footer>-->
      
        </div>
      
        </div>
        </div>
        </div>
        </div>
          
      <!--</div>
      </div>
    </div>
    </div>-->
    <!-- content viewport ends -->
    <ng-template #addcountry let-modal>
      <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
          <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
          <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
            data-dismiss="modal">&times;</button>
            Add Circle
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
        <div class="form-groupp">
              <!-- Grid row -->
            <div class="row">
              <!-- Default input -->
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="Circle Code" name="Code" formControlName="Code" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && aF.Code.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Circle Code</label>
                    <div *ngIf="submitted && aF.Code.errors" class="invalid-feedback">
                      <div *ngIf="aF.Code.errors.required">
                        Circle Code is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="Circle Name" name="Name" formControlName="Name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && aF.Name.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Circle Name</label>
                    <div *ngIf="submitted && aF.Name.errors" class="invalid-feedback">
                      <div *ngIf="aF.Name.errors.required">
                        Circle Name is required
                       </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div> 
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input type="file" name="Image" (change)="onFileSelect($event)" />
                    <div *ngIf="submitted && aF.Image.errors" class="invalid-feedback">
                      <div *ngIf="aF.Image.errors.required">
                       Circle image is required
                      </div>
                    </div>
                  </span>
                </div>
            <!-- Grid row -->
              <div class="clearfix"></div>
                <div class="col-12 text-right">
                  <button type="submit" class="dilog dilog1"> <mat-icon matPrefix>add</mat-icon>
                    Add</button>
                  <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3"><mat-icon matPrefix>cancel</mat-icon>
                      Cancel</button>
                    </div>
                </div>
            </div>
          </form>
  </ng-template>
  
  <ng-template #listmodify let-modal>
      <form [formGroup]="editForm" (ngSubmit)="onSubmitEditForm()">
           <div class="modal-header subpage">
              <h4 class="modal-title" id="modal-basic-title">
                <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                  data-dismiss="modal">&times;</button>
                Edit Circle: {{EditName}}
              </h4>
            </div>
          <div class="modal-body">
              <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
              <div class="form-groupp">
                  <!-- Grid row -->
                  <div class="row">
                    <!-- Default input -->
                   <div class="col-md-6">
                      <div class="form-group input-group">
                        <span class="has-float-label">
                        <input  placeholder="Circle Code" name="Code" formControlName="Code" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && eF.Code.errors }" required>
                          <i class="fa fa-globe icon_innertxtbox"></i>
                            <label>Circle Code</label>
                            <div *ngIf="submitted && eF.Code.errors" class="invalid-feedback">
                              <div *ngIf="eF.Code.errors.required">
                                Circle Code is required
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group input-group">
                          <span class="has-float-label">
                          <input matInput placeholder="Circle Name" name="Name" formControlName="Name" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && eF.Name.errors }" required>
                            <i class="fa fa-globe icon_innertxtbox"></i>
                            <label>Circle Name</label>
                            <div *ngIf="submitted && eF.Name.errors" class="invalid-feedback">
                              <div *ngIf="eF.Name.errors.required">
                                Circle Name is required
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      </div>
                      <div class="form-group input-group">
                    <span class="has-float-label">
                        <input type="file" name="Image" (change)="onFileSelectEdit($event)" />
                          <div *ngIf="submitted && eF.Image.errors" class="invalid-feedback">
                          <div *ngIf="eF.Image.errors.required">
                            Circle image  is required
                          </div>
                        </div>
                      </span>
                    </div>
                 
                  <!-- Grid row -->
                  <div class="clearfix"></div>
                      <div class="col-12 text-right">
                        <button type="submit" class="dilog dilog1"><mat-icon matPrefix>update</mat-icon>Update</button>
                        <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                        <mat-icon matPrefix>cancel</mat-icon>cancel</button>
                  </div>
              </div>
          </div>
          </form>
  </ng-template>