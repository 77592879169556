import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-autheditprofile',
  templateUrl: './autheditprofile.component.html',
  styleUrls: ['./autheditprofile.component.scss']
})
export class AutheditprofileComponent implements OnInit {
  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  isLoading: boolean = false;
  thumb: boolean = false;
  authorityDOB = "0000-00-00";
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority: any;
  assignedData = {};
  //authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);

  authLevel_sel: any = '';
  date: any;
  constructor(private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;

    });
  }

  ngOnInit() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
    this.authority = this.currentUser.data.userData;
    this.addAuthorityForm = this.formBuilder.group({
      uid: [''],
      subdivId: [{ value: '', disabled: true }, Validators.required],

      authFullName: ['', Validators.required],
      authUserName: [{ value: '', disabled: true }, Validators.required],
      //authPassword: ['', Validators.required],
      authEmailId: [{ value: '', disabled: true }, Validators.required],
      //authLandlineNo: ['', Validators.required],
      authLandlineNo: ['', [Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],

      authMobileNo: [{ value: '', disabled: true }, Validators.required],
      authHomeAddressline1: ['', Validators.required],
      authHomeAddressline2: ['', Validators.required],
      authOfficeAddressline1: ['', Validators.required],
      authOfficeAddressline2: ['', Validators.required],
      // authorityActiveStatus: ['', Validators.required],
      authorityDOB: ['', Validators.required]


    });
    this.preSetUserData();
    //console.log("helloo",this.authority);
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getDOB(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.authorityDOB = eyear + '-' + emonth + '-' + eday;
    }
  }

  formateDate(datevalue) {
    let date = new Date(datevalue);
    //console.log("daevalue",datevalue," ",date," ",date.getMonth()+1," ",date.getDate()," ",date.getFullYear());
    return (date.getMonth() + 1) + '/' + (date.getDate()) + '/' + date.getFullYear();
  }

  preSetUserData() {
    this.aD.uid.setValue(this.authority.aID | 0);
    //this.aD.authPassword.setValue(this.authority.authPassword);
    //this.aD.authorityActiveStatus.setValue(this.authority.authActiveStatus);
    //this.aD.authorityDOB.setValue((this.authority.dob));
    //console.log("hello",this.authority.dob);
    this.aD.authorityDOB.setValue(this.formateDate(this.authority.dob));
    //this.date = new FormControl(new Date(this.authority.dob));
    //console.log("hello ",this.date);
    //this.date = this.authority.dob;
    this.aD.authEmailId.setValue(this.authority.email);
    this.aD.authFullName.setValue(this.authority.name);
    this.aD.authHomeAddressline1.setValue(this.authority.homeadd1);
    this.aD.authHomeAddressline2.setValue(this.authority.homeadd2);
    this.aD.authMobileNo.setValue(this.authority.mobile);
    this.aD.authLandlineNo.setValue(this.authority.lanlineNo);
    this.aD.authOfficeAddressline1.setValue(this.authority.officeadd1);
    this.aD.authOfficeAddressline2.setValue(this.authority.officeadd2);
    this.aD.authUserName.setValue(this.authority.username);
    this.aD.subdivId.setValue(this.authority.subDivName);

  }

  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAddAuthority() {
    if (this.addAuthorityForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.AddProcess = true;
    let body = new URLSearchParams();
    body.set('authId', this.authority.uid);
    body.set('subdivId', this.authority.subdivision);
    body.set('authFullName', this.aD.authFullName.value);
    body.set('authUserName', this.authority.username);
    body.set('authPassword', this.authority.auth);
    body.set('authEmailId', this.authority.email);
    body.set('authLandlineNo', this.aD.authLandlineNo.value);
    body.set('authMobileNo', this.authority.mobile);
    body.set('authHomeAddressline1', this.aD.authHomeAddressline1.value);
    body.set('authHomeAddressline2', this.aD.authHomeAddressline2.value);
    body.set('authOfficeAddressline1', this.aD.authOfficeAddressline1.value);
    body.set('authOfficeAddressline2', this.aD.authOfficeAddressline2.value);
    body.set('authorityActiveStatus', this.authority.activeStatus);
    //body.set('authorityDOB', this.authorityDOB);//this.aD.authPassword.value);
    body.set('authorityDOB', this.convert(this.aD.authorityDOB.value));

    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/updateAuthInfo', body).subscribe((result) => {


      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;

        this.addAuthMessage = "Profile Information updated successfully. You will be logged out within 3 seconds.";
        setTimeout(() => {
          this.authenticationService.logout();
        },
          9000);

        //this.modal.close('done');
      } else {
        this.thumb = false;
      }

    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.isLoading = false;
      this.addAuthMessage = 'Unable to process yours request!';
    });
  }
  /*getDOB(pickedDate = new Date()) {
    if (pickedDate) {
        let emonth = pickedDate.getMonth() + 1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();
        this.authorityDOB = eyear + '-' + emonth + '-' + eday;
    }
  }*/
}
