<!-- partial -->
    <div class="page-content-wrapper" style="margin-top:0;">
      <div class="page-content-wrapper-inner">
        <div class="content-viewport">
          <div class="row">
            <div class="col-12 py-5">
              <h4>Users</h4>
            </div>
          </div>
          <div class="row">
              <div class="col-lg-12">
                  <div class="grid">
                    <div class="item-wrapper">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>Detail 1</th>
                              <th>Detail 2</th>
                              <th>Detail 3</th>
                              <th>Detail 4</th>
                              <th>Detail 5</th>
                              <th>Detail 6</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="d-flex align-items-center border-top-0">
                                <img class="profile-img img-sm img-rounded mr-2" src="../../../assets/images/profile/male/image_5.png" alt="profile image">
                                <span>Random User 1</span>
                              </td>
                              <td>123</td>
                              <td>123</td>
                              <td>123</td>
                              <td class="text-success"> 123 
                              </td>
                              <td>123 </td>
                              <td>123 </td>
                              <td class="actions">
                                <i class="mdi mdi-dots-vertical"></i>
                              </td>
                            </tr>
                            <tr>
                              <td class="d-flex align-items-center">
                                <img class="profile-img img-sm img-rounded mr-2" src="../../../assets/images/profile/male/image_7.png" alt="profile image">
                                <span>Random User 2</span>
                              </td>
                              <td>123</td>
                              <td>123</td>
                              <td>123</td>
                              <td class="text-success"> 123 
                              </td>
                              <td>123 </td>
                              <td>123 </td>
                              <td class="actions">
                                <i class="mdi mdi-dots-vertical"></i>
                              </td>
                            </tr>
                            <tr>
                              <td class="d-flex align-items-center">
                                <img class="profile-img img-sm img-rounded mr-2" src="../../../assets/images/profile/female/image_10.png" alt="profile image">
                                <span>Random User 3</span>
                              </td>
                              <td>123</td>
                              <td>123</td>
                              <td>123</td>
                              <td class="text-success"> 123 
                              </td>
                              <td>123 </td>
                              <td>123 </td>
                              <td class="actions">
                                <i class="mdi mdi-dots-vertical"></i>
                              </td>
                            </tr>
                            <tr>
                              <td class="d-flex align-items-center">
                                <img class="profile-img img-sm img-rounded mr-2" src="../../../assets/images/profile/female/image_1.png" alt="profile image">
                                <span>Random User 4</span>
                              </td>
                              <td>123</td>
                              <td>123</td>
                              <td>123</td>
                              <td class="text-success"> 123 
                              </td>
                              <td>123 </td>
                              <td>123 </td>
                              <td class="actions">
                                <i class="mdi mdi-dots-vertical"></i>
                              </td>
                            </tr>
                            <tr>
                              <td class="d-flex align-items-center">
                                <img class="profile-img img-sm img-rounded mr-2" src="../../../assets/images/profile/male/image_3.png" alt="profile image">
                                <span>Random User 5</span>
                              </td>
                              <td>123</td>
                              <td>123</td>
                              <td>123</td>
                              <td class="text-success"> 123 
                              </td>
                              <td>123 </td>
                              <td>123 </td>
                              <td class="actions">
                                <i class="mdi mdi-dots-vertical"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
         
          </div>
        </div>
      </div>
      <!-- content viewport ends -->
      <!-- partial:partials/_footer.html -->
      <footer class="footer">
        <div class="row">
          <div class="col-sm-6 text-center text-sm-right order-sm-1">
            <ul class="text-gray">
              <li><a href="#">Terms of use</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-sm-6 text-center text-sm-left mt-3 mt-sm-0">
            <small class="text-muted d-block">Copyright © 2019 <a href="#" target="_blank">AVON SM</a>. All rights reserved</small>
          </div>
        </div>
      </footer>
      <!-- partial -->