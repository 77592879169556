<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Edit Authority
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Authority Type" name="authType" formControlName="authType"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authType.errors}"
                                required>
                                <mat-option value="I" *ngIf="this.currentUser.data.userData.authorityType=='I'">Authority</mat-option>
                                <mat-option value="X" *ngIf="this.currentUser.data.userData.authorityType=='I'">Zone</mat-option>
                                <mat-option value="S" *ngIf="this.currentUser.data.userData.authorityType=='I'">Circle</mat-option>
                                <mat-option value="D" *ngIf="this.currentUser.data.userData.authorityType=='I'">Division</mat-option>
                                <mat-option value="C" *ngIf="this.currentUser.data.userData.authorityType=='I'">Sub-Division</mat-option>
                                <mat-option value="Y" *ngIf="this.currentUser.data.userData.authorityType=='I'">JE</mat-option>
                                <mat-option value="N">Meter Installer</mat-option>
                            </mat-select>
                            <i class="fa fa-envelope icon_innertxtbox"></i>
                            <label>Authority Type</label>
                            <div *ngIf="submitted && aD.authType.errors" class="invalid-feedback">
                                <div *ngIf="aD.authType.errors.required">
                                    Authority Type is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Authority Full Name" name="authFullName" formControlName="authFullName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authFullName.errors}"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Authority Full Name</label>
                            <div *ngIf="submitted && aD.authFullName.errors" class="invalid-feedback">
                                <div *ngIf="aD.authFullName.errors.required">
                                    Auth Full Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="User Name" name="authUserName" formControlName="authUserName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authUserName.errors}"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>User Name</label>
                            <div *ngIf="submitted && aD.authUserName.errors" class="invalid-feedback">
                                <div *ngIf="aD.authUserName.errors.required">
                                    Auth User Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="password" placeholder="Authority Password" name="authPassword"
                                formControlName="authPassword" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authPassword.errors}" required>
                            <i class="fa fa-lock icon_innertxtbox"></i>
                            <label>Authority Password</label>
                            <div *ngIf="submitted && aD.authPassword.errors" class="invalid-feedback">
                                <div *ngIf="aD.authPassword.errors.required">
                                    Auth Password is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="email" placeholder="Authority Email Id" name="authEmailId"
                                formControlName="authEmailId"
                                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authEmailId.errors}" required>
                            <i class="fa fa-envelope icon_innertxtbox"></i>
                            <label>Authority Email Id</label>
                            <div *ngIf="submitted && aD.authEmailId.errors" class="invalid-feedback">
                                <div *ngIf="aD.authEmailId.errors.required">
                                    Auth Email Id is required
                                </div>
                                <div *ngIf="aD.authEmailId.hasError('email')">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Authority Mobile No" name="authMobileNo"
                                formControlName="authMobileNo" pattern="^[6-9]\d{9}$" minlength="10" maxlength="10"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authMobileNo.errors}"
                                required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Authority Mobile No</label>
                            <div *ngIf="submitted && aD.authMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.authMobileNo.errors.required">
                                    Auth Mobile No is required
                                </div>
                                <div *ngIf="aD.authMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                (selectionChange)="getZoneData()" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                    {{auth.text}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Zone</label>
                            <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                <div *ngIf="aD.zone.errors.required">
                                    Zone is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="circle" name="circle" formControlName="circle"
                                (selectionChange)="getCircleData()" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                    {{auth.text}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Circle</label>
                            <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                <div *ngIf="aD.circle.errors.required">
                                    Circle is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
               
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="division" name="division" formControlName="division"
                                (selectionChange)="getDivisionData()" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                    {{auth.text}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Division</label>
                            <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                <div *ngIf="aD.division.errors.required">
                                    Division is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Sub-division" name="sub_division"
                                formControlName="sub_division"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                    {{auth.text}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Sub-Division</label>
                            <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                <div *ngIf="aD.sub_division.errors.required">
                                    sub_division is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Avtive Status" name="authorityActiveStatus"
                                formControlName="authorityActiveStatus" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authorityActiveStatus.errors}" required>
                                <mat-option value="Y">Active</mat-option>
                                <mat-option value="N">In-Active</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Avtive Status</label>
                            <div *ngIf="submitted && aD.authorityActiveStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.authorityActiveStatus.errors.required">
                                    Authority Active Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authorityDOB.errors}" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Authority DOB</label>
                            <div *ngIf="submitted && aD.authorityDOB.errors" class="invalid-feedback">
                                <div *ngIf="aD.authorityDOB.errors.required">
                                    Authority DOB is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && message.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->