import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { Access } from 'src/app/_models/access';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-ipclconfig',
  templateUrl: './ipclconfig.component.html',
  styleUrls: ['./ipclconfig.component.scss']
})
export class IpclconfigComponent implements OnInit {
  public today = new Date();
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  meterDetail:any = {
    muID:0,
    mID: "All",
    dID:0,
    sdID:this.selectedSubDivision
  };
  formName1="1-PH Tariff (Commercial)";
  formName2="1-PH Tariff (Commercial)";
  getThrough: Access;
  defaultTab: string = 'commercial';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    private ngmodalService: NgbModal,
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    if(this.meterDetail.deductionType=='2'){
      this.defaultTab = 'commercial';
    }else{
      this.defaultTab = 'domestic';
    }
    //console.log(this.meterDetail);
    if(this.meterDetail.meterType=='1'){
      this.formName1="1-Ph Config. Tariff";
      this.formName2="1-Ph Config. Tariff";
    }
    if(this.meterDetail.meterType=='3'){
      this.formName1="3-Ph Config. Tariff";
      this.formName2="3-Ph Config. Tariff";
    }
  }

  ngAfterViewInit() {
    //console.log(this.meterDetail);
  }

  ngOnDestroy() {
   // this.ngmodalService.dismissAll();
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }
  get cF() { return this.addForm.controls; }
}
