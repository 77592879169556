 <div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
    data-dismiss="modal">&times;</button>
    Actions
  </h4>
</div>

<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <ng-container>
  <div class="col-sm-12 col-md-12 mx-auto form-wrapper">
    <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()" >
      <mat-radio-group name="servantmode" formControlName="servantmode">
        <div class="col-sm-12 row">
          <div class="col-sm-6 form-group input-rounded o_b" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Action_Load_Limiter_Access">
            <div class="borderchk">
              <div  class="o_b1">
                <i class="fa fa-database"></i>
                <B>Load Limiter</B>
              </div>
              <div class="meteractivitychkouter">
                <div class="meteractivitychk">
                  <mat-radio-button value="S_2">
                    On
                  </mat-radio-button>
                </div>
                <div class="meteractivitychk">
                  <mat-radio-button value="S_1">
                    Off
                  </mat-radio-button>
                </div>  
              </div>
            </div>
          </div>
          <div class="col-sm-6 form-group input-rounded o_b" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Action_Meter_Load_Access">
            <div class="borderchk">
              <div  class="o_b1">
                <i class="fa fa-database"></i>
                <B>Meter Load</B>
              </div>
              <div class="meteractivitychkouter">
                <div class="meteractivitychk">
                  <mat-radio-button value="L_2">On</mat-radio-button>
                </div>
                <div class="meteractivitychk">
                  <mat-radio-button value="L_1">Off</mat-radio-button>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </mat-radio-group>
        <div class="row">
          <div class="col-sm-12 text-left">
            <ng-container *ngIf="isLoading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
              <div role="alert" *ngIf="message.length > 0" >
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
              </div>
          </div>
          <div class="col-sm-12 text-right">
            <button type="submit" class="dilog dilog1">
              <mat-icon>update</mat-icon>
              Update
            </button>
            <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3">
              <mat-icon>cancel</mat-icon>
              Cancel
            </button>
          </div>
        </div>
    </form>
  </div>
  </ng-container>
</div>