import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/_services/modal.service';
import { UntypedFormBuilder } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { User } from 'src/app/_models';
declare const mytest: any;

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {
  mihpayid: any;
  key: any;
  txnid: any;
  currentUser: User;
  discount: any;
  offer: any;
  productinfo: any;
  firstname: any;
  lastname: any;
  email: any;
  phone: any;
  hash: any;
  error: any;
  bankcode: any;
  PG_TYPE: any;
  bank_ref_num: any;
  authToken: string = '';
  responseData: any;
  tranId: any;
  responseMessage: string = '';
  tranDetails: string = '';

  successReacharge = {
    uniqueId: "",
    flatId: "",
    meterId: "",
    fullName: "",
    adddress: "",
    reportingDate: "",

    mode: "",
    status: "",
    tranNo: "",
    amount: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    productInfo: "",
    dateTime: ""
  };

  uniqueId: any;
  flatId: any;
  meterId: any;
  fullName: any;
  adddress: any;
  reportingDate: any;

  mode: any;
  status: any;
  tranNo: any;
  amount: any;
  firstName: any;
  lastName: any;
  emailId: any;
  mobileNo: any;
  productInfo: any;
  dateTime: any;
  invalidMessage: any = '';
  reviewSubmitMessage = '';
  reviewSubmitStatus: boolean = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.authToken = params['authToken'];
    });
    this.getRechargeUserInfo();
  }

  oncliclick() {
    mytest();
  }

  //   paymentinfo(){
  //     const YEARS = 40
  // const PAPER_AMOUNT = 80
  // const COLORS = [
  //   '#ab47bc',
  //   '#5c6bc0',
  //   '#29b6f6',
  //   '#66bb6a',
  //   '#ffee58',
  //   '#ffa726',
  //   '#ef5350',
  // ]

  // function getOrdinalIndicator(num) {
  //   switch (num) {
  //     case 1:
  //     case 21:
  //       return 'st'
  //     case 2:
  //     case 22:
  //       return 'nd'
  //     case 3:
  //     case 23:
  //       return 'rd'
  //   }

  //   return 'th'
  // }

  // function generatePapers(container) {
  //   const maxPaperSize = container.offsetWidth / 40
  //   const minPaperSize = maxPaperSize / 2

  //   for (let i = 0; i < PAPER_AMOUNT; i++) {
  //     const p = document.createElement('div')

  //     p.style.position = 'absolute'
  //     p.style.top = `-${maxPaperSize}px`
  //     p.style.left = `${anime.random(-5, container.offsetWidth + 5)}px`
  //     p.style.width = `${anime.random(minPaperSize, maxPaperSize)}px`
  //     p.style.height = `${anime.random(minPaperSize, maxPaperSize)}px`
  //     p.style.backgroundColor = COLORS[i % COLORS.length]

  //     container.appendChild(p)
  //     animatePaper(p, container)
  //   }
  // }

  // function animatePaper(p, container) {
  //   anime({
  //     targets: p,
  //     delay: anime.random(0, 7000),
  //     duration: anime.random(5000, 7000),
  //     easing: 'linear',
  //     loop: true,
  //     translateX: `${anime.random(-20, 20)}px`,
  //     translateY: `${container.offsetHeight * 2 / 3}px`,
  //     skewX: `${anime.random(-45, 45)}deg`,
  //     skewY: `${anime.random(-45, 45)}deg`,
  //     rotate: `${anime.random(-1.5, 1.5)}turn`,
  //     opacity: [0.8, 0],
  //   })
  // }

  // var app = new Vue({
  //   el: '#popup',
  //   data: {
  //     yearCounter: 0,
  //     ord: getOrdinalIndicator(YEARS),
  //   },
  //   mounted() {
  //     anime.timeline({
  //       loop: false
  //     }).add([
  //       {
  //         targets: '#popup',
  //         scale: [0, 1],
  //         duration: 1500,
  //         delay: 500,
  //       },
  //       {
  //         targets: '#table',
  //         translateY: ['180px', 0],
  //         duration: 2000,
  //       },
  //       {
  //         targets: '#dish',
  //         translateY: ['200px', 0],
  //         duration: 2000,
  //         offset: '-=1950',
  //       },
  //       {
  //         targets: '#cake',
  //         translateY: ['200px', 0],
  //         duration: 2000,
  //         offset: '-=1950',
  //       },
  //       {
  //         targets: '#text h1',
  //         scale: [0, 1],
  //         opacity: [0, 1],
  //         duration: 2000,
  //         delay: (el, i) => i*100,
  //         offset: '-=500',
  //       },
  //       {
  //         targets: this,
  //         yearCounter: [0, YEARS],
  //         duration: 1000,
  //         round: 1,
  //         offset: '-=2000',
  //         easing: 'easeInOutQuart',
  //         begin: () => generatePapers(document.getElementById('popup')),
  //       },
  //       {
  //         targets: '#ord',
  //         scale: [0, 1],
  //         opacity: [0, 1],
  //         duration: 1000,
  //         offset: '-=500',
  //       },
  //     ])
  //   },
  // })

  //   }

  getRechargeUserInfo() {
    this.reviewSubmitMessage = '';
    this.reviewSubmitStatus = false;
    let body = new URLSearchParams();
    body.set('authToken', this.authToken);

    this.genReqs.postReq('/getOnlineRechargeInfo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.reviewSubmitMessage = this.responseData.message;
        this.reviewSubmitStatus = this.responseData.success;

        this.successReacharge = this.responseData.data;

      }
    }, (err) => {
      this.reviewSubmitMessage = 'Unable to process yours request!';
      this.reviewSubmitStatus = false;
    });
  }


  printRechargeInfo() {
    let printContents, popupWin;
    printContents = document.getElementById('recharge_review').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Recharge Info</title>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}