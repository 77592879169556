import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { User } from '../../_models';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/';
import { ConsumermodalService } from '../../_services/consumermodal.service';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Notify } from 'src/app/_models/notify';
import { LeftnavService } from '../../_services/leftnav.service';

@Component({
  selector: 'app-headnav',
  templateUrl: './headnav.component.html',
  styleUrls: ['./headnav.component.scss']
})
export class HeadnavComponent implements OnInit {
  currentUser: User;
  checkNotification = false;
  currentUserSubscription: Subscription;
  messageResult: any;
  notifyCount: Notify;
  notifyCountSubscription: Subscription;
  check = false;
  innerWidth: any;
  navbarCollapsed = true;
  className="nav-item dropdown fleximg";
  collapsed = true;
  private toggleButton: any;
  mobile_menu_visible: any = 0;
  private sidebarVisible: boolean=true;
  constructor(
    private router: Router,
    private conmodalService: ConsumermodalService,
    private ngModalService: NgbModal,
    public authenticationService: AuthenticationService,
    private leftnavService : LeftnavService,
    private element: ElementRef
  ) { 

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.authenticationService.notify.subscribe(x => this.notifyCount = x);
   // console.log("topdata", this.authenticationService.currentUser.subscribe(x => this.currentUser = x), "Data");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //console.log("------------",this.innerWidth);
    if(this.innerWidth<600){
      this.className="nav-item dropdown fleximgMedia";
    }else{
      this.className="nav-item dropdown fleximg";
    }
    console.log("------------",this.innerWidth,"------",this.className);
  }

  hello(){
    alert("hi");
  }
  

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
  
  ngOnInit() {
    
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    //console.log("on top bar", this.authenticationService.setLoginType);
    //this.getNotifyCount();
    if(this.currentUser){
      if( this.currentUser.data.userData.userType.toString().toLowerCase() == 'consumer'){
        this.consumerResetPassword(1);
      }
      if( this.currentUser.data.userData.userType.toString().toLowerCase() == 'authority'){
        this.authorityResetPassword(1);
      }
    }
  }

  ngAfterViewInit() {
    //console.log("topdata",this.currentUser , "Data");
    //if(this.currentUser.data.userData.availData=='1'){
      //this.checkNotification=true;
   // }
  }

  getNotifyCount() {
    //console.log("counter",this.notifyCount.count);
    return this.notifyCount.count;
  }

  //@HostListener('click', ['$event.target'])
  logout() {
   // alert("hello");
    console.log("logoutclick");
    this.authenticationService.logout();

    this.router.navigate(['/login']);
  }

toggleMenu () {
  this.leftnavService.toggle();
}


getProfileInfo() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityProfileView();
  } else {
    this.consumerProfileView();
  }
}

getProfileEdit() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityProfileEdit();
  } else {
    this.consumerProfileEdit();
  }
}

getNotifications() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityNotifications();
  } else {
    this.consumerNotifications();
  }
}

resetPassword() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityResetPassword(0);
  } else {
    this.consumerResetPassword(0);
  }
}

contactUs() {
  if (this.authenticationService.isCurrentUserAuthority) {
    this.authorityContactus();
  } else {
    this.consumerContactus();
  }
}

consumerProfileView() {
  //editConsumerProfile
  this.conmodalService.consumerProfileView().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

sidebarOpen() {
  var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function(){
        toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function() {
        $toggle.classList.add('toggled');
    }, 430);

    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');


    if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
    }else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
    }

    setTimeout(function() {
        $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function() { //asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');
      setTimeout(function() {
          $layer.remove();
          $toggle.classList.remove('toggled');
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
};
sidebarClose() {
  var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    // $('html').removeClass('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
        $layer.remove();
    }

    setTimeout(function() {
        $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
};

sidebarToggle() {
  //window.location.reload();
  if (this.sidebarVisible === false) {
      this.sidebarOpen();
  } else {
      this.sidebarClose();
  }
}

consumerProfileEdit() {
  //editConsumerProfile
  this.conmodalService.consumerProfileEdit().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

authorityProfileEdit() {
  //editConsumerProfile
  this.conmodalService.authorityProfileEdit().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}



authorityProfileView() {
  //editConsumerProfile
  this.conmodalService.authorityProfileView().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

consumerNotifications() {
  //editConsumerProfile
  this.conmodalService.consumerNotifications().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

authorityNotifications() {
  //editConsumerProfile
  this.conmodalService.authorityNotifications().pipe(
    take(1) // take() manages unsubscription for us
  ).subscribe(result => {
     //console.log({ messageResult: result });
      this.messageResult = result;
    });
}

  consumerResetPassword(set) {
    this.conmodalService.consumerResetPassword(set).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  authorityResetPassword(set) {
    this.conmodalService.authorityResetPassword(set).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }


  consumerContactus() {
    this.conmodalService.consumerContactus().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  authorityContactus() {
    this.conmodalService.authorityContactus().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  allTeriff() {
    //editConsumerProfile
    this.conmodalService.consumerTeriff().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }
  


}
