
<div class="container-fluid innerpage_top page-content-wrapper" cdkDropList>
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH">
        <div class="boxflexEll row">
            <div class="col-md-5">
                <h1>  Activity Report
                    <span class="info_icntop ml-1">
                    <i data-toggle="tooltip" data-placement="top" title=" Information of Consumer's Activity  Report" class="fa fa-info tabula-tooltip "></i>
                    </span>
                </h1>
            </div>
             <div class="col-md-7">
                <div class="flexsearchouter">
                    <div class="flexsearch">
                        <div class="dflex_rowouterw row">
                            <div class="dflex_roww">
                                <div class="frowbtn mt-2">
                                    <!-- <div class="form-group input-group mt-2">
                                        <span class="has-float-label">
                                            <input type="text" #autocompleteInput placeholder="Flat Id"
                                            [matAutocomplete]="auto" [formControl]="flatControl" class="form-control col-md-12">
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFlatInfo">
                                                <mat-option *ngFor="let option of filteredFlatInfo | async" [value]="option"
                                                    (onSelectionChange)="onSelectFlat($event, option.consId)">
                                                    {{option.flatNo}} - {{option.meterId}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <i class="fa fa-edit icon_innertxtbox"></i>
                                            <label>Select Flat Id</label>
                                        </span>
                                    </div>
                                    <div class="">
                                        <button class="ml-2 dilog dilog1" (click)="filterData('1')" [disabled]="!canSearch" style="width: auto;">Go</button>
                                    </div> -->
                                    <div class="">
                                      <div class="cstmdropdoen dropdown ml-2 exportanimated fadeInLeftt" *ngIf="myTemplate.length > 0">
                                          <h2 class="menu-title">Export Data</h2>
                                          <ul class="menu-dropdown-export">
                                              <li (click)="downloadAsPDF2()"><i class="fa fa-file-pdf-o iconcircle"></i>
                                                  <span>PDF</span>
                                              </li>
                                              <li (click)="csvNavigate()"><i class="fa fa-file-excel-o iconcircle" ></i>
                                                  <span>XLS</span> 
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
      <div class="container-fluid inner-page-content mt-3">
        <ng-container class="allutilitysloader col-sm-12" *ngIf="isFirstLoading && !isLoading">
          <div class="filter-report-options card_view_box text-center" style="background-color: #fff;color: #000;padding-bottom: 10px;margin-top: 0px;">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </div>
        </ng-container>
       

        <ng-container class="allutilitysloader col-sm-12" *ngIf="isLoading && myTemplate.length < 1">
          <div class="filter-report-options card_view_box text-center" style="background-color: #fff;color: #000;padding-bottom: 10px;margin-top: 0px;">
          <div class="col-12 plr_c25px xs-no-p"><div>
           <img class="center" src="assets/images/Nodata.png">
         </div></div>
          </div>
        </ng-container>
        
        <ng-container *ngIf="myTemplate.length > 0" id="contentToConvert">
          <div class="setReportHeight" >
            <div class="filter-report-options card_view_boxx">
                 <mat-card-content class="">
                <div class="iframe-container2">
                  <!--<h2>Recharge Report</h2>-->
                  <div class="" id="pdfTable" #pdfTable>
                    <table class="blueTable">
                      <tr>
                        <td>
                          <div [innerHtml]="safeHTML(myTemplate)">
                          </div>
                        </td>
                      </tr>
                    </table>
                    <!--<table class="fl-table">
                        <thead>
                            <tr>
                              <th *ngFor="let item of toshowIndexs">
                                {{item}}
                              </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let cons of htmlContet; let i = index" >
                          <td *ngFor="let item of toshowKeys">
                            {{cons[item]}}
                          </td>
                        </tr>
                          </tbody>
                    </table>-->
                    
                </div>
              </div>
              </mat-card-content>
            </div>
            </div>
        </ng-container>
      </div>
    </div>
     <ng-container *ngIf="myTemplate.length > 0">
          <mat-paginator 
          #meterpaginator 
          [pageSize]="reportlimit" 
          [pageSizeOptions]="getPageSizeOptions()" 
          [length]="reportTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons
         >
           </mat-paginator>
      </ng-container>
  </div>
</div>
<!-- content viewport ends -->