<div class="modal-contentradius">
    <div class="modal-header">
        <button type="button" (click)="modal.close('Cancel')" class="dilog4 close_popupbtn">
            <mat-icon matPrefix>close</mat-icon>
        </button>
    </div>
    <div class="modal-body" data-aos="fade-down">
        <form [formGroup]="loginFormAuth" (ngSubmit)="onSubmitAuth()" *ngIf="isAuthLoginFormShowing" autocomplete="off">
            <div class="modal_txt">
                <h1>LOGIN</h1>
            </div>
            <div class="modal_contentall container">
                <div class="row">
                    <div class="col-md-5 col-sm-12 mr-auto">
                        <a href="#" class="popuplogo">
                            <img src="assets/img/avon-logo-blue.png" alt="logo" />
                        </a>
                        <div class="topsub_msg shakeMsg whitee">
                            <h2>Please Login to your Account</h2>
                        </div>

                        <div class="form-group input-rounded input-icons">
                            <i class="fa fa-user icon"></i>
                            <input type="text" formControlName="username" placeholder="Username*" class="form-control"
                                [ngClass]="{ 'is-invalid': submittedAuth && af.username.errors }" autocomplete="off" />
                            <!-- <span class="fa fa-asterisk errspan"></span>-->
                            <div *ngIf="submittedAuth && af.username.errors && (af.username.dirty || af.username.touched)"
                                class="invalid-feedback shakeMsg">
                                <div *ngIf="af.username.errors.required">Username is required</div>
                            </div>
                        </div>

                        <div class="form-group input-rounded input-icons">
                            <i class="fa fa-lock icon"> </i>
                            <input matInput formControlName="password" autocomplete="off" placeholder="Password*"
                                class="form-control" [ngClass]="{ 'is-invalid': submittedAuth && af.password.errors }"
                                [type]="old ? 'password' : 'text'" />
                            <span class="p-viewer" (click)="old = !old">
                                <div class="form-group input-rounded input-icons">
                                    <i class="{{old ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;"
                                        aria-hidden="true"></i>
                                </div>
                            </span>
                            <div *ngIf="submittedAuth && af.password.errors" class="invalid-feedback shakeMsg">
                                <div *ngIf="af.password.errors.required">Password is required</div>
                            </div>
                        </div>

                        <div class="form-group input-rounded" [innerHTML]="invalidMessage"></div>

                        <div class="displyflexRem">
                            <div class="form-inline">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" formControlName="remberMyLogin"
                                            class="form-check-input" />Remember me <i class="input-frame"></i>
                                    </label>
                                </div>
                            </div>
                            <div class="form-inline">
                                <div class="nochkbox" (click)="enableForgotLogin()">
                                    <label> Forgot Password? <i class="input-frame"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loadingAuthLogin" type="submit"
                                class="btn btn-primary btnnsanta btn-block">
                                Login
                                <img *ngIf="loadingAuthLogin" class="pl-3"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>
                        </div>
                        <div class="form-group">
                            <!--<a (click)="enableOtpLogin()" *ngIf="isShowOtpForm && isAuthLoginFormShowing"
                                class="pointer-link">
                                <span class="opt_login">OTP LogIn</span>
                            </a>-->
                            <button [disabled]="loadingAuthLogin" type="button" (click)="enableOtpLogin()"
                                class="btn btn-primary btn-block otpbtn otpbtnsanta">
                                OTP Login
                                <!--<img *ngIf="loadingAuthLogin" class="pl-3"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />-->
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="right_imglogin pinksanta">
                            <!-- <img src="assets/img/crismisssanta.gif" class="img-fluid" alt="Login User"> -->

                            <img src="assets/img/login_img.png" class="img-fluid" alt="Login User" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form [formGroup]="mpinLoginForm" (ngSubmit)="onSubmitMpin()" *ngIf="isOtpLoginFormShowing" autocomplete="off">
            <div class="modal_txt">
                <h1 *ngIf="enableForgotString">{{headingOne}}</h1>
                <h1 *ngIf="!enableForgotString">{{headingTwo}}</h1>
            </div>
            <div class="modal_contentall container">
                <div class="row">
                    <div class="col-md-5 col-sm-12 mr-auto">
                        <a href="#" class="popuplogo">
                            <img src="assets/img/avon-logo-blue.png" alt="logo" />
                        </a>
                        <div class="topsub_msg">
                            <!--<h2>Forgotten your Password?</h2>
                            <p>Don't worry, we'll send you message to help you reset your password.-->

                            <h2 *ngIf="enableForgotString"> {{message1}}</h2>
                            <h2 *ngIf="!enableForgotString"> {{message3}}</h2>

                            <p *ngIf="enableForgotString">{{message2}}</p>
                            <p *ngIf="!enableForgotString">{{message4}}</p>
                        </div>
                        <div class="form-group input-rounded input-icons" *ngIf="isMpinForm">
                            <i class="fa fa-user icon"></i>
                            <input type="text" formControlName="mobileNo" autocomplete="off" placeholder="Username"
                                class="form-control" [ngClass]="{ 'is-invalid': submittedOtp && m.mobileNo.errors }" />
                            <div *ngIf="submittedOtp && m.mobileNo.errors" class="invalid-feedback">
                                <div *ngIf="m.mobileNo.errors.required">Username is required</div>
                            </div>
                            <div class="form-group input-rounded" *ngIf="isMpinForm" [innerHTML]="invalidMessage">
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loadingOtp" type="submit" class="btn btn-primary btn-block"
                                *ngIf="isMpinForm">
                                Generate OTP
                                <img *ngIf="loadingOtp" class="pl-3"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>
                        </div>
                        <!--<div class="form-group input-rounded" *ngIf="isMpinForm">
                        <button type="button" class="btn btn-primary btn-block"
                            (click)="enableLoginForm()">
                            Back to Login
                        </button>
                    </div>-->
                        <div class="form-group input-rounded text-left">
                            <!--<button type="button" (click)="enableOtpLogin()" class="btn-danger btn btn-block"
                        *ngIf="isShowOtpForm && isAuthLoginFormShowing">OTP login click here</button>-->
                            <a (click)="enableLoginForm()" *ngIf="isMpinForm" class="pointer-link">
                                <span class="opt_login">
                                    Return to Login
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="right_imglogin">
                            <img *ngIf="enableForgotString" src="assets/img/forgot-pssword.png" class="img-fluid"
                                alt="Login User" />
                            <img *ngIf="!enableForgotString" src="assets/img/otp_img.png" class="img-fluid"
                                alt="Login User" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form [formGroup]="otpValidateForm" (ngSubmit)="onSubmitOtpValidate()" *ngIf="isMpinFormValidate"
            autocomplete="off">
            <div class="modal_txt">
                <h1>OTP For Login</h1>
            </div>
            <div class="modal_contentall container">
                <div class="row">
                    <div class="col-md-5 col-sm-12 mr-auto">
                        <a href="#" class="popuplogo">
                            <img src="assets/img/avon-logo-blue.png" alt="logo" />
                        </a>
                        <div class="topsub_msg">
                            <h2>Please Enter OTP for Login</h2>
                        </div>
                        <div class="form-group input-rounded input-icons">
                            <i class="fa fa-lock icon"></i>
                            <input autocomplete="off" type="password" formControlName="otp" placeholder="OTP"
                                class="form-control" [ngClass]="{ 'is-invalid': submittedOtpLogin && t.otp.errors }" />
                            <div *ngIf="submittedOtpLogin && t.otp.errors" class="invalid-feedback">
                                <div *ngIf="t.otp.errors.required">OTP is required</div>
                            </div>
                            <div class="form-group input-rounded" [innerHTML]="invalidMessage" style="color: green;">
                            </div>
                        </div>

                        <div class="form-group input-rounded text-center" *ngIf="resendOtp && !resendOtpNew">
                            <div class="text-center">
                                <div class="countdown" *ngIf="!resendOtpButton && resendOtp" style="color: red">
                                    <countdown-timer [countDownTimerConfig]="testConfig"></countdown-timer>
                                </div>
                                <div class="form-group input-rounded text-right">
                                    <a (click)="reGenrateOtp()" *ngIf="resendOtpButton" class="pointer-link">
                                        <span class="opt_login">
                                            Resend OTP
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group input-rounded" *ngIf="!resendOtp && resendOtpNew">
                            <div class="text-center">
                                <div class="countdown" *ngIf="!resendOtpButton && resendOtpNew" style="color: red">
                                    <countdown-timer [countDownTimerConfig]="testConfig"></countdown-timer>
                                </div>
                                <div class="form-group input-rounded text-right">
                                    <a (click)="reGenrateOtpNew()" *ngIf="resendOtpButton" class="pointer-link">
                                        <span class="opt_login">
                                            Resend OTP
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group input-rounded">
                            <button [disabled]="loadingOtpLogin" type="submit" class="btn btn-primary btn-block"> Login
                                <img *ngIf="loadingOtpLogin" class="pl-3"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>
                        </div>
                        <div class="form-group  text-left">
                            <a (click)="enableMpinForm()" class="pointer-link">
                                <span class="opt_login">
                                    Back to Username
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="right_imglogin">
                            <img src="assets/img/opt-image.png" class="img-fluid" alt="Login User" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>