import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, timer,BehaviorSubject  } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mergeMap, switchMap, shareReplay  } from 'rxjs/operators';
import { generalRequestsService } from './generalRequests.service';

@Injectable({ providedIn: 'root' })
export class GetJobsService {
    private dataCache$!: Observable<any>;
    private refreshInterval = 10000; // Refresh data every minute
    private dataSubject = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpClient,
        private genReqs:generalRequestsService) {
    }
  
    fetchData(): Observable<any> {
        let body = new URLSearchParams();
        let accessAuth = localStorage.getItem('accessAuth');
        body.set('authToken', accessAuth);
        if (!this.dataCache$) {
          this.dataCache$ = timer(0, this.refreshInterval).pipe(
            switchMap(() => this.genReqs.postReq('/getJobs', body)), // Replace with your server endpoint
            shareReplay(1) // Cache the result and share it with multiple subscribers
          );
          this.dataCache$.subscribe(data => {
            this.dataSubject.next(data); // Update data subject when new data is fetched
          });
        }
        return this.dataSubject.asObservable();
    }
}