<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Add Meter Range
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Meter Type" name="meterType" formControlName="meterType"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.meterType.errors }"
                                required>
                                <mat-option value="1-Ph">1-Ph</mat-option>
                                <mat-option value="3-Ph">3-Ph</mat-option>
                                <mat-option value="LTCT">LTCT</mat-option>
                                <!--<mat-option value="NET">NET</mat-option>-->
                            </mat-select>
                            <i class="fa fa-file icon_innertxtbox"></i>
                            <label>Meter Type</label>
                            <div *ngIf="submitted && aF.meterType.errors" class="invalid-feedback">
                                <div *ngIf="aF.meterType.errors.required">
                                    Meter Type is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Meter No's with , separated" name="meterSerialNo" formControlName="meterSerialNo"
                                class="form-control">
                            <i class="fa fa-first-order icon_innertxtbox"></i>
                            <label>Meter No's</label>
                            <!--<div *ngIf="submitted && aF.meterSerialNo.errors" class="invalid-feedback">
                                <div *ngIf="aF.meterSerialNo.errors.required">
                                    From Range is required
                                </div>
                            </div>-->
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="From Range" name="fromRange" formControlName="fromRange"
                                class="form-control">
                            <i class="fa fa-arrows-h icon_innertxtbox"></i>
                            <label>From Range</label>
                            <!--<div *ngIf="submitted && aF.fromRange.errors" class="invalid-feedback">
                                <div *ngIf="aF.fromRange.errors.required">
                                    From Range is required
                                </div>
                            </div>-->
                        </span>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="To Range" name="toRange" formControlName="toRange" class="form-control">
                            <i class="fa fa-arrows-h icon_innertxtbox"></i>
                            <label>To Range</label>
                            <!--<div *ngIf="submitted && aF.toRange.errors" class="invalid-feedback">
                                <div *ngIf="aF.toRange.errors.required">
                                    To Range is required
                                </div>
                            </div>-->
                        </span>
                    </div>
                </div>
                <!--<mat-form-field class="col-md-6">
                    <input matInput placeholder="Specific Id" name="specificId" formControlName="specificId" >
                    <mat-icon matPrefix class="d-c8">
                      assessment
                    </mat-icon>
                    <mat-error *ngIf="aF.specificId.hasError('required')">
                        Specific Id is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>-->


            </div>

            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1" style="width: auto;">
                        <mat-icon>add</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3" style="width: auto;">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>