import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-exporttoexcelpnb',
  templateUrl: './exporttoexcelpnb.component.html',
  styleUrls: ['./exporttoexcelpnb.component.scss']
})
export class ExporttoexcelpnbComponent implements OnInit {

  public today = new Date();
  siteUrl = this.genReqs.apiPath();
  messageString="";
  startDate = '';
  endDate = '';
  monthSelection: any = '';
  yearSelection: any = '';
  startDate1 = '';
  endDate1 = '';
  faSpinner = faSpinner;
  //downloadForm: FormGroup;
  responseMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  meterDetail: any;
  assignedData = {};
  authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);
  isLoading: boolean = false;
  thumb: boolean = false;
  authLevel_sel: any = '';
  submitted: boolean = false;
  date: any;
  subdivisionId:any;
  Consumptionsview = '';
  constructor(
      private formBuilder: UntypedFormBuilder,
      private modalService: NgbModal,
      public modal: NgbActiveModal,
      private genReqs: generalRequestsService,
      private authenticationService: AuthenticationService,
      private confirmationDialogService: ConfirmationDialogService
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
  }


  ngOnInit() {
      /*this.downloadForm = this.formBuilder.group({
          startDate: ['', Validators.required],
      });*/
      this.Consumptionsview = '1';
      //console.log("Data",this.meterDetail);    
  }

  ngAfterViewInit() {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });

  }

  ngOnDestroy() {
      //this.modal.close();
  }

  clear() {
    //alert("hello");
    this.startDate ='';
    this.endDate ='';
    this.startDate1 ='';
    this.endDate1 ='';
}

  /*
  add authority
  require authority data
*/
  //get aD() { return this.downloadForm.controls; }
  getDateFromRange(pickedRange: Date[]) {
    this.startDate ='';
    this.endDate ='';
    this.startDate1 ='';
    this.endDate1 ='';
    let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
    let sday = pickedRange[0].getDate();
    let syear = pickedRange[0].getFullYear();
    this.startDate = syear + '-' + smonth + '-' + sday;
    this.startDate1 = sday + '/' + smonth + '/' + syear;
    let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
    let eday = pickedRange[1].getDate();
    let eyear = pickedRange[1].getFullYear();
    this.endDate = eyear + '-' + emonth + '-' + eday;
    this.endDate1 = eday + '/' + emonth + '/' + eyear;
}

getMonthFromRange(pickedDate = new Date()) {
  this.startDate ='';
    this.endDate ='';
    this.startDate1 ='';
    this.endDate1 ='';
  if (pickedDate) {
      this.monthSelection = pickedDate;
      let smonth = pickedDate.getMonth() + 1; //always move 1 value up
      let sday = '01';//pickedDate.getDate();
      let syear = pickedDate.getFullYear();
      this.startDate = syear + '-' + smonth + '-' + sday;
      this.startDate1 = sday + '-' + smonth + '-' + syear;
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = '01';//pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.endDate = eyear + '-' + emonth + '-' + eday;
      this.endDate1 = eday + '/' + emonth + '/' + eyear;
  }
}
getMonthEndFromRange(pickedDate = new Date()) {

  if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = '01';//pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.endDate = eyear + '-' + emonth + '-' + eday;
      this.endDate1 = eday + '/' + emonth + '/' + eyear;
  }
}

getYearFromRange(pickedDate = new Date()) {
  this.startDate ='';
    this.endDate ='';
    this.startDate1 ='';
    this.endDate1 ='';
  if (pickedDate) {
      let smonth = '01'; //always move 1 value up
      let sday = '01';//pickedDate.getDate();
      let syear = pickedDate.getFullYear();
      this.startDate = syear + '-' + smonth + '-' + sday;
      this.startDate1 = sday + '-' + smonth + '-' + syear;
      let emonth = '12';
      let eday = '01';//pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.endDate = eyear + '-' + emonth + '-' + eday;
      this.endDate1 = eday + '/' + emonth + '/' + eyear;
      this.yearSelection = pickedDate;
  }
}

getYearEndFromRange(pickedDate = new Date()) {
  if (pickedDate) {
      let emonth = '01';
      let eday = '01';//pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.endDate = eyear + '-' + emonth + '-' + eday;
      this.endDate1 = eday + '/' + emonth + '/' + eyear;
  }
}

  /*downloadData() {
    
      if (this.downloadForm.invalid) {
          this.submitted = true;
          return;
      }
      this.messageString="Do you really want to download data from "+this.startDate1.toString()+" to "+this.endDate1.toString()+" ?";
      this.confirmationDialogService.confirm('Please confirm..',  this.messageString)
      .then((confirmed) => {
          if(confirmed) {
              this.isLoading = true;
              this.AddProcess = true;
              let body = new URLSearchParams();
              body.set('reportType', this.meterDetail);
              body.set('sdate', this.startDate.toString());
              body.set('edate', this.endDate.toString());
              body.set('authToken', this.currentUser.authToken);
              this.genReqs.postReq('/downloadData', body).subscribe((result) => {
                  this.responseData = result;
                  this.AddProcess = false;
                  this.isLoading = false;
                  if (this.responseData.success) {
                      this.thumb = true;
                      this.responseMessage = this.responseData.message;
                      //window.open("http://localhost:4200/#/oldNewMeter","_self");
                      setTimeout(() => 
                      {
                      this.modal.close('done');
                      },
                      2000);
                  } else {
                      this.thumb = false;
                  }
              }, (err) => {
                  if (err.status === 401) {
                      this.authenticationService.logout();
                  }
                  this.responseMessage = 'Unable to process yours request!';
              });
          }
      })
      .catch(() => console.log('no response or closed'));
  }*/
      
  downloadAsPDF2() {
    /*if (this.downloadForm.invalid) {
      this.submitted = true;
      return;
    }*/
  
  //console.log("hello",this.meterDetail); 
  this.messageString="Do you really want to download data from "+this.startDate1.toString()+" to "+this.endDate1.toString()+" ?";
  this.confirmationDialogService.confirm('Please confirm..',  this.messageString)
  .then((confirmed) => {
      if(confirmed) {
        this.subdivisionId=this.currentUser.data.userData.subdivision;
        let pdfUrl = this.siteUrl+'/reports/downloadDataPnb?subdivisionId='+this.subdivisionId
        +'&meterSubDivision='+ this.meterDetail[0]
        +'&defaultZone='+ this.meterDetail[1]
        +'&defaultCircle='+ this.meterDetail[2]
        +'&defaultDivision='+ this.meterDetail[3]
        +'&defaultSubDivision='+ this.meterDetail[4]
        +'&uploadingStatus='+ this.meterDetail[5]
        +'&meterType='+  this.meterDetail[6]
        +'&companyName='+  this.meterDetail[7]
        +'&authType='+ this.meterDetail[8]
        +'&offSet='+  this.meterDetail[9]
        +'&limit='+  this.meterDetail[10]
        +'&filter='+  this.meterDetail[11]
        +'&zoneId='+ this.meterDetail[12]
        +'&circleId='+ this.meterDetail[13]
        +'&divisionId='+ this.meterDetail[14]
        +'&subDivisionId='+ this.meterDetail[15]
        +'&report='+ 'OND'
        +'&sdate='+this.startDate.toString()
        +'&edate='+this.endDate.toString()
        +'&reportType='+this.Consumptionsview.toString()
        +'&authToken='+this.currentUser.authToken
        +'&pdf=1';
        window.open(pdfUrl);
      }
    })
    .catch(() => console.log('no response or closed'));
}

csvNavigate() {
  /*if (this.downloadForm.invalid) {
    this.submitted = true;
    return;
  }*/
  this.messageString="Do you really want to download data from "+this.startDate1.toString()+" to "+this.endDate1.toString()+" ?";
  this.confirmationDialogService.confirm('Please confirm..',  this.messageString)
  .then((confirmed) => {
    if(confirmed) {
      this.subdivisionId=this.currentUser.data.userData.subdivision;
      let pdfUrl = this.siteUrl+'/reports/downloadDataPnb?subdivisionId='+this.subdivisionId
      +'&meterSubDivision='+ this.meterDetail[0]
      +'&defaultZone='+ this.meterDetail[1]
      +'&defaultCircle='+ this.meterDetail[2]
      +'&defaultDivision='+ this.meterDetail[3]
      +'&defaultSubDivision='+ this.meterDetail[4]
      +'&uploadingStatus='+ this.meterDetail[5]
      +'&meterType='+  this.meterDetail[6]
      +'&companyName='+  this.meterDetail[7]
      +'&authType='+ this.meterDetail[8]
      +'&offSet='+  this.meterDetail[9]
      +'&limit='+  this.meterDetail[10]
      +'&filter='+  this.meterDetail[11]
      +'&zoneId='+ this.meterDetail[12]
      +'&circleId='+ this.meterDetail[13]
      +'&divisionId='+ this.meterDetail[14]
      +'&subDivisionId='+ this.meterDetail[15]
      +'&report='+ this.meterDetail.toString()
      +'&sdate='+this.startDate.toString()
      +'&edate='+this.endDate.toString()
      +'&reportType='+this.Consumptionsview.toString()
      +'&authToken='+this.currentUser.authToken
      +'&pdf=2';
      window.open(pdfUrl);
    }
  })
  .catch(() => console.log('no response or closed'));

}


  //default DCU

}
