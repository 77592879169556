import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { AuthenticationService, generalRequestsService } from './_services';
import { User } from './_models';
import { Access } from './_models/access';
import { UserIdleService } from 'angular-user-idle';
import { CountdownTimerService } from 'ngx-timer';
import { Subscription } from 'rxjs';
import { ModalService } from './_services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { faHandPointLeft, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import * as AOS from 'aos';
import { LeftnavService } from './_services/leftnav.service';
import { NavigationStart, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
// import { GuidedTour, Orientation } from 'ngx-guided-tour/lib/guided-tour.constants';
// import { GuidedTourService }  from 'ngx-guided-tour/lib/guided-tour.service';
import { TooltipPosition } from '@angular/material/tooltip';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  myclose = false;
  title = 'AvonwebApp';
  isSideNaveOpen = true;
  contentMargin = 0;
  currentUser: User;
  status: boolean = false;
  getThrough: Access;
  accessTokenVal: any;
  faHandPointLeft = faHandPointLeft;
  faHandPointRight = faHandPointRight;
  // title = 'ngx-guided-tour-demo';

  //setting for temper data
  intervalId: number;
  responseTemper: any;
  pageWidthValue: any = 'main-panel';
  dataTemper: any = [];
  reset: boolean = false;
  showSideNav = false;
  subscription: Subscription;

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[0]);

  // @HostListener('document:wheel', ['$event.target'])
  // public onWheel(targetElement) {
  //   AOS.refresh();
  // }

  @HostListener("window:beforeunload", ["$event.target"]) unloadHandler(event: Event) {
    // console.log("Processing beforeunload...", this.myValue);
    console.log("before---------");
    AOS.refresh();
  }


  constructor(
    public authenticationService: AuthenticationService,
    private userIdle: UserIdleService,
    private genReqs: generalRequestsService,
    public CountdownTimerService: CountdownTimerService,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private leftnavService: LeftnavService,
    // private guidedTourService: GuidedTourService,
    private router: Router
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.subscription = this.leftnavService.get().subscribe(sideNav => {
      $('body').removeClass('sidebar-mini');
      this.isSideNaveOpen = sideNav;
      if (this.isSideNaveOpen) {
        //this.contentMargin = 210;
      } else {
        $('body').addClass('sidebar-mini');
        //this.contentMargin = 72;
      }
    });
  }
  ngOnInit() {
    localStorage.removeItem('firsttime');
    this.userIdle.onTimeout().subscribe(() => {
      if (this.authenticationService.isCurrentUserLoggedIn && this.currentUser) {
        this.logout();
      }
    });

    if (this.authenticationService.isCurrentUserLoggedIn) {
      this.pageWidthValue = 'main-panel';
    } else {
      this.pageWidthValue = 'main-panel-full';
    }

    //this.onResizeInit(window.innerWidth);
    if (window.innerWidth <= 550) {
      this.contentMargin = 0;
      // location.reload();
    }
    // console.log("inner width",window.innerWidth);
  }

  openTemperReport() {
    if (this.authenticationService.isCurrentUserAuthority && this.currentUser && this.currentUser.authToken.length > 0 && !this.reset) {
      let body = new URLSearchParams();
      body.set('subdivId', '1');
      body.set('dcuId', '0');
      body.set('offSet', '');
      body.set('limit', '');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getTamperData', body).subscribe((result) => {
        this.responseTemper = result;
        if (this.responseTemper.success && !this.reset) {
          //if(this.responseTemper.data.length > 1) {
          this.reset = true;
          this.ngModalService.dismissAll();
          this.dataTemper = this.responseTemper.data;
          this.modalService.openTemperReport(this.dataTemper).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            this.reset = false;
          });
        }
      }, (err) => {
        //console.log('no error require');
      });
    }
  }



  ngOnDestroy() {
    this.logout();
    alert("Do you really want to close?");
    this.subscription.unsubscribe();
  }

  toggleSidenav() {
    this.isSideNaveOpen = !this.isSideNaveOpen;
    if (this.isSideNaveOpen) {
      //this.contentMargin = 240;
      //console.log("---- in toggle", this.contentMargin);
    } else {
      //this.contentMargin = 72;
      //console.log("---- in toggle", this.contentMargin);
    }
    //alert("helloone"+this.contentMargin);
  }

  /* @HostListener('window:resize', ['$event'])
   onResize(event) {
     //alert("hello");
     if (event.target.innerWidth <= 550) {
       this.contentMargin = 0;
       //console.log("---- in resize1",this.contentMargin);
     } else {
       if (event.target.innerWidth < 980 && this.isSideNaveOpen) {
         this.toggleSidenav();
       }
     }
   }
   onResizeInit(innerWidth) {
     if (innerWidth <= 550) {
       this.contentMargin = 0;
       //console.log("---- in resize2",this.contentMargin);
     } else {
       if (innerWidth < 980 && this.isSideNaveOpen) {
         this.toggleSidenav();
       }
     }
   }*/

  logout() {
    this.authenticationService.logout();
  }

  clickEvent() {
    this.status = true;//this.status?false:true; 
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  restart() {
    this.userIdle.resetTimer();
  }
}