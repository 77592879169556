<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
            data-dismiss="modal">&times;</button>
            Add Tariff
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-4"> 
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Select Tariff Group..." name="teriffGroup" formControlName="teriffGroup"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.teriffGroup.errors}" required>
                                <mat-option *ngFor="let group of groupData" [value]="group.groupId">
                                    {{group.groupName}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-users icon_innertxtbox"></i>
                            <label> DG Name</label>
                            <div *ngIf="submitted && aF.teriffGroup.errors" class="invalid-feedback">
                                <div *ngIf="aF.teriffGroup.errors.required">
                                Tariff Group Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Alarm Level" name="alarmLevel" formControlName="alarmLevel" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.alarmLevel.errors}" required>
                            <i class="fa fa-alarm-clock icon_innertxtbox"></i>
                            <label> Alarm Level</label>
                            <div *ngIf="submitted && aF.alarmLevel.errors" class="invalid-feedback">
                                <div *ngIf="aF.alarmLevel.errors.required">
                                    Alarm Level is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>   
               <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Emergency Credit" name="emergencyCredit" formControlName="emergencyCredit" 
                             class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.alarmLevel.errors}" required>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label> Emergency Credit</label>
                            <div *ngIf="submitted && aF.emergencyCredit.errors" class="invalid-feedback">
                                <div *ngIf="aF.emergencyCredit.errors.required">
                                    Emergency Credit is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                        <mat-select placeholder="Fixed Charge Deduction Type" name="deductionType" formControlName="deductionType" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.deductionType.errors}" required>
                            <mat-option *ngFor="let td of dedType" [value]="td.id">
                                {{td.Name}}
                            </mat-option>
                        </mat-select>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Fixed Charge Deduction Type</label>
                        <div *ngIf="submitted && aF.deductionType.errors" class="invalid-feedback">
                            <div *ngIf="aF.deductionType.errors.required">
                                Monthly Deduction is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input placeholder="Grid Unit Rate" name="eBFlatUnitRate" formControlName="eBFlatUnitRate" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.eBFlatUnitRate.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Grid Unit Rate</label>
                        <div *ngIf="submitted && aF.eBFlatUnitRate.errors" class="invalid-feedback">
                            <div *ngIf="aF.eBFlatUnitRate.errors.required">
                                    Grid Unit Rate is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input  placeholder="DG Unit Rate" name="dGUnitRate" formControlName="dGUnitRate" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dGUnitRate.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate</label>
                        <div *ngIf="submitted && aF.dGUnitRate.errors" class="invalid-feedback">
                            <div *ngIf="aF.dGUnitRate.errors.required">
                                    DG Unit Rate is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="Fixed Charge Grid" name="fixedChargeGrid" formControlName="fixedChargeGrid"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.fixedChargeGrid.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Fixed Charge Grid</label>
                        <div *ngIf="submitted && aF.fixedChargeGrid.errors" class="invalid-feedback">
                            <div *ngIf="aF.fixedChargeGrid.errors.required">
                                    Fixed Charge Grid is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input placeholder="Fixed Charge DG" name="fixedChargeDG" formControlName="fixedChargeDG"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.fixedChargeDG.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Fixed Charge DG</label>
                        <div *ngIf="submitted && aF.fixedChargeDG.errors" class="invalid-feedback">
                            <div *ngIf="aF.fixedChargeDG.errors.required">
                                 Fixed Charge DG is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input  placeholder="Vending Charge" name="vendingCharge" formControlName="vendingCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.vendingCharge.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Vending Charge</label>
                        <div *ngIf="submitted && aF.vendingCharge.errors" class="invalid-feedback">
                            <div *ngIf="aF.vendingCharge.errors.required">
                                 Vending Charge is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input placeholder="Common Area Maint. Charge" name="commonAreaMaintCharge" formControlName="commonAreaMaintCharge" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.commonAreaMaintCharge.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Common Area Maint. Charge</label>
                        <div *ngIf="submitted && aF.commonAreaMaintCharge.errors" class="invalid-feedback">
                            <div *ngIf="aF.commonAreaMaintCharge.errors.required">
                                Common Area Maint Charge is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input  placeholder="Grid Maint. Charge" name="gridMaintCharge" formControlName="gridMaintCharge"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gridMaintCharge.errors}" required>
                    <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>Grid Maint Charge</label>
                        <div *ngIf="submitted && aF.gridMaintCharge.errors" class="invalid-feedback">
                            <div *ngIf="aF.gridMaintCharge.errors.required">
                                Grid Maint Charge is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                    <input placeholder="DG Maint. Charge" name="dgMaintCharge" formControlName="dgMaintCharge"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgMaintCharge.errors}" required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Maint Charge</label>
                        <div *ngIf="submitted && aF.dgMaintCharge.errors" class="invalid-feedback">
                            <div *ngIf="aF.dgMaintCharge.errors.required">
                                 DG Maint Charge is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input matInput placeholder="Maintenance Charges" name="clubCharge" formControlName="clubCharge"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.clubCharge.errors}" required>
                            <i class="fa fa-inr icon_innertxtbox"></i>
                                <label>Maintenance Charges</label>
                                <div *ngIf="submitted && aF.clubCharge.errors" class="invalid-feedback">
                                    <div *ngIf="aF.clubCharge.errors.required">
                                        Maintenance Charges is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                            <input  placeholder="Water Charges" name="waterCharge" formControlName="waterCharge"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.waterCharge.errors}" required>
                                <i class="fa fa-inr icon_innertxtbox"></i>
                                <label>Water Charges</label>
                                <div *ngIf="submitted && aF.waterCharge.errors" class="invalid-feedback">
                                    <div *ngIf="aF.waterCharge.errors.required">
                                        Water Charges is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <input  placeholder="Other Charges" name="otherCharge" formControlName="otherCharge"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.otherCharge.errors}" required>
                                    <i class="fa fa-inr icon_innertxtbox"></i>
                                    <label>Other Charges</label>
                                    <div *ngIf="submitted && aF.otherCharge.errors" class="invalid-feedback">
                                        <div *ngIf="aF.otherCharge.errors.required">
                                            Other Charges is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                <input placeholder="Service Tax" name="serviceTax" formControlName="serviceTax"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.serviceTax.errors}" required>
                                    <i class="fa fa-inr icon_innertxtbox"></i>
                                    <label>Service Tax</label>
                                    <div *ngIf="submitted && aF.serviceTax.errors" class="invalid-feedback">
                                        <div *ngIf="aF.serviceTax.errors.required">
                                            Service Tax is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                <mat-select placeholder="Active Status" name="teriffActiveStatus" formControlName="teriffActiveStatus"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.teriffActiveStatus.errors}" required>
                                    <mat-option *ngFor="let td of activeStatus" [value]="td.id">
                                        {{td.Name}}
                                    </mat-option>
                                </mat-select>
                                    <i class="fa fa-inr icon_innertxtbox"></i>
                                    <label>Active Status</label>
                                    <div *ngIf="submitted && aF.teriffActiveStatus.errors" class="invalid-feedback">
                                        <div *ngIf="aF.teriffActiveStatus.errors.required">
                                            Active Status is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

               
        </div>
         <div class="row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>

                <!--<mat-form-field class="col-md-6">
                    <input matInput placeholder="Energy Rate kWH EB" name="energyRatekWHEB" formControlName="energyRatekWHEB" >
                    <mat-icon matPrefix class="d-c5">rate_review</mat-icon>
                    <mat-error *ngIf="aF.energyRatekWHEB.hasError('required')">
                        Energy Rate kWH EB is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6">
                    <input matInput placeholder="Permissible Eb kWH Rate" name="permissibleEbkWHRate" formControlName="permissibleEbkWHRate" >
                    <mat-icon matPrefix class="d-c6">rate_review</mat-icon>
                    <mat-error *ngIf="aF.permissibleEbkWHRate.hasError('required')">
                        Permissible Eb kWH Rate is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-md-6">
                      <input matInput placeholder="Electricity Duty Rate kWH EB" name="electDutyRatekWHEB" formControlName="electDutyRatekWHEB" >
                      <mat-icon matPrefix class="d-c7">rate_review</mat-icon>
                      <mat-error *ngIf="aF.electDutyRatekWHEB.hasError('required')">
                        Electricity Duty Rate kWH EB is <strong>required</strong>
                      </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-md-6">
                      <input matInput placeholder="Regulatery Surch Rate kWH EB" name="regulaSurchRatekWHEB" formControlName="regulaSurchRatekWHEB" >
                      <mat-icon matPrefix class="d-c8">rate_review</mat-icon>
                      <mat-error *ngIf="aF.regulaSurchRatekWHEB.hasError('required')">
                        Regulatery Surch Rate kWH EB is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6">
                        <input matInput placeholder="Total kWH EB Rate" name="totalkWHEBRate" formControlName="totalkWHEBRate" >
                        <mat-icon matPrefix class="d-c9">rate_review</mat-icon>
                        <mat-error *ngIf="aF.totalkWHEBRate.hasError('required')">
                            Total kWH EB Rate is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6">
                        <input matInput placeholder="Energy Rate kWH DG" name="energyRatekWHDG" formControlName="energyRatekWHDG" >
                        <mat-icon matPrefix class="d-c10">rate_review</mat-icon>
                        <mat-error *ngIf="aF.energyRatekWHDG.hasError('required')">
                            Energy Rate kWH DG is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field class="col-md-6">
                          <input matInput placeholder="GST kWH DG Rate" name="gSTkWHDGRate" formControlName="gSTkWHDGRate" >
                          <mat-icon matPrefix class="d-c11">rate_review</mat-icon>
                          <mat-error *ngIf="aF.gSTkWHDGRate.hasError('required')">
                            GST kWH DG Rate is <strong>required</strong>
                          </mat-error>
                      </mat-form-field>
                      <mat-form-field class="col-md-6">
                          <input matInput placeholder="Total DG kWH Rate" name="totalDGkWHRate" formControlName="totalDGkWHRate" >
                          <mat-icon matPrefix class="d-c12">rate_review</mat-icon>
                          <mat-error *ngIf="aF.totalDGkWHRate.hasError('required')">
                            Total DG kWH Rate is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input matInput placeholder="Fixed Rate kW EB" name="fixedRatekWEB" formControlName="fixedRatekWEB" >
                            <mat-icon matPrefix class="d-c13">rate_review</mat-icon>
                            <mat-error *ngIf="aF.fixedRatekWEB.hasError('required')">
                                Fixed Rate kW EB is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input matInput placeholder="Permissible Addition kW Fixed EB Rate" name="permissibleAdditionkWFixedEBRate" formControlName="permissibleAdditionkWFixedEBRate" >
                            <mat-icon matPrefix class="d-c14">rate_review</mat-icon>
                            <mat-error *ngIf="aF.permissibleAdditionkWFixedEBRate.hasError('required')">
                                Permissible Addition kW Fixed EB Rate is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-md-6">
                              <input matInput placeholder="Elect Duty On Fixed EB Rate" name="electDutyOnFixedEBRate" formControlName="electDutyOnFixedEBRate" >
                              <mat-icon matPrefix class="d-c15">rate_review</mat-icon>
                              <mat-error *ngIf="aF.electDutyOnFixedEBRate.hasError('required')">
                                Elect Duty On Fixed EB Rate is <strong>required</strong>
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-md-6">
                              <input matInput placeholder="Regulatery Surcharge On Fixed EB Rate" name="regulaSurchOnFixedEBRate" formControlName="regulaSurchOnFixedEBRate" >
                              <mat-icon matPrefix class="d-c2">rate_review</mat-icon>
                              <mat-error *ngIf="aF.regulaSurchOnFixedEBRate.hasError('required')">
                                Regulatery Surcharge On Fixed EB Rate is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-6">
                                <input matInput placeholder="Total Fixed Charge Kw EB Rate" name="totalFixedChargeKwEBRate" formControlName="totalFixedChargeKwEBRate" >
                                <mat-icon matPrefix class="d-c3">rate_review</mat-icon>
                                <mat-error *ngIf="aF.totalFixedChargeKwEBRate.hasError('required')">
                                    Total Fixed Charge Kw EB Rate is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-6">
                                <input matInput placeholder="MMC kW Fixed DG Rate" name="mMCkWFixedDGRate" formControlName="mMCkWFixedDGRate" >
                                <mat-icon matPrefix class="d-c4">rate_review</mat-icon>
                                <mat-error *ngIf="aF.mMCkWFixedDGRate.hasError('required')">
                                    MMC kW Fixed DG Rate is <strong>required</strong>
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field class="col-md-6">
                                  <input matInput placeholder="GST On Fixed DG Rate" name="gSTOnFixedDGRate" formControlName="gSTOnFixedDGRate" >
                                  <mat-icon matPrefix class="d-c5">rate_review</mat-icon>
                                  <mat-error *ngIf="aF.gSTOnFixedDGRate.hasError('required')">
                                    GST On Fixed DG Rate is <strong>required</strong>
                                  </mat-error>
                              </mat-form-field>
                              <mat-form-field class="col-md-6">
                                  <input matInput placeholder="Fixed Charges On DG Rate" name="fixedChargesOnDGRate" formControlName="fixedChargesOnDGRate" >
                                  <mat-icon matPrefix class="d-c6">rate_review</mat-icon>
                                  <mat-error *ngIf="aF.fixedChargesOnDGRate.hasError('required')">
                                    Fixed Charges On DG Rate is <strong>required</strong>
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input matInput placeholder="CAM Rate" name="cAMRate" formControlName="cAMRate" >
                                    <mat-icon matPrefix class="d-c7">rate_review</mat-icon>
                                    <mat-error *ngIf="aF.cAMRate.hasError('required')">
                                        CAM Rate is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                    <mat-form-field class="col-md-6">
                       <input matInput placeholder="Maint Rate" name="maintRate" formControlName="maintRate" >
                       <mat-icon matPrefix class="d-c8">rate_review</mat-icon>    
                       <mat-error *ngIf="aF.maintRate.hasError('required')">
                            Maint Rate is <strong>required</strong>
                           </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6">
                        <input matInput placeholder="Club Rate" name="clubRate" formControlName="clubRate" >
                        <mat-icon matPrefix class="d-c9">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.clubRate.hasError('required')">
                                Club Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Sewerage Rate" name="sewerageRate" formControlName="sewerageRate" >
                        <mat-icon matPrefix class="d-c10">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.sewerageRate.hasError('required')">
                                Sewerage Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Parking Rate" name="parkingRate" formControlName="parkingRate" >
                        <mat-icon matPrefix class="d-c11">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.parkingRate.hasError('required')">
                                Parking Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Water Rate" name="waterRate" formControlName="waterRate" >
                        <mat-icon matPrefix class="d-c12">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.waterRate.hasError('required')">
                                Water Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Property Tax Rate" name="propertyTaxRate" formControlName="propertyTaxRate" >
                        <mat-icon matPrefix class="d-c13">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.propertyTaxRate.hasError('required')">
                                Property Tax Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Insurance Rate" name="insuranceRate" formControlName="insuranceRate" >
                        <mat-icon matPrefix class="d-c14">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.insuranceRate.hasError('required')">
                                Insurance Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        
                        <input matInput placeholder="Other Charge Rate" name="otherChargeRate" formControlName="otherChargeRate" >
                        <mat-icon matPrefix class="d-c15">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.otherChargeRate.hasError('required')">
                                Other Charge Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="GST Charge Rate" name="gSTChargeRate" formControlName="gSTChargeRate" >
                        <mat-icon matPrefix class="d-c6">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.gSTChargeRate.hasError('required')">
                                GST Charge Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="First Slab Range" name="firstSlabRange" formControlName="firstSlabRange" >
                        <mat-icon matPrefix class="d-c7">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.firstSlabRange.hasError('required')">
                                First Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="First Slab Rate" name="firstSlabRate" formControlName="firstSlabRate" >
                        <mat-icon matPrefix class="d-c8">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.firstSlabRate.hasError('required')">
                                First Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Second Slab Range" name="secondSlabRange" formControlName="secondSlabRange" >
                        <mat-icon matPrefix class="d-c3">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.secondSlabRange.hasError('required')">
                                Second Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Second Slab Rate" name="secondSlabRate" formControlName="secondSlabRate" >
                        <mat-icon matPrefix class="d-c2">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.secondSlabRate.hasError('required')">
                                Second Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Third Slab Range" name="thirdSlabRange" formControlName="thirdSlabRange" >
                        <mat-icon matPrefix class="d-c6">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.thirdSlabRange.hasError('required')">
                                Third Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Third Slab Rate" name="thirdSlabRate" formControlName="thirdSlabRate" >
                        <mat-icon matPrefix class="d-c7">rate_review</mat-icon>   
                        <mat-error *ngIf="aF.thirdSlabRate.hasError('required')">
                                Third Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Forth Slab Range" name="forthSlabRange" formControlName="forthSlabRange" >
                        <mat-icon matPrefix class="d-c8">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.forthSlabRange.hasError('required')">
                                Forth Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Forth Slab Rate" name="forthSlabRate" formControlName="forthSlabRate" >
                        <mat-icon matPrefix class="d-c9">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.forthSlabRate.hasError('required')">
                                Forth Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Fifth Slab Range" name="fifthSlabRange" formControlName="fifthSlabRange" >
                        <mat-icon matPrefix class="d-c10">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.fifthSlabRange.hasError('required')">
                                Fifth Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Fifth Slab Rate" name="fifthSlabRate" formControlName="fifthSlabRate" >
                        <mat-icon matPrefix class="d-c11"> rate_review</mat-icon>    
                        <mat-error *ngIf="aF.fifthSlabRate.hasError('required')">
                                Fifth Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Sixth Slab Range" name="sixthSlabRange" formControlName="sixthSlabRange" >
                        <mat-icon matPrefix class="d-c12">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.sixthSlabRange.hasError('required')">
                                Sixth Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Sixth Slab Rate" name="sixthSlabRate" formControlName="sixthSlabRate" >
                        <mat-icon matPrefix class="d-c13">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.sixthSlabRate.hasError('required')">
                                Sixth Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Seventh Slab Range" name="seventhSlabRange" formControlName="seventhSlabRange" >
                        <mat-icon matPrefix class="d-c14">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.seventhSlabRange.hasError('required')">
                                Seventh Slab Range is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>

                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Seventh Slab Rate" name="seventhSlabRate" formControlName="seventhSlabRate" >
                        <mat-icon matPrefix class="d-c15">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.seventhSlabRate.hasError('required')">
                                Seventh Slab Rate is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>

                     <mat-form-field class="col-md-6">
                        <input matInput placeholder="Tariff Active Status" name="teriffActiveStatus" formControlName="teriffActiveStatus" >
                        <mat-icon matPrefix class="d-c2">rate_review</mat-icon>    
                        <mat-error *ngIf="aF.teriffActiveStatus.hasError('required')">
                                Tariff Active Status is <strong>required</strong>
                            </mat-error>
                     </mat-form-field>
            </div>-->
            <!-- Grid row
            <div class="clearfix"></div>
                <div class="col-12 text-right">
                    <button type="submit"  class="dilog-button dilog-button1"><mat-icon matPrefix>add</mat-icon>Add</button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog-button dilog-button1"><mat-icon matPrefix>cancel</mat-icon>Cancel</button>
                  
                </div> -->
        
    </div>
</div>
</form>