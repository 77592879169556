import { ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { first, window } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AlertService, AuthenticationService, generalRequestsService } from '../../_services/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit {
    filteredLoginSubDivision: Observable<any[]>;
    AutoCompleteControl = new UntypedFormControl('', Validators.required);
    responseData: any;
    addForm: UntypedFormGroup;
    submitted: boolean = false;
    subDevision = [{
        'sub_dev_id': 1,
        'sub_dev_name': 'Select subdevision',
        'dev_id': 1,
        'circle_id': 1,
        'utility_id': 1,
        'state_id': 1,
        'country_id': 1,
        'auth_id': 1,
        'prefix': 1,
        'tb': 'table'
        
    }];
    selectedSubDiv: number = 0;
    rowSubDivision: any = {};
    allowClose: boolean = false;
    constructor( private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        public modal: NgbActiveModal
    ) { }

    ngOnInit() {

          this.addForm = this.formBuilder.group({
            AutoCompleteControl: ['', Validators.required],
            filteredLoginSubDivision: ['', Validators.required],
          });

        this.filteredLoginSubDivision = this.AutoCompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => this.findLoginSubDivision(value))
        );
        this.getSubDomainLists();
    }

    // ngAfterViewInit() {
    //       this.addForm = this.formBuilder.group({
    //       });
        
    // }

    findLoginSubDivision(val: string) {
        return this.subDevision.filter(state =>
            state.sub_dev_name.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
    }

    getSubDomainLists() {
        this.genReqs.genReq('/getsubdevisions').then((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.subDevision = this.responseData.data;
                this.filteredLoginSubDivision = this.AutoCompleteControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this.findLoginSubDivision(value))
                );
            }
        }, (err) => { console.log(err); });
    }

    displayFn(user: any): string {
        return user ? user.sub_dev_name : undefined;
    }

    showSubDevisionData(event, subdivisionid: any, name: any, row: any) {
        if (event.source.selected) {
            this.selectedSubDiv = subdivisionid;
            this.rowSubDivision = row;
            this.allowClose = true;
            this.passSubDivision.emit(subdivisionid);
            this.SubDivisionRow.emit(row);
            this.modal.close(this.selectedSubDiv);
        }
    }

    @Output() passSubDivision: EventEmitter<any> = new EventEmitter();
    @Output() SubDivisionRow: EventEmitter<any> = new EventEmitter();

    get aF() { return this.addForm.controls; }
    
      onSubmitAddFormSub(){
      if (this.addForm.invalid) {
           this.submitted = true;
        return;
      }
      }

}
