import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';

@Component({
  selector: 'app-refundamount',
  templateUrl: './refundamount.component.html',
  styleUrls: ['./refundamount.component.scss']
})
export class RefundamountComponent implements OnInit {

  faSpinner = faSpinner;

  refundData: any = {};
  dgid:string = '0';
  dgName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  submitted: boolean = false;
  /*deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "EB"},
    {deviceType: "DG"}
  ];*/

  deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"}
  ];

  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';


  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      merchantAmount: [{value:'', disabled: true}, Validators.required],
      aggregatorAmount: [{value:'', disabled: true}, Validators.required],
      refundAmount: ['', Validators.required]
      });
     this.aF.merchantAmount.setValue(this.refundData.splitAmount);
     this.aF.aggregatorAmount.setValue(this.refundData.aggregatorCommission);
     this.aF.refundAmount.setValue(this.refundData.settelmentAmount);
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      this.isLoading = true;
      this.addAuthMessage ='';
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      let sum=this.aF.merchantAmount.value-this.aF.aggregatorAmount.value;
      //alert(sum);
      if(this.aF.refundAmount.value>this.aF.merchantAmount.value){
        this.isLoading = false;
        alert("Refund couldn't be processed as refund amount must be less than Merchant and Aggregator Amount");
        return;
      }
      if(this.aF.refundAmount.value==0){
        this.isLoading = false;
        alert("We can't refund 0 Amount!");
        return;
      }
      if((sum)!=this.aF.refundAmount.value){
        this.isLoading = false;
        alert("Refund couldn't be processed as Sum of Aggregator & Merchant amounts is not equal to refund amount");
        return;
      }
      let body = new URLSearchParams();
      body.set('payUTxnId', this.refundData.payUTxnId);
      body.set('payuPaymentId', this.refundData.childPayId);
      body.set('merchantId', this.refundData.merchant);
      body.set('merchantAmount', this.aF.merchantAmount.value);
      body.set('aggregatorAmount', this.aF.aggregatorAmount.value);
      body.set('refundAmount', this.aF.refundAmount.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/refundAmountApi', body).subscribe((result)=>{
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.addAuthMessage = this.responseData.data.message;
          if(this.addAuthMessage.includes("No Response")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else{
            this.thumb = false;
            this.addAuthMessage = 'Unable to process yours request!';
        }
      },(err)=>{
        this.isLoading = false;
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.addAuthMessage = 'Unable to process yours request!';
      });
    }
}
