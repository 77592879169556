<div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
    Recent Activity: {{meterDetail.mID}}
  </h4>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
  </ng-container>
  <ng-container>
    <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
      <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()">
        <mat-radio-group name="servantmode" formControlName="servantmode">
          <!--<div class="col-sm-12 form-group input-rounded o_b">
                          <div  class="o_b1">
                            <mat-icon class="d-c3">local_activity</mat-icon>
                           <B>Load Limiter</B>
                          </div>
                          
                            <div>
                              <mat-radio-button value="S_2">
                               On
                              </mat-radio-button>
                            </div>
                            <div>
                              <mat-radio-button value="S_1">
                                Off
                              </mat-radio-button>
                            </div>  
                           
                        </div>-->
          <div class="col-sm-12 row">
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_Meter_Load_On_Off_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>Meter Load</B>
                </div>
                <!--<mat-radio-group name="meterload" formControlName="meterload">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="L_2">On</mat-radio-button>
                  </div>
                  <div class="meteractivitychk">
                    <mat-radio-button value="L_1">Off</mat-radio-button>
                  </div>
                  <!--</mat-radio-group>-->
                </div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_DG_Supply_On_Off_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>DG Supply</B>
                </div>
                <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="D_2">On</mat-radio-button>
                  </div>
                  <div class="meteractivitychk">
                    <mat-radio-button value="D_1">Off</mat-radio-button>
                  </div>
                  <!--</mat-radio-group>-->
                </div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>Mode</B>
                </div>
                <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="P_2">Prepaid</mat-radio-button>
                  </div>
                  <div class="meteractivitychk">
                    <mat-radio-button value="P_1">Postpaid</mat-radio-button>
                  </div>
                  <!--</mat-radio-group>-->
                </div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_Meter_DG_Free_Units_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>DG Free Units</B>
                </div>
                <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="F_2">Active</mat-radio-button>
                  </div>
                  <div class="meteractivitychk">
                    <mat-radio-button value="F_1">In-Active</mat-radio-button>
                  </div>
                  <!--</mat-radio-group>-->
                </div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_Emergency_Credit_On_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>Emergency Credit</B>
                </div>
                <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="E_Y">On</mat-radio-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="getThrough.authority.Meter_Sub_Activity_On_Meter_MD_Reset_Button">
              <div class="borderchk">
                <div class="o_b1">
                  <i class="fa fa-database"></i>
                  <B>MD Reset</B>
                </div>
                <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                <div class="meteractivitychkouter">
                  <div class="meteractivitychk">
                    <mat-radio-button value="M_Y">Reset</mat-radio-button>
                  </div>
                </div>
                <!--</mat-radio-group>-->
              </div>
            </div>
          </div>
        </mat-radio-group>

        <!--
                          <div class="col-sm-12 form-group input-rounded o_b">
                            <mat-icon class="d-c6">chrome_reader_mode</mat-icon>
                              <mat-checkbox name="startemergencycredit" formControlName="startemergencycredit">Start Emergency Credit</mat-checkbox>
                            
                          </div>
                          
                          <div class="col-sm-12 form-group input-rounded o_b">
                            <mat-icon class="d-c7">chrome_reader_mode</mat-icon>
                              <mat-checkbox name="startfixedchargesonmeter" formControlName="startfixedchargesonmeter">Start Fixed Charges On Meter</mat-checkbox>
                            
                          </div>-->

        <div class="col-sm-12 row">
          <div class="col-sm-12 text-left">
            <ng-container *ngIf="isLoading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <!--<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="message.length > 0" >
                            <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon>{{message}}
                          </div>-->
            <div role="alert" *ngIf="message.length > 0">
              <div class="alert alert-success " role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
              </div>
              <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-right">
            <button type="submit" class="dilog dilog1">
              <mat-icon>add</mat-icon>
              Update
            </button>
            <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
              <mat-icon>cancel</mat-icon>
              Cancel
            </button>
          </div>
        </div>

        <!--<button [disabled]="AddProcess" type="submit" class="btn btn-primary btn-block" >
                              Action
                          </button>-->
      </form>

    </div>
  </ng-container>
</div>