import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { generalRequestsService, AuthenticationService, UserService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from '../../_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-activity-on-meter',
  templateUrl: './activity-on-meter.component.html',
  styleUrls: ['./activity-on-meter.component.scss']
})
export class ActivityOnMeterComponent implements OnInit {

  faSpinner = faSpinner;
  isFirstLoading = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  actionForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;
  
  responseData: any;
  meterDetail:any = {};
  getThrough : Access;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    this.actionForm = this.formBuilder.group({
      servantmode: [''],
      meterload: [''],
      backupsupply: [''],
      startfixedchargesonmeter: [''],
      startemergencycredit: [''],
    });
    //console.log(this.currentUser);
  }
  get aF() { return this.actionForm.controls; }
  get aD() { return this.actionForm.controls; }

  ngOnDestroy() {
    this.modal.dismiss;
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  onSubmitAddForm(){
    if (this.actionForm.invalid) {
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    //console.log(this.currentUser);
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', this.currentUser.data.userData.dcu);
    body.set('meterType', this.currentUser.data.userData.meterType);
    body.set('conAccId', this.currentUser.data.userData.uid);
    body.set('servantmode', this.aD.servantmode.value);
    body.set('meterload', this.aD.meterload.value);
    body.set('backupSupply', this.aD.backupsupply.value);
    body.set('cmdFrom', '1');
    body.set('startfixedchargesonmeter', this.aD.startfixedchargesonmeter.value);
    body.set('startemergencycredit', this.aD.startemergencycredit.value);
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/addMeterActivityDesh', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.message = this.responseData.data.message;
        if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessfully") || this.message.includes("Under Progress") || this.message.includes("Unsuccessfuly")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

}
