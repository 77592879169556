import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editconsumernotification',
  templateUrl: './editconsumernotification.component.html',
  styleUrls: ['./editconsumernotification.component.scss']
})
export class EditconsumernotificationComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  subDivisionId: any;
  notifiData: any;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }

    ngOnInit() { 
      this.addForm = this.formBuilder.group({
      //consSubDivision: ['', Validators.required],
      //consName: ['', Validators.required],
      consNotification: ['', Validators.required]
      });
    
      this.aF.consNotification.setValue(this.notifiData.consNotification);
    }
    ngAfterViewInit() {
       
    }
    ngOnDestroy() {
      //this.modalService.dismissAll();
    }
  
    get aF() { return this.addForm.controls; }
    onSubmitAddForm(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      let body = new URLSearchParams();
      body.set('subdivId', this.currentUser.data.userData.subdivision);
      body.set('notifiId', this.notifiData.consNotifiUid);
      body.set('consNotification',this.aF.consNotification.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/updateConsumerNotification', body).subscribe((result) => {
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.responseMessage = 'Unable to process yours request!';
      });
    }


}
