<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Current Status" name="mcs" formControlName="mcs">
        <mat-error *ngIf="cF.mcs.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Current Tampers" name="mct" formControlName="mct">
        <mat-error *ngIf="cF.mct.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Load Off Status" name="mloffs" formControlName="mloffs">
        <mat-error *ngIf="cF.mloffs.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Load On Status" name="mlons" formControlName="mlons">
        <mat-error *ngIf="cF.mlons.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Over Load Status" name="mols" formControlName="mols">
        <mat-error *ngIf="cF.mols.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Over voltage Status" name="movs" formControlName="movs">
        <mat-error *ngIf="cF.movs.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Low Voltage Status" name="mlvs" formControlName="mlvs">
        <mat-error *ngIf="cF.mlvs.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
        <input matInput placeholder="Meter Low Balance" name="mlb" formControlName="mlb">
        <mat-error *ngIf="cF.mlb.hasError('required')">
            lrd is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>