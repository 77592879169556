<form [formGroup]="actionForm" (ngSubmit)="submitForm()">
    <!--<fieldset class="scheduler-border">
                        <legend class="scheduler-border">
                            ESW Filter
                        </legend>
                        <div class="row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>ESW Filter Three Phase</B>
                                    </td>
                                </tr>
                            </table>
                        </div>-->
    <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="rPhaseVoltage" formControlName="rPhaseVoltage">
                <table>
                    <div class="innertbl">
                        <label> 1) R-Phase Voltge Missing</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="yPhaseVoltage" formControlName="yPhaseVoltage">
                <table>
                    <div class="innertbl">
                        <label> 2) Y-Phase Voltge Missing</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="bPhaseVoltage" formControlName="bPhaseVoltage">
                <table>
                    <div class="innertbl">
                        <label> 3) B-Phase Voltge Missing</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overVoltage" formControlName="overVoltage">
                <table>
                    <div class="innertbl">
                        <label> 4) Over Voltage</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>



        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="lowVoltage" formControlName="lowVoltage">
                <table>
                    <div class="innertbl">
                        <label> 5) Low Voltage</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="voltageUnbalance" formControlName="voltageUnbalance">
                <table>
                    <div class="innertbl">
                        <label> 6) Voltage Unbalance</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="rPhaseCurrentRev" formControlName="rPhaseCurrentRev">
                <table>
                    <div class="innertbl">
                        <label> 7) R-Phase Currrent Reverse</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="yPhaseCurrentRev" formControlName="yPhaseCurrentRev">
                <table>
                    <div class="innertbl">
                        <label> 8) Y-Phase Currrent Reverse</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>


        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="bPhaseCurrentRev" formControlName="bPhaseCurrentRev">
                <table>
                    <div class="innertbl">
                        <label> 9) B-Phase Currrent Reverse</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="currentUnbalance" formControlName="currentUnbalance">
                <table>
                    <div class="innertbl">
                        <label> 10) Current Unbalance</label>
                        <tr>
                            <td colspan="3" style="text-align-last: start;">


                            </td>

                        </tr>
                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="currentByPass" formControlName="currentByPass">
                <table>
                    <div class="innertbl">
                        <label> 11) Current By-Pass</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overCurrent" formControlName="overCurrent">
                <table>
                    <div class="innertbl">
                        <label> 12) Over Current (Any-Phase)</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>


        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="veryLPF" formControlName="veryLPF">
                <table>
                    <div class="innertbl">
                        <label> 13) Very Low PF</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="infMagnet" formControlName="infMagnet">
                <table>
                    <div class="innertbl">
                        <label> 14) Influence Of Magnet</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="neturalDistrubance" formControlName="neturalDistrubance">
                <table>
                    <div class="innertbl">
                        <label> 15) Neutral Disturbance</label>
                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="meterCOpen" formControlName="meterCOpen">
                <table>
                    <div class="innertbl">
                        <label> 16) Meter Cover Open</label>
                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>


        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="loadConnectDis" formControlName="loadConnectDis">
                <table>
                    <div class="innertbl">
                        <label> 17) Load Connect/Disconnect</label>
                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="lastGasp" formControlName="lastGasp">
                <table>
                    <div class="innertbl">
                        <label> 18) Last Gasp</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="firstBreath" formControlName="firstBreath">
                <table>
                    <div class="innertbl">
                        <label> 19) First Breath</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="mdReset" formControlName="mdReset">
                <table>
                    <div class="innertbl">
                        <label> 20) MD Reset</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="zeroBalance" formControlName="zeroBalance">
                <table>
                    <div class="innertbl">
                        <label> 21) Zero Balance</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>


        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="negativeBalance" formControlName="negativeBalance">
                <table>
                    <div class="innertbl">
                        <label> 22) Negative Balance</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overloadThree" formControlName="overloadThree">
                <table>
                    <div class="innertbl">
                        <label> 23) Overload</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="ebOn" formControlName="ebOn">
                <table>
                    <div class="innertbl">
                        <label> 24) EB On</label>
                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>


        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="dgOn" formControlName="dgOn">
                <table>
                    <div class="innertbl">
                        <label> 25) DG On</label>
                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="freqAbnormal" formControlName="freqAbnormal">
                <table>
                    <div class="innertbl">
                        <label> 26) Frequency Abnormal</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>

    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>

            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <!--<button type="submit" [disabled]="AddProcess" class="dilog dilog1">-->
            <button type="submit" class="dilog dilog1">    
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>
    <!--</fieldset>-->
</form>