<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
            data-dismiss="modal">&times;</button>
        Configure Meter: {{meterDetail.mID}}
    </h4>
</div>
<div class="modal-body">
    <!--<ng-container class="allutilitysloader col-12" *ngIf="loading">
      <fa name="spinner" animation="spin" size="4x"></fa>
    </ng-container>-->
    <div class="container">
        <div class="col-md-12">
            <div class="navall_tabblue">
                <nav>
                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav_gernal_config" data-toggle="tab"
                            href="#gernal_config" role="tab" aria-controls="load-limit" aria-selected="true"> Gernal
                            Configuration
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="Gernal Configuration"></i>
                            </div>
                        </a>
                        <a class="nav-item nav-link" id="nav_time_config" data-toggle="tab" href="#time_config"
                            role="tab" aria-controls="happy-hours" aria-selected="false">Time Configutation
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="Time Configutation"></i>
                            </div>
                        </a>

                        <a class="nav-item nav-link" id="nav_load_limit_config" data-toggle="tab"
                            href="#load_limit_config" role="tab" aria-controls="meter-alarm" aria-selected="false">Load
                            Limiter Configuration
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="Load Limiter Configuration"></i>
                            </div>
                        </a>

                        <a class="nav-item nav-link" id="nav_tod_zone_setting" data-toggle="tab"
                            href="#tod_zone_setting" role="tab" aria-controls="meter-alarm" aria-selected="false">TOD
                            Zone Setting
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="TOD Zone Setting"></i>
                            </div>
                        </a>

                        <a class="nav-item nav-link" id="nav_esw_filter" data-toggle="tab" href="#esw_filter" role="tab"
                            aria-controls="date-time" aria-selected="false">ESW Filter
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="ESW Filter"></i>
                            </div>
                        </a>
                    </div>
                </nav>
                <div class="tab-content meterload" id="nav-tabContent">

                    <div class="tab-pane fade" id="gernal_config" role="tabpanel" aria-labelledby="nav_gernal_config">
                        <fieldset class="scheduler-border"
                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Meter">
                            <legend class="scheduler-border">
                                General Configuration
                            </legend>
                            <div class="col-sm-12 row" *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config || 
                            getThrough.authority.Meters_Sub_Configure_Utility_Demand_Integration_Period_Config">
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config">
                                    <form [formGroup]="actionForm1">
                                        <div class="col-sm-12 row">
                                            <div class="col-sm-8">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <mat-select placeholder="Select Capture Period"
                                                            name="capturedPeriod" formControlName="capturedPeriod"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted1 && aF1.capturedPeriod.errors }"
                                                            required>
                                                            <mat-option *ngFor="let td of capture" [value]="td.id">
                                                                {{td.Name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                        <label>Load Profile Capture Period</label>
                                                        <div *ngIf="submitted1 && aF1.capturedPeriod.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="submitted1 && aF1.capturedPeriod.errors.required">
                                                                Captured Period Is required
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class=iconconfigbtn>
                                                    <div>
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('1')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class=iconconfigbtn>
                                                    <div>
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('1')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Integration_Period_Config">
                                    <form [formGroup]="actionForm2">
                                        <div class="col-sm-12 row">
                                            <div class="col-sm-8">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <mat-select placeholder="Select Integration Period"
                                                            name="demandIntegrationPeriod"
                                                            formControlName="demandIntegrationPeriod"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted2 && aF2.demandIntegrationPeriod.errors }"
                                                            required>
                                                            <mat-option *ngFor="let td of demand" [value]="td.id">
                                                                Earth Loading {{td.Name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                        <label>Demand Integration Period</label>
                                                        <div *ngIf="submitted2 && aF2.demandIntegrationPeriod.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="submitted2 &&  aF2.demandIntegrationPeriod.errors.required">
                                                                Demand Integration Period Is required
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class=iconconfigbtn>
                                                    <div>
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('2')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class=iconconfigbtn>
                                                    <div>
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('2')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-sm-12 row"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Config || getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Config">
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Config">
                                    <form [formGroup]="actionForm5">
                                        <div class="col-sm-12 row">
                                            <div class="col-sm-8">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <mat-select placeholder="Push Schedule" name="pushSchedular"
                                                            formControlName="pushSchedular" class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted5 && aF5.pushSchedular.errors }"
                                                            required>
                                                            <mat-option *ngFor="let td of push" [value]="td.id">
                                                                {{td.Name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                        <label>Push Schedule</label>
                                                        <div *ngIf="submitted5 && aF5.pushSchedular.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="submitted5 && aF5.pushSchedular.errors.required">
                                                                Push Schedular Is requiredd
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class=iconconfigbtn>
                                                    <div>
                                                        <button title="Get" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle"
                                                            (click)="loadCapturePeriod('5')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div>
                                                    <button title="Set" type="submit" [disabled]="isLoading"
                                                        class="dilog dilog1 buttonStyle" (click)="submitForm('5')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Write_Config">
                                                        <!--<mat-icon class="d-c4">update</mat-icon>-->
                                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Config">
                                    <form [formGroup]="actionForm4">
                                        <div class="col-sm-12 row">
                                            <div class="col-sm-4">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <input placeholder="Billing Date" name="billingDateFromMeter"
                                                            formControlName="billingDateFromMeter" autocomplete="off"
                                                            class="form-control">
                                                        <label>Get Billing Cycle</label>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <input placeholder="Billing Date (YYYY-MM-DD)"
                                                            name="billingDate" formControlName="billingDate"
                                                            (bsValueChange)="getBillingDate($event)" bsDatepicker
                                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted4 && aD.billingDate.errors}"
                                                            required>

                                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                        <label>Set Billing Cycle(YYYY-MM-DD)</label>
                                                        <div *ngIf="submitted4 && aF4.billingDate.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="submitted4 && aF4.billingDate.errors.required">
                                                                Billing Date Is required
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class="iconconfigbtn">
                                                    <div>
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('4')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2" style="align-self: center;">
                                                <div class="iconconfigbtn">
                                                    <div>
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('4')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-sm-12 row">
                                <div class="col-sm-12 text-left">
                                    <ng-container *ngIf="isLoadingz1">
                                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x"
                                            class="makeCenter"></fa-icon>
                                    </ng-container>
                                    <div role="alert" *ngIf="message1 && message1.length > 0">
                                        <div class="alert alert-success " role="alert" *ngIf="thumb">
                                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message1}}
                                        </div>
                                        <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message1}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="scheduler-border"
                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config">
                            <legend class="scheduler-border">
                                General Configuration
                            </legend>
                            <div class="col-sm-12 row">
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Config">
                                    <form [formGroup]="actionForm19">
                                        <mat-radio-group name="prepaidFunctionNew" formControlName="prepaidFunctionNew">
                                            <table>
                                                <div class="innertbl">
                                                    <tr>
                                                        <td colspan="2" style="text-align-last: start;">
                                                            <B>Payment Mode</B>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="background: white; text-align: -webkit-left;width:40%">
                                                            <mat-radio-button value="1">Prepaid</mat-radio-button>
                                                        </td>
                                                        <td
                                                            style="background: white; text-align: -webkit-left;width:40%">
                                                            <mat-radio-button value="2">Postpaid</mat-radio-button>
                                                        </td>


                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Get" class="dilog dilog1 buttonStyle"
                                                                    [disabled]="isLoading"
                                                                    (click)="loadCapturePeriod('7')"
                                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config">
                                                                    <i class="fa fa-download"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Set" type="submit" [disabled]="isLoading"
                                                                    class="dilog dilog1 buttonStyle"
                                                                    (click)="submitForm('7')"
                                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config">
                                                                    <i class="fa fa-upload"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </table>
                                        </mat-radio-group>
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config">
                                    <form [formGroup]="actionForm8" (ngSubmit)="submitForm('8')">
                                        <mat-radio-group name="netMeters" formControlName="netMeters">
                                            <table>
                                                <div class="innertbl">
                                                    <tr>
                                                        <td colspan="2" style="text-align-last: start;">
                                                            <B>Metering Mode</B>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="background: white; text-align: -webkit-left; width:40%">
                                                            <mat-radio-button value="1">Import/Export</mat-radio-button>
                                                        </td>
                                                        <td
                                                            style="background: white; text-align: -webkit-left; width:40%">
                                                            <mat-radio-button value="2">Import Only</mat-radio-button>
                                                        </td>
                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Get" class="dilog dilog1 buttonStyle"
                                                                    [disabled]="isLoading"
                                                                    (click)="loadCapturePeriod('8')"
                                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config">
                                                                    <i class="fa fa-download"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Set" type="submit" [disabled]="isLoading"
                                                                    class="dilog dilog1 buttonStyle"
                                                                    (click)="submitForm('8')"
                                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config">
                                                                    <i class="fa fa-upload"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                </div>
                                            </table>
                                        </mat-radio-group>
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config">
                                    <form [formGroup]="actionForm20">
                                        <!--<mat-radio-group name="connectDisconnectControl"
                                    formControlName="connectDisconnectControl">-->
                                        <table>
                                            <div class="innertbl">
                                                <tr>
                                                    <td colspan="2" style="text-align-last: start;">
                                                        <B>Load Control</B>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <!--<td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="0">Disable</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="4">Manual</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="6">Auto</mat-radio-button>
                                                </td>-->
                                                    <mat-select placeholder="Select Control Mode"
                                                        name="connectDisconnectControl"
                                                        formControlName="connectDisconnectControl" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && aF2.connectDisconnectControl.errors }"
                                                        required>
                                                        <mat-option *ngFor="let td of mode" [value]="td.id">
                                                            {{td.Name}}
                                                        </mat-option>
                                                    </mat-select>


                                                    <div *ngIf="submitted2 && aF2.connectDisconnectControl.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="submitted2 &&  aF2.connectDisconnectControl.errors.required">
                                                            Control Mode Is required
                                                        </div>
                                                    </div>
                                                    <td style="width:10%">
                                                        <div style="float:right;">
                                                            <button title="Get" class="dilog dilog1 buttonStyle"
                                                                [disabled]="isLoading" (click)="loadCapturePeriod('20')"
                                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config">
                                                                <i class="fa fa-download" style="font-size: 25px;"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td style="width:10%">
                                                        <div style="float:right;">
                                                            <button title="Set" type="submit" [disabled]="isLoading"
                                                                class="dilog dilog1 buttonStyle"
                                                                (click)="submitForm('20')"
                                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config">
                                                                <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </div>
                                        </table>
                                        <!--</mat-radio-group>-->
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config">
                                    <form [formGroup]="actionForm9" (ngSubmit)="submitForm('9')">
                                        <mat-radio-group name="connectDisconnect" formControlName="connectDisconnect">
                                            <table>
                                                <div class="innertbl">
                                                    <tr>
                                                        <td colspan="2" style="text-align-last: start;">
                                                            <B>Connect/Disconnect</B>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="background: white; text-align: -webkit-left; width:40%">
                                                            <mat-radio-button value="1">Connect</mat-radio-button>
                                                        </td>
                                                        <td
                                                            style="background: white; text-align: -webkit-left; width:40%">
                                                            <mat-radio-button value="2">Disconnect</mat-radio-button>
                                                        </td>
                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Get" class="dilog dilog1 buttonStyle"
                                                                    [disabled]="isLoading"
                                                                    (click)="loadCapturePeriod('9')">
                                                                    <i class="fa fa-download"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td style="width:10%">
                                                            <div style="float:right;">
                                                                <button title="Set" type="submit" [disabled]="isLoading"
                                                                    class="dilog dilog1 buttonStyle"
                                                                    (click)="submitForm('9')">
                                                                    <i class="fa fa-upload"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                </div>
                                            </table>
                                        </mat-radio-group>
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config">
                                    <form [formGroup]="actionForm10" (ngSubmit)="submitForm('10')">
                                        <mat-radio-group name="mdReset" formControlName="mdReset">
                                            <table>
                                                <div class="innertbl">
                                                    <tr>
                                                        <td style="text-align-last: start;">
                                                            <B>MD Reset</B>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="background: white;width: 90%;text-align: -webkit-left;">
                                                            <mat-radio-button value="1">On</mat-radio-button>
                                                        </td>
                                                        <td>
                                                            <div class="iconconfigbtn">
                                                                <!--<button [disabled]="isLoading" style="border: 0;background: azure;"
                                                        (click)="loadCapturePeriod('10')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config">
                                                        <i class="fa d-c4"
                                                            [ngClass]="{'fa-spin fa-spinner': isLoading10, 'fa-refresh': !isLoading10}"
                                                            style="font-size: 20px;"></i>
                                                    </button>-->
                                                                <div style="float:right;">
                                                                    <button title="Set" class="btnref" type="submit"
                                                                        [disabled]="isLoading"
                                                                        class="dilog dilog1 buttonStyle"
                                                                        (click)="submitForm('10')"
                                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config">
                                                                        <i class="fa fa-upload"
                                                                            style="font-size: 25px;"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </table>

                                        </mat-radio-group>
                                    </form>
                                </div>
                                <div class="col-sm-6 text-left"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config">
                                    <form [formGroup]="actionForm24" (ngSubmit)="submitForm('24')">
                                        <mat-radio-group name="ping" formControlName="ping">
                                            <table>
                                                <div class="innertbl">
                                                    <tr>
                                                        <td style="text-align-last: start;">
                                                            <B>Ping</B>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="background: white;width: 90%;text-align: -webkit-left;">
                                                            <mat-radio-button value="1">Ping</mat-radio-button>
                                                        </td>
                                                        <div class="iconconfigbtn">
                                                            <!--<button [disabled]="isLoading" style="border: 0;background: azure;"
                                                        (click)="loadCapturePeriod('10')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config">
                                                        <i class="fa d-c4"
                                                            [ngClass]="{'fa-spin fa-spinner': isLoading10, 'fa-refresh': !isLoading10}"
                                                            style="font-size: 20px;"></i>
                                                    </button>-->
                                                            <div style="float:right;">
                                                                <button title="Set" class="btnref" type="submit"
                                                                    [disabled]="isLoading"
                                                                    class="dilog dilog1 buttonStyle"
                                                                    (click)="submitForm('24')"
                                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config">
                                                                    <i class="fa fa-upload"
                                                                        style="font-size: 25px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                </div>
                                            </table>

                                        </mat-radio-group>
                                    </form>
                                </div>

                            </div>
                            <div class="col-sm-12 row">
                                <div class="col-sm-12 text-left">
                                    <ng-container *ngIf="isLoading">
                                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x"
                                            class="makeCenter"></fa-icon>
                                    </ng-container>
                                    <div role="alert" *ngIf="message2 && message2.length > 0">
                                        <div class="alert alert-success " role="alert" *ngIf="thumb">
                                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message2}}
                                        </div>
                                        <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message2}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="tab-pane fade" id="time_config" role="tabpanel" aria-labelledby="nav_time_config">

                    </div>
                    <div class="tab-pane fade" id="load_limit_config" role="tabpanel"
                        aria-labelledby="nav_load_limit_config">

                    </div>
                    <div class="tab-pane fade" id="tod_zone_setting" role="tabpanel"
                        aria-labelledby="nav_tod_zone_setting">

                    </div>
                    <div class="tab-pane fade" id="esw_filter" role="tabpanel" aria-labelledby="nav_esw_filter">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 
      <ng-container>
              <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c3">
                          flash_on
                        </mat-icon>
                        Set Load Limit On Meter
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfigloadlimit [meterDetail]="meterDetail"></app-meterconfigloadlimit>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c12">
                          add_box
                        </mat-icon>
                        Set Happy Hours On Meter
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfighappyhours [meterDetail]="meterDetail"></app-meterconfighappyhours>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c6">
                          alarm
                        </mat-icon>
                        Set Meter Alarm Level/Emergeny Credit /Billing Tariff
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfigaeb [meterDetail]="meterDetail"></app-meterconfigaeb>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c7">
                          schedule
                        </mat-icon>
                        Meter Bill Date And Time Settings
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                      <app-meterbillschedular [meterDetail]="meterDetail"></app-meterbillschedular>
                  </mat-expansion-panel>
  
                  <div>
                  </div>
                </mat-accordion>
                                
              </div>
      </ng-container> -->
    <ng-container col-sm-3>
    </ng-container>
</div>
<!-- content viewport ends -->