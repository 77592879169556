<!----add authority start-->

<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddConsumer()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Modify Permissions:
          {{consFullName}}
    </h4>
  </div>
    <div class="modal-body">
      <!--<div *ngIf="loading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
      </div>-->
      <div class="form-groupp">
        <nav>
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav_utlitiadmin_view" data-toggle="tab" href="#nav_utlitiadmin" role="tab"
              aria-controls="nav-authority" aria-selected="true"> 
             Utility Admin Report
              <i class="infoI fa fa-info" data-toggle="tooltip"
              data-placement="top" title="Information of Utility Admin Report Permission"></i>
            </a>
            <a class="nav-item nav-link" id="nav_society_view" data-toggle="tab" href="#nav_society" role="tab"
              aria-controls="nav-society" aria-selected="true">
              Society Admin Report
              <i class="infoI fa fa-info" data-toggle="tooltip"
              data-placement="top" title="Information of  Society Admin Report Permission"></i>
            </a>
            <a class="nav-item nav-link" id="nav_utilityconsumer_view" data-toggle="tab" href="#nav_utilityconsumer" role="tab"
              aria-controls="nav-utilityconsumer" aria-selected="true">
             Utility Consumer Report
              <i class="infoI fa fa-info" data-toggle="tooltip"
              data-placement="top" title="Information of Utility Consumer Report Permission"></i>
            </a>
          </div>
        </nav>
        <div class="tab-content meterload" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav_utlitiadmin" role="tabpanel" aria-labelledby="nav_utlitiadmin_view">
            <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c9">
                      phone_android
                    </mat-icon>
                    Active All Permissions
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="with-icon col-sm-4 panel-body">
                  <mat-icon class="d-c9">phone_android</mat-icon>
                  <mat-slide-toggle formControlName="Active_All_Permission_Access">
                    Active All Permissions
                  </mat-slide-toggle>
                </div>
              </mat-expansion-panel>
  
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Instant Data Recent
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Cons_Account_No">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Meter_Unque_Id">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Flat_No_Id">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Meter_Id">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_RTC">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Current">
                      Current
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Voltage">
                      Voltage
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Neutral_Current">
                      Neutral Current
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Power_Factor">
                      Power Factor
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Frequency">
                      Frequency
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Power">
                      Apperant Power
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Power">
                      Active Power
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c11">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Energy_import">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c12">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Energy_import">
                      Peerant Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c11">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Energy_Export">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c13">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Energy_Export">
                      Apperant Energy Eport
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kW">
                      MD kW
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kW_datetime">
                      MD kW Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kVA">
                      MD kVA
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kVA_datetime">
                      MD kVA Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Power_On_Minute">
                      Power On Minute
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Tamper_Count">
                      Tamper Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Billing_Count">
                      Billing Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Programming_Count">
                      Programming Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Disconnect_Control">
                      Disconnect Control
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_LL_Thrash_Hold">
                      LL Thrashold
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Instant Data History
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Cons_Account_No_His">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Meter_Unque_Id_His">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Flat_No_Id_His">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Meter_Id_His">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_RTC_His">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Current_His">
                      Current
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Voltage_His">
                      Voltage
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Neutral_Current_His">
                      Neutral Current
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Power_Factor_His">
                      Power Factor
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Frequency_His">
                      Frequency
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Power_His">
                      Apperant Power
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Power_His">
                      Active Power
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c11">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Energy_import_His">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c12">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Energy_import_His">
                      Peerant Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c11">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Active_Energy_Export_His">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c13">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Apperant_Energy_Export_His">
                      Apperant Energy Eport
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kW_His">
                      MD kW
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kW_datetime_His">
                      MD kW Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kVA_His">
                      MD kVA
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Parm_Md_kVA_datetime_His">
                      MD kVA Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Power_On_Minute_His">
                      Power On Minute
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Tamper_Count_His">
                      Tamper Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Billing_Count_His">
                      Billing Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Com_Programming_Count_His">
                      Programming Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_Disconnect_Control_His">
                      Disconnect Control
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c14">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Inst_LL_Thrash_Hold_His">
                      LL Thrashold
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>
  
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Billing Data Recent
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Cons_Account_No">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Meter_Unque_Id">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Flat_No_Id">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Meter_Id">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_RTC">
                      Meter RTC 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Avg_Power_Factor">
                      Avg. PF
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB">
                      Cum kWH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Consumed_Active_Energy">
                      Cons. kWH
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone1">
                      Cum kWH Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone2">
                      Cum kWH Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone3">
                      Cum kWH Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone4">
                      Cum kWH Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone5">
                      Cum kWH Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone6">
                      Cum kWH Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone7">
                      Cum kWH Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone8">
                      Cum kWH Z8
                    </mat-slide-toggle>
                  </div>

                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB">
                      Cum kVAH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Consumed_Apparent_Energy">
                      Cons. kVAH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone1">
                      Cum kVAH Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone2">
                      Cum kVAH Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone3">
                      Cum kVAH Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone4">
                      Cum kVAH Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone5">
                      Cum kVAH Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone6">
                      Cum kVAH Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone7">
                      Cum kVAH Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone8">
                      Cum kVAH Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value">
                      MD kW 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime">
                      MD kW D/T 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone1">
                      MD kW Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone1">
                      MD kW D/T Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone2">
                      MD kW Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone2">
                      MD kW D/T Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone3">
                      MD kW Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone3">
                      MD kW D/T Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone4">
                      MD kW Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone4">
                      MD kW D/T Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone5">
                      MD kW Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone5">
                      MD kW D/T Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone6">
                      MD kW Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone6">
                      MD kW D/T Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone7">
                      MD kW 7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone7">
                      MD kW D/T Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone8">
                      MD kW 8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone8">
                      MD kW D/T Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value">
                      MD kVA
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime">
                      MD kVA D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone1">
                      MD kVA Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone1">
                      MD kVA D/TZ1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone2">
                      MD kVA Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone2">
                      MD kVA D/TZ2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone3">
                      MD kVA Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone3">
                      MD kVA D/TZ3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone4">
                      MD kVA Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone4">
                      MD kVA D/TZ4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone5">
                      MD kVA Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone5">
                      MD kVA D/TZ5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone6">
                      MD kVA Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone6">
                      MD kVA D/TZ6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone7">
                      MD kVA Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone7">
                      MD kVA D/TZ7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone8">
                      MD kVA Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone8">
                      MD kVA D/TZ8
                    </mat-slide-toggle>
                  </div>

                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_1">
                      TOD Script 1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_2">
                      TOD Script 2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_3">
                      TOD Script 3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_4">
                      TOD Script 4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_5">
                      TOD Script 5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_6">
                      TOD Script 6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_7">
                      TOD Script 7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_8">
                      TOD Script 8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_1">
                      TOD Timing Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_2">
                      TOD Timing Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_3">
                      TOD Timing Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_4">
                      TOD Timing Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_5">
                      TOD Timing Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_6">
                      TOD Timing Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_7">
                      TOD Timing Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_8">
                      TOD Timing Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Md_Reset_Type">
                      MD ResetType
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Active_Energy_Export">
                     kWH Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Apperant_Energy_Export">
                      kVAH Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_PowerOn_Hrs_EB">
                      POH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Load_Limit_Grid">
                      LL Grid
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Billing_Mode_EB">
                      Billing Mode
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_Type">
                      Billing Type
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Previous_Bill_Date_Time">
                      Pre. Billing D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_Sent_Datetime">
                      Bill Sent D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Server_Date_Time">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Auth_Unique_Id">
                      Authority
                    </mat-slide-toggle>
                  </div>
                 
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Billing Data History
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Cons_Account_No_His">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Meter_Unque_Id_His">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Flat_No_Id_His">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_Meter_Id_His">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Parm_RTC_His">
                      Meter RTC
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Avg_Power_Factor_His">
                      Avg. PF
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_His">
                      Cum kWH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Consumed_Active_Energy_His">
                      Cons. kWH
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone1_His">
                      Cum kWH Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone2_His">
                      Cum kWH Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone3_His">
                      Cum kWH Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone4_His">
                      Cum kWH Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone5_His">
                      Cum kWH Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone6_His">
                      Cum kWH Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone7_His">
                      Cum kWH Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Active_Energy_EB_Zone8_His">
                      Cum kWH Z8
                    </mat-slide-toggle>
                  </div>

                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_His">
                      Cum kVAH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Consumed_Apparent_Energy_His">
                      Cons. kVAH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone1_His">
                      Cum kVAH Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone2_His">
                      Cum kVAH Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone3_His">
                      Cum kVAH Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone4_His">
                      Cum kVAH Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone5_His">
                      Cum kVAH Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone6_His">
                      Cum kVAH Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone7_His">
                      Cum kVAH Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Cum_Apperant_Energy_EB_Zone8_His">
                      Cum kVAH Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_His">
                      MD kW 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_His">
                      MD kW D/T 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone1_His">
                      MD kW Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone1_His">
                      MD kW D/T Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone2_His">
                      MD kW Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone2_His">
                      MD kW D/T Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone3_His">
                      MD kW Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone3_His">
                      MD kW D/T Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone4_His">
                      MD kW Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone4_His">
                      MD kW D/T Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone5_His">
                      MD kW Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone5_His">
                      MD kW D/T Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone6_His">
                      MD kW Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone6_His">
                      MD kW D/T Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone7_His">
                      MD kW 7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone7_His">
                      MD kW D/T Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Value_Zone8_His">
                      MD kW 8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Active_Power_Datetime_Zone8_His">
                      MD kW D/T Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_His">
                      MD kVA
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_His">
                      MD kVA D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone1_His">
                      MD kVA Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone1_His">
                      MD kVA Datetime Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone2_His">
                      MD kVA Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone2_His">
                      MD kVA D/TZ2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone3_His">
                      MD kVA Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone3_His">
                      MD kVA D/TZ3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone4_His">
                      MD kVA Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone4_His">
                      MD kVA D/TZ4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone5_His">
                      MD kVA Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone5_His">
                      MD kVA D/TZ5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone6_His">
                      MD kVA Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone6_His">
                      MD kVA D/TZ6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone7_His">
                      MD kVA Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone7_His">
                      MD kVA D/TZ7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Value_Zone8_His">
                      MD kVA Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_MD_Apparent_Power_Datetime_Zone8_His">
                      MD kVA D/TZ8
                    </mat-slide-toggle>
                  </div>

                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_1_His">
                      TOD Script 1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_2_His">
                      TOD Script 2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_3_His">
                      TOD Script 3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_4_His">
                      TOD Script 4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_5_His">
                      TOD Script 5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_6_His">
                      TOD Script 6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_7_His">
                      TOD Script 7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Script_8_His">
                      TOD Script 8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_1_His">
                      TOD Timing Z1
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_2_His">
                      TOD Timing Z2
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_3_His">
                      TOD Timing Z3
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_4_His">
                      TOD Timing Z4
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_5_His">
                      TOD Timing Z5
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_6_His">
                      TOD Timing Z6
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_7_His">
                      TOD Timing Z7
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Tod_Timing_TZ_8_His">
                      TOD Timing Z8
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Md_Reset_Type_His">
                      MD ResetType
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Active_Energy_Export_His">
                     kWH Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Apperant_Energy_Export_His">
                      kVAH Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_PowerOn_Hrs_EB_His">
                      POH
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Load_Limit_Grid_His">
                      LL Grid
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Billing_Mode_EB_His">
                      Billing Mode
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_Type_His">
                      Billing Type
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Previous_Bill_Date_Time_His">
                      Pre. Billing D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Bill_Sent_Datetime_His">
                      Bill Sent D/T
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Server_Date_Time_His">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-3">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Billing_Auth_Unique_Id_His">
                      Authority
                    </mat-slide-toggle>
                  </div>
                 
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Event Data Recent
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Cons_Account_No">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Meter_Unque_Id">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Flat_No_Id">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Meter_Id">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_RTC">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage">
                      Voltage-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage_Y">
                      Voltage-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage_B">
                      Voltage-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current">
                      Current-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current_Y">
                      Current-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current_B">
                      Current-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor">
                      Power Factor-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor_Y">
                      Power Factor-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor_B">
                      Power Factor-B
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Active_Energy_Imp">
                      Active Energy
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Apperant_Energy_Imp">
                      Apperant Energy
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Event_Count_Imp">
                      Event Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Event_Type_Imp">
                      Event Type
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Server_Datetime">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Authority">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Event Data History
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Cons_Account_No_His">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Meter_Unque_Id_His">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Flat_No_Id_His">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Meter_Id_His">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_RTC_His">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage_His">
                      Voltage-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage_Y_His">
                      Voltage-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Voltage_B_His">
                      Voltage-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current_His">
                      Current-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current_Y_His">
                      Current-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Current_B_His">
                      Current-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor_His">
                      Power Factor-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor_Y_His">
                      Power Factor-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Power_Factor_B_His">
                      Power Factor-B
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Active_Energy_Imp_His">
                      Active Energy
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Apperant_Energy_Imp_His">
                      Apperant Energy
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Event_Count_Imp_His">
                      Event Count
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Event_Type_Imp_His">
                      Event Type
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Server_Datetime_His">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Event_Parm_Authority_His">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Daily Loadsurvey Data Recent
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Cons_Account_No">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Meter_Unque_Id">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Flat_No_Id">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Meter_Id">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_RTC">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Active_Energy_Imp">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Apperant_Energy_Imp">
                      Apparent Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Active_Energy_Expo">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Apperant_Energy_Expo">
                      Apparent Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Server_Datetime">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Authority">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Daily Loadsurvey Data History
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Cons_Account_No_His">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Meter_Unque_Id_His">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Flat_No_Id_His">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Meter_Id_His">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_RTC_His">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Active_Energy_Imp_His">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Apperant_Energy_Imp_His">
                      Apparent Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Active_Energy_Expo_His">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Apperant_Energy_Expo_His">
                      Apparent Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Server_Datetime_His">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Daily_Parm_Authority_His">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Block Loadsurvey Data Recent
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Cons_Account_No">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Meter_Unque_Id">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Flat_No_Id">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Meter_Id">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_RTC">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage">
                      Average Voltage-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage_Y">
                      Average Voltage-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage_B">
                      Average Voltage-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current">
                      Average Current-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current_Y">
                      Average Current-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current_B">
                      Average Current-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Active_Energy_Imp">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Apperant_Energy_Imp">
                      Apparent Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Active_Energy_Expo">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Apperant_Energy_Expo">
                      Apparent Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Server_Datetime">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Authority">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="d-c6">
                      insert_chart
                    </mat-icon> Block Loadsurvey Data History
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <div class="form-row panel-body">
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c6">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Cons_Account_No_His">
                      Consumer Account No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c7">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Meter_Unque_Id_His">
                      Meter Unique Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Flat_No_Id_His">
                      Flat No
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Meter_Id_His">
                      Meter Id
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_RTC_His">
                      Meter Real Datetime 
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage_His">
                      Average Voltage-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage_Y_His">
                      Average Voltage-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Voltage_B_His">
                      Average Voltage-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current_His">
                      Average Current-R
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current_Y_His">
                      Average Current-Y
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Current_B_His">
                      Average Current-B
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Active_Energy_Imp_His">
                      Active Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Apperant_Energy_Imp_His">
                      Apparent Energy Import
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c10">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Active_Energy_Expo_His">
                      Active Energy Export
                    </mat-slide-toggle>
                  </div>
                  
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c9">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Apperant_Energy_Expo_His">
                      Apparent Energy Export
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Server_Datetime_His">
                      Server Datetime
                    </mat-slide-toggle>
                  </div>
                  <div class="with-icon col-sm-4">
                    <mat-icon class="d-c8">insert_chart</mat-icon>
                    <mat-slide-toggle formControlName="Single_Block_Parm_Authority_His">
                      Authority Id
                    </mat-slide-toggle>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
           <div class="tab-pane fade" id="nav_society" role="tabpanel" aria-labelledby="nav_society_view">
              <mat-accordion>
                Society Admin Report Permission
              </mat-accordion>
          </div>
           <div class="tab-pane fade" id="nav_utilityconsumer" role="tabpanel" aria-labelledby="nav_utilityconsumer_view">
             <mat-accordion>
             Utility Consumer Report Permission
               </mat-accordion>
          </div>
        </div>
        <!-- Grid row -->
        <!-- Grid row -->
        <div class="clearfix"></div>
  
        <div class="row">
          <div class="col-sm-12 text-left">
            <ng-container *ngIf="isloading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <div role="alert" *ngIf="message.length > 0">
              <div class="alert alert-success" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
              </div>
            </div>
  
          </div>
  
          <div class="col-sm-12 text-right">
            <button type="submit" class="dilog dilog1">
              <mat-icon>update</mat-icon>
              Update
            </button>
            <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
              <mat-icon>cancel</mat-icon>
              Cancel
            </button>
  
          </div>
        </div>
  
      </div>
    </div>
  </form>
  <!----add authority end-->