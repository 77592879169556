import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addcontractorissuedmeters',
  templateUrl: './addcontractorissuedmeters.component.html',
  styleUrls: ['./addcontractorissuedmeters.component.scss']
})
export class AddcontractorissuedmetersComponent implements OnInit {
  authority: any;
  faSpinner = faSpinner;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';

  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  submitted: boolean = false;
 /* deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "EB"},
    {deviceType: "DG"}
  ];*/

  deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "LTCT meters"}
  ];
  issuedData = [
    {deviceType: "Issued Meter"},
    {deviceType: "Revert Meter"},
  ];
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      prefix: ['', Validators.required],
      fromRange: ['', Validators.required],
      toRange: ['', Validators.required],
      deviceType: ['', Validators.required],
      specificId: [''],
      comment: [''],
      meterIssuedImage: [null, Validators.required]
    });
  }
  ngAfterViewInit() {
     
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  get aF() { return this.addForm.controls; }

  onFileSelect(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      //console.log(file.type);
      if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
        const file = event.target.files[0];
        this.aF.meterIssuedImage.setValue(file);
        //console.log(file.type,file);
        return true;
      }
      else {
        this.aF.meterIssuedImage.setValue(null);
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }
    }
  }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      console.log(this.aF.meterIssuedImage.value);
      if (this.aF.meterIssuedImage.value != null) {
        body.set('meterIssuedImage', this.aF.meterIssuedImage.value);
      } else {
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }

      body.set('subdivId', this.authority.sdID);
      body.set('contractorId', this.authority.aID);
      body.set('contractorName', this.authority.authFullName);
      body.set('prefix', this.aF.prefix.value);
      body.set('fromRange', this.aF.fromRange.value);
      body.set('toRange', this.aF.toRange.value);
      body.set('deviceType', this.aF.deviceType.value);
      body.set('specificId', this.aF.specificId.value);
      body.set('comment', this.aF.comment.value);

      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addContractorMeterRange', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
           
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                if(this.addAuthMessage.includes("Not Added")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
               /* setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);*/
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    onSubmitAddFormNew(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.aF.meterIssuedImage.value);
      if (this.aF.meterIssuedImage.value != null) {
        formData.append('meterIssuedImage', this.aF.meterIssuedImage.value);
      } else {
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }

      formData.append('subdivId', this.authority.sdID);
      formData.append('contractorId', this.authority.aID);
      formData.append('contractorName', this.authority.authFullName);
      formData.append('prefix', this.aF.prefix.value);
      formData.append('fromRange', this.aF.fromRange.value);
      formData.append('toRange', this.aF.toRange.value);
      formData.append('deviceType', this.aF.deviceType.value);
      formData.append('specificId', this.aF.specificId.value);
      formData.append('comment', this.aF.comment.value);

      formData.append('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.formDataPost('/addContractorMeterRange', formData).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
           
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                if(this.addAuthMessage.includes("Already released") || this.addAuthMessage.includes("Not Added")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
               /* setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);*/
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

   /* resetForm(form: FormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }*/
}

