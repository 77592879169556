<div class="container-fluid innerpage_top page-content-wrapper">
    <div class="row page-content-wrapper-innerr">
        <div class=" col-md-12 content-viewportt">
            <div class="page-content-wrapper-inner outerbox_shadow">
                <div class="row">
                    <div class="col-md-12 col-sm-12 boxflexEl">
                        <h1>Progress Till Date Dashboard 
                            <span class="info_icntop ml-1">
                                <i data-toggle="tooltip" data-placement="top"
                                    title="Progress Till Date Dashboard Information"
                                    class="fa fa-info tabula-tooltip "></i>
                            </span>
                        </h1>
                    </div>
                
                    <div class="container-fluid inner-page-content mt-2">
                            <div class="col-12">
                                <ng-container class="col-sm-12" *ngIf="loadingFirstTime">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div *ngIf="length!=0" class="table-responsivee table-responsive_fixed">
                                    <div *ngIf="checkStatus">
                                        <table id="ExampleMaterialTable" class="" mat-table [dataSource]="dataSource" matSort
                                           
                                            class="table-bordered table-sm table-hover">

                                            <!-- Position Column -->
                                            <ng-container matColumnDef="srNo">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by number" class="first">
                                                    Sr. No.
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="first"> {{element.srNo}} </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth"></td>
                                            </ng-container>

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="conCircle">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by name" class="first">
                                                    Circle name
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="first"> 
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>Total PD</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.conCircle}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">Total Meters</td>
                                            </ng-container>

                                            <!-- Weight Column -->
                                        
                                            <ng-container matColumnDef="total1PhProposed">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by weight">
                                                    Proposed<br>1-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.total1PhProposed}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.total1PhProposed}} 
                                                    </div> 
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalProposed1Ph}}</td>
                                            </ng-container>
        
                                            <!-- Symbol Column -->
                                            <ng-container matColumnDef="total1MeterCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Installed<br>1-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.total1MeterCount}}</b>
                                                    </div>
                                                
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.total1MeterCount}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalReplaced1Ph}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="dailyTotal1MeterCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Today's<br>1-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                        <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                            <b> {{element.dailyTotal1MeterCount}}</b>
                                                        </div>
                                                        <div *ngIf="element.conCircle!='Total PD'">
                                                             {{element.dailyTotal1MeterCount}}
                                                        </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalToday1Ph}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="total1PhBalance">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Balance<br>1-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b> {{element.total1PhBalance}} </b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                         {{element.total1PhBalance}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalBalanced1Ph}}</td>
                                            </ng-container>
                                        
                                            <ng-container matColumnDef="total3PhProposed">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by weight">
                                                    Proposed<br>3-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b> {{element.total3PhProposed}} </b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.total3PhProposed}} 
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalProposed3Ph}}</td>
                                            </ng-container>
                                    
                                        
                                            <!-- Symbol Column -->
                                            <ng-container matColumnDef="totalMeterCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Replaced<br>3-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b> {{element.totalMeterCount}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.totalMeterCount}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalReplaced3Ph}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="dailyTotalMeterCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Today's<br>3-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.dailyTotalMeterCount}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.dailyTotalMeterCount}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalToday3Ph}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="total3PhBalance">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Balance<br>3-Ph(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="second">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.total3PhBalance}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.total3PhBalance}} 
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalBalanced3Ph}}</td>
                                            </ng-container>
                                        
                                            <ng-container matColumnDef="totalLTCTProposed">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Proposed<br>LTCT(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="third">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.totalLTCTProposed}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.totalLTCTProposed}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalProposedLTCT}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="totalMeterCountLTCT">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Replaced<br>LTCT(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="third">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b> {{element.totalMeterCountLTCT}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.totalMeterCountLTCT}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalReplacedLTCT}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="dailyTotalMeterCountLTCT">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Today's<br>LTCT(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="third">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b> {{element.dailyTotalMeterCountLTCT}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                         {{element.dailyTotalMeterCountLTCT}}
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalTodayLTCT}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="totalLTCTBalance">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    sortActionDescription="Sort by symbol">
                                                    Balance<br>LTCT(WC)
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="third">
                                                    <div style="color: red;" *ngIf="element.conCircle=='Total PD'">
                                                        <b>{{element.totalLTCTBalance}}</b>
                                                    </div>
                                                    <div *ngIf="element.conCircle!='Total PD'">
                                                        {{element.totalLTCTBalance}}
                                                    </div>
                                                </td>
                                                    
                                                <td mat-footer-cell *matFooterCellDef class="forth">{{totalBalanceLTCT}}</td>
                                            </ng-container>
                                        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                                        </table>
                                    </div>
                                
                                </div>
                                <div>
                                    <div *ngIf="length==0">
                                        <div>
                                            <img class="center" src="assets/images/Nodata.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <mat-card-footer>
                    <div class="row">
                        <div class="col-11">
                            <!--<mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()"
                                [length]="length" (page)="changeMeterPage($event)" [showFirstLastButtons]="false" [disabled]="true" [hidePageSize]="true" >
                            </mat-paginator>-->
                        </div>
                        <div class="col-1 text-left1">
                            <div *ngIf="length!=0">
                                <button style="border:0;background: azure;" title="Export To Excel"
                                    (click)="exportTable()"><i class="fa fa-file-excel-o"
                                        style="font-size:25px;color:rgb(0, 132, 255)"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-footer>
            </div>
        </div>
    </div>
</div>


