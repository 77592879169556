<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
    <div class="col-sm-12 row">
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate" autocomplete="off"
                        formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)" bsDatepicker
                        [bsConfig]="{ adaptivePosition: true }" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.schedularStartEndDate.errors }" required>
                    <i class="fa fa-calendar icon_innertxtbox"></i>
                    <label>Start Date</label>
                    <div *ngIf="cF.schedularStartEndDate.invalid && (cF.schedularStartEndDate.dirty || cF.schedularStartEndDate.touched)"
                        class="alert alert-danger">
                        <div *ngIf="cF.schedularStartEndDate.errors.required">
                            Start Date required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Alarm Level" name="alarmLevel" formControlName="alarmLevel" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.alarmLevel.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>Alarm Level</label>
                    <div *ngIf="cF.alarmLevel.invalid && (cF.alarmLevel.dirty || cF.alarmLevel.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.alarmLevel.errors.required">
                            Alarm Level is required
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Emergency Credit Limit" name="emergencycredit" formControlName="emergencycredit"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.emergencycredit.errors }"
                        required>
                    <i class="fa fa-credit-card-alt icon_innertxtbox"></i>
                    <label>Emergency Credit Limit</label>
                    <div *ngIf="cF.emergencycredit.invalid && (cF.emergencycredit.dirty || cF.emergencycredit.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.emergencycredit.errors.required">
                            Emergency Credit is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <!--<mat-form-field class="col-md-4 form-group input-rounded">
         <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <mat-select placeholder="Fixed Charge Deduction Type" name="deductionType" formControlName="deductionType">
            <mat-option *ngFor="let td of dedType" [value]="td.id">
              {{td.Name}}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="cF.deductionType.hasError('required')">
            Monthly Deduction is <strong>required</strong>
        </mat-error>
    </mat-form-field>-->

        <!--<mat-form-field class="col-md-4 form-group input-rounded">
         <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <mat-select placeholder="Grid Selection Type" name="eBSelectionType" formControlName="eBSelectionType" >
            <mat-option>None</mat-option>
            <mat-option value="0">Flat Rate</mat-option>
            <mat-option value="1">Slab Rate</mat-option>
        </mat-select>
        <mat-error *ngIf="cF.eBSelectionType.hasError('required')">
            Grid Selection Type is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-4 form-group input-rounded">
         <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <mat-select placeholder="DG Selection Type" name="dgSelectionType" formControlName="dgSelectionType" >
            <mat-option>None</mat-option>
            <mat-option value="0">Flat Rate</mat-option>
            <mat-option value="1">Slab Rate</mat-option>
        </mat-select>
        <mat-error *ngIf="cF.dgSelectionType.hasError('required')">
            DG Selection Type is <strong>required</strong>
        </mat-error>
    </mat-form-field>-->

        <!--<mat-form-field class="col-md-4 form-group input-rounded">
         <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <input matInput placeholder="Grid Unit Rate" name="eBFlatUnitRate" formControlName="eBFlatUnitRate">
        <mat-error *ngIf="cF.eBFlatUnitRate.hasError('required')">
            Grid Unit Rate is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-4 form-group input-rounded">
         <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <input matInput placeholder="DG Unit Rate" name="dGUnitRate" formControlName="dGUnitRate">
        <mat-error *ngIf="cF.dGUnitRate.hasError('required')">
            DG Unit Rate is <strong>required</strong>
        </mat-error>
    </mat-form-field>-->

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Fixed Charge Grid" name="fixedChargeGrid" formControlName="fixedChargeGrid"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.fixedChargeGrid.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Fixed Charge Grid</label>
                    <div *ngIf="cF.fixedChargeGrid.invalid && (cF.fixedChargeGrid.dirty || cF.fixedChargeGrid.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.fixedChargeGrid.errors.required">
                            Fixed Charge Grid is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Fixed Charge DG" name="fixedChargeDG" formControlName="fixedChargeDG"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.fixedChargeDG.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Fixed Charge DG</label>
                    <div *ngIf="cF.fixedChargeDG.invalid && (cF.fixedChargeDG.dirty || cF.fixedChargeDG.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.fixedChargeDG.errors.required">
                            Fixed Charge DG is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Vending Charge" name="vendingCharge" formControlName="vendingCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.vendingCharge.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Vending Charge</label>
                    <div *ngIf="cF.vendingCharge.invalid && (cF.vendingCharge.dirty || cF.vendingCharge.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.vendingCharge.errors.required">
                            Vending Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Common Area Maintenance Charge" name="commonAreaMaintCharge"
                        formControlName="commonAreaMaintCharge" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.commonAreaMaintCharge.errors }" required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Common Area Maintenance Charge</label>
                    <div *ngIf="cF.commonAreaMaintCharge.invalid && (cF.commonAreaMaintCharge.dirty || cF.commonAreaMaintCharge.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Common Area Maintenance Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Grid Maintenance Charge" name="gridMaintCharge"
                        formControlName="gridMaintCharge" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.gridMaintCharge.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Grid Maintenance Charge</label>
                    <div *ngIf="submitted && cF.gridMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Grid Maintenance Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="DG Maintenance Charge" name="dgMaintCharge" formControlName="dgMaintCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgMaintCharge.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Grid Unit Rate</label>
                    <div *ngIf="submitted && cF.commonAreaMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Grid Unit Rate is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Maintenance Charges" name="clubCharge" formControlName="clubCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.clubCharge.errors }" required>
                    <i class="fa fa-building icon_innertxtbox"></i>
                    <label>Maintenance Charges</label>
                    <div *ngIf="submitted && cF.commonAreaMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Maintenance Charges is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Water Charges" name="waterCharge" formControlName="waterCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.waterCharge.errors }" required>
                    <i class="fa fa-tint icon_innertxtbox"></i>
                    <label>Water Charges</label>
                    <div *ngIf="submitted && cF.waterCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.waterCharge.errors.required">
                            Water Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Other Charges" name="otherCharge" formControlName="otherCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.otherCharge.errors }" required>
                    <i class="fa fa-building icon_innertxtbox"></i>
                    <label>Other Charges</label>
                    <div *ngIf="submitted && cF.otherCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.otherCharge.errors.required">
                            Other Charges is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Service Tax" name="serviceTax" formControlName="serviceTax" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.serviceTax.errors }" required>
                    <i class="fa fa-inr icon_innertxtbox"></i>
                    <label>Service Tax</label>
                    <div *ngIf="submitted && cF.serviceTax.errors" class="invalid-feedback">
                        <div *ngIf="cF.serviceTax.errors.required">
                            Service Tax is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <!--<mat-form-field class="col-md-4 form-group input-rounded">
        <span matPrefix class="d-c6"> <i class="fa fa-inr"></i>&nbsp;</span>
        <input matInput placeholder="Service Tax" name="serviceTax" formControlName="serviceTax">
        <mat-error *ngIf="cF.serviceTax.hasError('required')">
            Service Tax is <strong>required</strong>
        </mat-error>
    </mat-form-field>-->

    <fieldset class="scheduler-border">
        <legend class="scheduler-border">
            Slab Configuration
        </legend>


        <div class="col-sm-12 row">
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU1" name="ebSlabStartUnit1" formControlName="ebSlabStartUnit1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit1.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU1</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit1.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit1.errors.required">
                                EB Slab1 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU1" name="ebSlabEndUnit1" formControlName="ebSlabEndUnit1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit1.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU1</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit1.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit1.errors.required">
                                EB Slab1 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate" name="unitRate1" formControlName="unitRate1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate1.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate</label>
                        <div *ngIf="submitted && cF.unitRate1.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate1.errors.required">
                                EB Unit Rate 1 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU1" name="dgSlabStartUnit1" formControlName="dgSlabStartUnit1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit1.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU1</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit1.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit1.errors.required">
                                DG Slab1 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU1" name="dgSlabEndUnit1" formControlName="dgSlabEndUnit1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit1.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU1</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit1.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit1.errors.required">
                                DG Slab1 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate" name="unitRateDG1" formControlName="unitRateDG1"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG1.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate</label>
                        <div *ngIf="submitted && cF.unitRateDG1.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG1.errors.required">
                                DG Unit Rate 1 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU1" name="ebSlabStartUnit2" formControlName="ebSlabStartUnit2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit2.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU2</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit2.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit2.errors.required">
                                EB Slab2 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU2" name="ebSlabEndUnit2" formControlName="ebSlabEndUnit2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit2.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU2</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit2.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit2.errors.required">
                                EB Slab2 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate" name="unitRate2" formControlName="unitRate2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate2.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate 2</label>
                        <div *ngIf="submitted && cF.unitRate2.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate2.errors.required">
                                EB Unit Rate 2 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU2" name="dgSlabStartUnit2" formControlName="dgSlabStartUnit2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit2.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU2</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit2.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit2.errors.required">
                                DG Slab2 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU2" name="dgSlabEndUnit2" formControlName="dgSlabEndUnit2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit2.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU2</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit2.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit2.errors.required">
                                DG Slab2 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate" name="unitRateDG2" formControlName="unitRateDG2"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG2.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate</label>
                        <div *ngIf="submitted && cF.unitRateDG2.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG2.errors.required">
                                DG Unit Rate 2 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU3" name="ebSlabStartUnit3" formControlName="ebSlabStartUnit3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit3.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU3</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit3.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit3.errors.required">
                                EB Slab3 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU3" name="ebSlabEndUnit3" formControlName="ebSlabEndUnit3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit3.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU3</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit3.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit3.errors.required">
                                EB Slab3 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate 3" name="unitRate3" formControlName="unitRate3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate3.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate 3</label>
                        <div *ngIf="submitted && cF.unitRate3.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate3.errors.required">
                                EB Unit Rate 3 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU3" name="dgSlabStartUnit3" formControlName="dgSlabStartUnit3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit3.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU3</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit3.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit3.errors.required">
                                DG Slab3 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU3" name="dgSlabEndUnit3" formControlName="dgSlabEndUnit3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit3.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU3</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit3.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit3.errors.required">
                                DG Slab3 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate3" name="unitRateDG3" formControlName="unitRateDG3"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG3.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate3</label>
                        <div *ngIf="submitted && cF.unitRateDG3.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG3.errors.required">
                                DG Unit Rate 3 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU4" name="ebSlabStartUnit4" formControlName="ebSlabStartUnit4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit4.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU4</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit4.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit4.errors.required">
                                EB Slab4 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU4" name="ebSlabEndUnit4" formControlName="ebSlabEndUnit4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit4.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU4</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit4.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit4.errors.required">
                                EB Slab4 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate 4" name="unitRate4" formControlName="unitRate4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate4.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate 4</label>
                        <div *ngIf="submitted && cF.unitRate4.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate4.errors.required">
                                EB Unit Rate 4 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU4" name="dgSlabStartUnit4" formControlName="dgSlabStartUnit4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit4.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU4</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit4.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit4.errors.required">
                                DG Slab4 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU4" name="dgSlabEndUnit4" formControlName="dgSlabEndUnit4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit4.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU4</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit4.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit4.errors.required">
                                DG Slab4 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate4" name="unitRateDG4" formControlName="unitRateDG4"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG4.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate4</label>
                        <div *ngIf="submitted && cF.unitRateDG4.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG4.errors.required">
                                DG Unit Rate 4 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU5" name="ebSlabStartUnit5" formControlName="ebSlabStartUnit5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit5.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU5</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit5.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit5.errors.required">
                                EB Slab5 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU5" name="ebSlabEndUnit5" formControlName="ebSlabEndUnit5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit5.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU5</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit5.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit5.errors.required">
                                EB Slab5 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate5" name="unitRate5" formControlName="unitRate5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate5.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate5</label>
                        <div *ngIf="submitted && cF.unitRate5.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate5.errors.required">
                                EB Unit Rate 5 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU5" name="dgSlabStartUnit5" formControlName="dgSlabStartUnit5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit5.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU5</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit5.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit5.errors.required">
                                DG Slab5 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU5" name="dgSlabEndUnit5" formControlName="dgSlabEndUnit5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit5.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU5</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit5.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit5.errors.required">
                                DG Slab5 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate5" name="unitRateDG5" formControlName="unitRateDG5"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG5.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate5</label>
                        <div *ngIf="submitted && cF.unitRateDG5.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG5.errors.required">
                                DG Unit Rate 5 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU6" name="ebSlabStartUnit6" formControlName="ebSlabStartUnit6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit6.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU6</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit6.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit6.errors.required">
                                EB Slab6 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU6" name="ebSlabEndUnit6" formControlName="ebSlabEndUnit6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit6.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU6</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit6.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit6.errors.required">
                                EB Slab6 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate6" name="unitRate6" formControlName="unitRate6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate6.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate6</label>
                        <div *ngIf="submitted && cF.unitRate6.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate6.errors.required">
                                EB Unit Rate 6 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU6" name="dgSlabStartUnit6" formControlName="dgSlabStartUnit6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit6.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU6</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit6.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit6.errors.required">
                                DG Slab6 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU6" name="dgSlabEndUnit6" formControlName="dgSlabEndUnit6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit6.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU6</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit6.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit6.errors.required">
                                DG Slab6 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate6" name="unitRateDG6" formControlName="unitRateDG6"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG6.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate6</label>
                        <div *ngIf="submitted && cF.unitRateDG6.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG6.errors.required">
                                DG Unit Rate 6 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU7" name="ebSlabStartUnit7" formControlName="ebSlabStartUnit7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit7.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU7</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit7.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit7.errors.required">
                                EB Slab7 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU7" name="ebSlabEndUnit7" formControlName="ebSlabEndUnit7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit7.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU7</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit7.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit7.errors.required">
                                EB Slab7 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate7" name="unitRate7" formControlName="unitRate7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate7.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate7</label>
                        <div *ngIf="submitted && cF.unitRate7.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate7.errors.required">
                                EB Unit Rate 7 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU7" name="dgSlabStartUnit7" formControlName="dgSlabStartUnit7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit7.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU7</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit7.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit7.errors.required">
                                DG Slab7 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU7" name="dgSlabEndUnit7" formControlName="dgSlabEndUnit7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit7.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU7</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit7.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit7.errors.required">
                                DG Slab7 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate7" name="unitRateDG7" formControlName="unitRateDG7"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG7.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate7</label>
                        <div *ngIf="submitted && cF.unitRateDG7.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG7.errors.required">
                                DG Unit Rate 7 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SSU8" name="ebSlabStartUnit8" formControlName="ebSlabStartUnit8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabStartUnit8.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SSU8</label>
                        <div *ngIf="submitted && cF.ebSlabStartUnit8.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabStartUnit8.errors.required">
                                EB Slab8 Start Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB SEU8" name="ebSlabEndUnit8" formControlName="ebSlabEndUnit8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ebSlabEndUnit8.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>EB SEU8</label>
                        <div *ngIf="submitted && cF.ebSlabEndUnit8.errors" class="invalid-feedback">
                            <div *ngIf="cF.ebSlabEndUnit8.errors.required">
                                EB Slab8 End Unit required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="EB Unit Rate8" name="unitRate8" formControlName="unitRate8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRate8.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>EB Unit Rate8</label>
                        <div *ngIf="submitted && cF.unitRate8.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRate8.errors.required">
                                EB Unit Rate 8 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SSU8" name="dgSlabStartUnit8" formControlName="dgSlabStartUnit8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabStartUnit8.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SSU8</label>
                        <div *ngIf="submitted && cF.dgSlabStartUnit8.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabStartUnit8.errors.required">
                                DG Slab8 Start Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG SEU8" name="dgSlabEndUnit8" formControlName="dgSlabEndUnit8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgSlabEndUnit8.errors }"
                            required>
                        <i class="fa fa-bolt icon_innertxtbox"></i>
                        <label>DG SEU8</label>
                        <div *ngIf="submitted && cF.dgSlabEndUnit8.errors" class="invalid-feedback">
                            <div *ngIf="cF.dgSlabEndUnit8.errors.required">
                                DG Slab8 End Unit is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input placeholder="DG Unit Rate8" name="unitRateDG8" formControlName="unitRateDG8"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.unitRateDG8.errors }"
                            required>
                        <i class="fa fa-inr icon_innertxtbox"></i>
                        <label>DG Unit Rate8</label>
                        <div *ngIf="submitted && cF.unitRateDG8.errors" class="invalid-feedback">
                            <div *ngIf="cF.unitRateDG8.errors.required">
                                DG Unit Rate 8 is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </fieldset>

    <div class="row">
        <div class="col-sm-12 text-left">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>
            <!--<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="message.length > 0" >
              <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon>{{message}}
            </div>-->
            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-12 text-right">
            <button type="submit" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
            <!--<button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon >cancel</mat-icon>
            Exit
          </button>-->
        </div>
    </div>
    <!--
<button mat-raised-button color="primary" type="submit">Add</button>-->
</form>