<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Recent SMS Sent: {{meterDetail.mID}}
    </h4>
  </div>
      <div class="modal-body">
        <ng-container>
          <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>

          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
            <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Consumer Name
                  </div>
                  </td>
                  <td>
                    <div class="d_title">
                      <span></span>Mobile No
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Sent Status
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>SMS Transaction ID
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Sent Date & Time
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Sent Message
                  </div>
                  </td>
                  
                </tr>
              </thead>
              <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.consumerName}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.mobileNo}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.sendingStatus}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.tranId}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.sentDateTime}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W1">
      <p>{{item.message}}</p>
  </div>
  </td>
</tr>
</tbody>
</table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>          
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>--> 
          </div>

          <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
            <div class="col-sm-12">
              <img class="center" src="assets/images/Nodata.png">
            </div>
           <!-- <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
          </div>
          
        </ng-container>
    <ng-container col-sm-3>
    </ng-container>
</div>
</div>
  