import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../../_services';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
declare var $;

class DataSource {
  aID: string
  cID: string
  sID: string
  uID: string
  ciID: string
  dID: string
  sdID: string
  authFullName: string
  authUserName: string
  authEmail: string
  authMobileId: string
  authHomeAdd1: string
  authHomeAdd2: string
  authOffAdd1: string
  authOffAdd2: string
  authActiveStatus: string
  authDOB: string
}

class DataTablesResponse {
  aaData: any[];
  draw: number;
  iTotalDisplayRecords: number;
  iTotalRecords: number;
}
@Component({
  selector: 'app-authinfo',
  templateUrl: './authinfo.component.html',
  styleUrls: ['./authinfo.component.scss']
})
export class AuthinfoComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  getThroughSubject: Subscription;
  messageResult: any;
  allAutoritysData: any = {};
  loading: boolean = true;
  selectedSubDivision: any;
  faSpinner = faSpinner;

  dataSource: any = new MatTableDataSource([]);
  loadingMessage: any = '';
  responseData: any;
  displayedColumns: string[] = [
    'aID',
    'cID',
    'sID',
    'uID',
    'ciID',
    'dID',
    'sdID',
    'authFullName',
    'authUserName',
    'authEmail',
    'authMobileId',
    'authHomeAdd1',
    'authHomeAdd2',
    'authOffAdd1',
    'authOffAdd2',
    'authActiveStatus',
    'authDOB'
  ];

  limit: number = 10;
  skip: number = 0;
  filter = '';

  // MatPaginator Inputs
  length: number = 0;
  pageSize: number = 10;  //displaying three cards each row
  pageSizeOptions: number[] = [5, 10, 15];

  search: string = '';
  dateVal: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private router: Router
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    //that.http.post<DataTablesResponse>(this.apiPath+'/reports/getAuthLogins', {}
  }

  ngAfterViewInit() {
    this.getTerifListing();
  }

  onSearchChange(searchValue: string): void {
    this.search = searchValue;
    this.getTerifListing(false);
  }
  onDateChange(event): void {
    //console.log(event);
    let month = event.value.getMonth() + 1; //always move 1 value up
    let day = event.value.getDate();
    let year = event.value.getFullYear();
    this.dateVal = year + '-' + month + '-' + day;
    this.skip = 0;
    this.getTerifListing(false);
  }

  rerender(): void {
  }

  getTerifListing(isloading: boolean = true) {
    this.loading = isloading;
    let body = new URLSearchParams();
    body.set('start', this.skip.toString());
    body.set('length', this.limit.toString());
    body.set('search', this.search);
    body.set('dob', this.dateVal);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/reports/getAuthLogins', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      if (parseInt(this.responseData.iTotalRecords) > 0) {
        this.dataSource = this.responseData.aaData;
        this.length = this.responseData.iTotalRecords;
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  //To change DCU table length
  changePage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getTerifListing();
    } else {
      if (this.length > 0) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.getTerifListing();
        // }
      }
    }
  }

  //to download pdf
  downloadPdf() {
    let body = new URLSearchParams();
    body.set('start', this.skip.toString());
    body.set('length', this.limit.toString());
    body.set('search', this.search);
    body.set('dob', this.dateVal);
    body.set('pdf', '1');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/reports/getAuthLogins/pdf', body).subscribe((result) => {
      this.responseData = result;
      //console.log(this.responseData);
    });
  }

  //to download csv
  downloadCsv() {

  }

}
