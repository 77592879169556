import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { AuthenticationService, generalRequestsService } from '../../_services';

@Component({
  selector: 'app-authority-contactus',
  templateUrl: './authority-contactus.component.html',
  styleUrls: ['./authority-contactus.component.scss']
})
export class AuthorityContactusComponent implements OnInit {
  submitted: boolean = false;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  isLoading:boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    
    this.addForm = this.formBuilder.group({
      name: [{ value: this.currentUser.data.userData.name, disabled: true }, Validators.required],
      email: [{ value: this.currentUser.data.userData.email, disabled: true }, [Validators.required, Validators.email]],
      phone: [{ value: this.currentUser.data.userData.mobile, disabled: true },this.currentUser.data.userData.mobile, [Validators.required,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      msg: ['', Validators.required]
      });
  }

  get aF() { return this.addForm.controls; }
  onSubmitAddForm(){
     if (this.addForm.invalid) {
      this.submitted = true;
       return;
     }
     let body = new URLSearchParams();
     this.isLoading = true;
     body.set('name', this.aF.name.value);
     body.set('lastName', this.aF.name.value);
     body.set('emailId', this.aF.email.value);
     body.set('phoneNo', this.aF.phone.value);
     body.set('message', this.aF.msg.value);
     body.set('authToken', this.currentUser.authToken);
     this.AddProcess = true;
     this.genReqs.postReq('/addContactUs', body).subscribe((result)=>{
     this.responseData = result;
     this.AddProcess = false;
     this.isLoading = false;
     this.responseMessage = this.responseData.data.message;
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.AddProcess = false;
     this.responseMessage = 'Unable to process yours request!';
   });
   }
}
