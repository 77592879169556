<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
 <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
       Add Group
    </h4>
  </div>
  <div class="modal-body">
      <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
      <div class="form-groupp">
          <!-- Grid row -->
          <div class="row">
            <!-- Default input -->
            <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="DG Name" name="dgNameData" formControlName="dgNameData"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgNameData.errors}" required>
                <mat-option *ngFor="let dg of dgData" [value]="dg.dgId">
                  {{dg.dgName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label> DG Name</label>
              <div *ngIf="submitted && aF.dgNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.dgNameData.errors.required">
                  DG Name is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Tower..." name="towerNameData" formControlName="towerNameData"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.towerNameData.errors}" required>
                <mat-option *ngFor="let td of towerData" [value]="td.towerId">
                  {{td.towerName}}
                </mat-option>
              </mat-select>
              
              <i class="fa fa-building icon_innertxtbox"></i>
                <label> Tower Name</label>
                <div *ngIf="submitted && aF.towerNameData.errors" class="invalid-feedback">
                  <div *ngIf="aF.towerNameData.errors.required">
                 Tower Name is required
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input  placeholder="Group Name" name="groupName" formControlName="groupName"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.groupName.errors}" required>
                  <i class="fa fa-users icon_innertxtbox"></i>
                  <label> Group Name</label>
                  <div *ngIf="submitted && aF.groupName.errors" class="invalid-feedback">
                    <div *ngIf="aF.groupName.errors.required">
                      Group Name is required
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
  
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>
      </div>
  </div>
  </form>