<!-- <div class="page-content-wrapper" style="margin-top:0;">
    <div class="page-content-wrapper-inner">
      <div class="content-viewport">
        <div class="row py-3 headerArea commonClass">
          <div class="col-12">
              <h4>Dashboard</h4>
              <p class="text-white">Welcome, {{currentUser.data.userData.name | titlecase}}</p>
          </div>
      </div>
      <div class="row commonClass">
        <mat-toolbar class="subheaderArea h-40 fs-14">
          <span class="toolbar-space"></span>
          <button mat-icon-button color="primary">
              <mat-icon>search</mat-icon>
          </button>&nbsp;&nbsp;
          <button mat-icon-button color="primary" (click)="open(addcountry)">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>
    </div>
        <div class="row inner-page-content">
          <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys" >
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x"></fa-icon>
          </ng-container>
          <ng-container *ngIf="allutilitys" col-sm-3>
              <mat-grid-list style="width: 100%;" [cols]="breakpoint" (window:resize)="onResize($event)" rowHeight="3:3" >
                <mat-grid-tile *ngFor="let label of allutilitys; index as i;">
                  <mat-card class="card cardMarginRight folderView" >
                      <mat-card-content mat-card-image>
                      <div class="circular"> 
                          <img src="{{label.img_address}}" *ngIf="label.img_address"/>
                          <fa *ngIf="!label.img_address"  name="folder" style="color:#84ccff;" mat-card-lg-image></fa>
                        </div>
                        <mat-icon mat-button class="pointer cardAction" (click)="countryEditView(label, listmodify)">more_vert</mat-icon>
                      </mat-card-content>
                      <mat-card-footer (click)="goToSubDivisionView(label.sub_dev_id)">
                          <div class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;" mat-button>
                              <div class="fullbutton">
                                  <span >{{label.sub_dev_name}}</span>
                                  <mat-icon class="pointer">keyboard_arrow_right</mat-icon>
                              </div>
                            </div>
                      </mat-card-footer>
                  </mat-card>
                </mat-grid-tile>
              </mat-grid-list>
          </ng-container>
          <ng-container *ngIf="!allutilitys" col-sm-3>
            {{messageUtilitys}}
          </ng-container>
      </div>
      </div>
    </div>
    </div>
    <ng-template #addcountry let-modal>
      <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Sub-Division</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="modal-body">
              <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
              <div class="form-group">
                  <div class="form-row">
                    <mat-form-field class="col-md-6">
                      <input matInput placeholder="Sub-Division Code" name="Code" formControlName="Code" >
                      <mat-error *ngIf="aF.Code.hasError('required')">
                          Sub-Division Code is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
          
                    <mat-form-field class="col-md-6">
                      <input matInput placeholder="Sub-Division Name" name="Name" formControlName="Name">
                      <mat-error *ngIf="aF.Name.hasError('required')">
                          Sub-Division Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    <div class="col-md-6">
                        <input type="file" name="Image" (change)="onFileSelect($event)" />
                      <div *ngIf="aF.Image.hasError('required')">
                          Sub-Division image is <strong>required</strong>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                      <div class="col-12 text-right">
                          <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                        <button type="submit" mat-button color="primary" class="text-uppercase">Add</button>
                  </div>
              </div>
          </div>
          </form>
</ng-template>

<ng-template #listmodify let-modal>
    <form [formGroup]="editForm" (ngSubmit)="onSubmitEditForm()">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Sub Division: {{EditName}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
            <div class="form-group">
                <div class="form-row">
                  <mat-form-field class="col-md-6">
                    <input matInput placeholder="Division Code" name="Code" formControlName="Code" >
                    <mat-error *ngIf="eF.Code.hasError('required')">
                        Sub-Division Code is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="col-md-6">
                    <input matInput placeholder="Division Name" name="Name" formControlName="Name">
                    <mat-error *ngIf="eF.Name.hasError('required')">
                        Sub-Division Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                   <div class="col-md-6">
                      <input type="file" name="Image" (change)="onFileSelectEdit($event)" />
                    <div *ngIf="eF.Image.hasError('required')">
                        Sub-Division image is <strong>required</strong>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                    <div class="col-12 text-right">
                      <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                      <button type="submit" mat-button color="primary" class="text-uppercase">Update</button>
                </div>
            </div>
        </div>
      </form>
</ng-template> -->

<div class="container-fluid innerpage_top innerpage_top_graphic">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH_paginationn">
        <div class="">
          <div class="">
            <div class="row">
                <div class="col-md-5 col-sm-12">
                  <h1>Home<span class="info_icntop ml-1">
                        <i data-toggle="tooltip" data-placement="top" title="Information of all data" class="fa fa-info tabula-tooltip "></i>
                    </span>
                  </h1>
                  <p class="p-2">Welcome, {{currentUser.data.userData.name | titlecase}}</p>
                </div>
                <div class="col-md-7 col-sm-12">
                  <div class="flex_auth">
                    <div class="authsearch">
                      <div class="search">
                        <input class="search-txt searchplaceholder"  type="text" placeholder="Search by Username/Email/Mobile No"
                        autocomplete="off">
                        <div class="search-btn nobg">
                            <i class="fa fa-search"></i>
                        </div>
                      </div>
                    </div>
                    <button  class="editbordr_circlttop" (click)="open(addcountry)">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid inner-page-content">
            <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys" >
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>

            <div class="row t-3 mb-3">
              <div class="col-md-2">
                 <div class="border_cstm"> 
                  <div>
                    <div class="spacer ">
                      <div class="avatar-circlee">
                        <div class="circular">
                          <img  src="https://api.myavonlive.com/img/country/Argentina.png">
                        </div>
                      </div>
                      <!-- <mat-icon class="pointer cardAction ditbordr" class="editbordr" 
                      (click)="countryEditView(label, listmodify)" [matMenuTriggerFor]="dcuListMenu">menu_open</mat-icon>
                    -->
                      <!-- <mat-menu #dcuListMenu="matMenu" xPosition="before" class="authorityListMenu 
                        editbordrr gridinnerpagee mat-menu-panellall">
                          <mat-list role="list" class="editbordrr">
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="countryEditView(label, listmodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c5 authall">edit</mat-icon> Modify
                              </button>
                      </mat-list-item>

                      <mat-list-item role="listitem">
                        <button type="button" mat-button (click)="removelevel(label)"
                          class="btn-block text-left">
                          <mat-icon class="d-c5 authall">delete</mat-icon> Delete
                        </button>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu> -->
                      <!-- <mat-card-footer (click)="goToSubDivisionView(label.sub_dev_id)">
                          <div class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;" mat-button>
                    <div class="fullbutton">
                            <span>countName</span>
                          <mat-icon class="icons">keyboard_arrow_right</mat-icon>
                        </div>
                    </div>
                  </mat-card-footer> -->
                </div>
              </div>
          
          </div>
          <ng-container *ngIf="!allutilitys">
            {{messageUtilitys}}
          </ng-container>
        </div>
       
      </div>
    </div>
  </div>
</div>
<ng-template #addcountry let-modal>
  <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
          data-dismiss="modal">&times;</button>
          Add Sub-Division
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
          <div class="form-group">
            <div class="row">
             <div class="col-md-6">
              <div class="form-group input-group">
                <span class="has-float-label">
                <input  placeholder="Sub-Division Code" name="Code" formControlName="Code" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.stateCode.errors }" required>
                 <i class="fa fa-globe icon_innertxtbox"></i>
                <label>State Code</label>
                <div *ngIf="submitted && aF.Code.errors" class="invalid-feedback">
                  <div *ngIf="aF.Code.errors.required">
                    Sub-Division Code is required
                  </div>
                </div>
              </span>
            </div>
          </div>
           <div class="col-md-6">
              <div class="form-group input-group">
               <span class="has-float-label">
                <input  placeholder="Sub-Division Name" name="Name" formControlName="Name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.Name.errors }" required>
                <i class="fa fa-globe icon_innertxtbox"></i>
                <label>State Code</label>
                <div *ngIf="submitted && aF.Name.errors" class="invalid-feedback">
                  <div *ngIf="aF.Name.errors.required">
                    Sub-Division Name is required
                  </div>
                </div>
              </span>
            </div>
          </div>
        
              <div class="col-md-6">
                <input type="file" name="Image" (change)="onFileSelect($event)" />
                <div *ngIf="aF.Image.hasError('required')">
                    Sub-Division image is <strong>required</strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
              <div class="col-12 text-right">
                <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                <button type="submit" mat-button color="primary" class="text-uppercase">Add</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>

<ng-template #listmodify let-modal>
<form [formGroup]="editForm" (ngSubmit)="onSubmitEditForm()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Sub Division: {{EditName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
        <div class="form-group">
            <div class="form-row">
              <mat-form-field class="col-md-6">
                <input matInput placeholder="Division Code" name="Code" formControlName="Code" >
                <mat-error *ngIf="eF.Code.hasError('required')">
                    Sub-Division Code is <strong>required</strong>
                </mat-error>
              </mat-form-field>
    
              <mat-form-field class="col-md-6">
                <input matInput placeholder="Division Name" name="Name" formControlName="Name">
                <mat-error *ngIf="eF.Name.hasError('required')">
                    Sub-Division Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
                <div class="col-md-6">
                  <input type="file" name="Image" (change)="onFileSelectEdit($event)" />
                <div *ngIf="eF.Image.hasError('required')">
                    Sub-Division image is <strong>required</strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
                <div class="col-12 text-right">
                  <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                  <button type="submit" mat-button color="primary" class="text-uppercase">Update</button>
            </div>
        </div>
    </div>
  </form>
</ng-template>