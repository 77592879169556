import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addmeterrange',
  templateUrl: './addmeterrange.component.html',
  styleUrls: ['./addmeterrange.component.scss']
})
export class AddmeterrangeComponent implements OnInit {
  faSpinner = faSpinner;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';

  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  submitted: boolean = false;
 /* deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "EB"},
    {deviceType: "DG"}
  ];*/

  deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "LTCT"}
    
  ];
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      prefix: ['', Validators.required],
      fromRange: ['', Validators.required],
      toRange: ['', Validators.required],
      deviceType: ['', Validators.required],
      specificId: [''],
      comment: ['']
    });
  }
  ngAfterViewInit() {
     
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.subDivisionId);

      body.set('prefix', this.aF.prefix.value);
      body.set('fromRange', this.aF.fromRange.value);
      body.set('toRange', this.aF.toRange.value);
      body.set('deviceType', this.aF.deviceType.value);
      body.set('specificId', this.aF.specificId.value);
      body.set('comment', this.aF.comment.value);

      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addMeterRangeData', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
           
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                if(this.addAuthMessage.includes("Not Added")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}

