import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-authority-pnb',
  templateUrl: './edit-authority-pnb.component.html',
  styleUrls: ['./edit-authority-pnb.component.scss']
})
export class EditAuthorityPnbComponent implements OnInit {

  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority: any;
  assignedData = {};
  authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);
  isLoading:boolean = false;
  thumb:boolean = false;
  submitted: boolean = false;
  status: boolean = false;
  authLevel_sel: any = '';
  message: any = '';

  public authLevelData: any = [];
  public countryLevelData: any = [];
  public stateLevelData: any = [];
  public utilityLevelData: any = [];
  public circleLevelData: any = [];
  public divisionLevelData: any = [];
  public subdivisionLevelData: any = [];
  public dcuLevelData: any = [];

  ///multiselect
  authMulti1: boolean = false;
  authMulti2: boolean = false;
  authMulti3: boolean = false;
  authMulti4: boolean = false;
  authMulti5: boolean = false;
  authMulti6: boolean = false;
  authMulti7: boolean = false;
  authMulti8: boolean = false;
  zoneData: any = [];
  circleData: any = [];
  divisionData: any = [];
  subDivData: any = [];
  date:any;
  constructor(
      private formBuilder: UntypedFormBuilder, 
      private modalService: NgbModal,
      public modal: NgbActiveModal,
      private genReqs:generalRequestsService,
      private authenticationService: AuthenticationService,
    ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }
  

  ngOnInit() {
      let MOBILE_PATTERN = /[0-9\+\-\ ]/;
      this.addAuthorityForm = this.formBuilder.group({
          authType: ['', Validators.required],
          authFullName: ['', Validators.required],
          authUserName: ['', Validators.required],
          authPassword: ['', Validators.required],
          authEmailId: ['', [Validators.required, Validators.email]],
          authMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
          zone: ['', Validators.required],
          circle: [''],
          division: ['', Validators.required],
          sub_division: [''],
          authorityActiveStatus: ['', Validators.required],
          authorityDOB: ['', Validators.required]
      });
      this.preSetUserData();
      this.getZoneData();
     
  }

  ngAfterViewInit() {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
  }

  ngOnDestroy() {
      //this.modal.close();
  }

 
  get aD() { return this.addAuthorityForm.controls; }

  getZoneData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbZoneData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.zoneData = this.responseData.data;
        this.getCircleData();
        // console.log("leveldata", this.authLevelData);

      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getCircleData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbCircleData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.circleData = this.responseData.data;
        this.getDivisionData();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getDivisionData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbDivisionData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.divisionData = this.responseData.data;
        this.getSubDivisionData();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getSubDivisionData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('divisionId',  this.aD.division.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbsubDivData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.subDivData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }


  onSubmitAddAuthority() {
      
      if (this.addAuthorityForm.invalid) {
          this.submitted = true;

          return;
      }
      this.isLoading = true;
      this.AddProcess = true;
      let body = new URLSearchParams();
     
      body.set('authId', this.authority.aID);
      body.set('subdivId', this.authority.sdID);
      body.set('authFullName', this.aD.authFullName.value);
      body.set('authUserName', this.aD.authUserName.value);
      body.set('authPassword', this.aD.authPassword.value);
      body.set('authEmailId', this.aD.authEmailId.value);
      body.set('authMobileNo', this.aD.authMobileNo.value);
      body.set('zoneId', this.aD.zone.value);
      body.set('circleId', this.aD.circle.value);
      body.set('divisionId', this.aD.division.value);
      body.set('subDivId', this.aD.sub_division.value);
      body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
      body.set('authType', this.aD.authType.value);
      body.set('authorityDOB',  this.convert(this.aD.authorityDOB.value));
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateAuthInfoPnb', body).subscribe((result) => {
          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          if (this.responseData.success) {
              this.thumb = true;
              this.message = this.responseData.message;
              setTimeout(() => 
              {
              this.modal.close('done');
              },
              2000);
          }else{
              this.thumb = false;
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.message = 'Unable to process yours request!';
      });
  }

  convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    
  getAuthorityDetails() {
      let body = new URLSearchParams();
      body.set('authId', this.authority.aID);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getAuthorities', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
              this.authority = this.responseData.data[0];
              //this.assignedData = this.responseData.data['assignedData'];
             //this.authLevel_sel = this.assignedData['authLevel'];
              this.preSetUserData();
              
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.addAuthMessage = 'Unable to process yours request!';
      });
  }

  preSetUserData() {
      //console.log("authority",this.authority);
      this.aD.authPassword.setValue(this.authority.authPassword);
      this.aD.authorityActiveStatus.setValue(this.authority.authActiveStatus);
      this.aD.authorityDOB.setValue(this.formateDate(this.authority.authDOB));
      this.aD.authEmailId.setValue(this.authority.authEmail);
      this.aD.authFullName.setValue(this.authority.authFullName);
      this.aD.zone.setValue(this.authority.zoneId);
      this.aD.circle.setValue(this.authority.circleId);
      this.aD.authMobileNo.setValue(this.authority.authMobileId);
      this.aD.division.setValue(this.authority.divisionId);
      this.aD.sub_division.setValue(this.authority.subDivisionId);
      this.aD.authUserName.setValue(this.authority.authUserName);
      this.aD.authType.setValue(this.authority.authType);
  }

  formateDate(datevalue) {
      let date = new Date(datevalue);
      return (date.getMonth()+1)+'/'+(date.getDate()+1)+'/'+date.getFullYear();
    }

  defaultCountry() {
      this.countryLevelData = [{
          id: '0',
          text: "Select Country"
      }];
      this.loadCountryData();
  }
  defaultState() {
      this.stateLevelData = [
          {
              id: '0',
              text: "Select State"
          }];
  }
  defaultUtility() {
      this.utilityLevelData = [
          {
              id: '0',
              text: "Select Utility"
          }];
  }

  //default circle
  defaultCircle() {
      this.circleLevelData = [
          {
              id: '0',
              text: "Select Circle"
          }];
  }

  //default division
  defaultDivision() {
      this.divisionLevelData = [
          {
              id: '0',
              text: "Select Division"
          }];
  }

  //default sub division
  defaultSubDivision() {
      this.subdivisionLevelData = [
          {
              id: '0',
              text: "Select Sub-Division"
          }];
  }

  //default DCU
  defaultDcu() {
      this.dcuLevelData = [
          {
              id: '0',
              text: "Select DCU"
          }];
  }

  authLevelChanged(e) {
      this.authMulti1 = false;
      this.authMulti2 = false;
      this.authMulti3 = false;
      this.authMulti4 = false;
      this.authMulti5 = false;
      this.authMulti6 = false;
      this.authMulti7 = false;
      this.authMulti8 = false;
      if (e.value == 1) {
          this.authMulti1 = true;
      }
      if (e.value == 2) {
          this.authMulti2 = true;
      }
      if (e.value == 3) {
          this.authMulti3 = true;
      }
      if (e.value == 4) {
          this.authMulti4 = true;
      }
      if (e.value == 5) {
          this.authMulti5 = true;
      }
      if (e.value == 6) {
          this.authMulti6 = true;
      }
      if (e.value == 7) {
          this.authMulti7 = true;
      }
      if (e.value == 8) {
          this.authMulti8 = true;
      }
      //reset all values
      this.defaultCountry();
      this.defaultState();
      this.defaultUtility();
      this.defaultCircle();
      this.defaultDivision();
      this.defaultSubDivision();
      this.defaultDcu();
  }

  loadCountryData() {
      this.genReqs.genReq('/getCountry').then((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.countryLevelData = [...this.countryLevelData,
                  {
                      id: item.country_id,
                      text: item.countName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  countrySelect() {
      this.defaultState();
      let body = new URLSearchParams();
      //body.set('country', e.value.toString());
      body.set('country', this.aD.countId.value);
      this.genReqs.postReq('/getState', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.stateLevelData = [...this.stateLevelData,
                  {
                      id: item.state_id,
                      text: item.stateName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  stateSelect() {
      this.defaultUtility();
      let body = new URLSearchParams();
      body.set('country', this.aD.countId.value);
      body.set('state', this.aD.stateId.value);
      this.genReqs.postReq('/getUtility', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.utilityLevelData = [...this.utilityLevelData,
                  {
                      id: item.utility_id,
                      text: item.utilityName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  utilitySelect() {
      this.defaultCircle();
      let body = new URLSearchParams();
      body.set('country', this.aD.countId.value);
      body.set('state', this.aD.stateId.value);
      body.set('utility', this.aD.utilityId.value);
      this.genReqs.postReq('/getCircle', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.circleLevelData = [...this.circleLevelData,
                  {
                      id: item.circle_id,
                      text: item.circleName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  circleSelect() {
      this.defaultDivision();
      let body = new URLSearchParams();
      body.set('country', this.aD.countId.value);
      body.set('state', this.aD.stateId.value);
      body.set('utility', this.aD.utilityId.value);
      body.set('utility', this.aD.utilityId.value);
      body.set('circle', this.aD.circleId.value);
      this.genReqs.postReq('/getDivision', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.divisionLevelData = [...this.divisionLevelData,
                  {
                      id: item.division_id,
                      text: item.divisionName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }
  divisionSelect() {
      this.defaultSubDivision();
      let body = new URLSearchParams();
      body.set('country', this.aD.countId.value);
      body.set('state', this.aD.stateId.value);
      body.set('utility', this.aD.utilityId.value);
      body.set('utility', this.aD.utilityId.value);
      body.set('circle', this.aD.circleId.value);
      body.set('division', this.aD.devisionId.value);
      this.genReqs.postReq('/getSubDivision', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.subdivisionLevelData = [...this.subdivisionLevelData,
                  {
                      id: item.sub_dev_id,
                      text: item.sub_dev_name
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  subdivisionSelect() {
      this.defaultDcu();
      let body = new URLSearchParams();
      body.set('subDivisionId', this.aD.subdivId.value);
      body.set('authToken', this.currentUser.authToken);
      body.set('offSet', '0');
      body.set('limit', '100000');
      this.genReqs.postReq('/getDCU', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success && this.responseData.data.length > 0) {
              for (let item of this.responseData.data) {
                  this.dcuLevelData = [...this.dcuLevelData,
                  {
                      id: item.dcuID,
                      text: item.dcuName
                  }];
              }
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  getDOB(pickedDate = new Date()) {
      if (pickedDate) {
          let emonth = pickedDate.getMonth() + 1; //always move 1 value up
          let eday = pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.authorityDOB = eyear + '-' + emonth + '-' + eday;
          
      }
  }

}
