import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';

import { webconfig } from '../_models/webconfig';
import { map } from 'rxjs/operators';

let apiUrl = webconfig.apiUrl;

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get(`${apiUrl}/users/${id}`);
    }

    register(user: User) {
        return this.http.post(`${apiUrl}/users/register`, user);
    }

    delete(id: number) {
        return this.http.delete(`${apiUrl}/users/${id}`);
    }

    getUserLevelsData(authToken: string){
        //console.log('entered');
            let body = new URLSearchParams();
            body.set('authToken', authToken);
            return this.http.post<any>(`${apiUrl}/getLabels`, body.toString(), {
                headers:{
                  'content-type':"application/x-www-form-urlencoded"
                }
              }).pipe(resp => resp);
            //   .subscribe( (data) => {
            //     return data;
            // });
    }
}