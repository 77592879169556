import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-meterconfigaeb',
  templateUrl: './meterconfigaeb.component.html',
  styleUrls: ['./meterconfigaeb.component.scss']
})
export class MeterconfigaebComponent implements OnInit {
  faSpinner = faSpinner;
  configForm: UntypedFormGroup;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  eBUniteRateSelectionType: string = '0';
  dgUniteRateSelectionType: string = '0';
  cmdformData: any = 0;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;
  
  submitted: boolean = false;
  myDate = new Date();
  cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');
  tagValue = 'col-sm-8 text-left';
  startDate = '';
  optionsSelect: Array<any>;
  @Input() meterDetail:any = {
    mID: "All",
    dID:0,
    sdID:0
  };

  dedType: any = [
    {'id':0, 'Name':'Daily'}
    //{'id':1, 'Name':'Monthly'}
  ];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.optionsSelect = [
      { value: '1', label: 'Option 1' },
      { value: '2', label: 'Option 2' },
      { value: '3', label: 'Option 3' },
    ];
    
    this.configForm = this.formBuilder.group({
      alarmLevel: ['', Validators.required],
      emergencycredit: ['', Validators.required],
      //deductionType: ['', Validators.required],
      eBFlatUnitRate: ['', Validators.required],
      dGUnitRate: ['', Validators.required],
      fixedChargeGrid: ['', Validators.required],
      fixedChargeDG: ['', Validators.required],
      vendingCharge: ['', Validators.required],
      commonAreaMaintCharge: ['', Validators.required],
      carpetAreaMaintCharge: ['', Validators.required],
      gridMaintCharge: ['', Validators.required],
      dgMaintCharge: ['', Validators.required],
      clubCharge: ['', Validators.required],
      waterCharge: ['', Validators.required],
      otherCharge: ['', Validators.required],
      serviceTax: ['', Validators.required],
      schedularStartEndDate: [this.myDate, Validators.required],
    });
    if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
      this.getCurrentMeterConfig();
      this.cmdformData=1;
      this.tagValue = 'col-sm-10 text-left';
    }
  }
  get cF() { return this.configForm.controls; }

  getCurrentMeterConfig() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/getBillingTeriff', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
            //console.log(this.responseData.data);
            this.cF.alarmLevel.setValue(this.responseData.data.alarmLevel);
            this.cF.emergencycredit.setValue(this.responseData.data.emergCreditLimit);
            //this.cF.deductionType.setValue(this.responseData.data.fixDeductionType);
            this.cF.eBFlatUnitRate.setValue(this.responseData.data.eBUnitRate);
            this.cF.dGUnitRate.setValue(this.responseData.data.dGUnitRate);
            this.cF.fixedChargeGrid.setValue(this.responseData.data.eBFixCharges);
            this.cF.fixedChargeDG.setValue(this.responseData.data.dGFixCharges);
            this.cF.vendingCharge.setValue(this.responseData.data.vendingRate);
            this.cF.commonAreaMaintCharge.setValue(this.responseData.data.commonAreaMaintRate);
            this.cF.carpetAreaMaintCharge.setValue(this.responseData.data.carpetAreaMaintCharge);
            this.cF.gridMaintCharge.setValue(this.responseData.data.gridMaintRate);
            this.cF.dgMaintCharge.setValue(this.responseData.data.dgMaintRate);
            this.cF.clubCharge.setValue(this.responseData.data.clubRate);
            this.cF.waterCharge.setValue(this.responseData.data.waterRate);
            this.cF.otherCharge.setValue(this.responseData.data.otherChargeRate);
            this.cF.serviceTax.setValue(this.responseData.data.serviceTax);
           // this.cF.schedularStartEndDate.setValue(this.cValue);
           // console.log("date",this.cValue);
            //console.log(this.myDate);
            //##to do fill form data from response data with setvalue
        } else {
            //console.log(this.responseData.message);//this.responseData.isresponse;
        }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }

  onSubmitConfigMeter() {
    //alert("hi");

    if (this.configForm.invalid) {
      this.submitted = true;

      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);

    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }

    //formData.append('configSubDivisionId', this.meterDetail.sdID);

    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);

    formData.append('alarmLevel', this.cF.alarmLevel.value);
    formData.append('emergencycredit', this.cF.emergencycredit.value);
    formData.append('deductionType', '0');
    formData.append('eBUniteRateSelectionType',this.eBUniteRateSelectionType);
    formData.append('dgUniteRateSelectionType', this.dgUniteRateSelectionType);
    formData.append('eBFlatUnitRate', this.cF.eBFlatUnitRate.value);
    formData.append('dGUnitRate', this.cF.dGUnitRate.value);
    formData.append('fixedChargeGrid', this.cF.fixedChargeGrid.value);
    formData.append('fixedChargeDG', this.cF.fixedChargeDG.value);
    formData.append('vendingCharge', this.cF.vendingCharge.value);
    formData.append('commonAreaMaintCharge', this.cF.commonAreaMaintCharge.value);
    formData.append('carpetAreaMaintCharge', this.cF.carpetAreaMaintCharge.value);
    formData.append('gridMaintCharge', this.cF.gridMaintCharge.value);
    formData.append('dgMaintCharge', this.cF.dgMaintCharge.value);
    formData.append('clubCharge', this.cF.clubCharge.value);
    formData.append('waterCharge', this.cF.waterCharge.value);
    formData.append('otherCharge', this.cF.otherCharge.value);
    formData.append('serviceTax', this.cF.serviceTax.value);
    formData.append('schedularStartTime', this.startDate);
    formData.append('cmdformData',this.cmdformData);
    formData.append('configurationOn', '0');
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configBillingTeriff', formData).subscribe((result) => {
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }

  onSubmitConfigPendingMeter() {
    //alert("hi");

    if (this.configForm.invalid) {
      this.submitted = true;

      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);

    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }

    //formData.append('configSubDivisionId', this.meterDetail.sdID);

    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);

    formData.append('alarmLevel', this.cF.alarmLevel.value);
    formData.append('emergencycredit', this.cF.emergencycredit.value);
    formData.append('deductionType', '0');
    formData.append('eBUniteRateSelectionType',this.eBUniteRateSelectionType);
    formData.append('dgUniteRateSelectionType', this.dgUniteRateSelectionType);
    formData.append('eBFlatUnitRate', this.cF.eBFlatUnitRate.value);
    formData.append('dGUnitRate', this.cF.dGUnitRate.value);
    formData.append('fixedChargeGrid', this.cF.fixedChargeGrid.value);
    formData.append('fixedChargeDG', this.cF.fixedChargeDG.value);
    formData.append('vendingCharge', this.cF.vendingCharge.value);
    formData.append('commonAreaMaintCharge', this.cF.commonAreaMaintCharge.value);
    formData.append('carpetAreaMaintCharge', this.cF.carpetAreaMaintCharge.value);
    formData.append('gridMaintCharge', this.cF.gridMaintCharge.value);
    formData.append('dgMaintCharge', this.cF.dgMaintCharge.value);
    formData.append('clubCharge', this.cF.clubCharge.value);
    formData.append('waterCharge', this.cF.waterCharge.value);
    formData.append('otherCharge', this.cF.otherCharge.value);
    formData.append('serviceTax', this.cF.serviceTax.value);
    formData.append('schedularStartTime', this.startDate);
    formData.append('cmdformData',this.cmdformData);
    formData.append('configurationOn', '1');
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configBillingTeriff', formData).subscribe((result) => {
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("Already") || this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }

 

  getDateFromRange(pickedDate = new Date()) {
    if (pickedDate) {
        let emonth = pickedDate.getMonth() + 1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();
        this.startDate = eyear + '-' + emonth + '-' + eday;
    }
}


}

