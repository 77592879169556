import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { Access } from 'src/app/_models/access';
import { TableUtil } from '../TableUtil';

@Component({
  selector: 'app-disabledmeters',
  templateUrl: './disabledmeters.component.html',
  styleUrls: ['./disabledmeters.component.scss']
})

/*@Component({
  selector: 'app-disabledmeters',
  templateUrl: './disabledmeters.component.html',
  styleUrls: ['./disabledmeters.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
*/
export class DisabledmetersComponent implements OnInit {

  divClass: any="tblH_pagination";
  loadingNoData: boolean = false;
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = '';
  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  circleId:any = 0;
  divisionId:any = 0;
  subdivisionId:any = 0;
  breakpoint: number;
  closeResult: string;
  clickedMeterRow:any;
  accesLevel = 0;
  isDataRedyForTableDcu: boolean = false;
  dcuOffset:number = 0;
  dcuLimit:number = 0;
  dcuTotalRecord:number = 0;
  meterTotalRecord:number = 0;
  
  limit:number = 10;
  meterlimit:number = 5;
  allmeterlimit:number = 10;
  skip:number = 0;
  totalLength:number = 0;
  pageIndex : number = 0;
  pageLimit:number[] = [5, 10] ;
  meterpageLimit:number[] = [5, 10, 15];
  allmeterpageLimit:number[] = [10, 15, 20];
  dcuMeterSearch:string = '';
  dcuSearch:string = '';
  meterSearch:string = '';
  removeProcess= false;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  isMeterDataLoad = false;
  dataSource:any = new MatTableDataSource([]);
  dataMeterSource:any = new MatTableDataSource([]);
  
 

  displayedColumns: string[] = [
    'select',
    'dcuCode',
    'dcuRTC',
    'dcuGatewayStrength',
    'ebdgStatusMode',
    'ebStatus',
    'dgStatus',
    'dcuActivationDatetime',
    'dcuActiveStatus',
    'edit',
];
  meterColumnsToDisplay = [
    'srNo',
    'flatNo',
    'mID',
    'mLLEBSupply', 
    'mLLDGSupply', 
    'mRTC', 
    'activeEnergyEB', 
    'activeEnergyDG', 
    'availableAmount',
    'edit'];
  dcuHeadsCols = [
    { label: "select",name:"select", checked: false },
    { label: "ID",name:"dcuID", checked: false },
    { label: "Code",name:"dcuCode", checked: true },
    { label: "Name",name:"dcuName", checked: true },
    { label: "DCU RTC",name:"dcuRTC", checked: true },
    { label: "Location",name:"gisLocation", checked: false },
    { label: "DCU Gateway Strength",name:"dcuGatewayStrength", checked: true },
    { label: "Status Mode",name:"ebdgStatusMode", checked: true },
    { label: "EB Status",name:'ebStatus', checked: false },
    { label: "DG Status",name:'dgStatus', checked: false },
    { label: "SIM No",name:"dcuSIMNo", checked: false },
   
    { label: "DCU Activation Time",name:"dcuActivationDatetime", checked: true },
    { label: "DCU Activate Status",name:"dcuActiveStatus", checked: true },
    { label: "DCU Activation Status",name:"dcuActivationStatus", checked: false },
    { label: "Action",name:"edit", checked: true },
  ];
  meterHeadsCols = [
    { label: "Sr. No.", name: "srNo", checked: true },
    { label: "Consumer Id",name:"cACNO", checked: false },
    { label: "Flat Id",name:"flatNo", checked: true },
    { label: "Meter Id",name:"mID", checked: true },
    { label: "LL Grid(W)",name:"mLLEBSupply", checked: true },
    { label: "LL DG(W)",name:"mLLDGSupply", checked: true },
    { label: "Last Update",name:"mRTC", checked: true },
    { label: "Grid Units(kWH)",name:"activeEnergyEB", checked: true },
    { label: "DG Units(kWH)",name:"activeEnergyDG", checked: true },
    { label: "Balance",name:"availableAmount", checked: true },
    { label: "edit",name:"edit", checked: true },
  ];

  allmeterTotalRecord:any = 0;
  isallMeterDataLoad:boolean = false;
  allMeterMessages:any = false;
  tabToShow:number = 3;//no tab to show
  pageRefresh:any = 20;
  currentUserSubscription: Subscription;
  currentUser: User;
  getThrough: Access;
  loading: boolean = true;
  constructor(
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  ngOnInit() {
    this.allMeterView();
  }

  ngOnDestroy() {
    //this.ngModalService.dismissAll();
    //this.currentUserSubscription.unsubscribe();
  }

 

  onMeterSearchChange(search){
    this.meterSearch = search;
    this.skip = 0;
    this.allMeterView(false);
  }

  
  /**to get the meter list on click on Dcu */
  meterView(row, addNew:boolean=false,filter:boolean = false) {
    this.clickedMeterRow = row;
    if (addNew === false) {
      //this.dataMeterSource = [];
    }
    if (!filter) {
      this.meterSearch = '';
      this.isMeterDataLoad = addNew;
    }
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', row.dcuID.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.meterlimit.toString());
    body.set('filter', this.meterSearch);
    this.genReqs.postReq('/getDisabledMeters', body).subscribe((result)=>{
      this.responseData = result;
      //this.isMeterDataLoad = true;
      if(this.responseData.success){
          this.isDataRedyForTableDcu = true;
          this.dataMeterSource = this.responseData.data;
          this.allmeterTotalRecord = this.responseData.datalimit.totalResult;
          if(this.allmeterTotalRecord==0){
            this.loadingNoData=true;
            this.divClass="tblH_norecord";
          }
      }else{
          this.isDataRedyForTableDcu = false;
          this.messageUtilitys = this.responseData.message;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable","Disabled Meters Data");
  }

  /** */
  
  /**to get the meter list on click on Dcu */
  allMeterView(addNew:boolean=false) {
    this.loading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '0');
    body.set('meterId', '0');
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.allmeterlimit.toString());
    body.set('filter', this.meterSearch);
    this.genReqs.postReq('/getDisabledMeters', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
        this.isDataRedyForTableDcu = true;
        this.dataMeterSource = this.responseData.data;
        this.allmeterTotalRecord = this.responseData.datalimit.totalResult;
        if(this.allmeterTotalRecord==0){
          this.loadingNoData=true;
          this.divClass="tblH_norecord";
        }
      }else{
        this.isDataRedyForTableDcu = false;
        this.allMeterMessages = this.responseData.message;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.allMeterMessages = 'Unable to process yours request!';
    });
  }

  getPageSizeOptions() {
    if (this.allmeterTotalRecord > 0 && this.allmeterTotalRecord <= 10) {
      return [this.allmeterTotalRecord];
    } else if (this.allmeterTotalRecord > 10 && this.allmeterTotalRecord <= 20) {
      return [10, this.allmeterTotalRecord];
    } else if (this.allmeterTotalRecord > 20 && this.allmeterTotalRecord <= 40) {
      return [10, 20, this.allmeterTotalRecord];
    } else if (this.allmeterTotalRecord > 40 && this.allmeterTotalRecord <= 80) {
      return [10, 20, 40, this.allmeterTotalRecord];
    } else if (this.allmeterTotalRecord > 80 && this.allmeterTotalRecord <= 100) {
      return [10, 20, 40, 80, this.allmeterTotalRecord];
    } else {
        return [10, 20, 40, 80, 100];
    }
  }

  removelevel(row){
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove meter '+row.mID+' ?')
    .then((confirmed) => {
      if(confirmed) {
        this.removeProcess = true;
        let body = new URLSearchParams();
        body.set('meterId', row.muID);
        body.set('subDivisionId', row.sdID);
        body.set('meterSerialNo', row.mID);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/deleteMeter', body).subscribe((result)=>{
          this.responseData = result;
          this.removeProcess = false;
          this.allMeterMessages = this.responseData.message;
          this.allMeterView();
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.allMeterMessages = 'Unable to process yours request!';
        });
      }
    })
    .catch(() => console.log('no response or closed'));
  }


  //To change meter table length
  changeMeterPage(event){
    if(event.pageSize !== this.meterlimit){
        this.meterlimit = event.pageSize;
        this.skip = event.pageSize * event.pageIndex;
        this.meterView(this.clickedMeterRow, true);
      }else{
      if(this.allmeterTotalRecord > this.dataMeterSource.length){
            //if(this.pageIndex < event.pageIndex){
              // next page
              this.skip = event.pageSize * event.pageIndex;
              this.meterView(this.clickedMeterRow, true);
            // }
        }
      }
  }
  //To change meter table length
  changeAllMeterPage(event){
    if(event.pageSize !== this.allmeterTotalRecord){
        this.allmeterlimit = event.pageSize;
        this.skip = event.pageSize * event.pageIndex;
        this.allMeterView(true);
      }else{
      if(this.allmeterTotalRecord > this.dataMeterSource.length){
            //if(this.pageIndex < event.pageIndex){
              // next page
              this.skip = event.pageSize * event.pageIndex;
              this.allMeterView(true);
            // }
        }
      }
  }

  //To chnage DCU col
  onChange(event, index, item) {
    item.checked = !item.checked;
    this.displayedColumns = [];
    for ( let col of this.dcuHeadsCols ) {
      if (col.checked) {
        this.displayedColumns.push(col.name.toString());
      }
    }
  }

  //To chnage DCU col
  updateMeterChange(event, index, item) {
    item.checked = !item.checked;
    this.meterColumnsToDisplay = [];
    for ( let col of this.meterHeadsCols ) {
      if (col.checked) {
        this.meterColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  editMeter(row){
    this.modalService.metereditmeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
}
