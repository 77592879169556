<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
            data-dismiss="modal">&times;</button>
        Download Data
    </h4>
</div>
<div class="modal-body">
    <div class="form-groupp">
        <div class="row">
            <div class="">
                <div class="outerrowdate">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="Consumptionsview">
                        <mat-radio-button (click)="clear()" value="1">Daily</mat-radio-button>
                        <mat-radio-button (click)="clear()" value="2">Monthly</mat-radio-button>
                        <mat-radio-button (click)="clear()" value="3">Yearly</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="outerrowdatee">
                    <div class="">
                        <div *ngIf="Consumptionsview == '1'" class="col-sm-12">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input class="form-control" [maxDate]="today" bsDaterangepicker
                                        placeholder="Choose a date" (bsValueChange)="getDateFromRange($event)"
                                        [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }">
                                    <i class="fa fa-calendar icon_innertxtbox"></i>
                                    <label>Choose a date</label>
                                </span>
                            </div>
                        </div>
                        <div class="monthflex mtdlink">
                            <div *ngIf="Consumptionsview == '2'" class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input class="form-control" [maxDate]="today" bsDatepicker autocomplete="off"
                                            placeholder="Choose a month" (bsValueChange)="getMonthFromRange($event)"
                                            [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }">
                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                        <label>Choose a Month</label>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="Consumptionsview == '2'" class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input class="form-control" [maxDate]="today" bsDatepicker autocomplete="off"
                                            placeholder="Choose a month" (bsValueChange)="getMonthEndFromRange($event)"
                                            [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }"
                                            [(ngModel)]="monthSelection">
                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                        <label>Choose a Month</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Consumptionsview == '3'" class="col-sm-12">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input class="form-control" [maxDate]="today" bsDatepicker autocomplete="off"
                                        placeholder="Choose a year" (bsValueChange)="getYearFromRange($event)"
                                        [bsConfig]="{ dateInputFormat: 'YYYY', minMode: 'year'  }">
                                    <i class="fa fa-calendar icon_innertxtbox"></i>
                                    <label>Choose a year</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row flotrightD">
            <ul class="downloadicn">
                <li (click)="downloadAsPDF2()" class="outerbtnD"><i
                        class="fa fa-file-pdf-o iconcircle"></i>
                    <span>PDF</span>
                </li>
                <li (click)="csvNavigate()" class="outerbtnD"><i
                        class="fa fa-file-excel-o iconcircle"></i>
                    <span>XLS</span>
                </li>
            </ul>
            </div>-->
        <div class="row flotrightD">
            <!--<div class="downloadicn">
                <button class="button" (click)="downloadAsPDF2()" >
                    <i class="fa fa-file-pdf-o red-color" aria-hidden="true"></i>
                </button>
            </div>-->
            <div class="downloadicn">
                <button class="dilog dilog2" type="button" (click)="csvNavigate()" >
                    <i class="fa fa-file-excel-o iconcircle"></i>
                    <span>XLS</span>
                </button>
            </div>
        </div>
    </div>
</div>