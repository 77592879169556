import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';

@Component({
  selector: 'app-installationandcommissioning',
  templateUrl: './installationandcommissioning.component.html',
  styleUrls: ['./installationandcommissioning.component.scss']
})
export class InstallationandcommissioningComponent {

  divClass: any="tblH_pagination";
  loadingNoData: boolean = false;
  levelSearch:string = '';
  length: number = 0;
  messageResult:any;
  loading:boolean = true;
  enableSocietyChange:boolean = true;
  selectedSubDivision:any;
  currentUser: User;
  responseData: any;
  currentUserSubscription: Subscription;
  dataSource:any = new MatTableDataSource([]);
  loadingMessage:any = '';
  faSpinner = faSpinner;


  //pagination settings
  limit:number = 10;
  skip:number = 0;
  filter = '';
  totalLength:number = 0;
  pageSize: number = 7;
  pageSizeOptions: number[] = [7, 14, 21];
  allLevelTotalRecord: number = 0;
  getThrough: Access;
  removeProcess:boolean = false;
  responseMessage:string = '';
  levelHeadsCols = [
    { label: "Range Id",name:"rangeId", checked: true },
    { label: "Prefix Id",name:"prefix", checked: true },
    { label: "Start Range",name:"fromRange", checked: true },
    { label: "End Range",name:"toRange", checked: true },
    { label: "Device Type",name:"deviceType", checked: true },
    { label: "Comment",name:"comment", checked: false },
    { label: "Date & Time",name:"creationDateTime", checked: false },
    { label: "Authority",name:"authName", checked: false },

    { label: "Edit",name:"edit", checked: true },
  ];

  levelColumnsToDisplay = [
    "rangeId",
    "prefix",
    "fromRange",
    "toRange",
    "deviceType",
    "edit"
  ];
  constructor(
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    });
    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=false;
    }
  }

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
    ngOnInit() {
      if(!this.getThrough.authority.Auth_Integration_And_commissioning_Edit && !this.getThrough.authority.Auth_Integration_And_commissioning_Delete){
        this.levelHeadsCols = [
          { label: "Sr. No.",name:"srNo", checked: true },
          { label: "Meter Type",name:"meterType", checked: true },
          { label: "Total Meters",name:"totalMeters", checked: true },
          { label: "File Name",name:"fileName", checked: true },
          { label: "Download Uploded File",name:"fileUrl", checked: true },
          { label: "Status",name:"status", checked: true },
          { label: "Remark",name:"remark", checked: false },
          { label: "Date & Time",name:"datetime", checked: true },
          { label: "Authority",name:"authorityName", checked: false },
        ];

        this.levelColumnsToDisplay = [
          "srNo",
          "meterType",
          "totalMeters",
          "fileName",
          "fileUrl",
          "status",
          "datetime"
         ];
      }else{
        this.levelHeadsCols = [
          { label: "Sr. No.",name:"srNo", checked: true },
          { label: "Meter Type",name:"meterType", checked: true },
          { label: "Total Meters",name:"totalMeters", checked: true },
          { label: "File Name",name:"fileName", checked: true },
          { label: "Download Uploded File",name:"fileUrl", checked: true },
          { label: "Status",name:"status", checked: true },
          { label: "Remark",name:"remark", checked: false },
          { label: "Date & Time",name:"datetime", checked: true },
          { label: "Authority",name:"authorityName", checked: false },
          { label: "Edit",name:"edit", checked: true },
        ];
      
        this.levelColumnsToDisplay = [
          "srNo",
          "meterType",
          "totalMeters",
          "fileName",
          "fileUrl",
          "status",
          "datetime",
          "edit"
        ];
      }
      this.getMeterCommandListing();
      
    }
    ngAfterViewInit() {
      //this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    whichSubDomain(){
      this.modalService.popautocomplete().pipe(
        take(1)).subscribe(result => {
          this.selectedSubDivision = result;
          //console.log('selected val',this.selectedSubDivision);
          this.getMeterCommandListing();
        }
      );
    }

    updateLevelChange(event, index, item) {
      item.checked = !item.checked;
      this.levelColumnsToDisplay = [];
      for ( let col of this.levelHeadsCols ) {
        if (col.checked) {
          this.levelColumnsToDisplay.push(col.name.toString());
        }
      }
    }
  
    onLevelSearchChange(search){
      this.levelSearch = search;
      this.skip = 0;
      this.getMeterCommand(false);
    }

    getMeterCommand(addNew:boolean=false) {
      let body = new URLSearchParams();
      body.set('offSet', this.skip.toString());
      body.set('limit', this.limit.toString());
      body.set('filter', this.levelSearch);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getIntCommData', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
              this.dataSource = this.responseData.data;
              this.length = this.responseData.datalimit.totalResult;
              if(this.length==0){
                this.loadingNoData=true;
                this.divClass="tblH_norecord";
              }else{
                this.loadingNoData=false;
              }
              if(this.length<10){
                this.limit= this.length;
              }
    
        }else{
          this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loadingMessage = 'Unable to process yours request!';
      });
    }

    exportTable() {
      TableUtil.exportTableToExcel("ExampleMaterialTable","Meter's Range Data");
    }

    changeMeterPage(event) {
      if (event.pageSize !== this.limit) {
        this.limit = event.pageSize;
        this.skip = event.pageSize * event.pageIndex;
        this.getMeterCommandListing();
      } else {
        this.skip = event.pageSize * event.pageIndex;
        this.getMeterCommandListing();
      }
    }
  
    getPageSizeOptions() {
      if (this.length > 0 && this.length <= 10) {
        return [this.length];
      } else if (this.length > 10 && this.length <= 20) {
        return [10, this.length];
      } else if (this.length > 20 && this.length <= 40) {
        return [10, 20, this.length];
      } else if (this.length > 40 && this.length <= 80) {
        return [10, 20, 40, this.length];
      } else if (this.length > 80 && this.length <= 160) {
        return [10, 20, 40, 80, this.length];
      } else if (this.length > 160 && this.length <= 320) {
        return [10, 20, 40, 80, 160, this.length];
      } else if (this.length > 320) {
        return [10, 20, 40, 80, 160, 320, this.length];
      } 
    }

    downloadFile(url) {
      window.open(url);
    }
  getMeterCommandListing() {
    this.loading = true;
    let body = new URLSearchParams();
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getIntCommData', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      //console.log(this.responseData);

      if(this.responseData.success){
        //console.log(this.responseData.data);
        setTimeout(() => {
            this.dataSource = this.responseData.data;
            this.length = this.responseData.datalimit.totalResult;
            if(this.length==0){
              this.loadingNoData=true;
              this.divClass="tblH_norecord";
            }else{
              this.loadingNoData=false;
            }
            if(this.length<10){
              this.limit= this.length;
            }
  
        });
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  refreshData() {
    this.limit=10;
    this.loading = true;
    let body = new URLSearchParams();
    if(this.enableSocietyChange){
      body.set('subdivId', this.selectedSubDivision);
    }else{
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('dgId', '0');
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getMeterRangeData', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      //console.log(this.responseData);

      if(this.responseData.success){
        //console.log(this.responseData.data);
        setTimeout(() => {
            this.dataSource = this.responseData.data;
            this.length = this.responseData.datalimit.totalResult;
            if(this.length==0){
              this.loadingNoData=true;
              this.divClass="tblH_norecord";
            }else{
              this.loadingNoData=false;
            }
            if(this.length<10){
              this.limit= this.length;
            }
  
        });
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  addIntegrationFile(){
      this.modalService.addIntegrationFile(this.currentUser.data.userData.subdivision).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => {
        if(result.toString()!='Cancel'){
          this.refreshData();
        }
         // console.log({ messageResult: result });
          this.messageResult = result;
      });
  }

  editMeterRange(row){
    this.modalService.editMeterRange(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        this.refreshData();
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }
  // remove
  removelevel(row){
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove '+row.prefix+' ?')
    .then((confirmed) => {
      if(confirmed) {
        this.removeProcess = true;
        let body = new URLSearchParams();
        body.set('levelId', row.rangeId);
        body.set('subDivId', row.subDivId);
        body.set('prefix', row.prefix);
        
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/deleteMeterRange', body).subscribe((result)=>{
          this.responseData = result;
          this.removeProcess = false;
          this.responseMessage = this.responseData.message;
          this.getMeterCommandListing();
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.responseMessage = 'Unable to process yours request!';
        });
      }
    })
    .catch(() => console.log('no response or closed'));
  }

}


