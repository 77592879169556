import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editamihelpdeskdata',
  templateUrl: './editamihelpdeskdata.component.html',
  styleUrls: ['./editamihelpdeskdata.component.scss']
})
export class EditamihelpdeskdataComponent {
 
  defaultZone: any = "a";
  defaultCircle: any = "a";
  defaultDivision: any = "a";
  defaultSubDivision: any = "a";
  defaultRemark: any = "0";

  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority: any;
 
  authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);
  isLoading:boolean = false;
  thumb:boolean = false;
  submitted: boolean = false;
  status: boolean = false;
  authLevel_sel: any = '';
  public assignedData: any = [];
  public zoneData: any = [];
  public circleData: any = [];
  public divisionData: any = [];
  public subDivisionData: any = [];
  public remarkData: any = [];

  public circleLevelData: any = [];
  public divisionLevelData: any = [];
  public subdivisionLevelData: any = [];
  public dcuLevelData: any = [];

  ///multiselect
  authMulti1: boolean = false;
  authMulti2: boolean = false;
  authMulti3: boolean = false;
  authMulti4: boolean = false;
  authMulti5: boolean = false;
  authMulti6: boolean = false;
  authMulti7: boolean = false;
  authMulti8: boolean = false;
  date:any;
  constructor(
      private formBuilder: UntypedFormBuilder, 
      private modalService: NgbModal,
      public modal: NgbActiveModal,
      private genReqs:generalRequestsService,
      private authenticationService: AuthenticationService,
    ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }
  

  ngOnInit() {
      /*if(this.authority.sdID=="105"){
          this.status=true;
      }*/
      let MOBILE_PATTERN = /[0-9\+\-\ ]/;
      /*
         form authority add
       */
      
          this.addAuthorityForm = this.formBuilder.group({
              uid: [''],
              ticketId: [{value: '', disabled: true}, Validators.required],
              assignedId: ['', Validators.required],
              accountNo: ['', Validators.required],
              zone: ['', Validators.required],
              circle: ['', Validators.required],
              division: ['', Validators.required],
              subDivision: [''],
              meterId: ['', Validators.required],
              consAddress: ['', Validators.required],
              consMobileNo:['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
              jeMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
              assignMeterStatus: ['', Validators.required],
              siteVisitStatus: ['', Validators.required],
              remark: ['', Validators.required]
          });
      this.preSetUserData();
      this.getZoneData();
      //this.getCircleData();
      this.getDivisionData();
      this.getSubDivisionData();
      this.getRemarkData();
      this.getAssignedNameData();
  }

  ngAfterViewInit() {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      // this.preSetUserData();
      // if (this.authority.aID > 0) {
      //     this.getAuthorityDetails();
      // }
  }

  ngOnDestroy() {
      //this.modal.close();
  }

  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAddAuthority() {
      if (this.addAuthorityForm.invalid) {
          this.submitted = true;
          return;
      }
      this.isLoading = true;
      this.AddProcess = true;
      let body = new URLSearchParams();
      body.set('id', this.authority.accountId);
      body.set('subdivId', this.currentUser.data.userData.subdivision);
      body.set('ticketId', this.aD.ticketId.value);
      body.set('assignedId', this.aD.assignedId.value);
      body.set('accountNo', this.aD.accountNo.value);
      body.set('zone', this.aD.zone.value);
      body.set('circle', this.aD.circle.value);
      body.set('division', this.aD.division.value);
      body.set('subDivision', this.aD.subDivision.value);
      body.set('meterId', this.aD.meterId.value);
      body.set('consAddress', this.aD.consAddress.value);
      body.set('consMobileNo', this.aD.consMobileNo.value);
      body.set('jeMobileNo', this.aD.jeMobileNo.value);
      body.set('assignMeterStatus', this.aD.assignMeterStatus.value);
      body.set('siteVisitStatus', this.aD.siteVisitStatus.value);
      body.set('remark', this.aD.remark.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateAmiDataInfo', body).subscribe((result) => {
          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          if (this.responseData.success) {
              this.addAuthMessage = this.responseData.data.message;
              if(this.addAuthMessage.includes("successfully")){
                this.thumb = true;
              }else{
                this.thumb = false;
              }
              setTimeout(() => 
              {
                this.modal.close('done');
              },
              2000); 
          }else{
              this.thumb = false;
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.addAuthMessage = 'Unable to process yours request!';
      });
  }

  convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    
  getAuthorityDetails() {
      let body = new URLSearchParams();
      body.set('authId', this.authority.aID);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getAuthorities', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
              this.authority = this.responseData.data[0];
              //this.assignedData = this.responseData.data['assignedData'];
             //this.authLevel_sel = this.assignedData['authLevel'];
              this.preSetUserData();
              //let toAuthLevel = this.authLevelData.find(c => c.id == this.authLevel_sel);
              //this.aD.authLevel.setValue(toAuthLevel.id);
              //get state
              //this.countrySelect();
              //this.stateSelect();
              //this.utilitySelect();
              //this.circleSelect();
              //this.divisionSelect();
              //this.subdivisionSelect();
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.addAuthMessage = 'Unable to process yours request!';
      });
  }

  changeZone(defaultZone) {
    console.log("Default zONE",this.defaultZone);
    this.defaultZone = defaultZone;
    
    this.getCircleData();
  }

  changeCircle(defaultCircle) {
    this.defaultCircle = defaultCircle;
    //console.log("Default Circle",this.defaultCircle);
    this.getDivisionData();
   }

  changeDivision(defaultDivision) {
    this.defaultDivision = defaultDivision;
     //console.log("Default Circle",this.defaultCircle);
    this.getSubDivisionData();
  
  }

  changeSubDiv(defaultSubDivision) {
    this.defaultSubDivision = defaultSubDivision;
    //console.log("Default Circle",this.defaultCircle);
    //this.getDivisionData();
 
  }

  changeRemark(defaultRemark) {
    this.defaultRemark = defaultRemark;
  }

  preSetUserData() {
      //console.log("authority",this.authority);
      this.aD.uid.setValue(this.authority.accountId);
      this.aD.ticketId.setValue(this.authority.ticketId);
      this.aD.assignedId.setValue(this.authority.assignedId);
      this.aD.accountNo.setValue(this.authority.ivrsNo);
      this.aD.zone.setValue(this.authority.zoneId);
      this.aD.circle.setValue(this.authority.circleId);
      this.aD.division.setValue(this.authority.divisionId);
      this.aD.subDivision.setValue(this.authority.subDivisionId);
      this.aD.meterId.setValue(this.authority.newMeterId);
      this.aD.consAddress.setValue(this.authority.address);
      this.aD.consMobileNo.setValue(this.authority.consMobileNo);
      this.aD.jeMobileNo.setValue(this.authority.jeMobileNo);
      this.aD.assignMeterStatus.setValue(this.authority.assignment);
      this.aD.siteVisitStatus.setValue(this.authority.siteVisit);
      this.aD.remark.setValue(this.authority.remarkId);
  }

  formateDate(datevalue) {
      let date = new Date(datevalue);
      return (date.getMonth()+1)+'/'+(date.getDate()+1)+'/'+date.getFullYear();
  }

  getRemarkData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getRemarkData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.remarkData = this.responseData.data.remarkData;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getAssignedNameData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getFieldAdminData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.assignedData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getZoneData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getZoneDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.zoneData = this.responseData.data.circleData;
        this.getCircleData();
        if(this.defaultZone=='a'){
          this.defaultCircle='a';
          this.defaultDivision='a';
          this.defaultSubDivision='a';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }
  getCircleData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('zoneId', this.aD.zone.value);
    
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCircleDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.circleData = this.responseData.data.circleData;
        
        if(this.defaultCircle!='a'){
          this.getDivisionData();
        }
        if(this.defaultCircle=='a'){
          this.defaultDivision='a';
          this.defaultSubDivision='a';
          this.getDivisionData();
        }
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }
  getDivisionData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
   
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);

    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDivisionDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.divisionData = this.responseData.data.divisionData;
       
        if(this.defaultDivision!='a'){
         this.getSubDivisionData();
        }
        if(this.defaultDivision=='a'){
          this.defaultSubDivision='a';
          this.getSubDivisionData();
        }
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }

  getSubDivisionData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
   
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('divisionId',  this.aD.division.value);

    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSubDivisionDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.subDivisionData = this.responseData.data.subDivisionData;
        //this.getMeterCommandListing();
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }

}
