<!----add authority start-->
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Modify Old/New Meter
        </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="addAuthMessage.length > 0">{{addAuthMessage}}</div>-->
        <div class="form-groupp">
            <!-- Grid row -->
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    Type of Modification
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Updation Type" name="updationType"
                                        formControlName="updationType" class="form-control"
                                        (selectionChange)="updateInformation($event)"
                                        [ngClass]="{ 'is-invalid': submitted && aD.updationType.errors}" required>
                                        <mat-option *ngFor="let auth of updationTypeData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Updation Type</label>
                                    <div *ngIf="submitted && aD.updationType.errors" class="invalid-feedback">
                                        <div *ngIf="aD.updationType.errors.required">
                                            Updation Type is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="basicInfo && !releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Consumer Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                        (selectionChange)="getZoneData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                        <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Zone</label>
                                    <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                        <div *ngIf="aD.zone.errors.required">
                                            Zone is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="circle" name="circle" formControlName="circle"
                                        (selectionChange)="getCircleData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                        <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Circle</label>
                                    <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                        <div *ngIf="aD.circle.errors.required">
                                            Circle is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="division" name="division" formControlName="division"
                                        (selectionChange)="getDivisionData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                        <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Division</label>
                                    <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.division.errors.required">
                                            Division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Sub-division" name="sub_division"
                                        formControlName="sub_division" (selectionChange)="getSubDivisionData()"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Sub-Division</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            sub_division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!--<div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Feeder" name="feeder" formControlName="feeder"
                                        (selectionChange)="getFeederData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of feederData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Feeder</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            Feeder is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>-->
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Consumer Name" name="consumerName"
                                        formControlName="consumerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.consumerName.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Consumer Name</label>
                                    <div *ngIf="submitted && aD.consumerName.errors" class="invalid-feedback">
                                        <div *ngIf="aD.consumerName.errors.required">
                                            Consumer Name is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadLimit.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Limit</label>
                                    <div *ngIf="submitted && aD.loadLimit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadLimit.errors.required">
                                            Load Limit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="load unit" name="loadUnit" formControlName="loadUnit"
                                        (selectionChange)="loadUnit($event)" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadUnit.errors}" required>
                                        <mat-option *ngFor="let auth of loadUnitData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Unit</label>
                                    <div *ngIf="submitted && aD.loadUnit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadUnit.errors.required">
                                            Load Unit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Mobile/Whatsapp No" name="mobileNo" formControlName="mobileNo"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mobileNo.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Mobile/Whatsapp No</label>
                                    <div *ngIf="submitted && aD.mobileNo.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mobileNo.errors.required">
                                            Mobile No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Email Id" name="emailId" formControlName="emailId"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.emailId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Email Id</label>
                                    <div *ngIf="submitted && aD.emailId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.emailId.errors.required">
                                            Email Id is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Address" name="address" formControlName="address"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.address.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Address</label>
                                    <div *ngIf="submitted && aD.address.errors" class="invalid-feedback">
                                        <div *ngIf="aD.address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="basicInfo && !releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Old Meter Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Id" name="oldMeterId" formControlName="oldMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterId.errors }" required>
                                    <i class="fa fa-address-card icon_innertxtbox"></i>
                                    <label>Old Meter ID</label>
                                    <div *ngIf="submitted && aD.oldMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterId.errors.required">
                                            Old Meter ID is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Reading" name="oldMeterReading"
                                        formControlName="oldMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterReading.errors }" required>
                                    <i class="fa fa-check-square-o icon_innertxtbox"></i>
                                    <label>Old Meter Reading</label>
                                    <div *ngIf="submitted && aD.oldMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterReading.errors.required">
                                            Old Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Remark" name="oldMeterRemark"
                                        formControlName="oldMeterRemark" class="form-control">
                                    <i class="fa fa-comment  icon_innertxtbox"></i>
                                    <label>Old Meter Remark</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input style="margin-top: 6px;" type="file" name="oldMeterImage"
                                        (change)="onFileSelectOldImage($event)" />

                                    <label>Old Meter Image</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="basicInfo && !releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="SCO/MCO Remark" name="mcoRemark" formControlName="mcoRemark"
                                        class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>SCO/MCO Remark</label>
                                    <div *ngIf="submitted && aD.mcoRemark.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mcoRemark.errors.required">
                                            SCO/MCO Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input style="margin-top: 6px;" type="file" name="newMeterImage"
                                        (change)="onFileSelectNewMeterImage($event)">

                                    <label>New Meter Image</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input style="margin-top: 6px;" type="file" name="mcoImage"
                                        (change)="onFileSelectMCOImage($event)">

                                    <label>MCO Image</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="scheduler-border" *ngIf="!basicInfo && releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Consumer Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                        (selectionChange)="getZoneData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                        <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Zone</label>
                                    <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                        <div *ngIf="aD.zone.errors.required">
                                            Zone is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="circle" name="circle" formControlName="circle"
                                        (selectionChange)="getCircleData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                        <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Circle</label>
                                    <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                        <div *ngIf="aD.circle.errors.required">
                                            Circle is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="division" name="division" formControlName="division"
                                        (selectionChange)="getDivisionData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                        <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Division</label>
                                    <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.division.errors.required">
                                            Division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Sub-division" name="sub_division"
                                        formControlName="sub_division" (selectionChange)="getSubDivisionData()"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Sub-Division</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            sub_division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!--<div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Feeder" name="feeder" formControlName="feeder"
                                        (selectionChange)="getFeederData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of feederData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Feeder</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            Feeder is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>-->
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Consumer Name" name="consumerName"
                                        formControlName="consumerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.consumerName.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Consumer Name</label>
                                    <div *ngIf="submitted && aD.consumerName.errors" class="invalid-feedback">
                                        <div *ngIf="aD.consumerName.errors.required">
                                            Consumer Name is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadLimit.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Limit</label>
                                    <div *ngIf="submitted && aD.loadLimit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadLimit.errors.required">
                                            Load Limit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="load unit" name="loadUnit" formControlName="loadUnit"
                                        (selectionChange)="loadUnit($event)" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadUnit.errors}" required>
                                        <mat-option *ngFor="let auth of loadUnitData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Unit</label>
                                    <div *ngIf="submitted && aD.loadUnit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadUnit.errors.required">
                                            Load Unit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Mobile/Whatsapp No" name="mobileNo" formControlName="mobileNo"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mobileNo.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Mobile/Whatsapp No</label>
                                    <div *ngIf="submitted && aD.mobileNo.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mobileNo.errors.required">
                                            Mobile No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Email Id" name="emailId" formControlName="emailId"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.emailId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Email Id</label>
                                    <div *ngIf="submitted && aD.emailId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.emailId.errors.required">
                                            Email Id is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Address" name="address" formControlName="address"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.address.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Address</label>
                                    <div *ngIf="submitted && aD.address.errors" class="invalid-feedback">
                                        <div *ngIf="aD.address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="!basicInfo && releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="SCO/MCO Remark" name="mcoRemark" formControlName="mcoRemark"
                                        class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>SCO/MCO Remark</label>
                                    <div *ngIf="submitted && aD.mcoRemark.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mcoRemark.errors.required">
                                            SCO/MCO Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="!basicInfo && releaseAcc && !wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Release Account/Meter Remark
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select 
                                        placeholder="Release Remark" 
                                        name="releaseRemark"
                                        formControlName="releaseRemark" 
                                        class="form-control"
                                        (selectionChange)="updateInformation($event)" 
                                        [(ngModel)]="releaseRemark" required>

                                        <mat-option *ngFor="let auth of releaseData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>Release Remark</label>
                                    
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Consumer Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Old Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Old Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Avccount No" name="newAccount" formControlName="newAccount" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && aD.newAccount.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>New Account No</label>
                                    <div *ngIf="submitted && aD.newAccount.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newAccount.errors.required">
                                            New Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                        (selectionChange)="getZoneData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                        <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Zone</label>
                                    <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                        <div *ngIf="aD.zone.errors.required">
                                            Zone is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="circle" name="circle" formControlName="circle"
                                        (selectionChange)="getCircleData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                        <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Circle</label>
                                    <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                        <div *ngIf="aD.circle.errors.required">
                                            Circle is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="division" name="division" formControlName="division"
                                        (selectionChange)="getDivisionData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                        <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Division</label>
                                    <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.division.errors.required">
                                            Division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Sub-division" name="sub_division"
                                        formControlName="sub_division" (selectionChange)="getSubDivisionData()"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Sub-Division</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            sub_division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Consumer Name" name="consumerName"
                                        formControlName="consumerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.consumerName.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Consumer Name</label>
                                    <div *ngIf="submitted && aD.consumerName.errors" class="invalid-feedback">
                                        <div *ngIf="aD.consumerName.errors.required">
                                            Consumer Name is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadLimit.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Limit</label>
                                    <div *ngIf="submitted && aD.loadLimit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadLimit.errors.required">
                                            Load Limit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="load unit" name="loadUnit" formControlName="loadUnit"
                                        (selectionChange)="loadUnit($event)" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadUnit.errors}" required>
                                        <mat-option *ngFor="let auth of loadUnitData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Unit</label>
                                    <div *ngIf="submitted && aD.loadUnit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadUnit.errors.required">
                                            Load Unit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Mobile/Whatsapp No" name="mobileNo" formControlName="mobileNo"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mobileNo.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Mobile/Whatsapp No</label>
                                    <div *ngIf="submitted && aD.mobileNo.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mobileNo.errors.required">
                                            Mobile No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Email Id" name="emailId" formControlName="emailId"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.emailId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Email Id</label>
                                    <div *ngIf="submitted && aD.emailId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.emailId.errors.required">
                                            Email Id is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Address" name="address" formControlName="address"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.address.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Address</label>
                                    <div *ngIf="submitted && aD.address.errors" class="invalid-feedback">
                                        <div *ngIf="aD.address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && wrongAccount && !wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="SCO/MCO Remark" name="mcoRemark" formControlName="mcoRemark"
                                        class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>SCO/MCO Remark</label>
                                    <div *ngIf="submitted && aD.mcoRemark.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mcoRemark.errors.required">
                                            SCO/MCO Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </fieldset>

            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && !wrongAccount && wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    Consumer Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                        (selectionChange)="getZoneData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                        <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Zone</label>
                                    <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                        <div *ngIf="aD.zone.errors.required">
                                            Zone is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="circle" name="circle" formControlName="circle"
                                        (selectionChange)="getCircleData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                        <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Circle</label>
                                    <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                        <div *ngIf="aD.circle.errors.required">
                                            Circle is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="division" name="division" formControlName="division"
                                        (selectionChange)="getDivisionData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                        <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Division</label>
                                    <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.division.errors.required">
                                            Division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Sub-division" name="sub_division"
                                        formControlName="sub_division" (selectionChange)="getSubDivisionData()"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Sub-Division</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            sub_division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!--<div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Feeder" name="feeder" formControlName="feeder"
                                        (selectionChange)="getFeederData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of feederData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Feeder</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            Feeder is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>-->
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Consumer Name" name="consumerName"
                                        formControlName="consumerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.consumerName.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Consumer Name</label>
                                    <div *ngIf="submitted && aD.consumerName.errors" class="invalid-feedback">
                                        <div *ngIf="aD.consumerName.errors.required">
                                            Consumer Name is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadLimit.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Limit</label>
                                    <div *ngIf="submitted && aD.loadLimit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadLimit.errors.required">
                                            Load Limit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="load unit" name="loadUnit" formControlName="loadUnit"
                                        (selectionChange)="loadUnit($event)" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadUnit.errors}" required>
                                        <mat-option *ngFor="let auth of loadUnitData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Unit</label>
                                    <div *ngIf="submitted && aD.loadUnit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadUnit.errors.required">
                                            Load Unit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Mobile/Whatsapp No" name="mobileNo" formControlName="mobileNo"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mobileNo.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Mobile/Whatsapp No</label>
                                    <div *ngIf="submitted && aD.mobileNo.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mobileNo.errors.required">
                                            Mobile No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Email Id" name="emailId" formControlName="emailId"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.emailId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Email Id</label>
                                    <div *ngIf="submitted && aD.emailId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.emailId.errors.required">
                                            Email Id is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Address" name="address" formControlName="address"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.address.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Address</label>
                                    <div *ngIf="submitted && aD.address.errors" class="invalid-feedback">
                                        <div *ngIf="aD.address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && !wrongAccount && wrongSmart && !updateZone">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Wrong New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Wrong New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            Wrong New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Correct New Meter Id" name="correctNewMeterId" formControlName="correctNewMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.correctNewMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Correct New Meter Id</label>
                                    <div *ngIf="submitted && aD.correctNewMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.correctNewMeterId.errors.required">
                                            Correct New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            
            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && !wrongAccount && !wrongSmart && updateZone">
                <legend class="scheduler-border">
                    Consumer Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                        (selectionChange)="getZoneData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                        <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Zone</label>
                                    <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                        <div *ngIf="aD.zone.errors.required">
                                            Zone is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="circle" name="circle" formControlName="circle"
                                        (selectionChange)="getCircleData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                        <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Circle</label>
                                    <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                        <div *ngIf="aD.circle.errors.required">
                                            Circle is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="division" name="division" formControlName="division"
                                        (selectionChange)="getDivisionData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                        <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Division</label>
                                    <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.division.errors.required">
                                            Division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Sub-division" name="sub_division"
                                        formControlName="sub_division" (selectionChange)="getSubDivisionData()"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of subDivData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Sub-Division</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            sub_division is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!--<div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Feeder" name="feeder" formControlName="feeder"
                                        (selectionChange)="getFeederData()" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.sub_division.errors}" required>
                                        <mat-option *ngFor="let auth of feederData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-database icon_innertxtbox"></i>
                                    <label>Feeder</label>
                                    <div *ngIf="submitted && aD.sub_division.errors" class="invalid-feedback">
                                        <div *ngIf="aD.sub_division.errors.required">
                                            Feeder is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>-->
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Consumer Name" name="consumerName"
                                        formControlName="consumerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.consumerName.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Consumer Name</label>
                                    <div *ngIf="submitted && aD.consumerName.errors" class="invalid-feedback">
                                        <div *ngIf="aD.consumerName.errors.required">
                                            Consumer Name is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadLimit.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Limit</label>
                                    <div *ngIf="submitted && aD.loadLimit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadLimit.errors.required">
                                            Load Limit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="load unit" name="loadUnit" formControlName="loadUnit"
                                        (selectionChange)="loadUnit($event)" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.loadUnit.errors}" required>
                                        <mat-option *ngFor="let auth of loadUnitData" [value]="auth.id">
                                            {{auth.text}}
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Load Unit</label>
                                    <div *ngIf="submitted && aD.loadUnit.errors" class="invalid-feedback">
                                        <div *ngIf="aD.loadUnit.errors.required">
                                            Load Unit is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Mobile/Whatsapp No" name="mobileNo" formControlName="mobileNo"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mobileNo.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Mobile/Whatsapp No</label>
                                    <div *ngIf="submitted && aD.mobileNo.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mobileNo.errors.required">
                                            Mobile No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Email Id" name="emailId" formControlName="emailId"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.emailId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Email Id</label>
                                    <div *ngIf="submitted && aD.emailId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.emailId.errors.required">
                                            Email Id is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Address" name="address" formControlName="address"
                                        class=" form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.address.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>Address</label>
                                    <div *ngIf="submitted && aD.address.errors" class="invalid-feedback">
                                        <div *ngIf="aD.address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border" *ngIf="!basicInfo && !releaseAcc && !wrongAccount && !wrongSmart && updateZone">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="SCO/MCO Remark" name="mcoRemark" formControlName="mcoRemark"
                                        class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>SCO/MCO Remark</label>
                                    <div *ngIf="submitted && aD.mcoRemark.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mcoRemark.errors.required">
                                            SCO/MCO Remark is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </fieldset>
            
            
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">

                    <button type="submit" class="dilog dilog1" disabled *ngIf="disableStatus">
                        <mat-icon>edit</mat-icon>
                        Update
                    </button>


                    <button type="submit" class="dilog dilog1" *ngIf="!disableStatus">
                        <mat-icon>edit</mat-icon>
                        Update
                    </button>

                    <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->