<div class="modal-header subpage">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div style="padding-left: 34px;" [innerHTML]=message>
 
</div>
<div class="modal-footer">
  <div class="col-sm-12 text-right rowcoldelete">
    <button type="button" class="dilog dilog1" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="dilog dilog3" (click)="decline()">{{ btnCancelText }}</button>
  </div>
</div>