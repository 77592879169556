<div class="container-fluid innerpage_top page-content-wrapper ">
  <div class="row content-viewport">
    <div class="page-content-wrapper-inner outerbox_shadow tblH col-md-12">

      <div class="authentication-theme auth-style_1 authLoginAccessNew">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-10 col-10  mx-auto">
            <!--<mat-card>
               <mat-card-content>
                <button type="button" class="dilog dilog1" (click)="oncliclick()">Click</button>
                <div class="container">
                  <div id="app">
                    <div id="popup">
                      <div id="text">
                        <h1>Payment</h1>
                        <h1>Payment Text </h1>
                      </div>
                      <svg id="table" xmlns="#" xmlns:xlink="#" style="isolation:isolate"
                        viewBox="269.022 498.853 762 269" width="762" height="269">
                        <ellipse vector-effect="non-scaling-stroke" cx="650.0220755799724" cy="633.3526570048309"
                          rx="338.0000000000001" ry="91.5" fill="rgb(104,175,234)" />
                      </svg>
                    </div>
                  </div>
                </div>
              </mat-card-content> 

            </mat-card>-->
            <mat-card class="noPadding" id="recharge_review">
              <div class="display-avatar recpt">
                <img src="./assets/images/logo.png" alt="profile image">
              </div>

              <mat-card-content>
                <div style="overflow-x:auto;overflow-y:auto;">
                  <!--<div class="col-sm-12 row">
              <div class="col-sm-6 rechargeTitle1" style="text-align: left;font-size: x-large;">
                <b>Failed Receipt!</b>
              </div>
              <div class="col-sm-6 rechargeTitle1" >
                <b>Date :</b>{{successReacharge.reportingDate}}
              </div>
              <div class="col-sm-12" style="font-size: large;">
                <B>Personal Details:-</B>
              </div>
              <div class="col-sm-3">
                <b>Flat No:</b>
              </div>
              <div class="col-sm-3">
                {{successReacharge.flatId}}
              </div>
              <div class="col-sm-3">
                <B>Meter ID:-</B>
              </div>
              <div class="col-sm-3">
                {{successReacharge.meterId}}
              </div>
              
              <div class="col-sm-3">
                <b>Owner's Name:</b>
              </div>
              <div class="col-sm-3">
                {{successReacharge.fullName}}
              </div>
              <div class="col-sm-3">
                <B>Moblie No:-</B>
              </div>
              <div class="col-sm-3">
                {{successReacharge.mobileNo}}
              </div>

              <div class="col-sm-3">
                <b>Email:</b>
              </div>
              <div class="col-sm-3">
                {{successReacharge.emailId}}
              </div>
              <div class="col-sm-3">
                <B>Address:-</B>
              </div>
              <div class="col-sm-3">
                {{successReacharge.adddress}}
              </div>
            </div> -->
                  <table width='100%' class="t1">
                    <tr class="trborderN">
                      <td colspan="2" class="top_headng" class="rechargeTitle">
                        <b>Failed Receipt!</b>
                      </td>
                      <td colspan="2" style="text-align: right" class="rechargeTitle1">
                        <b>Date :</b>{{successReacharge.reportingDate}}
                      </td>
                    </tr>
                    <tr class="top_headngS">
                      <td colspan="4">
                        <B>Personal Details:-</B>
                      </td>
                    </tr>
                    <tr>
                      <td width="25%"><b>Flat No:</b></td>
                      <td width="25%">{{successReacharge.flatId}}</td>
                      <td width="25%"><b>Meter ID:</b></td>
                      <td width="25%">{{successReacharge.meterId}}</td>
                    </tr>
                    <tr>
                      <td width="25%"><b>Owner's Name:</b></td>
                      <td width="25%">{{successReacharge.fullName}}</td>
                      <td width="25%"><b>Moblie No:</b></td>
                      <td width="25%">{{successReacharge.mobileNo}}</td>
                    </tr>
                    <tr>
                      <td width="25%"><b>Email:</b></td>
                      <td width="25%">{{successReacharge.emailId}}</td>
                      <td width="25%"><b>Address:</b></td>
                      <td width="25%">{{successReacharge.adddress}}</td>
                    </tr>


                  </table>

                  <table width='100%' class="t1">
                    <tr>
                      <td colspan="2" style="font-size: large;">
                        <b>Recharge Details:-</b>
                      </td>
                    </tr>
                  </table>
                  <table border=1 width='100%' class="t1">

                    <tr>
                      <td width="50%" colspan="2"><b>Recharge Amount: </b></td>
                      <td width="50%" colspan="2">{{successReacharge.amount}} </td>
                    </tr>
                    <tr>
                      <td width="50%" colspan="2"><b>Recharge Type:</b> </td>
                      <td width="50%" colspan="2">{{successReacharge.productInfo}} </td>
                    </tr>
                    <tr>
                      <td width="50%" colspan="2"><b>Recharge Status:</b> </td>
                      <td width="50%" colspan="2">{{successReacharge.status}} </td>
                    </tr>
                    <tr>
                      <td width="50%" colspan="2"><b>Payment Method:</b></td>
                      <td width="50%" colspan="2">{{successReacharge.mode}} </td>
                    </tr>
                    <tr>
                      <td width="50%" colspan="2"><b>Payment Transaction Id:</b></td>
                      <td width="50%" colspan="2">{{successReacharge.tranNo}} </td>
                    </tr>
                  </table>
                  <table width='100%' class="t1">
                    <tr class="note">
                      <td width='100%' style=" white-space: pre-line !important;" colspan="4">
                        <b> Please Note :-</b>
                        <p> 1) Payment which have been made through modes other than cash are subject to clearance
                          including through electronic mode
                          payments.</p>
                        <p> 2) This document can be used as a proof of payment.</p>
                      </td>
                    </tr>
                  </table>

                </div>

              </mat-card-content>
            </mat-card>
            <mat-card class="noPadding1">
              <mat-card-content>
                <div class="col-sm-12 row">
                  <div class="col-sm-6 linkClass">
                    <a [routerLink]="['/recharge']" [queryParams]="{currentuser: currentUser.authToken,typeData:1}">
                      <!-- <mat-icon matPrefix>
                reply
              </mat-icon> -->

                      Recharge Again

                    </a>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button type="button" class="dilog dilog1" ngxPrint printSectionId="recharge_review">Print
                      Slip</button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Mpin Login-->

<!--Mpin Login-->
<!-- <div class="auth_footer">
    <p class="text-muted text-center">© Avonsm</p>
  </div> -->