import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-adjustmentusermeter',
  templateUrl: './adjustmentusermeter.component.html',
  styleUrls: ['./adjustmentusermeter.component.scss']
})
export class AdjustmentusermeterComponent implements OnInit {
  public today = new Date();
  mode = [
    {name: 'Cash Adjustment'},
    {name: 'Credit Card Adjustment'},
    {name: 'Debit Card Adjustment'},
    {name: 'Online Recharge Adjustment'},
    {name: 'NEFT Adjustment'},
    {name: 'Transfer Amount Adjustment'}
  ];

  paymentMode: string[] = [
    "id",
    "paymentModeCode",
    "paymentModeName",
    "inserDateTime",
    "authId"
  ];
  thumb = true;
  faSpinner = faSpinner;
  rechageModeValue:any='';
  isLoading = false;
  divTagOne='col-sm-4 text-left';
  divTagTwo='col-sm-8 text-right';
  currentUser: User;
  currentUserSubscription: Subscription;
  isLinear = true;
  rechargeFormGroup: UntypedFormGroup;
  otpFormGroup: UntypedFormGroup;
  userInfo:any = {};
  responseMessage:string = '';
  consFullName:string = '';
  consEmail:string = '';
  consMobileId:string = '';
  responseData:any;
  invalidMessage:any = '';
  reviewSubmitMessage = '';
  reviewSubmitStatus: boolean = false;
  meterDetail:any='';
  flatNo:any='';
  pickedDate = new Date();
  picked:any='';
  emonthNew = ''; //always move 1 value up
  edayNew = '';
  eyearNew = '';
  ehourNew = '';
  eminuteNew='';
  esecondNew='';
  submitted: boolean = false;
  meterData = [{
    "consFullName": "null",
    "consEmail": "null ",
    "consMobileId": "0000"
  }]
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {

    this.emonthNew = (this.pickedDate.getMonth() + 1).toString(); //always move 1 value up
        this.edayNew = this.pickedDate.getDate().toString();
        this.eyearNew = this.pickedDate.getFullYear().toString();
        this.ehourNew=this.pickedDate.getHours().toString();
        this.eminuteNew=this.pickedDate.getMinutes().toString();
        this.esecondNew=this.pickedDate.getSeconds().toString();

        //console.log(this.emonthNew.length," ",this.edayNew.length," ",this.eyearNew.length," ",this.ehourNew.length," ",this.eminuteNew.length," ",this.esecondNew.length);
        if(this.emonthNew.length==1){
          this.emonthNew = "0"+this.emonthNew;
        }
        if(this.edayNew.length==1){
          this.edayNew = "0"+this.edayNew;
        }
        if(this.eyearNew.length==1){
          this.eyearNew = "0"+this.eyearNew;
        }
        if(this.ehourNew.length==1){
          this.ehourNew = "0"+this.ehourNew;
        }
        if(this.eminuteNew.length==1){
          this.eminuteNew = "0"+this.eminuteNew;
        }
        if(this.esecondNew.length==1){
          this.esecondNew = "0"+this.esecondNew;
        }

    this.picked =this.edayNew+'/'+ this.emonthNew+'/'+this.eyearNew+' '+this.ehourNew+':'+this.eminuteNew+':'+this.esecondNew;
    this.consFullName = this.userInfo.consFullName;
    this.consEmail = this.userInfo.consEmail;
    this.consMobileId = this.userInfo.consMobileId;
    this.flatNo = this.userInfo.flatNo;
    this.rechargeFormGroup = this.formBuilder.group({
      meterId: ['', Validators.required],
      rechargeAmount: ['', [Validators.required, Validators.min(1), Validators.max(20000)]],
      //transactionId: ['', Validators.required],
      rechargeMode: ['', Validators.required],
      remark: ['', Validators.required],
    });
    this.otpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });
    this.rF.meterId.setValue(this.userInfo.meterSerialNo);
    this.paymentModeList();
  }

  get rF() { return this.rechargeFormGroup.controls; }
  get oF() { return this.otpFormGroup.controls; }
  
  generateOtp(){
    if (this.rechargeFormGroup.invalid) {
        this.submitted = true;
      return;
    }
            let body = new URLSearchParams();
            body.set('subdevid', this.userInfo.sdID);
            body.set('mobileNo', this.userInfo.consMobileId);
            body.set('emailId', this.userInfo.consEmail);
            body.set('activeStatus', this.userInfo.mActiveStatus);
            body.set('subDevisionDatabaseName', this.userInfo.subDivName);
            body.set('rechargeAmount', this.rF.rechargeAmount.value);
            body.set('type', 'Adjustment');
            body.set('authToken', this.currentUser.authToken);

            this.genReqs.postReq('/sendRechargeOtp', body).subscribe((result)=>{
              this.responseData = result;
              if(this.responseData.success){
                this.meterData = this.responseData.data;
              }
            },(err)=>{
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.responseMessage = '<div class="errors">Unable to process yours request!</div>';
            });
           
  }

  matchOtp(stepper){

        if (this.otpFormGroup.invalid) {
            this.submitted = true;
            return;
          }           let body = new URLSearchParams();
           body.set('subdevid', this.userInfo.sdID);
           body.set('mobileNo', this.userInfo.consMobileId);
           body.set('emailId', this.userInfo.consEmail);
           body.set('activeStatus', this.userInfo.mActiveStatus);
           body.set('otpValue', this.oF.otp.value);
           body.set('subDevisionDatabaseName', this.userInfo.subDivName);
           body.set('authToken', this.currentUser.authToken);
           this.genReqs.postReq('/matchRechargeDomainOtp', body).subscribe((result)=>{
             this.responseData = result;
             if(this.responseData.success){
              this.responseMessage = this.responseData.message;
              this.goForward(stepper);
             } else {
              this.invalidMessage = '<div class="errors">'+this.responseData.message+'</div>';
             }
           },(err)=>{
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = '<div class="errors">Unable to process yours request!</div>';
           });
        
 }

 goForward(stepper: MatStepper){
  stepper.next();
}

    processRechage() {
      this.reviewSubmitMessage = '';
      this.reviewSubmitStatus = false;
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.userInfo.sdID);
      body.set('consId', this.userInfo.consAccountNo);
      body.set('meterId', this.userInfo.meterUId);
      body.set('meterSerialNo', this.userInfo.meterSerialNo);
      body.set('meterIpAddress', this.userInfo.meterIpAddress);
      body.set('meterPortNo', this.userInfo.meterPortNo);
      body.set('rechargeAmount', this.rF.rechargeAmount.value);
      body.set('rechargeMode', this.rF.rechargeMode.value);
      body.set('remark', this.rF.remark.value);
      body.set('subDevisionDatabaseName', this.userInfo.subDivName);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/processAdjustment', body).subscribe((result)=>{
        this.responseData = result;
        this.divTagOne='col-sm-8 text-left';
        this.divTagTwo='col-sm-4 text-right';

       this.isLoading = false;
        if (this.responseData.success) {
          this.reviewSubmitStatus = true;
          this.thumb = true;
          this.reviewSubmitMessage = this.responseData.data.message;
          if(this.reviewSubmitMessage.includes("Not Updated") || 
          this.reviewSubmitMessage.includes("not updated") || 
          this.reviewSubmitMessage.includes("You are not authorize") || 
          this.reviewSubmitMessage.includes("Communication Failure") || 
          this.reviewSubmitMessage.includes("Un-Successfully")|| 
          this.reviewSubmitMessage.includes("Unsuccessfully") || 
          this.reviewSubmitMessage.includes("Under Progress") || 
          this.reviewSubmitMessage.includes("Error")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.isLoading = false;
          this.reviewSubmitMessage = 'Unable to process yours request!';
          this.reviewSubmitStatus = false;
      });
    }

    paymentModeList() {
      let body = new URLSearchParams();
      body.set('subDevisionDatabaseName', this.userInfo.subDivName);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getPaymentAdjustmentModes', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          this.paymentMode = this.responseData.data;
          
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
    });
  }

  /**to get the meter list on click on Dcu */
  meterView(row, addNew:boolean=false) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', this.currentUser.data.userData.dcu);
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getMeters', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.meterData = this.responseData.data;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  printRechargeInfo() {
    let printContents, popupWin;
    printContents = document.getElementById('recharge_review').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Recharge Info</title>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  onSelectFlat(e, rechageModeValue) {
    this.rechageModeValue = rechageModeValue;
  }
}