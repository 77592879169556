import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-recent-bill-from-subdivision',
  templateUrl: './recent-bill-from-subdivision.component.html',
  styleUrls: ['./recent-bill-from-subdivision.component.scss']
})
export class RecentBillFromSubdivisionComponent implements OnInit {
  loading:boolean = false;
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
  }

}
