import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from '../_services';
import { User } from '../_models';

@Component({
  selector: 'app-temper-report-modal',
  templateUrl: './temper-report-modal.component.html',
  styleUrls: ['./temper-report-modal.component.scss']
})
export class TemperReportModalComponent implements OnInit {
  temperData:any = {};
  midLists:string = '';
  currentUser: User;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
     // console.log(this.temperData);
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnDestroy() {
    //this.modal.close('Ok click')
  }

  markReadAll(){
    this.temperData.forEach( (element) => {
      this.midLists += element.Meter_Id+',';
  });
    //console.log(this.midLists);
   // console.log('no error require');
    if(this.midLists.length > 0) {
      /*let body = new URLSearchParams();
        body.set('mids', this.midLists);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getTamperData', body).subscribe((result)=>{    
        },(err)=>{
          console.log('no error require');
        });*/
    }
  }
}
