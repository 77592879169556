import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-relaycontrol',
  templateUrl: './relaycontrol.component.html',
  styleUrls: ['./relaycontrol.component.scss']
})
export class RelaycontrolComponent implements OnInit {

  faSpinner = faSpinner;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;
  cmdformData: any = 0;
  configForm: UntypedFormGroup;
  demoNumber = 5 ;
  counter = Array;
  subDivisionId: any = 0;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  submitted: boolean = false;
  rPhaseValue=0;
  yPhaseValue=0;
  bPhaseValue=0;
  @Input() meterDetail: any = {
    cACNO: 0,
    muID: 0,
    dCUID: 0,
    sdID: 0,
    meterType: 0
};

  numberReturn(length){
    return new Array(length);
  }
  tagValue = 'col-sm-8 text-left';


replayStatus: any = [{'id': 1,'name': 'Active'},{'id': 0,'name': 'In-Active'}];


constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
){    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
}

ngOnInit(){
    this.configForm = this.formBuilder.group({
      rPhaseReplay: [''],
      yPhaseReplay: [''],
      bPhaseReplay: [''],
    
    });
    if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
      this.getCurrentMeterConfig();
      this.cmdformData=1;
      this.tagValue = 'col-sm-10 text-left';
    }
}

  get cF() { return this.configForm.controls; }

  getCurrentMeterConfig() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/getRelayStatus', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
            //console.log(this.responseData.data);
            this.cF.rPhaseReplay.setValue(this.responseData.data.rPhaseReplay);
            this.cF.yPhaseReplay.setValue(this.responseData.data.yPhaseReplay);
            this.cF.bPhaseReplay.setValue(this.responseData.data.bPhaseReplay);
            //##to do fill form data from response data with setvalue
        } else {
           // console.log(this.responseData.message);//this.responseData.isresponse;
        }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }

  onSubmitConfigMeter() {
   /* if (this.configForm.invalid) {
      this.submitted = true;
      return;
    }*/
    if(!this.cF.rPhaseReplay.value){
      this.rPhaseValue=1;
    }else{
      this.rPhaseValue=0;
    }

    if(!this.cF.yPhaseReplay.value){
      this.yPhaseValue=1;
    }else{
      this.yPhaseValue=0;
    }

    if(!this.cF.bPhaseReplay.value){
      this.bPhaseValue=1;
    }else{
      this.bPhaseValue=0;
    }
    
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);
   
    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }
    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
   
    formData.append('rPhaseReplay', this.rPhaseValue.toString());
    formData.append('yPhaseReplay', this.yPhaseValue.toString());
    formData.append('bPhaseReplay', this.bPhaseValue.toString());
    formData.append('cmdformData',this.cmdformData);
    formData.append('configurationOn', '0');
    formData.append('authToken', this.currentUser.authToken);
   
    this.genReqs.formDataPost('/configRelayStatus', formData).subscribe((result) => {
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //console.log("message", this.message);
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }

  onSubmitConfigPendingMeter() {
    /* if (this.configForm.invalid) {
       this.submitted = true;
       return;
     }*/
     if(!this.cF.rPhaseReplay.value){
       this.rPhaseValue=1;
     }else{
       this.rPhaseValue=0;
     }
 
     if(!this.cF.yPhaseReplay.value){
       this.yPhaseValue=1;
     }else{
       this.yPhaseValue=0;
     }
 
     if(!this.cF.bPhaseReplay.value){
       this.bPhaseValue=1;
     }else{
       this.bPhaseValue=0;
     }
     
     this.message ='';
     this.AddProcess = true;
     this.isLoading = true;
 
     const formData = new FormData();
     formData.append('authLevel', this.currentUser.data.userData.authLevel);
     formData.append('consId', this.meterDetail.cACNO);
     formData.append('meterId', this.meterDetail.muID);
    
     if(this.meterDetail.sdID==''){
       formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
     }else{
       formData.append('configSubDivisionId', this.meterDetail.sdID);
     }
     formData.append('dcuId', this.meterDetail.dCUID);
     formData.append('meterType', this.meterDetail.meterType);
    
     formData.append('rPhaseReplay', this.rPhaseValue.toString());
     formData.append('yPhaseReplay', this.yPhaseValue.toString());
     formData.append('bPhaseReplay', this.bPhaseValue.toString());
     formData.append('cmdformData',this.cmdformData);
     formData.append('configurationOn', '1');
     formData.append('authToken', this.currentUser.authToken);
    
     this.genReqs.formDataPost('/configRelayStatus', formData).subscribe((result) => {
         this.responseData = result;
         this.AddProcess = false;
         this.isLoading = false;
         if (this.responseData.success) {
           this.thumb = true;
           this.message = this.responseData.data.message;
           if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
             this.thumb = false;
           }else{
             this.thumb = true;
           }
           //console.log("message", this.message);
           //this.modal.close('done');
         }
       },
       (err) => {
         if (err.status === 401) {
           this.authenticationService.logout();
         }
         this.thumb = false;
         this.message = 'Unable to process yours request!';
       }
     );
   }
}
