import { Component, OnInit, HostListener, Renderer2, Directive, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { first, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CountdownTimerService } from 'ngx-timer';
import { countUpTimerConfigModel, timerTexts } from 'ngx-timer';

import { AlertService, AuthenticationService, generalRequestsService } from '../_services/index';
import { User } from '../_models/user'
declare var jQuery: any;
// import * as AOS from 'aos';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/_services/modal.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
//import { Lightbox } from 'ng-gallery/lightbox';

//import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
    //items: GalleryItem[];
    //imageData = data;
    old: boolean = true;
    messageResult: any;
    myControl = new UntypedFormControl();
    CountryControl = new UntypedFormControl();
    filteredCountry: Observable<any[]>;
    StateControl = new UntypedFormControl();
    filteredState: Observable<any[]>;
    UtilityControl = new UntypedFormControl();
    filteredUtility: Observable<any[]>;
    CircleControl = new UntypedFormControl();
    filteredCircle: Observable<any[]>;
    DivisionControl = new UntypedFormControl();
    filteredDivision: Observable<any[]>;
    SubDivisionControl = new UntypedFormControl();
    filteredSubDivision: Observable<any[]>;
    LoginSubDivisionControl = new UntypedFormControl();
    filteredLoginSubDivision: Observable<any[]>;
    filteredOptions: Observable<string[]>;
    loginFormAuth: UntypedFormGroup;
    loginForm: UntypedFormGroup;
    mpinLoginForm: UntypedFormGroup;
    otpValidateForm: UntypedFormGroup;
    isLoginFormShowing = true;
    isAuthLoginFormShowing = true;
    isOtpLoginFormShowing = false;
    loading = false;
    submittedAuth = false;
    submitted = false;
    submittedCV = false;
    submittedOtp = false;
    submittedOtpLogin = false;
    loadingOtpLogin = false;
    loadingOtpResend = false;
    isloginForm = false;
    isMpinForm = false;
    isShowOtpForm = false;
    isMpinFormValidate = false;
    resendOtp = true;
    resendOtpButton = false;
    loadingLogin = false;
    loadingAuthLogin = false;
    loadingOtp = false;
    displayMsg = false;
    displayMsgCV = false;
    returnUrl: string;
    responseData: any;
    ResponseMessage: string;
    token: string;
    invalidMessage: string;
    //messageResult:any;
    subDevision = [{
        'sub_dev_id': 1,
        'sub_dev_name': 'Select your subdevision',
        'dev_id': 1,
        'circle_id': 1,
        'utility_id': 1,
        'state_id': 1,
        'country_id': 1,
        'auth_id': 1,
        'prefix': 1,
        'tb': 'table',
        'subDevisionDatabaseName': ''
    }];

    isloadedSubDivision = false;
    isOtploadedSubDivisionSelected = false;
    selectedCountry = 1;
    selectedState = 1;
    selectedUtility = 1;
    selectedCircle = 1;
    selectedDevision = 1;
    selectedSubDevision = 0;
    selectedSubDevisionName = '';
    selectedSubDevisionDbName = '';
    options = [{ id: 34, description: 'Adding new item' }, { id: 35, description: 'Adding new item1' }, { id: 36, description: 'Adding new item3' }];
    optionsCountry: any[] = [{ id: 0, name: 'Please select' }];
    testConfig: any;
    enteredmobileNo = '';
    enteredemailId = '';
    rembermeAccess = {
        username: '',
        password: '',
        subdevid: ''
    }
    isLoginSubDivChecked = false;
    jobIdData: any = [{
        'jobId': 0,
        'jobName': '',
    }];

    title = 'Client List';
    showSlider = true;

    //sliderWidth: Number = 940;
    //sliderImageWidth: Number = 250;
    //sliderImageHeight: Number = 200;

    //sliderimageSize: any = "{width: '400px', height: '300px', space: 4}";

    AddProcess: Boolean = false;
    AddProcessNew: Boolean = false;
    sliderArrowShow: Boolean = true;
    sliderInfinite: Boolean = true;
    sliderImagePopup: Boolean = true;
    sliderImagePopup1: Boolean = false;
    sliderAutoSlide: Boolean = true;
    sliderSlideImage: Number = 1;
    sliderSlideImage1: Number = 1;
    sliderAnimationSpeed: any = 1;

    slideOrderType: string = 'DESC';

    imageObject2: Array<object> = [{
        image: 'assets/img/photo_galleryimg1.png',
        thumbImage: 'assets/img/photo_galleryimg1.png',
        title: 'PCB Production Line'
    }, {
        image: 'assets/img/photo_galleryimg2.jpg', // Support base64 image
        thumbImage: 'assets/img/photo_galleryimg2.jpg', // Support base64 image
        title: 'Pick & Place Machine'
    }, {
        image: 'assets/img/photo_galleryimg3.jpg', // Support base64 image
        thumbImage: 'assets/img/photo_galleryimg3.jpg', // Support base64 image
        title: 'Moulding Machine'
    }, {
        image: 'assets/img/photo_galleryimg4.jpg', // Support base64 image
        thumbImage: 'assets/img/photo_galleryimg4.jpg', // Support base64 image
        title: 'Wave Soldring Machine'
    }, {
        image: 'assets/img/PCBAssemblyLine.jpg', // Support base64 image
        thumbImage: 'assets/img/PCBAssemblyLine.jpg', // Support base64 image
        title: 'PCB Assembly Line'
    }, {
        image: 'assets/img/galleryimg6.png', // Support base64 image
        thumbImage: 'assets/img/galleryimg6.png', // Support base64 image
        title: 'Pick & Place Machine'
    }, {
        image: 'assets/img/photo_galleryimg8.png', // Support base64 image
        thumbImage: 'assets/img/photo_galleryimg8.png', // Support base64 image
        title: 'Visual Check'
    }, {
        image: 'assets/img/Meterassemblyline.JPG', // Support base64 image
        thumbImage: 'assets/img/Meterassemblyline.JPG', // Support base64 image
        title: 'Meter Assembly Line'
    }, {
        image: 'assets/img/photo_galleryimg9.png', // Support base64 image
        thumbImage: 'assets/img/photo_galleryimg9.png', // Support base64 image
        title: 'Paste Printing Machine'
    }];

    imageObject1: Array<object> = [{
        image: 'assets/img/ISO_Certificate_8000_2014_001.png',
        thumbImage: 'assets/img/ISO_Certificate_8000_2014_001.png',
        title: 'ISO Certificate 8000'
    }, {
        image: 'assets/img/ISO_Certificate_9001_2015_001.png', // Support base64 image
        thumbImage: 'assets/img/ISO_Certificate_9001_2015_001.png', // Support base64 image
        title: 'ISO Certificate 9001'
    }, {
        image: 'assets/img/ISO_Certificate_14001_2015_001.png', // Support base64 image
        thumbImage: 'assets/img/ISO_Certificate_14001_2015_001.png', // Support base64 image
        title: 'ISO Certificate 14001'
    }, {
        image: 'assets/img/ISO_Certificate_27001_2013_001.png', // Support base64 image
        thumbImage: 'assets/img/ISO_Certificate_27001_2013_001.png', // Support base64 image
        title: 'ISO Certificate 27001'
    }, {
        image: 'assets/img/ISO-ISO_Certificate_20000_1_2018_001.png', // Support base64 image
        thumbImage: 'assets/img/ISO_Certificate_20000_1_2018_001.png', // Support base64 image
        title: 'ISO Certificate 20000'
    }, {
        image: 'assets/img/Certificate.jpg', // Support base64 image
        thumbImage: 'assets/img/Certificate.jpg', // Support base64 image
        title: 'Certificate'
    }, {
        image: 'assets/img/National_Award_Certificate_2002.png', // Support base64 image
        thumbImage: 'assets/img/National_Award_Certificate_2002.png', // Support base64 image
        title: 'National Award Certificate 2002'
    }, {
        image: 'assets/img/Quality_Products.jpg', // Support base64 image
        thumbImage: 'assets/img/Quality_Products.jpg', // Support base64 image
        title: 'Quality Products'
    }];


    imageObject: Array<object> = [{
        image: 'assets/img/AVVNL.png',
        thumbImage: 'assets/img/AVVNL.png',
        title: 'AVVNL'
    }, {
        image: 'assets/img/APEPDCL.png', // Support base64 image
        thumbImage: 'assets/img/APEPDCL.png', // Support base64 image
        title: 'APEPDCL'
    }, {
        image: 'assets/img/DGVCL.png', // Support base64 image
        thumbImage: 'assets/img/DGVCL.png', // Support base64 image
        title: 'DGVCL'
    }, {
        image: 'assets/img/DHBVN.jpg', // Support base64 image
        thumbImage: 'assets/img/DHBVN.jpg', // Support base64 image
        title: 'DHBVN'
    }, {
        image: 'assets/img/JDVVNL.png', // Support base64 image
        thumbImage: 'assets/img/JDVVNL.jpg', // Support base64 image
        title: 'JDVVNL'
    }, {
        image: 'assets/img/JVVNL.png', // Support base64 image
        thumbImage: 'assets/img/JVVNL.png', // Support base64 image
        title: 'JVVNL'
    }, {
        image: 'assets/img/MGVCL.png', // Support base64 image
        thumbImage: 'assets/img/MGVCL.png', // Support base64 image
        title: 'MGVCL'
    }, {
        image: 'assets/img/MPPKVVCL.png', // Support base64 image
        thumbImage: 'assets/img/MPPKVVCL.png', // Support base64 image
        title: 'MPPKVVCL'
    }, {
        image: 'assets/img/MSEDCL.jpg', // Support base64 image
        thumbImage: 'assets/img/MSEDCL.jpg', // Support base64 image
        title: 'MSEDCL'
    }, {
        image: 'assets/img/MVVNL.jpg', // Support base64 image
        thumbImage: 'assets/img/MVVNL.jpg', // Support base64 image
        title: 'MVVNL'
    }, {
        image: 'assets/img/PGVCL.png', // Support base64 image
        thumbImage: 'assets/img/PGVCL.png', // Support base64 image
        title: 'PGVCL'
    }, {
        image: 'assets/img/PSPCL.jpeg', // Support base64 image
        thumbImage: 'assets/img/PSPCL.jpeg', // Support base64 image
        title: 'PSPCL'
    }, {
        image: 'assets/img/PVVNL.jpg', // Support base64 image
        thumbImage: 'assets/img/PVVNL.jpg', // Support base64 image
        title: 'PVVNL'
    }, {
        image: 'assets/img/RMG.jpg', // Support base64 image
        thumbImage: 'assets/img/RMG.jpg', // Support base64 image
        title: 'RMG'
    }, {
        image: 'assets/img/SILVERGLADES.jpg', // Support base64 image
        thumbImage: 'assets/img/SILVERGLADES.jpg', // Support base64 image
        title: 'SILVERGLADES'
    }, {
        image: 'assets/img/Sushma.png', // Support base64 image
        thumbImage: 'assets/img/Sushma.png', // Support base64 image
        title: 'SUSHMA'
    }, {
        image: 'assets/img/TANGEDCO.jpg', // Support base64 image
        thumbImage: 'assets/img/TANGEDCO.jpg', // Support base64 image
        title: 'TANGEDCO'
    }, {
        image: 'assets/img/tsspdcl.jpg', // Support base64 image
        thumbImage: 'assets/img/tsspdcl.jpg', // Support base64 image
        title: 'TSSPDCL'
    }, {
        image: 'assets/img/UGVCL.png', // Support base64 image
        thumbImage: 'assets/img/UGVCL.png', // Support base64 image
        title: 'UGVCL'
    }, {
        image: 'assets/img/UHBVN.png', // Support base64 image
        thumbImage: 'assets/img/UHBVN.png', // Support base64 image
        title: 'UHBVN'
    }, {
        image: 'assets/img/WINTERHILLS.jpg', // Support base64 image
        thumbImage: 'assets/img/WINTERHILLS.jpg', // Support base64 image
        title: 'WINTERHILLS'
    }
    ];

    faSpinner = faSpinner;
    addForm: UntypedFormGroup;
    careerForm: UntypedFormGroup;
    responseMessage: string = '';
    responseMessageCV: string = '';
    apiMessage: string = '';

    isLoading = false;
    thumb: boolean = false;

    // @HostListener('document:wheel', ['$event.target'])
    // public onWheel(targetElement) {
    //     AOS.refresh();
    // }
    constructor(
        //public gallery: Gallery, 
        //public lightbox: Lightbox,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private genReqs: generalRequestsService,
        private alertService: AlertService,
        public CountdownTimerService: CountdownTimerService,
        private renderer2: Renderer2,
        public modal: NgbActiveModal,
        private modalService: ModalService
    ) {

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            //console.log("in login");
            let deshboard = "/" + this.authenticationService.getDeshboardRoute();
            this.router.navigate([deshboard]);
        }
        this.rembermeAccess = this.authenticationService.getRemberMeAccess();

        /*this.listener = this.renderer2.listen('window', 'scroll', (e) => {
            console.log(this.getYPosition(e));
        });*/
    }

    /*getYPosition(e: Event): number {
        return (e.target as Element).scrollTop;
    }*/

    getDgData() {
        let body = new URLSearchParams();
        let accessAuth = localStorage.getItem('accessAuth');
        body.set('authToken', accessAuth);
        this.genReqs.postReq('/getJobs', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.jobIdData = this.responseData.data
                //console.log("dgData", this.dgData);
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    openLoginModel() {
        this.modalService.openLoginModel().pipe(
            take(1) // take() manages unsubscription for us
        ).subscribe(result => {
            //console.log({ messageResult: result });
            this.messageResult = result;
        });
    }

    jobs() {
        this.modalService.jobs().pipe(
            take(1) // take() manages unsubscription for us
        ).subscribe(result => {
            //console.log({ messageResult: result });
            this.messageResult = result;
        });
    }

    scrollTo(selector, yOffset = 0) {
        const el = document.querySelector(selector);
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    ngOnInit() {

        //console.log("Hello");
        // AOS.init({
        //     duration: 1000,
        // })
        //window.addEventListener('load', AOS.refresh)

        //this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
        // this.basicLightboxExample();
        //this.withCustomGalleryConfig();
        /** Lightbox Example */
        // Get a lightbox gallery ref
        /* const lightboxRef = this.gallery.ref('lightbox');
         // Add custom gallery config to the lightbox (optional)
         lightboxRef.setConfig({
         imageSize: ImageSize.Cover,
         thumbPosition: ThumbnailsPosition.Top
         });*/

        // Load items into the lightbox gallery ref
        //lightboxRef.load(this.items);

        this.getDgData();
        let cdate = new Date();
        cdate.setMinutes(cdate.getMinutes() + 1);
        this.CountdownTimerService.startTimer(cdate);
        this.testConfig = new countUpTimerConfigModel();

        //custom class
        this.testConfig.timerClass = 'test_Timer_class';

        //timer text values  
        this.testConfig.timerTexts = new timerTexts();
        this.testConfig.timerTexts.hourText = "";
        this.testConfig.timerTexts.minuteText = ":";
        this.testConfig.timerTexts.secondsText = "Sec";
        this.CountdownTimerService.onTimerStatusChange.subscribe(status => {
            if (status == 'STOP') {
                this.enabledResendOtp();
            }
        });
        this.CountdownTimerService.stopTimer;
        this.loginFormAuth = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remberMyLogin: ['']
        });
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            subDevision: [''],
            remberMyLogin: ['']
        });

        if (this.rembermeAccess.password != '' && this.rembermeAccess.username != '') {
            this.loginForm.controls['username'].setValue(this.rembermeAccess.username);
            this.loginFormAuth.controls['username'].setValue(this.rembermeAccess.username);
            this.loginForm.controls['password'].setValue(this.rembermeAccess.password);
            this.loginFormAuth.controls['password'].setValue(this.rembermeAccess.password);
        }

        this.mpinLoginForm = this.formBuilder.group({
            mobileNo: ['', Validators.required],
            //emailId: ['', Validators.required],
            // email: new FormControl('', Validators.compose([
            //   Validators.required,
            //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            // ])),
            subDevision: ['']
        });



        this.otpValidateForm = this.formBuilder.group({
            otp: ['', Validators.required],
        });
        let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        this.addForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: [''],
            emailId: ['', [Validators.required, Validators.email]],
            phoneNo: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
            query: ['', Validators.required]
        });

        this.careerForm = this.formBuilder.group({
            nameCV: ['', Validators.required],
            jobId: ['', Validators.required],
            emailIdCV: ['', [Validators.required, Validators.email]],
            phoneNoCV: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
            messageCV: ['', Validators.required],
            visitorCV: [null, Validators.required]
        });

        // get return url from route parameters or default to '/'
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    }


    get aF() { return this.addForm.controls; }
    get cV() { return this.careerForm.controls; }

    /*basicLightboxExample() {
        this.gallery.ref().load(this.items);
    }
    withCustomGalleryConfig() {

        // 2. Get a lightbox gallery ref
        const lightboxGalleryRef = this.gallery.ref('anotherLightbox');
    
        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          imageSize: ImageSize.Cover,
          thumbPosition: ThumbnailsPosition.Top
        });
    
        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(this.items);
      }*/
    onFileSelect(event) {

        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let exten = file.name.split('.').pop();

            //console.log(file.type);
            if (exten == "doc" || exten == "docx" || exten == "pdf") {
                const file = event.target.files[0];
                this.cV.visitorCV.setValue(file);
                //console.log(file.type,file);
                this.AddProcess = false;
                return true;
            }
            else {
                this.cV.visitorCV.setValue(null);
                alert("Please select Files in MSWord/PDF Formats");
                return false;
            }
        }
    }


    onSubmitAddForm() {
        this.displayMsg = false;
        if (this.addForm.invalid) {
            this.submitted = true;
            return;
        }
        this.isLoading = true;
        let body = new URLSearchParams();
        body.set('name', this.aF.firstName.value);
        body.set('lastName', this.aF.lastName.value);
        body.set('emailId', this.aF.emailId.value);
        body.set('phoneNo', this.aF.phoneNo.value);
        body.set('message', this.aF.query.value);

        let accessAuth = localStorage.getItem('accessAuth');
        body.set('authToken', accessAuth);
        this.AddProcess = true;
        this.genReqs.postReq('/addContactUs', body).subscribe((result) => {
            this.displayMsg = true;
            this.isLoading = false;
            this.thumb = true;
            this.responseData = result;
            this.responseMessage = this.responseData.data.message;
            if (this.responseMessage != 'Thanks.') {
                this.responseMessage = '';
            }
            //this.resetForm(this.addForm);
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
        });
    }

    onSubmitCareerForm() {

        this.displayMsgCV = false;

        if (this.careerForm.invalid) {
            if (this.cV.visitorCV.value == '' || this.cV.visitorCV.value == null) {
                alert("Please select CV before submit details.");
            }
            this.submittedCV = true;
            return;
        }

        this.isLoading = true;
        const formData = new FormData();
        //let body = new URLSearchParams();
        formData.set('name', this.cV.nameCV.value);
        formData.set('jobId', this.cV.jobId.value);
        formData.set('emailId', this.cV.emailIdCV.value);
        formData.set('phoneNo', this.cV.phoneNoCV.value);
        formData.set('message', this.cV.messageCV.value);
        formData.set('visitorCV', this.cV.visitorCV.value);
        let accessAuth = localStorage.getItem('accessAuth');
        formData.set('authToken', accessAuth);
        this.AddProcessNew = true;
        this.genReqs.formDataPost('/sendCV', formData).subscribe((result) => {
            this.displayMsgCV = true;
            this.isLoading = false;
            this.thumb = true;
            this.responseData = result;
            this.responseMessageCV = this.responseData.data.message;
            if (this.responseMessageCV != 'Thanks.') {
                this.responseMessageCV = '';
            }
            //this.resetForm(this.addForm);
        }, (err) => {
            this.AddProcessNew = false;
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.responseMessageCV = 'Unable to process yours request!';
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        //this.router.navigate(["login"]);
        //this.router.reload()
        location.reload();
    }

    // convenience getter for easy access to form fields
    get af() { return this.loginFormAuth.controls; }
    get f() { return this.loginForm.controls; }
    get m() { return this.mpinLoginForm.controls; }
    get t() { return this.otpValidateForm.controls; }

    enabledResendOtp() {
        this.resendOtpButton = true;
    }


}

