<div class="container-fluid innerpage_top page-content-wrapperr">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH">

        <ng-container *ngIf="!loading">
          <div class="boxflexEll roww">
            <h1>Activity On All Meters
              <span class="info_icntop ml-1">
                <i data-toggle="tooltip" data-placement="top" title="Information of Activity On All Meters"
                  class="fa fa-info tabula-tooltip "></i>
              </span>
            </h1>

            <button mat-flat-button (click)="whichSubDomain()" class="cPbg rightcpbg" *ngIf="enableSocietyChange">
              <div class="centerfa">
                <i class="fa fa-reply centerfa"></i>
              </div>
            </button>
          </div>
          <div class="">
            <ng-container class="allutilitysloader col-12" *ngIf="loading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <ng-container>
              <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()">
                  <mat-radio-group name="servantmode" formControlName="servantmode">
                    <div class="col-sm-12 row">
                      <div class="col-md-4 col-sm-6"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_Sub_Meter_Load_On_Off">
                        <div class="borderchk">
                          <div class="o_b1">
                            <i class="fa fa-database"></i>
                            <B>Meter Load</B>
                          </div>
                          <div class="meteractivitychkouter">
                            <div class="meteractivitychk">
                              <mat-radio-button value="L_2">On</mat-radio-button>
                            </div>
                            <div class="meteractivitychk">
                              <mat-radio-button value="L_1">Off</mat-radio-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_Sub_Backup_Supply_On_Off && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <div class="borderchk">
                          <div class="o_b1">
                            <i class="fa fa-database"></i>
                            <B>DG Supply</B>
                          </div>
                          <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                          <div class="meteractivitychkouter">
                            <div class="meteractivitychk">
                              <mat-radio-button value="D_2">On</mat-radio-button>
                            </div>
                            <div class="meteractivitychk">
                              <mat-radio-button value="D_1">Off</mat-radio-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_Sub_DG_Free_Units_On_Off && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <div class="borderchk">
                          <div class="o_b1">
                            <i class="fa fa-database"></i>
                            <B>DG Free Units</B>
                          </div>
                          <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                          <div class="meteractivitychkouter">
                            <div class="meteractivitychk">
                              <mat-radio-button value="F_2">Active</mat-radio-button>
                            </div>
                            <div class="meteractivitychk">
                              <mat-radio-button value="F_1">In-Active</mat-radio-button>
                            </div>
                            <!--</mat-radio-group>-->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_Sub_Emergency_On && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <div class="borderchk">
                          <div class="o_b1">
                            <i class="fa fa-database"></i>
                            <B>Emergency Credit</B>
                          </div>
                          <div class="meteractivitychkouter">
                            <div class="meteractivitychk">
                              <mat-radio-button value="E_2">Active</mat-radio-button>
                            </div>
                            <!--<div>
                                    <mat-radio-button value="F_1">In-Active</mat-radio-button>
                                  </div>
                                  </mat-radio-group>-->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <div class="borderchk">
                          <div class="o_b1">
                            <i class="fa fa-database"></i>
                            <B>Mode</B>
                          </div>
                          <!--<mat-radio-group name="backupSupply" formControlName="backupSupply">-->
                          <div class="meteractivitychkouter">
                            <div class="meteractivitychk">
                              <mat-radio-button value="P_2">Prepaid</mat-radio-button>
                            </div>
                            <div class="meteractivitychk">
                              <mat-radio-button value="P_1">Postpaid</mat-radio-button>
                            </div>
                            <!--</mat-radio-group>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                  <div class="col-sm-12 row">
                    <div class="col-sm-12 text-left">
                      <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                      </ng-container>
                      <!--<div role="alert" *ngIf="message.length > 0">
                            <div class="alert alert-success" role="alert" *ngIf="thumb">
                              <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                            </div>
                            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                              <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                            </div>
                          </div>-->
                    </div>
                    <div class="col-sm-12 text-right form-group">
                      <button type="button" class="dilog dilog1" (click)="otpCheck()">
                        <mat-icon matPrefix>add</mat-icon>
                        Add Activity
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </ng-container>




          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- content viewport ends -->