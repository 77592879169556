import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editmetercommand',
  templateUrl: './editmetercommand.component.html',
  styleUrls: ['./editmetercommand.component.scss']
})
export class EditmetercommandComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  subDivisionId:any;
  meterCmdData:any;
  submitted: boolean = false;

  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';

  
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }

    ngOnInit() { 
      this.addForm = this.formBuilder.group({
      //meterSubDivision: ['', Validators.required],
      commandCode: ['', Validators.required],
      commandName: ['', Validators.required]
      });
      this.aF.commandCode.setValue(this.meterCmdData.meterCmdCode);
      this.aF.commandName.setValue(this.meterCmdData.meterCmdName);
    }

    ngAfterViewInit() {
       
    }
    ngOnDestroy() {
      //this.modalService.dismissAll();
    }
  
    get aF() { return this.addForm.controls; }
    onSubmitAddForm() {
      if (this.addForm.invalid) {
         this.submitted = true;
          return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.meterCmdData.subDivisionId);
      body.set('cmdId',this.meterCmdData.meterid);
      body.set('cmdCode',this.aF.commandCode.value);
      body.set('cmdName',this.aF.commandName.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/editMeterCmd', body).subscribe((result) => {
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.addAuthMessage = this.responseData.data.message;
            setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
        }else{
            this.thumb = false;
        }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.responseMessage = 'Unable to process yours request!';
      });
  }
}
