<div class="modal-content-new">
    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Recent Events: {{meterDetail.mID}}
      </h4>
    </div>
      <div class="modal-body">
        <ng-container>
          <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
            <!--<fa name="spinner" animation="spin" size="4x"></fa>-->
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData && view=='N'">
             <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Cons_Account_No==1">
                    <div class="d_title" >
                      <span></span>Cons UId
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Meter_Unque_Id==1">
                    <div class="d_title">
                      <span></span>Meter UId
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Flat_No_Id==1">
                    <div class="d_title">
                      <span></span>Flat No
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Meter_Id==1">
                    <div class="d_title">
                      <span></span>Meter No
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_RTC==1">
                    <div class="d_title">
                      <span></span>Event Date & Time
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Event_Type_Imp==1">
                    <div class="d_title ">
                      <span></span>Event Type
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Voltage==1">
                    <div class="d_title ">
                      <span></span>Voltage-R
                  </div>
                  </td>
                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Voltage_Y==1">
                    <div class="d_title ">
                      <span></span>Voltage-Y
                  </div>
                  </td>
                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Voltage_B==1">
                    <div class="d_title " >
                      <span></span>Voltage-B
                  </div>
                  </td>

                  <td *ngIf="permission.Single_Event_Parm_Current==1">
                    <div class="d_title ">
                      <span></span>Current-R
                  </div>
                  </td>

                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Current_Y==1">
                    <div class="d_title ">
                      <span></span>Current-Y
                  </div>
                  </td>

                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Current_B==1">
                    <div class="d_title ">
                      <span></span>Current-B
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Power_Factor==1">
                    <div class="d_title " >
                      <span></span>Power Factor-R
                  </div>
                  </td>
                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Power_Factor_Y==1">
                    <div class="d_title " >
                      <span></span>Power Factor-Y
                  </div>
                  </td>
                  <td *ngIf="meterType==3 && permission.Single_Event_Parm_Power_Factor_B==1">
                    <div class="d_title ">
                      <span></span>Power Factor-B
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Active_Energy_Imp==1">
                    <div class="d_title " >
                      <span></span>Cumulative kWh Grid
                  </div>
                  </td>
                  <td  *ngIf="permission.Single_Event_Parm_Apperant_Energy_Imp==1">
                    <div class="d_title " >
                      <span></span>Cumulative kVAh Grid
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Event_Count_Imp==1">
                    <div class="d_title " >
                      <span></span>Cumulative Event Count
                  </div>
                  </td>
                  <td  *ngIf="permission.Single_Event_Parm_Server_Datetime==1">
                    <div class="d_title ">
                      <span></span>Server Datetime
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Authority==1">
                    <div class="d_title ">
                      <span></span>Authority Name
                  </div>
                  </td>
                 
                </tr>
              </thead>
              <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Cons_Account_No==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cUID}}</p>
                  </div>
                </td>
                <td  *ngIf="permission.Single_Event_Parm_Meter_Unque_Id==1">
                  <div class="d_content d_content_W">
                    <p>{{item.mUID}}</p>
                  </div>
                </td>
                <td  *ngIf="permission.Single_Event_Parm_Flat_No_Id==1">
                  <div class="d_content d_content_W">
                    <p>{{item.flatNo}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Meter_Id==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.meterId}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_RTC==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.meterDateTime}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Event_Type_Imp==1">
                  <div class="d_content d_content_W1" >
                    <p>{{item.eventType}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Voltage==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.voltage}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Voltage_Y==1">
                  <div class="d_content d_content_W">
                    <p>{{item.voltageY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Voltage_B==1">
                  <div class="d_content d_content_W">
                    <p>{{item.voltageB}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Current==1">
                  <div class="d_content d_content_W">
                    <p>{{item.current}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Current_Y==1">
                  <div class="d_content d_content_W">
                    <p>{{item.currentY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Current_B==1">
                  <div class="d_content d_content_W">
                    <p>{{item.currentB}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Power_Factor==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.powerFactor}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Power_Factor_Y==1">
                  <div class="d_content d_content_W">
                    <p>{{item.powerFactorY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3 && permission.Single_Event_Parm_Power_Factor_B==1">
                  <div class="d_content d_content_W">
                    <p>{{item.powerFactorB}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Event_Parm_Active_Energy_Imp==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.cumkWhEB}}</p>
                </div>
                </td>
                  <td *ngIf="permission.Single_Event_Parm_Apperant_Energy_Imp==1">
                    <div class="d_content d_content_W">
                      <p>{{item.cumkVAhEB}}</p>
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Event_Count_Imp==1">
                    <div class="d_content d_content_W">
                      <p>{{item.cumEventCount}}</p>
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Server_Datetime==1">
                    <div class="d_content d_content_W">
                      <p>{{item.dateTime}}</p>
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Event_Parm_Authority==1">
                    <div class="d_content d_content_W">
                      <p>{{item.authName}}</p>
                  </div>
                  </td>
              </tr>
            </tbody>
          </table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>         
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->  
          </div>
          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData && view=='Y'">
            <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                 
                  <td>
                    <div class="d_title">
                      <span></span>Event Date & Time
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Event Type
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Voltage-R
                  </div>
                  </td>
                  <td *ngIf="meterType==3">
                    <div class="d_title ">
                      <span></span>Voltage-Y
                  </div>
                  </td>
                  <td *ngIf="meterType==3">
                    <div class="d_title " >
                      <span></span>Voltage-B
                  </div>
                  </td>

                  <td>
                    <div class="d_title " >
                      <span></span>Current-R
                  </div>
                  </td>

                  <td *ngIf="meterType==3 ">
                    <div class="d_title ">
                      <span></span>Current-Y
                  </div>
                  </td>

                  <td *ngIf="meterType==3 ">
                    <div class="d_title ">
                      <span></span>Current-B
                  </div>
                  </td>
                  <td>
                    <div class="d_title " >
                      <span></span>Power Factor-R
                  </div>
                  </td>
                  <td *ngIf="meterType==3">
                    <div class="d_title " >
                      <span></span>Power Factor-Y
                  </div>
                  </td>
                  <td *ngIf="meterType==3">
                    <div class="d_title ">
                      <span></span>Power Factor-B
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Cumulative kWh Grid
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title">
                      <span></span>Cumulative kWh DG
                  </div>
                  </td>
                  <td  *ngIf="view=='Y'" >
                    <div class="d_title " >
                      <span></span>Cumulative kVAh Grid
                  </div>
                  </td>
                  <td *ngIf="view=='Y'" >
                    <div class="d_title ">
                      <span></span>Cumulative kVAh DG
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Cumulative Event Count
                  </div>
                  </td>
                  
                 
                </tr>
              </thead>
            <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.meterDateTime}}</p>
                  </div>
                </td>
                <td>
                  <div class="d_content d_content_W1">
                    <p>{{item.eventType}}</p>
                </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.voltage}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.voltageY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.voltageB}}</p>
                </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.current}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.currentY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.currentB}}</p>
                </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.powerFactor}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.powerFactorY}}</p>
                </div>
                </td>
                <td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.powerFactorB}}</p>
                </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhEB}}</p>
                </div>
                </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_content d_content_W" >
                      <p>{{item.cumkWhDG}}</p>
                  </div>
                  </td>
                  <td  *ngIf="view=='Y'">
                    <div class="d_content d_content_W">
                      <p>{{item.cumkVAhEB}}</p>
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_content d_content_W">
                      <p>{{item.cumkVAhDG}}</p>
                  </div>
                  </td>
                  <!--<td>
                    <div class="d_content d_content_W">
                      <p>{{item.cumEventCount}}</p>
                  </div>
                  </td>-->
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.cumEventCount}}</p>
                  </div>
                  </td>
                  
</tr>
</tbody>
</table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>         
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->  
          </div>

          <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
           
            <div class="col-sm-12">
                <img class="center" src="assets/images/Nodata.png">
              </div>
           
            <!--<div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
          </div>
          
        </ng-container>
    <ng-container col-sm-3>
    </ng-container>
</div>
</div>  