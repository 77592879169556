<div class="containerr register innerpage_top page-content-wrapper ">
  <div class="row cstmWpayment content-viewport">
    <!-- <canvas id="canvas" onclick="shake" class="confettiCanvas"></canvas> -->
    <div class=" page-content-wrapper-inner outerbox_shadow tblH register-right col-md-12">
      <div class="tab-content container-fluid inner-page-contentt">
        <div class="row">
          <div class="col-md-6 mr-auto col-sm-12 bgwhitout">
            <div class="tab-pane">
              <h3 class="register-heading">Recharge</h3>
              <div class="register-form">
                <form ngNoForm role="form" action="https://secure.payu.in/_payment" name="payuform" method="POST">
                  <!--<form ngNoForm role="form" action="https://test.payu.in/_payment" name="payuform" method="POST">
                      <!--hidden values-->
                  <input type="hidden" class="form-control" id="productinfo" name="productinfo"
                    [(ngModel)]="payuform.productinfo">
                  <input type="hidden" class="form-control" id="udf1" name="udf1" [(ngModel)]="payuform.udf1">
                  <input type="hidden" class="form-control" id="udf2" name="udf2" [(ngModel)]="payuform.udf2">
                  <input type="hidden" class="form-control" id="udf3" name="udf3" [(ngModel)]="payuform.udf3">
                  <input type="hidden" class="form-control" id="udf4" name="udf4" [(ngModel)]="payuform.udf4">
                  <input type="hidden" class="form-control" id="udf5" name="udf5" [(ngModel)]="payuform.udf5">
                  <!--<input type="hidden" class="form-control" id="udf6" name="udf6" [(ngModel)]="payuform.udf6">
                      <input type="hidden" class="form-control" id="udf7" name="udf7" [(ngModel)]="payuform.udf7">-->


                  <input type="hidden" class="form-control" id="email" name="email" [(ngModel)]="payuform.email">
                  <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.surl"></textarea>
                  <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.furl"></textarea>
                  <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.key"></textarea>
                  <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.hash"></textarea>
                  <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.txnid"></textarea>
                  <textarea name="service_provider" id="service_provider" rows="2" cols="2" hidden
                    [(ngModel)]="payuform.service_provider"></textarea>
                  <!--hidden values-->
                  <div class="form-group">
                    <div class="form-group input-group">
                      <span class="has-float-label ">
                        <!--<input placeholder="Mobile Number" id="phone" name="phone" class="form-control"
                          [(ngModel)]="payuform.phone" readonly>
                        <i class="fa fa-phone icon_innertxtbox"></i>-->
                       
                        <mat-select [(ngModel)]="payuform.rechargeType" (selectionChange)="changeRechargeType(payuform.rechargeType)" class="form-control">
                          <mat-option value="0">Electricity</mat-option>
                          <!--<mat-option value="1">Maintenence</mat-option>-->
                        </mat-select>
                        <i class="fa fa-keyboard-o icon_innertxtbox"></i>
                        <label>Recharge Type</label>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group input-group">
                      <span class="has-float-label ">
                        <input placeholder="Mobile Number" id="phone" name="phone" class="form-control"
                          [(ngModel)]="payuform.phone" readonly>
                        <i class="fa fa-phone icon_innertxtbox"></i>
                        <label>Mobile Number</label>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group input-group">
                      <span class="has-float-label ">
                        <input placeholder="User Name" id="firstname" name="firstname" class="form-control"
                          [(ngModel)]="payuform.firstname" readonly>
                        <i class="fa fa-user icon_innertxtbox"></i>
                        <label>User Name</label>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="iform-group input-group">
                      <span class="has-float-label ">
                        <input (keypress)="numberOnly($event)" class="form-control" type="number" min="1" max="99999"
                          placeholder="Enter Amount" id="amount" name="amount" [(ngModel)]="payuform.amount">
                        <i class="fa fa-user icon_innertxtbox"></i>
                        <label>Enter Amount</label>
                        <span matSuffix *ngIf="currentUserPlan.length > 0">
                          <button type="button" mat-button color="primary" [matMenuTriggerFor]="priceList">
                            View Plans
                          </button>
                          <mat-menu #priceList="matMenu" xPosition="before" class="notifications-dropdown">
                            <mat-list role="list" *ngFor="let item of currentUserPlan">
                              <mat-list-item role="listitem">
                                <button type="button" mat-menu-item (click)="setAmount(item.dataValue)"><i
                                    class="fa fa-inr"></i>&nbsp;{{item.dataValue}}</button>
                              </mat-list-item>
                            </mat-list>
                          </mat-menu>
                        </span>
                        <!--<mat-error *ngIf="payuform.amount.hasError('required')">
                                  Amount is <strong>required</strong>
                                </mat-error>-->
                      </span>

                      <!-- <table width="100%">
                                  <tr>
                                    <td>
                                      <B>Sub-Total:-</B>
                                    </td>
                                    <td align="right">
                                      <span matPrefix>
                                        <i class="fa fa-inr"></i>&nbsp;{{payuform.amount}}
                                    </span>
                                      
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <B> Internet Handeling Charges (2%):-</B>
                                    </td>
                                    <td align="right">
                                      <span matPrefix>
                                        <i class="fa fa-inr"></i>&nbsp;{{((payuform.amount)*2/100)}}
                                    </span>
                                      
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                    <B> Total Payable:-</B>
                                    </td>
                                    <td align="right">
                                      <span matPrefix>
                                        <i class="fa fa-inr"></i>&nbsp;{{(((payuform.amount)*2/100)--(payuform.amount))}}
                                    </span>
                                      
                                    </td>
                                  </tr>
                                </table>-->
                    </div>
                  </div>
                  
                  <div class="col-12 text-right">
                    <button *ngIf="disablePaymentButton" type="button" class="dilog dilog1 wrechargefull"
                      (click)="confirmPayment()">
                      <!-- <i class="fa fa-money"></i> -->
                      Pay
                    </button>
                    <button *ngIf="!disablePaymentButton" type="submit" class="dilog dilog1 wrechargefull">

                      Pay Now</button>
                    <!-- <button *ngIf="!disablePaymentButton" type="submit" class="dilog dilog1 wrechargefull"
                      onmousedown="party.confetti(this)" (click)="specialofferbtn()">

                      Pay Now</button> -->
                  </div>
                  <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                      <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && responseMessage.length > 0">
                      <div class="alert alert-success" role="alert" *ngIf="thumb">
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
                      </div>
                      <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                        <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-12 bgwhitout">
            <div class="panel rechargeHistoryLits">
              <div class="rechargesHistory">
                <div class="rechargHistoryimg">
                  <img src="assets/img/recentpaymentt.png">
                </div>
                <h3 class="register-headingg"> Recent Recharge's</h3>
                <div *ngFor="let recharge of rechargeHistory">
                  <div class="pastRecharge text-primary">
                    <div class="subFlex inr">
                      <p><i class="fa fa-inr"></i>
                        {{recharge.amount}}</p>
                    </div>
                    <div class="subFlex date">
                      <p><i class="fa fa-calendar"></i> {{recharge.date}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="auth_footer">
    <p class="text-muted text-center">© Avonsm</p>
  </div> -->
</div>
<!-- <div class="authentication-theme auth-style_1 authLoginAccessNew  register innerpage_top">
  <div class="row cstmWpayment">
    <div class="register-right col-md-12">
      <div class="tab-content" id="myTabContent">
        <div class="row">
          <div class="col-md-6">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="panell-bodyy">
                  <h3 class="register-heading">Recharge</h3>
                    <form ngNoForm role="form" action="https://secure.payu.in/_payment" name="payuform" method="POST">
                      <input type="hidden" class="form-control" id="productinfo" name="productinfo" [(ngModel)]="payuform.productinfo">
                      <input type="hidden" class="form-control" id="udf1" name="udf1" [(ngModel)]="payuform.udf1">
                      <input type="hidden" class="form-control" id="udf2" name="udf2" [(ngModel)]="payuform.udf2">
                      <input type="hidden" class="form-control" id="udf3" name="udf3" [(ngModel)]="payuform.udf3">
                      <input type="hidden" class="form-control" id="udf4" name="udf4" [(ngModel)]="payuform.udf4">
                      <input type="hidden" class="form-control" id="udf5" name="udf5" [(ngModel)]="payuform.udf5">
                      

                      <input type="hidden" class="form-control" id="email" name="email" [(ngModel)]="payuform.email">
                      <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden [(ngModel)]="payuform.surl"></textarea>
                      <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden [(ngModel)]="payuform.furl"></textarea>
                      <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden [(ngModel)]="payuform.key"></textarea>
                      <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden [(ngModel)]="payuform.hash"></textarea>
                      <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden [(ngModel)]="payuform.txnid"></textarea>
                      <textarea name="service_provider" id="service_provider" rows="2" cols="2" hidden [(ngModel)]="payuform.service_provider"></textarea>
                    <div class="form-group">
                        <div class="input-group"> 
                            <mat-form-field>
                                <input matInput placeholder="Mobile Number" id="phone" name="phone" [(ngModel)]="payuform.phone" readonly>
                                <span matPrefix>
                                    <i class="fa fa-mobile"></i>&nbsp;
                                  </span>
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <mat-form-field>
                                <input matInput  placeholder="User Name" id="firstname" name="firstname" [(ngModel)]="payuform.firstname" readonly>
                                <span matPrefix>
                                    <i class="fa fa-user"></i>&nbsp;
                                </span>
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <mat-form-field>
                                <input (keypress)="numberOnly($event)" type="number"  min="1" max="99999" matInput placeholder="Enter Amount" id="amount" name="amount" [(ngModel)]="payuform.amount">
                                <span matPrefix>
                                    <i class="fa fa-inr"></i>&nbsp;
                                </span>
                                <span matSuffix *ngIf="currentUserPlan.length > 0" >
                                    <button type="button" mat-button color="primary" [matMenuTriggerFor]="priceList">
                                      View Plans
                                    </button>
                                      <mat-menu #priceList="matMenu" xPosition="before" class="notifications-dropdown">
                                          <mat-list role="list" *ngFor="let item of currentUserPlan">
                                            <mat-list-item role="listitem">
                                                <button type="button" mat-menu-item (click)="setAmount(item.dataValue)"><i class="fa fa-inr"></i>&nbsp;{{item.dataValue}}</button>
                                            </mat-list-item>
                                          </mat-list>
                                      </mat-menu>
                                </span>
                               
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                    <button *ngIf="disablePaymentButton" type="button" class="dilog dilog1" (click)="confirmPayment()">
                      <mat-icon matPrefix>
                        trending_up
                      </mat-icon>
                      Pay 
                    </button>
                    <button *ngIf="!disablePaymentButton" type="submit" class="dilog dilog1">
                      <mat-icon matPrefix>
                        add
                      </mat-icon>
                      Pay now</button>
                  </div>
                  </form>
                </div>
                
            </div>
            
        </div>
        <div class="panel rechargeHistoryLits col-xs-12 col-md-12">
                  <div class="panel-body" style="height: 33vh; border-radius: 3px 3px 0 0 !important;">
                    <div class="rechargeTitle sub-title">
                      <mat-icon matPrefix>
                        receipt
                      </mat-icon>
                      Recent Recharge's
                    </div>
                    <div class="rechargesHistory">
                      <div *ngFor="let recharge of rechargeHistory" >
                            <mat-card class="pastRecharge text-primary">
                                <i class="fa fa-inr"></i>&nbsp; {{recharge.amount}}
                                <div>{{recharge.date}}</div>
                            </mat-card>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div> 
  <div class="auth_footer">
    <p class="text-muted text-center">© Avonsm</p>
  </div>
</div>-->