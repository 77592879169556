import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigdcugateid',
  templateUrl: './meterconfigdcugateid.component.html',
  styleUrls: ['./meterconfigdcugateid.component.scss']
})
export class MeterconfigdcugateidComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      dcugid: ['', Validators.required],
      dcugcno: ['', Validators.required],
    });
 
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}