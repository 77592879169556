import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from '../../_services/modal.service';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-progresstilldatenew',
  templateUrl: './progresstilldatenew.component.html',
  styleUrls: ['./progresstilldatenew.component.scss']
})
export class ProgresstilldatenewComponent implements OnInit {



  messageResult: any;
  loading: boolean = false;
  loadingFirstTime: boolean = true;
  enableSocietyChange: boolean = true;
  selectedSubDivision: any;
  currentUser: User;
  responseData: any;
  currentUserSubscription: Subscription;
  dataSource: any = new MatTableDataSource([]);
  loadingMessage: any = '';
  levelSearch: string = '';
  faSpinner = faSpinner;
  length = 0;
  levelHeadsCols =[];
  levelColumnsToDisplay= [];

  levelHeadsColsCircle = [
    { label: "Sr No", name: "srNo", checked: true },
    { label: "Circle", name: "conCircle", checked: true },
   //{ label: "Proposed", name: "totalProposed", checked: true },
    { label: "3-Ph Proposed", name: "total3PhProposed", checked: true },
    { label: "Meter Installed", name: "totalMeterCount", checked: true },
    { label: "Today's Meter Installed", name: "dailyTotalMeterCount", checked: true },
    { label: "3-PH Balanced", name: "total3PhBalance", checked: true },
   
    { label: "LTCT Proposed", name: "totalLTCTProposed", checked: true },
    { label: "LTCT Installed", name: "totalMeterCountLTCT", checked: true },
    { label: "Today's LTCT Installed", name: "dailyTotalMeterCountLTCT", checked: true },
    { label: "LTCT Balanced", name: "totalLTCTBalance", checked: true },
  ];

  levelColumnsToDisplayCircle = [
    'srNo',
    'conCircle',
    //'totalProposed',
    'total3PhProposed',
    
    'totalMeterCount',
    'dailyTotalMeterCount',
    'total3PhBalance',
    'totalLTCTProposed',
   
    'totalMeterCountLTCT',
    'dailyTotalMeterCountLTCT',
    'totalLTCTBalance'
  ];

  levelHeadsColsDivision = [
    { label: "Sr No", name: "srNo", checked: true },
    { label: "Circle", name: "conCircle", checked: true },
    { label: "Division", name: "conDivision", checked: false },
    { label: "Total Meter Installed", name: "totalMeterCount", checked: true },
    { label: "Server Date & Time", name: "Server_date_Time", checked: true },
  ];

  levelColumnsToDisplayDivision = [
    'srNo',
    'conCircle',
    'conDivision',
    'totalMeterCount',
    'Server_date_Time',
  ];

  
  levelHeadsColsAuthority = [
    { label: "Sr No", name: "srNo", checked: true },
    { label: "Circle", name: "conCircle", checked: true },
    { label: "Division", name: "conDivision", checked: false },
    { label: "Total Meter Installed", name: "totalMeterCount", checked: true },
    { label: "Server Date & Time", name: "Server_date_Time", checked: true },
    { label: "Authority", name: "authName", checked: true },
  ];

  levelColumnsToDisplayAuthority = [
    'srNo',
    'conCircle',
    'conDivision',
    'totalMeterCount',
    'Server_date_Time',
    'authName',
  ];


  removeProcess: boolean = false;
  responseMessage: string = '';
  allLevelTotalRecord: number = 0;
  //pagination settings
  limit: number = 20;
  skip: number = 0;
  filter = '';
  totalLength: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 30];
  getThrough: Access;

  defaultCircle: number = 0;
  defaultDivision: number = 0;
  defaultDC: number = 0;
  defaultFeeder: number = 0;
  defaultGr: number = 0;
  defaultRd: number = 0;
  defaultAuth: number = 0;

  circleData: any = [{
    'id': 0,
    'name': 'Select Circle'
  }];

  divisionData: any = [{
    'id': 0,
    'name': 'Select Division'
  }];

  dcData: any = [{
    'id': 0,
    'name': 'Select DC'
  }];

  feederData: any = [{
    'id': 0,
    'name': 'Select Feeder'
  }];

  grData: any = [{
    'id': 0,
    'name': 'Select GR'
  }];

  rdData: any = [{
    'id': 0,
    'name': 'Select RD'
  }];

  authData: any = [{
    'id': 0,
    'name': 'Select Authority'
  }];
  countryId: any = 0;
  stateId: any = 0;
  utilityId: any = 0;
  circleId: any = 0;
  divisionId: any = 0;
  subdivisionId: any = 0;
  authToken: any = '';
  subDivName: any = 0;
  constructor(
    private route: ActivatedRoute,
    public modal: NgbActiveModal,
    private modalService: ModalService,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    if ('7' == '7') {
      this.enableSocietyChange = false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.selectedSubDivision = params['subDivisionId'];
      this.countryId = params['countryId'];
      this.stateId = params['stateId'];
      this.utilityId = params['utilityId'];
      this.circleId = params['circleId'];
      this.divisionId = params['divisionId'];
      this.subDivName= params['subDivName'];
      this.authToken = params['authToken'];
    });

    this.getCircleData();
    this.getMeterCommandListing();
    this.levelHeadsCols =this.levelHeadsColsCircle;
    this.levelColumnsToDisplay= this.levelColumnsToDisplayCircle;
    
    //console.log("Default Circle",this.defaultCircle);
    
  }
  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  whichSubDomain() {
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
        this.getMeterCommandListing();
      }
      );
  }


  onLevelSearchChange(search) {
    this.levelSearch = search;
    this.skip = 0;
    this.getMeterCommand(false);
  }

  getMeterCommand(addNew: boolean = false) {
    let body = new URLSearchParams();
    this.loading = true;
   
      body.set('meterSubDivision', this.selectedSubDivision);
    
    body.set('defaultCircle', this.defaultCircle.toString());
    body.set('defaultDivision', this.defaultDivision.toString());
    body.set('defaultDC', this.defaultDC.toString());
    body.set('defaultFeeder', this.defaultFeeder.toString());
    body.set('defaultGr', this.defaultGr.toString());
    body.set('defaultRd', this.defaultRd.toString());
    body.set('defaultAuth', this.defaultAuth.toString());

    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getInstalledMeter', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      if (this.responseData.success) {
        //console.log(this.responseData.data);
        this.dataSource = this.responseData.data;
        this.length = this.responseData.datalimit.totalResult;
        if (this.length < 10) {
          this.limit = this.length;
        }

      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  getDivisionId(addNew: boolean = false, circleId:number) {
    let body = new URLSearchParams();
   
    body.set('subdivId', this.selectedSubDivision);
  
    body.set('circleId', circleId.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getDivisionData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        //console.log(this.responseData.data);
        this.divisionData = this.responseData.data.divisionData;
        this.authData = this.responseData.data.authData;
        
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable", "Meter Commands Data");
  }

  getMeterCommandListing() {
    this.loading = true;
    let body = new URLSearchParams();
   
      body.set('meterSubDivision', this.selectedSubDivision);
    
    body.set('defaultCircle', this.defaultCircle.toString());
    body.set('defaultDivision', this.defaultDivision.toString());
    body.set('defaultDC', this.defaultDC.toString());
    body.set('defaultFeeder', this.defaultFeeder.toString());
    body.set('defaultGr', this.defaultGr.toString());
    body.set('defaultRd', this.defaultRd.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getInstalledMeter', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      this.loadingFirstTime = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        //console.log(this.responseData.data);
        setTimeout(() => {
          this.dataSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if (this.length < 10) {
            this.limit = this.length;
          }
        });
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    }
  }

  selectChangeCircle(defaultCircle) {
    this.defaultCircle = defaultCircle;
    this.skip = 0;
    if( this.defaultCircle!=0){
      this.levelHeadsCols =this.levelHeadsColsDivision;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayDivision;
    }else{
      this.levelHeadsCols =this.levelHeadsColsCircle;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayCircle;
    }
    //console.log("Default Circle",this.defaultCircle);
    this.getDivisionId(true, this.defaultCircle);
    this.getMeterCommand(true);
  }

  selectChangeDivision(defaultDivision) {
    this.defaultDivision = defaultDivision;
    this.skip = 0;
    if(this.defaultDivision!=0){
      this.levelHeadsCols =this.levelHeadsColsDivision;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayDivision;
    }else{
      this.levelHeadsCols =this.levelHeadsColsCircle;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayCircle;
    }
    this.getMeterCommand(true);
  }

  selectChangeDC(defaultDC) {
    this.defaultDC = defaultDC;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeFeeder(defaultFeeder) {
    this.defaultFeeder = defaultFeeder;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeGr(defaultGr) {
    this.defaultGr = defaultGr;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeRd(defaultRd) {
    this.defaultRd = defaultRd;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeAuth(defaultAuth) {
    this.defaultAuth = defaultAuth;
    this.skip = 0;
    if(this.defaultAuth!=0){
      this.levelHeadsCols =this.levelHeadsColsAuthority;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayAuthority;
    }else{
      this.levelHeadsCols =this.levelHeadsColsCircle;
      this.levelColumnsToDisplay= this.levelColumnsToDisplayCircle;
    }
    this.getMeterCommand(true);
  }
  

  getCircleData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.selectedSubDivision);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('dgId', '');
    body.set('towerId', '');
    body.set('groupId', '');
    body.set('filter', '');
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getCircleData', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.circleData = this.responseData.data.circleData;
        this.divisionData = this.responseData.data.divisionData;
        this.dcData = this.responseData.data.dcData;
        this.feederData = this.responseData.data.feederData;
        this.grData = this.responseData.data.grData;
        this.rdData = this.responseData.data.rdData;
        this.authData = this.responseData.data.authData;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }


  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [10, 20, 40, 80, 160, this.length];
    } else if (this.length > 320) {
      return [10, 20, 40, 80, 160, 320, this.length];
    }
  }


  addmetercommand() {
    this.modalService.addMeterCommand(this.selectedSubDivision).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  editmetercommand(row) {
    this.modalService.editMeterCommand(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  downloadOldMeterImage(row) {
    window.open(row.OldMeterImgAdd, "_blank");
  }

  downloadNewMeterImage(row) {
    window.open(row.newMeterImgAdd, "_blank");
  }





  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for (let col of this.levelHeadsCols) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  changePage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      if (this.allLevelTotalRecord > 0) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.getMeterCommandListing();
        // }
      }
    }
  }
}
