
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
          <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
            data-dismiss="modal">&times;</button>
            Edit Superviser
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Superviser Full Name" name="superFullName"
                            formControlName="superFullName" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superFullName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Superviser Full Name</label>
                            <div *ngIf="submitted && aD.superFullName.errors" class="invalid-feedback">
                                <div *ngIf="aD.superFullName.errors.required">
                                    Superviser Full Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Superviser User Name" name="superUserName" formControlName="superUserName" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superUserName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Superviser User Name</label>
                            <div *ngIf="submitted && aD.superUserName.errors" class="invalid-feedback">
                                <div *ngIf="aD.superUserName.errors.required">
                                    Superviser User Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                        <input type="password" placeholder="Superviser Password" name="superPassword"
                            formControlName="superPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superPassword.errors}" required>
                            <i class="fa fa-lock icon_innertxtbox"></i>
                            <label>Superviser Password</label>
                            <div *ngIf="submitted && aD.superPassword.errors" class="invalid-feedback">
                                <div *ngIf="aD.superPassword.errors.required">
                                    Superviser Password is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  type="email"placeholder="Superviser Email Id" name="superEmailId" 
                            formControlName="superEmailId"
                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superEmailId.errors}" required>
                            <i class="fa fa-envelope icon_innertxtbox"></i>
                            <label>Superviser Email Id</label>
                            <div *ngIf="submitted && aD.superEmailId.errors" class="invalid-feedback">
                                <div *ngIf="aD.superEmailId.errors.required">
                                    Superviser Email Id is required
                                </div>
                                <div *ngIf="aD.superEmailId.hasError('email')">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Superviser Landline No" name="superLandlineNo" formControlName="superLandlineNo" 
                            pattern="[0-9 -()+]+$" minlength="10" maxlength="10"class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superLandlineNo.errors}" required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Superviser Landline No</label>
                            <div *ngIf="submitted && aD.superLandlineNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.superLandlineNo.errors.required">
                                    Superviser Landline is required
                                </div>
                                <div *ngIf="aD.superLandlineNo.hasError('pattern')">
                                    Superviser Landline number not valid. 
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel"  placeholder="Superviser Mobile No"  name="superMobileNo"  formControlName="superMobileNo" 
                            pattern="^[6-9]\d{9}$" minlength="10" maxlength="10" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superMobileNo.errors}" required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Superviser Mobile No</label>
                            <div *ngIf="submitted && aD.superMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.superMobileNo.errors.required">
                                    Superviser Mobile No is required
                                </div>
                                <div *ngIf="aD.superMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Superviser Home Address Line 1" name="superHomeAddressline1"
                            formControlName="superHomeAddressline1" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superHomeAddressline1.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Superviser Home Address Line 1</label>
                            <div *ngIf="submitted && aD.superHomeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.superHomeAddressline1.errors.required">
                                    Superviser Home Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Superviser Home Address Line 2" name="superHomeAddressline2"
                            formControlName="superHomeAddressline2" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superHomeAddressline2.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Superviser Home Address Line 2</label>
                            <div *ngIf="submitted && aD.superHomeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.superHomeAddressline2.errors.required">
                                    Superviser Home Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Superviser Office Address Line 1" name="superOfficeAddressline1"
                            formControlName="superOfficeAddressline1" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superOfficeAddressline1.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Superviser Office Address Line 1</label>
                            <div *ngIf="submitted && aD.superOfficeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.superOfficeAddressline1.errors.required">
                                    Superviser Office Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Superviser Office Address Line 2" name="superOfficeAddressline2"
                            formControlName="superOfficeAddressline2" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superOfficeAddressline2.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Superviser Office Address Line 2</label>
                            <div *ngIf="submitted && aD.superOfficeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.superOfficeAddressline2.errors.required">
                                    Superviser Office Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Avtive Status" name="superActiveStatus"
                                formControlName="superActiveStatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.superActiveStatus.errors}" required>
                                <mat-option value="Y">Active</mat-option>
                                <mat-option value="N">In-Active</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Avtive Status</label>
                            <div *ngIf="submitted && aD.superActiveStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.superActiveStatus.errors.required">
                                    Superviser Active Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authorityDOB.errors}" required>
                                <i class="fa fa-calendar icon_innertxtbox"></i>
                                <label>Authority DOB</label>
                                <div *ngIf="submitted && aD.authorityDOB.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authorityDOB.errors.required">
                                        Authority DOB is required
                                   </div>
                            </div>
                        </span>
                    </div>
                </div>
                    <div class="col-md-6">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="SMS Receive Status" name="authoritySMSReceiveStatus"
                                    formControlName="authoritySMSReceiveStatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authoritySMSReceiveStatus.errors}" required>
                                    <mat-option value="Y">Active</mat-option>
                                    <mat-option value="N">In-Active</mat-option>
                                </mat-select>
                                <i class="fa fa-envelope icon_innertxtbox"></i>
                                <label>SMS Receive Status</label>
                                <div *ngIf="submitted && aD.authoritySMSReceiveStatus.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authoritySMSReceiveStatus.errors.required">
                                        Authority SMS Receive Status is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>-->
           
            <!-- Grid row -->
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                      <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                      <div role="alert" *ngIf="!isLoading && message.length > 0" >
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                          <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                          <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                      </div>
                    
                </div>
                 
                <div class="col-sm-12 text-right">
                  <button type="submit" class="dilog dilog1">
                    <mat-icon>edit</mat-icon>
                    Submit
                  </button>
                  <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                    <mat-icon >cancel</mat-icon>
                    Cancel
                  </button>
    
                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->