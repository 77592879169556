import { Component, Input, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-meterconfigaeb-slab',
  templateUrl: './meterconfigaeb-slab.component.html',
  styleUrls: ['./meterconfigaeb-slab.component.scss']
})
export class MeterconfigaebSlabComponent implements OnInit {
  submitted: boolean = false;
  faSpinner = faSpinner;
  configForm: UntypedFormGroup;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  eBUniteRateSelectionType: string = '0';
  dgUniteRateSelectionType: string = '0';
  cmdformData: any = 0;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;
  myDate = new Date();
  
  cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');
  
  startDate = '';
  optionsSelect: Array<any>;
  @Input() meterDetail:any = {
    mID: "All",
    dID:0,
    sdID:0
  };

  dedType: any = [
    {'id':0, 'Name':'Daily'}
    //{'id':1, 'Name':'Monthly'}
  ];


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.optionsSelect = [
      { value: '1', label: 'Option 1' },
      { value: '2', label: 'Option 2' },
      { value: '3', label: 'Option 3' },
    ];
    
    this.configForm = this.formBuilder.group({
      alarmLevel: ['', Validators.required],
      emergencycredit: ['', Validators.required],
      //deductionType: ['', Validators.required],
      //eBFlatUnitRate: ['', Validators.required],
      //dGUnitRate: ['', Validators.required],
      fixedChargeGrid: ['', Validators.required],
      fixedChargeDG: ['', Validators.required],
      vendingCharge: ['', Validators.required],
      commonAreaMaintCharge: ['', Validators.required],
      gridMaintCharge: ['', Validators.required],
      dgMaintCharge: ['', Validators.required],
      clubCharge: ['', Validators.required],
      waterCharge: ['', Validators.required],
      otherCharge: ['', Validators.required],
      serviceTax: ['', Validators.required],
      
      ebSlabStartUnit1: ['0'],
      ebSlabEndUnit1: [''],
      unitRate1: [''],
      dgSlabStartUnit1: ['0'],
      dgSlabEndUnit1: [''],
      unitRateDG1: [''],

      ebSlabStartUnit2: [''],
      ebSlabEndUnit2: [''],
      unitRate2: [''],
      dgSlabStartUnit2: [''],
      dgSlabEndUnit2: [''],
      unitRateDG2: [''],

      ebSlabStartUnit3: [''],
      ebSlabEndUnit3: [''],
      unitRate3: [''],
      dgSlabStartUnit3: [''],
      dgSlabEndUnit3: [''],
      unitRateDG3: [''],

      ebSlabStartUnit4: [''],
      ebSlabEndUnit4: [''],
      unitRate4: [''],
      dgSlabStartUnit4: [''],
      dgSlabEndUnit4: [''],
      unitRateDG4: [''],

      ebSlabStartUnit5: [''],
      ebSlabEndUnit5: [''],
      unitRate5: [''],
      dgSlabStartUnit5: [''],
      dgSlabEndUnit5: [''],
      unitRateDG5: [''],

      ebSlabStartUnit6: [''],
      ebSlabEndUnit6: [''],
      unitRate6: [''],
      dgSlabStartUnit6: [''],
      dgSlabEndUnit6: [''],
      unitRateDG6: [''],

      ebSlabStartUnit7: [''],
      ebSlabEndUnit7: [''],
      unitRate7: [''],
      dgSlabStartUnit7: [''],
      dgSlabEndUnit7: [''],
      unitRateDG7: [''],

      ebSlabStartUnit8: [''],
      ebSlabEndUnit8: [''],
      unitRate8: [''],
      dgSlabStartUnit8: [''],
      dgSlabEndUnit8: [''],
      unitRateDG8: [''],



      schedularStartEndDate: [this.myDate, Validators.required],
    });
    if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
      this.getCurrentMeterConfig();
      this.cmdformData=1;
    }
  }
  get cF() { return this.configForm.controls; }

  getCurrentMeterConfig() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/getBillingTeriffSlab', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
            //console.log(this.responseData.data);
            this.cF.alarmLevel.setValue(this.responseData.data.alarmLevel);
            this.cF.emergencycredit.setValue(this.responseData.data.emergCreditLimit);
            //this.cF.deductionType.setValue(this.responseData.data.fixDeductionType);
            //this.cF.eBFlatUnitRate.setValue(this.responseData.data.eBUnitRate);
            //this.cF.dGUnitRate.setValue(this.responseData.data.dGUnitRate);
            this.cF.fixedChargeGrid.setValue(this.responseData.data.eBFixCharges);
            this.cF.fixedChargeDG.setValue(this.responseData.data.dGFixCharges);
            this.cF.vendingCharge.setValue(this.responseData.data.vendingRate);
            this.cF.commonAreaMaintCharge.setValue(this.responseData.data.commonAreaMaintRate);
            this.cF.gridMaintCharge.setValue(this.responseData.data.gridMaintRate);
            this.cF.dgMaintCharge.setValue(this.responseData.data.dgMaintRate);
            this.cF.clubCharge.setValue(this.responseData.data.clubRate);
            this.cF.waterCharge.setValue(this.responseData.data.waterRate);
            this.cF.otherCharge.setValue(this.responseData.data.otherChargeRate);
            this.cF.serviceTax.setValue(this.responseData.data.serviceTax);

            this.cF.ebSlabStartUnit1.setValue(this.responseData.data.ebSlabStartUnit1);
            this.cF.ebSlabEndUnit1.setValue(this.responseData.data.ebSlabEndUnit1);
            this.cF.unitRate1.setValue(this.responseData.data.unitRate1);
            this.cF.dgSlabStartUnit1.setValue(this.responseData.data.dgSlabStartUnit1);
            this.cF.dgSlabEndUnit1.setValue(this.responseData.data.dgSlabEndUnit1);
            this.cF.unitRateDG1.setValue(this.responseData.data.unitRateDG1);

            this.cF.ebSlabStartUnit2.setValue(this.responseData.data.ebSlabStartUnit2);
            this.cF.ebSlabEndUnit2.setValue(this.responseData.data.ebSlabEndUnit2);
            this.cF.unitRate2.setValue(this.responseData.data.unitRate2);
            this.cF.dgSlabStartUnit2.setValue(this.responseData.data.dgSlabStartUnit2);
            this.cF.dgSlabEndUnit2.setValue(this.responseData.data.dgSlabEndUnit2);
            this.cF.unitRateDG2.setValue(this.responseData.data.unitRateDG2);

            this.cF.ebSlabStartUnit3.setValue(this.responseData.data.ebSlabStartUnit3);
            this.cF.ebSlabEndUnit3.setValue(this.responseData.data.ebSlabEndUnit3);
            this.cF.unitRate3.setValue(this.responseData.data.unitRate3);
            this.cF.dgSlabStartUnit3.setValue(this.responseData.data.dgSlabStartUnit3);
            this.cF.dgSlabEndUnit3.setValue(this.responseData.data.dgSlabEndUnit3);
            this.cF.unitRateDG3.setValue(this.responseData.data.unitRateDG3);

            this.cF.ebSlabStartUnit4.setValue(this.responseData.data.ebSlabStartUnit4);
            this.cF.ebSlabEndUnit4.setValue(this.responseData.data.ebSlabEndUnit4);
            this.cF.unitRate4.setValue(this.responseData.data.unitRate4);
            this.cF.dgSlabStartUnit4.setValue(this.responseData.data.dgSlabStartUnit4);
            this.cF.dgSlabEndUnit4.setValue(this.responseData.data.dgSlabEndUnit4);
            this.cF.unitRateDG4.setValue(this.responseData.data.unitRateDG4);
          
            this.cF.ebSlabStartUnit1.setValue(this.responseData.data.ebSlabStartUnit1);
            this.cF.ebSlabEndUnit1.setValue(this.responseData.data.ebSlabEndUnit1);
            this.cF.unitRate1.setValue(this.responseData.data.unitRate1);
            this.cF.dgSlabStartUnit1.setValue(this.responseData.data.dgSlabStartUnit1);
            this.cF.dgSlabEndUnit1.setValue(this.responseData.data.dgSlabEndUnit1);
            this.cF.unitRateDG1.setValue(this.responseData.data.unitRateDG1);

            this.cF.ebSlabStartUnit5.setValue(this.responseData.data.ebSlabStartUnit5);
            this.cF.ebSlabEndUnit5.setValue(this.responseData.data.ebSlabEndUnit5);
            this.cF.unitRate5.setValue(this.responseData.data.unitRate5);
            this.cF.dgSlabStartUnit5.setValue(this.responseData.data.dgSlabStartUnit5);
            this.cF.dgSlabEndUnit5.setValue(this.responseData.data.dgSlabEndUnit5);
            this.cF.unitRateDG5.setValue(this.responseData.data.unitRateDG5);

            this.cF.ebSlabStartUnit6.setValue(this.responseData.data.ebSlabStartUnit6);
            this.cF.ebSlabEndUnit6.setValue(this.responseData.data.ebSlabEndUnit6);
            this.cF.unitRate6.setValue(this.responseData.data.unitRate6);
            this.cF.dgSlabStartUnit6.setValue(this.responseData.data.dgSlabStartUnit6);
            this.cF.dgSlabEndUnit6.setValue(this.responseData.data.dgSlabEndUnit6);
            this.cF.unitRateDG6.setValue(this.responseData.data.unitRateDG6);

            this.cF.ebSlabStartUnit7.setValue(this.responseData.data.ebSlabStartUnit7);
            this.cF.ebSlabEndUnit7.setValue(this.responseData.data.ebSlabEndUnit7);
            this.cF.unitRate7.setValue(this.responseData.data.unitRate7);
            this.cF.dgSlabStartUnit7.setValue(this.responseData.data.dgSlabStartUnit7);
            this.cF.dgSlabEndUnit7.setValue(this.responseData.data.dgSlabEndUnit7);
            this.cF.unitRateDG7.setValue(this.responseData.data.unitRateDG7);

            this.cF.ebSlabStartUnit8.setValue(this.responseData.data.ebSlabStartUnit8);
            this.cF.ebSlabEndUnit8.setValue(this.responseData.data.ebSlabEndUnit8);
            this.cF.unitRate8.setValue(this.responseData.data.unitRate8);
            this.cF.dgSlabStartUnit8.setValue(this.responseData.data.dgSlabStartUnit8);
            this.cF.dgSlabEndUnit8.setValue(this.responseData.data.dgSlabEndUnit8);
            this.cF.unitRateDG8.setValue(this.responseData.data.unitRateDG8);




           // this.cF.schedularStartEndDate.setValue(this.cValue);
           // console.log("date",this.cValue);
            //console.log(this.myDate);
            //##to do fill form data from response data with setvalue
        } else {
            //console.log(this.responseData.message);//this.responseData.isresponse;
        }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }

  onSubmitConfigMeter() {
    //alert("hi");

    if (this.configForm.invalid) {
      this.submitted = true;
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);

    if(this.meterDetail.sdID==''){
      formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
      formData.append('configSubDivisionId', this.meterDetail.sdID);
    }

    //formData.append('configSubDivisionId', this.meterDetail.sdID);

    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);

    formData.append('alarmLevel', this.cF.alarmLevel.value);
    formData.append('emergencycredit', this.cF.emergencycredit.value);
    formData.append('deductionType', '0');
    formData.append('eBUniteRateSelectionType',this.eBUniteRateSelectionType);
    formData.append('dgUniteRateSelectionType', this.dgUniteRateSelectionType);
    //formData.append('eBFlatUnitRate', this.cF.eBFlatUnitRate.value);
    //formData.append('dGUnitRate', this.cF.dGUnitRate.value);
    formData.append('fixedChargeGrid', this.cF.fixedChargeGrid.value);
    formData.append('fixedChargeDG', this.cF.fixedChargeDG.value);
    formData.append('vendingCharge', this.cF.vendingCharge.value);
    formData.append('commonAreaMaintCharge', this.cF.commonAreaMaintCharge.value);
    formData.append('gridMaintCharge', this.cF.gridMaintCharge.value);
    formData.append('dgMaintCharge', this.cF.dgMaintCharge.value);
    formData.append('clubCharge', this.cF.clubCharge.value);
    formData.append('waterCharge', this.cF.waterCharge.value);
    formData.append('otherCharge', this.cF.otherCharge.value);
    formData.append('serviceTax', this.cF.serviceTax.value);
    formData.append('schedularStartTime', this.startDate);
    formData.append('cmdformData',this.cmdformData);

    formData.append('ebSlabStartUnit1',this.cF.ebSlabStartUnit1.value);
    formData.append('ebSlabEndUnit1',this.cF.ebSlabEndUnit1.value);
    formData.append('unitRate1',this.cF.unitRate1.value);
    formData.append('dgSlabStartUnit1',this.cF.dgSlabStartUnit1.value);
    formData.append('dgSlabEndUnit1',this.cF.dgSlabEndUnit1.value);
    formData.append('unitRateDG1',this.cF.unitRateDG1.value);

    formData.append('ebSlabStartUnit2',this.cF.ebSlabStartUnit2.value);
    formData.append('ebSlabEndUnit2',this.cF.ebSlabEndUnit2.value);
    formData.append('unitRate2',this.cF.unitRate2.value);
    formData.append('dgSlabStartUnit2',this.cF.dgSlabStartUnit2.value);
    formData.append('dgSlabEndUnit2',this.cF.dgSlabEndUnit2.value);
    formData.append('unitRateDG2',this.cF.unitRateDG2.value);

    formData.append('ebSlabStartUnit3',this.cF.ebSlabStartUnit3.value);
    formData.append('ebSlabEndUnit3',this.cF.ebSlabEndUnit3.value);
    formData.append('unitRate3',this.cF.unitRate3.value);
    formData.append('dgSlabStartUnit3',this.cF.dgSlabStartUnit3.value);
    formData.append('dgSlabEndUnit3',this.cF.dgSlabEndUnit3.value);
    formData.append('unitRateDG3',this.cF.unitRateDG3.value);

    formData.append('ebSlabStartUnit4',this.cF.ebSlabStartUnit4.value);
    formData.append('ebSlabEndUnit4',this.cF.ebSlabEndUnit4.value);
    formData.append('unitRate4',this.cF.unitRate4.value);
    formData.append('dgSlabStartUnit4',this.cF.dgSlabStartUnit4.value);
    formData.append('dgSlabEndUnit4',this.cF.dgSlabEndUnit4.value);
    formData.append('unitRateDG4',this.cF.unitRateDG4.value);

    formData.append('ebSlabStartUnit5',this.cF.ebSlabStartUnit5.value);
    formData.append('ebSlabEndUnit5',this.cF.ebSlabEndUnit5.value);
    formData.append('unitRate5',this.cF.unitRate5.value);
    formData.append('dgSlabStartUnit5',this.cF.dgSlabStartUnit5.value);
    formData.append('dgSlabEndUnit5',this.cF.dgSlabEndUnit5.value);
    formData.append('unitRateDG5',this.cF.unitRateDG5.value);

    formData.append('ebSlabStartUnit6',this.cF.ebSlabStartUnit6.value);
    formData.append('ebSlabEndUnit6',this.cF.ebSlabEndUnit6.value);
    formData.append('unitRate6',this.cF.unitRate6.value);
    formData.append('dgSlabStartUnit6',this.cF.dgSlabStartUnit6.value);
    formData.append('dgSlabEndUnit6',this.cF.dgSlabEndUnit6.value);
    formData.append('unitRateDG6',this.cF.unitRateDG6.value);

    formData.append('ebSlabStartUnit7',this.cF.ebSlabStartUnit7.value);
    formData.append('ebSlabEndUnit7',this.cF.ebSlabEndUnit7.value);
    formData.append('unitRate7',this.cF.unitRate7.value);
    formData.append('dgSlabStartUnit7',this.cF.dgSlabStartUnit7.value);
    formData.append('dgSlabEndUnit7',this.cF.dgSlabEndUnit7.value);
    formData.append('unitRateDG7',this.cF.unitRateDG7.value);

    formData.append('ebSlabStartUnit8',this.cF.ebSlabStartUnit8.value);
    formData.append('ebSlabEndUnit8',this.cF.ebSlabEndUnit8.value);
    formData.append('unitRate8',this.cF.unitRate8.value);
    formData.append('dgSlabStartUnit8',this.cF.dgSlabStartUnit8.value);
    formData.append('dgSlabEndUnit8',this.cF.dgSlabEndUnit8.value);
    formData.append('unitRateDG8',this.cF.unitRateDG8.value);

    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configBillingTeriffSlab', formData).subscribe((result) => {
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
      },
      /*(err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }*/
    );
  }

  getDateFromRange(pickedDate = new Date()) {
    if (pickedDate) {
        let emonth = pickedDate.getMonth() + 1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();
        this.startDate = eyear + '-' + emonth + '-' + eday;
    }
  }
}

