import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import { User } from '../../_models';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { delay } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-consumer-profile',
  templateUrl: './edit-consumer-profile.component.html',
  styleUrls: ['./edit-consumer-profile.component.scss']
})
export class EditConsumerProfileComponent implements OnInit {
  faSpinner = faSpinner;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  isLoading = false;
  date :any;
  thumb:boolean = false;
  maxDate = new Date(2020,6,3);
  currentDate='';
  city = [
    {name: 'Panchkula'},
    {name: 'Ambala'},
    {name: 'Kuk'},
    {name: 'Panipat'}
  ];
  state = [
    {name: 'Haryana'},
    {name: 'Panjab'},
    {name: 'Utter Pardesh'},
    {name: 'Madhaya Pardesh'}
  ];
  country = [
    {name: 'India'},
    {name: 'Pakistan'}
  ];
  userData:any = {
    'name': "",
    'username': "",
    'password': "",
    'email': "",
    'lanlineNo': "",
    'mobile': "",
    'homeadd1': "",
    'homeadd2': "",
    'officeadd1': "",
    'officeadd2': "",
    'city': "",
    'state': "",
    'country': "",
    'dob': "",
   //'rdatetime': "2019-11-13 12:23:13"
  }
  pickedDate = new Date();
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  

  
  ngOnInit() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        let emonth = this.pickedDate.getMonth(); //always move 1 value up
        let eday = this.pickedDate.getDate();
        let eyear = this.pickedDate.getFullYear();
        //this.currentDate = eyear + '-' + emonth + '-' + eday;
    this.maxDate = new Date(eyear,emonth,eday);
    this.addForm = this.formBuilder.group({
      consFullName: ['', Validators.required],
      consUserName: [{value:'', disabled: true} , Validators.required],
      //consEmailId: [{value:'', disabled: true}, Validators.required],
      //consLandlineNo: ['', Validators.required],
      consEmailId: ['', [Validators.required, Validators.email]],
      consLandlineNo: ['',[Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
      consMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            

     // consLandlineNo: ['', [Validators.min(0), Validators.max(9999999999)]],
     // consMobileNo: [{value:'', disabled: true}, Validators.required],
      consHomeAddressline1: [{value:'', disabled: true}, Validators.required],
      consHomeAddressline2: [{value:'', disabled: true}, Validators.required],
      consOfficeAddressline1: [{value:'', disabled: true}, Validators.required],
      consOfficeAddressline2: [{value:'', disabled: true}, Validators.required],
      consCity: [{value:'', disabled: true}, Validators.required],
      consState: [{value:'', disabled: true}, Validators.required],
      consCountry: [{value:'', disabled: true}, Validators.required],
      consDOB: ['', Validators.required]
      });

      this.userData = this.currentUser.data.userData;
      this.aF.consFullName.setValue(this.userData.name);
      this.aF.consUserName.setValue(this.userData.username);
      this.aF.consEmailId.setValue(this.userData.email);
      this.aF.consLandlineNo.setValue(this.userData.lanlineNo);
      this.aF.consMobileNo.setValue(this.userData.mobile);
      this.aF.consHomeAddressline1.setValue(this.userData.homeadd1);
      this.aF.consHomeAddressline2.setValue(this.userData.homeadd2);
      this.aF.consOfficeAddressline1.setValue(this.userData.officeadd1);
      this.aF.consOfficeAddressline2.setValue(this.userData.officeadd2);
      this.aF.consCity.setValue(this.userData.city);
      this.aF.consState.setValue(this.userData.state);
      this.aF.consCountry.setValue(this.userData.country);
      this.date = new UntypedFormControl(new Date(this.userData.dob));
      //this.aF.consDOB.setValue(this.userData.dob);
      //console.log(this.date);
  }

  get aF() { return this.addForm.controls; }
  onSubmitUpdateForm(){
     if (this.addForm.invalid) {
       return;
     }
     this.isLoading = true;
     //console.log(this.currentUser.authToken);
     /*const formData = new FormData();
     formData.append('consFullName', this.aF.consFullName.value);
     formData.append('consUserName', this.aF.consUserName.value);
     formData.append('consEmailId', this.aF.consEmailId.value);
     formData.append('consLandlineNo', this.aF.consLandlineNo.value);
     formData.append('consMobileNo', this.aF.consMobileNo.value);
     formData.append('consHomeAddressline1', this.aF.consHomeAddressline1.value);
     formData.append('consHomeAddressline2', this.aF.consHomeAddressline2.value);
     formData.append('consOfficeAddressline1', this.aF.consOfficeAddressline1.value);
     formData.append('consOfficeAddressline2', this.aF.consOfficeAddressline2.value);
     formData.append('consCity', this.aF.consCity.value);
     formData.append('consState', this.aF.consState.value);
     formData.append('consCountry', this.aF.consCountry.value);
     formData.append('consDOB', this.aF.consDOB.value);
     formData.append('authToken', this.currentUser.authToken);*/
    let body = new URLSearchParams();
    body.set('consFullName', this.aF.consFullName.value);
    body.set('consUserName', this.aF.consUserName.value);
    body.set('consEmailId', this.aF.consEmailId.value);
    body.set('consLandlineNo', this.aF.consLandlineNo.value);
    body.set('consMobileNo', this.aF.consMobileNo.value);
    body.set('consHomeAddressline1', this.aF.consHomeAddressline1.value);
    body.set('consHomeAddressline2', this.aF.consHomeAddressline2.value);
    body.set('consOfficeAddressline1', this.aF.consOfficeAddressline1.value);
    body.set('consOfficeAddressline2', this.aF.consOfficeAddressline2.value);
    body.set('consCity', this.aF.consCity.value);
    body.set('consState', this.aF.consState.value);
    body.set('consCountry', this.aF.consCountry.value);
    body.set('consDOB', this.convert(this.aF.consDOB.value));
    body.set('authToken', this.currentUser.authToken);
    
    this.responseMessage = '';
   this.genReqs.postReq('/updateConsumerProfile', body).subscribe((result)=>{
      this.isLoading = false;
      this.responseData = result;
      //this.responseMessage = this.responseData.message;
      this.responseMessage = "Profile Information updated successfully. You will be logged out within 3 seconds.";
      this.thumb=true;
      setTimeout(() => 
      {
        this.authenticationService.logout();
      },
      3000);
      
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.isLoading = false;
    
     this.responseMessage = 'Unable to process yours request!';
     this.thumb=false;
   });
   }

   convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
