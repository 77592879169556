<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">

    <button type="button" (click)="modal.close('Ok click')" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body greencont d-none d-lg-block">
    <div class="container">
      <div class="contacthelp">
        <div class="lefthelp">
          <div class="headinghelp">Contact Us</div>
          <div class="para">Feel free to contact us </div>
          <div class="formhelp">
            <div class="fieldhelp">
              <input type="text" name="name" id="name" formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && aF.name.errors}" required>
              <label for="name" class="labelhelp">Name :</label>
              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.name.hasError('required') && (aF.name.dirty || aF.name.touched)">
                Name is required.
              </span>

            </div>
            <div class="fieldhelp">
              <input type="email" name="email" id="email" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && aF.email.errors}" required>
              <label for="email" class="labelhelp">Email :</label>
              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.email.hasError('required') && (aF.email.dirty || aF.email.touched)">
                Email-Id is required
              </span>
              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.email.hasError('email') && (aF.email.dirty || aF.email.touched)">
                Please enter a valid email address
              </span>
            </div>
            <div class="fieldhelp">
              <input type="number" name="phone" id="phone" formControlName="phone"
                [ngClass]="{ 'is-invalid': submitted && aF.phone.errors}" required>
              <label for="text" class="labelhelp">Mobile Number :</label>
              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.phone.hasError('required') && (aF.phone.dirty || aF.phone.touched)">
                Mobile No is required
              </span>

              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.phone.hasError('pattern') && (aF.phone.dirty || aF.phone.touched)">
                Visitor Mobile number not valid.
              </span>

            </div>
            <div class="fieldhelp">
              <textarea name="msg" id="msg" cols="30" rows="3" formControlName="msg"
                [ngClass]="{ 'is-invalid': submitted && aF.msg.errors}" required></textarea>
              <label for="msg" class="labelhelp t_msg">Message :</label>
              <span class="invalid-feedback txtwhiteerror"
                *ngIf="aF.msg.hasError('required') && (aF.msg.dirty || aF.msg.touched)">
                Messagse is required
              </span>
            </div>
            <div class="fieldhelp">
              <div class="btnflex">
                <button class="dilog dilog1 widthauto">Submit</button>
                <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3 widthauto">
                  Cancel
                </button>
              </div>
            </div>
            <div class="authtxt" role="alert">
              {{responseMessage}}
            </div>
          </div>

        </div>

        <div class="righthelp">
          <div class="righthelptab tabs">
            <div class="tab1 tabb">
              <div class="email"><i class="fa fa-envelope"></i><a
                  href="mailto:info@avonmeters.com">info@avonmeters.com</a></div>
            </div>
            <div class="tab2 tabb">
              <div class="phone"><i class="fa fa-phone"></i>+91-1762-281200</div>
            </div>
            <div class="tab3 tabb">
              <div class="location"><i class="fa fa-home"></i> Dera Bassi (Punjab.), India</div>
            </div>
          </div>
          <div class="socialhelp">
            <a href="mailto:info@avonmeters.com" target="_blank">
              <i class="fa fa-envelope"></i>
            </a>
            <i class="fa fa-skype"></i>
            <i class="fa fa-instagram"></i>
          </div>
        </div>

      </div>
    </div>



  </div>
  <div class="modal-body greencont d-block  d-lg-none">
    <div class="container">
      <div class="contacthelp">
        <div class="lefthelp">
          <div class="headinghelp mb-2">Contact Us</div>
          <div class="para">Feel free to contact us </div>
          <div class="formhelp">
            <div class="fieldhelp">
              <input type="text" name="name" id="name" formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && aF.name.errors}" required>
              <label for="name" class="labelhelp">Name :</label>
              <div *ngIf="submitted && aF.name.errors" class="invalid-feedback">
                <div *ngIf="aF.name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="fieldhelp">
              <input type="email" name="email" id="email" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && aF.email.errors}" required>
              <label for="email" class="labelhelp">Email :</label>
              <div *ngIf="submitted && aF.email.errors" class="invalid-feedback">
                <div *ngIf="aF.email.errors.required">
                  Email-Id is required
                </div>
                <div *ngIf="aF.email.hasError('email')">
                  Please enter a valid email address
                </div>
              </div>
            </div>
            <div class="fieldhelp">
              <input type="number" name="phone" id="phone" formControlName="phone"
                [ngClass]="{ 'is-invalid': submitted && aF.phone.errors}" required>
              <label for="text" class="labelhelp">Mobile Number :</label>
              <div *ngIf="submitted && aF.phone.errors" class="invalid-feedback">
                <div *ngIf="aF.phone.errors.required">
                  Mobile Number is required.
                </div>
                <div *ngIf="aF.phone.errors.pattern">
                  Please, Enter 10 digit Mobile Number.
                </div>
              </div>
            </div>
            <div class="fieldhelp">
              <textarea name="msg" id="msg" cols="30" rows="3" formControlName="msg"
                [ngClass]="{ 'is-invalid': submitted && aF.msg.errors}" required></textarea>
              <label for="msg" class="labelhelp t_msg">Message :</label>
              <div *ngIf="submitted && aF.msg.errors" class="invalid-feedback">
                <div *ngIf="aF.msg.errors.required">
                  Meeagse is required
                </div>
              </div>
            </div>
            <div class="fieldhelp">
              <div class="btnflex">
                <button class="dilog dilog1 widthauto">Submit</button>
                <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3" style="width: auto;">
                  Cancel
                </button>
              </div>
            </div>
            <div class="authtxt" role="alert">
              {{responseMessage}}
            </div>
          </div>

        </div>

        <div class="righthelp">
          <div class="righthelptab tabs">
            <div class="tab1 tabb">
              <div class="email"><i class="fa fa-envelope"></i><a
                  href="mailto:info@avonmeters.com">info@avonmeters.com</a></div>
            </div>
            <div class="tab2 tabb">
              <div class="phone"><i class="fa fa-phone"></i>+91-1762-281200</div>
            </div>
            <div class="tab3 tabb">
              <div class="location"><i class="fa fa-home"></i> Dera Bassi (Punjab.), India</div>
            </div>
          </div>
          <div class="socialhelp">
            <i class="fa fa-envelope"></i>
            <i class="fa fa-skype"></i>
            <i class="fa fa-instagram"></i>
          </div>
        </div>

      </div>
    </div>



  </div>
</form>