import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { User } from '../_models';
import { Subscription, interval } from 'rxjs';
import { Access } from '../_models/access';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ModalService } from '../_services/modal.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { Notify } from '../_models/notify';

@Component({
  selector: 'app-deshboardconsumer',
  templateUrl: './deshboardconsumer.component.html',
  styleUrls: ['./deshboardconsumer.component.scss']
})
export class DeshboardconsumerComponent implements OnInit {
  length=0;
  allmeterTotalRecord: any = 0;
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getThrough : Access;
  loading = false;
  levelSearch:string = '';
  pageSize: number = 7;
  pageSizeOptions: number[] = [7, 14, 21];
  dataMeterSource:any = new MatTableDataSource([]);
  isMeterDataLoad = false;
  limit:number = 10;
  meterlimit:number = 5;
  skip:number = 0;
  meterTotalRecord:number = 0;
  responseData: any;
  message: string = '';
  allmeterlimit: number = 20;
  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  circleId:any = 0;
  divisionId:any = 0;
  subdivisionId:any = 0;
  dcu:any = 0;

  cumulativeEB:any = 135.26;
  cumulativeDG:any = 85.63;
  monthlyEB:any = 69.56;
  monthlyDG:any = 20.65;
  ongoingEB:any = 5.62;
  ongoingDG:any = 2.15;

  meterColumnsToDisplay1 ="srNo";
  meterColumnsToDisplay2 ="cACNo";
  meterColumnsToDisplay3 ="muID";
  meterColumnsToDisplay4 ="flatNo";
  meterColumnsToDisplay5 ="mID";
  meterColumnsToDisplay6 ="mIPAdd";
  meterColumnsToDisplay7 ="mType";
  meterColumnsToDisplay8 ="mLLEBSupply";
  meterColumnsToDisplay9 = "mLLDGSupply";
  meterColumnsToDisplay10 ="mRTC";
  meterColumnsToDisplay11 ="activeEnergyEB";
  meterColumnsToDisplay12 ="activeEnergyDG";
  meterColumnsToDisplay13 ="availableAmount";
  meterColumnsToDisplay14 ="currentRunningMode";
  meterColumnsToDisplay15 ="tamperStatus";
  meterColumnsToDisplay16 ="recentLsDateTime";
  meterColumnsToDisplay17 ="recentBlockLsDateTime";
  meterColumnsToDisplay18 ="mActiveStatus";
  meterColumnsToDisplay19 ="deadMeters";
  meterColumnsToDisplay20 ="edit";

  meterColumnsToDisplay21 ="voltR";
  meterColumnsToDisplay22 ="voltY";
  meterColumnsToDisplay23 ="voltB";
  meterColumnsToDisplay24 ="phaseCurrentR";
  meterColumnsToDisplay25 ="phaseCurrentY";
  meterColumnsToDisplay26 ="phaseCurrentB";
  meterColumnsToDisplay27 ="ncR";
  meterColumnsToDisplay28 ="ncY";
  meterColumnsToDisplay29 ="ncB";
  meterColumnsToDisplay30 ="pfR";
  meterColumnsToDisplay31 ="pfY";
  meterColumnsToDisplay32 ="pfB";
  meterColumnsToDisplay33 ="totalPF";
  meterColumnsToDisplay34 ="frequency";
  meterColumnsToDisplay35 ="kWHPower";
  meterColumnsToDisplay36 ="kvarhPower";
  meterColumnsToDisplay37 ="kvahPower";
  meterColumnsToDisplay38 ="mdKW";
  meterColumnsToDisplay39 ="mdKva";
  meterColumnsToDisplay40 ="reactiveEnergyEBQ1";
  meterColumnsToDisplay41 ="apperantEnergyEB";
  meterColumnsToDisplay42 ="apperantEnergyDG";
  meterColumnsToDisplay43 ="reactiveEnergyEBQ2";
  meterColumnsToDisplay44 ="reactiveEnergyEBQ3";
  meterColumnsToDisplay45 ="reactiveEnergyEBQ4";
  meterColumnsToDisplay46 ="tamperCount";
  meterColumnsToDisplay47 ="billingCount";
  meterColumnsToDisplay48 ="dgName";
  meterColumnsToDisplay49 ="towerName";
  meterColumnsToDisplay50 ="groupName";
  meterColumnsToDisplay51 ="hesId";

  meterHeads1 = { label: "Sr. No", name: "srNo", checked: true};
  meterHeads2 = { label: "Cons. UId", name: "cACNO", checked: false };
  meterHeads3 = { label: "Meter UId", name: "muID", checked: false };
  meterHeads4 = { label: "Flat Id", name: "flatNo", checked: true };
  meterHeads48 = { label: "DG Id", name: "dgName", checked: false };
  meterHeads49 = { label: "Tower Id", name: "towerName", checked: false };
  meterHeads50 = { label: "Group Id", name: "groupName", checked: false };
  meterHeads5 = { label: "Meter No", name: "mID", checked: true };
  meterHeads51 = { label: "HES Id", name: "hesId", checked: false };
  meterHeads6 = { label: "Meter IP", name: "mIPAdd", checked: false };
  meterHeads7 = { label: "Meter Type", name: "mType", checked: false };
  meterHeads8 = { label: "LL Grid(kW)", name: "mLLEBSupply", checked: false };
  meterHeads9 = { label: "LL DG(kW)", name: "mLLDGSupply", checked: false };
  meterHeads10 = { label: "Last Update", name: "mRTC", checked: true };

  meterHeads21 = { label: "Voltage-R", name: "voltR", checked: false };
  meterHeads22 = { label: "Voltage-Y", name: "voltY", checked: false };
  meterHeads23 = { label: "Voltage-B", name: "voltB", checked: false };
  meterHeads24 = { label: "Phase Currren-R", name: "phaseCurrentR", checked: false };
  meterHeads25 = { label: "Phase Currren-Y", name: "phaseCurrentY", checked: false };
  meterHeads26 = { label: "Phase Currren-B", name: "phaseCurrentB", checked: false };
  meterHeads27 = { label: "Neutral Current", name: "ncR", checked: false };
  meterHeads28 = { label: "Neutral Current-Y", name: "ncY", checked: false };
  meterHeads29 = { label: "Neutral Current-B", name: "ncB", checked: false };
  meterHeads30 = { label: "PF-R", name: "pfR", checked: false };
  meterHeads31 = { label: "PF-Y", name: "pfY", checked: false };
  meterHeads32 = { label: "PF-B", name: "pfB", checked: false };
  meterHeads33 = { label: "Total PF", name: "totalPF", checked: false };
  meterHeads34 = { label: "Frequency", name: "frequency", checked: false };
  meterHeads35 = { label: "kW Power", name: "kWHPower", checked: false };
  meterHeads36 = { label: "kVAR Power", name: "kvarhPower", checked: false };
  meterHeads37 = { label: "kVA Power", name: "kvahPower", checked: false };
  meterHeads38 = { label: "MD kW", name: "mdKW", checked: false };
  meterHeads39 = { label: "MD kVA", name: "mdKva", checked: false };

  meterHeads11 = { label: "Grid kWH", name: "activeEnergyEB", checked: true };
  meterHeads40 = { label: "Grid kVARH Q1", name: "reactiveEnergyEBQ1", checked: false };
  meterHeads43 = { label: "Grid kVARH Q2", name: "reactiveEnergyEBQ2", checked: false };
  meterHeads44 = { label: "Grid kVARH Q3", name: "reactiveEnergyEBQ3", checked: false };
  meterHeads45 = { label: "Grid kVARH Q4", name: "reactiveEnergyEBQ4", checked: false };
  meterHeads41 = { label: "Grid kVAH", name: "apperantEnergyEB", checked: false };
  meterHeads12 = { label: "DG kWH", name: "activeEnergyDG", checked: true };
  meterHeads42 = { label: "DG kVAH", name: "apperantEnergyDG", checked: false };
  meterHeads13 = { label: "Balance", name: "availableAmount", checked: true };
  meterHeads14 = { label: "Supply", name: "currentRunningMode", checked: true };
  meterHeads15 = { label: "Tamper", name: 'tamperStatus', checked: true };
  meterHeads16 = { label: "Last LS D/T", name: 'recentLsDateTime', checked: false };
  meterHeads17 = { label: "Last Block LS D/T", name: 'recentBlockLsDateTime', checked: false };
  meterHeads46 = { label: "Tamper Count", name: "tamperCount", checked: false };
  meterHeads47 = { label: "Billing Count", name: "billingCount", checked: false };
  meterHeads18 = { label: "Relay Status", name: 'mActiveStatus', checked: true };
  meterHeads19 = { label: "Live Status", name: 'deadMeters', checked: true };
  meterHeads20 = { label: "Action", name: "edit", checked: true };

  meterColumnsToDisplay = [];
  
  meterHeadsCols = [];

  pageRefresh:any = 20;
  
  public barChartType = 'line';
  public barChartLegend = true;
  //public barChartLabels: Label[] = [];
  public barChartLabels = ['January', 'February', 'Mars', 'April', 'May', 'June', 'July'];
  //public barChartData: ChartDataSets[] = [];
  

  consumedPer:any = '';
  notifyCount: Notify;
  notifyCountSubscription: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private genReqs:generalRequestsService,
    private modalService: ModalService,
    private ngModalService: NgbModal,
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      this.authenticationService.notify.subscribe(x => this.notifyCount = x);
      
      this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
      this.pageRefresh=this.currentUser.data.userData.pageLoadDuration;
      this.pageRefresh=(this.pageRefresh*1000);
      if(this.pageRefresh==0 ||  this.pageRefresh==''){
        this.pageRefresh=20000;
      }
  }

  ngOnInit() {
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Cons_UId_Column){
      this.meterHeadsCols.push(this.meterHeads2)
      if(this.meterHeads2.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay2);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_UId_Column){
      this.meterHeadsCols.push(this.meterHeads3);
      if(this.meterHeads3.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay3);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Flat_Id_Column){
      this.meterHeadsCols.push(this.meterHeads4);
      if(this.meterHeads4.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay4);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_DG_Column){
      this.meterHeadsCols.push(this.meterHeads48);
      if(this.meterHeads48.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay48);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_Tower_Column){
      this.meterHeadsCols.push(this.meterHeads49);
      if(this.meterHeads49.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay49);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_Group_Column){
      this.meterHeadsCols.push(this.meterHeads50);
      if(this.meterHeads50.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay50);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_No_Column){
      this.meterHeadsCols.push(this.meterHeads5);
      if(this.meterHeads5.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay5);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_HES_Id_Column){
      this.meterHeadsCols.push(this.meterHeads51);
      if(this.meterHeads51.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay51);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_Ip_Column){
      this.meterHeadsCols.push(this.meterHeads6);
      if(this.meterHeads6.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay6);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Meter_Type_Column){
      this.meterHeadsCols.push(this.meterHeads7);
      if(this.meterHeads7.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay7);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_LL_Grid_kW_Column){
      this.meterHeadsCols.push(this.meterHeads8);
      if(this.meterHeads8.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay8);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_LL_DG_kW_Column){
      this.meterHeadsCols.push(this.meterHeads9);
      if(this.meterHeads9.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay9);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Last_Update_Column){
      this.meterHeadsCols.push(this.meterHeads10);
      if(this.meterHeads10.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay10);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Voltage_R_Column){
      this.meterHeadsCols.push(this.meterHeads21);
      if(this.meterHeads21.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay21);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Voltage_Y_Column){
      this.meterHeadsCols.push(this.meterHeads22);
      if(this.meterHeads22.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay22);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Voltage_B_Column){
      this.meterHeadsCols.push(this.meterHeads23);
      if(this.meterHeads23.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay23);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Phase_Current_R_Column){
      this.meterHeadsCols.push(this.meterHeads24);
      if(this.meterHeads24.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay24);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Phase_Current_Y_Column){
      this.meterHeadsCols.push(this.meterHeads25);
      if(this.meterHeads25.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay25);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Phase_Current_B_Column){
      this.meterHeadsCols.push(this.meterHeads26);
      if(this.meterHeads26.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay26);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Nutural_Current_R_Column){
      this.meterHeadsCols.push(this.meterHeads27);
      if(this.meterHeads27.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay27);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Nutural_Current_Y_Column){
      this.meterHeadsCols.push(this.meterHeads28);
      if(this.meterHeads28.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay28);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Nutural_Current_B_Column){
      this.meterHeadsCols.push(this.meterHeads29);
      if(this.meterHeads29.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay29);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_PF_R_Column){
      this.meterHeadsCols.push(this.meterHeads30);
      if(this.meterHeads30.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay30);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_PF_Y_Column){
      this.meterHeadsCols.push(this.meterHeads31);
      if(this.meterHeads31.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay31);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_PF_B_Column){
      this.meterHeadsCols.push(this.meterHeads32);
      if(this.meterHeads32.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay32);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Total_Power_Factor_Column){
      this.meterHeadsCols.push(this.meterHeads33);
      if(this.meterHeads33.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay33);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Frequency_Column){
      this.meterHeadsCols.push(this.meterHeads34);
      if(this.meterHeads34.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay34);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Active_Power_Column){
      this.meterHeadsCols.push(this.meterHeads35);
      if(this.meterHeads35.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay35);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Reactive_Power_Column){
      this.meterHeadsCols.push(this.meterHeads36);
      if(this.meterHeads36.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay36);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_ApperantPower_kVA_Column){
      this.meterHeadsCols.push(this.meterHeads37);
      if(this.meterHeads37.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay37);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Md_Kw_Column){
      this.meterHeadsCols.push(this.meterHeads38);
      if(this.meterHeads38.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay38);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_Md_kVA_Column){
      this.meterHeadsCols.push(this.meterHeads39);
      if(this.meterHeads39.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay39);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kWH_Column){
      this.meterHeadsCols.push(this.meterHeads11);
      if(this.meterHeads11.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay11);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kVARH_Q1_Column){
      this.meterHeadsCols.push(this.meterHeads40);
      if(this.meterHeads40.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay40);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kVARH_Q2_Column){
      this.meterHeadsCols.push(this.meterHeads43);
      if(this.meterHeads43.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay43);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kVARH_Q3_Column){
      this.meterHeadsCols.push(this.meterHeads44);
      if(this.meterHeads44.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay44);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kVARH_Q4_Column){
      this.meterHeadsCols.push(this.meterHeads45);
      if(this.meterHeads45.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay45);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Tamper_Count_Column){
      this.meterHeadsCols.push(this.meterHeads46);
      if(this.meterHeads46.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay46);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Billing_Count_Column){
      this.meterHeadsCols.push(this.meterHeads47);
      if(this.meterHeads47.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay47);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Grid_kVAH_Column){
      this.meterHeadsCols.push(this.meterHeads41);
      if(this.meterHeads41.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay41);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_DG_kWH_Column){
      this.meterHeadsCols.push(this.meterHeads12);
      if(this.meterHeads12.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay12);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_DG_kVAH_Column){
      this.meterHeadsCols.push(this.meterHeads42);
      if(this.meterHeads42.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay42);
      }
    }

    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Balance_Column){
      this.meterHeadsCols.push(this.meterHeads13);
      if(this.meterHeads13.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay13);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Supply_Column){
      this.meterHeadsCols.push(this.meterHeads14);
      if(this.meterHeads14.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay14);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Tamper_Column){
      this.meterHeadsCols.push(this.meterHeads15);
      if(this.meterHeads15.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay15);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Last_LS_DT_Column){
      this.meterHeadsCols.push(this.meterHeads16);
      if(this.meterHeads16.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay16);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Last_Block_LS_DT_Column){
      this.meterHeadsCols.push(this.meterHeads17);
      if(this.meterHeads17.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay17);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Relay_Status_Column){
      this.meterHeadsCols.push(this.meterHeads18);
      if(this.meterHeads18.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay18);
      }
    }


    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Live_Status_Column){
      this.meterHeadsCols.push(this.meterHeads19);
      if(this.meterHeads19.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay19);
      }
    }
    if(this.getThrough.consumer && this.getThrough.consumer.Cons_Action_Column){
      this.meterHeadsCols.push(this.meterHeads20);
      if(this.meterHeads20.checked){
        this.meterColumnsToDisplay.push(this.meterColumnsToDisplay20);
      }
    }

    this.countryId = this.currentUser.data.userData.Country || 0;
    this.stateId = this.currentUser.data.userData.State || 0;
    this.utilityId = this.currentUser.data.userData.utility || 0;
    this.circleId = this.currentUser.data.userData.circle || 0;
    this.divisionId = this.currentUser.data.userData.division || 0;
    this.subdivisionId = this.currentUser.data.userData.subdivision || 0;
    this.dcu = this.currentUser.data.userData.dcu || 0;
      let authBaseURl = this.authenticationService.getDeshboardRoute();

      if (this.getThrough.authority){
        this.router.navigate([authBaseURl])
      }else{
       // console.log("data",this.getThrough.consumer,authBaseURl);  
      // this.deshboardInfo();
        this.meterView();
        const source = interval(this.pageRefresh);
        this.currentUserSubscription = source.subscribe(val => this.meterView());
      }
  }

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 100) {
      return [10, 20, 40, 80, this.length];
    } else{
        return [10, 20, 40, 80, 100];
    }
  }

  
  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
  }

      /**to get the meter list on click on Dcu */
      meterView() {
        //this.dataMeterSource = [];
        //this.loading=true;
        let body = new URLSearchParams();
        body.set('subdivId', this.subdivisionId.toString());
        body.set('consumerId', this.currentUser.toString());
        body.set('authToken', this.currentUser.authToken);
        body.set('offSet', this.skip.toString());
        body.set('limit', this.allmeterlimit.toString());
        body.set('filter', this.levelSearch);
        this.genReqs.postReq('/getConsumerMeters', body).subscribe((result)=>{
          this.responseData = result;
          
          this.isMeterDataLoad = true;
          if(this.responseData.success){
           
            this.length = this.responseData.datalimit.totalResult;
            setTimeout(() => {
              this.loading=false;
              this.dataMeterSource = this.responseData.data;
             //console.log("in data",this.dataMeterSource );
              this.notifyCount.count = this.dataMeterSource[0].notiCounter;
              //console.log("hello",this.dataMeterSource.notiCounter );
              if(this.meterTotalRecord === 0){
                this.meterTotalRecord = this.responseData.datalimit.totalResult;
              }
            });
          }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.message = 'Unable to process yours request!';
        });
      }

      /**to get the meter list on click on Dcu */
   

        //To change meter table length
  /*changeMeterPage(event){
    if(event.pageSize !== this.meterlimit){
        this.meterlimit = event.pageSize;
        this.skip = event.pageSize * event.pageIndex;
        this.meterView();
      }else{
      if(this.meterTotalRecord > this.dataMeterSource.length){
            //if(this.pageIndex < event.pageIndex){
              // next page
              this.skip = event.pageSize * event.pageIndex;
              this.meterView();
            // }
        }
      }
  }*/

  changeAllMeterPage(event) {
    if (event.pageSize !== this.allmeterTotalRecord) {
      this.allmeterlimit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.allMeterView(true);
    } else {
      if (this.allmeterTotalRecord > this.dataMeterSource.length) {
        this.skip = event.pageSize * event.pageIndex;
        this.allMeterView(true);
      }
    }
  }

  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.meterColumnsToDisplay = [];
    for ( let col of this.meterHeadsCols ) {
      if (col.checked) {
        this.meterColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  onLevelSearchChange(search){
    this.levelSearch = search;
    this.skip = 0;
    this.allMeterView(false);
  }

  allMeterView(addNew:boolean=false) {
    //this.dataMeterSource = [];
    //this.loading=true;
    let body = new URLSearchParams();
    body.set('subdivId', this.subdivisionId.toString());
    body.set('consumerId', this.currentUser.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.meterlimit.toString());
    body.set('filter', this.levelSearch);
    this.genReqs.postReq('/getConsumerMeters', body).subscribe((result)=>{
      this.responseData = result;
      
      this.isMeterDataLoad = true;
      if(this.responseData.success){
        this.meterTotalRecord = this.responseData.datalimit.totalResult;
        setTimeout(() => {
          this.loading=false;
          this.dataMeterSource = this.responseData.data;
          if(this.meterTotalRecord === 0){
            this.meterTotalRecord = this.responseData.datalimit.totalResult;
          }
        });
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.message = 'Unable to process yours request!';
    });
  }

  reportFromMeter(row){
    this.modalService.reportFromMeterDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  activityOnMeter(row){
    this.modalService.activityOnMeterDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  realTimeStatus(row){
    this.modalService.realTimeStatusDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentConsumption(row){
    this.modalService.recentConsumptionDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentRecharge(row){
    this.modalService.recentRechargeDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentAdjustment(row){
    this.modalService.recentAdjustmentDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentConfiguration(row){
    this.modalService.recentConfigurationDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentBill(row){
    this.modalService.recentBillDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentEvent(row){
    this.modalService.recentEventDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentLoadSurvey(row){
    this.modalService.recentLoadSurveyDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentActivity(row){
    this.modalService.recentActivityDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentSMSSent(row){
    this.modalService.recentSMSSentDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentComplaints(row){
    this.modalService.recentComplaintsDialog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


}
