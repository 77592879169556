import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AddauthorityComponent } from '../subview/addauthority/addauthority.component';
import { EditauthorityComponent } from '../subview/editauthority/editauthority.component';
import { PermauthorityComponent } from '../subview/permauthority/permauthority.component';

import { EditconsumerComponent } from '../consubview/editconsumer/editconsumer.component';
import { PermconsumerComponent } from '../consubview/permconsumer/permconsumer.component';

import { AddlevelComponent } from '../subview/addlevel/addlevel.component';
import { EditLevelComponent } from '../subview/edit-level/edit-level.component';
import { AddteriffComponent } from '../subview/addteriff/addteriff.component';
import { EditterillComponent } from '../subview/editterill/editterill.component';

import { AddConsumerNotificationComponent } from '../subview/add-consumer-notification/add-consumer-notification.component';
import { AddMeterComandComponent } from '../subview/add-meter-comand/add-meter-comand.component';
import { AdddcucomandComponent } from '../subview/adddcucomand/adddcucomand.component';
import { AddconsumerComponent } from '../consubview/addconsumer/addconsumer.component';
import { ConscomplaintComponent } from '../consubview/conscomplaint/conscomplaint.component';
import { AutocompleteComponent } from '../ncomps/autocomplete/autocomplete.component'
import { AddmeterComponent } from '../subdivision-to-layout/addmeter/addmeter.component'
import { EditmeterComponent } from '../subdivision-to-layout/editmeter/editmeter.component'
//import { MeterconfigComponent } from '../subdivision-to-layout/dcu/meterconfig/meterconfig.component'
//import { MeterreportsComponent } from '../subdivision-to-layout/dcu/meterreports/meterreports.component'
import { RechargeusermeterComponent } from '../consumerview/rechargeusermeter/rechargeusermeter.component'
import { EditconsumernotificationComponent } from '../subview/editconsumernotification/editconsumernotification.component';
import { EditdcucommandComponent } from '../subview/editdcucommand/editdcucommand.component';
import { EditmetercommandComponent } from '../subview/editmetercommand/editmetercommand.component';
import { EditconcomplaintComponent } from '../consubview/editconcomplaint/editconcomplaint.component';
import { ConfigmeterComponent } from '../authorityview/configmeter/configmeter.component';
import { MeterAddComponent } from '../deshboardauthall/meter-add/meter-add.component';
import { MeterEditComponent } from '../deshboardauthall/meter-edit/meter-edit.component';
import { AdjustmentusermeterComponent } from '../consumerview/adjustmentusermeter/adjustmentusermeter.component';
import { MeterRechargeComponent } from '../deshboardauthall/meter-recharge/meter-recharge.component';
import { MeterAdjustmentComponent } from '../deshboardauthall/meter-adjustment/meter-adjustment.component';
import { MeterConfigComponent } from '../deshboardauthall/meter-config/meter-config.component';
import { MeterActivityComponent } from '../deshboardauthall/meter-activity/meter-activity.component';
import { MeterReportingComponent } from '../deshboardauthall/meter-reporting/meter-reporting.component';
import { CurrentDataComponent } from '../deshboardauthall/current-data/current-data.component';
import { LatestEventComponent } from '../deshboardauthall/latest-event/latest-event.component';
import { LatestDipwiseLoadsurveyComponent } from '../deshboardauthall/latest-dipwise-loadsurvey/latest-dipwise-loadsurvey.component';
import { LatestDayLoadsurveyComponent } from '../deshboardauthall/latest-day-loadsurvey/latest-day-loadsurvey.component';
import { LatestSMSsentComponent } from '../deshboardauthall/latest-smssent/latest-smssent.component';
import { LatestLprConfigComponent } from '../deshboardauthall/latest-lpr-config/latest-lpr-config.component';
import { LatestMeterActivityComponent } from '../deshboardauthall/latest-meter-activity/latest-meter-activity.component';
import { RecentlyRechargedComponent } from '../deshboardauthall/recently-recharged/recently-recharged.component';
import { RecentlyConsumptionComponent } from '../deshboardauthall/recently-consumption/recently-consumption.component';
import { LastBillComponent } from '../deshboardauthall/last-bill/last-bill.component';
import { RecentMeterconfigurationComponent } from '../deshboardauthall/recent-meterconfiguration/recent-meterconfiguration.component';
import { LatestMeterteriffComponent } from '../deshboardauthall/latest-meterteriff/latest-meterteriff.component';
import { ConfigureDCUComponent } from '../subdivision-to-layout/configure-dcu/configure-dcu.component';
import { ActionsOnDCUComponent } from '../subdivision-to-layout/actions-on-dcu/actions-on-dcu.component';
import { ReportingFromDcuComponent } from '../subdivision-to-layout/reporting-from-dcu/reporting-from-dcu.component';
import { ConfigureMetersComponent } from '../subdivision-to-layout/configure-meters/configure-meters.component';
import { ActionOnMetersComponent } from '../subdivision-to-layout/action-on-meters/action-on-meters.component';
import { ReportingFromMetersComponent } from '../subdivision-to-layout/reporting-from-meters/reporting-from-meters.component';
import { MeterInformationReportComponent } from '../subdivision-to-layout/meter-information-report/meter-information-report.component';
import { RealTimeDataComponent } from '../subdivision-to-layout/real-time-data/real-time-data.component';
import { RecentBillingComponent } from '../subdivision-to-layout/recent-billing/recent-billing.component';
import { RecentEventsComponent } from '../subdivision-to-layout/recent-events/recent-events.component';
import { RecentDipWiseLoadsurveyComponent } from '../subdivision-to-layout/recent-dip-wise-loadsurvey/recent-dip-wise-loadsurvey.component';
import { RecentDayWiseLoadsurveyComponent } from '../subdivision-to-layout/recent-day-wise-loadsurvey/recent-day-wise-loadsurvey.component';
import { MeterConfigurationsComponent } from '../subdivision-to-layout/meter-configurations/meter-configurations.component';
import { LprConfigurationsComponent } from '../subdivision-to-layout/lpr-configurations/lpr-configurations.component';
import { MeterActivityDataReportComponent } from '../subdivision-to-layout/meter-activity-data-report/meter-activity-data-report.component';
import { RevenueReportComponent } from '../subdivision-to-layout/revenue-report/revenue-report.component';
import { UnitConsumptionComponent } from '../subdivision-to-layout/unit-consumption/unit-consumption.component';
import { LowBalanceMetersComponent } from '../subdivision-to-layout/low-balance-meters/low-balance-meters.component';
import { ZeroBalanceMetersComponent } from '../subdivision-to-layout/zero-balance-meters/zero-balance-meters.component';
import { RecentConsumptionFromSubdivisionComponent } from '../subdivision-to-layout/recent-consumption-from-subdivision/recent-consumption-from-subdivision.component';
import { RecentRechargesFromSubdivisionComponent } from '../subdivision-to-layout/recent-recharges-from-subdivision/recent-recharges-from-subdivision.component';
import { RecentActivityFromSubdivisionComponent } from '../subdivision-to-layout/recent-activity-from-subdivision/recent-activity-from-subdivision.component';
import { RecentLprConfigFromSubdivisionComponent } from '../subdivision-to-layout/recent-lpr-config-from-subdivision/recent-lpr-config-from-subdivision.component';
import { RecentMeterConfigFromSubdivisionComponent } from '../subdivision-to-layout/recent-meter-config-from-subdivision/recent-meter-config-from-subdivision.component';
import { RecentMeterTeriffFromSubdivisionComponent } from '../subdivision-to-layout/recent-meter-teriff-from-subdivision/recent-meter-teriff-from-subdivision.component';
import { RecentSmsSentFromSubdivisionComponent } from '../subdivision-to-layout/recent-sms-sent-from-subdivision/recent-sms-sent-from-subdivision.component';
import { RecentDayWiseLoadFromSubdivisionComponent } from '../subdivision-to-layout/recent-day-wise-load-from-subdivision/recent-day-wise-load-from-subdivision.component';
import { RecentDipWiseFromSubdivisionComponent } from '../subdivision-to-layout/recent-dip-wise-from-subdivision/recent-dip-wise-from-subdivision.component';
import { RecentEventsFromSubdivisionComponent } from '../subdivision-to-layout/recent-events-from-subdivision/recent-events-from-subdivision.component';
import { RecentBillFromSubdivisionComponent } from '../subdivision-to-layout/recent-bill-from-subdivision/recent-bill-from-subdivision.component';
import { RealTimeDataFromSubdivisionComponent } from '../subdivision-to-layout/real-time-data-from-subdivision/real-time-data-from-subdivision.component';
import { ActivityOnMeterFromSubdivisionComponent } from '../subdivision-to-layout/activity-on-meter-from-subdivision/activity-on-meter-from-subdivision.component';
import { ConfigureMeterFromSubdivisionComponent } from '../subdivision-to-layout/configure-meter-from-subdivision/configure-meter-from-subdivision.component';
import { MakeAdjustmentFromSubdivisionComponent } from '../subdivision-to-layout/make-adjustment-from-subdivision/make-adjustment-from-subdivision.component';
import { MakeRechargeFromSubdivisionComponent } from '../subdivision-to-layout/make-recharge-from-subdivision/make-recharge-from-subdivision.component';
import { EditMeterFromSubdivisionComponent } from '../subdivision-to-layout/edit-meter-from-subdivision/edit-meter-from-subdivision.component';
import { AddMeterFromSubdivisionComponent } from '../subdivision-to-layout/add-meter-from-subdivision/add-meter-from-subdivision.component';
import { ReportingFromMeterFromSubdivisionComponent } from '../subdivision-to-layout/reporting-from-meter-from-subdivision/reporting-from-meter-from-subdivision.component';
import { TemperReportModalComponent } from '../temper-report-modal/temper-report-modal.component';
import { ReportingFromMeterDialogComponent } from '../deshboardconsumer/reporting-from-meter-dialog/reporting-from-meter-dialog.component';
import { RecentComplinatDialogComponent } from '../deshboardconsumer/recent-complinat-dialog/recent-complinat-dialog.component';
import { RecentSmsSentDialogComponent } from '../deshboardconsumer/recent-sms-sent-dialog/recent-sms-sent-dialog.component';
import { RecentActivityDialogComponent } from '../deshboardconsumer/recent-activity-dialog/recent-activity-dialog.component';
import { RecenLoadsurveyDialogComponent } from '../deshboardconsumer/recen-loadsurvey-dialog/recen-loadsurvey-dialog.component';
import { RecenEventsDialogComponent } from '../deshboardconsumer/recen-events-dialog/recen-events-dialog.component';
import { RecenBillDialogComponent } from '../deshboardconsumer/recen-bill-dialog/recen-bill-dialog.component';
import { RecenConfigurationDialogComponent } from '../deshboardconsumer/recen-configuration-dialog/recen-configuration-dialog.component';
import { RecentAdjustmantDialogComponent } from '../deshboardconsumer/recent-adjustmant-dialog/recent-adjustmant-dialog.component';
import { RecentRechargeDialogComponent } from '../deshboardconsumer/recent-recharge-dialog/recent-recharge-dialog.component';
import { RecentConsumptionDialogComponent } from '../deshboardconsumer/recent-consumption-dialog/recent-consumption-dialog.component';
import { RealtimestatusDialogComponent } from '../deshboardconsumer/realtimestatus-dialog/realtimestatus-dialog.component';
import { ActivityOnMeterComponent } from '../deshboardconsumer/activity-on-meter/activity-on-meter.component';
import { CreateSchedularComponent } from '../subview/create-schedular/create-schedular.component';
import { EditSchedularComponent } from '../subview/edit-schedular/edit-schedular.component';
import { LatestComplaintComponent } from '../deshboardauthall/latest-complaint/latest-complaint.component';
import { LatestAdjustmentComponent } from '../deshboardauthall/latest-adjustment/latest-adjustment.component';
import { ConsumerComplaintsComponent } from '../deshboardconsumer/consumer-complaints/consumer-complaints.component';
import { AdddgComponent } from '../subview/adddg/adddg.component';
import { EditdgComponent } from '../subview/editdg/editdg.component';
import { AddgroupComponent } from '../subview/addgroup/addgroup.component';
import { EditgroupComponent } from '../subview/editgroup/editgroup.component';
import { AddtowerComponent } from '../subview/addtower/addtower.component';
import { EdittowerComponent } from '../subview/edittower/edittower.component';
import { LatestBillComponent } from '../deshboardauthall/latest-bill/latest-bill.component';
import { LatestLoadLimitComponent } from '../deshboardauthall/latest-load-limit/latest-load-limit.component';
import { BillingSchedulerComponent } from '../deshboardauthall/billing-scheduler/billing-scheduler.component';
import { LatestHappyHoursComponent } from '../deshboardauthall/latest-happy-hours/latest-happy-hours.component';
import { BillingSchedulerMeterComponent } from '../deshboardauthall/billing-scheduler-meter/billing-scheduler-meter.component';
import { LatestHappyHoursMeterComponent } from '../deshboardauthall/latest-happy-hours-meter/latest-happy-hours-meter.component';
import { LatestLoadLimitMeterComponent } from '../deshboardauthall/latest-load-limit-meter/latest-load-limit-meter.component';
import { LatestMeterteriffMeterComponent } from '../deshboardauthall/latest-meterteriff-meter/latest-meterteriff-meter.component';
import { AddmeterrangeComponent } from '../subview/addmeterrange/addmeterrange.component';
import { EditmeterrangeComponent } from '../subview/editmeterrange/editmeterrange.component';
import { AddmeterfirmwareversionComponent } from '../subview/addmeterfirmwareversion/addmeterfirmwareversion.component';
import { EditfirmwareversionComponent } from '../subview/editfirmwareversion/editfirmwareversion.component';

import { AppComponent } from '../app.component';
import { AddAuthorityComponent } from '../subview/add-authority/add-authority.component';
import { CheckOtpComponent } from '../authorityview/check-otp/check-otp.component';
import { UticonfigComponent } from '../deshboardauthall/uticonfig/uticonfig.component';
import { PermreportsComponent } from '../subview/permreports/permreports.component';
import { RefundamountComponent } from '../deshboardauthall/refundamount/refundamount.component';
import { LoginmodelComponent } from '../subview/loginmodel/loginmodel.component';
import { JobsComponent } from '../jobs/jobs.component';
import { EditOldNewMeterComponent } from '../subview/edit-old-new-meter/edit-old-new-meter.component';
import { AddcontractorComponent } from '../subview/addcontractor/addcontractor.component';
import { EditcontractorComponent } from '../subview/editcontractor/editcontractor.component';
import { AddsuperviserComponent } from '../subview/addsuperviser/addsuperviser.component';
import { EditsuperviserComponent } from '../subview/editsuperviser/editsuperviser.component';
import { ShowsuperviserinstallComponent } from '../subview/showsuperviserinstall/showsuperviserinstall.component';
import { SuperviserviewComponent } from '../deshboardauthall/superviserview/superviserview.component';
import { EditpaymentComponent } from '../subview/editpayment/editpayment.component';
import { AddcontractorissuedmetersComponent } from '../subview/addcontractorissuedmeters/addcontractorissuedmeters.component';
import { EditcontractorissuedmetersComponent } from '../subview/editcontractorissuedmeters/editcontractorissuedmeters.component';
import { EditnewmeterComponent } from '../subview/editnewmeter/editnewmeter.component';
import { ExporttoexcelComponent } from '../subview/exporttoexcel/exporttoexcel.component';
import { RelaycontrolComponent } from '../deshboardauthall/relaycontrol/relaycontrol.component';
import { RemarkrevertComponent } from '../subview/remarkrevert/remarkrevert.component';
import { ProcessrefunddedComponent } from '../subview/processrefundded/processrefundded.component';
import { AddSmartMeterOrderComponent } from '../subview/add-smart-meter-order/add-smart-meter-order.component';
import { AddSmartMeterMeterNoComponent } from '../subview/add-smart-meter-meter-no/add-smart-meter-meter-no.component';
import { EditSmartMeterOrderComponent } from '../subview/edit-smart-meter-order/edit-smart-meter-order.component';
import { EditsmartmeteraccountComponent } from '../subview/editsmartmeteraccount/editsmartmeteraccount.component';
import { ViewmetersComponent } from '../subview/viewmeters/viewmeters.component';
import { EditsmartmeterprodComponent } from '../subview/editsmartmeterprod/editsmartmeterprod.component';
import { ContractorComponent } from '../deshboardauthall/reports/contractor/contractor.component';
import { ContractorpermComponent } from '../subview/contractorperm/contractorperm.component';
import { ViewPaymentHistoryComponent } from '../subview/view-payment-history/view-payment-history.component';
import { AdddispetchedmetersComponent } from '../subview/adddispetchedmeters/adddispetchedmeters.component';
import { EditOldNewMeterPnbComponent } from '../subview/edit-old-new-meter-pnb/edit-old-new-meter-pnb.component';
import { CheckinstallmeterdataComponent } from '../subview/checkinstallmeterdata/checkinstallmeterdata.component';
import { AddAuthorityPnbComponent } from '../subview/add-authority-pnb/add-authority-pnb.component';
import { EditAuthorityPnbComponent } from '../subview/edit-authority-pnb/edit-authority-pnb.component';
import { ExporttoexcelpnbComponent } from '../subview/exporttoexcelpnb/exporttoexcelpnb.component';
import { AddIntegrationFileComponent } from '../subview/add-integration-file/add-integration-file.component';
import { AddedmetersupplypaymentsComponent } from '../authorityview/addedmetersupplypayments/addedmetersupplypayments.component';
import { AddmetersupplypaymentComponent } from '../subview/addmetersupplypayment/addmetersupplypayment.component';
import { AddmeterfmssimpaymentComponent } from '../subview/addmeterfmssimpayment/addmeterfmssimpayment.component';
import { AddhespaymentsComponent } from '../subview/addhespayments/addhespayments.component';
import { IpclconfigComponent } from '../deshboardauthall/ipclconfig/ipclconfig.component';
import { AddAmiMetersComponent } from '../subview/add-ami-meters/add-ami-meters.component';
import { AssignAmiMetersComponent } from '../subview/assign-ami-meters/assign-ami-meters.component';
import { EditamihelpdeskdataComponent } from '../subview/editamihelpdeskdata/editamihelpdeskdata.component';
import { CheckmeterbillComponent } from '../mobreports/checkmeterbill/checkmeterbill.component';
import { RecentinstdataComponent } from '../deshboardauthall/recentinstdata/recentinstdata.component';
import { RecentbillingdataComponent } from '../deshboardauthall/recentbillingdata/recentbillingdata.component';
import { RecentongoingdataComponent } from '../deshboardauthall/recentongoingdata/recentongoingdata.component';
import { RecentdailydataComponent } from '../deshboardauthall/recentdailydata/recentdailydata.component';
import { RecenttransactioneventdataComponent } from '../deshboardauthall/recenttransactioneventdata/recenttransactioneventdata.component';
import { RecentpowereventdataComponent } from '../deshboardauthall/recentpowereventdata/recentpowereventdata.component';
import { RecentvoltageeventdataComponent } from '../deshboardauthall/recentvoltageeventdata/recentvoltageeventdata.component';
import { RecentcurrenteventdataComponent } from '../deshboardauthall/recentcurrenteventdata/recentcurrenteventdata.component';
import { RecentcontroleventdataComponent } from '../deshboardauthall/recentcontroleventdata/recentcontroleventdata.component';
import { RecentothereventdataComponent } from '../deshboardauthall/recentothereventdata/recentothereventdata.component';
import { RecentnonrollovereventdataComponent } from '../deshboardauthall/recentnonrollovereventdata/recentnonrollovereventdata.component';
import { RecentnameplatedataComponent } from '../deshboardauthall/recentnameplatedata/recentnameplatedata.component';
import { RecentinstpushdataComponent } from '../deshboardauthall/recentinstpushdata/recentinstpushdata.component';
import { UtilityconfigComponent } from '../deshboardauthall/utilityconfig/utilityconfig.component';
import { OngoingpvtComponent } from '../deshboardauthall/ongoingpvt/ongoingpvt.component';
import { DailyloadsurveypvtComponent } from '../deshboardauthall/dailyloadsurveypvt/dailyloadsurveypvt.component';
import { RecenteventpushComponent } from '../deshboardauthall/recenteventpush/recenteventpush.component';


@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(private ngbModal: NgbModal) { }

    consumerAllComplaints(): Observable<boolean> {
        const modal = this.ngbModal.open(
            ConsumerComplaintsComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    message(): Observable<boolean> { 
        const modal = this.ngbModal.open(
            AddAuthorityComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.authoritData = "N";
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addAuth(authorityData): Observable<boolean> { 
        const modal = this.ngbModal.open(
            AddAuthorityComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.authority = authorityData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addPnbAuth(authorityData): Observable<boolean> { 
        const modal = this.ngbModal.open(
            AddAuthorityPnbComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.authority = authorityData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addContractor(): Observable<boolean> { 
        const modal = this.ngbModal.open(
            AddcontractorComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    openLoginModel(): Observable<boolean> {
        const modal = this.ngbModal.open(
            LoginmodelComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    jobs(): Observable<boolean> {
        const modal = this.ngbModal.open(
            JobsComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editAuthority(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditauthorityComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editPnbAuthority(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditAuthorityPnbComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editContractor(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditcontractorComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    showSuperviserInstallation(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            ShowsuperviserinstallComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addSuperviser(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddsuperviserComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addIssuedMeters(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddcontractorissuedmetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    editSuperviser(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditsuperviserComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    addAuthority(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddauthorityComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.authority = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    permAuthority(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            PermauthorityComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authorityData = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    permContractor(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            ContractorpermComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authorityData = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    

    reportPermAuthority(authority): Observable<boolean> {
        const modal = this.ngbModal.open(
            PermreportsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authorityData = authority;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editConsumer(consumer): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditconsumerComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.consumer = consumer;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    permConsumer(consumer): Observable<boolean> {
        const modal = this.ngbModal.open(
            PermconsumerComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.consumer = consumer;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addlevel(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddlevelComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    exportToExcel(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            ExporttoexcelComponent, { backdrop: 'static', size: 'sm' });
            modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    exportToExcelPnb(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            ExporttoexcelpnbComponent, { backdrop: 'static', size: 'sm' });
            modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     editOldNewDetail(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditOldNewMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editOldNewDetailPnb(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditOldNewMeterPnbComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editAmiHelpDeshData(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditamihelpdeskdataComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.authority = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     viewOldNewDetailPnb(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            CheckinstallmeterdataComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    assignMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            AssignAmiMetersComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editNewDetail(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditnewmeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    addMeterFirmWareVersion(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddmeterfirmwareversionComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.subDivisionId = subDivisionId;     
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editlevel(levelData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditLevelComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.levelData = levelData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editMeterFirmWareVersion(firmwareData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditfirmwareversionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.firmwareData = firmwareData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    revertRemark(remarkData): Observable<boolean> {
        const modal = this.ngbModal.open(
            RemarkrevertComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.remarkData = remarkData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    editContractorPayment(contractorPaymentInfo): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditpaymentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.contractorPaymentInfo = contractorPaymentInfo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }



    addteriff(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddteriffComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addNotification(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddConsumerNotificationComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.subDivisionId = subDivisionId;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addMeterCommand(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddMeterComandComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.subDivisionId = subDivisionId;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addAmiMeters(data): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddAmiMetersComponent, { backdrop: 'static', size: 'sm' });
            modal.componentInstance.dataType = data;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editTeriff(teriffData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditterillComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.teriffData = teriffData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    
    editNotification(notifiData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditconsumernotificationComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.notifiData = notifiData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editMeterCommand(meterCmdData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditmetercommandComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.meterCmdData = meterCmdData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    checkMeterBill(meterData): Observable<boolean> {
        const modal = this.ngbModal.open(
            CheckmeterbillComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.meterData = meterData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    /*setcontentMargin(contentMargin): Observable<boolean> {
        const modal = this.ngbModal.open(
            AppComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.contentMargin = contentMargin;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }*/


    addSchedular(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            CreateSchedularComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addGroup(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddgroupComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    addTower(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddtowerComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log("hello", subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addDG(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AdddgComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addMeterRange(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddmeterrangeComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addMeterSupplyFile(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddmetersupplypaymentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addIntegrationFile(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddIntegrationFileComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addFmsSimFile(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddmeterfmssimpaymentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addHesFile(subDivisionId: any = 0): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddhespaymentsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.subDivisionId = subDivisionId;
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }




    addSmartMEter(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddSmartMeterOrderComponent, { backdrop: 'static', size: 'lg' });
        //console.log(subDivisionId);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

  

    
    editSchedular(schedular): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditSchedularComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.editSchedular = schedular;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editGroup(groupData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditgroupComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.groupData = groupData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editTower(towerData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EdittowerComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.towerData = towerData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editRefund(refundData): Observable<boolean> {
        const modal = this.ngbModal.open(
            ProcessrefunddedComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.towerData = refundData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    editDG(dgData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditdgComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dgData = dgData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editMeterRange(dgData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditmeterrangeComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dgData = dgData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editContMeterRange(issuedMeters): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditSmartMeterOrderComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.issuedMeters = issuedMeters;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addSmartMeterNo(meterNo): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddSmartMeterMeterNoComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.meterNo = meterNo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    viewSmartMeterNo(meterNo): Observable<boolean> {
        const modal = this.ngbModal.open(
            ViewmetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterNo = meterNo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    viewReceivedPayments(meterNo): Observable<boolean> {
        const modal = this.ngbModal.open(
            ViewPaymentHistoryComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterNo = meterNo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editByAccounts(meterData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditsmartmeteraccountComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.issuedMeters = meterData;
        return from(modal.result).pipe(
            catchError(error => {0
                console.warn(error);
                return of(undefined);
            })
        );
    }
    
    editByProd(meterData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditsmartmeterprodComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.issuedMeters = meterData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    editByRecDisProd(meterData): Observable<boolean> {
        const modal = this.ngbModal.open(
            AdddispetchedmetersComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.issuedMeters = meterData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }



    refundAmount(refundData): Observable<boolean> {
        const modal = this.ngbModal.open(
            RefundamountComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.refundData = refundData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    addDCUCommand(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AdddcucomandComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editDCUCommand(): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditdcucommandComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addNewConsumer(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddconsumerComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addConsComplaint(): Observable<boolean> {
        const modal = this.ngbModal.open(
            ConscomplaintComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    editConsComplaint(complaintData): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditconcomplaintComponent, { backdrop: 'static', size: 'lg' });
            modal.componentInstance.complaintData = complaintData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open auto complete on modal
    popautocomplete(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AutocompleteComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    addRecharge(): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddConsumerNotificationComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    subdivisionaddmeter(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddmeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open add meter on modal
    meteraddmeter(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterAddComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open add meter on modal
    metereditmeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterEditComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open recharge meter on Meter List modal
    meterRecharge(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterRechargeComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Adjustment meter on Meter List modal
    meterAdjustment(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterAdjustmentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Config Meter meter on Meter List modal
    meterConfigMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterConfigComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    meterConfigMeterUtility(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            UticonfigComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    meterConfigMeterUtilityNew(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            UtilityconfigComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    meterConfigMeterUtilityIPCL(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            IpclconfigComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Activity Meter meter on Meter List modal
    meterActivityMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterActivityComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    meterReportingMeterDesh(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterReportingComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    

    //open Reporting Meter meter on Meter List modal
    realTimeDataMeterAuth(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            CurrentDataComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    namePlateData(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentnameplatedataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    eventPushLog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecenteventpushComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentInstPush(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentinstpushdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    
    superwiserView(contractorDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            SuperviserviewComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.contractorDetail = contractorDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentBillMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LastBillComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     recentBillsMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestBillComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    recentBillsDataMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentbillingdataComponent, { backdrop: 'static', size: 'xl'});
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     recentLoadLimit(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestLoadLimitComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentLoadLimitMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestLoadLimitMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    openOtpBox(servantMode): Observable<boolean> {
        const modal = this.ngbModal.open(
            CheckOtpComponent, { backdrop: 'static', size: 'sm' });
        modal.componentInstance.servantMode = servantMode;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     recentHappyHours(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestHappyHoursComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     recentHappyHoursMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestHappyHoursMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


     //open Reporting Meter meter on Meter List modal
     recentBillScheduler(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            BillingSchedulerComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    recentRelayConfig(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RelaycontrolComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
     recentBillSchedulerMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            BillingSchedulerMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentEventMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestEventComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentDipWiseLoadSurveyMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestDipwiseLoadsurveyComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    
    recentTransactionEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecenttransactioneventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentPowerEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentpowereventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentVoltageEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentvoltageeventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentCurrentEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentcurrenteventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentControlEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentcontroleventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentOtherEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentothereventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentNonRollOverEvent(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentnonrollovereventdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentOnGoingLoadSurveyMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentongoingdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    recentOnGoingLoadSurveyMeterPvt(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            OngoingpvtComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    recentDayConsumption(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentdailydataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentDayLoadSurveyMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestDayLoadsurveyComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    recentDayLoadSurveyMeterPvt(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            DailyloadsurveypvtComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentSmsSentMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestSMSsentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    teriffMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestMeterteriffComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

     //open Reporting Meter meter on Meter List modal
    teriffMeterMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestMeterteriffMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    meterConfigurationMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentMeterconfigurationComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    lprConfigurationMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestLprConfigComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    latestComplaintMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestComplaintComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentActivityMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestMeterActivityComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentRechargeMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentlyRechargedComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentInstData(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentinstdataComponent, { backdrop: 'static', size: 'xl' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentAdjustmentMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            LatestAdjustmentComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentConsumptionMeter(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentlyConsumptionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //meter Function From Subdivision

    //open add meter on modal
    meteraddmeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            AddMeterFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open add meter on modal
    metereditmeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditMeterFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open recharge meter on Meter List modal
    meterRechargeSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            MakeRechargeFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Adjustment meter on Meter List modal
    meterAdjustmentSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            MakeAdjustmentFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Config Meter meter on Meter List modal
    meterConfigMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            ConfigureMeterFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Activity Meter meter on Meter List modal
    meterActivityMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            ActivityOnMeterFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Activity Meter meter on Meter List modal
    meterReportingFromMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            ReportingFromMeterFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    realTimeDataMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RealTimeDataFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    recentBillMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentBillFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    //open Reporting Meter meter on Meter List modal
    recentEventMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentEventsFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentDipWiseLoadSurveyMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentDipWiseFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentDayLoadSurveyMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentDayWiseLoadFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentSmsSentMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentSmsSentFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    teriffMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentMeterTeriffFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    meterConfigurationMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentMeterConfigFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    lprConfigurationMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentLprConfigFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentActivityMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentActivityFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentRechargeMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentRechargesFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    //open Reporting Meter meter on Meter List modal
    recentConsumptionMeterSubDiv(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            RecentConsumptionFromSubdivisionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //////////////////

    //open Reporting Meter meter on Meter List modal
    dcuRemoveViewMeter(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterReportingComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open Reporting Meter meter on Meter List modal
    meterReportingMeter(meterDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            MeterReportingComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetails = meterDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    subdivisioneditmeter(meterDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            EditmeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetails = meterDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    reportFromMeterDialog(meterDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
            ReportingFromMeterDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetails = meterDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    activityOnMeterDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
            ActivityOnMeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    //open sub division add meter on modal
    realTimeStatusDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RealtimestatusDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentConsumptionDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentConsumptionDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentRechargeDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentRechargeDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentAdjustmentDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentAdjustmantDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentConfigurationDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecenConfigurationDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentBillDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecenBillDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentEventDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecenEventsDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentLoadSurveyDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecenLoadsurveyDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentActivityDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentActivityDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open sub division add meter on modal
    recentSMSSentDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentSmsSentDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


    //open sub division add meter on modal
    recentComplaintsDialog(meterDetail): Observable<boolean> {
        const modal = this.ngbModal.open(
        RecentComplinatDialogComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }






    //open sub division config meter on modal
    /*onDcuListMeterConfig(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        MeterconfigComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }*/

    //open sub division config meter on modal
    configureOnDCU(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ConfigureDCUComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    actionOnDCU(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ActionsOnDCUComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    reportingFromOnDCU(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ReportingFromDcuComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    configureOnMeter(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ConfigureMetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    actionOnMeter(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ActionOnMetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    reportingFromOnMeter(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ReportingFromMetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    meterInformationReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        MeterInformationReportComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    realTimeDataReport(meterDetail): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RealTimeDataComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.meterDetail = meterDetail;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    recentBillingReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RecentBillingComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    recentEventsReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RecentEventsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    recentDipWiseLoadSurveyReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RecentDipWiseLoadsurveyComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    recentLoadSurveyDayWiseReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RecentDayWiseLoadsurveyComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    meterConfigurationDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        MeterConfigurationsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    lprConfigurationDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        LprConfigurationsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    meterActivityDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        MeterActivityDataReportComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }

    //open sub division config meter on modal
    revenueDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        RevenueReportComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }
    //open sub division config meter on modal
    unitConsumptionDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        UnitConsumptionComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }
    //open sub division config meter on modal
    lowBalanceMeterDataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        LowBalanceMetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }
    //open sub division config meter on modal
    zeroBalanceMeterdataReport(dcuDetails): Observable<number> {
        let subdivision = 0;
        const modal = this.ngbModal.open(
        ZeroBalanceMetersComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        modal.componentInstance.passSubDivision.subscribe((receivedEntry) => {
            subdivision = receivedEntry;
        });
        return from(modal.componentInstance.selectedSubDiv).pipe(
            catchError(error => {
                return of(undefined);
            })
        );
    }
    //open sub division config meter on modal
   
    
    //open sub division report meter on modal
    /*onDcuListMeterReports(dcuDetails): Observable<boolean> {
        const modal = this.ngbModal.open(
        MeterreportsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = dcuDetails;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }*/

    //open sub division report meter on modal
    /*consumerMakeRecharge(meter): Observable<boolean> {
        const modal = this.ngbModal.open(
        MeterreportsComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.dcuDetails = meter;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }*/

    input(): Observable<string> {
        const modal = this.ngbModal.open(
        AutocompleteComponent, { backdrop: 'static', size: 'lg' });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    rechargeMeter(userInfo): Observable<boolean> {
        const modal = this.ngbModal.open(
        RechargeusermeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.userInfo = userInfo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    /*rechargeMeter(userInfo): Observable<boolean> {
        const modal = this.ngbModal.open(
        MeterRechargeComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.userInfo = userInfo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }*/


    adjustmentMeter(userInfo): Observable<boolean> {
        const modal = this.ngbModal.open(
        AdjustmentusermeterComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.userInfo = userInfo;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    //open temper modal
    openTemperReport(temperData): Observable<boolean> {
        const modal = this.ngbModal.open(
        TemperReportModalComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.temperData = temperData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

}