import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from '../../_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-contractorperm',
  templateUrl: './contractorperm.component.html',
  styleUrls: ['./contractorperm.component.scss']
})
export class ContractorpermComponent implements OnInit {
  faSpinner = faSpinner;
  thumb = false;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage:string = '';
  AddProcess:boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority:any;
  authorityPerms : {

    smart_meter_order_add: 0,
    smart_meter_order_view_serial_no: 0,
    smart_meter_order_add_serial_no: 0,
    smart_meter_order_edit_by_production: 0,
    smart_meter_order_edit_by_account: 0,
    smart_meter_order_edit_by_marketing: 0,
    smart_meter_order_delete_order: 0,

    contractor_Menu: 0,
    contractor_add: 0,
    contractor_modify: 0,
    contractor_add_superviser: 0,
    contractor_view_superviser: 0,
    contractor_delete: 0,

    contractor_superviser: 0,
    contractor_modify_superviser: 0,
    contractor_delete_superviser: 0,

    contractor_issued_meters_range_menu: 0,
    contractor_issued_meters_range: 0,
    contractor_modify_meters_range: 0,
    contractor_delete_meters_range: 0,

    contractor_progress: 0,
    contractor_modify_payment: 0,
    
    Contractor_Sub_Reports: 0,
    contractor_info_Report: 0,
    contractor_superviser_info_Report: 0,
    contractor_faulty_meters_Report: 0,
    contractor_installed_meters_report: 0,
    contractor_payment_pending_report: 0,
    contractor_payment_cleared_report: 0,

    Updation_date_Time: '',
    Server_Date_Time: '',
    Authirity_Id: 0
};
  loading:boolean = false;
  isloading:boolean = false;
  message:string = '';
  authorityData:any = {};
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    public access: Access
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
    });
  }

  ngOnInit() {
    this.getAuthPerms();
   this.addAuthorityForm = this.formBuilder.group({
    //uid: [''],

    
   
    contractor_Menu: ['', Validators.required],
    contractor_add: ['', Validators.required],
    contractor_modify: ['', Validators.required],
    contractor_add_superviser: ['', Validators.required],
    contractor_view_superviser: ['', Validators.required],
    contractor_delete: ['', Validators.required],

    contractor_superviser: ['', Validators.required],
    contractor_modify_superviser: ['', Validators.required],
    contractor_delete_superviser: ['', Validators.required],

    contractor_issued_meters_range_menu: ['', Validators.required],
    contractor_issued_meters_range: ['', Validators.required],
    contractor_modify_meters_range: ['', Validators.required],
    contractor_delete_meters_range: ['', Validators.required],

    contractor_progress: ['', Validators.required],
    contractor_modify_payment: ['', Validators.required],
    
    Contractor_Sub_Reports: ['', Validators.required],
    contractor_info_Report: ['', Validators.required],
    contractor_superviser_info_Report: ['', Validators.required],
    contractor_faulty_meters_Report: ['', Validators.required],
    contractor_installed_meters_report: ['', Validators.required],
    contractor_payment_pending_report: ['', Validators.required],
    contractor_payment_cleared_report: ['', Validators.required],

    smart_meter_order_add: ['', Validators.required],
    smart_meter_order_view_serial_no: ['', Validators.required],
    smart_meter_order_add_serial_no: ['', Validators.required],
    smart_meter_order_edit_by_production: ['', Validators.required],
    smart_meter_order_edit_by_account: ['', Validators.required],
    smart_meter_order_edit_by_marketing: ['', Validators.required],
    smart_meter_order_delete_order: ['', Validators.required]

  });
  }

  get pf() { return this.addAuthorityForm.controls; }
  onSubmitAddAuthority(){
    let seletedPerms = [];
    
    if(this.pf.Active_All_Permission_Access.value == true){

    seletedPerms.push('smart_meter_order_add');
    seletedPerms.push('smart_meter_order_view_serial_no');
    seletedPerms.push('smart_meter_order_add_serial_no');
    seletedPerms.push('smart_meter_order_edit_by_production');
    seletedPerms.push('smart_meter_order_edit_by_account');
    seletedPerms.push('smart_meter_order_edit_by_marketing');
    seletedPerms.push('smart_meter_order_delete_order');

    seletedPerms.push('contractor_Menu');
    seletedPerms.push('contractor_add');
    seletedPerms.push('contractor_modify');
    seletedPerms.push('contractor_add_superviser');
    seletedPerms.push('contractor_view_superviser');
    seletedPerms.push('contractor_delete');

    seletedPerms.push('contractor_superviser');
    seletedPerms.push('contractor_modify_superviser');
    seletedPerms.push('contractor_delete_superviser');

    seletedPerms.push('contractor_issued_meters_range_menu');
    seletedPerms.push('contractor_issued_meters_range');
    seletedPerms.push('contractor_modify_meters_range');
    seletedPerms.push('contractor_delete_meters_range');

    seletedPerms.push('contractor_progress');
    seletedPerms.push('contractor_modify_payment');
    
    seletedPerms.push('Contractor_Sub_Reports');
    seletedPerms.push('contractor_info_Report');
    seletedPerms.push('contractor_superviser_info_Report');
    seletedPerms.push('contractor_faulty_meters_Report');
    seletedPerms.push('contractor_installed_meters_report');
    seletedPerms.push('contractor_payment_pending_report');
    seletedPerms.push('contractor_payment_cleared_report');



    }

    if(this.pf.smart_meter_order_add.value == true){
    seletedPerms.push('smart_meter_order_add');
    }
    if(this.pf.smart_meter_order_view_serial_no.value == true){
    seletedPerms.push('smart_meter_order_view_serial_no');
    }
    if(this.pf.smart_meter_order_add_serial_no.value == true){
    seletedPerms.push('smart_meter_order_add_serial_no');
    }
    if(this.pf.smart_meter_order_edit_by_production.value == true){
    seletedPerms.push('smart_meter_order_edit_by_production');
    }
    if(this.pf.smart_meter_order_edit_by_account.value == true){
    seletedPerms.push('smart_meter_order_edit_by_account');
    }
    if(this.pf.smart_meter_order_edit_by_marketing.value == true){
    seletedPerms.push('smart_meter_order_edit_by_marketing');
    }
    if(this.pf.smart_meter_order_delete_order.value == true){
    seletedPerms.push('smart_meter_order_delete_order');
    }

    if(this.pf.contractor_Menu.value == true){
      seletedPerms.push('contractor_Menu');
    }

    if(this.pf.contractor_add.value == true){
      seletedPerms.push('contractor_add');
    }
    if(this.pf.contractor_modify.value == true){
      seletedPerms.push('contractor_modify');
    }
    if(this.pf.contractor_add_superviser.value == true){
      seletedPerms.push('contractor_add_superviser');
    }
    if(this.pf.contractor_view_superviser.value == true){
      seletedPerms.push('contractor_view_superviser');
    }
    if(this.pf.contractor_delete.value == true){
      seletedPerms.push('contractor_delete');
    }

    if(this.pf.contractor_superviser.value == true){
      seletedPerms.push('contractor_superviser');
    }
    
    if(this.pf.contractor_modify_superviser.value == true){
      seletedPerms.push('contractor_modify_superviser');
    }
    if(this.pf.contractor_progress.value == true){
      seletedPerms.push('contractor_progress');
    }

    if(this.pf.contractor_issued_meters_range_menu.value == true){
      seletedPerms.push('contractor_issued_meters_range_menu');
    }
    
    if(this.pf.contractor_issued_meters_range.value == true){
      seletedPerms.push('contractor_issued_meters_range');
    }
    if(this.pf.contractor_modify_meters_range.value == true){
      seletedPerms.push('contractor_modify_meters_range');
    }
    if(this.pf.contractor_delete_meters_range.value == true){
      seletedPerms.push('contractor_delete_meters_range');
    }

    if(this.pf.contractor_modify_payment.value == true){
      seletedPerms.push('contractor_modify_payment');
    }
    if(this.pf.contractor_delete_superviser.value == true){
      seletedPerms.push('contractor_delete_superviser');
    }
    
    if(this.pf.Contractor_Sub_Reports.value == true){
      seletedPerms.push('Contractor_Sub_Reports');
    }
    if(this.pf.contractor_info_Report.value == true){
      seletedPerms.push('contractor_info_Report');
    }
    if(this.pf.contractor_superviser_info_Report.value == true){
      seletedPerms.push('contractor_superviser_info_Report');
    }
    if(this.pf.contractor_faulty_meters_Report.value == true){
      seletedPerms.push('contractor_faulty_meters_Report');
    }
    if(this.pf.contractor_installed_meters_report.value == true){
      seletedPerms.push('contractor_installed_meters_report');
    }
    if(this.pf.contractor_payment_pending_report.value == true){
      seletedPerms.push('contractor_payment_pending_report');
    }
    if(this.pf.contractor_payment_cleared_report.value == true){
      seletedPerms.push('contractor_payment_cleared_report');
    }
    
    
      //this.loading = true;
      this.isloading=true;
      this.message ='';
      let body = new URLSearchParams();
      body.set('seletedPerms', seletedPerms.toString());
      body.set('authUniqueId', this.authorityData.aID);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/setAuthPermission', body).subscribe(
        (res) => {
          this.message = res.message;
          //this.loading = false;
         
          if (res.success) {
            this.isloading=false;
            this.thumb = true;
            //this.addAuthMessage = this.responseData.data.message;
          }else{
              this.thumb = false;
          }

        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.loading = false;
        }
      );
  }

  onDragChange() {
    //console.log('hello');
  }

  getAuthPerms() {
    this.loading = true;
    this.message ='';
    let body = new URLSearchParams();
    body.set('authId', this.authorityData.aID);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getAuthPermission', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.loading = false;
         this.authorityPerms = this.responseData.data;
         this.addAuthorityForm.patchValue(this.authorityPerms);
      }else{
        this.message = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.message = 'Unable to process yours request!';
    });
  }
}
