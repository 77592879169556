import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { Access } from '../_models/access'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GridlistService } from '../_services/gridlist.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deshboard',
  templateUrl: './deshboard.component.html',
  styleUrls: ['./deshboard.component.scss']
})
export class DeshboardComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentUserSubscription: Subscription;
  getThroughSubject: Subscription;
  getThrough: Access;
  users: User[] = [];
  allUsersLablesData: any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys: boolean = false;
  messageUtilitys = 'No Utility List Found';
  closeResult: string;
  breakpoint: number;
  allowMapViewer: boolean = true;
  addCountryForm: UntypedFormGroup;
  editCountryForm: UntypedFormGroup;
  responseMessage: string = '';
  levelSearch: string = '';
  skip: number = 0;
  submitted: boolean = false;
  faSpinner = faSpinner;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.breakpoint = this.gridlistService.breakpoint;
  }

  ngOnInit() {
    this.authenticationService.getDeshboardRoute();

    this.getAccesslevels();
    this.reCountCols(window.innerWidth);
    this.addCountryForm = this.formBuilder.group({
      countryCode: ['', Validators.required],
      countryName: ['', Validators.required],
      countryImage: [null, Validators.required]
    });
    this.editCountryForm = this.formBuilder.group({
      countryId: ['', Validators.required],
      countryCode: ['', Validators.required],
      countryName: ['', Validators.required],
      countryImage: [null, Validators.required]
    });
  }

  // @ViewChild(MapviewComponent) mapview:MapviewComponent
  ngAfterViewInit() {
    //this.mapview;

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
    this.modalService.dismissAll();
  }




  onResize(event) {
    this.reCountCols(event.target.innerWidth);
  }

  reCountCols(width: number) {
    this.breakpoint = this.gridlistService.reCountCols(width);
  }

  onLevelSearchChange(search) {
    this.levelSearch = search;
    this.skip = 0;
    this.getNotification(false);
  }

  getNotification(addNew: boolean = false) {
    //this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLabels', body).subscribe((result) => {
      this.responseData = result;
      this.loadingUtilitys = false;
      if (this.responseData.success) {
        this.allUsersLablesData = this.responseData.data;
        this.allutilitys = this.allUsersLablesData.allutilitys;
      } else {
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  getAccesslevels() {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLabels', body).subscribe((result) => {
      this.responseData = result;
      this.loadingUtilitys = false;
      if (this.responseData.success) {
        this.allUsersLablesData = this.responseData.data;
        this.allutilitys = this.allUsersLablesData.allutilitys;
      } else {
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  goToCountryView(countryId) {
    this.router.navigate(['/deshauthstates'],
      { queryParams: { 'country': this.genReqs.encryptData(countryId) } });
  }

  /**to get the meter list on click on Dcu */
  countryEditView(row, content) {
    this.responseMessage = '';
    this.eC.countryId.setValue(row.country_id);
    this.eC.countryCode.setValue(row.countCode);
    this.eC.countryName.setValue(row.countName);
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content) {
    this.responseMessage = '';
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  onFileSelect(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      //console.log(file.type);
      if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
        const file = event.target.files[0];
        this.aC.countryImage.setValue(file);
        //console.log(file.type,file);
        return true;
      }
      else {
        this.aC.countryImage.setValue(null);
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }
    }
  }

  onFileSelectEdit(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      //console.log(file.type);
      if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
        const file = event.target.files[0];
        this.eC.countryImage.setValue(file);
        //console.log(file.type,file);
        return true;
      }
      else {
        this.eC.countryImage.setValue(null);
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }
    }
  }

  get aC() { return this.addCountryForm.controls; }
  get eC() { return this.editCountryForm.controls; }
  onSubmitAddCountry() {
    //addCountry
    if (this.addCountryForm.invalid) {
       this.submitted = true;
      return;
    }
    const formData = new FormData();

    if (this.aC.countryImage.value != null) {
      formData.append('countryImage', this.aC.countryImage.value);
    } else {
      alert("Please select Files in PNG/JPEG Formats");
      return false;
    }
    //formData.append('countryImage', this.aC.countryImage.value);
    formData.append('countryName', this.aC.countryName.value);
    formData.append('countryCode', this.aC.countryCode.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/addCountry', formData).subscribe((res) => {
        this.responseMessage = res.message;
        if (res.success) {

          this.addCountryForm.reset();
          this.getAccesslevels();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

  removelevel(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove ' + row.countName + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          //this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('countName', row.countName);
          body.set('countId', row.country_id);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteCountry', body).subscribe((result) => {
            this.responseData = result;
            //this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getAccesslevels();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }

  onImageChangeFromFile(event) {

    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if (file.type == "image") {
        //console.log(file.type);
        alert("this is text file");

      }
      else {
        alert("please fill right file");
      }
    }
  }

  onSubmitEditCountry() {
    //addCountry
    if (this.editCountryForm.invalid) {
       this.submitted = true;
      return;
    }
    const formData = new FormData();
    //this.validateFile(this.eC.countryImage.value);
    if (this.eC.countryImage.value != null) {
      formData.append('countryImage', this.eC.countryImage.value);
    } else {
      alert("Please select Files in PNG/JPEG Formats");
      return false;
    }
    formData.append('countryName', this.eC.countryName.value);
    formData.append('countryCode', this.eC.countryCode.value);
    formData.append('countryId', this.eC.countryId.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/editCountry', formData).subscribe(
      (res) => {
        this.responseMessage = res.message;
        if (res.success) {
          this.getAccesslevels();
          //this.editCountryForm.reset();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

}
