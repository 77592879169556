
<div class="modal-content">
  <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
          data-dismiss="modal">&times;</button>
      Enter OTP
      </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
      <div class="form-group">
          <!-- Grid row -->
          <div class="form-row">
            <!-- Default input -->
            <div class="col-md-6">
              <div class="form-group input-group">
                <span class="has-float-label ">
                <input matInput placeholder="Otp" name="otpValue" formControlName="otpValue" autocomplete="off" 
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.otpValue.errors }" required>
                <i class="fa fa-lock icon_innertxtbox"></i>
                <label>OTP Value</label>
                <div *ngIf="submitted && aF.otpValue.errors" class="invalid-feedback">
                  <div *ngIf="aF.otpValue.errors.required">
                     OTP Value is required
                  </div>
                </div>
               </span>
              </div>
            </div>
             </div>
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="col-12 row">
              <div class="col-sm-4 text-left">
                <button type="submit" class="dilog dilog1">
                  <mat-icon class="mat-icon-new">announcement</mat-icon>
                  Submit
                </button>
              </div>
              <div class="col-sm-4 text-middle">
                <button type="button" class="dilog dilog1" (click)="otpCheck()">
                    <mat-icon class="mat-icon-new">replay</mat-icon>
                    Resend Otp
                  </button>
              </div>
              <div class="col-sm-4 text-middle">
                <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
                  <mat-icon class="mat-icon-new" >cancel</mat-icon>
                  Cancel
                </button>
              </div>
            </div>
            
            <div class="row">
              <div class="col-sm-12 text-left">
                  <ng-container *ngIf="isLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                  </ng-container>
                    <div role="alert" *ngIf="addAuthMessage.length > 0" >
                      <div class="alert alert-success" role="alert" *ngIf="thumb">
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                      </div>
                      <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                        <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                      </div>
                    </div>
                  
              </div>
            </div>   
            
        </div>
    </div>
    </form>
    </div>