import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-meter-add',
  templateUrl: './meter-add.component.html',
  styleUrls: ['./meter-add.component.scss']
})
export class MeterAddComponent implements OnInit {
  public today = new Date();
  currentUser: User;
  currentUserSubscription: Subscription;
  dcuDetails:any;
  closeResult: string;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  countId:string = '0';
	stateId:string = '0';
	utilityId:string = '0';
	circleId:string = '0';
	devisionId:string = '0';
	subdivId:string = '0';
  dcuid:string = '0';
  subdivisionId:any = 0;
  
  responseData: any;

  flatNo = '';
  flatIdData = [
    {
      'consAccountNo': 0,
      'flatNo': '',
      'meterId': '',
      'consumerName': ''
    }
  ];
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

		// $parsedBody['countId'] ?? '';
		// $parsedBody['stateId'] ?? '';
		// $parsedBody['utilityId'] ?? '';
		// $parsedBody['circleId'] ?? '';
		// $parsedBody['devisionId'] ?? '';
		// $parsedBody['subdivId'] ?? '';
		// $parsedBody['dcuId'] ?? '';
		// $parsedBody['consumerId'] ?? '';
		// $parsedBody['meterId'] ?? '';
		// $parsedBody['meterFirmwareUniqueId'] ?? '';
		// $parsedBody['meterLPRId'] ?? '';
		// $parsedBody['meterGPRSSimNo'] ?? '';
		// $parsedBody['meterGPRSSimIMEINo'] ?? '';
		// $parsedBody['meterRTCDateTime'] ?? '';
		// $parsedBody['meterGISLocation'] ?? '';
		// $parsedBody['meterInstalledLocationAddress'] ?? '';
		// $parsedBody['meterLoadLimitEBSupply'] ?? '';
		// $parsedBody['meterLoadLimitDGSupply'] ?? '';
		// $parsedBody['eBkWHStartUnits'] ?? '';
		// $parsedBody['dgkWHStartUnits'] ?? '';
		// $parsedBody['meterStartBalance'] ?? '';
		// $parsedBody['meterAlertSendingType'] ?? '';
		// $parsedBody['meterInstalletionDateTime'] ?? '';
		// $parsedBody['meterActiveStatus'] ?? '';
		// $parsedBody['meterType'] ?? 0;
		// $parsedBody['meterIpAddress'] ?? '';
		// ($parsedBody['meterPortNo'] ?? 0);
		// $parsedBody['meterServerIpAddress'] ?? '';
		// ($parsedBody['meterServerPortNo'] ?? 0);
		// $parsedBody['meterFirmwareCodeVersion'] ?? '';

  ngOnInit() {
    this.getFlatId();
    this.addForm = this.formBuilder.group({
      //flatNo: ['', Validators.required],
      meterId: ['', Validators.required],
      //meterFirmwareUniqueId: ['', Validators.required],
      //meterLPRId: ['', Validators.required],
      meterGPRSSimNo: ['', Validators.required],
      meterGPRSSimIMEINo: ['', Validators.required],
      meterRTCDateTime: ['', Validators.required],
      meterGISLocation: ['', Validators.required],
      meterInstalledLocationAddress: ['', Validators.required],
      meterLoadLimitEBSupply: ['', Validators.required],
      meterLoadLimitDGSupply: ['', Validators.required],
      eBkWHStartUnits: ['', Validators.required],
      dgkWHStartUnits: ['', Validators.required],
      meterStartBalance: ['', Validators.required],
      meterAlertSendingType: ['', Validators.required],
      meterInstalletionDateTime: ['', Validators.required],
      meterActiveStatus: ['', Validators.required],
      meterType: ['', Validators.required],
      meterIpAddress: ['', Validators.required],
      meterPortNo: ['', Validators.required],
      meterServerIpAddress: ['', Validators.required],
      meterServerPortNo: ['', Validators.required],
      meterFirmwareCodeVersion: ['', Validators.required],
      alarmLevel: ['', Validators.required],
    });
    this.subdivisionId = this.currentUser.data.userData.subdivision;
  }

  ngAfterViewInit() {  
    //console.log(this.dcuDetails);
    this.countId = this.dcuDetails.cID;
    this.stateId = this.dcuDetails.sID;
    this.utilityId = this.dcuDetails.uID;
    this.circleId = this.dcuDetails.ciID;
    this.devisionId = this.dcuDetails.dID;
    this.subdivId = this.dcuDetails.sdID;
    this.dcuid = this.dcuDetails.dcuID;
    this.EditName = this.dcuDetails.dcuName;
  }

      /*
    add utility form submition here
    */

  getFlatId() {
    let body = new URLSearchParams();
    body.set('subDivisionId', this.subdivisionId);
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('subDivName', this.currentUser.data.userData.subName);
    this.genReqs.postReq('/getFlatId', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        //console.log( this.responseData.data);
          this.flatIdData = this.responseData.data;
        }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

   get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
        return;
      }
      const formData = new FormData();
        formData.append('countId', this.countId);
        formData.append('stateId', this.stateId);
        formData.append('utilityId', this.utilityId);
        formData.append('circleId', this.circleId);
        formData.append('devisionId', this.devisionId);
        formData.append('subdivId', this.subdivId);
        formData.append('dcuId', this.dcuid);
        formData.append('consumerId', this.aF.consumerId.value);
        formData.append('meterId', this.aF.meterId.value);
        formData.append('meterFirmwareUniqueId', this.aF.meterFirmwareUniqueId.value);
        formData.append('meterLPRId', this.aF.meterLPRId.value);
        formData.append('meterGPRSSimNo', this.aF.meterGPRSSimNo.value);
        formData.append('meterGPRSSimIMEINo', this.aF.meterGPRSSimIMEINo.value);
        formData.append('meterRTCDateTime', this.aF.meterRTCDateTime.value);
        formData.append('meterGISLocation', this.aF.meterGISLocation.value);
        formData.append('meterInstalledLocationAddress', this.aF.meterInstalledLocationAddress.value);
        formData.append('meterLoadLimitEBSupply', this.aF.meterLoadLimitEBSupply.value);
        formData.append('meterLoadLimitDGSupply', this.aF.meterLoadLimitDGSupply.value);
        formData.append('eBkWHStartUnits', this.aF.eBkWHStartUnits.value);
        formData.append('dgkWHStartUnits', this.aF.dgkWHStartUnits.value);
        formData.append('meterStartBalance', this.aF.meterStartBalance.value);
        formData.append('meterAlertSendingType', this.aF.meterAlertSendingType.value);
        formData.append('meterInstalletionDateTime', this.aF.meterInstalletionDateTime.value);
        formData.append('meterActiveStatus', this.aF.meterActiveStatus.value);
        formData.append('meterType', this.aF.meterType.value);
        formData.append('meterIpAddress', this.aF.meterIpAddress.value);
        formData.append('meterPortNo', this.aF.meterPortNo.value);
        formData.append('meterServerIpAddress', this.aF.meterServerIpAddress.value);
        formData.append('meterServerPortNo', this.aF.meterServerPortNo.value);
        formData.append('meterFirmwareCodeVersion', this.aF.meterFirmwareCodeVersion.value);
        formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/addMeter', formData).subscribe(
        (res) => {
          this.responseMessage = res.message;
          if (res.success) {
            this.addForm.reset();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
    }

}
