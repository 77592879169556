<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Edit Scheduler
    </h4>
  </div>
  <div class="modal-body">
    <!--<div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>-->
    <div class="form-groupp">
      <!-- Grid row -->
      <div class="form-row">
        <!-- Default input -->
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Scheduler Name" name="schedularName" formControlName="schedularName"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.schedularName.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Scheduler Name</label>
              <div *ngIf="submitted && aF.schedularName.errors" class="invalid-feedback">
                <div *ngIf="aF.schedularName.errors.required">
                  Scheduler Name is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Command Type" name="commanType" formControlName="commanType"
                (selectionChange)="onCmdChange($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.schedularName.errors}" required>
                <mat-option *ngFor="let command of meterCommands" [value]="command.meterid">
                  {{command.meterCmdName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Select Command Type</label>
              <div *ngIf="submitted && aF.commanType.errors" class="invalid-feedback">
                <div *ngIf="aF.commanType.errors.required">
                  Select Command Type is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <!--<mat-form-field class="col-md-6">
                <mat-select [multiple]="true" placeholder="Select Meters" name="meters" formControlName="meters">
                  <mat-option *ngFor="let meterId of meterIdNo" [value]="meterId.muID">
                    {{meterId.mID}}
                  </mat-option>
                </mat-select>
                  <mat-icon matPrefix class="d-c6">select_all</mat-icon>
                  <mat-error *ngIf="aF.meters.hasError('required')">
                    Select Meters <strong>required</strong>
                  </mat-error>
              </mat-form-field>-->

        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Scheduler Start Date (MM/DD/YYYY)" name="schedularDate"
                formControlName="schedularDate" (bsValueChange)="getDOB($event)" bsDatepicker
                [bsConfig]="{ adaptivePosition: true }" [minDate]="nowDate" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.schedularDate.errors}" required>

              <i class="fa fa-calendar icon_innertxtbox"></i>
              <label>Scheduler Start/End Date</label>
              <div *ngIf="submitted && aF.schedularDate.errors" class="invalid-feedback">
                <div *ngIf="aF.schedularDate.errors.required">
                  Scheduler Start/End Date is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Hour" name="startHour" formControlName="startHour" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.startHour.errors}" required>
                <mat-option *ngFor="let hour of hours" [value]="hour">
                  {{hour}} hour
                </mat-option>
              </mat-select>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Start Hour</label>
              <div *ngIf="submitted && aF.startHour.errors" class="invalid-feedback">
                <div *ngIf="aF.startHour.errors.required">
                  Start Hour is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <!--<mat-form-field class="col-md-6">
              <mat-select placeholder="Select End Hour" name="endHour" formControlName="endHour">
                <mat-option *ngFor="let hour of hours" [value]="hour">
                  {{hour}} hour
                </mat-option>
              </mat-select>
                <mat-icon matPrefix class="d-c7">date_range</mat-icon>
                <mat-error *ngIf="aF.endHour.hasError('required')">
                 End Hour <strong>required</strong>
                </mat-error>
            </mat-form-field>-->
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Minute" name="startMinute" formControlName="startMinute"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.startMinute.errors}" required>
                <mat-option *ngFor="let min of minute" [value]="min">
                  {{min}} minute
                </mat-option>
              </mat-select>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Start Hour</label>
              <div *ngIf="submitted && aF.startMinute.errors" class="invalid-feedback">
                <div *ngIf="aF.startMinute.errors.required">
                  Start Minute is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <!--<mat-form-field class="col-md-6">
              <mat-select  placeholder="Select End Minute" name="endMinute" formControlName="endMinute">
                <mat-option *ngFor="let min of minute" [value]="min">
                  {{min}} minute
                </mat-option>
              </mat-select>
                <mat-icon matPrefix class="d-c7">date_range</mat-icon>
                <mat-error *ngIf="aF.endMinute.hasError('required')">
                 End Minute <strong>required</strong>
                </mat-error>
            </mat-form-field>-->
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Schedular Frequency" name="schedularFrequency"
                formControlName="schedularFrequency" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.schedularFrequency.errors}" required>
                <mat-option *ngFor="let command of scheExec" [value]="command.execUID">
                  {{command.execName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Scheduler Frequency</label>
              <div *ngIf="submitted && aF.schedularFrequency.errors" class="invalid-feedback">
                <div *ngIf="aF.schedularFrequency.errors.required">
                  Scheduler Frequency is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Active Status" name="activeStatus" formControlName="activeStatus"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.activeStatus.errors}" required>
                <mat-option value="Y">
                  Enable
                </mat-option>
                <mat-option value="N">
                  Disable
                </mat-option>
              </mat-select>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Active Status </label>
              <div *ngIf="submitted && aF.activeStatus.errors" class="invalid-feedback">
                <div *ngIf="aF.activeStatus.errors.required">
                  Active Status is required
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <!-- Grid row -->
      <div class="clearfix"></div>
      <div class="col-12 row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
            </div>
          </div>

        </div>

        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>edit</mat-icon>
            Submit
          </button>
          <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

        </div>
      </div>
    </div>
  </div>
</form>