import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-current-teriff',
  templateUrl: './current-teriff.component.html',
  styleUrls: ['./current-teriff.component.scss']
})
export class CurrentTeriffComponent implements OnInit {

  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  meterDetail:any = {};

  letviewis : any;

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    
  }

  ngOnInit() {
   
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
  }

 

  getRealTimeStatus() {
    let body = new URLSearchParams();
    
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getTeriffDataCons', body).subscribe((result)=>{
      this.responseData = result;
     // console.log(this.responseData);
      if(this.responseData.success){
        this.letviewis = this.responseData.data;
     }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}
