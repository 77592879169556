import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dcumeterreporting',
  templateUrl: './dcumeterreporting.component.html',
  styleUrls: ['./dcumeterreporting.component.scss']
})
export class DcumeterreportingComponent implements OnInit {
  loading:boolean = false;
  reportForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private ngmodalService: NgbModal,
  ) { }

  ngOnInit() {
    this.whichSubDomain();
    this.reportForm = this.formBuilder.group({
      gidata: ['', Validators.required],
      glndata: ['', Validators.required],
      gcmdata: ['', Validators.required],
      glnbdata: ['', Validators.required],
      gfbdata: ['', Validators.required],
      gltdata: ['', Validators.required],
      gftdata: ['', Validators.required],
      gclsdata: ['', Validators.required],
      gllsdata: ['', Validators.required],
      gmcdata: ['', Validators.required],
      gmrcdata: ['', Validators.required],
      gmhs: ['', Validators.required],
    });
  }
  ngOnDestroy() {
    this.ngmodalService.dismissAll();
}

public formControlIsValid(formGroup, formCtrl) : boolean {
  if (formGroup.controls.hasOwnProperty(formCtrl)) {
    if (!formGroup.controls[formCtrl].touched) return true;
    return formGroup.controls[formCtrl].valid;
  }
  return false;
}

whichSubDomain(){
  this.modalService.popautocomplete().pipe(
    take(1)).subscribe(result => {
      this.selectedSubDivision = result;
     // console.log('selected val',this.selectedSubDivision);
    }
  );
}
get rF() { return this.reportForm.controls; }

onSubmitAddForm(){
  //to do ##to be removed
}

}
