import { Component, OnInit, ChangeDetectionStrategy, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addcontractor',
  templateUrl: './addcontractor.component.html',
  styleUrls: ['./addcontractor.component.scss']
})
export class AddcontractorComponent implements OnInit {
  faSpinner = faSpinner;
    addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
   
    message: string = '';
    authLevelFieldValue: string = '';

    isLoading: boolean = false;
    thumb: boolean = false;
    ///multiselect
    
    authLevelField: boolean = false;
    nowDate = new Date();
    submitted: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        public modal: NgbActiveModal,
        private ref: ChangeDetectorRef
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        if (this.currentUser.data.userData.authLevel == '7') {
            this.authLevelField = true;
            this.authLevelFieldValue = '7';
        }
        ref.detach();
        setInterval(() => { this.ref.detectChanges(); }, 300);
    }

    ngOnInit() {
      let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        /*
          form authority add
        */
        this.addAuthorityForm = this.formBuilder.group({
            contFullName: ['', Validators.required],
            contUserName: ['', Validators.required],
            contPassword: ['', Validators.required],
            contEmailId: ['', [Validators.required, Validators.email]],
            contMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            contLandlineNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            contHomeAddressline1: ['', Validators.required],
            contHomeAddressline2: ['', Validators.required],
            contOfficeAddressline1: ['', Validators.required],
            contOfficeAddressline2: ['', Validators.required],

            ph1InstallationRate: ['', Validators.required],
            ph3InstallationRate: ['', Validators.required],
            ltctInstallationRate: ['', Validators.required],

            authorityActiveStatus: ['', Validators.required],
            gstNo: ['', Validators.required],
            contFirmName: ['', Validators.required]
        });

        //this.countryLevelData = this.getDynamicList();
       
    }
    get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        if (this.addAuthorityForm.invalid) {
              this.submitted = true;
            return;
        }
        this.message = '';
        this.isLoading = true;
        this.AddProcess = true;

        let body = new URLSearchParams();
        body.set('subdivId', this.currentUser.data.userData.subdivision);
        body.set('contFirmName', this.aD.contFirmName.value);
        body.set('contFullName', this.aD.contFullName.value);
        body.set('contUserName', this.aD.contUserName.value);
        body.set('contPassword', this.aD.contPassword.value);
        body.set('contEmailId', this.aD.contEmailId.value);
        body.set('contLandlineNo', this.aD.contLandlineNo.value);
        body.set('contMobileNo', this.aD.contMobileNo.value);
        body.set('contHomeAddressline1', this.aD.contHomeAddressline1.value);
        body.set('contHomeAddressline2', this.aD.contHomeAddressline2.value);
        body.set('contOfficeAddressline1', this.aD.contOfficeAddressline1.value);
        body.set('contOfficeAddressline2', this.aD.contOfficeAddressline2.value);
        body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
        body.set('ph1InstallationRate', this.aD.ph1InstallationRate.value);
        body.set('ph3InstallationRate', this.aD.ph3InstallationRate.value);
        body.set('ltctInstallationRate', this.aD.ltctInstallationRate.value);
        body.set('gstNo', this.aD.gstNo.value);
        body.set('gstNo', this.aD.gstNo.value);
        body.set('authToken', this.currentUser.authToken);

        this.AddProcess = true;
        this.genReqs.postReq('/addContractor', body).subscribe((result) => {

            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.thumb = false;
            if (this.responseData.success) {
                this.AddProcess = false;
                this.isLoading = false;
                this.thumb = true;
                this.message = this.responseData.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.AddProcess = false;
            this.message = 'Unable to process yours request!';
        });
    }
}
