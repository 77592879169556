import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-editauthority',
    templateUrl: './editauthority.component.html',
    styleUrls: ['./editauthority.component.scss']
})
export class EditauthorityComponent implements OnInit {
    faSpinner = faSpinner;
    addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
    assignedData = {};
    authorityDOB: any = "0000-00-00";
    bsAuthorityDOB: Date = new Date(2017, 7);
    isLoading:boolean = false;
    thumb:boolean = false;
    submitted: boolean = false;
    status: boolean = false;
    authLevel_sel: any = '';

    public authLevelData: any = [];
    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];

    ///multiselect
    authMulti1: boolean = false;
    authMulti2: boolean = false;
    authMulti3: boolean = false;
    authMulti4: boolean = false;
    authMulti5: boolean = false;
    authMulti6: boolean = false;
    authMulti7: boolean = false;
    authMulti8: boolean = false;
    date:any;
    constructor(
        private formBuilder: UntypedFormBuilder, 
        private modalService: NgbModal,
        public modal: NgbActiveModal,
        private genReqs:generalRequestsService,
        private authenticationService: AuthenticationService,
      ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
        });
      }
    

    ngOnInit() {
        if(this.authority.sdID=="105"){
            this.status=true;
        }
        let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        /*
           form authority add
         */
        if(this.status){
            this.addAuthorityForm = this.formBuilder.group({
                uid: [''],
                subdivId: [{value: '', disabled: true}, Validators.required],
                authFullName: ['', Validators.required],
                authUserName: ['', Validators.required],
                authPassword: ['', Validators.required],
                authEmailId: ['', [Validators.required, Validators.email]],
                authLandlineNo: [''],
                authMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
                authHomeAddressline1: ['', Validators.required],
                authHomeAddressline2: ['', Validators.required],
                authOfficeAddressline1: ['', Validators.required],
                authOfficeAddressline2: ['', Validators.required],
                authorityActiveStatus: ['', Validators.required],
                authorityDOB: ['', Validators.required],
                authType: ['', Validators.required]
            });
        }else{
            this.addAuthorityForm = this.formBuilder.group({
                uid: [''],
                subdivId: [{value: '', disabled: true}, Validators.required],
                authFullName: ['', Validators.required],
                authUserName: ['', Validators.required],
                authPassword: ['', Validators.required],
                authEmailId: ['', [Validators.required, Validators.email]],
                authLandlineNo: [''],
                authMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
                authHomeAddressline1: ['', Validators.required],
                authHomeAddressline2: ['', Validators.required],
                authOfficeAddressline1: ['', Validators.required],
                authOfficeAddressline2: ['', Validators.required],
                authorityActiveStatus: ['', Validators.required],
                authorityDOB: ['', Validators.required],
                authoritySMSReceiveStatus: ['', Validators.required]
            });
        }

        this.preSetUserData();
    }

    ngAfterViewInit() {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        // this.preSetUserData();
        // if (this.authority.aID > 0) {
        //     this.getAuthorityDetails();
        // }
    }

    ngOnDestroy() {
        //this.modal.close();
    }

    /*
    add authority
    require authority data
  */
    get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        
        if (this.addAuthorityForm.invalid) {
            this.submitted = true;

            return;
        }
        this.isLoading = true;
        this.AddProcess = true;
        let body = new URLSearchParams();
        //body.set('authLevel', this.aD.authLevel.value);
        //body.set('countId', this.aD.countId.value);
        //body.set('stateId', this.aD.stateId.value);
        //body.set('utilityId', this.aD.utilityId.value);
        //body.set('circleId', this.aD.circleId.value);
        //body.set('devisionId', this.aD.devisionId.value);
        //body.set('subdivId', this.aD.subdivId.value);
       
        //body.set('dcuId', this.aD.subdivId.value);
        body.set('authId', this.authority.aID);
        body.set('subdivId', this.authority.sdID);
        body.set('authFullName', this.aD.authFullName.value);
        body.set('authUserName', this.aD.authUserName.value);
        body.set('authPassword', this.aD.authPassword.value);
        body.set('authEmailId', this.aD.authEmailId.value);
        body.set('authLandlineNo', this.aD.authLandlineNo.value);
        body.set('authMobileNo', this.aD.authMobileNo.value);
        body.set('authHomeAddressline1', this.aD.authHomeAddressline1.value);
        body.set('authHomeAddressline2', this.aD.authHomeAddressline2.value);
        body.set('authOfficeAddressline1', this.aD.authOfficeAddressline1.value);
        body.set('authOfficeAddressline2', this.aD.authOfficeAddressline2.value);
        body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
        
        if(this.status){
            body.set('authType', this.aD.authType.value);
        }else{
            body.set('authoritySMSReceiveStatus', this.aD.authoritySMSReceiveStatus.value);
        }
        //body.set('authorityDOB', this.authorityDOB);//this.aD.authPassword.value);
        body.set('authorityDOB',  this.convert(this.aD.authorityDOB.value));
        //body.set('dataArray[]', this.aD.authPassword.value);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/updateAuthInfo', body).subscribe((result) => {
            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.message;
                setTimeout(() => 
                {
                this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.addAuthMessage = 'Unable to process yours request!';
        });
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      
    getAuthorityDetails() {
        let body = new URLSearchParams();
        body.set('authId', this.authority.aID);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getAuthorities', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.authority = this.responseData.data[0];
                //this.assignedData = this.responseData.data['assignedData'];
               //this.authLevel_sel = this.assignedData['authLevel'];
                this.preSetUserData();
                //let toAuthLevel = this.authLevelData.find(c => c.id == this.authLevel_sel);
                //this.aD.authLevel.setValue(toAuthLevel.id);
                //get state
                //this.countrySelect();
                //this.stateSelect();
                //this.utilitySelect();
                //this.circleSelect();
                //this.divisionSelect();
                //this.subdivisionSelect();
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.addAuthMessage = 'Unable to process yours request!';
        });
    }

    preSetUserData() {
        //console.log("authority",this.authority);
        this.aD.uid.setValue(this.authority.aID | 0);
        this.aD.authPassword.setValue(this.authority.authPassword);
        this.aD.authorityActiveStatus.setValue(this.authority.authActiveStatus);
       
        this.aD.authorityDOB.setValue(this.formateDate(this.authority.authDOB));
        //this.date = new FormControl(new Date(this.authority.authDOB));
        this.aD.authLandlineNo.setValue(this.authority.authLandLine);
        this.aD.authEmailId.setValue(this.authority.authEmail);
        this.aD.authFullName.setValue(this.authority.authFullName);
        this.aD.authHomeAddressline1.setValue(this.authority.authHomeAdd1);
        this.aD.authHomeAddressline2.setValue(this.authority.authHomeAdd2);
        this.aD.authMobileNo.setValue(this.authority.authMobileId);
        this.aD.authOfficeAddressline1.setValue(this.authority.authOffAdd1);
        this.aD.authOfficeAddressline2.setValue(this.authority.authOffAdd2);
        this.aD.authUserName.setValue(this.authority.authUserName);
        //this.aD.countId.setValue(this.authority.cID);
        //this.aD.stateId.setValue(this.authority.sID);
        //this.aD.utilityId.setValue(this.authority.uID);
        //this.aD.circleId.setValue(this.authority.ciID);
        //this.aD.devisionId.setValue(this.authority.dID);
        this.aD.subdivId.setValue(this.authority.subDivName);
        if(this.status){
            this.aD.authType.setValue(this.authority.authType);
        }else{
            this.aD.authoritySMSReceiveStatus.setValue(this.authority.authoritySMSReceiveStatus);
        }
    }

    formateDate(datevalue) {
        let date = new Date(datevalue);
        return (date.getMonth()+1)+'/'+(date.getDate()+1)+'/'+date.getFullYear();
      }

    defaultCountry() {
        this.countryLevelData = [{
            id: '0',
            text: "Select Country"
        }];
        this.loadCountryData();
    }
    defaultState() {
        this.stateLevelData = [
            {
                id: '0',
                text: "Select State"
            }];
    }
    defaultUtility() {
        this.utilityLevelData = [
            {
                id: '0',
                text: "Select Utility"
            }];
    }

    //default circle
    defaultCircle() {
        this.circleLevelData = [
            {
                id: '0',
                text: "Select Circle"
            }];
    }

    //default division
    defaultDivision() {
        this.divisionLevelData = [
            {
                id: '0',
                text: "Select Division"
            }];
    }

    //default sub division
    defaultSubDivision() {
        this.subdivisionLevelData = [
            {
                id: '0',
                text: "Select Sub-Division"
            }];
    }

    //default DCU
    defaultDcu() {
        this.dcuLevelData = [
            {
                id: '0',
                text: "Select DCU"
            }];
    }

    authLevelChanged(e) {
        this.authMulti1 = false;
        this.authMulti2 = false;
        this.authMulti3 = false;
        this.authMulti4 = false;
        this.authMulti5 = false;
        this.authMulti6 = false;
        this.authMulti7 = false;
        this.authMulti8 = false;
        if (e.value == 1) {
            this.authMulti1 = true;
        }
        if (e.value == 2) {
            this.authMulti2 = true;
        }
        if (e.value == 3) {
            this.authMulti3 = true;
        }
        if (e.value == 4) {
            this.authMulti4 = true;
        }
        if (e.value == 5) {
            this.authMulti5 = true;
        }
        if (e.value == 6) {
            this.authMulti6 = true;
        }
        if (e.value == 7) {
            this.authMulti7 = true;
        }
        if (e.value == 8) {
            this.authMulti8 = true;
        }
        //reset all values
        this.defaultCountry();
        this.defaultState();
        this.defaultUtility();
        this.defaultCircle();
        this.defaultDivision();
        this.defaultSubDivision();
        this.defaultDcu();
    }

    loadCountryData() {
        this.genReqs.genReq('/getCountry').then((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.countryLevelData = [...this.countryLevelData,
                    {
                        id: item.country_id,
                        text: item.countName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    countrySelect() {
        this.defaultState();
        let body = new URLSearchParams();
        //body.set('country', e.value.toString());
        body.set('country', this.aD.countId.value);
        this.genReqs.postReq('/getState', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.stateLevelData = [...this.stateLevelData,
                    {
                        id: item.state_id,
                        text: item.stateName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    stateSelect() {
        this.defaultUtility();
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        this.genReqs.postReq('/getUtility', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.utilityLevelData = [...this.utilityLevelData,
                    {
                        id: item.utility_id,
                        text: item.utilityName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    utilitySelect() {
        this.defaultCircle();
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        this.genReqs.postReq('/getCircle', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.circleLevelData = [...this.circleLevelData,
                    {
                        id: item.circle_id,
                        text: item.circleName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    circleSelect() {
        this.defaultDivision();
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('circle', this.aD.circleId.value);
        this.genReqs.postReq('/getDivision', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.divisionLevelData = [...this.divisionLevelData,
                    {
                        id: item.division_id,
                        text: item.divisionName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }
    divisionSelect() {
        this.defaultSubDivision();
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('circle', this.aD.circleId.value);
        body.set('division', this.aD.devisionId.value);
        this.genReqs.postReq('/getSubDivision', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.subdivisionLevelData = [...this.subdivisionLevelData,
                    {
                        id: item.sub_dev_id,
                        text: item.sub_dev_name
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    subdivisionSelect() {
        this.defaultDcu();
        let body = new URLSearchParams();
        body.set('subDivisionId', this.aD.subdivId.value);
        body.set('authToken', this.currentUser.authToken);
        body.set('offSet', '0');
        body.set('limit', '100000');
        this.genReqs.postReq('/getDCU', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.dcuLevelData = [...this.dcuLevelData,
                    {
                        id: item.dcuID,
                        text: item.dcuName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.authorityDOB = eyear + '-' + emonth + '-' + eday;
            
        }
    }

}
