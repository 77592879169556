import { Component, OnInit } from '@angular/core';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ReportsAccess } from 'src/app/_models/reportsaccess';
import { TableUtil } from '../TableUtil';

@Component({
  selector: 'app-recentnameplatedata',
  templateUrl: './recentnameplatedata.component.html',
  styleUrls: ['./recentnameplatedata.component.scss']
})
export class RecentnameplatedataComponent {
  currentUser: User;
  loading:boolean = false;
  currentUserSubscription: Subscription;
  responseData: any;
  meterDetail:any = {};
  getReportThrough: ReportsAccess;
  faSpinner = faSpinner;
  isLoading:boolean = false;
  isData:boolean = false;
  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal

  ) {
  
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getReportThrough.subscribe(nav => this.getReportThrough = nav);
  }

  ngOnInit() {
    console.log(this.getReportThrough);
    this.getRealTimeStatus();
  }


  getRealTimeStatus() {
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('meterId', this.meterDetail.muID);
    body.set('consId', this.meterDetail.cACNO);
    body.set('cmdFrom', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNamePlateData', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        this.isData = true;
        this.letviewis = this.responseData.data;
        //console.log("--------------", this.letviewis);
      }else{  
        this.isData = false;
      }
    },(err)=>{
      this.isLoading = false;
      this.isData = false;
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("NamePlateData", "Nameplate Data");
  }
}