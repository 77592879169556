import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-meter-activity',
  templateUrl: './meter-activity.component.html',
  styleUrls: ['./meter-activity.component.scss']
})
export class MeterActivityComponent implements OnInit {
  faSpinner = faSpinner;
  public today = new Date();
  message:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  actionForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  AddProcess:boolean = false;
  responseData: any;
  meterDetail:any = {};

  data = true;
  isLoading = false;
 
  thumb:boolean = false;
  servantmode:any;
  getThrough: Access;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService

  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    this.actionForm = this.formBuilder.group({
      servantmode: ['', Validators.required],
      //meterload: [''],
      //backupSupply: [''],
      //startfixedchargesonmeter: [''],
      //startemergencycredit: [''],
    });
    //this.aD.servantmode.setValue('L_2');
    
  }
  
  get aF() { return this.actionForm.controls; }
  get aD() { return this.actionForm.controls; }

  ngOnDestroy() {
    //this.modalService.dismissAll();
  }


  /*public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }*/

  onSubmitAddForm(){
    if (this.actionForm.invalid) {
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('servantmode', this.aD.servantmode.value);
    //body.set('meterload', this.aD.meterload.value);
   // body.set('backupSupply', this.aD.backupSupply.value);
    body.set('cmdFrom', '0');
    
    /*if(this.aD.startfixedchargesonmeter.value){
      body.set('startfixedchargesonmeter', 'Y');
    }else{
      body.set('startfixedchargesonmeter', 'N');
    }
    if(this.aD.startemergencycredit.value){
      body.set('startemergencycredit','Y');
    }else{
      body.set('startemergencycredit','N');
    }*/
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/addMeterActivityDesh', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || 
          this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || 
          this.message.includes("Under Progress") || this.message.includes("Not Generate") || 
          this.message.includes("Negative balance")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

}
