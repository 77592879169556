<html lang="en-US">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="refresh" content="0; url=https://myavonlive.com">
        <script type="text/javascript">
            window.location.href = "https://myavonlive.com"
        </script>
        <title>Page Redirection</title>
    </head>
    <body>
        <!-- Note: don't tell people to `click` the link, just tell them that it is a link. -->
        If you are not redirected automatically, follow this <a href='https://myavonlive.com'>link to example</a>.
    </body>
</html>