import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigcreditemerg',
  templateUrl: './meterconfigcreditemerg.component.html',
  styleUrls: ['./meterconfigcreditemerg.component.scss']
})
export class MeterconfigcreditemergComponent implements OnInit {
  addForm: UntypedFormGroup;
  booleanVals = [
    {name: 'Yes'},
    {name: 'No'}
  ];
  monthFreq = [
    {name: '1'},
    {name: '2'},
    {name: '3'},
    {name: '4'},
    {name: '5'},
    {name: '6'},
    {name: '7'},
    {name: '8'},
    {name: '9'},
    {name: '10'},
  ];
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      ecas: ['', Validators.required],
      ecgfm: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
