import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conscomplaint',
  templateUrl: './conscomplaint.component.html',
  styleUrls: ['./conscomplaint.component.scss']
})
export class ConscomplaintComponent implements OnInit {
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  submitted: boolean = false;
  
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal
    ){ }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      consSubDivision: ['', Validators.required],
      consName: ['', Validators.required],
      consComplaint: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
       
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      const formData = new FormData();
      formData.append('subDivisionCode', this.aF.Code.value);
      formData.append('subDivisionName', this.aF.Name.value);
    }
}
