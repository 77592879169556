<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header">
    <h3><mat-icon matPrefix>add_to_queue</mat-icon>
     &nbsp; Add Meter</h3>
     <button type="button"  (click)="modal.close('Ok click')" class="dilog4 dilog5">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
      <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
      <div class="form-groupp">
          <!-- Grid row -->
          <div class="form-row">
            <!-- Default input -->
            <!--<mat-form-field class="col-md-6">
              <mat-select placeholder="Flat Id" name="flatNo" formControlName="flatNo">
                <mat-option>Select Flat No...</mat-option>
                <mat-option *ngFor="let flats of flatIdData" [value]="flats.consAccountNo">
                  {{flats.flatNo}} - ({{flats.meterId}})
                </mat-option>
              </mat-select>
              <mat-icon matPrefix class="d-c2">grade</mat-icon>
              <mat-error *ngIf="aF.flatNo.hasError('required')">
                Flat No is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->
  
            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Id" name="meterId" formControlName="meterId">
              <mat-icon matPrefix class="d-c3">airplay</mat-icon>
              <mat-error *ngIf="aF.meterId.hasError('required')">
                Meter Id is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <!--<mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Firmware Unique Id" name="meterFirmwareUniqueId" formControlName="meterFirmwareUniqueId">
              <mat-icon matPrefix class="d-c4">album</mat-icon>
              <mat-error *ngIf="aF.meterFirmwareUniqueId.hasError('required')">
                Meter Id is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter LPR Id" name="meterLPRId" formControlName="meterLPRId">
              <mat-icon matPrefix class="d-c5">art_track</mat-icon>
              <mat-error *ngIf="aF.meterLPRId.hasError('required')">
                Meter Id is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter GPRS Sim No" name="meterGPRSSimNo" formControlName="meterGPRSSimNo">
              <mat-icon matPrefix class="d-c6">stay_current_landscape</mat-icon>
              <mat-error *ngIf="aF.meterGPRSSimNo.hasError('required')">
                Meter GPRS Sim No is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter GPRS Sim IMEI No" name="meterGPRSSimIMEINo" formControlName="meterGPRSSimIMEINo">
              <mat-icon matPrefix class="d-c7">rss_feed</mat-icon>
              <mat-error *ngIf="aF.meterGPRSSimIMEINo.hasError('required')">
                Meter GPRS Sim IMEI No is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter RTC Date Time" name="meterRTCDateTime" formControlName="meterRTCDateTime">
              <mat-icon matPrefix class="d-c8">access_time</mat-icon>
              <mat-error *ngIf="aF.meterRTCDateTime.hasError('required')">
                Meter RTC Date Time is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter GIS Location" name="meterGISLocation" formControlName="meterGISLocation">
              <mat-icon matPrefix class="d-c9">signal_cellular_alt</mat-icon>
              <mat-error *ngIf="aF.meterGISLocation.hasError('required')">
                Meter GIS Location is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Installed Location Address" name="meterInstalledLocationAddress" formControlName="meterInstalledLocationAddress">
              <mat-icon matPrefix class="d-c10">library_books</mat-icon>
              <mat-error *ngIf="aF.meterInstalledLocationAddress.hasError('required')">
                Meter Installed Location Address is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Load Limit EB Supply" name="meterLoadLimitEBSupply" formControlName="meterLoadLimitEBSupply">
              <mat-icon matPrefix class="d-c11">trending_up</mat-icon>
              <mat-error *ngIf="aF.meterLoadLimitEBSupply.hasError('required')">
                Meter Load Limit EB Supply is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Load Limit DG Supply" name="meterLoadLimitDGSupply" formControlName="meterLoadLimitDGSupply">
              <mat-icon matPrefix class="d-c12">trending_up</mat-icon>
              <mat-error *ngIf="aF.meterLoadLimitDGSupply.hasError('required')">
                Meter Load Limit DG Supply is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="EB kWH Start Units" name="eBkWHStartUnits" formControlName="eBkWHStartUnits">
              <mat-icon matPrefix class="d-c13">waves</mat-icon>
              <mat-error *ngIf="aF.eBkWHStartUnits.hasError('required')">
                EB kWH Start Units is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Dg kWH Start Units" name="dgkWHStartUnits" formControlName="dgkWHStartUnits">
              <mat-icon matPrefix class="d-c14">waves</mat-icon>
              <mat-error *ngIf="aF.dgkWHStartUnits.hasError('required')">
                Dg kWH Start Units is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Start Balance" name="meterStartBalance" formControlName="meterStartBalance">
              <mat-icon matPrefix class="d-c15">business</mat-icon>
              <mat-error *ngIf="aF.meterStartBalance.hasError('required')">
                Meter Start Balance is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Alert Sending Type" name="meterAlertSendingType" formControlName="meterAlertSendingType">
              <mat-icon matPrefix class="d-c2">warning</mat-icon>
              <mat-error *ngIf="aF.meterAlertSendingType.hasError('required')">
                Meter Alert Sending Type is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Installetion Date Time" name="meterInstalletionDateTime" formControlName="meterInstalletionDateTime">
              <mat-icon matPrefix class="d-c2">date_range</mat-icon>
              <mat-error *ngIf="aF.meterInstalletionDateTime.hasError('required')">
                Meter Installetion Date Time is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <mat-select placeholder="Meter Active Status" name="meterActiveStatus" formControlName="meterActiveStatus" >
                <mat-option>None</mat-option>
                <mat-option value="1">Active</mat-option>
                <mat-option value="2">InActive</mat-option>
              </mat-select>
              <mat-icon matPrefix class="d-c3">settings_input_antenna</mat-icon>
              <mat-error *ngIf="aF.meterActiveStatus.hasError('required')">
                Meter Active Status is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="d-c4">settings_input_antenna</mat-icon>
              <mat-select placeholder="Meter Type" name="meterType" formControlName="meterType" >
                <mat-option>None</mat-option>
                <mat-option value="1">Single Phase Meter</mat-option>
                <mat-option value="3">Three Phase Meter</mat-option>
              </mat-select>
              <mat-error *ngIf="aF.meterType.hasError('required')">
                Meter Type is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Ip Address" name="meterIpAddress" formControlName="meterIpAddress">
              <mat-icon matPrefix class="d-c5">vertical_split</mat-icon>
              <mat-error *ngIf="aF.meterIpAddress.hasError('required')">
                Meter Ip Address is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Port No" name="meterPortNo" formControlName="meterPortNo">
              <mat-icon matPrefix class="d-c6">local_activity</mat-icon>
              <mat-error *ngIf="aF.meterPortNo.hasError('required')">
                Meter Port No is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Server Ip Address" name="meterServerIpAddress" formControlName="meterServerIpAddress">
              <mat-icon matPrefix class="d-c7">graphic_eq</mat-icon>
              <mat-error *ngIf="aF.meterServerIpAddress.hasError('required')">
                Meter Server Ip Address is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Server Port No" name="meterServerPortNo" formControlName="meterServerPortNo">
              <mat-icon matPrefix class="d-c8">storage</mat-icon>
              <mat-error *ngIf="aF.meterServerPortNo.hasError('required')">
                Meter Server Port No is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">
              <mat-icon matPrefix class="d-c9">report_problem</mat-icon>
              <mat-error *ngIf="aF.meterFirmwareCodeVersion.hasError('required')">
                Meter Firmware Code Version is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Low Balance Alarm Level" name="alarmLevel" formControlName="alarmLevel">
              <mat-icon matPrefix class="d-c10">report_problem</mat-icon>
              <mat-error *ngIf="aF.meterFirmwareCodeVersion.hasError('required')">
                Meter Alarm Level Version is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Grid row -->
          <div class="clearfix"></div>
              <div class="col-12 text-right">
                <button type="submit" class="dilog-button dilog-button1">Add</button>
                <button type="button" class="dilog-button dilog-button1" (click)="modal.close('Ok click')" >cancel</button>
                 
          </div>
      </div>
  </div>
  </form>