import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from '../../_services/modal.service';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtilWithoutTime } from 'src/app/deshboardauthall/TableUtilWithoutTime';

@Component({
  selector: 'app-backgroundwork',
  templateUrl: './backgroundwork.component.html',
  styleUrls: ['./backgroundwork.component.scss']
})
export class BackgroundworkComponent {
  @ViewChild('getBulkMeterIPAddress') getBulkMeterIPAddress!: ElementRef;
  getBulkIpAddress: string = '<table id="GetBulkMeterIPAddress" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('addMetersWithoutAPP') addMetersWithoutAPP!: ElementRef;
  addMetersWithoutAP: string = '<table id="AddMetersWithoutAPP" border="1">'+
    '<tr>'+
      '<th>Account No</th>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('removedInactiveMeters') removedInactiveMeters!: ElementRef;
  removedInactiveMeter: string = '<table id="RemovedInactiveMeters" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('updateIPOnLocalHES') updateIPOnLocalHES!: ElementRef;
  updateIPOnLocalHE: string = '<table id="UpdateIPOnLocalHES" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
      '<th>IP Address</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('sIMActivation') sIMActivation!: ElementRef;
  sIMActivatio: string = '<table id="SIMActivation" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('uploadMetersWOutSIMActivate') uploadMetersWOutSIMActivate!: ElementRef;
  uploadMetersWOutSIMActivat: string = '<table id="UploadMetersWOutSIMActivate" border="1">'+
    '<tr>'+
      '<th>Account No</th>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('uploadMetersWithSIMActivation') uploadMetersWithSIMActivation!: ElementRef;
  uploadMetersWithSIMActivatio: string = '<table id="UploadMetersWithSIMActivation" border="1">'+
    '<tr>'+
      '<th>Account No</th>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('uploadPSPCLPortalData') uploadPSPCLPortalData!: ElementRef;
  uploadPSPCLPortalDat: string = '<table id="UploadPSPCLPortalData" border="1">'+
    '<tr>'+
      '<th>id</th>'+	
      '<th>Meter Installation ID</th>'+
      '<th>Make</th>'+
      '<th>Serial No</th>'+
      '<th>Meter Digits</th>'+
      '<th>MF</th>'+
      '<th>Class</th>'+
      '<th>Category</th>'+
      '<th>Memory</th>'+
      '<th>Protocol</th>'+
      '<th>Capacity</th>'+
      '<th>Voltage</th>'+
      '<th>Event</th>'+
      '<th>Date of event</th>'+
      '<th>Health Type</th>'+
      '<th>Reading</th>'+
      '<th>Seal Status</th>'+
      '<th>Remarks</th>'+
      '<th>Latitude</th>'+
      '<th>Longtitude</th>'+
      '<th>PSPCL Account Number</th>'+
      '<th>Consumer Name</th>'+
      '<th>Consumer Address</th>'+
      '<th>Load (KW)</th>'+
      '<th>Location Name</th>'+
      '<th>Consumer Mobile Number</th>'+
      '<th>Subdivision</th>'+
      '<th>Division</th>'+
      '<th>Circle</th>'+
      '<th>Zone</th>'+
      '<th>Accepted by</th>'+
      '<th>JE Accept Date</th>'+
      '<th>Meter Image</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('getIPAddress') getIPAddress!: ElementRef;
  getIPAddres: string = '<table id="GetIPAddress" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('uploadAirtelSIMInv') uploadAirtelSIMInv!: ElementRef;
  uploadAirtelSIMIn: string = '<table id="UploadAirtelSIMInv" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';

  @ViewChild('uploadSimMobileNo') uploadSimMobileNo!: ElementRef;
  uploadSimMobileN: string = '<table id="UploadSimMobileNo" border="1">'+
    '<tr>'+
      '<th>Meter No</th>'+
    '</tr>'+
  '</table>';


  isChecked: any = true;
  messageResult: any;
  defaultUStatus = 'A';
  
  defaultZone: any = "a";
  defaultCircle: any = "a";
  defaultDivision: any = "a";
  defaultSubDivision: any = "a";
  defaultAssignedStatus: any = "a";
  defaultVisitStatus: any = "a";

  defaultMeterType='A';
  defaultMCUType='A';
  loading: boolean = true;
  enableSocietyChange: boolean = true;
  selectedSubDivision: any;
  currentUser: User;
  responseData: any;
  currentUserSubscription: Subscription;
  dataSource: any = new MatTableDataSource([]);
  loadingMessage: any = '';
  levelSearch: string = '';
  faSpinner = faSpinner;
  length = 0;
  circleData: any = [];
  zoneData: any = [];
  divisionData: any = [];
  subDivisionData: any = [];

  OldMeterImgAdd: string = '';
  dataArray: any = [{ name: '', value: '' }];

  levelHeadsCols = [
    { label: "Sr No", name: "srNo", checked: true },
    { label: "Ticket Id", name: "ticketId", checked: true },
    { label: "Account No", name: "ivrsNo", checked: true },
    { label: "Zone", name: "conZone", checked: false },
    { label: "Circle", name: "jeCircle", checked: false },
    { label: "Division", name: "conDivision", checked: false },
    { label: "Sub-Division", name: "conFeeder", checked: true },
    { label: "New Meter Id", name: "newMeterId", checked: true },
    { label: "Meter Ip Address", name: "meterIpAddress", checked: false },
    { label: "Location", name: "location", checked: false },
    { label: "Cons Address", name: "address", checked: false },
    { label: "Cons Mobile No", name: "consMobileNo", checked: false },
    { label: "Je Mobile No", name: "jeMobileNo", checked: false },
    { label: "Assignment", name: "assignment", checked: true },
    { label: "Site Visit", name: "siteVisit", checked: true },
    { label: "Remark", name: "remark", checked: true },
    { label: "Execution D/T", name: "executionDt", checked: false },
    { label: "Assigned D/T", name: "assignedDt", checked: false },
    { label: "Upload D/T", name: "dateTime", checked: true },
    { label: "Assigned Authority", name: "assignedName", checked: false },
    { label: "Authority Name", name: "authName", checked: false },
    { label: "Edit", name: "edit", checked: true },
  ];

  levelColumnsToDisplay = [
    'srNo',
    'ticketId',
    'ivrsNo',
    'conFeeder',
    'newMeterId',
    'assignment',
    'siteVisit',
    'remark',
    'dateTime',
    'edit'
  ];

  data: string;
  removeProcess: boolean = false;
  responseMessage: string = '';
  allLevelTotalRecord: number = 0;
  //pagination settings
  limit: number = 20;
  skip: number = 0;
  filter = '';
  totalLength: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 30];
  getThrough: Access;
  constructor(
    public modal: NgbActiveModal,
    private modalService: ModalService,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    if (this.currentUser.data.userData.authLevel == '7') {
      this.enableSocietyChange = false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  ngOnInit() {
    //this.getMeterCommandListing();
    //this.selectedSubDivision = this.currentUser.data.userData.subdivision;
    //this.getZoneData();
    //this.getDivisionData();
    //this.getSubDivisionData();
    //this.getCircleData();
  }
  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getBulkMeterIPAddress.nativeElement.innerHTML = this.getBulkIpAddress;
    this.addMetersWithoutAPP.nativeElement.innerHTML = this.addMetersWithoutAP;
    this.removedInactiveMeters.nativeElement.innerHTML = this.removedInactiveMeter;
    this.updateIPOnLocalHES.nativeElement.innerHTML = this.updateIPOnLocalHE;
    this.sIMActivation.nativeElement.innerHTML = this.sIMActivatio;
    this.uploadMetersWOutSIMActivate.nativeElement.innerHTML = this.uploadMetersWOutSIMActivat;
    this.uploadMetersWithSIMActivation.nativeElement.innerHTML = this.uploadMetersWithSIMActivatio;
    this.uploadPSPCLPortalData.nativeElement.innerHTML = this.uploadPSPCLPortalDat;
    this.getIPAddress.nativeElement.innerHTML = this.getIPAddres;
    this.uploadAirtelSIMInv.nativeElement.innerHTML = this.uploadAirtelSIMIn;
    this.uploadSimMobileNo.nativeElement.innerHTML = this.uploadSimMobileN;
  }
  
 
  addAmiMeters(data) {
    this.modalService.addAmiMeters(data).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if (result.toString() != 'Cancel') {
        this.getMeterCommandListing();
      }
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }
  

  onLevelSearchChange(search) {
    this.filter = search;
    this.skip = 0;
    this.getMeterCommandListing();
  }



  onChange($event: Event, row) {
    console.log(row);
    console.log($event);
    console.log("value changed");
  }

  getMeterCommandListing() {
    this.loading = true;
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('meterSubDivision', this.selectedSubDivision);
    } else {
      body.set('meterSubDivision', this.currentUser.data.userData.subdivision);
    }
    if(this.currentUser.data.userData.authorityType=='Y'){
      body.set('defaultZone', this.currentUser.data.userData.zoneId);
      body.set('defaultCircle', this.currentUser.data.userData.circleId);
      body.set('defaultDivision', this.currentUser.data.userData.divisionId);
      body.set('defaultSubDivision', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId+"-"+this.currentUser.data.userData.divisionId+"-"+this.currentUser.data.userData.subDivisionId);
    }else if(this.currentUser.data.userData.authorityType=='X'){
      body.set('defaultZone', this.currentUser.data.userData.zoneId);
      body.set('defaultCircle', this.defaultCircle.toString());
      body.set('defaultDivision', this.defaultDivision.toString());
      body.set('defaultSubDivision', this.defaultSubDivision.toString());
    }else if(this.currentUser.data.userData.authorityType=='S'){
      body.set('defaultZone', this.currentUser.data.userData.zoneId);
      body.set('defaultCircle', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId);
      body.set('defaultDivision', this.defaultDivision.toString());
      body.set('defaultSubDivision', this.defaultSubDivision.toString());
    }else if(this.currentUser.data.userData.authorityType=='D'){
      body.set('defaultZone', this.currentUser.data.userData.zoneId);
      body.set('defaultCircle', this.defaultCircle.toString());
      body.set('defaultDivision', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId+"-"+this.currentUser.data.userData.divisionId);
      body.set('defaultSubDivision', this.defaultSubDivision.toString());
    }else if(this.currentUser.data.userData.authorityType=='C'){
      body.set('defaultZone', this.currentUser.data.userData.zoneId);
      body.set('defaultCircle',this.currentUser.data.userData.circleId);
      body.set('defaultDivision', this.currentUser.data.userData.divisionId);
      body.set('defaultSubDivision', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId+"-"+this.currentUser.data.userData.divisionId+"-"+this.currentUser.data.userData.subDivisionId);
    }else{
      body.set('defaultZone', this.defaultZone.toString());
      body.set('defaultCircle', this.defaultCircle.toString());
      body.set('defaultDivision', this.defaultDivision.toString());
      body.set('defaultSubDivision', this.defaultSubDivision.toString());
    }
    body.set('defaultAssignedStatus', this.defaultAssignedStatus.toString());
    body.set('defaultVisitStatus', this.defaultVisitStatus.toString());
    body.set('uploadingStatus', this.defaultUStatus.toString());
    body.set('mcoType', this.defaultMCUType.toString());
    body.set('meterType', this.defaultMeterType.toString());
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('zoneId', this.currentUser.data.userData.zoneId);
    body.set('circleId', this.currentUser.data.userData.circleId);
    body.set('divisionId', this.currentUser.data.userData.divisionId);
    body.set('subDivisionId', this.currentUser.data.userData.subDivisionId);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getAmiData', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        //console.log(this.responseData.data);
        setTimeout(() => {
          this.dataSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if (this.length < 10) {
            this.limit = this.length;
          }

        });
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    }
  }

  getPageSizeOptions() {
    if (this.length <= 20) {
      return [this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [20, 40, 80, 160, this.length];
    } else if (this.length > 320 && this.length <= 500) {
      return [20, 40, 80, 160, 320, 500];
    } else if (this.length > 500) {
      return [20, 40, 80, 160, 320, 500, this.length];
    }
  }


  downloadIpAddress() {
    TableUtilWithoutTime.exportTableToExcel("GetBulkMeterIPAddress", "GetBulkMeterIPAddress");
  }

  downloadAddMeterWithOutApp() {
    TableUtilWithoutTime.exportTableToExcel("AddMetersWithoutAPP", "AddMetersWithoutAPP");
  }

  downloadInactiveMeters() {
    TableUtilWithoutTime.exportTableToExcel("RemovedInactiveMeters", "RemovedInactiveMeters");
  }

  downloadUpdateIPOnLocalHES() {
    TableUtilWithoutTime.exportTableToExcel("UpdateIPOnLocalHES", "UpdateIPOnLocalHES");
  }

  downloadSIMActivation() {
    TableUtilWithoutTime.exportTableToExcel("SIMActivation", "SIMActivation");
  }

  downloadUploadMetersWOutSIMActivate() {
    TableUtilWithoutTime.exportTableToExcel("UploadMetersWOutSIMActivate", "UploadMetersWOutSIMActivate");
  }

  downloadUploadMetersWithSIMActivation() {
    TableUtilWithoutTime.exportTableToExcel("UploadMetersWithSIMActivation", "UploadMetersWithSIMActivation");
  }

  downloadUploadPSPCLPortalData() {
    TableUtilWithoutTime.exportTableToExcel("UploadPSPCLPortalData", "UploadPSPCLPortalData");
  }

  downloadGetIPAddress() {
    TableUtilWithoutTime.exportTableToExcel("GetIPAddress", "GetIPAddress");
  }

  downloadUploadAirtelSIMInv() {
    TableUtilWithoutTime.exportTableToExcel("UploadSimMobileNo", "UploadSimMobileNo");
    TableUtilWithoutTime.exportTableToExcel("UploadAirtelSIMInv", "UploadAirtelSIMInv");
  }
  uploadIpAddress() {
    this.modalService.editMeterCommand(this.selectedSubDivision).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if (result.toString() != 'Cancel') {
        this.getMeterCommandListing();
      }
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  
  downloadOldMeterImage(row) {
    if (this.defaultCircle == 'a') {
      //this.loading = true;
      let body = new URLSearchParams();
      body.set('zoneId', row.zoneId);
      body.set('circleId', row.circleId);
      body.set('oldId', row.oldUid);
      body.set('newId', row.newMeterUid);
      body.set('image', '0');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getOldMeterDataPnb', body).subscribe((result) => {
        this.responseData = result;
        //this.loading = false;
        //console.log(this.responseData);
        if (this.responseData.success) {
          console.log(this.responseData.data);
          //setTimeout(() => {
            this.OldMeterImgAdd= this.responseData.data.OldMeterImgAdd;
            //console.log("address",this.OldMeterImgAdd);
            if(this.responseData.data.OldMeterImgAdd.toString()!='N\/AN\/A'){
              window.open(this.responseData.data.OldMeterImgAdd.toString(), "_blank");
            }else{
              alert("Old Meters Image not available!");
            }
          //});
        } else {
          this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
        }
      }, (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loading = false;
        this.loadingMessage = 'Unable to process yours request!';
      });
     
    }else{
      window.open(row.OldMeterImgAdd, "_blank");
    }
    
  }



  downloadNewMeterImage(row) {
    /*let body = new URLSearchParams();
    if(this.enableSocietyChange){
      body.set('meterSubDivision', this.selectedSubDivision);
    }else{
      body.set('meterSubDivision', this.currentUser.data.userData.subdivision);
    }
    body.set('ivrsNo', row.ivrsNo.toString());
    body.set('meterImage', 'New');
    body.set('newMeterUid', row.newMeterUid.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/setOldNewImgCheckStatus', body).subscribe((result)=>{
      this.responseData = result;
      this.getMeterCommand(false);
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });*/
    window.open(row.newMeterImgAdd, "_blank");
  }


  downloadMcoImage(row) {
    window.open(row.mcoImgAdd, "_blank");
  }

 

  assignMeter(row) {
    this.modalService.assignMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getMeterCommandListing();
      }
      //console.log("Hello");
      console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }




  editAmiHelpDeshData(row) {
    this.modalService.editAmiHelpDeshData(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getMeterCommandListing();
      }
      //console.log("Hello");
      console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

 


  removeMeters(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove Ticket Id' + row.ticketId + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('ticketId', row.ticketId);
          body.set('subDivId', row.subDivisionId);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteTicketId', body).subscribe((result) => {
            this.responseData = result;
            this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getMeterCommandListing();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }

  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for (let col of this.levelHeadsCols) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  selectChangeMeterType(defaultMeterType) {
    this.defaultMeterType = defaultMeterType;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getMeterCommandListing();
  }

  selectChangeMCUType(defaultMCUType) {
    this.defaultMCUType = defaultMCUType;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getMeterCommandListing();
  }

  selectChangeUploadingStatus(defaultUStatus) {
    this.defaultUStatus = defaultUStatus;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getMeterCommandListing();
  }

  changeZone(defaultZone) {
    this.defaultZone = defaultZone;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getCircleData();
    this.getMeterCommandListing();
  }

  changeCircle(defaultCircle) {
    this.defaultCircle = defaultCircle;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getDivisionData();
    this.getMeterCommandListing();
  }

  changeDivision(defaultDivision) {
    this.defaultDivision = defaultDivision;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getSubDivisionData();
    this.getMeterCommandListing();
  }

  changeSubDiv(defaultSubDivision) {
    this.defaultSubDivision = defaultSubDivision;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    //this.getDivisionData();
    this.getMeterCommandListing();
  }

  changeAssignedStatus(defaultAssignedStatus) {
    this.defaultAssignedStatus = defaultAssignedStatus;
    this.skip = 0;
    this.getMeterCommandListing();
  }

  changeVisitStatus(defaultVisitStatus) {
    this.defaultVisitStatus = defaultVisitStatus;
    this.skip = 0;
    this.getMeterCommandListing();
  }

  getZoneData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getZoneDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.zoneData = this.responseData.data.circleData;
        this.getCircleData();
        if(this.defaultZone=='a'){
          this.defaultCircle='a';
          this.defaultDivision='a';
          this.defaultSubDivision='a';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }
  getCircleData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    if(this.currentUser.data.userData.authorityType=='X'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
    }else if(this.currentUser.data.userData.authorityType=='S'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
    }else if(this.currentUser.data.userData.authorityType=='D'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
    }else{
      body.set('zoneId', this.defaultZone.toString());
    }
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCircleDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.circleData = this.responseData.data.circleData;
        
        if(this.defaultCircle!='a'){
          this.getDivisionData();
        }
        if(this.defaultCircle=='a'){
          this.defaultDivision='a';
          this.defaultSubDivision='a';
          this.getDivisionData();
        }
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }
  getDivisionData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
   
    if(this.currentUser.data.userData.authorityType=='X'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.defaultCircle);
    }else if(this.currentUser.data.userData.authorityType=='S'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId);
    }else if(this.currentUser.data.userData.authorityType=='D'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId);
    }else{
      body.set('zoneId', this.defaultZone.toString());
      body.set('circleId', this.defaultCircle);
    }
   
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDivisionDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.divisionData = this.responseData.data.divisionData;
       
        if(this.defaultDivision!='a'){
         this.getSubDivisionData();
        }
        if(this.defaultDivision=='a'){
          this.defaultSubDivision='a';
          this.getSubDivisionData();
        }
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }

  getSubDivisionData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
   
    if(this.currentUser.data.userData.authorityType=='X'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.defaultCircle);
      body.set('divisionId', this.defaultDivision);
    }else if(this.currentUser.data.userData.authorityType=='S'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.currentUser.data.userData.circleId);
      body.set('divisionId', this.defaultDivision);
    }else if(this.currentUser.data.userData.authorityType=='D'){
      body.set('zoneId', this.currentUser.data.userData.zoneId);
      body.set('circleId', this.currentUser.data.userData.circleId);
      body.set('divisionId', this.currentUser.data.userData.zoneId+"-"+this.currentUser.data.userData.circleId+"-"+this.currentUser.data.userData.divisionId);
    }else{
      body.set('zoneId', this.defaultZone.toString());
      body.set('circleId', this.defaultCircle);
      body.set('divisionId', this.defaultDivision);
    }
    
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSubDivisionDataPnb', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.subDivisionData = this.responseData.data.subDivisionData;
        //this.getMeterCommandListing();
        //this.getMeterCommandListing();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }

  changePage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      if (this.allLevelTotalRecord > 0) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.getMeterCommandListing();
        // }
      }
    }
  }
}
