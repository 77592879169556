<div class="modal-content-new">

    <div class="modal-header subpage plusflexright">
        <div>
            <h4 class="modal-title" id="modal-basic-title">
                <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                    data-dismiss="modal">&times;</button>
                    Meter Details : {{meterNo.companyName}}
            </h4>
        </div>
    </div>


    <div class="modal-body">
        <ng-container>
            <!--<ng-container class="allutilitysloader col-12" *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenterNew"></fa-icon>
            </ng-container>-->

            <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
                <table class="table table-bordered table-sm table-hover">
                    <thead class="tablebghead">
                        <tr>
                            <td>
                                <div class="d_title">
                                    <span>Sr. No.</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span>Meter Type</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>Start</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>End</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>Meter Count</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>Server D/T</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>Authority</span>
                                </div>
                            </td>
                            <td>
                                <div class="d_title ">
                                    <span>Action</span>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="tbbodystyle">
                        <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{i+1}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.meterType}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.start}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.end}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.meterCount}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.serverDT}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.authId}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content_W5">
                                    <div class="action_iconbtn">
                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Update Comment"
                                            class="btn_outlineaction" type="button" (click)="removeEntry(item)">
                                            <span>
                                                <fa-icon [icon]="faSpinner" [spin]="true" size="1x" *ngIf="isLoading"></fa-icon>
                                            </span>
                                            <span><i class="fa fa-trash" *ngIf="!isLoading"></i></span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"></td>
                            <td style="text-align-last: left;">{{totalMeters}}</td>
                            <td  colspan="3"></td>
                        </tr>
                    </tbody>
                </table>
                <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                      <div class="d_title {{item.bold_class}}">
                          <span></span>{{item.title}}
                      </div>
                      <div class="d_content">
                          <p>{{item.content}}</p>
                      </div>
                      <hr>
          </div>          
          <div class="col-12 text-right">
            <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
              <mat-icon class="d-c3">cancel</mat-icon>
              Exit
            </button>
          </div>-->
            </div>

            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
                <div class="col-sm-12">
                    <img class="center" src="assets/images/Nodata.png">
                </div>

                <!--<div class="col-12 text-right">
            <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
              <mat-icon class="d-c3">cancel</mat-icon>
              Exit
            </button>
          </div>-->
            </div>

        </ng-container>
        <ng-container col-sm-3>
        </ng-container>
    </div>
</div>