import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { User } from '../_models';
import { Access } from '../_models/access';
import { AuthenticationService, generalRequestsService, UserService } from '../_services';
import { ConsumermodalService } from '../_services/consumermodal.service';
import { Chart } from "node_modules/chart.js";
@Component({
  selector: 'app-deshboardallsos',
  templateUrl: './deshboardallsos.component.html',
  styleUrls: ['./deshboardallsos.component.scss']
})
export class DeshboardallsosComponent implements OnInit {
  bankina: string = 'BOUNCE';
  zeroBalanceMeters = 0;
  negativeMeters = 0;
  positiveMeters = 0;
  activeMeters = 0;
  deadMeters = 0;
  powerMeters = 0;
  tampered = 0;
  unableCom = 0;
  meterRelayOff = 0;
  meterRelayOn = 0;
  totalMeters = 0;

  zeroBalanceMeters2 = 0;
  negativeMeters2 = 0;
  positiveMeters2 = 0;
  activeMeters2 = 0;
  deadMeters2 = 0;
  powerMeters2 = 0;
  tampered2 = 0;
  unableCom2 = 0;
  meterRelayOff2 = 0;
  meterRelayOn2 = 0;
  totalMeters2 = 0;

  zeroBalanceMeters3 = 0;
  negativeMeters3 = 0;
  positiveMeters3 = 0;
  activeMeters3 = 0;
  deadMeters3 = 0;
  powerMeters3 = 0;
  tampered3 = 0;
  unableCom3 = 0;
  meterRelayOff3 = 0;
  meterRelayOn3 = 0;
  totalMeters3 = 0;

  zeroBalanceMeters4 = 0;
  negativeMeters4 = 0;
  positiveMeters4 = 0;
  activeMeters4 = 0;
  deadMeters4 = 0;
  powerMeters4 = 0;
  tampered4 = 0;
  unableCom4 = 0;
  meterRelayOff4 = 0;
  meterRelayOn4 = 0;
  totalMeters4 = 0;

  zeroBalanceMeters5 = 0;
  negativeMeters5 = 0;
  positiveMeters5 = 0;
  activeMeters5 = 0;
  deadMeters5 = 0;
  powerMeters5 = 0;
  tampered5 = 0;
  unableCom5 = 0;
  meterRelayOff5 = 0;
  meterRelayOn5 = 0;
  totalMeters5 = 0;

  zeroBalanceMeters6 = 0;
  negativeMeters6 = 0;
  positiveMeters6 = 0;
  activeMeters6 = 0;
  deadMeters6 = 0;
  powerMeters6 = 0;
  tampered6 = 0;
  unableCom6 = 0;
  meterRelayOff6 = 0;
  meterRelayOn6 = 0;
  totalMeters6 = 0;

  zeroBalanceMeters7 = 0;
  negativeMeters7 = 0;
  positiveMeters7 = 0;
  activeMeters7 = 0;
  deadMeters7 = 0;
  powerMeters7 = 0;
  tampered7 = 0;
  unableCom7 = 0;
  meterRelayOff7 = 0;
  meterRelayOn7 = 0;
  totalMeters7 = 0;

  zeroBalanceMeters8 = 0;
  negativeMeters8 = 0;
  positiveMeters8 = 0;
  activeMeters8 = 0;
  deadMeters8 = 0;
  powerMeters8 = 0;
  tampered8 = 0;
  unableCom8 = 0;
  meterRelayOff8 = 0;
  meterRelayOn8 = 0;
  totalMeters8 = 0;

  chart_meter_statistics1 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };
  chart_meter_statistics2 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };
  chart_meter_statistics3 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };
  chart_meter_statistics4 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };

  chart_meter_statistics5 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };

  chart_meter_statistics6 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };

  chart_meter_statistics7 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };

  chart_meter_statistics8 = {
    labels: ['Non-Communicative', 'Active', 'Inactive', 'Tampered', 'Relay Connected', 'Relay Dis-Connected'],
    label: "Meter Statistics",
    backgroundColor: [
      '#1dc4d5',
      '#5d5d5f',
      '#dc1010',
      '#824dea',
      '#017d03',
      '#a7a70e'
    ],
    data: [0, 0, 0, 0, 0, 0],
  };
  currentUser: User;
  currentUserSubscription: Subscription;
  getThrough: Access;
  messageResult = false;
  pageRefresh: any = 10;
  totalEnergyApi: any;
  loadingMessage: any;
  loading: boolean = true;
  responseData: any;
  
  vodaPhoneIPV40: any;
  vodaPhoneIPV60: any;
  jio0: any;
  airTel0: any;

  vodaPhoneIPV41: any;
  vodaPhoneIPV61: any;
  jio1: any;
  airTel1: any;


  vodaPhoneIPV42: any;
  vodaPhoneIPV62: any;
  jio2: any;
  airTel2: any;

  vodaPhoneIPV43: any;
  vodaPhoneIPV63: any;
  jio3: any;
  airTel3: any;

  vodaPhoneIPV44: any;
  vodaPhoneIPV64: any;
  jio4: any;
  airTel4: any;

  vodaPhoneIPV45: any;
  vodaPhoneIPV65: any;
  jio5: any;
  airTel5: any;

  vodaPhoneIPV46: any;
  vodaPhoneIPV66: any;
  jio6: any;
  airTel6: any;

  vodaPhoneIPV47: any;
  vodaPhoneIPV67: any;
  jio7: any;
  airTel7: any;

  

  public canvas: any;
  public ctx;
  private updateSubscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private conmodalService: ConsumermodalService

  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    this.pageRefresh = this.currentUser.data.userData.pageLoadDuration;
    this.pageRefresh = (this.pageRefresh * 1000);
    //console.log("User Data",this.currentUser);
    dispatchEvent;
  }


  ngOnInit(): void {
    this.getTotalEnergy();
    //const source = interval(this.pageRefresh);
    this.updateSubscription = interval(this.pageRefresh).subscribe(
      (val) => {
        this.getTotalEnergy()
      }
    );
  }

  getTotalEnergy() {
    this.totalEnergyApi = '/deshboadMeterCountRefresh';
    this.loading = true;
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq(this.totalEnergyApi, body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      //console.log(this.selectedSubDivision);
      if (this.responseData.success) {
        setTimeout(() => {
          //console.log("energy data", this.totalEnergyGrid,this.totalEnergyDG );
          this.totalMeters = this.responseData.data.totalMeters;
          this.activeMeters = this.responseData.data.activeMeters;
          this.deadMeters = this.responseData.data.deadMeters;
          this.powerMeters = this.responseData.data.powerMeters;
          this.unableCom = this.responseData.data.unableCom;
          this.meterRelayOff = this.responseData.data.meterRelayOff;
          this.meterRelayOn = this.responseData.data.meterRelayOn;
          this.tampered = this.responseData.data.tampered;

          this.vodaPhoneIPV40 = this.responseData.data.simCount.vodaPhoneIPV40;
          this.vodaPhoneIPV60 = this.responseData.data.simCount.vodaPhoneIPV60;
          this.jio0 = this.responseData.data.simCount.jio0;
          this.airTel0 = this.responseData.data.simCount.airTel0;

          this.vodaPhoneIPV41 = this.responseData.data.simCount.vodaPhoneIPV41;
          this.vodaPhoneIPV61 = this.responseData.data.simCount.vodaPhoneIPV61;
          this.jio1 = this.responseData.data.simCount.jio1;
          this.airTel1 = this.responseData.data.simCount.airTel1;

          this.vodaPhoneIPV42 = this.responseData.data.simCount.vodaPhoneIPV42;
          this.vodaPhoneIPV62 = this.responseData.data.simCount.vodaPhoneIPV62;
          this.jio2 = this.responseData.data.simCount.jio2;
          this.airTel2 = this.responseData.data.simCount.airTel2;

          this.vodaPhoneIPV43 = this.responseData.data.simCount.vodaPhoneIPV43;
          this.vodaPhoneIPV63 = this.responseData.data.simCount.vodaPhoneIPV63;
          this.jio3 = this.responseData.data.simCount.jio3;
          this.airTel3 = this.responseData.data.simCount.airTel3;

          this.vodaPhoneIPV44 = this.responseData.data.simCount.vodaPhoneIPV44;
          this.vodaPhoneIPV64 = this.responseData.data.simCount.vodaPhoneIPV64;
          this.jio4 = this.responseData.data.simCount.jio4;
          this.airTel4 = this.responseData.data.simCount.airTel4;

          this.vodaPhoneIPV45 = this.responseData.data.simCount.vodaPhoneIPV45;
          this.vodaPhoneIPV65 = this.responseData.data.simCount.vodaPhoneIPV65;
          this.jio5 = this.responseData.data.simCount.jio5;
          this.airTel5 = this.responseData.data.simCount.airTel5;

          this.vodaPhoneIPV46 = this.responseData.data.simCount.vodaPhoneIPV46;
          this.vodaPhoneIPV66 = this.responseData.data.simCount.vodaPhoneIPV66;
          this.jio6 = this.responseData.data.simCount.jio6;
          this.airTel6 = this.responseData.data.simCount.airTel6;

          this.vodaPhoneIPV47 = this.responseData.data.simCount.vodaPhoneIPV47;
          this.vodaPhoneIPV67 = this.responseData.data.simCount.vodaPhoneIPV67;
          this.jio7 = this.responseData.data.simCount.jio7;
          this.airTel7 = this.responseData.data.simCount.airTel7;

          this.totalMeters2 = this.responseData.data.totalMeters2;
          this.activeMeters2 = this.responseData.data.activeMeters2;
          this.deadMeters2 = this.responseData.data.deadMeters2;
          this.powerMeters2 = this.responseData.data.powerMeters2;
          this.unableCom2 = this.responseData.data.unableCom2;
          this.meterRelayOff2 = this.responseData.data.meterRelayOff2;
          this.meterRelayOn2 = this.responseData.data.meterRelayOn2;
          this.tampered2 = this.responseData.data.tampered2;

          this.totalMeters3 = this.responseData.data.totalMeters3;
          this.activeMeters3 = this.responseData.data.activeMeters3;
          this.deadMeters3 = this.responseData.data.deadMeters3;
          this.powerMeters3 = this.responseData.data.powerMeters3;
          this.unableCom3 = this.responseData.data.unableCom3;
          this.meterRelayOff3 = this.responseData.data.meterRelayOff3;
          this.meterRelayOn3 = this.responseData.data.meterRelayOn3;
          this.tampered3 = this.responseData.data.tampered3;

          this.totalMeters4 = this.responseData.data.totalMeters4;
          this.activeMeters4 = this.responseData.data.activeMeters4;
          this.deadMeters4 = this.responseData.data.deadMeters4;
          this.powerMeters4 = this.responseData.data.powerMeters4;
          this.unableCom4 = this.responseData.data.unableCom4;
          this.meterRelayOff4 = this.responseData.data.meterRelayOff4;
          this.meterRelayOn4 = this.responseData.data.meterRelayOn4;
          this.tampered4 = this.responseData.data.tampered4;

          this.totalMeters5 = this.responseData.data.totalMeters5;
          this.activeMeters5 = this.responseData.data.activeMeters5;
          this.deadMeters5 = this.responseData.data.deadMeters5;
          this.powerMeters5 = this.responseData.data.powerMeters5;
          this.unableCom5 = this.responseData.data.unableCom5;
          this.meterRelayOff5 = this.responseData.data.meterRelayOff5;
          this.meterRelayOn5 = this.responseData.data.meterRelayOn5;
          this.tampered5 = this.responseData.data.tampered5;

          this.totalMeters6 = this.responseData.data.totalMeters6;
          this.activeMeters6 = this.responseData.data.activeMeters6;
          this.deadMeters6 = this.responseData.data.deadMeters6;
          this.powerMeters6 = this.responseData.data.powerMeters6;
          this.unableCom6 = this.responseData.data.unableCom6;
          this.meterRelayOff6 = this.responseData.data.meterRelayOff6;
          this.meterRelayOn6 = this.responseData.data.meterRelayOn6;
          this.tampered6 = this.responseData.data.tampered6;

          this.totalMeters7 = this.responseData.data.totalMeters7;
          this.activeMeters7 = this.responseData.data.activeMeters7;
          this.deadMeters7 = this.responseData.data.deadMeters7;
          this.powerMeters7 = this.responseData.data.powerMeters7;
          this.unableCom7 = this.responseData.data.unableCom7;
          this.meterRelayOff7 = this.responseData.data.meterRelayOff7;
          this.meterRelayOn7 = this.responseData.data.meterRelayOn7;
          this.tampered7 = this.responseData.data.tampered7;

          this.totalMeters8 = this.responseData.data.totalMeters8;
          this.activeMeters8 = this.responseData.data.activeMeters8;
          this.deadMeters8 = this.responseData.data.deadMeters8;
          this.powerMeters8 = this.responseData.data.powerMeters8;
          this.unableCom8 = this.responseData.data.unableCom8;
          this.meterRelayOff8 = this.responseData.data.meterRelayOff8;
          this.meterRelayOn8 = this.responseData.data.meterRelayOn8;
          this.tampered8 = this.responseData.data.tampered8;

          let labelsArray1 = [];
          let bgColorArray1 = [];
          let labelsArray2 = [];
          let bgColorArray2 = [];
          let labelsArray3 = [];
          let bgColorArray3 = [];
          let labelsArray4 = [];
          let bgColorArray4 = [];
          let labelsArray5 = [];
          let bgColorArray5 = [];
          let labelsArray6 = [];
          let bgColorArray6 = [];
          let labelsArray7 = [];
          let bgColorArray7 = [];
          let labelsArray8 = [];
          let bgColorArray8 = [];

          let dataArray1 = [];
          let dataArray2 = [];
          let dataArray3 = [];
          let dataArray4 = [];
          let dataArray5 = [];
          let dataArray6 = [];
          let dataArray7 = [];
          let dataArray8 = [];
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray1.push('Non-Communicative');
            bgColorArray1.push('#d81b60');
            dataArray1.push(this.unableCom);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray1.push('Active');
            bgColorArray1.push('#2eab30');
            dataArray1.push(this.activeMeters);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray1.push('Inactive');
            bgColorArray1.push('#5d5d5f');
            dataArray1.push(this.deadMeters);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray1.push('Power Off');
            bgColorArray1.push('#dc1010');
            dataArray1.push(this.powerMeters);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray1.push('Tampered');
            bgColorArray1.push('#824dea');
            dataArray1.push(this.tampered);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray1.push('Connected');
            bgColorArray1.push('#017d03');
            dataArray1.push(this.meterRelayOn);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray1.push('Dis-Connected');
            bgColorArray1.push('#a7a70e');
            dataArray1.push(this.meterRelayOff);
          }
          //console.log("labelsArray ",labelsArray," ",bgColorArray," ",dataArray);

          this.chart_meter_statistics1 = {
            labels: labelsArray1,
            label: "Meter Statistics Umang",
            backgroundColor: bgColorArray1,
            data: dataArray1,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray2.push('Non-Communicative');
            bgColorArray2.push('#d81b60');
            dataArray2.push(this.unableCom2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray2.push('Active');
            bgColorArray2.push('#2eab30');
            dataArray2.push(this.activeMeters2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray2.push('Inactive');
            bgColorArray2.push('#5d5d5f');
            dataArray2.push(this.deadMeters2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray2.push('Power Off');
            bgColorArray2.push('#dc1010');
            dataArray2.push(this.powerMeters2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray2.push('Tampered');
            bgColorArray2.push('#824dea');
            dataArray2.push(this.tampered2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray2.push('Connected');
            bgColorArray2.push('#017d03');
            dataArray2.push(this.meterRelayOn2);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray2.push('Dis-Connected');
            bgColorArray2.push('#a7a70e');
            dataArray2.push(this.meterRelayOff2);
          }
          //console.log("labelsArray ",labelsArray," ",bgColorArray," ",dataArray);

          this.chart_meter_statistics2 = {
            labels: labelsArray2,
            label: "Meter Statistics RMG",
            backgroundColor: bgColorArray2,
            data: dataArray2,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray3.push('Non-Communicative');
            bgColorArray3.push('#d81b60');
            dataArray3.push(this.unableCom3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray3.push('Active');
            bgColorArray3.push('#2eab30');
            dataArray3.push(this.activeMeters3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray3.push('Inactive');
            bgColorArray3.push('#5d5d5f');
            dataArray3.push(this.deadMeters3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray3.push('Power Off');
            bgColorArray3.push('#dc1010');
            dataArray3.push(this.powerMeters3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray3.push('Tampered');
            bgColorArray3.push('#824dea');
            dataArray3.push(this.tampered3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray3.push('Connected');
            bgColorArray3.push('#017d03');
            dataArray3.push(this.meterRelayOn3);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray3.push('Dis-Connected');
            bgColorArray3.push('#a7a70e');
            dataArray3.push(this.meterRelayOff3);
          }
          //console.log("labelsArray ",labelsArray," ",bgColorArray," ",dataArray);

          this.chart_meter_statistics3 = {
            labels: labelsArray3,
            label: "Meter Statistics Silver City",
            backgroundColor: bgColorArray3,
            data: dataArray3,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray4.push('Non-Communicative');
            bgColorArray4.push('#d81b60');
            dataArray4.push(this.unableCom4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray4.push('Active');
            bgColorArray4.push('#2eab30');
            dataArray4.push(this.activeMeters4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray4.push('Inactive');
            bgColorArray4.push('#5d5d5f');
            dataArray4.push(this.deadMeters4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray4.push('Power Off');
            bgColorArray4.push('#dc1010');
            dataArray4.push(this.powerMeters4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray4.push('Tampered');
            bgColorArray4.push('#824dea');
            dataArray4.push(this.tampered4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray4.push('Connected');
            bgColorArray4.push('#017d03');
            dataArray4.push(this.meterRelayOn4);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray4.push('Dis-Connected');
            bgColorArray4.push('#a7a70e');
            dataArray4.push(this.meterRelayOff4);
          }
          //console.log("labelsArray ",labelsArray," ",bgColorArray," ",dataArray);

          this.chart_meter_statistics4 = {
            labels: labelsArray4,
            label: "Meter Statistics Silver Glands",
            backgroundColor: bgColorArray4,
            data: dataArray4,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray5.push('Non-Communicative');
            bgColorArray5.push('#d81b60');
            dataArray5.push(this.unableCom5);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray5.push('Active');
            bgColorArray5.push('#1dc4d5');
            dataArray5.push(this.activeMeters5);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray5.push('Inactive');
            bgColorArray5.push('#5d5d5f');
            dataArray5.push(this.deadMeters5);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray5.push('Power Off');
            bgColorArray5.push('#dc1010');
            dataArray5.push(this.powerMeters5);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray5.push('Tampered');
            bgColorArray5.push('#824dea');
            dataArray5.push(this.tampered5);
          }
          /*if(this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters){
            labelsArray5.push('Connected');
            bgColorArray5.push('darkslategray');
            dataArray5.push(this.meterRelayOn5);
          }
          if(this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters){
            labelsArray5.push('Dis-Connected');
            bgColorArray5.push('yellow');
            dataArray5.push(this.meterRelayOff5);
          }*/
          //console.log("labelsArray ",labelsArray," ",bgColorArray," ",dataArray);

          this.chart_meter_statistics5 = {
            labels: labelsArray5,
            label: "Meter Statistics Silver Glands",
            backgroundColor: bgColorArray5,
            data: dataArray5,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray6.push('Non-Communicative');
            bgColorArray6.push('#d81b60');
            dataArray6.push(this.unableCom6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray6.push('Active');
            bgColorArray6.push('#1dc4d5');
            dataArray6.push(this.activeMeters6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray6.push('Inactive');
            bgColorArray6.push('#5d5d5f');
            dataArray6.push(this.deadMeters6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray6.push('Power Off');
            bgColorArray6.push('#dc1010');
            dataArray6.push(this.powerMeters6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray6.push('Tampered');
            bgColorArray6.push('#824dea');
            dataArray6.push(this.tampered6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray6.push('Connected');
            bgColorArray6.push('#017d03');
            dataArray6.push(this.meterRelayOn6);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray6.push('Dis-Connected');
            bgColorArray6.push('#a7a70e');
            dataArray6.push(this.meterRelayOff6);
          }
          this.chart_meter_statistics6 = {
            labels: labelsArray6,
            label: "Meter Statistics RAS Residency",
            backgroundColor: bgColorArray6,
            data: dataArray6,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray7.push('Non-Communicative');
            bgColorArray7.push('#d81b60');
            dataArray7.push(this.unableCom7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray7.push('Active');
            bgColorArray7.push('#1dc4d5');
            dataArray7.push(this.activeMeters7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray7.push('Inactive');
            bgColorArray7.push('#5d5d5f');
            dataArray7.push(this.deadMeters7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray7.push('Power Off');
            bgColorArray7.push('#dc1010');
            dataArray7.push(this.powerMeters7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray7.push('Tampered');
            bgColorArray7.push('#824dea');
            dataArray7.push(this.tampered7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray7.push('Connected');
            bgColorArray7.push('#017d03');
            dataArray7.push(this.meterRelayOn7);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray7.push('Dis-Connected');
            bgColorArray7.push('#a7a70e');
            dataArray7.push(this.meterRelayOff7);
          }
          this.chart_meter_statistics7 = {
            labels: labelsArray7,
            label: "Meter Statistics RAS Basera",
            backgroundColor: bgColorArray7,
            data: dataArray7,
          };

          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters) {
            labelsArray8.push('Non-Communicative');
            bgColorArray8.push('#d81b60');
            dataArray8.push(this.unableCom8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters) {
            labelsArray8.push('Active');
            bgColorArray8.push('#1dc4d5');
            dataArray8.push(this.activeMeters8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters) {
            labelsArray8.push('Inactive');
            bgColorArray8.push('#5d5d5f');
            dataArray8.push(this.deadMeters8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters) {
            labelsArray8.push('Power Off');
            bgColorArray8.push('#dc1010');
            dataArray8.push(this.powerMeters8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters) {
            labelsArray8.push('Tampered');
            bgColorArray8.push('#824dea');
            dataArray8.push(this.tampered8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters) {
            labelsArray8.push('Connected');
            bgColorArray8.push('#017d03');
            dataArray8.push(this.meterRelayOn8);
          }
          if (this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters) {
            labelsArray8.push('Dis-Connected');
            bgColorArray8.push('#a7a70e');
            dataArray8.push(this.meterRelayOff8);
          }
          this.chart_meter_statistics8 = {
            labels: labelsArray8,
            label: "Meter Statistics Meenal Housing",
            backgroundColor: bgColorArray8,
            data: dataArray8,
          };
          /*this.chart_meter_statistics = {
            labels: ['Non-Communicative','Active', 'Inactive', 'Tampered', 'Connected', 'Dis-Connected'],
            label: "Meter Statistics",
            backgroundColor: [
              'gainsboro',
              '#2eab30',
              'red',
              'firebrick',
              'darkslategray',
              'yellow'
            ],
            data: [this.unableCom, this.activeMeters, this.deadMeters, this.tampered , this.meterRelayOn, this.meterRelayOff],
          };*/
          this.meterStatistics1();
          this.meterStatistics2();
          this.meterStatistics3();
          this.meterStatistics4();
          this.meterStatistics5();
          this.meterStatistics6();
          this.meterStatistics7();
          this.meterStatistics8();
          //this.meterStatistics();
          //console.log("hello1",this.activeMeters, this.deadMeters, this.meterRelayOff,this.meterRelayOn);
        }, 1000);
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  meterStatistics1() {
    this.canvas = document.getElementById("chart_meter_statistics1");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics1.labels,
          datasets: [{
            label: this.chart_meter_statistics1.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics1.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics1.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
  meterStatistics2() {
    this.canvas = document.getElementById("chart_meter_statistics2");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics2.labels,
          datasets: [{
            label: this.chart_meter_statistics2.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics2.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics2.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
  meterStatistics3() {
    this.canvas = document.getElementById("chart_meter_statistics3");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics3.labels,
          datasets: [{
            label: this.chart_meter_statistics3.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics3.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics3.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
  meterStatistics4() {
    this.canvas = document.getElementById("chart_meter_statistics4");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics4.labels,
          datasets: [{
            label: this.chart_meter_statistics4.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics4.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics4.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
  meterStatistics5() {
    this.canvas = document.getElementById("chart_meter_statistics5");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics5.labels,
          datasets: [{
            label: this.chart_meter_statistics5.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics5.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics5.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
  meterStatistics6() {
    this.canvas = document.getElementById("chart_meter_statistics6");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics6.labels,
          datasets: [{
            label: this.chart_meter_statistics6.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics6.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics6.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }

  meterStatistics7() {
    this.canvas = document.getElementById("chart_meter_statistics7");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics7.labels,
          datasets: [{
            label: this.chart_meter_statistics7.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics7.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics7.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }

  meterStatistics8() {
    this.canvas = document.getElementById("chart_meter_statistics8");
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext("2d");

      new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: this.chart_meter_statistics8.labels,
          datasets: [{
            label: this.chart_meter_statistics8.label,
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: this.chart_meter_statistics8.backgroundColor,
            borderWidth: 0,
            data: this.chart_meter_statistics8.data,
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },

          legend: {

            display: true,
            position: 'right',
            align: 'start',
            usePointStyle: true,
            boxWidth: 10
          },

          // tooltips: {
          //   enabled: false
          // },

          data: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    }
  }
}
