<div class="container-fluid innerpage_top page-content-wrapper" cdkDropList>
    <div class="content-viewport">
        <div class="page-content-wrapper-inner outerbox_shadow tblH_pagination">

            <div class="boxflexEll row">
                <div class="col-md-5">
                    <h1> Installed Meter Summary Report
                        <span class="info_icntop ml-1">
                            <i data-toggle="tooltip" data-placement="top"
                                title="Information of Installed Meter Summary Report"
                                class="fa fa-info tabula-tooltip "></i>
                        </span>
                    </h1>
                </div>
                <div class="col-md-7">
                    <div class="flexsearchouter">
                        <div class="flexsearch">
                            <div class="dflex_rowouterw row">
                                <div class="dflex_roww">
                                    <div class="frowbtn">
                                        <!--<div class="">
                                            <div class="cstmdropdoen dropdown ml-2 exportanimated fadeInLeftt"
                                                *ngIf="myTemplate.length > 0">
                                                <h2 class="menu-title">Export Data</h2>
                                                <ul class="menu-dropdown-export">
                                                    <li (click)="downloadAsPDF2()"><i
                                                            class="fa fa-file-pdf-o iconcircle"></i>
                                                        <span>PDF</span>
                                                    </li>
                                                    <li (click)="csvNavigate()"><i
                                                            class="fa fa-file-excel-o iconcircle"></i>
                                                        <span>XLS</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid inner-page-content">
                <ng-container class="allutilitysloader col-sm-12" *ngIf="isFirstLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <ng-container class="allutilitysloader col-sm-12" *ngIf="isLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div class="boxflexEll row">
                    <div class="flexsearch searchtop">
                        <ng-container>
                            <a class="advncsearch" data-toggle="collapse" href="#advancedsearchauthorty" role="button"
                                aria-expanded="false" aria-controls="advancedsearchauthorty">
                                Advance Search
                            </a>
                            <div class="filter-report-options card_view_boxx reportinfo outerrowdate collapse"
                                id="advancedsearchauthorty">
                                <div class="outerrowdateall">
                                    <div class="outerrowdate">
                                        <mat-radio-group aria-label="Select an option" [(ngModel)]="Consumptionsview">
                                            <mat-radio-button (click)="clear()" value="1">Daily</mat-radio-button>
                                            <mat-radio-button (click)="clear()" value="2">Monthly</mat-radio-button>
                                            <mat-radio-button (click)="clear()" value="3">Yearly</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="outerrowdate">
                                        <div class="">
                                            <div *ngIf="Consumptionsview == '1'" class="col-sm-12">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <input class="form-control" [maxDate]="today" bsDaterangepicker
                                                            placeholder="Choose a date"
                                                            (bsValueChange)="getDateFromRange($event)"
                                                            [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }">
                                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                                        <label>Choose a date</label>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="monthflex">
                                                <div *ngIf="Consumptionsview == '2'" class="col-sm-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input class="form-control" [maxDate]="today" bsDatepicker
                                                                autocomplete="off" placeholder="Choose a month"
                                                                (bsValueChange)="getMonthFromRange($event)"
                                                                [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }">
                                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                                            <label>Choose a Month</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div *ngIf="Consumptionsview == '2'" class="col-sm-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input class="form-control" [maxDate]="today" bsDatepicker
                                                                autocomplete="off" placeholder="Choose a month"
                                                                (bsValueChange)="getMonthEndFromRange($event)"
                                                                [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }"
                                                                [(ngModel)]="monthSelection">
                                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                                            <label>Choose a Month</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="Consumptionsview == '3'" class="col-sm-12">
                                                <div class="form-group input-group">
                                                    <span class="has-float-label">
                                                        <input class="form-control" [maxDate]="today" bsDatepicker
                                                            autocomplete="off" placeholder="Choose a year"
                                                            (bsValueChange)="getYearFromRange($event)"
                                                            [bsConfig]="{ dateInputFormat: 'YYYY', minMode: 'year'  }">
                                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                                        <label>Choose a year</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="monthflex">
                                            <button class="dilog dilog1" (click)="filterData('1')"
                                                [disabled]="!canSearch" style="width: auto;">
                                                Go</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- <ng-container *ngIf="myTemplate.length > 0" id="contentToConvert">
                        <div class="setReportHeight">
                            <div class="filter-report-options card_view_box">
                             
                                    <div class="iframe-container2">
                                        <div class="" id="pdfTable" #pdfTable>
                                            
                                            <div [innerHtml]="myTemplate">
                                            </div>
                                           
                                        </div>
                                    </div>
                              
                            </div>
                        </div>
                    </ng-container> -->
                <ng-container *ngIf="myTemplate.length > 0" id="contentToConvert">
                    <div class="setReportHeight">
                        <div class="filter-report-options card_view_boxx">
                            <div class="iframe-container2">
                                <!--<h2>Recharge Report</h2>-->
                                <div class="new" id="pdfTable" #pdfTable>
                                    <!--<table class="blueTable">
                                                <tr>
                                                    <td>-->
                                    <div [innerHtml]="myTemplate">
                                    </div>
                                    <!--</td>
                                                </tr>
                                            </table>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container class="allutilitysloader col-sm-12"
                    *ngIf="!isFirstLoading && !isLoading && myTemplate.length < 1">
                    <div class="filter-report-options card_view_box text-center"
                        style="background-color: #fff;color: #000;padding-bottom: 10px;margin-top: 0px;">
                        <div class="col-12 plr_c25px xs-no-p">
                            <div>
                                <!--<img class="center" src="assets/images/Nodata.png">-->
                                <img class="center" [src]="imageAddress">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="myTemplate.length > 0">
            <!--<mat-paginator #meterpaginator [pageSize]="reportlimit" [pageSizeOptions]="getPageSizeOptions()"
                [length]="reportTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons>
            </mat-paginator>-->
        </ng-container>



    </div>
</div>
<!-- content viewport ends -->