import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-actionondcu',
  templateUrl: './actionondcu.component.html',
  styleUrls: ['./actionondcu.component.scss']
})
export class ActionondcuComponent implements OnInit {
  currentUserSubscription: Subscription;
  currentUser: User;
  enableSocietyChange:boolean = true;

  loading:boolean = false;
  actionForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  
  constructor(
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    });
    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=false;
    }
  }

  ngOnInit() {
    if(this.enableSocietyChange){
      this.whichSubDomain();
    }
    
    
  }
  get aF() { return this.actionForm.controls; }
     
  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
      }
    );
  }

  

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  onSubmitAddForm(){
    
  }

}
