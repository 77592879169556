<form [formGroup]="actionForm" (ngSubmit)="submitForm()">
    <div class="col-sm-12 row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overVoltage" formControlName="overVoltage">
                <table>
                    <div class="innertbl">
                        <label> 1) Over Voltage</label>

                        <tr>

                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="lowVoltage" formControlName="lowVoltage">
                <table>
                    <div class="innertbl">
                        <label> 2) Low Voltage</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="rPhaseCurrentRev" formControlName="rPhaseCurrentRev">
                <table>
                    <div class="innertbl">
                        <label> 3) R-Phase Currrent Reverse</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overCurrent" formControlName="overCurrent">
                <table>
                    <div class="innertbl">
                        <label> 4) Over Current (Any-Phase)</label>

                        <tr>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;width: 40%;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-sm-12 row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="earthLoading" formControlName="earthLoading">
                <table>
                    <div class="innertbl">
                        <label> 5) Earth Loading </label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="infMagnet" formControlName="infMagnet">
                <table>
                    <div class="innertbl">
                        <label> 6) Influence Of Magnet </label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="neturalDistrubance" formControlName="neturalDistrubance">
                <table>
                    <div class="innertbl">
                        <label> 7) Neutral Disturbance </label>

                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="meterCOpen" formControlName="meterCOpen">
                <table>
                    <div class="innertbl">
                        <label> 8) Meter Cover Open </label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-sm-12 row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="loadConnectDis" formControlName="loadConnectDis">
                <table>
                    <div class="innertbl">
                        <label> 9) Load Connect/Disconnect</label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="lastGasp" formControlName="lastGasp">
                <table>
                    <div class="innertbl">
                        <label> 10) Last Gasp</label>

                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="firstBreath" formControlName="firstBreath">
                <table>
                    <div class="innertbl">
                        <label> 11) First Breath</label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="mdReset" formControlName="mdReset">
                <table>
                    <div class="innertbl">
                        <label> 12) MD Reset</label>

                        <tr>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-sm-12 row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="zeroBalance" formControlName="zeroBalance">
                <table>
                    <div class="innertbl">
                        <label> 13) Zero Balance</label>


                        <tr>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="negativeBalance" formControlName="negativeBalance">
                <table>
                    <div class="innertbl">
                        <label> 14) Negative Balance</label>


                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="overloadThree" formControlName="overloadThree">
                <table>
                    <div class="innertbl">
                        <label> 15) Overload</label>

                        <tr>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="ebOn" formControlName="ebOn">
                <table>
                    <div class="innertbl">
                        <label> 16) EB On</label>


                        <tr>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-sm-12 row">
        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="dgOn" formControlName="dgOn">
                <table>
                    <div class="innertbl">
                        <label> 17) DG On</label>
                        <tr>

                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12 text-left">
            <mat-radio-group name="freqAbnormal" formControlName="freqAbnormal">
                <table>
                    <div class="innertbl">
                        <label> 18) Frequency Abnormal</label>
                        <tr>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="1">On</mat-radio-button>
                            </td>
                            <td style="background: white;text-align: -webkit-left;">
                                <mat-radio-button value="2">Off</mat-radio-button>
                            </td>
                        </tr>
                    </div>
                </table>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>
           
            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <button type="submit" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
      </div>
    <!--</fieldset>-->
</form>