import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-permconsumer',
  templateUrl: './permconsumer.component.html',
  styleUrls: ['./permconsumer.component.scss']
})
export class PermconsumerComponent implements OnInit {
  faSpinner = faSpinner;
  thumb = false;
  addConsumerForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  consumer: any;
  consFullName: any;
  consPerms: {

    Cons_Griphical_Desh_Grid_Society_View: 0,
    Cons_Griphical_Desh_Grid_Utility_View: 0,

    Cons_Griphical_Desh_Grid_Society_kWH_View: 0,
    Cons_Griphical_Desh_Grid_Society_DG_kWH_View: 0,
    Cons_Griphical_Desh_Grid_Society_Recharge_View: 0,

    Cons_Griphical_Desh_Grid_Utility_kWH_View: 0,
    Cons_Griphical_Desh_Grid_Utility_kVARH_View: 0,
    Cons_Griphical_Desh_Grid_Utility_kVAH_View: 0,

		Cons_Griphical_User_Consumption_Chart: 0, 
		Cons_Griphical_User_Consumption_Daily: 0, 
		Cons_Griphical_User_Consumption_Monthly: 0,
		Cons_Griphical_User_Consumption_Yearly: 0,
		Cons_Griphical_Power_Cut_Chat: 0,
		Cons_Griphical_Power_Cut_Daily: 0,
		Cons_Griphical_Power_Cut_Monthly: 0,
		Cons_Griphical_Power_Cut_Yearly: 0,
		Cons_Cons_UId_Column: 0,
    Cons_Meter_UId_Column: 0,
    
    Cons_Meter_DG_Column: 0,
    Cons_Meter_Tower_Column: 0,
    Cons_Meter_Group_Column: 0,
    Cons_Meter_HES_Id_Column: 0,

		Cons_Flat_Id_Column: 0,
		Cons_Meter_No_Column: 0,
		Cons_Meter_Ip_Column: 0,
		Cons_Meter_Type_Column: 0,
		Cons_LL_Grid_kW_Column: 0,
		Cons_LL_DG_kW_Column: 0,
		Cons_Last_Update_Column: 0,
		Cons_Grid_Voltage_R_Column: 0,
		Cons_Grid_Voltage_Y_Column: 0,
		Cons_Grid_Voltage_B_Column: 0,
		Cons_Grid_Phase_Current_R_Column: 0,
		Cons_Grid_Phase_Current_Y_Column: 0,
		Cons_Grid_Phase_Current_B_Column: 0,
		Cons_Grid_Nutural_Current_R_Column: 0,
		Cons_Grid_Nutural_Current_Y_Column: 0,
		Cons_Grid_Nutural_Current_B_Column: 0,
		Cons_Grid_PF_R_Column: 0,
		Cons_Grid_PF_Y_Column: 0,
		Cons_Grid_PF_B_Column: 0,
		Cons_Grid_Total_Power_Factor_Column: 0,
		Cons_Grid_Frequency_Column: 0,
		Cons_Grid_Active_Power_Column: 0,
		Cons_Grid_Reactive_Power_Column: 0,
		Cons_Grid_ApperantPower_kVA_Column: 0,
		Cons_Grid_Md_Kw_Column: 0,
		Cons_Grid_Md_kVA_Column: 0,
		Cons_Grid_kWH_Column: 0, 
		Cons_Grid_kVARH_Q1_Column: 0, 
		Cons_Grid_kVARH_Q2_Column: 0, 
		Cons_Grid_kVARH_Q3_Column: 0, 
		Cons_Grid_kVARH_Q4_Column: 0, 
		Cons_Grid_kVAH_Column: 0, 
		Cons_DG_kWH_Column: 0, 
		Cons_DG_kVAH_Column: 0, 
		Cons_Balance_Column: 0, 
		Cons_Supply_Column: 0, 
		Cons_Tamper_Column: 0, 
    Cons_Last_LS_DT_Column: 0, 
    Cons_Tamper_Count_Column: 0, 
    Cons_Billing_Count_Column: 0, 
		Cons_Last_Block_LS_DT_Column: 0, 
		Cons_Relay_Status_Column: 0, 
		Cons_Live_Status_Column: 0, 
		Cons_Action_Column: 0, 

    Cons_Real_Time_Status_Access: 0,
    Cons_On_Going_Consumption_Access: 0,
    Cons_Daily_Consumption_Access: 0,
    Cons_Recent_Recharge_Access: 0,
    Cons_Recent_Adjustment_Access: 0,
    Cons_Recent_Configuration_Access: 0,
    Cons_Recent_Bill_Access: 0,
    Cons_Recent_Event_Access: 0,
    Cons_Recent_Activity_Access: 0,
    Cons_Recent_SMS_Sent_Access: 0,
    Cons_Recent_Complaints_Access: 0,

    Cons_Menu_Action_Access: 0,
    Cons_Action_Load_Limiter_Access: 0,
    Cons_Action_Meter_Load_Access: 0,
    Recharge_Menu_Access: 0,

    Reports_Menu: 0,
    Report_Recharge_Data_Access: 0,
    Report_Adjustment_Data_Access: 0,
    Report_Bill_Data_Access: 0,
    Report_On_Going_Access: 0,
    Report_Daily_Consumption_Access: 0,
    Report_Monthly_Consumption_Access: 0,
    Report_Event_Log_Access: 0,
    Report_Meters_Activity_Log_Access: 0,
    Reports_SMS_Sent_Access: 0,
    Reports_Messages_Access: 0,
    Reports_Complaint_History_Access: 0,
    Reports_Login_Logout_Access: 0,

    Setting_Menu_Access: 0,
    Setting_Current_Teriff_Access: 0,
    Setting_load_limiter_Access: 0,
    Setting_Notification_Setting_Access: 0,

    Help_Menu_Access: 0,
    Help_Add_New_Complaint_Access: 0,
    Help_Existing_Complaint_Access: 0,
    Active_All_Permission_Access: 0,

    Updation_date_Time: '',
    Server_Date_Time: '',
    Authirity_Id: 0
  };
  loading: boolean = false;
  message: string = '';
  consumerData: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    if(this.consumer){
      this.consFullName=this.consumer.consFullName;
      this.getConsPerms();
    }else{
      this.consFullName="All Consumers";
    }
    this.addConsumerForm = this.formBuilder.group({
      //uid: [''],
      Cons_Griphical_Desh_Grid_Society_View: [''],
      Cons_Griphical_Desh_Grid_Utility_View: [''],

      Cons_Griphical_Desh_Grid_Society_kWH_View: [''],
      Cons_Griphical_Desh_Grid_Society_DG_kWH_View: [''],
      Cons_Griphical_Desh_Grid_Society_Recharge_View: [''],

      Cons_Griphical_Desh_Grid_Utility_kWH_View: [''],
      Cons_Griphical_Desh_Grid_Utility_kVARH_View: [''],
      Cons_Griphical_Desh_Grid_Utility_kVAH_View: [''],

      Cons_Griphical_User_Consumption_Chart: [''], 
      Cons_Griphical_User_Consumption_Daily: [''], 
      Cons_Griphical_User_Consumption_Monthly: [''],
      Cons_Griphical_User_Consumption_Yearly: [''],
      Cons_Griphical_Power_Cut_Chat: [''],
      Cons_Griphical_Power_Cut_Daily: [''],
      Cons_Griphical_Power_Cut_Monthly: [''],
      Cons_Griphical_Power_Cut_Yearly: [''],
      Cons_Cons_UId_Column: [''],
      Cons_Meter_UId_Column: [''],
      
      Cons_Meter_DG_Column: [''],
      Cons_Meter_Tower_Column: [''],
      Cons_Meter_Group_Column: [''],
      Cons_Meter_HES_Id_Column: [''],
      
      Cons_Flat_Id_Column: [''],
      Cons_Meter_No_Column: [''],
      Cons_Meter_Ip_Column: [''],
      Cons_Meter_Type_Column: [''],
      Cons_LL_Grid_kW_Column: [''],
      Cons_LL_DG_kW_Column: [''],
      Cons_Last_Update_Column: [''],
      Cons_Grid_Voltage_R_Column: [''],
      Cons_Grid_Voltage_Y_Column: [''],
      Cons_Grid_Voltage_B_Column: [''],
      Cons_Grid_Phase_Current_R_Column: [''],
      Cons_Grid_Phase_Current_Y_Column: [''],
      Cons_Grid_Phase_Current_B_Column: [''],
      Cons_Grid_Nutural_Current_R_Column: [''],
      Cons_Grid_Nutural_Current_Y_Column: [''],
      Cons_Grid_Nutural_Current_B_Column: [''],
      Cons_Grid_PF_R_Column: [''],
      Cons_Grid_PF_Y_Column: [''],
      Cons_Grid_PF_B_Column: [''],
      Cons_Grid_Total_Power_Factor_Column: [''],
      Cons_Grid_Frequency_Column: [''],
      Cons_Grid_Active_Power_Column: [''],
      Cons_Grid_Reactive_Power_Column: [''],
      Cons_Grid_ApperantPower_kVA_Column: [''],
      Cons_Grid_Md_Kw_Column: [''],
      Cons_Grid_Md_kVA_Column: [''],
      Cons_Grid_kWH_Column: [''], 
      Cons_Grid_kVARH_Q1_Column: [''], 
      Cons_Grid_kVARH_Q2_Column: [''], 
      Cons_Grid_kVARH_Q3_Column: [''], 
      Cons_Grid_kVARH_Q4_Column: [''], 
      Cons_Grid_kVAH_Column: [''], 
      Cons_DG_kWH_Column: [''], 
      Cons_DG_kVAH_Column: [''], 
      Cons_Balance_Column: [''], 
      Cons_Supply_Column: [''], 
      Cons_Tamper_Column: [''], 
      Cons_Last_LS_DT_Column: [''], 

      Cons_Tamper_Count_Column: [''], 
      Cons_Billing_Count_Column: [''], 

      Cons_Last_Block_LS_DT_Column: [''], 
      Cons_Relay_Status_Column: [''], 
      Cons_Live_Status_Column: [''], 
      Cons_Action_Column: [''], 

      Cons_Real_Time_Status_Access: [''],
      Cons_On_Going_Consumption_Access: [''],
      Cons_Daily_Consumption_Access: [''],
      Cons_Recent_Recharge_Access: [''],
      Cons_Recent_Adjustment_Access: [''],
      Cons_Recent_Configuration_Access: [''],
      Cons_Recent_Bill_Access: [''],
      Cons_Recent_Event_Access: [''],
      Cons_Recent_Activity_Access: [''],
      Cons_Recent_SMS_Sent_Access: [''],
      Cons_Recent_Complaints_Access: [''],

      Cons_Menu_Action_Access: [''],
      Cons_Action_Load_Limiter_Access: [''],
      Cons_Action_Meter_Load_Access: [''],
      Recharge_Menu_Access: [''],

      Reports_Menu: [''],
      Report_Recharge_Data_Access: [''],
      Report_Adjustment_Data_Access: [''],
      Report_Bill_Data_Access: [''],
      Report_On_Going_Access: [''],
      Report_Daily_Consumption_Access: [''],
      Report_Monthly_Consumption_Access: [''],
      Report_Event_Log_Access: [''],
      Report_Meters_Activity_Log_Access: [''],
      Reports_SMS_Sent_Access: [''],
      Reports_Messages_Access: [''],
      Reports_Complaint_History_Access: [''],
      Reports_Login_Logout_Access: [''],

      Setting_Menu_Access: [''],
      Setting_Current_Teriff_Access: [''],
      Setting_load_limiter_Access: [''],
      Setting_Notification_Setting_Access: [''],

      Help_Menu_Access: [''],
      Help_Add_New_Complaint_Access: [''],
      Help_Existing_Complaint_Access: [''],

      Active_All_Permission_Access: [''],
    });
  }

  get pf() { return this.addConsumerForm.controls; }
  onSubmitAddConsumer() {
    //console.log("1");
    let seletedPerms = [];
    if (this.pf.Active_All_Permission_Access.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_View');
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_View');

      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_kWH_View');
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_DG_kWH_View');
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_Recharge_View');

      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kWH_View');
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kVARH_View');
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kVAH_View');

      seletedPerms.push('Cons_Griphical_User_Consumption_Chart'); 
      seletedPerms.push('Cons_Griphical_User_Consumption_Daily'); 
      seletedPerms.push('Cons_Griphical_User_Consumption_Monthly');
      seletedPerms.push('Cons_Griphical_User_Consumption_Yearly');
      seletedPerms.push('Cons_Griphical_Power_Cut_Chat');
      seletedPerms.push('Cons_Griphical_Power_Cut_Daily');
      seletedPerms.push('Cons_Griphical_Power_Cut_Monthly');
      seletedPerms.push('Cons_Griphical_Power_Cut_Yearly');
      seletedPerms.push('Cons_Cons_UId_Column');
      seletedPerms.push('Cons_Meter_UId_Column');

      seletedPerms.push('Cons_Meter_DG_Column');
      seletedPerms.push('Cons_Meter_Tower_Column');
      seletedPerms.push('Cons_Meter_Group_Column');
      seletedPerms.push('Cons_Meter_HES_Id_Column');

      seletedPerms.push('Cons_Flat_Id_Column');
      seletedPerms.push('Cons_Meter_No_Column');
      seletedPerms.push('Cons_Meter_Ip_Column');
      seletedPerms.push('Cons_Meter_Type_Column');
      seletedPerms.push('Cons_LL_Grid_kW_Column');
      seletedPerms.push('Cons_LL_DG_kW_Column');
      seletedPerms.push('Cons_Last_Update_Column');
      seletedPerms.push('Cons_Grid_Voltage_R_Column');
      seletedPerms.push('Cons_Grid_Voltage_Y_Column');
      seletedPerms.push('Cons_Grid_Voltage_B_Column');
      seletedPerms.push('Cons_Grid_Phase_Current_R_Column');
      seletedPerms.push('Cons_Grid_Phase_Current_Y_Column');
      seletedPerms.push('Cons_Grid_Phase_Current_B_Column');
      seletedPerms.push('Cons_Grid_Nutural_Current_R_Column');
      seletedPerms.push('Cons_Grid_Nutural_Current_Y_Column');
      seletedPerms.push('Cons_Grid_Nutural_Current_B_Column');
      seletedPerms.push('Cons_Grid_PF_R_Column');
      seletedPerms.push('Cons_Grid_PF_Y_Column');
      seletedPerms.push('Cons_Grid_PF_B_Column');
      seletedPerms.push('Cons_Grid_Total_Power_Factor_Column');
      seletedPerms.push('Cons_Grid_Frequency_Column');
      seletedPerms.push('Cons_Grid_Active_Power_Column');
      seletedPerms.push('Cons_Grid_Reactive_Power_Column');
      seletedPerms.push('Cons_Grid_ApperantPower_kVA_Column');
      seletedPerms.push('Cons_Grid_Md_Kw_Column');
      seletedPerms.push('Cons_Grid_Md_kVA_Column');
      seletedPerms.push('Cons_Grid_kWH_Column'); 
      seletedPerms.push('Cons_Grid_kVARH_Q1_Column'); 
      seletedPerms.push('Cons_Grid_kVARH_Q2_Column'); 
      seletedPerms.push('Cons_Grid_kVARH_Q3_Column'); 
      seletedPerms.push('Cons_Grid_kVARH_Q4_Column'); 
      seletedPerms.push('Cons_Grid_kVAH_Column'); 
      seletedPerms.push('Cons_DG_kWH_Column'); 
      seletedPerms.push('Cons_DG_kVAH_Column'); 
      seletedPerms.push('Cons_Balance_Column'); 
      seletedPerms.push('Cons_Supply_Column'); 
      seletedPerms.push('Cons_Tamper_Column'); 
      seletedPerms.push('Cons_Last_LS_DT_Column'); 
      seletedPerms.push('Cons_Tamper_Count_Column'); 
      seletedPerms.push('Cons_Billing_Count_Column'); 
      seletedPerms.push('Cons_Last_Block_LS_DT_Column'); 
      seletedPerms.push('Cons_Relay_Status_Column'); 
      seletedPerms.push('Cons_Live_Status_Column'); 
      seletedPerms.push('Cons_Action_Column'); 

      
      seletedPerms.push('Cons_Real_Time_Status_Access');
      seletedPerms.push('Cons_On_Going_Consumption_Access');
      seletedPerms.push('Cons_Daily_Consumption_Access');
      seletedPerms.push('Cons_Recent_Recharge_Access');
      seletedPerms.push('Cons_Recent_Adjustment_Access');
      seletedPerms.push('Cons_Recent_Configuration_Access');
      seletedPerms.push('Cons_Recent_Bill_Access');
      seletedPerms.push('Cons_Recent_Event_Access');
      seletedPerms.push('Cons_Recent_Activity_Access');
      seletedPerms.push('Cons_Recent_SMS_Sent_Access');
      seletedPerms.push('Cons_Recent_Complaints_Access');

      seletedPerms.push('Cons_Menu_Action_Access');
      seletedPerms.push('Cons_Action_Load_Limiter_Access');
      seletedPerms.push('Cons_Action_Meter_Load_Access');
      seletedPerms.push('Recharge_Menu_Access');

      seletedPerms.push('Reports_Menu');
      seletedPerms.push('Report_Recharge_Data_Access');
      seletedPerms.push('Report_Adjustment_Data_Access');
      seletedPerms.push('Report_Bill_Data_Access');
      seletedPerms.push('Report_On_Going_Access');
      seletedPerms.push('Report_Daily_Consumption_Access');
      seletedPerms.push('Report_Monthly_Consumption_Access');
      seletedPerms.push('Report_Event_Log_Access');
      seletedPerms.push('Report_Meters_Activity_Log_Access');
      seletedPerms.push('Reports_SMS_Sent_Access');
      seletedPerms.push('Reports_Messages_Access');
      seletedPerms.push('Reports_Complaint_History_Access');
      seletedPerms.push('Reports_Login_Logout_Access');

      seletedPerms.push('Setting_Menu_Access');
      seletedPerms.push('Setting_Current_Teriff_Access');
      seletedPerms.push('Setting_load_limiter_Access');
      seletedPerms.push('Setting_Notification_Setting_Access');

      seletedPerms.push('Help_Menu_Access');
      seletedPerms.push('Help_Add_New_Complaint_Access');
      seletedPerms.push('Help_Existing_Complaint_Access');

    }
   
    if (this.pf.Cons_Griphical_Desh_Grid_Society_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_View');
    }

    if (this.pf.Cons_Griphical_Desh_Grid_Utility_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_View');
    }

    if (this.pf.Cons_Griphical_Desh_Grid_Society_kWH_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_kWH_View');
    }
    if (this.pf.Cons_Griphical_Desh_Grid_Society_DG_kWH_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_DG_kWH_View');
    }
    if (this.pf.Cons_Griphical_Desh_Grid_Society_Recharge_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Society_Recharge_View');
    }

    if (this.pf.Cons_Griphical_Desh_Grid_Utility_kWH_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kWH_View');
    }
    if (this.pf.Cons_Griphical_Desh_Grid_Utility_kVARH_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kVARH_View');
    }
    if (this.pf.Cons_Griphical_Desh_Grid_Utility_kVAH_View.value == true) {
      seletedPerms.push('Cons_Griphical_Desh_Grid_Utility_kVAH_View');
    }

    if (this.pf.Cons_Griphical_User_Consumption_Chart.value == true) {
      seletedPerms.push('Cons_Griphical_User_Consumption_Chart');
    } 
    if (this.pf.Cons_Griphical_User_Consumption_Daily.value == true) {
      seletedPerms.push('Cons_Griphical_User_Consumption_Daily');
    } 
    if (this.pf.Cons_Griphical_User_Consumption_Monthly.value == true) {
      seletedPerms.push('Cons_Griphical_User_Consumption_Monthly');
    }
    if (this.pf.Cons_Griphical_User_Consumption_Yearly.value == true) {
      seletedPerms.push('Cons_Griphical_User_Consumption_Yearly');
    }
    if (this.pf.Cons_Griphical_Power_Cut_Chat.value == true) {
      seletedPerms.push('Cons_Griphical_Power_Cut_Chat');
    }
    if (this.pf.Cons_Griphical_Power_Cut_Daily.value == true) {
      seletedPerms.push('Cons_Griphical_Power_Cut_Daily');
    }
    if (this.pf.Cons_Griphical_Power_Cut_Monthly.value == true) {
      seletedPerms.push('Cons_Griphical_Power_Cut_Monthly');
    }
    if (this.pf.Cons_Griphical_Power_Cut_Yearly.value == true) {
      seletedPerms.push('Cons_Griphical_Power_Cut_Yearly');
    }
    if (this.pf.Cons_Cons_UId_Column.value == true) {
      seletedPerms.push('Cons_Cons_UId_Column');
    }
    if (this.pf.Cons_Meter_UId_Column.value == true) {
      seletedPerms.push('Cons_Meter_UId_Column');
    }

    if (this.pf.Cons_Meter_DG_Column.value == true) {
      seletedPerms.push('Cons_Meter_DG_Column');
    }
    if (this.pf.Cons_Meter_Tower_Column.value == true) {
      seletedPerms.push('Cons_Meter_Tower_Column');
    }
    if (this.pf.Cons_Meter_Group_Column.value == true) {
      seletedPerms.push('Cons_Meter_Group_Column');
    }
    if (this.pf.Cons_Meter_HES_Id_Column.value == true) {
      seletedPerms.push('Cons_Meter_HES_Id_Column');
    }


    if (this.pf.Cons_Flat_Id_Column.value == true) {
      seletedPerms.push('Cons_Flat_Id_Column');
    }
    if (this.pf.Cons_Meter_No_Column.value == true) {
      seletedPerms.push('Cons_Meter_No_Column');
    }
    if (this.pf.Cons_Meter_Ip_Column.value == true) {
      seletedPerms.push('Cons_Meter_Ip_Column');
    }
    if (this.pf.Cons_Meter_Type_Column.value == true) {
      seletedPerms.push('Cons_Meter_Type_Column');
    }
    if (this.pf.Cons_LL_Grid_kW_Column.value == true) {
      seletedPerms.push('Cons_LL_Grid_kW_Column');
    }
    if (this.pf.Cons_LL_DG_kW_Column.value == true) {
      seletedPerms.push('Cons_LL_DG_kW_Column');
    }
    if (this.pf.Cons_Last_Update_Column.value == true) {
      seletedPerms.push('Cons_Last_Update_Column');
    }
    if (this.pf.Cons_Grid_Voltage_R_Column.value == true) {
      seletedPerms.push('Cons_Grid_Voltage_R_Column');
    }
    if (this.pf.Cons_Grid_Voltage_Y_Column.value == true) {
      seletedPerms.push('Cons_Grid_Voltage_Y_Column');
    }
    if (this.pf.Cons_Grid_Voltage_B_Column.value == true) {
      seletedPerms.push('Cons_Grid_Voltage_B_Column');
    }
		if (this.pf.Cons_Grid_Phase_Current_R_Column.value == true) {
      seletedPerms.push('Cons_Grid_Phase_Current_R_Column');
    }
    if (this.pf.Cons_Grid_Phase_Current_Y_Column.value == true) {
      seletedPerms.push('Cons_Grid_Phase_Current_Y_Column');
    }
    if (this.pf.Cons_Grid_Phase_Current_B_Column.value == true) {
      seletedPerms.push('Cons_Grid_Phase_Current_B_Column');
    }
    if (this.pf.Cons_Grid_Nutural_Current_R_Column.value == true) {
      seletedPerms.push('Cons_Grid_Nutural_Current_R_Column');
    }
    if (this.pf.Cons_Grid_Nutural_Current_Y_Column.value == true) {
      seletedPerms.push('Cons_Grid_Nutural_Current_Y_Column');
    }
    if (this.pf.Cons_Grid_Nutural_Current_B_Column.value == true) {
      seletedPerms.push('Cons_Grid_Nutural_Current_B_Column');
    }
    if (this.pf.Cons_Grid_PF_R_Column.value == true) {
      seletedPerms.push('Cons_Grid_PF_R_Column');
    }
    if (this.pf.Cons_Grid_PF_Y_Column.value == true) {
      seletedPerms.push('Cons_Grid_PF_Y_Column');
    }
    if (this.pf.Cons_Grid_PF_B_Column.value == true) {
      seletedPerms.push('Cons_Grid_PF_B_Column');
    }
    if (this.pf.Cons_Grid_Total_Power_Factor_Column.value == true) {
      seletedPerms.push('Cons_Grid_Total_Power_Factor_Column');
    }
    if (this.pf.Cons_Grid_Frequency_Column.value == true) {
      seletedPerms.push('Cons_Grid_Frequency_Column');
    }
    if (this.pf.Cons_Grid_Active_Power_Column.value == true) {
      seletedPerms.push('Cons_Grid_Active_Power_Column');
    }
    if (this.pf.Cons_Grid_Reactive_Power_Column.value == true) {
      seletedPerms.push('Cons_Grid_Reactive_Power_Column');
    }
    if (this.pf.Cons_Grid_ApperantPower_kVA_Column.value == true) {
      seletedPerms.push('Cons_Grid_ApperantPower_kVA_Column');
    }
    if (this.pf.Cons_Grid_Md_Kw_Column.value == true) {
      seletedPerms.push('Cons_Grid_Md_Kw_Column');
    }
    if (this.pf.Cons_Grid_Md_kVA_Column.value == true) {
      seletedPerms.push('Cons_Grid_Md_kVA_Column');
    }
    if (this.pf.Cons_Grid_kWH_Column.value == true) {
      seletedPerms.push('Cons_Grid_kWH_Column');
    } 
    if (this.pf.Cons_Grid_kVARH_Q1_Column.value == true) {
      seletedPerms.push('Cons_Grid_kVARH_Q1_Column');
    } 
    if (this.pf.Cons_Grid_kVARH_Q2_Column.value == true) {
      seletedPerms.push('Cons_Grid_kVARH_Q2_Column');
    } 
    if (this.pf.Cons_Grid_kVARH_Q3_Column.value == true) {
      seletedPerms.push('Cons_Grid_kVARH_Q3_Column');
    } 
    if (this.pf.Cons_Grid_kVARH_Q4_Column.value == true) {
      seletedPerms.push('Cons_Grid_kVARH_Q4_Column');
    } 
    if (this.pf.Cons_Grid_kVAH_Column.value == true) {
      seletedPerms.push('Cons_Grid_kVAH_Column');
    } 
    if (this.pf.Cons_DG_kWH_Column.value == true) {
      seletedPerms.push('Cons_DG_kWH_Column');
    } 
    if (this.pf.Cons_DG_kVAH_Column.value == true) {
      seletedPerms.push('Cons_DG_kVAH_Column');
    } 
    if (this.pf.Cons_Balance_Column.value == true) {
      seletedPerms.push('Cons_Balance_Column');
    } 
    if (this.pf.Cons_Supply_Column.value == true) {
      seletedPerms.push('Cons_Supply_Column');
    } 
    if (this.pf.Cons_Tamper_Column.value == true) {
      seletedPerms.push('Cons_Tamper_Column');
    } 
    if (this.pf.Cons_Last_LS_DT_Column.value == true) {
      seletedPerms.push('Cons_Last_LS_DT_Column');
    } 

    if (this.pf.Cons_Tamper_Count_Column.value == true) {
      seletedPerms.push('Cons_Tamper_Count_Column');
    } 

    if (this.pf.Cons_Billing_Count_Column.value == true) {
      seletedPerms.push('Cons_Billing_Count_Column');
    } 
    if (this.pf.Cons_Last_Block_LS_DT_Column.value == true) {
      seletedPerms.push('Cons_Last_Block_LS_DT_Column');
    } 
    if (this.pf.Cons_Relay_Status_Column.value == true) {
      seletedPerms.push('Cons_Relay_Status_Column');
    } 
    if (this.pf.Cons_Live_Status_Column.value == true) {
      seletedPerms.push('Cons_Live_Status_Column');
    } 
    if (this.pf.Cons_Action_Column.value == true) {
      seletedPerms.push('Cons_Action_Column');
    } 


    if (this.pf.Help_Existing_Complaint_Access.value == true) {
      seletedPerms.push('Help_Existing_Complaint_Access');
    }
    if (this.pf.Help_Add_New_Complaint_Access.value == true) {
      seletedPerms.push('Help_Add_New_Complaint_Access');
    }
    if (this.pf.Help_Menu_Access.value == true) {
      seletedPerms.push('Help_Menu_Access');
    }
    if (this.pf.Setting_Notification_Setting_Access.value == true) {
      seletedPerms.push('Setting_Notification_Setting_Access');
    }
    if (this.pf.Setting_load_limiter_Access.value == true) {
      seletedPerms.push('Setting_load_limiter_Access');
    }
    if (this.pf.Setting_Current_Teriff_Access.value == true) {
      seletedPerms.push('Setting_Current_Teriff_Access');
    }
    if (this.pf.Setting_Menu_Access.value == true) {
      seletedPerms.push('Setting_Menu_Access');
    }

    if (this.pf.Reports_Menu.value == true) {
      seletedPerms.push('Reports_Menu');
    }
    if (this.pf.Report_Recharge_Data_Access.value == true) {
      seletedPerms.push('Report_Recharge_Data_Access');
    }
    if (this.pf.Report_Adjustment_Data_Access.value == true) {
      seletedPerms.push('Report_Adjustment_Data_Access');
    }
    if (this.pf.Report_Bill_Data_Access.value == true) {
      seletedPerms.push('Report_Bill_Data_Access');
    }

    if (this.pf.Report_On_Going_Access.value == true) {
      seletedPerms.push('Report_On_Going_Access');
    }
    if (this.pf.Report_Daily_Consumption_Access.value == true) {
      seletedPerms.push('Report_Daily_Consumption_Access');
    }
    if (this.pf.Report_Monthly_Consumption_Access.value == true) {
      seletedPerms.push('Report_Monthly_Consumption_Access');
    }
    if (this.pf.Report_Event_Log_Access.value == true) {
      seletedPerms.push('Report_Event_Log_Access');
    }

    if (this.pf.Report_Meters_Activity_Log_Access.value == true) {
      seletedPerms.push('Report_Meters_Activity_Log_Access');
    }
    if (this.pf.Reports_SMS_Sent_Access.value == true) {
      seletedPerms.push('Reports_SMS_Sent_Access');
    }
    if (this.pf.Reports_Messages_Access.value == true) {
      seletedPerms.push('Reports_Messages_Access');
    }
    if (this.pf.Reports_Complaint_History_Access.value == true) {
      seletedPerms.push('Reports_Complaint_History_Access');
    }
    if (this.pf.Reports_Login_Logout_Access.value == true) {
      seletedPerms.push('Reports_Login_Logout_Access');
    }

    if (this.pf.Recharge_Menu_Access.value == true) {
      seletedPerms.push('Recharge_Menu_Access');
    }
    if (this.pf.Cons_Action_Meter_Load_Access.value == true) {
      seletedPerms.push('Cons_Action_Meter_Load_Access');
    }
    if (this.pf.Cons_Action_Load_Limiter_Access.value == true) {
      seletedPerms.push('Cons_Action_Load_Limiter_Access');
    }
    if (this.pf.Cons_Menu_Action_Access.value == true) {
      seletedPerms.push('Cons_Menu_Action_Access');
    }

    if (this.pf.Cons_Recent_Complaints_Access.value == true) {
      seletedPerms.push('Cons_Recent_Complaints_Access');
    }
    if (this.pf.Cons_Recent_SMS_Sent_Access.value == true) {
      seletedPerms.push('Cons_Recent_SMS_Sent_Access');
    }
    if (this.pf.Cons_Recent_Activity_Access.value == true) {
      seletedPerms.push('Cons_Recent_Activity_Access');
    }
    if (this.pf.Cons_Recent_Bill_Access.value == true) {
      seletedPerms.push('Cons_Recent_Bill_Access');
    }

    if (this.pf.Cons_Recent_Configuration_Access.value == true) {
      seletedPerms.push('Cons_Recent_Configuration_Access');
    }
    if (this.pf.Cons_Recent_Adjustment_Access.value == true) {
      seletedPerms.push('Cons_Recent_Adjustment_Access');
    }
    if (this.pf.Cons_Recent_Recharge_Access.value == true) {
      seletedPerms.push('Cons_Recent_Recharge_Access');
    }
    if (this.pf.Cons_Daily_Consumption_Access.value == true) {
      seletedPerms.push('Cons_Daily_Consumption_Access');
    }

    if (this.pf.Cons_Real_Time_Status_Access.value == true) {
      seletedPerms.push('Cons_Real_Time_Status_Access');
    }
    if (this.pf.Cons_On_Going_Consumption_Access.value == true) {
      seletedPerms.push('Cons_On_Going_Consumption_Access');
    }
    if (this.pf.Cons_Recent_Event_Access.value == true) {
      seletedPerms.push('Cons_Recent_Event_Access');
    }


    this.loading = true;
    let body = new URLSearchParams();
    body.set('seletedPerms', seletedPerms.toString());
    if(this.consumer){
      body.set('consUniqueId', this.consumer.consAccountNo);
      body.set('sub_devision', this.consumer.sdID);
    }else{
      body.set('consUniqueId','0');
      body.set('sub_devision', this.currentUser.data.userData.subdivision);
    }
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/editConsPermission', body).subscribe(
      (res) => {

        this.responseData = res;
        this.AddProcess = false;
        this.loading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = res.message;
        } else {
          this.thumb = false;
        }

      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loading = false;
      }
    );
  }

  onDragChange() {
    //console.log('hello');
  }

  getConsPerms() {
    this.loading = true;
    let body = new URLSearchParams();
   


    if(this.consumer){
      body.set('consId', this.consumer.consAccountNo);
      body.set('subDivisionId', this.consumer.sdID);
    }else{
      body.set('consId','0');
      body.set('subDivisionId','0');
    }

    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsPermission', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      if (this.responseData.success) {
        this.consPerms = this.responseData.data;
        this.addConsumerForm.patchValue(this.consPerms);
      } else {
        this.message = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.message = 'Unable to process yours request!';
    });
  }

}
