import { Component, OnInit } from '@angular/core';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { Access } from '../_models/access';
import { Router } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from '../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GridlistService } from '../_services/gridlist.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deshboardauth2',
  templateUrl: './deshboardauth2.component.html',
  styleUrls: ['./deshboardauth2.component.scss']
})
export class Deshboardauth2Component implements OnInit {

  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getGothrough : Access;
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId:any = 0;
  breakpoint: number;
  closeResult: string;
  addStateForm: UntypedFormGroup;
  editStateForm: UntypedFormGroup;
  responseMessage:string = '';
  stateName:string = '';
  faSpinner = faSpinner;
  submitted: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder,
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      
      this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
      this.breakpoint = this.gridlistService.breakpoint;
  }

  ngOnInit() {
      this.countryId = this.currentUser.data.userData.Country || 0;
      this.getAccesslevels();
      this.reCountCols(window.innerWidth);
  }

  ngAfterViewInit() {
    this.addStateForm = this.formBuilder.group({
      stateName: ['', Validators.required],
      stateCode: ['', Validators.required],
      stateImage: [null, Validators.required]
    });
    this.editStateForm = this.formBuilder.group({
      stateId: ['', Validators.required],
      stateName: ['', Validators.required],
      stateCode: ['', Validators.required],
      stateImage: ['']
    });
  }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
      this.modalService.dismissAll();
  }

  onResize(event) {
    this.reCountCols(event.target.innerWidth);
  }

  reCountCols(width:number) {
    this.breakpoint = this.gridlistService.reCountCols(width);
  }

  getAccesslevels() {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLabels', body).subscribe((result)=>{
      this.responseData = result;
      this.loadingUtilitys = false;
      if(this.responseData.success){
        this.allUsersLablesData = this.responseData.data;
        this.allutilitys = this.allUsersLablesData.allutilitys;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  // goToStateView(state_id){
  //   this.router.navigate(['/deshauthutility'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId), 'state': this.genReqs.encryptData(state_id)}});
  // }

   /**to get the meter list on click on Dcu */
   stateEditView(row, content) {
    this.responseMessage = '';
    this.eS.stateId.setValue(row.state_id);
    this.eS.stateCode.setValue(row.stateCode);
    this.eS.stateName.setValue(row.stateName);
    this.stateName = row.stateName;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

    /*removelevel(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove ' + row.countName + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          //this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('countName', row.countName);
          body.set('countId', row.country_id);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteCountry', body).subscribe((result) => {
            this.responseData = result;
            //this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getAccesslevels();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }*/

  open(content) {
    this.responseMessage = '';
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aS.stateImage.setValue(file);
    }
  }

  onFileSelectEdit(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.eS.countryImage.setValue(file);
    }
  }

  get aS() { return this.addStateForm.controls; }
  get eS() { return this.editStateForm.controls; }
  onSubmitStateCountry(){
    if (this.addStateForm.invalid) {
        this.submitted = true;
      return;
    }
    const formData = new FormData();
    formData.append('stateImage', this.aS.stateImage.value);
    formData.append('countryId', this.countryId);
    formData.append('stateName', this.aS.stateName.value);
    formData.append('stateCode', this.aS.stateCode.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/addState', formData).subscribe(
      (res) => {
        this.responseMessage = res.message;
        if (res.success) {
          this.addStateForm.reset();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

  onSubmitEditStateCountry() {
    //addCountry
      if (this.editStateForm.invalid) {
        return;
      }
      const formData = new FormData();
      if (this.eS.stateImage.value != '') {
        formData.append('stateImage', this.eS.stateImage.value);
      }
      formData.append('countryId', this.countryId);
      formData.append('stateId', this.eS.stateId.value);
      formData.append('stateName', this.eS.stateName.value);
      formData.append('stateCode', this.eS.stateCode.value);
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/editState', formData).subscribe(
        (res) => {
          this.responseMessage = res.message;
          if (res.success) {
            this.editStateForm.reset();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
  }

}
