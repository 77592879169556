import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, merge } from 'rxjs';
import { User } from '../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { Access } from '../_models/access';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators ,UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { take } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-subdivision-to-layout',
  templateUrl: './subdivision-to-layout.component.html',
  styleUrls: ['./subdivision-to-layout.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SubdivisionToLayoutComponent implements OnInit {

  @ViewChildren('innerTables') innerTables: [];
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  getThroughSubject: Subscription;
  getGothrough : Access;
  users: User[] = [];
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  circleId:any = 0;
  divisionId:any = 0;
  subdivisionId:any = 0;
  isDataRedyForTableDcu: boolean = false;
  dcuOffset:number = 0;
  dcuLimit:number = 0;
  dcuTotalRecord:number = 0;
  meterTotalRecord:number = 0;
  
  limit:number = 10;
  meterlimit:number = 5;
  skip:number = 0;
  totalLength:number = 0;
  pageIndex : number = 0;
  pageLimit:number[] = [5, 10] ;
  meterpageLimit:number[] = [5, 10, 15] ;

  bCumCountry: any = '';
  bCumState: any = '';
  bCumUtlity: any = '';
  bCumCircle: any = '';
  bCumDivision: any = '';
  bCumSubDivision: any = '';
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  isMeterDataLoad = false;
  dataSource:any = new MatTableDataSource([]);
  dataMeterSource:any = new MatTableDataSource([]);
  dataAllMeterSource:any = new MatTableDataSource([]);

  displayedColumns: string[] = [
    'select',
    'dcuCode',
    //'dcuName',
    'dcuRTC',
    //'gisLocation',
    'dcuGatewayStrength',
    'ebdgStatusMode',
    'ebStatus',
    'dgStatus',
    //'dcuSIMNo',
    
    'dcuActivationDatetime',
    'dcuActiveStatus',
    'edit',
];
  meterColumnsToDisplay = [
    'mID', 
    'mlprID', 
    //'mSimNo', 
    //'mIMEINo',
    'mRTC',
    'mILA',
    'mActiveStatus',
    'edit'];
  dcuHeadsCols = [
    { label: "select",name:"select", checked: true },
    { label: "ID",name:"dcuID", checked: false },
    { label: "Code",name:"dcuCode", checked: true },
    { label: "Name",name:"dcuName", checked: true },
    { label: "DCU RTC",name:"dcuRTC", checked: true },
    { label: "Location",name:"gisLocation", checked: false },
    { label: "DCU Gateway Strength",name:"dcuGatewayStrength", checked: true },
    { label: "Status Mode",name:"ebdgStatusMode", checked: true },
    { label: "EB Status",name:'ebStatus', checked: true },
    { label: "DG Status",name:'dgStatus', checked: true },
    { label: "SIM No",name:"dcuSIMNo", checked: false },
   
    { label: "DCU Activation Time",name:"dcuActivationDatetime", checked: true },
    { label: "DCU Activate Status",name:"dcuActiveStatus", checked: true },
    /*{ label: "AID",name:"aID", checked: false },
    { label: "CID",name:"cID", checked: false },
    { label: "SID",name:"sID", checked: false },
    { label: "UID",name:"uID", checked: false },
    { label: "CIID",name:"ciID", checked: false },
    { label: "DID",name:"dID", checked: false },
    { label: "SDID",name:"sdID", checked: false },*/
    { label: "DCU Activation Status",name:"dcuActivationStatus", checked: false },
    { label: "edit",name:"edit", checked: true },
  ];
  meterHeadsCols = [
    { label: "Meter Id",name:"mID", checked: true },
    { label: "lpr ID",name:"mlprID", checked: true },
    { label: "Sim No",name:"mSimNo", checked: false },
    { label: "IMEI No",name:"mIMEINo", checked: false },
    { label: "RTC",name:"mRTC", checked: true },
    { label: "ILA",name:"mILA", checked: true },
    { label: "ActiveStatus",name:'mActiveStatus', checked: true },
    { label: "edit",name:"edit", checked: true },
  ];
  closeResult: string;
  addDcuForm: UntypedFormGroup;
  editDcuForm: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  editDcuData = {
    dcuActivationDatetime: '',
    dcuActivationStatus: '',
    dcuActiveDateTime: '',
    dcuActiveStatus: '',
    dcuCode: '',
    dcuGatewayStrength: '',
    dcuID: '',
    dcuInstallAddress: '',
    dcuName: '',
    dcuRTC: '',
    dcuSIMEmiNo: '',
    dcuSIMNo: '',
    dgStatus: '',
    ebStatus: '',
    ebdgStatusMode: '',
    gisLocation: '',
    dcufmcodver: '',
    dcuip: '',
    dcuportno: '',
    dcuserverip: '',
    dcuserverport: ''
  }
  editDcuMessage:string = '';
  addDcuMessage:string = '';
  dcuUpdateProcess:boolean = false;
  dcuAddProcess:boolean = false;
  clickedMeterRow:any;
  accesLevel = 0;
  selectedTabIndex = 1;
  tabToShow:number = 3;//no tab to show
  dcuSearch:string = '';
  dcuMeterSearch:string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private ngModalService: NgbModal,
  ) { 

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.getThroughSubject = this.authenticationService.getThrough.subscribe(nav => {
      this.getGothrough = nav
    });
    
    this.route.queryParams.subscribe(params => {
      this.countryId = this.genReqs.decryptData(params['country']);
      this.stateId = this.genReqs.decryptData(params['state']);
      this.utilityId = this.genReqs.decryptData(params['utility']);
      this.circleId = this.genReqs.decryptData(params['circle']);
      this.divisionId = this.genReqs.decryptData(params['division']);
      this.subdivisionId = this.genReqs.decryptData(params['subdivision']);
    });
    this.accesLevel = parseInt(this.currentUser.data.userData.authLevel) || 0;

  }
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('meterpaginator', {static: false}) meterpaginator: MatPaginator;
  @ViewChild('allmeterpaginator', {static: false}) allmeterpaginator: MatPaginator;
  ngOnInit() {
    this.authenticationService.getDeshboardRoute();
    this.getBreadCrums();
    this.loadDcu();
    this.allMeterView();
    this.addDcuForm = this.formBuilder.group({
      dcuCode: ['', Validators.required],
      dcuName: ['', Validators.required],
      dcuGISLoc: ['', Validators.required],
      dcuGprsSimNo: ['', Validators.required],
      dcuGrpsSimEMINo: ['', Validators.required],
      dcuInstalledLoc: ['', Validators.required],
      dcuActivationStatus: ['', Validators.required],
      dcuIpAddress: ['', Validators.required],
      dcuPortNo: ['', Validators.required],
      dcuServerIpAddress: ['', Validators.required],
      dcuServerPortNo: ['', Validators.required],
      dcuFirmwareCodeVersion: ['', Validators.required]
  });
  this.editDcuForm = this.formBuilder.group({
      dcuCode: ['', Validators.required],
      dcuName: ['', Validators.required],
      dcuGISLoc: ['', Validators.required],
      dcuGprsSimNo: ['', Validators.required],
      dcuGrpsSimEMINo: ['', Validators.required],
      dcuInstalledLoc: ['', Validators.required],
      dcuActivationStatus: ['', Validators.required],
      dcuIpAddress: ['', Validators.required],
      dcuPortNo: ['', Validators.required],
      dcuServerIpAddress: ['', Validators.required],
      dcuServerPortNo: ['', Validators.required],
      dcuFirmwareCodeVersion: ['', Validators.required]
  });
    //this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.whichTabToShow();
    this.dataSource.paginator = this.paginator;
    this.dataMeterSource.paginator = this.meterpaginator;
  }

  ngOnDestroy() {
    this.ngModalService.dismissAll();
    this.currentUserSubscription.unsubscribe();
  }

  whichTabToShow(){
    this.tabToShow = parseInt(this.currentUser.data.userData.tabDisplayScreen);
  }

  onDcuSearchChange(dcusearch){
      this.dcuSearch = dcusearch;
      this.skip = 0;
      this.loadDcu(false);
  }

  onDcuMeterSearchChange(dcuMeterSearch){
    this.dcuMeterSearch = dcuMeterSearch;
    this.skip = 0;
    this.meterView(this.clickedMeterRow , false);
  }

  loadDcu(addNew:boolean=false) {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('subDivisionId', this.subdivisionId.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.dcuSearch);
    this.genReqs.postReq('/getDCU', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.isDataRedyForTableDcu = true;
        //this.dcuTotalRecord = this.responseData.datalimit.totalResult;
        this.dataSource = this.responseData.data;
        this.dcuTotalRecord = this.responseData.datalimit.totalResult;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

    /**to get the meter list on click on Dcu */
    meterView(row, addNew:boolean=false) {
      this.clickedMeterRow = row;
      if (addNew === false) {
        this.dataMeterSource = [];
      }
      this.isMeterDataLoad = addNew;
      let body = new URLSearchParams();
      body.set('subdivId', this.subdivisionId.toString());
      body.set('dcuId', row.dcuID.toString());
      body.set('meterId', '0');
      body.set('authToken', this.currentUser.authToken);
      body.set('offSet', this.skip.toString());
      body.set('limit', this.meterlimit.toString());
      body.set('filter', this.dcuMeterSearch);
      this.genReqs.postReq('/getMeters', body).subscribe((result)=>{
        this.responseData = result;
        this.isMeterDataLoad = true;
        if(this.responseData.success){
            this.dataMeterSource = this.responseData.data;
            this.meterTotalRecord = this.responseData.datalimit.totalResult;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.messageUtilitys = 'Unable to process yours request!';
      });
    }
    allmeterTotalRecord:any = 0;
    isallMeterDataLoad:boolean = false;
    allMeterMessages:any = false;
     /**to get the meter list on click on Dcu */
     allMeterView(addNew:boolean=false) {
      if (addNew === false) {
        this.dataAllMeterSource = [];
      }
      this.isallMeterDataLoad = addNew;
      let body = new URLSearchParams();
      body.set('subdivId', this.subdivisionId.toString());
      body.set('dcuId', '0');
      body.set('meterId', '0');
      body.set('authToken', this.currentUser.authToken);
      body.set('offSet', this.skip.toString());
      body.set('limit', this.meterlimit.toString());
      this.genReqs.postReq('/getMeters', body).subscribe((result)=>{
        this.responseData = result;
        this.isMeterDataLoad = true;
        if(this.responseData.success){
          //this.allmeterTotalRecord = this.responseData.datalimit.totalResult;
          this.dataAllMeterSource = this.responseData.data;
          this.allmeterTotalRecord = this.responseData.datalimit.totalResult;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.allMeterMessages = 'Unable to process yours request!';
      });
    }

  //To change DCU table length
  changePage(event){
      if(event.pageSize !== this.limit){
          this.limit = event.pageSize;
          this.skip = event.pageSize * event.pageIndex;
          this.loadDcu(true);
       }else{
        if(this.dcuTotalRecord > this.dataSource.length){
              //if(this.pageIndex < event.pageIndex){
                // next page
                this.skip = event.pageSize * event.pageIndex;
                this.loadDcu(true);
              // }
          }
        }
  }

  //To change meter table length
  changeMeterPage(event){
    if(event.pageSize !== this.meterlimit){
        this.meterlimit = event.pageSize;
        this.skip = event.pageSize * event.pageIndex;
        this.meterView(this.clickedMeterRow, true);
      }else{
      if(this.meterTotalRecord > this.dataMeterSource.length){
            //if(this.pageIndex < event.pageIndex){
              // next page
              this.skip = event.pageSize * event.pageIndex;
              this.meterView(this.clickedMeterRow, true);
            // }
        }
      }
  }

  //To chnage DCU col
  onChange(event, index, item) {
    item.checked = !item.checked;
    this.displayedColumns = [];
    for ( let col of this.dcuHeadsCols ) {
      if (col.checked) {
        this.displayedColumns.push(col.name.toString());
      }
    }
  }

  //To chnage DCU col
  updateMeterChange(event, index, item) {
    item.checked = !item.checked;
    this.meterColumnsToDisplay = [];
    for ( let col of this.meterHeadsCols ) {
      if (col.checked) {
        this.meterColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  addDcuMeter(){
    this.modalService.subdivisionaddmeter(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  getBreadCrums() {
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', this.stateId);
    body.set('utilityId', this.utilityId);
    body.set('circleId', this.circleId);
    body.set('divisionId', this.divisionId);
    body.set('subdivisionId', this.subdivisionId);
    body.set('dcuId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNames', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.bCumCountry = this.responseData.data.country[0].countName;
        this.bCumState = this.responseData.data.state[0].stateName;
        this.bCumUtlity = this.responseData.data.utility[0].utilityName;
        this.bCumCircle = this.responseData.data.circle[0].circleName;
        this.bCumDivision = this.responseData.data.division[0].divisionName;
        this.bCumSubDivision = this.responseData.data.subDivision[0].subDivisionName;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  /*
      bredcrum permissions
    */

    isCountry():boolean
    {
      return this.accesLevel == 1;
    }

    isState():boolean
    {
      return this.accesLevel >= 1 && this.accesLevel <= 2;
    }

    isUtility():boolean
    {
      return this.accesLevel >= 1 && this.accesLevel <= 3;
    }

    isCircle():boolean
    {
      return this.accesLevel >= 1 && this.accesLevel <= 4;
    }

    isDivision():boolean
    {
      return this.accesLevel >= 1 && this.accesLevel <= 5;
    }

    isSubDivision():boolean
    {
      return this.accesLevel >= 1 && this.accesLevel <= 6;
    }

  backToSubDivisionView() {
    this.router.navigate(['/deshauthsubdivision'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
        'circle': this.genReqs.encryptData(this.circleId),
        'division': this.genReqs.encryptData(this.divisionId),
      }
    });
  }

  backToDivisionView() {
    this.router.navigate(['/deshauthdivision'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
        'circle': this.genReqs.encryptData(this.circleId)
      }
    });
  }

  backToCircleView() {
    this.router.navigate(['/deshauthcircle'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
      }
    });
  }

  backToUtilityView() {
    this.router.navigate(['/deshauthutility'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
      }
    });
  }

  backToStateView() {
    this.router.navigate(['/deshauthstates'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId)
      }
    });
  }

  backToCountryView() {
    this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId)}});
  }

  dcuAddMeter(dcuID){
    //console.log(dcuID);
  }

  /**to get the meter list on click on Dcu */
  dcuEditView(row, content) {
    //console.log(row);
    this.editDcuData = row;
    this.editDcuMessage = '';
    this.eD.dcuCode.setValue(this.editDcuData.dcuCode);
		this.eD.dcuName.setValue(this.editDcuData.dcuName)
		this.eD.dcuGISLoc.setValue(this.editDcuData.gisLocation)
		this.eD.dcuGprsSimNo.setValue(this.editDcuData.dcuSIMNo)
		this.eD.dcuGrpsSimEMINo.setValue(this.editDcuData.dcuSIMEmiNo)
		this.eD.dcuInstalledLoc.setValue(this.editDcuData.dcuInstallAddress)
		this.eD.dcuActivationStatus.setValue(this.editDcuData.dcuActivationStatus)
		this.eD.dcuIpAddress.setValue(this.editDcuData.dcuip)
		this.eD.dcuPortNo.setValue(this.editDcuData.dcuportno)
		this.eD.dcuServerIpAddress.setValue(this.editDcuData.dcuserverip)
		this.eD.dcuServerPortNo.setValue(this.editDcuData.dcuserverport)
		this.eD.dcuFirmwareCodeVersion.setValue(this.editDcuData.dcufmcodver)
    this.ngModalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
    }, (reason) => {
      //console.log('log closed');
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  dcuMeterView(element) {
    this.modalService.subdivisioneditmeter(element);
  }

  open(content) {
    this.addDcuMessage = '';
    this.ngModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  get aD() { return this.addDcuForm.controls; }
  onSubmitAddDcu(){
    if (this.addDcuForm.invalid) {
      return;
    }
    this.dcuAddProcess = true;
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', this.stateId);
    body.set('utilityId', this.utilityId);
    body.set('circleId', this.circleId);
    body.set('divisionId', this.divisionId);
    body.set('subDivisionId', this.subdivisionId);
    body.set('dcuCode', this.aD.dcuCode.value);
		body.set('dcuName', this.aD.dcuName.value);
		body.set('dcuGISLoc', this.aD.dcuGISLoc.value);
		body.set('dcuGprsSimNo', this.aD.dcuGprsSimNo.value);
		body.set('dcuGrpsSimEMINo', this.aD.dcuGrpsSimEMINo.value);
		body.set('dcuInstalledLoc', this.aD.dcuInstalledLoc.value);
		body.set('dcuActivationStatus', this.aD.dcuActivationStatus.value);
		body.set('dcuIpAddress', this.aD.dcuIpAddress.value);
		body.set('dcuPortNo', this.aD.dcuPortNo.value);
		body.set('dcuServerIpAddress', this.aD.dcuServerIpAddress.value);
		body.set('dcuServerPortNo', this.aD.dcuServerPortNo.value);
		body.set('dcuFirmwareCodeVersion', this.aD.dcuFirmwareCodeVersion.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/addDCU', body).subscribe((result)=>{
      this.responseData = result;
      this.dcuAddProcess = false;
      this.addDcuMessage = this.responseData.message;
      this.addDcuForm.reset();
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  get eD() { return this.editDcuForm.controls; }
  onSubmitEditDcu(){
    //console.log(this.eD);
    if (this.editDcuForm.invalid) {
      return;
    }
    this.dcuUpdateProcess = true;
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', this.stateId);
    body.set('utilityId', this.utilityId);
    body.set('circleId', this.circleId);
    body.set('divisionId', this.divisionId);
    body.set('subDivisionId', this.subdivisionId);
    body.set('dcuID', this.editDcuData.dcuID);
    body.set('dcuCode', this.eD.dcuCode.value);
		body.set('dcuName', this.eD.dcuName.value);
		body.set('dcuGISLoc', this.eD.dcuGISLoc.value);
		body.set('dcuGprsSimNo', this.eD.dcuGprsSimNo.value);
		body.set('dcuGrpsSimEMINo', this.eD.dcuGrpsSimEMINo.value);
		body.set('dcuInstalledLoc', this.eD.dcuInstalledLoc.value);
		body.set('dcuActivationStatus', this.eD.dcuActivationStatus.value);
		body.set('dcuIpAddress', this.eD.dcuIpAddress.value);
		body.set('dcuPortNo', this.eD.dcuPortNo.value);
		body.set('dcuServerIpAddress', this.eD.dcuServerIpAddress.value);
		body.set('dcuServerPortNo', this.eD.dcuServerPortNo.value);
		body.set('dcuFirmwareCodeVersion', this.eD.dcuFirmwareCodeVersion.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/editDCU', body).subscribe((result)=>{
      this.responseData = result;
      this.dcuUpdateProcess = false;
      this.editDcuMessage = this.responseData.message;
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  //tabing
  getTabIndex(tabName: string): number {
    switch (tabName) {
      case 'Dcu Meters List': return 0;
      case 'All Meters': return 1;
      default: return -1; // return -1 if clicked text is not a tab label text
     }
    }

    tabClick(clickEvent: any) {

        // Get the index of clicked tab using the above function
        const clickedTabIndex = this.getTabIndex(clickEvent.toElement.innerText);
        
        // if click was not on a tab label, do nothing
        if (clickedTabIndex === -1) {
        return;
        }
        // if form is not dirty, change the tab
        this.selectedTabIndex = clickedTabIndex;
    }

    //meter reoport popup
    /*dcuMeterReports(row) {
        this.modalService.onDcuListMeterReports(row).pipe(
        take(1) // take() manages unsubscription for us
        ).subscribe(result => {
            console.log({ messageResult: result });
        });
    }*/

  //meter configuration popup
  configureDCU(row) {
      this.modalService.configureOnDCU(row).pipe(
      take(1) // take() manages unsubscription for us
      ).subscribe(result => {
         // console.log({ messageResult: result });
      });
    }

  //meter configuration popup
  actionOnDCU(row) {
    this.modalService.actionOnDCU(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }


  //meter configuration popup
  reportingFromDCU(row) {
    this.modalService.reportingFromOnDCU(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  configureMeter(row) {
    this.modalService.configureOnMeter(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
    });
}

  //meter configuration popup
  actionOnMeter(row) {
    this.modalService.actionOnMeter(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  reportingFromMeter(row) {
    this.modalService.reportingFromOnMeter(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  meterInformation(row) {
    this.modalService.meterInformationReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  realTimeData(row) {
   // console.log('-->', row);
    this.modalService.realTimeDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  recentBilling(row) {
    this.modalService.recentBillingReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  recentEvents(row) {
    this.modalService.recentEventsReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  recentDipWiseLoadSurvey(row) {
    this.modalService.recentDipWiseLoadSurveyReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  recentLoadSurveyDayWise(row) {
    this.modalService.recentLoadSurveyDayWiseReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }


  //meter configuration popup
  meterConfigurationData(row) {
    this.modalService.meterConfigurationDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  lprConfigurationData(row) {
    this.modalService.lprConfigurationDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  meterActivityData(row) {
    this.modalService.meterActivityDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  revenueData(row) {
    this.modalService.revenueDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  unitConsumptionData(row) {
    this.modalService.unitConsumptionDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  lowBalanceMeterData(row) {
    this.modalService.lowBalanceMeterDataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     //   console.log({ messageResult: result });
    });
  }

  //meter configuration popup
  zeroBalanceMeterdata(row) {
    this.modalService.zeroBalanceMeterdataReport(row).pipe(
    take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     //   console.log({ messageResult: result });
    });
  }



  //meterfuntions from subdivion

  addMeter(){
    this.modalService.meteraddmeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  editMeter(){
    this.modalService.metereditmeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterRecharge(){
    this.modalService.meterRechargeSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterAdjustment(){
    this.modalService.meterAdjustmentSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterConfig(){
    this.modalService.meterConfigMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     //   console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterActivity(){
    this.modalService.meterActivityMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     //   console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterReporting(){
    this.modalService.meterReportingFromMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  realTimeDataSub(){
    this.modalService.realTimeDataMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentBill(){
    this.modalService.recentBillMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentEvent(){
    this.modalService.recentEventMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentDipWiseLoadSurveySub(){
    this.modalService.recentDipWiseLoadSurveyMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentDayLoadSurvey(){
    this.modalService.recentDayLoadSurveyMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentSmsSent(){
    this.modalService.recentSmsSentMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
     //   console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  teriff(){
    this.modalService.teriffMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterConfiguration(){
    this.modalService.meterConfigurationMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //  console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  lprConfiguration(){
    this.modalService.lprConfigurationMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentActivity(){
    this.modalService.recentActivityMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentRecharge(){
    this.modalService.recentRechargeMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentConsumption(){
    this.modalService.recentConsumptionMeterSubDiv(this.clickedMeterRow).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       // console.log({ messageResult: result });
        //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
