<!----add authority start-->
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">

  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Modify Permissions:
      {{authorityData.authFullName}}
    </h4>
  </div>

  <div class="modal-body">
    <div *ngIf="loading">
      <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
    </div>
    <div class="form-groupp" *ngIf="!loading">
      <nav>
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav_authority_view" data-toggle="tab" href="#nav_authority" role="tab"
            aria-controls="nav-authority" aria-selected="true">Authority's
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Authority"></i>
          </a>
          <a class="nav-item nav-link" id="nav_contractor_view" data-toggle="tab" href="#nav_contractor" role="tab"
          aria-controls="nav-contractor" aria-selected="true"> Contractor's
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Contractor"></i>
          </a>
          <a class="nav-item nav-link" id="nav_consumer_view" data-toggle="tab" href="#nav_consumer" role="tab"
            aria-controls="nav-consumer" aria-selected="true"> Consumer's
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Consumer"></i>
          </a>
          <a class="nav-item nav-link" id="nav_setting_view" data-toggle="tab" href="#nav_setting" role="tab"
            aria-controls="nav-setting" aria-selected="true"> Setting's
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="setting"></i>
          </a>
          <a class="nav-item nav-link" id="nav_meterreport_view" data-toggle="tab" href="#nav_meterreport" role="tab"
            aria-controls="nav-meterreport" aria-selected="true"> Meter & Report
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Meter Report"></i>
          </a>
        </div>
      </nav>
      <div class="tab-content meterload" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav_authority" role="tabpanel" aria-labelledby="nav_authority_view">
          <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c9">
                  phone_android
                </mat-icon>
                Active All Permissions
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="with-icon col-sm-4 panel-body">
              <mat-icon class="d-c9">phone_android</mat-icon>
              <mat-slide-toggle formControlName="Active_All_Permission_Access">
                Active All Permissions
              </mat-slide-toggle>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c6">
                  insert_chart
                </mat-icon> Authority Graphical Dashboard
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Grid_Society_View">
                  Society View
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Grid_Utility_View">
                  Utility View
                </mat-slide-toggle>
              </div>
              
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kWH">
                  Grid kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_dg_kWH">
                  DG kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_recharges">
                  Recharges
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kWH_Utility">
                  Grid kWH Utility
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility">
                  kWH Monthly Consump.
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kWH_Daily_Cons_Utility">
                  kWH Daily Consump.
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c9">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kVARH_Utility">
                  Grid kVARH Utility
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kVAH_Utility">
                  Grid kVAH Utility
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility">
                  kVAH Monthly Consum.
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c12">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility">
                  kVAH Daily Consum.
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_User_Consumption_Chart">
                  Consumption Chart
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_User_Consumption_Daily">
                  Consumption Daily
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_User_Consumption_Monthly">
                  Consumption Monthly
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c12">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_User_Consumption_Yearly">
                  Consumption Yearly
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Chart">
                  Meter Statistics
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c13">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters">
                  Total Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c14">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                  Non-Communicative
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c15">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                  Active Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                  Inactive Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                  Power Off
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                  Tampered Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                  Connected Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                  Disconnected Meters
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">insert_chart</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Griphical_User_Consumption_kVAH_DG">
                    DG kVAH Consumption
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">insert_chart</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Statistics_Chart">
                    Meter Statistics Chart
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">insert_chart</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Balance_Chart">
                    Meter Balance Chart
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">insert_chart</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Griphical_Desh_Meter_Tamper_Chart">
                    Meter Tamper Chart
                  </mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Power_Cut_Chat">
                  Power Cut Chart
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Power_Cut_Daily">
                  Power Cut Daily
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c9">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Power_Cut_Monthly">
                  Power Cut Monthly
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Power_Cut_Yearly">
                  Power Cut Yearly
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Deshboard">
                  Graphical Deshboard
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Deshboard">
                  Grid Deshboard
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">insert_chart</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Griphical_Society_Deshboard">
                  All Society Deshboard
                </mat-slide-toggle>
              </div>

            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  view_list
                </mat-icon> Authority Grid Dashboard
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c5">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_DG_Filter">
                  All DG
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Tamper_Filter">
                  All Tower
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Group_Filter">
                  Alll Group
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_Filter">
                  All Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Search_Meter_Id_Filter">
                  Search Meter Id
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_UId_Column">
                  Cons UId
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_DG_Column">
                  DG ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_Tower_Column">
                  Tower ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_Group_Column">
                  Group ID
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_UId_Column">
                  Meter UId
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Flat_Id_Column">
                  Flat Id
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_No_Column">
                  Meter No
                </mat-slide-toggle>
              </div>


              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_Ip_Column">
                  Meter Ip
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Hes_Id_Column">
                  HES ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c15">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_Type_Column">
                  Meter Type
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c2">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_LL_Grid_kW_Column">
                  LL Grid (kW)
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c3">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_LL_DG_kW_Column">
                  LL DG (kW)
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c4">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_Update_Column">
                  Last Update
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c5">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_R_Column">
                  Voltage-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_Y_Column">
                  Voltage-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_B_Column">
                  Voltage-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_R_Column">
                  PH Current-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_Y_Column">
                  PH Current-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_B_Column">
                  PH Current-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_R_Column">
                  NU Current
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-3">
                  <mat-icon class="d-c12">view_list</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_Y_Column">
                    NU Current-Y
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-3">
                  <mat-icon class="d-c13">view_list</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_B_Column">
                    NU Current-B
                  </mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_R_Column">
                  PF-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c15">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_Y_Column">
                  PF-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c2">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_B_Column">
                  PF-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c3">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Total_Power_Factor_Column">
                  Total PF
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c4">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Frequency_Column">
                  Frequency
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c5">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Active_Power_Column">
                  Kw Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Reactive_Power_Column">
                  kVAR Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_ApperantPower_kVA_Column">
                  kVA Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Md_Kw_Column">
                  MD kW
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Md_kVA_Column">
                  MD kVA
                </mat-slide-toggle>
              </div>



              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kWH_Column">
                  Grid kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q1_Column">
                  Grid kVARH Q1
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q2_Column">
                  Grid kVARH Q2
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q3_Column">
                  Grid kVARH Q3
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q4_Column">
                  Grid kVARH Q4
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVAH_Column">
                  Grid kVAH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_DG_kWH_Column">
                  DG kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_DG_kVAH_Column">
                  DG kVAH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Balance_Column">
                  Balance
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Supply_Column">
                  Supply
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Tamper_Column">
                  Tamper
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_LS_DT_Column">
                  Last LS D/T
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_Block_LS_DT_Column">
                  Last Block LS D/T
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Tamper_Count_Column">
                  Tamper Count
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Billng_Count_Column">
                  Billing Count
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Relay_Status_Column">
                  Relay Status
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_DG_Supply_Status_Column">
                  DG Supply Status
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Live_Status_Column">
                  Live Status
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Antenna_Column">
                  Antenna Position
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Sim_Type_Column">
                  Sim Provider
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Action_Column">
                  Action
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  storage
                </mat-icon> Installed Meter Dashboard
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">storage</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Installed_Status">
                  Progress Till Date
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">storage</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Installed_Status_New">
                  Progress Till Date New
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">storage</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Installed_Circle">
                  Show Circle
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">view_list</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Installed_Authority">
                  Show Authority
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">view_list</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Single_Ph_Show">
                  Show 1-PH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">view_list</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_Three_Ph_Show">
                  Show 3-PH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">view_list</mat-icon> 
                <mat-slide-toggle formControlName="Auth_All_Meter_LTCT_Ph_Show">
                  Show LTCT
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  view_list
                </mat-icon> Authority Grid Dashboard View
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_UId_Column_View">
                  Cons UId
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_DG_Column_View">
                  DG ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_Tower_Column_View">
                  Tower ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Cons_Group_Column_View">
                  Group ID
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_UId_Column_View">
                  Meter UId
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Flat_Id_Column_View">
                  Flat Id
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_No_Column_View">
                  Meter No
                </mat-slide-toggle>
              </div>


              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_Ip_Column_View">
                  Meter Ip
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Hes_Id_Column_View">
                  HES ID
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c15">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Meter_Type_Column_View">
                  Meter Type
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c2">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_LL_Grid_kW_Column_View">
                  LL Grid (kW)
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c3">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_LL_DG_kW_Column_View">
                  LL DG (kW)
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c4">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_Update_Column_View">
                  Last Update
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c5">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_R_Column_View">
                  Voltage-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_Y_Column_View">
                  Voltage-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Voltage_B_Column_View">
                  Voltage-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_R_Column_View">
                  PH Current-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_Y_Column_View">
                  PH Current-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Phase_Current_B_Column_View">
                  PH Current-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_R_Column_View">
                  NU Current
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-3">
                  <mat-icon class="d-c12">view_list</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_Y_Column">
                    NU Current-Y
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-3">
                  <mat-icon class="d-c13">view_list</mat-icon> 
                  <mat-slide-toggle formControlName="Auth_All_Grid_Nutural_Current_B_Column">
                    NU Current-B
                  </mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_R_Column_View">
                  PF-R
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c15">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_Y_Column_View">
                  PF-Y
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c2">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_PF_B_Column_View">
                  PF-B
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c3">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Total_Power_Factor_Column_View">
                  Total PF
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c4">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Frequency_Column_View">
                  Frequency
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c5">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Active_Power_Column_View">
                  Kw Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Reactive_Power_Column_View">
                  kVAR Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_ApperantPower_kVA_Column_View">
                  kVA Power
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Md_Kw_Column_View">
                  MD kW
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_Md_kVA_Column_View">
                  MD kVA
                </mat-slide-toggle>
              </div>



              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kWH_Column_View">
                  Grid kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q1_Column_View">
                  Grid kVARH Q1
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q2_Column_View">
                  Grid kVARH Q2
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q3_Column_View">
                  Grid kVARH Q3
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVARH_Q4_Column_View">
                  Grid kVARH Q4
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Grid_kVAH_Column_View">
                  Grid kVAH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_DG_kWH_Column_View">
                  DG kWH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c6">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_DG_kVAH_Column_View">
                  DG kVAH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c7">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Balance_Column_View">
                  Balance
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c8">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Supply_Column_View">
                  Supply
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c9">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Tamper_Column_View">
                  Tamper
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c10">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_LS_DT_Column_View">
                  Last LS D/T
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-3">
                <mat-icon class="d-c11">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Last_Block_LS_DT_Column_View">
                  Last Block LS D/T
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Tamper_Count_Column_View">
                  Tamper Count
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Billng_Count_Column_View">
                  Billing Count
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c12">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Relay_Status_Column_View">
                  Relay Status
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c13">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Live_Status_Column_View">
                  Live Status
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Antenna_Column_View">
                  Antenna Position
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Sim_Type_Column_View">
                  Sim Provider
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-3">
                <mat-icon class="d-c14">view_list</mat-icon>
                <mat-slide-toggle formControlName="Auth_All_Action_Column_View">
                  Action
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c5 iconwhiteau ">
                  supervised_user_circle
                </mat-icon> Authority's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Menu">
                  Authority's
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Authority">
                  Authority
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Authority_Button">
                  Add Authority
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Authority_Button">
                  Modify Authority
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button">
                  Modify Auth Perm
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button">
                  Modify Report Perm
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Authority_Button">
                  Delete Auth
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <!--<mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c6 iconwhiteau ">
                  supervised_user_circle
                </mat-icon> Contractors's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Menu">
                  Contractors's
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Contractor">
                  Contractor
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c9">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Contractor_Button">
                  Add Contractor
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Contractor_Button">
                  Modify Contractor
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">supervised_user_circle</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Contractor_Button">
                  Delete Contractor
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  assessment
                </mat-icon>
                Level's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  assessment
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_New_Level">Level's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  assessment
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Level_Button">Add Level</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  assessment
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Level_Button">Modify Level</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  assessment
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Level_Button">Delete Level
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  developer_mode
                </mat-icon>
                Meter Firmware Version
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c8">
                  developer_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Meter_Firmware">MF Version</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c9">
                  developer_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button">Add MF Version
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c10">
                  developer_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button">Modify MF Version
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c11">
                  developer_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button">Delete MF Version
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c6">
                  low_priority
                </mat-icon>
                Meter Range
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  low_priority
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Meter_Range">Meter Range</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  low_priority
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Meter_Range_Button">Add Meter Range
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  low_priority
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Meter_Range_Button">Modify Meter Range
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Meter_Range_Button">Delete Meter Range
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                DG's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_DG">DG's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_DG_Button">Add DG</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_DG_Button">Modify DG</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  backup
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_DG_Button">Delete DG</mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c4">
                  storage
                </mat-icon>
                Tower's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  storage
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Tower">Tower's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  storage
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Tower_Button">Add Tower</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  storage
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Tower_Button">Modify Tower</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  storage
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Tower_Button">Delete Tower
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c5">
                  group_work
                </mat-icon>
                Group's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  group_work
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Group">Group's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  group_work
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Group_Button">Add Group</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  group_work
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Group_Button">Modify Group</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  group_work
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Group_Button">Delete Group
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c4">
                  chrome_reader_mode
                </mat-icon>
                Tariff's
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Teriff">Tariff's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Teriff_Button">Add Tariff</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Modify_Teriff_Button">Modify Tariff
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Teriff_Button">Delete Tariff
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c5 iconwhiteau">
                  notification_important
                </mat-icon>
                Consumer's Notification
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  notification_important
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_Consumer_Notification">Consumer's Notification
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  notification_important
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Notification_Button">Add Notification
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  notification_important
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Notification_Button">Modify Notification
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  notification_important
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Notification_Button">
                  Delete Notification
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c6">
                  dns
                </mat-icon>
                Meter's Command
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  dns
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_New_Meter_Command">Meter's Command
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  dns
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Meter_Command_Button">Add Command
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  dns
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Meter_Command_Button">Modify Command
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  dns
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Meter_Command_Button">Delete Command
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  schedule
                </mat-icon>
                Schedular
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  schedule
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_New_Scheduler">Schedular</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  schedule
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_Scheduler_Button">Add Schedular
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  schedule
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Scheduler_Button">Modify Schedular
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  schedule
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Scheduler_Button">Delete Schedular
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c5">
                  restore
                </mat-icon>
                Consumer Refund
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  restore
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Refund">Consumer Refund</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  restore
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button">Refund Button
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  pending_actions
                </mat-icon>
                Pending Demand
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  pending_actions
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Pending_Demands">Pending Demand</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  pending_actions
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button">Delete Demand
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  pending_actions
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button">Delete All Demand
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                Old New Meter Desh
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_New_Meter">New Meter Desh</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Old_New_Meter">Old/New Meter Desh</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Old_Meter_Modify_Button">Old/New Meter Modify
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Old_Meter_Image_Button">Old Meter Image
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_New_Meter_Image_Button">New Meter Image
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                Non Communicated Meters
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Communicated">Non-Communicated Meters Desh</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  add_photo_alternate
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Com_Circle">Circle Selection</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Com_Catergory">Category Selection
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c4">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Com_Authority">Installer
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Com_Date">Date Selection
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  add_a_photo
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Non_Com_Sim_Type">Sim Type
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <!--<mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="material-icons d-c7">
                    dns
                  </mat-icon>
                  DCU's Command
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="material-icons d-c7">
                    dns
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Add_New_DCU_Command">DCU's Command</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="material-icons d-c7">
                    dns
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Add_DCU_Command_Button">Add Command</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="material-icons d-c7">
                    dns
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Edit_DCU_Command_Button">Modify Command</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="material-icons d-c7">
                    dns
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Delete_DCU_Command_Button">Delete Command</mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>-->






          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                Reports
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">report</mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Reports">Reports</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="material-icons d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Authority_Info_Report">Auth's Info
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="material-icons d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report">Auth Wise Info
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report">Auth's
                  Login/Logout</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report">Auth Wise
                  Login/Logout</mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Country_Info_Report">Country's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_State_Info_Report">State's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Utility_Info_Report">Utility's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Circle_Info_Report">Circle's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Division_Info_Report">Division's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_All_Sub_Division_Info_Report">Sub-Division's Info</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Auth_Sub_Menu_Sub_Electricity_Tariff_History">Elect.Tariff History</mat-slide-toggle>
                </div>-->
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="tab-pane fade" id="nav_contractor" role="tabpanel" aria-labelledby="nav_contractor_view">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                Contractor's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_Menu">Contractor's</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_add">Add Contractror Button
                </mat-slide-toggle>
              </div>
              
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_modify">Contractor Modify
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_add_superviser">Add Superviser
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_view_superviser">View Superviser Progress
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_delete">Delete Contractor
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                Superviser's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_superviser">Superviser
                </mat-slide-toggle>
              </div>
             
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_modify_superviser">Modify Superviser
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_delete_superviser">Delete Superviser
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                Issued Meters
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_issued_meters_range_menu">Issued Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_issued_meters_range">Add Issued Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_modify_meters_range">Modify Issued Meters
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_delete_meters_range">Delete Issued Meters
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                Contractor's Progress
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_progress">Contractor Progress
                </mat-slide-toggle>
              </div>
             
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_modify_payment">Modify Payment
                </mat-slide-toggle>
              </div>
              
            </div>
          </mat-expansion-panel>

          
          <!--<mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                Smart Meter Order
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_add">Add Smart Meter order
                </mat-slide-toggle>
              </div>
             
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_view_serial_no">View Serial No
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_add_serial_no">Add Serial No
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_edit_by_production">Edit By Production
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_edit_by_account">Edit By Accounts
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_edit_by_marketing">Edit By Marketing
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="smart_meter_order_delete_order">Delete Order
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                Reports
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Contractor_Sub_Reports">Reports</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_info_Report">Contractor's Info Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c4">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_superviser_info_Report">Superviser Info Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_faulty_meters_Report">Faulty Meters Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_installed_meters_report">Installed Meters Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_payment_pending_report">Payment Pending Meters Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="contractor_payment_cleared_report">Payment Cleared Report
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          </mat-accordion>
      </div>
      <div class="tab-pane fade" id="nav_consumer" role="tabpanel" aria-labelledby="nav_consumer_view">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                Consumer's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Menu">Consumer's</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Add_Consumer">Add Consumer Menu</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Add_Consumer_Button">Add Consumer Button
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Consumer_Recharge_Button">Recharge</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Consumer_Adjustment_Button">Adjustment
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Edit_Consumer_Info_Button">Modify Info
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Edit_Consumer_Permission_Button">Modify Permission
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  supervisor_account
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Delete_Consumer_Button">Delete Consumer
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c9">
                  perm_data_setting
                </mat-icon>
                Set Consumers Permissions
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c9">
                  perm_data_setting
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Add_Consumer_Permissions">Set Consumers Permissions
                </mat-slide-toggle>
              </div>

            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                Consumer Complaint
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Add_Consumer_Complaint">Consumer Complaint
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="Consumer_Sub_Add_Consumer_Complaint_Button">Add Complaint</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Edit_Consumer_Complaint_Button">Modify Complaint
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  horizontal_split
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Delete_Consumer_Complaint_Button">Delete Complaint
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                Reports
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_Reports">Reports</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_All_Cons_Info_Report">Cons Information
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c4">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_All_Cons_Login_Logout_Report">Cons Login/Logout
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_All_Cons_Complaint_Report">Cons Complaint
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Consumer_Sub_All_Cons_Notifications_Report">Cons Notification
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          </mat-accordion>
      </div>
      <div class="tab-pane fade" id="nav_setting" role="tabpanel" aria-labelledby="nav_setting_view">
          <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c6">
                  build
                </mat-icon>
                Society Configuration Setting's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c6">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Menu">Setting's</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Meter">Configure All Meters</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Load_Limit_On_Meter">Configure load Limit On
                  1-PH Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Load_Limit_On_Meter_3_PH">Configure load Limit On
                  3-PH Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Happy_Hours_On_Meter">Configure Happy hours On
                  Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter">Set Meter
                  Alarm/Emergeny/Billing Tariff</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Bill_date">Billing Schedular
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Relay_Control">Relay Control
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_ESW_1_PH">ESW Filter 1-PH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_ESW_3_PH">ESW Filter 3-PH
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Periodic_Push">Periodic Push
                </mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                Utility Configuration Setting's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Meter"><b>Utility Config Setting's</b>
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Config"><b>Utility General
                    Config</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config">
                  Load Profile Capture</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config">Load
                  Profile Captured Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config">Load
                  Profile Captured Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Demand_Integration_Period_Config">Demand
                  Integration</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config">Demand
                  Integration Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config">Demand
                  Integration Write</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config">Load Limit
                  Thrashold</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config">Load
                  Limit Thrashold Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config">Load
                  Limit Thrashold Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Billing_Date_Config">Billing Date
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Billing_Date_Read_Config">Billing Date
                  Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Billing_Date_Write_Config">Billing Date
                  Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Push_Schedule_Config">Push Schedule
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Push_Schedule_Read_Config">Push Schedule
                  Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Push_Schedule_Write_Config">Push
                  Schedule Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Relay_Config"><b>Relay Config</b>
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Relay_Read_Config">Relay Config Read
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Relay_Write_Config">Relay Config Update
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config">
                  <b>Gernal Prepaid/Net/Conn/MD</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Prepaid_Config">Prepaid Config
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config">Prepaid
                  Config Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config">Prepaid
                  Config Update</mat-slide-toggle>
              </div>


              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config">Net Meter
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config">Net Meter
                  Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config">Net Meter
                  Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config">
                  Connect/Disconnect Control</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config">
                  Connect/Disconnect Control Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config">
                  Connect/Disconnect Control Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config">
                  Connect/Disconnect</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config">
                  Connect/Disconnect Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config">
                  Connect/Disconnect Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config">MD Reset
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config">MD Reset
                  Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config">MD Reset
                  Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Time_Config"><b>Meter Time
                    Configuration</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Time_Read_Config">Meter Time Read
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Time_Write_Config">Meter Time Update
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Password_Config"><b>Password Config</b>
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config">Low
                  Level Pass Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Password_High_Level_Write_Config">High
                  Level Pass Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Enc_Auth_Key_Config">Ench/Auth key
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config">Ench/Auth key
                  Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ThrashHold_Limit_Config"><b>Thrashold
                    Limit</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config">Thrashold
                  Limit Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config">Thrashold
                  Limit Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limiter_Config"><b>Load Limiter</b>
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limiter_Read_Config">Load Limiter
                  Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_Load_Limiter_Write_Config">Load Limiter
                  Update</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_Config"><b>TOD Zone Config</b>
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_1_Config">TOD Zone 1
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_2_Config">TOD Zone 2
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_3_Config">TOD Zone 3
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_4_Config">TOD Zone 4
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_5_Config">TOD Zone 5
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_6_Config">TOD Zone 6
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_7_Config">TOD Zone 7
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_8_Config">TOD Zone 8
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_Read_Config">TOD Zone Read
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_TOD_Zone_Write_Config">TOD Zone Update
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Phase_Config"><b>ESW Three
                    Phase</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config">R-Phase
                  Volt Missing</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config">Y-Phase
                  Volt Missing</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config">B-Phase
                  Volt Missing</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config">Over Voltage
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config">Low Voltage
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config">Voltage
                  Unbalance</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config">
                  Current Reverse-R</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config">
                  Current Reverse-Y</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config">
                  Current Reverse-B</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config">
                  Current Unbalance</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config">Current
                  Bypass</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config">Over
                  Current</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config">Low PF
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Influb_Config">Magnet
                  Influence</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config">Nutural
                  Distrubance</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config">Cover
                  Open</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config">
                  Connect/Disconnect</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config">Last
                  Gashp</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config">
                  First Breath</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config">
                  Incremented Bill Count</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config">ESW
                  Three Phase Read</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config">ESW
                  Three Phase Update</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-12">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config"><b>ESW
                    Single Phase Config</b></mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config">Earth
                  Loading</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config">Magnet
                  Influence</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config">
                  Nutural Distrubance</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config">
                  Cover Open</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config">
                  Connect/Disconnect</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config">Last
                  Gashp</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config">
                  First Breath</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config">
                  Increment Bill Count</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config">
                  Current Reverse</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config">Over
                  Voltage</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config">Low Voltage
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config">Over
                  Current</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Read_Config">ESW Single Read
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  build
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Configure_Utility_ESW_Single_Write_Config">ESW Single
                  Update</mat-slide-toggle>
              </div>

            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                Activity On All Meter
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_On_Meter">
                  Activity On All Meter
                </mat-slide-toggle>
              </div>

              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    chrome_reader_mode
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_Servant_Mode_On_Off">
                    Servant Mode On/Off
                  </mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_Meter_Load_On_Off">
                  Meter load On/Off
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_Backup_Supply_On_Off">
                  DG Supply On/Off
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_DG_Free_Units_On_Off">
                  DG Free Units Active/In-Active
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_Emergency_On">
                  Emergency Credit
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  chrome_reader_mode
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post">
                  Meter Mode (Pre/Post)
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    chrome_reader_mode
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Sub_Activity_Restart_Meter">
                    Restart Meter
                  </mat-slide-toggle>
                </div>-->
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c2">
                  eco
                </mat-icon>
                Reporting From Meter
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_From_Meter">
                  Reporting From Meter
                </mat-slide-toggle>
              </div>

              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    eco
                  </mat-icon>
                  <mat-form-field floatLabel="always">
                    <mat-label></mat-label>
                    <mat-slide-toggle>Down Inst Data</mat-slide-toggle>
                    <input matInput #value hidden />
                  </mat-form-field>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    eco
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Last_Night_Midnight_Data">Get All Load Survey Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    eco
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Latest_Billing_Data">Get Full Billing Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    eco
                  </mat-icon>
                  <mat-form-field floatLabel="always">
                    <mat-label></mat-label>
                    <mat-slide-toggle>Down Last Billing</mat-slide-toggle>
                    <input matInput #value hidden />
                  </mat-form-field>
                </div>-->
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Instant_Data">Get Instantaneous Data
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Full_Billing_Data">Get Full Billing Data
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    eco
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Latest_Tamper_Data">Down Latest Tamp</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data">Get FULL Tamper Data
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data">Get Block Load
                  Survey Data</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data">Get All Load
                  Survey Data</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data">Get Load Limit
                  Config Data</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class=" d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data">Get Happy
                  Hours Config Data</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status">Get Billing
                  Tariff Config Data</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  eco
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status">Get
                  Billing Date Config Data</mat-slide-toggle>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c4">
                  cloud_off
                </mat-icon>
                Disabled Meters
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  cloud_off
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Disabled_Meters">
                  Disabled Meters
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  edit
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Disabled_Meters_Modify">
                  Modify
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  delete_forever
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Sub_Disabled_Meters_Delete">
                  Delete
                </mat-slide-toggle>
              </div>
            </div>

          </mat-expansion-panel>
          </mat-accordion>
      </div>
      <div class="tab-pane fade" id="nav_meterreport" role="tabpanel" aria-labelledby="nav_meterreport_view">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c8">
                  stay_primary_portrait
                </mat-icon>
                Meter's
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">

              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    stay_primary_portrait
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meter_Sub_Add_Meter">Add Meter</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c9">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Modify_Meter">Modify</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c10">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Recharge_Button">Recharge</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c11">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Adjustment_Button">Adjustment</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c12">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Configure_Meter_Button">Society's Configure Meter
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c13">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Meter_Button">Activity On Meter
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-6">
                <mat-icon class="d-c14">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Meter_Load_On_Off_Button">Meter Load On/Off
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c15">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_DG_Supply_On_Off_Button">DG Supply On/Off
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c1">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Emergency_Credit_On_Button">Emergency Credit On
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Meter_MD_Reset_Button">MD Reset
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button">Meter Mode
                  (Pre/Post)</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c4">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Activity_On_Meter_DG_Free_Units_Button">DG Free Unit
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Option_Button">Meter Download Option
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c14">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Instant_Button">Instantaneous Profile
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c15">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Billing_Button">All Billing Profile Data
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c1">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Billing_Button">Selective Billing
                  Profile Data Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Block_Load_Survey_Button">Block Load Survey
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Daily_Load_Survey_Button">Daily Load Survey
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c4">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Tamper_Button">All Events Data Download
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Voltage_Event_Button">All Voltage Events
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c6">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Voltage_Event_Button">Selective
                  Voltage Events</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Current_Event_Button">All Current Events
                  Data Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c8">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Current_Event_Button">Selective
                  Current Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c9">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Power_Event_Button">All Power Events
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c10">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Power_Event_Button">Selective
                  Power Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c11">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Transaction_Event_Button">All
                  Transactional Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c12">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Transact_Event_Button">Selective
                  Transactional Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c13">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Other_Event_Button">All Other Events
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c14">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Other_Event_Button">Selective
                  Other Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c15">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Cover_Open_Event_Button">Cover Open
                  Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c1">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_All_Control_Event_Button">All Control Events
                  Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Selective_Control_Event_Button">Selective
                  Control Events Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Load_Limit_Config_Button">Load Limit
                  Configuration Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c4">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Happy_Hours_Config_Button">Happy Hours
                  Configuration Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Alarm_Level_Config_Button">Alarm Level
                  Configuration Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c6">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Download_Billing_Date_Config_Button">Billing Date
                  Configuration Download</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c15">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Real_Time_Data_Button">Meter Real Time Data
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Recharge_History_Button">Recent Recharge
                  History</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Adjustment_History_Button">Recent Adjustment
                  History</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c4">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Bill_Button">Recent Bill</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Bills_Button">Recent Bills</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c6">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Events_Button">Recent Events
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c7">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_OnGoing_Consumption_Button">On-Going Consumption
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c8">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Daily_Consumptiion_Button">Daily Consumption
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c9">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Activity_Button">Recent Activity
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c10">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_SMS_Sent_Button">Recent SMS Sent
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-6">
                <mat-icon class="d-c11">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Billing_Teriff_Status_Button">Recent Meter Teriff
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c12">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Load_Limit_Config_Status_Button">Recent Meter
                  Loadlimits</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c13">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Happy_Hours_Config_Status_Button">Recent Happy Hours
                  Config</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c14">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Billing_Scheduler_Status_Button">Recent Billing
                  Scheduler Config</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c15">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Billing_Teriff_From_Meter_Button">Billing Teriff From
                  Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c2">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button">Load limits
                  Config From Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c3">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button">Happy Hours
                  Config From Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c4">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button">Billing
                  Scheduler From Meter</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-6">
                <mat-icon class="d-c5">
                  stay_primary_portrait
                </mat-icon>
                <mat-slide-toggle formControlName="Meter_Sub_Meter_Recent_Complaints_Button">Recent Complaints
                </mat-slide-toggle>
              </div>
              
              <!--<div class="with-icon col-sm-6">
                  <mat-icon class="d-c6">
                    stay_primary_portrait
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meter_Sub_Meter_Delete_Meter_Button">Delete Meter</mat-slide-toggle>
                </div>-->
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                Reports
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="form-row panel-body">
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports">Reports</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Old_New_Meters_Information_Report">Old/New Meter Information</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Day_Wise_Meters_Information_Report">Daywise Meter Information</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Day_Wise_Non_Comunicated_Report">Daywise Non-Communicated</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Admin_Non_Comm_Count">Non-Communicated Count</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Replaced_Meters_Information_Report">Replaced Meters</mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Replaced_Meters_History_Report">Replaced Meter History</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Comulative_Fault_Report">Comulative Fault Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Removed_Meters_Report">Released Meters Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Stock_Analysis_Report">Stock Analysis Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c2">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="User_Work_Info_Report">User Work Info Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Meters_Information_Report">Meter's Information
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Meters_Teriff">Tariff Data</mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Activity_Log_Report">Meter-Wise Activity Log</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Activity_Log_Report">All Authorities Activity Log
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Authority_Wise_Activity_Log_Report">Auth Wise Activity
                  Log</mat-slide-toggle>
              </div>
              <!-- <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Current_Data_Report">Meter-Wise Current Data</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Current_Data_Report">Instantaneous Report
                </mat-slide-toggle>
              </div>
              <!-- <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Midnight_Data_Report">Meter-Wise Midnight Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Get_Midnight_Data_Report">Midnight Data</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_OnGoing_Consumption_Report">On-Going Consumption
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c7">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Unit_Consumption_Report">Daily Consumption
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c8">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Unit_Energy_Report">Daily Energy
                </mat-slide-toggle>
              </div>

              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c9">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Monthly_Unit_Consumption_Report">Monthly Consumption
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Billing_Data_Report">Consumer Bills
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Latest_Billing_Data_Report">Latest Billing Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Tamper_Data_Report">Meter-Wise Tamper Data</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Latest_Tamper_Data_Report">Events Report
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Load_Survery_Report">Meter-Wise Load Survery</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Current_Load_Survery_Report">Daily Consumption Report</mat-slide-toggle>
                </div>-->
              <!-- <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Config_Log_Report">Meter-Wise Config Log</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Config_Log_Report">Meter Config Log</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Router_Config_Log_Report">Meter Router Config Log</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Wise_Hardware_Status_Report">Meter-Wise Hardware Status</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meter_Hardware_Status_Report">Meter Hardware Status</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Daily_Unit_Consumption_Report">Meter-Wise Daily Consumption</mat-slide-toggle>
                </div>-->

              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Monthly_Unit_Consumption_Report">Meter-Wise Monthly Consumption</mat-slide-toggle>
                </div>-->


              <!-- <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Yearly_Unit_Consumption_Report">Meter-Wise Yearly Consumption</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Yearly_Unit_Consumption_Report">Yearly Unit Consumption</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Daily_Total_Revenue_Report">Meter-Wise Daily Revenue</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Tariff_Config_Report">Tariff Configuration
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Tariff_Config_Slab_Report">Slab Tariff Configuration
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Happy_Hours_Report">Happy Hours Config
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Bill_Creation_Report">Bill Creation Config
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Load_Limit_Config_Report">Load Limit Config
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Total_Revenue_Report">Transactional Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Total_Revenue_Report_failed">Failed
                  Transactional Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Total_Adjustment_Report">Adjustment Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Total_Adjustment_Report_Failed">Failed
                  Adjustment Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c3">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_DG_Status_Report">DG Status Report
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Monthly_Total_Revenue_Report">Meter-Wise Monthly Revenue</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Monthly_Total_Revenue_Report">Monthly Total Revenue</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Meterwise_Yearly_Total_Revenue_Report">Meter-Wise Yearly Total Revenue</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Yearly_Total_Revenue_Report">Yearly Total Revenue</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c4">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Low_Balance_Report">Low Balance Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class="d-c5">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Zero_Balance_Report">Zero Balance Report
                </mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_Daily_Dead_Meters_Report">Dead Meters Report
                </mat-slide-toggle>
              </div>
              <!--<div class="with-icon col-sm-4">
                  <mat-icon class=" d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Meters_Reports_Cons_Wise_SMS_Sent_Report">Cons-Wise SMS Sent</mat-slide-toggle>
                </div>-->
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_SMS_Sent_Report">SMS Sent Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_SLA_Report">SLA Report</mat-slide-toggle>
              </div>
              <div class="with-icon col-sm-4">
                <mat-icon class=" d-c6">
                  report
                </mat-icon>
                <mat-slide-toggle formControlName="Meters_Reports_PD_Meters_Report">PD Meters Report</mat-slide-toggle>
              </div>
              
            </div>
          </mat-expansion-panel>
            </mat-accordion>
      </div>
      </div>
      <!-- Grid row -->
      <!-- Grid row -->
      <div class="clearfix"></div>
      <div class=" row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="isloading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="!isLoading && message.length > 0">
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
            </div>
          </div>

        </div>

        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>update</mat-icon>
            Update
          </button>
          <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

        </div>
      </div>
    </div>
  </div>
</form>
<!----add authority end-->