<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Happy Hours Config From Meter: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container>
      <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
      </ng-container>

      <div class="row mx-auto form-wrapper table-responsive"  *ngIf="isData">
        <table class="table table-bordered table-sm table-hover">
          <thead class="tablebghead">
            <tr>
              <td>
                <div class="d_title">
                  <span></span>Sr. No.
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Meter Id
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>HH Start-Time
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>HH End-Time
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Week Day1
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Week Day2
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>HD1
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>HD2
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD3
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD4
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD5
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD6
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD7
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD8
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD9
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD10
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD11
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD12
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD13
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD14
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>HD15
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Date & Time
              </div>
              </td>
            </tr>
          </thead>
          <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
<td>
<div class="d_content d_content_W">
  <p>{{item.meterId}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.happyStartTime}}</p>
</div>
</td>


<td>
<div class="d_content d_content_W">
  <p>{{item.happyStopTime}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.weekOffDay1}}</p>
</div>
</td>

<td>
  <div class="d_content d_content_W">
    <p>{{item.weekOffDay2}}</p>
  </div>
  </td>

<td>
<div class="d_content d_content_W">
  <p>{{item.HappyDay1}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.HappyDay2}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.HappyDay3}}</p>
</div>
</td>

<td>
  <div class="d_content d_content_W">
    <p>{{item.HappyDay4}}</p>
  </div>
  </td>

  <td>
    <div class="d_content d_content_W">
      <p>{{item.HappyDay5}}</p>
    </div>
    </td>

    <td>
<div class="d_content d_content_W">
  <p>{{item.HappyDay6}}</p>
</div>
</td>

<td>
  <div class="d_content d_content_W">
    <p>{{item.HappyDay7}}</p>
  </div>
  </td>

  <td>
    <div class="d_content d_content_W">
      <p>{{item.HappyDay8}}</p>
    </div>
    </td>

    <td>
      <div class="d_content d_content_W">
        <p>{{item.HappyDay9}}</p>
      </div>
      </td>

      <td>
        <div class="d_content d_content_W">
          <p>{{item.HappyDay10}}</p>
        </div>
        </td>

        <td>
          <div class="d_content d_content_W">
            <p>{{item.HappyDay11}}</p>
          </div>
          </td>

          <td>
            <div class="d_content d_content_W">
              <p>{{item.HappyDay12}}</p>
            </div>
            </td>

            <td>
              <div class="d_content d_content_W">
                <p>{{item.HappyDay13}}</p>
              </div>
              </td>

              <td>
                <div class="d_content d_content_W">
                  <p>{{item.HappyDay14}}</p>
                </div>
                </td>

                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.HappyDay15}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.activedateTime}}</p>
                    </div>
                    </td>


</tr>
</tbody>
</table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        <span></span>{{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>          
        <div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>--> 
      </div>

      <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
        <div class="col-sm-12">
          <img class="center" src="assets/images/Nodata.png">
        </div>
        <!--<div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->
      </div>
      
    </ng-container>
<ng-container col-sm-3>
</ng-container>
</div>
</div>
