import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
import { TableUtilWithoutTime } from 'src/app/deshboardauthall/TableUtilWithoutTime';

@Component({
  selector: 'app-add-ami-meters',
  templateUrl: './add-ami-meters.component.html',
  styleUrls: ['./add-ami-meters.component.scss']
})
export class AddAmiMetersComponent {
  dataType: any;

  @ViewChild('amiData') amiData!: ElementRef;
  amiDat: string = '';

  @ViewChild('getBulkMeterIPAddress') getBulkMeterIPAddress!: ElementRef;
  getBulkMeterIPAddres: string = '';

  @ViewChild('addMetersWithoutAPP') addMetersWithoutAPP!: ElementRef;
  addMetersWithoutAP: string = '';

  @ViewChild('removedInactiveMeters') removedInactiveMeters!: ElementRef;
  removedInactiveMeter: string = '';

  @ViewChild('updateIPOnLocalHES') updateIPOnLocalHES!: ElementRef;
  updateIPOnLocalHE: string = '';

  @ViewChild('sIMActivation') sIMActivation!: ElementRef;
  sIMActivatio: string = '';

  @ViewChild('uploadMetersWOutSIMActivate') uploadMetersWOutSIMActivate!: ElementRef;
  uploadMetersWOutSIMActivat: string = '';

  @ViewChild('uploadMetersWithSIMActivation') uploadMetersWithSIMActivation!: ElementRef;
  uploadMetersWithSIMActivatio: string = '';

  @ViewChild('uploadPSPCLPortalData') uploadPSPCLPortalData!: ElementRef;
  uploadPSPCLPortalDat: string = '';

  @ViewChild('getIPAddress') getIPAddress!: ElementRef;
  getIPAddres: string = '';

  @ViewChild('uploadAirtelSIMInv') uploadAirtelSIMInv!: ElementRef;
  uploadAirtelSIMIn: string = '';

  @ViewChild('uploadSimMobileNo') uploadSimMobileNo!: ElementRef;
  uploadSimMobileN: string = '';

  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  authType: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;



  authority: any;
  authorityDOB = "0000-00-00";
  siteUrl = this.genReqs.apiPath();

  public authLevelData: any = [];
  public countryLevelData: any = [];
  public stateLevelData: any = [];
  public utilityLevelData: any = [];
  public circleLevelData: any = [];
  public divisionLevelData: any = [];
  public subdivisionLevelData: any = [];
  public dcuLevelData: any = [];
  message: string = '';
  authLevelFieldValue: string = '';
  authLevelField: boolean = false;
  isLoading: boolean = false;
  thumb: boolean = false;
  submitted: boolean = false;
  status: boolean = false;
  ///multiselect

  nowDate = new Date();
  authMulti1: boolean = false;
  authMulti2: boolean = false;
  authMulti3: boolean = false;
  authMulti4: boolean = false;
  authMulti5: boolean = false;
  authMulti6: boolean = false;
  authMulti7: boolean = false;
  authMulti8: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal

  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    if (this.currentUser.data.userData.authLevel == '7') {
      this.authLevelField = true;
      this.authLevelFieldValue = '7';
    }

  }

  downloadCsvFile(data) {
    if (data == 'amiData') {
      TableUtil.exportTableToExcel("amiData", "amiData");
    } else if (data == 'GetBulkMeterIPAddress') {
      TableUtilWithoutTime.exportTableToExcel("GetBulkMeterIPAddress", "GetBulkMeterIPAddress");
    } else if (data == 'AddMetersWithoutAPP') {
      TableUtilWithoutTime.exportTableToExcel("AddMetersWithoutAPP", "AddMetersWithoutAPP");
    } else if (data == 'RemovedInactiveMeters') {
      TableUtilWithoutTime.exportTableToExcel("RemovedInactiveMeters", "RemovedInactiveMeters");
    } else if (data == 'UpdateIPOnLocalHES') {
      TableUtilWithoutTime.exportTableToExcel("UpdateIPOnLocalHES", "UpdateIPOnLocalHES");
    } else if (data == 'SIMActivation') {
      TableUtilWithoutTime.exportTableToExcel("SIMActivation", "SIMActivation");
    } else if (data == 'UploadMetersWOutSIMActivate') {
      TableUtilWithoutTime.exportTableToExcel("UploadMetersWOutSIMActivate", "UploadMetersWOutSIMActivate");
    } else if (data == 'UploadMetersWithSIMActivation') {
      TableUtilWithoutTime.exportTableToExcel("UploadMetersWithSIMActivation", "UploadMetersWithSIMActivation");
    } else if (data == 'UploadPSPCLPortalData') {
      TableUtilWithoutTime.exportTableToExcel("UploadPSPCLPortalData", "UploadPSPCLPortalData");
    } else if (data == 'GetIPAddress') {
      TableUtilWithoutTime.exportTableToExcel("GetIPAddress", "GetIPAddress");
    } else if (data == 'UploadAirtelSIMInv') {
      TableUtilWithoutTime.exportTableToExcel("UploadAirtelSIMInv", "UploadAirtelSIMInv");
      TableUtilWithoutTime.exportTableToExcel("UploadSimMobileNo", "UploadSimMobileNo");
    }
  }

  ngAfterViewInit(): void {
    // After the view has been initialized, add the table to the DOM
    if (this.dataType == 'amiData') {
      this.amiDat = '<table id="amiData" border="1">' +
        '<tr>' +
        '<th>Sr. No</th>' +
        '<th>Account No</th>' +
        '<th>Zone</th>' +
        '<th>Circle</th>' +
        '<th>Division</th>' +
        '<th>Sub-Division</th>' +
        '<th>Meter No</th>' +
        '<th>Address</th>' +
        '<th>Cons Mobile No</th>' +
        '<th>JE Mobile No</th>' +
        '</tr>' +
        '</table>';
      this.amiData.nativeElement.innerHTML = this.amiDat;
    } else if (this.dataType == 'GetBulkMeterIPAddress') {
      this.getBulkMeterIPAddres = '<table id="GetBulkMeterIPAddress" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.getBulkMeterIPAddress.nativeElement.innerHTML = this.getBulkMeterIPAddres;
    } else if (this.dataType == 'AddMetersWithoutAPP') {
      this.addMetersWithoutAP = '<table id="AddMetersWithoutAPP" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.addMetersWithoutAPP.nativeElement.innerHTML = this.addMetersWithoutAP;
    } else if (this.dataType == 'RemovedInactiveMeters') {
      this.removedInactiveMeter = '<table id="RemovedInactiveMeters" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.removedInactiveMeters.nativeElement.innerHTML = this.removedInactiveMeter;
    } else if (this.dataType == 'UpdateIPOnLocalHES') {
      this.updateIPOnLocalHE = '<table id="UpdateIPOnLocalHES" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '<th>IP Address</th>' +
        '</tr>' +
        '</table>';
      this.updateIPOnLocalHES.nativeElement.innerHTML = this.updateIPOnLocalHE;
    } else if (this.dataType == 'SIMActivation') {
      this.sIMActivatio = '<table id="SIMActivation" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.sIMActivation.nativeElement.innerHTML = this.sIMActivatio;
    } else if (this.dataType == 'UploadMetersWOutSIMActivate') {
      this.uploadMetersWOutSIMActivat = '<table id="UploadMetersWOutSIMActivate" border="1">' +
        '<tr>' +
        '<th>Account No</th>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.uploadMetersWOutSIMActivate.nativeElement.innerHTML = this.uploadMetersWOutSIMActivat;
    } else if (this.dataType == 'UploadMetersWithSIMActivation') {
      this.uploadMetersWithSIMActivatio = '<table id="UploadMetersWithSIMActivation" border="1">' +
        '<tr>' +
        '<th>Account No</th>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.uploadMetersWithSIMActivation.nativeElement.innerHTML = this.uploadMetersWithSIMActivatio;
    } else if (this.dataType == 'UploadPSPCLPortalData') {
      this.uploadPSPCLPortalDat = '<table id="UploadPSPCLPortalData" border="1">' +
        '<tr>' +
        '<th>id</th>' +
        '<th>Meter Installation ID</th>' +
        '<th>Make</th>' +
        '<th>Serial No</th>' +
        '<th>Meter Digits</th>' +
        '<th>MF</th>' +
        '<th>Class</th>' +
        '<th>Category</th>' +
        '<th>Memory</th>' +
        '<th>Protocol</th>' +
        '<th>Capacity</th>' +
        '<th>Voltage</th>' +
        '<th>Event</th>' +
        '<th>Date of event</th>' +
        '<th>Health Type</th>' +
        '<th>Reading</th>' +
        '<th>Seal Status</th>' +
        '<th>Remarks</th>' +
        '<th>Latitude</th>' +
        '<th>Longtitude</th>' +
        '<th>PSPCL Account Number</th>' +
        '<th>Consumer Name</th>' +
        '<th>Consumer Address</th>' +
        '<th>Load (KW)</th>' +
        '<th>Location Name</th>' +
        '<th>Consumer Mobile Number</th>' +
        '<th>Subdivision</th>' +
        '<th>Division</th>' +
        '<th>Circle</th>' +
        '<th>Zone</th>' +
        '<th>Accepted by</th>' +
        '<th>JE Accept Date</th>' +
        '<th>Meter Image</th>' +
        '</tr>' +
        '</table>';
      this.uploadPSPCLPortalData.nativeElement.innerHTML = this.uploadPSPCLPortalDat;
    } else if (this.dataType == 'GetIPAddress') {
      this.getIPAddres = '<table id="GetIPAddress" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.getIPAddress.nativeElement.innerHTML = this.getIPAddres;
    } else if (this.dataType == 'UploadAirtelSIMInv') {
      this.uploadAirtelSIMIn = '<table id="UploadAirtelSIMInv" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.uploadAirtelSIMInv.nativeElement.innerHTML = this.uploadAirtelSIMIn;
      this.uploadSimMobileN = '<table id="UploadSimMobileNo" border="1">' +
        '<tr>' +
        '<th>Meter No</th>' +
        '</tr>' +
        '</table>';
      this.uploadSimMobileNo.nativeElement.innerHTML = this.uploadSimMobileN;
    }
  }

  ngOnInit() {

    if (this.currentUser.data.userData.subdivision == "105") {
      this.status = true;
    }
    console.log("Hello", this.currentUser.data.userData.subdivision);
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;

    this.addAuthorityForm = this.formBuilder.group({
      amiMetersFile: ['', Validators.required],
    });




  }

  onFileSelect(event) {
    if (event.target.files && event.target.files[0]) {

      let file = event.target.files[0];

      //console.log(file.type);
      if (file.type == "application/vnd.ms-excel") {
        const file = event.target.files[0];
        //if(file.name=='Sample_Excel.xls'){
        this.aD.amiMetersFile.setValue(file);
        return true;
        /*}else{
          this.aD.amiMetersFile.setValue(null);
          alert("Please choose Sample_Excel File For Uploading Data.");
          return false;
        }*/
      }
      else {
        this.aD.amiMetersFile.setValue(null);
        alert("Please select Files in Excel Formats.");
        return false;
      }
    }
  }


  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAddAmiData() {
      if (this.aD.amiMetersFile.value == null) {
        alert("Please choose Sample_Excel File For Uploading Data.");
        this.submitted = true;
        return;
      }
      if (this.addAuthorityForm.invalid) {
        this.submitted = true;
        return;
      }
      this.message = '';
      this.isLoading = true;
      this.AddProcess = true;

      const formData = new FormData();
      
      if (this.aD.amiMetersFile.value != null) {
        formData.append('amiData', this.aD.amiMetersFile.value);
      } else {
        alert("Please choose File For Uploading Data.");
        return false;
      }
      formData.set('authLevel', '7');
      formData.set('subdivId', this.currentUser.data.userData.subdivision);
      formData.append('fileType', this.dataType);
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/uploadAmiData', formData).subscribe((res) => {
        console.log(res.success);
        if (res.success) {
          this.isLoading = false;
          this.message = res.data.message;
          if (this.message.includes("Initiated")) {
            this.thumb = true;
          }
          setTimeout(() => {
            this.modal.close('done');
          },
            2000);
        }
      },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
    }
}
