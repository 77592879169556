<nav class="navbar navbar-default navbar-fixed mobappnavhide navbar-expand-lg" *ngIf="currentUser">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-warning btn-fill btn-round btn-icon d-none d-lg-block"
                    (click)="leftnavService.toggle()">
                    <i class="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
                    <i class="fa fa-navicon visible-on-sidebar-mini"></i>
                </button>
            </div>
            <div class="navbar-brand">
                <!-- <img class="heart welimg" src="assets/img/himsg.png"> -->
                {{now}}Hello, {{currentUser.data.userData.name | titlecase}} !&nbsp;
                <a class="navbar-brand" *ngIf="!check">
                    <span class="welc">Welcome to the portal</span>
                </a>


            </div>

        </div>
        <!--<a onClick="window.location.reload()">Refresh</a>-->
        <!--<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="window.location.reload(true)">
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
        </button>-->

        <!--<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
        </button>-->

        <button mat-button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" (click)="toggleCollapsed()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
         </button>

         
        <!--<button class="navbar-toggler" type="button" data-toggle="collapse"
         data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
         aria-expanded="false" aria-label="Toggle navigation"
         (click)="toggleCollapsed()">
        <span class="navbar-toggler-icon"></span>
      </button>-->

        <!--<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" 
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (onclick)="hello()">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
            </button> -->

        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <!--<li class="#hero">
                    <button class="userguid heart" type="button"> User Guide</button>
                </li>
                <li class="dropdown nav-item">
                        <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
                            <i class="nc-icon nc-bell-55"></i>
                            <span class="notification">5</span>
                            <span class="d-lg-none">Notification</span>
                        </a>
                        <ul class="dropdown-menu">
                            <a class="dropdown-item" href="#">Notification 1</a>
                            <a class="dropdown-item" href="#">Notification 2</a>
                            <a class="dropdown-item" href="#">Notification 3</a>
                            <a class="dropdown-item" href="#">Notification 4</a>
                            <a class="dropdown-item" href="#">Notification 5</a>
                        </ul>
                    </li>-->

                <li class="dropdown nav-item" *ngIf="!this.authenticationService.isCurrentUserAuthority">
                    <a href="#" class="dropdown-togglee nav-link" data-toggle="dropdown">
                        <div (click)="getNotifications()">
                            <i class="d-c16 fa fa-bell" [matBadgeHidden]="getNotifyCount() == 0"
                                [matBadge]="getNotifyCount()" *ngIf="getNotifyCount() == 0">
                            </i>
                            <i class="d-c11 fa fa-bell" [matBadgeHidden]="getNotifyCount() == 0"
                                [matBadge]="getNotifyCount()" *ngIf="!getNotifyCount() == 0">
                            </i>
                            <span class="badge" *ngIf="badgeContent > 0">{{badgeContent}}</span>
                        </div>
                    </a>
                </li>
                <!-- <div class="col-md-5 col-sm-12">
                        <h1>All Consumers List
                          <div class="tooltipp info_icntop ml-1">
                              <i data-toggle="tooltip" data-placement="top"class="fa fa-info tabula-tooltip "></i>
                              <span class="tooltiptextt">Tooltip text</span>
                          </div>
                        </h1>
                      </div> -->

                <li class="nav-item dropdown fleximg">

                    <a class="fleximg">
                        <div class="subfleximg">
                            <img src="../assets/img/default-avatar.png" class="imgR">
                        </div>
                        <div class="subflexitext">

                            <h2>{{currentUser.data.userData.name | titlecase}}</h2>
                            <!-- <p>Abcd Data</p> -->

                        </div>
                    </a>
                    <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="nc-icon nc-bullet-list-67"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
                        data-aos="fade-left">
                        <!-- <a [routerLink]="['/conscomplaint']" class="dropdown-item" href="#">
                            <i class="nc-icon nc-email-85" [routerLink]="['/conscomplaint']"></i> Messages
                        </a>
                        <a class="dropdown-item" [routerLink] (click)="contactUs()">
                            <i class="nc-icon nc-umbrella-13" (click)="contactUs()"></i> Contact Us
                        </a> -->
                        <a data-aos="zoom-in" data-aos-delay="150" [routerLink]="['/conscomplaint']"
                            class="dropdown-item" mat-raised-button color="warn">
                            <i class="nc-icon nc-email-85" [routerLink]="['/conscomplaint']"></i> Messages
                        </a>
                        <a data-aos="zoom-in" data-aos-delay="350" (click)="contactUs()"
                            class="dropdown-item text-dangergreen" mat-raised-button color="warn">
                            <i class="nc-icon nc-umbrella-13" (click)="contactUs()"></i> Contact Us
                        </a>

                        <div class="divider"></div>
                        <a data-aos="zoom-in" data-aos-delay="550" class="dropdown-item" [routerLink]="['/logOut']"
                            mat-raised-button (click)="logout()" color="warn">
                            <i class="nc-icon nc-lock-circle-open" [routerLink]="['/logOut']"></i> Lock Screen

                        </a>
                        <a data-aos="zoom-in" data-aos-delay="750" [routerLink]="['/logOut']"
                            class="dropdown-item text-dangergreen" (click)="logout()" mat-raised-button color="warn">
                            <i class="nc-icon nc-button-power"></i> Log out
                        </a>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</nav>


<!--<nav class="navbar navbar-default navbar-fixed navbar-expand-lg" *ngIf="currentUser">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-warning btn-fill btn-round btn-icon d-none d-lg-block"
                    (click)="leftnavService.toggle()">
                    <i class="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
                    <i class="fa fa-navicon visible-on-sidebar-mini"></i>
                </button>
            </div>
            <a class="navbar-brand" href="#pablo"> Dashboard </a>
        </div>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <li class="#hero">
                    <button class="userguid heart" type="button"> User Guide</button>
                </li>
                <li class="dropdown nav-item" *ngIf="!this.authenticationService.isCurrentUserAuthority">
                    <a href="#" class="dropdown-togglee nav-linkk " data-toggle="dropdownn">
                        <button (click)="getNotifications()" mat-button class=" site_color">
                            <mat-icon class="d-c16 mttopbell" [matBadgeHidden]="getNotifyCount() == 0"
                                [matBadge]="getNotifyCount()" *ngIf="getNotifyCount() == 0">notification_important
                            </mat-icon>
                            <mat-icon class="d-c11 mttopbell" [matBadgeHidden]="getNotifyCount() == 0"
                                [matBadge]="getNotifyCount()" *ngIf="!getNotifyCount() == 0">notification_important
                            </mat-icon>
                            <span class="badge" *ngIf="badgeContent > 0">{{badgeContent}}</span>
                        </button>
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="nc-icon nc-bullet-list-67"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a [routerLink]="['/conscomplaint']" class="dropdown-item" href="#">
                            <i class="nc-icon nc-email-85" [routerLink]="['/conscomplaint']"></i> Messages
                        </a>
                        <a class="dropdown-item" [routerLink] (click)="contactUs()">
                            <i class="nc-icon nc-umbrella-13" (click)="contactUs()"></i> Contact Us
                        </a>

                        <div class="divider"></div>
                        <a [routerLink]="['/login']" class="dropdown-item" (click)="logout()" mat-raised-button
                            color="warn">
                            <i class="nc-icon nc-lock-circle-open"></i> Lock Screen
                        </a>
                        <a [routerLink]="['/login']" class="dropdown-item text-dangergreen" (click)="logout()"
                            mat-raised-button color="warn">
                            <i class="nc-icon nc-button-power"></i> Log out
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>-->