
  <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
          data-dismiss="modal">&times;</button>
          Refund Amount
      </h4>
    </div>
    <div class="modal-body">
      <div class="form-groupp">
          <!-- Grid row -->
          <div class="row">
            <!-- Default input -->
            <div class="col-md-12">
              <div class="form-group input-group">
                <span class="has-float-label">
                <input matInput  type="number" placeholder="Merchant Amount:" name="merchantAmount" 
                formControlName="merchantAmount" class="form-control"[ngClass]="{ 'is-invalid': submitted && aF.merchantAmount.errors}" required>
                <i class="fa fa-user icon_innertxtbox"></i>
                    <label>Merchant Amount</label>
                    <div *ngIf="submitted && aF.merchantAmount.errors" class="invalid-feedback">
                        <div *ngIf="aF.merchantAmount.errors.required">
                            Merchant Amount is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group input-group">
                  <span class="has-float-label">
                  <input matInput  type="number" placeholder="Aggregator Amount:" name="aggregatorAmount" 
                  formControlName="aggregatorAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.aggregatorAmount.errors}" required>
                <i class="fa fa-user icon_innertxtbox"></i>
                    <label>Aggregator Amount</label>
                    <div *ngIf="submitted && aF.aggregatorAmount.errors" class="invalid-feedback">
                        <div *ngIf="aF.aggregatorAmount.errors.required">
                             Aggregator Amount is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput  type="number" placeholder="Refund Amount:" name="refundAmount" formControlName="refundAmount"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.refundAmount.errors}" required>
                    <i class="fa fa-user icon_innertxtbox"></i>
                    <label>Refund Amount</label>
                    <div *ngIf="submitted && aF.refundAmount.errors" class="invalid-feedback">
                        <div *ngIf="aF.refundAmount.errors.required">
                             Refund Amount is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
              <div class="col-sm-12 text-left">
                  <ng-container *ngIf="isLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                  </ng-container>
                    <div role="alert" *ngIf="addAuthMessage.length > 0" >
                      <div class="alert alert-success" role="alert" *ngIf="thumb">
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                      </div>
                      <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                        <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                      </div>
                    </div>
              </div>
            </div>
            <div class="col-12 row">   
              <div class="col-sm-12 text-right">
                <button type="submit" class="dilog dilog1">
                  <mat-icon>add</mat-icon>
                  Submit
                </button>
                <!--<button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
                  <mat-icon >cancel</mat-icon>
                  Cancel
                </button>-->
  
              </div>
          </div>
        </div>
    </div>
    </form>
