<div class="modal-content-new">
    <div class="modal-header subpage">
      <div class="col-11">
        <h4 class="modal-title" id="modal-basic-title">
          <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
              data-dismiss="modal">&times;</button>
          Recent Daily Consumption Data: {{meterDetail.mID}}
      </h4>
      </div>
      <div class="col-1 text-right">
        <div>
          <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
              class="fa fa-file-excel-o" style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
        <ng-container>
            <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <div class="scrollable-table-container" *ngIf="isData">
                <table id="RecentDailyData" class="table table-bordered table-sm table-hover">
                    <thead class="tablebghead">
                        <tr>
                          <th *ngFor="let header of letviewis.commonData">
                            <div *ngFor="let key of objectKeys(header)" class="d_title">
                              {{header[key]}}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="tbbodystyle">
                        <tr class="col-sm-12 col-md-6 o_b" *ngFor="let row of letviewis.dataFinal">
                          <td *ngFor="let cell of row">
                            <div *ngFor="let key of objectKeys(cell)" class="d_content d_content_W">
                              {{cell[key]}}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                </table>
            </div>

            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
                <div class="col-sm-12">
                    <img class="center" src="assets/images/Nodata.png">
                </div>
            </div>

        </ng-container>
        <ng-container col-sm-3>
        </ng-container>
    </div>
</div>