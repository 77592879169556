<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAmiData()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
                <div *ngIf="dataType=='amiData'">
                    Upload AMI Excel Data
                </div>
                <div *ngIf="dataType=='GetBulkMeterIPAddress'">
                    Get Bulk Meter IP Address
                </div>
                <div *ngIf="dataType=='AddMetersWithoutAPP'">
                    Add Meters Without APP
                </div>
                <div *ngIf="dataType=='RemovedInactiveMeters'">
                    Removed Inactive Meters
                </div>
                <div *ngIf="dataType=='UpdateIPOnLocalHES'">
                    Update IP On Local HES
                </div>
                <div *ngIf="dataType=='SIMActivation'">
                    SIM Activation
                </div>
                <div *ngIf="dataType=='UploadMetersWOutSIMActivate'">
                    Upload Meters WOut SIM Activate
                </div>
                <div *ngIf="dataType=='UploadMetersWithSIMActivation'">
                    Upload Meters With SIM Activation
                </div>
                <div *ngIf="dataType=='UploadPSPCLPortalData'">
                    Upload PSPCL Portal Data
                </div>
                <div *ngIf="dataType=='GetIPAddress'">
                    Get IP Address
                </div>
                <div *ngIf="dataType=='UploadAirtelSIMInv'">
                    Upload Airtel SIM Inventory
                </div>
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">

            <div class="row">
                <div class="col-md-6">
                    <span class="has-float-label ">
                        <div class="form-group input-group">

                        </div>

                    </span>
                </div>
            </div>
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    Download Sample File
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-12 text-center">
                                <div *ngIf="dataType=='amiData'">
                                    <div #amiData [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('amiData')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='GetBulkMeterIPAddress'">
                                    <div #getBulkMeterIPAddress [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('GetBulkMeterIPAddress')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='AddMetersWithoutAPP'">
                                    <div #addMetersWithoutAPP [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('AddMetersWithoutAPP')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='RemovedInactiveMeters'">
                                    <div #removedInactiveMeters [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('RemovedInactiveMeters')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='UpdateIPOnLocalHES'">
                                    <div #updateIPOnLocalHES [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('UpdateIPOnLocalHES')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='SIMActivation'">
                                    <div #sIMActivation [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('SIMActivation')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='UploadMetersWOutSIMActivate'">
                                    <div #uploadMetersWOutSIMActivate [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('UploadMetersWOutSIMActivate')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='UploadMetersWithSIMActivation'">
                                    <div #uploadMetersWithSIMActivation [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('UploadMetersWithSIMActivation')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='UploadPSPCLPortalData'">
                                    <div #uploadPSPCLPortalData [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('UploadPSPCLPortalData')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='GetIPAddress'">
                                    <div #getIPAddress [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('GetIPAddress')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                                <div *ngIf="dataType=='UploadAirtelSIMInv'">
                                    <div #uploadSimMobileNo [hidden]="true"></div>
                                    <div #uploadAirtelSIMInv [hidden]="true"></div>
                                    <button style="border:0;background: azure;" title="Download Sample Excel"
                                        (click)="downloadCsvFile('UploadAirtelSIMInv')"><i class="fa fa-file-excel-o"
                                            style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    Attach File
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="has-float-label ">
                                <div class="form-group input-group">
                                    <input type="file" name="amiMetersFile" (change)="onFileSelect($event)" class=""
                                        [ngClass]="{ 'is-invalid': submitted && aD.amiMetersFile.errors }" required  accept=".xls">
                                    <div *ngIf="submitted && aD.amiMetersFile.errors" class="invalid-feedback">
                                        <div *ngIf="aD.amiMetersFile.errors.required">
                                            AMI File is required
                                        </div>
                                    </div>
                                </div>

                            </span>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && message.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 text-left">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Upload
                    </button>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->