import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobreports',
  templateUrl: './mobreports.component.html',
  styleUrls: [
    './mobreports.component.scss']
})

export class MobreportsComponent implements OnInit {
  constructor(
  ) {
  }
  public ngOnInit() {
   
   }
}
