import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';


@Component({
  selector: 'app-edit-smart-meter-order',
  templateUrl: './edit-smart-meter-order.component.html',
  styleUrls: ['./edit-smart-meter-order.component.scss']
})
export class EditSmartMeterOrderComponent implements OnInit {

  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;
  issuedMeters:any = {};

  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyAddress: ['', Validators.required],
      gstNo: ['', Validators.required],
      singlePhMeterRate: ['', Validators.required],
      singlePhphMeterQty: ['', Validators.required],
      threePhMeterRate: ['', Validators.required],
      threePhMeterQty: ['', Validators.required],
      ltctMeterRate: ['', Validators.required],
      ltctMeterQty: ['', Validators.required],
      paymentReceived: ['', Validators.required],
      pendingPayment: ['']
    });
    this.setValues();
  }
  setValues() {
    this.aF.companyName.setValue(this.issuedMeters.companyName);
    this.aF.companyAddress.setValue(this.issuedMeters.address);
    this.aF.gstNo.setValue(this.issuedMeters.gstNo);
    this.aF.singlePhMeterRate.setValue(this.issuedMeters.onephRate);
    this.aF.singlePhphMeterQty.setValue(this.issuedMeters.onephQty);
    this.aF.threePhMeterRate.setValue(this.issuedMeters.threephRate);
    this.aF.threePhMeterQty.setValue(this.issuedMeters.threephQty);
    this.aF.ltctMeterRate.setValue(this.issuedMeters.ltctRate);
    this.aF.ltctMeterQty.setValue(this.issuedMeters.ltctQty);
    //this.aF.paymentReceived.setValue(this.issuedMeters.paymentReceivedMarket);
    this.aF.pendingPayment.setValue(this.issuedMeters.pendingPayment);
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      /*if (this.aF.paymentReceived.value>this.issuedMeters.pendingPayment) {
        this.confirmationDialogService.confirm('Please check..', 'Received Payment Must Be Lessthan or Equal to Pending Payment!')
        .then((confirmed) => {
          if(confirmed) {
            return;
          }
        })
        .catch(() => console.log('no response or closed'));
        return;
      }*/
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('id', this.issuedMeters.id);
      body.set('oldCompanyName', this.issuedMeters.companyName);
      body.set('companyName', this.aF.companyName.value);
      body.set('companyAddress', this.aF.companyAddress.value);
      body.set('gstNo', this.aF.gstNo.value);
      body.set('singlePhMeterRate', this.aF.singlePhMeterRate.value);
      body.set('singlePhphMeterQty', this.aF.singlePhphMeterQty.value);
      body.set('threePhMeterRate', this.aF.threePhMeterRate.value);
      body.set('threePhMeterQty', this.aF.threePhMeterQty.value);
      body.set('ltctMeterRate', this.aF.ltctMeterRate.value);
      body.set('ltctMeterQty', this.aF.ltctMeterQty.value);
      body.set('paymentReceived', this.aF.paymentReceived.value);
      body.set('pendingAmount', this.issuedMeters.pendingPayment);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/editSmartMeterCont', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
