import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ModalService } from '../../_services/modal.service';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { User } from 'src/app/_models';
import { Access } from 'src/app/_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// MDB Angular Free

@Component({
  selector: 'app-configmeter',
  templateUrl: './configmeter.component.html',
  styleUrls: ['./configmeter.component.scss']
})
export class ConfigmeterComponent implements OnInit {
  loading:boolean = false;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  currentUserSubscription: Subscription;
  currentUser: User;
  enableSocietyChange:boolean = true;
  getThrough: Access;
  faSpinner = faSpinner;
  constructor(
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    });
    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }
  ngOnInit() {
    
    if(this.enableSocietyChange){
      this.whichSubDomain();
    }
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
   
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
      }
    );
  }
  get cF() { return this.addForm.controls; }
}
