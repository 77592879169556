<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <mat-form-field class="col-md-6 form-group input-rounded">
      <input matInput placeholder="Server IP Address" name="serverIpAddress" formControlName="serverIpAddress">
      <mat-icon matPrefix class="d-c3"> flash_on </mat-icon>
      <mat-error *ngIf="cF.serverIpAddress.hasError('required')">
        Server IP Address is <strong>required</strong>
      </mat-error>
  </mat-form-field>

  <mat-form-field class="col-md-6 form-group input-rounded">
      <input matInput placeholder="Server Port No" name="serverPortNo" formControlName="serverPortNo">
      <mat-icon matPrefix class="d-c3"> flash_on </mat-icon>
      <mat-error *ngIf="cF.serverPortNo.hasError('required')">
          Server Port No is <strong>required</strong>
      </mat-error>
  </mat-form-field>
  <button mat-raised-button  class="cPbg" type="submit">Add</button>
</form>