import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeshboardComponent } from './deshboard/deshboard.component';
import { DeshboardconsumerComponent } from './deshboardconsumer/deshboardconsumer.component';
import { Deshboardauth2Component } from './deshboardauth2/deshboardauth2.component'
import { Deshboardauth3Component } from './deshboardauth3/deshboardauth3.component'
import { Deshboardauth4Component } from './deshboardauth4/deshboardauth4.component'
import { Deshboardauth5Component } from './deshboardauth5/deshboardauth5.component'
import { Deshboardauth6Component } from './deshboardauth6/deshboardauth6.component'
import { DeshboardauthallComponent } from './deshboardauthall/deshboardauthall.component'
import { CountryToStateComponent } from './country-to-state/country-to-state.component'
import { StateToUtilityComponent } from './state-to-utility/state-to-utility.component'
import { UtilityToCircleComponent } from './utility-to-circle/utility-to-circle.component'
import { CircleToDivisionComponent } from './circle-to-division/circle-to-division.component'
import { DivisionToSubdivisionComponent } from './division-to-subdivision/division-to-subdivision.component'
import { SubdivisionToLayoutComponent } from './subdivision-to-layout/subdivision-to-layout.component'

import { UsersComponent } from './users/users.component'
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_guards';

import { AddedconsumerComponent } from './consumerview/addedconsumer/addedconsumer.component';
import { ConsscomplaintComponent } from './consumerview/consscomplaint/consscomplaint.component';



import { RechargetopayComponent } from './rechargetopay/rechargetopay.component';

import { from } from 'rxjs';
import { DcumeterreportingComponent } from './ncomps/dcumeterreporting/dcumeterreporting.component';
import { ActivityonmeterComponent } from './authorityview/activityonmeter/activityonmeter.component';




import { SuccessComponent } from './rechargetopay/success/success.component';
import { FailedComponent } from './rechargetopay/failed/failed.component';



import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisabledmetersComponent } from './deshboardauthall/disabledmeters/disabledmeters.component';

import { RechargemobComponent } from './rechargemob/rechargemob.component';
import { FailedMobComponent } from './rechargemob/failed-mob/failed-mob.component';
import { SuccessMobComponent } from './rechargemob/success-mob/success-mob.component';

import { MeterInstallDeshComponent } from './deshboardauthall/meter-install-desh/meter-install-desh.component';

import { DeshboardallsosComponent } from './deshboardallsos/deshboardallsos.component';
import { ReplacedmetersreportComponent } from './deshboardauthall/replacedmetersreport/replacedmetersreport.component';
import { LogoutComponent } from './subview/logout/logout.component';


import { ProgresstilldateComponent } from './mobreports/progresstilldate/progresstilldate.component';
import { ProgresstilldatenewComponent } from './mobreports/progresstilldatenew/progresstilldatenew.component';
import { ReplacedmeterinfoComponent } from './mobreports/replacedmeterinfo/replacedmeterinfo.component';
import { DaywiseinstallationprogressComponent } from './mobreports/daywiseinstallationprogress/daywiseinstallationprogress.component';

import { SmreplacementComponent } from './mobreports/smreplacement/smreplacement.component';
import { SmdefectivereplacementComponent } from './mobreports/smdefectivereplacement/smdefectivereplacement.component';
import { OldnewmeterinfomobComponent } from './mobreports/oldnewmeterinfomob/oldnewmeterinfomob.component';

import { MobilemapviewComponent } from './mobilemapview/mobilemapview.component';

import { MeterInstallDeshOtherComponent } from './deshboardauthall/meter-install-desh-other/meter-install-desh-other.component';

import { NoncommunicatemetersComponent } from './deshboardauthall/noncommunicatemeters/noncommunicatemeters.component';
import { CommunicatedmetersComponent } from './deshboardauthall/communicatedmeters/communicatedmeters.component';
import { UserworkstatusComponent } from './mobreports/userworkstatus/userworkstatus.component';
import { UserworkComponent } from './deshboardauthall/userwork/userwork.component';
import { CheckmeterreportComponent } from './mobreports/checkmeterreport/checkmeterreport.component';
import { InstallationandcommissioningComponent } from './deshboardauthall/installationandcommissioning/installationandcommissioning.component';
import { MeterReportingComponent } from './deshboardauthall/meter-reporting/meter-reporting.component';
import { ConfigmeterComponent } from './authorityview/configmeter/configmeter.component';
import { AuthinfoComponent } from './authorityview/reports/authinfo/authinfo.component';
import { ConsumptionsComponent } from './deshboardconsumer/reports/consumptions/consumptions.component';
import { AdjustmentsComponent } from './deshboardconsumer/reports/adjustments/adjustments.component';
import { ConfigLogReportComponent } from './deshboardconsumer/reports/config-log-report/config-log-report.component';
import { ConsBillingComponent } from './deshboardconsumer/reports/cons-billing/cons-billing.component';
import { EventLogComponent } from './deshboardconsumer/reports/event-log/event-log.component';
import { LoadSurveyComponent } from './deshboardconsumer/reports/load-survey/load-survey.component';
import { ConMeterActivityComponent } from './deshboardconsumer/reports/con-meter-activity/con-meter-activity.component';
import { MeterRealTimeComponent } from './deshboardconsumer/reports/meter-real-time/meter-real-time.component';
import { RechargesComponent } from './deshboardconsumer/reports/recharges/recharges.component';
import { SmsReportsComponent } from './deshboardconsumer/reports/sms-reports/sms-reports.component';
import { ComplaintHistoryComponent } from './deshboardconsumer/reports/complaint-history/complaint-history.component';
import { NotificationHistoryComponent } from './deshboardconsumer/reports/notification-history/notification-history.component';
import { DipwiseLoadSurveyComponent } from './deshboardconsumer/reports/dipwise-load-survey/dipwise-load-survey.component';
import { ConsumerLoginsComponent } from './deshboardconsumer/reports/consumer-logins/consumer-logins.component';
import { MaintbillComponent } from './deshboardconsumer/reports/maintbill/maintbill.component';
import { BackgroundworkComponent } from './authorityview/backgroundwork/backgroundwork.component';
import { CheckmeterbillreportComponent } from './mobreports/checkmeterbillreport/checkmeterbillreport.component';
import { NoncommunicatemeterspvtComponent } from './deshboardauthall/noncommunicatemeterspvt/noncommunicatemeterspvt.component';
import { NoncommunicatemetersgvtComponent } from './deshboardauthall/noncommunicatemetersgvt/noncommunicatemetersgvt.component';
import { SinglePhComponent } from './deshboardauthall/single-ph/single-ph.component';
import { ThreephComponent } from './deshboardauthall/threeph/threeph.component';
import { LtctComponent } from './deshboardauthall/ltct/ltct.component';
import { ConfigallmetersComponent } from './deshboardauthall/configallmeters/configallmeters.component';
import { AllMetersComponent } from './deshboardauthall/all-meters/all-meters.component';




const appRoutes: Routes = [
  { 
      path: '', 
      canActivate: [AuthGuard] ,
      loadChildren: () => import('./deshboard-view/deshboard-view.module').then(m => m.DeshboardViewModule)
  },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./deshboard-view/deshboard-view.module').then(m => m.DeshboardViewModule)
    },
    
    
    /*{
      path: 'reports',
      canActivate: [AuthGuard],
      //loadChildren: './deshboardauthall/reports/reports.module#ReportsModule'
      loadChildren: () => import('./deshboardconsumer/reports/cons.reports.module').then(m => m.ConsReportsModule)
    },*/
    {
      path: 'deshall',
      canActivate: [AuthGuard],
      loadChildren: () => import('./subview/subview.module').then(m => m.SubviewModule)
      //loadChildren: './deshboardauthall/deshall.module#DeshallModule'
    },
    {
      path: 'deshall',
      canActivate: [AuthGuard],
      loadChildren: () => import('./deshboardauthall/deshall.module').then(m => m.DeshallModule)
      //loadChildren: './deshboardauthall/deshall.module#DeshallModule'
    },
    {
      path: 'deshall',
      canActivate: [AuthGuard],
      loadChildren: () => import('./deshboardconsumer/consdesh.module').then(m => m.ConsdeshModule)
    },
    {
      path: 'deshall',
      canActivate: [AuthGuard],
      loadChildren: () => import('./subdivision-to-layout/subdiv.module').then(m => m.SubdivModule)
    },
    {
      path: 'authView',
      canActivate: [AuthGuard],
      loadChildren: () => import('./authorityview/authorityview.module').then(m => m.AuthorityViewModule)
    },
    
    /*{
      path: 'authView',
      canActivate: [AuthGuard],
      loadChildren: './authorityview/authorityview.module#AuthorityViewModule'
    },*/
  { path: 'gridDeshBoard', component: DeshboardauthallComponent, canActivate: [AuthGuard] },
  { path: 'gridDeshBoardAll', component: AllMetersComponent, canActivate: [AuthGuard] },
  { path: 'gridDeshBoard1Ph', component: SinglePhComponent, canActivate: [AuthGuard] },
  { path: 'gridDeshBoard3Ph', component: ThreephComponent, canActivate: [AuthGuard] },
  { path: 'gridDeshBoardltct', component: LtctComponent, canActivate: [AuthGuard] },
  { path: 'allsosGridDeshBoard', component: DeshboardallsosComponent, canActivate: [AuthGuard] },
  { path: 'replacedMeterInfo',component: ReplacedmetersreportComponent, canActivate: [AuthGuard]},
  { path: 'replacedMeterInfoMob',component: ReplacedmeterinfoComponent},
  { path: 'mobileMap',component: MobilemapviewComponent},


  { path: 'installedMeterSummeryMob',component: DaywiseinstallationprogressComponent},
  { path: 'oldNewMeterInfoMob',component: OldnewmeterinfomobComponent},
  { path: 'checkMeterReportMob',component: CheckmeterreportComponent},
  { path: 'checkMeterReportMobBill',component: CheckmeterbillreportComponent},
  { path: 'smartMeterReplacementMob',component: SmreplacementComponent},
  { path: 'smartMeterDefectiveReplacementMob',component: SmdefectivereplacementComponent},
  { path: 'progressTillDate',component: ProgresstilldateComponent},
  { path: 'progressTillDateNew',component: ProgresstilldatenewComponent},
  { path: 'userWorkInfoMob',component: UserworkstatusComponent},


  //{ path: 'gridDeshBoard', component: AllmetersComponent, canActivate: [AuthGuard] },
  { path: 'gridDeshBoardCons', component: DeshboardconsumerComponent, canActivate: [AuthGuard] },
  { path: 'deshauth', component: DeshboardComponent, canActivate: [AuthGuard] },
  { path: 'deshstate', component: Deshboardauth2Component, canActivate: [AuthGuard] },
  { path: 'deshutility', component: Deshboardauth3Component, canActivate: [AuthGuard] },
  { path: 'deshcircle', component: Deshboardauth4Component, canActivate: [AuthGuard] },
  { path: 'deshdivision', component: Deshboardauth5Component, canActivate: [AuthGuard] },
  { path: 'deshsubdivision', component: Deshboardauth6Component, canActivate: [AuthGuard] },
  { path: 'deshall', component: DeshboardauthallComponent, canActivate: [AuthGuard] },
  { path: 'deshauthstates', component: CountryToStateComponent, canActivate: [AuthGuard] },
  { path: 'deshauthutility', component: StateToUtilityComponent, canActivate: [AuthGuard] },
  { path: 'deshauthcircle', component: UtilityToCircleComponent, canActivate: [AuthGuard] },
  { path: 'deshauthdivision', component: CircleToDivisionComponent, canActivate: [AuthGuard] },
  { path: 'deshauthsubdivision', component: DivisionToSubdivisionComponent, canActivate: [AuthGuard] },
  { path: 'deshauthsubview', component: SubdivisionToLayoutComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'addconsumer', component: AddedconsumerComponent, canActivate: [AuthGuard] },
  { path: 'conscomplaint', component: ConsscomplaintComponent, canActivate: [AuthGuard] },
  { path: 'meteractivity', component: ActivityonmeterComponent, canActivate: [AuthGuard] },
  

  { path: 'integrationCommissioning', component: InstallationandcommissioningComponent, canActivate: [AuthGuard] },
  { path: 'backgroundWork', component: BackgroundworkComponent, canActivate: [AuthGuard] },
  { path: 'MeterInstallDesh', component: MeterInstallDeshComponent, canActivate: [AuthGuard] },
  { path: 'nonCommunicatedMeters', component: NoncommunicatemetersComponent, canActivate: [AuthGuard] },
  { path: 'nonCommunicatedMetersGovt', component: NoncommunicatemetersgvtComponent, canActivate: [AuthGuard] },
  { path: 'nonCommunicatedMetersPvt', component: NoncommunicatemeterspvtComponent, canActivate: [AuthGuard] },
  { path: 'communicatedMeters', component: CommunicatedmetersComponent, canActivate: [AuthGuard] },
  { path: 'MeterInstallDeshMob', component: ProgresstilldateComponent},
  { path: 'MeterInstallDeshNew', component: MeterInstallDeshOtherComponent, canActivate: [AuthGuard] },
  { path: 'MeterInstallDeshNewMob', component: ProgresstilldatenewComponent},
  { path: 'userWork', component: UserworkComponent},
  { path: 'rechargeandpay', component: RechargemobComponent},
  { path: 'recharge', component: RechargetopayComponent  , canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'logOut', component:  LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'successRecharge', component: SuccessComponent, canActivate: [AuthGuard] },
  { path: 'failedRecharge', component: FailedComponent, canActivate: [AuthGuard] },
  { path: 'success', component: SuccessMobComponent },
  { path: 'failed', component: FailedMobComponent },
  { path: 'successmob', component: SuccessMobComponent },
  { path: 'failedmob', component: FailedMobComponent },
  { path: 'contactus', component: ContactusComponent},
  { path: 'policy', component: PrivacyPolicyComponent},
  { path: 'aboutus', component: AboutusComponent},
  { path: 'disabledMeters', component: DisabledmetersComponent, canActivate: [AuthGuard] },
  { path: 'meterreporting', component: MeterReportingComponent, canActivate: [AuthGuard] },
  { path: 'meterconfig', component: ConfigmeterComponent, canActivate: [AuthGuard] },
  { path: 'meterconfigUtility', component: ConfigallmetersComponent, canActivate: [AuthGuard] },
  {
    path: 'reports/authinfo',
    component: AuthinfoComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/consumptions',
    component: ConsumptionsComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/adjustments',
    component: AdjustmentsComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/configlog',
    component: ConfigLogReportComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/billConsumer',
    component: ConsBillingComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/eventlog',
    component: EventLogComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/loadsurvey',
    component: LoadSurveyComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/meteractivity',
    component: ConMeterActivityComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/instConsumerData',
    component: MeterRealTimeComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/myrecharges',
    component: RechargesComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/smsreports',
    component: SmsReportsComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/comphistory',
    component: ComplaintHistoryComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/notifihistory',
    component: NotificationHistoryComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/dipWiseloadsurvey',
    component: DipwiseLoadSurveyComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/consLoginLogout',
    component: ConsumerLoginsComponent,
    canActivate: [AuthGuard] 
}, {
    path: 'reports/maintBill',
    component: MaintbillComponent,
    canActivate: [AuthGuard] 
},

{
  path: 'reports',
  canActivate: [AuthGuard],
  //loadChildren: './deshboardauthall/reports/reports.module#ReportsModule'
  loadChildren: () => import('./deshboardauthall/reports/reports.module').then(m => m.ReportsModule)
},

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
