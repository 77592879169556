
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as AOS from 'aos';
import { take } from 'rxjs/operators';
import { ModalService } from 'src/app/_services/modal.service';

//import party from 'party-js';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  messageResult: boolean;
  constructor(
    private modalService: ModalService,
  ) {
    AOS.init();
  }


  openLoginModel() {
    this.modalService.openLoginModel().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  ngOnInit() {

  }

  function() {
    $(this).toggleClass('active');
    //console.log("Clicked menu");
    $("#mainListDiv").toggleClass("show_list");
    $("#mainListDiv").fadeIn();
  }

  scrollTo(selector, yOffset = 0) {
    const el = document.querySelector(selector);
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 550) {
      let element = document.getElementById('loginHeader');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('loginHeader');
      element.classList.remove('sticky');
    }
  }

}
