import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-dcumeterconfig',
  templateUrl: './dcumeterconfig.component.html',
  styleUrls: ['./dcumeterconfig.component.scss']
})
export class DcumeterconfigComponent implements OnInit {
  loading:boolean = false;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private ngmodalService: NgbModal,
  ) { }

  ngOnInit() {
    this.whichSubDomain();
    this.addForm = this.formBuilder.group({
      countryid: ['', Validators.required],
      stateid: ['', Validators.required],
      utilityid: ['', Validators.required],
      circleid: ['', Validators.required],
      divisionid: ['', Validators.required],
      subdivision: ['', Validators.required],
      gslu: ['', Validators.required],
      bslu: ['', Validators.required],
      eblusm: ['', Validators.required],
      dglsm: ['', Validators.required],
      lrd: ['', Validators.required],
      lrc: ['', Validators.required],
      df: ['', Validators.required],
      ssmt: ['', Validators.required],
      ssmtoff: ['', Validators.required],
      bfm: ['', Validators.required],
      bm: ['', Validators.required],
      mcs: ['', Validators.required],
      mct: ['', Validators.required],
      mloffs: ['', Validators.required],
      mlons: ['', Validators.required],
      mols: ['', Validators.required],
      mlb: ['', Validators.required],
      movs: ['', Validators.required],
      mlvs: ['', Validators.required],
      sip: ['', Validators.required],
      spno: ['', Validators.required],
      ppl: ['', Validators.required],
      hhhd: ['', Validators.required],
      hhst: ['', Validators.required],
      hhet: ['', Validators.required],
      ecas: ['', Validators.required],
      ecgfm: ['', Validators.required],
      ovth: ['', Validators.required],
      lvth: ['', Validators.required],
      octh: ['', Validators.required],
      rphase: ['', Validators.required],
      yphase: ['', Validators.required],
      bphase: ['', Validators.required],
      brphase: ['', Validators.required],
      byphase: ['', Validators.required],
      bbphase: ['', Validators.required],
      dcugid: ['', Validators.required],
      dcugcno: ['', Validators.required],
      ar: ['', Validators.required],
      seweragerate: ['', Validators.required],
      emergencycredit: ['', Validators.required],
      parkingrate: ['', Validators.required],
      erkwheb: ['', Validators.required],
      waterrate: ['', Validators.required],
      prkwh: ['', Validators.required],
      prt: ['', Validators.required],
      edrkwheb: ['', Validators.required],
      irate: ['', Validators.required],
      rsrkwheb: ['', Validators.required],
      srate: ['', Validators.required],
      tkwheb: ['', Validators.required],
      ocrate: ['', Validators.required],
      erkwhdg: ['', Validators.required],
      gstcr: ['', Validators.required],
      gstkwhdgr: ['', Validators.required],
      fsrange: ['', Validators.required],
      tdgkwhr: ['', Validators.required],
      fsrate: ['', Validators.required],
      fratekweb: ['', Validators.required],
      ssrange: ['', Validators.required],
      pakwebr: ['', Validators.required],
      ssrate: ['', Validators.required],
      edfebr: ['', Validators.required],
      tsrange: ['', Validators.required],
      rsfebr: ['', Validators.required],
      tsrate: ['', Validators.required],
      tfckweb: ['', Validators.required],
      fslabrange: ['', Validators.required],
      mmckwfdgr: ['', Validators.required],
      fslabrate: ['', Validators.required],
      gstfdgr: ['', Validators.required],
      fifthsrange: ['', Validators.required],
      fixedcdgrate: ['', Validators.required],
      fifthsrate: ['', Validators.required],
      vrate: ['', Validators.required],
      sixthsr: ['', Validators.required],
      camrate: ['', Validators.required],
      sixthslabrate: ['', Validators.required],
      maintrate: ['', Validators.required],
      seventhslabrange: ['', Validators.required],
      clubrate: ['', Validators.required],
      seventhslabrate: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.ngmodalService.dismissAll();
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
       // console.log('selected val',this.selectedSubDivision);
      }
    );
  }
  get cF() { return this.addForm.controls; }
}
