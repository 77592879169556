import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-editsmartmeterprod',
  templateUrl: './editsmartmeterprod.component.html',
  styleUrls: ['./editsmartmeterprod.component.scss']
})
export class EditsmartmeterprodComponent implements OnInit {



  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;
  issuedMeters:any = {};
  paymentDate:string = '';
  nowDate = new Date();
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      singlePhDispetched: ['', Validators.required],
      threePhDispetched: ['', Validators.required],
      ltctDispetched: ['', Validators.required],
    });
  }


  ngAfterViewInit() {
    this.aF.singlePhDispetched.setValue(this.issuedMeters.onephDispatched);
    this.aF.threePhDispetched.setValue(this.issuedMeters.threephDispatched);
    this.aF.ltctDispetched.setValue(this.issuedMeters.ltctDispetched);
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if(this.aF.singlePhDispetched.value>this.issuedMeters.onephQty ||
        this.aF.threePhDispetched.value>this.issuedMeters.threephQty ||
        this.aF.ltctDispetched.value>this.issuedMeters.ltctQty){

          this.confirmationDialogService.confirm('Please confirm..', 
          'Please check dispatched quantity. It should be less than or equal to ordered quantity.')
          .then((confirmed) => {
            
          }).catch(() => console.log('no response or closed'));
        //alert("Please check dispatched quantity. It should be less than or equal to ordered quantity.");
        return;
      }
      if (this.addForm.invalid) {
        this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('id', this.issuedMeters.id);
      body.set('companyName',  this.issuedMeters.companyName);
      body.set('singlePhDispetched', this.aF.singlePhDispetched.value);
      body.set('threePhDispetched', this.aF.threePhDispetched.value);
      body.set('ltctDispetched', this.aF.ltctDispetched.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/editSmartMeterProd', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
