import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-smart-meter-meter-no',
  templateUrl: './add-smart-meter-meter-no.component.html',
  styleUrls: ['./add-smart-meter-meter-no.component.scss']
})
export class AddSmartMeterMeterNoComponent implements OnInit {

  faSpinner = faSpinner;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  meterNo:any={};
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  submitted: boolean = false;
 /* deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "EB"},
    {deviceType: "DG"}
  ];*/

  deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"}
    
  ];
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      meterType: ['', Validators.required],
      fromRange: [''],
      toRange: [''],
      meterSerialNo: [''],
    });
  }
  ngAfterViewInit() {
     
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if(this.aF.meterSerialNo.value=='' && this.aF.fromRange.value==''  && this.aF.toRange.value==''){
        alert("Please enter Meter numbers");
        return;
      }
      if(this.aF.meterSerialNo.value!='' && this.aF.fromRange.value!=''  && this.aF.toRange.value!=''){
        alert("Please enter either Meter No's Or From range - To Range of Meters.");
        return;
      }
      if(this.aF.fromRange.value!==''  && this.aF.toRange.value==''){
        alert("Please enter To Range of Meter.");
        return;
      }
      if(this.aF.fromRange.value==''  && this.aF.toRange.value!=''){
        alert("Please enter From Range of Meter.");
        return;
      }

      if(this.aF.meterSerialNo.value.length>7){
        if(!this.aF.meterSerialNo.value.includes(',')){
          alert("Please enter Meter No with , Sepreted");
          return;
        }
      }

      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('orderId', this.meterNo.id);
      body.set('meterType', this.aF.meterType.value);
      body.set('fromRange', this.aF.fromRange.value);
      body.set('toRange', this.aF.toRange.value);
      body.set('meterSerialNo', this.aF.meterSerialNo.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addSmartMeters', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
           
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                if(this.addAuthMessage.includes("Not Added") || this.addAuthMessage.includes("Already")
                || this.addAuthMessage.includes("than")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                4000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}

