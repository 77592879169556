import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configure-dcu',
  templateUrl: './configure-dcu.component.html',
  styleUrls: ['./configure-dcu.component.scss']
})
export class ConfigureDCUComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  reportForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    private ngmodalService: NgbModal,
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
  }

}
