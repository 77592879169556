import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { ModalService } from '../_services/modal.service';
import { take } from 'rxjs/operators';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-rechargemob',
  templateUrl: './rechargemob.component.html',
  styleUrls: ['./rechargemob.component.scss']
})
export class RechargemobComponent implements OnInit {
  messageResult: any;
  thumb: boolean = false;
  isLoading=false;
  faSpinner = faSpinner;
  rechargeForm: UntypedFormGroup;
  merchantIdData = '';
  userRecharges = {
    userInfo: {
      mobilenumber: '',
      username: '',
      email: '',
      uid: '',
      meterUniqueId: '',
      merchantId: '',
      subdivId: ''
    },
    userPlans: {
      dataValue: ''
    },
    userHistory: [
      { 'amount': '0', 'date': '00-00-0000' }
    ],
    subName: {

    },
  }
  currentUserPlan: any;
  /*currentUserPlan:[
    {
     '100',
    '200'
    }
  ];*/
  subTotal: any;
  rechargeHistory: any;
  currentUserAuth: string = '';
  typeData: string = '';
  responseData: any;
  responseMessage: string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  defaultRechargeType="0";
  payuform: any = {
    rechargeType: '0',
    mobilenumber: '',
    username: '',
    amount: 1,
    email: '',
    uid: '',
    meterUniqueId: '',
    subName: '',
    productInfo: '',
    txnid: '',
    surl: 'http://localhost:4200/successurl',
    furl: 'http://localhost:4200/failedurl',
    key: '',
    hash: '',
    service_provider: 'payu_paisa'

  };
  disablePaymentButton: boolean = true;
  sitePath = '';
  constructor(
    private router: Router,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.sitePath = this.genReqs.apiPath();
  }

  ngOnInit() {
    /*this.payuform = this.formBuilder.group({
      amount: ['',  [Validators.max(100), Validators.min(0)]]
    });*/

    this.route.queryParams.subscribe(params => {
      this.currentUserAuth = params['currentuser'];
      this.typeData = params['typeData'];
    });

    this.getRechargeUserInfo();
    this.payuform.firstname = this.userRecharges.userInfo.username;
    this.payuform.phone = this.userRecharges.userInfo.mobilenumber;
    this.payuform.email = this.userRecharges.userInfo.email;
    this.payuform.productinfo = 'Meter Recharge';
    if (this.typeData == '1') {
      this.payuform.surl = this.sitePath + '/success';
      this.payuform.furl = this.sitePath + '/failed';
    } else {
      this.payuform.surl = this.sitePath + '/success';
      this.payuform.furl = this.sitePath + '/failed';
    }
    // console.log("payuform,",this.payuform.surl," ",this.payuform.furl);
    //this.payuform.surl =  'http://localhost:4200/#/successRecharge';
    //this.payuform.furl = 'http://localhost:4200/#/failedRecharge';

    this.payuform.udf1 = this.userRecharges.userInfo.uid;
    this.payuform.udf2 = this.userRecharges.userInfo.meterUniqueId;
    this.payuform.udf3 = this.userRecharges.subName;
    this.payuform.udf4 = this.userRecharges.userInfo.merchantId;
    this.payuform.udf5 = this.userRecharges.userInfo.subdivId;
    /*this.payuform.udf6 = this.currentUserAuth.substring(255,510);
    this.payuform.udf7 = this.currentUserAuth.substring(510,765);*/


    this.currentUserPlan = this.userRecharges.userPlans;
    this.rechargeHistory = this.userRecharges.userHistory;
    // console.log("data,",this.userRecharges);
  }

  get rf() { return this.rechargeForm.controls; }

  setAmount(amount: any = 0) {
    this.payuform.amount = amount;
    // console.log("hello", this.payuform.amount);
  }

  changeRechargeType(defaultRechargeType) {
    this.defaultRechargeType = defaultRechargeType;
  }


  confirmPayment() {
    if (!(this.payuform.amount > 0 && this.payuform.amount < 99999)) {
      alert("Recharge Amount Must be greator than 0 and less than 99999 !");
      return false;
    }
    this.isLoading=true;
    let body = new URLSearchParams();
    body.set('authToken', this.currentUserAuth);
    body.set('email', this.payuform.email);
    body.set('firstname', this.payuform.firstname);
    body.set('phone', this.payuform.phone);
    body.set('productinfo', this.payuform.productinfo);
    body.set('amount', this.payuform.amount);
    body.set('rechargeType', this.defaultRechargeType);

    /*body.set('udf7', this.payuform.udf7);
    body.set('udf6', this.payuform.udf6);*/
    body.set('udf5', this.payuform.udf5);
    body.set('udf4', this.payuform.udf4);
    body.set('udf3', this.payuform.udf3);
    body.set('udf2', this.payuform.udf2);
    body.set('udf1', this.payuform.udf1);
    this.genReqs.postReq('/confirmRechargeDetail', body).subscribe((result) => {
      this.responseData = result;
      this.isLoading=false;
      if (this.responseData.success) {
        let rDetails = this.responseData.data;
        this.payuform.txnid = rDetails.txnid;
        this.payuform.key = rDetails.key;
        this.payuform.hash = rDetails.hash;
        //this.payuform.productinfo = this.escapeHtml(rDetails.product_Info);
        this.payuform.productinfo = rDetails.product_Info;
        this.disablePaymentButton = false;
        this.responseMessage = 'Details verified. Please proceed for recharge!';
        this.thumb = true;
      }else{
        this.thumb = false;
        this.responseMessage = 'Recharge Command is already in queue. Please try after some time!';
      }
    }, (err) => {
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  getRechargeUserInfo() {
    let body = new URLSearchParams();
    body.set('currentuser', this.currentUserAuth);
    this.genReqs.postReq('/getrechargeinfo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.userRecharges = this.responseData.data;
        //console.log(this.userRecharges);
        this.payuform.firstname = this.userRecharges.userInfo.username;
        this.payuform.phone = this.userRecharges.userInfo.mobilenumber;
        this.payuform.email = this.userRecharges.userInfo.email;
        this.payuform.udf1 = this.userRecharges.userInfo.uid;
        this.payuform.udf2 = this.userRecharges.userInfo.meterUniqueId;
        this.payuform.udf3 = this.userRecharges.subName;
        this.payuform.udf4 = this.userRecharges.userInfo.merchantId;
        this.payuform.udf5 = this.userRecharges.userInfo.subdivId;
        /*this.payuform.udf6 = this.currentUserAuth.substring(255,510);
        this.payuform.udf7 = this.currentUserAuth.substring(510,765);*/
        this.currentUserPlan = this.userRecharges.userPlans;
        this.rechargeHistory = this.userRecharges.userHistory;
      }
    }, (err) => {
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  changeValue(value: string): void {
    this.subTotal += value;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  escapeHtml(text) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, function (m) { return map[m]; });
  }


}
