<app-topbar></app-topbar>
<div class="container inner_wraper" scroller>
  <div class="row">
    <div class="col-sm-10 mx-auto newClass" data-aos="fade-right" data-aos-delay="100">
      <div class="modal-content-new">
        <div class="">
          <h1 class="h2 bigav"> Privacy Policy of Avon Meters Pvt. Ltd. </h1>
          <h2 class="h3">Website Privacy Policy</h2>
          <!--<img src="images/at.net.crp.jpg" align="right">-->
          <p class="p"><b>Avon Meters Pvt. Ltd. </b> website privacy policy explains data collection, its Uses,
            disclosure and information's safeguard for users who uses our Website.
            We cares and respects the privacy of our users. </p>
          <p class="p"> Policy should be read carefully before the use of website. We request you not to access the
            web, if you don’t agree with the policy terms.
            Avon Meters has reserve the right to make changes in the Policy for any reason and at any
            point of time. You are encouraged to periodically review the Policy.
          </p>
          <h2 class="h3">User's Information Collection - </h2>
          <p class="p">We may collect your information in a variety of ways. Information we may collect via
            the web depends on the content and materials you use includes:
          </p>
          <h3 class="h4">Personal Data -</h3>
          <p class="p">Demographic and other personally identifiable information (such as name, contact number and email
            address etc.)
          </p>

          <h3 class="h4">Derivative Data -</h3>
          <p class="p">Information our servers automatically collect when you access the web
          </p>

          <h3 class="h4">Financial Data -</h3>
          <p class="p">Data related to your payment method (e.g. valid credit card number, card brand, expiration date)
            that we may collect when you purchase, order, return, exchange, or request information about our services
            from web. We store only very limited, if any, financial information that we collect. Otherwise, all
            financial information is/might be stored by our payment gateway partners
          </p>

          <h3 class="h4">Geo-Location Data -</h3>
          <p class="p">To provide location based services, we may request access or permission to track location-based
            information
          </p>

          <h3 class="h4">Device Access -</h3>
          <p class="p">Your system may be requested to access or permission. device information such as MAC ID number,
            make, model, operating system, country, location, and any other data may also be accessed.

          </p>
          <h3 class="h4"> Push Notifications – </h3>
          <p class="p">Regarding your account or Application, you may be requested to send push notifications.
          </p>
          <h3 class="h4"> Data From Contests, Giveaways, and Surveys - </h3>
          <p class="p">Personal and other information you may provide when entering contests or giveaways and/or
            responding to enquiries.
          </p>
          <h3 class="h4"> Third-Party Data - </h3>
          <p class="p">If you connect your account to the third party and grant the Application permission to access
            this information.
          </p>

          <!--<img src="images/Corp/Sales-Net.jpg" align="center" >-->
          <!-- <h2 class="h3"> Collected information Uses :- </h2><p class="p">
                    Your collected information may be used to - 
                  </p> -->
          <!-- <ul class="ul">
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>C</b>reate and manage your account.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>E</b>mail you regarding your account or order.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>S</b>end you a newsletter.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P</b>rocess payments and refunds.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>I</b>ncrease the efficiency and operation.</li> 
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>D</b>erive analytics from your load profile.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R</b>esolve disputes and troubleshoot problems.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R</b>espond to product and customer service requests.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>A</b>dminister sweepstakes, promotions, and contests.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>C</b>ompile anonymous statistical data and analysis.</li> 
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>D</b>elivering you advertisements, coupons, newsletters, and other promotional information.</li> 
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>F</b>ulfill and manage purchases, orders, payments, and other transactions.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>T</b>o Generate your personal profile for application.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>M</b>onitor and analyze usage and trends to improve your experience.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>N</b>otify you for updates.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>O</b>ffer you new products, services, and/or other recommendations.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P</b>erform other business activities as needed.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P</b>revent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                    <li><b><mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R</b>equest feedback
                </ul> -->
          <h3 class="h4">Information Disclosure – </h3>
          <p class="p"><b>Avon Meters Pvt. Ltd. </b> may share/disclose your collected information in certain situations
            :
          </p>
          <h3 class="h4">By Law or to Protect Rights - </h3>
          <p class="p">If release of your information is necessary to respond to legal process, to investigate or remedy
            potential violations of our policies, or to protect the rights, property, and safety of others, we may share
            your information as permitted or required by any applicable law, rule, or regulation. This includes
            exchanging information with other entities for fraud protection and credit risk reduction.
          </p>
          <h3 class="h4">Third-Party Service Providers - </h3>
          <p class="p">We may share your information with third parties that perform services for us or on our behalf,
            including payment processing, data analysis, email delivery, hosting services, customer service, and
            marketing assistance.
          </p>
          <h3 class="h4">Marketing Communications - </h3>
          <p class="p">With your consent, or with an opportunity for you to withdraw consent, we may share your
            information with third parties for marketing purposes, as permitted by law.
          </p>
          <h3 class="h4">Third-Party Advertisers - </h3>
          <p class="p">We may use third-party advertising companies to serve ads when you visit the Web. These companies
            may use information about your visits to the web and other websites that are contained in web cookies in
            order to provide advertisements about goods and services of interest to you.
          </p>
          <h3 class="h4">Affiliates - </h3>
          <p class="p">We may share your information with our affiliates, in which case we will require those affiliates
            to honor this Privacy Policy. Affiliates include any subsidiaries, joint venture partners or other companies
            that we control or that are under common control with us.
          </p>
          <h3 class="h4">Business Partners - </h3>
          <p class="p">We may share your information with our business partners to offer you certain products, services
            or promotions.
          </p>
          <h3 class="h4">Other Third Parties - </h3>
          <p class="p">We may share your information with advertisers and investors for the purpose of conducting
            general business analysis. We may also share your information with such third parties for marketing
            purposes, as permitted by law.
          </p>
          <h3 class="h4">Sale or Bankruptcy - </h3>
          <p class="p">If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by
            another entity, we may transfer your information to the successor entity. If we go out of business or enter
            bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge
            that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy
            Policy.
          </p>
          <p class="p">We are not responsible for the actions of third parties with whom you share personal or sensitive
            data, and we have no authority to manage or control third-party solicitations. If you no longer wish to
            receive correspondence, emails or other communications from third parties, you are responsible for
            contacting the third party directly.
          </p>
          <!-- <h2 class="h3">Technology Tracking</h2> -->
          <!-- 
          <h3 class="h4">Cookies and Web Beacon - </h3>
          <p class="p">We may use cookies, web beacons, tracking pixels, and other tracking technologies on the web to
            help customize the web and improve your experience.
          </p>
          <h3 class="h4">Internet-Based Advertising - </h3>
          <p class="p">We may use third-party software to serve ads on the web, implement email marketing campaigns, and
            manage other interactive marketing initiatives. This third-party software may use cookies or similar
            tracking technology to help manage and optimize your online experience with us.
          </p>
          <h3 class="h4">Third Party Services - </h3>
          <p class="p">Website may contain links to third-party applications of interest, including advertisements and
            external services, that are not affiliated with us. Once you have used these links to leave the web, any
            information you provide to these third parties is not covered by this Privacy Policy, and we cannot
            guarantee the safety and privacy of your information. Before visiting and providing any information to any
            third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third
            party responsible for that website, and should take those steps necessary to, in your discretion, protect
            the privacy of your information. We are not responsible for the content or privacy and security practices
            and policies of any third parties, including other sites, services or website that may be linked to or from
            the website.
          </p>
          <h3 class="h4">Information Security - </h3>
          <p class="p">We use administrative, technical, and physical security measures to help protect your personal
            information. While we have taken reasonable steps to secure the personal information you provide to us,
            please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of
            data transmission can be guaranteed against any interception or other type of misuse. Any information
            disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot
            guarantee complete security if you provide personal information.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</div>