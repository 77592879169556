<div class="container-fluid innerpage_top page-content-wrapperr">
    <div class="row page-content-wrapper-innerr">
        <div class=" col-md-12 content-viewportt">
            <div class="page-content-wrapper-inner outerbox_shadow">
                <div class="row">
                    <div class="col-md-12 col-sm-12 boxflexEl">
                        <h1>Replaced Meter Details
                            <span class="info_icntop ml-1">
                                <i data-toggle="tooltip" data-placement="top"
                                    title="Information of All Replaced Meter Details"
                                    class="fa fa-info tabula-tooltip "></i>
                            </span>
                        </h1>

                        <div class="">
                            <div class="boxflexEl">
                                <div class="d-none d-lg-block">
                                    <div class="innerboxxx col-md-12">
                                        <div class="searchflex">
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Circle">
                                                <mat-select class="search-txt" matNativeControl
                                                    (selectionChange)="selectChangeCircle(defaultCircle)"
                                                    [(ngModel)]="defaultCircle">
                                                    <mat-option [value]="0">All Circle</mat-option>
                                                    <mat-option *ngFor="let dg of circleData" [value]="dg.id">
                                                        {{dg.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search">
                                                <mat-select class="search-txt" matNativeControll
                                                    (selectionChange)="selectChangeDivision(defaultDivision)"
                                                    [(ngModel)]="defaultDivision">
                                                    <mat-option [value]="0">All Division</mat-option>
                                                    <mat-option *ngFor="let td of divisionData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>

                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Authority">
                                                <mat-select class="search-txt" matNativeControl
                                                    (selectionChange)="selectChangeAuth(defaultAuth)"
                                                    [(ngModel)]="defaultAuth">
                                                    <mat-option [value]="0">All Authority</mat-option>
                                                    <mat-option *ngFor="let td of authData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>

                                            <div class="search">
                                                <div class="dcuFilter" floatLabel="never">
                                                    <input class="search-txt searchplaceholder" type="text"
                                                        placeholder="Search by Date (yyyy-mm-dd)"
                                                        value="{{levelSearch}}"
                                                        (input)="onLevelSearchChange($event.target.value)"
                                                        autocomplete="off">
                                                    <a class="search-btn">
                                                        <i class="fa fa-search"></i>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="innerboxx col-md-12 ddd">
                                <div class="container-fluidd d-block  d-lg-none">
                                    <div class="">
                                        <div class="outerbox_shadoww grid_searchsm">
                                            <div id="accordion" class="accordion">
                                                <div class="card mb-1">
                                                    <div class="card-header collapsed" data-toggle="collapse"
                                                        data-parent="#accordion" href="#collapseThree">
                                                        <a class="card-title">
                                                            <span class="headngins">Search</span>
                                                        </a>
                                                    </div>
                                                    <div id="collapseThree" class="collapse" data-parent="#accordion">
                                                        <div class="card-bodyy">
                                                            <div class="innerboxxx col-md-12">
                                                                <div class="searchflex">
                                                                    <div class="search"
                                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Circle">
                                                                        <mat-select class="search-txt" matNativeControl
                                                                            (selectionChange)="selectChangeCircle(defaultCircle)"
                                                                            [(ngModel)]="defaultCircle">
                                                                            <mat-option [value]="0">All Circle
                                                                            </mat-option>
                                                                            <mat-option *ngFor="let dg of circleData"
                                                                                [value]="dg.id">
                                                                                {{dg.name}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                        <a class="search-btn">
                                                                            <i class="fa fa-search"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="search">
                                                                        <mat-select class="search-txt" matNativeControll
                                                                            (selectionChange)="selectChangeDivision(defaultDivision)"
                                                                            [(ngModel)]="defaultDivision">
                                                                            <mat-option [value]="0">All Division
                                                                            </mat-option>
                                                                            <mat-option *ngFor="let td of divisionData"
                                                                                [value]="td.id">
                                                                                {{td.name}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                        <a class="search-btn">
                                                                            <i class="fa fa-search"></i>
                                                                        </a>
                                                                    </div>

                                                                    <div class="search"
                                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Authority">
                                                                        <mat-select class="search-txt" matNativeControl
                                                                            (selectionChange)="selectChangeAuth(defaultAuth)"
                                                                            [(ngModel)]="defaultAuth">
                                                                            <mat-option [value]="0">All Authority
                                                                            </mat-option>
                                                                            <mat-option *ngFor="let td of authData"
                                                                                [value]="td.id">
                                                                                {{td.name}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                        <a class="search-btn">
                                                                            <i class="fa fa-search"></i>
                                                                        </a>
                                                                    </div>

                                                                    <div class="search">
                                                                        <div class="dcuFilter" floatLabel="never">
                                                                            <input class="search-txt searchplaceholder"
                                                                                type="text"
                                                                                placeholder="Search by Date (yyyy-mm-dd)"
                                                                                value="{{levelSearch}}"
                                                                                (input)="onLevelSearchChange($event.target.value)"
                                                                                autocomplete="off">
                                                                            <a class="search-btn">
                                                                                <i class="fa fa-search"></i>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid inner-page-content">
                    <div class="roww col-12">
                        <ng-container class="col-sm-12" *ngIf="loading">
                            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                        </ng-container>
                        <div *ngIf="length!=0 && !loading">
                            <table id="ExampleMaterialTable" matSort mat-table [dataSource]="dataSource"
                                class="mt-2 mat-elevation-z8" *ngIf="dataSource.length > 0">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let row">
                                        <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                                            <mat-icon class="d-c10">menu_open</mat-icon>
                                        </button>
                                        <mat-menu #dcuListMenu="matMenu" xPosition="before"
                                            class="notifications-dropdown">
                                            <mat-list role="list">
                                                <mat-list-item role="listitem"
                                                    *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_Meter_Image_Button">
                                                    <button type="button" mat-button
                                                        (click)="downloadOldMeterImage(row)"
                                                        class="btn-block text-left">
                                                        <mat-icon class="d-c13">add_a_photo</mat-icon> Download
                                                        Old Meter Image
                                                    </button>
                                                </mat-list-item>
                                                <mat-list-item role="listitem"
                                                    *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_New_Meter_Image_Button">
                                                    <button type="button" mat-button
                                                        (click)="downloadNewMeterImage(row)"
                                                        class="btn-block text-left">
                                                        <mat-icon class="d-c5">add_a_photo</mat-icon> Download
                                                        New Meter Image
                                                    </button>
                                                </mat-list-item>
                                            </mat-list>
                                        </mat-menu>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="srNo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headerData">Sr. No.
                                    </th>

                                    <td mat-cell *matCellDef="let element; let j = index;" style="text-align: center;">
                                        {{ element.srNo }}
                                        <!--<div *ngIf="element.conCircle!='Total Meters'">{{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }}</div>-->
                                    </td>

                                </ng-container>
                                <!-- meterCmdCode Column -->
                                <ng-container matColumnDef="conCircle">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Circle
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder">
                                        <div *ngIf="element.conCircle=='Total Meters'"><b>{{element.conCircle}} </b>
                                        </div>
                                        <div *ngIf="element.conCircle!='Total Meters'">{{element.conCircle}}</div>
                                    </td>
                                </ng-container>
                                <!-- meterCmdName Column -->
                                <ng-container matColumnDef="conDivision">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Division </th>
                                    <td mat-cell *matCellDef="let element" class="bolder">
                                        {{element.conDivision}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="conDC">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData"> DC
                                        Name </th>
                                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.conDC}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="conFeeder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Feeder
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.conFeeder}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="conGr">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData"> GR
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.conGr}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="conRd">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData"> RD
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.conRd}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="totalMeterCount">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Total
                                        Meter Installed</th>
                                    <td mat-cell *matCellDef="let element" class="bolder">

                                        <div *ngIf="element.conCircle=='Total Meters'"><b>{{element.totalMeterCount}}
                                            </b></div>
                                        <div *ngIf="element.conCircle!='Total Meters'">{{element.totalMeterCount}}</div>

                                    </td>
                                </ng-container>
                                <!-- updationDateTime Column -->
                                <ng-container matColumnDef="Server_date_Time">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Server
                                        Date & Time
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder">
                                        {{element.Server_date_Time}} </td>
                                </ng-container>
                                <ng-container matColumnDef="authName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder headerData">
                                        Authority Name
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="bolder">
                                        {{element.authName}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay" class="tableHeader ">
                                </tr>
                                <tr class="tableColumns" mat-row
                                    *matRowDef="let element; columns: levelColumnsToDisplay;">
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="!loading">
                            <div *ngIf="length==0">
                                <div>
                                    <img class="center" src="assets/images/Nodata.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

            <mat-card-footer>
                <div class="row">
                    <div class="col-11">
                        <!--<mat-paginator #meterpaginator [pageSize]="limit"
                        [pageSizeOptions]="getPageSizeOptions()" [length]="length"
                        (page)="changeMeterPage($event)" showFirstLastButtons>
                    </mat-paginator>-->
                    </div>
                    <div class="col-1 text-left1">
                        <div *ngIf="length!=0">
                            <button style="border:0;background: azure;" title="Export To Excel"
                                (click)="exportTable()"><i class="fa fa-file-excel-o"
                                    style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
                        </div>
                    </div>
                </div>
            </mat-card-footer>

        </div>
    </div>


    <div class="page-content-wrapper" style="margin-top:0;" style="display:none;">
        <div class="page-content-wrapper-inner">
            <div class="content-viewport">
                <!--<div class="row py-3 headerArea commonClass h-180">
          <div class="col-12">
            <h4>Meter Commands</h4>-->
                <!--<p class="text-white">Welcome, {{currentUser.data.userData.name | titlecase}}</p>-->
                <!--</div>
        </div>-->
                <div class="row inner-page-content of-auto">

                    <ng-container>
                        <div class="col-12 plr_c25px xs-no-p">

                            <mat-card class="-mt-20">
                                <mat-card-header class="h-16 xs-c-h">
                                    <div class="col-sm-12 row">
                                        <div class="title hidden-sm xs-dn col-sm-12">
                                            <mat-icon matPrefix class="d-c12">dns</mat-icon><b>Replaced Meter
                                                Details</b>
                                        </div>
                                    </div>
                                </mat-card-header>
                                <mat-card-header class="h-16 xs-c-h">
                                    <div class="col-sm-12 row">
                                        <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-2"
                                            *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Circle">
                                            <mat-form-field style="width: 122%;margin-left: -13px;">
                                                <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                                <mat-select matNativeControl
                                                    (selectionChange)="selectChangeCircle(defaultCircle)"
                                                    [(ngModel)]="defaultCircle">
                                                    <mat-option [value]="0">All Circle</mat-option>
                                                    <mat-option *ngFor="let dg of circleData" [value]="dg.id">
                                                        {{dg.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div
                                            class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125  col-sm-2">
                                            <mat-form-field style="width: 115%;margin-left: -13px;">
                                                <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                                <mat-select matNativeControl
                                                    (selectionChange)="selectChangeDivision(defaultDivision)"
                                                    [(ngModel)]="defaultDivision">
                                                    <mat-option [value]="0">All Division</mat-option>
                                                    <mat-option *ngFor="let td of divisionData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!--<div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-1" >
                                        <mat-form-field style="width: 115%;margin-left: -13px;">
                                            <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                            <mat-select matNativeControl (selectionChange)="selectChangeDC(defaultDC)"
                                                [(ngModel)]="defaultDC">
                                                <mat-option [value]="0">All DC</mat-option>
                                                <mat-option *ngFor="let td of dcData" [value]="td.id">
                                                    {{td.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-1" >
                                        <mat-form-field style="width: 115%;margin-left: -13px;">
                                            <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                            <mat-select matNativeControl
                                                (selectionChange)="selectChangeFeeder(defaultFeeder)"
                                                [(ngModel)]="defaultFeeder">
                                                <mat-option [value]="0">All Feeder</mat-option>
                                                <mat-option *ngFor="let td of feederData" [value]="td.id">
                                                    {{td.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-1" >
                                        <mat-form-field style="width: 115%;margin-left: -13px;">
                                            <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                            <mat-select matNativeControl (selectionChange)="selectChangeGr(defaultGr)"
                                                [(ngModel)]="defaultGr">
                                                <mat-option [value]="0">All GR</mat-option>
                                                <mat-option *ngFor="let td of grData" [value]="td.id">
                                                    {{td.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>-->
                                        <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-sm-2"
                                            *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Authority">
                                            <mat-form-field style="width: 115%;margin-left: -13px;">
                                                <mat-icon matPrefix class="d-c9 fs-30">search</mat-icon>
                                                <mat-select matNativeControl
                                                    (selectionChange)="selectChangeAuth(defaultAuth)"
                                                    [(ngModel)]="defaultAuth">
                                                    <mat-option [value]="0">All Authority</mat-option>
                                                    <mat-option *ngFor="let td of authData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125 col-sm-3">
                                            <div>
                                                <mat-form-field class="dcuFilter" floatLabel="never">
                                                    <span matSuffix>
                                                        <mat-icon matPrefix class="d-c9">search</mat-icon>
                                                    </span>
                                                    <input type="text" matInput
                                                        placeholder="Search by Date (yyyy-mm-dd)"
                                                        value="{{levelSearch}}"
                                                        (input)="onLevelSearchChange($event.target.value)"
                                                        autocomplete="off">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="toolbar-space"></span>
                                    <!--<div col-3 col-xs-6>
                                    <div *ngIf="length!=0">
                                        <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button
                                            mat-button-base mat-primary style="color: #000;" class="npMi">
                                            <mat-icon class="d-c10">filter_list</mat-icon>
                                        </button>
                                        <mat-menu #dcuListMenu="matMenu" xPosition="before"
                                            class="notifications-dropdown">
                                            <mat-list role="list" *ngFor="let item of levelHeadsCols">
                                                <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                                                    <mat-checkbox [checked]="item.checked"
                                                        (change)="updateLevelChange($event, i, item)">
                                                        {{item.label}}</mat-checkbox>
                                                </mat-list-item>
                                            </mat-list>
                                        </mat-menu>
                                    </div>
                                </div>-->
                                    <div col-3 col-xs-1>
                                        <!--<button type="button" mat-mini-fab class="d-c4" (click)="addmetercommand()"
                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Add_Meter_Command_Button">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                    <button mat-flat-button (click)="whichSubDomain()" class="cPbg"
                                        *ngIf="enableSocietyChange">
                                        <mat-icon>reply</mat-icon>
                                    </button>-->
                                    </div>
                                </mat-card-header>
                                <mat-card-content>
                                    <ng-container class="col-sm-12" *ngIf="loading">
                                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                        </fa-icon>
                                    </ng-container>
                                    <div *ngIf="length!=0 && !loading">
                                        <table id="ExampleMaterialTable" matSort mat-table [dataSource]="dataSource"
                                            class="mat-elevation-z8" *ngIf="dataSource.length > 0">

                                            <!-- Checkbox Column -->
                                            <ng-container matColumnDef="edit">
                                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                                <td mat-cell *matCellDef="let row">
                                                    <button type="button" class="icon"
                                                        [matMenuTriggerFor]="dcuListMenu">
                                                        <mat-icon class="d-c10">menu_open</mat-icon>
                                                    </button>
                                                    <mat-menu #dcuListMenu="matMenu" xPosition="before"
                                                        class="notifications-dropdown">
                                                        <mat-list role="list">
                                                            <mat-list-item role="listitem"
                                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Old_Meter_Image_Button">
                                                                <button type="button" mat-button
                                                                    (click)="downloadOldMeterImage(row)"
                                                                    class="btn-block text-left">
                                                                    <mat-icon class="d-c13">add_a_photo</mat-icon>
                                                                    Download
                                                                    Old Meter Image
                                                                </button>
                                                            </mat-list-item>
                                                            <mat-list-item role="listitem"
                                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_New_Meter_Image_Button">
                                                                <button type="button" mat-button
                                                                    (click)="downloadNewMeterImage(row)"
                                                                    class="btn-block text-left">
                                                                    <mat-icon class="d-c5">add_a_photo</mat-icon>
                                                                    Download
                                                                    New Meter Image
                                                                </button>
                                                            </mat-list-item>
                                                        </mat-list>
                                                    </mat-menu>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="srNo">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="headerData">Sr. No.</th>

                                                <td mat-cell *matCellDef="let element; let j = index;"
                                                    style="text-align: center;">
                                                    {{ element.srNo }}
                                                    <!--<div *ngIf="element.conCircle!='Total Meters'">{{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }}</div>-->
                                                </td>

                                            </ng-container>
                                            <!-- meterCmdCode Column -->
                                            <ng-container matColumnDef="conCircle">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData">Circle
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder">
                                                    <div *ngIf="element.conCircle=='Total Meters'">
                                                        <b>{{element.conCircle}} </b></div>
                                                    <div *ngIf="element.conCircle!='Total Meters'">{{element.conCircle}}
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <!-- meterCmdName Column -->
                                            <ng-container matColumnDef="conDivision">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData">
                                                    Division </th>
                                                <td mat-cell *matCellDef="let element" class="bolder">
                                                    {{element.conDivision}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="conDC">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData"> DC
                                                    Name </th>
                                                <td mat-cell *matCellDef="let element" class="bolder"> {{element.conDC}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="conFeeder">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData">Feeder
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder">
                                                    {{element.conFeeder}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="conGr">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData"> GR
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder"> {{element.conGr}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="conRd">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData"> RD
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder"> {{element.conRd}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="totalMeterCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData"> Total
                                                    Meter Installed</th>
                                                <td mat-cell *matCellDef="let element" class="bolder">

                                                    <div *ngIf="element.conCircle=='Total Meters'">
                                                        <b>{{element.totalMeterCount}} </b></div>
                                                    <div *ngIf="element.conCircle!='Total Meters'">
                                                        {{element.totalMeterCount}}</div>

                                                </td>
                                            </ng-container>
                                            <!-- updationDateTime Column -->
                                            <ng-container matColumnDef="Server_date_Time">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData"> Server
                                                    Date & Time
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder">
                                                    {{element.Server_date_Time}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="authName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="bolder headerData">
                                                    Authority Name
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="bolder">
                                                    {{element.authName}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay"
                                                class="tableHeader ">
                                            </tr>
                                            <tr class="tableColumns" mat-row
                                                *matRowDef="let element; columns: levelColumnsToDisplay;">
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="!loading">
                                        <div *ngIf="length==0">
                                            <div>
                                                <img class="center" src="assets/images/Nodata.png">
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                                <mat-card-footer>
                                    <div class="row">
                                        <div class="col-11">
                                            <!--<mat-paginator #meterpaginator [pageSize]="limit"
                                            [pageSizeOptions]="getPageSizeOptions()" [length]="length"
                                            (page)="changeMeterPage($event)" showFirstLastButtons>
                                        </mat-paginator>-->
                                        </div>
                                        <div class="col-1 text-left1">
                                            <div *ngIf="length!=0">
                                                <button style="border:0;background: azure;" title="Export To Excel"
                                                    (click)="exportTable()"><i class="fa fa-file-excel-o"
                                                        style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-footer>
                            </mat-card>


                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>