import { Component, OnInit, ChangeDetectionStrategy, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-addauthority',
    templateUrl: './addauthority.component.html', changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./addauthority.component.scss']
})

export class AddauthorityComponent implements OnInit {
    faSpinner = faSpinner;
    addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
    authorityDOB = "0000-00-00";

    public authLevelData: any = [];
    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];
    message: string = '';
    authLevelFieldValue: string = '';

    isLoading: boolean = false;
    thumb: boolean = false;
    ///multiselect
    authMulti1: boolean = false;
    authMulti2: boolean = false;
    authMulti3: boolean = false;
    authMulti4: boolean = false;
    authMulti5: boolean = false;
    authMulti6: boolean = false;
    authMulti7: boolean = false;
    authMulti8: boolean = false;
    authLevelField: boolean = false;
    nowDate = new Date();
    submitted: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        public modal: NgbActiveModal,
        private ref: ChangeDetectorRef
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        if (this.currentUser.data.userData.authLevel == '7') {
            this.authLevelField = true;
            this.authLevelFieldValue = '7';
        }
        ref.detach();
        setInterval(() => { this.ref.detectChanges(); }, 300);
    }

    ngOnInit() {
       console.log("Hello",this.currentUser.data.userData.subdivision);
        /*
          form authority add
        */
        this.addAuthorityForm = this.formBuilder.group({
            authLevel: [{ value: this.authLevelFieldValue, disabled: this.authLevelField }, Validators.required],
            //countId: ['', Validators.required],
            //stateId: ['', Validators.required],
            //utilityId: ['', Validators.required],
            //circleId: ['', Validators.required],
            //devisionId: ['', Validators.required],
            //subdivId: ['', Validators.required],
            //dcuId: ['', Validators.required],
            authFullName: ['', Validators.required],
            authUserName: ['', Validators.required],
            authPassword: ['', Validators.required],
            authEmailId: ['', Validators.required],
            authLandlineNo: ['', Validators.required],
            authMobileNo: ['', Validators.required],
            authHomeAddressline1: ['', Validators.required],
            authHomeAddressline2: ['', Validators.required],
            authOfficeAddressline1: ['', Validators.required],
            authOfficeAddressline2: ['', Validators.required],
            authorityActiveStatus: ['', Validators.required],
            authorityDOB: [this.nowDate, Validators.required],
            authoritySMSReceiveStatus: ['', Validators.required],
            dataArray: ['']
        });

        //this.countryLevelData = this.getDynamicList();
        this.countryLevelData = [
            {
                id: '0',
                text: "Select Country"
            }];

        this.authLevelData = [
            {
                id: '0',
                text: 'Select Auth Level'
            },
            {
                id: '1',
                text: 'Auth 1'
            },
            {
                id: '2',
                text: 'Auth 2'
            },
            {
                id: '3',
                text: 'Auth 3'
            },
            {
                id: '4',
                text: 'Auth 4'
            },
            {
                id: '5',
                text: 'Auth 5'
            },
            {
                id: '6',
                text: 'Auth 6'
            },
            {
                id: '7',
                text: 'Auth 7'
            }
        ];
        this.loadCountryData();
        this.defaultState();
        this.defaultUtility();
        this.defaultCircle();
        this.defaultDivision();
        this.defaultSubDivision();
        this.defaultDcu();
    }

    /*ngOnDestroy() {
        this.modal.close('Ok click')
    }*/

    defaultState() {
        this.stateLevelData = [
            {
                id: '0',
                text: "Select State"
            }];
    }
    defaultUtility() {
        this.utilityLevelData = [
            {
                id: '0',
                text: "Select Utility"
            }];
    }

    //default circle
    defaultCircle() {
        this.circleLevelData = [
            {
                id: '0',
                text: "Select Circle"
            }];
    }

    //default division
    defaultDivision() {
        this.divisionLevelData = [
            {
                id: '0',
                text: "Select Division"
            }];
    }

    //default sub division
    defaultSubDivision() {
        this.subdivisionLevelData = [
            {
                id: '0',
                text: "Select Sub-Division"
            }];
    }

    //default DCU
    defaultDcu() {
        this.dcuLevelData = [
            {
                id: '0',
                text: "Select DCU"
            }];
    }

    /*
     add authority
     require authority data
   */
    get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        if (this.addAuthorityForm.invalid) {
              this.submitted = true;
            return;
        }

        this.message = '';
        this.isLoading = true;
        this.AddProcess = true;

        let body = new URLSearchParams();
        if (this.authLevelField) {
            body.set('authLevel', '7');
            body.set('subdivId', this.currentUser.data.userData.subdivision);
        } else {
            body.set('authLevel', this.aD.authLevel.value);
            body.set('subdivId', this.authority.sub_dev_id);
        }
        body.set('authFullName', this.aD.authFullName.value);
        body.set('authUserName', this.aD.authUserName.value);
        body.set('authPassword', this.aD.authPassword.value);
        body.set('authEmailId', this.aD.authEmailId.value);
        body.set('authLandlineNo', this.aD.authLandlineNo.value);
        body.set('authMobileNo', this.aD.authMobileNo.value);
        body.set('authHomeAddressline1', this.aD.authHomeAddressline1.value);
        body.set('authHomeAddressline2', this.aD.authHomeAddressline2.value);
        body.set('authOfficeAddressline1', this.aD.authOfficeAddressline1.value);
        body.set('authOfficeAddressline2', this.aD.authOfficeAddressline2.value);
        body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
        body.set('authoritySMSReceiveStatus', this.aD.authoritySMSReceiveStatus.value);
        body.set('authorityDOB', this.authorityDOB);
        body.set('authToken', this.currentUser.authToken);

        this.AddProcess = true;
        this.genReqs.postReq('/addNewAuth', body).subscribe((result) => {

            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.thumb = false;
            if (this.responseData.success) {
                this.AddProcess = false;
                this.isLoading = false;
                this.thumb = true;
                this.message = this.responseData.data.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.AddProcess = false;
            this.message = 'Unable to process yours request!';
        });
    }


    getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.authorityDOB = eyear + '-' + emonth + '-' + eday;
        }
    }

    getLabelsListing() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getLevelData', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.authLevelData = this.responseData.data;
               // console.log("leveldata", this.authLevelData);

            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    makeAuthDataArray(): string {
        let dataArray = '';
        let str = '';
        if (parseInt(this.aD.authLevel.value) == 1) {
            str = this.aD.countId.value.toString();
            dataArray = str;//.split(',');
        }
        if (parseInt(this.aD.authLevel.value) == 2) {
            str = this.aD.stateId.value.toString();
            dataArray = str;//.split(',');
        }
        if (parseInt(this.aD.authLevel.value) == 3) {
            str = this.aD.utilityId.value.toString();
            dataArray = str;//.split(',');
        }
        if (parseInt(this.aD.authLevel.value) == 3) {
            str = this.aD.circleId.value.toString();
            dataArray = str;//.split(',');
        }
        if (parseInt(this.aD.authLevel.value) == 3) {
            str = this.aD.devisionId.value.toString();
            dataArray = str;//.split(',');
        }
        if (parseInt(this.aD.authLevel.value) == 3) {
            str = this.aD.subdivId.value.toString();
            dataArray = str;//.split(',');
        }
        return dataArray;
    }

    authLevelChanged(e) {
        this.authMulti1 = false;
        this.authMulti2 = false;
        this.authMulti3 = false;
        this.authMulti4 = false;
        this.authMulti5 = false;
        this.authMulti6 = false;
        this.authMulti7 = false;
        this.authMulti8 = false;
        if (e.value == 1) {
            this.authMulti1 = true;
        }
        if (e.value == 2) {
            this.authMulti2 = true;
        }
        if (e.value == 3) {
            this.authMulti3 = true;
        }
        if (e.value == 4) {
            this.authMulti4 = true;
        }
        if (e.value == 5) {
            this.authMulti5 = true;
        }
        if (e.value == 6) {
            this.authMulti6 = true;
        }
        if (e.value == 7) {
            this.authMulti7 = true;
        }
        if (e.value == 8) {
            this.authMulti8 = true;
        }
    }

    loadCountryData() {
        this.genReqs.genReq('/getCountry').then((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.countryLevelData = [...this.countryLevelData,
                    {
                        id: item.country_id,
                        text: item.countName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    countrySelect(e) {
        let body = new URLSearchParams();
        //body.set('country', e.value.toString());
        body.set('country', this.aD.countId.value);
        this.genReqs.postReq('/getState', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.stateLevelData = [...this.stateLevelData,
                    {
                        id: item.state_id,
                        text: item.stateName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    stateSelect(e) {
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        this.genReqs.postReq('/getUtility', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.utilityLevelData = [...this.utilityLevelData,
                    {
                        id: item.utility_id,
                        text: item.utilityName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    utilitySelect(e) {
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        this.genReqs.postReq('/getCircle', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.circleLevelData = [...this.circleLevelData,
                    {
                        id: item.circle_id,
                        text: item.circleName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    circleSelect(e) {
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('circle', this.aD.circleId.value);
        this.genReqs.postReq('/getDivision', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.divisionLevelData = [...this.divisionLevelData,
                    {
                        id: item.division_id,
                        text: item.divisionName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }
    divisionSelect(e) {
        let body = new URLSearchParams();
        body.set('country', this.aD.countId.value);
        body.set('state', this.aD.stateId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('utility', this.aD.utilityId.value);
        body.set('circle', this.aD.circleId.value);
        body.set('division', this.aD.devisionId.value);
        this.genReqs.postReq('/getSubDivision', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.subdivisionLevelData = [...this.subdivisionLevelData,
                    {
                        id: item.sub_dev_id,
                        text: item.sub_dev_name
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    subdivisionSelect() {
        let body = new URLSearchParams();
        body.set('subDivisionId', this.aD.subdivId.value);
        body.set('authToken', this.currentUser.authToken);
        body.set('offSet', '0');
        body.set('limit', '100000');
        this.genReqs.postReq('/getDCU', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success && this.responseData.data.length > 0) {
                for (let item of this.responseData.data) {
                    this.dcuLevelData = [...this.dcuLevelData,
                    {
                        id: item.dcuID,
                        text: item.dcuName
                    }];
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

}
