import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from '../../_services/modal.service';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-noncommunicatemeters',
  templateUrl: './noncommunicatemeters.component.html',
  styleUrls: ['./noncommunicatemeters.component.scss']
})
export class NoncommunicatemetersComponent implements AfterViewInit {


  messageResult: any;
  loading: boolean = false;
  loadingFirstTime: boolean = true;
  enableSocietyChange: boolean = true;
  selectedSubDivision: any;
  currentUser: User;
  responseData: any;
  currentUserSubscription: Subscription;
  dataSource = new MatTableDataSource();
  loadingMessage: any = '';
  levelSearch: string = '';
  faSpinner = faSpinner;
  length = 0;
  //levelHeadsCols =[];
  //levelColumnsToDisplay= [];
  startDate: any;
  meterSearch: string = '';
  levelHeadsCols = [
    { label: "Sr No", name: "srNo", checked: true },
    { label: "Circle", name: "conCircle", checked: true },
    { label: "Division", name: "conDivision", checked: false },
    { label: "DC", name: "conDC", checked: false },
    { label: "Feeder", name: "conFeeder", checked: false },
    { label: "GR", name: "conGr", checked: false },
    { label: "RD", name: "conRd", checked: false },
    { label: "Consumer Name", name: "conConsumerName", checked: false },
    { label: "IVRS", name: "conIVRS", checked: false },
    { label: "Address", name: "conAddress", checked: false },
    { label: "Meter Id", name: "meterId", checked: true },
    { label: "Meter IP Address", name: "meterIpAddress", checked: false },
    { label: "Category", name: "category", checked: true },
    { label: "Meter Install D/T", name: "meterInstallationDate", checked: true },
    { label: "Last Comm D/T", name: "lastComDateTime", checked: true },
    { label: "TD Status", name: "tdStatus", checked: true },
    { label: "Sim Type", name: "simType", checked: true },
    { label: "Location", name: "location", checked: false },
    { label: "Distance", name: "locationDistance", checked: false },
    { label: "Server D/T", name: "Server_date_Time", checked: false },
    { label: "Auth Name", name: "authName", checked: false },
  ];

  levelColumnsToDisplay = [
    'srNo',
    'conCircle',
    'meterId',
    'category',
    'lastComDateTime',
    'tdStatus',
    'simType',
    'location',
    'locationDistance',
    'meterInstallationDate'
  ];

  removeProcess: boolean = false;
  responseMessage: string = '';
  allLevelTotalRecord: number = 0;
  //pagination settings
  limit: number = 20;
  skip: number = 0;
  filter = '';
  totalLength: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 30];
  getThrough: Access;

  defaultShort: number = 0;
  defaultCircle: number = 0;
  defaultDivision: number = 0;
  defaultDC: number = 0;
  defaultFeeder: number = 0;
  defaultGr: number = 0;
  defaultRd: number = 0;
  defaultAuth: number = 0;
  defaultCategory: number = 0;
  defaultDate: number = 0;
  defaultSim: number = 0;
  circleData: any = [{
    'id': 0,
    'name': 'Select Circle'
  }];

  divisionData: any = [{
    'id': 0,
    'name': 'Select Division'
  }];

  dcData: any = [{
    'id': 0,
    'name': 'Select DC'
  }];

  feederData: any = [{
    'id': 0,
    'name': 'Select Feeder'
  }];

  grData: any = [{
    'id': 0,
    'name': 'Select GR'
  }];

  rdData: any = [{
    'id': 0,
    'name': 'Select RD'
  }];

  authData: any = [{
    'id': 0,
    'name': 'Select Authority'
  }];

  category: any = [
    {
      'id': 0,
      'name': 'Select Category'
    }
  ];


  shortBy: any = [
    {
    'id': 0,
    'name': 'Short By'
  },
  {
    'id': 1,
    'name': 'Circle'
  },
  {
    'id': 2,
    'name': 'Meter Id'
  },
  {
    'id': 3,
    'name': 'Category'
  },
  {
    'id': 4,
    'name': 'lastUpdate D/T'
  },
  {
    'id': 5,
    'name': 'TD Status'
  },
  {
    'id': 6,
    'name': 'SIM Type'
  },
  {
    'id': 8,
    'name': 'Distence'
  },
  {
    'id': 9,
    'name': 'Meter Install D/T'
  }
  ];

  dateData: any = [{
    'id': 0,
    'name': 'Select Date',
  }];

  simData: any = [{
    'id': 0,
    'name': 'Select Sim',
  }];

  constructor(
    public modal: NgbActiveModal,
    private modalService: ModalService,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    if (this.currentUser.data.userData.authLevel == '7') {
      this.enableSocietyChange = false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild('paginator', { static: false }) paginator: MatPaginator;
  ngAfterViewInit() {
    this.getCircleData();
    this.getMeterCommandListing();
    this.selectedSubDivision = this.currentUser.data.userData.subdivision;
  }
  /*ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    //this.dataSource.paginator = this.paginator;
  }*/



  onLevelSearchChange(search) {
    this.levelSearch = search;

    this.skip = 0;
    this.getMeterCommand(false);
  }

  getDateFromRange(pickedDate = new Date()) {
    if (pickedDate) {
      console.log(pickedDate);
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.startDate = eyear + '-' + emonth + '-' + eday;
    }
    this.getMeterCommand(true);
  }

  getMeterCommand(addNew: boolean = false) {
    let body = new URLSearchParams();
    this.loading = true;
    if (this.enableSocietyChange) {
      body.set('meterSubDivision', this.selectedSubDivision);
    } else {
      body.set('meterSubDivision', this.currentUser.data.userData.subdivision);
    }
    body.set('utility', 'A');
    body.set('defaultShort', this.defaultShort.toString());
    body.set('defaultCircle', this.defaultCircle.toString());
    body.set('defaultDivision', this.defaultDivision.toString());
    body.set('defaultDC', this.defaultDC.toString());
    body.set('defaultFeeder', this.defaultFeeder.toString());
    body.set('defaultGr', this.defaultGr.toString());
    body.set('defaultRd', this.defaultRd.toString());
    body.set('defaultAuth', this.defaultAuth.toString());
    body.set('defaultCategory', this.defaultCategory.toString());
    body.set('date', this.defaultDate.toString());
    body.set('sim', this.defaultSim.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNonCommunicatedMeter', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;

      if (this.responseData.success) {
        //console.log(this.responseData.data);
        this.dataSource = this.responseData.data;
        this.dataSource.sort = this.sort;
        this.length = this.responseData.datalimit.totalResult;
        //if (this.length < 10) {
        this.limit = this.length;
        //}

      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  getDivisionId(addNew: boolean = false, circleId: number) {
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('circleId', circleId.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDivisionData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        //console.log(this.responseData.data);
        this.divisionData = this.responseData.data.divisionData;
        this.authData = this.responseData.data.authData;
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable", "Meter Commands Data");
  }

  getMeterCommandListing() {
    this.loading = true;
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('meterSubDivision', this.selectedSubDivision);
    } else {
      body.set('meterSubDivision', this.currentUser.data.userData.subdivision);
    }
    body.set('utility', 'A');
    body.set('defaultShort', this.defaultShort.toString());
    body.set('defaultCircle', this.defaultCircle.toString());
    body.set('defaultDivision', this.defaultDivision.toString());
    body.set('defaultDC', this.defaultDC.toString());
    body.set('defaultFeeder', this.defaultFeeder.toString());
    body.set('defaultGr', this.defaultGr.toString());
    body.set('defaultRd', this.defaultRd.toString());
    body.set('defaultAuth', this.defaultAuth.toString());
    body.set('defaultCategory', this.defaultCategory.toString());
    body.set('date', this.defaultDate.toString());
    body.set('sim', this.defaultSim.toString());
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNonCommunicatedMeter', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      this.loadingFirstTime = false;

      //console.log(this.responseData);
      if (this.responseData.success) {
        this.dataSource = this.responseData.data;
        this.dataSource.sort = this.sort;
        this.length = this.responseData.datalimit.totalResult;
        //if (this.length < 10) {
        this.limit = this.length;
        //}

      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    }
  }

  selectChangeCircle(defaultCircle) {
    this.defaultCircle = defaultCircle;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getMeterCommand(true);
  }

  selectChangeShort(defaultShort) {
    this.defaultShort = defaultShort;
    this.skip = 0;
    //console.log("Default Circle",this.defaultCircle);
    this.getMeterCommand(true);
  }

  onMeterSearchChange(search) {
    this.filter = search;
    this.skip = 0;
    this.getMeterCommand(true);
  }



  selectChangeDC(defaultDC) {
    this.defaultDC = defaultDC;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeFeeder(defaultFeeder) {
    this.defaultFeeder = defaultFeeder;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeGr(defaultGr) {
    this.defaultGr = defaultGr;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeRd(defaultRd) {
    this.defaultRd = defaultRd;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeAuth(defaultAuth) {
    this.defaultAuth = defaultAuth;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeDate(defaultDate) {
    this.defaultDate = defaultDate;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeSim(defaultSim) {
    this.defaultSim = defaultSim;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  selectChangeCategory(defaultCategory) {
    this.defaultCategory = defaultCategory;
    this.skip = 0;
    this.getMeterCommand(true);
  }

  getCircleData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('dgId', '');
    body.set('towerId', '');
    body.set('groupId', '');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCircleData', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.circleData = this.responseData.data.circleData;
        this.divisionData = this.responseData.data.divisionData;
        this.dcData = this.responseData.data.dcData;
        this.feederData = this.responseData.data.feederData;
        this.grData = this.responseData.data.grData;
        this.rdData = this.responseData.data.rdData;
        this.authData = this.responseData.data.authData;
        this.category = this.responseData.data.catData;
        this.dateData = this.responseData.data.dateData;
        this.simData = this.responseData.data.simData;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }


  getPageSizeOptions() {
    return [this.length];
  }


  addmetercommand() {
    this.modalService.addMeterCommand(this.selectedSubDivision).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  editmetercommand(row) {
    this.modalService.editMeterCommand(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  downloadOldMeterImage(row) {
    window.open(row.OldMeterImgAdd, "_blank");
  }

  downloadNewMeterImage(row) {
    window.open(row.newMeterImgAdd, "_blank");
  }


  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for (let col of this.levelHeadsCols) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  changePage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getMeterCommandListing();
    } else {
      if (this.allLevelTotalRecord > 0) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.getMeterCommandListing();
        // }
      }
    }
  }
}
