import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-check-otp',
  templateUrl: './check-otp.component.html',
  styleUrls: ['./check-otp.component.css']
})
export class CheckOtpComponent implements OnInit {
  //message:any;
  message:any = '';
  servantMode:any = '';
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }
  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      otpValue: ['', Validators.required]
    });
  }

  get aF() { return this.addForm.controls; }

  generateCommand(){
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '');
    body.set('meterType', '');
    body.set('conAccId', '');
    body.set('meterId', '');
    body.set('servantmode', this.servantMode);
    body.set('meterload', '');
    body.set('backupSupply', '');
    body.set('cmdFrom', '2');
    body.set('startfixedchargesonmeter', '');
    body.set('startemergencycredit','');
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/addMeterActivityDesh', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.addAuthMessage = this.responseData.data.message;
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.isLoading = true;
      this.addAuthMessage='';
      let body = new URLSearchParams();
      body.set('subdivId', this.subDivisionId);
      body.set('userName', this.currentUser.data.userData.username);
      body.set('otpValue', this.aF.otpValue.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/matchDomainActivityOtp', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.addAuthMessage = this.responseData.message;
            if (this.responseData.success) {
                this.thumb = true;
                this.generateCommand();
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    otpCheck() {
      let body = new URLSearchParams();
      this.addAuthMessage='';
      body.set('userName', this.currentUser.data.userData.username);
      body.set('mobileNo', this.currentUser.data.userData.mobile);
      body.set('emailId', this.currentUser.data.userData.email);
      body.set('authToken', this.currentUser.authToken);
      this.isLoading = true;
      this.genReqs.postReq('/sendActivityOtp', body).subscribe((result)=>{
        this.responseData = result;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.addAuthMessage = this.responseData.message;
         // this.openOtpDilog(this.message);
        }else{
          this.thumb = false;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.addAuthMessage = 'Unable to process yours request!';
      });
    }

}
