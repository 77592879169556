import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editdg',
  templateUrl: './editdg.component.html',
  styleUrls: ['./editdg.component.scss']
})
export class EditdgComponent implements OnInit {
  faSpinner = faSpinner;
  dgData: any = {};
  dgid:string = '0';
  dgName:string = '';
  ebName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  dgNameData:string = '';
  submitted: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      dgName: ['', Validators.required],
      ebName: ['', Validators.required],
      dgNameData: ['', Validators.required]
      });
     // console.log("dgData",this.dgData);
      this.dgid = this.dgData.dgId;
      this.dgName = this.dgData.dgName;
      this.ebName = this.dgData.ebName;
      this.dgNameData = this.dgData.dgNameData;
      this.aF.dgName.setValue(this.dgName);
      this.aF.ebName.setValue(this.ebName);
      this.aF.dgNameData.setValue(this.dgNameData);
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      let body = new URLSearchParams();
      body.set('subdivId',  this.dgData.subDivId);
      body.set('dgName', this.aF.dgName.value);
      body.set('ebName', this.aF.ebName.value);
      body.set('dgId', this.dgData.dgId);
      body.set('dgNameData', this.aF.dgNameData.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateDGData', body).subscribe((result)=>{
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.addAuthMessage = this.responseData.data.message;
        }else{
            this.thumb = false;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }
}
