<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <div class="with-icon">
      <mat-icon matPrefix>person</mat-icon>Profile
    </div>

  </h4>
  <button type="button"  (click)="modal.close('Ok click')" class="dilog4 dilog5">
    <mat-icon matPrefix>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="dropdown-content mxh-none">

      <div class="col-md-6 pull-left profile_view">
        <mat-icon material-icons mat-icon-no-color class="d-c7">person</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Name:</strong></div>
          <div class="description">
            {{userData.name}}
          </div>
        </div>
      </div>

      <div class="col-md-6 pull-left profile_view">
        <mat-icon material-icons mat-icon-no-color class="d-c7">person</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Username: </strong></div>
          <div class="description">
            {{userData.username}}
          </div>
        </div>
      </div>

      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c11">email</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Email:</strong> </div>
          <div class="description">
            {{userData.email}}
          </div>
        </div>
      </div>

      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c12">call</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>LanlineNo:</strong> </div>
          <div class="description">
            {{userData.lanlineNo}}
          </div>
        </div>
      </div>

      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c13">stay_primary_portrait</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Home Address 1:</strong> </div>
          <div class="description">
            {{userData.homeadd1}}
          </div>
        </div>
      </div>
      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c14">home</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Home Address 2:</strong> </div>
          <div class="description">
            {{userData.homeadd2}}
          </div>
        </div>
      </div>
      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c15">home</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Office Address 1:</strong></div>
          <div class="description">
            {{userData.officeadd1}}
          </div>
        </div>
      </div>
      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c15">home</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Office Address 2:</strong> </div>
          <div class="description">
            {{userData.officeadd2}}
          </div>
        </div>
      </div>
      
      <div class="col-md-6 pull-left profile_view">
        <mat-icon matPrefix class="d-c7">date_range</mat-icon>
        <div class="nhGroup">
          <div class="nlabel"><strong>Dob:</strong> </div>
          <div class="description">
            {{userData.dob}}
          </div>
        </div>
      </div>


    </div>
    <!-- Grid row -->
    <div class="clearfix"></div>
    <!-- buttons are listed here-->
  </div>
</div>