import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addmeterfirmwareversion',
  templateUrl: './addmeterfirmwareversion.component.html',
  styleUrls: ['./addmeterfirmwareversion.component.scss']
})
export class AddmeterfirmwareversionComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage: string = '';
  responseData: any;
  AddProcess: boolean = false;
  apiMessage: string = '';
  subDivisionId: any = 0;
  isLoading: boolean = false;
  thumb: boolean = false;
  addAuthMessage: string = '';
  submitted: boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      firmwareName: ['', Validators.required],
      firmwareFile0: [null, Validators.required],
      firmwareFile1: [null, Validators.required]
    });
  }
  ngAfterViewInit() {

  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }
  get aF() { return this.addForm.controls; }
  onSubmitAddForm() {
    this.isLoading = true;
    // console.log(this.currentUser);
    if (this.addForm.invalid) {
      this.submitted = true;
      return;
    }
    const formData = new FormData();
    formData.append('subdivId', this.currentUser.data.userData.subdivision);
    formData.append('subName', this.currentUser.data.userData.subDivName);
    formData.append('firmwareName', this.aF.firmwareName.value);
    formData.append('firmwareFile0', this.aF.firmwareFile0.value);
    formData.append('firmwareFile1', this.aF.firmwareFile1.value);
    formData.append('authToken', this.currentUser.authToken);
    this.AddProcess = true;
    this.genReqs.formDataPost('/addMFVersion', formData).subscribe((result) => {
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.addAuthMessage = this.responseData.data.message;
        setTimeout(() => 
        {
          this.modal.close('done');
        },
        2000);
      } else {
        this.thumb = false;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.apiMessage = 'Unable to process yours request!';
    });
  }

  onFileSelect0(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log(file);
      this.aF.firmwareFile0.setValue(file);
    }
  }

  onFileSelect1(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      //console.log(file);
      this.aF.firmwareFile1.setValue(file);
    }
  }


  resetForm(form: UntypedFormGroup) {

    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }
}
