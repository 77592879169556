<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Recent Meter Tariff: {{meterDetail.mID}}
    </h4>
  </div>
    <div class="modal-body">
        <ng-container>
          <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>

          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
            <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Meter Id
                  </div>
                  </td>
                  <td>
                    <div class="d_title">
                      <span></span>Activation
                  </div>
                  </td>
                  <td>
                    <div class="d_title">
                      <span></span>Creation
                  </div>
                  </td>
                  <td>
                    <div class="d_title">
                      <span></span>Alarm Level (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Emergency Credit (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Fix Deduction Type
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Grid Unit Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>DG Unit Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Grid Fix Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>DG Fix Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Vending Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Common Area Maintenance Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Grid Maintenance Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Dg Maintenance Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Club Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Water Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Other Rate (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Service Tax (Rs.)
                  </div>
                  </td>
                  <td>
                    <div class="d_title ">
                      <span></span>Configuration Status
                  </div>
                  </td>
                </tr>
              </thead>
            <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.meterId}}</p>
  </div>
  </td>

  <td>
    <div class="d_content d_content_W">
      <p>{{item.activedateTime}}</p>
  </div>
  </td>

  <td>
    <div class="d_content d_content_W">
      <p>{{item.dateTime}}</p>
  </div>
  </td>
  

  <td>
    <div class="d_content d_content_W">
      <p>{{item.alarmLevel}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.emergCreditLimit}}</p>
  </div>
  </td>
  
  <td>
    <div class="d_content d_content_W">
      <p>{{item.fixDeductionType}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.eBUnitRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.dGUnitRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.eBFixCharges}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.dGFixCharges}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.vendingRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.commonAreaMaintRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.gridMaintRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.dgMaintRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.clubRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.waterRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.otherChargeRate}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.serviceTax}}</p>
  </div>
  </td>
  <td>
    <div class="d_content d_content_W">
      <p>{{item.configurationStatus}}</p>
  </div>
  </td>

</tr>
</tbody>
</table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>          
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>--> 
          </div>

          <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
            <div class="col-sm-12">
              <img class="center" src="assets/images/Nodata.png">
            </div>
            <!--<div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
          </div>
          
        </ng-container>
    <ng-container col-sm-3>
    </ng-container>
</div>
</div>
  