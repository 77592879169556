import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { AuthenticationService, generalRequestsService, UserService } from '../../_services';

@Component({
  selector: 'app-consumer-contactus',
  templateUrl: './consumer-contactus.component.html',
  styleUrls: ['./consumer-contactus.component.scss']
})
export class ConsumerContactusComponent implements OnInit {
  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;

  meterDetail:any = {};

  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
   // console.log(this.meterDetail);
    this.getContactUsDetails();
  }

 

  getContactUsDetails() {
    let body = new URLSearchParams();
    body.set('databaseName', this.currentUser.data.userData.subName);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/contact', body).subscribe((result)=>{
      this.responseData = result;
     
      if(this.responseData.success){
        this.letviewis = this.responseData.data;
     }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}
