<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <mat-form-field class="col-md-6 form-group input-rounded">
    
    <mat-icon matPrefix class="d-c15">exit_to_app</mat-icon>
      <mat-select placeholder="Choose Command Type" name="commType" formControlName="commType">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let cmdType of commandType" [value]="cmdType">
          {{cmdType.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cF.commType.hasError('required')">Please choose Command</mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
      <mat-icon matPrefix class="d-c15"> exit_to_app </mat-icon>
      <input matInput placeholder="Date Time" name="dateTime" formControlName="dateTime">
      <mat-error *ngIf="cF.dateTime.hasError('required')">
          Date Time is <strong>required</strong>
      </mat-error>
      </mat-form-field>
  
    <button mat-raised-button type="submit" class="cPbg">Add</button>
</form>