import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { generalRequestsService, AuthenticationService, UserService } from '../_services';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { Access } from '../_models/access';
import { GridlistService } from '../_services/gridlist.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-utility-to-circle',
  templateUrl: './utility-to-circle.component.html',
  styleUrls: ['./utility-to-circle.component.scss']
})
export class UtilityToCircleComponent implements OnInit {

  currentUser: User;
  currentUserSubscription: Subscription;
  getThroughSubject: Subscription;
  getGothrough : Access;
  users: User[] = [];
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  bCumUtlity: any = '';
  bCumCountry:any = '';
  bCumState:any = '';
  breakpoint: number;
  closeResult: string;
  addForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  levelSearch:any = '';
  submitted: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService
  ) { 

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.getThroughSubject = this.authenticationService.getThrough.subscribe(nav => {
      this.getGothrough = nav
    });
    
    this.route.queryParams.subscribe(params => {
      this.countryId = this.genReqs.decryptData(params['country']);
      this.stateId = this.genReqs.decryptData(params['state']);
      this.utilityId = this.genReqs.decryptData(params['utility']);
    });
    this.breakpoint = this.gridlistService.breakpoint;
    this.accesLevel = parseInt(this.currentUser.data.userData.authLevel) || 0;
  }

   ngOnInit() {
    this.authenticationService.getDeshboardRoute();
    this.getBreadCrums();
    this.getAccesslevels();
    this.reCountCols(window.innerWidth);
  }

  ngAfterViewInit() {
    this.addForm = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      Image: [null, Validators.required]
    });
    this.editForm = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      Image: [null, Validators.required],
      Id: ['']
    });
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  onResize(event) {
    this.reCountCols(event.target.innerWidth);
  }
  
  reCountCols(width:number) {
    this.breakpoint = this.gridlistService.reCountCols(width);
  }

  onLevelSearchChange(search){
    this.levelSearch = search;
    //this.skip = 0;
    this.getNotification(false);
  }

  getNotification(addNew:boolean=false) {
    //this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('country', this.countryId);
    body.set('filter', this.levelSearch);
    body.set('state', this.stateId);
    body.set('utility', this.utilityId);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCircle', body).subscribe((result)=>{
      this.responseData = result;
      this.loadingUtilitys = false;
      if(this.responseData.success){
        this.allutilitys = this.responseData.data;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }



  getAccesslevels() {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('country', this.countryId);
    body.set('state', this.stateId);
    body.set('utility', this.utilityId);
    this.genReqs.postReq('/getCircle', body).subscribe((result)=>{
      this.responseData = result;
      this.loadingUtilitys = false;
      if(this.responseData.success){
        this.allutilitys = this.responseData.data;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  getBreadCrums() {
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', this.stateId);
    body.set('utilityId', this.utilityId);
    body.set('circleId', '0');
    body.set('divisionId', '0');
    body.set('subdivisionId', '0');
    body.set('dcuId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNames', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.bCumCountry = this.responseData.data.country[0].countName;
        this.bCumState = this.responseData.data.state[0].stateName;
        this.bCumUtlity = this.responseData.data.utility[0].utilityName;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

     /*
      bredcrum permissions
    */

   isCountry():boolean
   {
     return this.accesLevel == 1;
   }

   isState():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 2;
   }

   isUtility():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 3;
   }

   isCircle():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 4;
   }

   isDivision():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 5;
   }

   isSubDivision():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 6;
   }

  backToCircleView() {
    this.router.navigate(['/deshauthcircle'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
      }
    });
  }

  backToUtilityView() {
    this.router.navigate(['/deshauthutility'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
      }
    });
  }

  backToStateView() {
    this.router.navigate(['/deshauthstates'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId)
      }
    });
  }

  backToCountryView() {
    this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId)}});
  }

  goToCircleView(circle_id){
    this.router.navigate(['/deshauthdivision'], 
    { queryParams: 
      { 
        'country': this.genReqs.encryptData(this.countryId),
        'state': this.genReqs.encryptData(this.stateId),
        'utility': this.genReqs.encryptData(this.utilityId),
        'circle': this.genReqs.encryptData(circle_id),
      }
    });
  }

    /**to get the meter list on click on Dcu */
    countryEditView(row, content) {
      this.responseMessage = '';
      this.eF.Code.setValue(row.circleCode);
      this.eF.Name.setValue(row.circleName);
      this.eF.Id.setValue(row.circle_id);
      this.EditName = row.circleName;
      this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    open(content) {
      this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }

      /*
      forms images pickers
    */
   /*onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aF.Image.setValue(file);
    }
  }*/

  onFileSelect(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      //console.log(file.type);
        if(file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
          const file = event.target.files[0];
          this.aF.Image.setValue(file);
          //console.log(file.type,file);
          return true;
        }
        else {
          this.aF.Image.setValue(null);
          alert("Please select Files in PNG/JPEG Formats");
           return false;
        }
    }
   /* if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.eC.countryImage.setValue(file);
    }*/
  }

   /* onFileSelectEdit(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
       this.eF.Image.setValue(file);
      }
    }*/

    onFileSelectEdit(event) {
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];
        //console.log(file.type);
          if(file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
            const file = event.target.files[0];
            this.eF.Image.setValue(file);
            //console.log(file.type,file);
            return true;
          }
          else {
            this.eF.Image.setValue(null);
            alert("Please select Files in PNG/JPEG Formats");
             return false;
          }
      }
     /* if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.eC.countryImage.setValue(file);
      }*/
    }



     /*
    add utility form submition here
    */
   get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      
      const formData = new FormData();
      if (this.aF.Image.value != null) {
        formData.append('circleImage', this.aF.Image.value);
      }else{
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }
      //formData.append('circleImage', this.aF.Image.value);
      formData.append('countryId', this.countryId);
      formData.append('stateId', this.stateId);
      formData.append('utilityId', this.utilityId);
      formData.append('circleCode', this.aF.Code.value);
      formData.append('circleName', this.aF.Name.value);
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/addCircle', formData).subscribe(
        (res) => {
          this.responseMessage = res.message;
          if (res.success) {
            this.addForm.reset();
            this.getAccesslevels();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
    }

    removelevel(row){
      //deleteAuth
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove '+row.circleName+' ?')
      .then((confirmed) => {
        if(confirmed) {
          //this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('circleName', row.circleName);
          body.set('circleId', row.circle_id);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteCircle', body).subscribe((result)=>{
            this.responseData = result;
            //this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getAccesslevels();
          },(err)=>{
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
    }

    get eF() { return this.editForm.controls; }
    onSubmitEditForm() {
      //addCountry
        if (this.editForm.invalid) {
           this.submitted = true;
          return;
        }
        const formData = new FormData();
        if (this.eF.Image.value != null) {
          formData.append('circleImage', this.eF.Image.value);
        }else{
          alert("Please select Files in PNG/JPEG Formats");
          return false;
        }

       

        formData.append('countryId', this.countryId);
        formData.append('stateId', this.stateId);
        formData.append('utilityId', this.utilityId);
        formData.append('circleId', this.eF.Id.value);
        formData.append('circleCode', this.eF.Code.value);
        formData.append('circleName', this.eF.Name.value);
        formData.append('authToken', this.currentUser.authToken);
        this.genReqs.formDataPost('/editCircle', formData).subscribe(
          (res) => {
            this.responseMessage = res.message;
            if (res.success) {
              //this.editForm.reset();
              this.getAccesslevels();
            }
          },
          (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
          }
        );
    }

}
