<form [formGroup]="addForm" (ngSubmit)="onSubmitUpdateForm()">
  <div class="modal-header">
    <button type="button" (click)="modal.close('Ok click')" class="dilog4 dilog5" class="close"
      data-dismiss="modal">&times;</button>
  </div>
  <div class="modal-body">
    <div class="form-groupp">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="userprofile_data">
            <div class="text-center mb-4">
              <img class="avatar_user  rounded-circle border_cstm" src="assets/img/director_img.png" alt="">
              <h4>Sub-Division/Society Name </h4>
              <h4><input matInput placeholder="Consumer User Name" class="form-control text-center" name="consUserName"
                  formControlName="consUserName"></h4>
              <mat-error *ngIf="aF.consUserName.hasError('required')">
                Consumer User Name is required
              </mat-error>
            </div>
            <div class="userlbl">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-user iconsie iconalllbl"></i>Authority Full Name</label>
                    <input matInput placeholder="Consumer Full Name" class="form-control" name="consFullName"
                      class="form-control" formControlName="consFullName">
                    <mat-error *ngIf="aF.consFullName.hasError('required')">
                      Consumer Full Name is required
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-user iconsie iconalllbl"></i>Consumer Email</label>
                    <input type="email" matInput placeholder="Consumer Email Id" class="form-control" name="consEmailId"
                      formControlName="consEmailId" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                    <mat-error *ngIf="aF.consEmailId.hasError('required')">
                      Consumer Email Id is required
                    </mat-error>
                    <mat-error *ngIf="aF.consEmailId.hasError('email')">
                      Please enter a valid email address
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-user iconsie iconalllbl"></i>Consumer Landline number</label>
                    <input type="tel" matInput placeholder="Consumer Landline No" class="form-control"
                      name="consLandlineNo" formControlName="consLandlineNo" pattern="[0-9 -()+]+$" minlength="10"
                      maxlength="10">
                    <mat-error *ngIf="aF.consLandlineNo.hasError('required')">
                      Consumer Landline is required
                    </mat-error>
                    <mat-error *ngIf="aF.consLandlineNo.hasError('pattern')">
                      Consumer Landline number not valid.
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-phone iconsie iconalllbl"></i>Consumer Mobile number</label>
                    <input type="tel" matInput class="form-control" placeholder="Consumer Mobile No" name="consMobileNo"
                      formControlName="consMobileNo" pattern="[0-9 -()+]+$" minlength="10" maxlength="10">
                    <mat-error *ngIf="aF.consMobileNo.hasError('required')">
                      Consumer Mobile No is required
                    </mat-error>
                    <mat-error *ngIf="aF.consMobileNo.hasError('pattern')">
                      Consumer Mobile No is not valid.
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-building iconsie iconalllbl"></i>Consumer Home Address line 1</label>
                    <input matInput class="form-control" placeholder="Consumer Home Address line1"
                      name="consHomeAddressline1" formControlName="consHomeAddressline1">
                    <mat-error *ngIf="aF.consHomeAddressline1.hasError('required')">
                      Consumer Home Address line1 is <strong>required</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-building iconsie iconalllbl"></i>Consumer Home Address line 2</label>
                    <input matInput class="form-control" placeholder="Consumer Home Address line2"
                      name="consHomeAddressline2" formControlName="consHomeAddressline2">
                    <mat-error *ngIf="aF.consHomeAddressline2.hasError('required')">
                      Consumer Home Address line2 is <strong>required</strong>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-building iconsie iconalllbl"></i>Consumer Home Address line 2</label>
                    <input matInput class="form-control" placeholder="Consumer Office Address line1"
                      name="consOfficeAddressline1" formControlName="consOfficeAddressline1">
                    <mat-error *ngIf="aF.consOfficeAddressline1.hasError('required')">
                      Consumer Office Address line1 is <strong>required</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-building iconsie iconalllbl"></i>Consumer Office Address line 2</label>
                    <input matInput class="form-control" placeholder="Consumer Office Address line2"
                      name="consOfficeAddressline2" formControlName="consOfficeAddressline2">
                    <mat-error *ngIf="aF.consOfficeAddressline2.hasError('required')">
                      Consumer Office Address line2 is required
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-map iconsie iconalllbl"></i>City</label>
                    <input matInput class="form-control" placeholder="City" name="consCity" formControlName="consCity">
                    <mat-error *ngIf="aF.consCity.hasError('required')">
                      City is required>
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-map iconsie iconalllbl"></i>State</label>
                    <input matInput class="form-control" placeholder="State" name="consState"
                      formControlName="consState">
                    <mat-error *ngIf="aF.consState.hasError('required')">
                      State is required
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-globe iconsie iconalllbl"></i>Country</label>
                    <input matInput class="form-control" placeholder="Country" name="consCountry"
                      formControlName="consCountry">
                    <mat-error *ngIf="aF.consCountry.hasError('required')">
                      Country is required
                    </mat-error>
                  </div>
                </div>
                <!--<mat-form-field class="col-md-6">
                  <mat-icon matPrefix class="d-c11">location_city</mat-icon>
                  <mat-select placeholder="City" name="consCity" formControlName="consCity" >
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let frm of city" [value]="frm.name">
                      {{frm.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="aF.consOfficeAddressline2.hasError('required')">
                    Consumer City is <strong>required</strong>
                  </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-md-6">
                    <mat-icon matPrefix class="d-c11">location_city</mat-icon>
                    <mat-select placeholder="State" name="consState" formControlName="consState" >
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let frm of state" [value]="frm.name">
                        {{frm.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="aF.consOfficeAddressline2.hasError('required')">
                      Consumer State is <strong>required</strong>
                    </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-md-6">
                      <mat-icon matPrefix class="d-c11">location_city</mat-icon>
                      <mat-select placeholder="Country" name="consCountry" formControlName="consCountry" >
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let frm of country" [value]="frm.name">
                          {{frm.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="aF.consOfficeAddressline2.hasError('required')">
                        Consumer Country is <strong>required</strong>
                      </mat-error>
                      </mat-form-field>-->
                <div class="col-md-6">
                  <div class="form-group">
                    <label><i class="fa fa-calendar iconsie iconalllbl"></i>Consumer DOB</label>
                    <input matInput [matDatepicker]="dp3" class="form-control" placeholder="Consumer DOB (MM/DD/YYYY)"
                      name="consDOB" formControlName="consDOB" [value]="date.value" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix class="absol" [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    <mat-error *ngIf="aF.consDOB.hasError('required')">
                      Consumer DOB is required
                    </mat-error>
                  </div>
                </div>
                <!-- <mat-form-field class="col-md-4">
                  <input matInput [matDatepicker]="dp3" placeholder="Consumer DOB (MM/DD/YYYY)" name="consDOB" formControlName="consDOB" [value]="date.value" [max]="maxDate">
                  <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                  <mat-icon matPrefix class="d-c7">date_range</mat-icon>
                  <mat-error *ngIf="aF.consDOB.hasError('required')">
                    Consumer DOB is <strong>required</strong>
                  </mat-error>
                </mat-form-field> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid row -->
      <div class="col-sm-12 row">
        <div class="col-sm-6 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="responseMessage.length > 0">
            <div class="alert alert-success " role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
            </div>
            <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
            </div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>update</mat-icon>
            Update
          </button>
          <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</form>