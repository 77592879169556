import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-reportingfromdcu',
  templateUrl: './reportingfromdcu.component.html',
  styleUrls: ['./reportingfromdcu.component.scss']
})
export class ReportingfromdcuComponent implements OnInit {

  loading:boolean = false;
  reportForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;

  currentUserSubscription: Subscription;
  currentUser: User;
  enableSocietyChange:boolean = true;
  
  constructor(
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    });
    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=false;
    }
  }

  ngOnInit() {
    if(this.enableSocietyChange){
      this.whichSubDomain();
    }
   
  }
  ngOnDestroy() {
   
}

public formControlIsValid(formGroup, formCtrl) : boolean {
  if (formGroup.controls.hasOwnProperty(formCtrl)) {
    if (!formGroup.controls[formCtrl].touched) return true;
    return formGroup.controls[formCtrl].valid;
  }
  return false;
}

whichSubDomain(){
  this.modalService.popautocomplete().pipe(
    take(1)).subscribe(result => {
      this.selectedSubDivision = result;
     // console.log('selected val',this.selectedSubDivision);
    }
  );
}
get rF() { return this.reportForm.controls; }

onSubmitAddForm(){
  //to do ##to be removed
}

}
