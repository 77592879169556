import { Injectable } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as SecureLS from 'secure-ls';

import { User } from '../_models';
import { webconfig } from '../_models/webconfig';
import { Access } from '../_models/access';
import { Router } from '@angular/router';
import { Notify } from '../_models/notify';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReportsAccess } from '../_models/reportsaccess';
//import { ConsumermodalService } from './consumermodal.service';
//import getMAC from 'getmac';

let apiUrl = webconfig.apiUrl;
let key = webconfig.storageKey;
var ls = new SecureLS({
  encodingType: 'AES',
  isCompression: false,
  encryptionSecret: key
});

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  deviceInfo:any;
  isMobile:any;
  isTablet:any;
  isDesktopDevice:any;
  browser:any;
  browser_version:any;
  os:any;
  os_version:any;
  userAgent :any;
  device :any;
  effectiveType:any;
  connectionSpeed:any;
  mac:any;
  ipv4:any;
  ipv6:any;
  macaddress:any;
  messageResult:any;
  setLoginType=false;
  protected currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private getThroughSubject: BehaviorSubject<Access>;
  private getReportThroughSubject: BehaviorSubject<ReportsAccess>;

  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  public currentUser: Observable<User>;
  public getThrough: Observable<Access>;
  public getReportThrough: Observable<ReportsAccess>;

  public httpOptions: any;
  public goThroughPerm = new Access();
  public goThroughReportPerm = new ReportsAccess();

  isLoggedInCheck: BehaviorSubject<boolean>;
  public notifySubject: Observable<Notify>;
  public notify: Observable<Notify>;

  constructor(private http: HttpClient,  
    //private conmodalService: ConsumermodalService,
    private deviceService: DeviceDetectorService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(JSON.stringify(ls.get('currentUser'))));
    this.getThroughSubject = new BehaviorSubject<Access>(JSON.parse(JSON.stringify(ls.get('gothorugh'))));
    this.getReportThroughSubject = new BehaviorSubject<ReportsAccess>(JSON.parse(JSON.stringify(ls.get('gotReporthorugh'))));

    this.isLoggedInCheck = new BehaviorSubject<boolean>(true);
    this.currentUser = this.currentUserSubject.asObservable();
    this.getThrough = this.getThroughSubject.asObservable();
    this.getReportThrough = this.getReportThroughSubject.asObservable();

    this.getGothrough();
    this.getGoReportthrough();
    this.notifySubject = new BehaviorSubject<Notify>({"count": 0});
    this.notify = this.notifySubject;

    
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
        // do something
      }
      const connectionlist = ["slow-2g", "2g", "3g", "4g"];
      this.effectiveType = conn.effectiveType;
      //console.log(this.effectiveType);
    }

    this.browser = this.deviceService.browser;
    this.browser_version = this.deviceService.browser_version;
    this.os = this.deviceService.os;
    this.os_version = this.deviceService.os_version;
    this.userAgent = this.deviceService.userAgent;
    this.device = this.deviceService.device;
    this.connectionSpeed = this.effectiveType;

  }

  

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get isCurrentUserAuthority(): boolean {
    return (this.isLoginSubject.value && this.currentUserSubject.value.data.userType === 'Authority');
  }

  public get isCurrentUserLoggedIn(): boolean {
    return this.isLoginSubject.value;
  }

   /**
   * Returns current user as observable
   */
    public user(): Observable<User | null> {
      return this.currentUserSubject.asObservable();
    }

  public getGothrough() {
    //console.log(this.getThroughSubject.value);
    return this.getThroughSubject.value;
  }

  public getGoReportthrough() {
    //console.log(this.getThroughSubject.value);
    return this.getReportThroughSubject.value;
  }

  public getRemberMeAccess(): any {
    return JSON.parse(JSON.stringify(ls.get('remberThrough')));
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('currentUser');
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  getDeshboardRoute() {
    let path = 'login';
    console.log("in login",this.currentUserSubject.value.data.userData.uid.toString());
    if (this.currentUserSubject.value) {
      let deshBoard = this.currentUserSubject.value.data.userData.authLevel;
      if (parseInt(deshBoard) == 1) {
         path = 'deshauth';
       } else if (parseInt(deshBoard) == 2) {
         path = 'deshstate';
       } else if (parseInt(deshBoard) == 3) {
         path = 'deshutility';
       } else if (parseInt(deshBoard) == 4) {
         path = 'deshcircle';
       } else if (parseInt(deshBoard) == 5) {
         path = 'deshdivision';
       } else if (parseInt(deshBoard) == 6) {
         path = 'deshsubdivision';
       } else if (parseInt(deshBoard) > 6 || this.currentUserSubject.value.data.userType == 'Authority') {
         //path = 'deshall';
          if (this.currentUserSubject.value.data.allowed.Auth_All_Griphical_Society_Deshboard) {
            path = 'allsosGridDeshBoard';
          }else if (this.currentUserSubject.value.data.allowed.Contractor_Deshboard) {
            path = 'allsosGridDeshBoard';
          }else if (this.currentUserSubject.value.data.allowed.Superviser_Deshboard) {
            path = 'allsosGridDeshBoard';
          }else if (this.currentUserSubject.value.data.allowed.pending_payment_view) {
            path = 'smartMeterSummery';
          } else{
            path = 'dashboard';
          }
       } else{
          path = 'dashboard';
       }
      /*if (parseInt(deshBoard) > 0 && parseInt(deshBoard) < 8 ) {
        path = 'dashboard';
      } else {
        path = 'dashboard';
      }*/
    }
    //console.log("hello",path);
    return path;
  }

  login(username: string, password: string, subdevid: string, subdevDb: string, remberme: boolean, authToken: string) {
   
      //this.mac=getMAC('eth0');
      //console.log("Mac Address",getMAC(),getMAC('eth0'));
      //this.ipv4=ipv4;
      //this.ipv6=ipv6;
   
    let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('subdevid', subdevid);
    body.set('subDevisionDatabaseName', subdevDb);

    //body.set('mac', this.mac);
    //body.set('ipv4', this.ipv4);
    //body.set('ipv6', this.ipv6);
   
    body.set('isMobile', this.isMobile);
    body.set('isTablet', this.isTablet);
    body.set('isDesktopDevice', this.isDesktopDevice);
    body.set('browser', this.browser);
    body.set('browser_version', this.browser_version);
    body.set('os', this.os);
    body.set('userAgent', this.userAgent);
    body.set('device', this.device);
    body.set('connectionSpeed', this.connectionSpeed);

  //let accessAuth = localStorage.getItem('accessAuth');
  let accessAuth = "eyJ0eXBlIjoiQVZPTlNNIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJyZW1vdGUiOiJOa0ZIUzNRNGRHTnlOREJHVTFZclQwcHVUamxqUVQwOSIsInVzZXJUeXBlIjoiZWtacmVWaGFPRmhCZDBNMVNsTnlkRGNyWWpneFVUMDkiLCJkYXRldGltZSI6IjIwMjMtMTAtMjcgMTE6MjI6MDgifQ.LdqmrRIG1u6Rblx95NdJp3W6B01QXrjqIJ7EHCWdCXE";
    body.set('authToken', accessAuth);
    return this.http.post<any>(`${apiUrl}/validateLogin`, body.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).pipe(map(user => {
      // login successful if there's a jwt token in the response
      let perms = {
        consumer: this.goThroughPerm.consumer,
        authority: this.goThroughPerm.authority,
      };
      let reportPerms = {
        authorityReport: this.goThroughReportPerm.reportsAuthority,
        consumerReport: this.goThroughReportPerm.reportsConsumer,
      };
      if (user.data.userData && user.success) {

        ls.set('currentUser', user);
        
        this.isLoginSubject.next(true);
        if (user.data.userType.toString().toLowerCase() == 'consumer') {
          //this.goThroughPerm.consumer = user.data.allowed;
          //this.getThroughSubject.next(user.data.allowed);
          perms.consumer = user.data.allowed;
          reportPerms.consumerReport = user.data.reportPerm;
        } else if (user.data.userType.toString().toLowerCase() == 'authority') {
         
          //this.goThroughPerm.authority = user.data.allowed;
          //this.getThroughSubject.next(user.data.allowed);
          perms.authority = user.data.allowed;
          reportPerms.authorityReport = user.data.reportPerm;
          //console.log("ReportsPerm",user.data.reportPerm);
        }
        ls.set('gothorugh', perms);
        ls.set('gotReporthorugh', reportPerms);
        if (remberme) {
          ls.set('remberThrough', { 'username': username, 'password': password, 'subdevid': subdevid });
        }
        this.currentUserSubject.next(user);
        this.getThroughSubject.next(perms);
        //window.location.reload();
        //this.getDeshboardRoute(); 
        //this.getReportThroughSubject.next(reportPerms);
      }
      return user;
    }));
  }

  /*authorityResetPassword() {
    //console.log("hello1",ls.get('currentUser'));
    this.conmodalService.authorityResetPassword(1,ls.get('currentUser')).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }*/

  mpinLogin(mobileno: string, authToken: string) {
    let body = new URLSearchParams();
    body.set('userName', mobileno);
    //console.log("mpinLogin", mobileno);
    //body.set('emailId', email);
    //body.set('generateOtp', 'Y');
    //body.set('subdevid', subdevid);
    //body.set('subDevisionDatabaseName', subdevDb);
    let accessAuth = localStorage.getItem('accessAuth');
    body.set('authToken', accessAuth);
    return this.http.post<any>(`${apiUrl}/sendDomainOtp`, body.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).pipe(map(user => {
      return user;
    }));
  }

  /* mpinLogin(mobileno: string, email: string, subdevid:string, subdevDb: string, authToken: string) {
       let body = new URLSearchParams();
       body.set('userName', mobileno);
       body.set('emailId', email);
       body.set('generateOtp', 'Y');
       body.set('subdevid', subdevid);
       body.set('subDevisionDatabaseName', subdevDb);
       let accessAuth = localStorage.getItem('accessAuth');
       body.set('authToken', accessAuth);
       return this.http.post<any>(`${apiUrl}/sendOtp`, body.toString(), {
           headers:{
             'content-type':"application/x-www-form-urlencoded"
           }
         }).pipe(map(user => {
           return user;
       }));
   }*/

  /* mpinOtpValidate(mobileno: string, email: string, otp:string, subdevid:string, authToken: string) {
       let body = new URLSearchParams();
       body.set('mobileNo', mobileno);
       body.set('emailId', email);
       body.set('otpValue', otp);
       body.set('activeStatus', 'Y');
       body.set('subdevid', subdevid);
       let accessAuth = localStorage.getItem('accessAuth');
       body.set('authToken', accessAuth);
       return this.http.post<any>(`${apiUrl}/matchDomainOtp`, body.toString(), {
           headers:{
             'content-type':"application/x-www-form-urlencoded"
           }
         }).pipe(map(user => {
           let perms = {
             consumer: this.goThroughPerm.consumer,
             authority: this.goThroughPerm.authority
           };
           if (user.data.userData && user.success) {
               //localStorage.setItem('currentUser', JSON.stringify(user));
               ls.set('currentUser', user);
               this.isLoginSubject.next(true);
               if (user.data.userType.toString().toLowerCase() == 'consumer') {
                 perms.consumer = user.data.allowed;
               } else if (user.data.userType.toString().toLowerCase() == 'authority') {
                 perms.authority = user.data.allowed;
               }
               ls.set('gothorugh', perms);
               this.currentUserSubject.next(user);
               this.getThroughSubject.next(perms);
           }
           return user;
       }));
   }*/

  mpinOtpValidate(mobileno: string, otp: string, authToken: string, enableForgotString:boolean) {
    let body = new URLSearchParams();
    body.set('userName', mobileno);
    body.set('otpValue', otp);

    body.set('isMobile', this.isMobile);
    body.set('isTablet', this.isTablet);
    body.set('isDesktopDevice', this.isDesktopDevice);
    body.set('browser', this.browser);
    body.set('browser_version', this.browser_version);
    body.set('os', this.os);
    body.set('userAgent', this.userAgent);
    body.set('device', this.device);
    body.set('connectionSpeed', this.connectionSpeed);
    //body.set('activeStatus', 'Y');
    
    let accessAuth = localStorage.getItem('accessAuth');
    body.set('authToken', accessAuth);
    return this.http.post<any>(`${apiUrl}/matchDomainOtp`, body.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).pipe(map(user => {
      let perms = {
        consumer: this.goThroughPerm.consumer,
        authority: this.goThroughPerm.authority
      };
      if (user.data.userData && user.success) {
        //localStorage.setItem('currentUser', JSON.stringify(user));
        ls.set('currentUser', user);
        //console.log("authuserdata",ls.get('currentUser'));
        this.isLoginSubject.next(true);
        if (user.data.userType.toString().toLowerCase() == 'consumer') {
          //this.consumerResetPassword(user);
          if(enableForgotString){
            this.setLoginType=true;
          }
          perms.consumer = user.data.allowed;
        } else if (user.data.userType.toString().toLowerCase() == 'authority') {
          //this.authorityResetPassword();
          if(enableForgotString){
            this.setLoginType=true;
          }
          perms.authority = user.data.allowed;
        }
        ls.set('gothorugh', perms);
        this.currentUserSubject.next(user);
        this.getThroughSubject.next(perms);
      }
      return user;
    }));
  }


  logout() {
    localStorage.removeItem('firsttime');
    $('body').removeClass('sidebar-mini');
    this.setLoginType=false;
    // remove user from local storage to log user out
    let body = new URLSearchParams();
    //console.log("helloone");
    body.set('isMobile', this.isMobile);
    body.set('isTablet', this.isTablet);
    body.set('isDesktopDevice', this.isDesktopDevice);
    body.set('browser', this.browser);
    body.set('browser_version', this.browser_version);
    body.set('os', this.os);
    body.set('userAgent', this.userAgent);
    body.set('device', this.device);
    body.set('connectionSpeed', this.connectionSpeed);
    
    body.set('authToken', this.currentUserSubject.value.authToken);
    this.http.post<any>(`${apiUrl}/out`, body.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).subscribe((result) => {
      //console.log(result)
    });
    this.setLoginType=false;
    localStorage.removeItem('accessAuth');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('gothorugh');
    this.currentUserSubject.next(null);
    this.isLoginSubject.next(false);
    this.router.navigate(['/login']);
  }

  //validateToken

}