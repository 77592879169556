<!--<div class="wrapper" (window:beforeunload)="logout()">-->

<div class="wrapper">
 
  <header class="hdr"></header>
  <mat-sidenav-container>
    <mat-sidenav class="vh_oh" #mainSideNav id="mainSideMenuNav" opened mode="side"
      *ngIf="authenticationService.currentUser | async" 
      [class.menu-open]="isSideNaveOpen"
      [class.menu-close]="!isSideNaveOpen" 
      [class.menu-open]="isSideNaveOpen" 
      [class.menu-close]="!isSideNaveOpen">

      <div class="sidebar">
        <app-leftnav></app-leftnav>
      </div>

    </mat-sidenav>
    <mat-sidenav-content>
      <ng-container>
        <div 
          [class.main-panel]="(authenticationService.currentUser | async)"
          [class.main-panel-full]="!(authenticationService.currentUser | async)">
          
          <app-headnav></app-headnav>
          <ngx-loading-bar></ngx-loading-bar>
          <router-outlet></router-outlet>
          <!-- <app-footer [class.footer-open]="isSideNaveOpen" [class.footer-close]="!isSideNaveOpen"></app-footer> -->

        </div>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>