<form [formGroup]="addFormData" (ngSubmit)="updateLoadLimit()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
       Load Limiter
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-groupp">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input  placeholder="Enter Password" name="password" formControlName="password" type="password" 
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.password.errors}" required>
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>Consumer Password</label>
              <div *ngIf="submitted && aF.password.errors" class="invalid-feedback">
                <div *ngIf="aF.password.errors.required">
                  Consumer Password is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input matInput type="number" placeholder="Grid Load Limit (0.05kW-15.00kW)" name="gridLoadlimit" formControlName="gridLoadlimit" 
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gridLoadlimit.errors}" required>
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>Grid Load Limit (0.05kW-15.00kW)</label>
              <div *ngIf="submitted && aF.gridLoadlimit.errors" class="invalid-feedback">
                <div *ngIf="aF.gridLoadlimit.errors.required">
                   Grid Load Limit is required
                </div>
                 <div *ngIf="aF.gridLoadlimit.hasError('min')">
                  Grid Load Limit is lower than the minimum
                </div>
                <div *ngIf="aF.gridLoadlimit.hasError('max')">
                    Grid Load Limit is greater than the maximum
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input  type="number" placeholder="DG Load Limit (0.05kW-15.00kW)" name="dgLoadlimit" formControlName="dgLoadlimit" 
               class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgLoadlimit.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>DG Load Limit (0.05kW-15.00kW)</label>
              <div *ngIf="submitted && aF.dgLoadlimit.errors" class="invalid-feedback">
                <div *ngIf="aF.dgLoadlimit.errors.required">
                    DG Load Limit is required
                </div>
                 <div *ngIf="aF.dgLoadlimit.hasError('min')">
                  DG Load Limit is lower than the minimum
                </div>
                <div *ngIf="aF.dgLoadlimit.hasError('max')">
                    DG Load Limit is greater than the maximum
                </div>
              </div>
            </span>
          </div>
        </div>    
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input  type="time" placeholder="Start Time (HH:MM)"  name="startTime" formControlName="startTime" 
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.startTime.errors}" required>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Start Time</label>
              <div *ngIf="submitted && aF.startTime.errors" class="invalid-feedback">
                <div *ngIf="aF.startTime.errors.required">
                  Start Time is required
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input  type="time"  placeholder="End Time (HH:MM)" name="endTime" formControlName="endTime" 
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.endTime.errors}" required>
                <i class="fa fa-clock-o icon_innertxtbox"></i>
                <label>End Time</label>
                <div *ngIf="submitted && aF.endTime.errors" class="invalid-feedback">
                  <div *ngIf="aF.endTime.errors.required">
                    End Time is required
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          </div>
          <!-- Grid row -->
          <div class="row">
            <div class="col-sm-12 text-left">
              <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
                <!--<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="responseMessage.length > 0" >
                  <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon>{{responseMessage}}
                </div>-->
                <div *ngIf="responseMessage.length > 0">
                  <div class="alert-success" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
                  </div>
                  <div class="alert-secondary" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
                  </div>
                </div>
            </div>
            <div class="col-sm-12 text-right">
              <button type="submit"  class="dilog dilog1">
                <mat-icon >update</mat-icon>
                Update
              </button>
              <button type="button"  (click)="modal.close('Ok click')"  class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>
            </div>
          </div>
      </div>

  </form>