<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAssignAdmin()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Assign Meter
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">

            <div class="row">
                <div class="col-md-6">
                    <span class="has-float-label ">
                        <div class="form-group input-group">

                        </div>

                    </span>
                </div>
            </div>
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    Field Admin Names
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="has-float-label ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <mat-select placeholder="Field Admin" name="fieldAdmin" formControlName="fieldAdmin"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.fieldAdmin.errors}" required>
                                            <mat-option *ngFor="let auth of adminData" [value]="auth.id">
                                                {{auth.name}}
                                            </mat-option>
                                        </mat-select>
                                        <i class="fa fa-database icon_innertxtbox"></i>
                                        <label>Field Admins</label>
                                        <div *ngIf="submitted && aD.fieldAdmin.errors" class="invalid-feedback">
                                            <div *ngIf="aD.fieldAdmin.errors.required">
                                                Name Is Required
                                            </div>
                                        </div>
                                    </span>
                                </div>

                            </span>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && message.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 text-left">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Assign
                    </button>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->