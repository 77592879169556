<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Edit Order Production:  {{issuedMeters.companyName}}
        </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
        <div class="form-groupp">
            <!-- Grid row -->
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="1-PH Dispetched" name="singlePhDispetched" formControlName="singlePhDispetched"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.singlePhDispetched.errors}"
                            required>
                            <i class="fa fa-id-badge icon_innertxtbox"></i>
                            <label>1-PH Dispetched</label>
                            <div *ngIf="submitted && aF.singlePhDispetched.errors" class="invalid-feedback">
                                <div *ngIf="aF.singlePhDispetched.errors.required">
                                    1-PH Dispetche is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="3-PH Dispetched" name="threePhDispetched" formControlName="threePhDispetched"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.threePhDispetched.errors}"
                            required>
                            <i class="fa fa-id-badge icon_innertxtbox"></i>
                            <label>3-PH Dispetched</label>
                            <div *ngIf="submitted && aF.threePhDispetched.errors" class="invalid-feedback">
                                <div *ngIf="aF.threePhDispetched.errors.required">
                                    3-PH Dispetche is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="LTCT Dispetched" name="ltctDispetched" formControlName="ltctDispetched"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.ltctDispetched.errors}"
                            required>
                            <i class="fa fa-id-badge icon_innertxtbox"></i>
                            <label>LTCT Dispetched</label>
                            <div *ngIf="submitted && aF.ltctDispetched.errors" class="invalid-feedback">
                                <div *ngIf="aF.ltctDispetched.errors.required">
                                    LTCT Dispetche is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1" style="width: auto;">
                        <mat-icon>edit</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3" style="width: auto;">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>