<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Add Consumer Complaint
    </h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
    <div class="form-groupp">
      <div class="">
        <div class="row">
          <!-- Default input -->
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input placeholder="Consumer Sub Division" name="consSubDivision" formControlName="consSubDivision"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consSubDivision.errors }" required>
                <i class="fa fa-user icon_innertxtbox"></i>
                <label> Consumer Sub Division</label>
                <div *ngIf="submitted && aF.consSubDivision.errors" class="invalid-feedback">
                  <div *ngIf="aF.consSubDivision.errors.required">
                    Consumer Sub Division is required
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input placeholder="Consumer Name" name="consName" formControlName="consName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && aF.consName.errors }" required>
                <i class="fa fa-user icon_innertxtbox"></i>
                <label> Consumer Name</label>
                <div *ngIf="submitted && aF.consName.errors" class="invalid-feedback">
                  <div *ngIf="aF.consName.errors.required">
                    Consumer Name is required
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input matInput placeholder="Consumer Complaint" name="consComplaint" formControlName="consComplaint"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consComplaint.errors }" required>
                <i class="fa fa-comment icon_innertxtbox"></i>
                <label> Consumer Complaint</label>
                <div *ngIf="submitted && aF.consComplaint.errors" class="invalid-feedback">
                  <div *ngIf="aF.consComplaint.errors.required">
                    Consumer Complaint is required
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid row -->
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>add</mat-icon>
            Add
          </button>
          <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</form>