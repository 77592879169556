import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-meter-billing-date',
  templateUrl: './meter-billing-date.component.html',
  styleUrls: ['./meter-billing-date.component.scss']
})
export class MeterBillingDateComponent implements OnInit {
  faSpinner = faSpinner;
  message:string = '';
  isLoading = false;
  AddProcess:boolean = false;
  thumb:boolean = false;

  configForm: UntypedFormGroup;
  demoNumber = 5 ;
  counter = Array;
  subDivisionId: any = 0;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  @Input() meterDetail:any = {
    mID: "All",
    dID:0,
    sdID:0
  };
  numberReturn(length){
    return new Array(length);
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.configForm = this.formBuilder.group({
      startHour: ['', Validators.required],
      startMinute: ['', Validators.required],
      endHour: ['', Validators.required],
      endMinute: ['', Validators.required],
      holidayMonth: ['', Validators.required],
      holidayDate: ['', Validators.required],
      weekDay: ['', Validators.required],
    });
  }

  get cF() { return this.configForm.controls; }

  onSubmitConfigMeter() {
    if (this.configForm.invalid) {
      return;
    }

    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);
    formData.append('configSubDivisionId', this.meterDetail.sdID);
    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
    formData.append('startHour', this.cF.startHour.value);
    formData.append('startMinute', this.cF.startMinute.value);
    formData.append('endHour', this.cF.endHour.value);
    formData.append('endMinute', this.cF.endMinute.value);
    formData.append('holidayMonth', this.cF.holidayMonth.value);
    formData.append('holidayDate', this.cF.holidayDate.value);
    formData.append('weekDay', this.cF.weekDay.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configHappyHours', formData).subscribe(
      (res) => {
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          //this.modal.close('done');
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      }
    );
  }


}
