import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from 'src/app/_services/modal.service';
import { take } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-pendingpayments',
  templateUrl: './pendingpayments.component.html',
  styleUrls: ['./pendingpayments.component.scss']
})
export class PendingpaymentsComponent implements OnInit {

  messageResult:any;
  faSpinner = faSpinner;
  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  isLoading:boolean = false;
  isData:boolean = false;
  totalPendingPayment:any;
  removeProcess:boolean = false;
  meterNo:any = {};

  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];
  getGothrough: Access;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: ModalService,
    private confirmationDialogService: ConfirmationDialogService,
    public modal: NgbActiveModal
  ) {
    this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
   
  }

 

  getRealTimeStatus() {
    this.loading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '10000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSmartMeterData', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        this.isData = true;
        this.letviewis = this.responseData.data;
        this.totalPendingPayment=this.responseData.datalimit.totalResult;
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  
  vewReceivedPayments(row){
    this.modalService.viewReceivedPayments(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        this.getRealTimeStatus();
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  /*edit(row){
    this.modalService.viewSmartMeterNo(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getRealTimeStatus();
      }
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }*/

  edit(row){
    this.modalService.editByAccounts(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getRealTimeStatus();
      }
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  editMeterRange(row){
    this.modalService.editContMeterRange(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getRealTimeStatus();
      }
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

}
