
<div class="page-content-wrapper" style="margin-top:0;" cdkDropList>
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <!--<div class="row py-3 headerArea commonClass h-180" style="margin-top: 0px;">
          <h2 class="mt-0 mb-1 col-12 with-icon">
            <mat-icon class="d-c4">
              assignment_ind
            </mat-icon> Consumptions Report
          </h2>
      </div>-->
      <div class="commonClass">
        <mat-card
        style="padding-top: 14px;padding-bottom: 0px; box-shadow: 0 0;background: transparent !important;"
        class="-mt-20 sm-overflow-auto">
        <ng-container>
            <div class="filter-report-options card_view_box"
                style="background-color: #fff;color: #000;padding-top: 0px;padding-bottom: 0px;">
                     <!--left side-->
            <div class="modal-header1"> 
              <h2>
                Consumptions Report
              </h2>
           </div>
            <div class="col-md-8 pull-left">
                <b>Please Select</b>
                <div class="clearfix"></div>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="Consumptionsview">
                  <mat-radio-button (click)="clear()"value="1">Daily</mat-radio-button>
                  <mat-radio-button (click)="clear()"value="2">Monthly</mat-radio-button>
                  <mat-radio-button (click)="clear()"value="3">Yearly</mat-radio-button>
                </mat-radio-group>
                <div class="clearfix"></div>
              <mat-form-field *ngIf="Consumptionsview == '1'" class="col-md-12">
                <input matInput bsDaterangepicker [maxDate]="today" autocomplete="off" placeholder="Choose a date" (bsValueChange)="getDateFromRange($event)" [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }">
                 <mat-icon matPrefix class="d-c7">date_range</mat-icon>
              </mat-form-field>
              <mat-form-field *ngIf="Consumptionsview == '2'" class="col-sm-5">
                <input matInput bsDatepicker [maxDate]="today" autocomplete="off" placeholder="Choose a month" (bsValueChange)="getMonthFromRange($event)" [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }">
                 <mat-icon matPrefix class="d-c7">date_range</mat-icon>
              </mat-form-field>
              <mat-form-field *ngIf="Consumptionsview == '2'" class="col-sm-5">
                <input matInput bsDatepicker [maxDate]="today" autocomplete="off" placeholder="Choose a month" (bsValueChange)="getMonthEndFromRange($event)" [bsConfig]="{ dateInputFormat: 'MM/YYYY', minMode: 'month' }" [(ngModel)]="monthSelection">
                 <mat-icon matPrefix class="d-c7">date_range</mat-icon>
              </mat-form-field>
              <mat-form-field *ngIf="Consumptionsview == '3'" class="col-sm-5">
                <input matInput bsDatepicker [maxDate]="today" autocomplete="off" placeholder="Choose a year" (bsValueChange)="getYearFromRange($event)" [bsConfig]="{ dateInputFormat: 'YYYY', minMode: 'year'  }">
                 <mat-icon matPrefix class="d-c7">date_range</mat-icon>
              </mat-form-field>
              <!--<mat-form-field *ngIf="Consumptionsview == '3'" class="col-sm-5">
                <input matInput bsDatepicker autocomplete="off" placeholder="Choose a year" (bsValueChange)="getYearEndFromRange($event)" [bsConfig]="{ dateInputFormat: 'YYYY', minMode: 'year'  }" [(ngModel)]="yearSelection">
              </mat-form-field>-->

              <button class="col-md-1 dilog-button dilog-button1" mat-raised-button (click)="filterData()">Go</button>

            </div>
            <div class="col-md-4 pull-left" *ngIf="htmlContet.length > 0">
              <b>Export Data</b>
              <div class="clearfix"></div>
              <mat-list class="export-links" >
                <mat-list-item style="height: 50px;">
                  <a type="button" mat-raised-button (click)="downloadAsPDF2()" target="_blank" class="col-md-12 dilog-button dilog-button1">
                    <fa name="file-pdf-o"></fa>&nbsp; PDF
                  </a>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item style="height: 50px;">
                  <a type="button" mat-raised-button (click)="csvNavigate()" target="_blank" class="col-md-12 dilog-button dilog-button1">
                    <fa name="file-excel-o"></fa>&nbsp;XLS
                  </a>
                </mat-list-item>
              </mat-list>
              <mat-divider></mat-divider>
                <mat-list-item style="height: 50px;">
                  <a type="button" mat-raised-button (click)="downloadCanvas($event)" target="_blank" class="btn_filter button_file_exporter">
                    <fa name="file-excel-o"></fa> Export chart
                  </a>
                </mat-list-item>
            </div>
          </div>
          </ng-container>
        </mat-card>
      </div>
      <div class="row inner-page-content of-auto" style="padding-top:0;overflow: auto;">
        <ng-container class="allutilitysloader col-sm-12" *ngIf="isFirstLoading && !isLoading">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        

        <ng-container class="allutilitysloader col-sm-12" *ngIf="isLoading && htmlContet.length < 1">
          <div class="col-12 plr_c25px xs-no-p"><div>
           <img class="center" src="assets/images/Nodata.png">
         </div></div>
        </ng-container>
        
        <ng-container *ngIf="htmlContet.length > 0">
          <div class="col-12" style="margin-bottom: 200px;padding-left:24px;">
            <mat-card class='mat-card' style="padding-top: 10px;" id="contentToConvert">
              <mat-card-content class="reportsTable" style="max-height: none;overflow: hidden;height: auto;margin-bottom: 0;height: 66vh;" >
                <div class="iframe-container2" style="overflow: auto;">
                  <h2>Consumption Report</h2>
                  <div class="" id="pdfTable" #pdfTable>
                    <table class="blueTable">
                      <tr>
                        <td>
                          <div [innerHtml]="safeHTML(myTemplate)">
                          </div>
                        </td>
                      </tr>
                    </table>
                    <!--<table class="fl-table">
                        <thead>
                            <tr>
                              <th *ngFor="let item of toshowIndexs">
                                {{item}}
                              </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let cons of htmlContet; let i = index" >
                          <td *ngFor="let item of toshowKeys">
                            {{cons[item]}}
                          </td>
                        </tr>
                          </tbody>
                    </table>-->
                    <canvas baseChart width="400" height="100"
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    [plugins]="lineChartPlugins" style="background-color: #fff;" *ngIf="lineChartData.length > 0">
                  </canvas>
                </div>
              </div>
              
              </mat-card-content>
             
            </mat-card>
          </div>
        </ng-container>
        <ng-container *ngIf="myTemplate.length > 0">
          <mat-paginator 
          #meterpaginator 
          [pageSize]="reportlimit" 
          [pageSizeOptions]="getPageSizeOptions()" 
          [length]="reportTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons
          style="margin-top: -16px">
           </mat-paginator>
      </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- content viewport ends -->