<div class="mat-drawer-content">
  <div>
    <div class="col-sm-12 topnav">
        <nav class="navbar navbar-expand-md navbar-dark" style="background-color: #333;color: #fff !important; padding: 0;">
            <a href="#login" data-toggle="modal" data-target="#loginmodal"><mat-icon matPrefix>input</mat-icon>&nbsp;Login</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="collapsibleNavbar">
              <ul class="navbar-nav">
                <li class="nav-item">
                    <div><a class="active navbar-brand" href="#aboutus"><mat-icon matPrefix>business</mat-icon>&nbsp;About Us</a></div>
                </li>
                <li class="nav-item">
                    <div><a href="#policy"><mat-icon matPrefix>policy</mat-icon>&nbsp;Privacy Policy</a></div>
                </li>    
                <li class="nav-item">
                    <div><a href="#contactus"><mat-icon matPrefix>contact_support</mat-icon>&nbsp;Contact Us</a></div>
                </li>  
                <li class="nav-item">
                    <div>
                        <a href = "https://play.google.com/store/apps/details?id=com.avonmeters.app&hl=en_IN" target = "_blank"> 
                            <img src = "../../assets/images/google-play.png" alt = "AvonSM" width="130" height="30"> 
                         </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div>
                        <a href = "https://apps.apple.com/in/app/xenius-sems/id1510122504" target = "_blank"> 
                            <img src = "../../assets/images/app-store.png" alt = "AvonSM" width="130" height="30"> 
                         </a>
                    </div>
                </li>    
              </ul>
            </div>  
        </nav>
    </div>

</div>  
  <!--<div class="col-lg-4 col-md-6 col-sm-8 col-10 mx-auto">-->
  <div class="">
   
    <div class="col-sm-12">
      <div class="modal-content-new">
       <!-- <div class="modal-header">
          <h3 class="modal-title" id="modal-basic-title">
            <mat-icon matPrefix>business</mat-icon>&nbsp;About Us</h3>
        
        </div>-->
        
        <div class="style">
      
          <div>
            <b>AVON METERS</b> is a Private Limited <b>ISO 9001</b> Certified Company incorporated under the Companies act, 1956 and was registered with the Registrar of Companies, Jalandhar on 21-06-95.  The company provides Energy Metering Solutions to the State Electricity Boards with its product range of <b>ISI Marked</b> High Precision Electromechanical & Electronic Energy Meters. The company has a modern, spacious and dust poof complex at Dera Bassi (Near Chandigarh). Its treatment plant, workshop, assembly hall, testing area, R & D Lab and administrative block etc. have been planned in a covered area of about 45,000 Sq. feet to ensure smooth working at all stages of production on Flow Line Method.  The company is fully equipped with sophisticated machinery and testing instruments required for maintaining high standards of quality & accuracy. The testing instruments and reference standards are kept in air conditioned labs/testing areas.  The vital meter components are subjected to rigorous on-line quality control for ensuring high level of acceptability and minimizing of defects at the early stages of production.
            <br><br>
            With the ushering in of the power sector reforms and in the new environment of opportunity for the power sector, Avon Meters has quickly adapted to this challenge & transformed itself in a short span of time.  Our supply of meters is not confined to Punjab alone but flexes itself to states like Haryana, Karnataka, Kerala, Tamil Nadu, Rajasthan, Maharashtra, Assam, Madhya Pradesh, Chattisgarh, Chandigarh etc. thus truly making our reach nation-wide and justifying the trust and faith customers place in us. Our tireless efforts have been amply rewarded as we have been approved on DGS&D Rate Contract and short listed by Power Finance Corporation.   
            <br><br>
            <b>Avon Meters is committed to provide superior quality products and we have been awarded the year 2002 "National Award" for Quality Products in Small Scale Industries and also "Special Recognition Award" for Entrepreneurship. These awards are instituted by Small Scale Industries, Govt. of India and were presented by the Hon'ble Prime Minister Dr. Manmohan Singh in a special function held on 30th August 2004 at Vigyan Bhawan in New Delhi. </b>
            <br><br>
            In addition to above, we have also been Awarded the "Rashtriya Udyog Ratan Award" by the Indian Organization for Business Research & Development New Delhi and the "PRIDE OF INDIA AWARD" by the Global Economic Council, New Delhi.
            <br><br>
            The management believes in bringing the global best practices in manufacturing of Energy Meters and become a world-class utility. Avon meters aims to achieve excellence in service, quality, reliability, safety and customer care, which will help us, progress consistently and achieve high growth with the highest levels of productivity.  Adequate steps are continuously taken to modernize our unit. We always try to keep our selves abreast with the latest technology and try to implement the same in our unit also.
            Our vision is to maintain leadership in the field of energy meters through continuous research. The modern manufacturing facilities at Dera Bassi are established to transform this vision into reality. Contributing to the developing era of information & technology in the country, we are designing innovative meters with features like remote and pre-paid metering. The possibilities of creating world class production facilities in association with some foreign partner are also being explored.  
            Needless to say, the coming years will be more glorious than ever.  
            <br><br>
          </div> 
 </div>
 </div>
   
    </div>
    <div class="col-sm-1">
    </div>
  </div>
</div>
  