<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Accounts:  {{issuedMeters.companyName}}
        </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
        <div class="form-groupp">
            <!-- Grid row -->
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            
                            <input autocapitalize="off" autocomplete="off" autocorrect="off" placeholder="Payment Date (MM/DD/YYYY)" name="payemntDate"
                                formControlName="payemntDate" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aF.payemntDate.errors}" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Payment Date</label>
                            <div *ngIf="submitted && aF.payemntDate.errors" class="invalid-feedback">
                                <div *ngIf="aF.payemntDate.errors.required">
                                    Payment Date is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Total Payment" name="totalPayment" formControlName="totalPayment"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.totalPayment.errors}"
                                required>
                            <i class="fa fa-id-badge icon_innertxtbox"></i>
                            <label>Total Payment</label>
                            <div *ngIf="submitted && aF.totalPayment.errors" class="invalid-feedback">
                                <div *ngIf="aF.totalPayment.errors.required">
                                    Total Payment is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Payment Received" name="amopuntReceived" formControlName="amopuntReceived"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.amopuntReceived.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>Payment Received</label>
                            <div *ngIf="submitted && aF.amopuntReceived.errors" class="invalid-feedback">
                                <div *ngIf="aF.amopuntReceived.errors.required">
                                    Payment Received is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Payment Confirmed" name="amopuntConfirmed" formControlName="amopuntConfirmed"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.amopuntConfirmed.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>Payment Confirmed</label>
                            <div *ngIf="submitted && aF.amopuntConfirmed.errors" class="invalid-feedback">
                                <div *ngIf="aF.amopuntConfirmed.errors.required">
                                    Payment Confirmed is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <!--<div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Payment Status" name="amountStatus"
                                formControlName="amountStatus" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aF.amountStatus.errors }" required>
                                <mat-option value="Y">Done</mat-option>
                                <mat-option value="P">Partial</mat-option>
                                <mat-option value="N">Pending</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Payment Status</label>
                            <div *ngIf="submitted && aF.amountStatus.errors" class="invalid-feedback">
                                <div *ngIf="aF.amountStatus.errors.required">
                                    Payment Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>-->
                
            </div>

            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1" style="width: auto;">
                        <mat-icon>edit</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3" style="width: auto;">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>