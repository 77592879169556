
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-make-recharge-from-subdivision',
  templateUrl: './make-recharge-from-subdivision.component.html',
  styleUrls: ['./make-recharge-from-subdivision.component.scss']
})
export class MakeRechargeFromSubdivisionComponent implements OnInit {

  currentUser: User;
  currentUserSubscription: Subscription;
  isLinear = true;
  rechargeFormGroup: UntypedFormGroup;
  otpFormGroup: UntypedFormGroup;
  userInfo:any = {};
  responseMessage:string = '';
  consFullName:string = '';
  consEmail:string = '';
  consMobileId:string = '';
  responseData:any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.consFullName = this.userInfo.consFullName;
    this.consEmail = this.userInfo.consEmail;
    this.consMobileId = this.userInfo.consMobileId;
    this.rechargeFormGroup = this.formBuilder.group({
      meterId: ['', Validators.required],
      rechargeAmount: ['', Validators.required],
      //transactionId: ['', Validators.required],
      transactionType: ['', Validators.required],
      remark: ['', Validators.required],
    });
    this.otpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });
  }

  get rF() { return this.rechargeFormGroup.controls; }
  get oF() { return this.otpFormGroup.controls; }
   onSubmitRechargeForm(){
      if (this.rechargeFormGroup.invalid) {
        return;
      }
      const formData = new FormData();
      formData.append('subdivId', this.userInfo.sdID);
      formData.append('rechargeAmount', this.rF.rechargeAmount.value);
      formData.append('meterId', this.rF.meterId.value);
      //formData.append('transactionId', this.rF.transactionId.value);
      formData.append('remark', this.rF.remark.value);
      formData.append('authToken', this.currentUser.authToken);
      //request otp post here
      // this.genReqs.formDataPost('/makeRecharge', formData).subscribe(
      //   (res) => {
      //     this.responseMessage = res.message;
      //   },
      //   (err) => console.log(err)
      // );
    }

    processRechage() {
      let body = new URLSearchParams();
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getTariff', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          //console.log(this.responseData.data);
        }else{
          this.responseMessage = this.responseData.message;//this.responseData.isresponse;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.responseMessage = 'Unable to process yours request!';
      });
    }

}