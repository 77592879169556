<form [formGroup]="actionForm" (ngSubmit)="submitForm()">
    <fieldset class="scheduler-border">
        <legend class="scheduler-border">
            {{formName1}}
        </legend>
        <div class="container">
            <div class="row">
                <table>
                    <tr>
                        <td style="width:92%;text-align-last: start;">
                            <B>{{formName2}}</B>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Start Date (MM/YYYY)" name="schedularStartEndDate" autocomplete="off"
                                formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)"
                                bsDatepicker [bsConfig]="bsConfig" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.schedularStartEndDate.errors }" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>New Tariff Activation Date (MM/YYYY)</label>
                            <div *ngIf="aD.schedularStartEndDate.invalid && (aD.schedularStartEndDate.dirty || aD.schedularStartEndDate.touched)"
                                class="alert alert-danger">
                                <div *ngIf="aD.schedularStartEndDate.errors.required">
                                    Tariff Activation is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Start Date (MM/YYYY)" name="pastTeriffDate" autocomplete="off"
                                formControlName="pastTeriffDate"
                                bsDatepicker [bsConfig]="bsConfig" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.pastTeriffDate.errors }" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Old Tariff Date (MM/YYYY)</label>
                            <div *ngIf="aD.pastTeriffDate.invalid && (aD.pastTeriffDate.dirty || aD.pastTeriffDate.touched)"
                                class="alert alert-danger">
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Category" name="consCategory" formControlName="consCategory"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.consCategory.errors }"
                                required>
                                <mat-option value="D(Lpp)">
                                    D(Lpp)
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-list-alt icon_innertxtbox"></i>
                            <label>Consumer Catergory</label>
                            <div *ngIf="submitted && aD.consCategory.errors" class="invalid-feedback">
                                <div *ngIf="aD.consCategory.errors.required">
                                    Consumer Catergory Is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Teriff" name="teriffName" formControlName="teriffName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.teriffName.errors }"
                                required>
                                <mat-option value="0">
                                    Prepaid
                                </mat-option>
                                <mat-option value="1">
                                    Postpaid
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-list-alt icon_innertxtbox"></i>
                            <label>Teriff Name</label>
                            <div *ngIf="submitted && aD.teriffName.errors" class="invalid-feedback">
                                <div *ngIf="aD.teriffName.errors.required">
                                    Teriff Is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <!-- <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Monthly Consumption" name="monthlyConsumption"
                                formControlName="monthlyConsumption" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.monthlyConsumption.errors }" required>
                                <mat-option value="0"> kWh </mat-option>
                                <mat-option value="1"> kVAh </mat-option>
                            </mat-select>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <label>Monthly Consumption</label>
                            <div *ngIf="submitted && aD.monthlyConsumption.errors" class="invalid-feedback">
                                <div *ngIf="aD.monthlyConsumption.errors.required">
                                    Monthly Consumption required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>-->
               <div class="col-sm-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Emergency Credit Limit" name="emergencyCreditLimit"
                                formControlName="emergencyCreditLimit" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.emergencyCreditLimit.errors }" required>
                            <label>
                                Emergency Credit Limit</label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.emergencyCreditLimit.errors" class="invalid-feedback">
                                <div *ngIf="aD.emergencyCreditLimit.errors.required">
                                    Emergency Credit Limit Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Alarm" name="alarm" formControlName="alarm" autocomplete="off"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.alarm.errors }"
                                required>
                            <label>
                                Alarm</label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.alarm.errors" class="invalid-feedback">
                                <div *ngIf="aD.alarm.errors.required">
                                    Alarm Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Energy Charges " name="ebUnitRate" formControlName="ebUnitRate"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.ebUnitRate.errors }" required>
                            <label>
                                Energy Charges <b>(P/kWh)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.ebUnitRate.errors" class="invalid-feedback">
                                <div *ngIf="aD.ebUnitRate.errors.required">
                                    Energy Charges Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>



                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="MVCA Charges " name="mvcaCharges" formControlName="mvcaCharges"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.mvcaCharges.errors }" required>
                            <label>
                                MVCA Charges <b>(P/kWh)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.mvcaCharges.errors" class="invalid-feedback">
                                <div *ngIf="aD.mvcaCharges.errors.required">
                                    MVCA Charges Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="fixedCharges" formControlName="fixedCharges"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.fixedCharges.errors }" required>
                            <label>
                                Fixed Charges <b>(Rs./kWh)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.fixedCharges.errors" class="invalid-feedback">
                                <div *ngIf="aD.fixedCharges.errors.required">
                                    MVCA Charges Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="meterRent" formControlName="meterRent"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.meterRent.errors }" required>
                            <label>
                                Meter Rent <b>(Rs./Month)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.meterRent.errors" class="invalid-feedback">
                                <div *ngIf="aD.meterRent.errors.required">
                                    Meter Rent Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electDutyCharges1"
                                formControlName="electDutyCharges1" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electDutyCharges1.errors }" required>
                            <label>
                                Electricity Duty Charges <b>(0-300 Units)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electDutyCharges1.errors" class="invalid-feedback">
                                <div *ngIf="aD.electDutyCharges1.errors.required">
                                    Electricity Duty Charges less than 300 Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-6 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electDutyCharges2"
                                formControlName="electDutyCharges2" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electDutyCharges2.errors }" required>
                            <label>
                                Electricity Duty Charges <b>(301-N Units)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electDutyCharges2.errors" class="invalid-feedback">
                                <div *ngIf="aD.electDutyCharges2.errors.required">
                                    Electricity Duty Charges Greater that 300 Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
            </div>
           
            <div class="row iconconfigbtn">
                <div class="col-sm-10 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
                    </ng-container>
                   
                    <div role="alert" *ngIf="message.length > 0">
                        <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 text-right">
                    <button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                        (click)="submitForm()">
                        <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoadingz, 'fa-refresh': !isLoadingz}"
                            style="font-size: 15px;"></i>
                        {{button}}
                    </button>
                </div>
            </div>
        </div>
    </fieldset>
</form>