export class ReportsAccess {
        'reportsAuthority' : {
                Active_All_Permission_Access:  0,
                Auth_All_Meter_Installed_Authority:0
                Single_Inst_Parm_Cons_Account_No: 0,
                Single_Inst_Parm_Meter_Unque_Id: 0,
                Single_Inst_Parm_Flat_No_Id: 0,
                Single_Inst_Parm_Meter_Id: 0,
                Single_Inst_Parm_RTC: 0,
                Single_Inst_Parm_Current: 0,
                Single_Inst_Parm_Voltage: 0,
                Single_Inst_Parm_Neutral_Current: 0,
                Single_Inst_Parm_Power_Factor: 0,
                Single_Inst_Parm_Frequency: 0, 
                Single_Inst_Parm_Apperant_Power: 0, 
                Single_Inst_Parm_Active_Power: 0,
                Single_Inst_Parm_Active_Energy_import: 0,
                Single_Inst_Parm_Apperant_Energy_import: 0,
                Single_Inst_Parm_Active_Energy_Export: 0,
                Single_Inst_Parm_Apperant_Energy_Export: 0,
                Single_Inst_Parm_Md_kW: 0,
                Single_Inst_Parm_Md_kW_datetime: 0,
                Single_Inst_Parm_Md_kVA: 0,
                Single_Inst_Parm_Md_kVA_datetime: 0,
                Single_Inst_Com_Power_On_Minute: 0,
                Single_Inst_Com_Tamper_Count: 0,
                Single_Inst_Com_Billing_Count: 0,
                Single_Inst_Com_Programming_Count: 0,
                Single_Inst_Disconnect_Control: 0,
                Single_Inst_LL_Thrash_Hold: 0,

                Single_Inst_Parm_Cons_Account_No_His: 0,
                Single_Inst_Parm_Meter_Unque_Id_His: 0,
                Single_Inst_Parm_Flat_No_Id_His: 0,
                Single_Inst_Parm_Meter_Id_His: 0,
                Single_Inst_Parm_RTC_His: 0,
                Single_Inst_Parm_Current_His: 0,
                Single_Inst_Parm_Voltage_His: 0,
                Single_Inst_Parm_Neutral_Current_His: 0,
                Single_Inst_Parm_Power_Factor_His: 0,
		Single_Inst_Parm_Frequency_His: 0, 
		Single_Inst_Parm_Apperant_P3ower_His: 0, 
		Single_Inst_Parm_Active_Power_His: 0,
		Single_Inst_Parm_Active_Energy_import_His: 0,
		Single_Inst_Parm_Apperant_Energy_import_His: 0,
		Single_Inst_Parm_Active_Energy_Export_His: 0,
		Single_Inst_Parm_Apperant_Energy_Export_His: 0,
		Single_Inst_Parm_Md_kW_His: 0,
		Single_Inst_Parm_Md_kW_datetime_His: 0,
                Single_Inst_Parm_Md_kVA_His: 0,
                Single_Inst_Parm_Md_kVA_datetime_His: 0,
                Single_Inst_Com_Power_On_Minute_His: 0,
                Single_Inst_Com_Tamper_Count_His: 0,
                Single_Inst_Com_Billing_Count_His: 0,
		Single_Inst_Com_Programming_Count_His: 0,
		Single_Inst_Disconnect_Control_His: 0,
		Single_Inst_LL_Thrash_Hold_His: 0
    
        };

        'reportsConsumer' : {
                Active_All_Permission_Access:  0,
                Single_Inst_Parm_Cons_Account_No: 0,
                Single_Inst_Parm_Meter_Unque_Id: 0,
                Single_Inst_Parm_Flat_No_Id: 0,
                Single_Inst_Parm_Meter_Id: 0,
                Single_Inst_Parm_RTC: 0,
                Single_Inst_Parm_Current: 0,
                Single_Inst_Parm_Voltage: 0,
                Single_Inst_Parm_Neutral_Current: 0,
                Single_Inst_Parm_Power_Factor: 0,
                Single_Inst_Parm_Frequency: 0, 
                Single_Inst_Parm_Apperant_Power: 0, 
                Single_Inst_Parm_Active_Power: 0,
                Single_Inst_Parm_Active_Energy_import: 0,
                Single_Inst_Parm_Apperant_Energy_import: 0,
                Single_Inst_Parm_Active_Energy_Export: 0,
                Single_Inst_Parm_Apperant_Energy_Export: 0,
                Single_Inst_Parm_Md_kW: 0,
                Single_Inst_Parm_Md_kW_datetime: 0,
                Single_Inst_Parm_Md_kVA: 0,
                Single_Inst_Parm_Md_kVA_datetime: 0,
                Single_Inst_Com_Power_On_Minute: 0,
                Single_Inst_Com_Tamper_Count: 0,
                Single_Inst_Com_Billing_Count: 0,
                Single_Inst_Com_Programming_Count: 0,
                Single_Inst_Disconnect_Control: 0,
                Single_Inst_LL_Thrash_Hold: 0
    
        };
}