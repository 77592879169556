<div class="">
  <div class="container-fluid innerpage_top page-content-wrapperr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow {{divClass}}">

        <ng-container>
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <h1>All Consumer Complaint List
                <span class="info_icntop ml-1">
                  <i data-toggle="tooltip" data-placement="top" title="All Consumer Complaint List"
                    class="fa fa-info tabula-tooltip "></i>
                </span>
              </h1>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="flex_auth">
                <div class="authsearch" *ngIf="length!=0">
                  <div class="search">
                    <input class="search-txt searchplaceholder" type="text"
                      placeholder="Search by DG Name/Tower Name/Group Name/Date/Authority" value="{{levelSearch}}"
                      (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                    <div class="search-btn nobg">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="length!=0">
                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                    style="color: #000;" class="npMi circlebtn">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage">
                    <div class="topspacemodelgrip">
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <div class="add_rowEgrid">Add Details</div>
                    </div>
                    <mat-list role="list" *ngFor="let item of levelHeadsCols" class="listaddinfogridfull">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                          {{item.label}}</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
                <div>

                  <button type="button" class="npMi editbordr_circlttop mr-3" (click)="addconsumercomplaint()"
                    *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Add_Group_Button">
                    <i class="fa fa-plus"></i>
                  </button>
                  <!-- <button class="npMi circlebtn" type="button" [matMenuTriggerFor]="dcuListMenu">
                    <i class="fa fa-ellipsis-v" data-toggle="modal" data-target="#gridoption"></i>
                  </button> -->
                  <button class="npMi circlebtn" (click)="whichSubDomain()" *ngIf="enableSocietyChange">
                    <i class="fa fa-ellipsis-v" data-toggle="modal"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid inner-page-content">
            <div class="col-12">
              <ng-container class="col-sm-12" *ngIf="loading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
              <div *ngIf="length!=0 && !loading" class="table-responsivee table-responsive_fixed">
                <table id="ExampleMaterialTable" matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                  *ngIf="dataSource.length > 0">

                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let row">

                      <div class="action_iconbtn">
                        <button class="btn_outlineaction" type="button" (click)="editconsumercomplaint(row)"
                          *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Edit_Consumer_Complaint_Button">
                          <span><i class="fa fa-edit"></i></span>
                        </button>
                        <button class="btn_outlineaction" (click)="removelevel(row)" type="button"
                          *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Delete_Consumer_Complaint_Button">
                          <span><i class="fa fa-trash"></i></span>
                        </button>
                      </div>


                      <!-- <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                        <mat-icon class="d-c10">menu_open</mat-icon>
                      </button> 
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                        <mat-list role="list">
                          <mat-list-item role="listitem"
                            *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Edit_Consumer_Complaint_Button">
                            <button type="button" mat-button (click)="editconsumercomplaint(row)"
                              class="btn-block text-left">
                              <mat-icon class="d-c13 authall ">edit</mat-icon> Modify
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem"
                            *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Delete_Consumer_Complaint_Button">
                            <button type="button" mat-button (click)="removelevel(row)" class="btn-block text-left">
                              <mat-icon class="d-c5 authall ">delete</mat-icon> Delete
                            </button>
                          </mat-list-item>
                        </mat-list>
                      </mat-menu>-->
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="srNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sr. No.</th>
                    <td mat-cell *matCellDef="let element; let j = index;" style="text-align: center;">
                      {{element.srNo}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="consFlatNo">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Flat No
                    </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.consFlatNo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="consFullName">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Consumer Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.consFullName}} </td>
                  </ng-container>
                  <!-- complaintType Column -->
                  <ng-container matColumnDef="complaintType">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>Complaint Type</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.complaintType}} </td>
                  </ng-container>
                  <!-- complaintStatus Column -->
                  <ng-container matColumnDef="complaintStatus">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Complaint Status </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.complaintStatus}}
                    </td>
                  </ng-container>
                  <!-- creationDateTime Column -->
                  <ng-container matColumnDef="creationDateTime">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Date & Time
                    </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.creationDateTime}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay; sticky:true" class="tableHeader"></tr>
                  <tr class="tableColumns" mat-row *matRowDef="let element; columns: levelColumnsToDisplay;">
                  </tr>
                </table>
              </div>
              <div *ngIf="loadingNoData">
                <div>
                  <div>
                    <img class="center" src="assets/images/Nodata.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-card-footer>
        <div class="row">
          <div class="col-11" *ngIf="!loadingNoData">
            <mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()" [length]="length"
              (page)="changeMeterPage($event)" showFirstLastButtons>
            </mat-paginator>
          </div>
          <div class="col-1 text-left1">
            <div *ngIf="!loadingNoData">
              <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                  class="fa fa-file-excel-o" style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
            </div>
          </div>
        </div>
      </mat-card-footer>
    </div>

  </div>
</div>