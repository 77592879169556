import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigcurrentthrashold',
  templateUrl: './meterconfigcurrentthrashold.component.html',
  styleUrls: ['./meterconfigcurrentthrashold.component.scss']
})
export class MeterconfigcurrentthrasholdComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      ovth: ['', Validators.required],
      lvth: ['', Validators.required],
      octh: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}

