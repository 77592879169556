import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-authority',
  templateUrl: './add-authority.component.html',
  styleUrls: ['./add-authority.component.scss']
})
export class AddAuthorityComponent implements OnInit {
    faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    authType: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
    authorityDOB = "0000-00-00";

    public authLevelData: any = [];
    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];
    message: string = '';
    authLevelFieldValue: string = '';
    authLevelField: boolean = false;
    isLoading: boolean = false;
    thumb: boolean = false;
    submitted: boolean = false;
    status: boolean = false;
    ///multiselect

    nowDate = new Date();
    authMulti1: boolean = false;
    authMulti2: boolean = false;
    authMulti3: boolean = false;
    authMulti4: boolean = false;
    authMulti5: boolean = false;
    authMulti6: boolean = false;
    authMulti7: boolean = false;
    authMulti8: boolean = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        public modal: NgbActiveModal
      
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        if (this.currentUser.data.userData.authLevel == '7') {
            this.authLevelField = true;
            this.authLevelFieldValue = '7';
        }
       
    }

  ngOnInit() {
    
    if(this.currentUser.data.userData.subdivision=="105"){
        this.status=true;
    }
    console.log("Hello",this.currentUser.data.userData.subdivision);
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;

    if(this.status){
            this.addAuthorityForm = this.formBuilder.group({
            authLevel: [{ value: this.authLevelFieldValue, disabled: this.authLevelField }, Validators.required],
            authFullName: ['', Validators.required],
            authUserName: ['', Validators.required],
            authPassword: ['', Validators.required],
            authEmailId: ['', [Validators.required, Validators.email]],
            //authLandlineNo: ['', Validators.required],
            authLandlineNo: ['',[Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            authMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            //authMobileNo: ['', Validators.required],
            authHomeAddressline1: ['', Validators.required],
            authHomeAddressline2: [''],
            authOfficeAddressline1: ['', Validators.required],
            authOfficeAddressline2: [''],
            authorityActiveStatus: ['', Validators.required],
            authorityDOB: [this.nowDate, Validators.required],
            authType: ['', Validators.required],
            dataArray: ['']
        });
    }else{
        this.addAuthorityForm = this.formBuilder.group({
            authLevel: [{ value: this.authLevelFieldValue, disabled: this.authLevelField }, Validators.required],
            authFullName: ['', Validators.required],
            authUserName: ['', Validators.required],
            authPassword: ['', Validators.required],
            authEmailId: ['', [Validators.required, Validators.email]],
            //authLandlineNo: ['', Validators.required],
            authLandlineNo: ['',[Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            authMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
            //authMobileNo: ['', Validators.required],
            authHomeAddressline1: ['', Validators.required],
            authHomeAddressline2: [''],
            authOfficeAddressline1: ['', Validators.required],
            authOfficeAddressline2: [''],
            authorityActiveStatus: ['', Validators.required],
            authorityDOB: [this.nowDate, Validators.required],
            authoritySMSReceiveStatus: ['', Validators.required],
            dataArray: ['']
        });
    }


    this.authLevelData = [
      {
          id: '0',
          text: 'Select Auth Level'
      },
      {
          id: '1',
          text: 'Auth 1'
      },
      {
          id: '2',
          text: 'Auth 2'
      },
      {
          id: '3',
          text: 'Auth 3'
      },
      {
          id: '4',
          text: 'Auth 4'
      },
      {
          id: '5',
          text: 'Auth 5'
      },
      {
          id: '6',
          text: 'Auth 6'
      },
      {
          id: '7',
          text: 'Auth 7'
      }
  ];
  }

  
  get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        if (this.addAuthorityForm.invalid) {
            this.submitted = true;
            return;
        }

        this.message = '';
        this.isLoading = true;
        this.AddProcess = true;

        let body = new URLSearchParams();
        if (this.authLevelField) {
            body.set('authLevel', '7');
            body.set('subdivId', this.currentUser.data.userData.subdivision);
        } else {
            body.set('authLevel', this.aD.authLevel.value);
            body.set('subdivId', this.authority.sub_dev_id);
        }
        body.set('authFullName', this.aD.authFullName.value);
        body.set('authUserName', this.aD.authUserName.value);
        body.set('authPassword', this.aD.authPassword.value);
        body.set('authEmailId', this.aD.authEmailId.value);
        body.set('authLandlineNo', this.aD.authLandlineNo.value);
        body.set('authMobileNo', this.aD.authMobileNo.value);
        body.set('authHomeAddressline1', this.aD.authHomeAddressline1.value);
        body.set('authHomeAddressline2', this.aD.authHomeAddressline2.value);
        body.set('authOfficeAddressline1', this.aD.authOfficeAddressline1.value);
        body.set('authOfficeAddressline2', this.aD.authOfficeAddressline2.value);
        body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
        if(this.status){
            body.set('authType', this.aD.authType.value);
        }else{
            body.set('authoritySMSReceiveStatus', this.aD.authoritySMSReceiveStatus.value);
        }
        
        body.set('authorityDOB', this.authorityDOB);
        body.set('authToken', this.currentUser.authToken);

        this.AddProcess = true;
        this.genReqs.postReq('/addNewAuth', body).subscribe((result) => {

            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.thumb = false;
            if (this.responseData.success) {
                this.AddProcess = false;
                this.isLoading = false;
                //this.thumb = true;
                this.message = this.responseData.data.message;
                if(this.message.includes("already") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
                    this.thumb = false;
                  }else{
                    this.thumb = true;
                  }
                  setTimeout(() => 
                  {
                    this.modal.close('done');
                  },
                  2000);  
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.AddProcess = false;
            this.message = 'Unable to process yours request!';
        });
    }


    getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.authorityDOB = eyear + '-' + emonth + '-' + eday;
        }
    }

    authLevelChanged(e) {
        this.authMulti1 = false;
        this.authMulti2 = false;
        this.authMulti3 = false;
        this.authMulti4 = false;
        this.authMulti5 = false;
        this.authMulti6 = false;
        this.authMulti7 = false;
        this.authMulti8 = false;
        if (e.value == 1) {
            this.authMulti1 = true;
        }
        if (e.value == 2) {
            this.authMulti2 = true;
        }
        if (e.value == 3) {
            this.authMulti3 = true;
        }
        if (e.value == 4) {
            this.authMulti4 = true;
        }
        if (e.value == 5) {
            this.authMulti5 = true;
        }
        if (e.value == 6) {
            this.authMulti6 = true;
        }
        if (e.value == 7) {
            this.authMulti7 = true;
        }
        if (e.value == 8) {
            this.authMulti8 = true;
        }
    }

    getLabelsListing() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getLevelData', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.authLevelData = this.responseData.data;
               // console.log("leveldata", this.authLevelData);

            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

}
