
<div class="modal-header subpage">
    <div class="col-11">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Meter Name Plate Data{{meterDetail.mID}}
      </h4>
    </div>
    <!--<div class="col-1 text-right">
      <div>
        <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
            class="fa fa-file-excel-o" style="font-size:18px;color:rgb(0, 132, 255);"></i></button>
      </div>
    </div>-->
  </div>
  <div class="modal-body p-1">
          <ng-container>
            <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              <!--<fa-icon size="6x" [icon]="['fas', 'sync']" [spin]="syncRunning"></fa-icon>-->
            </ng-container>
  
            <div id="NamePlateData" class="row mx-auto form-wrapper" *ngIf="isData">
              <div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                  <div class="d_title {{item.bold_class}}">
                      <span><i class="fa fa-bars iconcl"></i></span>{{item.title}}
                  </div>
                  <div class="d_content">
                      <p>{{item.content}}</p>
                  </div>
              </div>
              <div class="col-12 text-right">
                <button type="button"  (click)="modal.close('Ok click')" class="dilog dilog3">
                  <mat-icon class="d-c3">cancel</mat-icon>
                  Exit
                </button>
              </div>
            </div>
  
            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
              <div class="col-sm-12 o_b2">
                <h4><mat-icon matPrefix>thumb_down</mat-icon>Communication channel is busy. Please try again later.</h4>
              </div>
              <!--<div class="col-12 text-right">
                <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                  <mat-icon class="d-c3">cancel</mat-icon>
                  Exit
                </button>
              </div>-->
            </div>
          </ng-container>
      <ng-container col-sm-3>
      </ng-container>
  </div>
    