<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><mat-icon matPrefix>dns</mat-icon>&nbsp;Add DCU Command</h4>
    <button type="button"  (click)="modal.close('Cancel')" class="dilog4 dilog5">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
      <!--<div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>-->
      <div class="form-groupp">
          <!-- Grid row -->
          <div class="form-row">
            <!-- Default input -->
            <mat-form-field class="col-md-6">
              <input matInput placeholder="DCU Sub Division" name="dcuSubDivision" formControlName="dcuSubDivision" >
              <mat-icon matPrefix class="d-c8">account_circle</mat-icon>
              <mat-error *ngIf="aF.dcuSubDivision.hasError('required')">
                DCU Sub Division is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6">
                <input matInput placeholder="Command Code" name="commandCode" formControlName="commandCode" >
                <mat-icon matPrefix class="d-c9">horizontal_split</mat-icon>
                <mat-error *ngIf="aF.commandCode.hasError('required')">
                  Command Code is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6">
                  <input matInput placeholder="Command Name" name="commandName" formControlName="commandName" >
                  <mat-icon matPrefix class="d-c10">description</mat-icon>
                  <mat-error *ngIf="aF.commandName.hasError('required')">
                    Command Name is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
          </div>
          
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="col-12 row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>
      </div>
  </div>
  </form>