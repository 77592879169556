import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EditConsumerProfileComponent } from '../deshboardconsumer/edit-consumer-profile/edit-consumer-profile.component'
import { AddComplaintComponent } from '../deshboardconsumer/add-complaint/add-complaint.component'
import { ConsumerNotificationsComponent } from '../deshboardconsumer/consumer-notifications/consumer-notifications.component'
import { ConsumerSetPassComponent } from '../deshboardconsumer/consumer-set-pass/consumer-set-pass.component'
import { ConsumerProfileComponent } from '../deshboardconsumer/consumer-profile/consumer-profile.component'
import { ConsumerContactusComponent } from '../deshboardconsumer/consumer-contactus/consumer-contactus.component'
import { ConsumerComplaintsComponent } from '../deshboardconsumer/consumer-complaints/consumer-complaints.component';
import { CurrentTeriffComponent } from '../deshboardconsumer/current-teriff/current-teriff.component';
import { AuthorityProfileComponent } from '../deshboardauthall/authority-profile/authority-profile.component';
import { AuthorityNotificationsComponent } from '../deshboardauthall/authority-notifications/authority-notifications.component';
import { AuthorityContactusComponent } from '../deshboardauthall/authority-contactus/authority-contactus.component';
import { AuthorityChangepassComponent } from '../deshboardauthall/authority-changepass/authority-changepass.component';
import { ActivityOnMeterComponent } from '../deshboardconsumer/activity-on-meter/activity-on-meter.component';
import { ReportingFromMeterDialogComponent } from '../deshboardconsumer/reporting-from-meter-dialog/reporting-from-meter-dialog.component';
import { NotificationsSettingComponent } from '../deshboardconsumer/notifications-setting/notifications-setting.component';
import { LoadlimiterComponent } from '../deshboardconsumer/loadlimiter/loadlimiter.component';
import { AutheditprofileComponent } from '../deshboardauthall/autheditprofile/autheditprofile.component';
import { PendingpaymentsComponent } from '../deshboard-view/pendingpayments/pendingpayments.component';

@Injectable({
  providedIn: 'root'
})
export class ConsumermodalService {
  constructor(private ngbModal: NgbModal) { }


  //activity on meter
  activityOnMeterDialog(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ActivityOnMeterComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //reporting from  meter
  reportingFromMeterDialog(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ReportingFromMeterDialogComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
  //enable edit profile modal
  /*editConsumerProfile(): Observable<boolean> {
    const modal = this.ngbModal.open(
      EditConsumerProfileComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }*/

  //enable complaints modal
  consumerComplaints(): Observable<boolean> {
    const modal = this.ngbModal.open(
      AddComplaintComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
  //enable notification modal
  consumerNotifications(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConsumerNotificationsComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

    //enable notification modal
    authorityNotifications(): Observable<boolean> {
      const modal = this.ngbModal.open(
        AuthorityNotificationsComponent, { backdrop: 'static', size: 'lg' });
      return from(modal.result).pipe(
        catchError(error => {
          console.warn(error);
          return of(undefined);
        })
      );
    }
   //enable all complaint modal
   /*consumerAllComplaints(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConsumerComplaintsComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }*/

   //enable all complaint modal
   consumerTeriff(): Observable<boolean> {
    const modal = this.ngbModal.open(
      CurrentTeriffComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable all complaint modal
   consumerNotificationSettings(): Observable<boolean> {
    const modal = this.ngbModal.open(
      NotificationsSettingComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable all complaint modal
  loadlimiterSettings(): Observable<boolean> {
    const modal = this.ngbModal.open(
      LoadlimiterComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  

  //enable change password modal
  consumerResetPassword(set): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConsumerSetPassComponent, { backdrop: 'static', size: 'sm' });
      modal.componentInstance.set = set;
      //modal.componentInstance.setcurrentuser = currentUser;  
   
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable change password modal
  authorityResetPassword(set): Observable<boolean> {
    const modal = this.ngbModal.open(
      AuthorityChangepassComponent, { backdrop: 'static', size: 'sm' });
      modal.componentInstance.set = set;  
      //modal.componentInstance.setcurrentuser = currentUser;  
      //console.log("hello2",currentUser);
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  pendingPayments(): Observable<boolean> {
    const modal = this.ngbModal.open(
      PendingpaymentsComponent, { backdrop: 'static', size: 'lg' });
      //modal.componentInstance.set = set;  
      //modal.componentInstance.setcurrentuser = currentUser;  
      //console.log("hello2",currentUser);
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable view profile modal
  consumerProfileView(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConsumerProfileComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
//enable Edit profile modal
  consumerProfileEdit(): Observable<boolean> {
    const modal = this.ngbModal.open(
      EditConsumerProfileComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }


  authorityProfileEdit(): Observable<boolean> {
    const modal = this.ngbModal.open(
      AutheditprofileComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable view profile modal
  authorityProfileView(): Observable<boolean> {
    const modal = this.ngbModal.open(
      AuthorityProfileComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable contactus modal
  consumerContactus(): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConsumerContactusComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  //enable contactus modal
  authorityContactus(): Observable<boolean> {
    const modal = this.ngbModal.open(
      AuthorityContactusComponent, { backdrop: 'static', size: 'lg' });
    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

}