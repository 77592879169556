<div class="">
    <div class="container-fluid innerpage_top page-content-wrapperr">
        <div class="content-viewport">
            <div class="page-content-wrapper-inner outerbox_shadow tblH_pagination">
                <ng-container>
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <h1>Communicated Meters
                                <span class="info_icntop ml-1">
                                    <i data-toggle="tooltip" data-placement="top" title="Non-Comm. Meters"
                                        class="fa fa-info tabula-tooltip "></i>
                                </span>
                            </h1>
                        </div>
                        <div class="col-md-9 col-sm-6">
                            <div class="flex_auth">
                                <div class="d-none d-lg-block">
                                    <div class="innerboxxx col-md-12">
                                        <div class="searchflex">
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Catergory">
                                                <mat-select class="search-txt"
                                                    (selectionChange)="selectChangeCategory(defaultCategory)"
                                                    [(ngModel)]="defaultCategory" data-toggle="tooltip"
                                                    data-placement="top" title="Select Category">
                                                    <mat-option [value]="0">All Category</mat-option>
                                                    <mat-option *ngFor="let td of category" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Circle">
                                                <mat-select class="search-txt"
                                                    (selectionChange)="selectChangeCircle(defaultCircle)"
                                                    [(ngModel)]="defaultCircle" data-toggle="tooltip"
                                                    data-placement="top" title="Select Circle">
                                                    <mat-option [value]="0">All Circle</mat-option>
                                                    <mat-option *ngFor="let dg of circleData" [value]="dg.id">
                                                        {{dg.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Authority">
                                                <mat-select class="search-txt"
                                                    (selectionChange)="selectChangeAuth(defaultAuth)"
                                                    [(ngModel)]="defaultAuth" data-toggle="tooltip" data-placement="top"
                                                    title="Select Authority">
                                                    <mat-option [value]="0">All Authority</mat-option>
                                                    <mat-option *ngFor="let td of authData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Date">
                                                <mat-select class="search-txt"
                                                    (selectionChange)="selectChangeDate(defaultDate)"
                                                    [(ngModel)]="defaultDate" data-toggle="tooltip" data-placement="top"
                                                    title="Select Date">
                                                    <mat-option [value]="0">All Dates</mat-option>
                                                    <mat-option *ngFor="let td of dateData" [value]="td.name">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Sim_Type">
                                                <mat-select class="search-txt"
                                                    (selectionChange)="selectChangeSim(defaultSim)"
                                                    [(ngModel)]="defaultSim" data-toggle="tooltip" data-placement="top"
                                                    title="Select Sim Type">
                                                    <mat-option [value]="0">All Sim Type</mat-option>
                                                    <mat-option *ngFor="let td of simData" [value]="td.id">
                                                        {{td.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                            <div class="search"
                                                *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Date">
                                                <input type="text" class="search-txt searchplaceholderr"
                                                    placeholder="Search Meter No" value="{{meterSearch}}"
                                                    (input)="onMeterSearchChange($event.target.value)"
                                                    autocomplete="off" data-toggle="tooltip" data-placement="top"
                                                    title="Search Meter No">
                                                <a class="search-btn">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="length!=0">
                                    <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base
                                        mat-primary style="color: #000;" class="npMi circlebtn">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </button>
                                    <mat-menu #dcuListMenu="matMenu" xPosition="before"
                                        class="notifications-dropdown modal-contentradius gridinnerpage">
                                        <div class="topspacemodelgrip">
                                            <button type="button" class="dilog4 close_popupbtn">
                                                <mat-icon matPrefix>close</mat-icon>
                                            </button>
                                            <div class="add_rowEgrid">Add Details</div>
                                        </div>
                                        <mat-list role="list" *ngFor="let item of levelHeadsCols"
                                            class="listaddinfogridfull">
                                            <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                                                <mat-checkbox [checked]="item.checked"
                                                    (change)="updateLevelChange($event, i, item)">
                                                    {{item.label}}
                                                </mat-checkbox>
                                            </mat-list-item>
                                        </mat-list>

                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluidd d-block d-lg-none">
                        <div class="">
                            <div class="outerbox_shadoww grid_searchsm">
                                <div id="accordion" class="accordion">
                                    <div class="card mb-1">
                                        <div class="card-header collapsed" data-toggle="collapse"
                                            data-parent="#accordion" href="#collapseThree">
                                            <a class="card-title">
                                                <span class="headngins">Search</span>
                                            </a>
                                        </div>
                                        <div id="collapseThree" class="collapse" data-parent="#accordion">
                                            <div class="card-bodyy">
                                                <div class="innerboxx">
                                                    <div class="search"
                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Catergory">
                                                        <mat-select class="search-txt"
                                                            (selectionChange)="selectChangeCategory(defaultCategory)"
                                                            [(ngModel)]="defaultCategory">
                                                            <mat-option [value]="0">All Category</mat-option>
                                                            <mat-option *ngFor="let td of category" [value]="td.id">
                                                                {{td.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <a class="search-btn">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </div>
                                                    <div class="search"
                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Circle">
                                                        <mat-select class="search-txt"
                                                            (selectionChange)="selectChangeCircle(defaultCircle)"
                                                            [(ngModel)]="defaultCircle">
                                                            <mat-option [value]="0">All Circle</mat-option>
                                                            <mat-option *ngFor="let dg of circleData" [value]="dg.id">
                                                                {{dg.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <a class="search-btn">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </div>
                                                    <div class="search"
                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Authority">
                                                        <mat-select class="search-txt"
                                                            (selectionChange)="selectChangeAuth(defaultAuth)"
                                                            [(ngModel)]="defaultAuth">
                                                            <mat-option [value]="0">All Authority</mat-option>
                                                            <mat-option *ngFor="let td of authData" [value]="td.id">
                                                                {{td.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <a class="search-btn">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </div>
                                                    <div class="search"
                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Date">
                                                        <mat-select class="search-txt"
                                                            (selectionChange)="selectChangeDate(defaultDate)"
                                                            [(ngModel)]="defaultDate">
                                                            <mat-option [value]="0">All Dates</mat-option>
                                                            <mat-option *ngFor="let td of dateData" [value]="td.name">
                                                                {{td.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <a class="search-btn">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </div>
                                                    <div class="search"
                                                        *ngIf="getThrough.authority && getThrough.authority.Auth_Non_Com_Date">
                                                        <input type="text" class="search-txt searchplaceholder"
                                                            placeholder="Search Meter No" value="{{meterSearch}}"
                                                            (input)="onMeterSearchChange($event.target.value)"
                                                            autocomplete="off">
                                                        <a class="search-btn">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid inner-page-content">
                        <div class=" col-12">
                            <ng-container class="col-sm-12" *ngIf="loadingFirstTime">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                            <!--<ng-container class="col-sm-12" *ngIf="loading">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>-->
                            <div *ngIf="length!=0" class="table-responsivee table-responsive_fixed">
                                <table id="ExampleMaterialTable" class="table-bordered table-sm table-hover" mat-table
                                    [dataSource]="dataSource" matSort class="table-bordered table-sm table-hover">
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="srNo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by number" class="first">
                                            Sr. No.
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="first">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.srNo}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.srNo}}
                                            </div>
                                        </td>

                                    </ng-container>


                                    <ng-container matColumnDef="conCircle">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Circle
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conCircle}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conCircle}}
                                            </div>
                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="conDivision">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Division
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conDivision}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conDivision}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conDC">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by DC">
                                            DC
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conDC}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conDC}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conFeeder">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by feeder">
                                            Feeder
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conFeeder}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conFeeder}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conGr">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by GR">
                                            GR
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conGr}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conGr}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conRd">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by RD">
                                            RD
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conRd}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conRd}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conConsumerName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Consumer Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conConsumerName}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conConsumerName}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conIVRS">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            IVRS
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conIVRS}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conIVRS}}
                                            </div>


                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="conAddress">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Address
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.conAddress}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.conAddress}}
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tdStatus">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by TD">
                                            TD Status
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    Temp Disconnect
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                Normal
                                            </div>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="meterId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Meter ID
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.meterId}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.meterId}}
                                            </div>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Category
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.category}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.category}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="meterInstallationDate">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Meter Install D/T
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.meterInstallationDate}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.meterInstallationDate}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastComDateTime">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Last Update D/T
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">


                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.lastComDateTime}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.lastComDateTime}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="simType">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Sim Type
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="led-box" *ngIf="element.simType=='A'">

                                                <img name="Airtel" class="blink-image" src="assets/images/airtel.png"
                                                    style="width: 22px;">
                                            </div>
                                            <div class="led-box" *ngIf="element.simType=='V'">

                                                <img name="VodaPhone" class="blink-image"
                                                    src="assets/images/vodaphone.png" style="width: 22px;">
                                            </div>
                                            <div class="led-box" *ngIf="element.simType=='J'">

                                                <img name="Jio" class="blink-image" src="assets/images/jio.png"
                                                    style="width: 22px;">
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="meterIpAddress">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Meter IpAddress
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.meterIpAddress}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.meterIpAddress}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="location">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Meter location
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <a href="https://www.google.com/maps/dir/?api=1&origin={{element.conCircle}}&destination={{element.location}}&travelmode=driving"
                                                target="_blank">
                                                <img name="VodaPhone" class="blink-image"
                                                    src="assets/images/location.png" style="width: 14px;"
                                                    data-toggle="tooltip" data-placement="top" title="Location">
                                            </a>

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="locationDistance">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by symbol">
                                            Distance
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.locationDistance}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.locationDistance}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Server_date_Time">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by Server D/T">
                                            Server D/T
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.Server_date_Time}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.Server_date_Time}}
                                            </div>


                                        </td>

                                    </ng-container>
                                    <ng-container matColumnDef="authName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by Autohority Name">
                                            Authority Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="third">

                                            <div *ngIf="element.tdStatus=='Y'">
                                                <div style="background-color: red; color: white;">
                                                    {{element.authName}}
                                                </div>
                                            </div>
                                            <div *ngIf="element.tdStatus=='N'">
                                                {{element.authName}}
                                            </div>


                                        </td>

                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay; sticky: true"
                                        class="tableHeader"></tr>
                                    <tr mat-row *matRowDef="let row; columns: levelColumnsToDisplay;"></tr>

                                </table>
                            </div>
                            <div>
                                <div *ngIf="length==0">
                                    <div>
                                        <img class="center" src="assets/images/Nodata.png">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </ng-container>
            </div>
            <mat-card-footer>
                <div class="row">
                    <div class="col-11">
                        <mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()"
                            [length]="length" (page)="changeMeterPage($event)" [showFirstLastButtons]="false"
                            [disabled]="true" [hidePageSize]="true">
                        </mat-paginator>
                    </div>
                    <div class="col-1 text-left1">
                        <div *ngIf="length!=0">
                            <button style="border:0;background: azure;" title="Export To Excel"
                                (click)="exportTable()"><i class="fa fa-file-excel-o"
                                    style="font-size:25px;color:rgb(0, 132, 255)"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-footer>
        </div>
    </div>
</div>