import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editconcomplaint',
  templateUrl: './editconcomplaint.component.html',
  styleUrls: ['./editconcomplaint.component.scss']
})
export class EditconcomplaintComponent implements OnInit {
  isLoading = false;
  thumb = true;
  message ='';
  complaintData: any = {};
  towerData: any = {};
  dgData: any = {};
  groupUid:string = '0';
  groupName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  faSpinner = faSpinner;
  submitted: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    //this.getDgData() ;
    //this.getTowerData() ;
    this.addForm = this.formBuilder.group({
        consFlatNo: [{value: '', disabled: true}, Validators.required],
        consName: [{value: '', disabled: true}, Validators.required],
        consComplaint: [{value: '', disabled: true}, Validators.required],
        consComplaintSubject: [{value: '', disabled: true}, Validators.required],
        consComplaintRevert: ['', Validators.required],
        complaintStatus: ['', Validators.required]
      });
      this.groupUid = this.complaintData.groupId;
      this.groupName = this.complaintData.groupName;
      this.aF.consFlatNo.setValue(this.complaintData.consFlatNo);
      this.aF.consName.setValue(this.complaintData.consFullName);
      this.aF.consComplaint.setValue(this.complaintData.complaintMessage);
      this.aF.consComplaintSubject.setValue(this.complaintData.complaintType);
      this.aF.consComplaintRevert.setValue(this.complaintData.complaintRevert);
      this.aF.complaintStatus.setValue(this.complaintData.complaintStatus);
  }

  get aF() { return this.addForm.controls; }
  onSubmitAddForm(){
    //console.log("in submit function");

      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.message='';
        this.isLoading=true;
      let body = new URLSearchParams();
      body.set('subdivId', this.complaintData.subDivUid);
      body.set('complaintUid', this.complaintData.complaintUniqueId);
      body.set('flatNo', this.complaintData.consFlatNo);

      body.set('consComplaintRevert', this.aF.consComplaintRevert.value);
      body.set('complaintStatus', this.aF.complaintStatus.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateConsumerComplaint', body).subscribe((result)=>{
        this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          if (this.responseData.success) {
            this.thumb = true;
            this.message = this.responseData.data.message;
          }else{
            this.thumb = false;
          }
        //this.apiMessage = this.responseData.data.message;
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }
    
}
