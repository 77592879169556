import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-editsmartmeteraccount',
  templateUrl: './editsmartmeteraccount.component.html',
  styleUrls: ['./editsmartmeteraccount.component.scss']
})
export class EditsmartmeteraccountComponent implements OnInit {


  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;
  issuedMeters:any = {};
  paymentDate:string = '';
  nowDate = new Date();
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      payemntDate: [this.nowDate, Validators.required],
      totalPayment: [{value:'', disabled: true}, Validators.required],
      amopuntReceived: [{value:'', disabled: true}, Validators.required],
      amopuntConfirmed: ['', Validators.required],
    });
    //console.log("in"+this.issuedMeters);
    this.setValue();
  }

  getDOB(pickedDate = new Date()) {
    if (pickedDate) {
        let emonth = pickedDate.getMonth() + 1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();
        this.paymentDate = eyear + '-' + emonth + '-' + eday;
    }
  }

  setValue() {
    //this.aF.payemntDate.setValue(this.issuedMeters.payemntDate);
    //console.log("after"+this.issuedMeters.payemntDate);

    if(this.issuedMeters.payemntDate!=null){
      this.aF.payemntDate.setValue(this.formateDate(this.issuedMeters.payemntDate));
    }
    this.aF.totalPayment.setValue(this.issuedMeters.totalPayment);
    this.aF.amopuntReceived.setValue(this.issuedMeters.paymentMarketReceived);
    this.aF.amopuntConfirmed.setValue(this.issuedMeters.paymentMarketReceived);
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  formateDate(datevalue) {
    let date = new Date(datevalue);
    return (date.getMonth()+1)+'/'+(date.getDate())+'/'+date.getFullYear();
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      /*if (this.aF.amopuntConfirmed.value>this.issuedMeters.paymentMarketReceived) {
        this.confirmationDialogService.confirm('Please check..', 'Confirmed Payment Must Be Lessthan or Equal to Received Payment!')
        .then((confirmed) => {
          if(confirmed) {
            return;
          }
        })
        .catch(() => console.log('no response or closed'));
        return;
      }*/
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('id', this.issuedMeters.cUID);
      body.set('orderId',  this.issuedMeters.meterType);
      body.set('paymentDate', this.paymentDate);
      body.set('companyName',  this.issuedMeters.companyName);
      body.set('totalPayment', this.aF.totalPayment.value);
      body.set('amopuntReceived', this.aF.amopuntReceived.value);
      body.set('amopuntConfirmed', this.aF.amopuntConfirmed.value);
      body.set('paymentConfirmation',  this.issuedMeters.paymentConfirmation);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/editSmartMeterAccounts', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
