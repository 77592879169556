<div class="outerbox_shadow">
    <div class="headerall one_grid mb-3 ">
        <div class="container-fluidd">
            <div class="">
                <div class="outerbox_shadow">
                    <div class="btmcar card mb-0">
                        <div class="col-md-2" style="padding-top: 20px;">
                            <a class="card-title">
                                <span class="headngins pldashboardmap">Installed Meter's Location</span>
                            </a>
                        </div>
                        <div>
                            <ng-container>
                                <form [formGroup]="addAuthorityForm" (ngSubmit)="getMapData()">
                                    <a class="advncsearch" data-toggle="collapse" href="#advancedsearchauthorty"
                                        role="button" aria-expanded="false" aria-controls="advancedsearchauthorty">
                                        Advance Search
                                    </a>
                                    <div class="filter-report-options card_view_boxx reportinfo outerrowdate collapse"
                                        id="advancedsearchauthorty">
                                        <div class="outerrowdateall">
                                            <div class="card-header row">
                                                <div class="col-md-2 col-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input placeholder="latitude" name="latitude"
                                                                formControlName="latitude" class="form-control">
                                                            <i class="fa fa-user icon_innertxtbox"></i>
                                                            <label>Latitude</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input placeholder="longitude" name="longitude"
                                                                formControlName="longitude" class="form-control">
                                                            <i class="fa fa-user icon_innertxtbox"></i>
                                                            <label>Longitude</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input placeholder="radious" name="radious"
                                                                formControlName="radious" class="form-control">
                                                            <i class="fa fa-user icon_innertxtbox"></i>
                                                            <label>Radius</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-6">
                                                    <div class="form-group input-group">
                                                        <span class="has-float-label">
                                                            <input placeholder="Search by Meter No" name="radious" maxlength="7" type="text"
                                                                class="form-control" value="{{levelSearch}}" 
                                                                (input)="onLevelSearchChange($event.target.value)">
                                                            <i class="fa fa-user icon_innertxtbox"></i>
                                                            <label>Search</label>
                                                        </span>
                                                    </div>
                                                </div>
                                               
                                                <div
                                                    class="col-md-6 d-flex align-items-center justify-content-between flex-wrap">
                                                    <div class="exportanimatedgo fadeInLeftgo">
                                                        <button class="ml-2 dilog dilog1" (click)="filterData('1')"
                                                            style="width: 140px;">
                                                            Go
                                                        </button>
                                                    </div>
                                                    <div class="cstmdropdoen dropdown ml-2 exportanimated fadeInLeftt">
                                                        <h2 class="menu-title">Export Data</h2>
                                                        <ul class="menu-dropdown-export">
                                                            <li (click)="csvNavigate()"><i
                                                                    class="fa fa-file-excel-o iconcircle"></i>
                                                                <span>XLS</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-container>
                        </div>

                        <div class="card-bodyy pt-2">
                            <div class="innerboxx">
                                <div class="row block2">
                                    <div class="col-md-12" *ngIf="coords.length==1">
                                        <map [coords]="updateCoordValue()"></map>
                                    </div>
                                    <div class="col-md-12" *ngIf="coords.length>1">
                                        <map [coords]="updateCoordValue()"></map>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>