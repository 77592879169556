import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, generalRequestsService } from '../../../_services';
import { User } from '../../../_models';
import { Subscription } from 'rxjs';
import { Access } from '../../../_models/access';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ModalService } from '../../../_services/modal.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit {
  faSpinner = faSpinner;
  public today = new Date();
  myTemplate: any = "";
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getGothrough : Access;

  dataMeterSource:any = new MatTableDataSource([]);
  isLoading = false;
  isFirstLoading = false;
  limit:number = 20;
  reportlimit:number = 20;
  skip:number = 0;
  reportTotalRecord:number = 0;

  responseData: any;
  message: string = '';

  countryId:any = 0;
  stateId:any = 0;
  utilityId:any = 0;
  circleId:any = 0;
  divisionId:any = 0;
  subdivisionId:any = 0;
  dcu:any = 0;
  siteUrl = this.genReqs.apiPath();
  pdfUrl = '';
  csvUrl = '';

  meterColumnsToDisplay = [
    
  ];
  Consumptionsview = '';
  pickedRange: Date[] = null;
  startDate = '';
  endDate = '';
  reporturl = '';
  monthSelection:any = ''
  yearSelection:any = ''
  htmlContet: any = [];
  submitReport = false;
  ///
  toshowKeys: any = [];
  toshowIndexs: any = [];
  //
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private genReqs:generalRequestsService,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private sanitizer:DomSanitizer
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
  });
  
  this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
  }

  

  ngOnInit() {
    this.countryId = this.currentUser.data.userData.Country || 0;
    this.stateId = this.currentUser.data.userData.State || 0;
    this.utilityId = this.currentUser.data.userData.utility || 0;
    this.circleId = this.currentUser.data.userData.circle || 0;
    this.divisionId = this.currentUser.data.userData.division || 0;
    this.subdivisionId = this.currentUser.data.userData.subdivision || 0;
    this.dcu = this.currentUser.data.userData.dcu || 0;
    /*if(this.getGothrough.consumer.Cons_Meter_Daily_Consum_Report_Access){
      this.Consumptionsview = '1';
    } else if(this.getGothrough.consumer.Cons_Meter_Monthly_Consum_Report_Access) {
      this.Consumptionsview = '2';
    } else if(this.getGothrough.consumer.Cons_Meter_Yearly_Consum_Report_Access){
      this.Consumptionsview = '3';
    }*/
    this.Consumptionsview = '1';
      let authBaseURl = this.authenticationService.getDeshboardRoute();
      if (this.getGothrough.authority){
        this.router.navigate([authBaseURl])
      }
      this.loadReport();
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
}

    /**to get the meter list on click on Dcu */
    loadReport() {
      //this.htmlContet=[];
      this.htmlContet = [];
      if(this.submitReport){
          this.myTemplate = [];
          this.skip=0;
      }
      this.isFirstLoading = true;
      this.isLoading = false;
      let body = new URLSearchParams();
      body.set('sdate', this.startDate.toString());
      body.set('edate', this.endDate.toString());
      body.set('reporttype', this.Consumptionsview.toString());
      body.set('authToken', this.currentUser.authToken);
      body.set('offSet', this.skip.toString());
      body.set('limit', this.reportlimit.toString());
      this.genReqs.postReq('/reports/consEventPagination', body).subscribe((result)=>{
        this.responseData = result;
        this.isLoading = true;
        if(this.responseData.success){
          this.myTemplate=this.responseData.data.html;
          
          this.reportTotalRecord = this.responseData.datalimit.totalResult;
          this.getPageSizeOptions();
          this.htmlContet = this.responseData.data.Data;
          let toshow = this.responseData.datalimit.toshow;
          this.toshowKeys = Object.keys(toshow);
          this.toshowIndexs = Object.values(toshow);
         
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.message = 'Unable to process yours request!';
      });
    }

      //To change meter table length
changeMeterPage(event){
  this.submitReport=false;
  if(event.pageSize !== this.reportlimit){
      this.reportlimit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.loadReport();
    }else{
        this.skip = event.pageSize * event.pageIndex;
        this.loadReport();
    }
}

makemeterreport(row){
  //console.log(row);
}

getDateFromRange(pickedRange: Date[]) {
  let smonth = pickedRange[0].getMonth()+1; //always move 1 value up
  let sday = pickedRange[0].getDate();
  let syear = pickedRange[0].getFullYear();
  this.startDate = syear+'-'+smonth+'-'+sday;
  let emonth = pickedRange[1].getMonth()+1; //always move 1 value up
  let eday = pickedRange[1].getDate();
  let eyear = pickedRange[1].getFullYear();
  this.endDate = eyear+'-'+emonth+'-'+eday;
}

getMonthFromRange(pickedDate = new Date()){
  if (pickedDate) {
    this.monthSelection = pickedDate;
    let smonth = pickedDate.getMonth()+1; //always move 1 value up
    let sday = '01';//pickedDate.getDate();
    let syear = pickedDate.getFullYear();
    this.startDate = syear+'-'+smonth+'-'+sday;
    let emonth = pickedDate.getMonth()+1; //always move 1 value up
    let eday = '01';//pickedDate.getDate();
    let eyear = pickedDate.getFullYear();
    this.endDate = eyear+'-'+emonth+'-'+eday;
  }
}
getMonthEndFromRange(pickedDate = new Date()){
  if (pickedDate) {
    let emonth = pickedDate.getMonth()+1; //always move 1 value up
    let eday = '01';//pickedDate.getDate();
    let eyear = pickedDate.getFullYear();
    this.endDate = eyear+'-'+emonth+'-'+eday;
  }
}

getYearFromRange(pickedDate = new Date()) {
  if(pickedDate) {
    let smonth = '01'; //always move 1 value up
    let sday = '01';//pickedDate.getDate();
    let syear = pickedDate.getFullYear();
    this.startDate = syear+'-'+smonth+'-'+sday;
    let emonth = '12';
    let eday = '01';//pickedDate.getDate();
    let eyear = pickedDate.getFullYear();
    this.endDate = eyear+'-'+emonth+'-'+eday;
    this.yearSelection = pickedDate;
  }
}

getYearEndFromRange(pickedDate = new Date()) {
  if(pickedDate) {
    let emonth = '01';
    let eday = '01';//pickedDate.getDate();
    let eyear = pickedDate.getFullYear();
    this.endDate = eyear+'-'+emonth+'-'+eday;
  }
}


filterData() {
  this.submitReport=true;
  this.loadReport();

}

myLoadEvent() {
  this.isFirstLoading = true;
  this.isLoading = true;
}

clear() {
  //alert("hello");
  this.startDate ='';
  this.endDate ='';
}

pdfNavigate(){
  window.open(this.pdfUrl, "_blank");
}
csvNavigate(){
  let pdfUrl = this.siteUrl+'/reports/consEventPagination?sdate='+this.startDate+'&edate='+this.endDate+'&reporttype='+this.Consumptionsview+'&authToken='+this.currentUser.authToken+'&offSet='+this.skip.toString()+'&limit='+this.reportlimit.toString()+'&pdf=2';
      window.open(pdfUrl);
}

getPageSizeOptions() {
  if (this.reportTotalRecord > 0 && this.reportTotalRecord <= 10) {
    return [this.reportTotalRecord];
  } else if (this.reportTotalRecord > 10 && this.reportTotalRecord <= 20) {
    return [10, this.reportTotalRecord];
  } else if (this.reportTotalRecord > 20 && this.reportTotalRecord <= 40) {
    return [10, 20, this.reportTotalRecord];
  } else if (this.reportTotalRecord > 40 && this.reportTotalRecord <= 80) {
    return [10, 20, 40, this.reportTotalRecord];
  } else if (this.reportTotalRecord > 80 && this.reportTotalRecord <= 100) {
    return [10, 20, 40, 80, this.reportTotalRecord];
  } else{
      return [10, 20, 40, 80, 100];
  }
}

downloadAsPDF2() {
      let pdfUrl = this.siteUrl+'/reports/consEventPagination?sdate='+this.startDate+'&edate='+this.endDate+'&reporttype='+this.Consumptionsview+'&authToken='+this.currentUser.authToken+'&offSet='+this.skip.toString()+'&limit='+this.reportlimit.toString()+'&pdf=1';
      window.open(pdfUrl);
}
}
