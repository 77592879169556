import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../_models';
import { Subscription, interval } from 'rxjs';
import { Access } from '../../_models/access';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from '../../_services';
import { GridlistService } from '../../_services/gridlist.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ModalService } from '../../_services/modal.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { take } from 'rxjs/operators';
import { ConsumerLoginsComponent } from '../../deshboardconsumer/reports/consumer-logins/consumer-logins.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe, formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from "../TableUtil";
import { IfStmt } from '@angular/compiler';
//import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { data } from '../datasource';
@Component({
  selector: 'app-threeph',
  templateUrl: './threeph.component.html',
  styleUrls: ['./threeph.component.scss']
})
export class ThreephComponent implements OnInit {
  //public pageSettings: PageSettingsModel;
  divClass: any = "tblH_pagination";
  loadingNoData: boolean = false;
  loading: boolean = false;
  loadingFirstTime: boolean = true;
  faSpinner = faSpinner;
  currentUser: User;
  default_level: number = 0;
  default_DG: number = 0;
  default_tower: number = 0;
  default_group: number = 0;
  currentUserSubscription: Subscription;
  users: User[] = [];
  matHeader: any;
  allUsersLablesData: any;
  responseData: any;
  allutilitys: {};
  length = 0;
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId: any = 0;
  stateId: any = 0;
  utilityId: any = 0;
  circleId: any = 0;
  divisionId: any = 0;
  subdivisionId: any = 0;
  breakpoint: number;
  closeResult: string;
  clickedMeterRow: any;
  accesLevel = 0;
  getThrough: Access;
  isDataRedyForTableDcu: boolean = false;
  dcuOffset: number = 0;
  dcuLimit: number = 0;
  dcuTotalRecord: number = 0;
  meterTotalRecord: number = 0;

  bCumCountry: any = '';
  bCumState: any = '';
  bCumUtlity: any = '';
  bCumCircle: any = '';
  bCumDivision: any = '';
  bCumSubDivision: any = '';

  limit: number = 20;
  meterlimit: number = 5;
  allmeterlimit: number = 20;
  skip: number = 0;
  totalLength: number = 0;
  //pageIndex: number = 0;
  pageLimit: number[] = [5, 10];
  meterpageLimit: number[] = [5, 10, 15];
  allmeterpageLimit: number[] = [10, 15, 20];
  dcuMeterSearch: string = '';
  dcuSearch: string = '';
  meterSearch: string = '';
  removeProcess = false;
  page: any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  isMeterDataLoad = false;
  dataSource: any = new MatTableDataSource([]);
  dataMeterSource: any = new MatTableDataSource([]);
  dataAllMeterSource: any = new MatTableDataSource([]);

  groupData: any = [{}];
  towerData: any = [{
    'towerId': 0,
    'subDivId': 0,
    'dgId': 0,
    'dgName': "dgName",
    'towerName': "Select Tower"
  }];
  dgData: any = [{
    'dgId': 0,
    'subDivId': 0,
    'dgName': 'Select DG'
  }];

  filterData: any = [


    { id: "2", name: "Active Meters" },
    { id: "3", name: "Inactive Meters" },
    { id: "12", name: "Power Off" },

  ];

  displayedColumns: string[] = [
    'select',
    'dcuCode',
    'dcuRTC',
    'dcuGatewayStrength',
    'ebdgStatusMode',
    'ebStatus',
    'dgStatus',
    'dcuActivationDatetime',
    'dcuActiveStatus',
    'edit',
  ];

  meterColumnsToDisplay = [];

  dcuHeadsCols = [
    { label: "select", name: "select", checked: false },
    { label: "ID", name: "dcuID", checked: false },
    { label: "Code", name: "dcuCode", checked: true },
    { label: "Name", name: "dcuName", checked: true },
    { label: "DCU RTC", name: "dcuRTC", checked: true },
    { label: "Location", name: "gisLocation", checked: false },
    { label: "DCU Gateway Strength", name: "dcuGatewayStrength", checked: true },
    { label: "Status Mode", name: "ebdgStatusMode", checked: true },
    { label: "EB Status", name: 'ebStatus', checked: true },
    { label: "DG Status", name: 'dgStatus', checked: true },
    { label: "SIM No", name: "dcuSIMNo", checked: false },

    { label: "DCU Activation Time", name: "dcuActivationDatetime", checked: true },
    { label: "DCU Activate Status", name: "dcuActiveStatus", checked: true },
    { label: "DCU Activation Status", name: "dcuActivationStatus", checked: false },
    { label: "Action", name: "edit", checked: true },
  ];

  meterColumnsToDisplay1 = "srNo";
  meterColumnsToDisplay2 = "cACNO";
  meterColumnsToDisplay3 = "muID";
  meterColumnsToDisplay4 = "flatNo";
  meterColumnsToDisplay5 = "mID";
  meterColumnsToDisplay6 = "mIPAdd";
  meterColumnsToDisplay48 = "hesId";
  meterColumnsToDisplay7 = "mType";
  meterColumnsToDisplay8 = "mLLEBSupply";
  meterColumnsToDisplay9 = "mLLDGSupply";
  meterColumnsToDisplay10 = "mRTC";
  meterColumnsToDisplay11 = "activeEnergyEB";
  meterColumnsToDisplay12 = "activeEnergyDG";




  meterColumnsToDisplay14 = "currentRunningMode";

  meterColumnsToDisplay16 = "recentLsDateTime";
  meterColumnsToDisplay17 = "recentBlockLsDateTime";
  meterColumnsToDisplay18 = "mActiveStatus";
  meterColumnsToDisplay19 = "deadMeters";
  meterColumnsToDisplay56 = "fotaStatus";
  meterColumnsToDisplay20 = "edit";
  meterColumnsToDisplay46 = "tamperCount";
  meterColumnsToDisplay47 = "billingCount";
  meterColumnsToDisplay21 = "voltR";
  meterColumnsToDisplay22 = "voltY";
  meterColumnsToDisplay23 = "voltB";
  meterColumnsToDisplay24 = "phaseCurrentR";
  meterColumnsToDisplay25 = "phaseCurrentY";
  meterColumnsToDisplay26 = "phaseCurrentB";



  meterColumnsToDisplay33 = "totalPF";


  meterColumnsToDisplay40 = "reactiveEnergyEBQ1";
  meterColumnsToDisplay41 = "apperantEnergyEB";
  meterColumnsToDisplay42 = "apperantEnergyDG";
  meterColumnsToDisplay43 = "reactiveEnergyEBQ2";
  meterColumnsToDisplay44 = "reactiveEnergyEBQ3";
  meterColumnsToDisplay45 = "reactiveEnergyEBQ4";

  meterColumnsToDisplay49 = "dgName";
  meterColumnsToDisplay50 = "towerName";
  meterColumnsToDisplay51 = "groupName";
  meterColumnsToDisplay52 = "antennaPos";
  meterColumnsToDisplay53 = "gprsSimType";
  meterColumnsToDisplay54 = "backupStatus";
  meterColumnsToDisplay57 = "lastMdResetDt";

  meterHeads1 = { label: "Sr. No", name: "srNo", checked: true };
  meterHeads2 = { label: "Cons. UId", name: "cACNO", checked: false };
  meterHeads49 = { label: "DG Id", name: "dgName", checked: false };
  meterHeads50 = { label: "Tower Id", name: "towerName", checked: false };
  meterHeads51 = { label: "Group Id", name: "groupName", checked: false };
  meterHeads3 = { label: "Meter UId", name: "muID", checked: false };
  meterHeads4 = { label: "Flat Id", name: "flatNo", checked: false };
  meterHeads5 = { label: "Meter No", name: "mID", checked: false };
  meterHeads6 = { label: "Meter IP", name: "mIPAdd", checked: false };
  meterHeads48 = { label: "HES Id", name: "hesId", checked: false };
  meterHeads7 = { label: "Meter Type", name: "mType", checked: false };
  meterHeads8 = { label: "LL Grid(kW)", name: "mLLEBSupply", checked: false };
  meterHeads9 = { label: "LL DG(kW)", name: "mLLDGSupply", checked: false };
  meterHeads10 = { label: "Last Update", name: "mRTC", checked: false };

  meterHeads21 = { label: "Voltage-R", name: "voltR", checked: false };
  meterHeads22 = { label: "Voltage-Y", name: "voltY", checked: false };
  meterHeads23 = { label: "Voltage-B", name: "voltB", checked: false };
  meterHeads24 = { label: "Phase Currren-R", name: "phaseCurrentR", checked: false };
  meterHeads25 = { label: "Phase Currren-Y", name: "phaseCurrentY", checked: false };
  meterHeads26 = { label: "Phase Currren-B", name: "phaseCurrentB", checked: false };
  meterHeads27 = { label: "Neutral Current", name: "ncR", checked: false };
  //meterHeads28 = { label: "Neutral Current-Y", name: "ncY", checked: false };
  //meterHeads29 = { label: "Neutral Current-B", name: "ncB", checked: false };
  meterHeads30 = { label: "PF-R", name: "pfR", checked: false };
  meterHeads31 = { label: "PF-Y", name: "pfY", checked: false };
  meterHeads32 = { label: "PF-B", name: "pfB", checked: false };
  meterHeads33 = { label: "Total PF", name: "totalPF", checked: false };
  meterHeads34 = { label: "Frequency", name: "frequency", checked: false };
  meterHeads35 = { label: "kW Power", name: "kWHPower", checked: false };
  meterHeads36 = { label: "kVAr Power", name: "kvarhPower", checked: false };
  meterHeads37 = { label: "kVA Power", name: "kvahPower", checked: false };
  meterHeads38 = { label: "MD kW", name: "mdKW", checked: false };
  meterHeads39 = { label: "MD kVA", name: "mdKva", checked: false };

  meterHeads11 = { label: "Grid kWh", name: "activeEnergyEB", checked: false };
  meterHeads40 = { label: "Grid kVArh Q1", name: "reactiveEnergyEBQ1", checked: false };
  meterHeads43 = { label: "Grid kVArh Q2", name: "reactiveEnergyEBQ2", checked: false };
  meterHeads44 = { label: "Grid kVArh Q3", name: "reactiveEnergyEBQ3", checked: false };
  meterHeads45 = { label: "Grid kVArh Q4", name: "reactiveEnergyEBQ4", checked: false };
  meterHeads41 = { label: "Grid kVAh", name: "apperantEnergyEB", checked: false };
  meterHeads12 = { label: "DG kWh", name: "activeEnergyDG", checked: false };
  meterHeads42 = { label: "DG kVAh", name: "apperantEnergyDG", checked: false };
  meterHeads13 = { label: "Balance", name: "availableAmount", checked: false };



  meterHeads14 = { label: "Supply", name: "currentRunningMode", checked: false };
  meterHeads15 = { label: "Tamper", name: 'tamperStatus', checked: false };
  meterHeads16 = { label: "Last LS D/T", name: 'recentLsDateTime', checked: false };
  meterHeads17 = { label: "Last Block LS D/T", name: 'recentBlockLsDateTime', checked: false };
  meterHeads46 = { label: "Tamper Count", name: "tamperCount", checked: false };
  meterHeads47 = { label: "Billing Count", name: "billingCount", checked: false };
  meterHeads52 = { label: "Antenna Position", name: 'antennaPos', checked: false };
  meterHeads53 = { label: "Sim Provider", name: 'gprsSimType', checked: false };
  meterHeads18 = { label: "Relay Status", name: 'mActiveStatus', checked: false };
  meterHeads19 = { label: "Live Status", name: 'deadMeters', checked: false };
  meterHeads56 = { label: "Fota Status", name: 'fotaStatus', checked: false };
  meterHeads57 = { label: "Last Billing D/T", name: 'lastMdResetDt', checked: false };
  meterHeads54 = { label: "Backup Status", name: 'backupStatus', checked: false };
  meterHeads20 = { label: "Action", name: "edit", checked: false };

  meterHeadsCols = [];

  allmeterTotalRecord: any = 0;
  isallMeterDataLoad: boolean = false;
  allMeterMessages: any = false;
  tabToShow: number = 3;//no tab to show
  pageRefresh: any = 20;

  date1 = "2019-05-01 00:00:00";
  date2 = "2019-05-01 12:10:00";

  diffInMs: any = 0;
  diffInHours: any = 0;
  col0 = 2;
  col1 = 2;
  col2 = 2;
  col3 = 2;
  col4 = 2;
  col5 = 2;
  col6 = 1;
  authLevel: number = 0;
  meterHeads2Check = true;
  public data: object[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private gridlistService: GridlistService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });


    //this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    //this.pageRefresh = this.currentUser.data.userData.pageLoadDuration;
    //this.pageRefresh = (this.pageRefresh * 1000);
  }

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('meterpaginator', { static: false }) meterpaginator: MatPaginator;
  @ViewChild('allmeterpaginator', { static: false }) allmeterpaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit() {
    this.data = data;
    //this.pageSettings = { pageSize: 6 };
    //console.log("currentUser", this.currentUser);
    this.meterHeadsCols.push(this.meterHeads1);
    this.meterColumnsToDisplay.push(this.meterColumnsToDisplay1);

    this.meterHeads1 = { label: "Sr. No", name: "srNo", checked: true };
    if (this.getThrough.authority.Auth_All_Cons_UId_Column_View != 0) {
      this.meterHeads2 = { label: "Cons. UId", name: "cACNO", checked: true };
    } else {
      this.meterHeads2 = { label: "Cons. UId", name: "cACNO", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Cons_DG_Column_View != 0) {
      this.meterHeads49 = { label: "DG Id", name: "dgName", checked: true };
    } else {
      this.meterHeads49 = { label: "DG Id", name: "dgName", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Cons_Tower_Column_View != 0) {
      this.meterHeads50 = { label: "Tower Id", name: "towerName", checked: true };
    } else {
      this.meterHeads50 = { label: "Tower Id", name: "towerName", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Cons_Group_Column_View != 0) {
      this.meterHeads51 = { label: "Group Id", name: "groupName", checked: true };
    } else {
      this.meterHeads51 = { label: "Group Id", name: "groupName", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Meter_UId_Column_View != 0) {
      this.meterHeads3 = { label: "Meter UId", name: "muID", checked: true };
    } else {
      this.meterHeads3 = { label: "Meter UId", name: "muID", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Flat_Id_Column_View != 0) {
      this.meterHeads4 = { label: "House No", name: "flatNo", checked: true };
    } else {
      this.meterHeads4 = { label: "House No", name: "flatNo", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Meter_No_Column_View != 0) {
      this.meterHeads5 = { label: "Meter No", name: "mID", checked: true };
    } else {
      this.meterHeads5 = { label: "Meter No", name: "mID", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Meter_Ip_Column_View != 0) {
      this.meterHeads6 = { label: "Meter IP", name: "mIPAdd", checked: true };
    } else {
      this.meterHeads6 = { label: "Meter IP", name: "mIPAdd", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Hes_Id_Column_View != 0) {
      this.meterHeads48 = { label: "HES Id", name: "hesId", checked: true };
    } else {
      this.meterHeads48 = { label: "HES Id", name: "hesId", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Meter_Type_Column_View != 0) {
      this.meterHeads7 = { label: "Meter Type", name: "mType", checked: true };
    } else {
      this.meterHeads7 = { label: "Meter Type", name: "mType", checked: false };
    }
    if (this.getThrough.authority.Auth_All_LL_Grid_kW_Column_View != 0) {
      this.meterHeads8 = { label: "LL Grid(kW)", name: "mLLEBSupply", checked: true };
    } else {
      this.meterHeads8 = { label: "LL Grid(kW)", name: "mLLEBSupply", checked: false };
    }

    if (this.getThrough.authority.Auth_All_LL_DG_kW_Column_View != 0) {
      this.meterHeads9 = { label: "LL DG(kW)", name: "mLLDGSupply", checked: true };
    } else {
      this.meterHeads9 = { label: "LL DG(kW)", name: "mLLDGSupply", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Last_Update_Column_View != 0) {
      this.meterHeads10 = { label: "Last Update", name: "mRTC", checked: true };
    } else {
      this.meterHeads10 = { label: "Last Update", name: "mRTC", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Voltage_R_Column_View != 0) {
      this.meterHeads21 = { label: "Voltage-R", name: "voltR", checked: true };
    } else {
      this.meterHeads21 = { label: "Voltage-R", name: "voltR", checked: true };
    }
    if (this.getThrough.authority.Auth_All_Grid_Voltage_Y_Column_View != 0) {
      this.meterHeads22 = { label: "Voltage-Y", name: "voltY", checked: false };
    } else {
      this.meterHeads22 = { label: "Voltage-Y", name: "voltY", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Voltage_B_Column_View != 0) {
      this.meterHeads23 = { label: "Voltage-B", name: "voltB", checked: false };
    } else {
      this.meterHeads23 = { label: "Voltage-B", name: "voltB", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Phase_Current_R_Column_View != 0) {
      this.meterHeads24 = { label: "Phase Currren-R", name: "phaseCurrentR", checked: true };
    } else {
      this.meterHeads24 = { label: "Phase Currren-R", name: "phaseCurrentR", checked: true };
    }
    if (this.getThrough.authority.Auth_All_Grid_Phase_Current_Y_Column_View != 0) {
      this.meterHeads25 = { label: "Phase Currren-Y", name: "phaseCurrentY", checked: false };
    } else {
      this.meterHeads25 = { label: "Phase Currren-Y", name: "phaseCurrentY", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Phase_Current_B_Column_View != 0) {
      this.meterHeads26 = { label: "Phase Currren-B", name: "phaseCurrentB", checked: false };
    } else {
      this.meterHeads26 = { label: "Phase Currren-B", name: "phaseCurrentB", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Nutural_Current_R_Column_View != 0) {
      this.meterHeads27 = { label: "Neutral Current", name: "ncR", checked: true };
    } else {
      this.meterHeads27 = { label: "Neutral Current", name: "ncR", checked: false };
    }
    /*if (this.getThrough.authority.Auth_All_Grid_Nutural_Current_Y_Column_View != 0) {
      this.meterHeads28 = { label: "Neutral Current-Y", name: "ncY", checked: true };
    } else {
      this.meterHeads28 = { label: "Neutral Current-Y", name: "ncY", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Nutural_Current_B_Column_View != 0) {
      this.meterHeads29 = { label: "Neutral Current-B", name: "ncB", checked: true };
    } else {
      this.meterHeads29 = { label: "Neutral Current-B", name: "ncB", checked: false };
    }*/
    if (this.getThrough.authority.Auth_All_Grid_PF_R_Column_View != 0) {
      this.meterHeads30 = { label: "PF-R", name: "pfR", checked: true };
    } else {
      this.meterHeads30 = { label: "PF-R", name: "pfR", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_PF_Y_Column_View != 0) {
      this.meterHeads31 = { label: "PF-Y", name: "pfY", checked: false };
    } else {
      this.meterHeads31 = { label: "PF-Y", name: "pfY", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_PF_B_Column_View != 0) {
      this.meterHeads32 = { label: "PF-B", name: "pfB", checked: false };
    } else {
      this.meterHeads32 = { label: "PF-B", name: "pfB", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Total_Power_Factor_Column_View != 0) {
      this.meterHeads33 = { label: "Total PF", name: "totalPF", checked: true };
    } else {
      this.meterHeads33 = { label: "Total PF", name: "totalPF", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Frequency_Column_View != 0) {
      this.meterHeads34 = { label: "Frequency", name: "frequency", checked: true };
    } else {
      this.meterHeads34 = { label: "Frequency", name: "frequency", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Active_Power_Column_View != 0) {
      this.meterHeads35 = { label: "kW Power", name: "kWHPower", checked: true };
    } else {
      this.meterHeads35 = { label: "kW Power", name: "kWHPower", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Reactive_Power_Column_View != 0) {
      this.meterHeads36 = { label: "kVAr Power", name: "kvarhPower", checked: true };
    } else {
      this.meterHeads36 = { label: "kVAr Power", name: "kvarhPower", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_ApperantPower_kVA_Column_View != 0) {
      this.meterHeads37 = { label: "kVA Power", name: "kvahPower", checked: true };
    } else {
      this.meterHeads37 = { label: "kVA Power", name: "kvahPower", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Md_Kw_Column_View != 0) {
      this.meterHeads38 = { label: "MD kW", name: "mdKW", checked: true };
    } else {
      this.meterHeads38 = { label: "MD kW", name: "mdKW", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_Md_kVA_Column_View != 0) {
      this.meterHeads39 = { label: "MD kVA", name: "mdKva", checked: true };
    } else {
      this.meterHeads39 = { label: "MD kVA", name: "mdKva", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kWH_Column_View != 0) {
      this.meterHeads11 = { label: "Grid kWh", name: "activeEnergyEB", checked: true };
    } else {
      this.meterHeads11 = { label: "Grid kWh", name: "activeEnergyEB", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kVARH_Q1_Column_View != 0) {
      this.meterHeads40 = { label: "Grid kVArh Q1", name: "reactiveEnergyEBQ1", checked: true };
    } else {
      this.meterHeads40 = { label: "Grid kVArh Q1", name: "reactiveEnergyEBQ1", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kVARH_Q2_Column_View != 0) {
      this.meterHeads43 = { label: "Grid kVArh Q2", name: "reactiveEnergyEBQ2", checked: true };
    } else {
      this.meterHeads43 = { label: "Grid kVArh Q2", name: "reactiveEnergyEBQ2", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kVARH_Q3_Column_View != 0) {
      this.meterHeads44 = { label: "Grid kVArh Q3", name: "reactiveEnergyEBQ3", checked: true };
    } else {
      this.meterHeads44 = { label: "Grid kVArh Q3", name: "reactiveEnergyEBQ3", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kVARH_Q4_Column_View != 0) {
      this.meterHeads45 = { label: "Grid kVArh Q4", name: "reactiveEnergyEBQ4", checked: true };
    } else {
      this.meterHeads45 = { label: "Grid kVArh Q4", name: "reactiveEnergyEBQ4", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Grid_kVAH_Column_View != 0) {
      this.meterHeads41 = { label: "Grid kVAh", name: "apperantEnergyEB", checked: true };
    } else {
      this.meterHeads41 = { label: "Grid kVAh", name: "apperantEnergyEB", checked: false };
    }
    if (this.getThrough.authority.Auth_All_DG_kWH_Column_View != 0) {
      this.meterHeads12 = { label: "DG kWh", name: "activeEnergyDG", checked: true };
    } else {
      this.meterHeads12 = { label: "DG kWh", name: "activeEnergyDG", checked: false };
    }
    if (this.getThrough.authority.Auth_All_DG_kVAH_Column_View != 0) {
      this.meterHeads42 = { label: "DG kVAh", name: "apperantEnergyDG", checked: true };
    } else {
      this.meterHeads42 = { label: "DG kVAh", name: "apperantEnergyDG", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Balance_Column_View != 0) {
      this.meterHeads13 = { label: "Balance", name: "availableAmount", checked: true };
    } else {
      this.meterHeads13 = { label: "Balance", name: "availableAmount", checked: false };
    }

  
  

    if (this.getThrough.authority.Auth_All_Supply_Column_View != 0) {
      this.meterHeads14 = { label: "Supply", name: "currentRunningMode", checked: true };
    } else {
      this.meterHeads14 = { label: "Supply", name: "currentRunningMode", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Tamper_Column_View != 0) {
      this.meterHeads15 = { label: "Tamper", name: 'tamperStatus', checked: true };
    } else {
      this.meterHeads15 = { label: "Tamper", name: 'tamperStatus', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Last_LS_DT_Column_View != 0) {
      this.meterHeads16 = { label: "Last LS D/T", name: 'recentLsDateTime', checked: true };
    } else {
      this.meterHeads16 = { label: "Last LS D/T", name: 'recentLsDateTime', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Last_Block_LS_DT_Column_View != 0) {
      this.meterHeads17 = { label: "Last Block LS D/T", name: 'recentBlockLsDateTime', checked: true };
    } else {
      this.meterHeads17 = { label: "Last Block LS D/T", name: 'recentBlockLsDateTime', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Tamper_Count_Column_View != 0) {
      this.meterHeads46 = { label: "Tamper Count", name: "tamperCount", checked: true };
    } else {
      this.meterHeads46 = { label: "Tamper Count", name: "tamperCount", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Billng_Count_Column_View != 0) {
      this.meterHeads47 = { label: "Billing Count", name: "billingCount", checked: true };
    } else {
      this.meterHeads47 = { label: "Billing Count", name: "billingCount", checked: false };
    }
    if (this.getThrough.authority.Auth_All_Antenna_Column_View != 0) {
      this.meterHeads52 = { label: "Antenna Position", name: 'antennaPos', checked: true };
    } else {
      this.meterHeads52 = { label: "Antenna Position", name: 'antennaPos', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Sim_Type_Column_View != 0) {
      this.meterHeads53 = { label: "Sim Provider", name: 'gprsSimType', checked: true };
    } else {
      this.meterHeads53 = { label: "Sim Provider", name: 'gprsSimType', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Relay_Status_Column_View != 0) {
      this.meterHeads18 = { label: "Relay Status", name: 'mActiveStatus', checked: true };
    } else {
      this.meterHeads18 = { label: "Relay Status", name: 'mActiveStatus', checked: false };
    }

    if (this.getThrough.authority.Auth_All_DG_Supply_Status_Column != 0) {
      this.meterHeads54 = { label: "Backup Status", name: 'backupStatus', checked: true };
    } else {
      this.meterHeads54 = { label: "Backup Status", name: 'backupStatus', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Live_Status_Column_View != 0) {
      this.meterHeads19 = { label: "Live Status", name: 'deadMeters', checked: true };
    } else {
      this.meterHeads19 = { label: "Live Status", name: 'deadMeters', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Live_Status_Column_View != 0) {
      this.meterHeads56 = { label: "Fota Status", name: 'fotaStatus', checked: true };
    } else {
      this.meterHeads56 = { label: "Fota Status", name: 'fotaStatus', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Last_LS_DT_Column_View != 0) {
      this.meterHeads57= { label: "Last Billing D/T", name: 'lastMdResetDt', checked: true };
    } else {
      this.meterHeads57 = { label: "Last Billing D/T", name: 'lastMdResetDt', checked: false };
    }
    if (this.getThrough.authority.Auth_All_Action_Column_View != 0) {
      this.meterHeads20 = { label: "Action", name: "edit", checked: true };
    } else {
      this.meterHeads20 = { label: "Action", name: "edit", checked: false };
    }


    this.authLevel = parseInt(this.currentUser.data.userData.authLevel);
    if (this.authLevel < 7) {
      this.meterHeadsCols.push(this.meterHeads1);
      this.meterHeadsCols.push(this.meterHeads2);
      this.meterHeadsCols.push(this.meterHeads3);
      this.meterHeadsCols.push(this.meterHeads4);
      this.meterHeadsCols.push(this.meterHeads5);
      this.meterHeadsCols.push(this.meterHeads6);
      this.meterHeadsCols.push(this.meterHeads48);
      this.meterHeadsCols.push(this.meterHeads7);
      this.meterHeadsCols.push(this.meterHeads8);
      this.meterHeadsCols.push(this.meterHeads9);
      this.meterHeadsCols.push(this.meterHeads10);
      this.meterHeadsCols.push(this.meterHeads21);
      this.meterHeadsCols.push(this.meterHeads22);
      this.meterHeadsCols.push(this.meterHeads23);
      this.meterHeadsCols.push(this.meterHeads24);
      this.meterHeadsCols.push(this.meterHeads25);
      this.meterHeadsCols.push(this.meterHeads26);
      this.meterHeadsCols.push(this.meterHeads27);
      //this.meterHeadsCols.push(this.meterHeads28);
      //this.meterHeadsCols.push(this.meterHeads29);
      this.meterHeadsCols.push(this.meterHeads30);
      this.meterHeadsCols.push(this.meterHeads31);
      this.meterHeadsCols.push(this.meterHeads32);
      this.meterHeadsCols.push(this.meterHeads33);
      this.meterHeadsCols.push(this.meterHeads34);
      this.meterHeadsCols.push(this.meterHeads35);
      this.meterHeadsCols.push(this.meterHeads36);
      this.meterHeadsCols.push(this.meterHeads37);
      this.meterHeadsCols.push(this.meterHeads38);
      this.meterHeadsCols.push(this.meterHeads39);
      this.meterHeadsCols.push(this.meterHeads11);
      this.meterHeadsCols.push(this.meterHeads40);
      this.meterHeadsCols.push(this.meterHeads43);
      this.meterHeadsCols.push(this.meterHeads44);
      this.meterHeadsCols.push(this.meterHeads45);
      this.meterHeadsCols.push(this.meterHeads41);
      this.meterHeadsCols.push(this.meterHeads12);
      this.meterHeadsCols.push(this.meterHeads42);
      this.meterHeadsCols.push(this.meterHeads13);
     

     
      this.meterHeadsCols.push(this.meterHeads14);
      this.meterHeadsCols.push(this.meterHeads15);
      this.meterHeadsCols.push(this.meterHeads16);
      this.meterHeadsCols.push(this.meterHeads17);
      this.meterHeadsCols.push(this.meterHeads57);
      this.meterHeadsCols.push(this.meterHeads46);
      this.meterHeadsCols.push(this.meterHeads47);
      this.meterHeadsCols.push(this.meterHeads18);
      this.meterHeadsCols.push(this.meterHeads19);
      this.meterHeadsCols.push(this.meterHeads56);
      this.meterHeadsCols.push(this.meterHeads20);

      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay1);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay2);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay3);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay4);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay5);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay6);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay48);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay7);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay8);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay9);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay10);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay11);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay12);


     
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay14);
     
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay16);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay17);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay57);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay18);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay19);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay20);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay46);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay47);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay21);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay22);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay23);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay24);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay25);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay26);

      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay33);

      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay40);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay41);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay42);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay43);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay44);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay45);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay49);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay50);
      this.meterColumnsToDisplay.push(this.meterColumnsToDisplay51);

    } else {
      if (this.getThrough.authority.Auth_All_DG_Filter &&
        this.getThrough.authority.Auth_All_Tamper_Filter &&
        this.getThrough.authority.Auth_All_Group_Filter &&
        this.getThrough.authority.Auth_All_Meter_Filter &&
        this.getThrough.authority.Auth_All_Search_Meter_Id_Filter) {
        this.col1 = 2;
        this.col2 = 2;
        this.col3 = 2;
        this.col4 = 2;
        this.col5 = 2;

      }
      if (!this.getThrough.authority.Auth_All_DG_Filter) {
        if (this.getThrough.authority.Auth_All_Tamper_Filter &&
          this.getThrough.authority.Auth_All_Group_Filter &&
          this.getThrough.authority.Auth_All_Meter_Filter &&
          this.getThrough.authority.Auth_All_Search_Meter_Id_Filter) {
          this.col1 = 0;
          this.col2 = 2;
          this.col3 = 2;
          this.col4 = 2;
          this.col5 = 3;

        }
      }
      if (!this.getThrough.authority.Auth_All_DG_Filter &&
        !this.getThrough.authority.Auth_All_Tamper_Filter) {
        if (this.getThrough.authority.Auth_All_Group_Filter &&
          this.getThrough.authority.Auth_All_Meter_Filter &&
          this.getThrough.authority.Auth_All_Search_Meter_Id_Filter) {
          this.col1 = 0;
          this.col2 = 0;
          this.col3 = 2;
          this.col4 = 3;
          this.col5 = 3;

        }
      }
      if (!this.getThrough.authority.Auth_All_DG_Filter &&
        !this.getThrough.authority.Auth_All_Tamper_Filter &&
        !this.getThrough.authority.Auth_All_Group_Filter) {
        if (this.getThrough.authority.Auth_All_Meter_Filter &&
          this.getThrough.authority.Auth_All_Search_Meter_Id_Filter) {
          this.col1 = 0;
          this.col2 = 0;
          this.col3 = 0;
          this.col4 = 3;
          this.col5 = 3;

        }
      }
      if (!this.getThrough.authority.Auth_All_DG_Filter &&
        !this.getThrough.authority.Auth_All_Tamper_Filter &&
        !this.getThrough.authority.Auth_All_Group_Filter &&
        !this.getThrough.authority.Auth_All_Meter_Filter) {
        if (this.getThrough.authority.Auth_All_Search_Meter_Id_Filter) {
          this.col1 = 0;
          this.col2 = 0;
          this.col3 = 0;
          this.col4 = 0;
          this.col5 = 6;

        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Cons_UId_Column) {
        this.meterHeadsCols.push(this.meterHeads2)

        if (this.meterHeads2.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay2);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Cons_DG_Column) {
        this.meterHeadsCols.push(this.meterHeads49);
        if (this.meterHeads49.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay49);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Cons_Tower_Column) {
        this.meterHeadsCols.push(this.meterHeads50);
        if (this.meterHeads50.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay50);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Cons_Group_Column) {
        this.meterHeadsCols.push(this.meterHeads51);
        if (this.meterHeads51.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay51);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_UId_Column) {
        this.meterHeadsCols.push(this.meterHeads3);
        if (this.meterHeads3.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay3);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Flat_Id_Column) {
        this.meterHeadsCols.push(this.meterHeads4);
        if (this.meterHeads4.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay4);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_No_Column) {
        this.meterHeadsCols.push(this.meterHeads5);
        if (this.meterHeads5.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay5);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Ip_Column) {
        this.meterHeadsCols.push(this.meterHeads6);
        if (this.meterHeads6.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay6);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Hes_Id_Column) {
        this.meterHeadsCols.push(this.meterHeads48);
        if (this.meterHeads48.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay48);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Type_Column) {
        this.meterHeadsCols.push(this.meterHeads7);
        if (this.meterHeads7.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay7);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_LL_Grid_kW_Column) {
        this.meterHeadsCols.push(this.meterHeads8);
        if (this.meterHeads8.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay8);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_LL_DG_kW_Column) {
        this.meterHeadsCols.push(this.meterHeads9);
        if (this.meterHeads9.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay9);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Last_Update_Column) {
        this.meterHeadsCols.push(this.meterHeads10);
        if (this.meterHeads10.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay10);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Voltage_R_Column) {
        this.meterHeadsCols.push(this.meterHeads21);
        if (this.meterHeads21.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay21);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Voltage_Y_Column) {
        this.meterHeadsCols.push(this.meterHeads22);
        if (this.meterHeads22.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay22);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Voltage_B_Column) {
        this.meterHeadsCols.push(this.meterHeads23);
        if (this.meterHeads23.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay23);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Phase_Current_R_Column) {
        this.meterHeadsCols.push(this.meterHeads24);
        if (this.meterHeads24.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay24);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Phase_Current_Y_Column) {
        this.meterHeadsCols.push(this.meterHeads25);
        if (this.meterHeads25.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay25);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Phase_Current_B_Column) {
        this.meterHeadsCols.push(this.meterHeads26);
        if (this.meterHeads26.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay26);
        }
      }
      
      /*if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Nutural_Current_Y_Column) {
        this.meterHeadsCols.push(this.meterHeads28);
        if (this.meterHeads28.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay28);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Nutural_Current_B_Column) {
        this.meterHeadsCols.push(this.meterHeads29);
        if (this.meterHeads29.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay29);
        }
      }*/
      
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_Total_Power_Factor_Column) {
        this.meterHeadsCols.push(this.meterHeads33);
        if (this.meterHeads33.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay33);
        }
      }
      

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kWH_Column) {
        this.meterHeadsCols.push(this.meterHeads11);
        if (this.meterHeads11.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay11);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kVARH_Q1_Column) {
        this.meterHeadsCols.push(this.meterHeads40);
        if (this.meterHeads40.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay40);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kVARH_Q2_Column) {
        this.meterHeadsCols.push(this.meterHeads43);
        if (this.meterHeads43.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay43);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kVARH_Q3_Column) {
        this.meterHeadsCols.push(this.meterHeads44);
        if (this.meterHeads44.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay44);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kVARH_Q4_Column) {
        this.meterHeadsCols.push(this.meterHeads45);
        if (this.meterHeads45.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay45);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Grid_kVAH_Column) {
        this.meterHeadsCols.push(this.meterHeads41);
        if (this.meterHeads41.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay41);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_DG_kWH_Column) {
        this.meterHeadsCols.push(this.meterHeads12);
        if (this.meterHeads12.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay12);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_DG_kVAH_Column) {
        this.meterHeadsCols.push(this.meterHeads42);
        if (this.meterHeads42.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay42);
        }
      }

     
    
     

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Supply_Column) {
        this.meterHeadsCols.push(this.meterHeads14);
        if (this.meterHeads14.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay14);
        }
      }
      
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Last_LS_DT_Column) {
        this.meterHeadsCols.push(this.meterHeads16);
        if (this.meterHeads16.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay16);
        }
      }
     
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Last_Block_LS_DT_Column) {
        this.meterHeadsCols.push(this.meterHeads17);
        if (this.meterHeads17.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay17);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Last_LS_DT_Column) {
        this.meterHeadsCols.push(this.meterHeads57);
        if (this.meterHeads57.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay57);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Tamper_Count_Column) {
        this.meterHeadsCols.push(this.meterHeads46);
        if (this.meterHeads46.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay46);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Billng_Count_Column) {
        this.meterHeadsCols.push(this.meterHeads47);
        if (this.meterHeads47.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay47);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Antenna_Column) {
        this.meterHeadsCols.push(this.meterHeads52);
        if (this.meterHeads52.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay52);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Sim_Type_Column) {
        this.meterHeadsCols.push(this.meterHeads53);
        if (this.meterHeads53.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay53);
        }
      }
      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Relay_Status_Column) {
        this.meterHeadsCols.push(this.meterHeads18);
        if (this.meterHeads18.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay18);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_DG_Supply_Status_Column) {
        this.meterHeadsCols.push(this.meterHeads54);
        if (this.meterHeads54.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay54);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Live_Status_Column) {
        this.meterHeadsCols.push(this.meterHeads19);
        if (this.meterHeads19.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay19);
        }
      }

      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Live_Status_Column) {
        this.meterHeadsCols.push(this.meterHeads56);
        if (this.meterHeads56.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay56);
        }
      }


      if (this.getThrough.authority && this.getThrough.authority.Auth_All_Action_Column) {
        this.meterHeadsCols.push(this.meterHeads20);
        if (this.meterHeads20.checked) {
          this.meterColumnsToDisplay.push(this.meterColumnsToDisplay20);
        }
      }
    }
    //console.log("meterHeadsCols", this.meterHeadsCols);
    //console.log("meterColumnsToDisplay", this.meterColumnsToDisplay);




    if (this.currentUser.data.userData.authLevel != '7') {
      this.matHeader = '1';
      this.route.queryParams.subscribe(params => {
        ///this.page = this.genReqs.decryptData(params['page']).toString();
        //if(this.page=='1'){
        this.countryId = this.genReqs.decryptData(params['country']).toString();
        this.stateId = this.genReqs.decryptData(params['state']).toString();
        this.utilityId = this.genReqs.decryptData(params['utility']).toString();
        this.circleId = this.genReqs.decryptData(params['circle']).toString();
        this.divisionId = this.genReqs.decryptData(params['division']).toString();
        this.subdivisionId = this.genReqs.decryptData(params['subdivision']).toString();
        this.getBreadCrums();
      });
    } else {
      this.countryId = this.currentUser.data.userData.Country || 0;
      this.stateId = this.currentUser.data.userData.State || 0;
      this.utilityId = this.currentUser.data.userData.utility || 0;
      this.circleId = this.currentUser.data.userData.circle || 0;
      this.divisionId = this.currentUser.data.userData.division || 0;
      this.subdivisionId = this.currentUser.data.userData.subdivision || 0;
    }
    //console.log("currentUser",this.currentUser);
    this.getDgData();
    this.whichTabToShow(true);
    this.dataSource.paginator = this.paginator;
    this.dataMeterSource.paginator = this.meterpaginator;
    //const source = interval(this.pageRefresh);
   // this.currentUserSubscription = source.subscribe(val => this.whichTabToShow(false));
  }

  ngAfterViewInit() {
    this.dataAllMeterSource.sort = this.sort;
  }


  ngOnDestroy() {
    this.ngModalService.dismissAll();
    this.currentUserSubscription.unsubscribe();
  }

  whichTabToShow(loadingStatus: boolean = false) {
    this.tabToShow = parseInt(this.currentUser.data.userData.tabDisplayScreen);
    if (this.tabToShow == 0) {
      this.loadDcu();
    } else if (this.tabToShow == 1) {
      //console.log("in meters view",loadingStatus);

      this.allMeterView(loadingStatus);

    }
  }

  parseFloat(stringNumber) {
    return parseFloat(stringNumber);
  }

  getBreadCrums() {
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', this.stateId);
    body.set('utilityId', this.utilityId);
    body.set('circleId', this.circleId);
    body.set('divisionId', this.divisionId);
    body.set('subdivisionId', this.subdivisionId);
    body.set('dcuId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNames', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        //console.log("data", this.responseData.data);
        this.bCumCountry = this.responseData.data.country[0].countName;
        this.bCumState = this.responseData.data.state[0].stateName;
        this.bCumUtlity = this.responseData.data.utility[0].utilityName;
        this.bCumCircle = this.responseData.data.circle[0].circleName;
        this.bCumDivision = this.responseData.data.division[0].divisionName;
        this.bCumSubDivision = this.responseData.data.subDivision[0].subDivisionName;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  backToSubDivisionView() {
    this.router.navigate(['/deshauthsubdivision'],
      {
        queryParams:
        {
          'country': this.genReqs.encryptData(this.countryId),
          'state': this.genReqs.encryptData(this.stateId),
          'utility': this.genReqs.encryptData(this.utilityId),
          'circle': this.genReqs.encryptData(this.circleId),
          'division': this.genReqs.encryptData(this.divisionId)
        }
      });
  }

  backToDivisionView() {
    this.router.navigate(['/deshauthdivision'],
      {
        queryParams:
        {
          'country': this.genReqs.encryptData(this.countryId),
          'state': this.genReqs.encryptData(this.stateId),
          'utility': this.genReqs.encryptData(this.utilityId),
          'circle': this.genReqs.encryptData(this.circleId)
        }
      });
  }

  backToCircleView() {
    this.router.navigate(['/deshauthcircle'],
      {
        queryParams:
        {
          'country': this.genReqs.encryptData(this.countryId),
          'state': this.genReqs.encryptData(this.stateId),
          'utility': this.genReqs.encryptData(this.utilityId),
        }
      });
  }

  backToUtilityView() {
    this.router.navigate(['/deshauthutility'],
      {
        queryParams:
        {
          'country': this.genReqs.encryptData(this.countryId),
          'state': this.genReqs.encryptData(this.stateId),
          'utility': this.genReqs.encryptData(this.utilityId),
        }
      });
  }

  backToStateView() {
    this.router.navigate(['/deshauthstates'],
      {
        queryParams:
        {
          'country': this.genReqs.encryptData(this.countryId),
          'state': this.genReqs.encryptData(this.stateId)
        }
      });
  }

  backToCountryView() {
    this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId) } });
  }

  onDcuSearchChange(search) {
    this.dcuSearch = search;
    this.skip = 0;
    this.loadDcu(false);
  }
  onMeterSearchChange(search) {
    this.meterSearch = search;
    this.skip = 0;
    this.allMeterView(false);
  }

  loadDcu(addNew: boolean = false) {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('subDivisionId', this.subdivisionId.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.dcuSearch);
    this.genReqs.postReq('/getDCU', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.isDataRedyForTableDcu = true;
        //this.dcuTotalRecord = this.responseData.datalimit.totalResult;
        this.dataSource = this.responseData.data;
        this.dcuTotalRecord = this.responseData.datalimit.totalResult;
      } else {
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  /**to get the meter list on click on Dcu */
  meterView(row, addNew: boolean = false, filter: boolean = false) {
    this.clickedMeterRow = row;
    if (addNew === false) {
     this.dataMeterSource = [];
    }
    if (!filter) {
      this.meterSearch = '';
      this.isMeterDataLoad = addNew;
    }
    this.loading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.subdivisionId.toString());
    body.set('dcuId', row.dcuID.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.meterlimit.toString());
    body.set('filter', this.meterSearch);
    body.set('meterType','3');
    this.genReqs.postReq('/getMeters', body).subscribe((result) => {
      this.responseData = result;
      this.isMeterDataLoad = true;
      this.loading = false;
      if (this.responseData.success) {

        this.dataMeterSource = this.responseData.data;
        this.meterTotalRecord = this.responseData.datalimit.totalResult;
        if (this.meterTotalRecord == 0) {
          this.loadingNoData = true;
          this.divClass = "tblH_norecord";
        } else {
          this.loadingNoData = false;
        }
        this.date1 = this.responseData.data.mRTCDateTime;
        //console.log("this",this.date1);
        this.getPageSizeOptions();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  /** */
  onDcuMeterSearchChange(search) {
    //this.dcuMeterSearch = search;
    this.meterSearch = search;
    this.skip = 0;
    //console.log(search);
    this.meterView(this.clickedMeterRow, false, true);
  }



  /**to get the meter list on click on Dcu */
  allMeterView(addNew: boolean = false) {
    console.log("in function");
    //this.dataAllMeterSource =[];
    let body = new URLSearchParams();
    this.loading = true;
    this.loadingFirstTime = addNew;
    //console.log("loadingFirstTime",this.loadingFirstTime);
    body.set('subdivId', this.subdivisionId.toString());
    body.set('dcuId', '0');
    body.set('meterId', '0');
    body.set('dgId', this.default_DG.toString());
    body.set('towerId', this.default_tower.toString());
    body.set('groupId', this.default_group.toString());
    body.set('filterType', this.default_level.toString());
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.allmeterlimit.toString());
    body.set('filter', this.meterSearch);
    body.set('meterType','3');
    this.genReqs.postReq('/getMeters', body).subscribe((result) => {
      this.loadingFirstTime = false;
      this.responseData = result;
      this.isDataRedyForTableDcu = true;
      this.loading = false;

      if (this.responseData.success) {
        //this.allmeterTotalRecord = this.responseData.datalimit.totalResult;
        //this.dataAllMeterSource = this.responseData.data;
        this.dataAllMeterSource = new MatTableDataSource(this.responseData.data);
        //console.log(this.dataAllMeterSource);
        this.length = this.responseData.datalimit.totalResult;
        if (this.length == 0) {
          this.loadingNoData = true;
          this.divClass = "tblH_norecord";
        } else {
          this.loadingNoData = false;
        }
        this.dataAllMeterSource.sort = this.sort;
        this.getPageSizeOptions();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.dataAllMeterSource = [];
      this.allMeterMessages = 'Unable to process yours request!';
    });
  }

  selectChangeDG(default_DG) {
    this.default_DG = default_DG;
    this.skip = 0;
    this.meterpaginator.pageIndex = 0;
    this.allMeterView(true);
    //this.powercutchart.destroy();
    //this.getDailyPowerCut();
  }

  selectChangeTower(default_tower) {
    this.default_tower = default_tower;
    this.skip = 0;
    this.meterpaginator.pageIndex = 0;
    this.allMeterView(true);
    //this.powercutchart.destroy();
    //this.getDailyPowerCut();
  }

  selectChangeGroup(default_group) {
    this.default_group = default_group;
    this.skip = 0;
    this.meterpaginator.pageIndex = 0;
    this.allMeterView(true);
    //this.powercutchart.destroy();
    //this.getDailyPowerCut();
  }

  selectChange(default_level) {
    this.default_level = default_level;
    this.skip = 0;
    this.meterpaginator.pageIndex = 0;
    this.allMeterView(true);
    //this.powercutchart.destroy();
    //this.getDailyPowerCut();
  }

  removelevel(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove meter ' + row.complaintUniqueId + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('meterId', row.muID);
          body.set('subDivisionId', row.sdID);
          body.set('meterSerialNo', row.mID);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteMeter', body).subscribe((result) => {
            this.responseData = result;
            this.removeProcess = false;
            this.allMeterMessages = this.responseData.message;
            this.allMeterView();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.allMeterMessages = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }


  //To change meter table length
  changeMeterPage(event) {
    if (event.pageSize !== this.meterlimit) {
      this.meterlimit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.meterView(this.clickedMeterRow, true);
    } else {
      if (this.meterTotalRecord > this.dataMeterSource.length) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.meterView(this.clickedMeterRow, true);
        // }
      }
    }
  }
  //To change meter table length
  changeAllMeterPage(event) {
    if (event.pageSize !== this.allmeterTotalRecord) {
      this.allmeterlimit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.allMeterView(true);
    } else {
      if (this.allmeterTotalRecord > this.dataMeterSource.length) {
        this.skip = event.pageSize * event.pageIndex;
        this.allMeterView(true);
      }
    }
  }

  /*getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 100) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 100 && this.length <= 200) {
      return [10, 20, 40, 80, 100, this.length];
    } else if (this.length > 200 && this.length <= 300) {
      return [10, 20, 40, 80, 100, 200, this.length];
    } else if (this.length > 300 && this.length <= 400) {
      return [10, 20, 40, 80,100, 200, 300, this.length];
    } else if (this.length > 400 && this.length <= 500) {
      return [10, 20, 40, 80,100, 200, 300, 400, this.length];
    } else if (this.length > 400 && this.length <= 600) {
      return [10, 20, 40, 80,100, 200, 300, 400,500, this.length];
    } else if (this.length > 400 && this.length <= 600) {
      return [10, 20, 40, 80,100, 200, 300, 400,500,600, this.length];
    } else if (this.length > 400 && this.length <= 700) {
      return [10, 20, 40, 80,100, 200, 300, 400,500,600,700, this.length];
    } else if (this.length > 400 && this.length <= 800) {
      return [10, 20, 40, 80,100, 200, 300, 400,500,600,700,800, this.length];
    } else {
      return [10, 20, 40, 80, 100,200,300,400,500];
    }
  }*/

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 100) {
      return [10, 20, 40, 80, this.length];
    } else {
      return [10, 20, 40, 80, 100, this.length];
    }
  }



  getDgData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('dgId', '');
    body.set('towerId', '');
    body.set('groupId', '');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDGTowerGroupData', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.dgData = this.responseData.data.dgData;
        this.towerData = this.responseData.data.towerData;
        this.groupData = this.responseData.data.groupData;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }




  //To change DCU table length
  changePage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.loadDcu(true);
    } else {
      if (this.dcuTotalRecord > this.dataSource.length) {
        //if(this.pageIndex < event.pageIndex){
        // next page
        this.skip = event.pageSize * event.pageIndex;
        this.loadDcu(true);
        // }
      }
    }
  }

  //To chnage DCU col
  onChange(event, index, item) {
    item.checked = !item.checked;
    this.displayedColumns = [];
    for (let col of this.dcuHeadsCols) {
      if (col.checked) {
        this.displayedColumns.push(col.name.toString());
      }
    }
  }

  //To chnage DCU col
  updateMeterChange(event, index, item) {
    item.checked = !item.checked;
    this.meterColumnsToDisplay = [];
    for (let col of this.meterHeadsCols) {
      if (col.checked) {
        this.meterColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable", "Deshboard Data");
  }

  addMeter() {
    let authData = {
      "cID": this.currentUser.data.userData.Country,
      "sID": this.currentUser.data.userData.State,
      "uID": this.currentUser.data.userData.utility,
      "ciID": this.currentUser.data.userData.circle,
      "dID": this.currentUser.data.userData.division,
      "sdID": this.currentUser.data.userData.subdivision,
      "dcuID": 0,
    }
    this.modalService.meteraddmeter(authData).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  editMeter(row) {
    this.modalService.metereditmeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterRecharge(row) {
    this.modalService.meterRecharge(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterAdjustment(row) {
    this.modalService.meterAdjustment(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterConfig(row) {
    this.modalService.meterConfigMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterConfigUtility(row) {
      this.modalService.meterConfigMeterUtility(row).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
      });
    //subdivisionaddmeter
  }

  meterConfigUtilityNew(row) {
    this.modalService.meterConfigMeterUtilityNew(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
  //subdivisionaddmeter
}


  meterConfigTeriffUtility(row) {
      this.modalService.meterConfigMeterUtilityIPCL(row).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(result => {
        //console.log({ messageResult: result });
        //this.messageResult = result;
      });
    //subdivisionaddmeter
  }

  meterActivity(row) {
    this.modalService.meterActivityMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterReporting(row) {
    this.modalService.meterReportingMeterDesh(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  /*clearEvents(row) {
    this.modalService.clearTamperData(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }*/

  authRealTimeData(row) {

    this.modalService.realTimeDataMeterAuth(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  namePlateData(row) {

    this.modalService.realTimeDataMeterAuth(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentBill(row) {
    this.modalService.recentBillMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentBills(row) {
    this.modalService.recentBillsMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentBillData(row) {
    this.modalService.recentBillsDataMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  loadLimit(row) {
    this.modalService.recentLoadLimit(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  loadLimitMeter(row) {
    this.modalService.recentLoadLimitMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


  happyHours(row) {
    this.modalService.recentHappyHours(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  happyHoursMeter(row) {
    this.modalService.recentHappyHoursMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  billingSchedler(row) {
    this.modalService.recentBillScheduler(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  relay(row) {
    this.modalService.recentRelayConfig(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  billingSchedlerMeter(row) {
    this.modalService.recentBillSchedulerMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


  recentEvent(row) {
    this.modalService.recentEventMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  
  recentTransactionEvent(row) {
    this.modalService.recentTransactionEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentPowerEvent(row) {
    this.modalService.recentPowerEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentVoltageEvent(row) {
    this.modalService.recentVoltageEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentCurrentEvent(row) {
    this.modalService.recentCurrentEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentControlEvent(row) {
    this.modalService.recentControlEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentNonRollEvent(row) {
    this.modalService.recentNonRollOverEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentOtherEvent(row) {
    this.modalService.recentOtherEvent(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentDipWiseLoadSurvey(row) {
    this.modalService.recentDipWiseLoadSurveyMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


  recentOnGoingConsumption(row) {
    this.modalService.recentOnGoingLoadSurveyMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentDayConsumption(row) {
    this.modalService.recentDayConsumption(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  

  recentDayLoadSurvey(row) {
    this.modalService.recentDayLoadSurveyMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }
  recentOnGoingConsumptionPvt(row) {
    this.modalService.recentOnGoingLoadSurveyMeterPvt(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentPushLog(row) {
    this.modalService.eventPushLog(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentDayLoadSurveyPvt(row) {
    this.modalService.recentDayLoadSurveyMeterPvt(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentNamePlate(row) {
    this.modalService.namePlateData(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentInstPush(row) {
    this.modalService.recentInstPush(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


  recentSmsSent(row) {
    this.modalService.recentSmsSentMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  teriff(row) {
    this.modalService.teriffMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }


  teriffMeter(row) {
    this.modalService.teriffMeterMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  meterConfiguration(row) {
    this.modalService.meterConfigurationMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  lprConfiguration(row) {
    this.modalService.lprConfigurationMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  latestComplaint(row) {
    this.modalService.latestComplaintMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentActivity(row) {
    this.modalService.recentActivityMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentRecharge(row) {
    this.modalService.recentRechargeMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentInstData(row) {
    this.modalService.recentInstData(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentAdjustment(row) {
    this.modalService.recentAdjustmentMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  recentConsumption(row) {
    this.modalService.recentConsumptionMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      // console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

  dcuRemoveView(row) {
    this.modalService.dcuRemoveViewMeter(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      //this.messageResult = result;
    });
    //subdivisionaddmeter
  }

}
