<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c11">credit_card</mat-icon>
        <mat-select placeholder="Emergency Credit Active Status" name="ecas" formControlName="ecas">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let frm of booleanVals" [value]="frm">
                {{frm.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.ecas.hasError('required')">
            ecas is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c11">credit_card</mat-icon>
        <mat-select placeholder="Emergency Credit Giving Freq In Month" name="ecgfm" formControlName="ecgfm">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let mode of monthFreq" [value]="mode">
                {{mode.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.ecgfm.hasError('required')">
            ecgfm is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>