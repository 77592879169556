<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
   <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Revert On Complaint
    </h4>
  </div>
  <div class="modal-body">
    <!--<div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>-->
    <div class="form-groupp">
        <!-- Grid row -->
      <div class="">
        <!-- Default input -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input  placeholder="Flat No" name="consFlatNo" formControlName="consFlatNo" 
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consFlatNo.errors }" required>
                <i class="fa fa-building icon_innertxtbox"></i>
                <label> Consumer Flat No</label>
                <div *ngIf="submitted && aF.consFlatNo.errors" class="invalid-feedback">
                  <div *ngIf="aF.consFlatNo.errors.required">
                    Consumer Flat No is required
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
              <input matInput placeholder="Consumer Name" name="consName" formControlName="consName"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consName.errors }" required>
              <i class="fa fa-user icon_innertxtbox"></i>
                <label>Consumer Name</label>
                <div *ngIf="submitted && aF.consName.errors" class="invalid-feedback">
                  <div *ngIf="aF.consName.errors.required">
                    Consumer Name is required
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
              <input placeholder="Complaint Subject" name="consComplaintSubject" formControlName="consComplaintSubject"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consComplaintSubject.errors }" required>
              <i class="fa fa-comment icon_innertxtbox"></i>
              <label>Consumer Subject</label>
              <div *ngIf="submitted && aF.consComplaintSubject.errors" class="invalid-feedback">
                <div *ngIf="aF.consComplaintSubject.errors.required">
                  Consumer Subject is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
            <textarea  rows="2" [maxLength]="168" matInput placeholder="Consumer Complaint" 
              name="consComplaint" formControlName="consComplaint"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consComplaint.errors }" required></textarea>
              <i class="fa fa-comment icon_innertxtbox"></i>
              <label>Consumer Complaint</label>
              <div *ngIf="submitted && aF.consComplaint.errors" class="invalid-feedback">
                <div *ngIf="aF.consComplaint.errors.required">
                  Consumer Complaint is required
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group input-group">
          <span class="has-float-label">
            <textarea rows="2" [maxLength]="168" placeholder="Complaint Revert"name="consComplaintRevert" 
            formControlName="consComplaintRevert" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consComplaintRevert.errors }" required></textarea>
            <!-- <input matInput placeholder="Complaint Revert" name="consComplaintRevert" formControlName="consComplaintRevert" >-->
            <i class="fa fa-comment icon_innertxtbox"></i>
              <label>Consumer Revert</label>
              <div *ngIf="submitted && aF.consComplaintRevert.errors" class="invalid-feedback">
                <div *ngIf="aF.consComplaintRevert.errors.required">
                  Consumer Revert0 is required
                </div>
              </div>
            </span>
          </div>
        </div>  
        <div class="col-md-6">
          <div class="form-group input-group">
           <span class="has-float-label">
              <mat-select placeholder="Complaint Status" name="complaintStatus" formControlName="complaintStatus"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.complaintStatus.errors }" required>
                <mat-option value="Opened">Opened</mat-option>
                <mat-option value="Under Progress">Under Progress</mat-option>
                <mat-option value="Closed">Closed</mat-option>
              </mat-select>
              <i class="fa fa-comment icon_innertxtbox"></i>
              <label>Consumer Status</label>
              <div *ngIf="submitted && aF.complaintStatus.errors" class="invalid-feedback">
                <div *ngIf="aF.complaintStatus.errors.required">
                  Consumer Status is required
                </div>
              </div>
            </span>
          </div>
        </div> 
      </div> 
    </div>
    
    <!-- Grid row -->
    <div class="row">
      <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
            <div role="alert" *ngIf="message.length > 0" >
              <div class="alert alert-success" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
              </div>
            </div>
          
      </div>
        
      <div class="col-sm-12 text-right">
        <button type="submit" class="dilog dilog1">
          <mat-icon>update</mat-icon>
          Update
        </button>
        <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
          <mat-icon >cancel</mat-icon>
          Cancel
        </button>

      </div>
   
    
      
  </div>
  </div>
  </form>