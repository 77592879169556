import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable()
export class LeftnavService {

    isMenuOpen = true;
    private hideSideNavSubject = new Subject<boolean>();
  
    constructor() {}
  
    toggle(): void {
      //this.hideSideNavSubject.asObservable();
      this.isMenuOpen = !this.isMenuOpen;
      this.hideSideNavSubject.next(this.isMenuOpen);
    }
  
    get(): Observable<boolean> {
      return this.hideSideNavSubject.asObservable();
    }
  }
  