<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c14"> dns </mat-icon>
        <input matInput placeholder="Server IP Address" name="sip" formControlName="sip">
        <mat-error *ngIf="cF.sip.hasError('required')">
            sip is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c14"> dns </mat-icon>
        <input matInput placeholder="Server Port No" name="spno" formControlName="spno">
        <mat-error *ngIf="cF.spno.hasError('required')">
            spno is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>