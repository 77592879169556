
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
          <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
            data-dismiss="modal">&times;</button>
            Add Contractor
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Firm Name" name="contFirmName"
                            formControlName="contFirmName" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contFirmName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Contractor Firm Name</label>
                            <div *ngIf="submitted && aD.contFirmName.errors" class="invalid-feedback">
                                <div *ngIf="aD.contFirmName.errors.required">
                                    Contractor Firm Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Full Name" name="contFullName"
                            formControlName="contFullName" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contFullName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Contractor Full Name</label>
                            <div *ngIf="submitted && aD.contFullName.errors" class="invalid-feedback">
                                <div *ngIf="aD.contFullName.errors.required">
                                    Contractor Full Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Contractor User Name" name="contUserName" formControlName="contUserName" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contUserName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Contractor User Name</label>
                            <div *ngIf="submitted && aD.contUserName.errors" class="invalid-feedback">
                                <div *ngIf="aD.contUserName.errors.required">
                                    Contractor User Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                        <input type="password" placeholder="Contractor Password" name="contPassword"
                            formControlName="contPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contPassword.errors}" required>
                            <i class="fa fa-lock icon_innertxtbox"></i>
                            <label>Contractor Password</label>
                            <div *ngIf="submitted && aD.contPassword.errors" class="invalid-feedback">
                                <div *ngIf="aD.contPassword.errors.required">
                                    Contractor Password is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  type="email"placeholder="Contractor Email Id" name="contEmailId" 
                            formControlName="contEmailId"
                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contEmailId.errors}" required>
                            <i class="fa fa-envelope icon_innertxtbox"></i>
                            <label>Contractor Email Id</label>
                            <div *ngIf="submitted && aD.contEmailId.errors" class="invalid-feedback">
                                <div *ngIf="aD.contEmailId.errors.required">
                                    Contractor Email Id is required
                                </div>
                                <div *ngIf="aD.contEmailId.hasError('email')">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Contractor Landline No" name="contLandlineNo" formControlName="contLandlineNo" 
                            pattern="[0-9 -()+]+$" minlength="10" maxlength="10"class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contLandlineNo.errors}" required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Contractor Landline No</label>
                            <div *ngIf="submitted && aD.contLandlineNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.contLandlineNo.errors.required">
                                    Contractor Landline is required
                                </div>
                                <div *ngIf="aD.contLandlineNo.hasError('pattern')">
                                    Contractor Landline number not valid. 
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel"  placeholder="Contractor Mobile No"  name="contMobileNo"  formControlName="contMobileNo" 
                            pattern="^[6-9]\d{9}$" minlength="10" maxlength="10" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contMobileNo.errors}" required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Contractor Mobile No</label>
                            <div *ngIf="submitted && aD.contMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.contMobileNo.errors.required">
                                    Contractor Mobile No is required
                                </div>
                                <div *ngIf="aD.contMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Home Address Line 1" name="contHomeAddressline1"
                            formControlName="contHomeAddressline1" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contHomeAddressline1.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Contractor Home Address Line 1</label>
                            <div *ngIf="submitted && aD.contHomeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.contHomeAddressline1.errors.required">
                                    Contractor Home Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Home Address Line 2" name="contHomeAddressline2"
                            formControlName="contHomeAddressline2" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contHomeAddressline2.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Contractor Home Address Line 2</label>
                            <div *ngIf="submitted && aD.contHomeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.contHomeAddressline2.errors.required">
                                    Contractor Home Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Office Address Line 1" name="contOfficeAddressline1"
                            formControlName="contOfficeAddressline1" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contOfficeAddressline1.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Contractor Office Address Line 1</label>
                            <div *ngIf="submitted && aD.contOfficeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.contOfficeAddressline1.errors.required">
                                    Contractor Office Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Office Address Line 2" name="contOfficeAddressline2"
                            formControlName="contOfficeAddressline2" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contOfficeAddressline2.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Contractor Office Address Line 2</label>
                            <div *ngIf="submitted && aD.contOfficeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.contOfficeAddressline2.errors.required">
                                    Contractor Office Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="1-PH Installation Rate" name="ph1InstallationRate"
                            formControlName="ph1InstallationRate" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ph1InstallationRate.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>1-PH Installation Rate</label>
                            <div *ngIf="submitted && aD.ph1InstallationRate.errors" class="invalid-feedback">
                                <div *ngIf="aD.ph1InstallationRate.errors.required">
                                    1-PH Installation Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="3-PH Installation Rate" name="ph3InstallationRate"
                            formControlName="ph3InstallationRate" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ph3InstallationRate.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>3-PH Installation Rate</label>
                            <div *ngIf="submitted && aD.ph3InstallationRate.errors" class="invalid-feedback">
                                <div *ngIf="aD.ph3InstallationRate.errors.required">
                                    1-PH Installation Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="LTCT Installation Rate" name="ltctInstallationRate"
                            formControlName="ltctInstallationRate" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ltctInstallationRate.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>LTCT Installation Rate</label>
                            <div *ngIf="submitted && aD.ltctInstallationRate.errors" class="invalid-feedback">
                                <div *ngIf="aD.ltctInstallationRate.errors.required">
                                    LTCT Installation Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="GST No" name="gstNo"
                            formControlName="gstNo" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.gstNo.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>GST No</label>
                            <div *ngIf="submitted && aD.gstNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.gstNo.errors.required">
                                    GST Number is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Avtive Status" name="authorityActiveStatus"
                                formControlName="authorityActiveStatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authorityActiveStatus.errors}" required>
                                <mat-option value="Y">Active</mat-option>
                                <mat-option value="N">In-Active</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Avtive Status</label>
                            <div *ngIf="submitted && aD.authorityActiveStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.authorityActiveStatus.errors.required">
                                    Contractor Active Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authorityDOB.errors}" required>
                                <i class="fa fa-calendar icon_innertxtbox"></i>
                                <label>Authority DOB</label>
                                <div *ngIf="submitted && aD.authorityDOB.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authorityDOB.errors.required">
                                        Authority DOB is required
                                   </div>
                            </div>
                        </span>
                    </div>
                </div>
                    <div class="col-md-6">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="SMS Receive Status" name="authoritySMSReceiveStatus"
                                    formControlName="authoritySMSReceiveStatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authoritySMSReceiveStatus.errors}" required>
                                    <mat-option value="Y">Active</mat-option>
                                    <mat-option value="N">In-Active</mat-option>
                                </mat-select>
                                <i class="fa fa-envelope icon_innertxtbox"></i>
                                <label>SMS Receive Status</label>
                                <div *ngIf="submitted && aD.authoritySMSReceiveStatus.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authoritySMSReceiveStatus.errors.required">
                                        Authority SMS Receive Status is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>-->
           
            <!-- Grid row -->
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                      <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                      <div role="alert" *ngIf="!isLoading && message.length > 0" >
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                          <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                          <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                      </div>
                    
                </div>
                 
                <div class="col-sm-12 text-right">
                  <button type="submit" class="dilog dilog1">
                    <mat-icon>add</mat-icon>
                    Submit
                  </button>
                  <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                    <mat-icon >cancel</mat-icon>
                    Cancel
                  </button>
    
                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->