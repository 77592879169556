import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dcumeteractivity',
  templateUrl: './dcumeteractivity.component.html',
  styleUrls: ['./dcumeteractivity.component.scss']
})
export class DcumeteractivityComponent implements OnInit {
  loading:boolean = false;
  actionForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    private ngmodalService: NgbModal,
  ) { }

  ngOnInit() {
    this.whichSubDomain();
    this.actionForm = this.formBuilder.group({
      servantmode: ['', Validators.required],
      meterload: ['', Validators.required],
      backupsupply: ['', Validators.required],
      restartmeter: ['', Validators.required],
      startfixedchargesonmeter: ['', Validators.required],
      startemergencycredit: ['', Validators.required],
    });
  }
  get aF() { return this.actionForm.controls; }
     
  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
       // console.log('selected val',this.selectedSubDivision);
      }
    );
  }

  ngOnDestroy() {
    this.ngmodalService.dismissAll();
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  onSubmitAddForm(){
    
  }

}
