<!----add authority start-->
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Check Installed Meter
        </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="addAuthMessage.length > 0">{{addAuthMessage}}</div>-->
        <div class="form-groupp">
            <!-- Grid row -->
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    Old Meter Details
                </legend>
                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="IVRS" name="ivrs" formControlName="ivrs" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.ivrs.errors }" required>
                                    <i class="fa fa-microphone icon_innertxtbox"></i>
                                    <label>Account No</label>
                                    <div *ngIf="submitted && aD.ivrs.errors" class="invalid-feedback">
                                        <div *ngIf="aD.ivrs.errors.required">
                                            Account No is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Id" name="oldMeterId" formControlName="oldMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterId.errors }" required>
                                    <i class="fa fa-address-card icon_innertxtbox"></i>
                                    <label>Old Meter ID</label>
                                    <div *ngIf="submitted && aD.oldMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterId.errors.required">
                                            Old Meter ID is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Reading" name="oldMeterReading"
                                        formControlName="oldMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterReading.errors }" required>
                                    <i class="fa fa-check-square-o icon_innertxtbox"></i>
                                    <label>Old Meter Reading</label>
                                    <div *ngIf="submitted && aD.oldMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterReading.errors.required">
                                            Old Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="Old Meter Remark" name="oldMeterRemark"
                                        formControlName="oldMeterRemark" class="form-control">
                                    <i class="fa fa-comment  icon_innertxtbox"></i>
                                    <label>Old Meter Remark</label>
                                </span>
                            </div>
                        </div>
                   
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Old Meter Img Checked Status"
                                        name="oldMeterImgCheckedStatus" formControlName="oldMeterImgCheckedStatus"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterImgCheckedStatus.errors }"
                                        required>
                                        <mat-option value="Y">
                                            Checked
                                        </mat-option>
                                        <mat-option value="N">
                                            Unchecked
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">Old Meter Img Checked Status</label>
                                    <div *ngIf="submitted && aD.oldMeterImgCheckedStatus.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterImgCheckedStatus.errors.required">
                                            Please Select Old Meter Image Checked Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Old Meter Status" name="oldMeterStatus"
                                        formControlName="oldMeterStatus" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.oldMeterStatus.errors }" required>
                                        <mat-option value="Y">
                                            Ok
                                        </mat-option>
                                        <mat-option value="N">
                                            Not Ok
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">Old Meter Status</label>
                                    <div *ngIf="submitted && aD.oldMeterStatus.errors" class="invalid-feedback">
                                        <div *ngIf="aD.oldMeterStatus.errors.required">
                                            Please Select New Meter Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    New Meter Details
                </legend>

                <div class="form-roww">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Id" name="newMeterId" formControlName="newMeterId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterId.errors }" required>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label>New Meter Id</label>
                                    <div *ngIf="submitted && aD.newMeterId.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterId.errors.required">
                                            New MeterId is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter IP Addrss" name="newmterIPAddress"
                                        formControlName="newmterIPAddress" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newmterIPAddress.errors }" required>
                                    <i class="fa fa-server icon_innertxtbox"></i>
                                    <label>New Meter IP Addrss</label>
                                    <div *ngIf="submitted && aD.newmterIPAddress.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newmterIPAddress.errors.required">
                                            New Meter IP address is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Reading" name="newMeterReading"
                                        formControlName="newMeterReading" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterReading.errors }" required>
                                    <i class="fa fa-list icon_innertxtbox"></i>
                                    <label>New Meter Reading</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Remark" name="newMeterRemark"
                                        formControlName="newMeterRemark" class="form-control">
                                    <i class="fa fa-comment icon_innertxtbox"></i>
                                    <label>New Meter Remark</label>
                                    <div *ngIf="submitted && aD.newMeterReading.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterReading.errors.required">
                                            New Meter Reading is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="New Meter Latitude" name="newMeterLat"
                                        formControlName="newMeterLat" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLat.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>New Meter Latitude</label>
                                    <div *ngIf="submitted && aD.newMeterLat.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLat.errors.required">
                                            New Meter Latitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input matInput placeholder="New Meter Longitude" name="newMeterLong"
                                        formControlName="newMeterLong" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterLong.errors }" required>
                                    <i class="fa fa-globe icon_innertxtbox"></i>
                                    <label>New Meter Longitude</label>
                                    <div *ngIf="submitted && aD.newMeterLong.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterLong.errors.required">
                                            New Meter Longitude is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="New Meter Img Checked Status"
                                        name="newMeterImgCheckedStatus" formControlName="newMeterImgCheckedStatus"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterImgCheckedStatus.errors }"
                                        required>
                                        <mat-option value="Y">
                                            Checked
                                        </mat-option>
                                        <mat-option value="N">
                                            unchecked
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">New Meter Img checked Status</label>
                                    <div *ngIf="submitted && aD.newMeterImgCheckedStatus.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="aD.newMeterImgCheckedStatus.errors.required">
                                            Please Select New Meter Img Checked Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="New Meter Status" name="newMeterStatus"
                                        formControlName="newMeterStatus" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.newMeterStatus.errors }" required>
                                        <mat-option value="Y">
                                            Ok
                                        </mat-option>
                                        <mat-option value="N">
                                            Not Ok
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">New Meter Status</label>
                                    <div *ngIf="submitted && aD.newMeterStatus.errors" class="invalid-feedback">
                                        <div *ngIf="aD.newMeterStatus.errors.required">
                                            Please Select New Meter Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="Action" name="tdMeterStatus"
                                        formControlName="tdMeterStatus" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.tdMeterStatus.errors }" required>
                                        <mat-option value="1">
                                            Upload On Hes
                                        </mat-option>
                                        <!--<mat-option value="2">
                                            Update On Hes
                                        </mat-option>
                                        <mat-option value="3">
                                            Delete From Hes
                                        </mat-option>-->
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">Action*</label>
                                    <div *ngIf="submitted && aD.tdMeterStatus.errors" class="invalid-feedback">
                                        <div *ngIf="aD.tdMeterStatus.errors.required">
                                            Please Select Action
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </fieldset>
            <fieldset class="scheduler-border">
                <legend class="scheduler-border">
                    SCO/MCO Check
                </legend>
                <div class="form-roww">
                    <div class="row">
                        
                   
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="SCO/MCO Img Checked Status"
                                        name="mcoCheckedStatus" formControlName="mcoCheckedStatus"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mcoCheckedStatus.errors }"
                                        required>
                                        <mat-option value="Y">
                                            Checked
                                        </mat-option>
                                        <mat-option value="N">
                                            Unchecked
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">SCO/MCO Img Checked Status</label>
                                    <div *ngIf="submitted && aD.mcoCheckedStatus.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="aD.mcoCheckedStatus.errors.required">
                                            Please Select SCO/MCO Image Checked Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-select placeholder="SCO/MCO Status" name="mcoStatus"
                                        formControlName="mcoStatus" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && aD.mcoStatus.errors }" required>
                                        <mat-option value="Y">
                                            Ok
                                        </mat-option>
                                        <mat-option value="N">
                                            Not Ok
                                        </mat-option>
                                    </mat-select>
                                    <i class="fa fa-id-card icon_innertxtbox"></i>
                                    <label [style]="colorValue">SCO/MCO Status</label>
                                    <div *ngIf="submitted && aD.mcoStatus.errors" class="invalid-feedback">
                                        <div *ngIf="aD.mcoStatus.errors.required">
                                            SCO/MCO Status
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <input placeholder="SCO/MCO Remark" name="mcoRemark"
                                        formControlName="mcoRemark" class="form-control">
                                    <i class="fa fa-comment  icon_innertxtbox"></i>
                                    <label>SCO/MCO Remark</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group input-group">
                                <span class="has-float-label">
                                    <mat-checkbox formControlName="checkBox" name="checkBox" (change)="onChange($event)" class="checkBox">Note*:-I hearby authorised to add this meter on HES.</mat-checkbox>
                                    <div *ngIf="submitted && aD.checkBox.errors" class="invalid-feedback">
                                        <div *ngIf="aD.checkBox.errors.required">
                                            Please Select Check Box.
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>edit</mat-icon>
                        Update
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->