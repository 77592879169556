<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
            data-dismiss="modal">&times;</button>
        Configure Meter: {{meterDetail.mID}}
    </h4>
</div>
<div class="modal-body">
    <ng-container class="allutilitysloader col-12" *ngIf="loading">
        <fa name="spinner" animation="spin" size="4x"></fa>
    </ng-container>
    <div class="container">
        <div class="col-md-12">
            <div class="navall_tabblue">
                <nav>
                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a *ngIf="getThrough.authority && getThrough.authority.Utility_Config_Single_Domestic"
                        class="nav-item nav-link" id="nav_domestic" data-toggle="tab" href="#domestic"
                            role="tab" aria-controls="load-limit" [class.active]="defaultTab === 'domestic'" aria-selected="true"> {{formName1}}
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="1-Ph Configuration "></i>
                            </div>
                        </a>
                        <a *ngIf="getThrough.authority && getThrough.authority.Utility_Config_Single_Commercial"
                        class="nav-item nav-link " id="nav_commercial" data-toggle="tab" href="#commercial"
                            role="tab" aria-controls="commercial" [class.active]="defaultTab === 'commercial'" aria-selected="false">{{formName2}}
                            <div class="icntb">
                                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                                    title="1-Ph Configuration"></i>
                            </div>
                        </a>


                    </div>
                </nav>
                <div class="tab-content meterload" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="domestic" role="tabpanel"
                        aria-labelledby="nav_domestic"   [class.show]="defaultTab === 'domestic'" [class.active]="defaultTab === 'domestic'">
                        <app-meterteriff1phdomestic [meterDetail]="meterDetail"></app-meterteriff1phdomestic>
                    </div>
                    <div class="tab-pane fade" id="commercial" role="tabpanel" 
                        aria-labelledby="nav_commercial" [class.show]="defaultTab === 'commercial'" [class.active]="defaultTab === 'commercial'">
                        <app-meterteriff1phcommercial [meterDetail]="meterDetail"></app-meterteriff1phcommercial>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- 
      <ng-container>
              <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c3">
                          flash_on
                        </mat-icon>
                        Set Load Limit On Meter
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfigloadlimit [meterDetail]="meterDetail"></app-meterconfigloadlimit>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c12">
                          add_box
                        </mat-icon>
                        Set Happy Hours On Meter
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfighappyhours [meterDetail]="meterDetail"></app-meterconfighappyhours>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c6">
                          alarm
                        </mat-icon>
                        Set Meter Alarm Level/Emergeny Credit /Billing Tariff
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-meterconfigaeb [meterDetail]="meterDetail"></app-meterconfigaeb>
                  </mat-expansion-panel>
  
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="d-c7">
                          schedule
                        </mat-icon>
                        Meter Bill Date And Time Settings
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                      <app-meterbillschedular [meterDetail]="meterDetail"></app-meterbillschedular>
                  </mat-expansion-panel>
  
                  <div>
                  </div>
                </mat-accordion>
                                
              </div>
      </ng-container> -->
    <ng-container col-sm-3>
    </ng-container>
</div>
<!-- content viewport ends -->