import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-processrefundded',
  templateUrl: './processrefundded.component.html',
  styleUrls: ['./processrefundded.component.scss']
})
export class ProcessrefunddedComponent implements OnInit {
  faSpinner = faSpinner;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';

  responseMessage:string = '';
  towerData: any = {};
  towerUid:string = '0';
  towerName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  submitted: boolean = false;
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  dgNameData:string = '';
  dgIdData:any = 0;
  dgData = [
    {
      dgId: 13,
      dgName: "AVONDG0013",
      creationDateTime: "2020-03-17 12:26:16",
      authName: "Authority1"
    }
  ];
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      dgNameData: ['', Validators.required],
      towerName: ['', Validators.required]
      });
     
      this.dgIdData = this.towerData.dgId;
      this.towerUid = this.towerData.uid;
      this.towerName = this.towerData.towerName;
      
      this.aF.towerName.setValue(this.towerName);
      this.aF.dgNameData.setValue(this.towerData.dgId);
      this.getDgData() ;
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      let body = new URLSearchParams();

      body.set('subdivId',  this.towerData.subDivId);
      body.set('dgId', this.aF.dgNameData.value);
      body.set('towerName', this.aF.towerName.value);
      body.set('towerId', this.towerData.towerId);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateTower', body).subscribe((result)=>{
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.addAuthMessage = this.responseData.data.message;
            setTimeout(() => 
            {
              this.modal.close('done');
            },
            2000);
        }else{
            this.thumb = false;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    getDgData() {
      let body = new URLSearchParams();
      body.set('subdivId',  this.towerData.subDivId);
      body.set('dgId', '0');
      body.set('offSet', '0');
      body.set('limit', '100');
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getDGData', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          setTimeout(() => {
              this.dgData = this.responseData.data;
          });
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

}
