<!----add authority start-->
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Edit AMI Helpdesk Data
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Ticket Id" name="ticketId" formControlName="ticketId"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ticketId.errors }"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Ticket Id</label>
                            <div *ngIf="submitted && aD.ticketId.errors" class="invalid-feedback">
                                <div *ngIf="aD.ticketId.errors.required">
                                    Ticket Id is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="Assigned Name" name="assignedId" formControlName="assignedId" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && aD.assignedId.errors}" required>
                                    <mat-option *ngFor="let auth of assignedData" [value]="auth.id">
                                        {{auth.name}}
                                    </mat-option>
                                </mat-select>
                                <i class="fa fa-database icon_innertxtbox"></i>
                                <label>Assigned Name</label>
                                <div *ngIf="submitted && aD.assignedId.errors" class="invalid-feedback">
                                    <div *ngIf="aD.assignedId.errors.required">
                                        Assigned Name is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Account No" name="accountNo" formControlName="accountNo"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.accountNo.errors }"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Account No</label>
                            <div *ngIf="submitted && aD.accountNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.accountNo.errors.required">
                                    Account No is <strong>required</strong>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Zone" name="zone" formControlName="zone"
                                (selectionChange)="changeZone(defaultZone)" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.zone.errors}" required>
                                <mat-option *ngFor="let auth of zoneData" [value]="auth.id">
                                    {{auth.name}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Zone</label>
                            <div *ngIf="submitted && aD.zone.errors" class="invalid-feedback">
                                <div *ngIf="aD.zone.errors.required">
                                    Zone is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Circle" name="circle" formControlName="circle"
                                (selectionChange)="changeCircle(defaultCircle)" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.circle.errors}" required>
                                <mat-option *ngFor="let auth of circleData" [value]="auth.id">
                                    {{auth.name}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Circle</label>
                            <div *ngIf="submitted && aD.circle.errors" class="invalid-feedback">
                                <div *ngIf="aD.circle.errors.required">
                                    Circle is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Division" name="division" formControlName="division"
                                (selectionChange)="changeDivision(defaultDivision)" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.division.errors}" required>
                                <mat-option *ngFor="let auth of divisionData" [value]="auth.id">
                                    {{auth.name}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Division</label>
                            <div *ngIf="submitted && aD.division.errors" class="invalid-feedback">
                                <div *ngIf="aD.division.errors.required">
                                    division is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Sub-Division" name="subDivision" formControlName="subDivision"
                                (selectionChange)="changeSubDiv(defaultSubDivision)" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.subDivision.errors}" required>
                                <mat-option *ngFor="let auth of subDivisionData" [value]="auth.id">
                                    {{auth.name}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Sub-Division</label>
                            <div *ngIf="submitted && aD.subDivision.errors" class="invalid-feedback">
                                <div *ngIf="aD.subDivision.errors.required">
                                    Sub-Division is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Mewter Id" name="meterId" formControlName="meterId"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.meterId.errors }"
                                required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Meter ID</label>
                            <div *ngIf="submitted && aD.meterId.errors" class="invalid-feedback">
                                <div *ngIf="aD.meterId.errors.required">
                                    Meter ID is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Cons Address" name="consAddress" formControlName="consAddress"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.consAddress.errors }"
                                required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Cons Address</label>
                            <div *ngIf="submitted && aD.consAddress.errors" class="invalid-feedback">
                                <div *ngIf="aD.consAddress.errors.required">
                                    Address is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Cons Mobile No" name="consMobileNo" formControlName="consMobileNo"
                                pattern="^[6-9]\d{9}$" minlength="10" maxlength="10" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.consMobileNo.errors }" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Mobile No</label>
                            <div *ngIf="submitted && aD.consMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.consMobileNo.errors.required">
                                    Mobile No is required
                                </div>
                                <div *ngIf="aD.consMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="JE Mobile No" name="jeMobileNo" formControlName="jeMobileNo"
                                pattern="^[6-9]\d{9}$" minlength="10" maxlength="10" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.jeMobileNo.errors }" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>JE Mobile No</label>
                            <div *ngIf="submitted && aD.jeMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.jeMobileNo.errors.required">
                                    JE Mobile No is required
                                </div>
                                <div *ngIf="aD.jeMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Assignment Status" name="assignMeterStatus"
                                formControlName="assignMeterStatus" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.assignMeterStatus.errors }" required>
                                <mat-option value="Y">Assigned</mat-option>
                                <mat-option value="N">Pending</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Assignment Status</label>
                            <div *ngIf="submitted && aD.assignMeterStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.assignMeterStatus.errors.required">
                                    Assignment Status Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Site Visit Status" name="siteVisitStatus"
                                formControlName="siteVisitStatus" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.siteVisitStatus.errors }" required>
                                <mat-option value="Y">Visited</mat-option>
                                <mat-option value="N">Not Visit</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Site Visit Status</label>
                            <div *ngIf="submitted && aD.siteVisitStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.siteVisitStatus.errors.required">
                                    Site Visit Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="Remark" name="remark" formControlName="remark"
                                    (selectionChange)="changeRemark(defaultRemark)" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && aD.remark.errors}" required>
                                    <mat-option *ngFor="let auth of remarkData" [value]="auth.id">
                                        {{auth.name}}
                                    </mat-option>
                                </mat-select>
                                <i class="fa fa-database icon_innertxtbox"></i>
                                <label>Remark</label>
                                <div *ngIf="submitted && aD.remark.errors" class="invalid-feedback">
                                    <div *ngIf="aD.remark.errors.required">
                                        Remark is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>edit</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->