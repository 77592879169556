<div class="modal-header">
  <mat-icon>chrome_reader_mode</mat-icon><h3>Activity On DCU</h3>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <ng-container>
    <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="d-c3">chrome_reader_mode</mat-icon>
           <h4>Activity On DCU</h4> 
          </ng-template>
          <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()" >

            <div class="col-md-6 form-group input-rounded">
              <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                <mat-checkbox name="RESD" formControlName="RESD">Restart DCU</mat-checkbox>
              
            </div>
            
            <div class="col-md-6 form-group input-rounded">
              <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                <mat-checkbox name="SHDD" formControlName="SHDD">Shutdown DCU</mat-checkbox>
              
            </div>
              <div class="col-md-6 form-group input-rounded">
                <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                Broadcast Demand
                <mat-radio-group name="supply" formControlName="supply">
                    <div>
                      <mat-radio-button value="BGSD">Grid Supply</mat-radio-button>
                    </div>
                    <div>
                      <mat-radio-button value="BBSD">Backup Supply</mat-radio-button>
                    </div> 
                  </mat-radio-group>
              </div>
              <button type="submit" class="btn btn-primary btn-block">
                  Action
                  <img class="pl-3"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </button>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>