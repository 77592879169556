<div class="modal-content-new">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Latest Jobs
        </h4>
    </div>
    <div class="modal-body">
        <ng-container>
            <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
                <!--<fa name="spinner" animation="spin" size="4x"></fa>-->
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>

            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs justify-content-center" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#software" role="tab">
                                Software
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#database" role="tab">
                                Database
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#hardware" role="tab">
                                Hardware
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-bodyy">
                    <div class="tab-content">
                        <div class="tab-pane active" id="software" role="tabpanel">
                            <div class="row table-responsive" *ngIf="isData">
                                <table class="table table-bordered table-sm table-hover">
                                    <tbody class="tbbodystyle jobtblouter">
                                        <tr class="col-sm-12 col-md-6 o_b">
                                            <td *ngFor="let item of letviewis">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <div class="jobtblbox">
                                                                <p class="jobtitle">{{item.jobTitle}}</p>
                                                                <p class="jobCode">Job Code:{{item.jobCode}}
                                                                </p>
                                                                <p>{{item.expMessage}}</p>
                                                                <p>{{item.otherMessage}}</p>
                                                                <p>{{item.keySkill1}}</p>
                                                                <p>{{item.keySkill2}}</p>
                                                                <p>{{item.keySkill3}}</p>
                                                                <p>{{item.keySkill4}}</p>
                                                                <p class="jobexp">{{item.totalExperience}}
                                                                </p>
                                                                <!-- <p>{{item.serverDatetime}}</p> -->
                                                                <!--<p class="jobexp"><a href="mailto:info@avonmeters.com?subject =Job Code:{{item.jobCode}}&body = {{item.expMessage}}" target="_blank">Apply</a></p>-->
                                                                <p class="jobexp"><a
                                                                        (click)="goToLink(item.jobTitle,item.jobCode,item.expMessage,item.otherMessage,item.keySkill1,item.keySkill2,item.keySkill3,item.keySkill4,item.totalExperience,item.serverDatetime)">Apply</a>
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane" id="database" role="tabpanel">
                            <p>Database </p>
                        </div>
                        <div class="tab-pane" id="hardware" role="tabpanel">
                            <p> Hardware</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
                <div class="col-sm-12">
                    <img class="center" src="assets/images/Nodata.png">
                </div>

            </div>

        </ng-container>
        <ng-container col-sm-3>
        </ng-container>
    </div>
</div>