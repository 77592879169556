import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { IfStmt } from '@angular/compiler';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notifications-setting',
  templateUrl: './notifications-setting.component.html',
  styleUrls: ['./notifications-setting.component.scss']
})
export class NotificationsSettingComponent implements OnInit {
  faSpinner = faSpinner;

  addFormData: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  message:any;
  password:any;
  AddProcess:boolean = false;
  isLowBlance:boolean = true;
  grid:boolean = true;
  dg:boolean = true;
  date :any;
 
  flatNo:any;
  lowBalanceStatus:boolean = false;
  lowBalanceValue:any;
  consumExceedPerDay:boolean = false;
  gridUnits:any;
  dGUnits:any;
  powerCut:boolean = false;
  sourceChange:boolean = false;
  recharge:boolean = false;
  adjustment:boolean = false;
  isLoading :boolean = false;
  submitted: boolean = false;
  
  thumb :boolean = false;
  
  startTime:any;
  endTime:any;
  loadLimiterData:any = {
    'flatNo': "",
    'lowBalanceStatus': "",
    'lowBalance': "",
    'consumptionExceedPerdayStatus': "",
    'gridUnits': "",
    'dGUnits': "",
    'powerCutRestoreStatus': "",
    'sourceChange': "",
    'rechargeStatus': "",
    'adjustmentStatus': "",
   //'rdatetime': "2019-11-13 12:23:13"
  }

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }
  
  ngOnInit() {
    
    this.addFormData = this.formBuilder.group({
      flatNo: [{value:'', disabled: true}],
      lowBalanceStatus: ['', Validators.required],
      lowBalance1: [{value: '', disabled: true}, Validators.required],
      lowBalance: ['', Validators.required],
      consumptionExceedPerdayStatus: ['', Validators.required],
      gridUnits1: [{value: '', disabled: true}, Validators.required],
      gridUnits: ['', Validators.required],
      dGUnits1: [{value: '', disabled: true}, Validators.required],
      dGUnits: ['', Validators.required],
      powerCutRestoreStatus: ['', Validators.required],
      sourceChange: ['', Validators.required],
      rechargeStatus: ['', Validators.required],
      adjustmentStatus: ['', Validators.required],
      
      });
      
      //this.enableLowBalanceField();
      this.getNotificationValues();
     // this.loadLimiterData = this.currentUser.data.userData;
      //this.aF.consDOB.setValue(this.userData.dob);
    //console.log(this.date);
  }

  /*enableLowBalanceField(){
    if(this.lowBalanceValue.disabled){
      this.lowBalanceValue.disabled=false;
    }else{
      this.lowBalanceValue.disabled=true;
    }
  }*/

  /*enableExedUnitField(){
    this.gridUnits.disabled=false;
    this.dgUnits.disabled=false;
  }*/

  get aF() { return this.addFormData.controls; }
  
  upNotificationData(){
    if (this.addFormData.invalid) {
         this.submitted = true;
      return;
    }
    this.isLoading=true;
    let body = new URLSearchParams();
    
    body.set('lowBalanceStatus', this.aF.lowBalanceStatus.value);
    body.set('lowBalance', this.aF.lowBalance.value);
    body.set('consumptionExceedPerdayStatus', this.aF.consumptionExceedPerdayStatus.value);
    body.set('gridUnits', this.aF.gridUnits.value);
    body.set('dGUnits', this.aF.dGUnits.value);
    body.set('powerCutRestoreStatus', this.aF.powerCutRestoreStatus.value);
    body.set('sourceChange', this.aF.sourceChange.value);
    body.set('rechargeStatus', this.aF.rechargeStatus.value);
    body.set('adjustmentStatus', this.aF.adjustmentStatus.value);
    this.responseMessage ='';
    body.set('authToken', this.currentUser.authToken);
    this.AddProcess = true;
   this.genReqs.postReq('/setNotifiSetting', body).subscribe((result)=>{
     this.responseData = result;
     this.AddProcess = false;
     this.isLoading=false;
     this.responseMessage = this.responseData.data.Message;
     this.thumb=true;
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.AddProcess = false;
     this.responseMessage = 'Unable to process yours request!';
     this.thumb=false;
   });
   }

   checkLowBalanceStatus (enable: Boolean) { 
     if(enable) { 
        this.isLowBlance=true;
      } else { 
        this.isLowBlance=false;
      }
    }

    checkEBDGStatus (enable: Boolean) { 
      if(enable) { 
         this.grid=true;
         this.dg=true;
       } else { 
         this.grid=false;
         this.dg=false;
       }
     }

  getNotificationValues() {
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNotifiSetting', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
      this.addFormData.patchValue(this.responseData.data);
      if(this.responseData.data.lowBalanceStatus==0){
        this.isLowBlance=false;
      }else{
        this.isLowBlance=true;
      } 
      if(this.responseData.data.consumptionExceedPerdayStatus==0){
        this.grid=false;
        this.dg=false;
      }else{
        this.grid=true;
        this.dg=true;
      } 
      
      /*this.loadLimiterData = this.responseData.data;
      this.aF.flatNo.setValue(this.loadLimiterData.flatNo);
      this.aF.lowBalanceStatus.setValue(this.loadLimiterData.lowBalanceStatus);
      this.aF.lowBalance.setValue(this.loadLimiterData.lowBalance);
      this.aF.consumptionExceedPerdayStatus.setValue(this.loadLimiterData.consumptionExceedPerdayStatus);
      this.aF.gridUnits.setValue(this.loadLimiterData.gridUnits);
      this.aF.dGUnits.setValue(this.loadLimiterData.dGUnits);
      this.aF.powerCutRestoreStatus.setValue(this.loadLimiterData.powerCutRestoreStatus);
      this.aF.sourceChange.setValue(this.loadLimiterData.sourceChange);
      this.aF.rechargeStatus.setValue(this.loadLimiterData.rechargeStatus);
      this.aF.adjustmentStatus.setValue(this.loadLimiterData.adjustmentStatus);*/
      //console.log("-->",this.loadLimiterData);
     }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.message = 'No Record Found';
    });
  }

   convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
