import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-meter-edit',
  templateUrl: './meter-edit.component.html',
  styleUrls: ['./meter-edit.component.scss']
})
export class MeterEditComponent implements OnInit {
  faSpinner = faSpinner;
  public today = new Date();
  isLoading = false;
    thumb = true;
    AddProcess:boolean = false;
    loadingMessage: any;
    message:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  dcuDetails:any;
  closeResult: string;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  muID:string = '0';
  countId:string = '0';
	stateId:string = '0';
	utilityId:string = '0';
	circleId:string = '0';
	devisionId:string = '0';
	subdivId:string = '0';
  dcuid:string = '0';
  meterDetail:any;
  subdivisionId:any = 0;
  responseData: any;
  isMessage = false;
  meterCommMode = '0';
  flatNo = '';
  submitted: boolean = false;
  flatIdData = [
    {
      'consAccountNo': 0,
      'flatNo': '',
      'meterId': '',
      'consumerName': ''
    }
  ];
  activeStatus: any = [
    {'id':'Y', 'Name':'Enabled'},
    {'id':'N', 'Name':'Disabled'}
  ];

  firmWareVersion: any = [
    {'id':0, 'Name':''},
    {'id':1, 'Name':''}
  ];
  getThrough: Access;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

		// $parsedBody['countId'] ?? '';
		// $parsedBody['stateId'] ?? '';
		// $parsedBody['utilityId'] ?? '';
		// $parsedBody['circleId'] ?? '';
		// $parsedBody['devisionId'] ?? '';
		// $parsedBody['subdivId'] ?? '';
		// $parsedBody['dcuId'] ?? '';
		// $parsedBody['consumerId'] ?? '';
		// $parsedBody['meterId'] ?? '';
		// $parsedBody['meterFirmwareUniqueId'] ?? '';
		// $parsedBody['meterLPRId'] ?? '';
		// $parsedBody['meterGPRSSimNo'] ?? '';
		// $parsedBody['meterGPRSSimIMEINo'] ?? '';
		// $parsedBody['meterRTCDateTime'] ?? '';
		// $parsedBody['meterGISLocation'] ?? '';
		// $parsedBody['meterInstalledLocationAddress'] ?? '';
		// $parsedBody['meterLoadLimitEBSupply'] ?? '';
		// $parsedBody['meterLoadLimitDGSupply'] ?? '';
		// $parsedBody['eBkWHStartUnits'] ?? '';
		// $parsedBody['dgkWHStartUnits'] ?? '';
		// $parsedBody['meterStartBalance'] ?? '';
		// $parsedBody['meterAlertSendingType'] ?? '';
		// $parsedBody['meterInstalletionDateTime'] ?? '';
		// $parsedBody['meterActiveStatus'] ?? '';
		// $parsedBody['meterType'] ?? 0;
		// $parsedBody['meterIpAddress'] ?? '';
		// ($parsedBody['meterPortNo'] ?? 0);
		// $parsedBody['meterServerIpAddress'] ?? '';
		// ($parsedBody['meterServerPortNo'] ?? 0);
		// $parsedBody['meterFirmwareCodeVersion'] ?? '';

  ngOnInit() {
    this.meterCommMode=this.meterDetail.commMode;
    //this.getFlatId();
    this.meterFrimwareVersion();
    if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
      this.addForm = this.formBuilder.group({
        meterGPRSSimNo: [''],
        meterGPRSSimIMEINo: [''],
        meterInstalledLocationAddress1: [''],
        meterInstalledLocationAddress2: [''],
        eBkWHStartUnits: [''],
        dgkWHStartUnits: [''],
        meterStartBalance: [''],
        meterInstalletionDateTime: [''],
        //meterActiveStatus: [''],
        //meterServerIpAddress: [''],
        //meterServerPortNo: [''],
        //meterFirmwareCodeVersion: [''],
        hesId: [''],
        backUpStatus: ['']
      });
      this.aF.dgkWHStartUnits.setValue(this.meterDetail.mDGStartUnits);
      this.aF.meterStartBalance.setValue(this.meterDetail.mStartBal);
    }else{
      this.addForm = this.formBuilder.group({
        meterGPRSSimNo: [''],
        meterGPRSSimIMEINo: [''],
        meterInstalledLocationAddress1: [''],
        meterInstalledLocationAddress2: [''],
        eBkWHStartUnits: [''],
        //dgkWHStartUnits: [''],
        meterStartBalance: [''],
        meterInstalletionDateTime: [''],
        //meterFirmwareCodeVersion: [''],
        hesId: [''],
        backUpStatus: ['']
      });
    }
    this.subdivisionId = this.currentUser.data.userData.subdivision;
    this.aF.meterGPRSSimNo.setValue(this.meterDetail.mSimNo);
    this.aF.meterGPRSSimIMEINo.setValue(this.meterDetail.mIMEINo);
    this.aF.meterInstalledLocationAddress1.setValue(this.meterDetail.mILA);
    this.aF.meterInstalledLocationAddress2.setValue(this.meterDetail.mILA1);
    this.aF.eBkWHStartUnits.setValue(this.meterDetail.mEBKwhStartUnit);
    this.aF.meterInstalletionDateTime.setValue(this.meterDetail.mInstallDateTime);
    //this.aF.meterActiveStatus.setValue(this.meterDetail.meterEnble);
    //this.aF.meterServerIpAddress.setValue(this.meterDetail.mServerIp);
    //this.aF.meterServerPortNo.setValue(this.meterDetail.mServerPort);
    //this.aF.meterFirmwareCodeVersion.setValue(this.meterDetail.mFCV);
    this.aF.hesId.setValue(this.meterDetail.hesId);
    this.aF.backUpStatus.setValue(this.meterDetail.backupStat);
  }

  ngAfterViewInit() {  
    //console.log(this.meterDetail);
    /*this.muID = this.meterDetail.muID;
    this.countId = this.meterDetail.cID;
    this.stateId = this.meterDetail.sID;
    this.utilityId = this.meterDetail.uID;
    this.circleId = this.meterDetail.ciID;
    this.devisionId = this.meterDetail.dID;
    this.subdivId =  this.meterDetail.sdID;
    this.dcuid = this.meterDetail.dCUID;*/
    
  }

  /*getFlatId() {
    let body = new URLSearchParams();
    body.set('subDivisionId', this.subdivisionId);
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('subDivName', this.currentUser.data.userData.subName);
    this.genReqs.postReq('/getFlatId', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        console.log( this.responseData.data);
          this.flatIdData = this.responseData.data;
        }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }
*/

      /*
    add utility form submition here
    */
   get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
     //console.log("hello1",this.addForm.invalid);
      if (this.addForm.invalid) {
        this.submitted = true;
        return;
      }
      this.message='';
      this.isLoading = true;
      //console.log("hello2");
      //this.isMessage=false;
        let body = new URLSearchParams();
         body.set('subdivId', this.meterDetail.sdID);
         body.set('consumerId', this.meterDetail.cACNO);
         body.set('muID', this.meterDetail.muID);
         body.set('meterId', this.meterDetail.mID);
         body.set('meterGPRSSimNo', this.aF.meterGPRSSimNo.value);
         body.set('meterGPRSSimIMEINo', this.aF.meterGPRSSimIMEINo.value);
         body.set('meterInstalledLocationAddress1', this.aF.meterInstalledLocationAddress1.value);
         body.set('meterInstalledLocationAddress2', this.aF.meterInstalledLocationAddress2.value);
         body.set('eBkWHStartUnits', this.aF.eBkWHStartUnits.value);
         if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
          body.set('dgkWHStartUnits', this.aF.dgkWHStartUnits.value);
          body.set('meterStartBalance', this.aF.meterStartBalance.value);
         }else{
          body.set('dgkWHStartUnits', '');
          body.set('meterStartBalance', '');
         }
         body.set('backupStatus', this.aF.backUpStatus.value);
         body.set('meterInstalletionDateTime', this.aF.meterInstalletionDateTime.value);
         body.set('meterActiveStatus', '');
         body.set('meterServerIpAddress', '');
         body.set('meterServerPortNo', '');
         body.set('meterFirmwareCodeVersion', '');
         body.set('hesId', this.aF.hesId.value);
         body.set('authToken', this.currentUser.authToken);
         this.genReqs.postReq('/updateMeterInfo', body).subscribe((result)=>{
      
          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          this.message='';
          if (this.responseData.success) {
            this.thumb = true;
            this.message = this.responseData.data.message;
            if(this.message.includes("Not Updated") || this.message.includes("not updated") || this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Un-Successfully")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress") || this.message.includes("Error")){
              this.thumb = false;
            }else{
              this.thumb = true;
            }
            //this.modal.close('done');
          }
        },
        (err) => {
          if (err.status === 401) {
            this.AddProcess = false;
            this.authenticationService.logout();
          }
          this.isLoading = false;
        }
      );
    }

    meterFrimwareVersion() {
      let body = new URLSearchParams();
      body.set('subdivId', this.currentUser.data.userData.subdivision);
      body.set('dcuId','0');
      body.set('versionId', '');
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      body.set('offSet', '0');
      body.set('limit', '9999999');
      this.genReqs.postReq('/getMeterFirmwareVersion', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          this.firmWareVersion = this.responseData.data;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      });
    }

}
