import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-add-authority-pnb',
    templateUrl: './add-authority-pnb.component.html',
    styleUrls: ['./add-authority-pnb.component.scss']
})
export class AddAuthorityPnbComponent implements OnInit {

    faSpinner = faSpinner;
    addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    authType: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
    authorityDOB = "0000-00-00";

    public authLevelData: any = [];

    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];

  
    zoneData: any = [];
    circleData: any = [];
    divisionData: any = [];
    subDivData: any = [];
    message: string = '';


    authLevelFieldValue: string = '';
    authLevelField: boolean = false;
    isLoading: boolean = false;
    thumb: boolean = false;
    submitted: boolean = false;
    status: boolean = false;
    ///multiselect

    nowDate = new Date();
    authMulti1: boolean = false;
    authMulti2: boolean = false;
    authMulti3: boolean = false;
    authMulti4: boolean = false;
    authMulti5: boolean = false;
    authMulti6: boolean = false;
    authMulti7: boolean = false;
    authMulti8: boolean = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        public modal: NgbActiveModal

    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        if (this.currentUser.data.userData.authLevel == '7') {
            this.authLevelField = true;
            this.authLevelFieldValue = '7';
        }

    }

    ngOnInit() {
        console.log("Hello", this.currentUser.data.userData.subdivision);
        let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        this.addAuthorityForm = this.formBuilder.group({
            authType: ['', Validators.required],
            authFullName: ['', Validators.required],
            authUserName: ['', Validators.required],
            authPassword: ['', Validators.required],
            authEmailId: ['', [Validators.required, Validators.email]],
            authMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
            zone: ['', Validators.required],
            circle: [''],
            division: ['', Validators.required],
            sub_division: [''],
            authorityActiveStatus: ['', Validators.required],
            authorityDOB: [this.nowDate, Validators.required]
        });
        this.getZoneData();
    }


    get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        if (this.addAuthorityForm.invalid) {
            this.submitted = true;
            return;
        }
        this.message = '';
        this.isLoading = true;
        this.AddProcess = true;
        let body = new URLSearchParams();
        body.set('authLevel', '7');
        body.set('subdivId', this.currentUser.data.userData.subdivision);
        body.set('authFullName', this.aD.authFullName.value);
        body.set('authUserName', this.aD.authUserName.value);
        body.set('authPassword', this.aD.authPassword.value);
        body.set('authEmailId', this.aD.authEmailId.value);
        body.set('authMobileNo', this.aD.authMobileNo.value);
        body.set('zoneId', this.aD.zone.value);
        body.set('circleId', this.aD.circle.value);
        body.set('divisionId', this.aD.division.value);
        body.set('subDivId', this.aD.sub_division.value);
        body.set('authorityActiveStatus', this.aD.authorityActiveStatus.value);
        body.set('authType', this.aD.authType.value);
        body.set('authorityDOB', this.authorityDOB);
        body.set('authToken', this.currentUser.authToken);
        this.AddProcess = true;
        this.genReqs.postReq('/addNewAuthPnb', body).subscribe((result) => {

            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.thumb = false;
            if (this.responseData.success) {
                this.AddProcess = false;
                this.isLoading = false;
                //this.thumb = true;
                this.message = this.responseData.data.message;
                if (this.message.includes("already") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful") || this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")) {
                    this.thumb = false;
                } else {
                    this.thumb = true;
                }
                setTimeout(() => {
                    //this.modal.close('done');
                },
                    2000);
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.AddProcess = false;
            this.message = 'Unable to process yours request!';
        });
    }

    getZoneData() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getPnbZoneData', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
            this.zoneData = this.responseData.data;
            this.getCircleData();
            // console.log("leveldata", this.authLevelData);
    
          }
        }, (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        });
      }
    
      getCircleData() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('zoneId', this.aD.zone.value);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getPnbCircleData', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
            this.circleData = this.responseData.data;
            this.getDivisionData();
          }
        }, (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        });
      }

      getDivisionData() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('zoneId', this.aD.zone.value);
        body.set('circleId', this.aD.circle.value);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getPnbDivisionData', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
            this.divisionData = this.responseData.data;
            this.getSubDivisionData();
          }
        }, (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        });
      }
    
      getSubDivisionData() {
        let body = new URLSearchParams();
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('zoneId', this.aD.zone.value);
        body.set('circleId', this.aD.circle.value);
        body.set('divisionId',  this.aD.division.value);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getPnbsubDivData', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
            this.subDivData = this.responseData.data;
          }
        }, (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        });
      }

    getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.authorityDOB = eyear + '-' + emonth + '-' + eday;
        }
    }

  
}
