import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recent-complinat-dialog',
  templateUrl: './recent-complinat-dialog.component.html',
  styleUrls: ['./recent-complinat-dialog.component.scss']
})
export class RecentComplinatDialogComponent implements OnInit {
 
  isLoading:boolean = false;
  isData:boolean = false;

  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;

  meterDetail:any = {};

  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
   // console.log(this.meterDetail);
    this.getRealTimeStatus();
  }

 

  getRealTimeStatus() {
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subDivisionDatabaseName', this.meterDetail.subDivDatabase);
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getRecentComplaints', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        this.isData = true;
        this.letviewis = this.responseData.data;
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}
