<div class="container-fluid innerpage_top page-content-wrapper">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow {{divClass}}">

        <ng-container>
          <div class="">
            <div class="">
              <div class="row">
                <div class="col-md-5 col-sm-12">
                  <h1>All Consumers List
                    <span class="info_icntop ml-1">
                      <i data-toggle="tooltip" data-placement="top" title="All Consumers  List"
                        class="fa fa-info tabula-tooltip "></i>
                    </span>
                  </h1>
                </div>
                <div class="col-md-7 col-sm-12">
                  <div class="flex_auth">
                    <div class="authsearch">
                      <div class="search">
                        <input class="search-txt searchplaceholder" type="text"
                          placeholder="Search by Consumer Name/Email Id/Mobile No/Flat Id/Meter Id"
                          value="{{levelSearch}}" (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                        <a class="search-btn nobg" href="#">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>
                    </div>
                    <div>
                      <button type="button" mat-mini-fab (click)="addconsumer()" class="editbordr_circlttop"
                        *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Add_Consumer_Button">
                        <i class="fa fa-plus"></i>
                      </button>
                      <button mat-flat-button (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                        <div class="centerfa">
                          <i class="fa fa-reply centerfa"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-fluid inner-page-content">
              <div class="roww mrtop20">
                <ng-container *ngIf="loadingConsumers">
                  <div class="col-sm-12 text-center">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                  </div>
                </ng-container>
                <!-- Checkbox Column -->


                <div *ngIf="length!=0">
                  <div class="flexauth t-3 mb-3" *ngIf="allConsumersData">
                    <div class="flexcolauth" *ngFor="let label of allConsumersData; index as i;">
                      <div id="ExampleMaterialTable" class="allauthserviebox serviceBox">
                        <h3 class="title">{{label.consFullName | slice:0:2 | uppercase}}</h3>
                        <div class="">
                          <div class="">
                            <mat-icon class="pointer cardAction d-c2 ditbordr" [matMenuTriggerFor]="dcuListMenu"
                              class="editbordr" *ngIf="enableMenu"> menu_open
                            </mat-icon>
                            <mat-menu #dcuListMenu="matMenu" xPosition="before"
                              class="authorityListMenu editbordrr gridinnerpagee mat-menu-panellall">
                              <mat-list role="list">
                                <mat-list-item role="listitem"
                                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Consumer_Recharge_Button">
                                  <button type="button" mat-button (click)="rechargeMeter(label)">
                                    <mat-icon class="d-c2 authall">trending_up</mat-icon> Recharge Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Consumer_Adjustment_Button">
                                  <button type="button" mat-button (click)="adjustmentMeter(label)">
                                    <mat-icon class="d-c3 authall">trending_down</mat-icon> Adjustment Meter
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Edit_Consumer_Info_Button">
                                  <button type="button" mat-button (click)="editconsumer(label)">
                                    <mat-icon class="d-c4 authall">edit</mat-icon> Modify Info
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Edit_Consumer_Permission_Button">
                                  <button type="button" mat-button (click)="permconsumer(label)">
                                    <mat-icon class="d-c6 authall">edit</mat-icon> Modify Permissions
                                  </button>
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                  *ngIf="getThrough.authority && getThrough.authority.Consumer_Sub_Delete_Consumer_Button">
                                  <button type="button" mat-button (click)="removeconsumer(label)">
                                    <mat-icon class="d-c7">delete</mat-icon> Delete
                                  </button>
                                </mat-list-item>
                              </mat-list>
                            </mat-menu>


                            <h2 class="definedType">
                              <div>{{label.consFullName}} - ({{label.meterSerialNo}})</div>
                               <a href = "mailto:{{label.consEmail}}"><div class="mail">{{label.consEmail}}</div></a>
                            </h2>
                            <a href="tel:{{label.consMobileId}}" class="read-more">
                              <mat-icon>phone</mat-icon>{{label.consMobileId}}
                            </a>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="loadingNoData">
                  <div>
                    <div>
                      <img class="center" src="assets/images/Nodata.png">
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!allConsumersData && !loadingConsumers" col-sm-3>
                {{messageAuthoritys}}
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-card-footer>
        <div>
          <div class="row">
            <div class="col-11" *ngIf="!loadingNoData">
              <mat-paginator #meterpaginator [length]="length" [pageSize]="limit"
                [pageSizeOptions]="getPageSizeOptions()" (page)="changeMeterPage($event)" showFirstLastButtons>
              </mat-paginator>
            </div>
            <div class="col-1 text-left1" *ngIf="!loadingNoData">
              <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                  class="fa fa-file-excel-o" style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
            </div>
          </div>
        </div>
      </mat-card-footer>
    </div>
  </div>
</div>