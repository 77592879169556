import { Component, OnInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-authority-changepass',
  templateUrl: './authority-changepass.component.html',
  styleUrls: ['./authority-changepass.component.scss']
})
export class AuthorityChangepassComponent implements OnInit {
  faSpinner = faSpinner;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  old:boolean = true;
  npass:boolean = true;
  ncpass:boolean = true;
  thumb:boolean = false;
  isLoading:boolean = false;
  set:any;
  setcurrentuser:any;
  responseClass = '';
  private element: HTMLInputElement;
  submitted: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private elRef: ElementRef
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.element = elRef.nativeElement;
  }

  myplaceHolderCurrent: string ='Enter Current Password';
  myplaceHolderNew: string ='Enter New Password';
  myplaceHolderNewCon: string ='Enter Confirm Password';

  checkPlaceHolderCurrent() {
    if (this.myplaceHolderCurrent) {
      this.myplaceHolderCurrent = null
      return;
    } else {
      this.myplaceHolderCurrent = 'Enter Current Password'
      return
    }
  }

  checkPlaceHolderNew() {
    if (this.myplaceHolderNew) {
      this.myplaceHolderNew = null
      return;
    } else {
      this.myplaceHolderNew = 'Enter New Password'
      return
    }
  }
  
  checkPlaceHolderNewCon() {
    if (this.myplaceHolderNewCon) {
      this.myplaceHolderNewCon = null
      return;
    } else {
      this.myplaceHolderNewCon = 'Enter Confirm Password'
      return
    }
  }

  ngOnInit() {
    
    this.element.autocomplete = null;
    if(this.set==0){
      this.addForm = this.formBuilder.group({
      currentpass: ['', Validators.required],
      newpass: ['', Validators.required],
      confirmpass: ['', Validators.required]
      }, {
        validator: MustMatch('newpass', 'confirmpass')
    });
    }else{
      this.addForm = this.formBuilder.group({
        newpass: ['', Validators.required],
        confirmpass: ['', Validators.required]
        }, {
          validator: MustMatch('newpass', 'confirmpass')
      });
    }

  }

  data(){
    this.aF.newpass.reset;

  }
  get aF() { return this.addForm.controls; }
  onSubmitAddForm(){
     if (this.addForm.invalid) {
      this.submitted = true;
       return;
     }
     this.responseClass = '';
     this.isLoading = true;
     this.responseMessage ='';
     const formData = new FormData();
     if(this.set==0){
      formData.append('resetType', this.set);
      formData.append('currentpass', this.aF.currentpass.value);
      formData.append('newpass', this.aF.newpass.value);
      formData.append('confirmpass', this.aF.confirmpass.value);
     }else{
      formData.append('resetType', this.set);
      formData.append('currentpass', '0');
      formData.append('newpass', this.aF.newpass.value);
      formData.append('confirmpass', this.aF.confirmpass.value);
     }
     formData.append('authToken', this.currentUser.authToken);
     this.AddProcess = true;
   this.genReqs.formDataPost('/resetAuthPassword', formData).subscribe((result)=>{
     this.responseData = result;
     this.AddProcess = false;
     this.isLoading = false;
     if (this.responseData.success) {
       this.thumb = true;
       this.responseMessage = "Password Reset Successfully";
       setTimeout(() => 
      {
        this.authenticationService.logout();
      },
      3000);

       //this.modal.close('done');
     }else{
       this.thumb = false;
       this.responseMessage ="The old password you have entered is incorrect!";
     }

   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.responseClass = 'alert-danger';
     this.AddProcess = false;
     this.isLoading = false;
     this.thumb = false;
     this.responseMessage = (err.error.message)?err.error.message:'Unable to process yours request!';
   });
   }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
