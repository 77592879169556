import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-edit-old-new-meter',
    templateUrl: './edit-old-new-meter.component.html',
    styleUrls: ['./edit-old-new-meter.component.scss']
})
export class EditOldNewMeterComponent implements OnInit {

    faSpinner = faSpinner;
    addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    meterDetail: any;
    assignedData = {};
    authorityDOB: any = "0000-00-00";
    bsAuthorityDOB: Date = new Date(2017, 7);
    isLoading: boolean = false;
    thumb: boolean = false;

    authLevel_sel: any = '';
    submitted: boolean = false;

    public authLevelData: any = [];
    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];

    ///multiselect

    date: any;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        public modal: NgbActiveModal,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        private confirmationDialogService: ConfirmationDialogService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }


    ngOnInit() {
        let MOBILE_PATTERN = /[0-9\+\-\ ]/;
        /*
           form authority add
         */
        this.addAuthorityForm = this.formBuilder.group({
            ivrs: ['', Validators.required],
            oldMeterId: ['', Validators.required],
            oldMeterReading: ['', Validators.required],
            oldMeterMD: ['', Validators.required],
            oldMeterPf: ['', Validators.required],
            oldMeterImgCheckedStatus: ['', Validators.required],
            oldMeterStatus: ['', Validators.required],
            oldMeterRemark: [''],
            oldMeterLat: ['', Validators.required],
            oldMeterLong: ['', Validators.required],
            newMeterId: ['', Validators.required],
            newmterIPAddress: ['', Validators.required],
            newMeterReading: ['', Validators.required],
            newMeterRemark: [''],
            newMeterLat: ['', Validators.required],
            newMeterLong: ['', Validators.required],
            newMeterImgCheckedStatus: ['', Validators.required],
            newMeterStatus: ['', Validators.required],
            tdMeterStatus: ['', Validators.required],
            utlity: ['', Validators.required],
            //dataArray: ['']
        });

        this.preSetUserData();
    }

    ngAfterViewInit() {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });

    }

    ngOnDestroy() {
        //this.modal.close();
    }

    /*
    add authority
    require authority data
  */
    get aD() { return this.addAuthorityForm.controls; }

    onSubmitAddAuthority() {
        if (this.addAuthorityForm.invalid) {
            this.submitted = true;
            return;
        }
        this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to submit ?')
        .then((confirmed) => {
            if(confirmed) {
                this.isLoading = true;
                this.AddProcess = true;
                let body = new URLSearchParams();
                body.set('oldId', this.meterDetail.oldUid);
                body.set('newId', this.meterDetail.newMeterUid);
                body.set('oldIvrs', this.meterDetail.ivrsNo);
                body.set('oldNewMeterId', this.meterDetail.newMeterId);
                body.set('ivrs', this.aD.ivrs.value);
                body.set('oldMeterId', this.aD.oldMeterId.value);
                body.set('oldMeterReading', this.aD.oldMeterReading.value);
                body.set('oldMeterMD', this.aD.oldMeterMD.value);
                body.set('oldMeterPf', this.aD.oldMeterPf.value);
                body.set('oldMeterRemark', this.aD.oldMeterRemark.value);
                body.set('oldMeterLat', this.aD.oldMeterLat.value);
                body.set('oldMeterLong', this.aD.oldMeterLong.value);
                body.set('oldMeterImgCheckStatus', this.aD.oldMeterImgCheckedStatus.value);
                body.set('oldMeterStatus', this.aD.oldMeterStatus.value);
                body.set('newMeterId', this.aD.newMeterId.value);
                body.set('newmterIPAddress', this.aD.newmterIPAddress.value);
                body.set('newMeterReading', this.aD.newMeterReading.value);
                body.set('newMeterRemark', this.aD.newMeterRemark.value);
                body.set('newMeterLat', this.aD.newMeterLat.value);
                body.set('newMeterLong', this.aD.newMeterLong.value);
                body.set('newMeterImgCheckStatus', this.aD.newMeterImgCheckedStatus.value);
                body.set('newMeterStatus', this.aD.newMeterStatus.value);
                body.set('tdMeterStatus', this.aD.tdMeterStatus.value);
                body.set('utlity', this.aD.utlity.value);
                body.set('authToken', this.currentUser.authToken);
                this.genReqs.postReq('/updateOldNewInfo', body).subscribe((result) => {
                    this.responseData = result;
                    this.AddProcess = false;
                    this.isLoading = false;
                    if (this.responseData.success) {
                        this.thumb = true;
                        this.addAuthMessage = this.responseData.message;
                        //window.open("http://localhost:4200/#/oldNewMeter","_self");
                        setTimeout(() => 
                        {
                        this.modal.close('done');
                        },
                        2000);
                    } else {
                        this.thumb = false;
                    }
                }, (err) => {
                    if (err.status === 401) {
                        this.authenticationService.logout();
                    }
                    this.addAuthMessage = 'Unable to process yours request!';
                });
            }
        })
        .catch(() => console.log('no response or closed'));
    }
        


    preSetUserData() {
        this.aD.ivrs.setValue(this.meterDetail.ivrsNo);
        this.aD.oldMeterId.setValue(this.meterDetail.oldMeterId);
        this.aD.oldMeterReading.setValue(this.meterDetail.oldMeterReading);
        this.aD.oldMeterMD.setValue(this.meterDetail.oldMeterMD);
        this.aD.oldMeterPf.setValue(this.meterDetail.oldMeterPf);
        this.aD.oldMeterRemark.setValue(this.meterDetail.oldMeterRemark);
        this.aD.oldMeterLat.setValue(this.meterDetail.oldMeterLat);
        this.aD.oldMeterLong.setValue(this.meterDetail.oldMeterLong);
        this.aD.newMeterId.setValue(this.meterDetail.newMeterId);
        this.aD.newmterIPAddress.setValue(this.meterDetail.newmterIPAddress);
        this.aD.newMeterReading.setValue(this.meterDetail.newMeterReading);
        this.aD.newMeterRemark.setValue(this.meterDetail.newMeterRemark);
        this.aD.newMeterLat.setValue(this.meterDetail.newMeterLat);
        this.aD.newMeterLong.setValue(this.meterDetail.newMeterLong);
        this.aD.oldMeterImgCheckedStatus.setValue(this.meterDetail.checkedStatus);
        this.aD.oldMeterStatus.setValue(this.meterDetail.oldDataStatus);
        this.aD.newMeterImgCheckedStatus.setValue(this.meterDetail.newCheckedStatus);
        this.aD.newMeterStatus.setValue(this.meterDetail.newDataStatus);
        this.aD.tdMeterStatus.setValue(this.meterDetail.tdMeterStatus);
        this.aD.utlity.setValue(this.meterDetail.utlity);
        
    }
    //default DCU

}
