import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { ModalService } from '../../_services/modal.service';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Access } from 'src/app/_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';

@Component({
  selector: 'app-userwork',
  templateUrl: './userwork.component.html',
  styleUrls: ['./userwork.component.scss']
})
export class UserworkComponent implements OnInit {

  length: number = 0;
  selectedSubDivision:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  messageResult:any;
  loading = false;
  levelSearch:string = '';
  getThrough: Access;
  removeProcess:boolean = false;
  responseMessage:string = '';
  responseData: any;
  allData: {};
  alllevels = {
    uid: '',
    authLevelName: ''
  }
  dataLevelSource:any = new MatTableDataSource([]);
  faSpinner = faSpinner;

  dataSource:any = new MatTableDataSource([]);
  loadingMessage:any = '';

  //pagination settings
  limit:number = 20;
  skip:number = 0;
  filter = '';
  // MatPaginator Inputs
  allLevelTotalRecord: number = 0;
  
  pageSize: number = 7;
  pageSizeOptions: number[] = [7, 14, 21];

  levelHeadsCols = [];

  levelColumnsToDisplay = [];
  enableSocietyChange:boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService
    ) {
      this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
      if(this.currentUser.data.userData.authLevel=='7'){
        this.enableSocietyChange=false;
      }
  }

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  ngOnInit() {
    
      this.levelHeadsCols = [
        { label: "Sr. No.",name:"srNo", checked: true },
        { label: "Id",name:"id", checked: false },
        { label: "Authority",name:"authName", checked: true },
        { label: "Installed Meter Count",name:"installedMeterCount", checked: true },
        { label: "Replaced Meter Count",name:"replaceMeterCount", checked: true },
        { label: "Non-Com Count",name:"nonComCount", checked: true },
        { label: "Attend Office Status",name:"attendOffice", checked: false },
        { label: "Work Status",name:"notWorked", checked: false },
        { label: "Leave Status",name:"leave", checked: false },
        { label: "Remark",name:"remark", checked: true },
        { label: "Reply",name:"remarkRevert", checked: false },
        { label: "Location",name:"location", checked: true },
        { label: "Date & Time",name:"dateTime", checked: true },
        { label: "Revert Date & Time",name:"revertDatetime", checked: false },
        { label: "Edit",name:"edit", checked: true },
      ];
    
      this.levelColumnsToDisplay = [
        'srNo',
        'authName',
        'installedMeterCount',
        'replaceMeterCount',
        'nonComCount',
        'remark',
        'location',
        'dateTime',
        'edit'
      ];
    
    this.getLabelsListing();
    if(this.enableSocietyChange){
      this.whichSubDomain();
    }
  }

  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
        this.getLabelsListing();
      }
    );
  }


  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.ngModalService.dismissAll();
  }

 
  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable","Meter's Firmware Data");
  }

  //add
  openaddlevel(){
    let authData = {
      "cID": this.currentUser.data.userData.Country,
      "sID": this.currentUser.data.userData.State,
      "uID": this.currentUser.data.userData.utility,
      "ciID": this.currentUser.data.userData.circle,
      "dID": this.currentUser.data.userData.division,
      "sdID": this.currentUser.data.userData.subdivision,
      "dcuID": 0,
    }
    this.modalService.addMeterFirmWareVersion(this.currentUser.data.userData.subdivision).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
       
        if(result.toString()!='Cancel'){
          this.getCurrentData();
        }
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  
  //edit 
  revertRemark(row){
    //console.log(row);
    this.modalService.revertRemark(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getCurrentData();
      }
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for ( let col of this.levelHeadsCols ) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }
  
  onLevelSearchChange(search){
    this.levelSearch = search;
    this.skip = 0;
    this.getMeterFirmwareList(false);
  }

  getMeterFirmwareList(addNew:boolean=false) {
    //this.loading = true;
    let body = new URLSearchParams();
    if(this.enableSocietyChange){
      body.set('subdivId', this.selectedSubDivision);
    }else{
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('dcuId','0');
    body.set('versionId', '0');
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getUserInfoWork', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }

      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }
  
  // remove
  removelevel(row){
  //deleteAuth
  this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove Firmware Version '+row.Name+' ?')
  .then((confirmed) => {
    if(confirmed) {
      this.removeProcess = true;
      let body = new URLSearchParams();
      body.set('subDivisionId', row.subId);
      body.set('firmId', row.id);
      body.set('firmName', row.Name);
      body.set('subName', row.subDivName);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/deleteFWData', body).subscribe((result)=>{
        this.responseData = result;
        this.removeProcess = false;
        this.responseMessage = this.responseData.message;
        this.getLabelsListing();
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.responseMessage = 'Unable to process yours request!';
      });
    }
  })
  .catch(() => console.log('no response or closed'));
  }


  getCurrentData() {
    this.limit=10;
    this.loading = true;
    let body = new URLSearchParams();
    if(this.enableSocietyChange){
      body.set('subdivId', this.selectedSubDivision);
    }else{
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('dcuId','0');
    body.set('versionId', '0');
    
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getUserInfoWork', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
        
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  getLabelsListing() {
    this.loading = true;
    let body = new URLSearchParams();
     if(this.enableSocietyChange){
      body.set('subdivId', this.selectedSubDivision);
    }else{
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('dcuId','0');
    body.set('versionId', '0');
    
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getUserInfoWork', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
         
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getLabelsListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getLabelsListing();
    }
  }

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [10, 20, 40, 80, 160, this.length];
    } else if (this.length > 320) {
      return [10, 20, 40, 80, 160, 320, this.length];
    } 
  }
  
}
