import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
@Component({
  selector: 'app-assign-ami-meters',
  templateUrl: './assign-ami-meters.component.html',
  styleUrls: ['./assign-ami-meters.component.scss']
})
export class AssignAmiMetersComponent {
  adminData: any = [];

  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
    addAuthMessage: string = '';
    AddProcess: boolean = false;
    authType: boolean = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    responseData: any;
    authority: any;
    authorityDOB = "0000-00-00";
    siteUrl = this.genReqs.apiPath();
    defaultAdmin: any =0;
    meterDetail: any;
    public authLevelData: any = [];
    public countryLevelData: any = [];
    public stateLevelData: any = [];
    public utilityLevelData: any = [];
    public circleLevelData: any = [];
    public divisionLevelData: any = [];
    public subdivisionLevelData: any = [];
    public dcuLevelData: any = [];
    message: string = '';
    authLevelFieldValue: string = '';
    authLevelField: boolean = false;
    isLoading: boolean = false;
    thumb: boolean = false;
    submitted: boolean = false;
    status: boolean = false;
    ///multiselect

    nowDate = new Date();
    authMulti1: boolean = false;
    authMulti2: boolean = false;
    authMulti3: boolean = false;
    authMulti4: boolean = false;
    authMulti5: boolean = false;
    authMulti6: boolean = false;
    authMulti7: boolean = false;
    authMulti8: boolean = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
        public modal: NgbActiveModal
      
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        if (this.currentUser.data.userData.authLevel == '7') {
            this.authLevelField = true;
            this.authLevelFieldValue = '7';
        }
       
    }

    downloadCsvFile(){
        TableUtil.exportTableToExcel("MaterialTable", "Data");
        /*let xlsUrl = this.siteUrl+'/reports/downloadSampleExcel'
        +'?authToken='+this.currentUser.authToken;
        window.open(xlsUrl);*/
    }

    

  ngOnInit() {
    this.getAdminData();
    if(this.currentUser.data.userData.subdivision=="105"){
        this.status=true;
    }
    console.log("Hello",this.currentUser.data.userData.subdivision);
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;

        this.addAuthorityForm = this.formBuilder.group({
          fieldAdmin: ['', Validators.required],
        });
    


   
  }

  getAdminData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authType', this.currentUser.data.userData.authorityType);
    body.set('companyName', '11');
    body.set('source', 'F');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getFieldAdminData', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.adminData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }


  onFileSelect(event) {
    if (event.target.files && event.target.files[0]) {
      
      let file = event.target.files[0];
     
      //console.log(file.type);
      if (file.type == "application/vnd.ms-excel") {
        const file = event.target.files[0];
        //if(file.name=='Sample_Excel.xls'){
          this.aD.amiMetersFile.setValue(file);
          return true;
        /*}else{
          this.aD.amiMetersFile.setValue(null);
          alert("Please choose Sample_Excel File For Uploading Data.");
          return false;
        }*/
      }
      else {
        this.aD.amiMetersFile.setValue(null);
        alert("Please select Files in Excel Formats.");
        return false;
      }
    }
  }

  
  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAssignAdmin() {
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('fieldAdmin',this.aD.fieldAdmin.value);
    body.set('afdId',this.meterDetail.accountId);
    body.set('meterId',this.meterDetail.newMeterId);
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/assignAdmin', body).subscribe((result) => {
      this.responseData = result;
      //console.log("data",this.responseData.data);
      if (this.responseData.success) {
        this.isLoading = false;
        this.message = this.responseData.data.message;
        if(this.message.includes("Successfully")){
          this.thumb=true;
        }
        setTimeout(() => 
        {
          this.modal.close('done');
        },
        2000); 
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
    }

}
