<div class="page-content-wrapper" style="margin-top:0;">
    <div class="page-content-wrapper-inner">
      <div class="content-viewport">
        <div class="row py-3 headerArea commonClass">
          <div class="col-12">
              <h4>Dashboard</h4>
              <p class="text-white">Welcome, {{currentUser.data.userData.name | titlecase}}</p>
          </div>
      </div>
      <div class="row commonClass">
        <mat-toolbar class="subheaderArea h-40 fs-14">
          <span class="toolbar-space"></span>
          <button mat-icon-button color="primary">
              <mat-icon>search</mat-icon>
          </button>&nbsp;&nbsp;
          <button mat-icon-button color="primary" (click)="open(addcountry)">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>
    </div>
        <div class="row inner-page-content">
          <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys" >
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <ng-container *ngIf="allutilitys" col-sm-3>
              <mat-grid-list style="width: 100%;" [cols]="breakpoint" (window:resize)="onResize($event)" rowHeight="3:3" >
                <mat-grid-tile *ngFor="let label of allutilitys; index as i;">
                  <mat-card class="card cardMarginRight folderView" >
                      <mat-card-content mat-card-image>
                      <div class="circular">
                          <img src="{{label.img_address}}" *ngIf="label.img_address"/>
                          <fa *ngIf="!label.img_address"  name="folder" style="color:#84ccff;" mat-card-lg-image></fa>
                        </div>
                        <mat-icon mat-button class="pointer cardAction" (click)="countryEditView(label, listmodify)">more_vert</mat-icon>
                      </mat-card-content>
                      <mat-card-footer (click)="goToDivisionView(label.division_id)">
                        <div class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;" mat-button>
                            <div class="fullbutton">
                                <span >{{label.divisionName}}</span>
                                <mat-icon class="pointer">keyboard_arrow_right</mat-icon>
                            </div>
                          </div>
                      </mat-card-footer>
                  </mat-card>
                </mat-grid-tile>
              </mat-grid-list>
          </ng-container>
          <ng-container *ngIf="!allutilitys" col-sm-3>
            {{messageUtilitys}}
          </ng-container>
      </div>
      </div>
    </div>
    </div>
    <ng-template #addcountry let-modal>
      <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Division</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="modal-body">
              <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
              <div class="form-group">
                  <div class="form-row">
                    <mat-form-field class="col-md-6">
                      <input matInput placeholder="Division Code" name="Code" formControlName="Code" >
                      <mat-error *ngIf="aF.Code.hasError('required')">
                          Division Code is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
          
                    <mat-form-field class="col-md-6">
                      <input matInput placeholder="Division Name" name="Name" formControlName="Name">
                      <mat-error *ngIf="aF.Name.hasError('required')">
                          Division Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    <div class="col-md-6">
                        <input type="file" name="Image" (change)="onFileSelect($event)" />
                      <div *ngIf="aF.Image.hasError('required')">
                          Division image is <strong>required</strong>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                      <div class="col-12 text-right">
                          <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                        <button type="submit" mat-button color="primary" class="text-uppercase">Add</button>
                  </div>
              </div>
          </div>
          </form>
</ng-template>

<ng-template #listmodify let-modal>
    <form [formGroup]="editForm" (ngSubmit)="onSubmitEditForm()">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Division: {{EditName}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>
            <div class="form-group">
                <div class="form-row">
                  <mat-form-field class="col-md-6">
                    <input matInput placeholder="Division Code" name="Code" formControlName="Code" >
                    <mat-error *ngIf="eF.Code.hasError('required')">
                        Division Code is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="col-md-6">
                    <input matInput placeholder="Division Name" name="Name" formControlName="Name">
                    <mat-error *ngIf="eF.Name.hasError('required')">
                        Division Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                   <div class="col-md-6">
                      <input type="file" name="Image" (change)="onFileSelectEdit($event)" />
                    <div *ngIf="eF.Image.hasError('required')">
                        Division image is <strong>required</strong>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                    <div class="col-12 text-right">
                        <button type="button" (click)="modal.dismiss('Cross click')" mat-button class="text-uppercase">cancel</button>
                        <button type="submit" mat-button color="primary" class="text-uppercase">Update</button>
                </div>
            </div>
        </div>
      </form>
</ng-template>