<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <div class="with-icon">
      <mat-icon class="d-c9">notification_important</mat-icon>All Notifications
    </div>
    
  </h4>
  <button type="button"  (click)="modal.close('Ok click')" class="dilog4 dilog5">
    <mat-icon matPrefix>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of allnotifications" >
        <mat-expansion-panel-header>
          
          <mat-panel-title>
            <mat-icon class="d-c10">notification_important</mat-icon>
            <b>{{item.Notification | slice:0:15}}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="col-md-12 pull-left">
          <B>Notification:</B> <br>
          {{item.Notification}}
          <div class="timeStamp">
            {{item['Server Date Time'] | date:'fullDate'}}
          </div>
        </div>
        

      </mat-expansion-panel>
    </mat-accordion>
    <!-- Grid row -->
    <div class="clearfix"></div>
    <!-- buttons are listed here-->
  </div>
</div>