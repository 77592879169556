import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { formatDate } from '@angular/common';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-meterteriff1phcommercial',
  templateUrl: './meterteriff1phcommercial.component.html',
  styleUrls: ['./meterteriff1phcommercial.component.scss']
})
export class Meterteriff1phcommercialComponent implements OnInit {
  defaultValue = { hour: 13, minute: 30 };
  timeChangeHandler(event: any) {

  };
  invalidInputHandler() {

  }
  @ViewChild('picker') picker: any;
  faSpinner = faSpinner;
  currentMonth = new Date();
  public today = new Date();
  message: string = '';
  button = 'Update';
  currentUser: User;
  currentUserSubscription: Subscription;
  loading: boolean = false;
  status: boolean = true;
  submit: boolean = true;
  actionForm: UntypedFormGroup;
  isLoadingz = false;
  formName1 = "1-PH Teriff (Commercial)";
  formName2 = "1-PH Teriff Setting (Commercial, Construction, Public Utility)";
  isLoading = false;
  actionForm15: UntypedFormGroup;

  meterDateTime: string = '';
  timeDiffrence: string = '';
  responseMessage: string = '';
  EditName: string = '';
  accesLevel = 0;
  selectedSubDivision: any = 0;
  AddProcess: boolean = false;
  responseData: any;
  submitted: boolean = false;
  //meterDetail:any = {};
  @Input() meterDetail: any = {
    muID: 0,
    mID: "All",
    cACNO: 0,
    sdID: this.selectedSubDivision
  };

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'MM/YYYY',
    minMode: 'month',
    //dateInputFormat: 'DD/MM/YYYY', // Customize the date format if needed
    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Set the minimum date to the current date
  };

  numberReturn(length) {
    return new Array(length);
  }

  //serverDateTime = new Date();
  serverDateTime = "0000-00-00 00:00:00";
  data = true;
  /*capture: any = [
    { 'id': 0, 'Name': 'Select Capture Period' },
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];*/
  capture: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];
  /*demand: any = [
    { 'id': 0, 'Name': 'Select Capture Period' },
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  
  ];*/

  demand: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  ];
  cmdformData = 1;
  loadLimitValue = 0;
  lockOutPeriodValue = 0;
  relayConnDisvalue = 0;
  myDate = new Date();
  cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');

  startDate = '';

  thumb: boolean = false;
  servantmode: any;
  getThrough: Access;
  t1STime: string = '00:00';
  t1ETime: string = '00:00';

  t2STime: string = '00:00';
  t2ETime: string = '00:00';

  t3STime: string = '00:00';
  t3ETime: string = '00:00';

  t4STime: string = '00:00';
  t4ETime: string = '00:00';

  t5STime: string = '00:00';
  t5ETime: string = '00:00';

  t6STime: string = '00:00';
  t6ETime: string = '00:00';

  t7STime: string = '00:00';
  t7ETime: string = '00:00';

  t8STime: string = '00:00';
  t8ETime: string = '00:00';

  t1STimeVal: string = '00:00';
  t1ETimeVal: string = '00:00';

  t2STimeVal: string = '00:00';
  t2ETimeVal: string = '00:00';

  t3STimeVal: string = '00:00';
  t3ETimeVal: string = '00:00';

  t4STimeVal: string = '00:00';
  t4ETimeVal: string = '00:00';

  t5STimeVal: string = '00:00';
  t5ETimeVal: string = '00:00';

  t6STimeVal: string = '00:00';
  t6ETimeVal: string = '00:00';

  t7STimeVal: string = '00:00';
  t7ETimeVal: string = '00:00';

  t8STimeVal: string = '00:00';
  t8ETimeVal: string = '00:00';



  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    this.calculateNextMonthStartDate();
    this.actionForm = this.formBuilder.group({
      alarmLevel: ['', Validators.required],
      emgCreditLimit: ['', Validators.required],
      consCategory: ['', Validators.required],
      teriffName: ['', Validators.required],
      monthlyConsumption: ['', Validators.required],
      fixedCharges: ['', Validators.required],
      meterRent: ['', Validators.required],
      pastTeriffDate: [{ value: '', disabled: true }, Validators.required],
      //tod1StartTime: ['00:00', Validators.required],
      //tod1EndTime: ['00:00', Validators.required],
      ebTod1UnitRate: ['', Validators.required],
      mvcaTod1Charges: ['', Validators.required],
      electTod1DutyCharges1: ['', Validators.required],
      electTod1DutyCharges2: ['', Validators.required],
      electTod1DutyCharges3: ['', Validators.required],
      electTod1DutyCharges4: ['', Validators.required],

      //tod2StartTime: ['00:00', Validators.required],
      //tod2EndTime: ['00:00', Validators.required],
      ebTod2UnitRate: ['', Validators.required],
      mvcaTod2Charges: ['', Validators.required],
      /*electTod2DutyCharges1: ['', Validators.required],
      electTod2DutyCharges2: ['', Validators.required],
      electTod2DutyCharges3: ['', Validators.required],
      electTod2DutyCharges4: ['', Validators.required],*/

      //tod3StartTime: ['00:00', Validators.required],
      //tod3EndTime: ['00:00', Validators.required],
      ebTod3UnitRate: ['', Validators.required],
      mvcaTod3Charges: ['', Validators.required],
      /*electTod3DutyCharges1: ['', Validators.required],
      electTod3DutyCharges2: ['', Validators.required],
      electTod3DutyCharges3: ['', Validators.required],
      electTod3DutyCharges4: ['', Validators.required],*/

      //tod4StartTime: ['00:00', Validators.required],
      //tod4EndTime: ['00:00', Validators.required],
      ebTod4UnitRate: ['', Validators.required],
      mvcaTod4Charges: ['', Validators.required],
      /*electTod4DutyCharges1: ['', Validators.required],
      electTod4DutyCharges2: ['', Validators.required],
      electTod4DutyCharges3: ['', Validators.required],
      electTod4DutyCharges4: ['', Validators.required],*/

      //tod5StartTime: ['00:00', Validators.required],
      //tod5EndTime: ['00:00', Validators.required],
      ebTod5UnitRate: ['', Validators.required],
      mvcaTod5Charges: ['', Validators.required],
      /*electTod5DutyCharges1: ['', Validators.required],
      electTod5DutyCharges2: ['', Validators.required],
      electTod5DutyCharges3: ['', Validators.required],
      electTod5DutyCharges4: ['', Validators.required],*/

      tod6StartTime: ['', Validators.required],
      //tod6EndTime: ['', Validators.required],
      ebTod6UnitRate: ['', Validators.required],
      mvcaTod6Charges: ['', Validators.required],
      /*electTod6DutyCharges1: ['', Validators.required],
      electTod6DutyCharges2: ['', Validators.required],
      electTod6DutyCharges3: ['', Validators.required],
      electTod6DutyCharges4: ['', Validators.required],*/

      //tod7StartTime: ['00:00', Validators.required],
      //tod7EndTime: ['00:00', Validators.required],
      ebTod7UnitRate: ['', Validators.required],
      mvcaTod7Charges: ['', Validators.required],
      /*electTod7DutyCharges1: ['', Validators.required],
      electTod7DutyCharges2: ['', Validators.required],
      electTod7DutyCharges3: ['', Validators.required],
      electTod7DutyCharges4: ['', Validators.required],*/

      //tod8StartTime: ['00:00', Validators.required],
      //tod8EndTime: ['00:00', Validators.required],
      ebTod8UnitRate: ['', Validators.required],
      mvcaTod8Charges: ['', Validators.required],
      /*electTod8DutyCharges1: ['', Validators.required],
      electTod8DutyCharges2: ['', Validators.required],
      electTod8DutyCharges3: ['', Validators.required],
      electTod8DutyCharges4: ['', Validators.required],*/

      schedularStartEndDate: [this.myDate, Validators.required],
    });
    this.getConfiguratoin();
    if (this.meterDetail.meterType == '1') {
      this.formName1 = "1-PH Tariff (Commercial, Construction, Public Utility)";
      this.formName2 = "1-PH Tariff Setting (Commercial, Construction, Public Utility)";
    }
    if (this.meterDetail.meterType == '3') {
      this.formName1 = "3-PH Tariff (Commercial, Construction, Public Utility)";
      this.formName2 = "3-PH Tariff Setting (Commercial, Construction, Public Utility)";
    }
    if (this.meterDetail.meterType == '2') {
      this.formName1 = "LTCT Tariff (Commercial, Construction, Public Utility)";
      this.formName2 = "LTCT Tariff Setting (Commercial, Construction, Public Utility)";
    }
  }

  calculateNextMonthStartDate() {
    const currentDate = new Date();
    const currentDate1 = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(1); // Set the day to the 1st day of the next month

    this.myDate = currentDate;

    currentDate1.setMonth(currentDate1.getMonth() - 2);
    currentDate1.setDate(1);
    this.currentMonth = currentDate1;

    let emonth = this.myDate.getMonth(); //always move 1 value up
    let eday = this.myDate.getDate();
    let eyear = this.myDate.getFullYear();
    this.startDate = eyear + '-' + '11' + '-' + eday;

  }

  getDateFromRange(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.startDate = eyear + '-' + emonth + '-' + eday;
    }
  }


  getConfiguratoin() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getBillingTeriffIPCLCommercial', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.aD.consCategory.setValue(this.responseData.data.consCategory);
        this.aD.teriffName.setValue(this.responseData.data.teriffName);
        this.aD.monthlyConsumption.setValue(this.responseData.data.monthlyConsumption);
        this.aD.emgCreditLimit.setValue(this.responseData.data.emergCreditLimit.toString());
        this.aD.alarmLevel.setValue(this.responseData.data.alarmLevel.toString());
        this.aD.fixedCharges.setValue(this.responseData.data.eBFixCharges.toString());
        this.aD.meterRent.setValue(this.responseData.data.vendingRate.toString());

        //this.aD.tod1StartTime.setValue(this.responseData.data.tod1StartTime.toString());
        //this.t1STimeVal = this.responseData.data.tod1StartTime.toString();
        //this.aD.tod1EndTime.setValue(this.responseData.data.tod1EndTime.toString());
        //this.t1ETimeVal = this.responseData.data.tod1EndTime.toString();
        this.aD.ebTod1UnitRate.setValue(this.responseData.data.eBUnitRatet1.toString());
        this.aD.mvcaTod1Charges.setValue(this.responseData.data.mvcaRatet1.toString());
        this.aD.electTod1DutyCharges1.setValue(this.responseData.data.edt11.toString());
        this.aD.electTod1DutyCharges2.setValue(this.responseData.data.edt12.toString());
        this.aD.electTod1DutyCharges3.setValue(this.responseData.data.edt13.toString());
        this.aD.electTod1DutyCharges4.setValue(this.responseData.data.edt14.toString());

        //this.aD.tod2StartTime.setValue(this.responseData.data.tod2StartTime.toString());
        //this.t2STimeVal = this.responseData.data.tod2StartTime.toString();
        //this.aD.tod2EndTime.setValue(this.responseData.data.tod2EndTime.toString());
        //this.t2ETimeVal = this.responseData.data.tod2EndTime.toString();

        this.aD.ebTod2UnitRate.setValue(this.responseData.data.eBUnitRatet2.toString());
        this.aD.mvcaTod2Charges.setValue(this.responseData.data.mvcaRatet2.toString());
        //this.aD.electTod2DutyCharges1.setValue(this.responseData.data.edt21.toString());
        //this.aD.electTod2DutyCharges2.setValue(this.responseData.data.edt22.toString());
        //this.aD.electTod2DutyCharges3.setValue(this.responseData.data.edt23.toString());
        //this.aD.electTod2DutyCharges4.setValue(this.responseData.data.edt24.toString());

        //this.aD.tod3StartTime.setValue(this.responseData.data.tod3StartTime.toString());
       // this.t3STimeVal = this.responseData.data.tod3StartTime.toString();
        //this.aD.tod3EndTime.setValue(this.responseData.data.tod3EndTime.toString());
        //this.t3ETimeVal = this.responseData.data.tod3EndTime.toString();

        this.aD.ebTod3UnitRate.setValue(this.responseData.data.eBUnitRatet3.toString());
        this.aD.mvcaTod3Charges.setValue(this.responseData.data.mvcaRatet3.toString());
        //this.aD.electTod3DutyCharges1.setValue(this.responseData.data.edt31.toString());
        //this.aD.electTod3DutyCharges2.setValue(this.responseData.data.edt32.toString());
        //this.aD.electTod3DutyCharges3.setValue(this.responseData.data.edt33.toString());
        //this.aD.electTod3DutyCharges4.setValue(this.responseData.data.edt34.toString());

        //this.aD.tod4StartTime.setValue(this.responseData.data.tod4StartTime.toString());
        //this.t4STimeVal = this.responseData.data.tod4StartTime.toString();
        //this.aD.tod4EndTime.setValue(this.responseData.data.tod4EndTime.toString());
        //this.t4ETimeVal = this.responseData.data.tod4EndTime.toString();

        this.aD.ebTod4UnitRate.setValue(this.responseData.data.eBUnitRatet4.toString());
        this.aD.mvcaTod4Charges.setValue(this.responseData.data.mvcaRatet4.toString());
        //this.aD.electTod4DutyCharges1.setValue(this.responseData.data.edt41.toString());
        //this.aD.electTod4DutyCharges2.setValue(this.responseData.data.edt42.toString());
        //this.aD.electTod4DutyCharges3.setValue(this.responseData.data.edt43.toString());
        //this.aD.electTod4DutyCharges4.setValue(this.responseData.data.edt44.toString());

        //this.aD.tod5StartTime.setValue(this.responseData.data.tod5StartTime.toString());
        //this.t5STimeVal = this.responseData.data.tod5StartTime.toString();
        //this.aD.tod5EndTime.setValue(this.responseData.data.tod5EndTime.toString());
        //this.t5ETimeVal = this.responseData.data.tod5EndTime.toString();

        this.aD.ebTod5UnitRate.setValue(this.responseData.data.eBUnitRatet5.toString());
        this.aD.mvcaTod5Charges.setValue(this.responseData.data.mvcaRatet5.toString());
        //this.aD.electTod5DutyCharges1.setValue(this.responseData.data.edt51.toString());
        //this.aD.electTod5DutyCharges2.setValue(this.responseData.data.edt52.toString());
        //this.aD.electTod5DutyCharges3.setValue(this.responseData.data.edt53.toString());
        //this.aD.electTod5DutyCharges4.setValue(this.responseData.data.edt54.toString());

        //this.aD.tod6StartTime.setValue(this.responseData.data.tod6StartTime.toString());
        //this.t6STimeVal = this.responseData.data.tod6StartTime.toString();
        //this.aD.tod6EndTime.setValue(this.responseData.data.tod6EndTime.toString());
        //this.t6ETimeVal = this.responseData.data.tod6EndTime.toString();
        this.aD.ebTod6UnitRate.setValue(this.responseData.data.eBUnitRatet6.toString());
        this.aD.mvcaTod6Charges.setValue(this.responseData.data.mvcaRatet6.toString());
        //this.aD.electTod6DutyCharges1.setValue(this.responseData.data.edt61.toString());
        //this.aD.electTod6DutyCharges2.setValue(this.responseData.data.edt62.toString());
        //this.aD.electTod6DutyCharges3.setValue(this.responseData.data.edt63.toString());
        //this.aD.electTod6DutyCharges4.setValue(this.responseData.data.edt64.toString());

        //this.aD.tod7StartTime.setValue(this.responseData.data.tod7StartTime.toString());
        //this.t7STimeVal = this.responseData.data.tod7StartTime.toString();
        //this.aD.tod7EndTime.setValue(this.responseData.data.tod7EndTime.toString());
        //this.t7ETimeVal = this.responseData.data.tod7EndTime.toString();
        this.aD.ebTod7UnitRate.setValue(this.responseData.data.eBUnitRatet7.toString());
        this.aD.mvcaTod7Charges.setValue(this.responseData.data.mvcaRatet7.toString());
        //this.aD.electTod7DutyCharges1.setValue(this.responseData.data.edt71.toString());
        //this.aD.electTod7DutyCharges2.setValue(this.responseData.data.edt72.toString());
        //this.aD.electTod7DutyCharges3.setValue(this.responseData.data.edt73.toString());
        //this.aD.electTod7DutyCharges4.setValue(this.responseData.data.edt74.toString());

        //this.aD.tod8StartTime.setValue(this.responseData.data.tod8StartTime.toString());
        //this.t8STimeVal = this.responseData.data.tod8StartTime.toString();

        //this.aD.tod8EndTime.setValue(this.responseData.data.tod8EndTime.toString());
        //this.t8ETimeVal = this.responseData.data.tod8EndTime.toString();


        this.aD.ebTod8UnitRate.setValue(this.responseData.data.eBUnitRatet8.toString());
        this.aD.mvcaTod8Charges.setValue(this.responseData.data.mvcaRatet8.toString());
        //this.aD.electTod8DutyCharges1.setValue(this.responseData.data.edt81.toString());
        //this.aD.electTod8DutyCharges2.setValue(this.responseData.data.edt82.toString());
        //this.aD.electTod8DutyCharges3.setValue(this.responseData.data.edt83.toString());
        //this.aD.electTod8DutyCharges4.setValue(this.responseData.data.edt84.toString());
        this.aD.pastTeriffDate.setValue(new Date(this.responseData.data.dateTime.toString()));
      } else {
        //console.log(this.responseData.message);//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  get aD() { return this.actionForm.controls; }

  ngOnDestroy() {
    //this.modalService.dismissAll();
  }

  submitForm() {
    let check = 0;

    /*if (this.aD.tod1StartTime.value != this.t1STimeVal) {
      check = 1;
    }
   
    if (this.aD.tod2StartTime.value != this.t2STimeVal) {
      check = 1;
    }
    

    if (this.aD.tod3StartTime.value != this.t3STimeVal) {
      check = 1;
    }
    

    if (this.aD.tod4StartTime.value != this.t4STimeVal) {
      check = 1;
    }
   

    if (this.aD.tod5StartTime.value != this.t5STimeVal) {
      check = 1;
    }
    

    if (this.aD.tod6StartTime.value != this.t6STimeVal) {
      check = 1;
    }
    

    if (this.aD.tod7StartTime.value != this.t7STimeVal) {
      check = 1;
    }
   

    if (this.aD.tod8StartTime.value != this.t8STimeVal) {
      check = 1;
    }*/
   

    //if (check != 0) {
    if (false) {
      /*let message = 
      "<div style='padding-left: 34px;'><br><b>TOD Zone 1 From "+this.aD.tod1StartTime.value + " To " +  this.aD.tod1EndTime.value + 
      "</b><br><b>TOD Zone 2 From "+this.aD.tod2StartTime.value + " To " +  this.aD.tod2EndTime.value +
      "</b><br><b>TOD Zone 3 From "+this.aD.tod3StartTime.value + " To " +  this.aD.tod3EndTime.value +
      "</b><br><b>TOD Zone 4 From "+this.aD.tod4StartTime.value + " To " +  this.aD.tod4EndTime.value +
      "</b><br><b>TOD Zone 5 From "+this.aD.tod5StartTime.value + " To " +  this.aD.tod5EndTime.value +
      "</b><br><b>TOD Zone 6 From "+this.aD.tod6StartTime.value + " To " +  this.aD.tod6EndTime.value +
      "</b><br><b>TOD Zone 7 From "+this.aD.tod7StartTime.value + " To " +  this.aD.tod7EndTime.value +
      "</b><br><b>TOD Zone 8 From "+this.aD.tod8StartTime.value + " To " +  this.aD.tod8EndTime.value +"</b><br><br></div>";*/

      let message = 
      "<div style='padding-left: 34px;'><br><b>TOD Zone 1 From "+this.aD.tod1StartTime.value + 
      "</b><br><b>TOD Zone 2 From "+this.aD.tod2StartTime.value +
      "</b><br><b>TOD Zone 3 From "+this.aD.tod3StartTime.value +
      "</b><br><b>TOD Zone 4 From "+this.aD.tod4StartTime.value +
      "</b><br><b>TOD Zone 5 From "+this.aD.tod5StartTime.value +
      "</b><br><b>TOD Zone 6 From "+this.aD.tod6StartTime.value +
      "</b><br><b>TOD Zone 7 From "+this.aD.tod7StartTime.value +
      "</b><br><b>TOD Zone 8 From "+this.aD.tod8StartTime.value +"</b><br><br></div>";

      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update ' + message + ' Tod Timings ?')
        .then((confirmed) => {
          if (confirmed) {
            
            this.AddProcess = true;
            this.message = '';
            this.button = "Process";
            this.isLoadingz = true;
            this.isLoading = true;
            let body = new URLSearchParams();
            body.set('subdivId', this.meterDetail.sdID);
            body.set('dcuId', this.meterDetail.dCUID);
            body.set('meterType', this.meterDetail.meterType);
            body.set('consId', this.meterDetail.cACNO);
            body.set('meterId', this.meterDetail.muID);
            body.set('check', check.toString());

            body.set('emergencyCreditLimit', this.aD.emgCreditLimit.value);
            body.set('alarm', this.aD.alarmLevel.value);

            body.set('consCategory', this.aD.consCategory.value);
            body.set('teriffName', this.aD.teriffName.value);
            body.set('monthlyConsumption', this.aD.monthlyConsumption.value);
            body.set('fixedCharges', this.aD.fixedCharges.value);
            body.set('meterRent', this.aD.meterRent.value);

            body.set('tod1StartTime', this.aD.tod1StartTime.value);
            //body.set('tod1EndTime', this.aD.tod1EndTime.value);
            body.set('ebTod1UnitRate', this.aD.ebTod1UnitRate.value);
            body.set('mvcaTod1Charges', this.aD.mvcaTod1Charges.value);
            body.set('electTod1DutyCharges1', this.aD.electTod1DutyCharges1.value);
            body.set('electTod1DutyCharges2', this.aD.electTod1DutyCharges2.value);
            body.set('electTod1DutyCharges3', this.aD.electTod1DutyCharges3.value);
            body.set('electTod1DutyCharges4', this.aD.electTod1DutyCharges4.value);

            body.set('tod2StartTime', this.aD.tod2StartTime.value);
            //body.set('tod2EndTime', this.aD.tod2EndTime.value);
            body.set('ebTod2UnitRate', this.aD.ebTod2UnitRate.value);
            body.set('mvcaTod2Charges', this.aD.mvcaTod2Charges.value);
            /*body.set('electTod2DutyCharges1', this.aD.electTod2DutyCharges1.value);
            body.set('electTod2DutyCharges2', this.aD.electTod2DutyCharges2.value);
            body.set('electTod2DutyCharges3', this.aD.electTod2DutyCharges3.value);
            body.set('electTod2DutyCharges4', this.aD.electTod2DutyCharges4.value);*/

            body.set('tod3StartTime', this.aD.tod3StartTime.value);
            //body.set('tod3EndTime', this.aD.tod3EndTime.value);
            body.set('ebTod3UnitRate', this.aD.ebTod3UnitRate.value);
            body.set('mvcaTod3Charges', this.aD.mvcaTod3Charges.value);
            /*body.set('electTod3DutyCharges1', this.aD.electTod3DutyCharges1.value);
            body.set('electTod3DutyCharges2', this.aD.electTod3DutyCharges2.value);
            body.set('electTod3DutyCharges3', this.aD.electTod3DutyCharges3.value);
            body.set('electTod3DutyCharges4', this.aD.electTod3DutyCharges4.value);*/

            body.set('tod4StartTime', this.aD.tod4StartTime.value);
            //body.set('tod4EndTime', this.aD.tod4EndTime.value);
            body.set('ebTod4UnitRate', this.aD.ebTod4UnitRate.value);
            body.set('mvcaTod4Charges', this.aD.mvcaTod4Charges.value);
            /*body.set('electTod4DutyCharges1', this.aD.electTod4DutyCharges1.value);
            body.set('electTod4DutyCharges2', this.aD.electTod4DutyCharges2.value);
            body.set('electTod4DutyCharges3', this.aD.electTod4DutyCharges3.value);
            body.set('electTod4DutyCharges4', this.aD.electTod4DutyCharges4.value);*/

            body.set('tod5StartTime', this.aD.tod5StartTime.value);
            //body.set('tod5EndTime', this.aD.tod5EndTime.value);
            body.set('ebTod5UnitRate', this.aD.ebTod5UnitRate.value);
            body.set('mvcaTod5Charges', this.aD.mvcaTod5Charges.value);
            /*body.set('electTod5DutyCharges1', this.aD.electTod5DutyCharges1.value);
            body.set('electTod5DutyCharges2', this.aD.electTod5DutyCharges2.value);
            body.set('electTod5DutyCharges3', this.aD.electTod5DutyCharges3.value);
            body.set('electTod5DutyCharges4', this.aD.electTod5DutyCharges4.value);*/

            body.set('tod6StartTime', this.aD.tod6StartTime.value);
            //body.set('tod6EndTime', this.aD.tod6EndTime.value);
            body.set('ebTod6UnitRate', this.aD.ebTod6UnitRate.value);
            body.set('mvcaTod6Charges', this.aD.mvcaTod6Charges.value);
            /*body.set('electTod6DutyCharges1', this.aD.electTod6DutyCharges1.value);
            body.set('electTod6DutyCharges2', this.aD.electTod6DutyCharges2.value);
            body.set('electTod6DutyCharges3', this.aD.electTod6DutyCharges3.value);
            body.set('electTod6DutyCharges4', this.aD.electTod6DutyCharges4.value);*/

            body.set('tod7StartTime', this.aD.tod7StartTime.value);
            //body.set('tod7EndTime', this.aD.tod7EndTime.value);
            body.set('ebTod7UnitRate', this.aD.ebTod7UnitRate.value);
            body.set('mvcaTod7Charges', this.aD.mvcaTod7Charges.value);
            /*body.set('electTod7DutyCharges1', this.aD.electTod7DutyCharges1.value);
            body.set('electTod7DutyCharges2', this.aD.electTod7DutyCharges2.value);
            body.set('electTod7DutyCharges3', this.aD.electTod7DutyCharges3.value);
            body.set('electTod7DutyCharges4', this.aD.electTod7DutyCharges4.value);*/

            body.set('tod8StartTime', this.aD.tod8StartTime.value);
            //body.set('tod8EndTime', this.aD.tod8EndTime.value);
            body.set('ebTod8UnitRate', this.aD.ebTod8UnitRate.value);
            body.set('mvcaTod8Charges', this.aD.mvcaTod8Charges.value);
            /*body.set('electTod8DutyCharges1', this.aD.electTod8DutyCharges1.value);
            body.set('electTod8DutyCharges2', this.aD.electTod8DutyCharges2.value);
            body.set('electTod8DutyCharges3', this.aD.electTod8DutyCharges3.value);
            body.set('electTod8DutyCharges4', this.aD.electTod8DutyCharges4.value);*/



            body.set('schedularStartTime', this.startDate);
            body.set('cmdFrom', this.cmdformData.toString());
            body.set('dedTypeId', '1');
            body.set('authToken', this.currentUser.authToken);
            this.genReqs.postReq('/configBillingTeriffIpclCommercial', body).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.button = "Update";
              this.isLoadingz = false;
              this.isLoading = false;
              if (this.responseData.success) {

                this.thumb = true;
                this.message = this.responseData.data.message;
                this.modalService.dismissAll;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.thumb = false;
            });

          }
        }).catch(() => console.log('no response or closed'));
    }else{
   
      this.AddProcess = true;
      this.message = '';
      this.button = "Process";
      this.isLoadingz = true;
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.meterDetail.sdID);
      body.set('dcuId', this.meterDetail.dCUID);
      body.set('meterType', this.meterDetail.meterType);
      body.set('consId', this.meterDetail.cACNO);
      body.set('meterId', this.meterDetail.muID);
      body.set('check', check.toString());

      body.set('emergencyCreditLimit', this.aD.emgCreditLimit.value);
      body.set('alarm', this.aD.alarmLevel.value);

      body.set('consCategory', this.aD.consCategory.value);
      body.set('teriffName', this.aD.teriffName.value);
      body.set('monthlyConsumption', this.aD.monthlyConsumption.value);
      body.set('fixedCharges', this.aD.fixedCharges.value);
      body.set('meterRent', this.aD.meterRent.value);
      body.set('electTod1DutyCharges1', this.aD.electTod1DutyCharges1.value);
      body.set('electTod1DutyCharges2', this.aD.electTod1DutyCharges2.value);
      body.set('electTod1DutyCharges3', this.aD.electTod1DutyCharges3.value);
      body.set('electTod1DutyCharges4', this.aD.electTod1DutyCharges4.value);

      //body.set('tod1StartTime', this.aD.tod1StartTime.value);
      //body.set('tod1EndTime', this.aD.tod1EndTime.value);
      body.set('ebTod1UnitRate', this.aD.ebTod1UnitRate.value);
      body.set('mvcaTod1Charges', this.aD.mvcaTod1Charges.value);


      //body.set('tod2StartTime', this.aD.tod2StartTime.value);
      //body.set('tod2EndTime', this.aD.tod2EndTime.value);
      body.set('ebTod2UnitRate', this.aD.ebTod2UnitRate.value);
      body.set('mvcaTod2Charges', this.aD.mvcaTod2Charges.value);
      /*body.set('electTod2DutyCharges1', this.aD.electTod2DutyCharges1.value);
      body.set('electTod2DutyCharges2', this.aD.electTod2DutyCharges2.value);
      body.set('electTod2DutyCharges3', this.aD.electTod2DutyCharges3.value);
      body.set('electTod2DutyCharges4', this.aD.electTod2DutyCharges4.value);*/

      //body.set('tod3StartTime', this.aD.tod3StartTime.value);
      //body.set('tod3EndTime', this.aD.tod3EndTime.value);
      body.set('ebTod3UnitRate', this.aD.ebTod3UnitRate.value);
      body.set('mvcaTod3Charges', this.aD.mvcaTod3Charges.value);
      /*body.set('electTod3DutyCharges1', this.aD.electTod3DutyCharges1.value);
      body.set('electTod3DutyCharges2', this.aD.electTod3DutyCharges2.value);
      body.set('electTod3DutyCharges3', this.aD.electTod3DutyCharges3.value);
      body.set('electTod3DutyCharges4', this.aD.electTod3DutyCharges4.value);*/

      //body.set('tod4StartTime', this.aD.tod4StartTime.value);
      //body.set('tod4EndTime', this.aD.tod4EndTime.value);
      body.set('ebTod4UnitRate', this.aD.ebTod4UnitRate.value);
      body.set('mvcaTod4Charges', this.aD.mvcaTod4Charges.value);
      /*body.set('electTod4DutyCharges1', this.aD.electTod4DutyCharges1.value);
      body.set('electTod4DutyCharges2', this.aD.electTod4DutyCharges2.value);
      body.set('electTod4DutyCharges3', this.aD.electTod4DutyCharges3.value);
      body.set('electTod4DutyCharges4', this.aD.electTod4DutyCharges4.value);*/

      //body.set('tod5StartTime', this.aD.tod5StartTime.value);
      //body.set('tod5EndTime', this.aD.tod5EndTime.value);
      body.set('ebTod5UnitRate', this.aD.ebTod5UnitRate.value);
      body.set('mvcaTod5Charges', this.aD.mvcaTod5Charges.value);
      /*body.set('electTod5DutyCharges1', this.aD.electTod5DutyCharges1.value);
      body.set('electTod5DutyCharges2', this.aD.electTod5DutyCharges2.value);
      body.set('electTod5DutyCharges3', this.aD.electTod5DutyCharges3.value);
      body.set('electTod5DutyCharges4', this.aD.electTod5DutyCharges4.value);*/

      //body.set('tod6StartTime', this.aD.tod6StartTime.value);
      //body.set('tod6EndTime', this.aD.tod6EndTime.value);
      body.set('ebTod6UnitRate', this.aD.ebTod6UnitRate.value);
      body.set('mvcaTod6Charges', this.aD.mvcaTod6Charges.value);
      /*body.set('electTod6DutyCharges1', this.aD.electTod6DutyCharges1.value);
      body.set('electTod6DutyCharges2', this.aD.electTod6DutyCharges2.value);
      body.set('electTod6DutyCharges3', this.aD.electTod6DutyCharges3.value);
      body.set('electTod6DutyCharges4', this.aD.electTod6DutyCharges4.value);*/

      //body.set('tod7StartTime', this.aD.tod7StartTime.value);
      //body.set('tod7EndTime', this.aD.tod7EndTime.value);
      body.set('ebTod7UnitRate', this.aD.ebTod7UnitRate.value);
      body.set('mvcaTod7Charges', this.aD.mvcaTod7Charges.value);
      /*body.set('electTod7DutyCharges1', this.aD.electTod7DutyCharges1.value);
      body.set('electTod7DutyCharges2', this.aD.electTod7DutyCharges2.value);
      body.set('electTod7DutyCharges3', this.aD.electTod7DutyCharges3.value);
      body.set('electTod7DutyCharges4', this.aD.electTod7DutyCharges4.value);*/

      //body.set('tod8StartTime', this.aD.tod8StartTime.value);
      //body.set('tod8EndTime', this.aD.tod8EndTime.value);
      body.set('ebTod8UnitRate', this.aD.ebTod8UnitRate.value);
      body.set('mvcaTod8Charges', this.aD.mvcaTod8Charges.value);
      /*body.set('electTod8DutyCharges1', this.aD.electTod8DutyCharges1.value);
      body.set('electTod8DutyCharges2', this.aD.electTod8DutyCharges2.value);
      body.set('electTod8DutyCharges3', this.aD.electTod8DutyCharges3.value);
      body.set('electTod8DutyCharges4', this.aD.electTod8DutyCharges4.value);*/



      body.set('schedularStartTime', this.startDate);
      body.set('cmdFrom', this.cmdformData.toString());
      body.set('dedTypeId', '1');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/configBillingTeriffIpclCommercial', body).subscribe((result) => {
        this.responseData = result;
        this.AddProcess = false;
        this.button = "Update";
        this.isLoadingz = false;
        this.isLoading = false;
        if (this.responseData.success) {

          this.thumb = true;
          this.message = this.responseData.data.message;

        }
      }, (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
      });
    }
  }
}
