<div class="modal-content-new">
    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        All Supervisers Progress: {{contractorDetail.totalCount}}
      </h4>
    </div>
      <div class="modal-body">
        <ng-container>
         
            
        <div class="row mx-auto form-wrapper table-responsive"  >
            <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
          <table class="table table-bordered table-sm table-hover" *ngIf="isData">
            <thead class="tablebghead">
                  <tr>
                    <td>
                      <div class="d_title">
                        <span></span>Sr. No.
                    </div>
                    </td>
                    <td>
                      <div class="d_title">
                        <span></span>Superviser Name
                    </div>
                    </td>
                    <td>
                      <div class="d_title ">
                        <span></span>Contact No
                    </div>
                    </td>
                    <td>
                        <div class="d_title ">
                          <span></span>3-Ph Installed
                      </div>
                    </td>
                    <td>
                        <div class="d_title ">
                          <span></span>LTCT Installed
                      </div>
                    </td>
                    <td>
                      <div class="d_title ">
                        <span></span>Total Installed
                    </div>
                    </td>
                    <td>
                      <div class="d_title ">
                        <span></span>Active Status
                    </div>
                    </td>
                    <td>
                      <div class="d_title ">
                        <span></span>Active Date & Time
                    </div>
                    </td>
                    
                  </tr>
                </thead>
               <tbody class="tbbodystyle">
                <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{i+1}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.authFullName}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.authMobileId}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.threePhCount}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.ltctCount}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{(item.totalInstalled)}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.authActiveStatus}}</p>
                  </div>
                  </td>
                  <td>
                    <div class="d_content d_content_W">
                      <p>{{item.activeDateTime}}</p>
                  </div>
                  </td>
                </tr>
                </tbody>
            </table>
        </div>
  
            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
              <div class="col-sm-12">
                <img class="center" src="assets/images/Nodata.png">
              </div>
            </div>
            
          </ng-container>
  </div>
  </div>  