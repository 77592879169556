import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-checkmeterbill',
  templateUrl: './checkmeterbill.component.html',
  styleUrls: ['./checkmeterbill.component.scss']
})
export class CheckmeterbillComponent implements OnInit { 
  meterData: any;
  public today = new Date();
  isLoading:boolean = false;
  isData:boolean = false;
  faSpinner = faSpinner;
  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  consAccountNo:any='';
  meterId:any='';
  RTC:any='';
  Voltage:any='';
  phaseCurrent:any='';
  neturalCurrent:any='';
  powerFactor:any='';
  Frequency:any='';
  apperantPower:any='';
  activePower:any='';
  actveImport:any='';
  apparentImport:any='';
  mDkWmport:any='';
  mDkWImportDateTime:any='';
  mDkVAImport:any='';
  mDkVAImportDateTime:any='';
  tamperCount:any='';
  billingCount:any='';
  programmingCount:any='';
  activeExport:any='';
  apparentExport:any='';
  loadConnected:any='';
  meterDetail:any = {};
  meterType:any='';
  view:any='';
  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];
  permission = [];

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
    this.meterType=this.meterData.meterType;
    this.view=this.meterData.view;
  }

 

  getRealTimeStatus() {
    let body = new URLSearchParams();
    this.isLoading = true;
    body.set('subDivId', this.meterData.subdivId);
    body.set('meterId', this.meterData.meterId);
    body.set('meterType', this.meterData.meterType);
    body.set('authToken', this.meterData.token);
    this.genReqs.postReq('/getCheckConsumerBill', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        
        this.letviewis = this.responseData.data.data;
        if(this.letviewis.length!=0){
          this.isData = true;
        }
        this.permission = this.responseData.data.permission;
        //console.log( this.permission,this.view);
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}
