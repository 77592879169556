<form [formGroup]="actionForm" (ngSubmit)="submitForm()">
    <fieldset class="scheduler-border">
        <legend class="scheduler-border">
            {{formName1}}
        </legend>
        <div class="container">
            <div class="row">
                <table>
                    <tr>
                        <td style="width:92%;text-align-last: start;">
                            <B>{{formName2}}</B>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate" autocomplete="off"
                                formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)"
                                bsDatepicker [bsConfig]="bsConfig" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.schedularStartEndDate.errors }" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Start Date</label>
                            <div *ngIf="aD.schedularStartEndDate.invalid && (aD.schedularStartEndDate.dirty || aD.schedularStartEndDate.touched)"
                                class="alert alert-danger">
                                <div *ngIf="aD.schedularStartEndDate.errors.required">
                                    Start Date required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Start Date (MM/YYYY)" name="pastTeriffDate" autocomplete="off"
                                formControlName="pastTeriffDate" bsDatepicker [bsConfig]="bsConfig" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.pastTeriffDate.errors }" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Old Tariff Date (MM/YYYY)</label>
                            <div *ngIf="aD.pastTeriffDate.invalid && (aD.pastTeriffDate.dirty || aD.pastTeriffDate.touched)"
                                class="alert alert-danger">
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Category" name="consCategory" formControlName="consCategory"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.consCategory.errors }"
                                required>
                                <mat-option value="C(Ltp)(ia)">
                                    C(Ltp)(ia)
                                </mat-option>
                                <mat-option value="C(Ltp)(ib)">
                                    C(Ltp)(ib)
                                </mat-option>
                                <mat-option value="Rate D(3)">
                                    Rate D(3)
                                </mat-option>
                                <mat-option value="Rate Puppt(LT)">
                                    Rate Puppt(LT)
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-list-alt icon_innertxtbox"></i>
                            <label>Consumer Catergory</label>
                            <div *ngIf="submitted && aD.consCategory.errors" class="invalid-feedback">
                                <div *ngIf="aD.consCategory.errors.required">
                                    Consumer Catergory Is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Teriff" name="teriffName" formControlName="teriffName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.teriffName.errors }"
                                required>
                                <mat-option value="0">
                                    Prepaid
                                </mat-option>
                                <mat-option value="1">
                                    Postpaid
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-list-alt icon_innertxtbox"></i>
                            <label>Teriff Name</label>
                            <div *ngIf="submitted && aD.teriffName.errors" class="invalid-feedback">
                                <div *ngIf="aD.teriffName.errors.required">
                                    Teriff Is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <!--<div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Monthly Consumption" name="monthlyConsumption"
                                formControlName="monthlyConsumption" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.monthlyConsumption.errors }" required>
                                <mat-option value="0"> kWh </mat-option>
                                <mat-option value="1"> kVAh </mat-option>
                            </mat-select>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <label>Monthly Consumption</label>
                            <div *ngIf="submitted && aD.monthlyConsumption.errors" class="invalid-feedback">
                                <div *ngIf="aD.monthlyConsumption.errors.required">
                                    Monthly Consumption required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>-->
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="emgCreditLimit" formControlName="emgCreditLimit"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.emgCreditLimit.errors }" required>
                            <label>
                                Emergency Credit Limit<b>(Rs.)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.emgCreditLimit.errors" class="invalid-feedback">
                                <div *ngIf="aD.emgCreditLimit.errors.required">
                                    Emergency Credit Limit Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="alarmLevel" formControlName="alarmLevel"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.alarmLevel.errors }" required>
                            <label>
                                Alarm Level <b>(Rs.)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.alarmLevel.errors" class="invalid-feedback">
                                <div *ngIf="aD.alarmLevel.errors.required">
                                    Alarm Level Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="fixedCharges" formControlName="fixedCharges"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.fixedCharges.errors }" required>
                            <label>
                                Fixed Charges <b>(Rs./kWh)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.fixedCharges.errors" class="invalid-feedback">
                                <div *ngIf="aD.fixedCharges.errors.required">
                                    MVCA Charges Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Fixed Charges " name="meterRent" formControlName="meterRent"
                                autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.meterRent.errors }" required>
                            <label>
                                Meter Rent <b>(Rs./Month)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.meterRent.errors" class="invalid-feedback">
                                <div *ngIf="aD.meterRent.errors.required">
                                    Meter Rent Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electTod1DutyCharges1"
                                formControlName="electTod1DutyCharges1" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electTod1DutyCharges1.errors }" required>
                            <label>
                                ED Charges <b>(0-150 U)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electTod1DutyCharges1.errors" class="invalid-feedback">
                                <div *ngIf="aD.electTod1DutyCharges1.errors.required">
                                    Electricity Duty Charges less than 150 Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>

                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electTod1DutyCharges2"
                                formControlName="electTod1DutyCharges2" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electTod1DutyCharges2.errors }" required>
                            <label>
                                ED Charges <b>(151-500 U)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electTod1DutyCharges2.errors" class="invalid-feedback">
                                <div *ngIf="aD.electTod1DutyCharges2.errors.required">
                                    Electricity Duty Charges Greater that 300 Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-3 ">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electTod1DutyCharges3"
                                formControlName="electTod1DutyCharges3" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electTod1DutyCharges3.errors }" required>
                            <label>
                                ED Charges <b>(501-1000 U)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electTod1DutyCharges3.errors" class="invalid-feedback">
                                <div *ngIf="aD.electTod1DutyCharges3.errors.required">
                                    Electricity Duty Charges Greater that 501 Is required
                                </div>
                            </div>
                        </span>

                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Elect. Duty Charges " name="electTod1DutyCharges4"
                                formControlName="electTod1DutyCharges4" autocomplete="off" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.electTod1DutyCharges4.errors }" required>
                            <label>
                                ED Charges <b>(1001-N U)</b></label>
                            <i class="fa fa-rupee icon_innertxtbox"></i>
                            <div *ngIf="submitted && aD.electTod1DutyCharges4.errors" class="invalid-feedback">
                                <div *ngIf="aD.electTod1DutyCharges4.errors.required">
                                    Electricity Duty Charges Greater that 1001 Is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_1">
                    <legend class="scheduler-border">
                        Tod Zone 1
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">

                                        <input type="time" matInput placeholder="Start Time" name="tod1StartTime"
                                            formControlName="tod1StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod1StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod1StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod1StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod1EndTime"
                                            formControlName="tod1EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod1EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod1EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod1EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod1UnitRate"
                                            formControlName="ebTod1UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod1UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod1UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod1UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod1Charges"
                                            formControlName="mvcaTod1Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod1Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod1Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod1Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>


                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_2">
                    <legend class="scheduler-border">
                        Tod Zone 2
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod2StartTime"
                                            formControlName="tod2StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod2StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod2StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod2StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod2EndTime"
                                            formControlName="tod2EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod2EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod2EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod2EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod2UnitRate"
                                            formControlName="ebTod2UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod2UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod2UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod2UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod2Charges"
                                            formControlName="mvcaTod2Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod2Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod2Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod2Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod2DutyCharges1"
                                            formControlName="electTod2DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod2DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod2DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod2DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod2DutyCharges2"
                                            formControlName="electTod2DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod2DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod2DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod2DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod2DutyCharges3"
                                            formControlName="electTod2DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod2DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod2DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod2DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod2DutyCharges4"
                                            formControlName="electTod2DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod2DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod2DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod2DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_3">
                    <legend class="scheduler-border">
                        Tod Zone 3
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod3StartTime"
                                            formControlName="tod3StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod3StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod3StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod3StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod3EndTime"
                                            formControlName="tod3EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod3EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod3EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod3EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod3UnitRate"
                                            formControlName="ebTod3UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod3UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod3UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod3UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod3Charges"
                                            formControlName="mvcaTod3Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod3Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod3Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod3Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod3DutyCharges1"
                                            formControlName="electTod3DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod3DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod3DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod3DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod3DutyCharges2"
                                            formControlName="electTod3DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod3DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod3DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod3DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod3DutyCharges3"
                                            formControlName="electTod3DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod3DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod3DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod3DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod3DutyCharges4"
                                            formControlName="electTod3DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod3DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod3DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod3DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_4">
                    <legend class="scheduler-border">
                        Tod Zone 4
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                      
                                        <input type="time" matInput placeholder="Start Time" name="tod4StartTime"
                                            formControlName="tod4StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod4StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod4StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod4StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod4EndTime"
                                            formControlName="tod4EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod4EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod4EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod4EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod4UnitRate"
                                            formControlName="ebTod4UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod4UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod4UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod4UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod4Charges"
                                            formControlName="mvcaTod4Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod4Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod4Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod4Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod4DutyCharges1"
                                            formControlName="electTod4DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod4DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod4DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod4DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod4DutyCharges2"
                                            formControlName="electTod4DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod4DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod4DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod4DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod4DutyCharges3"
                                            formControlName="electTod4DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod4DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod4DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod4DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod4DutyCharges4"
                                            formControlName="electTod4DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod4DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod4DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod4DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_5">
                    <legend class="scheduler-border">
                        Tod Zone 5
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod5StartTime"
                                            formControlName="tod5StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod5StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod5StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod5StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod5EndTime"
                                            formControlName="tod5EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod5EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod5EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod5EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod5UnitRate"
                                            formControlName="ebTod5UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod5UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod5UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod5UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod5Charges"
                                            formControlName="mvcaTod5Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod5Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod5Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod5Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod5DutyCharges1"
                                            formControlName="electTod5DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod5DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod5DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod5DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod5DutyCharges2"
                                            formControlName="electTod5DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod5DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod5DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod5DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod5DutyCharges3"
                                            formControlName="electTod5DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod5DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod5DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod5DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod5DutyCharges4"
                                            formControlName="electTod5DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod5DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod5DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod5DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_6">
                    <legend class="scheduler-border">
                        Tod Zone 6
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod6StartTime"
                                            formControlName="tod6StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod6StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod6StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod6StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod6EndTime"
                                            formControlName="tod6EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod6EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod6EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod6EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod6UnitRate"
                                            formControlName="ebTod6UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod6UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod6UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod6UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod6Charges"
                                            formControlName="mvcaTod6Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod6Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod6Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod6Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod6DutyCharges1"
                                            formControlName="electTod6DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod6DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod6DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod6DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod6DutyCharges2"
                                            formControlName="electTod6DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod6DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod6DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod6DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod6DutyCharges3"
                                            formControlName="electTod6DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod6DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod6DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod6DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod6DutyCharges4"
                                            formControlName="electTod6DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod6DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod6DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod6DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_7">
                    <legend class="scheduler-border">
                        Tod Zone 7
                    </legend>
                    <div class="container">

                        <div class="row">
                             <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod7StartTime"
                                            formControlName="tod7StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod7StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod7StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod7StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod7EndTime"
                                            formControlName="tod7EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod7EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod7EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod7EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod7UnitRate"
                                            formControlName="ebTod7UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod7UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod7UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod7UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod7Charges"
                                            formControlName="mvcaTod7Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod7Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod7Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod7Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod7DutyCharges1"
                                            formControlName="electTod7DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod7DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod7DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod7DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod7DutyCharges2"
                                            formControlName="electTod7DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod7DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod7DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod7DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod7DutyCharges3"
                                            formControlName="electTod7DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod7DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod7DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod7DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod7DutyCharges4"
                                            formControlName="electTod7DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod7DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod7DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod7DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config_8">
                    <legend class="scheduler-border">
                        Tod Zone 8
                    </legend>
                    <div class="container">

                        <div class="row">
                            <!--<div class="col-sm-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                       
                                        <input type="time" matInput placeholder="Start Time" name="tod8StartTime"
                                            formControlName="tod8StartTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod8StartTime.errors }" required>
                                        <label>
                                            Start Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod8StartTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod8StartTime.errors.required">
                                                Start Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="time" matInput placeholder="End Time" name="tod8EndTime"
                                            formControlName="tod8EndTime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.tod8EndTime.errors }" required>
                                        <label>
                                            End Time</label>
                                        <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.tod8EndTime.errors" class="invalid-feedback">
                                            <div *ngIf="aD.tod8EndTime.errors.required">
                                                End Time Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Energy Charges " name="ebTod8UnitRate"
                                            formControlName="ebTod8UnitRate" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.ebTod8UnitRate.errors }"
                                            required>
                                        <label>
                                            Energy Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.ebTod8UnitRate.errors" class="invalid-feedback">
                                            <div *ngIf="aD.ebTod8UnitRate.errors.required">
                                                Energy Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="MVCA Charges " name="mvcaTod8Charges"
                                            formControlName="mvcaTod8Charges" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.mvcaTod8Charges.errors }"
                                            required>
                                        <label>
                                            MVCA Charges <b>(P/kWh)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.mvcaTod8Charges.errors" class="invalid-feedback">
                                            <div *ngIf="aD.mvcaTod8Charges.errors.required">
                                                MVCA Charges Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <!--<div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod8DutyCharges1"
                                            formControlName="electTod8DutyCharges1" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod8DutyCharges1.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(0-150 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod8DutyCharges1.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod8DutyCharges1.errors.required">
                                                Electricity Duty Charges less than 150 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod8DutyCharges2"
                                            formControlName="electTod8DutyCharges2" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod8DutyCharges2.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(151-500 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod8DutyCharges2.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod8DutyCharges2.errors.required">
                                                Electricity Duty Charges Greater that 300 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3 ">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod8DutyCharges3"
                                            formControlName="electTod8DutyCharges3" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod8DutyCharges3.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(501-1000 U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod8DutyCharges3.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod8DutyCharges3.errors.required">
                                                Electricity Duty Charges Greater that 501 Is required
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Elect. Duty Charges " name="electTod8DutyCharges4"
                                            formControlName="electTod8DutyCharges4" autocomplete="off"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aD.electTod8DutyCharges4.errors }"
                                            required>
                                        <label>
                                            ED Charges <b>(1001-N U)</b></label>
                                        <i class="fa fa-rupee icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aD.electTod8DutyCharges4.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aD.electTod8DutyCharges4.errors.required">
                                                Electricity Duty Charges Greater that 1001 Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="row iconconfigbtn">
                <div class="col-sm-10 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
                    </ng-container>

                    <div role="alert" *ngIf="message.length > 0">
                        <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 text-right">
                    <!--<button type="submit"
                        (click)="submitForm()">
                        Submit
                    </button>-->
                    <button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                        (click)="submitForm()">
                        <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoadingz, 'fa-refresh': !isLoadingz}"
                            style="font-size: 15px;"></i>
                        {{button}}
                    </button>
                </div>
            </div>
        </div>
    </fieldset>
</form>