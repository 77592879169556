import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigphasegsupply',
  templateUrl: './meterconfigphasegsupply.component.html',
  styleUrls: ['./meterconfigphasegsupply.component.scss']
})
export class MeterconfigphasegsupplyComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      rphase: ['', Validators.required],
      yphase: ['', Validators.required],
      bphase: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
