import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { webconfig } from 'src/app/_models/webconfig';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-editconsumer',
  templateUrl: './editconsumer.component.html',
  styleUrls: ['./editconsumer.component.scss']
})
export class EditconsumerComponent implements OnInit {
  llebData = "LL EB (0.05kW-15.00kW)";
  lldgData = "LL DG (0.05kW-15.00kW)";
  ebLimit = 15;
  dgLimit = 15;
  faSpinner = faSpinner;
  filterMeter: any;
  filterIp: any;
  enableIpField = true;
  messageNew = '';
  isLoading = false;
  thumb = true;
  AddProcess: boolean = false;
  loadingMessage: any;
  message: string = '';
  authorityDOB = "0000-00-00";
  addForm: UntypedFormGroup;
  responseMessage: string = '';
  date: any;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  getThrough: Access;
  loginType: boolean = false;
  consumer: any;
  submitted: boolean = false;
  activeStatus: any = [
    { 'id': 'N', 'Name': 'In-Active' },
    { 'id': 'Y', 'Name': 'Active' }
  ];

  firmWareVersion: any = [
    { 'id': 0, 'Name': '' },
    { 'id': 1, 'Name': '' }
  ];

  conCategory: any = [
    { 'id': 1, 'Name': 'D(Lpp)' },
    { 'id': 2, 'Name': 'C(Ltp)(ia)' },
    { 'id': 3, 'Name': 'C(Ltp)(ib)' },
    { 'id': 4, 'Name': 'Rate D(3)' },
    { 'id': 5, 'Name': 'Rate Puppt(LT)' },
  ];


  comm: any = [
    { 'id': '1', 'Name': 'GPRS Mode' },
    { 'id': '2', 'Name': 'RF Mode' }
  ];

  simType: any = [
    { 'id': 0, 'name': '' }
  ];
  area: any = [
    { 'id': 0, 'Name': '' },
    { 'id': 1, 'Name': '' }
  ];
  groupData: any = [{}];
  towerData: any = [{
    'towerId': 0,
    'subDivId': 0,
    'dgId': 0,
    'dgName': "dgName",
    'towerName': "Select Tower"
  }];
  dgData: any = [{
    'dgId': 0,
    'subDivId': 0,
    'dgName': 'Select DG'
  }];

  mType: any = [
    { 'id': '1', 'Name': '1 Phase' },
    { 'id': '3', 'Name': '3 Phase' },
    { 'id': '2', 'Name': 'LTCT Meters' }
  ];

  constructor(private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private router: Router,
    private httpClient: HttpClient
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  onCommTypeChange(e) {
    if (this.aF.commMode.value == '1') {
      this.enableIpField = true;
      //this.aF.hesId.setValue('00000000-0000-0000-0000-000000000000');

    }
    if (this.aF.commMode.value == '2') {
      this.enableIpField = false;
      //this.aF.meterIpAddress.setValue('');
      //this.aF.meterPortNo.setValue(0);
    }
  }

  ngOnInit() {

    if (this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      this.loginType = true;
    }

    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
    this.meterFrimwareVersion();
    if (this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      this.addForm = this.formBuilder.group({
        flatNo: ['', Validators.required],
        dgNameData: ['', Validators.required],
        towerNameData: ['', Validators.required],
        groupNameData: ['', Validators.required],
        consFullName: ['', Validators.required],
        consUserName: [{ value: '', disabled: true }],
        consPassword: ['', Validators.required],
        consEmailId: ['', [Validators.required, Validators.email]],
        consLandlineNo: [''],
        consMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
        consHomeAddressline1: ['', Validators.required],
        consHomeAddressline2: [''],
        consOfficeAddressline1: [''],
        consOfficeAddressline2: [''],
        latitude: [''],
        longitude: [''],
        authorityDOB: ['', Validators.required],
        conActiveStatus: ['', Validators.required],
        meterSerialNo: [''],
        loadLimitEB: ['', [Validators.min(0.05), Validators.max(65.00)]],
        meterIpAddress: ['', Validators.required],
        meterPortNo: ['', Validators.required],
        hesId: [''],
        meterType: ['', Validators.required],
        meterFirmwareCodeVersion: ['', Validators.required],
        commMode: ['', Validators.required],
        meterInstalletionDateTime: ['', Validators.required],
        gprsSimNo: [''],
        gprsSimContactNo: [''],
        gprsSimType: [''],
        flatArea: [''],
        appVersion: [''],
        category: ['']
      });
    } else {
      this.addForm = this.formBuilder.group({
        flatNo: ['', Validators.required],
        dgNameData: ['', Validators.required],
        towerNameData: ['', Validators.required],
        groupNameData: ['', Validators.required],
        consFullName: ['', Validators.required],
        consUserName: [{ value: '', disabled: true }],
        consPassword: ['', Validators.required],
        consEmailId: ['', [Validators.required, Validators.email]],
        consLandlineNo: [''],
        consMobileNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN), Validators.minLength(10), Validators.maxLength(10)]],
        consHomeAddressline1: ['', Validators.required],
        consHomeAddressline2: [''],
        consOfficeAddressline1: [''],
        consOfficeAddressline2: [''],
        latitude: [''],
        longitude: [''],
        authorityDOB: ['', Validators.required],
        conActiveStatus: ['', Validators.required],
        meterSerialNo: [''],
        loadLimitEB: ['', [Validators.min(0.05), Validators.max(65.00)]],
        loadLimitDG: ['', [Validators.min(0), Validators.max(65.00)]],
        meterIpAddress: ['', Validators.required],
        meterPortNo: ['', Validators.required],
        hesId: [''],
        meterType: ['', Validators.required],
        transferAmount: ['', [Validators.min(1), Validators.max(10000)]],
        adjustmentAmount: ['', [Validators.min(1), Validators.max(10000)]],
        meterFirmwareCodeVersion: ['', Validators.required],
        commMode: ['', Validators.required],
        dgFreeUnits: [''],
        meterInstalletionDateTime: ['', Validators.required],
        gprsSimNo: [''],
        gprsSimContactNo: [''],
        gprsSimType: [''],
        flatArea: [''],
        appVersion: ['']
      });
    }
    this.getDgData();
    if (this.loginType) {
      this.getTowerData();
    }
    this.getSimType();
    this.getGroupName();
    this.getTowerName();
    this.flatArea();
    this.aF.appVersion.setValue(this.consumer.appVersion);
    this.aF.hesId.setValue(this.consumer.hesId);
    this.aF.flatNo.setValue(this.consumer.flatNo);
    this.aF.dgNameData.setValue(this.consumer.dgId);
    this.aF.towerNameData.setValue(this.consumer.towerId);
    this.aF.groupNameData.setValue(this.consumer.groupId);
    this.aF.consFullName.setValue(this.consumer.consFullName);
    this.aF.consUserName.setValue(this.consumer.consUserName);
    this.aF.consPassword.setValue(this.consumer.cons);
    this.aF.consEmailId.setValue(this.consumer.consEmail);
    this.aF.consLandlineNo.setValue(this.consumer.consLandLine);
    this.aF.consMobileNo.setValue(this.consumer.consMobileId);
    this.aF.consHomeAddressline1.setValue(this.consumer.consHomeAdd1);
    this.aF.consHomeAddressline2.setValue(this.consumer.consHomeAdd2);
    this.aF.consOfficeAddressline1.setValue(this.consumer.consOfficeAdd1);
    this.aF.consOfficeAddressline2.setValue(this.consumer.consOfficeAdd2);
    this.aF.latitude.setValue(this.consumer.latitude);
    this.aF.longitude.setValue(this.consumer.longitude);
    //this.aF.authorityDOB.setValue(this.consumer.consDOB);
    this.aF.authorityDOB.setValue(this.formateDate(this.consumer.consDOB));
    //this.date = new FormControl(new Date(this.consumer.consDOB));
    this.aF.conActiveStatus.setValue(this.consumer.consActiveStatus);

    this.aF.meterSerialNo.setValue(this.consumer.meterSerialNo);
    this.aF.loadLimitEB.setValue(this.consumer.loadLimitEB);
    if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      this.aF.loadLimitDG.setValue(this.consumer.loadLimitDG);
    }
    this.aF.meterIpAddress.setValue(this.consumer.meterIpAddress);
    this.aF.meterPortNo.setValue(this.consumer.meterPortNo);
    this.aF.commMode.setValue(this.consumer.commMode);
    if (this.consumer.commMode == '1') {
      this.enableIpField = true;
    } else if (this.consumer.commMode == '2') {
      this.enableIpField = false;
    }
    if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      this.aF.dgFreeUnits.setValue(this.consumer.dgFreeUnits);
    }
    if (this.consumer.meterType == '1') {
      this.llebData = "LL EB (0.05kW-15.00kW)";
      this.lldgData = "LL DG (0.05kW-15.00kW)";
    } else {
      this.llebData = "LL EB (0.05kW-65.00kW)";
      this.lldgData = "LL DG (0.05kW-65.00kW)";
    }
    this.aF.meterType.setValue(this.consumer.meterType);
    this.aF.meterFirmwareCodeVersion.setValue(this.consumer.mFCV);
    this.aF.gprsSimNo.setValue(this.consumer.mGsn);
    this.aF.gprsSimContactNo.setValue(this.consumer.mGscn);
    this.aF.gprsSimType.setValue(this.consumer.simTypeData);
    this.aF.flatArea.setValue(this.consumer.flatArea);

    this.aF.meterInstalletionDateTime.setValue(this.consumer.mInstallDT);
    this.aF.category.setValue(this.consumer.deductionType);
  }

  getSimType() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSimTypeData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.simType = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  getTowerData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', '0');
    body.set('dgId', this.dgData[0].dgId);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.towerData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57)) {
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    } else {
      alert("Can't use special charactors!");
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }
  }

  onMeterTypeChange(e) {
    if (this.aF.meterType.value == '1') {
      this.ebLimit = 15;
      this.dgLimit = 15;
      this.llebData = "LL EB (0.05kW-15.00kW)";
      this.lldgData = "LL DG (0.05kW-15.00kW)";
      //this.aF.loadLimitEB.reset();
      //this.aF.loadLimitDG.reset();
    }
    if (this.aF.meterType.value == '3') {
      this.ebLimit = 65;
      this.dgLimit = 65;
      this.llebData = "LL EB (0.05kW-65.00kW)";
      this.lldgData = "LL DG (0.05kW-65.00kW)";
      //this.aF.loadLimitEB.reset();
      //this.aF.loadLimitDG.reset();
    }
  }


  ngAfterViewInit() {

  }
  ngOnDestroy() {
    //this.modalService.dismissAll();
  }

  formateDate(datevalue) {
    let date = new Date(datevalue);
    //console.log("daevalue",datevalue," ",date," ",date.getMonth()+1," ",date.getDate()," ",date.getFullYear());
    return (date.getMonth() + 1) + '/' + (date.getDate()) + '/' + date.getFullYear();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateFieldsIp(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterIp);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Ip")) {
          this.aF.meterIpAddress.setErrors({ 'uniqueIp': true });
          //this.thumb = false;
          //this.aF.meterIpAddress.reset();
        } else {
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  validateFieldsMeter(addNew: boolean = false) {
    this.messageNew = '';
    let body = new URLSearchParams();
    body.set('subDivisionId', this.currentUser.data.userData.subdivision);
    body.set('flatId', this.filterMeter);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/checkFlatNo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Meter")) {
          this.aF.meterSerialNo.setErrors({ 'uniqueMeter': true });
          //this.thumb = false;
          //this.aF.meterSerialNo.reset();
        } else {
          this.messageNew = '';
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  onValidateFieldsIp(search) {
    this.filterIp = search.trim();
    if (this.filterIp != this.consumer.meterIpAddress) {
      this.validateFieldsIp(false);
    }
  }

  onValidateFieldsMeter(search) {
    this.filterMeter = search.trim();
    if (this.filterMeter != this.consumer.meterSerialNo) {
      this.validateFieldsMeter(false);
    }
  }


  get aF() { return this.addForm.controls; }
  onSubmitAddForm() {
  //  alert("hello");
    /*alert("flatNo"+this.aF.flatNo.valid);
    alert("dgNameData"+this.aF.dgNameData.valid);
    alert("towerNameData"+this.aF.towerNameData.valid);
    alert("groupNameData"+this.aF.groupNameData.valid);
    alert("consFullName"+this.aF.consFullName.valid);
    alert("consUserName"+this.aF.consUserName.valid);
    alert("consPassword"+this.aF.consPassword.valid);
    alert("consEmailId"+this.aF.consEmailId.valid);
    alert("consLandlineNo"+this.aF.consLandlineNo.valid);
    alert("consMobileNo"+this.aF.consMobileNo.valid);
    alert("consHomeAddressline1"+this.aF.consHomeAddressline1.valid);
    alert("consHomeAddressline2"+this.aF.consHomeAddressline2.valid);
    alert("consOfficeAddressline1"+this.aF.consOfficeAddressline1.valid);
    alert("consOfficeAddressline2"+this.aF.consOfficeAddressline2.valid);

    alert("latitude"+this.aF.latitude.valid);
    alert("longitude"+this.aF.longitude.valid);
    alert("authorityDOB"+this.aF.authorityDOB.valid);
    alert("meterIpAddress"+this.aF.meterIpAddress.valid);
    alert("meterPortNo"+this.aF.meterPortNo.valid);
    alert("hesId"+this.aF.hesId.valid);
    alert("meterType"+this.aF.meterType.valid);
    alert("transferAmount"+this.aF.transferAmount.valid);
    alert("adjustmentAmount"+this.aF.adjustmentAmount.valid);
    alert("meterFirmwareCodeVersion"+this.aF.meterFirmwareCodeVersion.valid);
    alert("commMode"+this.aF.commMode.valid);
    alert("dgFreeUnits"+this.aF.dgFreeUnits.valid);
    alert("meterInstalletionDateTime"+this.aF.meterInstalletionDateTime.valid);
    alert("gprsSimNo"+this.aF.gprsSimNo.valid);
    alert("gprsSimContactNo"+this.aF.gprsSimContactNo.valid);
    alert("gprsSimType"+this.aF.gprsSimType.valid);*/
    //console.log("hello",this.aF.authorityDOB.value);
    if (this.aF.meterType.value == '1') {
      if (this.aF.loadLimitEB.value > 15) {
        alert("Single phase Grid Supply Load Limit must be less than or equal to 15 kW");
        this.aF.loadLimitEB.reset();
        return;
      }
      if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
        if (this.aF.loadLimitDG.value > 15) {
          alert("Single phase DG Supply Load Limit must be less than or equal to 15 kW");
          this.aF.loadLimitDG.reset();
          return;
        }
      }
    } else {
      if (this.aF.loadLimitEB.value > 65) {
        alert("Three phase Grid Supply Load Limit must be less than or equal to 65 kW");
        this.aF.loadLimitEB.reset();
        return;
      }
      if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
        if (this.aF.loadLimitDG.value > 65) {
          alert("Three phase DG Supply Load Limit must be less than or equal to 65 kW");
          this.aF.loadLimitDG.reset();
          return;
        }
      }
    }
    
    //alert("hello"+this.addForm.invalid);
    if (this.addForm.invalid) {
      this.submitted = true;
      return;
    }
    this.message = '';
    this.isLoading = true;
    let body = new URLSearchParams();
    //alert("1");
    body.set('consUId', this.consumer.consAccountNo);
    //alert("2");
    body.set('subDivId', this.currentUser.data.userData.subdivision);
    body.set('flatNo', this.aF.flatNo.value);
    body.set('dgId', this.aF.dgNameData.value);
    //alert("3");
    body.set('towerId', this.aF.towerNameData.value);
    body.set('groupId', this.aF.groupNameData.value);
    //alert("4");
    body.set('consFullName', this.aF.consFullName.value);
    body.set('consUserName', this.aF.consUserName.value);
    body.set('consPassword', this.aF.consPassword.value);
    //alert("5");
    body.set('consEmailId', this.aF.consEmailId.value);
    body.set('consLandlineNo', this.aF.consLandlineNo.value);
    body.set('consMobileNo', this.aF.consMobileNo.value);
    //alert("6");
    body.set('consHomeAddressline1', this.aF.consHomeAddressline1.value);
    body.set('consHomeAddressline2', this.aF.consHomeAddressline2.value);
    body.set('consOfficeAddressline1', this.aF.consOfficeAddressline1.value);
    //alert("7");
    body.set('consOfficeAddressline2', this.aF.consOfficeAddressline2.value);
    body.set('latitude', this.aF.latitude.value);
    body.set('longitude', this.aF.longitude.value);
    body.set('conActiveStatus', this.aF.conActiveStatus.value);
    //alert("8");
    //body.set('consDOB', this.authorityDOB);
    body.set('consDOB', this.convert(this.aF.authorityDOB.value));
    body.set('meterInstalletionDateTime', this.aF.meterInstalletionDateTime.value);
    if (this.aF.meterSerialNo.value == '') {
      body.set('meterSerialNo', '');
      body.set('loadLimitEB', '');
      body.set('loadLimitDG', '');
      body.set('meterIpAddress', '');
      body.set('meterPortNo', '');
      body.set('gprsSimNo', '');
      body.set('gprsSimContactNo', '');
      body.set('gprsSimType', '');
      body.set('meterType', '');
      body.set('hesId', '00000000-0000-0000-0000-000000000000');
      body.set('transferAmount', '0');
      body.set('adjustmentAmount', '0');
    } else {
      body.set('meterSerialNo', this.aF.meterSerialNo.value);
      body.set('loadLimitEB', this.aF.loadLimitEB.value);
      if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View){
        body.set('loadLimitDG', this.aF.loadLimitDG.value);
        body.set('category','');
      } else {
        body.set('loadLimitDG', '');
        body.set('category',this.aF.category.value);
      }
      body.set('meterIpAddress', this.aF.meterIpAddress.value);
      body.set('meterPortNo', this.aF.meterPortNo.value);
      body.set('gprsSimNo', this.aF.gprsSimNo.value);
      body.set('gprsSimContactNo', this.aF.gprsSimContactNo.value);
      body.set('gprsSimType', this.aF.gprsSimType.value);
      body.set('hesId', this.aF.hesId.value);
      body.set('meterType', this.aF.meterType.value);
      body.set('flatArea', this.aF.flatArea.value);
      body.set('appVersion', this.aF.appVersion.value);
      if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
        if (this.aF.transferAmount.value == '') {
          body.set('transferAmount', '0');
        } else {
          body.set('transferAmount', this.aF.transferAmount.value);
        }
        if (this.aF.adjustmentAmount.value == '') {
          body.set('adjustmentAmount', '0');
        } else {
          body.set('adjustmentAmount', this.aF.adjustmentAmount.value);
        }
      } else {
        body.set('transferAmount', '0');
        body.set('adjustmentAmount', '0');
      }
    }

    body.set('commMode', this.aF.commMode.value);
    if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      body.set('dgFreeUnits', this.aF.dgFreeUnits.value);
    } else {
      body.set('dgFreeUnits', '');
    }
    body.set('meterFirmwareCodeVersion', this.aF.meterFirmwareCodeVersion.value);
    body.set('meterUId', this.consumer.meterUId);
    body.set('authToken', this.currentUser.authToken);

    this.AddProcess = true;
    this.genReqs.postReq('/updateConsumerInfo', body).subscribe((result) => {
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;

        this.message = this.responseData.data.message;

        if (this.message.indexOf("So, it has been assigned to Consumer") == -1) {
          if (this.message.indexOf("Flat No") !== -1) {
            this.aF.flatNo.reset();
          }
          if (this.message.indexOf("IP Address") !== -1) {
            this.aF.meterIpAddress.reset();
          }
          if (this.message.indexOf("Meter Id") !== -1) {
            this.aF.meterSerialNo.reset();
          }
          if (this.message.indexOf("SIM No") !== -1) {
            this.aF.meterSerialNo.reset();
          }
          if (this.message.indexOf("SIM Contact No") !== -1) {
            this.aF.meterSerialNo.reset();
          }
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.AddProcess = false;
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  flatArea() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '0');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getFlatArea', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.area = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getDgData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDGData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.dgData = this.responseData.data;
        //this.getTowerData();
        //     setTimeout(() => {
        //         this.dgData = this.responseData.data;
        //     });
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      //this.apiMessage = 'Unable to process yours request!';
    });
  }

  onDGChange(e) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', '0');
    body.set('dgId', this.aF.dgNameData.value);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    //console.log("dgtowerload",this.currentUser.data.userData.subdivision," ",this.aF.dgNameData.value);
    //this.selectedTower = e.value;
    this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.towerData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  getTowerName() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', '0');
    body.set('dgId', this.consumer.dgId);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    //console.log("dgtowerload",this.currentUser.data.userData.subdivision," ",this.aF.dgNameData.value);
    //this.selectedTower = e.value;
    this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.towerData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  getDOB(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.authorityDOB = eyear + '-' + emonth + '-' + eday;
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onTowerChange(e) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', this.aF.towerNameData.value);
    if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      body.set('dgId', this.aF.dgNameData.value);
    } else {
      body.set('dgId', '1');
    }
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    //console.log("dgtowerload",this.currentUser.data.userData.subdivision," ",this.aF.dgNameData.value);
    //this.selectedTower = e.value;
    this.genReqs.postReq('/getGroupDataNew', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.groupData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

  meterFrimwareVersion() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '0');
    body.set('versionId', '');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getMeterFirmwareVersion', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.firmWareVersion = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }


  getGroupName() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('towerId', this.consumer.towerId);
    if (!this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View) {
      body.set('dgId', this.consumer.dgId);
    } else {
      body.set('dgId', '1');
    }
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    //console.log("dgtowerload",this.currentUser.data.userData.subdivision," ",this.aF.dgNameData.value);
    //this.selectedTower = e.value;
    this.genReqs.postReq('/getGroupDataNew', body).subscribe((result) => {

      this.responseData = result;
      if (this.responseData.success) {
        //this.towerData = [...this.towerData, this.responseData.data];
        this.groupData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {

      }
    });
  }

}
