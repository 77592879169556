import { Component, OnInit, ViewChild } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, Validators,UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { Access } from 'src/app/_models/access';
import { User } from 'src/app/_models';
import { Observable } from 'rxjs-compat';
import { take, startWith, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-utilityconfig',
  templateUrl: './utilityconfig.component.html',
  styleUrls: ['./utilityconfig.component.scss']
})
export class UtilityconfigComponent implements OnInit {
  @ViewChild('picker') picker: any;
  faSpinner = faSpinner;
  public today = new Date();
  firmwareVersionData = [
    {
        'Id': 0,
        'Name': ''
    }
];
myDate = new Date();
cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');


flatNo = '';
canSearch: boolean = true;

filteredFlatInfo: Observable<any[]>;
  message:string = '';
  message1:string = '';
  message2:string = '';
  message3:string = '';
  message4:string = '';
  message5:string = '';
  message6:string = '';
  message7:string = '';
  message8:string = '';
  message9:string = '';
  message10:string = '';
  message13:string = '';
  message11:string = '';
  message19:string = '';
  message15:string = '';
  message21:string = '';
  message22:string = '';
  message23:string = '';
  message24:string = '';
  message25:string = '';
  message26:string = '';

  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  status:boolean = true;
  actionForm: UntypedFormGroup;
  button   = 'Update';
  button2  = 'Update';
  button3  = 'Update';
  button4  = 'Update';
  button5  = 'Update';
  button6  = 'Update';
  button7  = 'Update';
  button8  = 'Update';
  button9  = 'Update';
  button10 = 'Update';
  button11 = 'Update';
  button12 = 'Update';
  button13 = 'Update';
  button14 = 'Update';
  button15 = 'Update';
  button16 = 'Update';
  button17 = 'Update';
  button18 = 'Update';
  button19 = 'Update';
  button20 = 'Update';
  button21 = 'Update';
  button22 = 'Upload';
  button23 = 'Upload';
  button25 = 'Upload';

  actionForm1: UntypedFormGroup;
  actionForm2: UntypedFormGroup;
  actionForm3: UntypedFormGroup;
  actionForm4: UntypedFormGroup;
  actionForm5: UntypedFormGroup;
  actionForm6: UntypedFormGroup;
  actionForm7: UntypedFormGroup;
  actionForm8: UntypedFormGroup;
  actionForm9: UntypedFormGroup;
  actionForm10: UntypedFormGroup;
  actionForm11: UntypedFormGroup;
  actionForm12: UntypedFormGroup;
  actionForm13: UntypedFormGroup;
  actionForm14: UntypedFormGroup;
  actionForm15: UntypedFormGroup;
  actionForm16: UntypedFormGroup;
  actionForm17: UntypedFormGroup;
  actionForm18: UntypedFormGroup;
  actionForm19: UntypedFormGroup;
  actionForm20: UntypedFormGroup;
  actionForm21: UntypedFormGroup;
  actionForm22: UntypedFormGroup;
  actionForm23: UntypedFormGroup;
  actionForm24: UntypedFormGroup;
  actionForm25: UntypedFormGroup;
  actionForm26: UntypedFormGroup;
  meterDateTime:string='';
  timeDiffrence:string='';
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  AddProcess:boolean = false;
  responseData: any;
  submitted: boolean = false;
  meterDetail:any = {};
  //serverDateTime = new Date();
  serverDateTime = "0000-00-00 00:00:00";
  data = true;
  /*capture: any = [
    { 'id': 0, 'Name': 'Select Capture Period'},
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];*/
  capture: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];
  push: any = [
    { 'id': 15, 'Name': '15 Minutes' },
    { 'id': 30, 'Name': '30 Minutes' },
  ];
  /*demand: any = [
    { 'id': 0, 'Name': 'Select Capture Period' },
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  
  ];*/
  currentMonth = new Date();
  demand: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  ];
  mode: any = [
    { 'id': 0, 'Name': 'NaN' },
    { 'id': 1, 'Name': 'Mode 1' },
    { 'id': 2, 'Name': 'Mode 2' },
    { 'id': 3, 'Name': 'Mode 3' },
    { 'id': 4, 'Name': 'Mode 4' },
    { 'id': 5, 'Name': 'Mode 5' },
    { 'id': 6, 'Name': 'Mode 6' }
  ];

  /*bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'DD/MM/YYYY',
    minMode: 'day',
    //dateInputFormat: 'DD/MM/YYYY', // Customize the date format if needed
    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Set the minimum date to the current date
  };*/

  loadLimitValue=0;
  lockOutPeriodValue=0;
  relayConnDisvalue=0;

  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;
  submitted5 = false;
  submitted15 = false;
  submitted26 = false;
 
  isLoading = false;
  isLoading1 = false;
  isLoading2 = false;
  isLoading3 = false;
  isLoading4 = false;
  isLoading5= false;
  isLoading6 = false;
  isLoading7 = false;
  isLoading8 = false;
  
  isLoading9 = false;
  isLoading10 = false;
  isLoading11 = false;
  isLoading12 = false;
  isLoading13 = false;
  isLoading14 = false;
  isLoading15 = false;
  isLoading19 = false;
  isLoading20 = false;
  isLoading21 = false;
  isLoading23 = false;
  isLoading24 = false;
  isLoading25 = false;

  isLoadingz1 = false;
  isLoadingz2 = false;
  isLoadingz3 = false;
  isLoadingz4 = false;
  isLoadingz5= false;
  isLoadingz6 = false;
  isLoadingz7 = false;
  isLoadingz8 = false;
  
  isLoadingz9 = false;
  isLoadingz10 = false;
  isLoadingz11 = false;
  isLoadingz12 = false;
  isLoadingz13 = false;
  isLoadingz14 = false;
  isLoadingz15 = false;
  isLoadingz16 = false;
  isLoadingz17 = false;
  isLoadingz18 = false;
  isLoadingz19 = false;
  isLoadingz20 = false;
  isLoadingz21 = false;
  isLoadingz22 = false;
  isLoadingz23 = false;
  isLoadingz24 = false;
  isLoadingz25 = false;
  isLoadingz26 = false;
  flatControl = new UntypedFormControl('');
  thumb:boolean = false;
  servantmode:any;
  billingDate:any;
  startDate = '';
  getThrough: Access;
  sec1File: File | undefined;
  sec2File: File | undefined;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService

  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  calculateNextMonthStartDate() {
    const currentDate = new Date();
    const currentDate1 = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(1); // Set the day to the 1st day of the next month

    this.myDate = currentDate;

    currentDate1.setMonth(currentDate1.getMonth() - 2);
    currentDate1.setDate(1);
    this.currentMonth = currentDate1;

    let emonth = this.myDate.getMonth(); //always move 1 value up
    let eday = this.myDate.getDate();
    let eyear = this.myDate.getFullYear();
    let emonthNew="";
    let edayNew="";
    if(emonth.toString().length==1){
      emonthNew="0"+emonth;
    }
    if(eday.toString().length==1){
      edayNew="0"+eday;
    }
    this.startDate = eyear + '-' + emonthNew + '-' + edayNew;

  }

  getBillingDate(pickedDate = new Date()) {
    if (pickedDate) {
      
        let emonth = pickedDate.getMonth() + 1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();

        let emonthNew="";
        let edayNew="";

        if(emonth.toString().length==1){
          emonthNew="0"+emonth;
        }else{
          emonthNew=emonth.toString();
        }
        if(eday.toString().length==1){
          edayNew="0"+eday;
        }else{
          edayNew=eday.toString();
        }

        this.billingDate = eyear + '-' + emonthNew + '-' + edayNew+"T00:00:00";
        console.log("----------------"+this.billingDate);
    }
  }

  ngOnInit() {
    
    this.getFirmwareVersion();
    //this.getTodConfig();
    this.calculateNextMonthStartDate();
    this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
        startWith(''),
        map(value => this.findFlatInfo(value))
    );
    /*this.actionForm = this.formBuilder.group({
      servantmode: ['', Validators.required],
      capturedPeriod: ['', Validators.required],
      demandIntegrationPeriod: ['', Validators.required],
      loadLimit: ['', Validators.required],
      billingDate: ['', Validators.required],
      //meterload: [''],
      //backupSupply: [''],
      //startfixedchargesonmeter: [''],
      //startemergencycredit: [''],
    });*/
    this.actionForm1 = this.formBuilder.group({
      capturedPeriod: ['', Validators.required],
    });
    this.actionForm2 = this.formBuilder.group({
      demandIntegrationPeriod: ['', Validators.required],
    });
    this.actionForm3 = this.formBuilder.group({
      loadLimit: ['', Validators.required],
    });
    this.actionForm4 = this.formBuilder.group({
      billingDateFromMeter: [{value:'', disabled: true}, Validators.required],
      billingDate: ['', Validators.required],
    });
    this.actionForm5 = this.formBuilder.group({
      pushSchedular: ['', Validators.required],
    });
    this.actionForm6 = this.formBuilder.group({
      autoReconnection: ['', Validators.required],
      timeIntervaL: ['', Validators.required],
      lockOutPeriod: ['', Validators.required],
      activationTime: ['', Validators.required],
    });
    this.actionForm26 = this.formBuilder.group({
      paymentMode: ['', Validators.required],
      lastTokenAmount: ['', Validators.required],
      lastTokenRechargeTime: ['', Validators.required],
      totalAmountLastRecharge: ['', Validators.required],
      currentBalanceAmount: ['', Validators.required],
      currentBalanceTime: ['', Validators.required],
    });

    this.actionForm21 = this.formBuilder.group({
      //loadLimit: ['', Validators.required],
      loadLimit: ['', [Validators.min(0.05), Validators.max(17)]],
      //lockOutPeriod: ['', Validators.required],
      lockOutPeriod: ['', [Validators.min(0), Validators.max(60)]],
      //relayConnDis: ['', Validators.required]
      relayConnDis: ['', [Validators.min(0), Validators.max(60)]]

    });
    this.actionForm7 = this.formBuilder.group({
      prepaidFunction: ['', Validators.required],
    });
    this.actionForm8 = this.formBuilder.group({
      netMeters: ['', Validators.required],
    });
    this.actionForm9 = this.formBuilder.group({
      connectDisconnect: ['', Validators.required],
    });
    this.actionForm10 = this.formBuilder.group({
      mdReset: ['', Validators.required],
    });
    this.actionForm24 = this.formBuilder.group({
      ping: ['', Validators.required],
    });
    this.actionForm11 = this.formBuilder.group({
      pushSchedular: ['', Validators.required],
    });
    this.actionForm12 = this.formBuilder.group({
      lowLevel: ['', Validators.required],
    });
    this.actionForm18 = this.formBuilder.group({
      highLevel: ['', Validators.required],
    });
    this.actionForm13 = this.formBuilder.group({
      masterKey: ['', Validators.required],
      newKey: ['', Validators.required],
    });
    this.actionForm14 = this.formBuilder.group({
      overVoltage: ['', Validators.required],
      lowVoltage: ['', Validators.required],
      overCurrent: ['', Validators.required],
      activationDatetime: ['', Validators.required],
    });
    this.actionForm15 = this.formBuilder.group({
      schedularStartEndDate: [this.myDate, Validators.required],
      pastTeriffDate: [{value:'', disabled: true}, Validators.required],
      tod1: ['', Validators.required],
      tod2: ['', Validators.required],
      tod3: ['', Validators.required],
      tod4: ['', Validators.required],
      tod5: ['', Validators.required],
      tod6: ['', Validators.required],
      tod7: ['', Validators.required],
      tod8: ['', Validators.required],

    });

    this.actionForm23 = this.formBuilder.group({
      schedularStartEndDateHappy: [this.myDate, Validators.required],
      pastHappyDate: [{value:'', disabled: true}, Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      holidayMonth: ['', Validators.required],
      holidayDate: ['', Validators.required],
      weekDay: ['', Validators.required],
     });
    this.actionForm16 = this.formBuilder.group({
      rPhaseVoltage: ['', Validators.required],
      yPhaseVoltage: ['', Validators.required],
      bPhaseVoltage: ['', Validators.required],
      overVoltage: ['', Validators.required],
      lowVoltage: ['', Validators.required],
      voltageUnbalance: ['', Validators.required],
      rPhaseCurrentRev: ['', Validators.required],
      yPhaseCurrentRev: ['', Validators.required],
      bPhaseCurrentRev: ['', Validators.required],
      currentUnbalance: ['', Validators.required],
      currentByPass: ['', Validators.required],
      overCurrent: ['', Validators.required],
      activationDatetime: ['', Validators.required],
      veryLPF: ['', Validators.required],
      infMagnet: ['', Validators.required],
      neturalDistrubance: ['', Validators.required],
      meterCOpen: ['', Validators.required],
      loadConnectDis: ['', Validators.required],
      lastGasp: ['', Validators.required],
      firstBreath: ['', Validators.required],
      incBillingCount: ['', Validators.required],

   
    });
    this.actionForm17 = this.formBuilder.group({
      earthLoading: ['', Validators.required],
      currentReverse: ['', Validators.required],
      infMagnetS: ['', Validators.required],
      neturalDistrubanceS: ['', Validators.required],
      meterCOpenS: ['', Validators.required],
      loadConnectDisS: ['', Validators.required],
      lastGaspS: ['', Validators.required],
      firstBreathS: ['', Validators.required],
      incBillingCountS: ['', Validators.required],
      overVoltageS: ['', Validators.required],
      lowVoltageS: ['', Validators.required],
      overCurrentS: ['', Validators.required],
     
    });
    this.actionForm19 = this.formBuilder.group({
      prepaidFunctionNew: ['', Validators.required],
    });
    this.actionForm20 = this.formBuilder.group({
      connectDisconnectControl: ['', Validators.required],
    });

    this.actionForm22 = this.formBuilder.group({
      firmWareVersionDate: [this.myDate, Validators.required],
      pastFirmWareDate: [{value:'', disabled: true}, Validators.required],
      oldFirmWare: [{value:'', disabled: true}, Validators.required],
      sec1File: ['', Validators.required],
      sec2File: ['', Validators.required]
    });

    //this.aD.servantmode.setValue('L_2');
    this.getConfiguratoin();
  }

  /*onSec1FileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aF22.sec1File.setValue(file);
    }
  }
  onSec2FileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aF22.sec2File.setValue(file);
    }
  }*/
  
  getDateFromRange(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      let emonthNew="";
      let edayNew="";
      if(emonth.toString().length==1){
        emonthNew="0"+emonth;
      }else{
        emonthNew=emonth.toString();
      }
      
      if(eday.toString().length==1){
        edayNew="0"+eday;
      }else{
        edayNew=eday.toString();
      }
      this.startDate = eyear + '-' + emonthNew + '-' + edayNew;
    }
  }


  get aF() { return this.actionForm.controls; }
  get aF1() { return this.actionForm1.controls; }
  get aF2() { return this.actionForm2.controls; }
  get aF3() { return this.actionForm3.controls; }
  get aF4() { return this.actionForm4.controls; }
  get aF5() { return this.actionForm5.controls; }

  get aF6() { return this.actionForm6.controls; }
  get aF26() { return this.actionForm26.controls; }
  get aF7() { return this.actionForm7.controls; }
  get aF8() { return this.actionForm8.controls; }
  get aF9() { return this.actionForm9.controls; }
  get aF10() { return this.actionForm10.controls; }
  get aF24() { return this.actionForm24.controls; }
  get aF11() { return this.actionForm11.controls; }
  get aF12() { return this.actionForm12.controls; }
  get aF13() { return this.actionForm13.controls; }
  get aF14() { return this.actionForm14.controls; }
  get aF15() { return this.actionForm15.controls; }
  get aF16() { return this.actionForm16.controls; }
  get aF17() { return this.actionForm17.controls; }
  get aF18() { return this.actionForm18.controls; }
  get aF19() { return this.actionForm19.controls; }
  get aF20() { return this.actionForm20.controls; }
  get aF21() { return this.actionForm21.controls; }
  get aF22() { return this.actionForm22.controls; }
  get aF23() { return this.actionForm23.controls; }
  get aF25() { return this.actionForm25.controls; }
  //get aD() { return this.actionForm.controls; }

  ngOnDestroy() {
    //this.modalService.dismissAll();
  }

  onFileSelectSec1(event) {
      const file = event.target.files[0];
      //this.aF22.sec1File.setValue(file);
      this.sec1File=(file);
      //this.actionForm22.get('sec1File').setValue(file);
      return true;
  }

  onFileSelectSec2(event) {
    const file = event.target.files[0];
    this.sec2File=(file);
    //this.actionForm22.get('sec2File').setValue(file);
     return true;
    //this.aF22.sec2File.setValue(file);
  }

  submitForm1(){
    if (this.actionForm1.invalid) 
    {
      this.submitted = true;
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('capturedPeriod',this.aF1.capturedPeriod.value);
    body.set('cmdType', "1");
    body.set('cmdFrom', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/loadProfileCapture', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if(this.message.includes("You are not authorize") 
            || this.message.includes("Communication Failure") 
            || this.message.includes("Unsuccessful")
            || this.message.includes("Unsuccessfully") 
            || this.message.includes("Under Progress") 
            || this.message.includes("Not Generate")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  submitFota() {
   //alert(this.aF22.sec1File.value);
   //alert(this.aF22.sec2File.value);
   
    if(this.flatControl.value.toString()==''){
      alert("Firmware version can't be left blank!");
      return;
    }
    if(this.aF22.sec1File.value=='' && this.aF22.sec2File.value==''){
      alert("Please select aleast one file for update firmware.");
      return;
    }
    /*if(this.aF22.sec1File.value==this.aF22.sec2File.value){
      alert("Both files can't be same!");
      return;
    }*/
    /*if (this.actionForm22.invalid) {
      this.submitted = true;
      return;
    }*/
    this.button22="Process";
    this.isLoadingz22 = true;
    this.isLoading = true;

    this.message22 = '';
   
    this.AddProcess = true;
    const formData = new FormData();
    formData.append('subdivId', this.meterDetail.sdID);
    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
    formData.append('conAccId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);
    formData.append('cmdType', "1");
    formData.append('cmdFrom', '0');
    formData.append('sec1File', this.sec1File);
    formData.append('sec2File', this.sec2File);
    formData.append('firmwareVersion', this.flatNo);
    formData.append('configStartDT', this.startDate);
    formData.append('meterIpAddress', this.meterDetail.mIPAdd);
    formData.append('authToken', this.currentUser.authToken);
    this.AddProcess = true;
    this.genReqs.formDataPost('/uploadFotaFile', formData).subscribe((result) => {
      this.responseData = result;
      this.button22="Upload";
      this.isLoadingz22 = false;
      this.thumb = false;
      if (this.responseData.success) {
        this.AddProcess = false;
        this.isLoading = false;
        this.thumb = true;
        this.message22 = this.responseData.data.message;
        if (this.message22.includes("Already") || this.message22.includes("Not")) {
          this.thumb = false;
        } else {
          setTimeout(() => {
            this.modal.close('done');
          },
            3000);
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.AddProcess = false;
      this.message22 = 'Unable to process yours request!';
    });
  }

  submitForm(number){
    //alert(number);
    this.message ='';
    this.AddProcess = true;
    this.message ='';
    this.message1='';
    this.message2='';
    this.message3='';
    this.message4='';
    this.message5='';
    this.message6='';
    this.message7='';
    this.message8='';
    this.message9='';
    this.message10='';
    this.message11='';
    this.message19='';
    this.message15='';
    this.message21='';
    this.message23='';
    this.message24='';
    this.message26='';
    if(number=='1'){
      //alert(this.aF1.capturedPeriod.value);
        /*if (this.aF1.capturedPeriod.value==''){
          alert("Please select Load Profile Capture period!");
          return;
        }*/
      if (this.actionForm1.invalid) {
        this.submitted1 = true;
        return;
      }
      this.button="Process";
      this.isLoadingz1 = true;
      //this.isLoading = true;
    }else if(number=='2'){
      
      if (this.actionForm2.invalid) {
        this.submitted2 = true;
        return;
      }
      
      this.button2="Process";
      this.isLoadingz1 = true;
      //this.isLoading = true;
    }else if(number=='3'){
     
      if (this.actionForm3.invalid) {
        this.submitted3 = true;
        return;
      }

      
      this.button3="Process";
      this.isLoadingz1 = true;
      //this.isLoading = true;
      
    }else if(number=='4'){
      if (this.actionForm4.invalid) {
        this.submitted4 = true;
        return;
      }
      this.button4="Process";
      this.isLoadingz1 = true;
      //this.isLoading = true;
      
    }else if(number=='5'){
      if (this.actionForm5.invalid) {
        this.submitted5 = true;
        return;
      }
      this.button5="Process";
      this.isLoadingz1 = true;
      //this.isLoading = true;
      
    }else if(number=='26'){
      if (this.actionForm26.invalid) {
        this.submitted26 = true;
        return;
      }
      this.button5="Process";
      this.isLoadingz26 = true;
      //this.isLoading = true;
    }else if(number=='6'){
      this.button6="Process";
      this.isLoadingz6 = true;
      this.isLoading = true;
      
    }else if(number=='7'){
      if (this.actionForm19.invalid) {
        alert("Please select Prepaid Functionality for update!");
        return;
      }
      this.button19="Process";
      this.isLoadingz19 = true;
      this.isLoading = true;
    }else if(number=='8'){
      if (this.actionForm8.invalid) {
        alert("Please select Net Meter Functionality for update!");
        return;
      }
      this.button8="Process";
      this.isLoadingz8 = true;
      this.isLoading = true;
      
    }else if(number=='9'){
      if (this.actionForm9.invalid) {
        alert("Please select Meter Load Connect/Disconnect Functionality for update!");
        return;
      }
      this.button9="Process";
      this.isLoadingz9 = true;
      this.isLoading = true;
     
    }else if(number=='20'){
      if (this.actionForm20.invalid) {
        alert("Please select Load Control Functionality for update!");
        return;
      }
      this.button20="Process";
      this.isLoadingz20 = true;
      this.status = true;
      this.isLoading = true;
     
    }else if(number=='10'){
      this.button10="Process";
      this.isLoadingz10 = true;
      this.isLoading = true;
     
    }else if(number=='24'){
      this.isLoadingz24 = true;
      this.isLoading = true;
     
    }else if(number=='11'){
      
      /*if (this.meterDateTime=='' || this.meterDateTime==undefined) {
        alert("Please read Meter Datetime before update!");
        return;
      }*/
      this.button11="Process";
      this.isLoadingz11 = true;
      //this.isLoading = true;
    }else if(number=='12'){
      this.button12="Process";
      this.isLoadingz12 = true;
      this.isLoading = true;
     
    }else if(number=='13'){
      /*if (this.actionForm15.invalid) {
        this.submitted15 = true;
        return;
      }*/

      this.button13="Process";
      this.isLoadingz13 = true;
      this.message13="";
      //this.isLoading = true;
    
    }else if(number=='23'){
      this.button23="Process";
      this.isLoadingz23 = true;
      this.isLoading = true;
    
    }else if(number=='14'){
      this.button14="Process";
      this.isLoadingz14 = true;
      this.isLoading = true;
     
    }else if(number=='15'){
      /*if (this.actionForm17.invalid) {
        alert("Please select ESW Filter for update!");
        return;
      }*/
      this.button15="Process";
      this.isLoadingz15 = true;
      //this.isLoading = true;
     
    }else if(number=='16'){
      this.button16="Process";
      this.isLoadingz16 = true;
      this.isLoading = true;
     
    }else if(number=='17'){
      this.button17="Process";
      this.isLoadingz17 = true;
      this.isLoading = true;
     
    }else if(number=='21'){
      /*if (this.actionForm21.invalid) {
        return;
      }*/
      this.button21="Process";
      this.isLoadingz21 = true;
      //this.isLoading = true;
    }
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    if(number=='1'){
      body.set('capturedPeriod',this.aF1.capturedPeriod.value);
    }else{
      body.set('capturedPeriod','');
    }
    if(number=='2'){
      body.set('demandIntegrationPeriod',this.aF2.demandIntegrationPeriod.value);
    }else{
      body.set('demandIntegrationPeriod','');
    }
    if(number=='3'){
      body.set('loadLimit',this.aF3.loadLimit.value);
    }else{
      body.set('loadLimit','');
    }
    if(number=='4'){
      body.set('billingDate',this.billingDate);
    }else{
      body.set('billingDate','');
    }
    if(number=='5'){
      body.set('pushSchedular',this.aF5.pushSchedular.value);
    }else{
      body.set('pushSchedular','');
    }
    if(number=='26'){
      body.set('paymentMode',this.aF26.paymentMode.value);
      body.set('lastTokenAmount',this.aF26.lastTokenAmount.value);
      body.set('lastTokenRechargeTime',this.aF26.lastTokenRechargeTime.value);
      body.set('totalAmountLastRecharge',this.aF26.totalAmountLastRecharge.value);
      body.set('currentBalanceAmount',this.aF26.currentBalanceAmount.value);
      body.set('currentBalanceTime',this.aF26.currentBalanceTime.value);
    }else{
      body.set('paymentMode','');
      body.set('lastTokenAmount','');
      body.set('lastTokenRechargeTime','');
      body.set('totalAmountLastRecharge','');
      body.set('currentBalanceAmount','');
      body.set('currentBalanceTime','');
    }

    if(number=='7'){
      body.set('prepaidFunction',this.aF19.prepaidFunctionNew.value);
    }else{
      body.set('prepaidFunction','');
    }

    if(number=='8'){
      body.set('netMeter',this.aF8.netMeters.value);
    }else{
      body.set('netMeter','');
    }

    if(number=='20'){
      body.set('connectDisconnectControl',this.aF20.connectDisconnectControl.value);
    }else{
      body.set('connectDisconnectControl','');
    }

    if(number=='9'){
      body.set('connectDisconnect',this.aF9.connectDisconnect.value);
    }else{
      body.set('connectDisconnect','');
    }

    if(number=='10'){
      body.set('mdReset',this.aF10.mdReset.value);
    }else{
      body.set('mdReset','');
    }

    if(number=='24'){
      body.set('ping',this.aF24.ping.value);
    }else{
      body.set('ping','');
    }

    if(number=='13'){
      body.set('tod1',this.aF15.tod1.value);
      body.set('tod2',this.aF15.tod2.value);
      body.set('tod3',this.aF15.tod3.value);
      body.set('tod4',this.aF15.tod4.value);
      body.set('tod5',this.aF15.tod5.value);
      body.set('tod6',this.aF15.tod6.value);
      body.set('tod7',this.aF15.tod7.value);
      body.set('tod8',this.aF15.tod8.value);
      body.set('configStartDT', this.startDate);
    }else{
      body.set('tod1','');
      body.set('tod2','');
      body.set('tod3','');
      body.set('tod4','');
      body.set('tod5','');
      body.set('tod6','');
      body.set('tod7','');
      body.set('tod8','');
      body.set('configStartDT','');
    }
    

    if(number=='23'){
      //alert(number);
      //alert(this.aF23.holidayMonth.value);
      body.set('startTime',this.aF23.startTime.value);
      body.set('endTime',this.aF23.endTime.value);
      body.set('holidayMonth',this.aF23.holidayMonth.value);
      body.set('holidayDate',this.aF23.holidayDate.value);
      body.set('weekDay',this.aF23.weekDay.value);
      body.set('configStartDTHappy', this.startDate);
    }else{
      body.set('startTime','');
      body.set('endTime','');
      body.set('holidayMonth','');
      body.set('holidayDate','');
      body.set('weekDay','');
      body.set('configStartDTHappy','');
    }


    if(number=='15'){
      body.set('earthLoading',this.aF17.earthLoading.value);
      body.set('infMagnetS',this.aF17.infMagnetS.value);
      body.set('neturalDistrubanceS',this.aF17.neturalDistrubanceS.value);
      body.set('meterCOpenS',this.aF17.meterCOpenS.value);
      body.set('loadConnectDisS',this.aF17.loadConnectDisS.value);
      body.set('lastGaspS',this.aF17.lastGaspS.value);
      body.set('firstBreathS',this.aF17.firstBreathS.value);
      body.set('incBillingCountS',this.aF17.incBillingCountS.value);
      body.set('currentReverse',this.aF17.currentReverse.value);
      body.set('overVoltageS',this.aF17.overVoltageS.value);
      body.set('lowVoltageS',this.aF17.lowVoltageS.value);
      body.set('overCurrentS',this.aF17.overCurrentS.value);
    }else{
      body.set('earthLoading','');
      body.set('infMagnetS','');
      body.set('neturalDistrubanceS','');
      body.set('meterCOpenS','');
      body.set('loadConnectDisS','');
      body.set('lastGaspS','');
      body.set('firstBreathS','');
      body.set('incBillingCountS','');
      body.set('currentReverse','');
      body.set('overVoltageS','');
      body.set('lowVoltageS','');
      body.set('overCurrentS','');
    }

    if(number=='21'){
      this.loadLimitValue=this.aF21.loadLimit.value;
      this.loadLimitValue=this.loadLimitValue*1000;
      body.set('loadLimit',this.aF21.loadLimit.value);
      
      this.lockOutPeriodValue=this.aF21.lockOutPeriod.value;
      this.lockOutPeriodValue=this.lockOutPeriodValue*60;
      body.set('lockOutPeriod',this.aF21.lockOutPeriod.value);
      
      this.relayConnDisvalue=this.aF21.relayConnDis.value;
      this.relayConnDisvalue=this.relayConnDisvalue*60;
      body.set('relayConnDis',this.aF21.relayConnDis.value);
      
    }else{
      //console.log(">>>>>>>>>>>>>>"+this.aF3.loadLimit.value);
      //body.set('loadLimit','');
      body.set('lockOutPeriod','');
      body.set('relayConnDis','');
    }
    console.log(">>>>>>>>>>>>>><<<<<<<<<<<<<<<<"+this.aF3.loadLimit.value);
    body.set('cmdType',number);
    body.set('cmdFrom', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/loadProfileUpdate', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
       
        this.thumb = true;
        //this.message = this.responseData.data.message;
        
        if(number=='1'){
          this.button="Update";
          this.isLoadingz1 = false;
          //this.aF1.capturedPeriod.setValue(this.responseData.data.capturedPeriod);
          this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm1.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
        }else if(number=='2'){
          this.button2="Update";
          this.isLoadingz1 = false;
          //this.aF2.demandIntegrationPeriod.setValue(this.responseData.data.demandIntegrationPeriod);
          this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm2.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
        }else if(number=='3'){
          this.button3="Update";
          this.isLoadingz1 = false;
          this.aF3.loadLimit.setValue(this.responseData.data.loadLimit);
          this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm3.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
        }else if(number=='4'){
          this.button4="Update";
          this.isLoadingz1 = false;
          this.aF4.billingDate.setValue(this.responseData.data.billingDate);
          this.message1=this.responseData.data.message;
          if(this.message1 && this.message1.includes("Error")){
            this.actionForm4.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='5'){
          this.button5="Update";
          this.isLoadingz1 = false;
          //this.aF5.pushSchedular.setValue(this.responseData.data.pushSchedular);
          this.message1=this.responseData.data.message;
          if(this.message1 && this.message1.includes("Error")){
            this.actionForm5.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='26'){
          console.log("---------------",this.responseData.data.message);
          this.isLoadingz26 = false;
          /*this.aF26.paymentMode.setValue(this.responseData.data.paymentMode);
          this.aF26.lastTokenAmount.setValue(this.responseData.data.lastTokenAmount);
          this.aF26.lastTokenRechargeTime.setValue(this.responseData.data.lastTokenRechargeTime);
          this.aF26.totalAmountLastRecharge.setValue(this.responseData.data.totalAMountLastRecharge);
          this.aF26.currentBalanceAmount.setValue(this.responseData.data.currentBalanceAmount);
          this.aF26.currentBalanceTime.setValue(this.responseData.data.currentBalanceTime);*/

          this.message26=this.responseData.data.message;
          if(this.message26 && this.message26.includes("Error")){
            //this.actionForm26.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='6'){
          this.button6="Update";
          this.isLoadingz6 = false;
          this.actionForm6.reset();
          this.aF6.autoReconnection.setValue(this.responseData.data.autoReconnection);
          this.aF6.timeIntervaL.setValue(this.responseData.data.timeIntervaL);
          this.aF6.lockOutPeriod.setValue(this.responseData.data.lockOutPeriod);
          this.aF6.activationTime.setValue(this.responseData.data.activationTime);
        }else if(number=='7'){
          this.button19="Update";
          //console.log("-------------------hello");
          this.isLoadingz19 = false;
          this.isLoading = false;
          this.aF19.prepaidFunctionNew.setValue(this.responseData.data.prepaidFunction);
          this.message2=this.responseData.data.message;
          console.log("-------------",this.message2);
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful"))){
            this.actionForm19.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='8'){
          this.button8="Update";
          this.isLoadingz8 = false;
          this.aF8.netMeters.setValue(this.responseData.data.meteringMode);
          this.message2=this.responseData.data.message;
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful"))){
            this.actionForm8.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='20'){
          this.button20="Update";
          this.isLoadingz20 = false;
          this.aF20.connectDisconnectControl.setValue(this.responseData.data.connectDisconnectControl);
          this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);
          this.message2=this.responseData.data.message;
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful"))){
            this.actionForm20.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
              if(this.message2 && this.message2.includes("Off")){
                this.status = true;
                this.actionForm9.reset();
              }else{
                this.status = false;
              }
          }
        }else if(number=='9'){
          this.button9="Update";
          this.isLoadingz9 = false;
          this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);
          this.message2=this.responseData.data.message;
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful")) ){
            this.actionForm9.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='10'){
          this.button10="Update";
          this.isLoading10 = false;
          //this.aF10.mdReset.setValue(this.responseData.data.mdReset);
          this.message2=this.responseData.data.message;
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful")) ){
            this.actionForm10.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='24'){
          //this.button24="Update";
          this.isLoading24 = false;
          //this.aF24.ping.setValue(this.responseData.data.ping);
          this.message2=this.responseData.data.message;
          if(this.message2 && (this.message2.includes("Error") || this.message2.includes("Under") || this.message2.includes("Unsuccessful")) ){
            this.actionForm24.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='11'){
          this.button11="Update";
          this.isLoadingz11 = false;
          //this.meterDateTime=this.responseData.data.meterDateTime;
            this.meterDateTime=this.responseData.data.meterDatetime;
            //console.log("In TS",this.meterDateTime,"Hello");
            this.serverDateTime=this.responseData.data.serverDatetime;
            this.timeDiffrence=this.responseData.data.diffrence;
            this.message11=this.responseData.data.message;
            if(this.message11 && this.message11.includes("Error")){
              /*if(this.serverDateTime.toString()==''){
                this.serverDateTime = new Date();
              }*/
              this.thumb = false;
            }else{
              this.thumb = true;
            }
        }else if(number=='12'){
          this.button12="Update";
          this.isLoadingz12 = false;
          this.aF14.overVoltage.setValue(this.responseData.data.overVoltage);
          this.aF14.lowVoltage.setValue(this.responseData.data.lowVoltage);
          this.aF14.overCurrent.setValue(this.responseData.data.overCurrent);
          this.aF14.activationDatetime.setValue(this.responseData.data.activationDatetime);
        }else if(number=='21'){
          this.button21="Update";
          this.isLoadingz21 = false;
          //console.log(this.responseData);
          /*this.aF21.loadLimit.setValue(this.responseData.data.loadLimit);
          this.aF21.lockOutPeriod.setValue(this.responseData.data.overDuration);
          this.aF21.relayConnDis.setValue(this.responseData.data.underDuration);*/
          this.message21=this.responseData.data.message;
          if(this.message21 && this.message21.includes("Error")){
            this.actionForm21.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='13'){
          this.button13="Update";
          this.isLoadingz13 = false;
          this.message13=this.responseData.data.message;
          if(this.message13 && (this.message13.includes("Error") || this.message13.includes("Under") || this.message13.includes("Unsuccessful"))){
            this.actionForm19.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='23'){
          this.button23="Update";
          this.isLoadingz23 = false;
          this.message23=this.responseData.data.message;
          if(this.message23 && (this.message23.includes("Error") || this.message23.includes("Under") || this.message23.includes("Unsuccessful"))){
            //this.actionForm23.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='14'){
          this.button14="Update";
          this.isLoadingz14 = false;
          this.aF16.rPhaseVoltage.setValue(this.responseData.data.rPhaseVoltage);
          this.aF16.yPhaseVoltage.setValue(this.responseData.data.yPhaseVoltage);
          this.aF16.bPhaseVoltage.setValue(this.responseData.data.bPhaseVoltage);
          this.aF16.overVoltage.setValue(this.responseData.data.overVoltage);
          this.aF16.lowVoltage.setValue(this.responseData.data.lowVoltage);
          this.aF16.voltageUnbalance.setValue(this.responseData.data.voltageUnbalance);
          this.aF16.rPhaseCurrentRev.setValue(this.responseData.data.rPhaseCurrentRev);
          this.aF16.yPhaseCurrentRev.setValue(this.responseData.data.yPhaseCurrentRev);
          this.aF16.bPhaseCurrentRev.setValue(this.responseData.data.bPhaseCurrentRev);
          this.aF16.currentUnbalance.setValue(this.responseData.data.currentUnbalance);
          this.aF16.currentByPass.setValue(this.responseData.data.currentByPass);
          this.aF16.overCurrent.setValue(this.responseData.data.overCurrent);
          this.aF16.activationDatetime.setValue(this.responseData.data.activationDatetime);
          this.aF16.veryLPF.setValue(this.responseData.data.veryLPF);
          this.aF16.infMagnet.setValue(this.responseData.data.infMagnet);
          this.aF16.neturalDistrubance.setValue(this.responseData.data.neturalDistrubance);
          this.aF16.meterCOpen.setValue(this.responseData.data.meterCOpen);
          this.aF16.loadConnectDis.setValue(this.responseData.data.loadConnectDis);
          this.aF16.lastGasp.setValue(this.responseData.data.lastGasp);
          this.aF16.firstBreath.setValue(this.responseData.data.firstBreath);
          this.aF16.incBillingCount.setValue(this.responseData.data.incBillingCount);
        }else if(number=='15'){
          this.button15="Update";
          this.isLoadingz15 = false;
          /*this.aF17.earthLoading.setValue(this.responseData.data.earthLoading);
          this.aF17.infMagnetS.setValue(this.responseData.data.infMagnetS);
          this.aF17.neturalDistrubanceS.setValue(this.responseData.data.neturalDistrubanceS);
          this.aF17.meterCOpenS.setValue(this.responseData.data.meterCOpenS);
          this.aF17.loadConnectDisS.setValue(this.responseData.data.loadConnectDisS);
          this.aF17.lastGaspS.setValue(this.responseData.data.lastGaspS);
          this.aF17.firstBreathS.setValue(this.responseData.data.firstBreathS);
          this.aF17.incBillingCountS.setValue(this.responseData.data.incBillingCountS);
          this.aF17.currentReverse.setValue(this.responseData.data.currentReverse);
          this.aF17.overVoltageS.setValue(this.responseData.data.overVoltageS);
          this.aF17.lowVoltageS.setValue(this.responseData.data.lowVoltageS);
          this.aF17.overCurrentS.setValue(this.responseData.data.overCurrentS);*/
          this.message15=this.responseData.data.message;
          if(this.message15 && this.message15.includes("Error")){
            this.actionForm17.reset();
            this.thumb = false;
          }else{
            this.thumb = true;
          }
        }else if(number=='16'){
          this.button16="Update";
          this.isLoadingz16 = false;
          this.aF12.lowLevel.setValue(this.responseData.data.lowLevel);
        }else if(number=='18'){
          this.button18="Update";
          this.isLoadingz18 = false;
          this.aF18.highLevel.setValue(this.responseData.data.highLevel);
        }else if(number=='17'){
          this.button17="Update";
          this.isLoadingz17 = false;
          this.aF13.masterKey.setValue(this.responseData.data.masterKey);
          this.aF13.newKey.setValue(this.responseData.data.newKey);
        }
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      if(number=='1'){``
        this.isLoading1 = false;
        this.isLoading = false;
        this.isLoadingz1= false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm1.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='2'){
        this.isLoading2 = false;
        this.isLoading = false;
        this.isLoadingz2= false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm2.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='3'){
        this.isLoading3 = false;
        this.isLoading = false;
        this.isLoadingz3= false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm3.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='4'){
        this.isLoading4 = false;
        this.isLoading = false;
        this.isLoadingz4= false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm4.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='5'){
        this.isLoading5 = false;
        this.isLoading = false;
        this.isLoadingz5= false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm5.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='6'){
        this.isLoading6 = false;
        this.isLoading = false;
        this.isLoadingz6= false;
        this.actionForm6.reset();
      }else if(number=='7'){
        this.isLoading7 = false;
        this.isLoadingz7= false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm7.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='8'){
        this.isLoading8 = false;
        this.isLoading = false;
        this.isLoadingz8= false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm8.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='20'){
        this.isLoading20 = false;
        this.isLoading = false;
        this.isLoadingz20= false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm20.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
          if(this.message2.includes("Off")){
            this.status = true;
            this.actionForm9.reset();
          }else{
            this.status = false;
          }
        }
      }else if(number=='9'){
        this.isLoading9 = false;
        this.isLoading = false;
        this.isLoadingz9= false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm9.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='10'){
        this.isLoading10 = false;
        this.isLoading = false;
        this.isLoadingz10= false;
        this.actionForm10.reset();
      }else if(number=='11'){
        this.isLoading11 = false;
        this.isLoading = false;
        this.isLoadingz11= false;
        this.message11='Communication Error !';
        if(this.message11.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='12'){
        this.isLoading12 = false;
        this.isLoading = false;
        this.isLoadingz12= false;
        this.actionForm14.reset();
      }else if(number=='13'){
        this.isLoading13 = false;
        this.isLoading = false;
        this.isLoadingz13= false;
        this.actionForm15.reset();
      }else if(number=='23'){
        this.isLoading23 = false;
        this.isLoading = false;
        this.isLoadingz23= false;
        //this.actionForm23.reset();
      }else if(number=='14'){
        this.isLoading14 = false;
        this.isLoading = false;
        this.isLoadingz14= false;
        this.actionForm16.reset();
      }else if(number=='15'){
        this.isLoading15 = false;
        this.isLoading = false;
        this.isLoadingz15= false;
        this.message15='Communication Error !';
        if(this.message15.includes("Error")){
          this.actionForm17.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='19'){
        this.isLoading19 = false;
        this.isLoading = false;
        this.isLoadingz19= false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='11'){
        this.isLoading11 = false;
        this.isLoading = false;
        this.isLoadingz11= false;
        this.message11='Communication Error !';
        if(this.message11.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='21'){
        this.isLoading = false;
        this.isLoading21 = false;
        this.isLoadingz2= false;
        this.message21='Communication Error !';
        if(this.message21.includes("Error")){
          this.actionForm21.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else{
        this.message = 'Communication Error !';
      }
    });
  }

  
  loadCapturePeriod(number){
    //this.button="Process";
    //alert("Hello");
    this.message ='';
    this.message1='';
    this.message2='';
    this.message3='';
    this.message4='';
    this.message5='';
    this.message6='';
    this.message7='';
    this.message8='';
    this.message9='';
    this.message10='';
    this.message11='';
    this.message19='';
    this.message15='';
    this.message21='';
    this.message25='';
    this.message26='';
    this.message13='';
    this.AddProcess = true;
    if(number=='1'){
      this.isLoadingz1 = true;
      //this.isLoading = true;
      this.actionForm1.reset();
    }else if(number=='2'){
      this.isLoadingz1 = true;
      //this.isLoading = true;
      this.actionForm2.reset();
    }else if(number=='3'){
      this.isLoadingz1 = true;
      //this.isLoading = true;
      this.actionForm3.reset();
    }else if(number=='4'){
      this.isLoadingz1 = true;
      //this.isLoading = true;
      this.actionForm4.reset();
    }else if(number=='5'){
      this.isLoadingz1 = true;
      //this.isLoading = true;
      this.actionForm5.reset();
    }else if(number=='6'){
      this.isLoading6 = true;
      this.isLoading = true;
      this.actionForm6.reset();
    }else if(number=='26'){
      this.isLoadingz26 = true;
      //this.isLoading = true;
      this.actionForm26.reset();
    }else if(number=='7'){
      this.isLoading = true;
      this.actionForm19.reset();
    }else if(number=='8'){
      this.isLoading8 = true;
      this.isLoading = true;
      this.actionForm8.reset();
    }else if(number=='20'){
      this.isLoading20 = true;
      this.isLoading = true;
      this.status = true;
      this.actionForm20.reset();
    }else if(number=='9'){
      this.isLoading9 = true;
      this.isLoading = true;
      this.actionForm9.reset();
    }else if(number=='10'){
      this.isLoading10 = true;
      this.isLoading = true;
      this.actionForm10.reset();
    }else if(number=='11'){
      this.isLoadingz11 = true;
      //this.isLoading = true;
    }else if(number=='12'){
      this.isLoading12 = true;
      this.isLoading = true;
      this.actionForm14.reset();
    }else if(number=='13'){
      //console.log("Before Call");
      this.isLoadingz13 = true;
      //this.isLoading = true;
      this.actionForm15.reset();
    }else if(number=='14'){
      this.isLoading14 = true;
      this.isLoading = true;
      this.actionForm16.reset();
    }else if(number=='15'){
      this.isLoadingz15 = true;
      //this.isLoading = true;
      this.actionForm17.reset();
    }else if(number=='19'){
      this.isLoading19 = true;
      this.isLoading = true;
      this.actionForm19.reset();
    }else if(number=='21'){
      this.isLoadingz21 = true;
      //this.isLoading = true;
      this.actionForm21.reset();
    }else if(number=='24'){
      this.isLoading24 = true;
      this.isLoading = true;
      this.actionForm24.reset();
    }else if(number=='25'){
      this.isLoading25 = true;
      this.isLoading = true;
      this.actionForm25.reset();
    }
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    //body.set('capturedPeriod',this.aF1.capturedPeriod.value);
    body.set('cmdType',number);
    body.set('cmdFrom', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/loadProfileCapture', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        if(this.responseData.data.message!=''){
          if(number=='1'){
            this.isLoadingz1 = false;
           //this.isLoading = false;
            this.aF1.capturedPeriod.setValue(this.responseData.data.capturePeriod);
            this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm1.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='2'){
            this.isLoadingz1 = false;
            //this.isLoading = false;
            this.aF2.demandIntegrationPeriod.setValue(this.responseData.data.demandIntegration);
            this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm2.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='3'){
            this.isLoadingz1 = false;
            //this.isLoading = false;
            this.aF3.loadLimit.setValue(this.responseData.data.loadLimit);
            this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm3.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='4'){
            this.isLoadingz1 = false;
            //this.isLoading = false;
            this.aF4.billingDateFromMeter.setValue(this.responseData.data.billingDate);
            this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm4.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='5'){
            this.isLoadingz1 = false;
            //this.isLoading = false;
            this.aF5.pushSchedular.setValue(this.responseData.data.pushSchedular);
            this.message1=this.responseData.data.message;
            if(this.message1 && this.message1.includes("Error")){
              this.actionForm5.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='26'){
            console.log("---------------",this.responseData.data.message);
            this.isLoadingz26 = false;
            this.aF26.paymentMode.setValue(this.responseData.data.paymentMode);
            this.aF26.lastTokenAmount.setValue(this.responseData.data.lastTokenAmount);
            this.aF26.lastTokenRechargeTime.setValue(this.responseData.data.lastTokenRechargeTime);
            this.aF26.totalAmountLastRecharge.setValue(this.responseData.data.totalAMountLastRecharge);
            this.aF26.currentBalanceAmount.setValue(this.responseData.data.currentBalanceAmount);
            this.aF26.currentBalanceTime.setValue(this.responseData.data.currentBalanceTime);

            this.message26=this.responseData.data.message;
            if(this.message26 && this.message26.includes("Error")){
              this.actionForm26.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='6'){
            this.isLoading6 = false;
            this.isLoading = false;
            this.actionForm6.reset();
          }else if(number=='7'){
            this.isLoading7 = false;
            this.isLoading = false;
            //console.log(this.responseData.data.prepaidFunction);
            this.aF19.prepaidFunctionNew.setValue(this.responseData.data.prepaidFunction);
            this.message26=this.responseData.data.message;
            if(this.message26 && this.message26.includes("Error")){
              this.actionForm19.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='8'){
            this.isLoading8 = false;
            this.isLoading = false;
            this.aF8.netMeters.setValue(this.responseData.data.meteringMode);
            this.message2=this.responseData.data.message;
            if(this.message2 && this.message2.includes("Error")){
              this.actionForm8.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='20'){
            this.isLoading20 = false;
            this.isLoading = false;
            this.aF20.connectDisconnectControl.setValue(this.responseData.data.connectDisconnectControl);
            this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);
            this.message2=this.responseData.data.message;
            if(this.message2 && this.message2.includes("Error")){
              this.actionForm20.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
              if(this.message2.includes("Off")){
                this.status = true;
                this.actionForm9.reset();
              }else{
                this.status = false;
              }
            }
          }else if(number=='9'){
            this.isLoading9 = false;
            this.isLoading = false;
            this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);
            this.message2=this.responseData.data.message;
            if(this.message2 && this.message2.includes("Error")){
              this.actionForm9.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='10'){
            this.isLoading10 = false;
            this.isLoading = false;
            this.actionForm10.reset();
          }else if(number=='24'){
            this.isLoading24 = false;
            this.isLoading = false;
            this.actionForm24.reset();
          }else if(number=='11'){
            this.isLoadingz11 = false;
            //this.isLoading = false;
            if(this.responseData.data.meterDatetime!=''){
              this.meterDateTime=this.responseData.data.meterDatetime;
            }
            this.serverDateTime=this.responseData.data.serverDatetime;
            this.timeDiffrence=this.responseData.data.diffrence;
            this.message11=this.responseData.data.message;
            if(this.message11 && this.message11.includes("Error")){
              /*if(this.serverDateTime.toString()==''){
                this.serverDateTime = new Date();
              }*/
              //this.actionForm1.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='12'){
            this.isLoading12 = false;
            this.isLoading = false;
            this.actionForm14.reset();
          }else if(number=='13'){
            //console.log("--------------------After Call-----------");
            this.isLoadingz13 = false;
            //this.isLoading = false;
            this.aF15.pastTeriffDate.setValue(this.responseData.data.oldActivationDt);
            this.aF15.tod1.setValue(this.responseData.data.tod1);
            this.aF15.tod2.setValue(this.responseData.data.tod2);
            this.aF15.tod3.setValue(this.responseData.data.tod3);
            this.aF15.tod4.setValue(this.responseData.data.tod4);
            this.aF15.tod5.setValue(this.responseData.data.tod5);
            this.aF15.tod6.setValue(this.responseData.data.tod6);
            this.aF15.tod7.setValue(this.responseData.data.tod7);
            this.aF15.tod8.setValue(this.responseData.data.tod8);
            this.message13=this.responseData.data.message;
            if(this.message13 && this.message13.includes("Error")){
              this.actionForm17.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
            //this.actionForm15.reset();
          }else if(number=='14'){
            this.isLoading14 = false;
            this.isLoading = false;
            this.actionForm16.reset();
          }else if(number=='15'){
            this.isLoadingz15 = false;
            //this.isLoading = false;
            this.aF17.earthLoading.setValue(this.responseData.data.earthLoading);
            this.aF17.infMagnetS.setValue(this.responseData.data.infMagnet);
            this.aF17.neturalDistrubanceS.setValue(this.responseData.data.neturalDistrubance);
            this.aF17.meterCOpenS.setValue(this.responseData.data.meterCOpen);
            this.aF17.loadConnectDisS.setValue(this.responseData.data.loadConnectDis);
            this.aF17.lastGaspS.setValue(this.responseData.data.lastGasp);
            this.aF17.firstBreathS.setValue(this.responseData.data.firstBreath);
            this.aF17.incBillingCountS.setValue(this.responseData.data.incBillingCount);
            this.aF17.currentReverse.setValue(this.responseData.data.rPhaseCurrentRev);
            this.aF17.overVoltageS.setValue(this.responseData.data.overVoltage);
            this.aF17.lowVoltageS.setValue(this.responseData.data.lowVoltage);
            this.aF17.overCurrentS.setValue(this.responseData.data.overCurrent);
            this.message15=this.responseData.data.message;

            if(this.message15 && this.message15.includes("Error")){
              this.actionForm17.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='19'){
            this.isLoading19 = false;
            this.isLoading = false;
            this.aF19.prepaidFunctionNew.setValue(this.responseData.data.prepaidFunction);
            this.message2=this.responseData.data.message;
            if(this.message2 && this.message2.includes("Error")){
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='11'){
            this.isLoading11 = false;
            this.isLoading = false;
            //this.meterDateTime=this.responseData.data.meterDatetime;
            this.message11=this.responseData.data.message;
            if(this.message11 && this.message11.includes("Error")){
              //this.serverDateTime = new Date();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='21'){
            //this.isLoading = false;
            this.isLoadingz21 = false;
            this.aF21.loadLimit.setValue(this.responseData.data.loadLimit);
            this.aF21.lockOutPeriod.setValue(this.responseData.data.overDuration);
            this.aF21.relayConnDis.setValue(this.responseData.data.underDuration);
            this.message21=this.responseData.data.message;
            if(this.message21 && this.message21.includes("Error")){
              this.actionForm21.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }else if(number=='25'){
            this.isLoading25 = false;
            this.isLoading = false;
            //console.log(this.responseData.data.prepaidFunction);
            this.aF25.relayStatus.setValue(this.responseData.data.relayStatus);
            this.message2=this.responseData.data.message;
            if(this.message2 && this.message2.includes("Error")){
              this.actionForm25.reset();
              this.thumb = false;
            }else{
              this.thumb = true;
            }
          }
        }else{
          this.thumb = true;
          //this.message = this.responseData.data.message;
          if(number=='1'){
            this.isLoading1 = false;
            this.aF1.capturedPeriod.setValue(this.responseData.data.capturedPeriod);
          }else if(number=='2'){
            this.isLoading2 = false;
            this.aF2.demandIntegrationPeriod.setValue(this.responseData.data.demandIntegrationPeriod);
          }else if(number=='3'){
            this.isLoading3 = false;
            this.aF3.loadLimit.setValue(this.responseData.data.loadLimit);
          }else if(number=='4'){
            this.isLoading4 = false;
            this.aF4.billingDate.setValue(this.responseData.data.billingDate);
          }else if(number=='5'){
            this.isLoading5 = false;
            this.aF5.pushSchedular.setValue(this.responseData.data.pushSchedular);
          }else if(number=='6'){
            this.isLoading6 = false;
            this.actionForm6.reset();
            this.aF6.autoReconnection.setValue(this.responseData.data.autoReconnection);
            this.aF6.timeIntervaL.setValue(this.responseData.data.timeIntervaL);
            this.aF6.lockOutPeriod.setValue(this.responseData.data.lockOutPeriod);
            this.aF6.activationTime.setValue(this.responseData.data.activationTime);
          }else if(number=='7'){
            this.isLoading7 = false;
            //this.aF7.prepaidFunction.setValue(this.responseData.data.prepaidFunction);
          }else if(number=='8'){
            this.isLoading8 = false;
            //this.aF8.netMeters.setValue(this.responseData.data.netMeters);
          }else if(number=='20'){
            this.isLoading20 = false;
            //this.aF20.connectDisconnectControl.setValue(this.responseData.data.connectDisconnectControl);
          }else if(number=='9'){
            this.isLoading9 = false;
            //this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);
          }else if(number=='10'){
            this.isLoading10 = false;
            //this.aF10.mdReset.setValue(this.responseData.data.mdReset);
          }else if(number=='11'){
            this.isLoading11 = false;
            if(this.responseData.data.meterDateTime!='' || this.responseData.data.meterDateTime!=undefined){
              this.meterDateTime=this.responseData.data.meterDateTime;
            }
          }else if(number=='12'){
            this.isLoading12 = false;
            this.aF14.overVoltage.setValue(this.responseData.data.overVoltage);
            this.aF14.lowVoltage.setValue(this.responseData.data.lowVoltage);
            this.aF14.overCurrent.setValue(this.responseData.data.overCurrent);
            this.aF14.activationDatetime.setValue(this.responseData.data.activationDatetime);
          }else if(number=='21'){
            this.isLoading21 = false;
            this.aF21.loadLimit.setValue(this.responseData.data.loadLimit);
            this.aF21.lockOutPeriod.setValue(this.responseData.data.overDuration);
            this.aF21.relayConnDis.setValue(this.responseData.data.underDuration);
          }else if(number=='13'){
            this.isLoadingz13 = false;
            console.log("iN FUNCTION");
            this.aF15.tod1.setValue("07:00");
            this.aF15.tod2.setValue(this.responseData.data.tod2);
            this.aF15.tod3.setValue(this.responseData.data.tod3);
            this.aF15.tod4.setValue(this.responseData.data.tod4);
            this.aF15.tod5.setValue(this.responseData.data.tod5);
            this.aF15.tod6.setValue(this.responseData.data.tod6);
            this.aF15.tod7.setValue(this.responseData.data.tod7);
            this.aF15.tod8.setValue(this.responseData.data.tod8);
          }else if(number=='14'){
            this.isLoading14 = false;
            this.aF16.rPhaseVoltage.setValue(this.responseData.data.rPhaseVoltage);
            this.aF16.yPhaseVoltage.setValue(this.responseData.data.yPhaseVoltage);
            this.aF16.bPhaseVoltage.setValue(this.responseData.data.bPhaseVoltage);
            this.aF16.overVoltage.setValue(this.responseData.data.overVoltage);
            this.aF16.lowVoltage.setValue(this.responseData.data.lowVoltage);
            this.aF16.voltageUnbalance.setValue(this.responseData.data.voltageUnbalance);
            this.aF16.rPhaseCurrentRev.setValue(this.responseData.data.rPhaseCurrentRev);
            this.aF16.yPhaseCurrentRev.setValue(this.responseData.data.yPhaseCurrentRev);
            this.aF16.bPhaseCurrentRev.setValue(this.responseData.data.bPhaseCurrentRev);
            this.aF16.currentUnbalance.setValue(this.responseData.data.currentUnbalance);
            this.aF16.currentByPass.setValue(this.responseData.data.currentByPass);
            this.aF16.overCurrent.setValue(this.responseData.data.overCurrent);
            this.aF16.activationDatetime.setValue(this.responseData.data.activationDatetime);
            this.aF16.veryLPF.setValue(this.responseData.data.veryLPF);
            this.aF16.infMagnet.setValue(this.responseData.data.infMagnet);
            this.aF16.neturalDistrubance.setValue(this.responseData.data.neturalDistrubance);
            this.aF16.meterCOpen.setValue(this.responseData.data.meterCOpen);
            this.aF16.loadConnectDis.setValue(this.responseData.data.loadConnectDis);
            this.aF16.lastGasp.setValue(this.responseData.data.lastGasp);
            this.aF16.firstBreath.setValue(this.responseData.data.firstBreath);
            this.aF16.incBillingCount.setValue(this.responseData.data.incBillingCount);
          }else if(number=='15'){
            this.isLoading15 = false;
            this.aF17.earthLoading.setValue(this.responseData.data.earthLoading);
            this.aF17.infMagnetS.setValue(this.responseData.data.infMagnetS);
            this.aF17.neturalDistrubanceS.setValue(this.responseData.data.neturalDistrubanceS);
            this.aF17.meterCOpenS.setValue(this.responseData.data.meterCOpenS);
            this.aF17.loadConnectDisS.setValue(this.responseData.data.loadConnectDisS);
            this.aF17.lastGaspS.setValue(this.responseData.data.lastGaspS);
            this.aF17.firstBreathS.setValue(this.responseData.data.firstBreathS);
            this.aF17.incBillingCountS.setValue(this.responseData.data.incBillingCountS);
            this.aF17.currentReverse.setValue(this.responseData.data.currentReverse);
            this.aF17.overVoltageS.setValue(this.responseData.data.overVoltageS);
            this.aF17.lowVoltageS.setValue(this.responseData.data.lowVoltageS);
            this.aF17.overCurrentS.setValue(this.responseData.data.overCurrentS);
            
          }else if(number=='19'){
            this.isLoading19 = false;
            this.aF19.prepaidFunctionNew.setValue(this.responseData.data.prepaidFunction);
          }
        }
        /*if(this.message.includes("You are not authorize") 
            || this.message.includes("Communication Failure") 
            || this.message.includes("Unsuccessful")
            || this.message.includes("Unsuccessfully") 
            || this.message.includes("Under Progress") 
            || this.message.includes("Not Generate")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }*/
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      if(number=='1'){
        this.isLoading1 = false;
        this.isLoading = false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm1.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='2'){
        this.isLoading2 = false;
        this.isLoading = false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm2.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='3'){
        this.isLoading3 = false;
        this.isLoading = false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm3.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='4'){
        this.isLoading4 = false;
        this.isLoading = false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm4.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='5'){
        this.isLoading5 = false;
        this.isLoading = false;
        this.message1='Communication Error !';
        if(this.message1.includes("Error")){
          this.actionForm5.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='6'){
        this.isLoading6 = false;
        this.isLoading = false;
        this.actionForm6.reset();
      }else if(number=='7'){
        this.isLoading7 = false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm19.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='8'){
        this.isLoading8 = false;
        this.isLoading = false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm8.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='20'){
        this.isLoading20 = false;
        this.isLoading = false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm20.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
          if(this.message2.includes("Off")){
            this.status = true;
            this.actionForm9.reset();
          }else{
            this.status = false;
          }
        }
      }else if(number=='9'){
        this.isLoading9 = false;
        this.isLoading = false;
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.actionForm9.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='10'){
        this.isLoading10 = false;
        this.isLoading = false;
        this.actionForm10.reset();
      }else if(number=='11'){
        this.isLoading11 = false;
        this.isLoading = false;
        this.message11='Communication Error !';
        if(this.message11.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='12'){
        this.isLoading12 = false;
        this.isLoading = false;
        this.actionForm14.reset();
      }else if(number=='13'){
        this.isLoading13 = false;
        this.isLoading = false;
        this.actionForm15.reset();
      }else if(number=='14'){
        this.isLoading14 = false;
        this.isLoading = false;
        this.actionForm16.reset();
      }else if(number=='15'){
        this.isLoading15 = false;
        this.isLoading = false;
        this.message15='Communication Error !';
        if(this.message15.includes("Error")){
          this.actionForm17.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='19'){
        this.isLoading19 = false;
        this.isLoading = false;
      
        this.message2='Communication Error !';
        if(this.message2.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='11'){
        this.isLoading11 = false;
        this.isLoading = false;
        this.message11='Communication Error !';
        if(this.message11.includes("Error")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else if(number=='21'){
        this.isLoading = false;
        this.isLoading21 = false;
        this.message21='Communication Error !';
        if(this.message21.includes("Error")){
          this.actionForm21.reset();
          this.thumb = false;
        }else{
          this.thumb = true;
        }
      }else{
        this.message = 'Communication Error !';
      }
    });
  }

  getConfiguratoin(){
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getUtilityConfigData', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      //console.log("hello",this.responseData.data.capturePeriod);
      if (this.responseData.success) {
        this.isLoading1 = false;
        this.isLoading = false;
        this.aF1.capturedPeriod.setValue(this.responseData.data.capturePeriod);
        this.aF2.demandIntegrationPeriod.setValue(this.responseData.data.demandIntegration);
        this.aF19.prepaidFunctionNew.setValue(this.responseData.data.prepaidFunction);
        this.aF8.netMeters.setValue(this.responseData.data.netMeters);
        this.aF20.connectDisconnectControl.setValue(this.responseData.data.connectDisconnectControl);
        this.aF9.connectDisconnect.setValue(this.responseData.data.connectDisconnect);

        this.aF14.overVoltage.setValue(this.responseData.data.overVoltage);
        this.aF14.lowVoltage.setValue(this.responseData.data.lowVoltage);
        this.aF14.overCurrent.setValue(this.responseData.data.overCurrent);
        this.aF21.loadLimit.setValue(this.responseData.data.loadLimit);
        this.aF21.lockOutPeriod.setValue(this.responseData.data.lockOutPeriod);
        this.aF21.relayConnDis.setValue(this.responseData.data.relayConnDis);
        this.aF17.earthLoading.setValue(this.responseData.data.earthLoading);
        this.aF17.infMagnetS.setValue(this.responseData.data.infMagnetS);
        this.aF17.neturalDistrubanceS.setValue(this.responseData.data.neturalDistrubanceS);
        this.aF17.meterCOpenS.setValue(this.responseData.data.meterCOpenS);
        this.aF17.loadConnectDisS.setValue(this.responseData.data.loadConnectDisS);
        this.aF17.lastGaspS.setValue(this.responseData.data.lastGaspS);
        this.aF17.firstBreathS.setValue(this.responseData.data.firstBreathS);
        this.aF17.incBillingCountS.setValue(this.responseData.data.incBillingCountS);
        this.aF17.currentReverse.setValue(this.responseData.data.currentReverse);
        this.aF17.overVoltageS.setValue(this.responseData.data.overVoltageS);
        this.aF17.lowVoltageS.setValue(this.responseData.data.lowVoltageS);
        this.aF17.overCurrentS.setValue(this.responseData.data.overCurrentS);
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  onSelectFlat(e, meterId) {
    this.canSearch = true;
    this.flatNo = meterId;
  }
  displayFlatInfo(flat: any): string {
    return flat ? flat.Name : undefined;
  }

  findFlatInfo(val: string) {
    return this.firmwareVersionData.filter(state =>
        state.Name.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
  }

  getFirmwareVersion() {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', '');
    body.set('versionId', '');
    body.set('filter', '');
    body.set('offSet', '0');
    body.set('limit', '9999999');
    body.set('authToken', this.currentUser.authToken);
    body.set('subDivName', this.currentUser.data.userData.subName);
    this.genReqs.postReq('/getMeterFirmwareVersion', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
            this.firmwareVersionData = this.responseData.data;
            this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
                startWith(''),
                map(value => this.findFlatInfo(value))
            );
       }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
}

getTodConfig() {
  let body = new URLSearchParams();
  body.set('subdivId', this.currentUser.data.userData.subdivision);
  body.set('dcuId', '');
  body.set('conAccId', this.meterDetail.cACNO);
  body.set('meterId', this.meterDetail.muID);
  body.set('filter', '');
  body.set('offSet', '0');
  body.set('limit', '9999999');
  body.set('authToken', this.currentUser.authToken);
  body.set('subDivName', this.currentUser.data.userData.subName);
  this.genReqs.postReq('/getTodConfig', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
          
          this.aF15.tod1.setValue(this.responseData.data.tod1.toString());
          this.aF15.tod2.setValue(this.responseData.data.tod2.toString());
          this.aF15.tod3.setValue(this.responseData.data.tod3.toString());
          this.aF15.tod4.setValue(this.responseData.data.tod4.toString());
          this.aF15.tod5.setValue(this.responseData.data.tod5.toString());
          this.aF15.tod6.setValue(this.responseData.data.tod6.toString());
          this.aF15.tod7.setValue(this.responseData.data.tod7.toString());
          this.aF15.tod8.setValue(this.responseData.data.tod8.toString());
          this.aF15.pastTeriffDate.setValue(new Date(this.responseData.data.activationDateTime.toString()));
          this.aF7.prepaidFunction.setValue(this.responseData.data.prePost);
          this.aF8.netMeters.setValue(this.responseData.data.impExp);
          this.aF9.connectDisconnect.setValue(this.responseData.data.connDis);
          
         

          this.aF23.pastHappyDate.setValue(new Date(this.responseData.data.happActivationDate));
          this.aF23.startTime.setValue(this.responseData.data.startHourTime);
          this.aF23.endTime.setValue(this.responseData.data.endHourTime);
          this.aF23.holidayMonth.setValue(this.responseData.data.holidayMonths);
          this.aF23.holidayDate.setValue(this.responseData.data.holidayDates);
          this.aF23.weekDay.setValue(this.responseData.data.weekDay);
          this.aF22.pastFirmWareDate.setValue(new Date(this.responseData.data.fotaActivationDate));
          this.aF22.oldFirmWare.setValue(this.responseData.data.firmWareVersion);
          
     }
  }, (err) => {
      if (err.status === 401) {
          this.authenticationService.logout();
      }
  });
}

formateDate(datevalue) {
  let date = new Date(datevalue);
  return (date.getMonth()+1)+'/'+(date.getDate()+1)+'/'+date.getFullYear();
}


}
