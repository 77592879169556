<app-topbar></app-topbar>
<div scrller>
    <div class="cstm_slider">
        <div class="snow-container">
            <div class="snow foreground"></div>
            <div class="snow foreground layered"></div>
            <div class="snow middleground"></div>
            <div class="snow middleground layered"></div>
            <div class="snow background"></div>
            <div class="snow background layered"></div>
        </div>
        <div id="carouselExampleIndicators" class="carousel slide" data-interval="3000" data-ride="carousel">
            <!-- Wrapper for carousel items -->
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item bgcurve active">
                    <img src="assets/img/Background1.png" alt="Avon MDMS">
                    <!-- <img src="assets/img/Republicjan.jpg" alt="Avon MDMS"> -->
                    <div class="carousel-caption  d-md-block">
                        <div class="container slidetoptxt">
                            <div class="banner-content" data-aos="zoom-out">
                                <H2>Avon <span>MDMS</span></H2>
                                <h3>Symbol of Precision & <span>Quality </span> <br> </h3>
                                <p class="othersm">Managing Power the Smart Way.....</p>
                                <!-- <button type="button" (click)="openLoginModel()" class="lognbtn"
                                    onmousedown="party.confetti(this)">Login</button>
  -->
                                <div class="bg">
                                    <div class="centerer">
                                        <button type="button" class="button" (click)="openLoginModel()">Login</button>
                                    </div>
                                </div>

                            </div>
                            <div class="smrightimgslider" id="hero" data-aos="zoom-out" data-aos-delay="300">
                                <img src="assets/img/infophonegrid.png" class="img-fluid animated" alt=infoPhone>
                                <!-- <img src="assets/img/infophonegrid-chrismis.png" class="img-fluid animated"
                                    alt=infoPhone> -->

                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item bgcurve">
                    <img src="assets/img/smartcity.png" alt="Smart cities">
                    <div class="carousel-caption  d-md-block">
                        <div class="container slidetoptxt">
                            <div class="banner-content bgoverflay" data-aos="zoom-out">
                                <h2>Avon <span>Meters</span> <br>
                                    Providing</h2>
                                <p>Smart solutions for Smart cities.....</p>
                                <!-- <button type="button" (click)="openLoginModel()" class="lognbtn"
                                    onmousedown="party.confetti(this)">Login</button> -->

                                <div class="bg">
                                    <div class="centerer">
                                        <button type="button" class="button" (click)="openLoginModel()">Login</button>
                                    </div>
                                </div>
                            </div>
                            <div class="smrightimgslider" id="hero" data-aos="zoom-out" data-aos-delay="300">
                                <img src="assets/img/smart-grid2.png" class="img-fluid animated" alt=infoPhone>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item bgcurve">
                    <img src="assets/img/Smart-city-concept.jpg" alt="Industry Leader">
                    <div class="carousel-caption d-md-block">
                        <div class="container slidetoptxt">
                            <div class="banner-content" data-aos="zoom-out">
                                <h2>Avon <span>Meters</span> <br></h2>
                                <h3>has been an industry leader for more than 20 years </h3>
                                <p class="othersm">in energy management solutions.</p>
                                <!-- <button type="button" (click)="openLoginModel()" class="lognbtn"
                                    onmousedown="party.confetti(this)">Login</button> -->
                                <div class="bg">
                                    <div class="centerer">
                                        <button type="button" class="button" (click)="openLoginModel()">Login</button>
                                    </div>
                                </div>
                            </div>
                            <div class="smrightimgslider mttopslider" id="hero" data-aos="zoom-out"
                                data-aos-delay="300">
                                <img src="assets/img/smart_meterinfo.png" class="img-fluid animated" alt=infoPhone
                                    style="border-radius:20px;">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="about" class="aboutus">
        <div class="container">
            <div class="mrtop35">
                <div class="all_main_heading" data-aos="zoom-in" data-aos-once="true">
                    <h2>About Avon Meters</h2>
                    <p class="subblue "><span>AVON METERS </span>is a Private Limited ISO 9001 Certified Company
                        incorporated under the Companies act,
                        1956 and was registered with the Registrar of Companies, Jalandhar on 21-06-95. The company
                        provides Energy Metering Solutions to the States Electricity Boards with its product range of
                        <span>ISI
                            Marked High Precision Electromechanical, Electronic & Smart Energy Meters. </span>
                    </p>
                </div>
                <div class="row mrtop35">
                    <div class="col-md-5 col-sm-12" data-aos="fade-right">
                        <img src="assets/img/about_us.png" class="img-fluid" alt="About Us">
                    </div>
                    <div class="col-md-7 col-sm-12 aboutflexcentr" data-aos="fade-left">
                        <p data-aos="zoom-in" data-aos-delay="220">The company has a modern, spacious and dust proof
                            complex
                            at Dera Bassi (near Chandigarh). Its treatment plant, workshop,
                            assembly hall, testing area, R&D Lab and administrative block etc.
                            have been planned in a covered area of about 45,000 Square feet
                            to ensure smooth working at all stages of production on Flow Line
                            Method.
                        </p>
                        <p data-aos="zoom-in" data-aos-delay="250">The company is fully equipped with sophisticated
                            machinery and testing instruments required for maintaining high
                            standards of quality & accuracy. The testing instruments and
                            reference standards are kept in air conditioned labs/testing areas.
                            The vital meter components are subjected to rigorous on-line
                            quality control to ensure a high level of acceptability and
                            minimise of defects at the early stages of production.
                        </p>
                    </div>
                </div>
                <p class="mrtop35 subblue" data-aos="zoom-in" data-aos-delay="350">With the ushering in of the power
                    sector reforms and in the new environment of opportunity for the power sector, Avon Meters has
                    quickly adapted to this challenge & transformed itself in a short span of time. <span>Our supply of
                        meters is not confined to Punjab alone but flexes itself to states like Haryana, Karnataka,
                        Kerala, Tamil Nadu, Rajasthan, Maharashtra, Assam, Madhya Pradesh, Chattisgarh, Chandigarh etc.
                    </span>thus truly making our reach nation-wide and justifying the trust and faith customers place in
                    us. Our tireless efforts have been amply rewarded as we have been approved on DGS&D Rate Contract
                    and short listed by Power Finance Corporation.</p>
            </div>
        </div>
        <div class="bgawards">
            <div class="container" data-aos="fade-up">
                <div class="awardstar socialicon text-center">
                    <span class="material-icons">
                        style
                    </span>
                    <span class="material-icons">
                        style
                    </span>
                    <span class="material-icons">
                        style
                    </span>
                </div>
                <p class="awardstartxt">Avon Meters is committed to provide superior quality products and we have been
                    awarded the year
                    2002 "National Award" for Quality Products in Small Scale Industries and the "Special Recognition
                    Award" for
                    Entrepreneurship. These awards are instituted by Small Scale Industries, Govt. of India and were
                    presented
                    by the Hon'ble Prime Minister Dr. Manmohan Singh in a special function held on August 30 2004 at
                    Vigyan Bhawan in New Delhi.
                </p>
            </div>
            <div class="container mrtop35" data-aos="fade-left">
                <div class="flexouter_award3">
                    <div class="boxcm" data-aos="zoom-in" data-aos-delay="50">
                        <i class="fa fa-star"></i>
                        <p>In addition to the above, we have also been Awarded the "Rashtriya Udyog Ratan Award" by the
                            Indian Organization for Business Research & Development New Delhi and the "PRIDE OF INDIA
                            AWARD" by the Global Economic Council, New Delhi.
                        </p>
                    </div>
                    <div class="boxcm" data-aos="zoom-in" data-aos-delay="100">
                        <span class="material-icons">thumb_up</span>
                        <p>The management believes in bringing global best practises in manufacturing to the Energy
                            Meters and become a world-class utility. Avon meters aims to achieve excellence in service,
                            quality, reliability, safety and customer care, which will help us progress consistently
                            and achieve high growth with the highest levels of productivity..
                        </p>
                    </div>
                    <div class="boxcm" data-aos="zoom-in" data-aos-delay="150">
                        <span class="material-icons">lightbulb</span>
                        <p>Adequate steps are continuously taken to modernise our unit. We always try to keep ourselves
                            abreast of the latest technology and try to implement it in our unit as well. The
                            company envisages to enter the markets of the Indian subcontinent in the next two years and
                            to provide unmatchable metering solutions and quality products.
                        </p>
                    </div>
                </div>
                <div class="mrtop35" data-aos="fade-up">
                    <div class="all_main_heading">
                        <h2 class="txt_white text-center">Our Vision</h2>
                        <p class="txt_white text-center">Our vision is to maintain leadership in the field of energy
                            meters through continuous research. The modern manufacturing facilities at Dera Bassi were
                            established to transform this vision into reality. Contributing to the developing era of
                            information & technology in the country, we are designing innovative meters with features
                            like remote and pre-paid metering. The possibilities of creating world class production
                            facilities in association with some foreign partners are also being explored. Needless to
                            say, the coming years will be more glorious than ever. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="products" class="our_product">
        <div class="container-fluidd mrtop35 marginlr">
            <div class="row">
                <div class="col-md-12 col-sm-12 flex_centercentent all_main_heading txt_blue text-left"
                    data-aos="fade-up" data-aos-delay="400">
                    <h2>Our Product</h2>
                    <h3 class="lblpr">Single Phase Two Wire, Electronic & Smart Energy Meter with Class Accuracy 1.0
                        (Uni-Directional and Bi-Directional)</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7 col-sm-12 flex_centercentent all_main_heading txt_blue text-left"
                    data-aos="fade-up" data-aos-delay="400">
                    <div class="our_productU">
                        <div class="box_pr">
                            <h2>Applicable Standards</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>IS 16444</li>
                                <li><i class="fa fa-chevron-right"></i>IS 13779</li>
                                <li><i class="fa fa-chevron-right"></i>DLMS</li>
                                <li><i class="fa fa-chevron-right"></i>Non DLMS</li>
                                <li><i class="fa fa-chevron-right"></i>CBIP 325 etc</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Reference Voltage</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>240V (Phase to Neutral)</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Current Ratings</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>5-20A</li>
                                <li><i class="fa fa-chevron-right"></i>5-30A</li>
                                <li><i class="fa fa-chevron-right"></i>10-60A</li>
                                <li><i class="fa fa-chevron-right"></i>Other Rating as per customer request</li>
                            </ul>
                        </div>
                    </div>
                    <h3 class="lblpr mt-2">Three Phase Four Wire, Direct Connected Electronic & Smart Energy Meter with
                        Class Accuracy 1.0 (Uni-Directional and Bi-Directional)</h3>
                    <div class="our_productU">
                        <div class="box_pr">
                            <h2>Applicable Standards</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>IS 16444</li>
                                <li><i class="fa fa-chevron-right"></i>IS 13779</li>
                                <li><i class="fa fa-chevron-right"></i>DLMS</li>
                                <li><i class="fa fa-chevron-right"></i>Non DLMS </li>
                                <li><i class="fa fa-chevron-right"></i>IS 16444</li>
                                <li><i class="fa fa-chevron-right"></i>CBIP 325 etc</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Reference Voltage</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>3*240V (Phase to Neutral)</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Current Ratings</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>5-30A</li>
                                <li><i class="fa fa-chevron-right"></i>10-40A</li>
                                <li><i class="fa fa-chevron-right"></i>10-60A etc</li>
                            </ul>
                        </div>
                    </div>
                    <h3 class="lblpr mt-2">Three Phase Four Wire, LTCT operated Electronic
                        & Smart Energy Meter with Class Accuracy 0.5 (Uni- Directional and Bi-Directional)</h3>
                    <div class="our_productU">
                        <div class="box_pr">
                            <h2>Applicable Standards</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>IS 16444</li>
                                <li><i class="fa fa-chevron-right"></i>IS 14697</li>
                                <li><i class="fa fa-chevron-right"></i>DLMS</li>
                                <li><i class="fa fa-chevron-right"></i>Non DLMS </li>
                                <li><i class="fa fa-chevron-right"></i>IS 16444</li>
                                <li><i class="fa fa-chevron-right"></i>CBIP 325 etc</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Reference Voltage</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>3*240V (Phase to Neutral)</li>
                            </ul>
                        </div>
                        <div class="box_pr">
                            <h2>Current Ratings</h2>
                            <ul class="productul">
                                <li><i class="fa fa-chevron-right"></i>-/5A</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-12">
                    <div class="productimgouter" data-aos="fade-left" data-aos-delay="200">
                        <img src="assets/img/avonproduct.jpg" class="img-fluid hovertransform">
                    </div>
                    <div class="mt-4" data-aos="fade-left" data-aos-delay="400">
                        <img src="assets/img/drawingimg.png" class="img-fluid posabb mt-5">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="drawrel">
            <img src="assets/img/drawingimg.png" class="img-fluid posab">
        </div> -->
    </div>
    <!--<section class="bgsoldimage" data-aos="fade-up">
        <div class="outermetersold container">
            <div class="submetersold" data-aos="zoom-in">
                <span class="material-icons">star_rate</span>
                <h2 class="counter">2000000</h2>
                <p>Meters Sold</p>
            </div>
            <div class="submetersold" data-aos="zoom-in">
                <span class="material-icons">language</span>
                <h2 class="counter">10</h2>
                <p>State</p>
            </div>
            <div class="submetersold" data-aos="zoom-in">
                <span class="material-icons">location_city</span>
                <h2 class="counter">30 </h2>
                <p>Cities</p>
            </div>
        </div>
    </section>-->
    <section>
        <div class="container mrtop35">
            <div class="flex_centercentent all_main_heading txt_blue text-center" data-aos="fade-up">
                <h2>Your tool for Better Energy Management</h2>
            </div>
            <div class="row mrtop35">
                <div class="col-md-4 col-sm-12" data-aos="zoom-in" data-aos-delay="100">
                    <div class="outerproimg">
                        <img src="assets/img/smart_meterr.png" class="img-fluid">

                    </div>
                </div>
                <div class="col-md-8 col-sm-12 align_center_txt" data-aos="fade-left" ata-aos-delay="100">
                    <div class="ml35left">
                        <div class=" flex_centercentent all_main_heading txt_blue text-left">
                            <h3 class="cstmborder">Smart Meter</h3>
                        </div>
                        <div class="subsmartdec">
                            <div class="iconsmartmeter">
                                <ul class="allsubtxtsmart">
                                    <li><i class="fa fa-star"></i>Remote connect & disconnect prepaid functionality
                                    </li>
                                    <li><i class="fa fa-star"></i>Modular communication supported (RF, Cellular (2G, 3G,
                                        4G), NB- IOT)</li>
                                    <li><i class="fa fa-star"></i>Sleek Design</li>
                                    <li><i class="fa fa-star"></i>Head End System (HES) & Meter Data Management(MDM)
                                        software</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row prosolowrpower">
                <div class="col-md-8 col-sm-12 align_center_txt" data-aos="fade-up" ata-aos-delay="200">
                    <div class="ml35left">
                        <div class="flex_centercentent all_main_heading txt_blue text-left">
                            <h3 class="cstmborder">Net Meters For Solar Power</h3>
                        </div>
                        <div class="subsmartdec">
                            <div class="iconsmartmeter">
                                <ul class="allsubtxtsmart">
                                    <li><i class="fa fa-star"></i>Separate Reading for KWh Import & Export</li>
                                    <li><i class="fa fa-star"></i>1 Phase, 3 Phase & CT Operated</li>
                                    <li><i class="fa fa-star"></i>Solar & Power Generation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12" data-aos="zoom-in" data-aos-delay="100">
                    <div class="outerproimg">
                        <img src="assets/img/1phasefull.png" class="img-fluid">
                    </div>
                </div>
            </div>

            <div class="row mrtop35 prosolowrpower">
                <div class="col-md-12 col-sm-12" data-aos="fade-up" ata-aos-delay="200">
                    <div class="ml35left">
                        <div class="flex_centercentent all_main_heading txt_blue text-left">
                            <h3 class="cstmborder">Multifuntion 1 Phase, 3 Phase & CT Operated Meters</h3>
                        </div>
                        <div class="outerboxIRDA">
                            <div class="threepointouter">
                                <div class="threepoint" data-aos="fade-right" ata-aos-delay="200">
                                    <div class="borderproduct proone">
                                        1
                                    </div>
                                    <div class="producttxtthree">
                                        <p>RF, IRDA, RS 232, RS 485, Optical Port Supported</p>
                                        <i class="fa fa-home"></i>
                                    </div>
                                </div>
                                <div class="threepoint" data-aos="fade-left" ata-aos-delay="200">
                                    <div class="borderproduct protwo">
                                        2
                                    </div>
                                    <div class="producttxtthree">
                                        <p>Comes with Data Accuisition Software Solution</p>
                                        <i class="fa fa-home"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="threepointouter mt-3">
                                <div class="threepoint" data-aos="fade-up" ata-aos-delay="300">
                                    <div class="borderproduct prothree">
                                        3
                                    </div>
                                    <div class="producttxtthree">
                                        <p class="mt-2">Tamper Proof i.e. Utrasonically Welded, Abnormal Magnetic field
                                            Detection, Top
                                            cover Opening Detection</p>
                                        <i class="fa fa-home"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="circleouterIRDA">
                            <div class="circleDLMS">
                                <p>DLMS Protocol Compatible</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mrtop35">
                <div class="flex_centercentent all_main_heading txt_blue text-left mrtop35" data-aos="fade-up">
                    <h3 class="cstmborder">Solutions For Townships & Commercial Establishments </h3>
                </div>
                <div class="row mrtop35">
                    <div class="col-md-5 col-sm-12" data-aos="fade-right">
                        <div class="solutionflex">
                            <div class="solutionflexsub" data-aos="zoom-in" data-aos-delay="100">
                                <i class="fa fa-star"></i>
                                <p>Separate EB & DG Energy Measurement, Advanced Analysis, Reporting & Prepaid
                                    Functionality</p>
                            </div>
                            <div class="solutionflexsub" data-aos="zoom-in" data-aos-delay="150">
                                <i class="fa fa-star"></i>
                                <p>We Will Help You To Develop a Solution You Require</p>
                            </div>
                            <div class="solutionflexsub" data-aos="zoom-in" data-aos-delay="200">
                                <i class="fa fa-star"></i>
                                <p>End to End Meter Management</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12 m-auto" data-aos="zoom-in" data-aos-delay="100">
                        <!-- <img src="assets/img/smartmeter.png" class="img-fluid"> -->
                        <img src="assets/img/bg_productlogin.png" class="img-fluid hovertransform">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="gallery">
        <div class="mrtop35 container">
            <div class="flex_centercentent all_main_heading txt_blue" data-aos="fade-up">
                <h2>Photo Gallery</h2>
            </div>
            <div>
                <ng-image-slider [images]="imageObject2" [infinite]="sliderInfinite" [imagePopup]="sliderImagePopup"
                    [showArrow]="sliderArrowShow" [autoSlide]="sliderAutoSlide ? 1 : 0" [slideImage]="sliderSlideImage"
                    [animationSpeed]="sliderAnimationSpeed" [manageImageRatio]="false" [videoAutoPlay]="false"
                    [showVideoControls]="false" [paginationShow]="true" [orderType]="slideOrderType" #nav>
                </ng-image-slider>
            </div>
        </div>
    </section>

    <section class="avonhistory">
        <div class="mrtop35">
            <div class="flex_centercentent all_main_heading txt_blue text-center" data-aos="fade-up">
                <h2>Avon’s History</h2>
            </div>
            <div id="timelineavon" class="mrtop35">
                <article data-aos="zoom-in" data-aos-delay="100">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2000</span>
                        </span>
                        <h2>Transition period from electro-mechanical to static meters. Commenced production of counter
                            type static meters.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="200">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2006</span>
                        </span>
                        <h2>Developed electronic LCD type meters with logging facility.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="300">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2010</span>
                        </span>
                        <h2>Established full in-house facility for meter manufacturing & Developed in-house designs for
                            1 Phase & 3 Phase
                            meters.

                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="400">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2013</span>
                        </span>
                        <h2>New Green Field Facility.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="500">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2013</span>
                        </span>
                        <h2>Bagged single largest order for 2.7 million meters.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="600">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2013</span>
                        </span>
                        <h2>Granted NABL and CMMI Level III certification.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="700">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2016</span>
                        </span>
                        <h2>Equipped meters with RF technology.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="800">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2017</span>
                        </span>
                        <h2>Bagged 500 thousand meters order for international market.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="900">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2018</span>
                        </span>
                        <h2>Awarded DSIR certificate for R&D.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="1000">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2019</span>
                        </span>
                        <h2>New product launched SMART Meters as per IS16444 and acord certification from BIS for 1Ph &
                            3Ph
                            Smart Meters.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="1100">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2020</span>
                        </span>
                        <h2>Executed Smart Energy Management Solution for Housing Projects.

                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="1200">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2021</span>
                        </span>
                        <h2>In 2021, Bagged first 3ph smart meter order for MP project.
                        </h2>
                    </div>
                </article>
                <article data-aos="zoom-in" data-aos-delay="1300">
                    <div class="inner">
                        <span class="date">
                            <span class="year">2022</span>
                        </span>
                        <h2>In October 2022, Bagged largest (0.55 million) 1Ph smart meters for PSPCL. 

                        </h2>
                    </div>
                </article>
            </div>
        </div>
    </section>
    <section>
        <div class="mrtop35 container">
            <div class="container flex_centercentent all_main_heading txt_blue" data-aos="fade-up">
                <h2>Recognition Awards & Certifications</h2>
                <p class="subblue"> <span>"National Award"</span> for Quality Products in Small Scale Industries sector
                    and <span>"Special Recognition Award” </span>for <span>“Entrepreneurship” </span>by the
                    <span>Hon'ble Prime Minister of India.
                    </span> <span>"Rashtriya Udyog Ratan Award" </span>by the Indian Organization for Business Research
                    & Development New Delhi and the <span>"PRIDE OF INDIA AWARD"</span>
                </p>
            </div><br>
            <div data-aos="fade-up">
                <ng-image-slider [images]="imageObject1" [infinite]="sliderInfinite" [imagePopup]="sliderImagePopup1"
                    [showArrow]="sliderArrowShow" [autoSlide]="sliderAutoSlide ? 1 : 0" [slideImage]="sliderSlideImage1"
                    [animationSpeed]="sliderAnimationSpeed" [manageImageRatio]="false" [videoAutoPlay]="true"
                    [showVideoControls]="true" [paginationShow]="false" [orderType]="slideOrderType" #nav>
                </ng-image-slider>
            </div>

        </div>
    </section>
    <section id="careers" class="mrtop35">
        <div class="container flex_centercentent all_main_heading txt_blue mt-4" data-aos="fade-up">
            <h2>Join Our Team</h2>
            <p>We are looking for people to join the team who are as excited as we are to help and build the platform
                that empowers the creators of future generation to be successful.</p>
            <div class="container login-container">
                <div class="row">
                    <div class="col-md-6 login-form-1">
                        <h3>Interview Process</h3>
                        <form>
                            <ul class="intrvwLI">
                                <li><i class="fa fa-dot-circle-o" aria-hidden="true"></i>APPLICATION</li>
                                <li><i class="fa fa-dot-circle-o" aria-hidden="true"></i>SHORTLISTING</li>
                                <li><i class="fa fa-dot-circle-o" aria-hidden="true"></i>TECHNICAL INTERVIEW</li>
                                <li><i class="fa fa-dot-circle-o" aria-hidden="true"></i>HR INTERVIEW</li>
                                <li><i class="fa fa-dot-circle-o" aria-hidden="true"></i>OFFER ROLL-OUT</li>
                            </ul>
                            <div class="form-group">
                                <input type="button" (click)="jobs()" class="btnSubmit" value="View Job">
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6 login-form-2">
                        <h3>Send Resume</h3>
                        <form [formGroup]="careerForm " class="interviwform" (ngSubmit)="onSubmitCareerForm()">
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <input matInput class="form-control bgwhiteerror" placeholder="Your Name*" name="nameCV"
                                    formControlName="nameCV"
                                    [ngClass]="{ 'is-invalid': submittedCV && cV.nameCV.errors}" required>
                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.nameCV.hasError('required') && (cV.nameCV.dirty || cV.nameCV.touched)">
                                    Visitor's Name is required
                                </span>
                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <mat-select class="form-control bgwhiteerror" placeholder="Select Job ID..."
                                    name="jobId" formControlName="jobId" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': submittedCV && cV.jobId.errors}" required>
                                    <mat-option *ngFor="let dg of jobIdData" [value]="dg.jobId">
                                        {{dg.jobCode}}
                                    </mat-option>
                                </mat-select>
                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.jobId.hasError('required') && (cV.jobId.dirty || cV.jobId.touched)">
                                    JOB Id is required
                                </span>

                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <input matInput class="form-control bgwhiteerror" placeholder="Email ID"
                                    name="emailIdCV" formControlName="emailIdCV"
                                    [ngClass]="{ 'is-invalid': submittedCV && cV.emailIdCV.errors}" required>

                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.emailIdCV.hasError('required') && (cV.emailIdCV.dirty || cV.emailIdCV.touched)">
                                    E-mail is required
                                </span>

                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.emailIdCV.hasError('email') && (cV.emailIdCV.dirty || cV.emailIdCV.touched)">
                                    Please enter a valid E-mail address
                                </span>

                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">

                                <input matInput class="form-control bgwhiteerror" [maxLength]="10"
                                    placeholder="Mobile No.*" name="phoneNoCV" formControlName="phoneNoCV"
                                    [ngClass]="{ 'is-invalid': submittedCV && cV.phoneNoCV.errors}" required>



                                <!--<div *ngIf="submittedCV && cV.phoneNoCV.errors" class="invalid-feedback txtwhiteerror">-->
                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.phoneNoCV.hasError('required') && (cV.phoneNoCV.dirty || cV.phoneNoCV.touched)">
                                    This field is required
                                </span>

                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.phoneNoCV.hasError('pattern') && (cV.phoneNoCV.dirty || cV.phoneNoCV.touched)">
                                    Please enter valid phone number
                                </span>
                                <!--</div>-->
                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <textarea class="form-control bgwhiteerror" rows="3" [maxLength]="160" matInput
                                    placeholder="Message (1 to 160  Charactors)" name="messageCV"
                                    formControlName="messageCV"
                                    [ngClass]="{ 'is-invalid': submittedCV && cV.messageCV.errors}" required></textarea>
                                <span class="invalid-feedback txtwhiteerror"
                                    *ngIf="cV.messageCV.hasError('required') && (cV.messageCV.dirty || cV.messageCV.touched)">
                                    Message is required
                                </span>
                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <input type="file" class="mtb15 form-control-file" name="visitorCV"
                                    (change)="onFileSelect($event)" accept=".doc,.docx,.pdf" />
                                <!--<div *ngIf="cV.visitorCV.errors" class="invalid-feedback txtwhiteerror">
                                    <div *ngIf="cV.visitorCV.errors.required">
                                        VisitorCV is required
                                    </div>
                                </div>-->
                            </div>
                            <div class="roww">
                                <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                    <button class="lognbtn bgbtncontact" [disabled]="AddProcessNew" type="submit">
                                        Send Resume
                                    </button>
                                </div>

                                <div class="form-group col-md-8 col-sm-12 text-left m-auto">
                                    <ng-container *ngIf="isLoading">
                                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                        </fa-icon>
                                    </ng-container>
                                    <div class="success" *ngIf="displayMsgCV && responseMessageCV.length > 0">
                                        {{responseMessageCV}}
                                    </div>
                                    <div class="error" *ngIf="displayMsgCV && responseMessageCV.length <= 0">
                                        Please try after some time.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group sendcv col-md-8 col-sm-12 m-auto">
                                <p class="sharecv">Share your profile directly to HR at <br><a
                                        href="mailto:avonmeterprojects@gmail.com "
                                        target="_blank">avonmeterprojects@gmail.com </a></p>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </section>



    <section id="branches">
        <div class="container flex_centercentent all_main_heading txt_blue" data-aos="fade-up">
            <h2>Our Branches</h2>
        </div>
        <div class="container ourclientouter mrtop35">
            <ul class="client-list ourbranchAll">
                <!-- <li class="item" data-aos="zoom-in" data-aos-delay="100">
                    <div class="imgcard">
                        <img src="assets/img/delhi-map.png" class="img-fluid">
                    </div>
                </li> -->
                <li class="item" data-aos="zoom-in" data-aos-delay="100">
                    <div class="imgcard">
                        <img src="assets/img/jaipur.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/bhopal.png" class="img-fluid">
                    </div>
                </li>

                <li class="item" data-aos="zoom-in" data-aos-delay="200">
                    <div class="imgcard">
                        <img src="assets/img/delhi-map.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/Jabalpur.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/Hyderabad.png" class="img-fluid">
                    </div>
                </li>
                <li class="item" data-aos="zoom-in" data-aos-delay="300">
                    <div class="imgcard">
                        <img src="assets/img/Bengaluru.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/Chennai.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/Vishakhapatnam.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/Vadodara-map.png" class="img-fluid">
                    </div>
                </li>
                <li class="item" data-aos="zoom-in" data-aos-delay="400">
                    <div class="imgcard">
                        <img src="assets/img/Lucknow.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/madhyapradesh.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/delhi.png" class="img-fluid">
                    </div>
                </li>
                <li class="item" data-aos="zoom-in" data-aos-delay="500">
                    <div class="imgcard">
                        <img src="assets/img/upradesh.png" class="img-fluid">
                    </div>
                    <div class="imgcard">
                        <img src="assets/img/gujrat.png" class="img-fluid">
                    </div>
                </li>
            </ul>
        </div>

    </section>
    <section>
        <div class="mrtop35 container">
            <div class="flex_centercentent all_main_heading txt_blue" data-aos="fade-up">
                <h2>Client Success</h2>
                <p>We Help Businesses Succeed</p>
            </div>
            <div class="ourclientouter mrtop35">
                <ng-image-slider [images]="imageObject" [infinite]="sliderInfinite" [imagePopup]="sliderImagePopup"
                    [showArrow]="sliderArrowShow" [autoSlide]="sliderAutoSlide ? 1 : 0" [slideImage]="sliderSlideImage"
                    [animationSpeed]="sliderAnimationSpeed" [manageImageRatio]="false" [videoAutoPlay]="false"
                    [showVideoControls]="false" [paginationShow]="true" [orderType]="slideOrderType" #nav>
                </ng-image-slider>
            </div>
        </div>
        <div class="mrtop35">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="col-md-5" data-aos="fade-right" data-aos-delay="100">
                        <ul class="intrvwLI1">
                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                Avon Meters was established in the year 1995 and commenced production of
                                Electro-Mechanical
                                counter type energy meters being supplied to home and neighbouring states with capacity
                                of
                                about
                                0.25 Million Meters initially.
                            </li>

                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                We also created additional infrastructures and facilities to manufacture static meters
                                and
                                taken
                                up production of Static Meters from the year 2001.
                            </li>
                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                One of the largest company in the field of metering technology in India.
                            </li>
                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                Capacity to produce more than 6 millions meters per annum.
                            </li>
                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                Equipped with state of the art machines and testing equipments.
                            </li>
                            <li> <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                In-house R&D facilities adheres to NABL & DSIR Recognition.
                            </li>
                        </ul>
                        <!--<div class="socialiconfooter">
                            <ul>
                                <li><a href="https://www.facebook.com/AvonMetersPvtLtd/" target="_blank"><i
                                            class="fa fa-facebook-f"></i></a></li>
                                <li><a href="https://in.linkedin.com/company/avon-meters-pvt-ltd" target="_blank"><i
                                            class="fa fa-linkedin"></i></a></li>
                                <li><a href="https://twitter.com/share?url=https://www.indiamart.com/avon-meters-limited/"
                                        target="_blank"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/avon_meters/?hl=en" target="_blank"><i
                                            class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>-->
                    </div>
                    <div class="col-md-7" data-aos="fade-left" data-aos-delay="200">
                        <div class="mapavon">
                            <img src="assets/img/mapavon.png" class="img-fluid">
                        </div>
                        <div class="countermap">
                            <div class="meters">
                                <h2 class="counter" data-aos="zoom-out" data-aos-delay="100">More Than 25 millions</h2>
                                <p>Meters Sold</p>
                            </div>
                            <div class="meters">
                                <h2 class="counter" data-aos="zoom-out" data-aos-delay="200">More Than 21+</h2>
                                <p>State</p>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </section>
    <div id="contactus" class="mrtop35">
        <div class="container flex_centercentent all_main_heading txt_blue" data-aos="fade-up">
            <h2>Let's get in touch</h2>
            <p>We are headquartered at our facility in Derabassi (Punjab). We have multiple regional offices to
                assist our customers and serve them with high quality service level agreements.</p>
        </div>
        <div class="cstm">
            <div class="bgcontactus letssectionimg">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 ml-auto contctflx" data-aos="fade-right" data-aos-delay="100">
                            <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()" style=width:100%;>
                                <div class="row">
                                    <div class="col form-group">
                                        <input class="form-control" matInput placeholder="First Name*" name="firstName"
                                            formControlName="firstName"
                                            [ngClass]="{ 'is-invalid': submitted && aF.firstName.errors}" required>
                                        <span class="txtwhiteerror invalid-feedback"
                                            *ngIf="aF.firstName.hasError('required') && (aF.firstName.dirty || aF.firstName.touched)">
                                            Visitor First Name is required
                                        </span>
                                    </div>
                                    <div class="col form-group">
                                        <input class="form-control" matInput placeholder="Last Name*" name="lastName"
                                            formControlName="lastName">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col form-group">
                                        <input class="form-control" matInput placeholder="Email ID" name="emailId"
                                            formControlName="emailId"
                                            [ngClass]="{ 'is-invalid': submitted && aF.emailId.errors}" required>
                                        <span class="invalid-feedback txtwhiteerror"
                                            *ngIf="aF.emailId.hasError('required') && (aF.emailId.dirty || aF.emailId.touched)">
                                            E-mail is required
                                        </span>
                                        <span class="invalid-feedback txtwhiteerror"
                                            *ngIf="aF.emailId.hasError('email') && (aF.emailId.dirty || aF.emailId.touched)">
                                            Please enter a valid E-mail address
                                        </span>
                                    </div>
                                    <div class="col form-group">
                                        <input class="form-control" matInput placeholder="Mobile No.*" [maxLength]="10"
                                            name="phoneNo" formControlName="phoneNo"
                                            [ngClass]="{ 'is-invalid': submitted && aF.phoneNo.errors}" required>
                                        <span class="invalid-feedback txtwhiteerror"
                                            *ngIf="aF.phoneNo.hasError('required') && (aF.phoneNo.dirty || aF.phoneNo.touched)">
                                            Mobile No is required
                                        </span>

                                        <span class="invalid-feedback txtwhiteerror"
                                            *ngIf="aF.phoneNo.hasError('pattern') && (aF.phoneNo.dirty || aF.phoneNo.touched)">
                                            Visitor Mobile number not valid.
                                        </span>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <textarea class="form-control" rows="4" [maxLength]="160" matInput
                                            placeholder="Message (1 to 160  Charactors)" name="query"
                                            formControlName="query"
                                            [ngClass]="{ 'is-invalid': submitted && aF.query.errors}"
                                            required></textarea>
                                        <span class="invalid-feedback txtwhiteerror"
                                            *ngIf="aF.query.hasError('required') && (aF.query.dirty || aF.query.touched)">
                                            Message is required
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="lognbtn bgbtncontact" [disabled]="AddProcess" type="submit">
                                            SEND ENQUIRY
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-sm-12 text-left">
                                        <ng-container *ngIf="isLoading">
                                            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                            </fa-icon>
                                        </ng-container>
                                        <div class="success" *ngIf="displayMsg && responseMessage.length > 0">
                                            {{responseMessage}}
                                        </div>
                                        <div class="error" *ngIf="displayMsg && responseMessage.length <= 0">
                                            Please try after some time.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">

                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-md-5 col-sm-12 ml-auto" data-aos="fade-left" data-aos-delay="100">
                            <img src="assets/img/contact-us-img.png" class="img-fluid contactusimg">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    
    <section class="map_sec">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="map_inner">
                        <h4>Find Us on Google Map</h4>
                        <p>Discover our location with ease using Google Maps. At Avon Meters private Limited, we strive to provide you with the best services and products in Derabassi. Whether you're a local resident or visiting from out of town, finding us has never been simpler.</p>
                        <div class="map_bind">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3433.9997422013507!2d76.84972721460757!3d30.605770498792495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f956f227c0cfb%3A0xcc29685ab7b3b17c!2sAvon%20Meters%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1639820428843!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  
    <div id="policy" class="mrtop35">
        <div class="container flex_centercentent all_main_heading txt_blue">
            <div data-aos="fade-up">
                <!-- <h2>Privacy Policy</h2> -->
                <h2 class="h2 bigav"> Privacy Policy of Avon Meters Pvt. Ltd. </h2>
            </div>
            <div data-aos="fade-up">
                <h2 class="h3 subpolicy">Website Privacy Policy</h2>
                <!--<img src="images/at.net.crp.jpg" align="right">-->
                <p class="p"><b>Avon Meters Pvt. Ltd. </b> website privacy policy explains data collection, its Uses,
                    disclosure and information's for users who use our Website.
                    We care about and respects the privacy of our users. </p>
                <p class="p">The Policy should be read carefully before using website. We request that you not to access
                    the
                    web, if you don’t agree with the policy terms.
                    Avon Meters has reserve the right to make changes in the Policy for any reason and at any
                    point of time. You are encouraged to periodically review the Policy.
                </p>
            </div>
            <div data-aos="fade-up">
                <h2 class="h3">User's Information Collection - </h2>
                <p class="p">We may collect your information in a variety of ways. Information we may collect via
                    the web depends on the content and materials you use includes:
                </p>
                <h3 class="h4">Personal Data -</h3>
                <p class="p">Demographic and other personally identifiable information (such as name, contact number and
                    email address etc.)
                </p>

                <h3 class="h4">Derivative Data -</h3>
                <p class="p">Information our servers automatically collect when you access the web
                </p>

                <h3 class="h4">Financial Data -</h3>
                <p class="p">Data related to your payment method (e.g. valid credit card number, card brand, expiration
                    date) that we may collect when you purchase, order, return, exchange, or request information about
                    our services from the web. We store only very limited, if any, financial information that we collect.
                    Otherwise, all financial information is/might be stored by our payment gateway partners.
                </p>

                <h3 class="h4">Geo-Location Data -</h3>
                <p class="p">To provide location-based services, we may request access to or permission to track
                    location-based information.
                </p>

                <h3 class="h4">Device Access -</h3>
                <p class="p">Your system may be requested to access for permission. Device information such as MAC ID
                    number, make, model, operating system, country, location, and any other data may also be accessed.

                </p>
                <h3 class="h4"> Push Notifications – </h3>
                <p class="p">Regarding your account or application, you may be asked to send push notifications.
                </p>
                <h3 class="h4"> Data From Contests, Giveaways, and Surveys - </h3>
                <p class="p">Personal and other information you may provide when entering contests or giveaways and/or
                    responding to inquiries.
                </p>
                <h3 class="h4"> Third-Party Data - </h3>
                <p class="p">If you connect your account to the third party and grant the Application permission to
                    access this information.
                </p>
            </div>
            <!--<img src="images/Corp/Sales-Net.jpg" align="center" >-->
            <!-- <div data-aos="fade-up">
                <h2 class="h3"> Collected information Uses :- </h2>
                <p class="p">Your collected information may be used to -</p>

                <ul class="ul collectedinfo">
                    <li>
                        <b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>C
                        </b>reate and manage your account.
                    </li>
                    <li>
                        <b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>E
                        </b>mail you regarding your account or order.
                    </li>
                    <li>
                        <b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>S
                        </b>end you a newsletter.
                    </li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P
                        </b>rocess payments and refunds.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>I
                        </b>ncrease the efficiency and operation.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>D
                        </b>erive analytics from your load profile.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R
                        </b>esolve disputes and troubleshoot problems.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R
                        </b>espond to product and customer service requests.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>A
                        </b>dminister sweepstakes, promotions, and contests.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>C
                        </b>ompile anonymous statistical data and analysis.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>D
                        </b>elivering you advertisements, coupons, newsletters, and other promotional information.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>F
                        </b>ulfill and manage purchases, orders, payments, and other transactions.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>T
                        </b>o Generate your personal profile for application.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>M
                        </b>onitor and analyze usage and trends to improve your experience.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>N
                        </b>otify you for updates.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>O
                        </b>ffer you new products, services, and/or other recommendations.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P
                        </b>erform other business activities as needed.</li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>P
                        </b>revent fraudulent transactions, monitor against theft, and protect against criminal
                        activity.
                    </li>
                    <li><b>
                            <mat-icon matPrefix class="d-c8 icon">fiber_manual_record</mat-icon>R
                        </b>equest feedback
                </ul>
            </div> -->
            <div data-aos="fade-up">
                <h3 class="h4">Information Disclosure – </h3>
                <p class="p"><b>Avon Meters Pvt. Ltd. </b> may share/disclose your collected information in certain
                    situations :
                </p>
                <h3 class="h4">By Law or to Protect Rights - </h3>
                <p class="p">If release of your information is necessary to respond to legal process, to investigate or
                    remedy potential violations of our policies or to protect the rights, property, and safety of
                    others, we may share your information as permitted or required by any applicable law, rule, or
                    regulation. This includes exchanging information with other entities for fraud protection and credit
                    risk reduction.
                </p>
                <h3 class="h4">Third-Party Service Providers - </h3>
                <p class="p">We may share your information with third parties that perform services for us or on our
                    behalf, including payment processing, data analysis, email delivery, hosting services, customer
                    service, and marketing assistance.
                </p>
                <h3 class="h4">Marketing Communications - </h3>
                <p class="p">With your consent or with an opportunity for you to withdraw consent, we may share your
                    information with third parties for marketing purposes, as permitted by law.
                </p>
                <h3 class="h4">Third-Party Advertisers - </h3>
                <p class="p">We may use third-party advertising companies to serve ads when you visit the Web. These
                    companies may use information about your visits to the web and other websites that are contained in
                    web cookies in order to provide advertisements about goods and services of interest to you.
                </p>
                <h3 class="h4">Affiliates - </h3>
                <p class="p">We may share your information with our affiliates, in which case we will require those
                    affiliates to honor this Privacy Policy. Affiliates include any subsidiaries, joint venture partners
                    or other companies that we control or that are under common control with us.
                </p>
                <h3 class="h4">Business Partners - </h3>
                <p class="p">We may share your information with our business partners to offer you certain products,
                    services or promotions.
                </p>
                <h3 class="h4">Other Third Parties - </h3>
                <p class="p">We may share your information with advertisers and investors for the purpose of conducting
                    general business analysis. We may also share your information with such third parties for marketing
                    purposes, as permitted by law.
                </p>
                <h3 class="h4">Sale or Bankruptcy - </h3>
                <p class="p">If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired
                    by another entity, we may transfer your information to the successor entity. If we go out of
                    business or enter bankruptcy, your information would be an asset transferred or acquired by a third
                    party. You acknowledge that such transfers may occur and that the transferee may decline honor
                    commitments we made in this Privacy Policy.
                </p>
                <p class="p">We are not responsible for the actions of third parties with whom you share personal or
                    sensitive data, and we have no authority to manage or control third-party solicitations. If you no
                    longer wish to receive correspondence, emails or other communications from third parties, you are
                    responsible for contacting the third party directly.
                </p>
            </div>
            <!-- <div data-aos="fade-up">
                <h2 class="h3 subpolicy">Technology Tracking</h2>

                <h3 class="h4">Cookies and Web Beacon - </h3>
                <p class="p">We may use cookies, web beacons, tracking pixels, and other tracking technologies on the
                    web to help customize the web and improve your experience.
                </p>
                <h3 class="h4">Internet-Based Advertising - </h3>
                <p class="p">We may use third-party software to serve ads on the web, implement email marketing
                    campaigns, and manage other interactive marketing initiatives. This third-party software may use
                    cookies or similar tracking technology to help manage and optimize your online experience with us.
                </p>
                <h3 class="h4">Third Party Services - </h3>
                <p class="p">Website may contain links to third-party applications of interest, including advertisements
                    and external services, that are not affiliated with us. Once you have used these links to leave the
                    web, any information you provide to these third parties is not covered by this Privacy Policy, and
                    we cannot guarantee the safety and privacy of your information. Before visiting and providing any
                    information to any third-party websites, you should inform yourself of the privacy policies and
                    practices (if any) of the third party responsible for that website, and should take those steps
                    necessary to, in your discretion, protect the privacy of your information. We are not responsible
                    for the content or privacy and security practices and policies of any third parties, including other
                    sites, services or website that may be linked to or from the website.
                </p>
                <h3 class="h4">Information Security - </h3>
                <p class="p">We use administrative, technical, and physical security measures to help protect your
                    personal information. While we have taken reasonable steps to secure the personal information you
                    provide to us, please be aware that despite our efforts, no security measures are perfect or
                    impenetrable, and no method of data transmission can be guaranteed against any interception or other
                    type of misuse. Any information disclosed online is vulnerable to interception and misuse by
                    unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal
                    information.
                </p>
            </div> -->
        </div>

        <div class="bgfootercurve">
            <div class="container mt-3">
                <div class="footerusefullink">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="logo"><img src="assets/img/avonmeters-logo.png" class="cstm_logo"></div>
                            <div class="mrouterfootr">
                                <p class="footrheading">The company
                                    provides Energy Metering Solutions to the States Electricity Boards with its product
                                    range of ISI Marked High Precision Electromechanical, Electronic & Smart Energy
                                    Meters.
                                </p>
                                <div class="hqname">
                                    <!-- <h2 class="txt_white text-centlefter mb-4">Derabassi (Headquarters)</h2> -->
                                </div>
                                <div class="hqinfo">
                                    <div class="hqinfoaddress">
                                        <div class="iconcontact">
                                            <i class="fa fa-map-marker"></i>
                                            <p>D-15 Industrial Focal Point, Dera
                                                Bassi-140507 (Pb.) India</p>
                                        </div>
                                    </div>
                                    <div class="hqinfoaddress">
                                        <div class="iconcontact">
                                            <i class="fa fa-phone"></i>
                                            <p>+91-1762-281200</p>
                                        </div>
                                    </div>
                                    <!-- <div class="hqinfoaddress">
                                        <div class="iconcontact">
                                            <i class="fa fa-fax"></i>
                                            <p>+91-1762-281200</p>
                                        </div>
                                    </div> -->
                                    <div class="hqinfoaddress">
                                        <div class="iconcontact">
                                            <i class="fa fa-envelope"></i>
                                            <p><a href="avonmeterprojects@gmail.com"></a>avonmeterprojects@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mrouterfootr">
                                <h2 class="txt_white text-centlefter mb-3">Useful Link </h2>
                                <ul>
                                    <li (click)="scrollTo('#about', -80)"><a class="smooth-scroll"
                                            routerLink="#about"><i class="fa fa-chevron-right"></i>About Us</a></li>
                                    <li (click)="scrollTo('#products', -80)"><a class="smooth-scroll"
                                            routerLink="#products"><i class="fa fa-chevron-right"></i>Products</a></li>
                                    <li (click)="scrollTo('#gallery', -80)"><a class="smooth-scroll"
                                            routerLink="#gallery"><i class="fa fa-chevron-right"></i>Gallery</a></li>
                                    <li (click)="scrollTo('#careers', -80)"> <a class="smooth-scroll"
                                            routerLink="#careers"><i class="fa fa-chevron-right"></i>Careers</a></li>
                                    <li (click)="scrollTo('#contactus', -80)">

                                        <a class="smooth-scroll" routerLink="#contactus"><i
                                                class="fa fa-chevron-right"></i>Contact Us </a>
                                    </li>
                                    <li><a (click)="openLoginModel()" class="pointer-link"><i
                                                class="fa fa-chevron-right"></i>Login</a></li>
                                    <!--<li><a href=""><i class="fa fa-chevron-right"></i>Our Facilities </a></li>
                                    <li><a href=""><i class="fa fa-chevron-right"></i>Our Capability</a></li>
                                    <li><a href=""><i class="fa fa-chevron-right"></i>Quality Assurance</a></li>-->
                                    <!--<li><a href=""><i class="fa fa-chevron-right"></i>Careers</a></li>-->
                                    <li (click)="scrollTo('#policy', -80)"><a class="smooth-scroll"
                                            routerLink="#policy"><i class="fa fa-chevron-right"></i>Privacy Policy </a>
                                    </li>
                                    <!--<li><a routerLink="#"><i class="fa fa-chevron-right"></i>Refund Policy</a></li>
                                    <li><a href=""><i class="fa fa-chevron-right"></i>Terms & Conditions</a></li>-->
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mrouterfootr">
                                <h2 class="txt_white text-centlefter mb-3">Customers Feedback Appreciative</h2>
                                <p class="footrheading">More than 15K+ resolved inqueries and happy customer
                                    reviews.<br>
                                    We give more than 100% to each Project while serving our client’s requirements.<br>
                                    Have a good idea or improvment? It can be ended up in our updates! Please share your
                                    feedback <a href="mailto:avonmeterprojects@gmail.com"
                                        target="_blank"><u>here</u></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<img src="https://cdn.dribbble.com/users/1106178/screenshots/4175222/orb.gif" alt=""  width="250" />-->

    <section>
        <p class="copyrightwebsite">© All Rights Reserved 2021. <b>Avon Meters Pvt. Ltd</b></p>
    </section>
    <section>
        <div class="scrollup" (click)="scrollTo('#carouselExampleIndicators', -80)"><i class="fa fa-angle-double-up"
                aria-hidden="true"></i>
        </div>
    </section>
</div>