<div class="container-fluid innerpage_top page-content-wrapper">
    <div class="content-viewport">
        <div class="page-content-wrapper-inner outerbox_shadow">

            <div class="row">
                <div class="col-md-5 col-sm-12">
                    <h1>Background Work
                        <span class="info_icntop ml-1">
                            <i data-toggle="tooltip" data-placement="top" title="Background Work"
                                class="fa fa-info tabula-tooltip "></i>
                        </span>
                    </h1>
                </div>



                <div class="container-fluid inner-page-content">
                    <!-- <mat-card-header class="h-16 xs-c-h">
                    <div class="title hidden-sm xs-dn" col-3>
                      <mat-icon matPrefix class="d-c12">dns</mat-icon><b>Old/New Meter Details</b>
                    </div>
                    
                        <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-5>
                          <div *ngIf="length!=0">
                          <mat-form-field class="dcuFilter" floatLabel="never">
                            <span matSuffix>
                              <mat-icon matSuffix class="d-c9">search</mat-icon>
                            </span>
                            <input type="text" matInput placeholder="Search by Command Code/Command Name/Date" value="{{levelSearch}}"
                              (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                          </mat-form-field>
                        </div>
                    </div>
                    <span class="toolbar-space"></span>
                    <div col-3 col-xs-6>
                      <div *ngIf="length!=0">
                        <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                          style="color: #000;" class="npMi">
                          <mat-icon class="d-c10">filter_list</mat-icon>
                        </button>
                        <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                          <mat-list role="list" *ngFor="let item of levelHeadsCols">
                            <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                              <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                                {{item.label}}</mat-checkbox>
                            </mat-list-item>
                          </mat-list>
                        </mat-menu>
                      </div>
                    </div>
                    <div col-3 col-xs-1>
                     
                    </div>
                  </mat-card-header> -->
                    <div class="col-12">
                        <div class="table-responsivee table-responsive_fixed">
                            <table id="ExampleMaterialTable" class="table-bordered table-sm table-hover">
                                <tr>
                                    <th class="thClass">Sr. No.</th>
                                    <th class="thClass">Task Name</th>
                                    <th class="thClass">Last Execution D/T</th>
                                    <th class="thClass">Download Format</th>
                                    <th class="thClass">Upload Format</th>
                                </tr>
                                <tr>
                                    <td>
                                        1.
                                    </td>
                                    <td>
                                        Get Bulk Meter IP Address
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('GetBulkMeterIPAddress')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2.
                                    </td>
                                    <td>
                                        Add Meters Without APP
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('AddMetersWithoutAPP')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3.
                                    </td>
                                    <td>
                                        Removed Meters / Inactive Meters
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                   
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('RemovedInactiveMeters')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4.
                                    </td>
                                    <td>
                                        Update IP On Local/HES
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('UpdateIPOnLocalHES')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5.
                                    </td>
                                    <td>
                                        SIM Activation
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('SIMActivation')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6.
                                    </td>
                                    <td>
                                        Upload Meters Without SIM Activation
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('UploadMetersWOutSIMActivate')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        7.
                                    </td>
                                    <td>
                                        Upload Meters With SIM Activation
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                   
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('UploadMetersWithSIMActivation')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        8.
                                    </td>
                                    <td>
                                        Upload PSPCL Portal Data
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('UploadPSPCLPortalData')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        9.
                                    </td>
                                    <td>
                                        Get Bulk IP Address
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>

                                    
                                    <td>

                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('GetIPAddress')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>

                                    </td>
                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        10.
                                    </td>
                                    <td>
                                        Upload Airtel SIM Inventory
                                    </td>
                                    <td>
                                        2024-02-05 18:16
                                    </td>
                                   
                                    <td>
                                        <button data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Format"
                                            class="btn_outlineaction" type="button" (click)="addAmiMeters('UploadAirtelSIMInv')">
                                            <span><i class="fa fa-upload"></i></span>
                                        </button>
                                    </td>

                                    <td>
                                        <div
                                            style="align-items: center;  border-radius:8px; background-color: #f3041b;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Pending
                                        </div>
                                        <!--<div style="WIDTH: 72PX;  border-radius:8px; background-color:#23b143;color:white;box-shadow: 0 2px 5px 1px rgb(128 119 135 / 75%);">
                                            Done
                                        </div>-->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>