import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-checkinstallmeterdata',
  templateUrl: './checkinstallmeterdata.component.html',
  styleUrls: ['./checkinstallmeterdata.component.scss']
})
export class CheckinstallmeterdataComponent implements OnInit {


  loading: boolean = false; 

  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  meterDetail: any;
  checkBox: any = "N";
  colorValue: any ="color: red";
  assignedData = {};
  authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);
  isLoading: boolean = false;
  thumb: boolean = false;

  authLevel_sel: any = '';
  submitted: boolean = false;
  dataSource:any = new MatTableDataSource([]);

  public authLevelData: any = [];
  public countryLevelData: any = [];
  public stateLevelData: any = [];
  public utilityLevelData: any = [];
  public circleLevelData: any = [];
  public divisionLevelData: any = [];
  public subdivisionLevelData: any = [];
  public dcuLevelData: any = [];

  ///multiselect

  date: any;
  constructor(
      private formBuilder: UntypedFormBuilder,
      private modalService: NgbModal,
      public modal: NgbActiveModal,
      private genReqs: generalRequestsService,
      private authenticationService: AuthenticationService,
      private confirmationDialogService: ConfirmationDialogService
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
  }


  ngOnInit() {
      let MOBILE_PATTERN = /[0-9\+\-\ ]/;
      /*
         form authority add
       */
      this.addAuthorityForm = this.formBuilder.group({
          ivrs: [{value: '', disabled: true}, Validators.required],
          oldMeterId: [{value: '', disabled: true}, Validators.required],
          oldMeterReading: ['', Validators.required],
          oldMeterImgCheckedStatus: ['', Validators.required],
          oldMeterStatus: ['', Validators.required],
          oldMeterRemark: [''],
        
          newMeterId: [{value: '', disabled: true}, Validators.required],
          newmterIPAddress: [{value: '', disabled: true}, Validators.required],
          newMeterReading: ['', Validators.required],
          newMeterRemark: [''],
          newMeterLat: ['', Validators.required],
          newMeterLong: ['', Validators.required],
          newMeterImgCheckedStatus: ['', Validators.required],
          newMeterStatus: ['', Validators.required],
          tdMeterStatus: ['', Validators.required],

          checkBox: [false, Validators.required],

          mcoCheckedStatus: ['', Validators.required],
          mcoStatus: ['', Validators.required],
          mcoRemark: [''],

          //dataArray: ['']
      });

      this.preSetUserData();
  }

  ngAfterViewInit() {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });

  }

  onChange(ob: MatCheckboxChange){
    if(ob.checked){
      this.checkBox="Y";
    }else{
      this.checkBox="N";
    }
  }

  ngOnDestroy() {
      //this.modal.close();
  }

  /*
  add authority
  require authority data
*/
  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAddAuthority() {
    
    if (this.aD.tdMeterStatus.value=="N") {
      this.confirmationDialogService.confirm('Action..', 'Please select Action before submit.')
      .then((confirmed) => {
          if(confirmed) {
            return;
          }
        })
        .catch(() => console.log('no response or closed'));
      return;
    }

    if (this.checkBox=="N") {
      this.confirmationDialogService.confirm('Check Box..', 'Please select the checkbox before submit.')
      .then((confirmed) => {
          if(confirmed) {
            return;
          }
        })
        .catch(() => console.log('no response or closed'));
      return;
    }
      if (this.addAuthorityForm.invalid) {
          this.submitted = true;
          return;
      }

      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to submit ?')
      .then((confirmed) => {
          if(confirmed) {
              this.isLoading = true;
              this.AddProcess = true;
              let body = new URLSearchParams();
              body.set('zoneId', this.meterDetail.zoneId);
              body.set('circleId', this.meterDetail.circleId);
              body.set('oldId', this.meterDetail.oldUid);
              body.set('newId', this.meterDetail.newMeterUid);
              body.set('oldIvrs', this.meterDetail.ivrsNo);
              body.set('oldNewMeterId', this.meterDetail.newMeterId);
              body.set('meterType', this.meterDetail.meterType);
              body.set('meterMake', this.meterDetail.meterMake);
              body.set('ivrs', this.aD.ivrs.value);
              body.set('oldMeterId', this.aD.oldMeterId.value);
              body.set('oldMeterReading', this.aD.oldMeterReading.value);
              body.set('checkBox', this.checkBox);
              body.set('oldMeterRemark', this.aD.oldMeterRemark.value);
              
              body.set('oldMeterImgCheckStatus', this.aD.oldMeterImgCheckedStatus.value);
              body.set('oldMeterStatus', this.aD.oldMeterStatus.value);
              body.set('newMeterId', this.aD.newMeterId.value);
              body.set('newmterIPAddress', this.aD.newmterIPAddress.value);
              body.set('newMeterReading', this.aD.newMeterReading.value);
              body.set('newMeterRemark', this.aD.newMeterRemark.value);
              body.set('newMeterLat', this.aD.newMeterLat.value);
              body.set('newMeterLong', this.aD.newMeterLong.value);
              body.set('newMeterImgCheckStatus', this.aD.newMeterImgCheckedStatus.value);
              body.set('newMeterStatus', this.aD.newMeterStatus.value);
              body.set('tdMeterStatus', this.aD.tdMeterStatus.value);

              body.set('mcoCheckedStatus', this.aD.mcoCheckedStatus.value);
              body.set('mcoStatus', this.aD.mcoStatus.value);
              body.set('mcoRemark', this.aD.mcoRemark.value);

              body.set('authToken', this.currentUser.authToken);
              this.genReqs.postReq('/updateOldNewInfoPnb', body).subscribe((result) => {
                  this.responseData = result;
                  this.AddProcess = false;
                  this.isLoading = false;
                  if (this.responseData.success) {
                      this.thumb = true;
                      this.addAuthMessage = this.responseData.data.message;
                      //window.open("http://localhost:4200/#/oldNewMeter","_self");
                      setTimeout(() => 
                      {
                      this.modal.close('done');
                      },
                      2000);
                  } else {
                      this.thumb = false;
                  }
              }, (err) => {
                  if (err.status === 401) {
                      this.authenticationService.logout();
                  }
                  this.addAuthMessage = 'Unable to process yours request!';
              });
          }
      })
      .catch(() => console.log('no response or closed'));
  }
      
  getOldMeterData(){
      this.loading = true;
      let body = new URLSearchParams();
      body.set('zoneId', this.meterDetail.zoneId);
      body.set('circleId', this.meterDetail.circleId);
      body.set('oldId', this.meterDetail.oldUid);
      body.set('newId', this.meterDetail.newMeterUid);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getOldMeterDataPnb', body).subscribe((result)=>{
        this.responseData = result;
        this.loading = false;
        //console.log(this.responseData);
        if(this.responseData.success){
          //console.log(this.responseData.data);
          setTimeout(() => {
              this.dataSource = this.responseData.data;
              this.aD.oldMeterId.setValue(this.dataSource.oldMeterId);
              this.aD.oldMeterReading.setValue(this.dataSource.oldMeterReading);
              this.aD.oldMeterRemark.setValue(this.dataSource.oldMeterRemark);
              this.aD.oldMeterImgCheckedStatus.setValue(this.dataSource.checkedStatus);
              this.aD.oldMeterStatus.setValue(this.dataSource.oldDataStatus);
           });
        }else{
          this.addAuthMessage = this.responseData.message;//this.responseData.isresponse;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loading = false;
        this.addAuthMessage = 'Unable to process yours request!';
      });
  }

  preSetUserData() {
      this.getOldMeterData();

      this.aD.ivrs.setValue(this.meterDetail.ivrsNo);
     
      
      this.aD.newMeterId.setValue(this.meterDetail.newMeterId);
      this.aD.newmterIPAddress.setValue(this.meterDetail.newmterIPAddress);
      this.aD.newMeterReading.setValue(this.meterDetail.newMeterReading);
      this.aD.newMeterRemark.setValue(this.meterDetail.newMeterRemark);
      this.aD.newMeterLat.setValue(this.meterDetail.newMeterLat);
      this.aD.newMeterLong.setValue(this.meterDetail.newMeterLong);
      this.aD.newMeterImgCheckedStatus.setValue(this.meterDetail.newCheckedStatus);
      this.aD.newMeterStatus.setValue(this.meterDetail.newDataStatus);
      this.aD.tdMeterStatus.setValue(this.meterDetail.tdMeterStatus);

      this.aD.mcoCheckedStatus.setValue(this.meterDetail.mcoCheckedStatus);
      this.aD.mcoStatus.setValue(this.meterDetail.mcoStatus);
      this.aD.mcoRemark.setValue(this.meterDetail.mcoRemark);
  }
  //default DCU

}
