import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editterill',
  templateUrl: './editterill.component.html',
  styleUrls: ['./editterill.component.scss']
})
export class EditterillComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  message:string = '';
  responseData: any;
  teriffData: any;
  AddProcess:boolean = false;
  subDivisionId: any = 0;
  submitted: boolean = false;
  dedType: any = [
    {'id':0, 'Name':'Daily'},
    {'id':1, 'Name':'Monthly'}
  ];

  activeStatus: any = [
    {'id':'N', 'Name':'In-Active'},
    {'id':'Y', 'Name':'Active'}
  ];
  isLoading = false;
  thumb = true;
  loadingMessage: any;
  groupData: any = [];

  addAuthMessage:string = '';


  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
    }

    ngOnInit() {
      this.addForm = this.formBuilder.group({
        teriffGroup: ['', Validators.required],
        alarmLevel: ['', Validators.required],
        emergencyCredit: ['', Validators.required],
        deductionType: ['', Validators.required],
        eBFlatUnitRate: ['', Validators.required],
        dGUnitRate: ['', Validators.required],
        fixedChargeGrid: ['', Validators.required],
        fixedChargeDG: ['', Validators.required],
        vendingCharge: ['', Validators.required],
        commonAreaMaintCharge: ['', Validators.required],
        gridMaintCharge: ['', Validators.required],
        dgMaintCharge: ['', Validators.required],
        clubCharge: ['', Validators.required],
        waterCharge: ['', Validators.required],
        otherCharge: ['', Validators.required],
        serviceTax: ['', Validators.required],
        teriffActiveStatus: ['', Validators.required],
       /* energyRatekWHEB: ['', Validators.required],
        permissibleEbkWHRate: ['', Validators.required],
        electDutyRatekWHEB: ['', Validators.required],
        regulaSurchRatekWHEB: ['', Validators.required],
        totalkWHEBRate: ['', Validators.required],
        energyRatekWHDG: ['', Validators.required],
        gSTkWHDGRate: ['', Validators.required],
        totalDGkWHRate: ['', Validators.required],
        fixedRatekWEB: ['', Validators.required],
        permissibleAdditionkWFixedEBRate: ['', Validators.required],
        electDutyOnFixedEBRate: ['', Validators.required],
        regulaSurchOnFixedEBRate: ['', Validators.required],
        totalFixedChargeKwEBRate: ['', Validators.required],
        mMCkWFixedDGRate: ['', Validators.required],
        gSTOnFixedDGRate: ['', Validators.required],
        fixedChargesOnDGRate: ['', Validators.required],
        cAMRate: ['', Validators.required],
        maintRate: ['', Validators.required],
        clubRate: ['', Validators.required],
        sewerageRate: ['', Validators.required],
        parkingRate: ['', Validators.required],
        waterRate: ['', Validators.required],
        propertyTaxRate: ['', Validators.required],
        insuranceRate: ['', Validators.required],
        serviceRate: ['', Validators.required],
        otherChargeRate: ['', Validators.required],
        gSTChargeRate: ['', Validators.required],
        firstSlabRange: ['', Validators.required],
        firstSlabRate: ['', Validators.required],
        secondSlabRange: ['', Validators.required],
        secondSlabRate: ['', Validators.required],
        thirdSlabRange: ['', Validators.required],
        thirdSlabRate: ['', Validators.required],
        forthSlabRange: ['', Validators.required],
        forthSlabRate: ['', Validators.required],
        fifthSlabRange: ['', Validators.required],
        fifthSlabRate: ['', Validators.required],
        sixthSlabRange: ['', Validators.required],
        sixthSlabRate: ['', Validators.required],
        seventhSlabRange: ['', Validators.required],
        seventhSlabRate: ['', Validators.required],
        teriffActiveStatus: ['', Validators.required]*/
      });
      this.getGroupData();
      this.preSetUserData();
    }
    
    ngAfterViewInit() {
     
    }
    ngOnDestroy() {
      //this.modalService.dismissAll();
    }

    getGroupData() {
      //console.log("data",this.subDivisionId);
      let body = new URLSearchParams();
      body.set('subdivId', this.teriffData.Sub_Division_Unique_Id);
      body.set('groupId', '0');
      body.set('offSet', '0');
      body.set('limit', '1000');
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getGroupData', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          setTimeout(() => {
              this.groupData = this.responseData.data;
               
          });
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loadingMessage = 'Unable to process yours request!';
      });
    }

    get aF() { return this.addForm.controls; }

    preSetUserData() {
      //console.log("authority",this.authority);
      this.aF.teriffGroup.setValue(this.teriffData.TariffGroupName);
      this.aF.alarmLevel.setValue(this.teriffData.AlarmRange);
      this.aF.emergencyCredit.setValue(this.teriffData.EmergencyCreditLimit);
      this.aF.deductionType.setValue(this.teriffData.deductionType);
      this.aF.eBFlatUnitRate.setValue(this.teriffData.TotalGridUnitRate);
      this.aF.dGUnitRate.setValue(this.teriffData.TotalBackupSupplyRate);
      this.aF.fixedChargeGrid.setValue(this.teriffData.TotalFixedRateonGrid );
      this.aF.fixedChargeDG.setValue(this.teriffData.FixedChargesOnBackupSupply);
      this.aF.vendingCharge.setValue(this.teriffData.VendingRate);
      this.aF.commonAreaMaintCharge.setValue(this.teriffData.CAMRate);
      this.aF.gridMaintCharge.setValue(this.teriffData.MaintRate);
      this.aF.dgMaintCharge.setValue(this.teriffData.MaintRateDG);
      this.aF.clubCharge.setValue(this.teriffData.ClubRate);
      this.aF.waterCharge.setValue(this.teriffData.WaterRate);
      this.aF.otherCharge.setValue(this.teriffData.OtherChargeRate);
      this.aF.serviceTax.setValue(this.teriffData.GSTChargeRate);
      this.aF.teriffActiveStatus.setValue(this.teriffData.TeriffActiveStatus);
  }

    onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.isLoading = true;
      //const formData = new FormData();
      let body = new URLSearchParams();
      body.set('tariffUniqueId', this.teriffData.teriffUid);
      body.set('subdivId', this.teriffData.Sub_Division_Unique_Id);
      body.set('teriffGroupId', this.teriffData.groupUId);
      body.set('teriffGroup', this.aF.teriffGroup.value);
      body.set('alarmLevel', this.aF.alarmLevel.value);
      body.set('emergencyCredit', this.aF.emergencyCredit.value);
      body.set('deductionType', this.aF.deductionType.value);
      body.set('eBFlatUnitRate', this.aF.eBFlatUnitRate.value);
      body.set('dGUnitRate', this.aF.dGUnitRate.value);
      body.set('fixedChargeGrid', this.aF.fixedChargeGrid.value);
      body.set('fixedChargeDG', this.aF.fixedChargeDG.value);
      body.set('vendingCharge', this.aF.vendingCharge.value);
      body.set('commonAreaMaintCharge', this.aF.commonAreaMaintCharge.value);
      body.set('gridMaintCharge', this.aF.gridMaintCharge.value);
      body.set('dgMaintCharge', this.aF.dgMaintCharge.value);
      body.set('clubCharge', this.aF.clubCharge.value);
      body.set('waterCharge', this.aF.waterCharge.value);
      body.set('otherCharge', this.aF.otherCharge.value);
      body.set('serviceTax', this.aF.serviceTax.value);
      body.set('teriffActiveStatus', this.aF.teriffActiveStatus.value);
      /*formData.append('energyRatekWHEB', this.aF.energyRatekWHEB.value);
      formData.append('permissibleEbkWHRate', this.aF.permissibleEbkWHRate.value);
      formData.append('electDutyRatekWHEB', this.aF.electDutyRatekWHEB.value);
      formData.append('regulaSurchRatekWHEB', this.aF.regulaSurchRatekWHEB.value);
      formData.append('totalkWHEBRate', this.aF.totalkWHEBRate.value);
      formData.append('energyRatekWHDG', this.aF.energyRatekWHDG.value);
      formData.append('gSTkWHDGRate', this.aF.gSTkWHDGRate.value);
      formData.append('totalDGkWHRate', this.aF.totalDGkWHRate.value);
      formData.append('fixedRatekWEB', this.aF.fixedRatekWEB.value);
      formData.append('permissibleAdditionkWFixedEBRate', this.aF.permissibleAdditionkWFixedEBRate.value);formData.append('electDutyOnFixedEBRate', this.aF.electDutyOnFixedEBRate.value);
      formData.append('regulaSurchOnFixedEBRate', this.aF.regulaSurchOnFixedEBRate.value);
      formData.append('totalFixedChargeKwEBRate', this.aF.totalFixedChargeKwEBRate.value);
      formData.append('mMCkWFixedDGRate', this.aF.mMCkWFixedDGRate.value);
      formData.append('gSTOnFixedDGRate', this.aF.gSTOnFixedDGRate.value);
      formData.append('fixedChargesOnDGRate', this.aF.fixedChargesOnDGRate.value);
      formData.append('cAMRate', this.aF.cAMRate.value);
      formData.append('maintRate', this.aF.maintRate.value);
      formData.append('clubRate', this.aF.clubRate.value);
      formData.append('sewerageRate', this.aF.sewerageRate.value);
      formData.append('parkingRate', this.aF.parkingRate.value);
      formData.append('waterRate', this.aF.waterRate.value);
      formData.append('propertyTaxRate', this.aF.propertyTaxRate.value);
      formData.append('insuranceRate', this.aF.insuranceRate.value);
      formData.append('serviceRate', this.aF.serviceRate.value);
      formData.append('otherChargeRate', this.aF.otherChargeRate.value);
      formData.append('gSTChargeRate', this.aF.gSTChargeRate.value);
      formData.append('firstSlabRange', this.aF.firstSlabRange.value);
      formData.append('firstSlabRate', this.aF.firstSlabRate.value);
      formData.append('secondSlabRange', this.aF.secondSlabRange.value);
      formData.append('secondSlabRate', this.aF.secondSlabRate.value);
      formData.append('thirdSlabRange', this.aF.thirdSlabRange.value);
      formData.append('thirdSlabRate', this.aF.thirdSlabRate.value);
      formData.append('forthSlabRange', this.aF.forthSlabRange.value);
      formData.append('forthSlabRate', this.aF.forthSlabRate.value);
      formData.append('fifthSlabRange', this.aF.fifthSlabRange.value);
      formData.append('fifthSlabRate', this.aF.fifthSlabRate.value);
      formData.append('sixthSlabRange', this.aF.sixthSlabRange.value);
      formData.append('sixthSlabRate', this.aF.sixthSlabRate.value);
      formData.append('seventhSlabRange', this.aF.seventhSlabRange.value);
      formData.append('seventhSlabRate', this.aF.seventhSlabRate.value);
      formData.append('teriffActiveStatus', this.aF.teriffActiveStatus.value);*/
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateTariff', body).subscribe((result)=>{
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.addAuthMessage = this.responseData.data.message;
            setTimeout(() => 
            {
              this.modal.close('done');
            },
            2000);
        }else{
            this.thumb = false;
        }

      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.message = 'Unable to process yours request!';
      });
    }

}
