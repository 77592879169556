import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';

@Component({
  selector: 'app-consumer-complaints',
  templateUrl: './consumer-complaints.component.html',
  styleUrls: ['./consumer-complaints.component.scss']
})
export class ConsumerComplaintsComponent implements OnInit {

  addFormData: UntypedFormGroup;
  responseStatus:boolean = false;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  complaintSubjectData:string;
  complaintMessageData:string;
  allcomplaints = [
    {
      "complaintUniqueId": 0,
      "consFullName": 0,
      "complaintType": null,
      "complaintMessage": "No New Complaints.",
      "complaintStatus": "Under Progress",
      "complaintRevert": "No Revert.",
      "revertDateTime": "2019-11-26 14:13:35",
      "creationDateTime": "2019-11-19 16:31:27"
    }
  ];

  complaintTypeData = [
    {compName: 'Billing Units Issue'},
    {compName: 'Recharge Not Done'},
    {compName: 'About Adjustment'},
    {compName: 'Customer Care Service'}
  ];

  skip = 0;
  limit = 10;
  totalAllNotifications = 0;
  isloadcomplaints:boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
   /* this.addFormData = this.formBuilder.group({
      complaintSubjectData: ['', Validators.required],
      complaintMessageData: ['', Validators.required]
      });*/
    //this.getComplaintTypeData();
    this.getAllNotifications();
  }

  get aF() { return this.addFormData.controls; }
  onSubmitAddForm(){
    if (this.addFormData.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('complaintSubject', this.aF.complaintSubject.value);
    formData.append('consComplaint', this.aF.complaintMessage.value);
    formData.append('consSubDivision', this.currentUser.data.userData.subName);
    formData.append('authToken', this.currentUser.authToken);
    this.AddProcess = true;
  this.genReqs.formDataPost('/addConsumerComplaint', formData).subscribe((result)=>{
    this.responseData = result;
    this.AddProcess = false;
    this.responseMessage = this.responseData.message;
    this.addFormData.reset();
  },(err)=>{
    if (err.status === 401) {
      this.authenticationService.logout();
    }
    this.AddProcess = false;
    this.responseMessage = 'Unable to process yours request!';
  });
  }

   /**to get the meter list on click on Dcu */
   getAllNotifications() {
    let body = new URLSearchParams();
   // console.log(this.currentUser.data.userData);
    body.set('authToken', this.currentUser.authToken);
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('consId', this.currentUser.data.userData.uid);
    body.set('offSet', '0');
    body.set('limit', '10');
    body.set('filter', '');
    this.genReqs.postReq('/getConsComplaint', body).subscribe((result)=>{
      this.responseData = result;
      
      this.isloadcomplaints = true;
      if(this.responseData.success){
        setTimeout(() => {
          
          this.allcomplaints = this.responseData.data;
        });
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getComplaintTypeData() {
    this.responseStatus=false;
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    body.set('subDivName', this.currentUser.data.userData.subName);
    this.genReqs.postReq('/getComplaintType', body).subscribe((result)=>{
      this.responseData = result;
      this.addFormData.reset();
      if(this.responseData.success){
       // console.log( this.responseData.data);
        this.complaintTypeData = this.responseData.data;
        this.responseStatus=true;
        }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

}
