
<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
     Existing Complaints
    </h4>
  </div>
<div class="modal-body">
  <!--<mat-tab-group>-->
    <!--<mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="d-c5">chrome_reader_mode</mat-icon>
       <h5>Add Complaint</h5> 
      </ng-template>-->
      
      
        <!-- Grid row -->
        
          <!-- Default input -->
           <!-- {{complaintTypeData | json}}-->
     <!-- <div [formGroup]="addFormData">
            
        <div class="form-row">
          <mat-form-field class="col-md-12">
            <mat-icon matPrefix class="d-c8">edit</mat-icon>
            <mat-select placeholder="Complaint Subject" name="complaintSubjectData" formControlName="complaintMessageData" *ngIf="complaintTypeData">
              <mat-option *ngFor="let complaintData of complaintTypeData" [value]="complaintData.compName">
                {{complaintData.compName}}
              </mat-option>
            </mat-select>

            
           <mat-error *ngIf="aF.complaintSubjectData.hasError('required')">
              Subject is <strong>required</strong>
            </mat-error>
          </mat-form-field>
            <mat-icon matPrefix class="col-md-1 d-c9">description</mat-icon>
            <mat-form-field class="col-md-11">
              <textarea rows="10" matInput placeholder="Complaint Message" name="complaintMessageData" formControlName="complaintMessageData"></textarea>
              <mat-error *ngIf="aF.complaintMessageData.hasError('required')">
                message is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 text-right">
          <button type="button" (click)="onSubmitAddForm()" mat-button class="dilog-button dilog-button1">
            <mat-icon>add</mat-icon>
            Add Complaint
          </button>
          <button type="button" (click)="modal.close('Ok click')" mat-button class="dilog-button dilog-button1">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>
        </div>
      </div>
    
    </mat-tab>-->
    <!--<mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="d-c6">chrome_reader_mode</mat-icon>
       <h5>Recent Issues</h5> 
      </ng-template>-->
        <div class="form-group">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let item of allcomplaints" >
              <mat-expansion-panel-header class="">
                <mat-panel-title>
                  <mat-icon matPrefix class="d-c9">chrome_reader_mode</mat-icon>&nbsp;
                  <b>{{item.complaintType}}</b>
                </mat-panel-title>
                <mat-panel-description class="text-right1">
                  <div class="col-sm-12 text-right1" style="color:red;">
                  {{item.complaintStatus}}  
                  </div>
                  
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="col-md-12 pull-left setHeight">
                <mat-icon matPrefix class="d-c10">mode_comment</mat-icon>&nbsp;<B>Query: </B>{{item.complaintMessage}}
                <div class="col-sm-12 text-right timeStamp">
                  {{item['creationDateTime']}}
                </div>
              </div>
              <div class="col-md-12 pull-left setHeight" *ngIf="item.complaintRevert.length > 1">
                <mat-icon matPrefix class="d-c11">question_answer</mat-icon>&nbsp;<b>Revert: </b> {{item.complaintRevert}}
                <div class="col-sm-12 text-right timeStamp">{{item['revertDateTime']}}
                </div>
              </div>
              
            </mat-expansion-panel>
          </mat-accordion>
          <!-- Grid row -->
          <div class="clearfix"></div>
          <!-- buttons are listed here-->
        </div>
      <!--</mat-tab>
  </mat-tab-group>-->
</div>