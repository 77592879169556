import { Component, OnInit, ChangeDetectionStrategy, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-editpayment',
  templateUrl: './editpayment.component.html',
  styleUrls: ['./editpayment.component.scss']
})
export class EditpaymentComponent implements OnInit {
  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  contractorPaymentInfo: any;
 
  message: string = '';
  authLevelFieldValue: string = '';

  isLoading: boolean = false;
  thumb: boolean = false;
  ///multiselect
  
  authLevelField: boolean = false;
  nowDate = new Date();
  submitted: boolean = false;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private genReqs: generalRequestsService,
      private authenticationService: AuthenticationService,
      public modal: NgbActiveModal
  ) {

      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      if (this.currentUser.data.userData.authLevel == '7') {
          this.authLevelField = true;
          this.authLevelFieldValue = '7';
      }
  }

  ngOnInit() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
      /*
        form authority add
      */
      this.addAuthorityForm = this.formBuilder.group({
          uid: [''],
          contractorId: [''],
          contractorName:  [{value: '', disabled: true}, Validators.required],
          //singlePhMeters: ['', Validators.required],
          //singlePhMetersAmount: ['', Validators.required],
          threePhMeters:  [{value: '', disabled: true}, Validators.required],
          threePhMetersAmount:  [{value: '', disabled: true}, Validators.required],
          ltctMeters: [{value: '', disabled: true}, Validators.required],
          ltctMetersAmount:  [{value: '', disabled: true}, Validators.required],
          totalAmount:  [{value: '', disabled: true}, Validators.required],
          gstAmount:  [{value: '', disabled: true}, Validators.required],
          netAmount: [{value: '', disabled: true}, Validators.required],
          amountPaid: ['', Validators.required],
          totalAmountPaid:  [{value: '', disabled: true}, Validators.required],
          pendingAmount:  [{value: '', disabled: true}, Validators.required],
      });

      //this.countryLevelData = this.getDynamicList();
      this.preSetUserData();
      
  }
  get aD() { return this.addAuthorityForm.controls; }

  preSetUserData() {
    this.aD.uid.setValue(this.contractorPaymentInfo.aID | 0);
    this.aD.contractorId.setValue(this.contractorPaymentInfo.contractorId | 0);
    this.aD.contractorName.setValue(this.contractorPaymentInfo.contFirmName);
    //this.aD.singlePhMeters.setValue(this.contractorPaymentInfo.authUserName);
    //this.aD.singlePhMetersAmount.setValue(this.contractorPaymentInfo.authPassword);
    this.aD.threePhMeters.setValue(this.contractorPaymentInfo.threePhInstalled);
    this.aD.threePhMetersAmount.setValue(this.contractorPaymentInfo.threePhInstalledAmount);
    this.aD.ltctMeters.setValue(this.contractorPaymentInfo.ltctInstalled);
    this.aD.ltctMetersAmount.setValue(this.contractorPaymentInfo.ltctInstalledAmount);
    this.aD.totalAmount.setValue(this.contractorPaymentInfo.amountWithoutGST);
    this.aD.gstAmount.setValue(this.contractorPaymentInfo.totalGstAmount);
    this.aD.netAmount.setValue(this.contractorPaymentInfo.totalAmount);
    this.aD.amountPaid.setValue('0');
    this.aD.totalAmountPaid.setValue(this.contractorPaymentInfo.amountPaid);
    this.aD.pendingAmount.setValue(this.contractorPaymentInfo.amountPending);
  }

  onSubmitAddAuthority() {
      if (this.addAuthorityForm.invalid) {
            this.submitted = true;
          return;
      }
      this.message = '';
      this.isLoading = true;
      this.AddProcess = true;

      let body = new URLSearchParams();
      body.set('subdivId', this.currentUser.data.userData.subdivision);
      body.set('uid', this.aD.uid.value);
      body.set('conractorName', this.aD.contractorName.value);
      body.set('contractorId', this.aD.contractorId.value);
      //body.set('singlePhMeters', this.aD.singlePhMeters.value);
      //body.set('singlePhMetersAmount', this.aD.singlePhMetersAmount.value);
      body.set('threePhMetersAmount', this.aD.threePhMetersAmount.value);
      body.set('threePhMeters', this.aD.threePhMeters.value);
      body.set('ltctMeters', this.aD.ltctMeters.value);
      body.set('ltctMetersAmount', this.aD.ltctMetersAmount.value);
      body.set('totalAmount', this.aD.totalAmount.value);
      body.set('gstAmount', this.aD.gstAmount.value);
      body.set('netAmount', this.aD.netAmount.value);
      body.set('amountPaid', this.aD.amountPaid.value);
      body.set('totalAmountPaid', this.aD.totalAmountPaid.value);
      body.set('pendingAmount', this.aD.pendingAmount.value);
      body.set('authToken', this.currentUser.authToken);

      this.AddProcess = true;
      this.genReqs.postReq('/updateContractorPayment', body).subscribe((result) => {

          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          this.thumb = false;
          if (this.responseData.success) {
              this.AddProcess = false;
              this.isLoading = false;
              this.thumb = true;
              this.message = this.responseData.data.message;
              setTimeout(() => 
              {
                this.modal.close('done');
              },
              2000);
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.AddProcess = false;
          this.message = 'Unable to process yours request!';
      });
  }
}
