<!----add authority start-->
<form [formGroup]="addConsumerForm" (ngSubmit)="onSubmitAddConsumer()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Modify Permissions:
      {{consFullName}}
    </h4>
  </div>
  <div class="modal-body">
    <div class="">
      <nav class="">
        <div class="navmodifyall nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav_meteraction_view" data-toggle="tab" href="#nav_meteraction"
            role="tab" aria-controls="nav-meteraction" aria-selected="true">
            Meter & Action
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
              title="Information of Utility Admin Report Permission"></i>
          </a>
          <a class="nav-item nav-link" id="nav_rechargereport_view" data-toggle="tab" href="#nav_rechargereport"
            role="tab" aria-controls="nav-rechargereport" aria-selected="true">
            Recharge & Reports
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
              title="Information of  Society Admin Report Permission"></i>
          </a>
          <a class="nav-item nav-link" id="nav_settinghelp_view" data-toggle="tab" href="#nav_settinghelp" role="tab"
            aria-controls="nav-settinghelp" aria-selected="true">
            Settings & Help
            <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
              title="Information of Utility Consumer Report Permission"></i>
          </a>
        </div>
      </nav>
      <div class="tab-content meterload" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav_meteraction" role="tabpanel"
          aria-labelledby="nav_meteraction_view">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c9">
                    phone_android
                  </mat-icon>
                  Active All Permissions
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="with-icon col-sm-4 panel-body">
                <mat-icon class="d-c9">phone_android</mat-icon>
                <mat-slide-toggle formControlName="Active_All_Permission_Access">
                  Active All Permissions
                </mat-slide-toggle>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c6">
                    insert_chart
                  </mat-icon> Consumer Graphical Deshboard
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Society_View">
                    Society View
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Utility_View">
                    Utility View
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Society_kWH_View">
                    Grid kWH Society
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Society_DG_kWH_View">
                    DG kWH Society
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Society_Recharge_View">
                    Recharge Society
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Utility_kWH_View">
                    Grid kWH Utility
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Utility_kVARH_View">
                    Grid kVARH Utility
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Desh_Grid_Utility_kVAH_View">
                    Grid kVAH Utility
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_User_Consumption_Chart">
                    Consumption Chart
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_User_Consumption_Daily">
                    Consumption Daily
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_User_Consumption_Monthly">
                    Consumption Monthly
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_User_Consumption_Yearly">
                    Consumption Yearly
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Power_Cut_Chat">
                    Power Cut Chart
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Power_Cut_Daily">
                    Power Cut Daily
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Power_Cut_Monthly">
                    Power Cut Monthly
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">insert_chart</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Griphical_Power_Cut_Yearly">
                    Power Cut Yearly
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c6">
                    view_list
                  </mat-icon> Consumer Grid Deshboard
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Cons_UId_Column">
                    Cons UId
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_UId_Column">
                    Meter UId
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_DG_Column">
                    DG Id
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_Tower_Column">
                    Tower Id
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_Group_Column">
                    Group Id
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Flat_Id_Column">
                    Flat Id
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_No_Column">
                    Meter No
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_HES_Id_Column">
                    HES Id
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_Ip_Column">
                    Meter Ip
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Meter_Type_Column">
                    Meter Type
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_LL_Grid_kW_Column">
                    LL Grid (kW)
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_LL_DG_kW_Column">
                    LL DG (kW)
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Last_Update_Column">
                    Last Update
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Voltage_R_Column">
                    Voltage-R
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Voltage_Y_Column">
                    Voltage-Y
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Voltage_B_Column">
                    Voltage-B
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Phase_Current_R_Column">
                    PH Current-R
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Phase_Current_Y_Column">
                    PH Current-Y
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Phase_Current_B_Column">
                    PH Current-B
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Nutural_Current_R_Column">
                    Neutral Current
                  </mat-slide-toggle>
                </div>
                <!--<div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Nutural_Current_Y_Column">
                    NU Current-Y
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Nutural_Current_B_Column">
                    NU Current-B
                  </mat-slide-toggle>
                </div>-->
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_PF_R_Column">
                    PF-R
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_PF_Y_Column">
                    PF-Y
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_PF_B_Column">
                    PF-B
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Total_Power_Factor_Column">
                    Total PF
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Frequency_Column">
                    Frequency
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Active_Power_Column">
                    Kw Power
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Reactive_Power_Column">
                    kVAR Power
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_ApperantPower_kVA_Column">
                    kVA Power
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Md_Kw_Column">
                    MD kW
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_Md_kVA_Column">
                    MD kVA
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kWH_Column">
                    Grid kWH
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kVARH_Q1_Column">
                    Grid kVARH Q1
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kVARH_Q2_Column">
                    Grid kVARH Q2
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kVARH_Q3_Column">
                    Grid kVARH Q3
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kVARH_Q4_Column">
                    Grid kVARH Q4
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Grid_kVAH_Column">
                    Grid kVAH
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_DG_kWH_Column">
                    DG kWH
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_DG_kVAH_Column">
                    DG kVAH
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Balance_Column">
                    Balance
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Supply_Column">
                    Supply
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Tamper_Column">
                    Tamper
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Last_LS_DT_Column">
                    Last LS D/T
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Tamper_Count_Column">
                    Tamper Count
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Billing_Count_Column">
                    Billing Count
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Last_Block_LS_DT_Column">
                    Last Block LS D/T
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Relay_Status_Column">
                    Relay Status
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Live_Status_Column">
                    Live Status
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">view_list</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Action_Column">
                    Action
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>


            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c5">
                    settings_remote
                  </mat-icon> Meter's
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">settings_remote</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Real_Time_Status_Access">
                    Real Time Status
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_On_Going_Consumption_Access">
                    On-Going Consumption
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Daily_Consumption_Access">
                    Daily Consumption
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Recharge_Access">
                    Recent Rcharge
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Adjustment_Access">
                    Recent Adjustment
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Configuration_Access">
                    Recent Configuration
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Bill_Access">
                    Recent Bill
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Event_Access">
                    Recent Event
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Activity_Access">
                    Recent Activity
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_SMS_Sent_Access">
                    Recent SMS Sent
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">supervised_user_circle</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Recent_Complaints_Access">
                    Recent Complaints
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c5">
                    local_activity
                  </mat-icon> Action
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">local_activity</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Menu_Action_Access">
                    Action
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">local_activity</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Action_Load_Limiter_Access">
                    Load Limiter
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">local_activity</mat-icon>
                  <mat-slide-toggle formControlName="Cons_Action_Meter_Load_Access">
                    Meter Load
                  </mat-slide-toggle>
                </div>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="tab-pane fade" id="nav_rechargereport" role="tabpanel" aria-labelledby="nav_rechargereport_view">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c9">
                    phone_android
                  </mat-icon>
                  Recharge
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="with-icon col-sm-4 panel-body">
                <mat-icon class="d-c7">phone_android</mat-icon>
                <mat-slide-toggle formControlName="Recharge_Menu_Access">
                  Recharge
                </mat-slide-toggle>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c10">
                    assignment
                  </mat-icon>
                  Report's
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c9">
                    assignment
                  </mat-icon>
                  <mat-slide-toggle formControlName="Reports_Menu">Reports's</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c8">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Recharge_Data_Access">Recharge Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Adjustment_Data_Access">Adjustment Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Bill_Data_Access">Bill Data</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_On_Going_Access">On-Going Consumption</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Daily_Consumption_Access">Daily Consumption
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Monthly_Consumption_Access">Monthly Consumption
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Event_Log_Access">Event log Report</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Report_Meters_Activity_Log_Access">Meter Activity Log
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Reports_SMS_Sent_Access">SMS Sent Report</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Reports_Messages_Access">Messages</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c6">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Reports_Complaint_History_Access">Complaint History
                  </mat-slide-toggle>
                </div>

                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Reports_Login_Logout_Access">Login/Logout Report
                  </mat-slide-toggle>
                </div>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="tab-pane fade" id="nav_settinghelp" role="tabpanel" aria-labelledby="nav_settinghelp_view">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c9">
                    build
                  </mat-icon>
                  Settings
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c10">build</mat-icon>
                  <mat-slide-toggle formControlName="Setting_Menu_Access">
                    Setting Menu
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c11">description</mat-icon>
                  <mat-slide-toggle formControlName="Setting_Current_Teriff_Access">
                    Current Tariff
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c12">settings</mat-icon>
                  <mat-slide-toggle formControlName="Setting_load_limiter_Access">
                    Load Limiter
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c13">notification_important</mat-icon>
                  <mat-slide-toggle formControlName="Setting_Notification_Setting_Access">
                    Notification Setting
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c9">
                    notification_important
                  </mat-icon>
                  Help
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c14">notification_important</mat-icon>
                  <mat-slide-toggle formControlName="Help_Menu_Access">
                    Help Menu
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c15">chrome_reader_mode</mat-icon>
                  <mat-slide-toggle formControlName="Help_Add_New_Complaint_Access">
                    Add New Complaints
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c2">chrome_reader_mode</mat-icon>
                  <mat-slide-toggle formControlName="Help_Existing_Complaint_Access">
                    Existing Complaint
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="loading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="message.length > 0">
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
            </div>
          </div>

        </div>

        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>update</mat-icon>
            Update
          </button>
          <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

        </div>
      </div>

    </div>
  </div>
</form>
<!----add authority end-->