<div class="modal-header">
  <mat-icon>eco</mat-icon><h3>Reporting From DCU</h3>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <ng-container>
    <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="d-c2">eco</mat-icon>
           <h4>Reporting From DCU</h4> 
          </ng-template>
          <form [formGroup]="reportForm" (ngSubmit)="onSubmitAddForm()" >
                <div class="col-md-6 form-group input-rounded">
                    <mat-icon matPrefix class="d-c2">eco</mat-icon>
                    <mat-checkbox name="GDGC" formControlName="GDGC">Get DCU Gateway Configuration Data</mat-checkbox>
                </div>
                <div class="col-md-6 form-group input-rounded">
                  <mat-icon matPrefix class="d-c2">eco</mat-icon>
                    <mat-checkbox name="GMRD" formControlName="GMRD">Get Meter And Router Details From DCU</mat-checkbox>
                </div>
                <div class="col-md-6 form-group input-rounded">
                  <mat-icon matPrefix class="d-c2">eco</mat-icon>
                    <mat-checkbox name="GSDD" formControlName="GSDD">Get Scheduled Demands and Date Time</mat-checkbox>
                  
                </div>
                <div class="col-md-6 form-group input-rounded">
                  <mat-icon matPrefix class="d-c2">eco</mat-icon>
                    <mat-checkbox name="GCSD" formControlName="GCSD">Get Configured Server Details</mat-checkbox>
                  
                </div>
                <div class="col-md-6 form-group input-rounded">
                  <mat-icon matPrefix class="d-c2">eco</mat-icon>
                    <mat-checkbox name="GDRD" formControlName="GDRD">Get DCU RTC Details</mat-checkbox>
                </div>
                <button type="submit" class="btn btn-primary btn-block">
                    Report
                    <img class="pl-3"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </button>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>