<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
 <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Add New Complaints
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-groupp">
      <!-- Grid row -->
      <div class="row">
        <div class="col-md-12">
          <div class="form-group input-group">
            <span class="has-float-label">
            <mat-select placeholder="Complaint Subject" name="complaintSubject" formControlName="complaintSubject"
            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.complaintSubject.errors}" required>
              <mat-option *ngFor="let complaint of complaintType" [value]="complaint.compName">
                {{complaint.compName}}
              </mat-option>
            </mat-select>
            <i class="fa fa-database icon_innertxtbox"></i>
              <label>  Subject</label>
              <div *ngIf="submitted && aF.complaintSubject.errors" class="invalid-feedback">
                <div *ngIf="aF.complaintSubject.errors.required">
                  Subject is required
                </div>
              </div>
            </span>
          </div>
        </div>
          
         <div class="col-md-12">
          <div class="form-group input-group">
            <span class="has-float-label">
            <textarea rows="8" [maxLength]="160"  placeholder="Complaint Message (1 to 160  Charactors)" 
            name="complaintMessage" formControlName="complaintMessage" 
            class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.complaintMessage.errors}" required>></textarea>
            <i class="fa fa-comment icon_innertxtbox"></i>
              <label>Message</label>
              <div *ngIf="submitted && aF.complaintMessage.errors" class="invalid-feedback">
                <div *ngIf="aF.complaintMessage.errors.required">
                  Message is required
                </div>
              </div>
            </span>
          </div>
        </div>
          
      </div>
      <!-- Grid row -->

      <div class="row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
            <!--<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="responseMessage.length > 0" >
              <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon> {{responseMessage}}
            </div>-->

            <div role="alert" *ngIf="responseMessage.length > 0" >
              <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
              </div>
              <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
              </div>
            </div>
          

          
        </div>
        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>add</mat-icon>
            Submit
          </button>
          <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3">
            <mat-icon >cancel</mat-icon>
            Cancel
          </button>
        </div>
      </div>
  
    </div>
    <!--</mat-tab>
    </mat-tab-group>-->
  </div>
</form>