<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Recent Happy Hours Config: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container>
      <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
      </ng-container>

      <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
        <table class="table table-bordered table-sm table-hover">
          <thead class="tablebghead">
            <tr>
              <td>
                <div class="d_title">
                  <span></span>Sr No
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Meter Id
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Creation Date & Time
              </div>
              </td>
              
              <td>
                <div class="d_title">
                  <span></span>Happy Hour Start-Time
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Happy Hour End-Time
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Holiday Months
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Holiday Dates
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Week Day
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Configuration Status
              </div>
              </td>
            </tr>
          </thead>
          <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W1">
                    <p>{{i+1}}</p>
                  </div>
                </td>
            <td>
          <div class="d_content d_content_W2">
            <p>{{item.meterId}}</p>
          </div>
          </td>

          <td>
          <div class="d_content d_content_W3">
            <p>{{item.activedateTime}}</p>
          </div>
          </td>


          <td>
          <div class="d_content d_content_W4">
            <p>{{item.hhST}}</p>
          </div>
          </td>
          <td>
          <div class="d_content d_content_W5">
            <p>{{item.hhET}}</p>
          </div>
          </td>

          <td>
            <div class="d_content d_content_W6">
              <p>{{item.holidayMonths}}</p>
            </div>
            </td>

          <td>
          <div class="d_content d_content_W7">
            <p>{{item.holidayDates}}</p>
          </div>
          </td>
          <td>
          <div class="d_content d_content_W8">
            <p>{{item.weekDay}}</p>
          </div>
          </td>

          <td>
          <div class="d_content d_content_W9">
            <p>{{item.configurationStatus}}</p>
          </div>
          </td>

          </tr>
          </tbody>
          </table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        <span></span>{{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>          
        <div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>--> 
      </div>

      <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
        <div class="col-sm-12">
           <img class="center" src="assets/images/Nodata.png">
        </div>
     
        <!--<div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->
      </div>
      
    </ng-container>
<ng-container col-sm-3>
</ng-container>
</div>
</div>
