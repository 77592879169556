<div class="container-fluid innerpage_top innerpage_top_graphic">
    <div class="outerbox_shadow">
        <div class="headerall one_grid mb-3 ">
            <h1 class="demo-title">All Society Dashboard
                <span class="info_icntop ml-1">
                    <i data-toggle="tooltip" data-placement="top" title="information of All Society Dashboard"
                        class="fa fa-info tabula-tooltip"></i>
                </span>
            </h1>
            <div class="row"
                *ngIf="getThrough.authority && getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Chart">

                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics Umang</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics1" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters}}</div>
                                        <div class="meterSvalue">Total <br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV40}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV60}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel0}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio0}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics RMG</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics2" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>

                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters2}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom2}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters2}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters2}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters2}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered2}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn2}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff2}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV41}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV61}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel1}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio1}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!---<div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics Silver City</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics3" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters3}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom3}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters3}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters3}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters3}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered3}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn3}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff3}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV42}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV62}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel2}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio2}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics Silver Gland</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics4" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters4}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom4}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters4}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters4}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters4}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered4}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn4}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff4}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV43}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV63}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel3}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio3}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics Sushma Builders</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics5" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters5}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom5}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters5}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters5}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters5}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered5}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn5}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff5}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV44}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV64}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel4}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio4}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics RAS Residency</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics6" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters6}}</div>
                                        <div class="meterSvalue">Total Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom6}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters6}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters6}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters6}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered6}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn6}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff6}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV45}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV65}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel5}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio5}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics RAS Basera</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics7" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters7}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom7}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters7}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters7}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters7}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered7}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn7}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff7}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV46}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV66}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel6}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio6}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="outerbox_shadow griddashboadH">
                        <h1>Meter Statistics Meenal Housing</h1>
                        <div class="innerbox">
                            <canvas id="chart_meter_statistics8" class="ct-chart ct-perfect-fourth" width="460"
                                height="217"></canvas>
                        </div>
                        <div class="container">
                            <div class="meterstatisticsalll">
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6">
                                        <div class="meterS bggreentotal">{{totalMeters8}}</div>
                                        <div class="meterSvalue">Total<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters">
                                        <div class="meterS redbg"> {{unableCom8}}</div>
                                        <div class="meterSvalue">Non<br>Communicative</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters">
                                        <div class="meterS bgblue"> {{activeMeters8}}</div>
                                        <div class="meterSvalue">Active<br>Meters</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters">
                                        <div class="meterS bginactive"> {{deadMeters8}}</div>
                                        <div class="meterSvalue">Inactive<br>Meters</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{powerMeters8}}</div>
                                        <div class="meterSvalue">Power<br>Off</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{tampered8}}</div>
                                        <div class="meterSvalue">Tampered</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{meterRelayOn8}}</div>
                                        <div class="meterSvalue"> Relay<br>Connected </div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{meterRelayOff8}}</div>
                                        <div class="meterSvalue"> Relay<br>Disconnected</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters">
                                        <div class="meterS poweroffbg"> {{vodaPhoneIPV47}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV4</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters">
                                        <div class="meterS tempered"> {{vodaPhoneIPV67}}</div>
                                        <div class="meterSvalue">Vodaphone<br>IPV6</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters">
                                        <div class="meterS relayconnected">{{airTel7}}</div>
                                        <div class="meterSvalue">Airtel</div>
                                    </div>
                                    <div class="meterstatistics col-md-3 col-sm-6 col-xs-6"
                                        *ngIf="this.getThrough.authority.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters">
                                        <div class="meterS relayoffbg"> {{jio7}}</div>
                                        <div class="meterSvalue">Jio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>