import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from '../../_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-permauthority',
  templateUrl: './permauthority.component.html',
  styleUrls: ['./permauthority.component.scss'],
  providers: [Access]
})
export class PermauthorityComponent implements OnInit {
  faSpinner = faSpinner;
  thumb = false;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage:string = '';
  AddProcess:boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority:any;
  authorityPerms : {

    smart_meter_order_add: 0,
    smart_meter_order_view_serial_no: 0,
    smart_meter_order_add_serial_no: 0,
    smart_meter_order_edit_by_production: 0,
    smart_meter_order_edit_by_account: 0,
    smart_meter_order_edit_by_marketing: 0,
    smart_meter_order_delete_order: 0,

    Auth_Menu: 0,
    Admin_Non_Comm_Count: 0,
    Admin_User_Remark_Revert: 0,
    Admin_User_Remark_Revert_ADD_Button: 0,
    Admin_User_Remark_Revert_Edit_Button: 0,
    Admin_User_Remark_Remove_Button: 0,
    
    Auth_Non_Communicated: 0,
    Auth_Non_Com_Circle: 0,
    Auth_Non_Com_Authority: 0,
    Auth_Non_Com_Catergory: 0,
    Auth_Non_Com_Date: 0,
    Auth_Non_Com_Sim_Type: 0,
    Meters_Reports_PD_Meters_Report: 0,

    Meters_Sub_Configure_Load_Limit_On_Meter_3_PH: 0,
    Meters_Sub_Configure_Bill_date: 0,
    Meters_Sub_Configure_Relay_Control: 0,
    Meters_Sub_Configure_ESW_1_PH: 0,
    Meters_Sub_Configure_ESW_3_PH: 0,
    Meters_Sub_Configure_Periodic_Push: 0,

    Auth_All_Griphical_Deshboard: 0,
    Auth_All_Grid_Deshboard: 0,

    Auth_Sub_Menu_Sub_New_Meter: 0,
    Auth_Sub_Menu_Sub_Old_New_Meter: 0,
    Auth_Sub_Menu_Sub_Old_Meter_Image_Button: 0,
    Auth_Sub_Menu_Sub_Old_Meter_Modify_Button: 0,
    Auth_Sub_Menu_Sub_New_Meter_Image_Button: 0,

    Auth_All_Griphical_Desh_Grid_Society_View: 0,
    Auth_All_Griphical_Desh_Grid_Utility_View: 0,
    Auth_All_Griphical_Society_Deshboard: 0,

    Meters_Reports_Old_New_Meters_Information_Report: 0,
    Meters_Reports_Day_Wise_Meters_Information_Report: 0,
    Meters_Reports_Day_Wise_Non_Comunicated_Report: 0,
    Meters_Reports_Replaced_Meters_Information_Report: 0,
    Meters_Reports_Replaced_Meters_History_Report: 0,
    Comulative_Fault_Report: 0,
    Removed_Meters_Report: 0,
    Stock_Analysis_Report: 0,
    User_Work_Info_Report: 0,

    Auth_All_Meter_Installed_Status: 0,
    Auth_All_Meter_Installed_Status_New: 0,
    Auth_All_Meter_Installed_Circle: 0,
    Auth_All_Meter_Installed_Authority: 0,
    Auth_All_Meter_Single_Ph_Show: 0,
    Auth_All_Meter_Three_Ph_Show: 0,
    Auth_All_Meter_LTCT_Ph_Show: 0,


    Auth_All_Griphical_grid_kWH: 0,
    Auth_All_Griphical_dg_kWH: 0,
    Auth_All_Griphical_recharges: 0,
    Auth_All_Griphical_grid_kWH_Utility: 0,
    Auth_All_Griphical_grid_kVARH_Utility: 0,
    Auth_All_Griphical_grid_kVAH_Utility: 0,

    Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility: 0,
    Auth_All_Griphical_grid_kWH_Daily_Cons_Utility: 0,
    Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility: 0,
    Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility: 0,

    Auth_All_Griphical_User_Meter_Statistics_Chart: 0,
    Auth_All_Griphical_User_Consumption_Chart: 0,
    Auth_All_Griphical_User_Consumption_Daily: 0,
    Auth_All_Griphical_User_Consumption_Monthly: 0,
    Auth_All_Griphical_User_Consumption_Yearly: 0,
    Auth_All_Griphical_User_Consumption_kWH_Grid: 0,
    Auth_All_Griphical_User_Consumption_kVAH_Grid: 0,
    Auth_All_Griphical_User_Consumption_kVARH_Q1_Grid: 0,
    Auth_All_Griphical_User_Consumption_kVARH_Q2_Grid: 0,
    Auth_All_Griphical_User_Consumption_kVARH_Q3_Grid: 0,
    Auth_All_Griphical_User_Consumption_kVARH_Q4_Grid: 0,
    Auth_All_Griphical_User_Consumption_kWH_DG: 0,
    Auth_All_Griphical_User_Consumption_kVAH_DG: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Chart: 0,
    Auth_All_Griphical_Desh_Meter_Balance_Chart: 0,
    Auth_All_Griphical_Desh_Meter_Tamper_Chart: 0,
    Auth_All_Griphical_Power_Cut_Chat: 0,
    Auth_All_Griphical_Power_Cut_Daily: 0,
    Auth_All_Griphical_Power_Cut_Monthly: 0,
    Auth_All_Griphical_Power_Cut_Yearly: 0,

    Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters: 0,
    Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters: 0,

    Auth_All_DG_Filter: 0,
    Auth_All_Tamper_Filter: 0,
    Auth_All_Group_Filter: 0,
    Auth_All_Meter_Filter: 0,
    Auth_All_Search_Meter_Id_Filter: 0,
   

   
    Auth_All_Cons_UId_Column: 0,
    Auth_All_Cons_DG_Column: 0,
    Auth_All_Cons_Tower_Column: 0,
    Auth_All_Cons_Group_Column: 0,
    Auth_All_Meter_UId_Column: 0,
    Auth_All_Flat_Id_Column: 0,
    Auth_All_Meter_No_Column: 0,
    Auth_All_Meter_Ip_Column: 0,
    Auth_All_Hes_Id_Column: 0,
    Auth_All_Meter_Type_Column: 0,
    Auth_All_LL_Grid_kW_Column: 0,
    Auth_All_LL_DG_kW_Column: 0,
    Auth_All_Last_Update_Column: 0,
    Auth_All_Grid_Voltage_R_Column: 0,
    Auth_All_Grid_Voltage_Y_Column: 0,
    Auth_All_Grid_Voltage_B_Column: 0,
    Auth_All_Grid_Phase_Current_R_Column: 0,
    Auth_All_Grid_Phase_Current_Y_Column: 0,
    Auth_All_Grid_Phase_Current_B_Column: 0,
    Auth_All_Grid_Nutural_Current_R_Column: 0,
    Auth_All_Grid_Nutural_Current_Y_Column: 0,
    Auth_All_Grid_Nutural_Current_B_Column: 0,
    Auth_All_Grid_PF_R_Column: 0,
    Auth_All_Grid_PF_Y_Column: 0,
    Auth_All_Grid_PF_B_Column: 0,
    Auth_All_Grid_Total_Power_Factor_Column: 0,
    Auth_All_Grid_Frequency_Column: 0,
    Auth_All_Grid_Active_Power_Column: 0,
    Auth_All_Grid_Reactive_Power_Column: 0,
    Auth_All_Grid_ApperantPower_kVA_Column: 0,
    Auth_All_Grid_Md_Kw_Column: 0,
    Auth_All_Grid_Md_kVA_Column: 0,
    Auth_All_Grid_kWH_Column: 0,
    Auth_All_Grid_kVARH_Q1_Column: 0,
    Auth_All_Grid_kVARH_Q2_Column: 0,
    Auth_All_Grid_kVARH_Q3_Column: 0,
    Auth_All_Grid_kVARH_Q4_Column: 0,
    Auth_All_Grid_kVAH_Column: 0,
    Auth_All_DG_kWH_Column: 0,
    Auth_All_DG_kVAH_Column: 0,
    Auth_All_Balance_Column: 0,
    Auth_All_Supply_Column: 0,
    Auth_All_Tamper_Column: 0,
    Auth_All_Last_LS_DT_Column: 0,
    Auth_All_Last_Block_LS_DT_Column: 0,
    Auth_All_Tamper_Count_Column: 0,
    Auth_All_Billng_Count_Column: 0,
    Auth_All_Relay_Status_Column: 0,
    Auth_All_DG_Supply_Status_Column: 0,
    Auth_All_Live_Status_Column: 0,
    Auth_All_Antenna_Column: 0,
    Auth_All_Sim_Type_Column: 0,
    Auth_All_Action_Column: 0,
   

    Auth_All_Cons_UId_Column_View: 0,
    Auth_All_Cons_DG_Column_View: 0,
    Auth_All_Cons_Tower_Column_View: 0,
    Auth_All_Cons_Group_Column_View: 0,
    Auth_All_Meter_UId_Column_View: 0,
    Auth_All_Flat_Id_Column_View: 0,
    Auth_All_Meter_No_Column_View: 0,
    Auth_All_Meter_Ip_Column_View: 0,
    Auth_All_Hes_Id_Column_View: 0,
    Auth_All_Meter_Type_Column_View: 0,
    Auth_All_LL_Grid_kW_Column_View: 0,
    Auth_All_LL_DG_kW_Column_View: 0,
    Auth_All_Last_Update_Column_View: 0,
    Auth_All_Grid_Voltage_R_Column_View: 0,
    Auth_All_Grid_Voltage_Y_Column_View: 0,
    Auth_All_Grid_Voltage_B_Column_View: 0,
    Auth_All_Grid_Phase_Current_R_Column_View: 0,
    Auth_All_Grid_Phase_Current_Y_Column_View: 0,
    Auth_All_Grid_Phase_Current_B_Column_View: 0,
    Auth_All_Grid_Nutural_Current_R_Column_View: 0,
    Auth_All_Grid_Nutural_Current_Y_Column_View: 0,
    Auth_All_Grid_Nutural_Current_B_Column_View: 0,
    Auth_All_Grid_PF_R_Column_View: 0,
    Auth_All_Grid_PF_Y_Column_View: 0,
    Auth_All_Grid_PF_B_Column_View: 0,
    Auth_All_Grid_Total_Power_Factor_Column_View: 0,
    Auth_All_Grid_Frequency_Column_View: 0,
    Auth_All_Grid_Active_Power_Column_View: 0,
    Auth_All_Grid_Reactive_Power_Column_View: 0,
    Auth_All_Grid_ApperantPower_kVA_Column_View: 0,
    Auth_All_Grid_Md_Kw_Column_View: 0,
    Auth_All_Grid_Md_kVA_Column_View: 0,
    Auth_All_Grid_kWH_Column_View: 0,
    Auth_All_Grid_kVARH_Q1_Column_View: 0,
    Auth_All_Grid_kVARH_Q2_Column_View: 0,
    Auth_All_Grid_kVARH_Q3_Column_View: 0,
    Auth_All_Grid_kVARH_Q4_Column_View: 0,
    Auth_All_Grid_kVAH_Column_View: 0,
    Auth_All_DG_kWH_Column_View: 0,
    Auth_All_DG_kVAH_Column_View: 0,
    Auth_All_Balance_Column_View: 0,
    Auth_All_Supply_Column_View: 0,
    Auth_All_Tamper_Column_View: 0,
    Auth_All_Last_LS_DT_Column_View: 0,
    Auth_All_Last_Block_LS_DT_Column_View: 0,
    Auth_All_Tamper_Count_Column_View: 0,
    Auth_All_Billng_Count_Column_View: 0,
    Auth_All_Relay_Status_Column_View: 0,
    Auth_All_Live_Status_Column_View: 0,
    Auth_All_Antenna_Column_View: 0,
    Auth_All_Sim_Type_Column_View: 0,
    Auth_All_Action_Column_View: 0,
   

    Auth_Sub_Menu_Add_New_Level: 0,
    Auth_Sub_Menu_Sub_Add_Level_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Level_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Level_Button: 0,

    Auth_Sub_Menu_Add_DG: 0,
    Auth_Sub_Menu_Sub_Add_DG_Button: 0,
    Auth_Sub_Menu_Sub_Edit_DG_Button: 0,
    Auth_Sub_Menu_Sub_Delete_DG_Button: 0,

    Auth_Sub_Menu_Add_Meter_Range: 0,
    Auth_Sub_Menu_Sub_Add_Meter_Range_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Meter_Range_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Meter_Range_Button: 0,

    Auth_Sub_Menu_Add_Meter_Firmware: 0,
    Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button: 0,

    Auth_Sub_Menu_Add_Tower: 0,
    Auth_Sub_Menu_Sub_Add_Tower_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Tower_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Tower_Button: 0,

    Auth_Sub_Menu_Add_Group: 0,
    Auth_Sub_Menu_Sub_Add_Group_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Group_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Group_Button: 0,

    Auth_Sub_Menu_Add_Authority: 0,
    Auth_Sub_Menu_Sub_Add_Authority_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Authority_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Authority_Button: 0,

    Auth_Sub_Menu_Add_Contractor: 0,
    Auth_Sub_Menu_Sub_Add_Contractor_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Contractor_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Contractor_Button: 0,

    Auth_Sub_Menu_Add_Teriff: 0,
    Auth_Sub_Menu_Sub_Add_Teriff_Button: 0,
    Auth_Sub_Menu_Sub_Modify_Teriff_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Teriff_Button: 0,
    Auth_Sub_Menu_Add_Consumer_Notification: 0,
    Auth_Sub_Menu_Sub_Add_Notification_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Notification_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Notification_Button: 0,
    Auth_Sub_Menu_Add_New_Meter_Command: 0,
    Auth_Sub_Menu_Sub_Add_Meter_Command_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Meter_Command_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Meter_Command_Button: 0,
    Auth_Sub_Menu_Add_New_DCU_Command: 0,
    Auth_Sub_Menu_Sub_Add_DCU_Command_Button: 0,
    Auth_Sub_Menu_Sub_Edit_DCU_Command_Button: 0,
    Auth_Sub_Menu_Sub_Delete_DCU_Command_Button: 0,

    Auth_Sub_Menu_Add_New_Scheduler: 0,
    Auth_Sub_Menu_Sub_Add_Scheduler_Button: 0,
    Auth_Sub_Menu_Sub_Edit_Scheduler_Button: 0,
    Auth_Sub_Menu_Sub_Delete_Scheduler_Button: 0,

    Auth_Sub_Menu_Refund: 0,
    Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button: 0,

    Auth_Sub_Menu_Sub_Pending_Demands: 0,
    Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button: 0,
    Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button: 0,

    Auth_Sub_Menu_Reports: 0,
    Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report: 0,
    Auth_Sub_Menu_Sub_All_Authority_Info_Report: 0,
    Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report: 0,
    Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report: 0,
  
    Consumer_Menu: 0,
    Consumer_Sub_Add_Consumer: 0,
    Consumer_Sub_Add_Consumer_Button: 0,

    Consumer_Sub_Consumer_Recharge_Button: 0,
    Consumer_Sub_Consumer_Adjustment_Button: 0,

    Consumer_Sub_Edit_Consumer_Info_Button: 0,
    Consumer_Sub_Edit_Consumer_Permission_Button: 0,
    Consumer_Sub_Delete_Consumer_Button: 0,
    Consumer_Sub_Add_Consumer_Permissions: 0,
    Consumer_Sub_Add_Consumer_Complaint: 0,
    Consumer_Sub_Add_Consumer_Complaint_Button: 0,
    Consumer_Sub_Edit_Consumer_Complaint_Button: 0,
    Consumer_Sub_Delete_Consumer_Complaint_Button: 0,
    Consumer_Sub_Reports: 0,
    Consumer_Sub_All_Cons_Info_Report: 0,
    Consumer_Sub_All_Cons_Login_Logout_Report: 0,
    Consumer_Sub_All_Cons_Complaint_Report: 0,
    Consumer_Sub_All_Cons_Notifications_Report: 0,

    contractor_Menu: 0,
    contractor_add: 0,
    contractor_modify: 0,
    contractor_add_superviser: 0,
    contractor_view_superviser: 0,
    contractor_delete: 0,

    contractor_superviser: 0,
    contractor_modify_superviser: 0,
    contractor_delete_superviser: 0,

    contractor_issued_meters_range_menu: 0,
    contractor_issued_meters_range: 0,
    contractor_modify_meters_range: 0,
    contractor_delete_meters_range: 0,

    contractor_progress: 0,
    contractor_modify_payment: 0,
    
    
    Contractor_Sub_Reports: 0,
    contractor_info_Report: 0,
    contractor_superviser_info_Report: 0,
    contractor_faulty_meters_Report: 0,
    contractor_installed_meters_report: 0,
    contractor_payment_pending_report: 0,
    contractor_payment_cleared_report: 0,

    Meters_Menu: 0,
    DCU_Sub_Add_Meter: 0,
    DCU_Sub_Edit_Meter: 0,
    DCU_Sub_Delete_Meter: 0,

    Meter_Sub_Modify_Meter: 0,
    Meter_Sub_Recharge_Button: 0,
    Meter_Sub_Adjustment_Button: 0,
    Meter_Sub_Configure_Meter_Button: 0,
    Meter_Sub_Activity_On_Meter_Button: 0,

    Meter_Sub_Activity_On_Meter_Load_On_Off_Button: 0,
    Meter_Sub_Activity_On_DG_Supply_On_Off_Button: 0,
    Meter_Sub_Activity_On_Emergency_Credit_On_Button: 0,
    Meter_Sub_Activity_On_Meter_MD_Reset_Button: 0,
    Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button: 0,
    Meter_Sub_Activity_On_Meter_DG_Free_Units_Button: 0,

    Meter_Sub_Meter_Download_Option_Button: 0,

    Meter_Sub_Meter_Download_Instant_Button: 0,
    Meter_Sub_Meter_Download_All_Billing_Button: 0,
    Meter_Sub_Meter_Download_Selective_Billing_Button: 0,
    Meter_Sub_Meter_Download_Block_Load_Survey_Button: 0,
    Meter_Sub_Meter_Download_Daily_Load_Survey_Button: 0,
    Meter_Sub_Meter_Download_All_Tamper_Button: 0,
    Meter_Sub_Meter_Download_All_Voltage_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Voltage_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Current_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Current_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Power_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Power_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Transaction_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Transact_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Other_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Other_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Cover_Open_Event_Button: 0,
    Meter_Sub_Meter_Download_All_Control_Event_Button: 0,
    Meter_Sub_Meter_Download_Selective_Control_Event_Button: 0,
    Meter_Sub_Meter_Download_Load_Limit_Config_Button: 0,
    Meter_Sub_Meter_Download_Happy_Hours_Config_Button: 0,
    Meter_Sub_Meter_Download_Alarm_Level_Config_Button: 0,
    Meter_Sub_Meter_Download_Billing_Date_Config_Button: 0,


    Meter_Sub_Meter_Real_Time_Data_Button: 0,
    Meter_Sub_Meter_Recent_Recharge_History_Button: 0,
    Meter_Sub_Meter_Recent_Adjustment_History_Button: 0,
    Meter_Sub_Meter_Recent_Bill_Button: 0,
    Meter_Sub_Meter_Recent_Bills_Button: 0,
    Meter_Sub_Meter_Recent_Events_Button: 0,
    Meter_Sub_Meter_OnGoing_Consumption_Button: 0,
    Meter_Sub_Meter_Daily_Consumptiion_Button: 0,
    Meter_Sub_Meter_Recent_Activity_Button: 0,
    Meter_Sub_Meter_Recent_SMS_Sent_Button: 0,
    Meter_Sub_Meter_Billing_Teriff_Status_Button: 0,
    Meter_Sub_Meter_Load_Limit_Config_Status_Button: 0,
    Meter_Sub_Meter_Happy_Hours_Config_Status_Button: 0,
    Meter_Sub_Meter_Billing_Scheduler_Status_Button: 0,
    Meter_Sub_Meter_Billing_Teriff_From_Meter_Button: 0,
    Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button: 0,
    Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button: 0,
    Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button: 0,
    Meter_Sub_Meter_Recent_Complaints_Button: 0,
    Meter_Sub_Meter_Delete_Meter_Button: 0,
    
    Meters_Sub_Configure_Meter: 0,
    Meters_Sub_Configure_Load_Limit_On_Meter: 0,
 
    Meters_Sub_Configure_Server_Details_On_Meter: 0,
  
    Meters_Sub_Configure_Happy_Hours_On_Meter: 0,
    
    Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter: 0,
    Meters_Sub_Activity_On_Meter: 0,
   
    Meters_Sub_Activity_Sub_Meter_Load_On_Off: 0,
    Meters_Sub_Activity_Sub_Backup_Supply_On_Off: 0,
    Meters_Sub_Activity_Sub_DG_Free_Units_On_Off: 0,
    Meters_Sub_Activity_Sub_Emergency_On: 0,
    Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post: 0,
    Meters_Sub_Reporting_From_Meter: 0,
   
    Meters_Sub_Reporting_Sub_Get_Full_Billing_Data: 0,
  
    Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data: 0,
    Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data: 0,
    Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Config_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Router_Config_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Hardware_Status: 0,

    Meters_Sub_Reporting_Sub_Get_Instant_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data: 0,
    Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status: 0,
    Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status: 0,
    
    Meters_Sub_Disabled_Meters: 0,
    Meters_Sub_Disabled_Meters_Modify: 0,
    Meters_Sub_Disabled_Meters_Delete: 0,
    
    Meters_Reports: 0,
    Meters_Reports_Meters_Information_Report: 0,
    Meters_Reports_Meters_Teriff: 0,
  
    Meters_Reports_Activity_Log_Report: 0,
    Meters_Reports_Authority_Wise_Activity_Log_Report: 0,
  
    Meters_Reports_Current_Data_Report: 0,

    Meters_Reports_Meter_Wise_Billing_Data_Report: 0,
 
    Meters_Reports_Latest_Tamper_Data_Report: 0,
  
    Meters_Reports_Daily_Unit_Consumption_Report: 0,

    Meters_Reports_Daily_Unit_Energy_Report: 0,

    Meters_Reports_Monthly_Unit_Consumption_Report: 0,
 
    Meters_Reports_Daily_Total_Revenue_Report: 0,
    Meters_Reports_Daily_Total_Revenue_Report_failed: 0,
 
    Meters_Reports_Daily_Low_Balance_Report: 0,
    Meters_Reports_Daily_Zero_Balance_Report: 0,
    Meters_Reports_Daily_Dead_Meters_Report: 0,
 
    Meters_Reports_SMS_Sent_Report: 0,
    Meters_Reports_SLA_Report: 0,

    Meters_Reports_Daily_DG_Status_Report: 0,
    Meters_Reports_Daily_Total_Adjustment_Report: 0,
    Meters_Reports_Daily_Total_Adjustment_Report_Failed: 0,
    Meters_Reports_Load_Limit_Config_Report:  0,
    Meters_Reports_Tariff_Config_Report: 0,
    Meters_Reports_Tariff_Config_Slab_Report: 0,
    
    Meters_Reports_Happy_Hours_Report:  0,
    Meters_Reports_Daily_OnGoing_Consumption_Report:  0,
    Meters_Reports_Bill_Creation_Report:  0,
    Active_All_Permission_Access:  0,


    Meters_Sub_Configure_Utility_Meter:  0,
    Meters_Sub_Configure_Utility_Gernal_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config:  0,
    Meters_Sub_Configure_Utility_Demand_Integration_Period_Config:  0,
    Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config:  0,
    Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config:  0,
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config:  0,
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config:  0,
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config:  0,
    Meters_Sub_Configure_Utility_Billing_Date_Config:  0,
    Meters_Sub_Configure_Utility_Billing_Date_Read_Config:  0,
    Meters_Sub_Configure_Utility_Billing_Date_Write_Config:  0,
    Meters_Sub_Configure_Utility_Push_Schedule_Config:  0,
    Meters_Sub_Configure_Utility_Push_Schedule_Read_Config:  0,
    Meters_Sub_Configure_Utility_Push_Schedule_Write_Config:  0,
    Meters_Sub_Configure_Utility_Relay_Config:  0,
    Meters_Sub_Configure_Utility_Relay_Read_Config:  0,
    Meters_Sub_Configure_Utility_Relay_Write_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config:  0,

    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config:  0,
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config:  0,
    Meters_Sub_Configure_Utility_Time_Config:  0,
    Meters_Sub_Configure_Utility_Time_Read_Config:  0,
    Meters_Sub_Configure_Utility_Time_Write_Config:  0,
    Meters_Sub_Configure_Utility_Password_Config:  0,
    Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config:  0,
    Meters_Sub_Configure_Utility_Password_High_Level_Write_Config:  0,
    Meters_Sub_Configure_Utility_Enc_Auth_Key_Config:  0,
    Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config:  0,
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Config:  0,
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config:  0,
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config:  0,

    Meters_Sub_Configure_Utility_Load_Limiter_Config:  0,
    Meters_Sub_Configure_Utility_Load_Limiter_Read_Config:  0,
    Meters_Sub_Configure_Utility_Load_Limiter_Write_Config:  0,


    Meters_Sub_Configure_Utility_TOD_Zone_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_1_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_2_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_3_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_4_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_5_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_6_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_7_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_8_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_Read_Config:  0,
    Meters_Sub_Configure_Utility_TOD_Zone_Write_Config:  0,

    
    Meters_Sub_Configure_Utility_ESW_Three_Phase_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Influb_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config:  0,

    Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config :  0,
    Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Read_Config:  0,
    Meters_Sub_Configure_Utility_ESW_Single_Write_Config:  0,


    Updation_date_Time: '',
    Server_Date_Time: '',
    Authirity_Id: 0
};
  loading:boolean = false;
  isloading:boolean = false;
  message:string = '';
  authorityData:any = {};
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    public access: Access
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
    });
  }

  ngOnInit() {
    this.getAuthPerms();
   this.addAuthorityForm = this.formBuilder.group({
    //uid: [''],


    Admin_Non_Comm_Count: ['', Validators.required],
    Admin_User_Remark_Revert: ['', Validators.required],
    Admin_User_Remark_Revert_ADD_Button: ['', Validators.required],
    Admin_User_Remark_Revert_Edit_Button: ['', Validators.required],
    Admin_User_Remark_Remove_Button: ['', Validators.required],

    Auth_Menu: ['', Validators.required],

    Meters_Sub_Configure_Load_Limit_On_Meter_3_PH: ['', Validators.required],
    Meters_Sub_Configure_Bill_date: ['', Validators.required],
    Meters_Sub_Configure_Relay_Control: ['', Validators.required],
    Meters_Sub_Configure_ESW_1_PH: ['', Validators.required],
    Meters_Sub_Configure_ESW_3_PH: ['', Validators.required],
    Meters_Sub_Configure_Periodic_Push: ['', Validators.required],

    Auth_Non_Communicated: ['', Validators.required],
    Auth_Non_Com_Circle: ['', Validators.required],
    Auth_Non_Com_Authority: ['', Validators.required],
    Auth_Non_Com_Catergory: ['', Validators.required],
    Auth_Non_Com_Date: ['', Validators.required],
    Auth_Non_Com_Sim_Type: ['', Validators.required],
    Meters_Reports_PD_Meters_Report: ['', Validators.required],
   
    Auth_All_Griphical_Deshboard: ['', Validators.required],
    Auth_All_Grid_Deshboard: ['', Validators.required],

    Auth_Sub_Menu_Sub_New_Meter: ['', Validators.required],
    Auth_Sub_Menu_Sub_Old_New_Meter: ['', Validators.required],
    Auth_Sub_Menu_Sub_Old_Meter_Image_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Old_Meter_Modify_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_New_Meter_Image_Button: ['', Validators.required],

    Auth_All_Griphical_Desh_Grid_Society_View: ['', Validators.required],
    Auth_All_Griphical_Desh_Grid_Utility_View: ['', Validators.required],
    Auth_All_Griphical_Society_Deshboard: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Chart: ['', Validators.required],
    Auth_All_Griphical_User_Consumption_Chart: ['', Validators.required],
    Auth_All_Griphical_User_Consumption_Daily: ['', Validators.required],
    Auth_All_Griphical_User_Consumption_Monthly: ['', Validators.required],
    Auth_All_Griphical_User_Consumption_Yearly: ['', Validators.required],
    
    Auth_All_Meter_Installed_Status: ['', Validators.required],
    Auth_All_Meter_Installed_Status_New: ['', Validators.required],
    Auth_All_Meter_Installed_Circle: ['', Validators.required],
    Auth_All_Meter_Installed_Authority: ['', Validators.required],
    Auth_All_Meter_Single_Ph_Show: ['', Validators.required],
    Auth_All_Meter_Three_Ph_Show: ['', Validators.required],
    Auth_All_Meter_LTCT_Ph_Show: ['', Validators.required],

    Auth_All_Griphical_grid_kWH: ['', Validators.required],
    Auth_All_Griphical_dg_kWH: ['', Validators.required],
    Auth_All_Griphical_recharges: ['', Validators.required],

    Meters_Reports_Old_New_Meters_Information_Report: ['', Validators.required],
    Meters_Reports_Day_Wise_Meters_Information_Report: ['', Validators.required],
    Meters_Reports_Day_Wise_Non_Comunicated_Report: ['', Validators.required],
    Meters_Reports_Replaced_Meters_Information_Report: ['', Validators.required],
    Meters_Reports_Replaced_Meters_History_Report: ['', Validators.required],
    Comulative_Fault_Report: ['', Validators.required],
    Removed_Meters_Report: ['', Validators.required],
    Stock_Analysis_Report: ['', Validators.required],
    User_Work_Info_Report: ['', Validators.required],

    Auth_All_Griphical_grid_kWH_Utility: ['', Validators.required],
    Auth_All_Griphical_grid_kVARH_Utility: ['', Validators.required],
    Auth_All_Griphical_grid_kVAH_Utility: ['', Validators.required],
    
    
    Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility: ['', Validators.required],
    Auth_All_Griphical_grid_kWH_Daily_Cons_Utility: ['', Validators.required],
    Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility: ['', Validators.required],
    Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility: ['', Validators.required],
    
    
   
    Auth_All_Griphical_Power_Cut_Chat: ['', Validators.required],
    Auth_All_Griphical_Power_Cut_Daily: ['', Validators.required],
    Auth_All_Griphical_Power_Cut_Monthly: ['', Validators.required],
    Auth_All_Griphical_Power_Cut_Yearly: ['', Validators.required],

    Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters: ['', Validators.required],
    
    Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters: ['', Validators.required],
    Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters: ['', Validators.required],
    
    Auth_All_Grid_Voltage_R_Column:['', Validators.required],
    Auth_All_Grid_Voltage_Y_Column:['', Validators.required],
    Auth_All_Grid_Voltage_B_Column:['', Validators.required],
    Auth_All_Grid_Phase_Current_R_Column:['', Validators.required],
    Auth_All_Grid_Phase_Current_Y_Column:['', Validators.required],
    Auth_All_Grid_Phase_Current_B_Column:['', Validators.required],
    Auth_All_Grid_Nutural_Current_R_Column:['', Validators.required],
    Auth_All_Grid_Nutural_Current_Y_Column:['', Validators.required],
    Auth_All_Grid_Nutural_Current_B_Column:['', Validators.required],
    Auth_All_Grid_PF_R_Column:['', Validators.required],
    Auth_All_Grid_PF_Y_Column:['', Validators.required],
    Auth_All_Grid_PF_B_Column:['', Validators.required],
    Auth_All_Grid_Total_Power_Factor_Column:['', Validators.required],
    Auth_All_Grid_Frequency_Column:['', Validators.required],
    Auth_All_Grid_Active_Power_Column:['', Validators.required],
    Auth_All_Grid_Reactive_Power_Column:['', Validators.required],
    Auth_All_Grid_ApperantPower_kVA_Column: ['', Validators.required],
    Auth_All_Grid_Md_Kw_Column: ['', Validators.required],
    Auth_All_Grid_Md_kVA_Column: ['', Validators.required],
    Auth_All_Tamper_Count_Column: ['', Validators.required],
    Auth_All_Billng_Count_Column: ['', Validators.required],

    Auth_All_DG_Filter:['', Validators.required],
    Auth_All_Tamper_Filter:['', Validators.required],
    Auth_All_Group_Filter: ['', Validators.required],
    Auth_All_Meter_Filter:['', Validators.required],
    Auth_All_Search_Meter_Id_Filter:['', Validators.required],
    Auth_All_Cons_UId_Column: ['', Validators.required],
    Auth_All_Meter_UId_Column: ['', Validators.required],

    Auth_All_Cons_DG_Column: ['', Validators.required],
    Auth_All_Cons_Tower_Column: ['', Validators.required],
    Auth_All_Cons_Group_Column: ['', Validators.required],

    Auth_All_Flat_Id_Column: ['', Validators.required],
    Auth_All_Meter_No_Column: ['', Validators.required],
    Auth_All_Meter_Ip_Column:['', Validators.required],
    Auth_All_Hes_Id_Column:['', Validators.required],
    Auth_All_Meter_Type_Column: ['', Validators.required],
    Auth_All_LL_Grid_kW_Column: ['', Validators.required],
    Auth_All_LL_DG_kW_Column: ['', Validators.required],
    Auth_All_Last_Update_Column: ['', Validators.required],
    Auth_All_Grid_kWH_Column: ['', Validators.required],
    Auth_All_Grid_kVARH_Q1_Column: ['', Validators.required],
    Auth_All_Grid_kVARH_Q2_Column: ['', Validators.required],
    Auth_All_Grid_kVARH_Q3_Column: ['', Validators.required],
    Auth_All_Grid_kVARH_Q4_Column: ['', Validators.required],


    Auth_All_Grid_kVAH_Column: ['', Validators.required],

    Auth_All_DG_kWH_Column: ['', Validators.required],
    Auth_All_DG_kVAH_Column: ['', Validators.required],
    Auth_All_Balance_Column: ['', Validators.required],
    Auth_All_Supply_Column: ['', Validators.required],
    Auth_All_Tamper_Column: ['', Validators.required],
    Auth_All_Last_LS_DT_Column: ['', Validators.required],
    Auth_All_Last_Block_LS_DT_Column: ['', Validators.required],
    Auth_All_Relay_Status_Column: ['', Validators.required],
    Auth_All_DG_Supply_Status_Column: ['', Validators.required],
    Auth_All_Live_Status_Column: ['', Validators.required],
    Auth_All_Antenna_Column: ['', Validators.required],
    Auth_All_Sim_Type_Column: ['', Validators.required],
    Auth_All_Action_Column:['', Validators.required],

    Auth_All_Cons_UId_Column_View:['', Validators.required],
    Auth_All_Cons_DG_Column_View:['', Validators.required],
    Auth_All_Cons_Tower_Column_View:['', Validators.required],
    Auth_All_Cons_Group_Column_View:['', Validators.required],
    Auth_All_Meter_UId_Column_View:['', Validators.required],
    Auth_All_Flat_Id_Column_View:['', Validators.required],
    Auth_All_Meter_No_Column_View:['', Validators.required],
    Auth_All_Meter_Ip_Column_View:['', Validators.required],
    Auth_All_Hes_Id_Column_View:['', Validators.required],
    Auth_All_Meter_Type_Column_View:['', Validators.required],
    Auth_All_LL_Grid_kW_Column_View:['', Validators.required],
    Auth_All_LL_DG_kW_Column_View:['', Validators.required],
    Auth_All_Last_Update_Column_View:['', Validators.required],
    Auth_All_Grid_Voltage_R_Column_View:['', Validators.required],
    Auth_All_Grid_Voltage_Y_Column_View:['', Validators.required],
    Auth_All_Grid_Voltage_B_Column_View:['', Validators.required],
    Auth_All_Grid_Phase_Current_R_Column_View:['', Validators.required],
    Auth_All_Grid_Phase_Current_Y_Column_View:['', Validators.required],
    Auth_All_Grid_Phase_Current_B_Column_View:['', Validators.required],
    Auth_All_Grid_Nutural_Current_R_Column_View:['', Validators.required],
    Auth_All_Grid_Nutural_Current_Y_Column_View:['', Validators.required],
    Auth_All_Grid_Nutural_Current_B_Column_View:['', Validators.required],
    Auth_All_Grid_PF_R_Column_View:['', Validators.required],
    Auth_All_Grid_PF_Y_Column_View:['', Validators.required],
    Auth_All_Grid_PF_B_Column_View:['', Validators.required],
    Auth_All_Grid_Total_Power_Factor_Column_View:['', Validators.required],
    Auth_All_Grid_Frequency_Column_View:['', Validators.required],
    Auth_All_Grid_Active_Power_Column_View:['', Validators.required],
    Auth_All_Grid_Reactive_Power_Column_View:['', Validators.required],
    Auth_All_Grid_ApperantPower_kVA_Column_View:['', Validators.required],
    Auth_All_Grid_Md_Kw_Column_View:['', Validators.required],
    Auth_All_Grid_Md_kVA_Column_View:['', Validators.required],
    Auth_All_Grid_kWH_Column_View:['', Validators.required],
    Auth_All_Grid_kVARH_Q1_Column_View:['', Validators.required],
    Auth_All_Grid_kVARH_Q2_Column_View:['', Validators.required],
    Auth_All_Grid_kVARH_Q3_Column_View:['', Validators.required],
    Auth_All_Grid_kVARH_Q4_Column_View:['', Validators.required],
    Auth_All_Grid_kVAH_Column_View:['', Validators.required],
    Auth_All_DG_kWH_Column_View:['', Validators.required],
    Auth_All_DG_kVAH_Column_View:['', Validators.required],
    Auth_All_Balance_Column_View:['', Validators.required],
    Auth_All_Supply_Column_View:['', Validators.required],
    Auth_All_Tamper_Column_View:['', Validators.required],
    Auth_All_Last_LS_DT_Column_View:['', Validators.required],
    Auth_All_Last_Block_LS_DT_Column_View:['', Validators.required],
    Auth_All_Tamper_Count_Column_View:['', Validators.required],
    Auth_All_Billng_Count_Column_View:['', Validators.required],
    Auth_All_Relay_Status_Column_View:['', Validators.required],
    Auth_All_Live_Status_Column_View:['', Validators.required],
    Auth_All_Antenna_Column_View:['', Validators.required],
    Auth_All_Sim_Type_Column_View:['', Validators.required],
    Auth_All_Action_Column_View:['', Validators.required],

    Auth_Sub_Menu_Add_New_Level: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Level_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Level_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Level_Button: ['', Validators.required],
    
    Auth_Sub_Menu_Add_DG: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_DG_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_DG_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_DG_Button: ['', Validators.required],

    Auth_Sub_Menu_Add_Meter_Range: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Meter_Range_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Meter_Range_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Meter_Range_Button: ['', Validators.required],
    
    Auth_Sub_Menu_Add_Tower: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Tower_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Tower_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Tower_Button: ['', Validators.required],
    
    Auth_Sub_Menu_Add_Group: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Group_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Group_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Group_Button: ['', Validators.required],
    
    
    Auth_Sub_Menu_Add_Authority: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Authority_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Authority_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Authority_Button: ['', Validators.required],

    Auth_Sub_Menu_Add_Contractor: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Contractor_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Contractor_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Contractor_Button: ['', Validators.required],

    Auth_Sub_Menu_Add_Teriff: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Teriff_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Modify_Teriff_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Teriff_Button: ['', Validators.required],
    Auth_Sub_Menu_Add_Consumer_Notification: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Notification_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Notification_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Notification_Button: ['', Validators.required],
    Auth_Sub_Menu_Add_New_Meter_Command: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Meter_Command_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Meter_Command_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Meter_Command_Button: ['', Validators.required],

   
    Auth_Sub_Menu_Add_New_Scheduler: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Scheduler_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Scheduler_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Scheduler_Button: ['', Validators.required],

    Auth_Sub_Menu_Refund: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button: ['', Validators.required],

    Auth_Sub_Menu_Sub_Pending_Demands: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button: ['', Validators.required],


    Auth_Sub_Menu_Reports: ['', Validators.required],
    Auth_Sub_Menu_Sub_All_Authority_Info_Report: ['', Validators.required],
    Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report: ['', Validators.required],
    Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report: ['', Validators.required],
    Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report: ['', Validators.required],
   
    Consumer_Menu: ['', Validators.required],
    Consumer_Sub_Add_Consumer: ['', Validators.required],
    Consumer_Sub_Add_Consumer_Button: ['', Validators.required],
    Consumer_Sub_Consumer_Recharge_Button: ['', Validators.required],
    Consumer_Sub_Consumer_Adjustment_Button: ['', Validators.required],
    Consumer_Sub_Edit_Consumer_Info_Button: ['', Validators.required],
    Consumer_Sub_Edit_Consumer_Permission_Button: ['', Validators.required],
    Consumer_Sub_Delete_Consumer_Button: ['', Validators.required],
    Consumer_Sub_Add_Consumer_Permissions: ['', Validators.required],
    Consumer_Sub_Add_Consumer_Complaint: ['', Validators.required],
   
    Consumer_Sub_Edit_Consumer_Complaint_Button: ['', Validators.required],
    Consumer_Sub_Delete_Consumer_Complaint_Button: ['', Validators.required],
    Consumer_Sub_Reports: ['', Validators.required],
    Consumer_Sub_All_Cons_Info_Report: ['', Validators.required],
    Consumer_Sub_All_Cons_Login_Logout_Report: ['', Validators.required],
    Consumer_Sub_All_Cons_Complaint_Report: ['', Validators.required],
    Consumer_Sub_All_Cons_Notifications_Report: ['', Validators.required],

    contractor_Menu: ['', Validators.required],
    contractor_add: ['', Validators.required],
    contractor_modify: ['', Validators.required],
    contractor_add_superviser: ['', Validators.required],
    contractor_view_superviser: ['', Validators.required],
    contractor_delete: ['', Validators.required],

    contractor_superviser: ['', Validators.required],
    contractor_modify_superviser: ['', Validators.required],
    contractor_delete_superviser: ['', Validators.required],

    contractor_issued_meters_range_menu: ['', Validators.required],
    contractor_issued_meters_range: ['', Validators.required],
    contractor_modify_meters_range: ['', Validators.required],
    contractor_delete_meters_range: ['', Validators.required],

    contractor_progress: ['', Validators.required],
    contractor_modify_payment: ['', Validators.required],
    
    Contractor_Sub_Reports: ['', Validators.required],
    contractor_info_Report: ['', Validators.required],
    contractor_superviser_info_Report: ['', Validators.required],
    contractor_faulty_meters_Report: ['', Validators.required],
    contractor_installed_meters_report: ['', Validators.required],
    contractor_payment_pending_report: ['', Validators.required],
    contractor_payment_cleared_report: ['', Validators.required],

    Meters_Menu: ['', Validators.required],
    DCU_Sub_Add_Meter: ['', Validators.required],
    DCU_Sub_Edit_Meter: ['', Validators.required],
    DCU_Sub_Delete_Meter: ['', Validators.required],
    Meters_Sub_Configure_Meter: ['', Validators.required],
    Meters_Sub_Configure_Load_Limit_On_Meter: ['', Validators.required],
  
    Meters_Sub_Configure_Server_Details_On_Meter: ['', Validators.required],
 
    Meters_Sub_Configure_Happy_Hours_On_Meter: ['', Validators.required],
  
    Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter: ['', Validators.required],
    Meters_Sub_Activity_On_Meter: ['', Validators.required],

    Meters_Sub_Activity_Sub_Meter_Load_On_Off: ['', Validators.required],
    Meters_Sub_Activity_Sub_Backup_Supply_On_Off: ['', Validators.required],
    Meters_Sub_Activity_Sub_DG_Free_Units_On_Off: ['', Validators.required],
    Meters_Sub_Activity_Sub_Emergency_On: ['', Validators.required],
    Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post: ['', Validators.required],
 
    
    Meters_Sub_Reporting_From_Meter: ['', Validators.required],
   
    Meters_Sub_Reporting_Sub_Get_Full_Billing_Data: ['', Validators.required],
 
    Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data: ['', Validators.required],
    
    Meters_Sub_Reporting_Sub_Get_Instant_Data: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status: ['', Validators.required],
    Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status: ['', Validators.required],
    
    Auth_Sub_Menu_Add_Meter_Firmware: ['', Validators.required],
    Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button: ['', Validators.required],
    Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button: ['', Validators.required],

 
    Meter_Sub_Modify_Meter: ['', Validators.required],
    Meter_Sub_Recharge_Button: ['', Validators.required],
    Meter_Sub_Adjustment_Button: ['', Validators.required],
    Meter_Sub_Configure_Meter_Button: ['', Validators.required],
    Meter_Sub_Activity_On_Meter_Button: ['', Validators.required],

    Meter_Sub_Activity_On_Meter_Load_On_Off_Button: ['', Validators.required],
    Meter_Sub_Activity_On_DG_Supply_On_Off_Button: ['', Validators.required],
    Meter_Sub_Activity_On_Emergency_Credit_On_Button: ['', Validators.required],
    Meter_Sub_Activity_On_Meter_MD_Reset_Button: ['', Validators.required],
    Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button: ['', Validators.required],
    Meter_Sub_Activity_On_Meter_DG_Free_Units_Button: ['', Validators.required],

    Meter_Sub_Meter_Download_Option_Button: ['', Validators.required],


    Meter_Sub_Meter_Download_Instant_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Billing_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Billing_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Block_Load_Survey_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Daily_Load_Survey_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Tamper_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Voltage_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Voltage_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Current_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Current_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Power_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Power_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Transaction_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Transact_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Other_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Other_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Cover_Open_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_All_Control_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Selective_Control_Event_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Load_Limit_Config_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Happy_Hours_Config_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Alarm_Level_Config_Button: ['', Validators.required],
    Meter_Sub_Meter_Download_Billing_Date_Config_Button: ['', Validators.required],

    Meter_Sub_Meter_Real_Time_Data_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Recharge_History_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Adjustment_History_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Bill_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Bills_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Events_Button: ['', Validators.required],
    Meter_Sub_Meter_OnGoing_Consumption_Button: ['', Validators.required],
    Meter_Sub_Meter_Daily_Consumptiion_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Activity_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_SMS_Sent_Button: ['', Validators.required],
    Meter_Sub_Meter_Billing_Teriff_Status_Button: ['', Validators.required],
    Meter_Sub_Meter_Load_Limit_Config_Status_Button: ['', Validators.required],
    Meter_Sub_Meter_Happy_Hours_Config_Status_Button: ['', Validators.required],
    Meter_Sub_Meter_Billing_Scheduler_Status_Button: ['', Validators.required],
    Meter_Sub_Meter_Billing_Teriff_From_Meter_Button: ['', Validators.required],
    Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button: ['', Validators.required],
    Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button: ['', Validators.required],
    Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button: ['', Validators.required],
    Meter_Sub_Meter_Recent_Complaints_Button: ['', Validators.required],
    Meter_Sub_Meter_Delete_Meter_Button: ['', Validators.required],
    

    Meters_Reports: ['', Validators.required],
    Meters_Reports_Meters_Information_Report: ['', Validators.required],
    Meters_Reports_Meters_Teriff: ['', Validators.required],
 
    Meters_Reports_Activity_Log_Report: ['', Validators.required],
    Meters_Reports_Authority_Wise_Activity_Log_Report: ['', Validators.required],
  
    Meters_Reports_Current_Data_Report: ['', Validators.required],
    
    Meters_Reports_Meter_Wise_Billing_Data_Report: ['', Validators.required],
   
    Meters_Reports_Latest_Tamper_Data_Report: ['', Validators.required],
   
    Meters_Reports_Daily_Unit_Consumption_Report: ['', Validators.required],

    Meters_Reports_Daily_Unit_Energy_Report: ['', Validators.required],
   
    Meters_Reports_Monthly_Unit_Consumption_Report: ['', Validators.required],
    
    Meters_Reports_Daily_Total_Revenue_Report: ['', Validators.required],

    Meters_Reports_Daily_Total_Revenue_Report_failed: ['', Validators.required],
    
    Meters_Reports_Daily_Low_Balance_Report: ['', Validators.required],
    Meters_Reports_Daily_Zero_Balance_Report: ['', Validators.required],
    Meters_Reports_Daily_Dead_Meters_Report: ['', Validators.required],
   
    Meters_Reports_SMS_Sent_Report: ['', Validators.required],
    Meters_Reports_SLA_Report: ['', Validators.required],
    Meters_Reports_Daily_DG_Status_Report: ['', Validators.required],
    Meters_Reports_Daily_Total_Adjustment_Report: ['', Validators.required],
    Meters_Reports_Daily_Total_Adjustment_Report_Failed: ['', Validators.required],
    Meters_Reports_Load_Limit_Config_Report: ['', Validators.required],
    Meters_Reports_Tariff_Config_Report: ['', Validators.required],
    Meters_Reports_Tariff_Config_Slab_Report: ['', Validators.required],
    Meters_Reports_Happy_Hours_Report: ['', Validators.required],
    Meters_Reports_Daily_OnGoing_Consumption_Report: ['', Validators.required],
    Meters_Reports_Bill_Creation_Report: ['', Validators.required],
    Active_All_Permission_Access: ['', Validators.required],
    Meters_Sub_Disabled_Meters: ['', Validators.required],
    Meters_Sub_Disabled_Meters_Modify: ['', Validators.required],
    Meters_Sub_Disabled_Meters_Delete: ['', Validators.required],

    
    Meters_Sub_Configure_Utility_Meter:  ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Demand_Integration_Period_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config:   ['', Validators.required],
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Billing_Date_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Billing_Date_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Billing_Date_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Push_Schedule_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Push_Schedule_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Push_Schedule_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Relay_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Relay_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Relay_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config : ['', Validators.required],

    Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Time_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Time_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Time_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Password_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Password_High_Level_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Enc_Auth_Key_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config : ['', Validators.required],

    Meters_Sub_Configure_Utility_Load_Limiter_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Load_Limiter_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_Load_Limiter_Write_Config : ['', Validators.required],

    Meters_Sub_Configure_Utility_TOD_Zone_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_1_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_2_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_3_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_4_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_5_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_6_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_7_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_8_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_TOD_Zone_Write_Config : ['', Validators.required],

    
    Meters_Sub_Configure_Utility_ESW_Three_Phase_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Influb_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config : ['', Validators.required],

    Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config  : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Read_Config : ['', Validators.required],
    Meters_Sub_Configure_Utility_ESW_Single_Write_Config: ['', Validators.required],
    smart_meter_order_add: ['', Validators.required],
    smart_meter_order_view_serial_no: ['', Validators.required],
    smart_meter_order_add_serial_no: ['', Validators.required],
    smart_meter_order_edit_by_production: ['', Validators.required],
    smart_meter_order_edit_by_account: ['', Validators.required],
    smart_meter_order_edit_by_marketing: ['', Validators.required],
    smart_meter_order_delete_order: ['', Validators.required]

  });
  }

  get pf() { return this.addAuthorityForm.controls; }
  onSubmitAddAuthority(){
    let seletedPerms = [];
    
    if(this.pf.Active_All_Permission_Access.value == true){

    seletedPerms.push('smart_meter_order_add');
    seletedPerms.push('smart_meter_order_view_serial_no');
    seletedPerms.push('smart_meter_order_add_serial_no');
    seletedPerms.push('smart_meter_order_edit_by_production');
    seletedPerms.push('smart_meter_order_edit_by_account');
    seletedPerms.push('smart_meter_order_edit_by_marketing');
    seletedPerms.push('smart_meter_order_delete_order');

    seletedPerms.push('Admin_Non_Comm_Count');
    seletedPerms.push('Admin_User_Remark_Revert');
    seletedPerms.push('Admin_User_Remark_Revert_ADD_Button');
    seletedPerms.push('Admin_User_Remark_Revert_Edit_Button');
    seletedPerms.push(' Admin_User_Remark_Remove_Button');
    seletedPerms.push('Auth_Menu');

    seletedPerms.push('Meters_Sub_Configure_Load_Limit_On_Meter_3_PH');
    seletedPerms.push('Meters_Sub_Configure_Bill_date');
    seletedPerms.push('Meters_Sub_Configure_Relay_Control');
    seletedPerms.push('Meters_Sub_Configure_ESW_1_PH');
    seletedPerms.push('Meters_Sub_Configure_ESW_3_PH');
    seletedPerms.push('Meters_Sub_Configure_Periodic_Push');

    seletedPerms.push('Auth_Non_Communicated');
    seletedPerms.push('Auth_Non_Com_Circle');
    seletedPerms.push('Auth_Non_Com_Authority');
    seletedPerms.push('Auth_Non_Com_Catergory');
    seletedPerms.push('Auth_Non_Com_Date');
    seletedPerms.push('Auth_Non_Com_Sim_Type');
    seletedPerms.push('Meters_Reports_PD_Meters_Report');

    seletedPerms.push('Auth_All_Griphical_Deshboard');
    seletedPerms.push('Auth_All_Grid_Deshboard');
    seletedPerms.push('Auth_Sub_Menu_Sub_New_Meter');
    seletedPerms.push('Auth_Sub_Menu_Sub_Old_New_Meter');
    seletedPerms.push('Auth_Sub_Menu_Sub_Old_Meter_Modify_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Old_Meter_Image_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_New_Meter_Image_Button');

    seletedPerms.push('Auth_All_Griphical_Desh_Grid_Society_View');
    seletedPerms.push('Auth_All_Griphical_Desh_Grid_Utility_View');
    seletedPerms.push('Auth_All_Griphical_Society_Deshboard');
    seletedPerms.push('Auth_All_Griphical_grid_kWH');

    seletedPerms.push('Auth_All_Meter_Installed_Status');
    seletedPerms.push('Auth_All_Meter_Installed_Status_New');
    seletedPerms.push('Auth_All_Meter_Installed_Circle');
    seletedPerms.push('Auth_All_Meter_Installed_Authority');
    seletedPerms.push('Auth_All_Meter_Single_Ph_Show');
    seletedPerms.push('Auth_All_Meter_Three_Ph_Show');
    seletedPerms.push('Auth_All_Meter_LTCT_Ph_Show');



    seletedPerms.push('Meters_Reports_Old_New_Meters_Information_Report');
    seletedPerms.push('Meters_Reports_Day_Wise_Meters_Information_Report');
    seletedPerms.push('Meters_Reports_Day_Wise_Non_Comunicated_Report');
    seletedPerms.push('Meters_Reports_Replaced_Meters_Information_Report');
    seletedPerms.push('Meters_Reports_Replaced_Meters_History_Report');
    seletedPerms.push('Comulative_Fault_Report');
    seletedPerms.push('Removed_Meters_Report');
    seletedPerms.push('Stock_Analysis_Report');
    seletedPerms.push('User_Work_Info_Report');

    
    seletedPerms.push('Auth_All_Griphical_recharges');
    seletedPerms.push('Auth_All_Griphical_grid_kWH_Utility');
    seletedPerms.push('Auth_All_Griphical_grid_kVARH_Utility');
    seletedPerms.push('Auth_All_Griphical_grid_kVAH_Utility');
    
    seletedPerms.push('Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility');
    seletedPerms.push('Auth_All_Griphical_grid_kWH_Daily_Cons_Utility');
    seletedPerms.push('Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility');
    seletedPerms.push('Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility');

    seletedPerms.push('Auth_All_Griphical_User_Consumption_Chart');
    seletedPerms.push('Auth_All_Griphical_User_Consumption_Daily');
    seletedPerms.push('Auth_All_Griphical_User_Consumption_Monthly');
    seletedPerms.push('Auth_All_Griphical_User_Consumption_Yearly');
    
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Chart');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters');
    
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters');
    seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters');
    
    seletedPerms.push('Auth_All_Griphical_Power_Cut_Chat');
    seletedPerms.push('Auth_All_Griphical_Power_Cut_Daily');
    seletedPerms.push('Auth_All_Griphical_Power_Cut_Monthly');
    seletedPerms.push('Auth_All_Griphical_Power_Cut_Yearly');

    seletedPerms.push('Auth_All_Grid_Voltage_R_Column');
    seletedPerms.push('Auth_All_Grid_Voltage_Y_Column');
    seletedPerms.push('Auth_All_Grid_Voltage_B_Column');
    seletedPerms.push('Auth_All_Grid_Phase_Current_R_Column');
    seletedPerms.push('Auth_All_Grid_Phase_Current_Y_Column');
    seletedPerms.push('Auth_All_Grid_Phase_Current_B_Column');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_R_Column');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_Y_Column');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_B_Column');
    seletedPerms.push('Auth_All_Grid_PF_R_Column');
    seletedPerms.push('Auth_All_Grid_PF_Y_Column');
    seletedPerms.push('Auth_All_Grid_PF_B_Column');
    seletedPerms.push('Auth_All_Grid_Total_Power_Factor_Column');
    seletedPerms.push('Auth_All_Grid_Frequency_Column');
    seletedPerms.push('Auth_All_Grid_Active_Power_Column');
    seletedPerms.push('Auth_All_Grid_Reactive_Power_Column');
    seletedPerms.push('Auth_All_Grid_ApperantPower_kVA_Column');
    seletedPerms.push('Auth_All_Grid_Md_Kw_Column');
    seletedPerms.push('Auth_All_Grid_Md_kVA_Column');
  

    seletedPerms.push('Auth_All_DG_Filter');
    seletedPerms.push('Auth_All_Tamper_Filter');
    seletedPerms.push('Auth_All_Group_Filter');
    seletedPerms.push('Auth_All_Meter_Filter');
    seletedPerms.push('Auth_All_Search_Meter_Id_Filter');
    seletedPerms.push('Auth_All_Cons_UId_Column');
    seletedPerms.push('Auth_All_Meter_UId_Column');

    seletedPerms.push('Auth_All_Cons_DG_Column');
    seletedPerms.push('Auth_All_Cons_Tower_Column');
    seletedPerms.push('Auth_All_Cons_Group_Column');

    seletedPerms.push('Auth_All_Flat_Id_Column');
    seletedPerms.push('Auth_All_Meter_No_Column');
    seletedPerms.push('Auth_All_Meter_Ip_Column');
    seletedPerms.push('Auth_All_Hes_Id_Column');
    seletedPerms.push('Auth_All_Meter_Type_Column');
    seletedPerms.push('Auth_All_LL_Grid_kW_Column');
    seletedPerms.push('Auth_All_LL_DG_kW_Column');
    seletedPerms.push(' Auth_All_Last_Update_Column');
    seletedPerms.push('Auth_All_Grid_kWH_Column');
    seletedPerms.push('Auth_All_Grid_kVARH_Q1_Column');
    seletedPerms.push('Auth_All_Grid_kVARH_Q2_Column');
    seletedPerms.push('Auth_All_Grid_kVARH_Q3_Column');
    seletedPerms.push('Auth_All_Grid_kVARH_Q4_Column');
    seletedPerms.push('Auth_All_Grid_kVAH_Column');
    seletedPerms.push('Auth_All_DG_kWH_Column');
    seletedPerms.push('Auth_All_DG_kVAH_Column');
    seletedPerms.push('Auth_All_Balance_Column');
    seletedPerms.push('Auth_All_Supply_Column');
    seletedPerms.push('Auth_All_Tamper_Column');
    seletedPerms.push('Auth_All_Last_LS_DT_Column');
    seletedPerms.push('Auth_All_Last_Block_LS_DT_Column');
    seletedPerms.push('Auth_All_Tamper_Count_Column');
    seletedPerms.push('Auth_All_Billng_Count_Column');
    seletedPerms.push('Auth_All_Relay_Status_Column');
    seletedPerms.push('Auth_All_DG_Supply_Status_Column');
    seletedPerms.push('Auth_All_Live_Status_Column');
    seletedPerms.push('Auth_All_Antenna_Column');
    seletedPerms.push('Auth_All_Sim_Type_Column');
    seletedPerms.push('Auth_All_Action_Column');
   
    
    seletedPerms.push('Auth_All_Cons_UId_Column_View');
    seletedPerms.push('Auth_All_Cons_DG_Column_View');
    seletedPerms.push('Auth_All_Cons_Tower_Column_View');
    seletedPerms.push('Auth_All_Cons_Group_Column_View');
    seletedPerms.push('Auth_All_Meter_UId_Column_View');
    seletedPerms.push('Auth_All_Flat_Id_Column_View');
    seletedPerms.push('Auth_All_Meter_No_Column_View');
    seletedPerms.push('Auth_All_Meter_Ip_Column_View');
    seletedPerms.push('Auth_All_Hes_Id_Column_View');
    seletedPerms.push('Auth_All_Meter_Type_Column_View');
    seletedPerms.push('Auth_All_LL_Grid_kW_Column_View');
    seletedPerms.push('Auth_All_LL_DG_kW_Column_View');
    seletedPerms.push('Auth_All_Last_Update_Column_View');
    seletedPerms.push('Auth_All_Grid_Voltage_R_Column_View');
    seletedPerms.push('Auth_All_Grid_Voltage_Y_Column_View');
    seletedPerms.push('Auth_All_Grid_Voltage_B_Column_View');
    seletedPerms.push('Auth_All_Grid_Phase_Current_R_Column_View');
    seletedPerms.push('Auth_All_Grid_Phase_Current_Y_Column_View');
    seletedPerms.push('Auth_All_Grid_Phase_Current_B_Column_View');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_R_Column_View');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_Y_Column_View');
    seletedPerms.push('Auth_All_Grid_Nutural_Current_B_Column_View');
    seletedPerms.push('Auth_All_Grid_PF_R_Column_View');
    seletedPerms.push('Auth_All_Grid_PF_Y_Column_View');
    seletedPerms.push('Auth_All_Grid_PF_B_Column_View');
    seletedPerms.push('Auth_All_Grid_Total_Power_Factor_Column_View');
    seletedPerms.push('Auth_All_Grid_Frequency_Column_View');
    seletedPerms.push('Auth_All_Grid_Active_Power_Column_View');
    seletedPerms.push('Auth_All_Grid_Reactive_Power_Column_View');
    seletedPerms.push('Auth_All_Grid_ApperantPower_kVA_Column_View');
    seletedPerms.push('Auth_All_Grid_Md_Kw_Column_View');
    seletedPerms.push('Auth_All_Grid_Md_kVA_Column_View');
    seletedPerms.push('Auth_All_Grid_kWH_Column_View');
    seletedPerms.push('Auth_All_Grid_kVARH_Q1_Column_View');
    seletedPerms.push('Auth_All_Grid_kVARH_Q2_Column_View');
    seletedPerms.push('Auth_All_Grid_kVARH_Q3_Column_View');
    seletedPerms.push('Auth_All_Grid_kVARH_Q4_Column_View');
    seletedPerms.push('Auth_All_Grid_kVAH_Column_View');
    seletedPerms.push('Auth_All_DG_kWH_Column_View');
    seletedPerms.push('Auth_All_DG_kVAH_Column_View');
    seletedPerms.push('Auth_All_Balance_Column_View');
    seletedPerms.push('Auth_All_Supply_Column_View');
    seletedPerms.push('Auth_All_Tamper_Column_View');
    seletedPerms.push('Auth_All_Last_LS_DT_Column_View');
    seletedPerms.push('Auth_All_Last_Block_LS_DT_Column_View');
    seletedPerms.push('Auth_All_Tamper_Count_Column_View');
    seletedPerms.push('Auth_All_Billng_Count_Column_View');
    seletedPerms.push('Auth_All_Relay_Status_Column_View');
    seletedPerms.push('Auth_All_Live_Status_Column_View');
    seletedPerms.push('Auth_All_Antenna_Column_View');
    seletedPerms.push('Auth_All_Sim_Type_Column_View');
    seletedPerms.push('Auth_All_Action_Column_View');

    seletedPerms.push('Auth_Sub_Menu_Add_New_Level');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Level_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Level_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Level_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_DG');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_DG_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_DG_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_DG_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Meter_Range');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Range_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Range_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Range_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Meter_Firmware');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Tower');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Tower_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Tower_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Tower_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Group');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Group_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Group_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Group_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Authority');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Authority_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Authority_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Authority_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Contractor');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Contractor_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Contractor_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Contractor_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_Teriff');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Teriff_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Modify_Teriff_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Teriff_Button');
    seletedPerms.push('Auth_Sub_Menu_Add_Consumer_Notification');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Notification_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Notification_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Notification_Button');

    seletedPerms.push('Auth_Sub_Menu_Add_New_Meter_Command');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Command_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Command_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Command_Button');
    
    seletedPerms.push('Auth_Sub_Menu_Add_New_Scheduler');
    seletedPerms.push('Auth_Sub_Menu_Sub_Add_Scheduler_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Scheduler_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Scheduler_Button');

    seletedPerms.push('Auth_Sub_Menu_Refund');
    seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button');

    
    seletedPerms.push('Auth_Sub_Menu_Sub_Pending_Demands');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button');
    seletedPerms.push('Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button');
    
    
    seletedPerms.push('Auth_Sub_Menu_Reports');
    seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report');
    seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Info_Report');
    seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report');
    seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report');
    seletedPerms.push('Meters_Sub_Disabled_Meters');
    seletedPerms.push('Meters_Sub_Disabled_Meters_Modify');
    seletedPerms.push('Meters_Sub_Disabled_Meters_Delete');
  
    seletedPerms.push('Consumer_Menu');
    seletedPerms.push('Consumer_Sub_Add_Consumer');
    seletedPerms.push('Consumer_Sub_Add_Consumer_Button');

    seletedPerms.push('Consumer_Sub_Consumer_Recharge_Button');
    seletedPerms.push('Consumer_Sub_Consumer_Adjustment_Button');

    seletedPerms.push('Consumer_Sub_Edit_Consumer_Info_Button');
    seletedPerms.push('Consumer_Sub_Edit_Consumer_Permission_Button');
    seletedPerms.push('Consumer_Sub_Delete_Consumer_Button');
    seletedPerms.push('Consumer_Sub_Add_Consumer_Permissions');
    seletedPerms.push('Consumer_Sub_Add_Consumer_Complaint');
   
    seletedPerms.push('Consumer_Sub_Edit_Consumer_Complaint_Button');
    seletedPerms.push('Consumer_Sub_Delete_Consumer_Complaint_Button');
    seletedPerms.push('Consumer_Sub_Reports');
    seletedPerms.push('Consumer_Sub_All_Cons_Info_Report');
    seletedPerms.push('Consumer_Sub_All_Cons_Login_Logout_Report');
    seletedPerms.push('Consumer_Sub_All_Cons_Complaint_Report');
    seletedPerms.push('Consumer_Sub_All_Cons_Notifications_Report');

    seletedPerms.push('contractor_Menu');
    seletedPerms.push('contractor_add');
    seletedPerms.push('contractor_modify');
    seletedPerms.push('contractor_add_superviser');
    seletedPerms.push('contractor_view_superviser');
    seletedPerms.push('contractor_delete');

    seletedPerms.push('contractor_superviser');
    seletedPerms.push('contractor_modify_superviser');
    seletedPerms.push('contractor_delete_superviser');

    seletedPerms.push('contractor_issued_meters_range_menu');
    seletedPerms.push('contractor_issued_meters_range');
    seletedPerms.push('contractor_modify_meters_range');
    seletedPerms.push('contractor_delete_meters_range');

    seletedPerms.push('contractor_progress');
    seletedPerms.push('contractor_modify_payment');
    
    seletedPerms.push('Contractor_Sub_Reports');
    seletedPerms.push('contractor_info_Report');
    seletedPerms.push('contractor_superviser_info_Report');
    seletedPerms.push('contractor_faulty_meters_Report');
    seletedPerms.push('contractor_installed_meters_report');
    seletedPerms.push('contractor_payment_pending_report');
    seletedPerms.push('contractor_payment_cleared_report');


    seletedPerms.push('Meters_Menu');
  
    seletedPerms.push('Meter_Sub_Modify_Meter');
    seletedPerms.push('Meter_Sub_Recharge_Button');
    seletedPerms.push('Meter_Sub_Adjustment_Button');
    seletedPerms.push('Meter_Sub_Configure_Meter_Button');
    seletedPerms.push('Meter_Sub_Activity_On_Meter_Button');

    seletedPerms.push('Meter_Sub_Activity_On_Meter_Load_On_Off_Button');
    seletedPerms.push('Meter_Sub_Activity_On_DG_Supply_On_Off_Button');
    seletedPerms.push('Meter_Sub_Activity_On_Emergency_Credit_On_Button');
    seletedPerms.push('Meter_Sub_Activity_On_Meter_MD_Reset_Button');
    seletedPerms.push('Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button');
    seletedPerms.push('Meter_Sub_Activity_On_Meter_DG_Free_Units_Button');

    seletedPerms.push('Meter_Sub_Meter_Download_Option_Button');


    
    seletedPerms.push('Meter_Sub_Meter_Download_Instant_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Billing_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Billing_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Block_Load_Survey_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Daily_Load_Survey_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Tamper_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Voltage_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Voltage_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Current_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Current_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Power_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Power_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Transaction_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Transact_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Other_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Other_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Cover_Open_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_All_Control_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Selective_Control_Event_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Load_Limit_Config_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Happy_Hours_Config_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Alarm_Level_Config_Button');
    seletedPerms.push('Meter_Sub_Meter_Download_Billing_Date_Config_Button');

    seletedPerms.push('Meter_Sub_Meter_Real_Time_Data_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Recharge_History_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Adjustment_History_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Bill_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Bills_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Events_Button');
    seletedPerms.push('Meter_Sub_Meter_OnGoing_Consumption_Button');
    seletedPerms.push('Meter_Sub_Meter_Daily_Consumptiion_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Activity_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_SMS_Sent_Button');
    seletedPerms.push('Meter_Sub_Meter_Billing_Teriff_Status_Button');
    seletedPerms.push('Meter_Sub_Meter_Load_Limit_Config_Status_Button');
    seletedPerms.push('Meter_Sub_Meter_Happy_Hours_Config_Status_Button');
    seletedPerms.push('Meter_Sub_Meter_Billing_Scheduler_Status_Button');
    seletedPerms.push('Meter_Sub_Meter_Billing_Teriff_From_Meter_Button');
    seletedPerms.push('Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button');
    seletedPerms.push('Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button');
    seletedPerms.push('Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button');
    seletedPerms.push('Meter_Sub_Meter_Recent_Complaints_Button');
    seletedPerms.push('Meter_Sub_Meter_Delete_Meter_Button');
    
    seletedPerms.push('Meters_Sub_Configure_Meter');
    seletedPerms.push('Meters_Sub_Configure_Load_Limit_On_Meter');
   
    seletedPerms.push('Meters_Sub_Configure_Server_Details_On_Meter');
   
    seletedPerms.push('Meters_Sub_Configure_Happy_Hours_On_Meter');
  
    seletedPerms.push('Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter');
    seletedPerms.push('Meters_Sub_Activity_On_Meter');
  
    seletedPerms.push('Meters_Sub_Activity_Sub_Meter_Load_On_Off');
    seletedPerms.push('Meters_Sub_Activity_Sub_Backup_Supply_On_Off');
    seletedPerms.push('Meters_Sub_Activity_Sub_DG_Free_Units_On_Off');
   
    seletedPerms.push('Meters_Sub_Activity_Sub_Emergency_On');
    seletedPerms.push('Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post');

    seletedPerms.push('Meters_Sub_Reporting_From_Meter');
 
    seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Billing_Data');
 
    seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Config_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Router_Config_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Hardware_Status');

    seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Instant_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status');
    seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status');
    
    seletedPerms.push('Meters_Reports');
    seletedPerms.push('Meters_Reports_Meters_Information_Report');
    seletedPerms.push('Meters_Reports_Meters_Teriff');
  
    seletedPerms.push('Meters_Reports_Activity_Log_Report');
    seletedPerms.push('Meters_Reports_Authority_Wise_Activity_Log_Report');
 
    seletedPerms.push('Meters_Reports_Current_Data_Report');
  
    seletedPerms.push('Meters_Reports_Meter_Wise_Billing_Data_Report');
   
    seletedPerms.push('Meters_Reports_Latest_Tamper_Data_Report');
 
    seletedPerms.push('Meters_Reports_Daily_Unit_Consumption_Report');
  
    seletedPerms.push('Meters_Reports_Daily_Unit_Energy_Report');
  
    seletedPerms.push('Meters_Reports_Monthly_Unit_Consumption_Report');
  
    seletedPerms.push('Meters_Reports_Daily_Total_Revenue_Report');
    seletedPerms.push('Meters_Reports_Daily_Total_Revenue_Report_failed');
   
    seletedPerms.push('Meters_Reports_Daily_Low_Balance_Report');
    seletedPerms.push('Meters_Reports_Daily_Zero_Balance_Report');
    seletedPerms.push('Meters_Reports_Daily_Dead_Meters_Report');
  
    seletedPerms.push('Meters_Reports_SMS_Sent_Report');
    seletedPerms.push('Meters_Reports_SLA_Report');
    seletedPerms.push('Meters_Reports_Daily_DG_Status_Report');
    seletedPerms.push('Meters_Reports_Daily_Total_Adjustment_Report');
    seletedPerms.push('Meters_Reports_Daily_Total_Adjustment_Report_Failed');
    seletedPerms.push('Meters_Reports_Load_Limit_Config_Report');
    seletedPerms.push('Meters_Reports_Tariff_Config_Report');
    seletedPerms.push('Meters_Reports_Tariff_Config_Slab_Report');
    seletedPerms.push('Meters_Reports_Happy_Hours_Report');
    seletedPerms.push('Meters_Reports_Daily_OnGoing_Consumption_Report');
    seletedPerms.push('Meters_Reports_Bill_Creation_Report');

    
    seletedPerms.push('Meters_Sub_Configure_Utility_Meter');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Integration_Period_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config');

    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Time_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Time_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Time_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Password_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Password_High_Level_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Enc_Auth_Key_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config');

    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Write_Config');

    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_1_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_2_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_3_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_4_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_5_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_6_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_7_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_8_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Write_Config');

    
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Phase_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Influb_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config');

    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config ');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Read_Config');
    seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Write_Config');

    }

    if(this.pf.smart_meter_order_add.value == true){
    seletedPerms.push('smart_meter_order_add');
    }
    if(this.pf.smart_meter_order_view_serial_no.value == true){
    seletedPerms.push('smart_meter_order_view_serial_no');
    }
    if(this.pf.smart_meter_order_add_serial_no.value == true){
    seletedPerms.push('smart_meter_order_add_serial_no');
    }
    if(this.pf.smart_meter_order_edit_by_production.value == true){
    seletedPerms.push('smart_meter_order_edit_by_production');
    }
    if(this.pf.smart_meter_order_edit_by_account.value == true){
    seletedPerms.push('smart_meter_order_edit_by_account');
    }
    if(this.pf.smart_meter_order_edit_by_marketing.value == true){
    seletedPerms.push('smart_meter_order_edit_by_marketing');
    }
    if(this.pf.smart_meter_order_delete_order.value == true){
    seletedPerms.push('smart_meter_order_delete_order');
    }

    if(this.pf.Auth_Menu.value == true){
      seletedPerms.push('Auth_Menu');
    }

    if(this.pf.Admin_Non_Comm_Count.value == true){
      seletedPerms.push('Admin_Non_Comm_Count');
    }

    if(this.pf.Admin_User_Remark_Revert.value == true){
      seletedPerms.push('Admin_User_Remark_Revert');
    }


    if(this.pf.Admin_User_Remark_Revert_ADD_Button.value == true){
      seletedPerms.push('Admin_User_Remark_Revert_ADD_Button');
    }

    if(this.pf.Admin_User_Remark_Revert_Edit_Button.value == true){
      seletedPerms.push('Admin_User_Remark_Revert_Edit_Button');
    }

    if(this.pf.Admin_User_Remark_Remove_Button.value == true){
      seletedPerms.push('Admin_User_Remark_Remove_Button');
    }


    if(this.pf. Auth_All_Griphical_Desh_Grid_Society_View.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Grid_Society_View');
    }
    if(this.pf.Auth_All_Griphical_Desh_Grid_Utility_View.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Grid_Utility_View');
    }



    if(this.pf.Auth_Non_Communicated.value == true){
      seletedPerms.push('Auth_Non_Communicated');
    }

    if(this.pf.Auth_Non_Com_Circle.value == true){
      seletedPerms.push('Auth_Non_Com_Circle');
    }
    if(this.pf.Auth_Non_Com_Authority.value == true){
      seletedPerms.push('Auth_Non_Com_Authority');
    }
    if(this.pf.Auth_Non_Com_Catergory.value == true){
      seletedPerms.push('Auth_Non_Com_Catergory');
    }
    if(this.pf.Auth_Non_Com_Date.value == true){
      seletedPerms.push('Auth_Non_Com_Date');
    }
    if(this.pf.Auth_Non_Com_Sim_Type.value == true){
      seletedPerms.push('Auth_Non_Com_Sim_Type');
    }
    if(this.pf.Meters_Reports_PD_Meters_Report.value == true){
      seletedPerms.push('Meters_Reports_PD_Meters_Report');
    }
    if(this.pf.Auth_All_Griphical_Deshboard.value == true){
      seletedPerms.push('Auth_All_Griphical_Deshboard');
    }

    if(this.pf.Auth_All_Grid_Deshboard.value == true){
      seletedPerms.push('Auth_All_Grid_Deshboard');
    }

    if(this.pf.Auth_Sub_Menu_Sub_New_Meter.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_New_Meter');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Old_New_Meter.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Old_New_Meter');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Old_Meter_Modify_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Old_Meter_Modify_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Old_Meter_Image_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Old_Meter_Image_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_New_Meter_Image_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_New_Meter_Image_Button');
    }

    if(this.pf.Auth_All_Griphical_Society_Deshboard.value == true){
      seletedPerms.push('Auth_All_Griphical_Society_Deshboard');
    }

    if(this.pf.Auth_All_Griphical_grid_kWH.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kWH');
    }

    if(this.pf.Auth_All_Meter_Installed_Status.value == true){
      seletedPerms.push('Auth_All_Meter_Installed_Status');
    }
    if(this.pf.Auth_All_Meter_Installed_Status_New.value == true){
      seletedPerms.push('Auth_All_Meter_Installed_Status_New');
    }
    if(this.pf.Auth_All_Meter_Installed_Circle.value == true){
      seletedPerms.push('Auth_All_Meter_Installed_Circle');
    }
    if(this.pf.Auth_All_Meter_Installed_Authority.value == true){
      seletedPerms.push('Auth_All_Meter_Installed_Authority');
    }

    if(this.pf.Auth_All_Meter_Single_Ph_Show.value == true){
      seletedPerms.push('Auth_All_Meter_Single_Ph_Show');
    }

    if(this.pf.Auth_All_Meter_Three_Ph_Show.value == true){
      seletedPerms.push('Auth_All_Meter_Three_Ph_Show');
    }

    if(this.pf.Auth_All_Meter_LTCT_Ph_Show.value == true){
      seletedPerms.push('Auth_All_Meter_LTCT_Ph_Show');
    }


    if(this.pf.Meters_Reports_Old_New_Meters_Information_Report.value == true){
      seletedPerms.push('Meters_Reports_Old_New_Meters_Information_Report');
    }
    if(this.pf.Meters_Reports_Day_Wise_Meters_Information_Report.value == true){
      seletedPerms.push('Meters_Reports_Day_Wise_Meters_Information_Report');
    }
    if(this.pf.Meters_Reports_Day_Wise_Non_Comunicated_Report.value == true){
      seletedPerms.push('Meters_Reports_Day_Wise_Non_Comunicated_Report');
    }
    if(this.pf.Meters_Reports_Replaced_Meters_Information_Report.value == true){
      seletedPerms.push('Meters_Reports_Replaced_Meters_Information_Report');
    }
    if(this.pf.Meters_Reports_Replaced_Meters_History_Report.value == true){
      seletedPerms.push('Meters_Reports_Replaced_Meters_History_Report');
    }

    if(this.pf.Comulative_Fault_Report.value == true){
      seletedPerms.push('Comulative_Fault_Report');
    }

    if(this.pf.Removed_Meters_Report.value == true){
      seletedPerms.push('Removed_Meters_Report');
    }

    if(this.pf.Stock_Analysis_Report.value == true){
      seletedPerms.push('Stock_Analysis_Report');
    }
    if(this.pf.User_Work_Info_Report.value == true){
      seletedPerms.push('User_Work_Info_Report');
    }

    if(this.pf.Auth_All_Griphical_dg_kWH.value == true){
      seletedPerms.push('Auth_All_Griphical_dg_kWH');
    }
    if(this.pf.Auth_All_Griphical_recharges.value == true){
      seletedPerms.push('Auth_All_Griphical_recharges');
    }
    if(this.pf.Auth_All_Griphical_grid_kWH_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kWH_Utility');
    }
    if(this.pf.Auth_All_Griphical_grid_kVARH_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kVARH_Utility');
    }
    if(this.pf.Auth_All_Griphical_grid_kVAH_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kVAH_Utility');
    }

    if(this.pf.Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility');
    }
    if(this.pf.Auth_All_Griphical_grid_kWH_Daily_Cons_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kWH_Daily_Cons_Utility');
    }
    if(this.pf.Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility');
    }
    if(this.pf.Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility.value == true){
      seletedPerms.push('Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility');
    }


    if(this.pf.Auth_All_Griphical_User_Consumption_Chart.value == true){
      seletedPerms.push('Auth_All_Griphical_User_Consumption_Chart');
    }
    if(this.pf.Auth_All_Griphical_User_Consumption_Daily.value == true){
      seletedPerms.push('Auth_All_Griphical_User_Consumption_Daily');
    }
    if(this.pf.Auth_All_Griphical_User_Consumption_Monthly.value == true){
      seletedPerms.push('Auth_All_Griphical_User_Consumption_Monthly');
    }
    if(this.pf.Auth_All_Griphical_User_Consumption_Yearly.value == true){
      seletedPerms.push('Auth_All_Griphical_User_Consumption_Yearly');
    }
   
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters');
    }

    /*if(this.pf.Auth_All_Griphical_User_Consumption_kVAH_DG.value == true){
      seletedPerms.push('Auth_All_Griphical_User_Consumption_kVAH_DG');
    }*/
    if(this.pf.Auth_All_Griphical_Desh_Meter_Statistics_Chart.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Statistics_Chart');
    }
    /*if(this.pf.Auth_All_Griphical_Desh_Meter_Balance_Chart.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Balance_Chart');
    }
    if(this.pf.Auth_All_Griphical_Desh_Meter_Tamper_Chart.value == true){
      seletedPerms.push('Auth_All_Griphical_Desh_Meter_Tamper_Chart');
    }*/
    
    if(this.pf.Auth_All_Griphical_Power_Cut_Chat.value == true){
      seletedPerms.push('Auth_All_Griphical_Power_Cut_Chat');
    }
    if(this.pf.Auth_All_Griphical_Power_Cut_Daily.value == true){
      seletedPerms.push('Auth_All_Griphical_Power_Cut_Daily');
    }
    if(this.pf.Auth_All_Griphical_Power_Cut_Monthly.value == true){
      seletedPerms.push('Auth_All_Griphical_Power_Cut_Monthly');
    }
    if(this.pf.Auth_All_Griphical_Power_Cut_Yearly.value == true){
      seletedPerms.push('Auth_All_Griphical_Power_Cut_Yearly');
    }

    if(this.pf.Meters_Sub_Configure_Load_Limit_On_Meter_3_PH.value == true){
      seletedPerms.push('Meters_Sub_Configure_Load_Limit_On_Meter_3_PH');
    }
    if(this.pf.Meters_Sub_Configure_Bill_date.value == true){
      seletedPerms.push('Meters_Sub_Configure_Bill_date');
    }
    if(this.pf.Meters_Sub_Configure_Relay_Control.value == true){
      seletedPerms.push('Meters_Sub_Configure_Relay_Control');
    }
    if(this.pf.Meters_Sub_Configure_ESW_1_PH.value == true){
      seletedPerms.push('Meters_Sub_Configure_ESW_1_PH');
    }
    if(this.pf.Meters_Sub_Configure_ESW_3_PH.value == true){
      seletedPerms.push('Meters_Sub_Configure_ESW_3_PH');
    }

    if(this.pf.Meters_Sub_Configure_Periodic_Push.value == true){
      seletedPerms.push('Meters_Sub_Configure_Periodic_Push');
    }

    

    if(this.pf.Auth_All_Grid_Voltage_R_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Voltage_R_Column');
    }
    if(this.pf.Auth_All_Grid_Voltage_Y_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Voltage_Y_Column');
    }
    if(this.pf.Auth_All_Grid_Voltage_B_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Voltage_B_Column');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_R_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Phase_Current_R_Column');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_Y_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Phase_Current_Y_Column');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_B_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Phase_Current_B_Column');
    }
     if(this.pf.Auth_All_Grid_Nutural_Current_R_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Nutural_Current_R_Column');
    }
    if(this.pf.Auth_All_Grid_Nutural_Current_Y_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Nutural_Current_Y_Column');
    }
    if(this.pf.Auth_All_Grid_Nutural_Current_B_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Nutural_Current_B_Column');
    }
    if(this.pf.Auth_All_Grid_PF_R_Column.value == true){
      seletedPerms.push('Auth_All_Grid_PF_R_Column');
    }
    if(this.pf.Auth_All_Grid_PF_Y_Column.value == true){
      seletedPerms.push('Auth_All_Grid_PF_Y_Column');
    }
    if(this.pf.Auth_All_Grid_PF_B_Column.value == true){
      seletedPerms.push('Auth_All_Grid_PF_B_Column');
    }
    if(this.pf.Auth_All_Grid_Total_Power_Factor_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Total_Power_Factor_Column');
    }
    if(this.pf.Auth_All_Grid_Frequency_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Frequency_Column');
    }
    if(this.pf.Auth_All_Grid_Active_Power_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Active_Power_Column');
    }
    if(this.pf.Auth_All_Grid_Reactive_Power_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Reactive_Power_Column');
    }
    if(this.pf.Auth_All_Grid_ApperantPower_kVA_Column.value == true){
      seletedPerms.push('Auth_All_Grid_ApperantPower_kVA_Column');
    }
    if(this.pf.Auth_All_Grid_Md_Kw_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Md_Kw_Column');
    }
    if(this.pf.Auth_All_Grid_Md_kVA_Column.value == true){
      seletedPerms.push('Auth_All_Grid_Md_kVA_Column');
    }

    if(this.pf.Auth_All_DG_Filter.value == true){
      seletedPerms.push('Auth_All_DG_Filter');
    }
    if(this.pf.Auth_All_Tamper_Filter.value == true){
      seletedPerms.push('Auth_All_Tamper_Filter');
    }
    if(this.pf.Auth_All_Group_Filter.value == true){
      seletedPerms.push('Auth_All_Group_Filter');
    }
    if(this.pf.Auth_All_Meter_Filter.value == true){
      seletedPerms.push('Auth_All_Meter_Filter');
    }
    if(this.pf.Auth_All_Search_Meter_Id_Filter.value == true){
      seletedPerms.push('Auth_All_Search_Meter_Id_Filter');
    }
    if(this.pf.Auth_All_Cons_UId_Column.value == true){
      seletedPerms.push('Auth_All_Cons_UId_Column');
    }
    if(this.pf.Auth_All_Meter_UId_Column.value == true){
      seletedPerms.push('Auth_All_Meter_UId_Column');
    }

    if(this.pf.Auth_All_Cons_DG_Column.value == true){
      seletedPerms.push('Auth_All_Cons_DG_Column');
    }
    if(this.pf.Auth_All_Cons_Tower_Column.value == true){
      seletedPerms.push('Auth_All_Cons_Tower_Column');
    }
    if(this.pf.Auth_All_Cons_Group_Column.value == true){
      seletedPerms.push('Auth_All_Cons_Group_Column');
    }

    if(this.pf.Auth_All_Flat_Id_Column.value == true){
      seletedPerms.push('Auth_All_Flat_Id_Column');
    }
    if(this.pf.Auth_All_Meter_No_Column.value == true){
      seletedPerms.push('Auth_All_Meter_No_Column');
    }
    if(this.pf.Auth_All_Meter_Ip_Column.value == true){
      seletedPerms.push('Auth_All_Meter_Ip_Column');
    }
    if(this.pf.Auth_All_Hes_Id_Column.value == true){
      seletedPerms.push('Auth_All_Hes_Id_Column');
    }
    if(this.pf.Auth_All_Meter_Type_Column.value == true){
      seletedPerms.push('Auth_All_Meter_Type_Column');
    }
    if(this.pf.Auth_All_LL_Grid_kW_Column.value == true){
      seletedPerms.push('Auth_All_LL_Grid_kW_Column');
    }
    if(this.pf.Auth_All_LL_DG_kW_Column.value == true){
      seletedPerms.push('Auth_All_LL_DG_kW_Column');
    }
    if(this.pf.Auth_All_Last_Update_Column.value == true){
      seletedPerms.push('Auth_All_Last_Update_Column');
    }
    if(this.pf.Auth_All_Grid_kWH_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kWH_Column');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q1_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kVARH_Q1_Column');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q2_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kVARH_Q2_Column');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q3_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kVARH_Q3_Column');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q4_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kVARH_Q4_Column');
    }
    
    if(this.pf.Auth_All_Grid_kVAH_Column.value == true){
      seletedPerms.push('Auth_All_Grid_kVAH_Column');
    }

    if(this.pf.Auth_All_DG_kWH_Column.value == true){
      seletedPerms.push('Auth_All_DG_kWH_Column');
    }
    if(this.pf.Auth_All_DG_kVAH_Column.value == true){
      seletedPerms.push('Auth_All_DG_kVAH_Column');
    }
    if(this.pf.Auth_All_DG_kWH_Column.value == true){
      seletedPerms.push('Auth_All_DG_kWH_Column');
    }
    if(this.pf.Auth_All_Balance_Column.value == true){
      seletedPerms.push('Auth_All_Balance_Column');
    }
    if(this.pf.Auth_All_Supply_Column.value == true){
      seletedPerms.push('Auth_All_Supply_Column');
    }
    if(this.pf.Auth_All_Tamper_Column.value == true){
      seletedPerms.push('Auth_All_Tamper_Column');
    }
    if(this.pf.Auth_All_Last_LS_DT_Column.value == true){
      seletedPerms.push('Auth_All_Last_LS_DT_Column');
    }
    if(this.pf.Auth_All_Last_Block_LS_DT_Column.value == true){
      seletedPerms.push('Auth_All_Last_Block_LS_DT_Column');
    }

    if(this.pf.Auth_All_Tamper_Count_Column.value == true){
      seletedPerms.push('Auth_All_Tamper_Count_Column');
    }
    if(this.pf.Auth_All_Billng_Count_Column.value == true){
      seletedPerms.push('Auth_All_Billng_Count_Column');
    }


    if(this.pf.Auth_All_Relay_Status_Column.value == true){
      seletedPerms.push('Auth_All_Relay_Status_Column');
    }
    if(this.pf.Auth_All_DG_Supply_Status_Column.value == true){
      seletedPerms.push('Auth_All_DG_Supply_Status_Column');
    }
    if(this.pf.Auth_All_Live_Status_Column.value == true){
      seletedPerms.push('Auth_All_Live_Status_Column');
    }
    if(this.pf.Auth_All_Antenna_Column.value == true){
      seletedPerms.push('Auth_All_Antenna_Column');
    }

    if(this.pf.Auth_All_Sim_Type_Column.value == true){
      seletedPerms.push('Auth_All_Sim_Type_Column');
    }

    if(this.pf.Auth_All_Action_Column.value == true){
      seletedPerms.push('Auth_All_Action_Column');
    }

    if(this.pf.Auth_All_Cons_UId_Column_View.value == true){
    seletedPerms.push('Auth_All_Cons_UId_Column_View');
    }
    if(this.pf.Auth_All_Cons_DG_Column_View.value == true){
    seletedPerms.push('Auth_All_Cons_DG_Column_View');
    }
    if(this.pf.Auth_All_Cons_Tower_Column_View.value == true){
    seletedPerms.push('Auth_All_Cons_Tower_Column_View');
    }
    if(this.pf.Auth_All_Cons_Group_Column_View.value == true){
    seletedPerms.push('Auth_All_Cons_Group_Column_View');
    }
    if(this.pf.Auth_All_Meter_UId_Column_View.value == true){
    seletedPerms.push('Auth_All_Meter_UId_Column_View');
    }
    if(this.pf.Auth_All_Flat_Id_Column_View.value == true){
    seletedPerms.push('Auth_All_Flat_Id_Column_View');
    }
    if(this.pf.Auth_All_Meter_No_Column_View.value == true){
    seletedPerms.push('Auth_All_Meter_No_Column_View');
    }
    if(this.pf.Auth_All_Meter_Ip_Column_View.value == true){
    seletedPerms.push('Auth_All_Meter_Ip_Column_View');
    }
    if(this.pf.Auth_All_Hes_Id_Column_View.value == true){
    seletedPerms.push('Auth_All_Hes_Id_Column_View');
    }
    if(this.pf.Auth_All_Meter_Type_Column_View.value == true){
    seletedPerms.push('Auth_All_Meter_Type_Column_View');
    }
    if(this.pf.Auth_All_LL_Grid_kW_Column_View.value == true){
    seletedPerms.push('Auth_All_LL_Grid_kW_Column_View');
    }
    if(this.pf.Auth_All_LL_DG_kW_Column_View.value == true){
    seletedPerms.push('Auth_All_LL_DG_kW_Column_View');
    }
    if(this.pf.Auth_All_Last_Update_Column_View.value == true){
    seletedPerms.push('Auth_All_Last_Update_Column_View');
    }
    if(this.pf.Auth_All_Grid_Voltage_R_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Voltage_R_Column_View');
    }
    if(this.pf.Auth_All_Grid_Voltage_Y_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Voltage_Y_Column_View');
    }
    if(this.pf.Auth_All_Grid_Voltage_B_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Voltage_B_Column_View');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_R_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Phase_Current_R_Column_View');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_Y_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Phase_Current_Y_Column_View');
    }
    if(this.pf.Auth_All_Grid_Phase_Current_B_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Phase_Current_B_Column_View');
    }
    if(this.pf.Auth_All_Grid_Nutural_Current_R_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Nutural_Current_R_Column_View');
    }
    if(this.pf.Auth_All_Grid_Nutural_Current_Y_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Nutural_Current_Y_Column_View');
    }
    if(this.pf.Auth_All_Grid_Nutural_Current_B_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Nutural_Current_B_Column_View');
    }
    if(this.pf.Auth_All_Grid_PF_R_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_PF_R_Column_View');
    }
    if(this.pf.Auth_All_Grid_PF_Y_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_PF_Y_Column_View');
    }
    if(this.pf.Auth_All_Grid_PF_B_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_PF_B_Column_View');
    }
    if(this.pf.Auth_All_Grid_Total_Power_Factor_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Total_Power_Factor_Column_View');
    }
    if(this.pf.Auth_All_Grid_Frequency_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Frequency_Column_View');
    }
    if(this.pf.Auth_All_Grid_Active_Power_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Active_Power_Column_View');
    }
    if(this.pf.Auth_All_Grid_Reactive_Power_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Reactive_Power_Column_View');
    }
    if(this.pf.Auth_All_Grid_ApperantPower_kVA_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_ApperantPower_kVA_Column_View');
    }
    if(this.pf.Auth_All_Grid_Md_Kw_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Md_Kw_Column_View');
    }
    if(this.pf.Auth_All_Grid_Md_kVA_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_Md_kVA_Column_View');
    }
    if(this.pf.Auth_All_Grid_kWH_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kWH_Column_View');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q1_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kVARH_Q1_Column_View');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q2_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kVARH_Q2_Column_View');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q3_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kVARH_Q3_Column_View');
    }
    if(this.pf.Auth_All_Grid_kVARH_Q4_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kVARH_Q4_Column_View');
    }
    if(this.pf.Auth_All_Grid_kVAH_Column_View.value == true){
    seletedPerms.push('Auth_All_Grid_kVAH_Column_View');
    }
    if(this.pf.Auth_All_DG_kWH_Column_View.value == true){
    seletedPerms.push('Auth_All_DG_kWH_Column_View');
    }
    if(this.pf.Auth_All_DG_kVAH_Column_View.value == true){
    seletedPerms.push('Auth_All_DG_kVAH_Column_View');
    }
    if(this.pf.Auth_All_Balance_Column_View.value == true){
    seletedPerms.push('Auth_All_Balance_Column_View');
    }
    if(this.pf.Auth_All_Supply_Column_View.value == true){
    seletedPerms.push('Auth_All_Supply_Column_View');
    }
    if(this.pf.Auth_All_Tamper_Column_View.value == true){
    seletedPerms.push('Auth_All_Tamper_Column_View');
    }
    if(this.pf.Auth_All_Last_LS_DT_Column_View.value == true){
    seletedPerms.push('Auth_All_Last_LS_DT_Column_View');
    }
    if(this.pf.Auth_All_Last_Block_LS_DT_Column_View.value == true){
    seletedPerms.push('Auth_All_Last_Block_LS_DT_Column_View');
    }
    if(this.pf.Auth_All_Tamper_Count_Column_View.value == true){
    seletedPerms.push('Auth_All_Tamper_Count_Column_View');
    }
    if(this.pf.Auth_All_Billng_Count_Column_View.value == true){
    seletedPerms.push('Auth_All_Billng_Count_Column_View');
    }
    if(this.pf.Auth_All_Relay_Status_Column_View.value == true){
    seletedPerms.push('Auth_All_Relay_Status_Column_View');
    }
    if(this.pf.Auth_All_Live_Status_Column_View.value == true){
    seletedPerms.push('Auth_All_Live_Status_Column_View');
    }
    if(this.pf.Auth_All_Antenna_Column_View.value == true){
    seletedPerms.push('Auth_All_Antenna_Column_View');
    }
    if(this.pf.Auth_All_Sim_Type_Column_View.value == true){
    seletedPerms.push('Auth_All_Sim_Type_Column_View');
    }
    if(this.pf.Auth_All_Action_Column_View.value == true){
    seletedPerms.push('Auth_All_Action_Column_View');
    }



    if(this.pf.Auth_Sub_Menu_Add_New_Level.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_New_Level');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Level_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Level_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Level_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Level_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Level_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Level_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Meter_Range.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Meter_Range');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Meter_Range_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Range_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Meter_Range_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Range_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Meter_Range_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Range_Button');
    }


    if(this.pf.Auth_Sub_Menu_Add_DG.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_DG');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_DG_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_DG_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_DG_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_DG_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_DG_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_DG_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Meter_Firmware.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Meter_Firmware');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Tower.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Tower');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Tower_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Tower_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Tower_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Tower_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Tower_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Tower_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Group.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Group');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Group_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Group_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Group_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Group_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Group_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Group_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Authority.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Authority');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Authority_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Authority_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Authority_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Authority_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Authority_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Authority_Button');
    }

    if(this.pf.Auth_Sub_Menu_Add_Contractor.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Contractor');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Contractor_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Contractor_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Contractor_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Contractor_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Contractor_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Contractor_Button');
    }
   
    if(this.pf.Auth_Sub_Menu_Add_Teriff.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Teriff');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Teriff_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Teriff_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Modify_Teriff_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Modify_Teriff_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Teriff_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Teriff_Button');
    }
    if(this.pf.Auth_Sub_Menu_Add_Consumer_Notification.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_Consumer_Notification');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Notification_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Notification_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Notification_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Notification_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Notification_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Notification_Button');
    }
    if(this.pf.Auth_Sub_Menu_Add_New_Meter_Command.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_New_Meter_Command');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Add_Meter_Command_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Meter_Command_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Meter_Command_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Meter_Command_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_Meter_Command_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Meter_Command_Button');
    }
   
    if(this.pf.Auth_Sub_Menu_Reports.value == true){
      seletedPerms.push('Auth_Sub_Menu_Reports');
    }
    if(this.pf.Auth_Sub_Menu_Sub_All_Authority_Info_Report.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Info_Report');
    }
    if(this.pf.Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report');
    }
    if(this.pf.Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report');
    }
    if(this.pf.Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report');
    }
   
    if(this.pf.Consumer_Menu.value == true){
      seletedPerms.push('Consumer_Menu');
    }
    if(this.pf.Consumer_Sub_Add_Consumer.value == true){
      seletedPerms.push('Consumer_Sub_Add_Consumer');
    }
    if(this.pf.Consumer_Sub_Add_Consumer_Button.value == true){
      seletedPerms.push('Consumer_Sub_Add_Consumer_Button');
    }
    
    if(this.pf.Consumer_Sub_Consumer_Recharge_Button.value == true){
      seletedPerms.push('Consumer_Sub_Consumer_Recharge_Button');
    }
    if(this.pf.Consumer_Sub_Consumer_Adjustment_Button.value == true){
      seletedPerms.push('Consumer_Sub_Consumer_Adjustment_Button');
    }

    if(this.pf.Consumer_Sub_Edit_Consumer_Info_Button.value == true){
      seletedPerms.push('Consumer_Sub_Edit_Consumer_Info_Button');
    }
    if(this.pf.Consumer_Sub_Edit_Consumer_Permission_Button.value == true){
      seletedPerms.push('Consumer_Sub_Edit_Consumer_Permission_Button');
    }
    if(this.pf.Consumer_Sub_Delete_Consumer_Button.value == true){
      seletedPerms.push('Consumer_Sub_Delete_Consumer_Button');
    }
    if(this.pf.Consumer_Sub_Add_Consumer_Permissions.value == true){
      seletedPerms.push('Consumer_Sub_Add_Consumer_Permissions');
    }
    if(this.pf.Consumer_Sub_Add_Consumer_Complaint.value == true){
      seletedPerms.push('Consumer_Sub_Add_Consumer_Complaint');
    }
   
    if(this.pf.Consumer_Sub_Edit_Consumer_Complaint_Button.value == true){
      seletedPerms.push('Consumer_Sub_Edit_Consumer_Complaint_Button');
    }
    if(this.pf.Consumer_Sub_Delete_Consumer_Complaint_Button.value == true){
      seletedPerms.push('Consumer_Sub_Delete_Consumer_Complaint_Button');
    }
    if(this.pf.Consumer_Sub_Reports.value == true){
      seletedPerms.push('Consumer_Sub_Reports');
    }
    if(this.pf.Consumer_Sub_All_Cons_Info_Report.value == true){
      seletedPerms.push('Consumer_Sub_All_Cons_Info_Report');
    }
    if(this.pf.Consumer_Sub_All_Cons_Login_Logout_Report.value == true){
      seletedPerms.push('Consumer_Sub_All_Cons_Login_Logout_Report');
    }
    if(this.pf.Consumer_Sub_All_Cons_Complaint_Report.value == true){
      seletedPerms.push('Consumer_Sub_All_Cons_Complaint_Report');
    }
    if(this.pf.Consumer_Sub_All_Cons_Notifications_Report.value == true){
      seletedPerms.push('Consumer_Sub_All_Cons_Notifications_Report');
    }
    if(this.pf.Meters_Menu.value == true){
      seletedPerms.push('Meters_Menu');
    }
   

    if(this.pf.contractor_Menu.value == true){
      seletedPerms.push('contractor_Menu');
    }

    if(this.pf.contractor_add.value == true){
      seletedPerms.push('contractor_add');
    }
    if(this.pf.contractor_modify.value == true){
      seletedPerms.push('contractor_modify');
    }
    if(this.pf.contractor_add_superviser.value == true){
      seletedPerms.push('contractor_add_superviser');
    }
    if(this.pf.contractor_view_superviser.value == true){
      seletedPerms.push('contractor_view_superviser');
    }
    if(this.pf.contractor_delete.value == true){
      seletedPerms.push('contractor_delete');
    }

    if(this.pf.contractor_superviser.value == true){
      seletedPerms.push('contractor_superviser');
    }
    
    if(this.pf.contractor_modify_superviser.value == true){
      seletedPerms.push('contractor_modify_superviser');
    }
    if(this.pf.contractor_progress.value == true){
      seletedPerms.push('contractor_progress');
    }

    if(this.pf.contractor_issued_meters_range_menu.value == true){
      seletedPerms.push('contractor_issued_meters_range_menu');
    }
    
    if(this.pf.contractor_issued_meters_range.value == true){
      seletedPerms.push('contractor_issued_meters_range');
    }
    if(this.pf.contractor_modify_meters_range.value == true){
      seletedPerms.push('contractor_modify_meters_range');
    }
    if(this.pf.contractor_delete_meters_range.value == true){
      seletedPerms.push('contractor_delete_meters_range');
    }

    if(this.pf.contractor_modify_payment.value == true){
      seletedPerms.push('contractor_modify_payment');
    }
    if(this.pf.contractor_delete_superviser.value == true){
      seletedPerms.push('contractor_delete_superviser');
    }
    
    if(this.pf.Contractor_Sub_Reports.value == true){
      seletedPerms.push('Contractor_Sub_Reports');
    }
    if(this.pf.contractor_info_Report.value == true){
      seletedPerms.push('contractor_info_Report');
    }
    if(this.pf.contractor_superviser_info_Report.value == true){
      seletedPerms.push('contractor_superviser_info_Report');
    }
    if(this.pf.contractor_faulty_meters_Report.value == true){
      seletedPerms.push('contractor_faulty_meters_Report');
    }
    if(this.pf.contractor_installed_meters_report.value == true){
      seletedPerms.push('contractor_installed_meters_report');
    }
    if(this.pf.contractor_payment_pending_report.value == true){
      seletedPerms.push('contractor_payment_pending_report');
    }
    if(this.pf.contractor_payment_cleared_report.value == true){
      seletedPerms.push('contractor_payment_cleared_report');
    }

    
   

    if(this.pf.Auth_Sub_Menu_Add_New_Scheduler.value == true){
      seletedPerms.push('Auth_Sub_Menu_Add_New_Scheduler');
    }
      if(this.pf.Auth_Sub_Menu_Sub_Add_Scheduler_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Add_Scheduler_Button');
    }
      if(this.pf.Auth_Sub_Menu_Sub_Edit_Scheduler_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Scheduler_Button');
    }
      if(this.pf.Auth_Sub_Menu_Sub_Delete_Scheduler_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Scheduler_Button');
    }
    
    if(this.pf.Auth_Sub_Menu_Refund.value == true){
      seletedPerms.push('Auth_Sub_Menu_Refund');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button');
    }

    if(this.pf.Auth_Sub_Menu_Sub_Pending_Demands.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Pending_Demands');
    }
      if(this.pf.Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_Pending_Demand_Button');
    }
    if(this.pf.Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button.value == true){
      seletedPerms.push('Auth_Sub_Menu_Sub_Delete_All_Pending_Demand_Button');
    }
    if(this.pf.Meter_Sub_Modify_Meter.value == true){
      seletedPerms.push('Meter_Sub_Modify_Meter');
    }
      if(this.pf.Meter_Sub_Recharge_Button.value == true){
      seletedPerms.push('Meter_Sub_Recharge_Button');
    }
      if(this.pf.Meter_Sub_Adjustment_Button.value == true){
      seletedPerms.push('Meter_Sub_Adjustment_Button');
    }
      if(this.pf.Meter_Sub_Configure_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Configure_Meter_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Meter_Button');
    }

    if(this.pf.Meter_Sub_Activity_On_Meter_Load_On_Off_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Meter_Load_On_Off_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_DG_Supply_On_Off_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_DG_Supply_On_Off_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_Emergency_Credit_On_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Emergency_Credit_On_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_Meter_MD_Reset_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Meter_MD_Reset_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button');
    }
    if(this.pf.Meter_Sub_Activity_On_Meter_DG_Free_Units_Button.value == true){
      seletedPerms.push('Meter_Sub_Activity_On_Meter_DG_Free_Units_Button');
    }

      if(this.pf.Meter_Sub_Meter_Download_Option_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Option_Button');
    }

    if(this.pf.Meter_Sub_Meter_Download_Instant_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Instant_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Billing_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Billing_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Billing_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Billing_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Block_Load_Survey_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Block_Load_Survey_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Daily_Load_Survey_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Daily_Load_Survey_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Tamper_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Tamper_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Voltage_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Voltage_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Voltage_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Current_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Current_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Current_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Current_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Power_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Power_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Power_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Power_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Transaction_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Transaction_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Transact_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Transact_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Other_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Other_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Other_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Other_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Cover_Open_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_All_Control_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_All_Control_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Selective_Control_Event_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Selective_Control_Event_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Load_Limit_Config_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Load_Limit_Config_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Happy_Hours_Config_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Happy_Hours_Config_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Alarm_Level_Config_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Alarm_Level_Config_Button');
    }
    if(this.pf.Meter_Sub_Meter_Download_Billing_Date_Config_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Download_Billing_Date_Config_Button');
    }


    if(this.pf.Meter_Sub_Meter_Real_Time_Data_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Real_Time_Data_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Recharge_History_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Recharge_History_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Adjustment_History_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Adjustment_History_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Bill_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Bill_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Bills_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Bills_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Events_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Events_Button');
    }
      if(this.pf.Meter_Sub_Meter_OnGoing_Consumption_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_OnGoing_Consumption_Button');
    }
      if(this.pf.Meter_Sub_Meter_Daily_Consumptiion_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Daily_Consumptiion_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Activity_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Activity_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_SMS_Sent_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_SMS_Sent_Button');
    }
      if(this.pf.Meter_Sub_Meter_Billing_Teriff_Status_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Billing_Teriff_Status_Button');
    }
      if(this.pf.Meter_Sub_Meter_Load_Limit_Config_Status_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Load_Limit_Config_Status_Button');
    }
      if(this.pf.Meter_Sub_Meter_Happy_Hours_Config_Status_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Happy_Hours_Config_Status_Button');
    }
      if(this.pf.Meter_Sub_Meter_Billing_Scheduler_Status_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Billing_Scheduler_Status_Button');
    }
      if(this.pf.Meter_Sub_Meter_Billing_Teriff_From_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Billing_Teriff_From_Meter_Button');
    }
      if(this.pf.Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button');
    }
      if(this.pf.Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button');
    }
      if(this.pf.Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button');
    }
      if(this.pf.Meter_Sub_Meter_Recent_Complaints_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Recent_Complaints_Button');
    }
    if(this.pf.Meter_Sub_Meter_Delete_Meter_Button.value == true){
      seletedPerms.push('Meter_Sub_Meter_Delete_Meter_Button');
    }
    if(this.pf.Meters_Sub_Configure_Meter.value == true){
      seletedPerms.push('Meters_Sub_Configure_Meter');
   }
    if(this.pf.Meters_Sub_Configure_Load_Limit_On_Meter.value == true){
      seletedPerms.push('Meters_Sub_Configure_Load_Limit_On_Meter');
    }

    if(this.pf.Meters_Sub_Disabled_Meters.value == true){
      seletedPerms.push('Meters_Sub_Disabled_Meters');
    }

    if(this.pf.Meters_Sub_Disabled_Meters_Modify.value == true){
      seletedPerms.push('Meters_Sub_Disabled_Meters_Modify');
    }

    if(this.pf.Meters_Sub_Disabled_Meters_Delete.value == true){
      seletedPerms.push('Meters_Sub_Disabled_Meters_Delete');
    }
  
    if(this.pf.Meters_Sub_Configure_Server_Details_On_Meter.value == true){
      seletedPerms.push('Meters_Sub_Configure_Server_Details_On_Meter');
    }
    
    if(this.pf.Meters_Sub_Configure_Happy_Hours_On_Meter.value == true){
      seletedPerms.push('Meters_Sub_Configure_Happy_Hours_On_Meter');
    }
  
    if(this.pf.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter.value == true){
      seletedPerms.push('Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter');
    }
    if(this.pf.Meters_Sub_Activity_On_Meter.value == true){
      seletedPerms.push('Meters_Sub_Activity_On_Meter');
    }
  
    if(this.pf.Meters_Sub_Activity_Sub_Meter_Load_On_Off.value == true){
      seletedPerms.push('Meters_Sub_Activity_Sub_Meter_Load_On_Off');
    }
    if(this.pf.Meters_Sub_Activity_Sub_Backup_Supply_On_Off.value == true){
      seletedPerms.push('Meters_Sub_Activity_Sub_Backup_Supply_On_Off');
    }

    if(this.pf.Meters_Sub_Activity_Sub_DG_Free_Units_On_Off.value == true){
      seletedPerms.push('Meters_Sub_Activity_Sub_DG_Free_Units_On_Off');
    }

    if(this.pf.Meters_Sub_Activity_Sub_Emergency_On.value == true){
      seletedPerms.push('Meters_Sub_Activity_Sub_Emergency_On');
    }
    if(this.pf.Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post.value == true){
      seletedPerms.push('Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post');
    }
  

    if(this.pf.Meters_Sub_Reporting_From_Meter.value == true){
      seletedPerms.push('Meters_Sub_Reporting_From_Meter');
    }
  
    if(this.pf.Meters_Sub_Reporting_Sub_Get_Full_Billing_Data.value == true){
      seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Billing_Data');
    }
   
    if(this.pf.Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data.value == true){
      seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data');
    }
    if(this.pf.Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data.value == true){
      seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data');
    }
    if(this.pf.Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data.value == true){
      seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data');
    }
   
    if(this.pf.Meters_Sub_Reporting_Sub_Get_Instant_Data.value == true){
            seletedPerms.push('Meters_Sub_Reporting_Sub_Get_Instant_Data');
          }
      
          if(this.pf.Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data.value == true){
            seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data');
          }
      
          if(this.pf.Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data.value == true){
            seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data');
          }
      
          if(this.pf.Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status.value == true){
            seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status');
          }
      
          if(this.pf.Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status.value == true){
            seletedPerms.push('Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status');
          }
          
          if(this.pf.Meters_Reports.value == true){
            seletedPerms.push('Meters_Reports');
           }
           if(this.pf.Meters_Reports_Meters_Information_Report.value == true){
             seletedPerms.push('Meters_Reports_Meters_Information_Report');
           }
           if(this.pf.Meters_Reports_Meters_Teriff.value == true){
             seletedPerms.push('Meters_Reports_Meters_Teriff');
           }
        
           if(this.pf.Meters_Reports_Activity_Log_Report.value == true){
             seletedPerms.push('Meters_Reports_Activity_Log_Report');
           }
           if(this.pf.Meters_Reports_Authority_Wise_Activity_Log_Report.value == true){
            seletedPerms.push('Meters_Reports_Authority_Wise_Activity_Log_Report');
          }
         
           if(this.pf.Meters_Reports_Current_Data_Report.value == true){
             seletedPerms.push('Meters_Reports_Current_Data_Report');
           }
          
           if(this.pf.Meters_Reports_Meter_Wise_Billing_Data_Report.value == true){
             seletedPerms.push('Meters_Reports_Meter_Wise_Billing_Data_Report');
           }
        
           if(this.pf.Meters_Reports_Latest_Tamper_Data_Report.value == true){
             seletedPerms.push('Meters_Reports_Latest_Tamper_Data_Report');
           }
         
           if(this.pf.Meters_Reports_Daily_Unit_Consumption_Report.value == true){
             seletedPerms.push('Meters_Reports_Daily_Unit_Consumption_Report');
           }

           if(this.pf.Meters_Reports_Daily_Unit_Energy_Report.value == true){
            seletedPerms.push('Meters_Reports_Daily_Unit_Energy_Report');
          }
         
           if(this.pf.Meters_Reports_Monthly_Unit_Consumption_Report.value == true){
             seletedPerms.push('Meters_Reports_Monthly_Unit_Consumption_Report');
           }
          
           if(this.pf.Meters_Reports_Daily_Total_Revenue_Report.value == true){
             seletedPerms.push('Meters_Reports_Daily_Total_Revenue_Report');
           }

           if(this.pf.Meters_Reports_Daily_Total_Revenue_Report_failed.value == true){
            seletedPerms.push('Meters_Reports_Daily_Total_Revenue_Report_failed');
          }
         
           if(this.pf.Meters_Reports_Daily_Low_Balance_Report.value == true){
             seletedPerms.push('Meters_Reports_Daily_Low_Balance_Report');
           }
           if(this.pf.Meters_Reports_Daily_Zero_Balance_Report.value == true){
             seletedPerms.push('Meters_Reports_Daily_Zero_Balance_Report');
           }
           if(this.pf.Meters_Reports_Daily_Dead_Meters_Report.value == true){
             seletedPerms.push('Meters_Reports_Daily_Dead_Meters_Report');
           }
          
           if(this.pf.Meters_Reports_SMS_Sent_Report.value == true){
            seletedPerms.push('Meters_Reports_SMS_Sent_Report');
          }
          if(this.pf.Meters_Reports_SLA_Report.value == true){
            seletedPerms.push('Meters_Reports_SLA_Report');
          }
      
      
          if(this.pf.Meters_Reports_Daily_DG_Status_Report.value == true){
            seletedPerms.push('Meters_Reports_Daily_DG_Status_Report');
          }
          if(this.pf.Meters_Reports_Daily_Total_Adjustment_Report.value == true){
            seletedPerms.push('Meters_Reports_Daily_Total_Adjustment_Report');
          }
          if(this.pf.Meters_Reports_Daily_Total_Adjustment_Report_Failed.value == true){
            seletedPerms.push('Meters_Reports_Daily_Total_Adjustment_Report_Failed');
          }
          if(this.pf.Meters_Reports_Load_Limit_Config_Report.value == true){
            seletedPerms.push('Meters_Reports_Load_Limit_Config_Report');
          }
          if(this.pf.Meters_Reports_Tariff_Config_Report.value == true){
            seletedPerms.push('Meters_Reports_Tariff_Config_Report');
          }
          if(this.pf.Meters_Reports_Tariff_Config_Slab_Report.value == true){
            seletedPerms.push('Meters_Reports_Tariff_Config_Slab_Report');
          }
          if(this.pf.Meters_Reports_Happy_Hours_Report.value == true){
            seletedPerms.push('Meters_Reports_Happy_Hours_Report');
          }
          if(this.pf.Meters_Reports_Daily_OnGoing_Consumption_Report.value == true){
            seletedPerms.push('Meters_Reports_Daily_OnGoing_Consumption_Report');
          }
          if(this.pf.Meters_Reports_Bill_Creation_Report.value == true){
            seletedPerms.push('Meters_Reports_Bill_Creation_Report');
          }

          if(this.pf.Meters_Sub_Configure_Utility_Meter.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Meter');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Demand_Integration_Period_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Integration_Period_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Billing_Date_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Billing_Date_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Billing_Date_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Billing_Date_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Push_Schedule_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Push_Schedule_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Push_Schedule_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Push_Schedule_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Relay_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Relay_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Relay_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Relay_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Prepaid_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config');
          }
      
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config');
          }

          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config');
          }
          
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config');
          }
          if(this.pf.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config');
          }
         if(this.pf.Meters_Sub_Configure_Utility_Time_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Time_Config');
          }
         if(this.pf.Meters_Sub_Configure_Utility_Time_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Time_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Time_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Time_Write_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Password_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Password_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Password_High_Level_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Password_High_Level_Write_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Enc_Auth_Key_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Enc_Auth_Key_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ThrashHold_Limit_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config');
          }

          if(this.pf.Meters_Sub_Configure_Utility_Load_Limiter_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Load_Limiter_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_Load_Limiter_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_Load_Limiter_Write_Config');
          }

        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_1_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_1_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_2_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_2_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_3_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_3_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_4_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_4_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_5_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_5_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_6_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_6_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_7_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_7_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_8_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_8_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_TOD_Zone_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_TOD_Zone_Write_Config');
          }
      
          
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Phase_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Phase_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config');
          }
        if(this.pf. Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Influb_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Influb_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config');
          }
      
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config .value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Read_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Read_Config');
          }
        if(this.pf.Meters_Sub_Configure_Utility_ESW_Single_Write_Config.value == true){
            seletedPerms.push('Meters_Sub_Configure_Utility_ESW_Single_Write_Config');
          }
      
      

    
    
      //this.loading = true;
      this.isloading=true;
      this.message ='';
      let body = new URLSearchParams();
      body.set('seletedPerms', seletedPerms.toString());
      body.set('authUniqueId', this.authorityData.aID);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/setAuthPermission', body).subscribe(
        (res) => {
          this.message = res.message;
          //this.loading = false;
         
          if (res.success) {
            this.isloading=false;
            this.thumb = true;
            //this.addAuthMessage = this.responseData.data.message;
          }else{
              this.thumb = false;
          }

        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.loading = false;
        }
      );
  }

  onDragChange() {
    //console.log('hello');
  }

  getAuthPerms() {
    this.loading = true;
    this.message ='';
    let body = new URLSearchParams();
    body.set('authId', this.authorityData.aID);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getAuthPermission', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.loading = false;
         this.authorityPerms = this.responseData.data;
         this.addAuthorityForm.patchValue(this.authorityPerms);
      }else{
        this.message = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.message = 'Unable to process yours request!';
    });
  }
}
