import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from '../_services';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
declare var jQuery: any;
import * as AOS from 'aos';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  faSpinner = faSpinner;
  addForm: UntypedFormGroup;
  responseMessage: string = '';
  responseData: any;
  AddProcess: boolean = false;
  apiMessage: string = '';

  isLoading = false;
  thumb: boolean = false;

  @HostListener('document:wheel', ['$event.target'])
  public onWheel(targetElement) {
    AOS.refresh();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private router: Router,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    if (this.authenticationService.currentUserValue) {
      let deshboard = "/" + this.authenticationService.getDeshboardRoute();
      this.router.navigate([deshboard]);
    }

  }

  ngOnInit() {
    AOS.init({
      duration: 1000,
    })
    this.addForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: [''],
      phoneNo: ['', Validators.required],
      query: ['', Validators.required]
    });

  }

  ngOnDestroy() {

  }
  get aF() { return this.addForm.controls; }

  onSubmitAddForm() {
    if (this.addForm.invalid) {
      return;
    }
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('name', this.aF.firstName.value);
    body.set('lastName', this.aF.lastName.value);
    body.set('emailId', this.aF.emailId.value);
    body.set('phoneNo', this.aF.phoneNo.value);
    body.set('message', this.aF.query.value);

    let accessAuth = localStorage.getItem('accessAuth');
    body.set('authToken', accessAuth);
    this.AddProcess = true;
    this.genReqs.postReq('/addContactUs', body).subscribe((result) => {
      this.isLoading = false;
      this.thumb = true;
      this.responseData = result;
      this.responseMessage = "Thanks you for contacting us, we will get back to you soon.";
      //this.resetForm(this.addForm);
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  

}
