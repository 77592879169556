import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { HttpResponse } from '@angular/common/http';
import { webconfig } from '../_models/webconfig';
import * as CryptoJS from 'crypto-js';

let apiUrl = webconfig.apiUrl;
let siteUrl = webconfig.siteUrl;

@Injectable({ providedIn: 'root' })
export class generalRequestsService {
  encryptSecretKey = 'RZ5q(vM2dH)[2A-Y';
  constructor(private http: HttpClient) {

  }

  apiPath() {
    return apiUrl;
  }

  sitePath() {
    return siteUrl;
  }

  genReq(type) {
    //console.log(apiUrl+type);
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + type).subscribe(res => {
        resolve(res)
      }, (err) => {
        reject(err);
      });
    });
  }

  postReq(type, params) {
    return this.http.post<any>(`${apiUrl}` + type, params.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).pipe(map(data => {
      return data;
    }));
  }

  getReq(type, params) {
    return this.http.get<any>(`${apiUrl}` + type, params.toString()).pipe(map(data => {
      return data;
    }));
  }

  getHtml(url: string = '') {

    if (url.length < 1) {
      return '';
    }

    return this.http.get(url, { responseType: 'text' }).subscribe((html: string) => {
      //console.log(html);
      return html;
    });
  }

  postReqWithHeader(type, params, Header) {
    return this.http.post<any>(`${apiUrl}` + type, params.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded",
        'authToken': Header
      }
    }).pipe(map(data => {
      return data;
    }));
  }

  public formDataPost(type, data) {
    return this.http.post<any>(`${apiUrl}` + type, data).pipe(map(data => {
      return data;
    }));
  }

  getNameByIds(authToken: string = '', countryId: string = '0', stateId: string = '0', utilityId: string = '0', circleId: string = '0', divisionId: string = '0', subdivisionId: string = '0', dcuId: string = '0') {
    let body = new URLSearchParams();
    body.set('countryId', countryId);
    body.set('stateId', stateId);
    body.set('utilityId', utilityId);
    body.set('circleId', circleId);
    body.set('divisionId', divisionId);
    body.set('subdivisionId', subdivisionId);
    body.set('dcuId', dcuId);
    body.set('authToken', authToken);
    return this.http.post<any>(`${apiUrl}getNames`, body.toString(), {
      headers: {
        'content-type': "application/x-www-form-urlencoded"
      }
    }).subscribe(data => {
      return data;
    });
  }

  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify([data]), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log('failed');
    }
  }

  getDownloadFile(type, params): any {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(`${apiUrl}` + type, {
      headers: {
        'content-type': "application/pdf"
      }
    });
  }


  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

}