<div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
    Recent Messages
  </h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="simple-alertmsg" *ngFor="let item of allnotifications" >
      <div class="outerflex ">
        <div class="lblleft ">
          <h3 class="setHeight">{{item.Notification | slice:0:15}}</h3>
          <p>{{item.Notification}}</p>
        </div>
      </div>
      <div class="datelocationflex">
        <p class="timeStampp"><i class="fa fa-calendar"></i>{{item['Server Date Time'] | date:'fullDate'}}</p>
       
      </div>
    </div>
    <!-- <mat-accordion>
      <mat-expansion-panel *ngFor="let item of allnotifications" >
        <mat-expansion-panel-header>
          <mat-panel-title class="setHeight">
            <mat-icon class="d-c16">notification_important</mat-icon>
            <b>{{item.Notification | slice:0:15}}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="col-md-12 pull-left">
          <mat-icon matprefix class="d-c12">message</mat-icon><B>Message:</B> {{item.Notification}}
          <div class="timeStamp">
            {{item['Server Date Time'] | date:'fullDate'}}
          </div>
        </div>
        

      </mat-expansion-panel>
    </mat-accordion> -->
    <!-- Grid row -->
    <div class="clearfix"></div>
    <!-- buttons are listed here-->
  </div>
</div>