<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Upload HES Payments
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Meter Type" name="meterType" formControlName="meterType"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.meterType.errors}"
                                required>
                                <mat-option value=1> 1-PH </mat-option>
                                <mat-option value=3> LTCT </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Meter Type</label>
                            <div *ngIf="submitted && aD.meterType.errors" class="invalid-feedback">
                                <div *ngIf="aD.meterType.errors.required">
                                    Meter Type is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Payment Type" name="paymentType" formControlName="paymentType"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.paymentType.errors}"
                                required>
                                <mat-option value=1> Inst. Payment (40%) </mat-option>
                                <mat-option value=2> Payment after installation (40%) </mat-option>
                                <mat-option value=3> Payment after One Month (20%) </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Payment Type</label>
                            <div *ngIf="submitted && aD.paymentType.errors" class="invalid-feedback">
                                <div *ngIf="aD.paymentType.errors.required">
                                    Payment Type is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <textarea placeholder="Upload Remark" name="remark" formControlName="remark"
                                class="form-control" rows="6" cols="50"></textarea>
                            <i class="fa fa-id-card-o icon_innertxtbox"></i>
                            <label>Tender Remark</label>
                            <!--<div *ngIf="submitted && aD.remark.errors" class="invalid-feedback">
                                    <div *ngIf="aD.remark.errors.required">
                                        Tender Remark is required
                                    </div>
                                </div>-->
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group input-group">
                    <span class="has-float-label">
                        <input type="file" accept="application/vnd.ms-excel" class="form-control" #fileInput multiple name="selectedFilesNew"
                            (change)="onFileSelect($event)" formControlName="selectedFilesNew" class=""
                            [ngClass]="{ 'is-invalid': submitted && aD.selectedFilesNew.errors}" required>
                        <label>Select file</label>
                        <div *ngIf="submitted && aD.selectedFilesNew.errors" class="invalid-feedback">
                            <div *ngIf="aD.selectedFilesNew.errors.required">
                                File is required
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group input-group">
                    <span class="has-float-label">

                        <label>Upload queue</label>
                        <table class="table-headed table-striped subleft">
                            <thead>
                                <tr>
                                    <th>Count</th>
                                    <th>Name</th>
                                    <th>Size</th>
                                    <!--<th class="text-left">Actions</th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of queue | async ; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{ item?.file?.name }}</td>
                                    <td>{{ item?.file?.size/1024/1024 |
                                        number:'.2' }} MB</td>
                                    <!--<td class="text-center" style="width: 6%">
                                            <a tooltip="Remove from queue" (click)="item.remove()" *ngIf="!item.inProgress()">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </td>-->
                                </tr>
                            </tbody>
                        </table>
                        <!--<div>
                                <a class="button button-clear" (click)="uploader.clearQueue()">Clear queue</a>
                                <a class="button button-primary" (click)="uploader.uploadAll()">Upload all</a>
                            </div>-->
                    </span>
                </div>
            </div>
            <!--<div class="col-md-12">
                    
                    <div class="col-md-9">
                        <h3>Upload queue</h3>
                        <table class="table-headed table-striped">
                            <thead>
                                <tr>
                                    <th class="text-left">Name</th>
                                    <th class="text-right">Size</th>
                                    <th class="text-left">Progress</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of queue | async">
                                    <td>{{ item?.file?.name }}</td>
                                    <td class="text-right">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                                    <td>{{ item.progress + ' %' }}</td>
                                    <td>
                                        <span *ngIf="item.isPending()" class="tag tag-default"></span>
                                        <span *ngIf="item.isSuccess()" class="tag tag-success"></span>
                                        <span *ngIf="item.inProgress()" class="tag tag-warning"></span>
                                        <span *ngIf="item.isError()" class="tag tag-danger"></span>
                                    </td>
                                    <td class="text-right">
                                        <a tooltip="Upload file" (click)="item.upload()" *ngIf="item.isUploadable()">
                                            <i class="fa fa-upload"></i>
                                        </a>
                                        <a tooltip="Cancel upload" (click)="item.cancel()" *ngIf="item.inProgress()">
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                                        <a tooltip="Remove from queue" (click)="item.remove()" *ngIf="!item.inProgress()">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <a class="button button-clear" (click)="uploader.clearQueue()">Clear queue</a>
                            <a class="button button-primary" (click)="uploader.uploadAll()">Upload all</a>
                        </div>
                    </div>
                </div>-->


            <div class="col-12 row">
                <div class="col-sm-7 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && messageNew.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{messageNew}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{messageNew}}
                        </div>
                    </div>
                </div>

                <div class="col-sm-5 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>