import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigpushdata',
  templateUrl: './meterconfigpushdata.component.html',
  styleUrls: ['./meterconfigpushdata.component.scss']
})
export class MeterconfigpushdataComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      mcs: ['', Validators.required],
      mct: ['', Validators.required],
      mloffs: ['', Validators.required],
      mlons: ['', Validators.required],
      mols: ['', Validators.required],
      mlb: ['', Validators.required],
      movs: ['', Validators.required],
      mlvs: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
