<!----add authority start-->
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">

    <div class="modal-header subpage">
      <h4 class="modal-title" id="modal-basic-title">
        <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
          data-dismiss="modal">&times;</button>
        Modify Permissions:
        {{authorityData.authFullName}}
      </h4>
    </div>
  
    <div class="modal-body">
      <div *ngIf="loading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
      </div>
      <div class="form-groupp" *ngIf="!loading">
        <nav>
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a class="nav-item nav-link" id="nav_contractor_view" data-toggle="tab" href="#nav_contractor" role="tab"
            aria-controls="nav-contractor" aria-selected="true"> Contractor's
              <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Contractor"></i>
            </a>
          </div>
        </nav>
        <div class="tab-content meterload" id="nav-tabContent">
        <div class="tab-pane fade" id="nav_contractor" role="tabpanel" aria-labelledby="nav_contractor_view">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  Contractor's
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_Menu">Contractor's</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_add">Add Contractror Button
                  </mat-slide-toggle>
                </div>
                
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_modify">Contractor Modify
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_add_superviser">Add Superviser
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_view_superviser">View Superviser Progress
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    supervisor_account
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_delete">Delete Contractor
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
            
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  Superviser's
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_superviser">Superviser
                  </mat-slide-toggle>
                </div>
               
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_modify_superviser">Modify Superviser
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_delete_superviser">Delete Superviser
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
  
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  Issued Meters
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_issued_meters_range_menu">Issued Meters
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_issued_meters_range">Add Issued Meters
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_modify_meters_range">Modify Issued Meters
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_delete_meters_range">Delete Issued Meters
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
  
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  Contractor's Progress
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_progress">Contractor Progress
                  </mat-slide-toggle>
                </div>
               
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_modify_payment">Modify Payment
                  </mat-slide-toggle>
                </div>
                
              </div>
            </mat-expansion-panel>
  
            
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  Smart Meter Order
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_add">Add Smart Meter order
                  </mat-slide-toggle>
                </div>
               
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_view_serial_no">View Serial No
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_add_serial_no">Add Serial No
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_edit_by_production">Edit By Production
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_edit_by_account">Edit By Accounts
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_edit_by_marketing">Edit By Marketing
                  </mat-slide-toggle>
                </div>
  
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    horizontal_split
                  </mat-icon>
                  <mat-slide-toggle formControlName="smart_meter_order_delete_order">Delete Order
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="d-c7">
                    report
                  </mat-icon>
                  Reports
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="form-row panel-body">
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c7">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="Contractor_Sub_Reports">Reports</mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c5">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_info_Report">Contractor's Info Report
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c4">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_superviser_info_Report">Superviser Info Report
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class="d-c3">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_faulty_meters_Report">Faulty Meters Report
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_installed_meters_report">Installed Meters Report
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_payment_pending_report">Payment Pending Meters Report
                  </mat-slide-toggle>
                </div>
                <div class="with-icon col-sm-4">
                  <mat-icon class=" d-c2">
                    report
                  </mat-icon>
                  <mat-slide-toggle formControlName="contractor_payment_cleared_report">Payment Cleared Report
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-expansion-panel>
            </mat-accordion>
        </div>
        </div>
        <!-- Grid row -->
        <!-- Grid row -->
        <div class="clearfix"></div>
        <div class=" row">
          <div class="col-sm-12 text-left">
            <ng-container *ngIf="isloading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
            </ng-container>
            <div role="alert" *ngIf="!isLoading && message.length > 0">
              <div class="alert alert-success" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
              </div>
            </div>
  
          </div>
  
          <div class="col-sm-12 text-right">
            <button type="submit" class="dilog dilog1">
              <mat-icon>update</mat-icon>
              Update
            </button>
            <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
              <mat-icon>cancel</mat-icon>
              Cancel
            </button>
  
          </div>
        </div>
      </div>
    </div>
  </form>
  <!----add authority end-->