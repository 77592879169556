<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
<div class="row">
	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<mat-select placeholder="Start Hour" name="startHour" formControlName="startHour" class="form-control">
					<mat-option *ngFor="let td of smOnHour" [value]="td.id"  [ngClass]="{ 'is-invalid': submitted && cF.startHour.errors }" required>
					{{td.id}}
					</mat-option>
				</mat-select>
				<i class="fa fa-database icon_innertxtbox"></i>
				<label>Start Hour</label>
				<div *ngIf="submitted && cF.startHour.errors" class="invalid-feedback">
					<div *ngIf="cF.startHour.errors.required">
						Start Hour required
					</div>
				</div>
			</span>
		</div>
	</div>



	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<mat-select placeholder="Start Minute" name="startMinute" formControlName="startMinute" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.startMinute.errors }" required>
					<mat-option *ngFor="let td of smOnMinute" [value]="td.id">
					{{td.id}}
					</mat-option>
				</mat-select>
				<i class="fa fa-clock-o icon_innertxtbox"></i>
				<label>Start Minute</label>
				<div *ngIf="submitted && cF.startMinute.errors" class="invalid-feedback">
					<div *ngIf="cF.startMinute.errors.required">
						Start Minute required
					</div>
				</div>
			</span>
		</div>
	</div>

	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<mat-select placeholder="End Hour" name="endHour" formControlName="endHour" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.endHour.errors }" required>
					<mat-option *ngFor="let td of smOnHour" [value]="td.id">
					{{td.id}}
					</mat-option>
				</mat-select>
				<i class="fa fa-clock-o icon_innertxtbox"></i>
				<label>End Hour</label>
				<div *ngIf="submitted && cF.endHour.errors" class="invalid-feedback">
					<div *ngIf="cF.endHour.errors.required">
						End Hour required
					</div>
				</div>
			</span>
		</div>
	</div>
	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<mat-select placeholder="End Minute" name="endMinute" formControlName="endMinute" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.endMinute.errors }" required>
					<mat-option *ngFor="let td of smOnMinute" [value]="td.id">
					{{td.id}}
					</mat-option>
				</mat-select>
				<i class="fa fa-clock-o icon_innertxtbox"></i>
				<label>End Minute</label>
				<div *ngIf="submitted && cF.endMinute.errors" class="invalid-feedback">
					<div *ngIf="cF.endMinute.errors.required">
						End Minute required
					</div>
				</div>
			</span>
		</div>
	</div>
	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
		<!--<mat-select placeholder="Holiday Month" name="holidayMonth" formControlName="holidayMonth">
			<mat-option *ngFor="let td of startMonth" [value]="td.id">
			  {{td.id}}
			</mat-option>
			</mat-select>-->
				<input  placeholder="H  oliday Month Like 1,2,3...12" formControlName="holidayMonth" type="text" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.holidayMonth.errors }" required>
				<i class="fa fa-calendar icon_innertxtbox"></i>
				<label>Holiday Month</label>
				<div *ngIf="submitted && cF.holidayMonth.errors" class="invalid-feedback">
					<div *ngIf="cF.holidayMonth.errors.required">
						Holiday Month required
					</div>
				</div>
			</span>
		</div>
	</div>
	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<input  placeholder="Holiday Date like 1,2,3...31" formControlName="holidayDate" type="text" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.holidayDate.errors }" required>
				<i class="fa fa-calendar icon_innertxtbox"></i>
				<label>Holiday Date</label>
				<div *ngIf="submitted && cF.holidayMonth.errors" class="invalid-feedback">
					<div *ngIf="cF.holidayMonth.errors.required">
						Holiday Date required
					</div>
				</div>
			</span>
		</div>
	</div>
</div>
<div class=" row">
	<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
				<input  placeholder="Week Day like 6,7" formControlName="weekDay" type="text" class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.weekDay.errors }" required>
				<i class="fa fa-calendar icon_innertxtbox"></i>
				<label>Week Day</label>
				<div *ngIf="submitted && cF.weekDay.errors" class="invalid-feedback">
					<div *ngIf="cF.weekDay.errors.required">
						Week Day required
					</div>
				</div>
			</span>
		</div>
	</div>
</div>

<div class="row">
	<div class="{{tagValue}}">
		<ng-container *ngIf="isLoading">
			<fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
		</ng-container>
	   
		<div role="alert" *ngIf="message.length > 0">
			<div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
				<mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
			</div>
			<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
				<mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
			</div>
		</div>
	</div>
	<div class="col-sm-2 text-right" *ngIf="cmdformData==0">
		<button type="button" (click)="onSubmitConfigPendingMeter()" [disabled]="AddProcess" class="dilog dilog1">
			<mat-icon>add</mat-icon>
			Pending
		</button>
	</div>
	<div class="col-sm-2 text-right">
		<button type="submit" [disabled]="AddProcess" class="dilog dilog1">
			<mat-icon>add</mat-icon>
			Set
		</button>
	</div>
</div>

	<!--<button mat-raised-button color="primary" type="submit">Add</button>-->
</form>