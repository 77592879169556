<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
       <h4 class="modal-title" id="modal-basic-title">
         <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
           data-dismiss="modal">&times;</button>
         Admin Revert
       </h4>
     </div>
       <div class="modal-body">
           <!--<div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>-->
           <div class="form-groupp">
               <!-- Grid row -->
               <div class="form-row">
                 <!-- Default input 
                 <mat-form-field class="col-md-6">
                   <input matInput placeholder="Consumer Sub Division" name="consSubDivision" formControlName="consSubDivision" >
                   <mat-icon matPrefix class="d-c2">account_circle</mat-icon>
                   <mat-error *ngIf="aF.consSubDivision.hasError('required')">
                     Consumer Sub Division is <strong>required</strong>
                   </mat-error>
                 </mat-form-field>
                 <mat-form-field class="col-md-6">
                     <input matInput placeholder="Consumer Name" name="consName" formControlName="consName" >
                     <mat-icon matPrefix class="d-c3">account_box</mat-icon>
                     <mat-error *ngIf="aF.consName.hasError('required')">
                       Consumer Name is <strong>required</strong>
                     </mat-error>
                   </mat-form-field>-->
                     <div class="col-md-12">
                    <div class="form-group input-group">
                       <span class="has-float-label">
                           <textarea name="consNotification"formControlName="consNotification" 
                           rows="5" [maxLength]="168" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.consNotification.errors}" required></textarea>
                           <i class="fa fa-bell icon_innertxtbox"></i>
                           <label> Reply</label>
                           <div *ngIf="submitted && aF.consNotification.errors" class="invalid-feedback">
                           <div *ngIf="aF.consNotification.errors.required">
                                Reply filed Can't be left blank!
                           </div>
                       </div>
                     </span>
                   </div>
                 </div>
               </div>
               
               <!-- Grid row -->
               <div class="clearfix"></div>
               <div class="row">
                 <div class="col-sm-12 text-left">
                     <ng-container *ngIf="isLoading">
                       <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                     </ng-container>
                       <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                         <div class="alert alert-success" role="alert" *ngIf="thumb">
                           <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                         </div>
                         <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                           <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                         </div>
                       </div>
                     
                 </div>
                  
                 <div class="col-sm-12 text-right">
                   <button type="submit" class="dilog dilog1">
                     <mat-icon>add</mat-icon>
                     Submit
                   </button>
                   <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                     <mat-icon >cancel</mat-icon>
                     Cancel
                   </button>
     
                 </div>
               </div>
                  
       </div>
       </div>
       </form>