import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { ModalService } from '../../_services/modal.service';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Access } from 'src/app/_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkmeterbillreport',
  templateUrl: './checkmeterbillreport.component.html',
  styleUrls: ['./checkmeterbillreport.component.scss']
})
export class CheckmeterbillreportComponent implements OnInit {

  length: number = 0;
  selectedSubDivision:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  messageResult:any;
  loading = false;
  levelSearch:string = '';
  getThrough: Access;
  removeProcess:boolean = false;
  responseMessage:string = '';
  responseData: any;
  allData: {};
  alllevels = {
    uid: '',
    authLevelName: ''
  }
  dataLevelSource:any = new MatTableDataSource([]);
  faSpinner = faSpinner;

  dataSource:any = new MatTableDataSource([]);
  loadingMessage:any = '';

  //pagination settings
  limit:number = 20;
  skip:number = 0;
  filter = '';
  // MatPaginator Inputs
  allLevelTotalRecord: number = 0;
  
  pageSize: number = 7;
  pageSizeOptions: number[] = [7, 14, 21];

  levelHeadsCols = [];
  countryId: any = 0;
  stateId: any = 0;
  utilityId: any = 0;
  circleId: any = 0;
  divisionId: any = 0;
  subdivisionId: any = 0;
  subDivName = '';
  authToken= '';
  levelColumnsToDisplay = [];
  enableSocietyChange:boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService
    ) {
      this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
      
  }

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  ngOnInit() {
    
      this.levelHeadsCols = [
        { label: "Sr. No.",name:"srNo", checked: true },
        { label: "Meter Make",name:"meterMake", checked: false },
        { label: "Meter No",name:"meterId", checked: true },
        { label: "Meter Type",name:"meterType", checked: false },
        { label: "Status",name:"status", checked: true },
        { label: "Remark",name:"remark", checked: false },
        { label: "Location",name:"location", checked: false },
        { label: "Date & Time",name:"dateTime", checked: true},
        { label: "Action",name:"edit", checked: true }
       ];
    
      this.levelColumnsToDisplay = [
        'srNo',
        'meterId',
        'status',
        'dateTime',
        'edit'
      ];
    
      this.route.queryParams.subscribe(params => {
        this.subdivisionId = params['subDivisionId'];
        this.countryId = params['countryId'];
        this.stateId = params['stateId'];
        this.utilityId = params['utilityId'];
        this.circleId = params['circleId'];
        this.divisionId = params['divisionId'];
        this.subDivName = params['subDivName'];
        this.authToken = params['authToken'];
      });

    this.getLabelsListing();
    /*if(this.enableSocietyChange){
      this.whichSubDomain();
    }*/
  }

  /*whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
        this.getLabelsListing();
      }
    );
  }*/


  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.ngModalService.dismissAll();
  }

 
  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable","Meter's Firmware Data");
  }

  //add


  
  //edit 
  checkMeter(row){
    //console.log(row);
    this.modalService.checkMeterBill(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getCurrentData();
      }
       // console.log({ messageResult: result });
        this.messageResult = result;
      });
  }

  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for ( let col of this.levelHeadsCols ) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }
  
  onLevelSearchChange(search){
    this.levelSearch = search;
    this.skip = 0;
    this.getMeterFirmwareList(false);
  }

  getMeterFirmwareList(addNew:boolean=false) {
    //this.loading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.subdivisionId);
    body.set('dcuId','0');
    body.set('versionId', '0');
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getCheckMeterBillInfo', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }

      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }
  
  // remove
  removelevel(row){
  //deleteAuth
  this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove Firmware Version '+row.Name+' ?')
  .then((confirmed) => {
    if(confirmed) {
      this.removeProcess = true;
      let body = new URLSearchParams();
      body.set('subDivisionId', this.subdivisionId);
      body.set('firmId', row.id);
      body.set('firmName', row.Name);
      body.set('subName', row.subDivName);
      body.set('authToken', this.authToken);
      this.genReqs.postReq('/deleteFWData', body).subscribe((result)=>{
        this.responseData = result;
        this.removeProcess = false;
        this.responseMessage = this.responseData.message;
        this.getLabelsListing();
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.responseMessage = 'Unable to process yours request!';
      });
    }
  })
  .catch(() => console.log('no response or closed'));
  }


  getCurrentData() {
    //this.limit=10;
    this.loading = true;
    let body = new URLSearchParams();
    
    body.set('subdivId', this.subdivisionId);
   
    
    body.set('dcuId','0');
    body.set('versionId', '0');
    
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getCheckMeterBillInfo', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
        
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  getLabelsListing() {
    this.loading = true;
    let body = new URLSearchParams();
     body.set('subdivId', this.subdivisionId);
    
    body.set('dcuId','0');
    body.set('versionId', '0');
    
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('authToken', this.authToken);
    this.genReqs.postReq('/getCheckMeterBillInfo', body).subscribe((result)=>{
      this.responseData = result;
      this.loading = false;
      if(this.responseData.success){
          this.dataLevelSource = this.responseData.data;
         
          this.length = this.responseData.datalimit.totalResult;
          if(this.length<10){
            this.limit= this.length;
          }
      }else{
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getLabelsListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getLabelsListing();
    }
  }

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [10, 20, 40, 80, 160, this.length];
    } else if (this.length > 320) {
      return [10, 20, 40, 80, 160, 320, this.length];
    } 
  }
  
}
