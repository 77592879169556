<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Add Meter Range
    </h4>
  </div>
  <div class="modal-body">
      <div class="form-groupp">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                  <input placeholder="Prefix Id" name="prefix" formControlName="prefix" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.prefix.errors}" required>
                    <i class="fa fa-user icon_innertxtbox"></i>
                    <label>Prefix</label>
                    <div *ngIf="submitted && aF.prefix.errors" class="invalid-feedback">
                        <div *ngIf="aF.prefix.errors.required">
                          Prefix Id is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
             <div class="col-md-6">
               <div class="form-group input-group">
                  <span class="has-float-label">
                    <input  placeholder="From Range" name="fromRange" formControlName="fromRange" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.fromRange.errors}" required>
                    <i class="fa fa-arrows-h icon_innertxtbox"></i>
                      <label>From Range</label>
                      <div *ngIf="submitted && aF.fromRange.errors" class="invalid-feedback">
                          <div *ngIf="aF.fromRange.errors.required">
                              From Range is required
                          </div>
                      </div>
                    </span>
                  </div>
                </div>

              <div class="col-md-6">
                <div class="form-group input-group">
                    <span class="has-float-label">
                      <input placeholder="To Range" name="toRange" formControlName="toRange" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.toRange.errors}" required>
                     <i class="fa fa-arrows-h icon_innertxtbox"></i>
                        <label>To Range</label>
                        <div *ngIf="submitted && aF.toRange.errors" class="invalid-feedback">
                            <div *ngIf="aF.toRange.errors.required">
                                 To Range is required
                            </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                          <mat-select placeholder="Device Type" name="deviceType" formControlName="deviceType" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.deviceType.errors}" required>
                            <mat-option *ngFor="let dg of deviceData" [value]="dg.deviceType">
                              {{dg.deviceType}}
                            </mat-option>
                          </mat-select>
                          <i class="fa fa-database icon_innertxtbox"></i>
                          <label>Device Type</label>
                          <div *ngIf="submitted && aF.deviceType.errors" class="invalid-feedback">
                            <div *ngIf="aF.deviceType.errors.required">
                                Device Type is required
                            </div>
                        </div>
                      </span>
                    </div>
                  </div>

              <!--<mat-form-field class="col-md-6">
                <input matInput placeholder="Specific Id" name="specificId" formControlName="specificId" >
                <mat-icon matPrefix class="d-c8">
                  assessment
                </mat-icon>
                <mat-error *ngIf="aF.specificId.hasError('required')">
                    Specific Id is <strong>required</strong>
                </mat-error>
              </mat-form-field>-->

            <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input  placeholder="Comment" name="comment" formControlName="comment" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.comment.errors}" required>

                  <i class="fa fa-comment icon_innertxtbox"></i>
                    <label>Comment</label>
                    <div *ngIf="submitted && aF.comment.errors" class="invalid-feedback">
                      <div *ngIf="aF.comment.errors.required">
                           Comment is required
                      </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>
      </div>
  </div>
  </form>