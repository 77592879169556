<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
    <div class="row">
        <!--<div class="col-md-4">
		<div class="form-group input-group">
			<span class="has-float-label">
                <input  placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate" autocomplete="off" formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)" 
                bsDatepicker [bsConfig]="{ adaptivePosition: true }" class="form-control">
                <i class="fa fa-clock-o icon_innertxtbox"></i>
                <label>Start Minute</label>
			</span>
		</div>
	</div>-->
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate" autocomplete="off"
                        formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)" bsDatepicker
                        [bsConfig]="{ adaptivePosition: true }" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.schedularStartEndDate.errors }" required>
                    <i class="fa fa-calendar icon_innertxtbox"></i>
                    <label>Start Date</label>
                    <div *ngIf="cF.schedularStartEndDate.invalid && (cF.schedularStartEndDate.dirty || cF.schedularStartEndDate.touched)"
                        class="alert alert-danger">
                        <div *ngIf="cF.schedularStartEndDate.errors.required">
                            Start Date required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Alarm Level" name="alarmLevel" formControlName="alarmLevel" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.alarmLevel.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>Alarm Level</label>
                    <div *ngIf="cF.alarmLevel.invalid && (cF.alarmLevel.dirty || cF.alarmLevel.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.alarmLevel.errors.required">
                            Alarm Level is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Emergency Credit Limit" name="emergencycredit" formControlName="emergencycredit"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.emergencycredit.errors }"
                        required>
                    <i class="fa fa-credit-card-alt icon_innertxtbox"></i>
                    <label>Emergency Credit Limit</label>
                    <div *ngIf="cF.emergencycredit.invalid && (cF.emergencycredit.dirty || cF.emergencycredit.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.emergencycredit.errors.required">
                            Emergency Credit is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Grid Unit Rate" name="eBFlatUnitRate" formControlName="eBFlatUnitRate"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.eBFlatUnitRate.errors }"
                        required>
                    <i class="fa fa-star icon_innertxtbox"></i>
                    <label>Grid Unit Rate</label>
                    <div *ngIf="cF.eBFlatUnitRate.invalid && (cF.eBFlatUnitRate.dirty || cF.eBFlatUnitRate.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.eBFlatUnitRate.errors.required">
                            Grid Unit Rate is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="DG Unit Rate" name="dGUnitRate" formControlName="dGUnitRate"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dGUnitRate.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>DG Unit Rate</label>
                    <div *ngIf="cF.dGUnitRate.invalid && (cF.dGUnitRate.dirty || cF.dGUnitRate.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.dGUnitRate.errors.required">
                            DG Unit Rate is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Fixed Charge Grid" name="fixedChargeGrid" formControlName="fixedChargeGrid"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.fixedChargeGrid.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Fixed Charge Grid</label>
                    <div *ngIf="cF.fixedChargeGrid.invalid && (cF.fixedChargeGrid.dirty || cF.fixedChargeGrid.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.fixedChargeGrid.errors.required">
                            Fixed Charge Grid is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Fixed Charge DG" name="fixedChargeDG" formControlName="fixedChargeDG"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.fixedChargeDG.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Fixed Charge DG</label>
                    <div *ngIf="cF.fixedChargeDG.invalid && (cF.fixedChargeDG.dirty || cF.fixedChargeDG.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.fixedChargeDG.errors.required">
                            Fixed Charge DG is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Vending Charge" name="vendingCharge" formControlName="vendingCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.vendingCharge.errors }"
                        required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Vending Charge</label>
                    <div *ngIf="cF.vendingCharge.invalid && (cF.vendingCharge.dirty || cF.vendingCharge.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.vendingCharge.errors.required">
                            Vending Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Common Area Maintenance Rate" name="commonAreaMaintCharge"
                        formControlName="commonAreaMaintCharge" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.commonAreaMaintCharge.errors }" required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Common Area Maintenance Rate</label>
                    <div *ngIf="cF.commonAreaMaintCharge.invalid && (cF.commonAreaMaintCharge.dirty || cF.commonAreaMaintCharge.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Common Area Maintenance Rate is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Carpet Area Maint Rate" name="carpetAreaMaintCharge"
                        formControlName="carpetAreaMaintCharge" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.carpetAreaMaintCharge.errors }" required>
                    <i class="fa fa-plus-square-o icon_innertxtbox"></i>
                    <label>Carpet Area Maintenance Rate</label>
                    <div *ngIf="cF.carpetAreaMaintCharge.invalid && (cF.carpetAreaMaintCharge.dirty || cF.carpetAreaMaintCharge.touched)"
                        class="invalid-feedback">
                        <div *ngIf="cF.carpetAreaMaintCharge.errors.required">
                            Carpet Area Maintenance Rate is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Grid Maintenance Charge" name="gridMaintCharge"
                        formControlName="gridMaintCharge" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.gridMaintCharge.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Grid Maintenance Charge</label>
                    <div *ngIf="submitted && cF.commonAreaMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Grid Maintenance Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="DG Maintenance Charge" name="dgMaintCharge" formControlName="dgMaintCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dgMaintCharge.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>DG Maintenance Charge</label>
                    <div *ngIf="submitted && cF.commonAreaMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            DG Maintenance Charge required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Maintenance Charges" name="clubCharge" formControlName="clubCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.clubCharge.errors }" required>
                    <i class="fa fa-building icon_innertxtbox"></i>
                    <label>Maintenance Charges</label>
                    <div *ngIf="submitted && cF.commonAreaMaintCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.commonAreaMaintCharge.errors.required">
                            Maintenance Charges is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Water Charges" name="waterCharge" formControlName="waterCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.waterCharge.errors }" required>
                    <i class="fa fa-tint icon_innertxtbox"></i>
                    <label>Water Charges</label>
                    <div *ngIf="submitted && cF.waterCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.waterCharge.errors.required">
                            Water Charge is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Other Charges" name="otherCharge" formControlName="otherCharge"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.otherCharge.errors }" required>
                    <i class="fa fa-building icon_innertxtbox"></i>
                    <label>Other Charges</label>
                    <div *ngIf="submitted && cF.otherCharge.errors" class="invalid-feedback">
                        <div *ngIf="cF.otherCharge.errors.required">
                            Other Charges is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Service Tax" name="serviceTax" formControlName="serviceTax" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.serviceTax.errors }" required>
                    <i class="fa fa-inr icon_innertxtbox"></i>
                    <label>Service Tax</label>
                    <div *ngIf="submitted && cF.serviceTax.errors" class="invalid-feedback">
                        <div *ngIf="cF.serviceTax.errors.required">
                            Service Tax is required
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>

            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <button type="submit"  class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>
    <!--
<button mat-raised-button color="primary" type="submit">Add</button>-->
</form>