<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
      <mat-icon matPrefix class="d-c2">chrome_reader_mode</mat-icon>
        <mat-select placeholder="Billing From Meter" name="bfm" formControlName="bfm">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let frm of booleanVals" [value]="frm">
            {{frm.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.bfm.hasError('required')">Please choose a Days Frequency</mat-error>
      </mat-form-field>
      <!--<mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c2">chrome_reader_mode</mat-icon>
          <mat-select placeholder="Billing Mode" name="bm" formControlName="bm">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let mode of modeMeter" [value]="mode">
              {{mode.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cF.bm.hasError('required')">Please choose a Days Frequency</mat-error>
        </mat-form-field>-->
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>