<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()" autocomplete="off">
<div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Change Password
    </h4>
  </div>
<div class="modal-body">
  <div class="form-group">
    <!-- Grid row -->
    <div class="form-row">
      <!-- Default input -->
      <div class="col-md-12" *ngIf="set==0">
        <div class="form-group input-group">
          <span class="has-float-label ">
            <input placeholder="Enter Current Password" autocomplete="off"  
            name="currentpass" formControlName="currentpass" [type]="old ? 'password' : 'text'" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && aF.currentpass.errors }" required>
            <span class="passwordeye" (click)="old = !old">
              <div class="form-group input-rounded input-icons">
                <i class="{{old ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
              </div>
            </span>
            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
            <label>Old Password</label>
             <div *ngIf="submitted && aF.currentpass.errors" class="invalid-feedback">
                <div *ngIf="aF.currentpass.errors.required">
                  Old Password is required
                </div>
              </div>
            <!--  <button type="button" mat-icon-button matSuffix (click)="old = !old">
             <mat-icon class="d-c3">{{old ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button> -->
          
          </span>
        </div>
      </div>
       <div class="col-md-12">
        <div class="form-group input-group">
          <span class="has-float-label ">
            <input matInput placeholder="Enter New Password" name="newpass" formControlName="newpass" 
            [type]="npass ? 'password' : 'text'" autocomplete="off"  class="form-control"
            [ngClass]="{ 'is-invalid': submitted && aF.newpass.errors }" required>
            <span class="passwordeye" (click)="npass = !npass">
              <div class="form-group input-rounded input-icons">
                <i class="{{npass ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
              </div>
            </span>
            <i class="fa fa-lock icon_innertxtbox"></i>
            <label>New Password</label>
        <!-- <button type="button" mat-icon-button matSuffix (click)="npass = !npass">
        <mat-icon class="d-c7">{{npass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button> -->

          <div *ngIf="submitted && aF.newpass.errors" class="invalid-feedback">
            <div *ngIf="aF.newpass.errors.required">
              New Password is required
               </div>
              </div>
            </span>
          </div>
        </div>

      <div class="col-md-12">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input matInput placeholder="Enter Confirm Password" name="confirmpass" formControlName="confirmpass" [type]="ncpass ? 'password' : 'text'" 
              autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.confirmpass.errors }" required>
              <span class="passwordeye" (click)="ncpass = !ncpass">
                <div class="form-group input-rounded input-icons">
                  <i class="{{ncpass ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
                </div>
              </span>
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>Confirm password</label>
              <div *ngIf="submitted && aF.confirmpass.errors" class="invalid-feedback">
                <div *ngIf="aF.confirmpass.errors.required">
                  Confirm password is required
                </div>
                 <div *ngIf="aF.confirmpass.hasError('mustMatch')">
                  Passwords do not match
                 </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <!-- <button type="button" mat-icon-button matSuffix (click)="ncpass = !ncpass">
        <mat-icon class="d-c9">{{ncpass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button> -->
     

    <!-- Grid row -->
    <div class="clearfix"></div>

    <!--<ng-container *ngIf="isLoading">
      <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
    </ng-container>
    <div *ngIf="responseMessage.length > 0">
      <div class="alert-success" *ngIf="thumb">
        <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
      </div>
      <div class="alert-secondary" *ngIf="!thumb">
        <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
      </div>
    </div>

    
    <div class="col-12 text-right">
      
      <button type="submit" class="dilog dilog1">
        <mat-icon>update</mat-icon>
        Update
      </button>
      <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>
    </div>-->

    <div class="row">
      <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
            <div role="alert" *ngIf="responseMessage.length > 0" >
              <div class="alert alert-success" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
              </div>
            </div>
          
     
       
      <div class="col-sm-12 text-right dflexsave"> 
        <button type="submit" class="dilog dilog1">
          <mat-icon>update</mat-icon>
          Update
        </button>
        <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
          <mat-icon >cancel</mat-icon>
          Cancel
        </button>
      </div>
      </div>
    </div>

  </div>
</div>
</form>