<form [formGroup]="addFormData" (ngSubmit)="upNotificationData()">

  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Actions
    </h4>
  </div>

  <div class="form-grouppact">
    <div class="form-row o_b">
      <div class="with-icon col-md-6">
        <mat-icon class="d-c3">business</mat-icon>
        <label><b>Flat No: </b></label>
      </div>
      <div class="with-icon col-md-6">
        <div class="form-group input-group">
          <span class="has-float-label">
            <input placeholder="" name="flatNo" formControlName="flatNo" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && aF.flatNo.errors}" required>
            <i class="fa fa-building icon_innertxtbox"></i>
            <label>Flat No</label>
            <div *ngIf="submitted && aF.flatNo.errors" class="invalid-feedback">
              <div *ngIf="aF.flatNo.errors.required">
                Flat No is required
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="form-row o_b">
        <div class="with-icon col-sm-6">
          <mat-icon class="d-c3">account_balance</mat-icon>
          <mat-slide-toggle (change)="checkLowBalanceStatus($event.checked)" formControlName="lowBalanceStatus">
            <b>Low Balance</b>
          </mat-slide-toggle>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" placeholder="Low Balance" name="lowBalance1" formControlName="lowBalance1"
                *ngIf="!isLowBlance" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.lowBalance.errors}"
                required>
              <input matInput type="number" placeholder="Low Balance" name="lowBalance" formControlName="lowBalance"
                *ngIf="isLowBlance" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.lowBalance.errors}"
                required>
              <i class="fa fa-university icon_innertxtbox"></i>
              <label>Low Balance</label>
              <div *ngIf="submitted && aF.lowBalance.errors" class="invalid-feedback">
                <div *ngIf="aF.lowBalance.errors.required">
                  Low Balance is required
                </div>
              </div>
            </span>
          </div>
        </div>
     
    </div>

    <div class="form-row o_b">
      <div class="with-icon col-sm-6">
        <mat-icon class="d-c3">history</mat-icon>
        <mat-slide-toggle (change)="checkEBDGStatus($event.checked)" formControlName="consumptionExceedPerdayStatus">
          
          <b>Consumption Exceed Per Day</b>
        </mat-slide-toggle>
      </div>
      <div class="col-md-3">
        <div class="form-group input-group">
          <span class="has-float-label">
            <input type="number" placeholder="Grid Units" name="gridUnits1" formControlName="gridUnits1" *ngIf="!grid"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gridUnits.errors}" required>
            <input matInput type="number" placeholder="Grid Units" name="gridUnits" formControlName="gridUnits"
              *ngIf="grid" class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gridUnits.errors}" required>
            <i class="fa fa-history icon_innertxtbox"></i>
            <label>Grid Units</label>
            <div *ngIf="submitted && aF.gridUnits.errors" class="invalid-feedback">
              <div *ngIf="aF.gridUnits.errors.required">
                Grid Units is required
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group input-group">
          <span class="has-float-label">
            <input matInput type="number" placeholder="DG Units" name="dGUnits1" formControlName="dGUnits1" *ngIf="!dg"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dGUnits1.errors}" required>
            <input matInput type="number" placeholder="DG Units" name="dGUnits" formControlName="dGUnits" *ngIf="dg"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dGUnits1.errors}" required>
            <i class="fa fa-history icon_innertxtbox"></i>
            <label>DG Units</label>
            <div *ngIf="submitted && aF.dGUnits.errors" class="invalid-feedback">
              <div *ngIf="aF.dGUnits.errors.required">
                DG Units is required
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="form-row o_b">
      <div class="with-icon col-sm-6">
        <mat-icon class="d-c9">visibility_off</mat-icon>
        <mat-slide-toggle formControlName="powerCutRestoreStatus">
          <b>Power Cut/Restore</b>
        </mat-slide-toggle>
      </div>
      <div class="with-icon col-sm-6">
        <mat-icon class="d-c5">sync_alt</mat-icon>
        <mat-slide-toggle formControlName="sourceChange">
          <b>Source Charge</b>
        </mat-slide-toggle>
      </div>
    </div>

    <div class="form-row o_b">
      <div class="with-icon col-sm-6">
        <mat-icon class="d-c4">playlist_add</mat-icon>
        <mat-slide-toggle formControlName="rechargeStatus">
          <b>Recharge</b>
        </mat-slide-toggle>
      </div>
      <div class="with-icon col-sm-6">
        <mat-icon class="d-c3">adjust</mat-icon>
        <mat-slide-toggle formControlName="adjustmentStatus">
          <b>Adjustment</b>
        </mat-slide-toggle>
      </div>
    </div>

   
    <!-- Grid row -->
    <div class="row">
      <div class="col-sm-12 text-left">
        <ng-container *ngIf="isLoading">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        <!--<div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="responseMessage.length > 0" >
              <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon>{{responseMessage}}
            </div>-->
        <div *ngIf="responseMessage.length > 0">
          <div class="alert-success" *ngIf="thumb">
            <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
          </div>
          <div class="alert-secondary" *ngIf="!thumb">
            <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
          </div>
        </div>
      </div>

      <div class="col-sm-12 text-right">
        <button type="submit" class="dilog dilog1">
          <mat-icon>update</mat-icon>
          Update
        </button>
        <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>