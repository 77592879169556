import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editmeter',
  templateUrl: './editmeter.component.html',
  styleUrls: ['./editmeter.component.scss']
})
export class EditmeterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
