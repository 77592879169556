import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { Access } from 'src/app/_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { idLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-activityonmeter',
  templateUrl: './activityonmeter.component.html',
  styleUrls: ['./activityonmeter.component.scss']
})
export class ActivityonmeterComponent implements OnInit {
  getThrough: Access;
  message:string = '';
  enableSocietyChange:boolean = true;
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  actionForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  AddProcess:boolean = false;
  responseData: any;
  meterDetail:any = {};
  faSpinner = faSpinner;

 
  isLoading = false;
 
  thumb:boolean = false;
  

  constructor(
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,

    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private ngmodalService: NgbModal,
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    if(this.currentUser.data.userData.authLevel=='7'){
      this.enableSocietyChange=false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    this.actionForm = this.formBuilder.group({
      servantmode: [''],
      meterload: [''],
      backupSupply: [''],
      startfixedchargesonmeter: [''],
      startemergencycredit: [''],
    });
    if(this.enableSocietyChange){
      this.whichSubDomain();
    }
  }
  get aF() { return this.actionForm.controls; }
  get aD() { return this.actionForm.controls; }

  whichSubDomain(){
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val',this.selectedSubDivision);
      }
    );
  }

  ngOnDestroy() {
    this.ngmodalService.dismissAll();
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  onSubmitAddForm(){
    if (this.actionForm.invalid) {
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    if(this.enableSocietyChange){
      body.set('subdivId', this.meterDetail.sdID);
    }else{
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }

   
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('servantmode', this.aD.servantmode.value);
    body.set('meterload', this.aD.meterload.value);
    body.set('backupSupply', this.aD.backupSupply.value);
    body.set('cmdFrom', '2');
    
    if(this.aD.startfixedchargesonmeter.value){
      body.set('startfixedchargesonmeter', 'Y');
    }else{
      body.set('startfixedchargesonmeter', 'N');
    }
    if(this.aD.startemergencycredit.value){
      body.set('startemergencycredit','Y');
    }else{
      body.set('startemergencycredit','N');
    }
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/addMeterActivityDesh', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  otpCheck() {
    if(this.aD.servantmode.value){
      let body = new URLSearchParams();
      this.isLoading = true;
      body.set('userName', this.currentUser.data.userData.username);
      body.set('mobileNo', this.currentUser.data.userData.mobile);
      body.set('emailId', this.currentUser.data.userData.email);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/sendActivityOtp', body).subscribe((result)=>{
        this.responseData = result;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.message;
          this.openOtpDilog(this.aD.servantmode.value);
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.thumb = false;
        this.message = 'Unable to process yours request!';
      });
    }else{
      alert("Please select at least one option to proceed further!");
    }
  }

  openOtpDilog(message) {
    //this.otpCheck();
    this.modalService.openOtpBox(message).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
    });
  }

}
