import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class GridlistService {

    breakpoint: number = 1;
    constructor() {
    }

    reCountCols(width:number) {
        let currentCols = 6;
        if (width >= 1200) {
          currentCols = 6;
        } else if (width >= 992) {
          currentCols = 4;
        } else if (width >= 768) {
          currentCols = 3;
        } else if (width >= 576) {
          currentCols = 2;
        } else if (width < 576) {
          currentCols = 1;
        }
        return currentCols;
      }

      reCountAuthCols(width:number) {
        let currentCols = 5;
        if (width >= 1200) {
          currentCols = 4;
        } else if (width >= 992) {
          currentCols = 3;
        } else if (width >= 768) {
          currentCols = 3;
        } else if (width >= 576) {
          currentCols = 2;
        } else if (width < 576) {
          currentCols = 1;
        }
        return currentCols;
      }

      heightToCols(cols: number): number {
        if (cols === 1) {
          return 250;
        } else if (cols === 2) {
          return 300;
        } else {
          return 350;
        }
      }
}