import { Component, OnInit } from '@angular/core';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from '../TableUtil';
@Component({
  selector: 'app-recentnonrollovereventdata',
  templateUrl: './recentnonrollovereventdata.component.html',
  styleUrls: ['./recentnonrollovereventdata.component.scss']
})
export class RecentnonrollovereventdataComponent {
  faSpinner = faSpinner;
  isLoading:boolean = false;
  isData:boolean = false;

  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;

  meterDetail:any = {};
  commonData:any={};
  dataFinal:any={};
  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];


  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getRealTimeStatus() {
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subDivisionDatabaseName', this.meterDetail.subDivDatabase);
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getRecentNonRollOverData', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        this.isData = true;
        this.letviewis = this.responseData.data;
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("NonRollOverData", "Non-Rollover Event Data");
  }
}
