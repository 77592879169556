<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
    <div class="container mt-3 mb-3">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group input-group">
                  <div class="toggle-wrapper">
                    <div class="toggle checkcross">
                      <input id="checkcross" type="checkbox" placeholder="R-Phase Relay" name="rPhaseReplay" formControlName="rPhaseReplay"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.rPhaseReplay.errors }"
                      required>
                      <label class="toggle-item" for="checkcross">
                        <div class="check"></div>
                      </label>
                    </div>
                    <div class="name">R-Phase Relay</div>
                   
                  <div *ngIf="submitted && cF.rPhaseReplay.errors" class="invalid-feedback">
                      <div *ngIf="cF.rPhaseReplay.errors.required">
                          R-Phase Relay
                      </div>
                  </div>
                  </div>


                    <!-- <span class="has-float-label">
                        <mat-select placeholder="R-Phase Relay" name="rPhaseReplay" formControlName="rPhaseReplay"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.rPhaseReplay.errors }"
                            required>
                            <mat-option *ngFor="let td of replayStatus" [value]="td.id">
                                {{td.name}}
                            </mat-option>
                        </mat-select>
                        <div>
                            <img class="blink-image" style="width: 35px;margin-top: -72px;"
                                src="assets/img/relay.png" />
                        </div>

                        <label>R-Phase Relay</label>
                        <div *ngIf="submitted && cF.rPhaseReplay.errors" class="invalid-feedback">
                            <div *ngIf="cF.rPhaseReplay.errors.required">
                                R-Phase Relay
                            </div>
                        </div>
                    </span> -->
                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group">
                  <div class="toggle-wrapper">
                    <div class="toggle checkcross2">
                      <input id="checkcross2" type="checkbox" placeholder="Y-Phase Relay" name="yPhaseReplay" formControlName="yPhaseReplay"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.yPhaseReplay.errors }"
                      required>
                      <label class="toggle-item" for="checkcross2">
                        <div class="check"></div>
                      </label>
                    </div>
                    <div class="name">Y-Phase Relay</div>
                  <div *ngIf="submitted && cF.yPhaseReplay.errors" class="invalid-feedback">
                      <div *ngIf="cF.yPhaseReplay.errors.required">
                          Y-Phase Relay required
                      </div>
                  </div>
                  </div>


                    <!-- <span class="has-float-label">
                        <mat-select placeholder="Y-Phase Relay" name="yPhaseReplay" formControlName="yPhaseReplay"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.yPhaseReplay.errors }"
                            required>
                            <mat-option *ngFor="let td of replayStatus" [value]="td.id">
                                {{td.name}}
                            </mat-option>
                        </mat-select>
                        <div>
                            <img class="blink-image" style="width: 35px;margin-top: -72px;"
                                src="assets/img/relay.png" />
                        </div>
                        <label>Y-Phase Relay</label>
                        <div *ngIf="submitted && cF.yPhaseReplay.errors" class="invalid-feedback">
                            <div *ngIf="cF.yPhaseReplay.errors.required">
                                Y-Phase Relay required
                            </div>
                        </div>
                    </span> -->
                   
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group input-group">
                  <div class="toggle-wrapper">
                    <div class="toggle checkcross3">
                      <input id="checkcross3" type="checkbox" placeholder="B-Phase Relay" name="bPhaseReplay" formControlName="bPhaseReplay"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.bPhaseReplay.errors }"
                      required>
                      <label class="toggle-item" for="checkcross3">
                        <div class="check"></div>
                      </label>
                    </div>
                    <div class="name">B-Phase Relay</div>
                  <div *ngIf="submitted && cF.bPhaseReplay.errors" class="invalid-feedback">
                      <div *ngIf="cF.bPhaseReplay.errors.required">
                          B-Phase Relay required
                      </div>
                  </div>
                  </div>


                    <!-- <span class="has-float-label">
                        <mat-select placeholder="B-Phase Relay" name="bPhaseReplay" formControlName="bPhaseReplay"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.bPhaseReplay.errors }"
                            required>
                            <mat-option *ngFor="let td of replayStatus" [value]="td.id">
                                {{td.name}}
                            </mat-option>
                        </mat-select>
                        <div>
                            <img class="blink-image" style="width: 35px;margin-top: -72px;"
                                src="assets/img/relay.png" />
                        </div>
                        <label>B-Phase Relay</label>
                        <div *ngIf="submitted && cF.bPhaseReplay.errors" class="invalid-feedback">
                            <div *ngIf="cF.bPhaseReplay.errors.required">
                                B-Phase Relay required
                            </div>
                        </div>
                    </span> -->
                  
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">This configuration is to set the relay's default condition while the meter is running through the DG source.</div>
        </div>

   

    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>
           
            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <button type="submit" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>
  </div>
    <!--<button mat-raised-button color="primary" type="submit">Add</button>-->
</form>