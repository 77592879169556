import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxTimerModule } from 'ngx-timer';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { LoginService } from './services/login.service';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { UserIdleModule } from 'angular-user-idle';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { DeshboardComponent } from './deshboard/deshboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './users/users.component';
import { MenuComponent } from './menu/menu.component';
import { Deshboardauth2Component } from './deshboardauth2/deshboardauth2.component';
import { Deshboardauth3Component } from './deshboardauth3/deshboardauth3.component';
import { Deshboardauth4Component } from './deshboardauth4/deshboardauth4.component';
import { Deshboardauth5Component } from './deshboardauth5/deshboardauth5.component';
import { Deshboardauth6Component } from './deshboardauth6/deshboardauth6.component';
import { FooterComponent } from './footer/footer.component';
import { CountryToStateComponent } from './country-to-state/country-to-state.component';
import { StateToUtilityComponent } from './state-to-utility/state-to-utility.component';
import { UtilityToCircleComponent } from './utility-to-circle/utility-to-circle.component';
import { CircleToDivisionComponent } from './circle-to-division/circle-to-division.component';
import { DivisionToSubdivisionComponent } from './division-to-subdivision/division-to-subdivision.component';
import { LeftnavComponent } from './nav/leftnav/leftnav.component';
import { HeadnavComponent } from './nav/headnav/headnav.component';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AddedconsumerComponent } from './consumerview/addedconsumer/addedconsumer.component';
import { AddconsumerComponent } from './consubview/addconsumer/addconsumer.component';
import { EditconsumerComponent } from './consubview/editconsumer/editconsumer.component';
import { PermconsumerComponent } from './consubview/permconsumer/permconsumer.component';
import { ConscomplaintComponent } from './consubview/conscomplaint/conscomplaint.component';
import { ConsscomplaintComponent } from './consumerview/consscomplaint/consscomplaint.component';


import { RechargetopayComponent } from './rechargetopay/rechargetopay.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { RechargeusermeterComponent } from './consumerview/rechargeusermeter/rechargeusermeter.component';
import { EditconcomplaintComponent } from './consubview/editconcomplaint/editconcomplaint.component';


import { AdjustmentusermeterComponent } from './consumerview/adjustmentusermeter/adjustmentusermeter.component';
import { SubdivisionToLayoutComponent } from './subdivision-to-layout/subdivision-to-layout.component';
import { TemperReportModalComponent } from './temper-report-modal/temper-report-modal.component';
import { DeshboardconsumerComponent } from './deshboardconsumer/deshboardconsumer.component';
import { SafePipe } from './pipes/safe.pipe';
import { DeshboardauthallComponent } from './deshboardauthall/deshboardauthall.component';
import { DcuComponent } from './deshboardauthall/reports/dcu/dcu.component';
import { ConsumersComponent } from './deshboardauthall/reports/consumers/consumers.component';


import { NgxPrintModule } from 'ngx-print';
import { SuccessComponent } from './rechargetopay/success/success.component';
import { FailedComponent } from './rechargetopay/failed/failed.component';
import { ChartsModule } from 'ng2-charts';
import { MobsuccessComponent } from './rechargetopay/mobsuccess/mobsuccess.component';
import { MobfailComponent } from './rechargetopay/mobfail/mobfail.component';


import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { TopbarComponent } from './nav/topbar/topbar.component';


import { MeterConfigComponent } from './deshboardauthall/meter-config/meter-config.component';
import { RechargemobComponent } from './rechargemob/rechargemob.component';
import { SuccessMobComponent } from './rechargemob/success-mob/success-mob.component';
import { FailedMobComponent } from './rechargemob/failed-mob/failed-mob.component';


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { ConfigureMeterFromSubdivisionComponent } from './subdivision-to-layout/configure-meter-from-subdivision/configure-meter-from-subdivision.component';
import { ConfigureMetersComponent } from './subdivision-to-layout/configure-meters/configure-meters.component';
import { ConfigureDCUComponent } from './subdivision-to-layout/configure-dcu/configure-dcu.component';
import { LeftnavService } from './_services/leftnav.service';
import { NgImageSliderModule } from 'ng-image-slider';

import { FootertopComponent } from './footertop/footertop.component';
import { JobsComponent } from './jobs/jobs.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { CounterdataComponent } from './counterdata/counterdata.component';

import { DeshboardallsosComponent } from './deshboardallsos/deshboardallsos.component';
import { ReplacedmetersreportComponent } from './deshboardauthall/replacedmetersreport/replacedmetersreport.component';

import { MobreportsComponent } from './mobreports/mobreports.component';
import { DaywiseinstallationprogressComponent } from './mobreports/daywiseinstallationprogress/daywiseinstallationprogress.component';
import { SmreplacementComponent } from './mobreports/smreplacement/smreplacement.component';
import { SmdefectivereplacementComponent } from './mobreports/smdefectivereplacement/smdefectivereplacement.component';
import { ProgresstilldateComponent } from './mobreports/progresstilldate/progresstilldate.component';
import { ProgresstilldatenewComponent } from './mobreports/progresstilldatenew/progresstilldatenew.component';
import { ReplacedmeterinfoComponent } from './mobreports/replacedmeterinfo/replacedmeterinfo.component';
import { OldnewmeterinfomobComponent } from './mobreports/oldnewmeterinfomob/oldnewmeterinfomob.component';

import { MapModule } from './mobilemapview/map/map.module';
import { MobilemapviewComponent } from './mobilemapview/mobilemapview.component';



import { UserworkstatusComponent } from './mobreports/userworkstatus/userworkstatus.component';


import { CheckmeterreportComponent } from './mobreports/checkmeterreport/checkmeterreport.component';
import { FileUploaderService } from './subview/file-uploader.service';



import { IpclconfigComponent } from './deshboardauthall/ipclconfig/ipclconfig.component';


import { MeterconfigserverdetailsComponent } from './ncomps/meterconfigserverdetails/meterconfigserverdetails.component';
import { MeterconfigpushdataComponent } from './ncomps/meterconfigpushdata/meterconfigpushdata.component';
import { MeterconfigphasegsupplyComponent } from './ncomps/meterconfigphasegsupply/meterconfigphasegsupply.component';
import { MeterconfigphasebkpsupplyComponent } from './ncomps/meterconfigphasebkpsupply/meterconfigphasebkpsupply.component';
import { MeterconfigloadlimitThreeComponent } from './ncomps/meterconfigloadlimit-three/meterconfigloadlimit-three.component';
import { MeterconfigloadlimitComponent } from './ncomps/meterconfigloadlimit/meterconfigloadlimit.component';
import { MeterconfighappyhoursComponent } from './ncomps/meterconfighappyhours/meterconfighappyhours.component';
import { MeterconfigdisplayparamComponent } from './ncomps/meterconfigdisplayparam/meterconfigdisplayparam.component';
import { MeterconfigdcugateidComponent } from './ncomps/meterconfigdcugateid/meterconfigdcugateid.component';
import { MeterconfigcurrentthrasholdComponent } from './ncomps/meterconfigcurrentthrashold/meterconfigcurrentthrashold.component';
import { MeterconfigcreditemergComponent } from './ncomps/meterconfigcreditemerg/meterconfigcreditemerg.component';
import { MeterconfigbilltariffComponent } from './ncomps/meterconfigbilltariff/meterconfigbilltariff.component';
import { MeterconfigaebSlabComponent } from './ncomps/meterconfigaeb-slab/meterconfigaeb-slab.component';
import { MetercofigpushComponent } from './ncomps/metercofigpush/metercofigpush.component';
import { MeterbillschedularComponent } from './ncomps/meterbillschedular/meterbillschedular.component';
import { MeterBillingDateComponent } from './ncomps/meter-billing-date/meter-billing-date.component';
import { Eswfilter1Component } from './ncomps/eswfilter1/eswfilter1.component';
import { EswfilterComponent } from './ncomps/eswfilter/eswfilter.component';
import { DcumeterreportingComponent } from './ncomps/dcumeterreporting/dcumeterreporting.component';
import { DcumeterconfigComponent } from './ncomps/dcumeterconfig/dcumeterconfig.component';
import { DcumeteractivityComponent } from './ncomps/dcumeteractivity/dcumeteractivity.component';
import { DcuconfigserverComponent } from './ncomps/dcuconfigserver/dcuconfigserver.component';
import { DcuconfigschedularComponent } from './ncomps/dcuconfigschedular/dcuconfigschedular.component';
import { DcuconfigrtcComponent } from './ncomps/dcuconfigrtc/dcuconfigrtc.component';
import { DcuconfigmeterrouterComponent } from './ncomps/dcuconfigmeterrouter/dcuconfigmeterrouter.component';
import { DcuconfigComponent } from './ncomps/dcuconfig/dcuconfig.component';
import { AutocompleteComponent } from './ncomps/autocomplete/autocomplete.component';
import { ActionondcuComponent } from './ncomps/actionondcu/actionondcu.component';

import { MeterconfigaebComponent } from './ncomps/meterconfigaeb/meterconfigaeb.component';
import { Meterteriff1phcommercialComponent } from './ncomps/meterteriff1phcommercial/meterteriff1phcommercial.component';
import { Meterteriff1phdomesticComponent } from './ncomps/meterteriff1phdomestic/meterteriff1phdomestic.component';
import { RelaycontrolComponent } from './ncomps/relaycontrol/relaycontrol.component';
import { ReportingfromdcuComponent } from './ncomps/reportingfromdcu/reportingfromdcu.component';
import { ConfigmeterComponent } from './authorityview/configmeter/configmeter.component';
import { GetJobsService } from './_services/getjobs.service';
import { AuthinfoComponent } from './authorityview/reports/authinfo/authinfo.component';
import { ConsumptionsComponent } from './deshboardconsumer/reports/consumptions/consumptions.component';
import { RechargesComponent } from './deshboardconsumer/reports/recharges/recharges.component';
import { AdjustmentsComponent } from './deshboardconsumer/reports/adjustments/adjustments.component';
import { ConfigLogReportComponent } from './deshboardconsumer/reports/config-log-report/config-log-report.component';
import { MeterRealTimeComponent } from './deshboardconsumer/reports/meter-real-time/meter-real-time.component';
import { ConsumerBillComponent } from './deshboardconsumer/reports/consumer-bill/consumer-bill.component';
import { EventLogComponent } from './deshboardconsumer/reports/event-log/event-log.component';
import { LoadSurveyComponent } from './deshboardconsumer/reports/load-survey/load-survey.component';
import { SmsReportsComponent } from './deshboardconsumer/reports/sms-reports/sms-reports.component';
import { ConMeterActivityComponent } from './deshboardconsumer/reports/con-meter-activity/con-meter-activity.component';
import { NotificationHistoryComponent } from './deshboardconsumer/reports/notification-history/notification-history.component';
import { ComplaintHistoryComponent } from './deshboardconsumer/reports/complaint-history/complaint-history.component';
import { DipwiseLoadSurveyComponent } from './deshboardconsumer/reports/dipwise-load-survey/dipwise-load-survey.component';
import { ConsumerLoginsComponent } from './deshboardconsumer/reports/consumer-logins/consumer-logins.component';
import { MaintbillComponent } from './deshboardconsumer/reports/maintbill/maintbill.component';
import { CheckmeterbillreportComponent } from './mobreports/checkmeterbillreport/checkmeterbillreport.component';
import { CheckmeterbillComponent } from './mobreports/checkmeterbill/checkmeterbill.component';
import { UtilityconfigComponent } from './deshboardauthall/utilityconfig/utilityconfig.component';



@NgModule({
    declarations: [
        MobilemapviewComponent,
        ConfigureDCUComponent,
        ConfigureMetersComponent,
        MeterConfigComponent,
   
        AppComponent,
        ErrorDialogComponent,
        DeshboardComponent,
        LoginComponent,
        RegisterComponent,
        UsersComponent,
        MenuComponent,
        Deshboardauth2Component,
        Deshboardauth3Component,
        Deshboardauth4Component,
        Deshboardauth5Component,
        Deshboardauth6Component,
        DeshboardconsumerComponent,
        FooterComponent,
        CountryToStateComponent,
        StateToUtilityComponent,
        UtilityToCircleComponent,
        CircleToDivisionComponent,
        DivisionToSubdivisionComponent,
        SubdivisionToLayoutComponent,
        LeftnavComponent,
        HeadnavComponent,
        AddconsumerComponent,
        ConscomplaintComponent,
        ConsscomplaintComponent,
        EditconsumerComponent,
        PermconsumerComponent,
        RechargetopayComponent,
        ConfirmationDialogComponent,
        RechargeusermeterComponent,
        EditconcomplaintComponent,
        DeshboardauthallComponent,
  
        AdjustmentusermeterComponent,
        ConfigureMeterFromSubdivisionComponent,
        TemperReportModalComponent,
        SafePipe,
        ConsumersComponent,
        DcuComponent,
        SuccessComponent,
        FailedComponent,
        MobsuccessComponent,
        MobfailComponent,

        PrivacyPolicyComponent,
        ContactusComponent,
        AboutusComponent,
        TopbarComponent,
        RechargemobComponent,
        SuccessMobComponent,
        FailedMobComponent,
   
        FootertopComponent,
        JobsComponent,
        CounterdataComponent,
        DeshboardallsosComponent,
        ReplacedmetersreportComponent,
        MobreportsComponent,
        DaywiseinstallationprogressComponent,
        SmreplacementComponent,
        SmdefectivereplacementComponent,
        ProgresstilldateComponent,
        ProgresstilldatenewComponent,
        ReplacedmeterinfoComponent,
        OldnewmeterinfomobComponent,
        UserworkstatusComponent,
        AddedconsumerComponent,
        CheckmeterreportComponent,
      
        IpclconfigComponent,
        

        MeterconfigserverdetailsComponent,
        MeterconfigpushdataComponent,
        MeterconfigphasegsupplyComponent,
        MeterconfigphasebkpsupplyComponent,
        MeterconfigloadlimitThreeComponent,
        MeterconfigloadlimitComponent,
        MeterconfighappyhoursComponent,
        MeterconfigdisplayparamComponent,
        MeterconfigdcugateidComponent,
        MeterconfigcurrentthrasholdComponent,
        MeterconfigcreditemergComponent,
        MeterconfigbilltariffComponent,
        MeterconfigaebSlabComponent,
        MeterconfigaebSlabComponent,
        MetercofigpushComponent,
        MeterbillschedularComponent,
        MeterBillingDateComponent,
        Eswfilter1Component,
        EswfilterComponent,
        DcumeterreportingComponent,
        DcumeterconfigComponent,
        DcumeteractivityComponent,
        DcuconfigserverComponent,
        DcuconfigschedularComponent,
        DcuconfigrtcComponent,
        DcuconfigmeterrouterComponent,
        DcuconfigComponent,
        AutocompleteComponent,
        ActionondcuComponent,
        RelaycontrolComponent,
        MeterconfigaebComponent,
        Meterteriff1phcommercialComponent,
        Meterteriff1phdomesticComponent,
        ReportingfromdcuComponent,
        ConfigmeterComponent,
        AuthinfoComponent, 
        ConsumptionsComponent,
        RechargesComponent,
        AdjustmentsComponent,
        ConfigLogReportComponent,
        MeterRealTimeComponent,
        ConsumerBillComponent,
        EventLogComponent,
        LoadSurveyComponent,
        SmsReportsComponent,
        ConMeterActivityComponent,
        NotificationHistoryComponent,
        ComplaintHistoryComponent,
        DipwiseLoadSurveyComponent,
        ConsumerLoginsComponent,
        MaintbillComponent,
        CheckmeterbillreportComponent,
        CheckmeterbillComponent,
        UtilityconfigComponent
    ],
    imports: [
        
        LeafletModule,
        LeafletMarkerClusterModule,
        MapModule,
        //MatTableExporterModule,
        //ScrollToModule.forRoot(),
        //GalleryModule,
        //LightboxModule,
        NgImageSliderModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDialogModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatSidenavModule,
        MatGridListModule,
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        NgxTimerModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatRadioModule,
        NgbModule,
        DragDropModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatStepperModule,
        FormsModule,
        MatDatepickerModule, MatNativeDateModule, MatSortModule, MatBadgeModule,
        // for HttpClient use:
        LoadingBarHttpClientModule,
        NgxPrintModule,
        UserIdleModule.forRoot({ idle: 200, timeout: 150, ping: 120 }),
        ChartsModule,
        //RxReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        FontAwesomeModule,
        MatTooltipModule,
        //UticonfigComponent
        // GuidedTourModule
        //MatTableExporterModule
    ],
    providers: [
        LoginService,
        ErrorDialogService,
        NgbActiveModal,
        ConfirmationDialogService, MatDatepickerModule,
        LeftnavService,
        //VirtualScrollService,
        //PageService,
        //SortService,
        //FilterService,
        //GroupService,
        // GuidedTourService,
        FileUploaderService,
        GetJobsService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
