<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Recent Bills: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container>
      <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
      </ng-container>
    <div class="blgrid table-responsive" *ngIf="isData && view=='N'">
      <table class="table table-bordered table-sm table-hover">
        <thead class="tablebghead">
            <tr>
              <th>
                <div class="d_title">
                  <span></span>Sr. No.
              </div>
            </th>
              <th *ngIf="permission.Single_Billing_Parm_Cons_Account_No==1">
                <div class="d_title">
                  <span></span>Cons UId
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Parm_Meter_Unque_Id==1">
                <div class="d_title">
                  <span></span>Meter UId
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Parm_Flat_No_Id==1">
                <div class="d_title">
                  <span></span>Flat No
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Parm_Meter_Id==1">
                <div class="d_title">
                  <span></span>Meter No
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Parm_RTC==1">
                <div class="d_title">
                  <span></span>Bill Date
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Md_Reset_Type==1">
                <div class="d_title ">
                  <span></span>Billing Type
              </div>
              </th>
              
              <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB==1">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone1==1">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z1
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone2==1">
                <div class="d_title" >
                  <span></span>Cumulative kWh Grid Z2
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone3==1">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z3
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone4==1">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z4
              </div>
              </th>
             
                <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone5==1">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z5
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone6==1">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z6
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone7==1">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z7
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone8==1">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z8
                </div>
                </th>
              <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB==1">
                <div class="d_title ">
                  <span></span>Cumulative kVAh Grid
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone1==1">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z1
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone2==1">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z2
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone3==1">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z3
              </div>
              </th>
              <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone4==1">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z4
              </div>
              </th>
              
                <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone5==1">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z5
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone6==1">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z6
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone7==1">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z7
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone8==1">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z8
                </div>
                </th>
                <th *ngIf="meterType==3" >
                  <div class="d_title">
                    <span></span>Cumulative kVArh Lg
                </div>
                </th>
                <!--<th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cum kVArh Q2
                </div>
                </td>
                <th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cum kVArh Q3
                </div>
                </td>-->
                <th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cumulative kVArh Ld
                </div>
                </th>
                
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value==1">
                  <div class="d_title">
                    <span></span>MD kW Grid
                </div>
                </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Date & Time
                </div>
                </th>

                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone1==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z1
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone1==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z1 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone2==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z2
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone2==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z2 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone3==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z3
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone3==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z3 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone4==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z4
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone4==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z4 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone5==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z5
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone5==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z5 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone6==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z6
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone6==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z6 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone7==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z7
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone7==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z7 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone8==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z8
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone8==1">
                  <div class="d_title">
                    <span></span>MD kW Grid Z8 Date & Time
                </div>
              </th>


                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone1==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z1
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone1==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z1 Date & Time
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone2==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z2
                </div>
              </th>
                <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone2==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z2 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone3==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z3
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone3==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z3 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone4==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z4
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone4==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z4 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone5==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z5
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone5==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z5 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone6==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z6
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone6==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z6 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone7==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z7
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone7==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z7 Date & Time
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone8==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z8
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone8==1">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z8 Date & Time
                </div>
              </th>

              <th *ngIf="permission.Single_Billing_Tod_Script_1==1">
                  <div class="d_title">
                    <span></span>TOD Script 1
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_1==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 1
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_2==1">
                  <div class="d_title">
                    <span></span>TOD Script 2
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_2==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 2
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_3==1">
                  <div class="d_title">
                    <span></span>TOD Script 3
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_3==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 3
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_4==1">
                  <div class="d_title">
                    <span></span>TOD Script 4
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_4==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 4
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_5==1">
                  <div class="d_title">
                    <span></span>TOD Script 5
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_5==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 5
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_6==1">
                  <div class="d_title">
                    <span></span>TOD Script 6
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_6==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 6
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_7==1">
                  <div class="d_title">
                    <span></span>TOD Script 7
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_7==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 7
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Script_8==1">
                  <div class="d_title">
                    <span></span>TOD Script 8
                </div>
              </th>
              <th *ngIf="permission.Single_Billing_Tod_Timing_TZ_8==1">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 8
                </div>
              </th>
             
              <th *ngIf="permission.Single_Billing_Bill_PowerOn_Hrs_EB==1">
                <div class="d_title">
                  <span></span>PowerOnHours EB
              </div>
            </th>
            <th *ngIf="permission.Single_Billing_Server_Date_Time==1">
                <div class="d_title ">
                  <span></span>Server Datetime
              </div>
            </th>
            <th *ngIf="permission.Single_Billing_Auth_Unique_Id==1">
                <div class="d_title ">
                  <span></span>Authority Name
              </div>
            </th>
            </tr>
          </thead>
          <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Parm_Cons_Account_No==1">
              <div class="d_content d_content_W">
                <p>{{item.cUID}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Parm_Meter_Unque_Id==1">
              <div class="d_content d_content_W">
                <p>{{item.mUID}}</p>
              </div>
            </td>
            <td  *ngIf="permission.Single_Billing_Parm_Flat_No_Id==1">
              <div class="d_content d_content_W">
                <p>{{item.flatNo}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Parm_Meter_Id==1">
              <div class="d_content d_content_W">
                <p>{{item.meterId}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Parm_RTC==1">
              <div class="d_content d_content_W">
                <p>{{item.billingDateTime}}</p>
            </div>
            </td>
            <td *ngIf="permission.Single_Billing_Md_Reset_Type==1">
              <div class="d_content d_content_W">
                <p>{{item.mdRestType}}</p>
            </div>
            </td>
            
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEB}}</p>
            </div>
            </td>

            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone1==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ1}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone2==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ2}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone3==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ3}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone4==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ4}}</p>
              </div>
            </td>
          
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone5==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ5}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone6==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ6}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone7==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ7}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Active_Energy_EB_Zone8==1">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ8}}</p>
              </div>
            </td>

            
            <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB==1">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEB}}</p>
            </div>
            </td>

            <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone1==1">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ1}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone2==1">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ2}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone3==1">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ3}}</p>
              </div>
            </td>
            <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone4==1">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ4}}</p>
              </div>
            </td>
            
              <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone5==1">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone6==1">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone7==1">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Cum_Apperant_Energy_EB_Zone8==1">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ1}}</p>
                </div>
              </td>
              <!--<th *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ2}}</p>
                </div>
              </td>
              <th *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ3}}</p>
                </div>
              </td>-->
              <td *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ4}}</p>
                </div>
              </td>

              
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEB}}</p>
              </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDate}}</p>
              </div>
              </td>

              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone1==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ1}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone1==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ1}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone2==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone2==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone3==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone3==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone4==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone4==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone5==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone5==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone6==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone6==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone7==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone7==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Value_Zone8==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Active_Power_Datetime_Zone8==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ8}}</p>
                </div>
              </td>

              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEB}}</p>
              </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDate}}</p>
              </div>
              </td>

              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone1==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ1}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone1==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ1}}</p>
                </div>
              </td>

              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone2==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone2==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone3==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone3==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone4==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone4==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone5==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone5==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone6==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone6==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone7==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone7==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Value_Zone8==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_MD_Apparent_Power_Datetime_Zone8==1">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ8}}</p>
                </div>
              </td>

              <td *ngIf="permission.Single_Billing_Tod_Script_1==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript1}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_1==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ1}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_2==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_2==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ2}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_3==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_3==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ3}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_4==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_4==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ4}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_5==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_5==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ5}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_6==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_6==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ6}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_7==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_7==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ7}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Script_8==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScript8}}</p>
                </div>
              </td>
              <td *ngIf="permission.Single_Billing_Tod_Timing_TZ_8==1">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ8}}</p>
                </div>
              </td>
            <td *ngIf="permission.Single_Billing_Bill_PowerOn_Hrs_EB==1">
              <div class="d_content d_content_W">
                <p>{{item.billPowerOnHrsEB}}</p>
            </div>
            </td>
            <td *ngIf="permission.Single_Billing_Server_Date_Time==1">
              <div class="d_content d_content_W">
                <p>{{item.dateTime}}</p>
            </div>
            </td>
            <td *ngIf="permission.Single_Billing_Auth_Unique_Id==1">
              <div class="d_content d_content_W">
                <p>{{item.authName}}</p>
            </div>
            </td>
</tr>
</tbody>
</table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        <span></span>{{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>       
        <div class="col-12 text-left">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->    
      </div>

      <div class="row blgrid table-responsive" *ngIf="isData && view=='Y'">
        <table class="table table-bordered table-sm table-hover">
          <thead class="tablebghead">
            <tr>
              <th>
                <div class="d_title">
                  <span></span>Sr. No.
              </div>
              </th>
              <th>
                <div class="d_title">
                  <span></span>Meter Id
              </div>
              </th>
              <th>
                <div class="d_title">
                  <span></span>Bill Date
              </div>
              </th>
              <div colspan="2" *ngIf="databaseName!='Panasonic_93'">
                <th *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Billing Mode EB
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title " >
                    <span></span>Billing Mode DG
                </div>
                </th>
              </div>
              <th>
                <div class="d_title ">
                  <span></span>Billing Type
              </div>
              </th>
              
              <th>
                <div class="d_title">
                  <span></span>Cumulative kWh Grid
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z1
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title" >
                  <span></span>Cumulative kWh Grid Z2
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z3
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kWh Grid Z4
              </div>
              </th>
              <div *ngIf="databaseName!='Panasonic_93'">
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z5
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z6
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z7
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kWh Grid Z8
                </div>
                </th>
                <th  *ngIf="view=='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kWh DG
                </div>
                </th>
              </div>
              <th>
                <div class="d_title ">
                  <span></span>Cumulative kVAh Grid
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z1
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z2
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z3
              </div>
              </th>
              <th *ngIf="meterType==3 || view!='Y'">
                <div class="d_title">
                  <span></span>Cumulative kVAh Grid Z4
              </div>
              </th>
              <div *ngIf="databaseName!='Panasonic_93'">
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z5
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z6
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z7
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>Cumulative kVAh Grid Z8
                </div>
                </th>
                <th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cumulative kVArh Lg
                </div>
                </th>
                <!--<th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cum kVArh Q2
                </div>
                </th>
                <th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cum kVArh Q3
                </div>
                </th>-->
                <th *ngIf="meterType==3">
                  <div class="d_title">
                    <span></span>Cumulative kVArh Ld
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title " >
                    <span></span>Cumulative kVAh DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Month kWh Grid
                </div>
                </th>
                <th  *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Month kWh DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Month kVAh Grid
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Month kVAh DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Free Units DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title " >
                    <span></span>Opening (Rs.)
                </div>
                </th>
                <th  *ngIf="view=='Y'">
                  <div class="d_title">
                    <span></span>Credit (Rs.)
                </div>
                </th>
                <th  *ngIf="view=='Y'">
                  <div class="d_title ">
                    <span></span>Grid Charges (Rs.)
                </div>
                </th>
                <th  *ngIf="view=='Y'">
                  <div class="d_title">
                    <span></span>DG Charges (Rs.)
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>Fix Charges (Rs.)
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>Bill Amount (Rs.)
                </div>
                </th>
              
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>Closing Balance (Rs.)
                </div>
                </th>

                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>Bill Count
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title">
                    <span></span> Tamper Count
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title">
                    <span></span>Programming Count
                </div>
                </th>
                <th>
                  <div class="d_title">
                    <span></span>MD kW Grid
                </div>
                </th>
                <th>
                  <div class="d_title">
                    <span></span>MD kW Grid Date & Time
                </div>
                </th>

                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z1
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z1 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z2
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z2 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z3
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z3 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z4
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z4 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z5
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z5 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z6
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z6 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z7
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z7 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z8
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kW Grid Z8 Date & Time
                </div>
                </th>


                <th>
                  <div class="d_title">
                    <span></span>MD kVA Grid
                </div>
                </th>
                <th>
                  <div class="d_title">
                    <span></span>MD kVA Grid Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z1
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z1 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z2
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z2 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z3
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z3 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z4
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z4 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z5
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z5 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z6
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z6 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z7
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z7 Date & Time
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z8
                </div>
                </th>
                <th *ngIf="meterType==3 || view!='Y'">
                  <div class="d_title">
                    <span></span>MD kVA Grid Z8 Date & Time
                </div>
                </th>

                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>MD kW DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>MD kW DG Date & Time
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>MD kVA DG
                </div>
                </th>
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>MD kVA DG Date & Time
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 1
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 1
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 2
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 2
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 3
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 3
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 4
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 4
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 5
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 5
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 6
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 6
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 7
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 7
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Script 8
                </div>
                </th>
                <th *ngIf="view!='Y'">
                  <div class="d_title">
                    <span></span>TOD Timing TZ 8
                </div>
                </th>
              </div>
              <th>
                <div class="d_title">
                  <span></span>PowerOnHours EB
              </div>
              </th>
              <div *ngIf="databaseName!='Panasonic_93'">
                <th *ngIf="view=='Y'">
                  <div class="d_title" >
                    <span></span>PowerOnHours DG
                </div>
                </th>
              </div>
              
            </tr>
          </thead>
          <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.meterId}}</p>
              </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.billingDateTime}}</p>
            </div>
            </td>
            <div *ngIf="databaseName!='Panasonic_93'">
              <td  *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.billingModeEB}}</p>
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_content d_content_W" >
                  <p>{{item.billingModeDG}}</p>
              </div>
              </td>
            </div>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.mdRestType}}</p>
            </div>
            </td>
            
            <td>
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEB}}</p>
            </div>
            </td>

            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ1}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ2}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ3}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ4}}</p>
              </div>
            </td>
            <div *ngIf="databaseName!='Panasonic_93'">
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ5}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ6}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ7}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumActiveEnergyEBZ8}}</p>
              </div>
            </td>

            <td  *ngIf="view=='Y'">
              <div class="d_content d_content_W" >
                <p>{{item.cumActiveEnergyDG}}</p>
            </div>
            </td>
            </div>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEB}}</p>
            </div>
            </td>

            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ1}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ2}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ3}}</p>
              </div>
            </td>
            <td *ngIf="meterType==3 || view!='Y'">
              <div class="d_content d_content_W">
                <p>{{item.cumApperantEnergyEBZ4}}</p>
              </div>
            </td>
            <div *ngIf="databaseName!='Panasonic_93'">
              <td *ngIf="meterType==3 || view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 || view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 || view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 || view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ1}}</p>
                </div>
              </td>
              <!--<th *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ2}}</p>
                </div>
              </td>
              <th *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ3}}</p>
                </div>
              </td>-->
              <td *ngIf="meterType==3">
                <div class="d_content d_content_W">
                  <p>{{item.cumkVARHQ4}}</p>
                </div>
              </td>

              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.cumApperantEnergyDG}}</p>
              </div>
              </td>
              
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.billActiveEnergyEB}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billActiveEnergyDG}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.billApperantEnergyEB}}</p>
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_content d_content_W" >
                  <p>{{item.billApperantEnergyDG}}</p>
              </div>
              </td>
              
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.dgFreeUnits}}</p>
              </div>
              </td>
              
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billOpeningBalance}}</p>
              </div>
              </td>
              
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billTotalCredit}}</p>
              </div>
              </td>

              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billGridCharges}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billDGCharges}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billFixedCharges}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W"  >
                  <p>{{item.billAmount}}</p>
              </div>
              </td>
              
              <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W"  >
                    <p>{{item.billAvailableBalance}}</p>
                </div>
              </td>
              
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W" >
                  <p>{{item.totalBillCount}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.totalTamperCount}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.totalProgrammingCount}}</p>
              </div>
              </td>
              <td>
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEB}}</p>
              </div>
              </td>
              <td>
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDate}}</p>
              </div>
              </td>

              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ1}}</p>
                </div>
              </td>
              <th *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ1}}</p>
                </div>
              </th>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ2}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ2}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ3}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ3}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ4}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ4}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ5}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ6}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ7}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWEBDateZ8}}</p>
                </div>
              </td>

              <td>
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEB}}</p>
              </div>
              </td>
              <td>
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDate}}</p>
              </div>
              </td>

              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ1}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ1}}</p>
                </div>
              </td>

              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ2}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ2}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ3}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ3}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ4}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ4}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ5}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ5}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ6}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ6}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ7}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ7}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBZ8}}</p>
                </div>
              </td>
              <td *ngIf="meterType==3 ||  view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVAEBDateZ8}}</p>
                </div>
              </td>

              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript1}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ1}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript2}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ2}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript3}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ3}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript4}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ4}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript5}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ5}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript6}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ6}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript7}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ7}}</p>
                </div>
              </td>
              <td *ngIf="view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScript8}}</p>
                </div>
              </td>
              <td *ngIf=" view!='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.todScriptTZ8}}</p>
                </div>
              </td>


              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkWDG}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W" >
                  <p>{{item.mDkWDGDate}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W">
                  <p>{{item.mDkVADG}}</p>
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_content d_content_W ">
                  <p>{{item.mDkVADGDate}}</p>
              </div>
              </td>
            </div>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.billPowerOnHrsEB}}</p>
            </div>
            </td>
            <div *ngIf="databaseName!='Panasonic_93'">
            <td *ngIf="view=='Y'">
              <div class="d_content d_content_W" >
                <p>{{item.billPowerOnHrsDG}}</p>
            </div>
            </td>
          </div>
</tr>
</tbody>
</table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        <span></span>{{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>       
        <div class="col-12 text-left">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->    
      </div>

      <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
        <div class="col-sm-12">
          <img class="center" src="assets/images/Nodata.png">
        </div>
        <!--<div class="col-12 text-left">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->
      </div>
      
    </ng-container>
<ng-container col-sm-3>
</ng-container>
</div>
</div>  