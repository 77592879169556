import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-smart-meter-order',
  templateUrl: './add-smart-meter-order.component.html',
  styleUrls: ['./add-smart-meter-order.component.scss']
})
export class AddSmartMeterOrderComponent implements OnInit {

  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  companyId= 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
   submitted: boolean = false;
  companyAddress = '';
  companyGst= '';
  public projectCodeData: any = [];

  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyAddress: ['', Validators.required],
      gstNo: ['', Validators.required],
      singlePhMeterRate: ['', Validators.required],
      singlePhphMeterQty: ['', Validators.required],
      threePhMeterRate: ['', Validators.required],
      threePhMeterQty: ['', Validators.required],
      ltctMeterRate: ['', Validators.required],
      ltctMeterQty: ['', Validators.required],
    });
  }
  ngAfterViewInit() {
     this.getCompanyNames();
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  getCompanyNames() {
    let body = new URLSearchParams();
    body.set('companyId','0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCompanyName', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.projectCodeData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getAddress() {
    let body = new URLSearchParams();
    body.set('companyId',  this.companyId.toString());
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCompanyAddress', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.aF.companyAddress.setValue(this.responseData.data.address);
        this.aF.gstNo.setValue(this.responseData.data.gstNo);
      }else{
        this.aF.companyAddress.reset();
        this.aF.gstNo.reset();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  public saveComapnyId(e): void {
    let find = this.projectCodeData.find(x => x?.text === e.target.value);

    if (find?.text === undefined) {
      this.companyId = 0;
      this.aF.companyAddress.reset();
      this.aF.gstNo.reset();
      this.aF.singlePhMeterRate.reset();
      this.aF.threePhMeterRate.reset();
      this.aF.ltctMeterRate.reset();
    } else {
      this.companyId = find?.id;
      this.aF.companyAddress.setValue(find?.address);
      this.aF.gstNo.setValue(find?.gstNo);
      this.aF.singlePhMeterRate.setValue(find?.singlePhRate);
      this.aF.threePhMeterRate.setValue(find?.threePhRate);
      this.aF.ltctMeterRate.setValue(find?.ltctRate);
    }
    //this.getAddress();
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('companyName', this.aF.companyName.value);
      body.set('companyAddress', this.aF.companyAddress.value);
      body.set('gstNo', this.aF.gstNo.value);
      body.set('singlePhMeterRate', this.aF.singlePhMeterRate.value);
      body.set('singlePhphMeterQty', this.aF.singlePhphMeterQty.value);
      body.set('threePhMeterRate', this.aF.threePhMeterRate.value);
      body.set('threePhMeterQty', this.aF.threePhMeterQty.value);
      body.set('ltctMeterRate', this.aF.ltctMeterRate.value);
      body.set('ltctMeterQty', this.aF.ltctMeterQty.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addSmartMeterCont', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
