<div class="container-fluid innerpage_top  page-content-wrapperr">
    <div class="row page-content-wrapper-innerr">
        <div class=" col-md-12 content-viewportt">
            <div class="page-content-wrapper-inner outerbox_shadow">

                <div class="row">
                    <div class="col-md-12 col-sm-12 boxflexEl">
                        <h1>Progress Till Date Dashboard
                            <span class="info_icntop ml-1">
                                <i data-toggle="tooltip" data-placement="top"
                                    title="Progress Till Date Dashboard Information"
                                    class="fa fa-info tabula-tooltip "></i>
                            </span>
                        </h1>
                        <!--<div class="boxflexEl">
                            <div class="d-none d-lg-block">
                                <div class="innerboxxx col-md-12 ">
                                    <div class="searchflex">
                                        <div class="search"
                                            *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Circle">
                                            <mat-select class="search-txt"
                                                (selectionChange)="selectChangeCircle(defaultCircle)"
                                                [(ngModel)]="defaultCircle">
                                                <mat-option [value]="0">All Circle</mat-option>
                                                <mat-option *ngFor="let dg of circleData" [value]="dg.id">
                                                    {{dg.name}}
                                                </mat-option>
                                            </mat-select>
                                            <a class="search-btn">
                                                <i class="fa fa-search"></i>
                                            </a>
                                        </div>
                                        <div class="search">
                                            <mat-select class="search-txt"
                                                (selectionChange)="selectChangeDivision(defaultDivision)"
                                                [(ngModel)]="defaultDivision">
                                                <mat-option [value]="0">All Division</mat-option>
                                                <mat-option *ngFor="let td of divisionData" [value]="td.id">
                                                    {{td.name}}
                                                </mat-option>
                                            </mat-select>
                                            <a class="search-btn">
                                                <i class="fa fa-search"></i>
                                            </a>
                                        </div>
                                        <div class="search"
                                            *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Authority">
                                            <mat-select class="search-txt"
                                                (selectionChange)="selectChangeAuth(defaultAuth)"
                                                [(ngModel)]="defaultAuth">
                                                <mat-option [value]="0">All Authority</mat-option>
                                                <mat-option *ngFor="let td of authData" [value]="td.id">
                                                    {{td.name}}
                                                </mat-option>
                                            </mat-select>
                                            <a class="search-btn">
                                                <i class="fa fa-search"></i>
                                            </a>
                                        </div>
                                        <div class="search">
                                            <input type="text" class="search-txt"
                                                placeholder="Search by Date (yyyy-mm-dd)" value="{{levelSearch}}"
                                                (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                                            <a class="search-btn">
                                                <i class="fa fa-search"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                    <!--<div class="innerboxx col-md-12 ddd">
                        <div class="container-fluidd d-block  d-lg-none">
                            <div class="">
                                <div class="outerbox_shadoww grid_searchsm">
                                    <div id="accordion" class="accordion">
                                        <div class="card mb-1">
                                            <div class="card-header collapsed" data-toggle="collapse"
                                                data-parent="#accordion" href="#collapseThree">
                                                <a class="card-title">
                                                    <span class="headngins">Search</span>
                                                </a>
                                            </div>
                                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                                <div class="card-bodyy">
                                                    <div class="innerboxxx col-md-12 ">
                                                        <div class="searchflex">
                                                            <div class="search"
                                                                *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Circle">
                                                                <mat-select class="search-txt"
                                                                    (selectionChange)="selectChangeCircle(defaultCircle)"
                                                                    [(ngModel)]="defaultCircle">
                                                                    <mat-option [value]="0">All Circle</mat-option>
                                                                    <mat-option *ngFor="let dg of circleData"
                                                                        [value]="dg.id">
                                                                        {{dg.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <a class="search-btn">
                                                                    <i class="fa fa-search"></i>
                                                                </a>
                                                            </div>
                                                            <div class="search">
                                                                <mat-select class="search-txt"
                                                                    (selectionChange)="selectChangeDivision(defaultDivision)"
                                                                    [(ngModel)]="defaultDivision">
                                                                    <mat-option [value]="0">All Division</mat-option>
                                                                    <mat-option *ngFor="let td of divisionData"
                                                                        [value]="td.id">
                                                                        {{td.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <a class="search-btn">
                                                                    <i class="fa fa-search"></i>
                                                                </a>
                                                            </div>
                                                            <div class="search"
                                                                *ngIf="getThrough.authority && getThrough.authority.Auth_All_Meter_Installed_Authority">
                                                                <mat-select class="search-txt"
                                                                    (selectionChange)="selectChangeAuth(defaultAuth)"
                                                                    [(ngModel)]="defaultAuth">
                                                                    <mat-option [value]="0">All Authority</mat-option>
                                                                    <mat-option *ngFor="let td of authData"
                                                                        [value]="td.id">
                                                                        {{td.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <a class="search-btn">
                                                                    <i class="fa fa-search"></i>
                                                                </a>
                                                            </div>
                                                            <div class="search">
                                                                <input type="text" class="search-txt"
                                                                    placeholder="Search by Date (yyyy-mm-dd)"
                                                                    value="{{levelSearch}}"
                                                                    (input)="onLevelSearchChange($event.target.value)"
                                                                    autocomplete="off">
                                                                <a class="search-btn">
                                                                    <i class="fa fa-search"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>-->

                    <span class="toolbar-space"></span>
                    <div col-3 col-xs-1>
                    </div>

                    <div class="container-fluid inner-page-content">
                        <div class="roww col-12">
                            <ng-container class="col-sm-12" *ngIf="loadingFirstTime">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                </fa-icon>
                            </ng-container>
                            <ng-container class="col-sm-12" *ngIf="loading">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                </fa-icon>
                            </ng-container>
                            <div *ngIf="length!=0" class="table-responsivee table-responsive_fixed">

                                <table id="ExampleMaterialTable" matSort mat-table [dataSource]="dataSource"
                                    class="table-bordered table-sm table-hover" *ngIf="dataSource.length > 0">

                                    <ng-container matColumnDef="srNo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">Sr. No.</th>
                                        <td mat-cell *matCellDef="let element; let j = index;"
                                           class="first">
                                            {{ element.srNo }}
                                            <!--{{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }}-->
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="conCircle">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">Circle
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder" class="first">
                                            <div *ngIf="element.conCircle=='Total Meters'">
                                                <b>{{element.conCircle}} </b>
                                            </div>
                                            <div *ngIf="element.conCircle!='Total Meters'" class="first">
                                                {{element.conCircle}}
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="conDivision">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder">
                                            Division </th>
                                        <td mat-cell *matCellDef="let element" class="bolder">
                                            {{element.conDivision}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conDC">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder" > DC
                                            Name </th>
                                        <td mat-cell *matCellDef="let element" class="bolder"> {{element.conDC}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conFeeder">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder">Feeder
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder"> {{element.conFeeder}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conGr">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder"> GR
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder"> {{element.conGr}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="conRd">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder"> RD
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder"> {{element.conRd}}
                                        </td>
                                    </ng-container>
                                    <!--<ng-container matColumnDef="totalProposed">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder">
                                            Proposed<br>Meters</th>
                                        <td mat-cell *matCellDef="let element" class="bolder">

                                            <b>{{element.totalProposed}}</b>


                                        </td>
                                    </ng-container>-->
                                    <ng-container matColumnDef="total3PhProposed">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Proposed<br>3-Ph(WC)</th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <b>{{element.total3PhProposed}} </b>

                                        </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="totalMeterCount">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Replaced<br>3-Ph(WC)</th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.conCircle=='Total Meters'">
                                                <b>{{element.totalMeterCount}}</b>
                                            </div>
                                            <div *ngIf="element.conCircle!='Total Meters'" class="second">
                                                {{element.totalMeterCount}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dailyTotalMeterCount">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Today's<br> 3-Ph(WC) </th>
                                        <td mat-cell *matCellDef="let element" class="second">
                                            <div *ngIf="element.conCircle=='Total Meters'">
                                                <b>{{element.dailyTotalMeterCount}} </b>
                                            </div>
                                            <div *ngIf="element.conCircle!='Total Meters'" class="second">
                                                {{element.dailyTotalMeterCount}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="total3PhBalance">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Balance<br>3-Ph(WC)</th>
                                        <td mat-cell *matCellDef="let element" class="second">

                                            <b>{{element.total3PhBalance}}</b>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="totalLTCTProposed">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Proposed<br>LTCT</th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <b>{{element.totalLTCTProposed}}</b>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="totalMeterCountLTCT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Replaced<br>LTCT </th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <div *ngIf="element.conCircle=='Total Meters'">
                                                <b>{{element.totalMeterCountLTCT}} </b>
                                            </div>
                                            <div *ngIf="element.conCircle!='Total Meters'" class="third">
                                                {{element.totalMeterCountLTCT}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dailyTotalMeterCountLTCT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Today's<br>LTCT </th>
                                        <td mat-cell *matCellDef="let element" class="third">

                                            <div *ngIf="element.conCircle=='Total Meters'">
                                                <b>{{element.dailyTotalMeterCountLTCT}} </b>
                                            </div>
                                            <div *ngIf="element.conCircle!='Total Meters'" class="third">
                                                {{element.dailyTotalMeterCountLTCT}}
                                            </div>

                                        </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="totalLTCTBalance">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader">
                                            Balance<br>LTCT</th>
                                        <td mat-cell *matCellDef="let element" class="third">
                                            <b>{{element.totalLTCTBalance}}</b>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="Server_date_Time">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="topHeader"> Server
                                            Date & Time
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder">
                                            {{element.Server_date_Time}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="authName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="bolder">
                                            Authority Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="bolder">
                                            {{element.authName}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay; sticky:true;"
                                        class="tableHeader">
                                    </tr>
                                    <tr class="tableColumns" mat-row
                                        *matRowDef="let element; columns: levelColumnsToDisplay;">
                                    </tr>
                                </table>

                            </div>
                            <div>
                                <div *ngIf="length==0">
                                    <div>
                                        <img class="center" src="assets/images/Nodata.png">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
                <!--<mat-card-footer>
                    <div class="row">
                        <div class="col-11">
                            <mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()"
                                [length]="length" (page)="changeMeterPage($event)" [showFirstLastButtons]="false" [disabled]="true" [hidePageSize]="true" >
                            </mat-paginator>
                        </div>
                        <div class="col-1 text-left1">
                            <div *ngIf="length!=0">
                                <button style="border:0;background: azure;" title="Export To Excel"
                                    (click)="exportTable()"><i class="fa fa-file-excel-o"
                                        style="font-size:25px;color:rgb(0, 132, 255)"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-footer>-->
            </div>
        </div>