import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dcuconfigrtc',
  templateUrl: './dcuconfigrtc.component.html',
  styleUrls: ['./dcuconfigrtc.component.scss']
})
export class DcuconfigrtcComponent implements OnInit {

  addForm: UntypedFormGroup;
  commandType = [
    {name: 'Synchronised DCU RTC'}
  ];
  
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      commType: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }
}
