<div class="container-fluid innerpage_top innerpage_top_graphic">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH_pagination">
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <h1>Home
                  <span class="info_icntop ml-1">
                    <i data-toggle="tooltip" data-placement="top" title="Information of all data"
                      class="fa fa-info tabula-tooltip "></i>
                  </span>
                </h1>
              </div>
              <div class="col-md-7 col-sm-12">
                <div class="flex_auth">
                  <div class="authsearch">
                    <div class="search">
                      <input class="search-txt searchplaceholder" type="text"
                        placeholder="Search by Username/Email/Mobile No" value="{{levelSearch}}"
                        (input)="onLevelSearchChange($event.target.value)" autocomplete="off" autocomplete="off">
                      <div class="search-btn nobg">
                        <i class="fa fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <span class="toolbar-space"></span>
                    <div>
                      <button type="button" mat-mini-fab (click)="open(addcountry)" class="editbordr_circlttop">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid inner-page-content">
              <!--<div class="row inner-page-content">-->
              <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
              <div class="row t-3 mb-3" *ngIf="allutilitys">
                <div class="col-md-2" *ngFor="let label of allutilitys; index as i;">
                  <div class="border_cstm">
                    <!--<mat-card-content mat-card-image (dblclick)="mapview.openMapViewer(label.countName)" >-->
                    <div>
                      <div class="spacer">
                        <div class="avatar-circlee">
                          <div class="circular">
                            <img src="{{label.img_address}}" />
                          </div>
                        </div>
                        <!--<mat-icon mat-button class="pointer cardAction" (click)="countryEditView(label, listmodify)">more_vert</mat-icon>-->
                        <mat-icon mat-button class="pointer cardAction d-c2 ditbordr" class="editbordr"
                          [matMenuTriggerFor]="dcuListMenu">menu_open
                        </mat-icon>

                        <mat-menu #dcuListMenu="matMenu" xPosition="before" class="authorityListMenu 
                          editbordrr gridinnerpagee mat-menu-panellall">
                          <mat-list role="list" class="editbordrr">
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="countryEditView(label, listmodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c5 authall">edit</mat-icon> Modify
                              </button>
                            </mat-list-item>

                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="removelevel(label)" class="btn-block text-left">
                                <mat-icon class="d-c5 authall">delete</mat-icon> Delete
                              </button>
                            </mat-list-item>
                          </mat-list>
                        </mat-menu>
                      </div>
                      <mat-card-footer (click)="goToCountryView(label.country_id)">
                        <div style="WIDTH: 100%;"
                          class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;"
                          mat-button>
                          <div class="fullbutton">
                            <span>{{label.countName}}</span>
                            <mat-icon class="icons">keyboard_arrow_right</mat-icon>
                          </div>
                        </div>
                      </mat-card-footer>
                    </div>

                  </div>
                </div>
                <ng-container *ngIf="!allutilitys" col-sm-3>
                  {{messageUtilitys}}
                </ng-container>



              </div>

            </div>
          </div>
        </div>
      </div>

      <ng-template #addcountry let-modal>
        <form [formGroup]="addCountryForm" (ngSubmit)="onSubmitAddCountry()">
          <div class="modal-header subpage">
            <h4 class="modal-title" id="modal-basic-title">
              <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
              Add counrty
            </h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
            <div class="row">
              <!-- Grid row -->
              <div class="col-md-6">
                <!-- Default input -->
                <div class="form-group input-group">
                  <span class="has-float-label ">
                    <input placeholder="Country Code" name="countryCode" formControlName="countryCode"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && aC.countryCode.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Country Code</label>
                    <div *ngIf="submitted && aC.countryCode.errors" class="invalid-feedback">
                      <div *ngIf="aC.countryCode.errors.required">
                        Country Code is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Default input -->
                <div class="form-group input-group">
                  <span class="has-float-label ">
                    <input placeholder="Country Name" name="countryName" formControlName="countryName"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && aC.countryName.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Country Name</label>
                    <div *ngIf="submitted && aC.countryName.errors" class="invalid-feedback">
                      <div *ngIf="aC.countryName.errors.required">
                        Country Name is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group input-group">
              <span class="has-float-label ">
                <input type="file" name="countryImage" (change)="onFileSelect($event)" class=""
                  [ngClass]="{ 'is-invalid': submitted && aC.countryImage.errors }" required>
                <div *ngIf="submitted && aC.countryImage.errors" class="invalid-feedback">
                  <div *ngIf="aC.countryImage.errors.required">
                    Country Image is required
                  </div>
                </div>
              </span>
            </div>
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="col-12 text-right">
              <button type="submit" class="dilog dilog1 w_autobtn float-sm-right float-md-none">
                <mat-icon matPrefix>add</mat-icon>Add
              </button>
              <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                <mat-icon matPrefix>cancel</mat-icon>Cancel
              </button>
            </div>
          </div>
        </form>
      </ng-template>

      <ng-template #listmodify let-modal>
        <form [formGroup]="editCountryForm" (ngSubmit)="onSubmitEditCountry()">
          <div class="modal-header subpage">
            <h4 class="modal-title" id="modal-basic-title">
              <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
              Edit Counrty
            </h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
            <div class="form-group">
              <!-- Grid row -->
              <div class="row">
                <!-- Default input -->
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                      <input placeholder="Country Code" name="countryCode" formControlName="countryCode"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && eC.countryCode.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label>Country Code</label>
                      <div *ngIf="submitted && eC.countryCode.errors" class="invalid-feedback">
                        <div *ngIf="eC.countryCode.errors.required">
                          Country Code is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                      <input placeholder="Country Name" name="countryName" formControlName="countryName"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && eC.countryName.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label>Country Name</label>
                      <div *ngIf="submitted && eC.countryName.errors" class="invalid-feedback">
                        <div *ngIf="eC.countryName.errors.required">
                          Country Name is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input type="file" name="countryImage" (change)="onFileSelectEdit($event)"
                    [ngClass]="{ 'is-invalid': submitted && eC.countryImage.errors }" required>
                  <div *ngIf="submitted && eC.countryImage.errors" class="invalid-feedback">
                    <div *ngIf="eC.countryImage.errors.required">
                      Country Image is required
                    </div>
                  </div>
                </span>
              </div>


              <!-- Grid row -->
              <div class="clearfix"></div>
              <div class="col-12 text-right">
                <button type="submit" class="dilog dilog1">
                  <mat-icon matPrefix>update</mat-icon>Update
                </button>
                <button type="button" (click)="modal.dismiss('Cross click')"
                  class="dilog dilog3 w_autobtn float-sm-right float-md-none">
                  <mat-icon matPrefix>cancel</mat-icon>Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-template>