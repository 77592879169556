<div class="authentication-theme auth-style_1 authLoginAccess">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-8 col-10 mx-auto">
    <mat-card class="noPadding" id="recharge_review">
        <div class="rechargeTitle">
            Recharge Failed!
        </div>
        <mat-card-content>
          <div >
            <table width ='100%' >
              <tr>
                <td colspan="2"><div>
                  <B>Recharge Failed!</B>
                </div></td>
              </tr>
              <tr>
                <th>Name:</th>
                <td>{{successReacharge.firstName}}</td>
              </tr>
              <tr>
                <th>Email:</th>
                <td>{{successReacharge.emailId}}</td>
              </tr>
              <tr>
                <th>Mobile:</th>
                <td>{{successReacharge.mobileNo}}</td>
              </tr>
              <tr>
                <th>Product Info:</th>
                <td>{{successReacharge.productInfo}}</td>
              </tr>
              <tr>
                <th>Recharge Amount:</th>
                <td>{{successReacharge.amount}}</td>
              </tr><tr>
                <th>Transaction Id:</th>
                <td>{{successReacharge.tranNo}}</td>
              </tr>
              
              <tr>
                <th>Recharge Mode:</th>
                <td>{{successReacharge.mode}}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{{successReacharge.status}}</td>
              </tr>
              
            </table>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="noPadding">
        <mat-card-content>
          <div class="col-12 text-right">
            <button mat-button class="cPbg" ngxPrint printSectionId="recharge_review" >print</button>
          </div>
        </mat-card-content>
      </mat-card>
      
    </div>
  </div>
  <!--Mpin Login-->

  <!--Mpin Login-->
  <!-- <div class="auth_footer">
    <p class="text-muted text-center">© Avonsm</p>
  </div> -->
</div>