<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass h-180">
        <div class="col-12">
          <h4>Activity On DCU</h4>
          <!--<p class="text-white">Welcome, {{currentUser.data.userData.name | titlecase}}</p>-->
        </div>
      </div>
      <div class="row inner-page-content of-auto">
        <ng-container class="col-sm-12" *ngIf="loading">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        <ng-container>
          <div class="col-12 plr_c25px xs-no-p">
            <mat-card class="-mt-20">
              <mat-card-header class="h-16 xs-c-h">
               
                <span class="toolbar-space"></span>
                <div col-3 col-xs-6>
                 
                  <!--<button type="button" mat-mini-fab class="cPbg npMi" (click)="openaddlevel()">
                    <mat-icon class="d-c11">add</mat-icon>
                  </button>-->
                  <button mat-flat-button  (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                    <mat-icon class="d-c11">reply</mat-icon>
                </button>
                </div>
              </mat-card-header>
              <mat-card-content>
                <ng-container class="allutilitysloader col-12" *ngIf="loading">
                  <fa name="spinner" animation="spin" size="4x"></fa>
                </ng-container>
                <ng-container>
                  <div class="grid col-sm-12">
                    <div class="grid-body">
                     
                      <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()" >

                        <div class="col-md-6 form-group input-rounded">
                          <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                            <mat-checkbox name="RESD" formControlName="RESD">Restart DCU</mat-checkbox>
                          
                        </div>
                        
                        <div class="col-md-6 form-group input-rounded">
                          <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                            <mat-checkbox name="SHDD" formControlName="SHDD">Shutdown DCU</mat-checkbox>
                          
                        </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                            Broadcast Demand
                            <mat-radio-group name="supply" formControlName="supply">
                                <div>
                                  <mat-radio-button value="BGSD">Grid Supply</mat-radio-button>
                                </div>
                                <div>
                                  <mat-radio-button value="BBSD">Backup Supply</mat-radio-button>
                                </div> 
                              </mat-radio-group>
                          </div>
                          <button type="submit" class="btn btn-primary btn-block">
                              Action
                          </button>
                      </form>
    
                  </div>
                </div>
              </ng-container>
             </mat-card-content>
              
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!--
<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass white-bg h-75">
        <div class="col-12">
          <div class="deshHeadWcTitle">
            <mat-icon>chrome_reader_mode</mat-icon>Activity On DCU
          </div>
        </div>
      </div>
      <div class="row commonClass">
        <mat-toolbar class="subheaderArea h-40 fs-14">
          <span class="toolbar-space"></span>
          <button mat-icon-button color="primary">
            <mat-icon class="d-c">search</mat-icon>
          </button>
          <button mat-flat-button (click)="whichSubDomain()" class="cPbg">
            <mat-icon>chrome_reader_mode</mat-icon>
            Change Sub-Divsion
          </button>
        </mat-toolbar>
      </div>
      <div class="row inner-page-content">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
        <ng-container>
          <div class="grid col-sm-12">
            <div class="grid-body">
              <div class="row">
                <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                
                    
                    <form [formGroup]="actionForm" (ngSubmit)="onSubmitAddForm()" >

                      <div class="col-md-6 form-group input-rounded">
                        <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                          <mat-checkbox name="RESD" formControlName="RESD">Restart DCU</mat-checkbox>
                        
                      </div>
                      
                      <div class="col-md-6 form-group input-rounded">
                        <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                          <mat-checkbox name="SHDD" formControlName="SHDD">Shutdown DCU</mat-checkbox>
                        
                      </div>
                        <div class="col-md-6 form-group input-rounded">
                          <mat-icon matPrefix class="d-c3">chrome_reader_mode</mat-icon>
                          Broadcast Demand
                          <mat-radio-group name="supply" formControlName="supply">
                              <div>
                                <mat-radio-button value="BGSD">Grid Supply</mat-radio-button>
                              </div>
                              <div>
                                <mat-radio-button value="BBSD">Backup Supply</mat-radio-button>
                              </div> 
                            </mat-radio-group>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">
                            Action
                        </button>
                    </form>
                 
              
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container col-sm-3>
      </ng-container>
    </div>
  </div>
</div>
</div>-->
<!-- content viewport ends -->