<div class="container-fluidd">
	<div class="row">
		<div class="col-md-12">
			<!-- Map -->
			<article #fs fullscreen-able [class.is-active]="isActive">
				<div class="row">
					<div class="col-md-12">
						<div [style]="displaybtnright">
							<button [style]="displaybtnn" (click)="closeFullscreen()" data-toggle="tooltip"
								data-placement="right" title="Active">
								<i class="fa fa-database"></i>
							</button>
							<button [style]="displaybtnn" (click)="closeFullscreen()" data-toggle="tooltip"
								data-placement="right" title="In Active">
								<i class="fa fa-home"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="leaflet-top leaflet-left">
					<div class="leaflet-control-zoom leaflet-control">
						<!--<a class="leaflet-control-zoom-in" href="#" title="Zoom in" role="button" aria-label="Zoom in">+</a>
					<a class="leaflet-control-zoom-out" href="#" title="Zoom out" role="button" aria-label="Zoom out">−</a>
					-->
					</div>

					<div class="leaflet-control-fullscreen leaflet-control" style="padding: 0px;">
						<div *ngIf="display">
							<button [style]="styleButton1" (click)="openFullscreen()" data-toggle="tooltip"
								data-placement="right" title="Fullscreen">
								<span class="material-icons">
									fullscreen
								</span>
							</button>
						</div>
						<div *ngIf="!display">
							<button [style]="styleButton2" (click)="closeFullscreen()" data-toggle="tooltip"
								data-placement="right" title="Fullscreen Exit">
								<span class="material-icons">
									fullscreen_exit
								</span>
							</button>
							<button [style]="styleButton2" (click)="closeFullscreen()" data-toggle="tooltip"
								data-placement="right" title="Fullscreen Exit">
								<span class="material-icons">
									fullscreen_exit
								</span>
							</button>
						</div>
					</div>
				</div>
				<div leaflet 
					[style]="screenHeight" 
					[leafletOptions]="options"
					[leafletMarkerCluster]="markerClusterData" 
					[leafletMarkerClusterOptions]="markerClusterOptions"
					(leafletMarkerClusterReady)="markerClusterReady($event)">
				</div>


			</article>
		</div>
	</div>
</div>