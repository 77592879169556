import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription, Observable } from 'rxjs';
import { Access } from 'src/app/_models/access';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from 'src/app/_services';
import { ModalService } from 'src/app/_services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { take, startWith, map } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-maintbill',
  templateUrl: './maintbill.component.html',
  styleUrls: ['./maintbill.component.scss']
})
export class MaintbillComponent implements OnInit {
  faSpinner = faSpinner;
  public today = new Date();
  selectedSubDivision: any;
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getGothrough: Access;
  addForm: UntypedFormGroup;
  dataMeterSource: any = new MatTableDataSource([]);
  isLoading = false;
  isFirstLoading = false;
  submitReport = false;
  limit: number = 20;
  reportlimit: number = 20;
  skip: number = 0;
  reportTotalRecord: number = 0;
  imageAddress='';
  responseData: any;
  message: string = '';

  countryId: any = 0;
  stateId: any = 0;
  utilityId: any = 0;
  circleId: any = 0;
  divisionId: any = 0;
  subdivisionId: any = 0;
  dcu: any = 0;
  siteUrl = this.genReqs.apiPath();
  pdfUrl = '';
  csvUrl = '';

  meterColumnsToDisplay = [];
  Consumptionsview = '';
  pickedRange: Date[] = null;
  startDate = '';
  endDate = '';
  
  reporturl = '';
  enableSocietyChange: boolean = true;
  monthSelection: any = '';
  yearSelection: any = '';
  htmlContet: any = [];
  myTemplate: any = "";
  flatNo = '';
  towerNo = '';

  towerId='';
  flatIdData = [
      {
          'consId': '',
          'flatNo': '',
          'meterId': '',
          'consumerName': ''
      }
  ];

  towerIdData = [
      {
          'towerId': '',
          'towerNo': ''
      }
  ];
  pastMonthDate: Date;
  ///
  cUIDMuID='';
  toshowKeys: any = [];
  toshowIndexs: any = [];
  canSearch: boolean = true;
  flatControl = new UntypedFormControl('');
  towerControl = new UntypedFormControl('');
  startLimit = new UntypedFormControl('');
  endLimit = new UntypedFormControl('');
  filteredFlatInfo: Observable<any[]>;
  filteredTowerInfo: Observable<any[]>;
  //
  constructor(
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private genReqs: generalRequestsService,
      private modalService: ModalService,
      private ngModalService: NgbModal,
      private sanitizer:DomSanitizer
  ){
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });

      this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
      if (this.currentUser.data.userData.authLevel == '7') {
          this.enableSocietyChange = false;
      }
  }
  safeHTML(unsafe: string) {
      return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }
  ngOnInit() {

      this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
          startWith(''),
          map(value => this.findFlatInfo(value))
      );

      /*this.filteredTowerInfo = this.towerControl.valueChanges.pipe(
          startWith(''),
          map(value => this.findTowerInfo(value))
      );*/

      this.countryId = this.currentUser.data.userData.Country || 0;
      this.stateId = this.currentUser.data.userData.State || 0;
      this.utilityId = this.currentUser.data.userData.utility || 0;
      this.circleId = this.currentUser.data.userData.circle || 0;
      this.divisionId = this.currentUser.data.userData.division || 0;
      this.subdivisionId = this.currentUser.data.userData.subdivision || 0;

      this.dcu = this.currentUser.data.userData.dcu || 0;

      this.Consumptionsview = '2';
      this.calculatePastMonth();
      this.getFlatId();
      //this.getTowerId();
      /*if (this.enableSocietyChange) {
          this.whichSubDomain();
      }*/
      
  }

  whichSubDomain() {
      this.modalService.popautocomplete().pipe(
          take(1)).subscribe(result => {
              this.selectedSubDivision = result;
              //console.log('selected val',this.selectedSubDivision);
              this.getFlatId();
              this.getTowerId();
          }
          );
  }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
  }

  getFlatId() {
      let body = new URLSearchParams();
      body.set('subDivisionId', this.currentUser.data.userData.subdivision);
     

      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      body.set('subDivName', this.currentUser.data.userData.subName);
      this.genReqs.postReq('/getFlat', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
              this.flatIdData = this.responseData.data;
              this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
                  startWith(''),
                  map(value => this.findFlatInfo(value))
              );
              this.getTowerId();
              //console.log("hello1",this.flatIdData[0].consId);
              //this.flatNo=this.flatIdData[0].consId;
              this.loadReport(true);
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  getTowerId() {
      let body = new URLSearchParams();
      body.set('subDivisionId', this.currentUser.data.userData.subdivision);
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      body.set('subDivName', this.currentUser.data.userData.subName);
      this.genReqs.postReq('/getTowerDataGet', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
              this.towerIdData = this.responseData.data;
              /*this.filteredTowerInfo = this.towerControl.valueChanges.pipe(
                  startWith(''),
                  map(value => this.findTowerInfo(value))
              );*/
              //console.log(this.towerIdData,this.filteredTowerInfo);
              //console.log("hello1",this.flatIdData[0].consId);
              //this.flatNo=this.flatIdData[0].consId;
              this.loadReport(true);
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }

  /**to get the meter list on click on Dcu */

  onSelectTower(e, towerId) {
      this.canSearch = true;
      this.towerId = towerId;
      alert(this.towerId);
  }

  calculatePastMonth() {
    const currentDate = new Date();
   
    this.pastMonthDate = currentDate;
    this.startDate=currentDate.getFullYear()+"-"+(currentDate.getMonth())+"-01";
    this.endDate=currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-01";
  } 

  loadReport(addNew: boolean) {
    
      if(addNew){
          this.isLoading = false;
      }else{
          this.isLoading = true;
      }
      this.isFirstLoading = addNew;
      
      this.htmlContet = [];
      
      /*if (this.startLimit.value=='') {
          if (this.startLimit.value==''  && this.startDate!=''){
              alert("Please Select Start Limit!");
              this.isLoading = false;
              return;
          }
      }
      if (this.endLimit.value=='') {
          if (this.endLimit.value==''  && this.startDate!=''){
              alert("Please Select End Limit!");
              this.isLoading = false;
              return;
          }
      }else{
          if (this.endLimit.value>35  && this.startDate!=''){
              alert("End Limit Must be less then or equal to 35!");
              this.isLoading = false;
              return;
          }
      }*/

      
      if (this.towerControl.value!='') {
          if (this.startDate==''){
              alert("Please Select Billing Month For Comsumer Billing!");
              this.isLoading = false;
              return;
          }
      }
      if(this.submitReport){
          this.myTemplate = [];
          this.skip=0;
      }
    
      //this.isLoading = false;
      let body = new URLSearchParams();
      this.cUIDMuID=this.currentUser.data.userData.uid+"-"+this.currentUser.data.userData.meterUniqueId+"-"+this.currentUser.data.userData.meterType;
      body.set('subDivId', this.currentUser.data.userData.subdivision);
      body.set('cUIDMuID', this.cUIDMuID.toString());
      body.set('sdate', this.startDate.toString());
      body.set('edate', this.endDate.toString());
      body.set('view','1');
      body.set('reporttype', this.Consumptionsview.toString());
      body.set('authToken', this.currentUser.authToken);
      if( this.startLimit.value.toString()==''){
        body.set('offSet', '0');
      }else{
        body.set('offSet', this.startLimit.value.toString());
      }
      if( this.startLimit.value.toString()==''){
        body.set('limit', '20');
      }else{
        body.set('limit',this.endLimit.value.toString());
      }
     

      body.set('towerId', this.towerControl.value);
      this.genReqs.postReq('/reports/consBillingMaintPagination', body).subscribe((result)=>{
     // this.genReqs.postReq('/reports/consumerBillsReport', body).subscribe((result) => {
          this.responseData = result;
          this.isLoading = false;
          this.isFirstLoading = false;
          // this.htmlContet = this.responseData.data;
          if (this.responseData.success) {
             
              this.reportTotalRecord = this.responseData.datalimit.totalResult;
              this.myTemplate = this.responseData.data;
              this.getPageSizeOptions();
              let toshow = this.responseData.datalimit.toshow;
              this.htmlContet = this.responseData.data;
              this.toshowKeys = Object.keys(toshow);
              this.toshowIndexs = Object.values(toshow);
              if(this.myTemplate.length < 1){
                  this.imageAddress="assets/images/Nodata.png";
              }
          }else{
              this.imageAddress="assets/images/Nodata.png";
          }
      }, (err) => {
          this.isLoading = false;
          this.isFirstLoading = false;
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.imageAddress="assets/images/Nodata.png";
          this.message = 'Unable to process yours request!';
      });
  }

  /*changeMeterPage(event) {
      this.submitReport=false;
      if (event.pageSize !== this.reportlimit) {
          this.reportlimit = event.pageSize;
          this.skip = event.pageSize * event.pageIndex;
          this.loadReport();
      } else {
          this.skip = event.pageSize * event.pageIndex;
          this.loadReport();
      }
  }*/

  makemeterreport(row) {
      //console.log(row);
  }

  getDateFromRange(pickedRange: Date[]) {
      let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
      let sday = pickedRange[0].getDate();
      let syear = pickedRange[0].getFullYear();
      this.startDate = syear + '-' + smonth + '-' + sday;
      let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
      let eday = pickedRange[1].getDate();
      let eyear = pickedRange[1].getFullYear();
      this.endDate = eyear + '-' + emonth + '-' + eday;
  }

  clear() {
      //alert("hello");
      this.startDate ='';
      this.endDate ='';
  }

  getMonthFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          this.monthSelection = pickedDate;
          let smonth = pickedDate.getMonth() + 1; //always move 1 value up
          let sday = '01';//pickedDate.getDate();
          let syear = pickedDate.getFullYear();
          this.startDate = syear + '-' + smonth + '-' + sday;
         
      }
  }

  /*getMonthFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          this.monthSelection = pickedDate;
          let smonth = pickedDate.getMonth() + 1; //always move 1 value up
          let sday = '01';//pickedDate.getDate();
          let syear = pickedDate.getFullYear();
          this.startDate = syear + '-' + smonth + '-' + sday;
          let emonth = pickedDate.getMonth() + 1; //always move 1 value up
          let eday = '01';//pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.endDate = eyear + '-' + emonth + '-' + eday;
      }
  }*/
  getMonthEndFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          let emonth = pickedDate.getMonth() + 1; //always move 1 value up
          let eday = '01';//pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.endDate = eyear + '-' + emonth + '-' + eday;
      }
  }

  getYearFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          let smonth = '01'; //always move 1 value up
          let sday = '01';//pickedDate.getDate();
          let syear = pickedDate.getFullYear();
          this.startDate = syear + '-' + smonth + '-' + sday;
          let emonth = '12';
          let eday = '01';//pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.endDate = eyear + '-' + emonth + '-' + eday;
          this.yearSelection = pickedDate;
      }
  }

  getYearEndFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          let emonth = '01';
          let eday = '01';//pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.endDate = eyear + '-' + emonth + '-' + eday;
      }
  }


  filterData(val: string) {
      this.submitReport=true;
      if(val=='1'){
          this.loadReport(false);
      }else{
          this.loadReport(true);
      }
  }

  myLoadEvent() {
      this.isFirstLoading = true;
      this.isLoading = true;
  }

  pdfNavigate() {
      window.open(this.pdfUrl, "_blank");
  }


 
  getPageSizeOptions() {
      if (this.reportTotalRecord > 0 && this.reportTotalRecord <= 10) {
        return [this.reportTotalRecord];
      } else if (this.reportTotalRecord > 10 && this.reportTotalRecord <= 20) {
        return [10, this.reportTotalRecord];
      } else if (this.reportTotalRecord > 20 && this.reportTotalRecord <= 40) {
        return [10, 20, this.reportTotalRecord];
      } else if (this.reportTotalRecord > 40 && this.reportTotalRecord <= 80) {
        return [10, 20, 40, this.reportTotalRecord];
      } else if (this.reportTotalRecord > 80 && this.reportTotalRecord <= 100) {
        return [10, 20, 40, 80, this.reportTotalRecord];
      } else{
          return [10, 20, 40, 80, 100];
      }
    }
 

  downloadAsPDF2() {
      if (this.enableSocietyChange) {
          this.subdivisionId= this.subdivisionId;
      } else {
          this.subdivisionId=  this.currentUser.data.userData.subdivision;
         // body.set('subDivId', this.currentUser.data.userData.subdivision);
      }
      this.cUIDMuID=this.currentUser.data.userData.uid+"-"+this.currentUser.data.userData.meterUniqueId+"-"+this.currentUser.data.userData.meterType;
      let startLimit=0
      let endLimit=20
      if(this.startLimit.value.toString()!=''){
        startLimit=this.startLimit.value.toString();
      }
      if(this.endLimit.value.toString()!=''){
        endLimit=this.endLimit.value.toString();
      }
      let pdfUrl = this.siteUrl
      +'/reports/consBillingMaintPagination?sdate='+this.startDate
      +'&subDivId='+this.subdivisionId
      +'&cUIDMuID='+this.cUIDMuID
      +'&edate='+this.endDate
      +'&reporttype='+this.Consumptionsview
      +'&offSet='+startLimit
      +'&limit='+endLimit
      +'&view=1'
      +'&pdf=1'
      +'&towerId='+this.towerControl.value.toString()
      +'&authToken='+this.currentUser.authToken;
      window.open(pdfUrl);
}

  /*downloadAsPDF2() {
      if (this.enableSocietyChange) {
          this.subdivisionId=this.subdivisionId;
      } else {
          this.subdivisionId=this.currentUser.data.userData.subdivision;
      }
      let pdfUrl = this.siteUrl+'/reports/consumerBillsReport?subdivisionId='+this.subdivisionId
      +'&countryId='+this.currentUser.data.userData.Country
      +'&stateId='+this.currentUser.data.userData.State
      +'&utilityId='+this.currentUser.data.userData.utility
      +'&circleId='+this.currentUser.data.userData.circle
      +'&divisionId='+this.currentUser.data.userData.division
      +'&flatNo='+this.flatNo.toString()
      +'&sdate='+this.startDate.toString()
      +'&edate='+this.endDate.toString()
      +'&reporttype='+this.Consumptionsview.toString()
      +'&authToken='+this.currentUser.authToken
      +'&offSet='+this.skip.toString()
      +'&limit='+this.reportlimit.toString()
      +'&pdf=1';
      window.open(pdfUrl);
  }*/

  csvNavigate(){
      this.cUIDMuID=this.currentUser.data.userData.uid+"-"+this.currentUser.data.userData.meterUniqueId+"-"+this.currentUser.data.userData.meterType;
      let startLimit=0
      let endLimit=20
      if(this.startLimit.value.toString()!=''){
        startLimit=this.startLimit.value.toString();
      }
      if(this.endLimit.value.toString()!=''){
        endLimit=this.endLimit.value.toString();
      }
      let pdfUrl = this.siteUrl
      +'/reports/consBillingMaintPagination?sdate='+this.startDate
      +'&subDivId='+this.subdivisionId
      +'&cUIDMuID='+this.cUIDMuID
      +'&edate='+this.endDate
      +'&reporttype='+this.Consumptionsview
      +'&offSet='+startLimit
      +'&limit='+endLimit
      +'&view=1'
      +'&pdf=2'    
      +'&towerId='+this.towerControl.value.toString()
      +'&authToken='+this.currentUser.authToken;
      window.open(pdfUrl);
    }

    
  
/*  csvNavigate() {
      if (this.enableSocietyChange) {
          this.subdivisionId=this.subdivisionId;
      } else {
          this.subdivisionId=this.currentUser.data.userData.subdivision;
      }
          let pdfUrl = this.siteUrl+'/reports/consumerBillsReport?subdivisionId='+this.subdivisionId
          +'&countryId='+this.currentUser.data.userData.Country
          +'&stateId='+this.currentUser.data.userData.State
          +'&utilityId='+this.currentUser.data.userData.utility
          +'&circleId='+this.currentUser.data.userData.circle
          +'&divisionId='+this.currentUser.data.userData.division
          +'&flatNo='+this.flatNo.toString()
          +'&sdate='+this.startDate.toString()
          +'&edate='+this.endDate.toString()
          +'&reporttype='+this.Consumptionsview.toString()
          +'&authToken='+this.currentUser.authToken
          +'&offSet='+this.skip.toString()
          +'&limit='+this.reportlimit.toString()
          +'&pdf=2';
          window.open(pdfUrl);
  }
*/
  displayFlatInfo(flat: any): string {
      return flat ? flat.flatNo+' - '+flat.meterId : undefined;
  }

  displayTowerInfo(tower: any): string {
      return tower ? tower.towerNo : undefined;
  }

  findFlatInfo(val: string) {
      //console.log("hello1",this.flatIdData);
      return this.flatIdData.filter(state =>
          state.flatNo.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
  }

  findTowerInfo(val: string) {
      //console.log("hello1",this.towerIdData);
      return this.towerIdData.filter(state =>state.towerNo.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
  }

  onSelectFlat(e, meterId) {
      this.canSearch = true;
      this.flatNo = meterId;
  }

  

}
