<div class="page-content-wrapper" style="margin-top:0;" cdkDropList>
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass">
        <div class="col-12">
          <h4>Dashboard</h4>
          <p class="text-white">Report Autho Info's</p>
        </div>
      </div>
      <div class="row commonClass">
        <mat-toolbar class="subheaderArea h-40 fs-14">
          <span class="toolbar-space"></span>
          <button mat-icon-button color="primary">
              <mat-icon class="d-c">search</mat-icon>
          </button>
        </mat-toolbar>
    </div>
        <div class="row inner-page-content_bottom_page">
          <ng-container class="allutilitysloader col-12" *ngIf="loading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <ng-container *ngIf="dataSource && !loading">
              <div class="col-12 plr_c25px">
                  <mat-card class="allTableWithCard">
                      <mat-card-content>
                          <table mat-table [dataSource]="dataSource">
                              <!--aID Column --->
                              <ng-container matColumnDef="aID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Auth ID
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.aID}}
                                </td>
                              </ng-container>
                              <!--cID Column --->
                              <ng-container matColumnDef="cID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Country
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.cID}}
                                </td>
                              </ng-container>
                              <!--sID Column --->
                              <ng-container matColumnDef="sID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  State
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.sID}}
                                </td>
                              </ng-container>
                              <!--uID Column --->
                              <ng-container matColumnDef="uID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Utility
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.uID}}
                                </td>
                              </ng-container>
                              <!--ciID Column --->
                              <ng-container matColumnDef="ciID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Circle
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.ciID}}
                                </td>
                              </ng-container>
                              <!--dID Column --->
                              <ng-container matColumnDef="dID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Division
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.dID}}
                                </td>
                              </ng-container>
                              <!--sdID Column --->
                              <ng-container matColumnDef="sdID">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Sub-Division
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.sdID}}
                                </td>
                              </ng-container>
                              <!--authFullName Column --->
                              <ng-container matColumnDef="authFullName">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Full Name
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authFullName}}
                                </td>
                              </ng-container>
                              <!--authUserName Column --->
                              <ng-container matColumnDef="authUserName">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Username
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authUserName}}
                                </td>
                              </ng-container>
                              <!--authEmail Column --->
                              <ng-container matColumnDef="authEmail">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Email
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authEmail}}
                                </td>
                              </ng-container>
                              <!--authMobileId Column --->
                              <ng-container matColumnDef="authMobileId">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Mobile
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authMobileId}}
                                </td>
                              </ng-container>
                              <!--authHomeAdd1 Column --->
                              <ng-container matColumnDef="authHomeAdd1">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Address
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authHomeAdd1}}
                                </td>
                              </ng-container>
                              <!--authHomeAdd2 Column --->
                              <ng-container matColumnDef="authHomeAdd2">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Address 2
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authHomeAdd2}}
                                </td>
                              </ng-container>
                              <!--authOffAdd1 Column --->
                              <ng-container matColumnDef="authOffAdd1">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Office Address
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authOffAdd1}}
                                </td>
                              </ng-container>
                              <!--authOffAdd2 Column --->
                              <ng-container matColumnDef="authOffAdd2">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Office Address2
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authOffAdd2}}
                                </td>
                              </ng-container>
                              <!--authActiveStatus Column --->
                              <ng-container matColumnDef="authActiveStatus">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  Status
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authActiveStatus}}
                                </td>
                              </ng-container>
                              <!--authDOB Column --->
                              <ng-container matColumnDef="authDOB">
                                <th mat-header-cell *matHeaderCellDef class="bolder">
                                  DOB
                                </th>
                                <td mat-cell *matCellDef="let element" class="bolder">
                                  {{element.authDOB}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="group1">
                                <td mat-header-cell *matHeaderCellDef [colSpan]="displayedColumns.length">
                                  <mat-card-header style="background-color: #fff;height: 45px;">
                                    <div class="">
                                      <mat-form-field>
                                        <input matInput [matDatepicker]="picker" placeholder="DOB" (dateInput)="onDateChange($event.target)" (dateChange)="onDateChange($event.target)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                    <div class="">
                                      <mat-form-field>
                                        <input  matInput placeholder="Search"  value="{{search}}" (input)="onSearchChange($event.target.value)">
                                        </mat-form-field>
                                    </div>
                                    <span class="toolbar-space"></span>
                                      <button mat-raised-button title="PDF" (click)="downloadPdf()">
                                        <fa mat-icon name="file-pdf-o"></fa>
                                      </button>
                                      <button mat-raised-button title="CSV" (click)="downloadCsv()">
                                        <fa mat-icon name="file-excel-o"></fa>
                                      </button>
                                  </mat-card-header>
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tableHeader"></tr>
                              <tr mat-header-row *matHeaderRowDef="['group1'];sticky: true" class="tableHeaderFilter"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                  class="tableColumns example-element-row"></tr>
                          </table>
                      </mat-card-content>
                  </mat-card>
              </div>
          </ng-container>
          <ng-container *ngIf="!dataSource && !loading" col-sm-3>
              {{messageAuthoritys}}
          </ng-container>
      </div>
      <div class="row commonClass secondFooterArea">
        <mat-toolbar class="subheaderArea fs-14">
          <span class="toolbar-space"></span>
          <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="changePage($event)" showFirstLastButtons>
      </mat-paginator>
    </mat-toolbar>
    </div>
    </div>
  </div>
</div>