import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription, Observable } from 'rxjs';
import { Access } from 'src/app/_models/access';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, UserService, generalRequestsService } from 'src/app/_services';
import { ModalService } from 'src/app/_services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { take, startWith, map } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-oldnewmeterinfomob',
    templateUrl: './oldnewmeterinfomob.component.html',
    styleUrls: ['./oldnewmeterinfomob.component.scss']
})
export class OldnewmeterinfomobComponent implements OnInit {


    faSpinner = faSpinner;
    public today = new Date();
    selectedSubDivision: any;
    currentUser: User;
    currentUserSubscription: Subscription;
    subscriptions: Subscription;
    users: User[] = [];
    getThrough: Access;

    addForm: UntypedFormGroup;
    dataMeterSource: any = new MatTableDataSource([]);
    isLoading = false;
    isFirstLoading = false;
    submitReport = false;
    limit: number = 20;
    reportlimit: number = 20;
    skip: number = 0;
    reportTotalRecord: number = 0;

    responseData: any;
    message: string = '';

    countryId: any = 0;
    stateId: any = 0;
    utilityId: any = 0;
    circleId: any = 0;
    divisionId: any = 0;
    subdivisionId: any = 0;

    authToken: any = '';
    subDivName: any = 0;

    dcu: any = 0;
    siteUrl = this.genReqs.apiPath();
    pdfUrl = '';
    csvUrl = '';

    meterColumnsToDisplay = [

    ];
    Consumptionsview = '';
    pickedRange: Date[] = null;
    startDate = '';
    endDate = '';
    reporturl = '';
    enableSocietyChange: boolean = true;
    monthSelection: any = '';
    yearSelection: any = '';
    htmlContet: any = [];
    myTemplate: any = "";
    myTemplate1: any = "";

    authUname = '';
    flatIdData = [
        {
            'circleUid': 0,
            'circleName': ''
        }
    ];
    imageAddress = "";
    //noDataFound = "<div class='filter-report-options card_view_box text-center' style='background-color: #fff;color: #000;padding-bottom: 10px;margin-top: 0px;'><div class='col-12 plr_c25px xs-no-p'> <div><img class='center' src='assets/images/Nodata.png'></div></div></div>";

    ///
    toshowKeys: any = [];
    toshowIndexs: any = [];
    canSearch: boolean = true;
    flatControl = new UntypedFormControl('');
    filteredFlatInfo: Observable<any[]>;
    //
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private genReqs: generalRequestsService,
        private modalService: ModalService,
        private ngModalService: NgbModal,

        protected sanitizer: DomSanitizer
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });



        this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
        if ('7' == '7') {
            this.enableSocietyChange = false;
        }
    }

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.subdivisionId = params['subDivisionId'];
            this.countryId = params['countryId'];
            this.stateId = params['stateId'];
            this.utilityId = params['utilityId'];
            this.circleId = params['circleId'];
            this.divisionId = params['divisionId'];
            this.subDivName = params['subDivName'];
            this.authToken = params['authToken'];
        });

        this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
            startWith(''),
            map(value => this.findFlatInfo(value))
        );



        this.Consumptionsview = '1';

        this.getFlatId();
        if (this.enableSocietyChange) {
            this.whichSubDomain();
        }

    }

    whichSubDomain() {
        this.modalService.popautocomplete().pipe(
            take(1)).subscribe(result => {
                this.selectedSubDivision = result;
                //console.log('selected val',this.selectedSubDivision);
                this.getFlatId();
            }
            );
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
    }

    safeHTML(unsafe: string) {
        return this.sanitizer.bypassSecurityTrustHtml(unsafe);
    }

    getFlatId() {
        let body = new URLSearchParams();

        body.set('subDivisionId', this.subdivisionId);



        body.set('filter', '');
        body.set('authToken', this.authToken);
        body.set('subDivName', '');
        this.genReqs.postReq('/getCircleId', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.flatIdData = this.responseData.data;
                this.filteredFlatInfo = this.flatControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this.findFlatInfo(value))
                );
                this.loadReport(true);
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    /**to get the meter list on click on Dcu */
    loadReport(addNew: boolean) {
        if (addNew) {
            this.isLoading = false;
        } else {
            this.isLoading = true;
        }
        this.htmlContet = [];
        if (this.submitReport) {
            this.myTemplate = [];
            this.skip = 0;
        }
        this.isFirstLoading = addNew;
        let body = new URLSearchParams();

        body.set('subdivisionId', this.subdivisionId);
        body.set('countryId', this.countryId);
        body.set('stateId', this.stateId);
        body.set('utilityId', this.utilityId);
        body.set('circleId', this.circleId);
        body.set('divisionId', this.divisionId);
        body.set('authUname', this.authUname.toString());
        body.set('sdate', this.startDate.toString());
        body.set('edate', this.endDate.toString());
        body.set('reporttype', this.Consumptionsview.toString());
        body.set('view', '0');
        body.set('authToken', this.authToken);
        body.set('offSet', this.skip.toString());
        body.set('limit', this.reportlimit.toString());

        this.genReqs.postReq('/reports/oldNewMeterInfoPagination', body).subscribe((result) => {
            this.responseData = result;
            this.isLoading = false;
            this.isFirstLoading = false;
            // this.htmlContet = this.responseData.data;
            if (this.responseData.success) {
                this.reportTotalRecord = this.responseData.datalimit.totalResult;
                //console.log("1>>>>>>>>>>>>>>>>",this.responseData.data.html);
                this.myTemplate1 = this.sanitizer.bypassSecurityTrustHtml(this.responseData.data.html);
                //console.log("2>>>>>>>>>>>>>>>>",this.myTemplate1.changingThisBreaksApplicationSecurity);
                this.myTemplate = this.myTemplate1.changingThisBreaksApplicationSecurity;
                this.getPageSizeOptions();
                //let toshow = this.responseData.datalimit.toshow;
                //this.htmlContet = this.responseData.data.Data;
                //this.toshowKeys = Object.keys(toshow);
                //this.toshowIndexs = Object.values(toshow);
                if (this.myTemplate.length < 1) {
                    this.imageAddress = "assets/images/Nodatamobapp.png";
                }
            } else {
                this.imageAddress = "assets/images/Nodatamobapp.png";
            }
        }, (err) => {
            this.isLoading = false;
            this.isFirstLoading = false;
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.imageAddress = "assets/images/Nodatamobapp.png";
            this.message = 'Unable to process yours request!';
        });
    }

    changeMeterPage(event) {
        this.submitReport = false;
        if (event.pageSize !== this.reportlimit) {
            this.reportlimit = event.pageSize;
            this.skip = event.pageSize * event.pageIndex;
            //console.log("first",this.reportlimit,this.skip);
            this.loadReport(true);
        } else {
            this.skip = event.pageSize * event.pageIndex;
            // console.log("second",this.reportlimit,this.skip);
            this.loadReport(true);
        }
    }

    makemeterreport(row) {
        //console.log(row);
    }

    clear() {
        //alert("hello");
        this.startDate = '';
        this.endDate = '';
    }

    getDateFromRange(pickedRange: Date[]) {
        let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
        let sday = pickedRange[0].getDate();
        let syear = pickedRange[0].getFullYear();
        this.startDate = syear + '-' + smonth + '-' + sday;
        let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
        let eday = pickedRange[1].getDate();
        let eyear = pickedRange[1].getFullYear();
        this.endDate = eyear + '-' + emonth + '-' + eday;
    }

    getMonthFromRange(pickedDate = new Date()) {
        if (pickedDate) {
            this.monthSelection = pickedDate;
            let smonth = pickedDate.getMonth() + 1; //always move 1 value up
            let sday = '01';//pickedDate.getDate();
            let syear = pickedDate.getFullYear();
            this.startDate = syear + '-' + smonth + '-' + sday;
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = '01';//pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.endDate = eyear + '-' + emonth + '-' + eday;
        }
    }
    getMonthEndFromRange(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = '01';//pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.endDate = eyear + '-' + emonth + '-' + eday;
        }
    }

    getYearFromRange(pickedDate = new Date()) {
        if (pickedDate) {
            let smonth = '01'; //always move 1 value up
            let sday = '01';//pickedDate.getDate();
            let syear = pickedDate.getFullYear();
            this.startDate = syear + '-' + smonth + '-' + sday;
            let emonth = '12';
            let eday = '01';//pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.endDate = eyear + '-' + emonth + '-' + eday;
            this.yearSelection = pickedDate;
        }
    }

    getYearEndFromRange(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = '01';
            let eday = '01';//pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.endDate = eyear + '-' + emonth + '-' + eday;
        }
    }


    filterData(val: string) {
        this.submitReport = true;
        if (val == '1') {
            this.loadReport(false);
        } else {
            this.loadReport(true);
        }
    }

    myLoadEvent() {
        this.isFirstLoading = true;
        this.isLoading = true;
    }

    pdfNavigate() {
        window.open(this.pdfUrl, "_blank");
    }



    getPageSizeOptions() {
        if (this.reportTotalRecord > 0 && this.reportTotalRecord <= 10) {
            return [this.reportTotalRecord];
        } else if (this.reportTotalRecord > 10 && this.reportTotalRecord <= 20) {
            return [10, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 20 && this.reportTotalRecord <= 40) {
            return [10, 20, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 40 && this.reportTotalRecord <= 80) {
            return [10, 20, 40, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 80 && this.reportTotalRecord <= 100) {
            return [10, 20, 40, 80, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 100 && this.reportTotalRecord <= 200) {
            return [10, 20, 40, 80, 100, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 200 && this.reportTotalRecord <= 300) {
            return [10, 20, 40, 80, 100, 200, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 300 && this.reportTotalRecord <= 400) {
            return [10, 20, 40, 80, 100, 200, 300, this.reportTotalRecord];
        } else if (this.reportTotalRecord > 400 && this.reportTotalRecord <= 500) {
            return [10, 20, 40, 80, 100, 200, 300, 400, this.reportTotalRecord];
        } else {
            return [10, 20, 40, 80, 100, 200, 400, 500, this.reportTotalRecord];
        }
    }



    downloadAsPDF2() {

        let pdfUrl = this.siteUrl + '/reports/oldNewMeterInfoPagination?subdivisionId=' + this.subdivisionId
            + '&countryId=' + this.countryId
            + '&stateId=' + this.stateId
            + '&utilityId=' + this.utilityId
            + '&circleId=' + this.circleId
            + '&divisionId=' + this.divisionId
            + '&authUname=' + this.authUname.toString()
            + '&sdate=' + this.startDate.toString()
            + '&edate=' + this.endDate.toString()
            + '&reporttype=' + this.Consumptionsview.toString()
            + '&authToken=' + this.authToken
            + '&offSet=' + this.skip.toString()
            + '&limit=' + this.reportlimit.toString()
            + '&pdf=1';
        window.open(pdfUrl);
    }

    csvNavigate() {

        let pdfUrl = this.siteUrl + '/reports/oldNewMeterInfoPagination?subdivisionId=' + this.subdivisionId
            + '&countryId=' + this.countryId
            + '&stateId=' + this.stateId
            + '&utilityId=' + this.utilityId
            + '&circleId=' + this.circleId
            + '&divisionId=' + this.divisionId
            + '&authUname=' + this.authUname.toString()
            + '&sdate=' + this.startDate.toString()
            + '&edate=' + this.endDate.toString()
            + '&reporttype=' + this.Consumptionsview.toString()
            + '&authToken=' + this.authToken
            + '&offSet=' + this.skip.toString()
            + '&limit=' + this.reportlimit.toString()
            + '&pdf=2';
        window.open(pdfUrl);
    }

    displayFlatInfo(flat: any): string {
        return flat ? flat.circleName : undefined;
    }

    findFlatInfo(val: string) {
        return this.flatIdData.filter(state =>
            state.circleName.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
    }

    onSelectFlat(e, meterId) {
        this.canSearch = true;
        this.authUname = meterId;
    }

}
