<div class="container-fluid innerpage_top page-content-wrapperr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow {{divClass}}">
  
        <ng-container>
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <h1>Integrations Files
                <span class="info_icntop ml-1">
                  <i data-toggle="tooltip" data-placement="top" title="Information of All Integrations"
                    class="fa fa-info tabula-tooltip "></i>
                </span>
              </h1>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="flex_auth">
                <div class="authsearch">
                  <div class="search">
                    <input class="search-txt searchplaceholder" type="text"
                      placeholder="Search by Date"
                      value="{{levelSearch}}" (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                    <div class="search-btn nobg">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="length!=0">
                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                    style="color: #000;" class="npMi circlebtn">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage">
                    <div class="topspacemodelgrip">
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <div class="add_rowEgrid">Add Details</div>
                    </div>
                    <mat-list role="list" *ngFor="let item of levelHeadsCols" class="listaddinfogridfull">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                          {{item.label}}</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
                <div>
                  <button type="button" class="npMi editbordr_circlttop  mr-3" (click)="addIntegrationFile()"
                    *ngIf="getThrough.authority && getThrough.authority.Auth_Integration_And_commissioning_Add">
                    <i class="fa fa-plus"></i>
                  </button>
                  <button mat-flat-button (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                    <mat-icon>reply</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <div class="container-fluid inner-page-content">
  
            <!-- <mat-card-header class="">
                   <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-5>
                      <div *ngIf="length!=0">
                      <mat-form-field class="dcuFilter" floatLabel="never">
                        <span matSuffix>
                          <mat-icon matSuffix class="d-c9">search</mat-icon>
                        </span>
                        <input type="text" matInput
                          placeholder="Search by Prefix/Start Range/End Range/Devicetype/Date/Authority"
                          value="{{levelSearch}}" (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                      </mat-form-field>
                    </div>
                  </div>
                  <span class="toolbar-space"></span> -->
            <!-- <div col-3 col-xs-6>
                    <div *ngIf="length!=0">
                      <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                        style="color: #000;" class="npMi">
                        <mat-icon class="d-c10">filter_list</mat-icon>
                      </button>
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                        <mat-list role="list" *ngFor="let item of levelHeadsCols">
                          <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                            <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                              {{item.label}}</mat-checkbox>
                          </mat-list-item>
                        </mat-list>
                      </mat-menu>
                      </div>
                  </div> 
                  <div col-3 col-xs-1>
                    <button type="button" mat-mini-fab class="d-c9" (click)="addMeterRange()"
                      *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Add_Meter_Range_Button">
                      <mat-icon>add</mat-icon>
                    </button>
                    <button mat-flat-button (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                      <mat-icon>reply</mat-icon>
                    </button>
                  </div>
                </mat-card-header>-->
            <div class="roww col-12">
              <ng-container *ngIf="loading">
                <div class="col-sm-12 text-center">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </div>
              </ng-container>
              <div *ngIf="length!=0 && !loading" class="table-responsivee table-responsive_fixed">
                <table id="ExampleMaterialTable" matSort mat-table [dataSource]="dataSource"
                  class="table-bordered table-sm table-hover" *ngIf="dataSource.length > 0">
  
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="action_iconbtn">
                        <button class="btn_outlineaction" type="button" (click)="editMeterRange(row)"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_Integration_And_commissioning_Edit">
                          <span><i class="fa fa-edit"></i></span>
                        </button>
                        <button class="btn_outlineaction" (click)="removelevel(row)" type="button"
                          *ngIf="getThrough.authority && getThrough.authority.Auth_Integration_And_commissioning_Delete">
                          <span><i class="fa fa-trash"></i></span>
                        </button>
                      </div>
                      <!-- <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                            <mat-icon class="d-c10">menu_open</mat-icon>
                          </button> 
                           <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                            <mat-list role="list">
                              <mat-list-item role="listitem" class="btn-block text-left"
                                *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Edit_Meter_Range_Button">
                                <button type="button" mat-button (click)="editMeterRange(row)">
                                  <mat-icon class="d-c13 authall">edit</mat-icon> Modify
                                </button>
                              </mat-list-item>
                              <mat-list-item role="listitem"
                                *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Delete_Meter_Range_Button">
                                <button type="button" mat-button (click)="removelevel(row)" class="btn-block text-left">
                                  <mat-icon class="d-c5">delete</mat-icon> Delete
                                </button>
                              </mat-list-item>
                            </mat-list>
                          </mat-menu> -->
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="srNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sr. No.</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.srNo}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="meterType">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Meter Type
                    </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.meterType}} </td>
                  </ng-container>
                  <ng-container matColumnDef="totalMeters">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header> Total Meters
                    </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.totalMeters}} </td>
                  </ng-container>
                  <!-- meterCmdCode Column -->
                  <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>File Name</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.fileName}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="fileUrl">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>Download Uploded File</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      <button class="btn_outlineaction" title="Download" (click)="downloadFile(element.fileUrl)"><i
                        class="fa fa-download" ></i></button>
                     </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.status}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="datetime">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>Date & Time</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.datetime}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="authorityName">
                    <th mat-header-cell *matHeaderCellDef class="bolder" mat-sort-header>Authority Id</th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      {{element.authorityName}} </td>
                  </ng-container>
  
  
                  <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay; sticky: true" class="tableHeader"></tr>
                  <tr class="tableColumns" mat-row *matRowDef="let element; columns: levelColumnsToDisplay;">
                  </tr>
                </table>
              </div>
              <div *ngIf="loadingNoData">
                <div>
                  <div>
                    <img class="center" src="assets/images/Nodata.png">
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
        </ng-container>
      </div>
      <mat-card-footer>
        <div class="row">
          <div class="col-11" *ngIf="!loadingNoData">
            <mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()" [length]="length"
              (page)="changeMeterPage($event)" showFirstLastButtons>
            </mat-paginator>
          </div>
          <div class="col-1 text-left1">
            <div *ngIf="!loadingNoData">
              <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                  class="fa fa-file-excel-o" style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
            </div>
          </div>
        </div>
      </mat-card-footer>
    </div>
  </div>