import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { ModalService } from '../_services/modal.service';
import { take } from 'rxjs/operators';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { party } from "node_modules/party-js/bundle/party.js";
// import VueEmojiParty from 'vue-emoji-party';
// import Vue from 'vue';

@Component({
  selector: 'app-rechargetopay',
  templateUrl: './rechargetopay.component.html',
  styleUrls: ['./rechargetopay.component.scss']
})
export class RechargetopayComponent implements OnInit {
  messageResult: any;
  thumb: boolean = false;
  rechargeForm: UntypedFormGroup;
  merchantIdData = '';
  numberValue = '1';
  rechargeAmount = '';
  userRecharges = {
    userInfo: {
      mobilenumber: '',
      username: '',
      email: '',
      uid: '',
      meterUniqueId: '',
      merchantId: '',
      subdivId: ''
    },
    userPlans: {
      dataValue: ''
    },
    userHistory: [
      { 'amount': '0', 'date': '00-00-0000' }
    ],
    subName: {

    },
  }
  currentUserPlan: any;
  /*currentUserPlan:[
    {
     '100',
    '200'
    }
  ];*/
  defaultRechargeType="0";
  subTotal: any;
  rechargeHistory: any;
  currentUserAuth: string = '';
  typeData: string = '';
  responseData: any;
  responseMessage: string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  isLoading=false;
  faSpinner = faSpinner;
  payuform: any = {
    rechargeType: '0',
    mobilenumber: '',
    username: '',
    amount: 1,
    email: '',
    uid: '',
    meterUniqueId: '',
    subName: '',
    productInfo: '',
    txnid: '',
    surl: 'http://localhost:4200/successurl',
    furl: 'http://localhost:4200/failedurl',
    key: '',
    hash: '',
    service_provider: 'payu_paisa'

  };
  disablePaymentButton: boolean = true;
  sitePath = '';
  constructor(
    private router: Router,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.sitePath = this.genReqs.apiPath();
  }

  ngOnInit() {
    // window.onload = function () {
    //   //canvas init
    //   var canvas = document.getElementById("canvas");
    //   var ctx = canvas.getContext("2d");

    //   //canvas dimensions
    //   var W = window.innerWidth;
    //   var H = window.innerHeight;
    //   canvas.width = W;
    //   canvas.height = H;

    //   //snowflake particles
    //   var mp = 25; //max particles
    //   var particles = [];
    //   for (var i = 0; i < mp; i++) {
    //     particles.push({
    //       x: Math.random() * W, //x-coordinate
    //       y: Math.random() * H, //y-coordinate
    //       r: Math.random() * 4 + 1, //radius
    //       d: Math.random() * mp, //density
    //       color: "rgba(" + Math.floor((Math.random() * 255)) + ", " + Math.floor((Math.random() * 255)) + ", " + Math.floor((Math.random() * 255)) + ", 1)"
    //     })
    //   }

    //   //Lets draw the flakes
    //   function draw() {
    //     ctx.clearRect(0, 0, W, H);



    //     for (var i = 0; i < mp; i++) {
    //       var p = particles[i];
    //       ctx.beginPath();
    //       ctx.fillStyle = p.color;
    //       ctx.moveTo(p.x, p.y);
    //       ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
    //       ctx.fill();
    //     }

    //     update();
    //   }

    //Function to move the snowflakes
    //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
    //   var angle = 0;
    //   function update() {
    //     angle += 0.01;
    //     for (var i = 0; i < mp; i++) {
    //       var p = particles[i];
    //       //Updating X and Y coordinates
    //       //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
    //       //Every particle has its own density which can be used to make the downward movement different for each flake
    //       //Lets make it more random by adding in the radius
    //       p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
    //       p.x += Math.sin(angle) * 2;

    //       //Sending flakes back from the top when it exits
    //       //Lets make it a bit more organic and let flakes enter from the left and right also.
    //       if (p.x > W + 5 || p.x < -5 || p.y > H) {
    //         if (i % 3 > 0) //66.67% of the flakes
    //         {
    //           particles[i] = { x: Math.random() * W, y: -10, r: p.r, d: p.d, color: p.color };
    //         }
    //         else {
    //           //If the flake is exitting from the right
    //           if (Math.sin(angle) > 0) {
    //             //Enter from the left
    //             particles[i] = { x: -5, y: Math.random() * H, r: p.r, d: p.d, color: p.color };
    //           }
    //           else {
    //             //Enter from the right
    //             particles[i] = { x: W + 5, y: Math.random() * H, r: p.r, d: p.d, color: p.color };
    //           }
    //         }
    //       }
    //     }
    //   }

    //   //animation loop
    //   setInterval(draw, 10);


    // }
    // function shake() {
    //   setInterval(draw, 100);

    // }

    /*this.payuform = this.formBuilder.group({
      amount: ['',  [Validators.max(100), Validators.min(0)]]
    });*/

    this.route.queryParams.subscribe(params => {
      this.currentUserAuth = params['currentuser'];
      this.typeData = params['typeData'];
    });

    this.getRechargeUserInfo();
    this.payuform.firstname = this.userRecharges.userInfo.username;
    this.payuform.phone = this.userRecharges.userInfo.mobilenumber;
    this.payuform.email = this.userRecharges.userInfo.email;
    this.payuform.productinfo = 'Meter Recharge';
    if (this.typeData == '1') {
      this.payuform.surl = this.sitePath + '/successRecharge';
      this.payuform.furl = this.sitePath + '/failedRecharge';
    } else {
      this.payuform.surl = this.sitePath + '/success';
      this.payuform.furl = this.sitePath + '/failed';
    }
    // console.log("payuform,",this.payuform.surl," ",this.payuform.furl);
    //this.payuform.surl =  'http://localhost:4200/#/successRecharge';
    //this.payuform.furl = 'http://localhost:4200/#/failedRecharge';

    this.payuform.udf1 = this.userRecharges.userInfo.uid;
    this.payuform.udf2 = this.userRecharges.userInfo.meterUniqueId;
    this.payuform.udf3 = this.userRecharges.subName;
    this.payuform.udf4 = this.userRecharges.userInfo.merchantId;
    this.payuform.udf5 = this.userRecharges.userInfo.subdivId;
    /*this.payuform.udf6 = this.currentUserAuth.substring(255,510);
    this.payuform.udf7 = this.currentUserAuth.substring(510,765);*/


    this.currentUserPlan = this.userRecharges.userPlans;
    this.rechargeHistory = this.userRecharges.userHistory;
    //this.specialofferbtn();
    // console.log("data,",this.userRecharges);
  }

  changeRechargeType(defaultRechargeType) {
    this.defaultRechargeType = defaultRechargeType;
  }



  get rf() { return this.rechargeForm.controls; }

  // specialofferbtnn() {
  //   party.confetti({
  //     count: party.variation.range(20, 40),
  //   });
  // }

  // specialofferbtn() {
  //   // party.confetti({
  //   //   count: party.variation.range(20, 40),
  //   // });

  // }


  setAmount(amount: any = 0) {
    this.payuform.amount = amount;
    // console.log("hello", this.payuform.amount);
  }

  float2int(value) {
    return value | 0;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  confirmPayment() {
    this.rechargeAmount = this.payuform.amount;
    this.isLoading=true;
    if (!(this.payuform.amount > 0 && this.payuform.amount < 99999)) {
      alert("Recharge Amount Must be greator than 0 and less than 99999 !");
      return false;
    }
    //console.log("Data",this.rechargeAmount.toString().indexOf('.'),this.rechargeAmount.toString());

    if (this.rechargeAmount.toString().indexOf('.') > -1) {
      alert("Please Enter Interger Value!");
      return false;
    }
    let body = new URLSearchParams();
    body.set('authToken', this.currentUserAuth);
    body.set('email', this.payuform.email);
    body.set('firstname', this.payuform.firstname);
    body.set('phone', this.payuform.phone);
    body.set('productinfo', this.payuform.productinfo);
    body.set('amount', this.rechargeAmount);
    body.set('rechargeType', this.defaultRechargeType);
    /*body.set('udf7', this.payuform.udf7);
    body.set('udf6', this.payuform.udf6);*/
    body.set('udf5', this.payuform.udf5);
    body.set('udf4', this.payuform.udf4);
    body.set('udf3', this.payuform.udf3);
    body.set('udf2', this.payuform.udf2);
    body.set('udf1', this.payuform.udf1);
    this.genReqs.postReq('/confirmRechargeDetail', body).subscribe((result) => {
      this.responseData = result;
      this.isLoading=false;
      if (this.responseData.success) {
        
        let rDetails = this.responseData.data;
        this.payuform.txnid = rDetails.txnid;
        this.payuform.key = rDetails.key;
        this.payuform.hash = rDetails.hash;
        //this.payuform.productinfo = this.escapeHtml(rDetails.product_Info);
        this.payuform.productinfo = rDetails.product_Info;
        this.disablePaymentButton = false;
        this.responseMessage = 'Details verified. Please proceed for recharge!';
        this.thumb = true;
      }else{
        this.thumb = false;
        this.responseMessage = 'Recharge Command is already in queue. Please try after some time!';
      }
    }, (err) => {
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  getRechargeUserInfo() {
    let body = new URLSearchParams();
    body.set('currentuser', this.currentUserAuth);
    this.genReqs.postReq('/getrechargeinfo', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.userRecharges = this.responseData.data;
        //console.log(this.userRecharges);
        this.payuform.firstname = this.userRecharges.userInfo.username;
        this.payuform.phone = this.userRecharges.userInfo.mobilenumber;
        this.payuform.email = this.userRecharges.userInfo.email;
        this.payuform.udf1 = this.userRecharges.userInfo.uid;
        this.payuform.udf2 = this.userRecharges.userInfo.meterUniqueId;
        this.payuform.udf3 = this.userRecharges.subName;
        this.payuform.udf4 = this.userRecharges.userInfo.merchantId;
        this.payuform.udf5 = this.userRecharges.userInfo.subdivId;
        /*this.payuform.udf6 = this.currentUserAuth.substring(255,510);
        this.payuform.udf7 = this.currentUserAuth.substring(510,765);*/
        this.currentUserPlan = this.userRecharges.userPlans;
        this.rechargeHistory = this.userRecharges.userHistory;
      }
    }, (err) => {
      this.responseMessage = 'Unable to process yours request!';
    });
  }

  changeValue(value: string): void {
    this.subTotal += value;
  }

  escapeHtml(text) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, function (m) { return map[m]; });
  }


}
