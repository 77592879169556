import { Component, OnInit, ɵConsole, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from '../../_models';
import { ModalService } from '../../_services/modal.service';
import { AuthenticationService, generalRequestsService } from '../../_services';
import { GridlistService } from '../../_services/gridlist.service';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';
@Component({
  selector: 'app-addedconsumer',
  templateUrl: './addedconsumer.component.html',
  styleUrls: ['./addedconsumer.component.scss']
})
export class AddedconsumerComponent implements OnInit {
  currentUser: User;
  dataExport: any;
  currentUserSubscription: Subscription;
  responseData: any;
  messageResult: any;
  breakpoint: number;
  allConsumersData: any;
  messageAuthoritys = 'No Consumer Found';
  selectedSubDivision: any;
  loadingConsumers: boolean = false;
  consId: number = 0;
  levelSearch: string = '';
  filter = '';
  //pagination settings
  limit: number = 10;
  skip: number = 0;
  responseMessage: string = '';
  removeProcess: boolean = false;
  // MatPaginator Inputs
  length: number = 0;
  pageSize: number = 10;  //displaying three cards each row
  pageSizeOptions: number[] = [5, 10, 15];
  enableSocietyChange: boolean = true;
  getThrough: Access;
  faSpinner = faSpinner;
  enableMenu=true;
  loadingNoData: boolean = false;
  divClass: any="tblH_pagination";
  constructor(
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private gridlistService: GridlistService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.breakpoint = this.gridlistService.breakpoint;
    });
    if (this.currentUser.data.userData.authLevel == '7') {
      this.enableSocietyChange = false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    if (this.enableSocietyChange) {
      this.whichSubDomain();
    } else {
      this.getAllConsumers();
    }
    this.reCountCols(window.innerWidth);
    if( this.getThrough.authority && this.getThrough.authority.Consumer_Sub_Consumer_Recharge_Button==0 &&
      this.getThrough.authority && this.getThrough.authority.Consumer_Sub_Consumer_Adjustment_Button==0 &&
      this.getThrough.authority && this.getThrough.authority.Consumer_Sub_Edit_Consumer_Info_Button==0 &&
      this.getThrough.authority && this.getThrough.authority.Consumer_Sub_Edit_Consumer_Permission_Button==0 &&
      this.getThrough.authority && this.getThrough.authority.Consumer_Sub_Delete_Consumer_Button==0 
    ){
        this.enableMenu=false;
    }
  }

  onLevelSearchChange(search) {
    this.levelSearch = search;
    this.skip = 0;
    this.getAllConsumersData(false);
  }

  getAllConsumersData(addNew: boolean = false) {
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }

    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('consId', this.consId.toString());
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsumers', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.allConsumersData = this.responseData.data.completeData;
        this.length = this.responseData.datalimit.totalResult;
        if(this.length==0){
          this.loadingNoData=true;
          this.divClass="tblH_norecord";
        }else{
          this.loadingNoData=false;
        }
        if(this.length<10){
          this.limit= this.length;
        }

      } else {
        this.messageAuthoritys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageAuthoritys = 'Unable to process yours request!';
    });
  }

  refreshData() {
    this.limit=10;
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }

    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('consId', this.consId.toString());
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsumers', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.allConsumersData = this.responseData.data.completeData;
        this.length = this.responseData.datalimit.totalResult;
        if(this.length==0){
          this.loadingNoData=true;
          this.divClass="tblH_norecord";
        }else{
          this.loadingNoData=false;
        }
        if(this.length<10){
          this.limit= this.length;
        }

      } else {
        this.messageAuthoritys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageAuthoritys = 'Unable to process yours request!';
    });
  }


  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
  }

  onResize(event) {
    this.reCountCols(event.target.innerWidth);
  }

  reCountCols(width: number) {
    this.breakpoint = this.gridlistService.reCountAuthCols(width);
  }
  addconsumer() {
    this.modalService.addNewConsumer().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.refreshData();
      }
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  whichSubDomain() {
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //add sub-division name
        //console.log('selected val', this.selectedSubDivision);
        this.getAllConsumers();
      }
      );
  }
  getAllConsumers() {
    this.loadingConsumers = true;
    let body = new URLSearchParams();
    this.allConsumersData = [];
    //this.skip=0;

    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }

    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('consId', this.consId.toString());
    body.set('authToken', this.currentUser.authToken);

    this.genReqs.postReq('/getConsumers', body).subscribe((result) => {
      this.responseData = result;
      this.loadingConsumers = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        this.allConsumersData = this.responseData.data.completeData;
        this.dataExport = this.responseData.data.dataExport;
        this.length = this.responseData.datalimit.totalResult;
        if(this.length==0){
          this.loadingNoData=true;
          this.divClass="tblH_norecord";
        }else{
          this.loadingNoData=false;
        }
        if(this.length<10){
          this.limit= this.length;
        }

        //console.log("in test", this.length, this.skip, this.limit);
        //this.getPageSizeOptions();
      } else {
        this.messageAuthoritys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingConsumers = false;
      this.messageAuthoritys = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportArrayToExcel(this.dataExport,"Consumer Data");
  }


  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getAllConsumers();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getAllConsumers();
    }
  }

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [10, 20, 40, 80, 160, this.length];
    } else if (this.length > 320) {
      return [10, 20, 40, 80, 160, 320, this.length];
    } 
  }

  
  rechargeMeter(user) {
    //console.log(user);
    this.modalService.rechargeMeter(user).pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log(result);
      }
      );
  }

  adjustmentMeter(user) {
    //console.log(user);
    this.modalService.adjustmentMeter(user).pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log(result);
      }
      );
  }

  editconsumer(consumer) {
    //console.log(consumer);
    this.modalService.editConsumer(consumer).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.refreshData();
      }
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  removeconsumer(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove ' + row.consFullName + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('consId', row.consAccountNo);
          body.set('consFullName', row.consFullName);
          body.set('subDivisionId', row.sdID);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteConsumer', body).subscribe((result) => {
            this.responseData = result;
            this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getAllConsumers();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }

  permconsumer(consumer) {
    this.modalService.permConsumer(consumer).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }
}
