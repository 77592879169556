<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c10"> bug_report </mat-icon>
        <input matInput placeholder="Over Voltage thrasholds" name="ovth" formControlName="ovth">
        <mat-error *ngIf="cF.ovth.hasError('required')">
            ovth is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c10"> bug_report </mat-icon>
        <input matInput placeholder="Low Voltage thrasholds" name="lvth" formControlName="lvth">
        <mat-error *ngIf="cF.lvth.hasError('required')">
            lvth is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c10"> bug_report </mat-icon>
        <input matInput placeholder="Over Current Thrasholds" name="octh" formControlName="octh">
        <mat-error *ngIf="cF.octh.hasError('required')">
            octh is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>