import { Component, OnInit, ChangeDetectionStrategy, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addsuperviser',
  templateUrl: './addsuperviser.component.html',
  styleUrls: ['./addsuperviser.component.scss']
})
export class AddsuperviserComponent implements OnInit {
  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority: any;
 
  message: string = '';
  authLevelFieldValue: string = '';

  isLoading: boolean = false;
  thumb: boolean = false;
  ///multiselect
  
  authLevelField: boolean = false;
  nowDate = new Date();
  submitted: boolean = false;
  superSubDivId: any;
  superSubDivData = [
    {
        id: '0',
        name: 'Select Sub-Division'
    }
];
  constructor(
      private formBuilder: UntypedFormBuilder,
      private genReqs: generalRequestsService,
      private authenticationService: AuthenticationService,
      public modal: NgbActiveModal,
      private ref: ChangeDetectorRef
  ) {

      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      if (this.currentUser.data.userData.authLevel == '7') {
          this.authLevelField = true;
          this.authLevelFieldValue = '7';
      }
      ref.detach();
      setInterval(() => { this.ref.detectChanges(); }, 300);
  }

  ngOnInit() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
      /*
        form authority add
      */
      this.addAuthorityForm = this.formBuilder.group({
          superFullName: ['', Validators.required],
          superUserName: ['', Validators.required],
          superPassword: ['', Validators.required],
          superEmailId: ['', [Validators.required, Validators.email]],
          superMobileNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
          superLandlineNo: ['', [Validators.required,Validators.pattern(MOBILE_PATTERN),Validators.minLength(10), Validators.maxLength(10)]],
          superHomeAddressline1: ['', Validators.required],
          superHomeAddressline2: ['', Validators.required],
          superOfficeAddressline1: ['', Validators.required],
          superOfficeAddressline2: ['', Validators.required],
          superActiveStatus: ['', Validators.required],
          superSubDiv: ['']
      });
      //console.log("hello");
      this.getUserSubdivision();
      
      //this.countryLevelData = this.getDynamicList();
     
  }
  get aD() { return this.addAuthorityForm.controls; }



  onSubmitAddAuthority() {
      if (this.addAuthorityForm.invalid) {
            this.submitted = true;
          return;
      }
      this.message = '';
      this.isLoading = true;
      this.AddProcess = true;

      let body = new URLSearchParams();
      body.set('subdivId', this.currentUser.data.userData.subdivision);
      body.set('contractorId', this.authority.aID);
      body.set('superFullName', this.aD.superFullName.value);
      body.set('superUserName', this.aD.superUserName.value);
      body.set('superPassword', this.aD.superPassword.value);
      body.set('superEmailId', this.aD.superEmailId.value);
      body.set('superLandlineNo', this.aD.superLandlineNo.value);
      body.set('superMobileNo', this.aD.superMobileNo.value);
      body.set('superHomeAddressline1', this.aD.superHomeAddressline1.value);
      body.set('superHomeAddressline2', this.aD.superHomeAddressline2.value);
      body.set('superOfficeAddressline1', this.aD.superOfficeAddressline1.value);
      body.set('superOfficeAddressline2', this.aD.superOfficeAddressline2.value);
      body.set('superActiveStatus', this.aD.superActiveStatus.value);
      body.set('superSubDiv', this.aD.superSubDiv.value);
      body.set('authToken', this.currentUser.authToken);

      this.AddProcess = true;
      this.genReqs.postReq('/addSuperwise', body).subscribe((result) => {

          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          this.thumb = false;
          if (this.responseData.success) {
              this.AddProcess = false;
              this.isLoading = false;
              this.thumb = true;
              this.message = this.responseData.message;
              setTimeout(() => 
              {
                this.modal.close('done');
              },
              2000);
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
          this.AddProcess = false;
          this.message = 'Unable to process yours request!';
      });
  }

  getUserSubdivision() {
    let body = new URLSearchParams();
    body.set('subDivision', this.currentUser.data.userData.subdivision);
    body.set('offSet', '0');
    body.set('limit', '99999999');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSubDiv', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.superSubDivData = this.responseData.data;
       // this.meterList();
        
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

}
