import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-addgroup',
    templateUrl: './addgroup.component.html',
    styleUrls: ['./addgroup.component.scss']
})
export class AddgroupComponent implements OnInit {
    isLoading:boolean = false;
    thumb:boolean = false;
    addAuthMessage:string = '';
    faSpinner = faSpinner;
    currentUser: User;
    currentUserSubscription: Subscription;
    addForm: UntypedFormGroup;
    responseMessage: string = '';

    responseData: any;
    AddProcess: boolean = false;
    apiMessage: string = '';
    subDivisionId: any = 0;
     submitted: boolean = false;
    groupData: any = {};
    towerData: any = [{
        'towerId': 0,
        'subDivId': 0,
        'dgId': 0,
        'dgName': "dgName",
        'towerName': "Select Tower"
    }];
    dgData: any = [{
        'dgId': 0,
        'subDivId': 0,
        'dgName': 'Select DG'
      }];
    selectedTower:any = '0';
    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        public modal: NgbActiveModal,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
       
    }

    ngOnInit() {
        this.addForm = this.formBuilder.group({
            dgNameData: ['', Validators.required],
            towerNameData: ['', Validators.required],
            groupName: ['', Validators.required]
        });

        this.getDgData();
        //this.getTowerData();

    }

    get aF() { return this.addForm.controls; }
    onSubmitAddForm() {
         this.submitted = true;
        
        if (this.addForm.invalid) {
            return;
        }
        this.isLoading = true;
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('dgName', this.aF.dgNameData.value);
        body.set('towerName', this.aF.towerNameData.value);
        body.set('groupName', this.aF.groupName.value);
        body.set('authToken', this.currentUser.authToken);
        this.AddProcess = true;
        this.genReqs.postReq('/addGroupData', body).subscribe((result) => {
            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.apiMessage = 'Unable to process yours request!';
        });
    }

    getTowerData() {
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('towerId', this.selectedTower);
        body.set('dgId', this.aF.dgNameData.value);
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('filter', '');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getTowerData', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.towerData = this.responseData.data;
               // console.log(typeof this.towerData);
                // setTimeout(() => {
                // });
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.apiMessage = 'Unable to process yours request!';
        });
    }

    getDgData() {
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('dgId', '0');
        body.set('offSet', '0');
        body.set('limit', '100');
        body.set('filter', '');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getDGData', body).subscribe((result) => {
            this.responseData = result;
             if (this.responseData.success) {
                    this.dgData = this.responseData.data;
                    this.getTowerData();
            //     setTimeout(() => {
            //         this.dgData = this.responseData.data;
            //     });
             }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.apiMessage = 'Unable to process yours request!';
        });
    }

    onDGChange(e) {
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('towerId', '0');
        body.set('dgId', this.aF.dgNameData.value);
        body.set('offSet', '0');
        body.set('limit', '1000');
        body.set('filter', '');
        body.set('authToken', this.currentUser.authToken);
        //console.log("dgtowerload",this.subDivisionId," ",this.aF.dgNameData.value);
        //this.selectedTower = e.value;
        this.genReqs.postReq('/getTowerData', body).subscribe((result) => {

            this.responseData = result;
            if (this.responseData.success) {
                //this.towerData = [...this.towerData, this.responseData.data];
                this.towerData =  this.responseData.data;
                }
        }, (err) => {
            if (err.status === 401) {

            }
        });
    }

    resetForm(form: UntypedFormGroup) {

        form.reset();

        Object.keys(form.controls).forEach(key => {
            form.get(key).setErrors(null);
        });
    }

}
