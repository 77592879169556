<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass h-180">
        <div class="col-12">
          <h2 class="hAtitle mt-0 mb-1 col-12">Sub-Division View : {{bCumSubDivision}}</h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="bCumCountry != ''">
              <a href="#/deshauth" class="btn-primary" mat-icon>
                <fa name="home"></fa>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumCountry != '' && isCountry()">
              <a href="javascript:void(0)" class="btn-primary" (click)="backToCountryView()">
                <span>{{bCumCountry}}</span>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumState != '' && isState()">
              <a href="javascript:void(0)" (click)="backToUtilityView()" class="btn-primary">
                <span>{{bCumState}}</span>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumUtlity != '' && isUtility()">
              <a href="javascript:void(0)" (click)="backToCircleView()" class="btn-primary">
                <span>{{bCumUtlity}}</span>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumCircle != '' && isCircle()">
              <a href="javascript:void(0)" (click)="backToDivisionView()" class="btn-primary">
                <span>{{bCumCircle}}</span>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumDivision != '' && isDivision()">
              <a href="javascript:void(0)" (click)="backToSubDivisionView()" class="btn-primary">
                <span>{{bCumDivision}}</span>
              </a>
            </li>
            <li class="breadcrumb-item" *ngIf="bCumSubDivision != '' && isSubDivision()">
              <a href="jvascript:void(0)" class="btn-primary">
                <span>{{bCumSubDivision}}</span>
              </a>
            </li>
          </ol>
        </div>
      </div>

      <div class="row inner-page-content of-auto">
        <ng-container class="allutilitysloader col-sm-12" *ngIf="!isDataRedyForTableDcu">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        <ng-container *ngIf="isDataRedyForTableDcu  && tabToShow == 0">
          <div class="col-12 plr_c25px xs-no-p">
            <mat-card class="-mt-20">
              <mat-card-header class="h-16 xs-c-h">
                <div class="title hidden-sm xs-dn" col-3>
                  DCU List
                </div>
                <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-6>
                  <mat-form-field class="dcuFilter" floatLabel="never">
                    <span matPrefix>
                      <mat-icon matSuffix>search</mat-icon>
                    </span>
                    <input type="text" matInput placeholder="search" value="{{dcuSearch}}" (input)="onDcuSearchChange($event.target.value)">
                  </mat-form-field>
                </div>
                <span class="toolbar-space"></span>
                <div col-3 col-xs-6>
                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                    style="color: #000;">
                    <mat-icon>filter_list</mat-icon>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                    <mat-list role="list" *ngFor="let item of dcuHeadsCols">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="onChange($event, i, item)">{{item.label}}
                        </mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                  <button type="button" mat-mini-fab class="cPbg" (click)="open(content)">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </mat-card-header>
              <mat-card-content>
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                    <td mat-cell *matCellDef="let row" (click)="expandedElement = expandedElement === row ? null : row">
                      <button type="button" class="icon" (click)="meterView(row)">
                        <mat-icon>
                          {{expandedElement === row ? 'keyboard_arrow_down' : 'chevron_right'}}
                        </mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                      <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                        <mat-icon>more_horiz</mat-icon>
                      </button>
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                        <mat-list role="list">
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="dcuEditView(row, dcumodify)">
                              <mat-icon class="d-c2">edit</mat-icon> Modify
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="configureDCU(row)">
                              <mat-icon class="d-c3">build</mat-icon> Configure DCU
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="actionOnDCU(row)">
                              <mat-icon class="d-c4">chrome_reader_mode</mat-icon> Activity On DCU
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="reportingFromDCU(row)">
                              <mat-icon class="d-c5">eco</mat-icon> Reporting From DCU
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="configureMeter(row)">
                                <mat-icon class="d-c6">build</mat-icon> Configure Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="actionOnMeter(row)">
                                <mat-icon class="d-c7">chrome_reader_mode</mat-icon> Activity On Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="reportingFromMeter(row)">
                                <mat-icon class="d-c8">eco</mat-icon> Reporting from Meters
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="meterInformation(row)">
                                <mat-icon class="d-c9">assignment</mat-icon> Meter Information
                              </button>
                            </mat-list-item>
                            
                            
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="realTimeData(row)">
                                <mat-icon class="d-c10">assignment</mat-icon>Real Time Data
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="recentBilling(row)">
                                <mat-icon class="d-c13">assignment</mat-icon> Recent Billing
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="recentEvents(row)">
                                <mat-icon class="d-c12">assignment</mat-icon> Recent Events
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="recentDipWiseLoadSurvey(row)">
                                <mat-icon class="d-c11">assignment</mat-icon> Recent DIP Wise Load Survey
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="recentLoadSurveyDayWise(row)">
                                <mat-icon class="d-c14">assignment</mat-icon> Recent Day Wise Load Survey
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="meterConfigurationData(row)">
                                <mat-icon class="d-c15">assignment</mat-icon> Meter Configuration
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="lprConfigurationData(row)">
                                <mat-icon class="d-c8">assignment</mat-icon> LPR Configuration
                              </button>
                            </mat-list-item>
                            
                           
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="meterActivityData(row)">
                                <mat-icon class="d-c7">assignment</mat-icon> Meter's Activity
                              </button>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="revenueData(row)">
                                <mat-icon class="d-c6">assignment</mat-icon> Revenue
                              </button>
                            </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="unitConsumptionData(row)">
                              <mat-icon class="d-c5">assignment</mat-icon> Unit Consumption
                            </button>
                          </mat-list-item>
                          
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="lowBalanceMeterData(row)">
                              <mat-icon class="d-c4">assignment</mat-icon> Low Balance Meter
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="zeroBalanceMeterdata(row)">
                              <mat-icon class="d-c3">assignment</mat-icon> Zero Balance
                            </button>
                          </mat-list-item>
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="deadMetersData(row)">
                              <mat-icon class="d-c2">assignment</mat-icon> Dead Meters
                            </button>
                          </mat-list-item>

                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="dgStatusData(row)">
                              <mat-icon class="d-c">assignment</mat-icon> DG Status
                            </button>
                          </mat-list-item>
                          
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="deleteDCU(row)">
                              <mat-icon class="d-c4">delete</mat-icon> Delete
                            </button>
                          </mat-list-item>

                        </mat-list>
                      </mat-menu>
                    </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="dcuName">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuName}} </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="dcuCode">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Code </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuCode}} </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="dcuID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> ID </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="aID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Authority Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.aID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="cID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Country Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.cID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="sID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> State Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.sID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="uID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Utility Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.uID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="ciID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Circle Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.ciID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="dID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Division Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="sdID">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Sub Division Name </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.sdID}} </td>
                  </ng-container>
                  <!-- Weight Column -->
                  <ng-container matColumnDef="dcuActivationStatus">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Activation </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuActivationStatus}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="gisLocation">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Location </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.gisLocation}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dcuGatewayStrength">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Gateway Strength </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuGatewayStrength}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="ebdgStatusMode">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Status Mode </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.ebdgStatusMode}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="ebStatus">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> EB Status </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      <fa name="circle" size="2x"
                        class="{{element.ebStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                    </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dgStatus">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> DG Status </th>
                    <td mat-cell *matCellDef="let element" class="bolder">
                      <fa name="circle" size="2x"
                        class="{{element.dgStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                    </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dcuSIMNo">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> SIM No </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuSIMNo}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dcuRTC">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Real Date Time </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuRTC}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dcuActiveStatus" class="bolder">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Active Status </th>
                    <td mat-cell *matCellDef="let element">
                      <fa name="circle" size="2x"
                        class="{{element.dcuActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                    </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="dcuActivationDatetime">
                    <th mat-header-cell *matHeaderCellDef class="bolder"> Activation Datetime </th>
                    <td mat-cell *matCellDef="let element" class="bolder"> {{element.dcuActivationDatetime}} </td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail" col-12>
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                      <div class="dcu-meter-list"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="allutilitysloader col-sm-12" *ngIf="!isMeterDataLoad">
                          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                        </div>
                        <div class="allutilitysloader col-sm-12" *ngIf="isMeterDataLoad && dataMeterSource.length == 0">
                          No Result Found!
                        </div>
                        <table #innerTables mat-table [dataSource]="dataMeterSource"
                          class="mat-elevation-z8 meterTableData" *ngIf="isMeterDataLoad && dataMeterSource.length > 0">

                          <!-- Checkbox Column -->
                          <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                              <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                                <mat-icon>more_horiz</mat-icon>
                              </button>
                              <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                <mat-list role="list">
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="editMeter(row)">
                                      <mat-icon class="d-c13">edit</mat-icon> Modify
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterRecharge(row)">
                                      <mat-icon class="d-c14">trending_up</mat-icon> Make Recharge
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterAdjustment(row)">
                                      <mat-icon class="d-c15">trending_down</mat-icon> Make Adjustment
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterConfig(row)">
                                      <mat-icon class="d-c2">build</mat-icon> Configure Meter
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterActivity(row)">
                                      <mat-icon class="d-c3">chrome_reader_mode</mat-icon> Activity On Meter
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterReporting(row)">
                                      <mat-icon class="d-c4">eco</mat-icon> Reporting From Meter
                                    </button>
                                  </mat-list-item>
                                  
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="realTimeDataSub(row)">
                                      <mat-icon class="d-c5">assignment</mat-icon>Real Time data
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentBill(row)">
                                      <mat-icon class="d-c6">assignment</mat-icon>Recent Bill
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentEvent(row)">
                                      <mat-icon class="d-c7">assignment</mat-icon>Recent Events
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentDipWiseLoadSurveySub(row)">
                                      <mat-icon class="d-c8">assignment</mat-icon>Recent Dip Wise Load Survey
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentDayLoadSurvey(row)">
                                      <mat-icon class="d-c9">assignment</mat-icon>Recent Day Load Survey
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentSmsSent(row)">
                                      <mat-icon class="d-c10">assignment</mat-icon> Recent SMS Sent
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="teriff(row)">
                                      <mat-icon class="d-c11">assignment</mat-icon>Meter Teriff
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="meterConfiguration(row)">
                                      <mat-icon class="d-c11">assignment</mat-icon>Recent Meter Configuration
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="lprConfiguration(row)">
                                      <mat-icon class="d-c11">assignment</mat-icon>Recent Lpr Configuration
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentActivity(row)">
                                      <mat-icon class="d-c8">assignment</mat-icon>Recent Activity
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentRecharge(row)">
                                      <mat-icon class="d-c10">assignment</mat-icon> Recent Recharges
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="recentConsumption(row)">
                                      <mat-icon class="d-c11">assignment</mat-icon> Recent Consumption
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon class="d-c12">delete</mat-icon> Delete
                                    </button>
                                  </mat-list-item>
                                </mat-list>
                              </mat-menu>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mID">
                            <th mat-header-cell *matHeaderCellDef> Meter ID </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mID}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mlprID">
                            <th mat-header-cell *matHeaderCellDef> LPR ID </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mlprID}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mSimNo">
                            <th mat-header-cell *matHeaderCellDef> Sim No </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mSimNo}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mIMEINo">
                            <th mat-header-cell *matHeaderCellDef> IMEI No </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mIMEINo}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mRTC">
                            <th mat-header-cell *matHeaderCellDef> RTC </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mRTC}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mILA">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mILA}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef> Active Status </th>
                            <td mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="paginator">
                            <td mat-footer-cell *matFooterCellDef [colSpan]="meterColumnsToDisplay.length">
                              <mat-paginator #meterpaginator [pageSize]="meterlimit" [pageSizeOptions]="meterpageLimit"
                                [length]="meterTotalRecord" (page)="changeMeterPage($event)"></mat-paginator>
                            </td>
                          </ng-container>
                          <!-- Group1 Column -->
                          <ng-container matColumnDef="group1">
                            <td mat-header-cell *matHeaderCellDef [colSpan]="meterColumnsToDisplay.length">
                              <mat-card-header class="h-16">
                                <div class="title hidden-sm " col-3>
                                  DCU's Meters List
                                </div>
                                <div class="bg-card rounded-full border px-4" col-6>
                                  <mat-form-field class="dcuFilter" floatLabel="never">
                                    <span matPrefix>
                                      <mat-icon matSuffix>search</mat-icon>
                                    </span>
                                    <input type="text" matInput placeholder="search meter" value="{{dcuMeterSearch}}" (input)="onDcuMeterSearchChange($event.target.value)">
                                  </mat-form-field>
                                </div>
                                <span class="toolbar-space"></span>
                                <div col-3 col-xs-6>
                                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base
                                    mat-primary style="color: #000;">
                                    <mat-icon>filter_list</mat-icon>
                                  </button>
                                  <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                    <mat-list role="list" *ngFor="let item of meterHeadsCols">
                                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                                        <mat-checkbox [checked]="item.checked"
                                          (change)="updateMeterChange($event, i, item)">{{item.label}}</mat-checkbox>
                                      </mat-list-item>
                                    </mat-list>
                                  </mat-menu>
                                  <button type="button" mat-mini-fab class="cPbg" (click)="addDcuMeter()">
                                    <mat-icon>add</mat-icon>
                                  </button>
                                </div>
                              </mat-card-header>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="['group1'];sticky: true"></tr>
                          <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                          <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                          </tr>
                          <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
                        </table>
                        <!-- <mat-toolbar>
                          <mat-toolbar-row>
                            <span class="example-spacer"></span>
                            <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                          </mat-toolbar-row>
                        </mat-toolbar> -->
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tableHeader"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [class.make-inActive]="row.dcuActiveStatus != 'Y'" class="tableColumns example-element-row"
                    [class.example-expanded-row]="expandedElement === element"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </mat-card-content>
              <mat-card-footer>
                <mat-paginator #paginator [pageSize]="limit" [pageSizeOptions]="pageLimit" [length]="dcuTotalRecord"
                  (page)="changePage($event)"></mat-paginator>
                <!-- <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
              </mat-card-footer>
            </mat-card>
          </div>
        </ng-container>
        <ng-container *ngIf="isDataRedyForTableDcu && tabToShow == 1">
          <div class="col-12 plr_c25px xs-no-p">
            <mat-card class="-mt-20">
              <mat-card-header class="h-16 xs-c-h">
                  <div class="title hidden-sm xs-dn" col-3>
                      All Meter List
                  </div>
                  <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-6>
                    <mat-form-field class="dcuFilter" floatLabel="never">
                      <span matPrefix>
                        <mat-icon matSuffix>search</mat-icon>
                      </span>
                      <input type="text" matInput placeholder="search">
                    </mat-form-field>
                  </div>
                  <span class="toolbar-space"></span>
                  <div col-3 col-xs-6>
                    <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base
                      mat-primary style="color: #000;">
                      <mat-icon>filter_list</mat-icon>
                    </button>
                    <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                      <mat-list role="list" *ngFor="let item of meterHeadsCols">
                        <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                          <mat-checkbox [checked]="item.checked"
                            (change)="updateMeterChange($event, i, item)">{{item.label}}</mat-checkbox>
                        </mat-list-item>
                      </mat-list>
                    </mat-menu>
                    <button type="button" mat-mini-fab class="cPbg" >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </mat-card-header>
              <mat-card-content>
                <table mat-table [dataSource]="dataAllMeterSource"
                          class="mat-elevation-z8" *ngIf="dataAllMeterSource.length > 0">

                          <!-- Checkbox Column -->
                          <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                              <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                                <mat-icon>more_horiz</mat-icon>
                              </button>
                              <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                <mat-list role="list">
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuMeterView(row)">
                                      <mat-icon>edit</mat-icon> Modify
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon class="d-c6">build</mat-icon> Configure Meter
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>redeem</mat-icon> Make Recharge
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>adjust</mat-icon> Make Adjustment
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>stay_current_portrait</mat-icon> Current Data Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>chrome_reader_mode</mat-icon> Last Bill Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>bug_report</mat-icon> Meter Event Log Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> Dip Wise Load Survey Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> Day Wise Load Survey Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> SMS Sent Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> LPR Config Data Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> Meter Activity Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> Recharge Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>assignment</mat-icon> Consumption Report
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem">
                                    <button type="button" mat-button (click)="dcuRemoveView(row)">
                                      <mat-icon>delete</mat-icon> Delete
                                    </button>
                                  </mat-list-item>
                                </mat-list>
                              </mat-menu>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mID">
                            <th mat-header-cell *matHeaderCellDef> Meter ID </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mID}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mlprID">
                            <th mat-header-cell *matHeaderCellDef> LPR ID </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mlprID}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mSimNo">
                            <th mat-header-cell *matHeaderCellDef> Sim No </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mSimNo}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mIMEINo">
                            <th mat-header-cell *matHeaderCellDef> IMEI No </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mIMEINo}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mRTC">
                            <th mat-header-cell *matHeaderCellDef> RTC </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mRTC}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mILA">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              {{element.mILA}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef> Active Status </th>
                            <td mat-cell *matCellDef="let element" (click)="dcuMeterView(element)">
                              <fa name="circle" size="2x"
                                class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                          <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                          </tr>
                </table>
              </mat-card-content>
              <mat-card-footer>
                  <mat-paginator #meterpaginator [pageSize]="meterlimit" [pageSizeOptions]="meterpageLimit"
                  [length]="allmeterTotalRecord" ></mat-paginator>
                <!-- <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
              </mat-card-footer>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <form [formGroup]="addDcuForm" (ngSubmit)="onSubmitAddDcu()">
    <div class="modal-header">
      
      <mat-icon class="d-c6">post_add</mat-icon>
      <h4 class="modal-title" id="modal-basic-title">Add DCU</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-secondary" role="alert" *ngIf="addDcuMessage.length > 0">{{addDcuMessage}}</div>
      <div class="form-groupp">
        <!-- Grid row -->
        <div class="form-row">
          <!-- Default input -->
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Code" name="dcuCode" formControlName="dcuCode">
            <mat-hint>DCU Code</mat-hint>
            <mat-icon matPrefix class="d-c2">grade</mat-icon>
            <mat-error *ngIf="aD.dcuCode.hasError('required')">
              Code is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Name" name="dcuName" formControlName="dcuName">
            <mat-hint>Dcu Name</mat-hint>
            <mat-icon matPrefix class="d-c3">airplay</mat-icon>
            <mat-error *ngIf="aD.dcuName.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu GIS Loc" name="dcuGISLoc" formControlName="dcuGISLoc">
            <mat-hint>Dcu GIS Loc</mat-hint>
            <mat-icon matPrefix class="d-c4">album</mat-icon>
            <mat-error *ngIf="aD.dcuGISLoc.hasError('required')">
              dcuGISLoc is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Gprs Sim No" name="dcuGprsSimNo" formControlName="dcuGprsSimNo">
            <mat-hint>Dcu Gprs Sim No</mat-hint>
            <mat-icon matPrefix class="d-c5">art_track</mat-icon>
            <mat-error *ngIf="aD.dcuGprsSimNo.hasError('required')">
              dcuGprsSimNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Grps Sim EMI No" name="dcuGrpsSimEMINo" formControlName="dcuGrpsSimEMINo">
            <mat-hint>Dcu Grps Sim EMI No</mat-hint>
            <mat-icon matPrefix class="d-c6">stay_current_landscape</mat-icon>
            <mat-error *ngIf="aD.dcuGrpsSimEMINo.hasError('required')">
              dcuGrpsSimEMINo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Installed Loc" name="dcuInstalledLoc" formControlName="dcuInstalledLoc">
            <mat-hint>Dcu Installed Loc</mat-hint>
            <mat-icon matPrefix class="d-c7">rss_feed</mat-icon>
            <mat-error *ngIf="aD.dcuInstalledLoc.hasError('required')">
              dcuInstalledLoc is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Activation Status" name="dcuActivationStatus"
              formControlName="dcuActivationStatus">
            <mat-hint>Dcu Activation Status</mat-hint>
            <mat-icon matPrefix class="d-c8">access_time</mat-icon>
            <mat-error *ngIf="aD.dcuActivationStatus.hasError('required')">
              dcuActivationStatus is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Ip Address" name="dcuIpAddress" formControlName="dcuIpAddress">
            <mat-hint>Dcu Ip Address</mat-hint>
            <mat-icon matPrefix class="d-c9">signal_cellular_alt</mat-icon>
            <mat-error *ngIf="aD.dcuIpAddress.hasError('required')">
              dcuIpAddress is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Port No" name="dcuPortNo" formControlName="dcuPortNo">
            <mat-hint>Dcu Port No</mat-hint>
            <mat-icon matPrefix class="d-c10">library_books</mat-icon>
            <mat-error *ngIf="aD.dcuPortNo.hasError('required')">
              dcuPortNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Server Ip Address" name="dcuServerIpAddress"
              formControlName="dcuServerIpAddress">
            <mat-hint>Dcu Server Ip Address</mat-hint>
            <mat-icon matPrefix class="d-c11">trending_up</mat-icon>
            <mat-error *ngIf="aD.dcuServerIpAddress.hasError('required')">
              dcuServerIpAddress is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Server Port No" name="dcuServerPortNo" formControlName="dcuServerPortNo">
            <mat-hint>Dcu Server Port No</mat-hint>
            <mat-icon matPrefix class="d-c12">trending_up</mat-icon>
            <mat-error *ngIf="aD.dcuServerPortNo.hasError('required')">
              dcuServerPortNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Firmware Code Version" name="dcuFirmwareCodeVersion"
              formControlName="dcuFirmwareCodeVersion">
            <mat-hint>Dcu Firmware Code Version</mat-hint>
            <mat-icon matPrefix class="d-c13">waves</mat-icon>
            <mat-error *ngIf="aD.dcuFirmwareCodeVersion.hasError('required')">
              dcuFirmwareCodeVersion is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>
        <!-- Grid row -->
      </div>
    </div>
    <div class="modal-footer">
      

        <button type="button" (click)="modal.close('Ok click')" mat-button class="text-uppercase" class="cPbg">cancel</button>
      <button type="submit" [disabled]="dcuAddProcess" class="cPbg">
        Save
        <mat-icon *ngIf="dcuAddProcess">
          <mat-spinner diameter="20">
          </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</ng-template>

<ng-template #dcumodify let-modal>
  <form [formGroup]="editDcuForm" (ngSubmit)="onSubmitEditDcu()">
    <div class="modal-header">
      <mat-icon class="d-c6">edit</mat-icon>
      <h4 class="modal-title">Edit DCU</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-secondary" role="alert" *ngIf="editDcuMessage.length > 0">{{editDcuMessage}}</div>
      <div class="form-groupp">
        <!-- Grid row -->
        <div class="form-row">
          <!-- Default input -->
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Code" name="dcuCode" [value]="editDcuData.dcuCode"
              formControlName="dcuCode">
            <mat-hint>DCU Code</mat-hint>
            <mat-icon matPrefix class="d-c2">grade</mat-icon>
            <mat-error *ngIf="eD.dcuCode.hasError('required')">
              Code is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Name" name="dcuName" [value]="editDcuData.dcuName"
              formControlName="dcuName">
            <mat-hint>DCU Name</mat-hint>
            <mat-icon matPrefix class="d-c3">airplay</mat-icon>
            <mat-error *ngIf="eD.dcuName.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu GIS Loc" name="dcuGISLoc" [value]="editDcuData.gisLocation"
              formControlName="dcuGISLoc">
            <mat-hint>Dcu GIS Loc</mat-hint>
            <mat-icon matPrefix class="d-c4">album</mat-icon>
            <mat-error *ngIf="eD.dcuGISLoc.hasError('required')">
              dcuGISLoc is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Gprs Sim No" name="dcuGprsSimNo" [value]="editDcuData.dcuSIMNo"
              formControlName="dcuGprsSimNo">
            <mat-hint>Dcu Gprs Sim No</mat-hint>
            <mat-icon matPrefix class="d-c5">art_track</mat-icon>
            <mat-error *ngIf="eD.dcuGprsSimNo.hasError('required')">
              dcuGprsSimNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Grps Sim EMI No" name="dcuGrpsSimEMINo" [value]="editDcuData.dcuSIMEmiNo"
              formControlName="dcuGrpsSimEMINo">
            <mat-hint>Dcu Grps Sim EMI No</mat-hint>
            <mat-icon matPrefix class="d-c6">stay_current_landscape</mat-icon>
            <mat-error *ngIf="eD.dcuGrpsSimEMINo.hasError('required')">
              dcuGrpsSimEMINo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Installed Loc" [value]="editDcuData.dcuInstallAddress"
              name="dcuInstalledLoc" formControlName="dcuInstalledLoc">
            <mat-hint>Dcu Installed Loc</mat-hint>
            <mat-icon matPrefix class="d-c7">rss_feed</mat-icon>
            <mat-error *ngIf="eD.dcuInstalledLoc.hasError('required')">
              dcuInstalledLoc is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Activation Status" [value]="editDcuData.dcuActivationStatus"
              name="dcuActivationStatus" formControlName="dcuActivationStatus">
            <mat-hint>Dcu Activation Status</mat-hint>
            <mat-icon matPrefix class="d-c8">access_time</mat-icon>
            <mat-error *ngIf="eD.dcuActivationStatus.hasError('required')">
              dcuActivationStatus is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Ip Address" name="dcuIpAddress" [value]="editDcuData.dcuip"
              formControlName="dcuIpAddress">
            <mat-hint>Dcu Ip Address</mat-hint>
            <mat-icon matPrefix class="d-c9">signal_cellular_alt</mat-icon>
            <mat-error *ngIf="eD.dcuIpAddress.hasError('required')">
              dcuIpAddress is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Port No" name="dcuPortNo" [value]="editDcuData.dcuportno"
              formControlName="dcuPortNo">
            <mat-hint>Dcu Port No</mat-hint>
            <mat-icon matPrefix class="d-c10">library_books</mat-icon>
            <mat-error *ngIf="eD.dcuPortNo.hasError('required')">
              dcuPortNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Server Ip Address" [value]="editDcuData.dcuserverip"
              name="dcuServerIpAddress" formControlName="dcuServerIpAddress">
            <mat-hint>Dcu Server Ip Address</mat-hint>
            <mat-icon matPrefix class="d-c11">trending_up</mat-icon>
            <mat-error *ngIf="eD.dcuServerIpAddress.hasError('required')">
              dcuServerIpAddress is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Server Port No" [value]="editDcuData.dcuserverport" name="dcuServerPortNo"
              formControlName="dcuServerPortNo">
            <mat-hint>Dcu Server Port No</mat-hint>
            <mat-icon matPrefix class="d-c12">trending_up</mat-icon>
            <mat-error *ngIf="eD.dcuServerPortNo.hasError('required')">
              dcuServerPortNo is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="fill">
            <input matInput placeholder="Dcu Firmware Code Version" [value]="editDcuData.dcufmcodver"
              name="dcuFirmwareCodeVersion" formControlName="dcuFirmwareCodeVersion">
            <mat-hint>Dcu Firmware Code Version</mat-hint>
            <mat-icon matPrefix class="d-c13">waves</mat-icon>
            <mat-error *ngIf="eD.dcuFirmwareCodeVersion.hasError('required')">
              dcuFirmwareCodeVersion is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>
        <!-- Grid row -->
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="modal.close('Ok click')" class="btn btn-outline-dark" [disabled]="dcuUpdateProcess" class="cPbg">
        Cancel
      </button>
      <button type="submit" class="btn btn-outline-dark" [disabled]="dcuUpdateProcess" class="cPbg">
        Update
        <mat-icon *ngIf="dcuUpdateProcess">
          <mat-spinner diameter="20">
          </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</ng-template>