import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';

@Component({
  selector: 'app-authority-notifications',
  templateUrl: './authority-notifications.component.html',
  styleUrls: ['./authority-notifications.component.scss']
})
export class AuthorityNotificationsComponent implements OnInit {

  addForm: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  allnotifications = [
    {
      "Notification_Unique_Id": 0,
      "Account No": 0,
      "Consumer Name": null,
      "Notification": "no new notifications.",
      "Read Status": "N",
      "Revert On Notification": "N",
      "Updation Date Time": "2019-11-26 14:13:35",
      "Server Date Time": "2019-11-19 16:31:27"
    }
  ];
  skip = 0;
  limit = 10;
  totalAllNotifications = 0;
  isloadnotification:boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.getAllNotifications();
    //console.log("hello");
  }

   /**to get the meter list on click on Dcu */
   getAllNotifications() {
    let body = new URLSearchParams();
  
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    this.genReqs.postReq('/getAuthMessages', body).subscribe((result)=>{
      this.responseData = result;
      this.isloadnotification = true;
      if(this.responseData.success){
        setTimeout(() => {
          this.allnotifications = this.responseData.data;
          // if(this.totalAllNotifications === 0){
          //   this.totalAllNotifications = this.responseData.datalimit.totalResult;
          // }
        });
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

}
