<div class="container-fluid innerpage_top innerpage_top_graphic">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH">
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="text hidden-sm xs-dn col-md-8">
                  <a href="#/deshauth">
                    <span>Home</span>
                  </a> >
                  <a href="javascript:void(0)">
                    <span>{{bCumCountry}}</span>
                  </a>
                </div>
              </div>
              <div class="col-md-7 col-sm-12">
                <div class="flex_auth">
                  <div class="authsearch">
                    <div class="search">
                      <input class="search-txt searchplaceholder" type="text"
                        placeholder="Search by Username/Email/Mobile No" value="{{levelSearch}}"
                        (input)="onLevelSearchChange($event.target.value)" autocomplete="off" autocomplete="off">
                      <div class="search-btn nobg">
                        <i class="fa fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <span class="toolbar-space"></span>
                    <div>
                      <button type="button" mat-mini-fab (click)="open(addcountry)" class="editbordr_circlttop">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid inner-page-content">
              <!-- <mat-card-header class="h-16 xs-c-h">
                <div class="text hidden-sm xs-dn col-md-8">
                  <a href="#/deshauth" mat-icon>
                    <i class="icons d-c3">
                      home
                    </i>
                    <span>Home</span>
                  </a>->
                  <a href="javascript:void(0)">
                    <i class="icons d-c4">
                      flag
                    </i><span>{{bCumCountry}}</span>
                  </a>
                </div>
                <div class="bg-card rounded-full border col-md-2">
                  <mat-form-field class="dcuFilter" floatLabel="never">
                    <span matPrefix>
                      <mat-icon matSuffix class="d-c9">search</mat-icon>
                    </span>
                    <input type="text" matInput placeholder="search" value="{{levelSearch}}"
                      (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                  </mat-form-field>
                </div>
                <span class="toolbar-space"></span>
                <div class="col-md-2">

                  <button type="button" mat-mini-fab (click)="open(addcountry)">
                    <mat-icon>add</mat-icon>
                  </button>

                </div>
              </mat-card-header> -->
              <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
              <div class="row t-3 mb-3" *ngIf="allutilitys">
                <div class="col-md-2" *ngFor="let label of allutilitys; index as i;">
                  <div class="border_cstm">
                    <div>
                      <div class="spacer">
                        <div class="avatar-circlee">
                          <div class="circular">
                            <img src="{{label.img_address}}" *ngIf="label.img_address" />
                            <fa *ngIf="!label.img_address" name="folder" style="color:#84ccff;" mat-card-lg-image></fa>
                          </div>
                        </div>
                        <mat-icon mat-button class="pointer cardAction d-c2 ditbordr" class="editbordr"
                          [matMenuTriggerFor]="dcuListMenu">menu_open
                        </mat-icon>
                        <mat-menu #dcuListMenu="matMenu" xPosition="before" class="authorityListMenu 
                          editbordrr gridinnerpagee mat-menu-panellall">
                          <mat-list role="list" class="editbordrr">
                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="stateEditView(label, listmodify)"
                                class="btn-block text-left">
                                <mat-icon class="d-c13 authall">edit</mat-icon> Modify
                              </button>
                            </mat-list-item>

                            <mat-list-item role="listitem">
                              <button type="button" mat-button (click)="removelevel(label)" class="btn-block text-left">
                                <mat-icon class="d-c5">delete</mat-icon> Delete
                              </button>
                            </mat-list-item>
                          </mat-list>
                        </mat-menu>
                      </div>
                      <mat-card-footer (click)="goToStateView(label.state_id)">
                        <div style="WIDTH: 100%;"
                          class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;"
                          mat-button>
                          <div class="fullbutton">
                            <span>{{label.stateName}}</span>
                            <mat-icon class="icons">keyboard_arrow_right</mat-icon>
                          </div>
                        </div>
                      </mat-card-footer>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!allutilitys" col-sm-3>
                  {{messageUtilitys}}
                </ng-container>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- content viewport ends -->
      <ng-template #addcountry let-modal>
        <form [formGroup]="addStateForm" (ngSubmit)="onSubmitStateCountry()">
          <div class="modal-header subpage">
            <h4 class="modal-title" id="modal-basic-title">
              <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
              Add State
            </h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
            <div class="form-group">
              <!-- Grid row -->
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                      <input placeholder="State Code" name="stateCode" formControlName="stateCode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && aC.stateCode.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label>Country Code</label>
                      <div *ngIf="submitted && aC.stateCode.errors" class="invalid-feedback">
                        <div *ngIf="aC.stateCode.errors.required">
                          State Code is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>

                <div class="col-md-6">
                  <!-- Default input -->
                  <div class="form-group input-group">
                    <span class="has-float-label ">
                      <input placeholder="State Name" name="stateName" formControlName="stateName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && aS.countryName.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label> State Name</label>
                      <div *ngIf="submitted && aS.stateName.errors" class="invalid-feedback">
                        <div *ngIf="aS.stateName.errors.required">
                          State Name is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group input-group">
                <span class="has-float-label ">
                  <input type="file" name="stateImage" (change)="onFileSelect($event)" class=""
                    [ngClass]="{ 'is-invalid': submitted && aC.stateImage.errors }" required>
                  <div *ngIf="submitted && aS.stateImage.errors" class="invalid-feedback">
                    <div *ngIf="aC.stateImage.errors.required">
                      State image is required
                    </div>
                  </div>
                </span>
              </div>
              <!-- Grid row -->
              <div class="clearfix"></div>
              <div class="col-12 text-right">
                <button type="submit" class="dilog dilog1 dilog-button1">
                  <mat-icon matPrefix>add</mat-icon>Add
                </button>
                <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                  <mat-icon matPrefix>cancel</mat-icon>Cancel
                </button>

              </div>
            </div>
          </div>
        </form>
      </ng-template>

      <ng-template #listmodify let-modal>
        <form [formGroup]="editStateForm" (ngSubmit)="onSubmitEditStateCountry()">
          <div class="modal-header subpage">
            <h4 class="modal-title" id="modal-basic-title">
              <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
              Edit State: {{stateName}}
            </h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
            <div class="form-group">
              <!-- Grid row -->
              <div class="row">
                <!-- Default input -->
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                      <input placeholder="State Code" name="stateCode" formControlName="stateCode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && eS.stateCode.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label>State Code</label>
                      <div *ngIf="submitted && eS.stateCode.errors" class="invalid-feedback">
                        <div *ngIf="eS.stateCode.errors.required">
                          State Code is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                      <input matInput placeholder="State Name" name="stateName" formControlName="stateName"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && eS.countryName.errors }" required>
                      <i class="fa fa-globe icon_innertxtbox"></i>
                      <label>Country Name</label>
                      <div *ngIf="submitted && eS.countryName.errors" class="invalid-feedback">
                        <div *ngIf="eS.countryName.errors.required">
                          State Name is is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input type="file" name="stateImage" (change)="onFileSelectEdit($event)"
                    [ngClass]="{ 'is-invalid': submitted && eS.stateImage.errors }" required>
                  <div *ngIf="submitted && eS.stateImage.errors" class="invalid-feedback">
                    <div *ngIf="eS.stateImage.errors.required">
                      State image is required
                    </div>
                  </div>
                </span>
              </div>


              <!-- Grid row -->
              <div class="clearfix"></div>
              <div class="col-12 text-right">
                <button type="submit" class="dilog dilog1">
                  <mat-icon matPrefix>update</mat-icon>Update
                </button>
                <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                  <mat-icon matPrefix>cancel</mat-icon>Cancel
                </button>

              </div>
            </div>
          </div>
        </form>
      </ng-template>