import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dcuconfigserver',
  templateUrl: './dcuconfigserver.component.html',
  styleUrls: ['./dcuconfigserver.component.scss']
})
export class DcuconfigserverComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }
  ngOnInit() {
    this.addForm = this.formBuilder.group({
      serverIpAddress: ['', Validators.required],
      serverPortNo: ['', Validators.required]
    });
  }
  get cF() {return this.addForm.controls;}
  onSubmitAddForm() {
    //To Do #to be removed
  }
}
