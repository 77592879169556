
      <div class="modal-header">
        <mat-icon>build</mat-icon><h3>Configure DCU</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
        <ng-container>
          <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
            <mat-tab-group>
              <mat-tab> 
              <ng-template mat-tab-label>
                <mat-icon class="d-c5">build</mat-icon> 
                Configure DCU
              </ng-template>
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c3">
                        flash_on
                      </mat-icon>
                      Configure Server Details On DCU
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-dcuconfigserver></app-dcuconfigserver>
                </mat-expansion-panel>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c2">
                        chrome_reader_mode
                      </mat-icon>
                      Configure Meter Routers On DCU
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-dcuconfigmeterrouter></app-dcuconfigmeterrouter>
                </mat-expansion-panel>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c15">
                        exit_to_app
                      </mat-icon>
                      Configure Schedular On DCU
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-dcuconfigschedular></app-dcuconfigschedular>
                </mat-expansion-panel>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c14">
                        dns
                      </mat-icon>
                      Synchronise DCU RTC
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-dcuconfigrtc></app-dcuconfigrtc>
                </mat-expansion-panel>

                
                <div>
                </div>
              </mat-accordion>
              </mat-tab>
            </mat-tab-group>
            </div>
        </ng-container>
        <ng-container col-sm-3>
        </ng-container>
      </div>
  
<!-- content viewport ends -->