<!-- <mat-toolbar class="toolbar shadow-b sticky h-40">
  <span class="toolbar-space"></span>
  <div>
    <a href="#">
      <button>
        <mat-icon class="d-c3">notification_important</mat-icon>
      </button>
    </a>
  </div>
</mat-toolbar> -->
<nav class="topnav nav" id="loginHeader" (scroll)="onWindowScroll($event);">
  <div class="container">
    <div class="logo">
      <a class="smooth-scroll" routerLink="#carouselExampleIndicators">
        <img src="assets/img/avonmeters-logo.png" (click)="scrollTo('#carouselExampleIndicators', -80)"
          class="cstm_logo">
      </a>
    </div>
    <div id="mainListDiv" class="main_list">
      <ul class="navlinks">
        <li (click)="scrollTo('#about', -80)"><a class="smooth-scroll" routerLink="#about">About Us</a></li>
        <li (click)="scrollTo('#products', -80)"><a class="smooth-scroll" routerLink="#products">Products</a></li>
        <li (click)="scrollTo('#gallery', -80)"><a class="smooth-scroll" routerLink="#gallery">Gallery</a></li>
        <li (click)="scrollTo('#careers', -80)"><a class="smooth-scroll" routerLink="#careers">Careers</a></li>
        <li (click)="scrollTo('#contactus', -80)"><a class="smooth-scroll" routerLink="#contactus">Contact Us </a></li>
        <li><a (click)="openLoginModel()" onmousedown="party.confetti(this)"
            class="pointer-link">Login</a></li>
        <li class="no_border"><a href="https://play.google.com/store/apps/details?id=com.avonmeters.app&hl=en_IN"
            target="_blank"><img src="../../assets/img/googleplay.png" class="btnplaystor"></a></li>
        <li class="no_border"><a href="https://apps.apple.com/in/app/Avon-Meters/id1510122504" target="_blank"><img
              src="../../assets/img/iosbtn.png" class="btnplaystor"></a></li>
      </ul>
    </div>
    <span class="navTrigger">
      <i></i>
      <i></i>
      <i></i>
    </span>
  </div>
</nav>