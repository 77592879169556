
      <div class="modal-header">
        <mat-icon>build</mat-icon><h3>Configure Meter</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
        <ng-container>
                <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <mat-tab-group>
                  <mat-tab> 
                  <ng-template mat-tab-label>
                    <mat-icon class="d-c5">build</mat-icon> 
                    Configure Meter
                  </ng-template>
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c3">
                            flash_on
                          </mat-icon>
                          Configure Load Limit On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigloadlimit></app-meterconfigloadlimit>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c2">
                            chrome_reader_mode
                          </mat-icon>
                          Configure Billing Tariff On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigbilltariff></app-meterconfigbilltariff>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c15">
                            exit_to_app
                          </mat-icon>
                          Configure Push Data
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigpushdata></app-meterconfigpushdata>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c14">
                            dns
                          </mat-icon>
                          Configure Server Details On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigserverdetails></app-meterconfigserverdetails>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c13">
                            video_label
                          </mat-icon>
                          Configure Display Parameter On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigdisplayparam></app-meterconfigdisplayparam>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c12">
                            add_box
                          </mat-icon>
                          Configure Happy hours On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfighappyhours></app-meterconfighappyhours>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c11">
                            credit_card
                          </mat-icon>
                         Configure Emergency Credit
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigcreditemerg></app-meterconfigcreditemerg>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c10">
                            bug_report
                          </mat-icon>
                         Configure Volt/Current Thrasholds
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigcurrentthrashold></app-meterconfigcurrentthrashold>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c9">
                            settings_input_component
                          </mat-icon>
                         Configure Meter Phase On Grid Supply
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigphasegsupply></app-meterconfigphasegsupply>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c8">
                            settings_input_component
                          </mat-icon>
                         Configure Meter Phase On Backup Supply
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigphasebkpsupply></app-meterconfigphasebkpsupply>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c7">
                            build
                          </mat-icon>
                         Configure DCU Gateway Id On Meter
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigdcugateid></app-meterconfigdcugateid>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="d-c6">
                            alarm
                          </mat-icon>
                         Set Meter Alarm Level/Emergeny Credit /Billing Teriff
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-meterconfigaeb></app-meterconfigaeb>
                    </mat-expansion-panel>

                    <div>
                    </div>
                  </mat-accordion>
                  </mat-tab>
                </mat-tab-group>
                </div>
        </ng-container>
        <ng-container col-sm-3>
        </ng-container>
      </div>
  
<!-- content viewport ends -->