import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editgroup',
  templateUrl: './editgroup.component.html',
  styleUrls: ['./editgroup.component.scss']
})
export class EditgroupComponent implements OnInit {
  faSpinner = faSpinner;
  groupData: any = {};
  towerData: any = {};
  dgData: any = {};
  groupUid:string = '0';
  groupName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';

  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
  submitted: boolean = false;

  
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    //this.getDgData() ;
    //this.getTowerData() ;
    this.addForm = this.formBuilder.group({
        dgNameData: [{value: '', disabled: true}, Validators.required],
        towerNameData: [{value: '', disabled: true}, Validators.required],
        groupName: ['', Validators.required],
      });
      this.groupUid = this.groupData.groupId;
      this.groupName = this.groupData.groupName;
      this.aF.groupName.setValue(this.groupData.groupName);
      this.aF.towerNameData.setValue(this.groupData.towerName);
      this.aF.dgNameData.setValue(this.groupData.dgName);
     
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.groupData.subDivId);
      body.set('dgId', this.groupData.dgUId);
      body.set('towerId', this.groupData.towerUId);
      body.set('groupId', this.groupData.groupId);
      body.set('groupName', this.aF.groupName.value);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/updateGroup', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    getTowerData() {
      let body = new URLSearchParams();
      body.set('subdivId',  this.groupData.subDivId);
      body.set('towerId', '0');
      body.set('dgId', this.groupData.dgUId);
      body.set('offSet', '0');
      body.set('limit', '1000');
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getTowerData', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          setTimeout(() => {
              this.towerData = this.responseData.data;
         
          });
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    getDgData() {
      let body = new URLSearchParams();
      body.set('subdivId',  this.groupData.subDivId);
      body.set('dgId', '0');
      body.set('offSet', '0');
      body.set('limit', '100');
      body.set('filter', '');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getDGData', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          setTimeout(() => {
              this.dgData = this.responseData.data;
          });
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    onDGChange() {
      this.getTowerData();
    }
}
