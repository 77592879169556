<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
       Edit Meter Command
    </h4>
  </div>
  <div class="modal-body">
      <!--<div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0" >{{responseMessage}}</div>-->
      <div class="form-groupp">
          <!-- Grid row -->
          <div class="form-row">
            <!-- Default input 
            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Sub Division" name="meterSubDivision" formControlName="meterSubDivision" >
              <mat-icon matPrefix class="d-c3">account_circle</mat-icon>
              <mat-error *ngIf="aF.meterSubDivision.hasError('required')">
                Meter Sub Division is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                    <input placeholder="Command Code" name="commandCode" formControlName="commandCode"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.commandCode.errors}" required>
                     <i class="fa fa-database icon_innertxtbox"></i>
                      <label>Command Code</label>
                      <div *ngIf="submitted && aF.commandCode.errors" class="invalid-feedback">
                        <div *ngIf="aF.commandCode.errors.required">
                          Command Code is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group input-group">
                    <span class="has-float-label">
                    <input matInput placeholder="Command Name" name="commandName" formControlName="commandName"
                     class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.commandName.errors}" required>
                     <i class="fa fa-user icon_innertxtbox"></i>
                      <label>Command Name</label>
                      <div *ngIf="submitted && aF.commandName.errors" class="invalid-feedback">
                        <div *ngIf="aF.commandName.errors.required">
                          Command Name is required
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
            </div>
          
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>edit</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>
      </div>
    </div>
  </form>