import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loadlimiter',
  templateUrl: './loadlimiter.component.html',
  styleUrls: ['./loadlimiter.component.scss']
})
export class LoadlimiterComponent implements OnInit {
  faSpinner = faSpinner;
  isLoading=false;
  addFormData: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  message:any;
  password:any;
  AddProcess:boolean = false;
  date :any;
  thumb:boolean = false;
  
  gridLoadlimit:any;
  dgLoadlimit:any;
  startTime:any;
  endTime:any;
  submitted: boolean = false;
  
  
  //startTime:any;
  //endTime:any;
  loadLimiterData:any = {
    "consAccNo": "",
    "meterId": "",
    "gridLLEB": "",
    "gridLLDG": "",
    "gridLoadlimit": "",
    "dgLoadlimit": "",
    "startTime": "",
    "endTime": "",
   //'rdatetime': "2019-11-13 12:23:13"
  }

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }
  
  ngOnInit() {
    
    this.addFormData = this.formBuilder.group({
      password: ['', Validators.required],
      //gridLoadlimit: ['', Validators.required],
      //dgLoadlimit: ['', Validators.required],
      gridLoadlimit: ['', [Validators.required, Validators.min(0.05), Validators.max(15.00)]],
      dgLoadlimit: ['', [Validators.required, Validators.min(0.05), Validators.max(15.00)]],
           
      startTime: ['', Validators.required],
      endTime: [, Validators.required],
     });
      this.getLoadLimiterValues();
     // console.log(this.date);
  }

  get aF() { return this.addFormData.controls; }
  
  updateLoadLimit(){
    if (this.addFormData.invalid) {
        this.submitted = true;
      return;
    }
    
    let body = new URLSearchParams();
    
    body.set('password', this.aF.password.value);
    body.set('gridLoadlimit', this.aF.gridLoadlimit.value);
    body.set('dgLoadlimit', this.aF.dgLoadlimit.value);
    body.set('startTime', this.aF.startTime.value);
    body.set('endTime', this.aF.endTime.value);
    
    body.set('authToken', this.currentUser.authToken);
    this.AddProcess = true;
    this.isLoading=true;
    this.responseMessage='';
   this.genReqs.postReq('/setLoadLimiterData', body).subscribe((result)=>{
     this.responseData = result;
     this.AddProcess = false;
     this.isLoading=false;
     this.responseMessage = this.responseData.data.message;
     this.thumb=true;
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.AddProcess = false;
     this.responseMessage = 'Unable to process yours request!';
     this.thumb=false;
   });
   }

 


   getLoadLimiterValues() {
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLoadLimiterData', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
      //this.addFormData.patchValue(this.responseData.data);
     
      this.loadLimiterData = this.responseData.data;
     // console.log("-->",this.loadLimiterData.gridLoadlimit,this.loadLimiterData.dgLoadlimit,this.loadLimiterData.startTime,this.loadLimiterData.endTime);
      this.aF.gridLoadlimit.setValue(this.loadLimiterData.gridLoadlimit);
      this.aF.dgLoadlimit.setValue(this.loadLimiterData.dgLoadlimit);
      this.aF.startTime.setValue(this.loadLimiterData.startTime);
      this.aF.endTime.setValue(this.loadLimiterData.endTime);
     
      //console.log("-->",this.loadLimiterData);
     }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.message = 'No Record Found';
    });
  }

   convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
