import { Component, OnInit, EventEmitter, ChangeDetectionStrategy, ɵConsole, ChangeDetectorRef, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { FileQueueObject, FileUploaderService } from '../file-uploader.service';

@Component({
  selector: 'app-addmetersupplypayment',
  templateUrl: './addmetersupplypayment.component.html',
  styleUrls: ['./addmetersupplypayment.component.scss']
})

export class AddmetersupplypaymentComponent {

  // public dataGet: FileQueueObject
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  sampleStatusData: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  avonChecked=true;
  holleyChecked=false;
  
  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authority: any;
  tenderSubmissionDate = "0000-00-00";
  tenderTestingDate = "0000-00-00";

  public tenderTypeData: any = [];
  stagesData: any = [];
  public countryLevelData: any = [];
  public stateLevelData: any = [];
  public utilityLevelData: any = [];
  public circleLevelData: any = [];
  public divisionLevelData: any = [];
  public subdivisionLevelData: any = [];
  public dcuLevelData: any = [];
  messageNew: string = '';
  authLevelFieldValue: string = '';

  isLoading: boolean = false;
  thumb: boolean = false;
  ///multiselect

  authLevelField: boolean = false;
  nowDate = new Date();
  submitted: boolean = false;
  myFiles: any[] = [];
  mappedFiles: any[] = [];
  newFiles: any[] = [];
  sMsg: string = '';

  files: any[] = [];
  check1=true;
  check2=false;

  @Output() onCompleteItem = new EventEmitter();

  @ViewChild('fileInput') fileInput;

  countryId = 1;
  stateId = 0;
  utilityId = 0;
  circleId = 0;
  divisionId = 0;
  subDivId = 0;


  countryData = [
    { id: 1, text: 'India' }
  ];
  stateData = [
    { id: 1, text: 'Haryana' },
    { id: 2, text: 'Utter Pardesh' },
    { id: 3, text: 'Punjab' }
  ];
  utiltiyData = [
    { id: 0, text: 'UHBVNL' },
    { id: 1, text: 'DHBVNL' },
    { id: 2, text: 'AVVNL' }
  ];
  circleData = [
    { id: 0, text: 'Panchkula' },
    { id: 1, text: 'Ambala1' },
    { id: 2, text: 'Yamunanagar' }
  ];
  divisionData = [
    { id: 0, text: 'Pinjore' },
    { id: 1, text: 'Barwala' },
    { id: 2, text: 'Farukhnagar' }
  ];
  subdivisionData = [
    { id: 0, text: 'ModelTown' },
    { id: 1, text: 'Thaper' },
    { id: 2, text: 'PANDWALA' }
  ];
  paymentTypeData: any = [];

  queue: Observable<FileQueueObject[]>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    private ref: ChangeDetectorRef,
    public uploader: FileUploaderService
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    
    ref.detach();
    setInterval(() => { this.ref.detectChanges(); }, 300);
  }

  ngOnInit() {
    /*
      form authority add
    */
    this.uploader.clearQueue();
    
    this.addAuthorityForm = this.formBuilder.group({
      meterType: ['', Validators.required],
      paymentType: ['', Validators.required],
      remark: [''],
      selectedFilesNew: ['', Validators.required]
    });

    //this.countryLevelData = this.getDynamicList();
    this.queue = this.uploader.queue;
    this.uploader.onCompleteItem = this.completeItem;
    this.getPaymentType();
  }

  getPaymentType() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPaymentType', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.paymentTypeData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }



  completeItem = (item: FileQueueObject, response: any) => {
    this.onCompleteItem.emit({ item, response });
  }

  clearFile() {
    //alert("1"+this.aD.selectedFiles.value);
    this.files = [];
    this.aD.selectedFilesNew.reset();
    //alert("2"+this.aD.selectedFiles.value);
  }

  onFileSelectChweck(event) {
    if (event.target.files.length == 0) {
      this.uploader.clearQueue();
    } else {
      this.uploader.clearQueue();
      //let fileBrowser = this.fileInput.clearQueue;
      let fileBrowser = this.fileInput.nativeElement;
      this.uploader.addToQueue(fileBrowser.files);
      //const file = event.target.files;
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        if(file.type=="application/vnd.ms-excel"){
          this.files.push(file);
        }else{
          alert("File Type Not Allowed");
        }
      }
      
      return true;
    }
  }

  onFileSelect(event) {
    if (event.target.files.length == 0) {
      this.uploader.clearQueue();
    } else {
      this.uploader.clearQueue();
      //let fileBrowser = this.fileInput.clearQueue;
      let fileBrowser = this.fileInput.nativeElement;
      this.uploader.addToQueue(fileBrowser.files);
      //const file = event.target.files;
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.files.push(file);

      }
      //let file = event.target.files;
      //this.aD.selectedFiles.setValue(file);
      //console.log(this.files)
      return true;
    }
  }


  get aD() { return this.addAuthorityForm.controls; }

  onSubmitAddAuthority() {
    //alert(this.addAuthorityForm.controls.value);
    if (this.addAuthorityForm.invalid) {
      this.submitted = true;
      return;
    }
    this.messageNew = '';
    this.isLoading = true;
    this.AddProcess = true;
    const formData = new FormData();
    formData.append('meterType', this.aD.meterType.value);
    formData.append('paymentType', this.aD.paymentType.value);
    formData.append('remark', this.aD.remark.value);
    this.files.forEach(file => {
      formData.append('uploads[]', file);
    })
    formData.append('authToken', this.currentUser.authToken);
   
    this.AddProcess = true;
    this.genReqs.formDataPost('/addIntMeters', formData).subscribe((result) => {
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      this.thumb = false;
      if (this.responseData.success) {
        this.AddProcess = false;
        this.isLoading = false;
        this.thumb = true;
        this.messageNew = this.responseData.data.message;
        if (this.messageNew.includes("Already") || this.messageNew.includes("Not")) {
          this.thumb = false;
        } else {
          setTimeout(() => {
            this.modal.close('done');
          },
            3000);
        }
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.AddProcess = false;
      this.messageNew = 'Unable to process yours request!';
    });
  }


  tenderSubDate(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up
      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.tenderSubmissionDate = eyear + '-' + emonth + '-' + eday + ' 00:00:00';
    }
  }

  tenderTestDate(pickedDate = new Date()) {
    if (pickedDate) {
      let emonth = pickedDate.getMonth() + 1; //always move 1 value up

      let eday = pickedDate.getDate();
      let eyear = pickedDate.getFullYear();
      this.tenderTestingDate = eyear + '-' + emonth + '-' + eday + ' 00:00:00';
    }
  }
}
