
<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Add Authority Level
    </h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group input-group">
          <span class="has-float-label">
              <input  placeholder="Level Name" name="levelName" formControlName="levelName" 
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.levelName.errors}" required>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Sub-Division Name</label>
              <div *ngIf="submitted && aF.levelName.errors" class="invalid-feedback">
                  <div *ngIf="aF.levelName.errors.required">
                    Level Name is required 
                  </div>
              </div>
          </span>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <ng-container *ngIf="isLoading">
          <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
        </ng-container>
          <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
            </div>
          </div>
      </div>
      <div class="col-sm-12 text-right">
        <button type="submit" class="dilog dilog1">
          <mat-icon>add</mat-icon>
          Submit
        </button>
        <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
          <mat-icon >cancel</mat-icon>
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>