import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';
import { IfStmt } from '@angular/compiler';
@Component({
  selector: 'app-meterconfigloadlimit-three',
  templateUrl: './meterconfigloadlimit-three.component.html',
  styleUrls: ['./meterconfigloadlimit-three.component.scss']
})
export class MeterconfigloadlimitThreeComponent implements OnInit {
  faSpinner = faSpinner;
  configMeter: UntypedFormGroup;
  subDivisionId: any = 0;
  currentUserSubscription: Subscription;
  currentUser: User;
  responseMessage: string = '';
  responseData: any;
  cmdformData: any = 0;
  submitted: boolean = false;
  tagValue = 'col-sm-8 text-left';
  billingModeGrid: any = [
      {'id':'W', 'Name':'kWh'},
      {'id':'A', 'Name':'kVAh'}
  ];

  billingModeDG: any = [
      {'id':'W', 'Name':'kWh'},
      {'id':'A', 'Name':'kVAh'}
  ];
  loadResCount: any = 
      [
          {'id':1},
          {'id':2},
          {'id':3},
          {'id':4},
          {'id':5},
          {'id':6},
          {'id':7},
          {'id':8},
          {'id':9},
          {'id':10},
  ];

  smOnHour: any = 
      [
          {'id':'Disable'}, {'id':0},  {'id':1}, {'id':2}, {'id':3},{'id':4},{'id':5},{'id':6},{'id':7},{'id':8},{'id':9},{'id':10},
          {'id':11}, {'id':12}, {'id':13},{'id':14},{'id':15},{'id':16},{'id':17},{'id':18},{'id':19},{'id':20},
          {'id':21}, {'id':22}, {'id':23}
  ];

  smOnMinute: any = 
  [
      {'id':'Disable'},{'id':0},  {'id':1}, {'id':2}, {'id':3},{'id':4},{'id':5},{'id':6},{'id':7},{'id':8},{'id':9},{'id':10},
      {'id':11}, {'id':12}, {'id':13},{'id':14},{'id':15},{'id':16},{'id':17},{'id':18},{'id':19},{'id':20},
      {'id':21}, {'id':22}, {'id':23},{'id':24},{'id':25},{'id':26},{'id':27},{'id':28},{'id':29},{'id':30},
      {'id':31}, {'id':32}, {'id':33},{'id':34},{'id':35},{'id':36},{'id':37},{'id':38},{'id':39},{'id':40},
      {'id':41}, {'id':42}, {'id':43},{'id':44},{'id':45},{'id':46},{'id':47},{'id':48},{'id':49},{'id':50},
      {'id':51}, {'id':52}, {'id':53},{'id':54},{'id':55},{'id':56},{'id':57},{'id':58},{'id':59}
  ];
  
  dayFreq = [
      { name: 'Hourly' },
      { name: 'Daily' },
      { name: 'weekly' },
      { name: 'Monthly' },
  ];
  @Input() meterDetail: any = {
      cACNO: 0,
      muID: 0,
      dCUID: 0,
      sdID: 0,
      meterType: 0
  };

  myDate = new Date();
  cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');
  message: string = '';
  isLoading = false;
  AddProcess: boolean = false;
  thumb: boolean = false;
  meterTypeCheck: boolean = false;
  startDate = '';
  getThrough: Access;
  constructor(
      private formBuilder: UntypedFormBuilder,
      private modalService: NgbModal,
      public modal: NgbActiveModal,
      private genReqs: generalRequestsService,
      private authenticationService: AuthenticationService,
    
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
      this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
      //console.log("Cons Data",this.meterDetail.meterType);
      
          this.meterTypeCheck=true;
          if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
          this.configMeter = this.formBuilder.group({
              schedularStartEndDate: [this.myDate, Validators.required],
              bMFEB: ['', Validators.required],
              bMFDG: ['', Validators.required],
              gslu: ['', [Validators.required, Validators.min(0.05), Validators.max(65.00)]],
              bslu: ['', [Validators.required, Validators.min(0.05), Validators.max(65.00)]],
              eblusm: ['0', [ Validators.min(0.05), Validators.max(65.00)]],
              dglsm: ['0', [ Validators.min(0.05), Validators.max(65.00)]],
              lrdgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
              lrcgs: ['0', Validators.required],
              lrddgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
              lrcdgs: ['0', Validators.required],
              lrdsmgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
              lrcsmgs: ['0', Validators.required],
              lrdsmdgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
              lrcsmdgs: ['0', Validators.required],
              ssmonh: ['25'],
              ssmonm: ['25'],
              ssmtoffh: ['25'],
              ssmtoffm: ['25'],
              ovthrasholds: ['', [Validators.required, Validators.min(245), Validators.max(440)]],
              lvthrasholds: ['', [Validators.required, Validators.min(144), Validators.max(220)]],
              octhrasholds: ['', [Validators.required, Validators.min(1), Validators.max(80)]],
          });
            this.cF.lrdgs.setValue(30);
            this.cF.lrddgs.setValue(30);
            this.cF.lrdsmgs.setValue(30);
            this.cF.lrdsmdgs.setValue(30);

            this.cF.ssmonh.setValue('Disable');
            this.cF.ssmonm.setValue('Disable');
            this.cF.ssmtoffh.setValue('Disable');
            this.cF.ssmtoffm.setValue('Disable');
        }else{
            this.configMeter = this.formBuilder.group({
                schedularStartEndDate: [this.myDate, Validators.required],
                bMFEB: ['', Validators.required],
                //bMFDG: ['', Validators.required],
                gslu: ['', [Validators.required, Validators.min(0.05), Validators.max(65.00)]],
                //bslu: ['', [Validators.required, Validators.min(0.05), Validators.max(65.00)]],
                //eblusm: ['0', [ Validators.min(0.05), Validators.max(65.00)]],
                //dglsm: ['0', [ Validators.min(0.05), Validators.max(65.00)]],
                lrdgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
                lrcgs: ['0', Validators.required],
                //lrddgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
                //lrcdgs: ['0', Validators.required],
                //lrdsmgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
                //lrcsmgs: ['0', Validators.required],
                //lrdsmdgs: ['30', [Validators.required, Validators.min(30), Validators.max(65536)]],
                //lrcsmdgs: ['0', Validators.required],
                //ssmonh: ['25'],
                //ssmonm: ['25'],
                //ssmtoffh: ['25'],
                //ssmtoffm: ['25'],
                ovthrasholds: ['', [Validators.required, Validators.min(245), Validators.max(440)]],
                lvthrasholds: ['', [Validators.required, Validators.min(144), Validators.max(220)]],
                octhrasholds: ['', [Validators.required, Validators.min(1), Validators.max(80)]],
            });
        }
      
      // console.log("currentUser",this.currentUser);
      // console.log("meterDetail", this.meterDetail);

      if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
          this.getCurrentMeterConfig();
          this.cmdformData=1;
          this.tagValue = 'col-sm-10 text-left';
      }

        
  }

  get cF() { return this.configMeter.controls; }

  onSubmitConfigMeter() {
      
      if (this.configMeter.invalid) {
        this.submitted = true;
          return;
      }
      this.message = '';
      this.AddProcess = true;
      this.isLoading = true;

      const formData = new FormData();
      formData.append('authLevel', this.currentUser.data.userData.authLevel);
      formData.append('consId', this.meterDetail.cACNO);
      formData.append('meterId', this.meterDetail.muID);

      if(this.meterDetail.sdID==''){
          formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
      }else{
          formData.append('configSubDivisionId', this.meterDetail.sdID);
      }
      //formData.append('configSubDivisionId', this.meterDetail.sdID);

      if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
        formData.append('bMFDG', this.cF.bMFDG.value);
        formData.append('bslu', this.cF.bslu.value);
        formData.append('dglsm', this.cF.dglsm.value);
        formData.append('eblusm', this.cF.eblusm.value);
        formData.append('lrddgs', this.cF.lrddgs.value);
        formData.append('lrcdgs', this.cF.lrcdgs.value);
        formData.append('lrdsmgs', this.cF.lrdsmgs.value);
        formData.append('lrcsmgs', this.cF.lrcsmgs.value);
        formData.append('lrdsmdgs', this.cF.lrdsmdgs.value);
        formData.append('lrcsmdgs', this.cF.lrcsmdgs.value);
        if(this.cF.ssmonh.value=='Disable' || 
            this.cF.ssmonm.value=='Disable'|| 
            this.cF.ssmtoffh.value=='Disable'|| 
            this.cF.ssmtoffm.value=='Disable'){
                formData.append('ssmonh', '25');
                formData.append('ssmonm', '00');
                formData.append('ssmtoffh', '25');
                formData.append('ssmtoffm', '00');
            }else{
                formData.append('ssmonh', this.cF.ssmonh.value);
                formData.append('ssmonm', this.cF.ssmonm.value);
                formData.append('ssmtoffh', this.cF.ssmtoffh.value);
                formData.append('ssmtoffm', this.cF.ssmtoffm.value);
            }
            
    }else{
        formData.append('bMFDG','');
        formData.append('bslu', '');
        formData.append('dglsm', '');
        formData.append('eblusm','');
        formData.append('lrddgs', '');
        formData.append('lrcdgs', '');
        formData.append('lrdsmgs', '');
        formData.append('lrcsmgs', '');
        formData.append('lrdsmdgs', '');
        formData.append('lrcsmdgs', '');
        formData.append('ssmonh', '25');
        formData.append('ssmonm', '00');
        formData.append('ssmtoffh', '25');
        formData.append('ssmtoffm', '00');
    }
      formData.append('dcuId', this.meterDetail.dCUID);
      formData.append('meterType', this.meterDetail.meterType);
      formData.append('bMFEB', this.cF.bMFEB.value);
      formData.append('gslu', this.cF.gslu.value);
      formData.append('lrdgs', this.cF.lrdgs.value);
      formData.append('lrcgs', this.cF.lrcgs.value);
      
      

      formData.append('ovthrasholds', this.cF.ovthrasholds.value);
      formData.append('lvthrasholds', this.cF.lvthrasholds.value);
      formData.append('octhrasholds', this.cF.octhrasholds.value);
      formData.append('schedularStartTime', this.startDate);
      formData.append('cmdformData',this.cmdformData);
      formData.append('configMeter','3');
      formData.append('configurationOn', '0');
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/configLoadLimitMeter', formData).subscribe((result) => {
          this.responseData = result;
          this.AddProcess = false;
          this.isLoading = false;
          if (this.responseData.success) {
              this.thumb = true;
              this.message = this.responseData.data.message;
              if(this.message.includes("Unable") || this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              //this.modal.close('done');
          }
      },
          (err) => {
              if (err.status === 401) {
                  this.authenticationService.logout();
              }
              this.thumb = false;
              this.message = 'Unable to process yours request!';
          }
      );
  }

  onSubmitConfigPendingMeter() {
   
    this.message = '';
    this.AddProcess = true;
    this.isLoading = true;

    const formData = new FormData();
    formData.append('authLevel', this.currentUser.data.userData.authLevel);
    formData.append('consId', this.meterDetail.cACNO);
    formData.append('meterId', this.meterDetail.muID);

    if(this.meterDetail.sdID==''){
        formData.append('configSubDivisionId', this.currentUser.data.userData.subdivision);
    }else{
        formData.append('configSubDivisionId', this.meterDetail.sdID);
    }
    //formData.append('configSubDivisionId', this.meterDetail.sdID);

    if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
      formData.append('bMFDG', this.cF.bMFDG.value);
      formData.append('bslu', this.cF.bslu.value);
      formData.append('dglsm', this.cF.dglsm.value);
      formData.append('eblusm', this.cF.eblusm.value);
      formData.append('lrddgs', this.cF.lrddgs.value);
      formData.append('lrcdgs', this.cF.lrcdgs.value);
      formData.append('lrdsmgs', this.cF.lrdsmgs.value);
      formData.append('lrcsmgs', this.cF.lrcsmgs.value);
      formData.append('lrdsmdgs', this.cF.lrdsmdgs.value);
      formData.append('lrcsmdgs', this.cF.lrcsmdgs.value);
      if(this.cF.ssmonh.value=='Disable' || 
          this.cF.ssmonm.value=='Disable'|| 
          this.cF.ssmtoffh.value=='Disable'|| 
          this.cF.ssmtoffm.value=='Disable'){
              formData.append('ssmonh', '25');
              formData.append('ssmonm', '00');
              formData.append('ssmtoffh', '25');
              formData.append('ssmtoffm', '00');
          }else{
              formData.append('ssmonh', this.cF.ssmonh.value);
              formData.append('ssmonm', this.cF.ssmonm.value);
              formData.append('ssmtoffh', this.cF.ssmtoffh.value);
              formData.append('ssmtoffm', this.cF.ssmtoffm.value);
          }
  }else{
      formData.append('bMFDG','');
      formData.append('bslu', '');
      formData.append('dglsm', '');
      formData.append('eblusm','');
      formData.append('lrddgs', '');
      formData.append('lrcdgs', '');
      formData.append('lrdsmgs', '');
      formData.append('lrcsmgs', '');
      formData.append('lrdsmdgs', '');
      formData.append('lrcsmdgs', '');
      formData.append('ssmonh', '25');
      formData.append('ssmonm', '00');
      formData.append('ssmtoffh', '25');
      formData.append('ssmtoffm', '00');
  }
    formData.append('dcuId', this.meterDetail.dCUID);
    formData.append('meterType', this.meterDetail.meterType);
    formData.append('bMFEB', this.cF.bMFEB.value);
    formData.append('gslu', this.cF.gslu.value);
    formData.append('lrdgs', this.cF.lrdgs.value);
    formData.append('lrcgs', this.cF.lrcgs.value);
    
   

    formData.append('ovthrasholds', this.cF.ovthrasholds.value);
    formData.append('lvthrasholds', this.cF.lvthrasholds.value);
    formData.append('octhrasholds', this.cF.octhrasholds.value);
    formData.append('schedularStartTime', this.startDate);
    formData.append('cmdformData',this.cmdformData);
    formData.append('configMeter','3');
    formData.append('configurationOn', '1');
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/configLoadLimitMeter', formData).subscribe((result) => {
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.message = this.responseData.data.message;
            if(this.message.includes("Unable") 
            || this.message.includes("You are not authorize") 
            || this.message.includes("Communication Failure") 
            || this.message.includes("Unsuccessful")
            || this.message.includes("Unsuccessfully") 
            || this.message.includes("Under Progress")
            || this.message.includes("Queue")){
                this.thumb = false;
              }else{
                this.thumb = true;
              }
            //this.modal.close('done');
        }
    },
        (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.thumb = false;
            this.message = 'Unable to process yours request!';
        }
    );
}

  getDateFromRange(pickedDate = new Date()) {
      if (pickedDate) {
          let emonth = pickedDate.getMonth() + 1; //always move 1 value up
          let eday = pickedDate.getDate();
          let eyear = pickedDate.getFullYear();
          this.startDate = eyear + '-' + emonth + '-' + eday;
      }
  }

  getCurrentMeterConfig() {


      let body = new URLSearchParams();
      body.set('consId', this.meterDetail.cACNO);
      body.set('meterId', this.meterDetail.muID);
      body.set('subDivDatabase', this.meterDetail.subDivDatabase);
      body.set('dcuId', this.meterDetail.dCUID);
      body.set('meterType', this.meterDetail.meterType);
      body.set('authToken', this.currentUser.authToken);

      this.genReqs.postReq('/getLoadLimitParmFull', body).subscribe((result) => {
          this.responseData = result;
          if (this.responseData.success) {
             // console.log(this.responseData.data);
             if(this.getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View){
                this.cF.bMFDG.setValue(this.responseData.data.dGBILLINGMODE);
                this.cF.bslu.setValue(this.responseData.data.dGLoadLimit);
                this.cF.eblusm.setValue(this.responseData.data.gridLoadLimitServantMode);
                this.cF.dglsm.setValue(this.responseData.data.dGLoadLimitServantMode);
                this.cF.lrddgs.setValue(this.responseData.data.loadRestartDurationDG);
                this.cF.lrdsmgs.setValue(this.responseData.data.loadRestartDurationServantModeGrid);
                this.cF.lrdsmdgs.setValue(this.responseData.data.loadRestartDurationServantModeDG);
                this.cF.lrcdgs.setValue(this.responseData.data.loadRestartCounterDG);
                this.cF.lrcsmgs.setValue(this.responseData.data.loadRestartCounterServantModeGrid);
                this.cF.lrcsmdgs.setValue(this.responseData.data.loadRestartCounterServantModeDG);
                if(this.responseData.data.scheduleServantModeOnHour==''){
                    this.cF.ssmonh.setValue('Disable');
                   }else{
                    this.cF.ssmonh.setValue(this.responseData.data.scheduleServantModeOnHour);
                   }
                   
                   if(this.responseData.data.scheduleServantModeOnMinute==''){
                    this.cF.ssmonm.setValue('Disable');
                   }else{
                    this.cF.ssmonm.setValue(this.responseData.data.scheduleServantModeOnMinute);
                   }
    
                   if(this.responseData.data.scheduleServantModeOffHour==''){
                    this.cF.ssmtoffh.setValue('Disable');
                   }else{
                    this.cF.ssmtoffh.setValue(this.responseData.data.scheduleServantModeOffHour);
                   }
    
                   if(this.responseData.data.scheduleServantModeOffM==''){
                    this.cF.ssmtoffm.setValue('Disable');
                   }else{
                    this.cF.ssmtoffm.setValue(this.responseData.data.scheduleServantModeOffM);
                   }
                   
            }
            this.cF.bMFEB.setValue(this.responseData.data.gridBILLINGMODE);
            this.cF.gslu.setValue(this.responseData.data.gridLoadLimit);
            this.cF.lrdgs.setValue(this.responseData.data.loadRestartDurationGrid);
            this.cF.lrcgs.setValue(this.responseData.data.loadRestartCounterGrid);
            this.cF.ovthrasholds.setValue(this.responseData.data.overVoltageThrashold);
            this.cF.lvthrasholds.setValue(this.responseData.data.lowVoltageThrashold);
            this.cF.octhrasholds.setValue(this.responseData.data.overCurrentThrashold);
        
          } else {
              this.cF.ssmonh.setValue('Disable');
              this.cF.ssmonm.setValue('Disable');
              this.cF.ssmtoffh.setValue('Disable');
              this.cF.ssmtoffm.setValue('Disable');
             // console.log(this.responseData.message);//this.responseData.isresponse;
          }
      }, (err) => {
          if (err.status === 401) {
              this.authenticationService.logout();
          }
      });
  }
}