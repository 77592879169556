<div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
    Configure Meter: {{meterDetail.mID}}
  </h4>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <div class="container">
    <div class="col-md-12">
      <div class="navall_tabblue">
        <nav>
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Happy_Hours_On_Meter"
            class="nav-item nav-link active" id="nav_load_limit" data-toggle="tab" href="#load_limit" role="tab"
              aria-controls="load-limit" aria-selected="true"> Set Load Limit
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                  title="Set Load Limit On Meter"></i>
              </div>
            </a>
            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Happy_Hours_On_Meter" 
            class="nav-item nav-link"
              id="nav_happy_hours" data-toggle="tab" href="#happy_hours" role="tab" aria-controls="happy-hours"
              aria-selected="false">Set Happy Hours
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                  title="Set Happy Hours On Meter"></i>
              </div>
            </a>

            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter"
              class="nav-item nav-link" id="nav_meter_alarm" data-toggle="tab" href="#meter_alarm" role="tab"
              aria-controls="meter-alarm" aria-selected="false">Set Meter Alarm
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                  title="Set Meter Alarm Level/Emergeny Credit /Billing Tariff"></i>
              </div>
            </a>

            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter_Slab"
              class="nav-item nav-link" id="nav_meter_alarm_slab" data-toggle="tab" href="#meter_alarm_slab" role="tab"
              aria-controls="meter-alarm" aria-selected="false">Set Meter Alarm
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                  title="Set Meter Alarm Level/Emergeny Credit /Billing Tariff"></i>
              </div>
            </a>

            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Bill_date" 
            class="nav-item nav-link" id="nav_date_time" data-toggle="tab" href="#date_time" role="tab"
              aria-controls="date-time" aria-selected="false">Meter Bill Date
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top"
                  title="Meter Bill Date And Time Settings"></i>
              </div>
            </a>

            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Relay_Control" 
            class="nav-item nav-link" id="nav_relay_control" data-toggle="tab" href="#relay_control" role="tab"
              aria-controls="date-time" aria-selected="false"> Relay Control
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="DG Relay Control">
                </i>
              </div>
            </a>

            <a *ngIf="getThrough.authority.Meters_Sub_Configure_ESW_3_PH" 
            class="nav-item nav-link" id="nav_esw_3_ph_control" data-toggle="tab" href="#esw_filter_3_ph" role="tab"
              aria-controls="date-time" aria-selected="false"> ESW Filter 3-PH
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="ESW Filter 3-PH">
                </i>
              </div>
            </a>
            <a *ngIf="getThrough.authority.Meters_Sub_Configure_ESW_1_PH" 
            class="nav-item nav-link" id="nav_esw_1_ph_control" data-toggle="tab" href="#esw_filter_1_ph" role="tab"
              aria-controls="date-time" aria-selected="false"> ESW Filter 1-PH
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="ESW Filter 1-PH">
                </i>
              </div>
            </a>
            <a *ngIf="getThrough.authority.Meters_Sub_Configure_Periodic_Push"
            class="nav-item nav-link" id="nav_esw_1_ph_control" data-toggle="tab" href="#config_periodic_push" role="tab"
              aria-controls="date-time" aria-selected="false"> Config Periodic Push
              <div class="icntb">
                <i class="infoI fa fa-info" data-toggle="tooltip" data-placement="top" title="Config periodic push">
                </i>
              </div>
            </a>
          </div>
        </nav>
        <div class="tab-content meterload" id="nav-tabContent">
          <div class="tab-pane fade show active" id="load_limit" role="tabpanel" aria-labelledby="nav_load_limit">
            <app-meterconfigloadlimit [meterDetail]="meterDetail"></app-meterconfigloadlimit>
          </div>
          <div class="tab-pane fade" id="happy_hours" role="tabpanel" aria-labelledby="nav_happy_hours"
            *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <app-meterconfighappyhours [meterDetail]="meterDetail"></app-meterconfighappyhours>
          </div>
          <div class="tab-pane fade" id="meter_alarm" role="tabpanel" aria-labelledby="nav_meter_alarm"
            *ngIf="getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter">
            <app-meterconfigaeb [meterDetail]="meterDetail"></app-meterconfigaeb>
          </div>
          <div class="tab-pane fade" id="meter_alarm_slab" role="tabpanel" aria-labelledby="nav_meter_alarm_slab"
            *ngIf="getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter_Slab">
            <app-meterconfigaeb-slab [meterDetail]="meterDetail"></app-meterconfigaeb-slab>
          </div>
          <div class="tab-pane fade" id="date_time" role="tabpanel" aria-labelledby="nav_date_time">
            <app-meterbillschedular [meterDetail]="meterDetail"></app-meterbillschedular>
          </div>
          <div class="tab-pane fade" id="relay_control" role="tabpanel" aria-labelledby="nav_relay_control">
            <app-relaycontrol [meterDetail]="meterDetail"></app-relaycontrol>
          </div>
          <div class="tab-pane fade" id="esw_filter_3_ph" role="tabpanel" aria-labelledby="nav_esw_3_ph_control">
            <app-eswfilter [meterDetail]="meterDetail"></app-eswfilter>
          </div>
          <div class="tab-pane fade" id="esw_filter_1_ph" role="tabpanel" aria-labelledby="nav_esw_1_ph_control">
            <app-eswfilter1 [meterDetail]="meterDetail"></app-eswfilter1>
          </div>
          <div class="tab-pane fade" id="config_periodic_push" role="tabpanel" aria-labelledby="nav_config_periodic_push">
            <app-metercofigpush [meterDetail]="meterDetail"></app-metercofigpush>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- 
    <ng-container>
            <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c3">
                        flash_on
                      </mat-icon>
                      Set Load Limit On Meter
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-meterconfigloadlimit [meterDetail]="meterDetail"></app-meterconfigloadlimit>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c12">
                        add_box
                      </mat-icon>
                      Set Happy Hours On Meter
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-meterconfighappyhours [meterDetail]="meterDetail"></app-meterconfighappyhours>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c6">
                        alarm
                      </mat-icon>
                      Set Meter Alarm Level/Emergeny Credit /Billing Tariff
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-meterconfigaeb [meterDetail]="meterDetail"></app-meterconfigaeb>
                </mat-expansion-panel>

                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon class="d-c7">
                        schedule
                      </mat-icon>
                      Meter Bill Date And Time Settings
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <app-meterbillschedular [meterDetail]="meterDetail"></app-meterbillschedular>
                </mat-expansion-panel>

                <div>
                </div>
              </mat-accordion>
                              
            </div>
    </ng-container> -->
  <ng-container col-sm-3>
  </ng-container>
</div>
<!-- content viewport ends -->