<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass white-bg h-75">
        <div class="col-12">
          <div class="deshHeadWcTitle">
            <mat-icon>eco</mat-icon>Reporting From Meter's On DCU
          </div>
        </div>
      </div>
      <div class="row commonClass">
        <mat-toolbar class="subheaderArea h-40 fs-14">
          <span class="toolbar-space"></span>
          <button mat-icon-button color="primary">
            <mat-icon class="d-c">search</mat-icon>
          </button>
          <button mat-flat-button (click)="whichSubDomain()" class="cPbg">
            <mat-icon>eco</mat-icon>
            Change Sub-Divsion
          </button>
        </mat-toolbar>
      </div>
      <div class="row inner-page-content">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
        <ng-container>
          <div class="grid col-sm-12">
            <div class="grid-body">
              <div class="row">
                <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <mat-tab-group>
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon class="d-c2">eco</mat-icon>
                     <h4>Reporting From Meter</h4> 
                    </ng-template>
                    <form [formGroup]="reportForm" (ngSubmit)="onSubmitAddForm()" >
                          <div class="col-md-6 form-group input-rounded">
                              <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gidata" formControlName="gidata">Get Instant Data</mat-checkbox>
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="glndata" formControlName="glndata">Get Last Night Midnight Data</mat-checkbox>
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gcmdata" formControlName="gcmdata">Get Complete Midnight Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="glnbdata" formControlName="glnbdata">Get Latest Billing Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gfbdata" formControlName="gfbdata">Get Full Billing Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gftdata" formControlName="gftdata">Get Full Tamper Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gltdata" formControlName="gltdata">Get Latest Tamper Data</mat-checkbox>
                           
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gllsdata" formControlName="gllsdata">Get All Load Survey Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gclsdata" formControlName="gclsdata">Get Current Load Survey Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gmcdata" formControlName="gmcdata">Get Meter Configuration Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gmrcdata" formControlName="gmrcdata">Get Meter Router Configuration Data</mat-checkbox>
                            
                          </div>
                          <div class="col-md-6 form-group input-rounded">
                            <mat-icon matPrefix class="d-c2">eco</mat-icon>
                              <mat-checkbox name="gmhs" formControlName="gmhs">Get Meter Hardware Status</mat-checkbox>
                          </div>
                          <button type="submit" class="btn btn-primary btn-block">
                              Report
                              <img class="pl-3"
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          </button>
                    </form>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container col-sm-3>
      </ng-container>
    </div>
  </div>
</div>
</div>
<!-- content viewport ends -->