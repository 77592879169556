import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import { DISABLED } from '@angular/forms/src/model';

@Component({
    selector: 'app-edit-schedular',
    templateUrl: './edit-schedular.component.html',
    styleUrls: ['./edit-schedular.component.scss']
})
export class EditSchedularComponent implements OnInit {
  faSpinner = faSpinner;
    nowDate = new Date();
    currentUser: User;
    currentUserSubscription: Subscription;
    addForm: UntypedFormGroup;
    responseMessage: string = '';
    responseData: any;
    AddProcess: boolean = false;
    editSchedular:any = {};
    subDivisionId: any = 1;
    //

    isLoading:boolean = false;
    thumb:boolean = false;
    addAuthMessage:string = '';
   submitted: boolean = false;
    

    subDevision: any = [];
    meterCommands = [{
        "meterid": 0,
        "subDivisionId": 0,
        "meterCmdCode": "null",
        "meterCmdName": 'Please select command',
        "updationDateTime": null
    }]

      scheExec1 = [{
        "execUID": 0,
        "subDivisionId": 0,
        "execName": "",
        "serverDateTime": ""
    }];
    
      scheExec = [ {
        "execUID": 1,
        "subDivisionId": 2,
        "execName": "Every Minute",
        "serverDateTime": "2020-05-12 17:50:28"
      },
      {
        "execUID": 2,
        "subDivisionId": 2,
        "execName": "Every 30 Minuts",
        "serverDateTime": "2020-05-12 17:50:29"
      },
      {
        "execUID": 3,
        "subDivisionId": 2,
        "execName": "Every Hour",
        "serverDateTime": "2020-05-12 17:50:29"
      },
      {
        "execUID": 4,
        "subDivisionId": 2,
        "execName": "Every Day",
        "serverDateTime": "2020-05-12 17:50:29"
      },
      {
        "execUID": 5,
        "subDivisionId": 2,
        "execName": "Every Week",
        "serverDateTime": "2020-05-12 17:50:29"
      },
      {
        "execUID": 6,
        "subDivisionId": 2,
        "execName": "Every Month",
        "serverDateTime": "2020-05-12 17:50:30"
      },
      {
        "execUID": 7,
        "subDivisionId": 2,
        "execName": "Quaterly",
        "serverDateTime": "2020-05-12 17:50:30"
      },
      {
        "execUID": 8,
        "subDivisionId": 2,
        "execName": "Yearly",
        "serverDateTime": "2020-05-12 17:50:31"
      }];
    date1 :any;
    date2 :any;
    meterIdNo = [{
        "muID": 0,
        "mID": null
    }]

  

    hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    minute = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    startDate = '';
    endDate = '';
    startHour='';
    startMinute='';
    congigRangeDate: Date[];
    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        public modal: NgbActiveModal,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    


    ngOnInit() {
      this.schedularFrequencyFun();
        //console.log(this.editSchedular);
        // this.congigRangeDate = [this.editSchedular.scheduleStartDateTime, this.editSchedular.scheduleEndDateTime];
        this.addForm = this.formBuilder.group({
            schedularName: ['', Validators.required],
            //commanType: [{value:'', disabled: true},  Validators.required],
            commanType: ['',  Validators.required],
            //meters: ['', Validators.required],
            schedularDate: ['', Validators.required],
            //schedularStartDate: ['', Validators.required],
            //schedularEndDate: ['', Validators.required],
            //schedularStartEndTime: ['', Validators.required],
            schedularFrequency: ['', Validators.required],
            activeStatus: ['', Validators.required],
            startHour: ['', Validators.required],
            //endHour: ['', Validators.required],
            startMinute: ['', Validators.required],
            //endMinute: ['', Validators.required]
        });
        this.getMeterCommandListing();
        let schedularStartDate = this.editSchedular.scheduleStartDateTime; 
        //console.log("hello",this.editSchedular.scheduleStartDateTime);
        //let newSDate = new Date(schedularStartDate);
        let newSDate = schedularStartDate.substr(0,10);

        let newSHour=schedularStartDate.substr(11,2);
        let newSMinute=schedularStartDate.substr(14,2);
       // let schedularEndtDate = this.editSchedular.scheduleEndDateTime; 
        //let newEDate = new Date(schedularEndtDate);

        //let newEDate = schedularEndtDate.substr(0,10);
        //let newEHour = schedularEndtDate.substr(11,2);
        //let newEMInute = schedularEndtDate.substr(14,2);

        if(this.editSchedular.scheduleName) {
         this.aF.schedularName.setValue(this.editSchedular.scheduleName);
        }
        
        let dSStatus = 'N';
        if (this.editSchedular.scheduleStatus != 'Disable') {
            dSStatus = 'Y';
        }
        this.aF.activeStatus.setValue(dSStatus);
        this.aF.schedularFrequency.setValue(this.editSchedular.scheduleFrequency);
       
        
        var d = new Date(newSDate.substr(5,2)+"/"+newSDate.substr(8,2)+"/"+newSDate.substr(0,4));
        this.aF.schedularDate.setValue(this.formateDate(d));
        //this.aD.authorityDOB.setValue(this.formateDate(this.authority.authDOB));
        //d.setDate(  );
        //console.log(newSDate,newEDate);
        this.date1 = new UntypedFormControl(new Date(newSDate));
        //this.date2 = new FormControl(new Date(newEDate));
        //this.aF.schedularStartDate.setValue(d.getDate() + d.getTimezoneOffset());
        //this.aF.schedularEndDate.setValue(newEDate.substr(5,2)+"/"+newEDate.substr(8,2)+"/"+newEDate.substr(0,4));
       /* this.aF.startHour.setValue(parseInt(newSHour, 10));
        this.aF.startMinute.setValue(parseInt(newSMinute, 10));
        this.aF.endHour.setValue(parseInt(newEHour, 10));
        this.aF.endMinute.setValue(parseInt(newEMInute, 10));*/
        this.aF.startHour.setValue(newSHour);
        this.aF.startMinute.setValue(newSMinute);
        //this.aF.endHour.setValue(newEHour);
        //this.aF.endMinute.setValue(newEMInute);
        //this.schedularFrequency();
        this.onCmdChangeData();
        
    }

    schedularFrequencyFun() {
      let body = new URLSearchParams();
      body.set('execSubDivision', this.subDivisionId);
      body.set('execTypeId', '0');
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getSchedExecType', body).subscribe((result)=>{
        this.responseData = result;
        if(this.responseData.success){
          this.scheExec = this.responseData.data;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
    });
  }

    onCmdChangeData() {
        //console.log("hello",this.aF.commanType.value);
        this.hours=[''];
        this.minute=[''];
       
        this.scheExec = [{
            "execUID": 0,
            "subDivisionId": 0,
            "execName": "",
            "serverDateTime": ""
        }];
       
        if(this.editSchedular.meterCmdName=="SET RTC"){
            this.scheExec= [
                {
                  "execUID": 4,
                  "subDivisionId": 2,
                  "execName": "Every Day",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 5,
                  "subDivisionId": 2,
                  "execName": "Every Week",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 6,
                  "subDivisionId": 2,
                  "execName": "Every Month",
                  "serverDateTime": "2020-05-12 17:50:30"
                }
              ];
            this.hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
            this.minute = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        }else{
          this.schedularFrequencyFun();
            /*this.scheExec= [
                {
                  "execUID": 1,
                  "subDivisionId": 2,
                  "execName": "Every Minute",
                  "serverDateTime": "2020-05-12 17:50:28"
                },
                {
                  "execUID": 2,
                  "subDivisionId": 2,
                  "execName": "Every 30 Minuts",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 3,
                  "subDivisionId": 2,
                  "execName": "Every Hour",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 4,
                  "subDivisionId": 2,
                  "execName": "Every Day",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 5,
                  "subDivisionId": 2,
                  "execName": "Every Week",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 6,
                  "subDivisionId": 2,
                  "execName": "Every Month",
                  "serverDateTime": "2020-05-12 17:50:30"
                },
                {
                  "execUID": 7,
                  "subDivisionId": 2,
                  "execName": "Quaterly",
                  "serverDateTime": "2020-05-12 17:50:30"
                },
                {
                  "execUID": 8,
                  "subDivisionId": 2,
                  "execName": "Yearly",
                  "serverDateTime": "2020-05-12 17:50:31"
                },
                {
                  "execUID": 9,
                  "subDivisionId": 2,
                  "execName": "Every 15 Minuts",
                  "serverDateTime": "2020-05-12 17:50:31"
                }
              ];*/
            this.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
            this.minute = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        }
 }

    onCmdChange(e) {
        //console.log("hello",this.aF.commanType.value);
        this.hours=[''];
        this.minute=[''];
       
        this.scheExec = [{
            "execUID": 0,
            "subDivisionId": 0,
            "execName": "",
            "serverDateTime": ""
        }];
       
        if(this.aF.commanType.value=='46'){
            this.scheExec= [
                {
                  "execUID": 4,
                  "subDivisionId": 2,
                  "execName": "Every Day",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 5,
                  "subDivisionId": 2,
                  "execName": "Every Week",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 6,
                  "subDivisionId": 2,
                  "execName": "Every Month",
                  "serverDateTime": "2020-05-12 17:50:30"
                }
              ];
            this.hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
            this.minute = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        }else{
          this.schedularFrequencyFun();
            /*this.scheExec= [
                {
                  "execUID": 1,
                  "subDivisionId": 2,
                  "execName": "Every Minute",
                  "serverDateTime": "2020-05-12 17:50:28"
                },
                {
                  "execUID": 2,
                  "subDivisionId": 2,
                  "execName": "Every 30 Minuts",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 3,
                  "subDivisionId": 2,
                  "execName": "Every Hour",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 4,
                  "subDivisionId": 2,
                  "execName": "Every Day",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 5,
                  "subDivisionId": 2,
                  "execName": "Every Week",
                  "serverDateTime": "2020-05-12 17:50:29"
                },
                {
                  "execUID": 6,
                  "subDivisionId": 2,
                  "execName": "Every Month",
                  "serverDateTime": "2020-05-12 17:50:30"
                },
                {
                  "execUID": 7,
                  "subDivisionId": 2,
                  "execName": "Quaterly",
                  "serverDateTime": "2020-05-12 17:50:30"
                },
                {
                  "execUID": 8,
                  "subDivisionId": 2,
                  "execName": "Yearly",
                  "serverDateTime": "2020-05-12 17:50:31"
                },
                {
                  "execUID": 9,
                  "subDivisionId": 2,
                  "execName": "Every 15 Minuts",
                  "serverDateTime": "2020-05-12 17:50:31"
                }
              ];*/
            this.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
            this.minute = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
        }
 }

    formateDate(datevalue) {
        let date = new Date(datevalue);
        return (date.getMonth()+1)+'/'+(date.getDate()+1)+'/'+date.getFullYear();
      }

    /*ngAfterViewInit() {
        this.getMeterCommandListing();
        let schedularStartDate = this.editSchedular.scheduleStartDateTime; 
        let newSDate = new Date(schedularStartDate);
        let schedularEndtDate = this.editSchedular.scheduleEndDateTime; 
        let newEDate = new Date(schedularEndtDate);
        if(this.editSchedular.scheduleName) {
         this.aF.schedularName.setValue(this.editSchedular.scheduleName);
        }
        let dSStatus = 'N';
        if (this.editSchedular.scheduleStatus != 'Disable') {
            dSStatus = 'Y';
        }
        this.aF.activeStatus.setValue(dSStatus);
        this.aF.startHour.setValue(newSDate.getHours());
        this.aF.startMinute.setValue(newSDate.getMinutes);
        this.aF.endHour.setValue(newEDate.getHours());
        this.aF.endMinute.setValue(newEDate.getMinutes);
    }*/
    ngOnDestroy() {
        //this.modalService.dismissAll();
    }

    /*getDateFromRange(pickedRange: Date[]) {
        let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
        let sday = pickedRange[0].getDate();
        let syear = pickedRange[0].getFullYear();
        this.startDate = syear + '-' + smonth + '-' + sday;
        let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
        let eday = pickedRange[1].getDate();
        let eyear = pickedRange[0].getFullYear();
        this.endDate = eyear + '-' + emonth + '-' + eday;
    }*/

    /*makeDateFinal() {
        let startHour = this.aF.startHour.value
        let endHour = this.aF.endHour.value
        let startMinute = this.aF.startMinute.value
        let endMinute = this.aF.endMinute.value
        this.startDate = this.convert(this.aF.schedularStartDate.value) + ' ' + startHour + ':' + startMinute + ':00';
        this.endDate = this.convert(this.aF.schedularEndDate.value) + ' ' + endHour + ':' + endMinute + ':00';
    }*/

    
    makeDateFinal (){
        this.startHour = this.aF.startHour.value
        //let endHour = this.aF.endHour.value
        this.startMinute = this.aF.startMinute.value
        //let endMinute = this.aF.endMinute.value

        if(this.startDate==''){
            this.startDate = '';
          }else{
            this.startDate = this.startDate+' '+this.startHour+':'+this.startMinute+':00';
          }
        //this.startDate = this.startDate+' '+this.startHour+':'+this.startMinute+':00';
        //this.endDate = this.endDate+' '+endHour+':'+endMinute+':00';
       
    }

    getDateFromRange(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.startDate = eyear + '-' + emonth + '-' + eday;
        }
    }

    getDateEndRange(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.endDate = eyear + '-' + emonth + '-' + eday;
        }
    }

    getDOB(pickedDate = new Date()) {
        if (pickedDate) {
            let emonth = pickedDate.getMonth() + 1; //always move 1 value up
            let eday = pickedDate.getDate();
            let eyear = pickedDate.getFullYear();
            this.startDate = eyear + '-' + emonth + '-' + eday;
            if(eday==29 || eday==30 || eday==31){
                this.startDate ='';
                //this.aF.schedularDate.setValue("");
                //alert("Do not select 29,30 or 31 dates. Please choose another one.");
                this.aF.schedularDate.reset();
               
              }else{
                this.startDate = eyear + '-' + emonth + '-' + eday;
              }
        }
    }

    get aF() { return this.addForm.controls; }
    onSubmitAddForm() {
        this.makeDateFinal();
       // console.log("1");
        if (this.addForm.invalid) {
           this.submitted = true;
            return;

        }

        if (this.startDate =='' ) {
            let schedularStartDate = this.editSchedular.scheduleStartDateTime; 
            //console.log("hello",this.editSchedular.scheduleStartDateTime);
            //let newSDate = new Date(schedularStartDate);
            let newSDate = schedularStartDate.substr(0,10);
    
            var d = new Date(newSDate.substr(5,2)+"/"+newSDate.substr(8,2)+"/"+newSDate.substr(0,4));
            this.aF.schedularDate.setValue(this.formateDate(d));
    
            //this.aF.schedularDate.setValue(this.nowDate);
            alert("Do not select 29,30 or 31 dates. Please choose another one.");
            //this.aF.schedularDate.reset();
            return;
        }
        //console.log("12");

        this.isLoading = true;
        const formData = new FormData();
        formData.append('subDivisionId', this.currentUser.data.userData.subdivision);
        formData.append('schedularId', this.editSchedular.scheduleUniqueId);
        formData.append('schedularName', this.aF.schedularName.value);
        formData.append('commanType', this.aF.commanType.value);
        formData.append('meters', '');
        formData.append('schedularStartTime', this.startDate);
        //console.log("3");
        //formData.append('schedularStartTime',  this.convert(this.aF.schedularStartDate.value));
        formData.append('schedularEndTime', this.endDate);
        //console.log("4");
        //formData.append('schedularEndTime',  this.convert(this.aF.schedularEndDate.value));
        formData.append('schedularFrequency', this.aF.schedularFrequency.value);
        formData.append('activeStatus', this.aF.activeStatus.value);
        formData.append('authToken', this.currentUser.authToken);
      //  console.log("5");
        this.genReqs.formDataPost('/editSchedular', formData).subscribe((result) => {
            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
               
                this.addAuthMessage = this.responseData.data.message;
                if(this.addAuthMessage.includes("Details Not Updated")){
                    this.thumb = false;
                }else{
                    this.thumb = true;
                }
                setTimeout(() => 
                {
                this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
        });
    }

   
    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
    

    getMeterCommandListing() {
        let body = new URLSearchParams();
        body.set('meterSubDivision', this.subDivisionId);
        body.set('offSet', '0');
        body.set('limit', '99999999');
        body.set('filter', '');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getMeterCmd', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.meterCommands = this.responseData.data;
                if (this.editSchedular.meterCmdName) {
                 let meterCmdName = this.meterCommands.find(c => c.meterCmdName == this.editSchedular.meterCmdName);
                    this.aF.commanType.setValue(meterCmdName.meterid);
                }
                //this.meterList();
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

    meterList() {
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('dcuId', '0');
        body.set('authToken', this.currentUser.authToken);
        body.set('offSet', '0');
        body.set('limit', '99999999');
        this.genReqs.postReq('/getMeters', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.meterIdNo = this.responseData.data;
                //"A000001"
                if (this.editSchedular.mID) {
                    let metersIds = this.meterIdNo.find(c => c.mID == this.editSchedular.mID);
                    this.aF.meters.setValue(metersIds.mID);
                }
                //this.schedularFrequency();
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }

   /* schedularFrequency() {
        let body = new URLSearchParams();
        body.set('execSubDivision', this.subDivisionId);
        body.set('execTypeId', '0');
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/getSchedExecType', body).subscribe((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.scheExec = this.responseData.data;
                if (this.editSchedular.scheExeName) {
                    let scheExeName = this.scheExec.find(c => c.execName == this.editSchedular.scheExeName);
                    this.aF.schedularFrequency.setValue(scheExeName.execUID);
                }
            }
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
        });
    }*/

    schedularFrequency() {
                //this.scheExec = this.responseData.data;
                if (this.editSchedular.scheExeName) {
                    let scheExeName = this.scheExec.find(c => c.execName == this.editSchedular.scheExeName);
                    this.aF.schedularFrequency.setValue(scheExeName.execUID);
                }
    }
}
