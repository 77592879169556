import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-addtower',
  templateUrl: './addtower.component.html',
  styleUrls: ['./addtower.component.scss']
})
export class AddtowerComponent implements OnInit {
  faSpinner = faSpinner;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';

  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  loadingMessage:string='';
  subDivisionId:any = 0;
  submitted: boolean = false;
  dgData = [
    {
      srNo: 0,
      dgId: 0,
      subDivId: 0,
      dgName: "",
      ebName: "",
      dgNameData: "",
      creationDateTime: "",
      authName: ""
    }
  ];
  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  
  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      dgNameData: ['', Validators.required],
      towerName: ['', Validators.required]
    });
    
    this.getDgData();
   
  }
  ngAfterViewInit() {
     
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  getDgData() {
    let body = new URLSearchParams();
    body.set('subdivId', this.subDivisionId);
    body.set('dgId', '0');
    body.set('offSet', '0');
    body.set('limit', '100');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getDGData', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        setTimeout(() => {
            this.dgData = this.responseData.data;
        });
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
          this.submitted = true;
        return;
      }
      this.isLoading=true;
      let body = new URLSearchParams();
      body.set('subdivId', this.subDivisionId);
      body.set('dgName', this.aF.dgNameData.value);
      body.set('towerName', this.aF.towerName.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addTowerData', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
