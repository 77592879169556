  <form [formGroup]="addForm" (ngSubmit)="onSubmitAddFormSub()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Select Sub-Division
    </h4>
</div>
<div class="modal-body">
    <div class="form-group">
       <div class="row">
         <div class="col-md-6">
            <div class="form-group input-group">
                <span class="has-float-label">
                <input #autocompleteInput type="text" placeholder="select sub-division" 
                [matAutocomplete]="auto" [formControl]="AutoCompleteControl" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.Code.errors }" required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">

                <mat-option *ngFor="let devision of filteredLoginSubDivision | async" [value]="devision"
                    (onSelectionChange)="showSubDevisionData($event, devision.sub_dev_id, devision.sub_dev_name, devision)">
                    {{devision.sub_dev_name}}
                </mat-option>
                </mat-autocomplete>
                <i class="fa fa-globe icon_innertxtbox"></i>
                <label>Division Code</label>
                <div *ngIf="submitted && aF.Code.errors" class="invalid-feedback">
                    <div *ngIf="aF.Code.errors.required">
                    Division Code is required
                    </div>
                </div>
                </span>
            </div>
            </div>
        </div>

        <!-- Grid row -->
        <div class="clearfix"></div>
        <div class="col-12 text-right">
            <button type="button" [disabled]="!allowClose" (click)="modal.dismiss(selectedSubDiv)" 
            class="dilog dilog3">
             <mat-icon matPrefix>cancel</mat-icon>Cancel
            </button>
        </div>
    </div>
</div>
</form>