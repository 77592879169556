      <!-- 
      <footer class="footer new-footer" *ngIf="currentUser">
        <div class="row">
          <div class="col-sm-6 text-center text-sm-right order-sm-1">
            <ul class="text-gray">
              <li><a href="#aboutus" target="_blank"> <mat-icon matPrefix>business</mat-icon>&nbsp; About Us</a></li>
              <li><a href="#policy" target="_blank"><mat-icon matPrefix>policy</mat-icon>&nbsp;Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-sm-6 text-center text-sm-left mt-3 mt-sm-0">
            <small class="text-muted d-block">Copyright © 2020 Avon Meters Pvt. Ltd. All rights reserved</small>
          </div>
        </div>
      </footer>
     -->

      <footer *ngIf="currentUser" class="">
        <p class="copyright">© All Rights Reserved 2021. <b>Avon Meters</b></p>
    </footer>
