import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-edit-old-new-meter-pnb',
  templateUrl: './edit-old-new-meter-pnb.component.html',
  styleUrls: ['./edit-old-new-meter-pnb.component.scss']
})
export class EditOldNewMeterPnbComponent implements OnInit {

  basicInfo = true;
  releaseAcc = false;
  wrongAccount = false;
  wrongSmart = false;
  updateZone = false;

  disableStatus = true;
  faSpinner = faSpinner;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  meterDetail: any;
  checkBox: any = "N";
  assignedData = {};
  authorityDOB: any = "0000-00-00";
  bsAuthorityDOB: Date = new Date(2017, 7);
  isLoading: boolean = false;
  thumb: boolean = false;

  authLevel_sel: any = '';

  zoneId: number = 0;
  circleId: number = 0;
  divisionId: number = 0;
  subDivId: number = 0;
  feederId: number = 0;
  submitStatus = false;
  consumerName = '';
  mobileNo = '';
  emailId = '';
  address = '';
  loadUnitId = '';
  loadLimit = '';
  oldMeterId = '';
  oldMeterReading = '';
  oldMeterRemark = '';
  oldMeterImgCheckedStatus = '';
  oldMeterStatus = '';
  releaseRemark = "";
  newAccountNo = "";
  newMeterId = "";

  releaseRemarkFeedBack = "";
  newAccountNoFeedBack = "";
  newMeterIdFeedBack = "";

  submitted: boolean = false;
  public updationTypeData: any = [];
  public releaseData: any = [];
  public zoneData: any = [];
  public circleData: any = [];
  public divisionData: any = [];
  public subDivData: any = [];
  public feederData: any = [];
  public meterMakeData: any = [];
  public meterRatingData: any = [];
  public meterManufectData: any = [];
  public loadUnitData: any = [
    {
      id: 1,
      text: 'N/A'
    },
    {
      id: 2,
      text: 'kW'
    },
    {
      id: 3,
      text: 'HP'
    },
  ];
  ///multiselect

  date: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }


  ngOnInit() {
    let MOBILE_PATTERN = /[0-9\+\-\ ]/;
    /*
       form authority add
     */
    this.addAuthorityForm = this.formBuilder.group({
      ivrs: [{ value: '', disabled: true }, Validators.required],
      newAccount:  ['', Validators.required],
      zone: [{ value: '', disabled: true }, Validators.required],
      circle: [{ value: '', disabled: true }, Validators.required],
      division: [{ value: '', disabled: true }, Validators.required],
      sub_division: [{ value: '', disabled: true }, Validators.required],
      consumerName: [''],
      loadLimit: [''],
      loadUnit: [''],
      mobileNo: [''],
      emailId: [''],
      address: [''],
      updationType: [1],
      oldMeterId: [''],
      oldMeterReading: [''],
      oldMeterRemark: [''],
      oldMeterImage: [''],

      newMeterId: [{ value: '', disabled: true }, Validators.required],
      correctNewMeterId:  ['', Validators.required],
      newmterIPAddress: ['', Validators.required],
      newMeterReading: ['', Validators.required],
      newMeterRemark: [''],
      newMeterLat: ['', Validators.required],
      newMeterLong: ['', Validators.required],
      mcoRemark: [''],
      newMeterImage: [''],
      mcoImage: [''],
      releaseRemark: ['', Validators.required],

      //dataArray: ['']
    });
    this.getOldMeterData();
   
    this.getUpdationTypeData();
    this.getReleaseData();

  }

  conditionalValidator(predicate, validator) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl);
      }
      return null;
    })
  }


  ngAfterViewInit() {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  onChange(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.checkBox = "Y";
    } else {
      this.checkBox = "N";
    }
  }

  onFileSelectOldImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.oldMeterImage.setValue(file);
    }
  }

  onFileSelectNewMeterImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.newMeterImage.setValue(file);
    }
  }

  onFileSelectMCOImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  newMeterManufacturing(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  newMeterRating(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  updateInformation(event) {

    if (this.aD.updationType.value == 1) {
      this.basicInfo = true;
      this.releaseAcc = false;
      this.wrongAccount = false;
      this.wrongSmart = false;
      this.updateZone = false;


      this.aD.ivrs.disable();
      this.aD.zone.disable();
      this.aD.circle.disable();
      this.aD.division.disable();
      this.aD.sub_division.disable();
      this.aD.newMeterId.disable();

      this.aD.consumerName.enable();
      this.aD.loadLimit.enable();
      this.aD.loadUnit.enable();
      this.aD.mobileNo.enable();
      this.aD.emailId.enable();
      this.aD.address.enable();
      this.aD.newmterIPAddress.enable();
      this.aD.newMeterReading.enable();
      this.aD.newMeterRemark.enable();
      this.aD.newMeterLat.enable();
      this.aD.newMeterLong.enable();
      this.aD.mcoRemark.enable();

    } else if (this.aD.updationType.value == 2) {
      this.basicInfo = false;
      this.releaseAcc = true;
      this.wrongAccount = false;
      this.wrongSmart = false;
      this.updateZone = false;

      this.aD.ivrs.disable();
      this.aD.zone.disable();
      this.aD.circle.disable();
      this.aD.division.disable();
      this.aD.sub_division.disable();

      this.aD.consumerName.disable();
      this.aD.loadLimit.disable();
      this.aD.loadUnit.disable();
      this.aD.mobileNo.disable();
      this.aD.emailId.disable();
      this.aD.address.disable();
      this.aD.newMeterId.disable();
      this.aD.newmterIPAddress.disable();
      this.aD.newMeterReading.disable();
      this.aD.newMeterRemark.disable();
      this.aD.newMeterLat.disable();
      this.aD.newMeterLong.disable();
      this.aD.mcoRemark.disable();

    } else if (this.aD.updationType.value == 3) {
      this.basicInfo = false;
      this.releaseAcc = false;
      this.wrongAccount = true;
      this.wrongSmart = false;
      this.updateZone = false;

      this.aD.ivrs.disable();
      this.aD.zone.disable();
      this.aD.circle.disable();
      this.aD.division.disable();
      this.aD.sub_division.disable();
      this.aD.newMeterId.disable();

      this.aD.consumerName.disable();
      this.aD.loadLimit.disable();
      this.aD.loadUnit.disable();
      this.aD.mobileNo.disable();
      this.aD.emailId.disable();
      this.aD.address.disable();
      this.aD.newmterIPAddress.disable();
      this.aD.newMeterReading.disable();
      this.aD.newMeterRemark.disable();
      this.aD.newMeterLat.disable();
      this.aD.newMeterLong.disable();
      this.aD.mcoRemark.disable();

    } else if (this.aD.updationType.value == 4) {
      this.basicInfo = false;
      this.releaseAcc = false;
      this.wrongAccount = false;
      this.wrongSmart = true;
      this.updateZone = false;

      this.aD.ivrs.disable();
      this.aD.zone.disable();
      this.aD.circle.disable();
      this.aD.division.disable();
      this.aD.sub_division.disable();
      this.aD.newMeterId.enable();
      this.aD.consumerName.disable();
      this.aD.loadLimit.disable();
      this.aD.loadUnit.disable();
      this.aD.mobileNo.disable();
      this.aD.emailId.disable();
      this.aD.address.disable();
      this.aD.newMeterId.disable();
      this.aD.newmterIPAddress.disable();
      this.aD.newMeterReading.disable();
      this.aD.newMeterRemark.disable();
      this.aD.newMeterLat.disable();
      this.aD.newMeterLong.disable();
      this.aD.mcoRemark.disable();

    } else if (this.aD.updationType.value == 5) {
      this.basicInfo = false;
      this.releaseAcc = false;
      this.wrongAccount = false;
      this.wrongSmart = false;
      this.updateZone = true;
      this.aD.ivrs.disable();
      this.aD.zone.enable();
      this.aD.circle.enable();
      this.aD.division.enable();
      this.aD.sub_division.enable();
      this.aD.newMeterId.disable();
      this.aD.consumerName.disable();
      this.aD.loadLimit.disable();
      this.aD.loadUnit.disable();
      this.aD.mobileNo.disable();
      this.aD.emailId.disable();
      this.aD.address.disable();
      this.aD.newMeterId.disable();
      this.aD.newmterIPAddress.disable();
      this.aD.newMeterReading.disable();
      this.aD.newMeterRemark.disable();
      this.aD.newMeterLat.disable();
      this.aD.newMeterLong.disable();
      this.aD.mcoRemark.disable();
    } else {
      this.basicInfo = true;
      this.releaseAcc = false;
      this.wrongAccount = false;
      this.wrongSmart = false;
      this.updateZone = false;

      this.aD.ivrs.disable();
      this.aD.zone.disable();
      this.aD.circle.disable();
      this.aD.division.disable();
      this.aD.sub_division.disable();
      this.aD.newMeterId.disable();
    }
  }

  feederChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  subDivChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  divisionChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  circleChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }
  zoneChanged() {
    this.getCircleData();
  }


  newMeterMake(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  loadUnit(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }


  getZoneData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbZoneData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.zoneData = this.responseData.data;
        if(this.aD.zone.value){
          //console.log("zone id------",this.aD.zone.value);
          this.getCircleData();
        }
        // console.log("leveldata", this.authLevelData);

      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getCircleData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbCircleData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.circleData = this.responseData.data;
        this.getDivisionData();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getDivisionData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbDivisionDataOld', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.divisionData = this.responseData.data;
        this.getSubDivisionData();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getSubDivisionData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('divisionId', this.aD.division.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbsubDivDataOld', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.subDivData = this.responseData.data;
        this.getFeederData();
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getFeederData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('zoneId', this.aD.zone.value);
    body.set('circleId', this.aD.circle.value);
    body.set('divisionId', this.aD.division.value);
    body.set('subDivisionId', this.aD.sub_division.value);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPnbFeederData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.feederData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getUpdationTypeData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getUpdationTypeData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.updationTypeData = this.responseData.data;
        console.log("updation log data", this.updationTypeData);
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  getReleaseData() {
    let body = new URLSearchParams();
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getReleaseData', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.releaseData = this.responseData.data;
        console.log("updation log data", this.releaseData);
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  ngOnDestroy() {
    //this.modal.close();
  }

  /*
  add authority
  require authority data
*/
  get aD() { return this.addAuthorityForm.controls; }
  setRequired() {
    return [Validators.required];
  }
  onSubmitAddAuthority() {
   
    //let body = new URLSearchParams();
    const formData = new FormData();
    formData.append('accountId', this.meterDetail.accountId);
    formData.append('oldAccountNo', this.meterDetail.ivrsNo);
    formData.append('newAccount', this.aD.newAccount.value);
    formData.append('zoneIdOld', this.meterDetail.zoneId);
    formData.append('zoneIdNew', this.aD.zone.value);
    formData.append('circleIdOld', this.meterDetail.circleId);
    formData.append('circleIdNew', this.aD.circle.value);
    formData.append('divisionIdOld', this.meterDetail.divisionId);
    formData.append('divisionIdNew', this.aD.division.value);
    formData.append('subDivisionIdOld', this.meterDetail.subDivisionId);
    formData.append('subDivisionIdNew', this.aD.sub_division.value);
    formData.append('consumerName', this.aD.consumerName.value);
    formData.append('mobileNo', this.aD.mobileNo.value);
    formData.append('emailId', this.aD.emailId.value);
    formData.append('address', this.aD.address.value);
    formData.append('loadUnit', this.aD.loadUnit.value);
    formData.append('loadLimit', this.aD.loadLimit.value);
    formData.append('oldMeterId', this.aD.oldMeterId.value);
    formData.append('oldMeterReading', this.aD.oldMeterReading.value);
    formData.append('oldMeterRemark', this.aD.oldMeterRemark.value);
    formData.append('oldMeterImage', this.aD.oldMeterImage.value);
    formData.append('oldId', this.meterDetail.oldUid);
    formData.append('newId', this.meterDetail.newMeterUid);
    formData.append('updationType', this.aD.updationType.value);
    formData.append('newMeterIdOld', this.meterDetail.newMeterId);
    formData.append('correctNewMeterId', this.aD.correctNewMeterId.value);
    formData.append('newMeterIdNew', this.aD.newMeterId.value);
    formData.append('newmterIPAddress', this.aD.newmterIPAddress.value);
    formData.append('newMeterReading', this.aD.newMeterReading.value);
    formData.append('newMeterRemark', this.aD.newMeterRemark.value);
    formData.append('newMeterLat', this.aD.newMeterLat.value);
    formData.append('newMeterLong', this.aD.newMeterLong.value);
    formData.append('mcoRemark', this.aD.mcoRemark.value);
    formData.append('newMeterImage', this.aD.newMeterImage.value);
    formData.append('mcoImage', this.aD.mcoImage.value);
    formData.append('releaseRemark', this.aD.releaseRemark.value);
    formData.append('authToken', this.currentUser.authToken);
    if (this.aD.updationType.value == 1) {
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update basic information?')
        .then((confirmed) => {
          if (confirmed) {
            this.isLoading = true;
            this.AddProcess = true;
            this.genReqs.formDataPost('/updateOldNewPnb', formData).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.isLoading = false;
              if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                //window.open("http://localhost:4200/#/oldNewMeter","_self");
                setTimeout(() => {
                  this.modal.close('done');
                },
                  2000);
              } else {
                this.thumb = false;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.addAuthMessage = 'Unable to process yours request!';
            });
          }
        }).catch(() => console.log('no response or closed'));
    } else if (this.aD.updationType.value == 2) {
    
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to release Account/Meter No?')
        .then((confirmed) => {
          if (confirmed) {
            this.isLoading = true;
            this.AddProcess = true;
            this.genReqs.formDataPost('/updateOldNewPnb', formData).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.isLoading = false;
              if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                //window.open("http://localhost:4200/#/oldNewMeter","_self");
                setTimeout(() => {
                  this.modal.close('done');
                },
                  2000);
              } else {
                this.thumb = false;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.addAuthMessage = 'Unable to process yours request!';
            });
          }
        }).catch(() => console.log('no response or closed'));
    } else if (this.aD.updationType.value == 3) {
      if (this.aD.newAccount.value=='') {
        alert("New Account No can't be left blank!");
        this.addAuthorityForm.get('newAccount')['controls'].focus();
        return;
      }
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update wrong account no?')
        .then((confirmed) => {
          
          if (confirmed) {
            this.isLoading = true;
            this.AddProcess = true;
            this.genReqs.formDataPost('/updateOldNewPnb', formData).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.isLoading = false;
              if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                //window.open("http://localhost:4200/#/oldNewMeter","_self");
                setTimeout(() => {
                  this.modal.close('done');
                },
                  2000);
              } else {
                this.thumb = false;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.addAuthMessage = 'Unable to process yours request!';
            });
          }
        }).catch(() => console.log('no response or closed'));
    } else if (this.aD.updationType.value == 4) {
      if (this.aD.correctNewMeterId.value=='') {
        alert("New Meter No can't be left blank!");
        this.addAuthorityForm.get('correctNewMeterId')['controls'].focus();
        return;
      }
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update wrong Smart Meter No?')
        .then((confirmed) => {
          if (confirmed) {
              this.isLoading = true;
              this.AddProcess = true;
              this.genReqs.formDataPost('/updateOldNewPnb', formData).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.isLoading = false;
              if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                //window.open("http://localhost:4200/#/oldNewMeter","_self");
                setTimeout(() => {
                  this.modal.close('done');
                },
                  2000);
              } else {
                this.thumb = false;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.addAuthMessage = 'Unable to process yours request!';
            });
          }
        }).catch(() => console.log('no response or closed'));
    } else if (this.aD.updationType.value == 5) {
      this.isLoading = true;
      this.AddProcess = true;
      if (this.aD.zone.value=='') {
        alert("Zone can't be left blank!");
        return;
      }
      if (this.aD.circle.value=='') {
        alert("Circle can't be left blank!");
        return;
      }
      if (this.aD.division.value=='') {
        alert("Division can't be left blank!");
        return;
      }
      if (this.aD.sub_division.value=='') {
        alert("Sub-Division can't be left blank!");
        return;
      }
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update Zone/Circle/Division/Sub-Division Details?')
        .then((confirmed) => {
          if (confirmed) {
            this.isLoading = true;
            this.AddProcess = true;
            this.genReqs.formDataPost('/updateOldNewPnb', formData).subscribe((result) => {
              this.responseData = result;
              this.AddProcess = false;
              this.isLoading = false;
              if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                //window.open("http://localhost:4200/#/oldNewMeter","_self");
                setTimeout(() => {
                  this.modal.close('done');
                },
                  2000);
              } else {
                this.thumb = false;
              }
            }, (err) => {
              if (err.status === 401) {
                this.authenticationService.logout();
              }
              this.addAuthMessage = 'Unable to process yours request!';
            });
          }
        }).catch(() => console.log('no response or closed'));
    }
    //this.submitted = true;
    //return;
    //}

  }

  onNewMeterFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.newMeterImage.setValue(file);
    }
  }

  onOldMeterFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.oldMeterImage.setValue(file);
    }
  }

  onMcoFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aD.mcoImage.setValue(file);
    }
  }

  getOldMeterData() {
    this.isLoading = true;
    this.disableStatus = true;
    let body = new URLSearchParams();
    body.set('zoneId', this.meterDetail.zoneId);
    body.set('circleId', this.meterDetail.circleId);
    body.set('oldId', this.meterDetail.oldUid);
    body.set('newId', this.meterDetail.newMeterUid);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getOldMeterDataPnb', body).subscribe((result) => {
      this.responseData = result;
      this.isLoading = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        this.disableStatus = false;
        //console.log(this.responseData.data);
        setTimeout(() => {
          this.consumerName = this.responseData.data.consName;
          this.mobileNo = this.responseData.data.consMobile;
          this.emailId = this.responseData.data.conaEmail;
          this.address = this.responseData.data.consAddress;
          this.loadUnit = this.responseData.data.consUnit;
          this.loadLimit = this.responseData.data.consSanctLoad;
          this.oldMeterId = (this.responseData.data.oldMeterId);
          this.oldMeterReading = this.responseData.data.oldMeterReading;
          this.oldMeterRemark = this.responseData.data.oldMeterRemark;
          this.aD.consumerName.setValue(this.consumerName);
          this.aD.mobileNo.setValue(this.mobileNo);
          this.aD.emailId.setValue(this.emailId);
          this.aD.address.setValue(this.address);
          this.aD.loadUnit.setValue(this.loadUnit);
          this.aD.loadLimit.setValue(this.loadLimit);
          this.aD.oldMeterId.setValue(this.oldMeterId);
          this.aD.oldMeterReading.setValue(this.oldMeterReading);
          this.aD.oldMeterRemark.setValue(this.oldMeterRemark);

          this.aD.ivrs.setValue(this.meterDetail.ivrsNo);
          this.aD.zone.setValue(this.responseData.data.zoneId);
          this.aD.circle.setValue(this.responseData.data.circleId);
          this.aD.division.setValue(this.responseData.data.divisionId);
          this.aD.sub_division.setValue(this.responseData.data.subDivisionId);

          this.aD.newMeterId.setValue(this.meterDetail.newMeterId);
          this.aD.newmterIPAddress.setValue(this.meterDetail.newmterIPAddress);
          this.aD.newMeterReading.setValue(this.meterDetail.newMeterReading);
          this.aD.newMeterRemark.setValue(this.meterDetail.newMeterRemark);
          this.aD.newMeterLat.setValue(this.meterDetail.newMeterLat);
          this.aD.newMeterLong.setValue(this.meterDetail.newMeterLong);
          this.aD.mcoRemark.setValue(this.meterDetail.mcoRemark);
          this.getZoneData();
        });
      } else {
        this.addAuthMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.addAuthMessage = 'Unable to process yours request!';
    });
  }

}
