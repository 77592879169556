import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Access } from 'src/app/_models/access';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reporting-from-meter-dialog',
  templateUrl: './reporting-from-meter-dialog.component.html',
  styleUrls: ['./reporting-from-meter-dialog.component.scss']
})
export class ReportingFromMeterDialogComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  isLoading = false;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  reportForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  AddProcess:boolean = false;
  selectedSubDivision:any = 0;
  meterDetail:any = {};
  message:string = '';
  responseData: any;
  getGothrough : Access;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
  }

  ngOnInit() {
   // console.log(this.getGothrough.consumer);
    this.reportForm = this.formBuilder.group({
      reporting: [''],
    });
  }
  ngOnDestroy() {
    this.modal.dismiss();
}

public formControlIsValid(formGroup, formCtrl) : boolean {
  if (formGroup.controls.hasOwnProperty(formCtrl)) {
    if (!formGroup.controls[formCtrl].touched) return true;
    return formGroup.controls[formCtrl].valid;
  }
  return false;
}

get rF() { return this.reportForm.controls; }
get aD() { return this.reportForm.controls; }

onSubmitAddForm(){
  if (this.reportForm.invalid) {
    return;
  }
  this.isLoading = true;
  this.AddProcess = true;

  let body = new URLSearchParams();
  body.set('subdivId', this.currentUser.data.userData.subdivision);
  body.set('conAccId', this.currentUser.data.userData.uid);
  body.set('reporting', this.aD.reporting.value);
  body.set('authToken', this.currentUser.authToken);

  this.genReqs.postReq('/addMeterReportingConsDesh', body).subscribe((result)=>{
    this.responseData = result;
    this.AddProcess = false;
   
    this.isLoading = false;
    if (this.responseData.success) {
      this.message = this.responseData.data.message;
      //this.modal.close('done');
    }
  },(err)=>{
    if (err.status === 401) {
      this.authenticationService.logout();
    }
    this.message = 'Unable to process yours request!';
  });
}

}
