<div class="style">
  <div class="">
    <div class="col-lg-4 col-md-6 col-sm-8 col-12 mx-auto">
    
    <mat-card class="noPadding" id="recharge_review">
      <div class="display-avatar" style="direction: rtl;">
        <img src="./assets/images/logo.png" alt="profile image">
      </div>
        <mat-card-content>
          <div style="overflow-x:auto;overflow-y:auto;">
            <table width ='100%' class="t1" >
                <tr>
                  <td  style="text-align: left;font-size: x-large;" colspan="2" class="rechargeTitle">
                    <b>Recharge Receipt!</b>
                  </td>
                </tr>
                <tr>
                  <td  style="text-align: left" class="" colspan="2">
                    <b>Date :</b>{{successReacharge.reportingDate}}
                  </td>
                </tr>
                <tr>
                  <td  style="font-size: large;" colspan="2">
                      <B>Personal Details:-</B>
                  </td>
                </tr>
               
              <tr>
                <td width="50%"><b>Flat No:</b></td><td width="50%">{{successReacharge.flatId}}</td>
              </tr>
              <tr>
                <td width="50%"><b>Meter ID:</b></td><td width="50%">{{successReacharge.meterId}}</td>
              </tr>
              <tr>
                <td width="50%"><b>Owner's Name:</b></td><td width="50%">{{successReacharge.fullName}}</td>
              </tr>
              <tr>
                <td width="50%"><b>Moblie No:</b></td><td width="50%">{{successReacharge.mobileNo}}</td>
              </tr>
              <tr>
                <td width="50%"><b>Email:</b></td><td width="50%">{{successReacharge.emailId}}</td>
              </tr>
              <tr>
                <td width="50%"><b>Address:</b></td><td width="50%">{{successReacharge.adddress}}</td>
              </tr>
              <!--<tr>
                <td width="25%"><b>Service Provider:</b></td> 
                <td width="25%" colspan="3">Avon Meters Private Limited</td>
              </tr>-->
              
            </table>
            <br>
            <table width ='100%' class="t1">
              <tr>
                <td style="font-size: large;">
                  <b>Recharge Details:-</b>
                </td>
              </tr>
            </table>
            <table border=1 width ='100%'  class="t1">
              
              <tr>
                <td width="50%" ><b>Recharge Amount: </b></td><td width="50%">{{successReacharge.amount}} </td>
              </tr>
              <tr>
                <td width="50%" ><b>Recharge Type:</b> </td><td width="50%" >{{successReacharge.productInfo}} </td>
              </tr>
              <tr>
                <td width="50%" ><b>Recharge Status:</b> </td><td width="50%" >{{successReacharge.status}} </td>
              </tr>
              <tr>
                <td width="50%"><b>Payment Method:</b></td><td width="50%">{{successReacharge.mode}} </td>
              </tr>
              <tr>
                <td width="50%"><b>Payment Tran. Id:</b></td><td width="50%" >{{successReacharge.tranNo}} </td>
              </tr>
            </table>
            <table width ='100%' class="t1">
              <tr>
                <td width ='100%' style=" white-space: pre-line !important;" colspan="4">
                  Please Note :-<br>
                  1) Payment which have been made through modes other than cash are subject to clearance including through electronic mode
                  payments.<br>
                  2) This document can be used as a proof of payment.
                </td>
              </tr>
            </table>
            
          </div>
        </mat-card-content>
      </mat-card>
     <!--<mat-card class="noPadding1">
        <mat-card-content>
          <div class="col-12 text-right">
            <button type="button" class="dilog-button dilog-button1" ngxPrint printSectionId="recharge_review" >Print Slip</button>
          </div>
        </mat-card-content>
      </mat-card>-->
    
    </div>
  </div>
  <!--Mpin Login-->

  <!--Mpin Login-->
  <!-- <div class="auth_footer">
    <p class="text-muted text-center">© Avonsm</p>
  </div> -->
</div>