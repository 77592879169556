<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c8">settings_input_component </mat-icon>
        <mat-select placeholder="R- Phase" name="brphase" formControlName="brphase">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let frm of booleanVals" [value]="frm">
                {{frm.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.brphase.hasError('required')">
            brphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c8">settings_input_component </mat-icon>
        <mat-select placeholder="Y- Phase" name="byphase" formControlName="byphase">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let frm of booleanVals" [value]="frm">
                {{frm.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.byphase.hasError('required')">
            byphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c8">settings_input_component </mat-icon>
        <mat-select placeholder="B- Phase" name="bbphase" formControlName="bbphase">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let frm of booleanVals" [value]="frm">
                {{frm.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.bbphase.hasError('required')">
            bbphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit">Add</button>
</form>