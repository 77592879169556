<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()" autocomplete="off">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Change Password
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-groupp">
      <div class="row">
        <div class="col-md-12" *ngIf="set==0">
          <div class="form-group input-group">
            <span class="has-float-label ">
              <input placeholder="Old Password" name="currentpass" formControlName="currentpass"
                [type]="old ? 'password' : 'text'" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.currentpass.errors }" required>

              <span class="passwordeye" (click)="old = !old">
                <div class="form-group input-rounded input-icons">
                  <i class="{{old ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
                </div>
              </span>
              <!--<i class="fa fa-eye"></i>-->
              <i class="fa fa-unlock-alt icon_innertxtbox"></i>
              <label>Old Password</label>
              <div *ngIf="submitted && aF.currentpass.errors" class="invalid-feedback">
                <div *ngIf="aF.currentpass.errors.required">
                  Old Password is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group input-group">
            <span class="has-float-label ">
              <input placeholder=" New Password" name="newpass" formControlName="newpass"
                [type]="npass ? 'password' : 'text'" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.newpass.errors }" required>

              <span class="passwordeye" (click)="npass = !npass">
                <div class="form-group input-rounded input-icons">
                  <i class="{{npass ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
                </div>
              </span>
              <!--<i class="fa fa-eye"></i>-->
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>New Password</label>
              <div *ngIf="submitted && aF.newpass.errors" class="invalid-feedback">
                <div *ngIf="aF.newpass.errors.required">
                  New Password is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder=" {{myplaceHolderNewCon}}" name="confirmpass" formControlName="confirmpass"
                [type]="ncpass ? 'password' : 'text'" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.confirmpass.errors }" required>
              <span class="passwordeye" (click)="ncpass = !ncpass">
                <div class="form-group input-rounded input-icons">
                  <i class="{{ncpass ? 'fa fa-eye-slash' : 'fa fa-eye'}}" style="color:#333;" aria-hidden="true"></i>
                </div>
              </span>
              <!--<i class="fa fa-eye"></i>-->
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>Confirm password</label>
              <div *ngIf="submitted && aF.confirmpass.errors" class="invalid-feedback">
                <div *ngIf="aF.confirmpass.errors.required">
                  Confirm Password is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <!-- <div class="form-group input-rounded input-icons">
          <mat-form-field class="col-md-12" *ngIf="set==0">
            <i class="fa fa-lock icon iconalllbl"></i>
            <input  matInput
              placeholder=" {{myplaceHolderCurrent}}" name="currentpass" formControlName="currentpass"
              [type]="old ? 'password' : 'text'" class="form-control">
            <span class="p-viewer all_formview">
              <button type="button" mat-icon-button matSuffix (click)="old = !old">
                <mat-icon class="d-c3 iconalllbl" matPrefix>{{old ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </span>

            <mat-error *ngIf="aF.currentpass.hasError('required')">
              Old Password is required
            </mat-error>

          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="set==0">
          <div class="form-group input-rounded input-icons">
            <mat-form-field class="col-md-12" *ngIf="set==0">
              <i class="fa fa-lock icon iconalllbl"></i>
              <input autocomplete="new-password" matInput (click)="checkPlaceHolderNew()" (blur)="checkPlaceHolderNew()"
                matInput placeholder=" {{myplaceHolderNew}}" name="newpass" formControlName="newpass"
                [type]="npass ? 'password' : 'text'" class="form-control">
              <span class="p-viewer all_formview">
                <button type="button" mat-icon-button matSuffix (click)="npass = !npass">
                  <mat-icon class="d-c7 iconalllbl" matPrefix>{{npass ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>
              <mat-error *ngIf="aF.newpass.hasError('required')">
                New password is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-md-12" *ngIf="set==0">
          <div class="form-group input-rounded input-icons">
            <mat-form-field class="col-md-12" *ngIf="set==0">
             <i class="fa fa-lock icon iconalllbl"></i>
              <input matInput (click)="checkPlaceHolderNewCon()" (blur)="checkPlaceHolderNewCon()" matInput
                placeholder=" {{myplaceHolderNewCon}}" name="confirmpass" formControlName="confirmpass"
                [type]="ncpass ? 'password' : 'text'" class="form-control">
              <span class="p-viewer all_formview">
                <button type="button" mat-icon-button matSuffix (click)="ncpass = !ncpass">
                  <mat-icon class="d-c9 iconalllbl" matPrefix>{{ncpass ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>
              <mat-error *ngIf="aF.confirmpass.hasError('required')">
                Confirm password isrequired
              </mat-error>
              <mat-error *ngIf="aF.confirmpass.hasError('mustMatch')">
                Passwords do not match
              </mat-error>
            </mat-form-field>
          </div>
        </div> -->
        <!-- Default input -->
        <!-- <mat-form-field class="col-md-12" *ngIf="set==0">
          <input matInput placeholder="Enter Current Password" autocomplete="new-password"  name="currentpass" formControlName="currentpass" [type]="old ? 'password' : 'text'">
          <mat-icon matPrefix class="d-c2">lock</mat-icon>
          <button type="button" mat-icon-button matSuffix (click)="old = !old">
            <mat-icon class="d-c3" matPrefix>{{old ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="aF.currentpass.hasError('required')">
            Old Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <input autocomplete="new-password" matInput placeholder="New Password" name="newpass" formControlName="newpass" [type]="npass ? 'password' : 'text'">
          <mat-icon matPrefix class="d-c6">lock</mat-icon>
          <button type="button" mat-icon-button matSuffix (click)="npass = !npass">
            <mat-icon class="d-c7" matPrefix>{{npass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="aF.newpass.hasError('required')">
            New password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Confirm Password" autocomplete="new-password" name="confirmpass" formControlName="confirmpass" [type]="ncpass ? 'password' : 'text'">
          <mat-icon matPrefix class="d-c8">lock</mat-icon>
          <button type="button" mat-icon-button matSuffix (click)="ncpass = !ncpass">
            <mat-icon class="d-c9" matPrefix>{{ncpass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="aF.confirmpass.hasError('required')">
            Confirm password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="aF.confirmpass.hasError('mustMatch')">
            Passwords do not match
          </mat-error>
        </mat-form-field>-->
      </div>

      <!-- Grid row -->
      <div class="clearfix"></div>

      <div class="col-12 row">
        <div class="col-sm-12">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="responseMessage.length > 0">
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{responseMessage}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{responseMessage}}
            </div>
          </div>

        </div>

        <div class="col-md-12 dflexsave">
          <button type="submit" class="dilog dilog1 w_autobtn float-sm-right float-md-none">
            <mat-icon>add</mat-icon>
            Submit
          </button>
          <button type="button" (click)="modal.close('Ok click')"
            class="dilog dilog3 w_autobtn float-sm-right float-md-none">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

        </div>
      </div>
    </div>
  </div>
</form>