import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-add-meter-comand',
    templateUrl: './add-meter-comand.component.html',
    styleUrls: ['./add-meter-comand.component.scss']
})
export class AddMeterComandComponent implements OnInit {
    faSpinner = faSpinner;
    currentUser: User;
    currentUserSubscription: Subscription;
    addForm: UntypedFormGroup;
    responseMessage: string = '';
    responseData: any;
    AddProcess: boolean = false;
    subDivisionId: any;
    isLoading:boolean = false;
    thumb:boolean = false;
    addAuthMessage:string = '';
    submitted: boolean = false;

    
    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        public modal: NgbActiveModal,
        private genReqs: generalRequestsService,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    ngOnInit() {
        this.addForm = this.formBuilder.group({
            //meterSubDivision: ['', Validators.required],
            commandCode: ['', Validators.required],
            commandName: ['', Validators.required]
        });
    }

    ngAfterViewInit() {

    }
    ngOnDestroy() {
        //this.modalService.dismissAll();
    }

    get aF() { return this.addForm.controls; }
    onSubmitAddForm() {
        
        if (this.addForm.invalid) {
              this.submitted = true;
            return;
        }
        this.isLoading = true;
        let body = new URLSearchParams();
        body.set('subdivId', this.subDivisionId);
        body.set('cmdCode',this.aF.commandCode.value);
        body.set('cmdName',this.aF.commandName.value);
        body.set('authToken', this.currentUser.authToken);
        this.AddProcess = true;
        this.genReqs.postReq('/addMeterCmd', body).subscribe((result) => {
            this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
   
        }, (err) => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
        });
    }

}
