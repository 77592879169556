<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <mat-form-field class="col-md-6 form-group input-rounded">
    
    <mat-icon matPrefix class="d-c2">chrome_reader_mode</mat-icon>
      <mat-select placeholder="Meter Router Id" name="meterRouterId" formControlName="meterRouterId">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let routerId of meterRouter" [value]="routerId">
          {{routerId.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cF.meterRouterId.hasError('required')">Please choose Meter Router Id</mat-error>
    </mat-form-field>
    
    <mat-form-field class="col-md-6 form-group input-rounded">
      <mat-icon matPrefix class="d-c2">chrome_reader_mode</mat-icon>
        <mat-select placeholder="Router Channel No" name="routerChannelNo" formControlName="routerChannelNo">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let routerChannel of routChannel" [value]="routerChannel">
            {{routerChannel.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="cF.routerChannelNo.hasError('required')">Please choose Router Channel</mat-error>
      </mat-form-field>
  
      <button mat-raised-button type="submit" class="cPbg">Add</button>
</form>