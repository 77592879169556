import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-meter-config',
  templateUrl: './meter-config.component.html',
  styleUrls: ['./meter-config.component.scss']
})
export class MeterConfigComponent implements OnInit {
  public today = new Date();
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  meterDetail:any = {
    muID:0,
    mID: "All",
    dID:0,
    sdID:this.selectedSubDivision
  };
  getThrough: Access;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    private ngmodalService: NgbModal,
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    //console.log(this.meterDetail);
  }

  ngAfterViewInit() {
    //console.log(this.meterDetail);
  }

  ngOnDestroy() {
   // this.ngmodalService.dismissAll();
  }

  public formControlIsValid(formGroup, formCtrl) : boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }
  get cF() { return this.addForm.controls; }
}
