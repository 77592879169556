import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-real-time-data',
  templateUrl: './real-time-data.component.html',
  styleUrls: ['./real-time-data.component.scss']
})
export class RealTimeDataComponent implements OnInit {

  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  consAccountNo:any='';
  meterId:any='';
  RTC:any='';
  Voltage:any='';
  phaseCurrent:any='';
  neturalCurrent:any='';
  powerFactor:any='';
  Frequency:any='';
  apperantPower:any='';
  activePower:any='';
  actveImport:any='';
  apparentImport:any='';
  mDkWmport:any='';
  mDkWImportDateTime:any='';
  mDkVAImport:any='';
  mDkVAImportDateTime:any='';
  tamperCount:any='';
  billingCount:any='';
  programmingCount:any='';
  activeExport:any='';
  apparentExport:any='';
  loadConnected:any='';
  meterDetasil:any = {};

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetasil);
    this.getRealTimeStatus();
  }

 

  getRealTimeStatus() {
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetasil.subdivId);
    body.set('dcuId', '0');
    body.set('meterType', '1');
    body.set('meterId', '33');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getCurrentMetersStatus', body).subscribe((result)=>{
      this.responseData = result;
      //console.log(this.responseData);

      if(this.responseData.success){

        this.consAccountNo = this.responseData.data.consAccountNo;
        this.meterId = this.responseData.data.meterId;
        this.RTC = this.responseData.data.RTC;
        this.Voltage = this.responseData.data.Voltage;
        this.phaseCurrent = this.responseData.data.phaseCurrent;
        this.neturalCurrent = this.responseData.data.neturalCurrent;
        this.powerFactor = this.responseData.data.powerFactor;
        this.Frequency = this.responseData.data.Frequency;
        this.apperantPower = this.responseData.data.apperantPower;
        this.activePower = this.responseData.data.activePower;
        this.actveImport = this.responseData.data.actveImport;
        this.apparentImport = this.responseData.data.apparentImport;
        this.mDkWmport = this.responseData.data.mDkWmport;
        this.mDkWImportDateTime = this.responseData.mDkWImportDateTime;
        this.mDkVAImport = this.responseData.data.mDkVAImport;
        this.mDkVAImportDateTime = this.responseData.mDkVAImportDateTime;
        this.tamperCount = this.responseData.data.tamperCount;
        this.billingCount = this.responseData.data.billingCount;
        this.programmingCount = this.responseData.data.programmingCount;
        this.activeExport = this.responseData.data.activeExport;
        this.apparentExport = this.responseData.data.apparentExport;
        this.loadConnected = this.responseData.data.loadConnected;
        

      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}