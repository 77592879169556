  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
       Current Tariff
    </h4>
  </div> 
      <div class="modal-body">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
              <ng-container>
                <!--<div class="row mx-auto form-wrapper">
                  <div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                          <div class="col-9 d_title {{item.bold_class}}">
                            <span>
                              <mat-icon class="d-c10">assignment</mat-icon>
                            </span>
                            {{item.title}}
                          </div>
                          <div class="col-3 o_b d_content">
                              {{item.content}}
                          </div>
                  </div>-->
                
        <mat-accordion>
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                    <mat-icon class="d-c2">alarm</mat-icon>
                    <b>Alarm Range & Emergency Credit</b>
              </mat-expansion-panel-header>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c2">alarm</mat-icon>
                      {{letviewis[2].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[2].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c2">alarm</mat-icon>
                      {{letviewis[3].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[3].content}}
                    </div>
                </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
                    <mat-icon class="d-c3">waves</mat-icon>
                    <b> Grid Supply Energy Rates</b>
          </mat-expansion-panel-header> 
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[4].content">
                      <mat-icon class="d-c3">waves</mat-icon>
                      {{letviewis[4].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[4].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[4].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[5].content">
                      <mat-icon class="d-c3">waves</mat-icon>
                      {{letviewis[5].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[5].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[5].content}}
                    </div>
                </div>
                <div class="row o_b">
                  <div class="col-4" *ngIf="letviewis && letviewis[6].content">
                    <mat-icon class="d-c3">waves</mat-icon>
                    {{letviewis[6].title}}
                  </div>
                  <div class="col-2" *ngIf="letviewis && letviewis[6].content">
                    <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                    {{letviewis[6].content}}
                  </div>
                  <div class="col-4" *ngIf="letviewis && letviewis[7].content">
                    <mat-icon class="d-c3">waves</mat-icon>
                    {{letviewis[7].title}}
                  </div>
                  <div class="col-2" *ngIf="letviewis && letviewis[7].content">
                    <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                    {{letviewis[7].content}}
                  </div>
                </div>
                <div class="row o_b">
                  <div class="col-4" *ngIf="letviewis && letviewis[8].content">
                    <mat-icon class="d-c3">waves</mat-icon>
                    {{letviewis[8].title}}
                  </div>
                  <div class="col-2" *ngIf="letviewis && letviewis[8].content">
                    <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                    {{letviewis[8].content}}
                  </div>
                  <div class="col-4">
                   
                  </div>
                  <div class="col-2">
                    
                  </div>
                </div>
            </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
                      <mat-icon class="d-c4">waves</mat-icon>
                      <b>Grid Supply Fixed Charges</b>
            </mat-expansion-panel-header>
                <div class="row o_b">
                      <div class="col-4" *ngIf="letviewis && letviewis[12].content">
                        <mat-icon class="d-c4">waves</mat-icon>
                        {{letviewis[12].title}}
                      </div>
                      <div class="col-2" *ngIf="letviewis && letviewis[12].content">
                        <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[12].content}}
                      </div>
                      <div class="col-4" *ngIf="letviewis && letviewis[13].content">
                        <mat-icon class="d-c4">waves</mat-icon>
                        {{letviewis[13].title}}
                      </div>
                      <div class="col-2" *ngIf="letviewis && letviewis[13].content">
                        <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[13].content}}
                      </div>
                </div>
                <div class="row o_b">
                      <div class="col-4" *ngIf="letviewis && letviewis[14].content">
                        <mat-icon class="d-c4">waves</mat-icon>
                        {{letviewis[14].title}}
                      </div>
                      <div class="col-2" *ngIf="letviewis && letviewis[14].content">
                        <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[14].content}}
                      </div>
                      <div class="col-4" *ngIf="letviewis && letviewis[15].content">
                        <mat-icon class="d-c4">waves</mat-icon>
                        {{letviewis[15].title}}
                      </div>
                      <div class="col-2" *ngIf="letviewis && letviewis[15].content">
                        <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[15].content}}
                      </div>
                </div>
                <div class="row o_b">
                      <div class="col-4" *ngIf="letviewis && letviewis[16].content">
                        <mat-icon class="d-c4">waves</mat-icon>
                        {{letviewis[16].title}}
                      </div>
                      <div class="col-2" *ngIf="letviewis && letviewis[16].content">
                        <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[16].content}}
                      </div>
                </div>
          </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>         
                    <mat-icon class="d-c5">backup</mat-icon>
                    <b>Backup Supply Energy Rates</b>
          </mat-expansion-panel-header>           
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[9].content">
                      <mat-icon class="d-c5">backup</mat-icon>
                      {{letviewis[9].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[9].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[9].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[10].content">
                      <mat-icon class="d-c5">backup</mat-icon>
                      {{letviewis[10].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[10].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[10].content}}
                    </div>
                </div>
                <div class="row o_b">
                  
                    <div class="col-4" *ngIf="letviewis && letviewis[11].content">
                      <mat-icon class="d-c5">backup</mat-icon>
                      {{letviewis[11].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[11].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[11].content}}
                    </div>
                </div>
            </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>  
                    <mat-icon class="d-c6">backup</mat-icon>
                    <b>Backup Supply Fixed Charges</b>
            </mat-expansion-panel-header>       
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[17].content">
                      <mat-icon class="d-c6">backup</mat-icon>
                      {{letviewis[17].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[17].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[17].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[18].content">
                      <mat-icon class="d-c6">backup</mat-icon>
                      {{letviewis[18].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[18].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[18].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[19].content">
                      <mat-icon class="d-c6">backup</mat-icon>
                      {{letviewis[19].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[19].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[19].content}}
                    </div>
                </div>
            </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>      
               
                    <mat-icon class="d-c7">ballot</mat-icon>
                    <b>Other Fixed Charges</b>
            </mat-expansion-panel-header>      
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[20].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[20].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[20].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[20].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[21].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[21].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[21].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[21].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[22].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[22].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[22].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[22].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[23].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[23].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[23].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[23].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[24].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[24].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[24].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[24].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[25].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[25].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[25].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[25].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[26].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[26].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[26].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[26].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[27].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[27].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[27].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[27].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[28].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[28].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[28].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[28].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[29].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[29].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[29].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[29].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis && letviewis[30].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[30].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[30].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                      {{letviewis[30].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis && letviewis[31].content">
                      <mat-icon class="d-c7">ballot</mat-icon>
                      {{letviewis[31].title}}
                    </div>
                    <div class="col-2" *ngIf="letviewis && letviewis[31].content">
                      <i class="fa fa-rupee d-c7" style="font-size:18px"></i>
                        {{letviewis[31].content}}
                    </div>
                </div>
            </mat-expansion-panel>
         <!--<mat-expansion-panel>
            <mat-expansion-panel-header> 
                
                    <mat-icon class="d-c6">waves</mat-icon>
                    <b>Grid Supply Slab Rates</b>
                 
            </mat-expansion-panel-header> 
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                          <mat-icon class="d-c6">waves</mat-icon>
                          {{letviewis[32].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[32].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                        <mat-icon class="d-c6">waves</mat-icon>
                        {{letviewis[33].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[33].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[34].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[34].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[35].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[35].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[36].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[36].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[37].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[37].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[38].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[38].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[39].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[39].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[40].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[40].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[41].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[41].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[42].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[42].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[43].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[43].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[44].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[44].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[45].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[45].content}}
                    </div>
                </div>
                <div class="row o_b">
                                     
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[46].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[46].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c6">waves</mat-icon>
                      {{letviewis[47].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[47].content}}
                    </div>
                </div>
            </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>       
                    <mat-icon class="d-c7">backup</mat-icon>
                    <b>Backup Supply Slab Rates</b>
            </mat-expansion-panel-header> 
                <div class="row  o_b">
                    <div class="col-4" *ngIf="letviewis">
                          <mat-icon class="d-c7">backup</mat-icon>
                          {{letviewis[48].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[48].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                        <mat-icon class="d-c7">backup</mat-icon>
                        {{letviewis[49].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[49].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[50].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[50].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[51].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[51].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[52].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[52].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[53].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[53].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[54].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[54].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[55].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[55].content}}
                    </div>
                </div>
                <div class="row o_b">
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[56].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[56].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[57].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[57].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[58].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[58].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[59].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[59].content}}
                    </div>
                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[60].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[60].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[61].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[61].content}}
                    </div>

                </div>
                <div class="row o_b">
                 
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[62].title}}
                    </div>
                    <div class="col-3" *ngIf="letviewis">
                        {{letviewis[62].content}}
                    </div>
                    <div class="col-4" *ngIf="letviewis">
                      <mat-icon class="d-c7">backup</mat-icon>
                      {{letviewis[63].title}}
                    </div>
                    <div class="col-1" *ngIf="letviewis">
                        {{letviewis[63].content}}
                    </div>
                </div>
          </mat-expansion-panel>-->
              </mat-accordion>
              </ng-container>
          <ng-container col-sm-3>
          </ng-container>
      </div>
        