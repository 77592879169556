import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigbilltariff',
  templateUrl: './meterconfigbilltariff.component.html',
  styleUrls: ['./meterconfigbilltariff.component.scss']
})
export class MeterconfigbilltariffComponent implements OnInit {
  addForm: UntypedFormGroup;
  booleanVals = [
    {name: 'Yes'},
    {name: 'No'}
  ];
  modeMeter = [
    {name: 'kWH'},
    {name: 'kVAH'},
  ];
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      bfm: ['', Validators.required],
      bm: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
