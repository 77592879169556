import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';

@Component({
  selector: 'app-eswfilter',
  templateUrl: './eswfilter.component.html',
  styleUrls: ['./eswfilter.component.scss']
})
export class EswfilterComponent implements OnInit {
  faSpinner = faSpinner;
  cmdformData: any = 0;
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  status:boolean = true;
  actionForm: UntypedFormGroup;
  thumb:boolean = false;
  message:string = '';
  @Input() meterDetail:any = {
    mID: "All",
    dID:0,
    sdID:0
  };
  isLoading = false;
  responseData: any;
  AddProcess:boolean = false;
  getThrough: Access;
  tagValue = 'col-sm-8 text-left';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit(): void {
    this.actionForm = this.formBuilder.group({
      rPhaseVoltage: [''],
      yPhaseVoltage: [''],
      bPhaseVoltage: [''],
      overVoltage: [''],
      lowVoltage: [''],
      voltageUnbalance: [''],
      rPhaseCurrentRev: [''],
      yPhaseCurrentRev: [''],
      bPhaseCurrentRev: [''],
      currentUnbalance: [''],
      currentByPass: [''],
      overCurrent: [''],
      activationDatetime: [''],
      veryLPF: [''],
      infMagnet: [''],
      neturalDistrubance: [''],
      meterCOpen: [''],
      loadConnectDis: [''],
      lastGasp: [''],
      firstBreath: [''],
      incBillingCount: [''],
      freqAbnormal: [''],
      dgOn: [''],
      ebOn: [''],
      overloadThree: [''],
      negativeBalance: [''],
      mdReset: [''],
      zeroBalance: [''],
    });
    if (this.meterDetail.cACNO > 0 && typeof this.meterDetail.muID !== 'undefined') {
      this.getCurrentMeterConfig();
      this.cmdformData=1;
      this.tagValue = 'col-sm-10 text-left';
    }
  }
  get aF() { return this.actionForm.controls; }
  submitForm(){
    /*if (this.actionForm.invalid) {
      return;
    }*/
    
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
   
    if(this.meterDetail.sdID==''){
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }else{
       body.set('subdivId', this.meterDetail.sdID);
    }
    body.set('dcuId', this.meterDetail.dCUID);
    
    body.set('rPhaseVoltage',this.aF.rPhaseVoltage.value);
    body.set('yPhaseVoltage',this.aF.yPhaseVoltage.value);
    body.set('bPhaseVoltage',this.aF.bPhaseVoltage.value);
    body.set('overVoltage',this.aF.overVoltage.value);
    body.set('lowVoltage',this.aF.lowVoltage.value);
    body.set('voltageUnbalance',this.aF.voltageUnbalance.value);
    body.set('rPhaseCurrentRev',this.aF.rPhaseCurrentRev.value);
    body.set('yPhaseCurrentRev',this.aF.yPhaseCurrentRev.value);
    body.set('bPhaseCurrentRev',this.aF.bPhaseCurrentRev.value);

    body.set('currentUnbalance',this.aF.currentUnbalance.value);
    body.set('currentByPass',this.aF.currentByPass.value);
    body.set('overCurrent',this.aF.overCurrent.value);
    body.set('veryLPF',this.aF.veryLPF.value);
    body.set('infMagnet',this.aF.infMagnet.value);
    body.set('neturalDistrubance',this.aF.neturalDistrubance.value);
    body.set('meterCOpen',this.aF.meterCOpen.value);
    body.set('loadConnectDis',this.aF.loadConnectDis.value);
    body.set('lastGasp',this.aF.lastGasp.value);
    body.set('firstBreath',this.aF.firstBreath.value);
    body.set('mdReset',this.aF.mdReset.value);
    body.set('zeroBalance',this.aF.zeroBalance.value);
    body.set('negativeBalance',this.aF.negativeBalance.value);
    body.set('overloadThree',this.aF.overloadThree.value);
    body.set('ebOn',this.aF.ebOn.value);
    body.set('dgOn',this.aF.dgOn.value);
    body.set('freqAbnormal',this.aF.freqAbnormal.value);
    
    body.set('authLevel', this.currentUser.data.userData.authLevel);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);

    body.set('cmdFrom', '0');
    body.set('configMeter', '3');
    body.set('configurationOn', '0');
    body.set('authToken', this.currentUser.authToken);
    body.set('cmdformData',this.cmdformData);
    this.genReqs.postReq('/configESW3PhMeter', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress") || this.message.includes("Not Generate")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  onSubmitConfigPendingMeter(){
    /*if (this.actionForm.invalid) {
      return;
    }*/
    
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    let body = new URLSearchParams();
   
    if(this.meterDetail.sdID==''){
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }else{
       body.set('subdivId', this.meterDetail.sdID);
    }

    body.set('dcuId', this.meterDetail.dCUID);
    
    body.set('rPhaseVoltage',this.aF.rPhaseVoltage.value);
    body.set('yPhaseVoltage',this.aF.yPhaseVoltage.value);
    body.set('bPhaseVoltage',this.aF.bPhaseVoltage.value);
    body.set('overVoltage',this.aF.overVoltage.value);
    body.set('lowVoltage',this.aF.lowVoltage.value);
    body.set('voltageUnbalance',this.aF.voltageUnbalance.value);
    body.set('rPhaseCurrentRev',this.aF.rPhaseCurrentRev.value);
    body.set('yPhaseCurrentRev',this.aF.yPhaseCurrentRev.value);
    body.set('bPhaseCurrentRev',this.aF.bPhaseCurrentRev.value);

    body.set('currentUnbalance',this.aF.currentUnbalance.value);
    body.set('currentByPass',this.aF.currentByPass.value);
    body.set('overCurrent',this.aF.overCurrent.value);
    body.set('veryLPF',this.aF.veryLPF.value);
    body.set('infMagnet',this.aF.infMagnet.value);
    body.set('neturalDistrubance',this.aF.neturalDistrubance.value);
    body.set('meterCOpen',this.aF.meterCOpen.value);
    body.set('loadConnectDis',this.aF.loadConnectDis.value);
    body.set('lastGasp',this.aF.lastGasp.value);
    body.set('firstBreath',this.aF.firstBreath.value);
    body.set('mdReset',this.aF.mdReset.value);
    body.set('zeroBalance',this.aF.zeroBalance.value);
    body.set('negativeBalance',this.aF.negativeBalance.value);
    body.set('overloadThree',this.aF.overloadThree.value);
    body.set('ebOn',this.aF.ebOn.value);
    body.set('dgOn',this.aF.dgOn.value);
    body.set('freqAbnormal',this.aF.freqAbnormal.value);
    
    body.set('authLevel', this.currentUser.data.userData.authLevel);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);

    body.set('cmdFrom', '0');
    body.set('configMeter', '3');
    body.set('configurationOn', '1');
    body.set('authToken', this.currentUser.authToken);
    body.set('cmdformData',this.cmdformData);
    this.genReqs.postReq('/configESW3PhMeter', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if(this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress") || this.message.includes("Not Generate")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }

  getCurrentMeterConfig() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getESWFilter3', body).subscribe((result) => {
        this.responseData = result;

        if (this.responseData.success) {
          this.aF.rPhaseVoltage.setValue(this.responseData.data.rPhaseVoltage.toString());
          this.aF.yPhaseVoltage.setValue(this.responseData.data.yPhaseVoltage.toString());
          this.aF.bPhaseVoltage.setValue(this.responseData.data.bPhaseVoltage.toString());
          this.aF.overVoltage.setValue(this.responseData.data.overVoltage.toString());
          this.aF.lowVoltage.setValue(this.responseData.data.lowVoltage.toString());
          this.aF.voltageUnbalance.setValue(this.responseData.data.voltageUnbalance.toString());
          this.aF.rPhaseCurrentRev.setValue(this.responseData.data.rPhaseCurrentRev.toString());
          this.aF.yPhaseCurrentRev.setValue(this.responseData.data.yPhaseCurrentRev.toString());
          this.aF.bPhaseCurrentRev.setValue(this.responseData.data.bPhaseCurrentRev.toString());
          this.aF.currentUnbalance.setValue(this.responseData.data.currentUnbalance.toString());
          this.aF.currentByPass.setValue(this.responseData.data.currentByPass.toString());
          this.aF.overCurrent.setValue(this.responseData.data.overCurrent.toString());
          this.aF.veryLPF.setValue(this.responseData.data.veryLPF.toString());
          this.aF.infMagnet.setValue(this.responseData.data.infMagnet.toString());
          this.aF.neturalDistrubance.setValue(this.responseData.data.neturalDistrubance.toString());
          this.aF.meterCOpen.setValue(this.responseData.data.meterCOpen.toString());
          this.aF.loadConnectDis.setValue(this.responseData.data.loadConnectDis.toString());
          this.aF.lastGasp.setValue(this.responseData.data.lastGasp.toString());
          this.aF.firstBreath.setValue(this.responseData.data.firstBreath.toString());
          this.aF.mdReset.setValue(this.responseData.data.mdReset.toString());
          this.aF.zeroBalance.setValue(this.responseData.data.zeroBalance.toString());
          this.aF.negativeBalance.setValue(this.responseData.data.negativeBalance.toString());
          this.aF.overloadThree.setValue(this.responseData.data.overloadThree.toString());
          this.aF.ebOn.setValue(this.responseData.data.ebOn.toString());
          this.aF.dgOn.setValue(this.responseData.data.dgOn.toString());
          this.aF.freqAbnormal.setValue(this.responseData.data.freqAbnormal.toString());
         } 
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }
}
