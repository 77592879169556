<div class="container-fluid innerpage_top innerpage_top_graphic">
  <div class="page-content-wrapper-innerr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow tblH">
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="text hidden-sm xs-dn col-md-8">
                  <a href="#/deshauth">
                    <span>Home</span>
                  </a> >
                  <a href="javascript:void(0)" (click)="backToCountryView()">
                    <span>{{bCumCountry}}</span>
                  </a> >
                  <a href="javascript:void(0)" (click)="backToUtilityView()">
                    <span>{{bCumState}}</span>
                  </a> >
                  <a href="javascript:void(0)" (click)="backToCircleView()">
                    <span>{{bCumUtlity}}</span>
                  </a> >
                  <a href="javascript:void(0)">
                    <span>{{bCumDivision}}</span>
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="flex_auth">
                  <div class="authsearch">
                    <div class="search">
                      <input class="search-txt searchplaceholder" type="text"
                        placeholder="Search by Username/Email/Mobile No" value="{{levelSearch}}"
                        (input)="onLevelSearchChange($event.target.value)" autocomplete="off" autocomplete="off">
                      <div class="search-btn nobg">
                        <i class="fa fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <span class="toolbar-space"></span>
                    <div>
                      <button type="button" mat-mini-fab (click)="open(addcountry)" class="editbordr_circlttop">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid inner-page-content">
              <ng-container class="allutilitysloader col-sm-3" *ngIf="loadingUtilitys">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
              <div class="row t-3 mb-3" *ngIf="allutilitys">
                <div class="col-md-2" *ngFor="let label of allutilitys; index as i;">
                  <div class="border_cstm">
                    <div>
                      <div class="spacer">
                        <div class="avatar-circlee">
                          <div class="circular">
                            <img src="{{label.img_address}}" *ngIf="label.img_address" />
                            <fa *ngIf="!label.img_address" name="folder" style="color:#84ccff;" mat-card-lg-image></fa>
                          </div>
                        </div>
                      </div>
                      <mat-icon mat-button class="pointer cardAction d-c2 ditbordr" class="editbordr"
                        [matMenuTriggerFor]="dcuListMenu">menu_open
                      </mat-icon>
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="authorityListMenu 
                                editbordrr gridinnerpagee mat-menu-panellall">
                        <mat-list role="list" class="editbordrr">
                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="countryEditView(label, listmodify)"
                              class="btn-block text-left">
                              <mat-icon class="d-c13 authall">edit</mat-icon> Modify
                            </button>
                          </mat-list-item>

                          <mat-list-item role="listitem">
                            <button type="button" mat-button (click)="removelevel(label)" class="btn-block text-left">
                              <mat-icon class="d-c5">delete</mat-icon> Delete
                            </button>
                          </mat-list-item>
                        </mat-list>
                      </mat-menu>
                    </div>
                    <mat-card-footer (click)="goToDivisionView(label.division_id)">
                      <div style="WIDTH: 100%;"
                        class="card-footer flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;"
                        mat-button>
                        <div class="fullbutton">
                          <span>{{label.divisionName}}</span>
                          <mat-icon class="icons">keyboard_arrow_right</mat-icon>
                        </div>
                      </div>
                    </mat-card-footer>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!allutilitys" col-sm-3>
                {{messageUtilitys}}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content viewport ends -->
    <ng-template #addcountry let-modal>
      <form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
        <div class="modal-header subpage">
          <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
              data-dismiss="modal">&times;</button>
            Add Division
          </h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
          <div class="form-group">
            <!-- Grid row -->
            <div class="row">
              <!-- Default input -->
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input placeholder="Division Code" name="Code" formControlName="Code" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aF.Code.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Division Code</label>
                    <div *ngIf="submitted && aF.Code.errors" class="invalid-feedback">
                      <div *ngIf="aF.Code.errors.required">
                        Division Code is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="Division Name" name="Name" formControlName="Name" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aF.Name.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Division Name</label>
                    <div *ngIf="submitted && aF.Name.errors" class="invalid-feedback">
                      <div *ngIf="aF.Name.errors.required">
                        Division Name is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group input-group">
              <span class="has-float-label">
                <input type="file" name="Image" (change)="onFileSelect($event)" />
                <div *ngIf="submitted && aF.Image.errors" class="invalid-feedback">
                  <div *ngIf="aF.Image.errors.required">
                    Division image is required
                  </div>
                </div>
              </span>
            </div>

            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="col-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon matPrefix>add</mat-icon>Add
              </button>
              <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                <mat-icon matPrefix>cancel</mat-icon>Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template #listmodify let-modal>
      <form [formGroup]="editForm" (ngSubmit)="onSubmitEditForm()">
        <div class="modal-header subpage">
          <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
              data-dismiss="modal">&times;</button>
            Edit Division: {{EditName}}
          </h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-secondary" role="alert" *ngIf="responseMessage.length > 0">{{responseMessage}}</div>
          <div class="form-group">
            <!-- Grid row -->
            <div class="row">
              <!-- Default input -->
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input placeholder="Division Code" name="Code" formControlName="Code" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && eF.Code.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Division Code</label>
                    <div *ngIf="submitted && eF.Code.errors" class="invalid-feedback">
                      <div *ngIf="eF.Code.errors.required">
                        Division Code is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="Division Name" name="Name" formControlName="Name" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && eF.Name.errors }" required>
                    <i class="fa fa-globe icon_innertxtbox"></i>
                    <label>Division Name</label>
                    <div *ngIf="submitted && eF.Name.errors" class="invalid-feedback">
                      <div *ngIf="eF.Name.errors.required">
                        Division Name is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group input-group">
              <span class="has-float-label">
                <input type="file" name="Image" (change)="onFileSelectEdit($event)" />
                <div *ngIf="submitted && eF.Image.errors" class="invalid-feedback">
                  <div *ngIf="eF.Image.errors.required">
                    Division image is required
                  </div>
                </div>
              </span>
            </div>
            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="col-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon matPrefix>update</mat-icon>Update
              </button>

              <button type="button" (click)="modal.dismiss('Cross click')" class="dilog dilog3">
                <mat-icon matPrefix>cancel</mat-icon>Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>