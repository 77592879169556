<div class="container-fluid innerpage_top page-content-wrapperr">
  <div class="row page-content-wrapper-innerr">
    <div class=" col-md-12 content-viewportt">
      <div class="outerbox_shadow tblH_pagination  inner-page-content1w of-autow">

        <ng-container>
          <div class="boxflexEl">
            <h1>Meter's List
              <span class="info_icntop ml-1">
                <i data-toggle="tooltip" data-placement="top" title="All information of meter's list"
                  class="fa fa-info tabula-tooltip">
                </i>
              </span>
            </h1>
            <div>
              <button class="npMi circlebtn" type="button" [matMenuTriggerFor]="dcuListMenu">
                <i class="fa fa-ellipsis-v" data-toggle="modal" data-target="#gridoption"></i>
              </button>
              <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown 
                  modal-contentradius gridinnerpage  listaddinfogrid_bg">
                <div class="topspacemodelgrip">
                  <button type="button" class="dilog4 close_popupbtn">
                    <mat-icon matPrefix>close</mat-icon>
                  </button>
                  <div class="add_rowEgrid">Add Meter Info</div>
                </div>
                <mat-list role="list" *ngFor="let item of meterHeadsCols" class="listaddinfogrid">
                  <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                    <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                      {{item.label}}</mat-checkbox>
                  </mat-list-item>
                </mat-list>
              </mat-menu>
            </div>
          </div>
          <div class="searchflex">
            <div class="search">
              <input type="text" class="search-txt searchplaceholder" placeholder="Search Flat Id Or Meter Id"
                value="{{levelSearch}}" (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
              <a class="search-btn" href="#">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>


          <mat-card-content>
            <ng-container class="col-sm-12" *ngIf="loading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <table mat-table [dataSource]="dataMeterSource" class=""
              *ngIf="isMeterDataLoad && dataMeterSource.length > 0" matSort>

              <!-- Checkbox Column -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let row">
                  <button type="button" class="icon npMi" [matMenuTriggerFor]="dcuListMenu">
                    <mat-icon>menu_open</mat-icon>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage listactionspc">
                    <div class="topspacemodelgrip">
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <div class="add_rowEgrid">Action</div>
                    </div>
                    <mat-list role="list" class="cstmborderall">
                      <!--<mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Activity_On_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="activityOnMeter(row)">
                                      <mat-icon class="d-c5">redeem</mat-icon> Actions
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Reporting_From_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="reportFromMeter(row)">
                                      <mat-icon class="d-c6">input</mat-icon> Reporting From Meter
                                    </button>
                                  </mat-list-item>-->
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Real_Time_Status_Access">
                        <button type="button" mat-button (click)="realTimeStatus(row)">
                          <mat-icon class="d-c3">report</mat-icon> Real Time Status
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_On_Going_Consumption_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentConsumption(row)">
                          <mat-icon class="d-c7">report</mat-icon> On-Going Consumption
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Daily_Consumption_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentLoadSurvey(row)">
                          <mat-icon class="d-c13">report</mat-icon> Daily Consumption
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Recharge_Access">
                        <!--<a type="button" mat-button [routerLink]="['/reports/myrecharges']">-->
                        <button class="btn-block text-left" type="button" mat-button (click)="recentRecharge(row)">
                          <mat-icon class="d-c8">report</mat-icon> Recent Recharge
                        </button>
                        <!--</a>-->
                      </mat-list-item>
                      <mat-list-item role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Adjustment_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentAdjustment(row)">
                          <mat-icon class="d-c9">report</mat-icon> Recent Adjustment
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Configuration_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentConfiguration(row)">
                          <mat-icon class="d-c10">report</mat-icon> Recent Configuration
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Bill_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentBill(row)">
                          <mat-icon class="d-c11">report</mat-icon> Recent Bill
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Event_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentEvent(row)">
                          <mat-icon class="d-c12">report</mat-icon> Recent Events
                        </button>
                      </mat-list-item>

                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Activity_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentActivity(row)">
                          <mat-icon class="d-c14">report</mat-icon>Recent Activity
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_SMS_Sent_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentSMSSent(row)">
                          <mat-icon class="d-c15">report</mat-icon> Recent SMS Sent
                        </button>
                      </mat-list-item>
                      <mat-list-item class="cstmbordergrid" role="listitem"
                        *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Complaints_Access">
                        <button class="btn-block text-left" type="button" mat-button (click)="recentComplaints(row)">
                          <mat-icon class="d-c2">report</mat-icon> Recent Complaints
                        </button>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </td>
              </ng-container>

              <ng-container matColumnDef="srNo">
                <th mat-header-cell *matHeaderCellDef>Sr. No</th>
                <td mat-cell *matCellDef="let element; let j = index;" style="text-align: left;">
                  {{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="cACNO"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Cons_UId_Column">
                <th title="Sort By Cons UID" mat-header-cell *matHeaderCellDef mat-sort-header> Cons. UId </th>
                <td mat-cell *matCellDef="let element">
                  {{element.cACNO}}
                </td>
              </ng-container>
              <ng-container matColumnDef="muID"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_UId_Column">
                <th title="Sort By Meter UID" mat-header-cell *matHeaderCellDef mat-sort-header> Meter UId </th>
                <td mat-cell *matCellDef="let element">
                  {{element.muID}}
                </td>
              </ng-container>

              <ng-container matColumnDef="flatNo"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                <th title="Sort By Flat No" mat-header-cell *matHeaderCellDef mat-sort-header> Flat No </th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.flatNo}}
                </td>
              </ng-container>

              <ng-container matColumnDef="dgName"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_DG_Column">
                <th title="Sort By DG ID" mat-header-cell *matHeaderCellDef mat-sort-header> DG Id </th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.dgName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="towerName"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_Tower_Column">
                <th title="Sort By Tower ID" mat-header-cell *matHeaderCellDef mat-sort-header> Tower ID</th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.towerName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="groupName"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_Group_Column">
                <th title="Sort By Group ID" mat-header-cell *matHeaderCellDef mat-sort-header> Group ID</th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.groupName}}
                </td>
              </ng-container>


              <ng-container matColumnDef="mID" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_No_Column">
                <th title="Sort By Meter No" mat-header-cell *matHeaderCellDef mat-sort-header> Meter No </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mID}}
                </td>
              </ng-container>

              <ng-container matColumnDef="hesId"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_HES_Id_Column">
                <th title="Sort By HES ID" mat-header-cell *matHeaderCellDef mat-sort-header> HES ID </th>
                <td mat-cell *matCellDef="let element">
                  {{element.hesId}}
                </td>
              </ng-container>

              <ng-container matColumnDef="mType"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_Type_Column">
                <th title="Sort By Meter Type" mat-header-cell *matHeaderCellDef mat-sort-header> Meter Type </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mType}}
                </td>
              </ng-container>
              <ng-container matColumnDef="mIPAdd"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Meter_Ip_Column">
                <th title="Sort By IP Address" mat-header-cell *matHeaderCellDef mat-sort-header> IP Address</th>
                <td mat-cell *matCellDef="let element">
                  {{element.mIPAdd}}
                </td>
              </ng-container>
              <ng-container matColumnDef="mLLEBSupply"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_LL_Grid_kW_Column">
                <th title="Sort By LL kW(kW)" mat-header-cell *matHeaderCellDef mat-sort-header>LL Grid(kW)</th>
                <td mat-cell *matCellDef="let element">
                  {{element.mLLEBSupply}}
                </td>
              </ng-container>

              <ng-container matColumnDef="mLLDGSupply"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_LL_DG_kW_Column">
                <th title="Sort By LL DG(kW)" mat-header-cell *matHeaderCellDef mat-sort-header>LL DG(kW)</th>
                <td mat-cell *matCellDef="let element">
                  {{element.mLLDGSupply}}
                </td>
              </ng-container>

              <ng-container matColumnDef="mRTC"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Last_Update_Column">
                <th title="Last update" mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mRTC}}
                </td>
              </ng-container>
              <ng-container matColumnDef="voltR"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Voltage_R_Column">
                <th title="Sort By Voltage-R" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-R </th>
                <td mat-cell *matCellDef="let element">
                  {{element.voltR}}
                </td>
              </ng-container>
              <ng-container matColumnDef="voltY"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Voltage_Y_Column">
                <th title="Sort By Voltage-Y" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-Y </th>
                <td mat-cell *matCellDef="let element">
                  {{element.voltY}}
                </td>
              </ng-container>

              <ng-container matColumnDef="voltB"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Voltage_B_Column">
                <th title="Sort By Voltage-B" mat-header-cell *matHeaderCellDef mat-sort-header> Voltage-B</th>
                <td mat-cell *matCellDef="let element">
                  {{element.voltB}}
                </td>
              </ng-container>
              <ng-container matColumnDef="phaseCurrentR"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Phase_Current_R_Column">
                <th title="Sort By Phase Currren-R" mat-header-cell *matHeaderCellDef mat-sort-header> Phase Currren-R
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.phaseCurrentR}}
                </td>
              </ng-container>
              <ng-container matColumnDef="phaseCurrentY"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Phase_Current_Y_Column">
                <th title="Sort By Phase Currren-Y" mat-header-cell *matHeaderCellDef mat-sort-header> Phase Currren-Y
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.phaseCurrentY}}
                </td>
              </ng-container>
              <ng-container matColumnDef="phaseCurrentB"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Phase_Current_B_Column">
                <th title="Sort By Phase Currren-B" mat-header-cell *matHeaderCellDef mat-sort-header> Phase Current-B
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.phaseCurrentB}}
                </td>
              </ng-container>
              <ng-container matColumnDef="ncR"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Nutural_Current_R_Column">
                <th title="Sort By Neutral Currren-R" mat-header-cell *matHeaderCellDef mat-sort-header> Neutral
                  Current-R </th>
                <td mat-cell *matCellDef="let element">
                  {{element.ncR}}
                </td>
              </ng-container>
              <ng-container matColumnDef="ncY"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Nutural_Current_Y_Column">
                <th title="Sort By Neutral Currren-Y" mat-header-cell *matHeaderCellDef mat-sort-header> Neutral
                  Current-Y</th>
                <td mat-cell *matCellDef="let element">
                  {{element.ncY}}
                </td>
              </ng-container>
              <ng-container matColumnDef="ncB"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Nutural_Current_B_Column">
                <th title="Sort By Neutral Currren-B" mat-header-cell *matHeaderCellDef mat-sort-header> Neutral
                  Current-B </th>
                <td mat-cell *matCellDef="let element">
                  {{element.ncB}}
                </td>
              </ng-container>
              <ng-container matColumnDef="pfR" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_PF_R_Column">
                <th title="Sort By Neutral PF-R" mat-header-cell *matHeaderCellDef mat-sort-header> PF-R </th>
                <td mat-cell *matCellDef="let element">
                  {{element.pfR}}
                </td>
              </ng-container>
              <ng-container matColumnDef="pfY" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_PF_Y_Column">
                <th title="Sort By Neutral PF-Y" mat-header-cell *matHeaderCellDef mat-sort-header> PF-Y </th>
                <td mat-cell *matCellDef="let element">
                  {{element.pfY}}
                </td>
              </ng-container>
              <ng-container matColumnDef="pfB" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_PF_B_Column">
                <th title="Sort By Neutral PF-B" mat-header-cell *matHeaderCellDef mat-sort-header> PF-B </th>
                <td mat-cell *matCellDef="let element">
                  {{element.pfB}}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalPF"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Total_Power_Factor_Column">
                <th title="Sort By Total PF" mat-header-cell *matHeaderCellDef mat-sort-header>Total PF</th>
                <td mat-cell *matCellDef="let element">
                  {{element.totalPF}}
                </td>
              </ng-container>
              <ng-container matColumnDef="frequency"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Frequency_Column">
                <th title="Sort By Frequency" mat-header-cell *matHeaderCellDef mat-sort-header> Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{element.frequency}}
                </td>
              </ng-container>
              <ng-container matColumnDef="kWHPower"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Active_Power_Column">
                <th title="Sort By kW Power" mat-header-cell *matHeaderCellDef mat-sort-header> kW Power </th>
                <td mat-cell *matCellDef="let element">
                  {{element.kWHPower}}
                </td>
              </ng-container>
              <ng-container matColumnDef="kvarhPower"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Reactive_Power_Column">
                <th title="Sort By kVAR Power" mat-header-cell *matHeaderCellDef mat-sort-header> kVAR Power </th>
                <td mat-cell *matCellDef="let element">
                  {{element.kvarhPower}}
                </td>
              </ng-container>
              <ng-container matColumnDef="kvahPower"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_ApperantPower_kVA_Column">
                <th title="Sort By kVA Power" mat-header-cell *matHeaderCellDef mat-sort-header> kVA Power </th>
                <td mat-cell *matCellDef="let element">
                  {{element.kvahPower}}
                </td>
              </ng-container>
              <ng-container matColumnDef="mdKW"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Md_Kw_Column">
                <th title="Sort By MD kW" mat-header-cell *matHeaderCellDef mat-sort-header>MD kW</th>
                <td mat-cell *matCellDef="let element">
                  {{element.mdKW}}
                </td>
              </ng-container>
              <ng-container matColumnDef="mdKva"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_Md_kVA_Column">
                <th title="Sort By MD kVA" mat-header-cell *matHeaderCellDef mat-sort-header>MD kVA</th>
                <td mat-cell *matCellDef="let element">
                  {{element.mdKva}}
                </td>
              </ng-container>

              <ng-container matColumnDef="activeEnergyEB"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kWH_Column">
                <th title="Sort By Grid kWH" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kWH </th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.activeEnergyEB}}
                </td>
              </ng-container>
              <ng-container matColumnDef="reactiveEnergyEBQ1"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kVARH_Q1_Column">
                <th title="Sort By Grid kVARH Q1" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVARH Q1</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.reactiveEnergyEBQ1}}
                </td>
              </ng-container>
              <ng-container matColumnDef="reactiveEnergyEBQ2"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kVARH_Q2_Column">
                <th title="Sort By Grid kVARH Q2" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVARH Q2</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.reactiveEnergyEBQ2}}
                </td>
              </ng-container>
              <ng-container matColumnDef="reactiveEnergyEBQ3"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kVARH_Q3_Column">
                <th title="Sort By Grid kVARH Q3" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVARH Q3</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.reactiveEnergyEBQ3}}
                </td>
              </ng-container>
              <ng-container matColumnDef="reactiveEnergyEBQ4"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kVARH_Q4_Column">
                <th title="Sort By Grid kVARH Q4" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVARH Q4</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.reactiveEnergyEBQ4}}
                </td>
              </ng-container>

              <ng-container matColumnDef="tamperCount"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Tamper_Count_Column">
                <th title="Sort By Tamper Count" mat-header-cell *matHeaderCellDef mat-sort-header>TamperCount</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.tamperCount}}
                </td>
              </ng-container>
              <ng-container matColumnDef="billingCount"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Billing_Count_Column">
                <th title="Sort By Billing Count" mat-header-cell *matHeaderCellDef mat-sort-header>Billing Count</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.billingCount}}
                </td>
              </ng-container>



              <ng-container matColumnDef="apperantEnergyEB"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Grid_kVAH_Column">
                <th title="Sort By Grid kVAH" mat-header-cell *matHeaderCellDef mat-sort-header> Grid kVAH </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.apperantEnergyEB}}
                </td>
              </ng-container>

              <ng-container matColumnDef="activeEnergyDG"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_DG_kWH_Column">
                <th title="Sort By DG kWH" mat-header-cell *matHeaderCellDef mat-sort-header> DG kWH</th>
                <td width="10%" mat-cell *matCellDef="let element">
                  {{element.activeEnergyDG}}
                </td>
              </ng-container>
              <ng-container matColumnDef="apperantEnergyDG"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_DG_kVAH_Column">
                <th title="Sort By DG kVAH" mat-header-cell *matHeaderCellDef mat-sort-header> DG kVAH</th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  {{element.apperantEnergyDG}}
                </td>
              </ng-container>


              <ng-container matColumnDef="mlprID"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                <th mat-header-cell *matHeaderCellDef> LPR ID </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mlprID}}
                </td>
              </ng-container>

              <!--<ng-container matColumnDef="availableAmount" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                    <th mat-header-cell *matHeaderCellDef >Balance (Rs.)</th>
                    <td width="10%" mat-cell *matCellDef="let element">
                      {{element.availableAmount}}
                    </td>
                  </ng-container>-->

              <ng-container matColumnDef="availableAmount"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Balance_Column">
                <th title="Sort By Balance" mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>


                <td mat-cell *matCellDef="let element" style="text-align: right;">
                  <div class="led-box" *ngIf="element.availableAmount=='I'">

                    <div>---</div>

                  </div>
                  <div class="led-box"
                    *ngIf="element.availableAmount > 0 && element.availableAmount <= element.lowBalance">

                    <div class="icn_dg balancered">{{element.availableAmount}}</div>

                  </div>
                  <div class="led-box" *ngIf="element.availableAmount <=0">

                    <div class="icn_dg balancered">{{element.availableAmount}}</div>

                  </div>
                  <div class="led-box" *ngIf="element.availableAmount > element.lowBalance">

                    <div class="icn_dg balancered">{{element.availableAmount}}</div>

                  </div>
                </td>

              </ng-container>



              <ng-container matColumnDef="mSimNo"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                <th mat-header-cell *matHeaderCellDef> Sim No </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mSimNo}}
                </td>
              </ng-container>


              <ng-container matColumnDef="mIMEINo"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                <th mat-header-cell *matHeaderCellDef> IMEI No </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mIMEINo}}
                </td>
              </ng-container>



              <ng-container matColumnDef="mILA" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                <th mat-header-cell *matHeaderCellDef> Location </th>
                <td mat-cell *matCellDef="let element">
                  {{element.mILA}}
                </td>
              </ng-container>

              <ng-container matColumnDef="recentLsDateTime"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Last_LS_DT_Column">
                <th title="Sort By Last LS D/T" mat-header-cell *matHeaderCellDef mat-sort-header>Last LS D/T </th>
                <td mat-cell *matCellDef="let element">
                  {{element.recentLsDateTime}}
                </td>
              </ng-container>
              <ng-container matColumnDef="recentBlockLsDateTime"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Last_Block_LS_DT_Column">
                <th title="Sort By Last Block LS D/T" mat-header-cell *matHeaderCellDef mat-sort-header>Last Block LS
                  D/T </th>
                <td mat-cell *matCellDef="let element">
                  {{element.recentBlockLsDateTime}}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="lowBalance">
                            <th mat-header-cell *matHeaderCellDef> Low Balance </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{ element.availableAmount <= element.lowBalance ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="currentRunningMode">
                            <th mat-header-cell *matHeaderCellDef> DG </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.currentRunningMode=='E' ? 'dtatusOffColor' : 'dtatusOnColor'}}"></fa>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>-->

              <ng-container matColumnDef="currentRunningMode"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Supply_Column">
                <th title="Sort By Supply" mat-header-cell *matHeaderCellDef mat-sort-header> Supply </th>
                <td mat-cell *matCellDef="let element">
                  <div class="led-box" *ngIf="element.currentRunningMode=='I'">
                    <div>---</div>
                  </div>
                  <div class="led-box" *ngIf="element.currentRunningMode=='E'">
                    <!--<div style="color:green">Grid</div>-->
                    <img class="blink-image" src="assets/images/gridsupply.png" style="width: 18px;">
                  </div>
                  <div class="led-box" *ngIf="element.currentRunningMode=='D'">
                    <!--<div style="color:red">DG</div>-->
                    <img class="blink-image" src="assets/images/generac.png" style="width: 18px;">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="tamperStatus"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Tamper_Column">
                <th title="Sort By Tamper" mat-header-cell *matHeaderCellDef mat-sort-header>Tamper</th>
                <td mat-cell *matCellDef="let element">
                  <div class="led-box" *ngIf="element.tamperStatus=='0' || element.tamperStatus==''">
                    <!--<div class="led-white"></div>-->
                    ---
                  </div>
                  <!--<div class="led-box" *ngIf="element.tamperStatus!='0'">
                                  <div class="led-red"></div>
                                </div>-->

                  <div class="" *ngIf="element.tamperStatus!='0'">
                    <div>
                      <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                    </div>
                  </div>
                </td>
              </ng-container>

              <!--<ng-container matColumnDef="lowBalance" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Flat_Id_Column">
                            <th mat-header-cell *matHeaderCellDef > Credit </th>
                            <td width="5%" mat-cell *matCellDef="let element">
                                <div class="led-box" *ngIf="element.availableAmount <= element.lowBalance">
                                 
                                  <div class="led-red"></div>
                                </div>
                                <div class="led-box" *ngIf="element.availableAmount > element.lowBalance">
                                  <div class="led-green"></div>
                                  
                                </div>
                            </td>
                          </ng-container>-->

              <ng-container matColumnDef="mActiveStatus"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Relay_Status_Column">
                <th title="Sort By Relay Status" mat-header-cell *matHeaderCellDef mat-sort-header> Relay Status </th>
                <td mat-cell *matCellDef="let element">
                  <div class="led-box" *ngIf="element.mActiveStatus=='I'">
                    <!--<div class="led-red"></div>-->
                    <div>---</div>
                  </div>
                  <div class="" *ngIf="element.mActiveStatus=='N'">
                    <div><img class="blink-image" style="width: 25px;" src="assets/images/Disconnect.png" /></div>

                  </div>
                  <div class="" *ngIf="element.mActiveStatus=='Y'">
                    <div><img class="blink-image" style="width: 25px;" src="assets/images/Connect.png" /></div>
                  </div>
                </td>
              </ng-container>

              <!--<ng-container matColumnDef="currentRunningMode">
                            <th mat-header-cell *matHeaderCellDef > EB/DG </th>
                            <td width="5%" mat-cell *matCellDef="let element">
                                <div class="led-box" *ngIf="element.currentRunningMode=='E'">
                                  <div class="led-green"></div>
                                  
                                </div>
                                <div class="led-box" *ngIf="element.currentRunningMode!='E'">
                                  <div class="led-red"></div>
                                  
                                </div>
                            </td>
                          </ng-container>
        
                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef > Active </th>
                            <td width="5%" mat-cell *matCellDef="let element">
                                <div class="led-box" *ngIf="element.mActiveStatus!='Y'">
                                  <div class="led-red"></div>
                                  
                                </div>
                                <div class="led-box" *ngIf="element.mActiveStatus=='Y'">
                                 
                                  <div class="led-green"></div>
                                </div>
                            </td>
                          </ng-container>-->
              <ng-container matColumnDef="deadMeters"
                *ngIf="getThrough.consumer && getThrough.consumer.Cons_Live_Status_Column">
                <th title="Sort By Live Status" mat-header-cell *matHeaderCellDef mat-sort-header> Live Status </th>
                <td mat-cell *matCellDef="let element">
                  <div class="led-box" *ngIf="element.deadMeters=='I'">
                    <div>---</div>
                  </div>
                  <div class="" *ngIf="element.deadMeters=='Y'">
                    <!--<div class="led-red"></div>-->
                    <div><i class="disconnectedic fa fa-times"><i><span></span></i></i></div>
                  </div>
                  <div class="" *ngIf="element.deadMeters=='N'">
                    <div><img style="width: 18px;" src="assets/images/connected.png" /></div>
                    <!--<div class="led-green"></div>-->
                  </div>
                </td>
              </ng-container>


              <!-- Group1 Column -->
              <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
              <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
              </tr>
            </table>
          </mat-card-content>



          <!--<mat-card class="-mt-20">
              <mat-card-content>
                <div class="row">
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Voltage<br>
                          240.52
                        </div>
                        <div class="col-2">
                        <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Current<br>
                          240.52
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Current<br>
                          240.52
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Current<br>
                          240.52
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Grid3
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Grid3
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Grid3
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-10">
                          Grid3
                        </div>
                        <div class="col-2">
                          <img src="assets/images/avonsm.jpg" alt="Girl in a jacket" width="20" height="20">
                        </div>
                      </div>
                    </div>
                </div>
              </mat-card-content>
              </mat-card>-->

        </ng-container>
      </div>

      <mat-card-footer>
        <!--<mat-paginator #meterpaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                  [length]="meterTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons>
                  
                </mat-paginator>-->
        <mat-paginator #meterpaginator [length]="length" [pageSize]="allmeterlimit"
          [pageSizeOptions]="getPageSizeOptions()" (page)="changeAllMeterPage($event)" showFirstLastButtons>
        </mat-paginator>
      </mat-card-footer>
    </div>
  </div>
</div>

<!--
<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
      <div class="row py-3 headerArea commonClass h-180">
        <div class="col-12">
          <h2 class="mt-0 mb-1 col-12 with-icon">
            <mat-icon>
              assignment_ind
            </mat-icon> {{currentUser.data.userData.name | titlecase}}
          </h2>
        </div>
      </div>
      <div class="row inner-page-content of-auto">
        <ng-container class="allutilitysloader col-sm-12" *ngIf="!isMeterDataLoad">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        <ng-container *ngIf="isMeterDataLoad">
          <div class="col-12 plr_c25px">
            <mat-card class="-mt-20">
              <mat-card-content>
                <table mat-table [dataSource]="dataMeterSource"
                          class="" *ngIf="isMeterDataLoad && dataMeterSource.length > 0">-->

<!-- Checkbox Column -->
<!--<ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                              <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Report_Menu == 1">
                                <mat-icon>menu_open</mat-icon>
                              </button>
                              <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                <mat-list role="list">-->
<!--<mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Activity_On_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="activityOnMeter(row)">
                                      <mat-icon class="d-c5">redeem</mat-icon> Actions
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Reporting_From_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="reportFromMeter(row)">
                                      <mat-icon class="d-c6">input</mat-icon> Reporting From Meter
                                    </button>
                                  </mat-list-item>-->
<!-- <mat-list-item role="listitem" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Real_Time_Status_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="realTimeStatus(row)">
                                      <mat-icon class="d-c3">report</mat-icon> Real Time Status
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Consumption_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentConsumption(row)">
                                      <mat-icon class="d-c7">report</mat-icon> Recent Consumption
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Recharge_Dilog_Access == 1">
                                  -->
<!--<a type="button" mat-button [routerLink]="['/reports/myrecharges']">-->
<!--<button type="button" mat-button (click)="recentRecharge(row)">
                                       <mat-icon class="d-c8">report</mat-icon> Recent Recharge
                                    </button>-->
<!--</a>-->
<!-- </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Daily_Adjustment_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentAdjustment(row)">
                                      <mat-icon class="d-c9">report</mat-icon> Recent Adjustment
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Configuration_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentConfiguration(row)">
                                      <mat-icon class="d-c10">report</mat-icon> Recent Configuration
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Bill_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentBill(row)">
                                      <mat-icon class="d-c11">report</mat-icon> Recent Bill
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Events_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentEvent(row)">
                                      <mat-icon class="d-c12">report</mat-icon> Recent Events
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Load_Survey_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentLoadSurvey(row)">
                                      <mat-icon class="d-c13">report</mat-icon> Recent Load Survey
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Activity_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentActivity(row)">
                                      <mat-icon class="d-c14">report</mat-icon>Recent Activity
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_SMS_Sent_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentSMSSent(row)">
                                      <mat-icon class="d-c15">report</mat-icon> Recent SMS Sent
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Complaints_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentComplaints(row)">
                                      <mat-icon class="d-c2">report</mat-icon> Recent Complaints
                                    </button>
                                  </mat-list-item>

                                  
                                </mat-list>
                              </mat-menu>
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="cACNO">
                            <th mat-header-cell *matHeaderCellDef> Consumer Id </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.cACNO}}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mID">
                            <th mat-header-cell *matHeaderCellDef> Meter ID </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.mID}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mRTC">
                            <th mat-header-cell *matHeaderCellDef> Last Update </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.mRTC}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="activeEnergyEB">
                            <th mat-header-cell *matHeaderCellDef> Grid Units (kWH) </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.activeEnergyEB}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="activeEnergyDG">
                            <th mat-header-cell *matHeaderCellDef> DG Units (kWH)</th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.activeEnergyDG}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="availableAmount">
                            <th mat-header-cell *matHeaderCellDef>Balance Amount</th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              {{element.availableAmount}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="lowBalance">
                            <th mat-header-cell *matHeaderCellDef> Low Balance </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{ element.availableAmount <= element.lowBalance ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="currentRunningMode">
                            <th mat-header-cell *matHeaderCellDef> DG </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.currentRunningMode=='E' ? 'dtatusOffColor' : 'dtatusOnColor'}}"></fa>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td width="10%" mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>-->

<!-- Group1 Column -->
<!--<tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                          <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                          </tr>
                        </table>
              </mat-card-content>
              <mat-card-footer>
                <mat-paginator #meterpaginator [pageSize]="meterlimit" [pageSizeOptions]="meterpageLimit"
                                [length]="meterTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons></mat-paginator>
              </mat-card-footer>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>-->
<!-- content viewport ends -->
<!--<div class="page-content-wrapper" style="margin-top:0;">
  <div class="page-content-wrapper-inner">
    <div class="content-viewport">
       <div class="row py-3 headerArea commonClass h-180">
        <div class="col-12">
          <h2 class="mt-0 mb-1 col-12 with-icon">
            <mat-icon class="d-c4" >
              assignment_ind
            </mat-icon> {{currentUser.data.userData.name | titlecase}}
          </h2>
        </div>
      </div>

      

      <div class="row inner-page-content of-auto">-->
<!-- user monthly consumption graph -->
<!--<ng-container class="allutilitysloader col-sm-12" *ngIf="!isMeterDataLoad">
          <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
        </ng-container>
        <ng-container *ngIf="isMeterDataLoad">
          <div class="col-12  mb-30">
            <mat-card class="mt-20">
              <mat-card-content>
                <table mat-table [dataSource]="dataMeterSource" *ngIf="isMeterDataLoad && dataMeterSource.length > 0">-->
<!-- Checkbox Column -->
<!--<ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                              <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Report_Menu == 1">
                                <mat-icon>menu_open</mat-icon>
                              </button>
                              <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                                <mat-list role="list">
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Activity_On_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="activityOnMeter(row)">
                                      <mat-icon class="d-c5">redeem</mat-icon> Activity On Meter
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Reporting_From_Meter_Dilog_Access == 1" >
                                    <button type="button" mat-button (click)="reportFromMeter(row)">
                                      <mat-icon class="d-c6">input</mat-icon> Reporting From Meter
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer &&  getThrough.consumer.Cons_Real_Time_Status_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="realTimeStatus(row)">
                                      <mat-icon class="d-c3">report</mat-icon> Real Time Status
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Consumption_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentConsumption(row)">
                                      <mat-icon class="d-c7">report</mat-icon> Recent Consumption
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Recharge_Dilog_Access == 1">-->
<!--<a type="button" mat-button [routerLink]="['/reports/myrecharges']">-->
<!--<button type="button" mat-button (click)="recentRecharge(row)">
                                       <mat-icon class="d-c8">report</mat-icon> Recent Recharge
                                    </button>-->
<!--</a>-->
<!--</mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Daily_Adjustment_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentAdjustment(row)">
                                      <mat-icon class="d-c9">report</mat-icon> Recent Adjustment
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Configuration_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentConfiguration(row)">
                                      <mat-icon class="d-c10">report</mat-icon> Recent Configuration
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Bill_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentBill(row)">
                                      <mat-icon class="d-c11">report</mat-icon> Recent Bill
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Events_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentEvent(row)">
                                      <mat-icon class="d-c12">report</mat-icon> Recent Events
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Load_Survey_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentLoadSurvey(row)">
                                      <mat-icon class="d-c13">report</mat-icon> Recent Load Survey
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Activity_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentActivity(row)">
                                      <mat-icon class="d-c14">report</mat-icon>Recent Activity
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_SMS_Sent_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentSMSSent(row)">
                                      <mat-icon class="d-c15">report</mat-icon> Recent SMS Sent
                                    </button>
                                  </mat-list-item>
                                  <mat-list-item role="listitem" *ngIf="getThrough.consumer && getThrough.consumer.Cons_Recent_Complaints_Dilog_Access == 1">
                                    <button type="button" mat-button (click)="recentComplaints(row)">
                                      <mat-icon class="d-c2">report</mat-icon> Recent Complaints
                                    </button>
                                  </mat-list-item>

                                  
                                </mat-list>
                              </mat-menu>
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mID">
                            <th mat-header-cell *matHeaderCellDef> Meter ID </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mID}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mlprID">
                            <th mat-header-cell *matHeaderCellDef> LPR ID </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mlprID}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mSimNo">
                            <th mat-header-cell *matHeaderCellDef> Sim No </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mSimNo}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mIMEINo">
                            <th mat-header-cell *matHeaderCellDef> IMEI No </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mIMEINo}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mRTC">
                            <th mat-header-cell *matHeaderCellDef> RTC </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mRTC}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mILA">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.mILA}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="mActiveStatus">
                            <th mat-header-cell *matHeaderCellDef> Active Status </th>
                            <td mat-cell *matCellDef="let element">
                              <fa name="circle" size="2x"
                                class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                            </td>
                          </ng-container>-->

<!-- Group1 Column -->
<!-- <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                          <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                          </tr>
                        </table>
              </mat-card-content>
              <mat-card-footer>
                <mat-paginator #meterpaginator [pageSize]="meterlimit" [pageSizeOptions]="meterpageLimit"
                                [length]="meterTotalRecord" (page)="changeMeterPage($event)" showFirstLastButtons></mat-paginator>
              </mat-card-footer>
            </mat-card>
          </div>
          <div class="clearfix"></div>
        </ng-container>-->
<!--<ng-container>
    <div class="col-sm-12 col-lg-12">
      <div class="row">
        <div class="col-sm-8 col-lg-8">
          <div class="row">
          <div class="col-sm-6 col-lg-6 py-4">
            <div class="overview-item overview-item--c1">
              <div class="overview__inner">
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                      <div class="overview-box clearfix">
                        <div class="icon">
                            <h3>Cumulative</h3>
                        </div>
                        <div class="text">
                            <b>{{cumulativeEB}} </b>
                            <span>kWH Grid</span><br>
                            <b>{{cumulativeDG}} </b>
                            <span>kWH Backup</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <div class="overview-box clearfix">
                      <div class="chart">
                        <canvas baseChart
                          [data]="cumulativepieChartData"
                          [chartType]="cumulativepieChartType"
                          [colors]="cumulativepieChartColors" 
                          height= "111px" width="160px">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </div>

          <div class="col-sm-6 col-lg-6 py-4">
            <div class="overview-item overview-item--c2">
                <div class="overview__inner">
                  <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="overview-box clearfix">
                          <div class="icon">
                              <h3>Monthly</h3>
                          </div>
                          <div class="text">
                              <b>{{monthlyEB}} </b>
                              <span>kWH Grid</span><br>
                              <b>{{monthlyDG}} </b>
                              <span>kWH Backup</span>
                          </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                      <div class="overview-box clearfix">
                        <div class="chart">
                          <canvas baseChart
                            [data]="monthlypieChartData"
                            [chartType]="monthlypieChartType"
                            [colors]="monthlypieChartColors" 
                            
                            height= "111vh" width="160px">
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        </div>
        <div class="row">
        <div class="col-sm-6 col-lg-6 ">
          <div class="overview-item overview-item--c3">
            <div class="overview__inner">
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="overview-box clearfix">
                      <div class="icon">
                          <h3>On-Going</h3>
                      </div>
                      <div class="text">
                          <b>{{ongoingEB}} </b>
                          <span>kWH Grid</span><br>
                          <b>{{ongoingDG}} </b>
                          <span>kWH Backup</span>
                      </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="overview-box clearfix">
                    <div class="chart">
                      <canvas baseChart
                        [data]="ongoingpieChartData"
                        [chartType]="ongoingpieChartType"
                        [colors]="ongoingpieChartColors" 
                        
                        height= "111vh" width="160px">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
        <div class="col-sm-6 col-lg-6 ">
          <div class="overview-item overview-item--c5">
            <div class="overview__inner">
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="overview-box clearfix">
                      <div class="icon">
                          <h3>Supply Mode</h3>
                      </div>
                      <div class="text">
                          <b> </b>
                          <span>Grid</span><br>
                          <b> </b>
                          <span>Backup</span>
                      </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="overview-box clearfix">
                    <div class="chart">
                      <canvas baseChart
                        [data]="ongoingpieChartData"
                        [chartType]="ongoingpieChartType"
                        [colors]="ongoingpieChartColors" 
                        
                        height= "111vh" width="160px">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
        </div>

          <div class="col-sm-4 col-lg-4">
            <div class="row">
              <div class="col-sm-12 col-lg-12 py-4">
                <div class="overview-item overview-item--c6">
                    <div class="overview__inner">
                      
                      <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="overview-box clearfix">
                              <div class="icon">
                                  <h3>Account Balance</h3>
                              </div>
                              <div class="text">
                                  <b>{{accountData.data}}</b>
                                  <span>Total Amount</span><br>
                                  <b>800 </b>
                                  <span>Consumed Amount</span><br>
                                  <b>200 </b>
                                  <span>Available Amount</span>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-lg-12">
                          <div class="overview-box clearfix">
                            <div class="chart" *ngIf="isBalanceInfoLoad" >
                              <canvas baseChart
                                  [chartType]="accountType"
                                  [datasets]="accountData"
                                  [colors]="accountColors"
                                  [options]="chartOptions"
                                  [legend]="true" height= "120vh" >
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div> 
      </div>
    </div>

    <div class="col-sm-12 col-lg-12">
        <div class="row">
        </div>
    </div>

      <div class="col-sm-12" class="card">
        <div class="row">
            <div class="col-12">
              <h4 class="card-title3 mb-0">Consumptions
                <div class="float-right">
                  <button mat-raised-button class="sml-btn" type="button" (click)="consConsumption(1)">On-Going</button>
                  <button mat-raised-button class="sml-btn" type="button" (click)="consConsumption(2)">Day</button>
                  <button mat-raised-button class="sml-btn" type="button" (click)="consConsumption(3)">Month</button>
                  <button mat-raised-button class="sml-btn" type="button" (click)="consConsumption(4)">Year</button>
                </div>
              </h4>
            </div>
          </div>
          <mat-card class="mt-20">
            <div style="display: block;" *ngIf="isBarLoaded" >
              <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [legend]="barChartLegend"
                [colors]="myColors" 
                [chartType]="barChartType" 
                style="height: 59vh;display: block;width: 100%;" height= "60vh">
              </canvas>
            </div>
          </mat-card>
          <div class="card-footer">
            <ul>
              <li *ngIf="lineConsumption.grid">
                <div class="text-muted">Grid Consumption</div>
                <strong>{{lineConsumption.grid[0]}} consumption ({{lineConsumption.grid[1]}}%)</strong>
                <div class="progress progress-xs mt-2">
                  <div class="progress-bar bg-success" role="progressbar" [style.width.%]="lineConsumption.grid[1]" [attr.aria-valuenow]="lineConsumption.grid[1]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="d-none d-md-table-cell" *ngIf="lineConsumption.dg">
                <div class="text-muted">DG Consumption</div>
                <strong>{{lineConsumption.dg[0]}} Consumption ({{lineConsumption.dg[1]}}%)</strong>
                <div class="progress progress-xs mt-2">
                  <div class="progress-bar bg-info" role="progressbar" [style.width.%]="lineConsumption.dg[1]" [attr.aria-valuenow]="lineConsumption.dg[1]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li *ngIf="lineConsumption.grid_charges">
                <div class="text-muted">Grid Consumption Charges</div>
                <strong>{{lineConsumption.grid_charges[0]}} Charges ({{lineConsumption.grid_charges[1]}}%)</strong>
                <div class="progress progress-xs mt-2">
                  <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="lineConsumption.grid_charges[1]" [attr.aria-valuenow]="lineConsumption.grid_charges[1]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="d-none d-md-table-cell" *ngIf="lineConsumption.dg_charges">
                <div class="text-muted">DG Consumption Charges</div>
                <strong>{{lineConsumption.dg_charges[0]}} Charges ({{lineConsumption.dg_charges[1]}}%)</strong>
                <div class="progress progress-xs mt-2">
                  <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="lineConsumption.dg_charges[1]" [attr.aria-valuenow]="lineConsumption.dg_charges[1]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
            </ul>
            </div>
      </div>

          <div class="col-md-12 py-3">
            <div class="row">
                <div class="col-xl-8 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title">
                        Recent Recharges
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Meter Id</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Payment Mode</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Disabled
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title1">
                        Current Teriff
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Teriff Parameter</th>
                            <th>Value</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1.
                              </td>
                              <td>
                                EB Unit Charges
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                               Active
                              </td>
                              
                            </tr>
                            <tr>
                              <td>
                                2.
                              </td>
                              <td>
                                DG Unit Charges
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              
                            </tr>
                            </tbody>
                          </table>
                        </div>
                  </div>
                </div>
            </div>
          </div>  
          <div class="col-md-12 py-3">
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title2">
                        Activities
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Activity</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1.
                              </td>
                              <td>
                                Meter Load ON Successfully On Meter No A000040
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2.
                              </td>
                              <td>
                                Meter Load On Successfully.
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3.
                              </td>
                              <td>
                                Instant Data Downloaded Successfully On Meter No A000040
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            
                            </tbody>
                          </table>
                    </div>
                  </div>
                  
                </div>
                <div class="col-xl-8 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title3">
                        Recent Adjustments
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>SR. No</th>
                            <th>Meter Id</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Payment Mode</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                               Disabled
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3.
                              </td>
                              <td>
                                A00001
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              <td>
                               Online/NEFT
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            </tbody>
                          </table>
                    </div>
                  </div>
                </div>
            </div>
          </div> 

          <div class="col-md-12 py-3">
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title">
                        Tampers
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Tamper Name</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1. 
                              </td>
                              <td>
                                Voltage Missing - Occurence
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2. 
                              </td>
                              <td>
                                Voltage Missing - Restoration
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3. 
                              </td>
                              <td>
                                Over Voltage - Occurence
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                4. 
                              </td>
                              <td>
                                Over Voltage - Restoration
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            </tbody>
                          </table>
                    </div>
                  </div>
                  
                </div>
                <div class="col-xl-4 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title1">
                        Login History
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Type</th>
                            <th>IP Add</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                1
                              </td>
                              <td>
                                Loded In
                              </td>
                              <td>
                                103.156.78.90
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2
                              </td>
                              <td>
                                Loded In
                              </td>
                              <td>
                                103.156.78.90
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Loded In
                              </td>
                              <td>
                                103.156.78.90
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            <tr>
                              <td>
                                4
                              </td>
                              <td>
                                Loded In
                              </td>
                              <td>
                                103.156.78.90
                              </td>
                              <td>
                                20 Jan 2020 15:20:56
                              </td>
                            </tr>
                            </tbody>
                          </table>
                    </div>
                  </div>
                  
                </div>
                <div class="col-xl-4 col-lg-12 col-12">
                  <div style="height: 320px;" class="card">
                    <div class="card-header">
                      <h4 class="card-title2">
                        Current Teriff
                      </h4>
                    </div>
                    <div>
                      <table class="table-Data">
                        <thead>
                          <tr>
                            <th>Teriff Parameter</th>
                            <th>Value</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                EB Unit Charges
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              
                            </tr>
                            <tr>
                              <td>
                                DG Unit Charges
                              </td>
                              <td>
                                1000.00
                              </td>
                              <td>
                                Active
                              </td>
                              
                            </tr>
                            </tbody>
                          </table>
                    </div>
                  </div>
                  
                </div>
            </div>
          </div> 
          <div class="col-md-12 py-3">
            <div class="row">
            </div>
          </div> 
        </ng-container>-->

<!--</div>
    </div>
  </div>
</div>-->
<!-- content viewport ends -->