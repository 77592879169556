<div class="container-fluid innerpage_top page-content-wrapper">
    <div class="content-viewport">
        <div class="page-content-wrapper-inner outerbox_shadow tblH">
            <div class="boxflexEll row">
                <div class="col-md-5">
                    <h1>Configure All Meters
                        <span class="info_icntop ml-1">
                            <i data-toggle="tooltip" data-placement="top" title=" Information of Configure All Meters "
                                class="fa fa-info tabula-tooltip "></i>
                        </span>
                    </h1>
                </div>
            </div>
            <div class="">
                <ng-container class="" *ngIf="loading">
                    <div class="col-sm-12 text-center">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading">
                    <div class="">
                        <div class="row">
                            <div class="col-md-12">
                                <button mat-flat-button (click)="whichSubDomain()" class="cPbg rightcpbg"
                                    *ngIf="enableSocietyChange">
                                    <div class="centerfa">
                                        <i class="fa fa-reply centerfa"></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <ng-container class="allutilitysloader " *ngIf="loading">
                            <div class="col-sm-12 text-center">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </div>
                        </ng-container>
                        <ng-container>
                            <div class="container-fluid">
                                <div class="col-md-12">
                                    <div class="navall_tabblue">
                                        <nav class="">
                                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter"
                                                    class="nav-item nav-link active" id="nav_load_limit"
                                                    data-toggle="tab" href="#load_limit" role="tab"
                                                    aria-controls="load-limit" aria-selected="true">LL 1-PH<i
                                                        class="infoI fa fa-info" data-toggle="tooltip"
                                                        data-placement="top" title="Set Load Limit On Meter"></i></a>

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter_3_PH"
                                                    class="nav-item nav-link" id="nav_load_limit_three"
                                                    data-toggle="tab" href="#load_limit_three" role="tab"
                                                    aria-controls="load-limit-three" aria-selected="false">LL 3-PH<i class="infoI fa fa-info" data-toggle="tooltip"
                                                        data-placement="top" title="Set Load Limit On Meter"></i></a>

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Happy_Hours_On_Meter && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View"
                                                    class="nav-item nav-link" id="nav_happy_hours" data-toggle="tab"
                                                    href="#happy_hours" role="tab" aria-controls="happy-hours"
                                                    aria-selected="false">Happy Hour<i class="infoI fa fa-info"
                                                        data-toggle="tooltip" data-placement="top"
                                                        title="Set Happy Hours On Meter"></i></a>

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter  && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View"
                                                    class="nav-item nav-link" id="nav_meter_alarm" data-toggle="tab"
                                                    href="#meter_alarm" role="tab" aria-controls="meter-alarm"
                                                    aria-selected="false">Alarm <i class="infoI fa fa-info"
                                                        data-toggle="tooltip" data-placement="top"
                                                        title="Set Meter Alarm Level/Emergeny Credit /Billing Tariff"></i></a>
                                                <!--                                     
                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Server_Details_On_Meter"
                                                class="nav-item nav-link" id="nav_meter_alarm_slab" data-toggle="tab" href="#meter_alarm_slab" role="tab"
                                                aria-controls="meter-alarm" aria-selected="false">Set Meter Alarm <i class="infoI fa fa-info"
                                                    data-toggle="tooltip" data-placement="top"
                                                    title="Set Meter Alarm Level/Emergeny Credit /Billing Tariff"></i></a> -->

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Bill_date"
                                                    class="nav-item nav-link" id="nav_date_time" data-toggle="tab"
                                                    href="#date_time" role="tab" aria-controls="date-time"
                                                    aria-selected="false">Bill Date <i class="infoI fa fa-info"
                                                        data-toggle="tooltip" data-placement="top"
                                                        title="Meter Bill Date And Time Settings"></i></a>

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Relay_Control"
                                                class="nav-item nav-link" id="nav_relay_control" data-toggle="tab"
                                                    href="#relay_control" role="tab" aria-controls="date-time"
                                                    aria-selected="false">Relay Control <div class="icntb">
                                                        <i class="infoI fa fa-info" data-toggle="tooltip"
                                                            data-placement="top" title="DG Relay Control"></i>
                                                    </div>
                                                </a>
                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_ESW_1_PH"
                                                class="nav-item nav-link" id="nav_esw_1_ph_control" data-toggle="tab" 
                                                href="#esw_filter_1_ph" role="tab"
                                                aria-controls="date-time" aria-selected="false">ESW 1-PH
                                                <div class="icntb">
                                                    <i class="infoI fa fa-info"
                                                    data-toggle="tooltip" data-placement="top" title="ESW Filter 1-PH">
                                                    </i>
                                                </div>
                                                </a>
                                               <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_ESW_3_PH"
                                               class="nav-item nav-link" id="nav_esw_3_ph_control" data-toggle="tab" 
                                               href="#esw_filter_3_ph" role="tab"
                                                aria-controls="date-time" aria-selected="false"> ESW 3-PH  
                                                <div class="icntb">
                                                    <i class="infoI fa fa-info"
                                                    data-toggle="tooltip" data-placement="top" title="ESW Filter 3-PH">
                                                    </i>
                                                </div>
                                                </a>

                                                <a *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Periodic_Push"
                                               class="nav-item nav-link" id="nav_periodic_push" data-toggle="tab" 
                                               href="#periodic_push" role="tab"
                                                aria-controls="date-time" aria-selected="false"> Config Periodic Push 
                                                <div class="icntb">
                                                    <i class="infoI fa fa-info"
                                                    data-toggle="tooltip" data-placement="top" title="Periodic Push">
                                                    </i>
                                                </div>
                                                </a>
                                                
                                            </div>
                                        </nav>
                                        <div class="tab-content meterload" id="nav-tabContent">
                                            <div *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter"
                                                class="tab-pane fade show active" id="load_limit" role="tabpanel"
                                                aria-labelledby="nav_load_limit">
                                                <app-meterconfigloadlimit> </app-meterconfigloadlimit>
                                            </div>
                                            <div *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter_3_PH"
                                                class="tab-pane fade show " id="load_limit_three" role="tabpanel"
                                                aria-labelledby="nav_load_limit_three">
                                                <app-meterconfigloadlimit-three></app-meterconfigloadlimit-three>
                                            </div>
                                            <div class="tab-pane fade" id="happy_hours" role="tabpanel"
                                                aria-labelledby="nav_happy_hours"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Happy_Hours_On_Meter && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                                                <app-meterconfighappyhours></app-meterconfighappyhours>
                                            </div>
                                            <div class="tab-pane fade" id="meter_alarm" role="tabpanel"
                                                aria-labelledby="nav_meter_alarm"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter  && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                                                <app-meterconfigaeb></app-meterconfigaeb>
                                            </div>

                                            <div class="tab-pane fade" id="date_time" role="tabpanel"
                                                aria-labelledby="nav_date_time"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Bill_date">
                                                <app-meterbillschedular></app-meterbillschedular>
                                            </div>
                                            <div class="tab-pane fade" id="relay_control" role="tabpanel"
                                                aria-labelledby="nav_relay_control"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Relay_Control">
                                                <app-relaycontrol></app-relaycontrol>
                                            </div>
                                            <div class="tab-pane fade" id="esw_filter_3_ph" role="tabpanel" 
                                                aria-labelledby="nav_esw_3_ph_control"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_ESW_3_PH">
                                                <app-eswfilter></app-eswfilter>
                                              </div>
                                              <div class="tab-pane fade" id="esw_filter_1_ph" role="tabpanel" 
                                                aria-labelledby="nav_esw_1_ph_control"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_ESW_1_PH">
                                                <app-eswfilter1></app-eswfilter1>
                                              </div>

                                              <div class="tab-pane fade" id="periodic_push" role="tabpanel" 
                                                aria-labelledby="nav_periodic_push"
                                                *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Periodic_Push">
                                                <app-metercofigpush></app-metercofigpush>
                                              </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <ng-container>
                                    <div class="container-fluid inner-page-content">
                                        <div class="grid-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">

                                                    <mat-accordion>
                                                        <mat-expansion-panel
                                                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-icon class="d-c3">
                                                                        flash_on
                                                                    </mat-icon>
                                                                    Configure Load Limit On Single Phase Meter
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <app-meterconfigloadlimit></app-meterconfigloadlimit>
                                                        </mat-expansion-panel>

                                                        <mat-expansion-panel
                                                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Load_Limit_On_Meter">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-icon class="d-c3">
                                                                        flash_on
                                                                    </mat-icon>
                                                                    Configure Load Limit On Three Phase Meter
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <app-meterconfigloadlimit-three>
                                                            </app-meterconfigloadlimit-three>
                                                        </mat-expansion-panel>

                                                        <mat-expansion-panel
                                                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Happy_Hours_On_Meter && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-icon class="d-c12">
                                                                        add_box
                                                                    </mat-icon>
                                                                    Configure Happy hours On Meter
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <app-meterconfighappyhours></app-meterconfighappyhours>
                                                        </mat-expansion-panel>

                                                        <mat-expansion-panel
                                                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter  && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-icon class="d-c6">
                                                                        alarm
                                                                    </mat-icon>
                                                                    Set Meter Alarm Level/Emergeny Credit /Billing
                                                                    Tariff
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <app-meterconfigaeb></app-meterconfigaeb>
                                                        </mat-expansion-panel>

                                                        <mat-expansion-panel
                                                            *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Server_Details_On_Meter">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-icon class="d-c7">
                                                                        schedule
                                                                    </mat-icon>
                                                                    Billing Schedular
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <app-meterbillschedular></app-meterbillschedular>
                                                        </mat-expansion-panel>

                                                        <div>
                                                        </div>
                                                    </mat-accordion>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container> -->

                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>