<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()" autocomplete="off">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Add Consumer
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-groupp">
      <!-- Grid row -->
      <div class="form-row">
        <div class="col-md-4" *ngIf="loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Flat/House No" name="flatNo" formControlName="flatNo" value="{{filterFlat}}"
                (input)="onValidateFields($event.target.value)" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.flatNo.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Flat/House No</label>
              <div *ngIf="submitted && aF.flatNo.errors" class="invalid-feedback">
                <div *ngIf="aF.flatNo.errors.required">
                  Consumer Flat/House No is required
                </div>
                <div *ngIf="aF.flatNo.errors('uniqueFlat')">
                  Consumer Flat/House No already exist
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-3" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Flat No" name="flatNo" formControlName="flatNo" value="{{filterFlat}}"
                (input)="onValidateFields($event.target.value)" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.flatNo.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Flat No</label>
              <div *ngIf="submitted && aF.flatNo.errors" class="invalid-feedback">
                <div *ngIf="aF.flatNo.errors.required">
                  Consumer Flat No is required
                </div>
                <div *ngIf="aF.flatNo.errors('uniqueFlat')">
                  Consumer Flat No already exist
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-3" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select DG..." name="dgNameData" formControlName="dgNameData"
                (selectionChange)="onDGChange($event)" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.dgNameData.errors}" required>
                <mat-option *ngFor="let dg of dgData" [value]="dg.dgId">
                  {{dg.dgName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Select DG</label>
              <div *ngIf="submitted && aF.dgNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.dgNameData.errors.required">
                  DG Name is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <!--<div class="col-md-4" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Tower..." name="towerNameData" formControlName="towerNameData"
                (selectionChange)="onTowerChange($event)" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.towerNameData.errors}" required>
                <mat-option *ngFor="let td of towerData" [value]="td.towerId">
                  {{td.towerName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Select Tower</label>
              <div *ngIf="submitted && aF.towerNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.towerNameData.errors.required">
                  Tower Name is required
                </div>
              </div>
            </span>
          </div>
        </div>-->
        <div class="col-md-3" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Tower..." name="towerNameData" formControlName="towerNameData"
                (selectionChange)="onTowerChange($event)" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.towerNameData.errors}" required>
                <mat-option *ngFor="let td of towerData" [value]="td.towerId">
                  {{td.towerName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Select Tower</label>
              <div *ngIf="submitted && aF.towerNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.towerNameData.errors.required">
                  Tower Name is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <!--<div class="col-md-4" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Group..." name="groupNameData" formControlName="groupNameData"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.groupNameData.errors}" required>
                <mat-option *ngFor="let td of groupData" [value]="td.groupId">
                  {{td.groupName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-users icon_innertxtbox"></i>
              <label>Select Group</label>
              <div *ngIf="submitted && aF.groupNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.groupNameData.errors.required">
                  Group Name is required
                </div>
              </div>
            </span>
          </div>
        </div>-->
        <div class="col-md-3" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select Group..." name="groupNameData" formControlName="groupNameData"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.groupNameData.errors}" required>
                <mat-option *ngFor="let td of groupData" [value]="td.groupId">
                  {{td.groupName}}
                </mat-option>
              </mat-select>
              <i class="fa fa-users icon_innertxtbox"></i>
              <label>Select Group</label>
              <div *ngIf="submitted && aF.groupNameData.errors" class="invalid-feedback">
                <div *ngIf="aF.groupNameData.errors.required">
                  Group Name is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer Full Name" name="consFullName"
                formControlName="consFullName" (keypress)="omit_special_char($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consFullName.errors}" required>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Consumer Full Name</label>
              <div *ngIf="submitted && aF.consFullName.errors" class="invalid-feedback">
                <div *ngIf="aF.consFullName.errors.required">
                  Consumer Full Name is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer User Name" name="userName" formControlName="userName"
                value="{{filterUser}}" (input)="onValidateFieldsUser($event.target.value)" autocomplete="new-username"
                (keypress)="omit_special_char($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.userName.errors}" required>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Consumer User Name</label>
              <div *ngIf="submitted && aF.userName.errors" class="invalid-feedback">
                <div *ngIf="aF.userName.errors.required">
                  Consumer User Name is required
                </div>
                <div *ngIf="aF.userName.hasError('uniqueUser')">
                  User Name already exist
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="password" autocomplete="new-password" matInput placeholder="Consumer Password"
                name="consPassword" formControlName="consPassword" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consPassword.errors}" required>
              <i class="fa fa-lock icon_innertxtbox"></i>
              <label>Consumer Password</label>
              <div *ngIf="submitted && aF.consPassword.errors" class="invalid-feedback">
                <div *ngIf="aF.consPassword.errors.required">
                  Consumer Password is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="email" placeholder="Consumer Email Id" name="consEmailId" formControlName="consEmailId"
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consEmailId.errors}" required>
              <i class="fa fa-envelope icon_innertxtbox"></i>
              <label>Consumer Email Id</label>
              <div *ngIf="submitted && aF.consEmailId.errors" class="invalid-feedback">
                <div *ngIf="aF.consEmailId.errors.required">
                  Consumer Email Id is required
                </div>
                <div *ngIf="aF.consEmailId.hasError('email')">
                  Please enter a valid email address
                </div>
              </div>
            </span>
          </div>
        </div>


        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" placeholder="Consumer Landline No" name="consLandlineNo"
                formControlName="consLandlineNo" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consLandlineNo.errors}" required>
              <i class="fa fa-phone icon_innertxtbox"></i>
              <label>Consumer Landline No</label>
              <div *ngIf="submitted && aF.consLandlineNo.errors" class="invalid-feedback">
                <div *ngIf="aF.consLandlineNo.errors.required">
                  Consumer Landline is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input (keypress)="numberOnly($event)" type="tel" placeholder="Consumer Mobile No" name="consMobileNo"
                formControlName="consMobileNo" pattern="[0-9 -()+]+$" minlength="10" maxlength="10" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consMobileNo.errors}" required>
              <i class="fa fa-phone icon_innertxtbox"></i>
              <label>Consumer Mobile No</label>
              <div *ngIf="submitted && aF.consLandlineNo.errors" class="invalid-feedback">
                <div *ngIf="aF.consLandlineNo.errors.required">
                  Consumer Mobile is required
                </div>
                <div *ngIf="aF.consMobileNo.hasError('pattern')">
                  Consumer Mobile number not valid.
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Consumer Home Address 1" name="consHomeAddressline1"
                formControlName="consHomeAddressline1" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consHomeAddressline1.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Consumer Home Address 1</label>
              <div *ngIf="submitted && aF.consLandlineNo.errors" class="invalid-feedback">
                <div *ngIf="aF.consHomeAddressline1.errors.required">
                  Consumer Home Address 1 is required
                </div>

              </div>
            </span>
          </div>
        </div>


        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer Home Address 2" name="consHomeAddressline2"
                formControlName="consHomeAddressline2" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consHomeAddressline2.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Consumer Home Address 2</label>
              <div *ngIf="submitted && aF.consHomeAddressline2.errors" class="invalid-feedback">
                <div *ngIf="aF.consHomeAddressline2.errors.required">
                  Consumer Home Address 2 is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer Office Address 1" name="consOfficeAddressline1"
                formControlName="consOfficeAddressline1" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consOfficeAddressline1.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Consumer Office Address 1</label>
              <div *ngIf="submitted && aF.consOfficeAddressline1.errors" class="invalid-feedback">
                <div *ngIf="aF.consOfficeAddressline1.errors.required">
                  Consumer Office Address 1 is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer Office Address 2" name="consOfficeAddressline2"
                formControlName="consOfficeAddressline2" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.consOfficeAddressline2.errors}" required>
              <i class="fa fa-building icon_innertxtbox"></i>
              <label>Consumer Office Address 2</label>
              <div *ngIf="submitted && aF.consOfficeAddressline2.errors" class="invalid-feedback">
                <div *ngIf="aF.consOfficeAddressline2.errors.required">
                  Consumer Office Address 2 is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" matInput autocomplete="off" placeholder="GPS Latitude" name="latitude"
                formControlName="latitude" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.latitude.errors}" required>
              <i class="fa fa-map icon_innertxtbox"></i>
              <label>GPS Latitude</label>
              <div *ngIf="submitted && aF.latitude.errors" class="invalid-feedback">
                <div *ngIf="aF.latitude.errors.required">
                  Latitude is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" autocomplete="off" placeholder="GPS Longitude " name="longitude"
                formControlName="longitude" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.longitude.errors}" required>
              <i class="fa fa-map icon_innertxtbox"></i>
              <label>GPS Longitude</label>
              <div *ngIf="submitted && aF.longitude.errors" class="invalid-feedback">
                <div *ngIf="aF.longitude.errors.required">
                  Latitude is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input autocomplete="off" placeholder="Consumer DOB (MM/DD/YYYY)" name="authorityDOB"
                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                [bsConfig]="{ adaptivePosition: true }" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.authorityDOB.errors}" required>
              <i class="fa fa-calendar icon_innertxtbox"></i>
              <label>Consumer DOB</label>
              <div *ngIf="submitted && aF.authorityDOB.errors" class="invalid-feedback">
                <div *ngIf="aF.authorityDOB.errors.required">
                  Consumer DOB is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Active Status" name="conActiveStatus" formControlName="conActiveStatus"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.conActiveStatus.errors}" required>
                <mat-option *ngFor="let td of activeStatus" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Active Status</label>
              <div *ngIf="submitted && aF.conActiveStatus.errors" class="invalid-feedback">
                <div *ngIf="aF.conActiveStatus.errors.required">
                  Status is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-3"  *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Flat Area" name="flatArea" formControlName="flatArea"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.flatArea.errors}" required>
                <mat-option *ngFor="let td of area" [value]="td.Name">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Flat Area</label>
              <div *ngIf="submitted && aF.flatArea.errors" class="invalid-feedback">
                <div *ngIf="aF.flatArea.errors.required">
                  Flat Area is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter Serial No" name="meterSerialNo" formControlName="meterSerialNo"
                value="{{filterMeter}}" (input)="onValidateFieldsMeter($event.target.value)" autocomplete="off"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.meterSerialNo.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter Serial No</label>
              <div *ngIf="submitted && aF.meterSerialNo.errors" class="invalid-feedback">
                <div *ngIf="aF.meterSerialNo.errors.required">
                  Meter Serial No is required
                </div>
                <div *ngIf="aF.meterSerialNo.hasError('uniqueMeter')">
                  Meter Serial No already exist
                </div>
              </div>
            </span>
          </div>
        </div>


        <div class="col-md-2" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Meter Type" name="meterType" formControlName="meterType"
                (selectionChange)="onMeterTypeChange($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterType.errors}" required>
                <mat-option *ngFor="let td of mType" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter Type</label>
              <div *ngIf="submitted && aF.meterType.errors" class="invalid-feedback">
                <div *ngIf="aF.meterType.errors.required">
                  Meter Type is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4" *ngIf="loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Meter Type" name="meterType" formControlName="meterType"
                (selectionChange)="onMeterTypeChange($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterType.errors}" required>
                <mat-option *ngFor="let td of mType" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-calendar icon_innertxtbox"></i>
              <label>Meter Type</label>
              <div *ngIf="submitted && aF.meterType.errors" class="invalid-feedback">
                <div *ngIf="aF.meterType.errors.required">
                  Meter Type is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" placeholder={{llebData}} name="loadLimitEB" formControlName="loadLimitEB"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.loadLimitEB.errors}" required>
              <i class="fa fa-tasks icon_innertxtbox"></i>
              <label>Grid Supply Load</label>
              <div *ngIf="submitted && aF.loadLimitEB.errors" class="invalid-feedback">
                <div *ngIf="aF.loadLimitEB.errors.required">
                  Grid Supply Load Limit is required
                </div>
                <div *ngIf="aF.loadLimitEB.hasError('min')">
                  Grid Supply Load Limit is lower than the minimum
                </div>
                <div *ngIf="aF.loadLimitEB.hasError('max')">
                  Grid Supply Load Limit is greater than the maximum
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input type="number" placeholder={{lldgData}} name="loadLimitDG" formControlName="loadLimitDG"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.loadLimitDG.errors}" required>
              <i class="fa fa-tasks icon_innertxtbox"></i>
              <label>DG Supply Load</label>
              <div *ngIf="submitted && aF.loadLimitDG.errors" class="invalid-feedback">
                <div *ngIf="aF.loadLimitDG.errors.required">
                  DG Supply Load Limit is required
                </div>
                <div *ngIf="aF.loadLimitDG.hasError('min')">
                  DG Supply Load Limit is lower than the minimum
                </div>
                <div *ngIf="aF.loadLimitDG.hasError('max')">
                  DG Supply Load Limit is greater than the maximum
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-2" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="DG Free Units" name="dgFreeUnits" formControlName="dgFreeUnits"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgFreeUnits.errors}" required>
                <mat-option *ngFor="let td of activeStatus" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>DG Free Units</label>
              <div *ngIf="submitted && aF.dgFreeUnits.errors" class="invalid-feedback">
                <div *ngIf="aF.dgFreeUnits.errors.required">
                  DG Free units status is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <!--<input matInput placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">-->
              <mat-select placeholder="Com Mode" name="commMode" formControlName="commMode"
                (selectionChange)="onCommTypeChange($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.commMode.errors}" required>
                <mat-option *ngFor="let td of comm" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-tasks icon_innertxtbox"></i>
              <label>Com Mode</label>
              <div *ngIf="submitted && aF.commMode.errors" class="invalid-feedback">
                <div *ngIf="aF.commMode.errors.required">
                  Meter Communication Mode is required
                </div>
              </div>
            </span>
          </div>
        </div>


        <div class="col-md-2" *ngIf="!loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <!--<input matInput placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">-->
              <mat-select placeholder="Com Mode" name="commMode" formControlName="commMode"
                (selectionChange)="onCommTypeChange($event)" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.commMode.errors}" required>
                <mat-option *ngFor="let td of comm" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-tasks icon_innertxtbox"></i>
              <label>Com Mode</label>
              <div *ngIf="submitted && aF.commMode.errors" class="invalid-feedback">
                <div *ngIf="aF.commMode.errors.required">
                  Meter Communication Mode is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="enableIpField">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter Ip Address" name="meterIpAddress" formControlName="meterIpAddress"
                value="{{filterIp}}" (input)="onValidateFieldsIp($event.target.value)" autocomplete="off"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.meterIpAddress.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter Ip Address</label>
              <div *ngIf="submitted && aF.meterIpAddress.errors" class="invalid-feedback">
                <div *ngIf="aF.meterIpAddress.errors.required">
                  Meter Ip Address is required
                </div>
                <div *ngIf="aF.meterIpAddress.hasError('uniqueIp')">
                  Meter IP Address No already exist
                </div>
              </div>
            </span>
          </div>
        </div>


        <div class="col-md-4" *ngIf="enableIpField && loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter Port No" name="meterPortNo" (keypress)="numberOnly($event)"
                formControlName="meterPortNo" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterPortNo.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label> Meter Port No</label>
              <div *ngIf="submitted && aF.meterPortNo.errors" class="invalid-feedback">
                <div *ngIf="aF.meterPortNo.errors.required">
                  Meter Port No is required
                </div>
                <div *ngIf="aF.meterPortNo.hasError('uniqueIp')">
                  Meter Port No is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-2" *ngIf="enableIpField && !loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter Port No" name="meterPortNo" (keypress)="numberOnly($event)"
                formControlName="meterPortNo" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterPortNo.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label> Meter Port No</label>
              <div *ngIf="submitted && aF.meterPortNo.errors" class="invalid-feedback">
                <div *ngIf="aF.meterPortNo.errors.required">
                  Meter Port No is required
                </div>
                <div *ngIf="aF.meterPortNo.hasError('uniqueIp')">
                  Meter Port No is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!enableIpField  && !loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="HES ID eg.7263a2cc-6bd6-453c-9c43-9206043c80f8" name="hesId" formControlName="hesId"
                onFocus="document.execCommand('OverWrite', false, true);" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.hesId.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter HES Id</label>
              <div *ngIf="submitted && aF.hesId.errors" class="invalid-feedback">
                <div *ngIf="aF.hesId.errors.required">
                  Meter HES Id is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-8" *ngIf="!enableIpField && loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="HES ID eg.7263a2cc-6bd6-453c-9c43-9206043c80f8" name="hesId" formControlName="hesId"
                onFocus="document.execCommand('OverWrite', false, true);" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.hesId.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter HES</label>
              <div *ngIf="submitted && aF.hesId.errors" class="invalid-feedback">
                <div *ngIf="aF.hesId.errors.required">
                  Meter HES Id is required
                </div>
              </div>
            </span>
          </div>
        </div>


        <div class="col-md-4">
          <div class="form-group input-group">
            <span class="has-float-label">
              <!--<input matInput placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">-->
              <mat-select placeholder="Meter Firmware Version" name="meterFirmwareCodeVersion"
                formControlName="meterFirmwareCodeVersion" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterFirmwareCodeVersion.errors}" required>
                <mat-option *ngFor="let td of firmWareVersion" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Meter Firmware Version</label>
              <div *ngIf="submitted && aF.meterFirmwareCodeVersion.errors" class="invalid-feedback">
                <div *ngIf="aF.meterFirmwareCodeVersion.errors.required">
                  Meter Firmware Version is required
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="enableIpField">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Select SIM Type.." name="gprsSimType" formControlName="gprsSimType"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gprsSimType.errors}" required>
                <mat-option *ngFor="let sim of simType" [value]="sim.id">
                  {{sim.name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>Select SIM Type</label>
              <div *ngIf="submitted && aF.gprsSimType.errors" class="invalid-feedback">
                <div *ngIf="aF.gprsSimType.errors.required">
                  SIM Type is required
                </div>
                <div *ngIf="aF.gprsSimType.hasError('uniqueIp')">
                  Sim type already exist
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="enableIpField">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="GPRS SIM No" (keypress)="numberOnly($event)" name="gprsSimNo"
                formControlName="gprsSimNo" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.gprsSimNo.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>GPRS SIM No</label>
              <div *ngIf="submitted && aF.gprsSimNo.errors" class="invalid-feedback">
                <div *ngIf="aF.gprsSimNo.errors.required">
                  GPRS Sim No is required
                </div>
                <div *ngIf="aF.gprsSimNo.hasError('uniqueIp')">
                  GPRS Sim No already exist
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="enableIpField">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="GPRS SIM Contact No" (keypress)="numberOnly($event)" name="gprsSimContactNo"
                formControlName="gprsSimContactNo" autocomplete="off" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.gprsSimContactNo.errors}" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>GPRS Sim Contact</label>
              <div *ngIf="submitted && aF.gprsSimContactNo.errors" class="invalid-feedback">
                <div *ngIf="aF.gprsSimContactNo.errors.required">
                  GPRS Sim Contact No is required
                </div>
                <div *ngIf="aF.gprsSimContactNo.hasError('uniqueIp')">
                  GPRS Sim Contact already exist
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-4" *ngIf="loginType">
          <div class="form-group input-group">
            <span class="has-float-label">
              <mat-select placeholder="Consumer Category" name="category" formControlName="category"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.category.errors}" required>
                <mat-option *ngFor="let td of conCategory" [value]="td.id">
                  {{td.Name}}
                </mat-option>
              </mat-select>
              <i class="fa fa-user icon_innertxtbox"></i>
              <label>Consumer Category</label>
              <div *ngIf="submitted && aF.category.errors" class="invalid-feedback">
                <div *ngIf="aF.category.errors.required">
                  Consumer Category is required
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <!-- Grid row -->

      <div class="row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="message.length > 0">
            <div class="alert alert-success" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
            </div>
          </div>

        </div>

        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>add</mat-icon>
            Submit
          </button>
          <button type="button" (click)="modal.close('Cancel')" class="dilog2 dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

        </div>
      </div>

    </div>
  </div>
</form>