<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c9"> settings_input_component </mat-icon>
        <input matInput placeholder="R- Phase" name="rphase" formControlName="rphase">
        <mat-error *ngIf="cF.rphase.hasError('required')">
            rphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c9"> settings_input_component </mat-icon>
        <input matInput placeholder="Y- Phase" name="yphase" formControlName="yphase">
        <mat-error *ngIf="cF.yphase.hasError('required')">
            yphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c9"> settings_input_component </mat-icon>
        <input matInput placeholder="B- Phase" name="bphase" formControlName="bphase">
        <mat-error *ngIf="cF.bphase.hasError('required')">
            bphase is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>