<div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
    Contact Us
  </h4>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <ng-container>
  <div class="row form-wrapper outercontactborder">
    <div class="text-center userimgcontact">
      <img src="assets/img/director_img.png" alt="consumer image"
       class="avatar_user rounded-circle border_cstm">
    </div>
    <div class="outerdata" *ngFor="let item of letviewis">
      <div class="d_title {{item.bold_class}}">
        {{item.title.substr(2)}} :
      </div>
      <div class="d_content">
          <p>{{item.content}}</p>
      </div> 
    </div>
  </div>
  <!-- <div class="col-sm-12 col-md-8 o_b" *ngFor="let item of letviewis">
    <div class="d_title {{item.bold_class}}">
      <span><mat-icon class="d-c10">assignment</mat-icon></span>{{item.title.substr(2)}}
    </div>
    <div class="d_content">
        <p>{{item.content}}</p>
    </div>  
  </div> -->
  
  </ng-container>
  <ng-container col-sm-3>
  </ng-container>
</div>
      