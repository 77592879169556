import { Component, OnInit } from '@angular/core';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-superviserview',
  templateUrl: './superviserview.component.html',
  styleUrls: ['./superviserview.component.scss']
})
export class SuperviserviewComponent implements OnInit {

  faSpinner = faSpinner;
  isLoading:boolean = false;
  isData:boolean = false;

  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;

  contractorDetail:any = {};

  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
  }


  getRealTimeStatus() {
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('authLevel','7');
    body.set('subDivId', this.currentUser.data.userData.subdivision);
    body.set('authId', this.contractorDetail.caID);
    body.set('offSet', '0');
    body.set('limit', '1000');
    body.set('filter', '');
    body.set('authToken', this.currentUser.authToken);
    body.set('authorityType', 'Super');
    this.genReqs.postReq('/getSupervisers', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        this.isData = true;
        this.letviewis = this.responseData.data;
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }
}
