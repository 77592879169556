import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigphasebkpsupply',
  templateUrl: './meterconfigphasebkpsupply.component.html',
  styleUrls: ['./meterconfigphasebkpsupply.component.scss']
})
export class MeterconfigphasebkpsupplyComponent implements OnInit {
  addForm: UntypedFormGroup;
  booleanVals = [
    {name: 'Yes'},
    {name: 'No'}
  ];
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      brphase: ['', Validators.required],
      byphase: ['', Validators.required],
      bbphase: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
