<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c7"> build</mat-icon>
        <input matInput placeholder="DCU Gateway Id" name="dcugid" formControlName="dcugid">
        <mat-error *ngIf="cF.dcugid.hasError('required')">
            dcugid is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 form-group input-rounded">
        <mat-icon matPrefix class="d-c7"> build</mat-icon>
        <input matInput placeholder="DCU Gateway  Chanel No" name="dcugcno" formControlName="dcugcno">
        <mat-error *ngIf="cF.dcugcno.hasError('required')">
            dcugcno is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Add</button>
</form>