import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { Router } from '@angular/router';
import { Access } from '../_models/access';
import { User } from '../_models';
import { Subscription } from 'rxjs';
import { GridlistService } from '../_services/gridlist.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deshboardauth3',
  templateUrl: './deshboardauth3.component.html',
  styleUrls: ['./deshboardauth3.component.scss']
})
export class Deshboardauth3Component implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  getGothrough: Access;
  allUsersLablesData: any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId: any = 0;
  stateId: any = 0;
  breakpoint: number;
  closeResult: string;
  addUtilityForm: UntypedFormGroup;
  editUtilityForm: UntypedFormGroup;
  responseMessage: string = '';
  UtilityName: string = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
    private userService: UserService,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.authenticationService.getThrough.subscribe(nav => this.getGothrough = nav);
  }

  ngOnInit() {
    this.countryId = this.currentUser.data.userData.Country || 0;
    this.stateId = this.currentUser.data.userData.State || 0;
    this.authenticationService.getDeshboardRoute();
    this.getAccesslevels();
    this.reCountCols(window.innerWidth);
  }

  ngAfterViewInit() {
    this.addUtilityForm = this.formBuilder.group({
      utilityCode: ['', Validators.required],
      utilityName: ['', Validators.required],
      utilityImage: [null, Validators.required]
    });
    this.editUtilityForm = this.formBuilder.group({
      utilityCode: ['', Validators.required],
      utilityName: ['', Validators.required],
      utilityImage: ['',],
      utilityId: ['']
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

  getAccesslevels() {
    this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getLabels', body).subscribe((result) => {
      this.responseData = result;
      this.loadingUtilitys = false;
      if (this.responseData.success) {
        this.allUsersLablesData = this.responseData.data;
        this.allutilitys = this.allUsersLablesData.allutilitys;
      } else {
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  onResize(event) {
    this.reCountCols(event.target.innerWidth);
  }

  reCountCols(width: number) {
    this.breakpoint = this.gridlistService.reCountCols(width);
  }

  backToCountryView() {
    this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId) } });
  }

  backToStateView() {
    this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId) } });
  }

  goToCircleView(utility_id) {
    this.router.navigate(['/deshauthcircle'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId), state: this.genReqs.encryptData(this.stateId), utility: this.genReqs.encryptData(utility_id) } });
  }

  /**to get the meter list on click on Dcu */
  countryEditView(row, content) {
    //console.log(row);
    this.responseMessage = '';
    this.eU.utilityCode.setValue(row.utilityName);
    this.eU.utilityName.setValue(row.utilityName);
    this.eU.utilityId.setValue(row.utility_id);
    this.UtilityName = row.utilityName;
    this.modalService.open(content, { size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /*
    forms images pickers
  */
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.aU.utilityImage.setValue(file);
    }
  }

  onFileSelectEdit(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.eU.utilityImage.setValue(file);
    }
  }

  /*
  add utility form submition here
  */
  get aU() { return this.addUtilityForm.controls; }
  onSubmitUtilityState() {
    if (this.addUtilityForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('utilityImage', this.aU.utilityImage.value);
    formData.append('countryId', this.countryId);
    formData.append('stateId', this.stateId);
    formData.append('utilityCode', this.aU.utilityCode.value);
    formData.append('utilityName', this.aU.utilityName.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/addUtility', formData).subscribe(
      (res) => {
        this.responseMessage = res.message;
        if (res.success) {
          this.addUtilityForm.reset();
          this.getAccesslevels();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

  get eU() { return this.editUtilityForm.controls; }
  onSubmitEditUtilityState() {
    //console.log(this.eU);
    //addCountry
    if (this.editUtilityForm.invalid) {
      return;
    }
    const formData = new FormData();
    if (this.eU.utilityImage.value != '') {
      formData.append('utilityImage', this.eU.utilityImage.value);
    }
    formData.append('countryId', this.countryId);
    formData.append('stateId', this.stateId);
    formData.append('utilityCode', this.eU.utilityCode.value);
    formData.append('utilityName', this.eU.utilityName.value);
    formData.append('authToken', this.currentUser.authToken);
    this.genReqs.formDataPost('/editUtility', formData).subscribe(
      (res) => {
        this.responseMessage = res.message;
        if (res.success) {
          this.editUtilityForm.reset();
        }
      },
      (err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
        }
      }
    );
  }

}
