<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <div class="with-icon">
      <mat-icon class="d-c3">account_circle</mat-icon>{{consFullName}}
    </div>
  </h4>
  <button type="button" class="close" aria-label="Close"
    (click)="modal.dismiss('')">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <mat-horizontal-stepper [linear]="isLinear" #stepper > 
    <mat-step [stepControl]="adjustFormGroup">
      <form [formGroup]="adjustFormGroup" (ngSubmit)="onSubmitAdjustmentForm()">
        <ng-template matStepLabel>User Info</ng-template>
        <div class="form-group">
          <!-- Grid row -->
          <div class="form-row">
            <!-- Default input -->
            <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="material-icons d-c4">
                verified_user
              </mat-icon>
              <input matInput placeholder="Meter Id" name="meterId" formControlName="meterId">
              <mat-error *ngIf="rF.meterId.hasError('required')">
                Meter Id is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            
            <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="material-icons d-c5">
                note_add
              </mat-icon>
              <input matInput placeholder="Adjustment Amount" name="adjustAmount" formControlName="adjustAmount">
              <mat-error *ngIf="rF.adjustAmount.hasError('required')">
                Adjustment Amount is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="material-icons d-c6">
                view_list
              </mat-icon>
              <input matInput placeholder="transaction Type" name="transactionType" formControlName="transactionType">
              <mat-error *ngIf="rF.transactionType.hasError('required')">
                Transaction Type is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="material-icons d-c7">
                feedback
              </mat-icon>
              <input matInput placeholder="Remark" name="remark" formControlName="remark">
              
              <mat-error *ngIf="rF.remark.hasError('required')">
                Remarks is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          </div>
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="col-12 text-right">
            <button type="submit" mat-button matStepperNext class="cPbg">Generate OTP</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="otpFormGroup">
      <form [formGroup]="otpFormGroup">
        <ng-template matStepLabel>Validate OTP</ng-template>
        <mat-form-field>
          <mat-icon matPrefix class="material-icons d-c5">
            control_camera
          </mat-icon>
          <input matInput placeholder="Otp" formControlName="otp">
          <mat-error *ngIf="oF.otp.hasError('required')">
            Otp is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious  class="cPbg">Back</button>
          <button mat-button matStepperNext  class="cPbg">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div>
        <table>
          <tr>
            <th>Name:</th>
            <td>{{consFullName}}</td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>{{consEmail}}</td>
          </tr>
          <tr>
            <th>Mobile:</th>
            <td>{{consMobileId}}</td>
          </tr>
          <tr>
            <th>OTP:</th>
            <td>{{oF.otp.value}}</td>
          </tr>
          <tr>
            <th>Meter Id:</th>
            <td>{{rF.meterId.value}}</td>
          </tr><tr>
            <th>Adjustment Amount:</th>
            <td>{{rF.adjustAmount.value}}</td>
          </tr>
          
          <tr>
            <th>Transaction Type:</th>
            <td>{{rF.transactionType.value}}</td>
          </tr><tr>
            <th>Remark:</th>
            <td>{{rF.remark.value}}</td>
          </tr>
        </table>
        <div class="col-12 text-right">
        <button mat-button matStepperPrevious class="cPbg">Back</button>
        <button mat-button (click)="stepper.reset()"  class="cPbg">Reset</button>
        <button mat-button (click)="processAdjustment()" class="cPbg">Process Recharge</button>
      </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>