<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
    <div class="row">
        
        <div class="col-md-12">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Periodic Push Interval In Minute" name="sbMinute" formControlName="sbMinute"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.sbMinute.errors }" required>
                        <mat-option *ngFor="let td of smOnMinute" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-calendar icon_innertxtbox"></i>
                    <label>Periodic Push Interval In Minute</label>
                    <div *ngIf="submitted && cF.sbMinute.errors" class="invalid-feedback">
                        <div *ngIf="cF.sbMinute.errors.required">
                            Periodic Push Minute required
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>

            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <button type="submit" [disabled]="AddProcess" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>

</form>