import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editmeterrange',
  templateUrl: './editmeterrange.component.html',
  styleUrls: ['./editmeterrange.component.scss']
})
export class EditmeterrangeComponent implements OnInit {
  faSpinner = faSpinner;

  dgData: any = {};
  dgid:string = '0';
  dgName:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  submitted: boolean = false;
  /*deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "EB"},
    {deviceType: "DG"}
  ];*/

  deviceData = [
    {deviceType: "1Ph meters"},
    {deviceType: "3Ph meters"},
    {deviceType: "LTCT"}
  ];

  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';


  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
        prefix: ['', Validators.required],
        fromRange: ['', Validators.required],
        toRange: ['', Validators.required],
        deviceType: ['', Validators.required],
        specificId: [''],
        comment: ['']
      });
     // console.log("dgData",this.dgData);
      this.aF.prefix.setValue(this.dgData.prefix);
      this.aF.fromRange.setValue(this.dgData.fromRange);
      this.aF.toRange.setValue(this.dgData.toRange);
      this.aF.deviceType.setValue(this.dgData.deviceType);
      this.aF.specificId.setValue(this.dgData.specificId);
      this.aF.comment.setValue(this.dgData.comment);
  }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
        this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId',  this.dgData.subDivId);
      body.set('rangeId', this.dgData.rangeId);
      body.set('prefix', this.aF.prefix.value);
      body.set('fromRange', this.aF.fromRange.value);
      body.set('toRange', this.aF.toRange.value);
      body.set('deviceType', this.aF.deviceType.value);
      body.set('specificId', this.aF.specificId.value);
      body.set('comment', this.aF.comment.value);

      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/editMeterRange', body).subscribe((result)=>{
        this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
            this.thumb = true;
            this.addAuthMessage = this.responseData.data.message;
            setTimeout(() => 
                {
                  this.modal.close('done');
                },
                2000);
        }else{
            this.thumb = false;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }
}
