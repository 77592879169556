<div>
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Make Recharge : {{consFullName}}
    </h4>
  </div>
  <div class="modal-body nopaddingstep">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="rechargeFormGroup">
        <form [formGroup]="rechargeFormGroup">
          <ng-template matStepLabel>User Info</ng-template>
          <div class="form-groupp">
            <!-- Grid row -->
            <div class="row" *ngIf="meterDetail.sdID==87">
              <!-- Default input -->
              <div class="col-md-4">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select placeholder="Recharge Type" name="rechargeType" formControlName="rechargeType"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && rF.rechargeType.errors }" required>
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let frm of rechargeType" [value]="frm.id"
                        (onSelectionChange)="onSelectFlat($event, frm.rechargeName)">
                        {{frm.rechargeName}}
                      </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Recharge Type</label>
                    <div *ngIf="submitted && rF.rechargeType.errors" class="invalid-feedback">
                      <div *ngIf="rF.rechargeType.errors.required">Recharge Mode is required</div>
                    </div>
                  </span>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input placeholder="Meter Id" name="meterId" formControlName="meterId" class="form-control"
                      readonly>
                    <i class="fa fa-id-card-o icon_innertxtbox"></i>
                    <label>Meter Id</label>
                  </span>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input type="number" placeholder="Recharge Amount" name="rechargeAmount"
                      formControlName="rechargeAmount" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && rF.rechargeAmount.errors }" required>
                    <i class="fa fa-inr icon_innertxtbox"></i>
                    <label>Recharge Amount</label>
                    <div *ngIf="submitted && rF.rechargeAmount.errors" class="invalid-feedback">
                      <div *ngIf="rF.rechargeAmount.errors.required">
                        Recharge Amount is required.
                      </div>
                      <div *ngIf="rF.rechargeAmount.errors.min">
                        Recharge Amount Must be greator than 1.
                      </div>
                      <div *ngIf="rF.rechargeAmount.errors.max">
                        Recharge Amount Must be less than 99999.
                      </div>
                    </div>

                  </span>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="meterDetail.sdID!=87">
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input placeholder="Meter Id" name="meterId" formControlName="meterId" class="form-control"
                      readonly>
                    <i class="fa fa-id-card-o icon_innertxtbox"></i>
                    <label>Meter Id</label>
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input type="number" placeholder="Recharge Amount" name="rechargeAmount"
                      formControlName="rechargeAmount" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && rF.rechargeAmount.errors }" required>
                    <i class="fa fa-inr icon_innertxtbox"></i>
                    <label>Recharge Amount</label>
                    <div *ngIf="submitted && rF.rechargeAmount.errors" class="invalid-feedback">
                      <div *ngIf="rF.rechargeAmount.errors.required">
                        Recharge Amount is required.
                      </div>
                      <div *ngIf="rF.rechargeAmount.errors.min">
                        Recharge Amount Must be greator than 1.
                      </div>
                      <div *ngIf="rF.rechargeAmount.errors.max">
                        Recharge Amount Must be less than 99999.
                      </div>
                    </div>

                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select placeholder="Recharge Mode" name="rechargeMode" formControlName="rechargeMode"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && rF.rechargeMode.errors }" required>
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let frm of paymentMode" [value]="frm.id"
                        (onSelectionChange)="onSelectFlat($event, frm.paymentModeName)">
                        {{frm.paymentModeName}}
                      </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Recharge Mode</label>
                    <div *ngIf="submitted && rF.rechargeMode.errors" class="invalid-feedback">
                      <div *ngIf="rF.rechargeMode.errors.required">Recharge Mode is required</div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input placeholder="Remark" name="remark" formControlName="remark" class="form-control" required>
                    <i class="fa fa-comment-o icon_innertxtbox"></i>
                    <label>Remark </label>
                  </span>
                </div>
              </div>
            </div>
            <!-- <mat-form-field class="col-md-6">
              <mat-icon matPrefix class="material-icons  d-c4">
                view_list
              </mat-icon>
                <mat-select placeholder="Recharge Mode" name="rechargeMode" formControlName="rechargeMode"  >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let frm of paymentMode" [value]="frm.id" (onSelectionChange)="onSelectFlat($event, frm.paymentModeName)">
                    {{frm.paymentModeName}}
                  </mat-option>
                </mat-select>
              <mat-error *ngIf="rF.rechargeMode.hasError('required')">
                Recharge Mode is <strong>required</strong>
              </mat-error>
            </mat-form-field> -->


            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="text-right">
              <button matStepperNext class="dilog dilog1" (click)="generateOtp()">
                <mat-icon>add</mat-icon>
                Generate OTP
              </button>
              <button type="button" (click)="modal.close('Ok click')" class="dilog dilog3">
                <mat-icon>cancel</mat-icon>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="otpFormGroup">
        <div *ngIf="invalidMessage.length > 0" [innerHTML]="invalidMessage"></div>
        <form [formGroup]="otpFormGroup">
          <ng-template matStepLabel>Validate OTP</ng-template>
          <div class="col-md-6">
            <div class="form-group input-group">
              <span class="has-float-label">
                <input placeholder="Otp" formControlName="otp" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && oF.otp.errors }" required>
                <label>Otp </label>
                <div *ngIf="submitted && oF.otp.errors" class="invalid-feedback">
                  <div *ngIf="oF.otp.errors.required">OTP is required</div>
                </div>
              </span>
            </div>
          </div>
          <div>
            <button matStepperPrevious class="dilog dilog1">
              <mat-icon>arrow_back_ios</mat-icon>
              Back
            </button>
            <button class="dilog dilog1" (click)="matchOtp(stepper)">
              <mat-icon>arrow_forward_ios</mat-icon>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Review and Submit</ng-template>
        <!--<div *ngIf="reviewSubmitMessage.length > 0" [class.errors]="!reviewSubmitStatus" [class.success]="reviewSubmitStatus">{{reviewSubmitMessage}}</div>
      -->
        <div style="overflow-x:auto;overflow-y:auto;" id="recharge_review">
          <table width='100%' class="t1">
            <tr>
              <td colspan="4" style="text-align: left;font-size: x-large;" class="rechargeTitle">
                <b>Recharge Receipt!</b>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="font-size: large;">
                <B>Personal Details:-</B>
              </td>
            </tr>

            <tr>
              <td width="25%"><b>Flat No:</b></td>
              <td width="25%">{{flatNo}}</td>
              <td width="25%"><b>Meter ID:</b></td>
              <td width="25%">{{rF.meterId.value}}</td>
            </tr>
            <tr>
              <td width="25%"><b>Owner's Name:</b></td>
              <td width="25%">{{consFullName}}</td>
              <td width="25%"><b>Mobile No:</b></td>
              <td width="25%">{{consMobileId}}</td>
            </tr>
            <tr>
              <td width="25%"><b>Email:</b></td>
              <td width="25%">{{consEmail}}</td>
              <td width="25%"><b>Date:</b></td>
              <td width="25%">{{picked}}</td>
            </tr>


          </table>

          <table width='100%' class="t1">
            <tr>
              <td colspan="2" style="font-size: large;">
                <b>Recharge Details:-</b>
              </td>
            </tr>
          </table>
          <table border=1 width='100%' class="t1">

            <tr>
              <td width="50%" colspan="2"><b>Recharge Amount: </b></td>
              <td width="50%" colspan="2">{{rF.rechargeAmount.value}} </td>
            </tr>
            <!-- <tr>
            <td width="50%" colspan="2"><b>Recharge Status:</b> </td><td width="50%" colspan="2">{{processData.status}} </td>
          </tr>-->
            <tr>
              <td width="50%" colspan="2"><b>Payment Method:</b></td>
              <td width="50%" colspan="2">{{rechageModeValue}} </td>
            </tr>
            <!--<tr>
            <td width="50%" colspan="2"><b>Payment Transaction Id:</b></td><td width="50%" colspan="2">{{processData.tranNo}} </td>
          </tr>-->
            <tr>
              <td width="50%" colspan="2"><b>Remark:</b> </td>
              <td width="50%" colspan="2">{{rF.remark.value}} </td>
            </tr>

          </table>
          <table width='100%' class="t1">
            <tr>
              <td width='100%' style=" white-space: pre-line !important;text-align: justify;" colspan="4">
                Please Note :-<br>
                1) Payment which have been made through modes other than cash are subject to clearance including through
                electronic mode
                payments.<br>
                2) This document can be used as a proof of payment.<br>
                3) Your request is under progress. Amount will reflect in your account within 15-20 minutes.
              </td>
            </tr>
          </table>

        </div>
        <div class="col-sm-12 row">
          <div class={{divTagOne}}>

            <ng-container *ngIf="isLoading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <div role="alert" *ngIf="reviewSubmitMessage.length > 0">
              <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                <mat-icon>thumb_up</mat-icon>&nbsp;{{reviewSubmitMessage}}
              </div>
              <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                <mat-icon>thumb_down</mat-icon>&nbsp;{{reviewSubmitMessage}}
              </div>
            </div>

          </div>
          <div class={{divTagTwo}}>

            <button (click)="goBack(stepper)" class="dilog dilog1" *ngIf="!reviewSubmitStatus"
              [disabled]="disableBackButton">
              <mat-icon>arrow_back</mat-icon>
              Back
            </button>
            <button (click)="modal.close('Ok click')" class="dilog dilog3">
              <mat-icon>close</mat-icon>
              Exit
            </button>
            <button (click)="processRechage()" class="dilog dilog1" *ngIf="!reviewSubmitStatus"
              [disabled]="disableButton">
              <mat-icon>cached</mat-icon>
              Process Recharge
            </button>
            <button class="dilog dilog1" ngxPrint printSectionId="recharge_review" *ngIf="reviewSubmitStatus">
              <mat-icon>print</mat-icon>
              Print
            </button>

          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>