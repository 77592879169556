import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import {map, startWith} from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-complaint',
  templateUrl: './add-complaint.component.html',
  styleUrls: ['./add-complaint.component.scss']
})
export class AddComplaintComponent implements OnInit {
  faSpinner = faSpinner;
  addForm: UntypedFormGroup;
  isLoading:boolean = false;
  thumb:boolean = false;
  isLoad:boolean = true;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  submitted: boolean = false;
  
  public descriptionLength = new BehaviorSubject(0);
  filteredCType: Observable<any[]>;
  public complaintMessage = new UntypedFormControl('');
  AutoCTControl = new UntypedFormControl('', Validators.required);
  //addConsumerComplaint
  complaintType = [
    {name: 'Billing Units Issue'},
    {name: 'Recharge Not Done'},
    {name: 'About Adjustment'},
    {name: 'Customer Care Service'}
  ];

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
    this.currentUser = user;
    this.complaintMessage.valueChanges.subscribe((v)=> this.descriptionLength.next(v.length));
    });

  }

  ngOnInit() {
      if(this.isLoad){
        this.addForm = this.formBuilder.group({
          complaintSubject: ['', Validators.required],
          complaintMessage: ['', [Validators.required, Validators.maxLength(160)]]
        });
      }
      this.getComplaintType();
  }

  filteredCTypeNew(val: string) {
    return this.complaintType.filter(state =>
      state.name.toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
  }

  get aF() { 
   
    return this.addForm.controls; 
  }
  onSubmitAddForm(){
     if (this.addForm.invalid) {
        this.submitted = true;
       return;
     }
     this.isLoading = true;
     this.responseMessage = '';
     const formData = new FormData();
     formData.append('complaintSubject', this.aF.complaintSubject.value);
     formData.append('consComplaint', this.aF.complaintMessage.value);
     formData.append('consSubDivision', this.currentUser.data.userData.subName);
     formData.append('authToken', this.currentUser.authToken);
     this.AddProcess = true;
   this.genReqs.formDataPost('/addConsumerComplaint', formData).subscribe((result)=>{
     this.responseData = result;
     this.AddProcess = false;
     this.isLoading = false;
     this.thumb = true;
     this.responseMessage = this.responseData.data.message;
     this.isLoad=false;
     //this.addForm.reset();
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.AddProcess = false;
     this.isLoading = false;
     this.thumb = false;
     this.responseMessage = 'Unable to process yours request!';
   });
   }

   getComplaintType() {
    let body = new URLSearchParams();
    body.set('authToken', this.currentUser.authToken);
    body.set('subDivName', this.currentUser.data.userData.subName);
    this.genReqs.postReq('/getComplaintType', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        //console.log( this.responseData.data);
        this.complaintType = this.responseData.data;
        }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }
}
