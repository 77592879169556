<!----add authority start-->
<div class="modal-header">

  <h4 class="modal-title mat-dialog-title" id="modal-basic-title">
    <mat-icon class="d-c5">supervised_user_circle</mat-icon>Temper Report
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <mat-list role="list" *ngIf="temperData">
    <mat-list-item role="listitem">
      <mat-card style="width: 100%;">
        <div class="displayList">
          <b>Meter ID</b>
        </div>
        <div class="displayList">
          <b>Type</b>
        </div>
        <div class="displayList">
          <b>Name</b>
        </div>
      </mat-card>
    </mat-list-item>
    <mat-list-item role="listitem" *ngFor="let item of temperData">
      <mat-card style="width: 100%;">
        <div class="displayList">
          {{item.Meter_Id}}
        </div>
        <div class="displayList">
          {{item.Meter_Type}}
        </div>
        <div class="displayList">
          {{item.Cons_Full_Name}}
        </div>
      </mat-card>
    </mat-list-item>
  </mat-list>
  <div class="clearfix"></div>
  <br><br>
  <div class="col-12 text-right">
    <button type="button" (click)="modal.close('Cancel')" mat-button class="cPbg">Cancel</button>
    <button type="button" mat-button class="cPbg" (click)="markReadAll()">Clear All</button>
  </div>
</div>
<!----add authority end-->