<form [formGroup]="configMeter" (ngSubmit)="onSubmitConfigMeter()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input class="form-control" placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate"
                        autocomplete="off" formControlName="schedularStartEndDate"
                        (bsValueChange)="getDateFromRange($event)" bsDatepicker [bsConfig]="{ adaptivePosition: true }">
                        <i class="fa fa-calendar icon_innertxtbox"></i>
                    <label>Select Date</label>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Billing Mode For Grid" name="bMFEB" formControlName="bMFEB"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.bMFEB.errors }" required>
                        <mat-option *ngFor="let td of billingModeGrid" [value]="td.id">
                            {{td.Name}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Billing Mode For Grid</label>
                    <div *ngIf="submitted && cF.bMFEB.errors" class="invalid-feedback">
                        <div *ngIf="cF.bMFEB.errors.required">
                            Billing Mode For Grid is required.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Billing Mode For DG" name="bMFDG" formControlName="bMFDG"
                        class="form-control"  [ngClass]="{ 'is-invalid': submitted && cF.bMFDG.errors }" required>
                        <mat-option *ngFor="let td of billingModeDG" [value]="td.id">
                            {{td.Name}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Billing Mode For DG</label>
                    <div *ngIf="submitted && cF.bMFDG.errors" class="invalid-feedback">
                        <div *ngIf="cF.bMFDG.errors.required">
                            Billing Mode For DG is required.
                        </div>

                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="!this.meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Grid (0.05-15.00kW)" type="text" name="llgrid" formControlName="gslu"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.gslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Grid (0.05-15.00kW)</label>

                    <div *ngIf="submitted && cF.gslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.gslu.errors.required">
                            LL Grid is required.
                        </div>
                        <div *ngIf="cF.gslu.errors.min">
                            LL Grid Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.gslu.errors.max">
                            LL Grid Must be less than 15.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="this.meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Grid (0.05-65.00kW)" type="text" formControlName="gslu" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.gslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Grid (0.05-65.00kW)</label>
                    <div *ngIf="submitted && cF.gslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.gslu.errors.required">
                            LL Grid is required.
                        </div>
                        <div *ngIf="cF.gslu.errors.min">
                            LL Grid Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.gslu.errors.max">
                            LL Grid Must be less than 65.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Grid Alarm in (%)" type="text" formControlName="llga" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.llga.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>LL Grid Alarm in (%)</label>
                    <div *ngIf="submitted && cF.llga.errors" class="invalid-feedback">
                        <div *ngIf="cF.llga.errors.required">
                            Grid Supply Load Limit Alarm required
                        </div>
                        <div *ngIf="cF.llga.errors.min">
                            Grid Supply Load Limit Alarm is lower than the minimum
                        </div>
                        <div *ngIf="cF.llga.errors.max">
                            Grid Supply Load Limit Alarm is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"
            *ngIf="!this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL DG (0.05-15.00kW)" type="text" formControlName="bslu" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.bslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL DG (0.05-15.00kW)</label>
                    <div *ngIf="submitted && cF.bslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.bslu.errors.required">
                            LL DG is required.
                        </div>
                        <div *ngIf="cF.bslu.errors.min">
                            LL DG Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.bslu.errors.max">
                            LL DG Must be less than 15.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"
            *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL DG (0.05-65.00kW)" type="text" formControlName="bslu" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.bslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL DG (0.05-65.00kW</label>
                    <div *ngIf="submitted && cF.bslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.bslu.errors.required">
                            LL DG is required.
                        </div>
                        <div *ngIf="cF.bslu.errors.min">
                            LL DG Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.bslu.errors.max">
                            LL DG Must be less than 65.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" 
        *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL DG Alarm in (%)" type="text" formControlName="lldga" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lldga.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>LL DG Alarm in (%)</label>
                    <div *ngIf="submitted && cF.lldga.errors" class="invalid-feedback">
                        <div *ngIf="cF.lldga.errors.required">
                            DG Supply Load Limit Alarm required
                        </div>
                        <div *ngIf="cF.lldga.errors.min">
                            DG Supply Load Limit Alarm is lower than the minimum
                        </div>
                        <div *ngIf="cF.lldga.errors.max">
                            DG Supply Load Limit Alarm is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"
            *ngIf="!this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving Grid (0.05-15.00kW)" type="text" formControlName="eblusm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.eblusm.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>   
                    <label>LL Power Saving Grid (0.05-15.00kW)</label>
                    <div *ngIf="submitted && cF.eblusm.errors" class="invalid-feedback">
                        <div *ngIf="cF.eblusm.errors.required">
                            LL Power Saving Grid is required.
                        </div>
                        <div *ngIf="cF.eblusm.errors.min">
                            LL Power Saving Grid Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.eblusm.errors.max">
                            LL Power Saving Grid Must be less than 15.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4"
            *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving Grid (0.05-65.00kW)" type="text" formControlName="eblusm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.eblusm.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Power Saving Grid (0.05-65.00kW)</label>
                    <div *ngIf="submitted && cF.eblusm.errors" class="invalid-feedback">
                        <div *ngIf="cF.eblusm.errors.required">
                            LL Power Saving Grid is required.
                        </div>
                        <div *ngIf="cF.eblusm.errors.min">
                            LL Power Saving Grid Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.eblusm.errors.max">
                            LL Power Saving Grid Must be less than 65.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"  *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL PS Grid Alarm in (%)" type="text" formControlName="llpsga" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.llpsga.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>LL PS Grid Alarm in (%)</label>
                    <div *ngIf="submitted && cF.llpsga.errors" class="invalid-feedback">
                        <div *ngIf="cF.llpsga.errors.required">
                            LL PS Grid Supply Load Limit Alarm required
                        </div>
                        <div *ngIf="cF.llpsga.errors.min">
                            LL PS Grid Supply Load Limit Alarm is lower than the minimum
                        </div>
                        <div *ngIf="cF.llpsga.errors.max">
                            LL PS Grid Supply Load Limit Alarm is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"
            *ngIf="!this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving DG (0.05-15.00kW)" type="text" formControlName="dglsm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dglsm.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Power Saving DG (0.05-15.00kW)</label>
                    <div *ngIf="submitted && cF.dglsm.errors" class="invalid-feedback">
                        <div *ngIf="cF.dglsm.errors.required">
                            LL Power Saving DG is required.
                        </div>
                        <div *ngIf="cF.dglsm.errors.min">
                            LL Power Saving DG Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.dglsm.errors.max">
                            LL Power Saving DG Must be less than 15.00 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4"
            *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving DG (0.05-65.00kW)" type="text" formControlName="dglsm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dglsm.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Power Saving DG (0.05-65.00kW)</label>
                    <div *ngIf="submitted && cF.dglsm.errors" class="invalid-feedback">
                        <div *ngIf="cF.dglsm.errors.required">
                            LL Power Saving DG is required.
                        </div>
                        <div *ngIf="cF.dglsm.errors.min">
                            LL Power Saving DG Must be greator than 0.05 kW.
                        </div>
                        <div *ngIf="cF.dglsm.errors.max">
                            LL Power Saving DG Must be less than 65 kW.
                        </div>
                    </div>
                </span>
            </div>
        </div>

         <div class="col-md-4" *ngIf="this.meterTypeCheck && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL PS DG Alarm in (%)" type="text" formControlName="llpsdga" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.llpsdga.errors }" required>
                    <i class="fa fa-bell-o icon_innertxtbox"></i>
                    <label>LL PS Grid Alarm in (%)</label>
                    <div *ngIf="submitted && cF.llpsdga.errors" class="invalid-feedback">
                        <div *ngIf="cF.llpsdga.errors.required">
                            LL PS DG Supply Load Limit Alarm required
                        </div>
                        <div *ngIf="cF.llpsdga.errors.min">
                            LL PS DG Supply Load Limit Alarm is lower than the minimum
                        </div>
                        <div *ngIf="cF.llpsdga.errors.max">
                            LL PS DG Supply Load Limit Alarm is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration Grid (>30sec)" type="number" formControlName="lrdgs"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrdgs.errors }"
                        required>
                    <i class="fa fa-spinner icon_innertxtbox"></i>
                    <label>Load Restart Duration Grid (>30sec)</label>
                    <div *ngIf="submitted && cF.lrdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdgs.errors.required">
                            Load Restart Duration Grid is required.
                        </div>
                        <div *ngIf="cF.lrdgs.errors.min">
                            Load Restart Duration Grid Must be greator than 30 sec.
                        </div>
                        <div *ngIf="cF.lrdgs.errors.max">
                            Load Restart Duration Grid be less than 65536 sec.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration DG (>30sec)" type="number"
                        formControlName="lrddgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrddgs.errors }" required>
                    <i class="fa fa-spinner icon_innertxtbox"></i>
                    <label>Load Restart Duration DG (>30sec)</label>
                    <div *ngIf="submitted && cF.lrddgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrddgs.errors.required">
                            Load Restart Duration DG is required.
                        </div>
                        <div *ngIf="cF.lrddgs.errors.min">
                            Load Restart Duration DG Must be greator than 30 sec.
                        </div>
                        <div *ngIf="cF.lrddgs.errors.max">
                            Load Restart Duration DG Must be less than 65536 sec.
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration PS Grid(>30sec)" formControlName="lrdsmgs"
                        type="number" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrdsmgs.errors }" required>
                    <i class="fa fa-spinner icon_innertxtbox"></i>
                    <label>Load Restart Duration PS Grid(>30sec)</label>
                    <div *ngIf="submitted && cF.lrdsmgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdsmgs.errors.required">
                            Load Restart Duration PS Grid is required.
                        </div>
                        <div *ngIf="cF.lrdsmgs.errors.min">
                            Load Restart Duration PS Grid Must be greator than 30 sec.
                        </div>
                        <div *ngIf="cF.lrdsmgs.errors.max">
                            Load Restart Duration PS Grid Must be less than 65536 sec.
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration PS DG(>30sec)" formControlName="lrdsmdgs"
                        type="number" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrdsmdgs.errors }" required>
                    <i class="fa fa-spinner icon_innertxtbox"></i>
                    <label>Load Restart Duration PS DG(>30sec)</label>
                    <div *ngIf="submitted && cF.lrdsmdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdsmdgs.errors.required">
                            Load Restart Duration Power Saving DG is required.
                        </div>
                        <div *ngIf="cF.lrdsmdgs.errors.min">
                            Load Restart Duration Power Saving DG Must be greator than 30 sec.
                        </div>
                        <div *ngIf="cF.lrdsmdgs.errors.max">
                            Load Restart Duration Power Saving DG Must be less than 65536 sec.
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For Grid" name="lrcgs" formControlName="lrcgs"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrcgs.errors }"
                        required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-eercast icon_innertxtbox"></i>
                    <label>Load Restart Counter For Grid</label>
                    <div *ngIf="submitted && cF.lrcgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcgs.errors.required">
                            Load Restart Counter For Grid is required.
                        </div>
                        
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For DG" name="lrcdgs" formControlName="lrcdgs"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrcdgs.errors }"
                        required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-eercast icon_innertxtbox"></i>
                    <label>Load Restart Counter For DG</label>
                    <div *ngIf="submitted && cF.lrcdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcdgs.errors.required">
                            Load Restart Counter For DG is required.
                        </div>
                       
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter PS Grid" name="lrcsmgs"
                        formControlName="lrcsmgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrcsmgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-eercast icon_innertxtbox"></i>
                    <label>Load Restart Counter PS Grid</label>
                    <div *ngIf="submitted && cF.lrcsmgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcsmgs.errors.required">
                            Load Restart Counter Power Saving Grid is required.
                        </div>
                        
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For PS DG" name="lrcsmdgs"
                        formControlName="lrcsmdgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrcsmdgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-eercast icon_innertxtbox"></i>
                    <label>Load Restart Counter For PS DG</label>
                    <div *ngIf="submitted && cF.lrcsmdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcsmdgs.errors.required">
                            Load Restart Counter For Power Saving DG is required.
                        </div>
                       
                    </div>

                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving On Hour" name="ssmonh" formControlName="ssmonh"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmonh.errors }"
                        required>
                        <mat-option *ngFor="let td of smOnHour" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-hourglass-half icon_innertxtbox"></i>
                    <label>Schedule Power Saving On Hour</label>
                    <div *ngIf="submitted && cF.ssmonh.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmonh.errors.required">
                            Schedule Power Saving On Hour is required.
                        </div>
                        <div *ngIf="cF.ssmonh.errors.min">
                            Schedule Power Saving On Hour Must be greator than 1.
                        </div>
                        <div *ngIf="cF.ssmonh.errors.max">
                            Schedule Power Saving On Hour Must be less than 25.
                        </div>
                    </div>

                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving On Minute" name="ssmonm" formControlName="ssmonm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmonm.errors }"
                        required>
                        <mat-option *ngFor="let td of smOnMinute" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-hourglass-half icon_innertxtbox"></i>
                    <label>Schedule Power Saving On Minute</label>
                    <div *ngIf="submitted && cF.ssmonm.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmonm.errors.required">
                            Schedule Power Saving On Minute is required.
                        </div>
                        <div *ngIf="cF.ssmonm.errors.min">
                            Schedule Power Saving On Minute Must be greator than 1.
                        </div>
                        <div *ngIf="cF.ssmonm.errors.max">
                            Schedule Power Saving On Minute Must be less than 25.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving Off Hour" name="ssmtoffh" formControlName="ssmtoffh"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmtoffh.errors }"
                        required>
                        <mat-option *ngFor="let td of smOnHour" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-hourglass-half icon_innertxtbox"></i>
                    <label>Schedule Power Saving Off Hour</label>
                    <div *ngIf="submitted && cF.ssmtoffh.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmtoffh.errors.required">
                            Schedule Power Saving Off Hour is required.
                        </div>
                        <div *ngIf="cF.ssmtoffh.errors.min">
                            Schedule Power Saving Off Hour Must be greator than 1.
                        </div>
                        <div *ngIf="cF.ssmtoffh.errors.max">
                            Schedule Power Saving Off Hour Must be less than 25.
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving Off Minute" name="ssmtoffm"
                        formControlName="ssmtoffm" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.ssmtoffm.errors }" required>
                        <mat-option *ngFor="let td of smOnMinute" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-hourglass-half icon_innertxtbox"></i>
                    <label>Schedule Power Saving Off Minute</label>
                    <div *ngIf="submitted && cF.ssmtoffm.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmtoffm.errors.required">
                            Schedule Power Saving Off Minute is required.
                        </div>
                        <div *ngIf="cF.ssmtoffm.errors.min">
                            Schedule Power Saving Off Minute Must be greator than 1.
                        </div>
                        <div *ngIf="cF.ssmtoffm.errors.max">
                            Schedule Power Saving Off Minute Must be less than 25.
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Overvoltage Thresholds (245-440)" formControlName="ovthrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ovthrasholds.errors }"
                        required>
                    <i class="fa fa-houzz icon_innertxtbox"></i>
                    <label>Overvoltage Thresholds (245-440)</label>
                    <div *ngIf="submitted && cF.ovthrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.ovthrasholds.errors.required">
                            Overvoltage Thresholds is required.
                        </div>
                        <div *ngIf="cF.ovthrasholds.errors.min">
                            Overvoltage Thresholds Must be greator than 245.
                        </div>
                        <div *ngIf="cF.ovthrasholds.errors.max">
                            Overvoltage Thresholds Must be less than 440.
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Low Voltage Thresholds (144-220)" formControlName="lvthrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lvthrasholds.errors }"
                        required>
                    <i class="fa fa-bars icon_innertxtbox"></i>
                    <label>Low Voltage Thresholds (144-220)</label>
                    <div *ngIf="submitted && cF.lvthrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.lvthrasholds.errors.required">
                            Low Voltage Thresholds is required.
                        </div>
                        <div *ngIf="cF.lvthrasholds.errors.min">
                            Low Voltage Thresholds Must be greator than 144.
                        </div>
                        <div *ngIf="cF.lvthrasholds.errors.max">
                            Low Voltage Thresholds Must be less than 220.
                        </div>
                    </div>
                </span>
            </div>
        </div>



        <div class="col-md-4" *ngIf="!meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Over Current Thresholds (1-63)" formControlName="octhrasholds" type="number"
                        class="form-control
                        " [ngClass]="{ 'is-invalid': submitted && cF.octhrasholds.errors }"
                        required>
                        <i class="fa fa-bars icon_innertxtbox"></i>
                    <label>Over Current Thresholds (1-63)</label>

                    <div *ngIf="submitted && cF.octhrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.octhrasholds.errors.required">
                            Over Current Thresholds required
                        </div>
                        <div *ngIf="cF.octhrasholds.errors.min">
                            Over Current Thresholds Must be greator than 1.
                        </div>
                        <div *ngIf="cF.octhrasholds.errors.max">
                            Over Current Thresholds Must be less than 63.
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Over Current Thresholds (1-80)" formControlName="octhrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.octhrasholds.errors }"
                        required>
                        <i class="fa fa-bars icon_innertxtbox"></i>
                    <label>Over Current Thresholds (1-80)</label>

                    <div *ngIf="submitted && cF.octhrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.octhrasholds.errors.required">
                            Over Current Thresholds required
                        </div>
                        <div *ngIf="cF.octhrasholds.errors.min">
                            Over Current Thresholds Must be greator than 1.
                        </div>
                        <div *ngIf="cF.octhrasholds.errors.max">
                            Over Current Thresholds Must be less than 80.
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>
           
            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <!--<button type="submit" [disabled]="AddProcess" class="dilog dilog1">-->
            <button type="submit" class="dilog dilog1">    
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>
    <!--<button mat-raised-button color="primary" type="submit">Config Load Limits</button>-->
</form>