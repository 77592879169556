<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Daily Consumption: {{meterDetail.mID}}
    </h4>
  </div>
    <div class="modal-body">
        <ng-container>
          <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>

          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData && view=='N'">
            <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Cons_Account_No==1">
                    <div class="d_title" >
                      <span></span>Cons UId
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Meter_Unque_Id==1">
                    <div class="d_title">
                      <span></span>Meter UId
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Flat_No_Id==1">
                    <div class="d_title">
                      <span></span>Flat No
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Meter_Id==1">
                    <div class="d_title">
                      <span></span>Meter No
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_RTC==1">
                    <div class="d_title">
                      <span></span>Date
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Active_Energy_Imp==1">
                    <div class="d_title ">
                      <span></span>kWh Import
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Active_Energy_Expo==1">
                    <div class="d_title ">
                      <span></span>kWh Export
                  </div>
                  </td>

                  <td *ngIf="permission.Single_Daily_Parm_Apperant_Energy_Imp==1">
                    <div class="d_title ">
                      <span></span>kVAh Import
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Apperant_Energy_Expo==1">
                    <div class="d_title ">
                      <span></span>kVAh Export
                  </div>
                  </td>
                  <td  *ngIf="permission.Single_Daily_Parm_Server_Datetime==1">
                    <div class="d_title ">
                      <span></span>Server Datetime
                  </div>
                  </td>
                  <td *ngIf="permission.Single_Daily_Parm_Authority==1">
                    <div class="d_title ">
                      <span></span>Authority Name
                  </div>
                  </td>
                </tr>
              </thead>
              <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Cons_Account_No==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cUID}}</p>
                  </div>
                </td>
                <td  *ngIf="permission.Single_Daily_Parm_Meter_Unque_Id==1">
                  <div class="d_content d_content_W">
                    <p>{{item.mUID}}</p>
                  </div>
                </td>
                <td  *ngIf="permission.Single_Daily_Parm_Flat_No_Id==1">
                  <div class="d_content d_content_W">
                    <p>{{item.flatNo}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Meter_Id==1">
                  <div class="d_content d_content_W" >
                    <p>{{item.meterId}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_RTC==1">
                  <div class="d_content d_content_W">
                    <p>{{item.meterDateTime}}</p>
                  </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Active_Energy_Imp==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhEB}}</p>
                </div>
                </td>
              
                <td *ngIf="permission.Single_Daily_Parm_Active_Energy_Expo==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhEBExport}}</p>
                </div>
                </td>

                <td *ngIf="permission.Single_Daily_Parm_Apperant_Energy_Imp==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkVAhEB}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Apperant_Energy_Expo==1">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkVAhEBExport}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Server_Datetime==1">
                  <div class="d_content d_content_W">
                    <p>{{item.dateTime}}</p>
                </div>
                </td>
                <td *ngIf="permission.Single_Daily_Parm_Authority==1">
                  <div class="d_content d_content_W">
                    <p>{{item.authName}}</p>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>          
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>--> 
          </div>

          <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData && view=='Y'">
            <table class="table table-bordered table-sm table-hover">
              <thead class="tablebghead">
                <tr>
                  <td>
                    <div class="d_title">
                      <span></span>Sr. No.
                  </div>
                  </td>
                  <td>
                    <div class="d_title">
                      <span></span>Date
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Billing Mode EB
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Billing Mode DG
                  </div>
                  </td>
                  
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Consumed kWh EB
                  </div>
                  </td>
                  <td *ngIf="view!='Y'">
                    <div class="d_title ">
                      <span></span>Consumed kWh
                  </div>
                  </td>
                  <!--<td *ngIf="meterType==3">
                    <div class="d_title ">
                      <span></span>Cum. kWh EB Export
                  </div>
                  </td>-->
                  <td *ngIf="view=='Y'">
                    <div class="d_title">
                      <span></span>Consumed kWh DG
                  </div>
                  </td>
                  <td  *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Consumed kVAh EB
                  </div>
                  </td>
                  <td  *ngIf="view!='Y'">
                    <div class="d_title ">
                      <span></span>Consumed kVAh
                  </div>
                  </td>
                  <!--<td *ngIf="meterType==3">
                    <div class="d_title ">
                      <span></span>Cum. kVAh EB Export
                  </div>
                  </td>-->
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Consumed kVAh DG
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Total Free Units DG
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Available Free Units DG
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Opening Balance(Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Credit (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Debit (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>EB Charges (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>DG Charges (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Fixed Charges (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Bill Amount (Rs.)
                  </div>
                  </td>
                  <td *ngIf="view=='Y'">
                    <div class="d_title ">
                      <span></span>Closing Balance (Rs.)
                  </div>
                  </td>
                </tr>
              </thead>
              <tbody class="tbbodystyle">
              <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                <td>
                  <div class="d_content d_content_W">
                    <p>{{i+1}}</p>
                  </div>
                </td>
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.meterDateTime}}</p>
                  </div>
                </td>
  
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.billingModeEB}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.billingModeDG}}</p>
                </div>
                </td>
                              
                <td>
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhEB}}</p>
                </div>
                </td>
              
                <!--<td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhEBExport}}</p>
                </div>
                </td>-->

                <td *ngIf="view=='Y'"> 
                  <div class="d_content d_content_W">
                    <p>{{item.cumkWhDG}}</p>
                </div>
                </td>
                <td >
                  <div class="d_content d_content_W">
                    <p>{{item.cumkVAhEB}}</p>
                </div>
                </td>
                <!--<td *ngIf="meterType==3">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkVAhEBExport}}</p>
                </div>
                </td>-->
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.cumkVAhDG}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.freeUnits}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.availableFreeUnits}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.openingBalance}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.credits}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.debits}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.ebCharges}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.dgCharges}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.fixedCharges}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.billAmount}}</p>
                </div>
                </td>
                <td *ngIf="view=='Y'">
                  <div class="d_content d_content_W">
                    <p>{{item.closingBalance}}</p>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            <span></span>{{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>          
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>--> 
          </div>

          <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
            <div class="col-sm-12">
              <img class="center" src="assets/images/Nodata.png">
            </div>
            <!--<div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
          </div>
          
        </ng-container>
    <ng-container col-sm-3>
    </ng-container>
</div>
</div>
  