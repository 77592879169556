<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header">
        <button type="button" (click)="modal.close('Ok click')" class="dilog4 dilog5" class="close"
            data-dismiss="modal">&times;</button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="form-groupp">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="userprofile_data">
                            <div class="text-center mb-4">
                                <img class="avatar_user  rounded-circle border_cstm" src="assets/img/director_img.png"
                                    alt="">
                                <h4>Sub-Division/Society Name </h4>
                                <h4 style="text-align:center;"><input matInput placeholder="Sub-Division Name"
                                        class="txtcentr" name="subdivId" formControlName="subdivId"
                                        style="text-align:center;"></h4>
                                <mat-error *ngIf="aD.subdivId.hasError('required')">
                                    Sub-Division Name is required
                                </mat-error>
                            </div>
                            <div class="userlbl">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><i class="fa fa-user iconsie iconalllbl"></i>Aauthority Full
                                                Name</label>
                                            <input matInput placeholder="" name="authFullName"
                                                formControlName="authFullName" class="form-control">
                                            <mat-error *ngIf="aD.authFullName.hasError('required')">
                                                Authority Full Name is required
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label><i class="fa fa-user iconsie iconalllbl"></i>Authority User
                                                Name</label>
                                            <input matInput placeholder="Authority User Name" class="form-control"
                                                name="authUserName" formControlName="authUserName">
                                            <mat-error *ngIf="aD.authUserName.hasError('required')">
                                                Authority User Name is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">email</mat-icon>Authority Email
                                                Id
                                            </label>
                                            <input matInput placeholder="" name="authEmailId"
                                                formControlName="authEmailId" class="form-control">
                                            <mat-error *ngIf="aD.authEmailId.hasError('required')">
                                                Authority Email Id is required
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">call</mat-icon>Authority Landline
                                                No
                                            </label>
                                            <input type="tel" matInput placeholder="" name="authLandlineNo"
                                                formControlName="authLandlineNo" pattern="[0-9 -()+]+$" minlength="10"
                                                maxlength="10" class="form-control">
                                            <mat-error *ngIf="aD.authLandlineNo.hasError('required')">
                                                Consumer Landline is required
                                            </mat-error>
                                            <mat-error *ngIf="aD.authLandlineNo.hasError('pattern')">
                                                Consumer Landline number not valid.
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">stay_primary_portrait</mat-icon>
                                                Authority Mobile No
                                            </label>
                                            <input matInput placeholder="" name="authMobileNo"
                                                formControlName="authMobileNo" class="form-control">
                                            <mat-error *ngIf="aD.authMobileNo.hasError('required')">
                                                Authority Mobile No is required
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">home</mat-icon>Auth Home Address
                                                Line 1
                                            </label>
                                            <input matInput placeholder="" name="authHomeAddressline1"
                                                formControlName="authHomeAddressline1" class="form-control">
                                            <mat-error *ngIf="aD.authHomeAddressline1.hasError('required')">
                                                Auth Home Address Line 1 is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">home</mat-icon>Auth Home Address
                                                Line 2
                                            </label>
                                            <input matInput placeholder="" name="authHomeAddressline2"
                                                formControlName="authHomeAddressline2" class="form-control">
                                            <mat-error *ngIf="aD.authHomeAddressline2.hasError('required')">
                                                Auth Home Address Line 2 is required
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">location_city</mat-icon>Auth
                                                Office Address Line 1
                                            </label>
                                            <input matInput placeholder="Auth Office Address Line 1"
                                                name="authOfficeAddressline1" formControlName="authOfficeAddressline1"
                                                class="form-control">
                                            <mat-error *ngIf="aD.authOfficeAddressline1.hasError('required')">
                                                Auth Office Address Line 1 is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">location_city</mat-icon>Auth
                                                Office Address Line 2
                                            </label>
                                            <input matInput placeholder="" name="authOfficeAddressline2"
                                                formControlName="authOfficeAddressline2" class="form-control">
                                            <mat-error *ngIf="aD.authOfficeAddressline2.hasError('required')">
                                                Auth Office Address Line 2 is required
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>
                                                <mat-icon matPrefix class="iconalllbl">location_city</mat-icon>Auth
                                                Office Address Line 1
                                            </label>
                                            <!--<input matInput 
                                                placeholder="Authority DOB (MM/DD/YYYY)"
                                                class="form-control" 
                                                name="authorityDOB" 
                                                formControlName="authorityDOB"
                                                (bsValueChange)="getDOB($event)" 
                                                bsDatepicker
                                                [bsConfig]="{ adaptivePosition: true }">-->
                                            <input matInput placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)"
                                                bsDatepicker [bsConfig]="{ adaptivePosition: true }">
                                            <mat-error *ngIf="aD.authorityDOB.hasError('required')">
                                                Authority DOB is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <ng-container *ngIf="isLoading">
                                            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter">
                                            </fa-icon>
                                        </ng-container>
                                        <div role="alert" *ngIf="addAuthMessage.length > 0">
                                            <div class="alert alert-success" role="alert" *ngIf="thumb">
                                                <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                                            </div>
                                            <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                                                <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                                            </div>
                                        </div>

                                        <div class="col-sm-12 text-right">
                                            <button type="submit" class="dilog dilog1">
                                                <mat-icon>add</mat-icon>
                                                Submit
                                            </button>
                                            <button type="button" (click)="modal.close('Ok click')"
                                                class="dilog dilog3">
                                                <mat-icon>cancel</mat-icon>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--   <div class="form-row">
            

                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Sub-Division Name" name="subdivId" formControlName="subdivId">
                    <mat-icon matPrefix class="d-c8">flag</mat-icon>
                    <mat-error *ngIf="aD.subdivId.hasError('required')">
                        Sub-Division Name is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Authority Full Name" name="authFullName"
                        formControlName="authFullName">
                    <mat-icon matPrefix class="d-c10">edit</mat-icon>
                    <mat-error *ngIf="aD.authFullName.hasError('required')">
                        Authority Full Name is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Authority User Name" name="authUserName"
                        formControlName="authUserName">
                    <mat-icon matPrefix class="d-c11">edit</mat-icon>
                    <mat-error *ngIf="aD.authUserName.hasError('required')">
                        Authority User Name is <strong>required</strong>
                    </mat-error>
                </mat-form-field> 

                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Authority Email Id" name="authEmailId" formControlName="authEmailId">
                    <mat-icon matPrefix class="d-c13">email</mat-icon>
                    <mat-error *ngIf="aD.authEmailId.hasError('required')">
                        Authority Email Id is <strong>required</strong>
                    </mat-error>
                </mat-form-field> 
                <mat-form-field class="col-md-6" >
                <input 
                type="tel"  
                matInput 
                placeholder="Authority Landline No" 
                name="authLandlineNo" 
                formControlName="authLandlineNo" 
                pattern="[0-9 -()+]+$" minlength="10" maxlength="10">
                    <mat-icon matPrefix class="d-c6">call</mat-icon>
                    <mat-error *ngIf="aD.authLandlineNo.hasError('required')">
                    Consumer Landline is required
                    </mat-error>
                    <mat-error *ngIf="aD.authLandlineNo.hasError('pattern')">
                    Consumer Landline number not valid.  
                </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Authority Mobile No" name="authMobileNo"
                        formControlName="authMobileNo">
                    <mat-icon matPrefix class="d-c15">stay_primary_portrait</mat-icon>
                    <mat-error *ngIf="aD.authMobileNo.hasError('required')">
                        Authority Mobile No is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Auth Home Address Line 1" name="authHomeAddressline1"
                        formControlName="authHomeAddressline1">
                    <mat-icon matPrefix class="d-c2">home</mat-icon>
                    <mat-error *ngIf="aD.authHomeAddressline1.hasError('required')">
                        Auth Home Address Line 1 is <strong>required</strong>
                    </mat-error>
                </mat-form-field> 
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Auth Home Address Line 2" name="authHomeAddressline2"
                        formControlName="authHomeAddressline2">
                    <mat-icon matPrefix class="d-c3">home</mat-icon>
                    <mat-error *ngIf="aD.authHomeAddressline2.hasError('required')">
                        Auth Home Address Line 2 is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Auth Office Address Line 1" name="authOfficeAddressline1"
                        formControlName="authOfficeAddressline1">
                    <mat-icon matPrefix class="d-c4">location_city</mat-icon>
                    <mat-error *ngIf="aD.authOfficeAddressline1.hasError('required')">
                        Auth Office Address Line 1 is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    <input matInput placeholder="Auth Office Address Line 2" name="authOfficeAddressline2"
                        formControlName="authOfficeAddressline2">
                    <mat-icon matPrefix class="d-c5">location_city</mat-icon>
                    <mat-error *ngIf="aD.authOfficeAddressline2.hasError('required')">
                        Auth Office Address Line 2 is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6" >
                    
                <input matInput 
                placeholder="Authority DOB (MM/DD/YYYY)" 
                name="authorityDOB" 
                formControlName="authorityDOB"
                (bsValueChange)="getDOB($event)" 
                bsDatepicker [bsConfig]="{ adaptivePosition: true }">

                    <mat-error *ngIf="aD.authorityDOB.hasError('required')">
                        Authority DOB is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

            </div>
        
            <div class="clearfix"></div>
            <div class="col-12 row">
            <div class="col-sm-6 text-left">
                <ng-container *ngIf="isLoading">
                    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                    <div role="alert" *ngIf="addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                        <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    </div>
                
            </div>
                
            <div class="col-sm-6 text-right">
                <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
                </button>
                <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
                </button>

            </div>
            </div>-->
        </div>
    </div>
</form>
<!----add authority end-->