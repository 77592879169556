import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../_services';
import { Access } from '../_models/access';
import { GridlistService } from '../_services/gridlist.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-country-to-state',
  templateUrl: './country-to-state.component.html',
  styleUrls: ['./country-to-state.component.scss']
})
export class CountryToStateComponent implements OnInit {

  currentUser: User;
  currentUserSubscription: Subscription;
  getThrough : Access;
  users: User[] = [];
  allUsersLablesData : any;
  responseData: any;
  allutilitys: {};
  loadingUtilitys = false;
  messageUtilitys = 'No Utility List Found';
  countryId:any = 0;
  infoLinks : any = [];
  bCumCountry: any = '';
  breakpoint: number;
  closeResult: string;
  addStateForm: UntypedFormGroup;
  editStateForm: UntypedFormGroup;
  responseMessage:string = '';
  stateName:string = '';
  accesLevel = 0;
  levelSearch: any = '';
  faSpinner = faSpinner;
  submitted: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: NgbModal,
    private gridlistService: GridlistService,
    private formBuilder: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService
  ) { 
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
        });
        this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
        
        this.route.queryParams.subscribe(params => {
          this.countryId = this.genReqs.decryptData(params['country']);
        });
        this.breakpoint = this.gridlistService.breakpoint;
        this.accesLevel = parseInt(this.currentUser.data.userData.authLevel) || 0;
  }

  ngOnInit() {
    this.authenticationService.getDeshboardRoute();
    this.getBreadCrums();
    this.getAccesslevels();
    this.reCountCols(window.innerWidth);
  }

  ngAfterViewInit() {
    this.addStateForm = this.formBuilder.group({
      stateName: ['', Validators.required],
      stateCode: ['', Validators.required],
      stateImage: [null, Validators.required]
    });
    this.editStateForm = this.formBuilder.group({
      stateId: ['', Validators.required],
      stateName: ['', Validators.required],
      stateCode: ['', Validators.required],
      stateImage: [null, Validators.required]
    });
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
}

onResize(event) {
  this.reCountCols(event.target.innerWidth);
}

reCountCols(width:number) {
  this.breakpoint = this.gridlistService.reCountCols(width);
}

  getAccesslevels() {
      this.loadingUtilitys = true;
      let body = new URLSearchParams();
      body.set('country', this.countryId);
      body.set('filter', this.levelSearch);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/getState', body).subscribe((result)=>{
        this.responseData = result;
        this.loadingUtilitys = false;
        if(this.responseData.success){
          this.allutilitys = this.responseData.data;
        }else{
          this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
        }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.loadingUtilitys = false;
        this.messageUtilitys = 'Unable to process yours request!';
      });
  }

  onLevelSearchChange(search){
    this.levelSearch = search;
    //this.skip = 0;
    this.getNotification(false);
  }

  getNotification(addNew:boolean=false) {
    //this.loadingUtilitys = true;
    let body = new URLSearchParams();
    body.set('country', this.countryId);
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getState', body).subscribe((result)=>{
      this.responseData = result;
      this.loadingUtilitys = false;
      if(this.responseData.success){
        this.allutilitys = this.responseData.data;
      }else{
        this.messageUtilitys = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingUtilitys = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }



  getBreadCrums() {
    let body = new URLSearchParams();
    body.set('countryId', this.countryId);
    body.set('stateId', '0');
    body.set('utilityId', '0');
    body.set('circleId', '0');
    body.set('divisionId', '0');
    body.set('subdivisionId', '0');
    body.set('dcuId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getNames', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.infoLinks = this.responseData.data.country;
        this.bCumCountry = this.responseData.data.country[0].countName;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

   /*
      bredcrum permissions
    */

   isCountry():boolean
   {
     return this.accesLevel == 1;
   }

   isState():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 2;
   }

   isUtility():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 3;
   }

   isCircle():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 4;
   }

   isDivision():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 5;
   }

   isSubDivision():boolean
   {
     return this.accesLevel >= 1 && this.accesLevel <= 6;
   }

  goToStateView(state_id){
    this.router.navigate(['/deshauthutility'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId), 'state': this.genReqs.encryptData(state_id)}});
  }

  backToCountryView(){
      this.router.navigate(['/deshauthstates'], { queryParams: { 'country': this.genReqs.encryptData(this.countryId)}});
  }

    /**to get the meter list on click on Dcu */
    stateEditView(row, content) {
      this.responseMessage = '';
      this.eS.stateId.setValue(row.state_id);
      this.eS.stateCode.setValue(row.stateCode);
      this.eS.stateName.setValue(row.stateName);
      this.stateName = row.stateName;
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    open(content) {
      this.responseMessage = '';
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }

   
    onFileSelect(event) {
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];
        //console.log(file.type);
          if(file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
            const file = event.target.files[0];
            this.aS.stateImage.setValue(file);
            //console.log(file.type,file);
            return true;
          }
          else {
            this.aS.stateImage.setValue(null);
            alert("Please select Files in PNG/JPEG Formats");
             return false;
          }
      }
     /* if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.eC.countryImage.setValue(file);
      }*/
    }
  
   /* onFileSelectEdit(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.eS.countryImage.setValue(file);
      }
    }*/

    onFileSelectEdit(event) {
      if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];
        //console.log(file.type);
          if(file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/gif") {
            const file = event.target.files[0];
            this.eS.stateImage.setValue(file);
            //console.log(file.type,file);
            return true;
          }
          else {
            this.eS.stateImage.setValue(null);
            alert("Please select Files in PNG/JPEG Formats");
             return false;
          }
      }
     /* if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.eC.countryImage.setValue(file);
      }*/
    }
  

    get aS() { return this.addStateForm.controls; }
    get eS() { return this.editStateForm.controls; }
    onSubmitStateCountry(){
      if (this.addStateForm.invalid) {
          this.submitted = true;
        return;
      }
      const formData = new FormData();
      if (this.aS.stateImage.value != null) {
        formData.append('stateImage', this.aS.stateImage.value);
      }else{
        alert("Please select Files in PNG/JPEG Formats");
        return false;
      }

      formData.append('stateImage', this.aS.stateImage.value);
      formData.append('countryId', this.countryId);
      formData.append('stateName', this.aS.stateName.value);
      formData.append('stateCode', this.aS.stateCode.value);
      formData.append('authToken', this.currentUser.authToken);
      this.genReqs.formDataPost('/addState', formData).subscribe(
        (res) => {
          this.responseMessage = res.message;
          if (res.success) {
            this.addStateForm.reset();
            this.getAccesslevels();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
        }
      );
    }

    onSubmitEditStateCountry() {
      //addCountry
        if (this.editStateForm.invalid) {
           this.submitted = true;
          return;
        }
        const formData = new FormData();
        if (this.eS.stateImage.value != null) {
          formData.append('stateImage', this.eS.stateImage.value);
        }else{
          alert("Please select Files in PNG/JPEG Formats");
          return false;
        }

        formData.append('countryId', this.countryId);
        formData.append('stateId', this.eS.stateId.value);
        formData.append('stateName', this.eS.stateName.value);
        formData.append('stateCode', this.eS.stateCode.value);
        formData.append('authToken', this.currentUser.authToken);
        this.genReqs.formDataPost('/editState', formData).subscribe(
          (res) => {
            this.responseMessage = res.message;
            if (res.success) {
              this.getAccesslevels();
              //this.editStateForm.reset();
            }
          },
          (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
          }
        );
    }

    removelevel(row){
      //deleteAuth
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove '+row.stateName+' ?')
      .then((confirmed) => {
        if(confirmed) {
          //this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('stateName', row.stateName);
          body.set('stateId', row.state_id);
          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteState', body).subscribe((result)=>{
            this.responseData = result;
            //this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getAccesslevels();
          },(err)=>{
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
    }    

}
