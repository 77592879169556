import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/_services/modal.service';
import { UntypedFormBuilder } from '@angular/forms';
import { generalRequestsService } from 'src/app/_services';

@Component({
  selector: 'app-mobfail',
  templateUrl: './mobfail.component.html',
  styleUrls: ['./mobfail.component.scss']
})
export class MobfailComponent implements OnInit {

  mihpayid:any;
  key:any;
  txnid:any;
  
  discount:any;
  offer:any;
  productinfo:any;
  firstname:any;
  lastname:any;
  email:any;
  phone:any;
  hash:any;
  error:any;
  bankcode:any;
  PG_TYPE:any;
  bank_ref_num:any;
  authToken: string = '';
  responseData: any;
  tranId: any;
  responseMessage:string = '';
  tranDetails:string = '';

  successReacharge = {
    uniqueId: "",
    mode: "",
    status: "",
    tranNo: "",
    amount: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    productInfo: "",
    dateTime: ""
  };

  uniqueId:any;
  mode: any;
  status: any;
  tranNo: any;
  amount: any;
  firstName: any;
  lastName: any;
  emailId: any;
  mobileNo: any;
  productInfo: any;
  dateTime: any;
  invalidMessage:any = '';
  reviewSubmitMessage = '';
  reviewSubmitStatus: boolean = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private genReqs:generalRequestsService,
  ) { 

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.authToken = params['authToken'];
      });
      this.getRechargeUserInfo();
  }

  getRechargeUserInfo() {
    this.reviewSubmitMessage = '';
    this.reviewSubmitStatus = false;
    let body = new URLSearchParams();
    body.set('authToken', this.authToken);
    
    this.genReqs.postReq('/getOnlineRechargeInfo', body).subscribe((result)=>{
    this.responseData = result;
      if(this.responseData.success){
        this.reviewSubmitMessage = this.responseData.message;
        this.reviewSubmitStatus = this.responseData.success;

        this.successReacharge = this.responseData.data;
       
      }
    },(err)=>{
      this.reviewSubmitMessage = 'Unable to process yours request!';
          this.reviewSubmitStatus = false;
    });
  }

  printRechargeInfo() {
    let printContents, popupWin;
    printContents = document.getElementById('recharge_review').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Recharge Info</title>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}