<div class="modal-content-new">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Consumer Bills: {{meterDetail.meterId}}
        </h4>
    </div>
    <div class="modal-body">
        <ng-container>
            <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
                <!--<fa name="spinner" animation="spin" size="4x"></fa>-->
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>
            <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
                <!--<div>{{meterData.meterId}}</div>
                <div>{{meterData.meterType}}</div>
                <div>{{meterData.token}}</div>-->
                <table class="table table-bordered table-sm table-hover">
                    <thead class="tablebghead">
                        <tr>
                            <td>
                                <div class="d_title">
                                    <span></span>Sr. No.
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Meter Id
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Meter Type
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Meter Real D/T
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Avg PF
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>CUM kWh
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kWh Zone 1
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kWh Zone 2
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kWh Zone 3
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kWh Zone 4
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kWh Zone 5
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>CUM kVAh
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kVAh Zone 1
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kVAh Zone 2
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kVAh Zone 3
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kVAh Zone 4
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>kVAh Zone 5
                                </div>
                            </td>

                            <td>
                                <div class="d_title">
                                    <span></span>Md kW
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Md Kw DT
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Md Kva
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>MD Kva DT
                                </div>
                            </td>
                            <td>
                                <div class="d_title">
                                    <span></span>Bill Power On Hours
                                </div>
                            </td>

                        </tr>
                    </thead>
                    <tbody class="tbbodystyle">
                        <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{i+1}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.meterId}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.meterType}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.meterRealDateTIme}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.avgPf}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwh}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwhZone1}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwhZone2}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwhZone3}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwhZone4}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kwhZone5}}</p>
                                </div>
                            </td>

                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAh}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAhZone1}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAhZone2}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAhZone3}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAhZone4}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.kVAhZone5}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.mdkW}}</p>
                                </div>
                            </td>

                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.mdKwDT}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.mdKva}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.mdKvaDT}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="d_content d_content_W">
                                    <p>{{item.billPowerOnHours}}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">

                <div class="col-sm-12">
                    <!--<div>Meter Id:-{{meterData.meterId}}</div>
                    <div>Type:-{{meterData.meterType}}</div>
                    <div>Token:-{{meterData.token}}</div>-->
                    <img class="center" src="assets/images/NodataBlue.png">
                </div>

                <!--<div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
            </div>

        </ng-container>
       
    </div>
</div>