<div class="container-fluid innerpage_top page-content-wrapperr">
  <div class="content-viewport">
    <div class="page-content-wrapper-inner outerbox_shadow {{divClass}}">
      <div class="">
        <div class="boxflexEll">
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <h1>All Disabled Meter List
                <span class="info_icntop ml-1">
                  <i data-toggle="tooltip" data-placement="top" title="Information of All Disabled Meter List
                      List" class="fa fa-info tabula-tooltip "></i>
                </span>
              </h1>
            </div>
            <div class="col-md-7 col-sm-12" *ngIf="isDataRedyForTableDcu">
              <div class="flex_auth">
                <div class="authsearch" *ngIf="allmeterTotalRecord!=0">
                  <div class="search">
                    <input type="text" class="search-txt searchplaceholder" placeholder="Search Flat Id Or Meter Id"
                      value="{{meterSearch}}" (input)="onMeterSearchChange($event.target.value)" autocomplete="off">
                    <div class="search-btn nobg" href="#">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="!loadingNoData">
                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                    style="color: #000;" class="npMi circlebtn">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage">
                    <div class="topspacemodelgrip">
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <div class="add_rowEgrid">Add Details</div>
                    </div>
                    <mat-list role="list" *ngFor="let item of meterHeadsCols" class="listaddinfogridfull">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                          {{item.label}}</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
              </div>
              <!-- <div>
                  <button type="button" class="npMi editbordr_circlttop mr-3" (click)="openconsnotification()"
                  *ngIf="getThrough.authority && getThrough.authority.Auth_Sub_Menu_Sub_Add_Notification_Button">
                  <i class="fa fa-plus"></i>
                </button>
                <button mat-flat-button (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                  <mat-icon>reply</mat-icon>
                </button>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isDataRedyForTableDcu" col-sm-3>
        {{messageUtilitys}}
      </ng-container>
      <ng-container *ngIf="isDataRedyForTableDcu">
        <div class="container-fluid inner-page-content">
          <div class="col-12">
            <ng-container class="col-sm-12" *ngIf="loading">
              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
            </ng-container>

            <!-- <div *ngIf="allmeterTotalRecord!=0">
                  <div class="bg-card rounded-full border px-4 xs-no-p xs-no-mg xs-w-125" col-6>
                    <mat-form-field class="dcuFilter" floatLabel="never">
                      <span matSuffix>
                        <mat-icon matSuffix class="d-c9 fs-30">search</mat-icon>
                      </span>
                      <input type="text" matInput placeholder="Search Flat Id Or Meter Id" value="{{meterSearch}}"
                        (input)="onMeterSearchChange($event.target.value)" autocomplete="off">
                    </mat-form-field>
                  </div>
                  </div> -->
            <!-- <span class="toolbar-space"></span> -->
            <!-- <div col-3 col-xs-6>
                  <div *ngIf="allmeterTotalRecord!=0">
                    <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                      style="color: #000;" class="npMi">
                      <mat-icon class="d-c10">filter_list</mat-icon>
                    </button>
                    <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                      <mat-list role="list" *ngFor="let item of meterHeadsCols">
                        <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                          <mat-checkbox [checked]="item.checked" (change)="updateMeterChange($event, i, item)">
                            {{item.label}}</mat-checkbox>
                        </mat-list-item>
                      </mat-list>
                    </mat-menu>
                  </div>
                </div> -->



            <div *ngIf="allmeterTotalRecord!=0 && !loading">
              <table mat-table [dataSource]="dataMeterSource" class="mat-elevation-z8"
                *ngIf="dataMeterSource.length > 0" matSort id="ExampleMaterialTable">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let row">

                    <div class="action_iconbtn">
                      <button class="btn_outlineaction" type="button" (click)="editMeter(row)"
                        *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Modify_Meter">
                        <span><i class="fa fa-edit"></i></span>
                      </button>
                      <button class="btn_outlineaction" (click)="removelevel(row)" type="button" *ngIf="
                        getThrough.authority && getThrough.authority.Meter_Sub_Meter_Delete_Meter_Button">
                        <span><i class="fa fa-trash"></i></span>
                      </button>
                    </div>

                    <!-- <button type="button" class="icon" [matMenuTriggerFor]="dcuListMenu">
                        <mat-icon class="d-c10">menu_open</mat-icon>
                      </button>
                      <mat-menu #dcuListMenu="matMenu" xPosition="before" class="notifications-dropdown">
                        <mat-list role="list">
                          <mat-list-item role="listitem" *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Modify_Meter">
                            <button type="button" mat-button (click)="editMeter(row)" class="btn-block text-left">
                              <mat-icon class="d-c13">edit</mat-icon> Modify
                            </button>
                          </mat-list-item>
                          
                          <mat-list-item role="listitem" *ngIf="getThrough.authority && getThrough.authority.Meter_Sub_Meter_Delete_Meter_Button">
                            <button type="button" mat-button (click)="removelevel(row)" class="btn-block text-left">
                              <mat-icon class="d-c8">delete</mat-icon> Delete Meter
                            </button>
                          </mat-list-item>
                        </mat-list>
                      </mat-menu> -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="srNo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Sr. No</th>
                  <!--<td mat-cell *matCellDef="let element; let j = index;" style="text-align: center;"> 
                      {{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }} 
                    </td>-->
                  <td mat-cell *matCellDef="let element">
                    <div style="padding-right: 8px;">{{element.srNo}}</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cACNO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Consumer Id </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.cACNO}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="flatNo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Flat No </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.flatNo}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Meter ID </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mID}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mLLEBSupply">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>LL Grid(kW)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mLLEBSupply}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mLLDGSupply">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>LL DG(kW)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mLLDGSupply}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mRTC">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mRTC}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="activeEnergyEB">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Grid Units (kWh) </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.activeEnergyEB}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="activeEnergyDG">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> DG Units (kWh)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.activeEnergyDG}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mlprID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> LPR ID </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mlprID}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="availableAmount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Balance Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.availableAmount}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mSimNo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sim No </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mSimNo}}
                  </td>
                </ng-container>


                <ng-container matColumnDef="mIMEINo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> IMEI No </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mIMEINo}}
                  </td>
                </ng-container>



                <ng-container matColumnDef="mILA">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.mILA}}
                  </td>
                </ng-container>

                <!--<ng-container matColumnDef="lowBalance">
                    <th mat-header-cell *matHeaderCellDef> Low Balance </th>
                    <td width="10%" mat-cell *matCellDef="let element">
                      <fa name="circle" size="2x"
                        class="{{ element.availableAmount <= element.lowBalance ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                    </td>
                  </ng-container>-->

                <!--<ng-container matColumnDef="currentRunningMode">
                    <th mat-header-cell *matHeaderCellDef> DG </th>
                    <td width="10%" mat-cell *matCellDef="let element" >
                      <fa name="circle" size="2x"
                        class="{{element.currentRunningMode=='E' ? 'dtatusOffColor' : 'dtatusOnColor'}}"></fa>
                    </td>
                  </ng-container>-->

                <!--<ng-container matColumnDef="lowBalance">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="led-box" *ngIf="element.availableAmount <= element.lowBalance">
                         
                          <div class="led-red"></div>
                        </div>
                        <div class="led-box" *ngIf="element.availableAmount > element.lowBalance">
                          <div class="led-green"></div>
                          
                        </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="currentRunningMode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EB/DG </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="led-box" *ngIf="element.currentRunningMode=='E'">
                          <div class="led-green"></div>
                          
                        </div>
                        <div class="led-box" *ngIf="element.currentRunningMode!='E'">
                          <div class="led-red"></div>
                          
                        </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="mActiveStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="led-box" *ngIf="element.mActiveStatus!='Y'">
                          <div class="led-red"></div>
                          
                        </div>
                        <div class="led-box" *ngIf="element.mActiveStatus=='Y'">
                         
                          <div class="led-green"></div>
                        </div>
                    </td>
                  </ng-container>-->

                <!--<ng-container matColumnDef="mActiveStatus">
                    <th mat-header-cell *matHeaderCellDef> Active Status </th>
                    <td mat-cell *matCellDef="let element" >
                      <fa name="circle" size="2x"
                        class="{{element.mActiveStatus=='Y' ? 'dtatusOnColor' : 'dtatusOffColor'}}"></fa>
                    </td>
                  </ng-container>-->

                <tr mat-header-row *matHeaderRowDef="meterColumnsToDisplay" class="tableHeader"></tr>
                <tr class="tableColumns" mat-row *matRowDef="let element; columns: meterColumnsToDisplay;">
                </tr>
              </table>
            </div>
            <div *ngIf="loadingNoData">
              <img class="center" src="assets/images/Nodata.png">
            </div>

          </div>

        </div>
      </ng-container>
    </div>

    <mat-card-footer>
      <div class="row">
        <div class="col-11" *ngIf="!loadingNoData">
          <mat-paginator #meterpaginator [pageSize]="allmeterlimit" [pageSizeOptions]="getPageSizeOptions()"
            [length]="allmeterTotalRecord" (page)="changeAllMeterPage($event)" showFirstLastButtons>
          </mat-paginator>
        </div>

        <div class="col-1 text-left1" *ngIf="!loadingNoData">
          <div *ngIf="length!=0">
            <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                class="fa fa-file-excel-o" style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
          </div>
        </div>
      </div>

    </mat-card-footer>
  </div>
</div>