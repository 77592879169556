
      <div class="modal-header">
        <mat-icon class="d-c8">assignment</mat-icon><h3>Revenue Report</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Ok click')">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
          <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
              <ng-container>
                <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">

                </div>
              </ng-container>
          <ng-container col-sm-3>
          </ng-container>
      </div>
        