<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Recent Configuration: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container>
      <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
        <fa name="spinner" animation="spin" size="4x"></fa>
      </ng-container>

      <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
        <table class="table-bordered table-sm table-hover">
          <thead class="tablebghead">
            <tr>
              <td>
                <div class="d_title">
                  Sr. No.
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Meter Id
              </div>
              </td>
              <td>
                <div class="d_title">
                  Activation
              </div>
              </td>
              <td>
                <div class="d_title">
                  Creation
              </div>
              </td>
              <td>
                <div class="d_title">
                  Alarm Level
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Emergency Credit
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Fix Deduction Type
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Grid Unit Rate
              </div>
              </td>
              <td>
                <div class="d_title ">
                  DG Unit Rate
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Grid Fix Rate
              </div>
              </td>
              <td>
                <div class="d_title ">
                  DG Fix Rate
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Vending Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Comman Area Maintenance Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Grid Maintenance Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Dg Maintenance Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Maintenance Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Water Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Other Charge
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Service Tax
              </div>
              </td>
              <td>
                <div class="d_title ">
                  Configuration Status
              </div>
              </td>
            </tr>
          </thead>
          <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
<td>
<div class="d_content d_content_W">
  <p>{{item.meterId}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.activedateTime}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.dateTime}}</p>
</div>
</td>


<td>
<div class="d_content d_content_W">
  <p>{{item.alarmLevel}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.emergCreditLimit}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.fixDeductionType}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.eBUnitRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.dGUnitRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.eBFixCharges}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.dGFixCharges}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.vendingRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.commonAreaMaintRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.gridMaintRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.dgMaintRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.clubRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.waterRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.otherChargeRate}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.serviceTax}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.configurationStatus}}</p>
</div>
</td>

</tr>
</tbody>
</table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        {{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>          
        <div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>--> 
      </div>

      <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
        <div class="col-sm-12">
          <img class="center" src="assets/images/Nodata.png">
        </div>
        <div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog2 dilog3">
            <mat-icon class="d-c3">cancel</mat-icon>
            Cancel
          </button>
        </div>
      </div>
      
    </ng-container>
<ng-container col-sm-3>
</ng-container>
</div>
</div>
