import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { generalRequestsService, AuthenticationService } from '../../_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-permreports',
  templateUrl: './permreports.component.html',
  styleUrls: ['./permreports.component.scss']
})
export class PermreportsComponent implements OnInit {
  isloading=false;
  faSpinner = faSpinner;
  thumb = false;
  addAuthorityForm: UntypedFormGroup;
  addAuthMessage: string = '';
  AddProcess: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  authorityData: any;
  consFullName: any;
  authorityPerms: {
    Active_All_Permission_Access:  0,
    Single_Inst_Parm_Cons_Account_No: 0,
    Single_Inst_Parm_Meter_Unque_Id: 0,
    Single_Inst_Parm_Flat_No_Id: 0,
    Single_Inst_Parm_Meter_Id: 0,
    Single_Inst_Parm_RTC: 0,
    Single_Inst_Parm_Current: 0,
    Single_Inst_Parm_Voltage: 0,
    Single_Inst_Parm_Neutral_Current: 0,
    Single_Inst_Parm_Power_Factor: 0,
		Single_Inst_Parm_Frequency: 0, 
		Single_Inst_Parm_Apperant_Power: 0, 
		Single_Inst_Parm_Active_Power: 0,
		Single_Inst_Parm_Active_Energy_import: 0,
		Single_Inst_Parm_Apperant_Energy_import: 0,
		Single_Inst_Parm_Active_Energy_Export: 0,
		Single_Inst_Parm_Apperant_Energy_Export: 0,
		Single_Inst_Parm_Md_kW: 0,
		Single_Inst_Parm_Md_kW_datetime: 0,
    Single_Inst_Parm_Md_kVA: 0,
    Single_Inst_Parm_Md_kVA_datetime: 0,
    Single_Inst_Com_Power_On_Minute: 0,
    Single_Inst_Com_Tamper_Count: 0,
    Single_Inst_Com_Billing_Count: 0,
		Single_Inst_Com_Programming_Count: 0,
		Single_Inst_Disconnect_Control: 0,
    Single_Inst_LL_Thrash_Hold: 0,
    
    Single_Inst_Parm_Cons_Account_No_His: 0,
    Single_Inst_Parm_Meter_Unque_Id_His: 0,
    Single_Inst_Parm_Flat_No_Id_His: 0,
    Single_Inst_Parm_Meter_Id_His: 0,
    Single_Inst_Parm_RTC_His: 0,
    Single_Inst_Parm_Current_His: 0,
    Single_Inst_Parm_Voltage_His: 0,
    Single_Inst_Parm_Neutral_Current_His: 0,
    Single_Inst_Parm_Power_Factor_His: 0,
		Single_Inst_Parm_Frequency_His: 0, 
		Single_Inst_Parm_Apperant_Power_His: 0, 
		Single_Inst_Parm_Active_Power_His: 0,
		Single_Inst_Parm_Active_Energy_import_His: 0,
		Single_Inst_Parm_Apperant_Energy_import_His: 0,
		Single_Inst_Parm_Active_Energy_Export_His: 0,
		Single_Inst_Parm_Apperant_Energy_Export_His: 0,
		Single_Inst_Parm_Md_kW_His: 0,
		Single_Inst_Parm_Md_kW_datetime_His: 0,
    Single_Inst_Parm_Md_kVA_His: 0,
    Single_Inst_Parm_Md_kVA_datetime_His: 0,
    Single_Inst_Com_Power_On_Minute_His: 0,
    Single_Inst_Com_Tamper_Count_His: 0,
    Single_Inst_Com_Billing_Count_His: 0,
		Single_Inst_Com_Programming_Count_His: 0,
		Single_Inst_Disconnect_Control_His: 0,
    Single_Inst_LL_Thrash_Hold_His: 0,

    Single_Daily_Parm_Cons_Account_No: 0,
    Single_Block_Parm_Cons_Account_No: 0,
    Single_Event_Parm_Cons_Account_No: 0,
    Single_Daily_Parm_Cons_Account_No_His: 0,
    Single_Block_Parm_Cons_Account_No_His: 0,
    Single_Event_Parm_Cons_Account_No_His: 0,
    Single_Daily_Parm_Meter_Unque_Id: 0,
    Single_Block_Parm_Meter_Unque_Id: 0,
    Single_Event_Parm_Meter_Unque_Id: 0,
		Single_Daily_Parm_Meter_Unque_Id_His: 0, 
		Single_Block_Parm_Meter_Unque_Id_His: 0, 
		Single_Event_Parm_Meter_Unque_Id_His: 0,
		Single_Daily_Parm_Flat_No_Id: 0,
		Single_Block_Parm_Flat_No_Id: 0,
		Single_Event_Parm_Flat_No_Id: 0,
		Single_Daily_Parm_Flat_No_Id_His: 0,
		Single_Block_Parm_Flat_No_Id_His: 0,
		Single_Event_Parm_Flat_No_Id_His: 0,
    Single_Daily_Parm_Meter_Id: 0,
    Single_Block_Parm_Meter_Id: 0,
    Single_Event_Parm_Meter_Id: 0,
    Single_Daily_Parm_Meter_Id_His: 0,
    Single_Block_Parm_Meter_Id_His: 0,
		Single_Event_Parm_Meter_Id_His: 0,
		Single_Daily_Parm_RTC: 0,
    Single_Block_Parm_RTC: 0,
    Single_Event_Parm_RTC: 0,
    Single_Daily_Parm_RTC_His: 0,
    Single_Block_Parm_RTC_His: 0,
    Single_Event_Parm_RTC_His: 0,
    Single_Block_Parm_Voltage: 0,
    Single_Block_Parm_Voltage_Y: 0,
    Single_Block_Parm_Voltage_B: 0,

    Single_Event_Parm_Voltage: 0,
    Single_Event_Parm_Voltage_Y: 0,
    Single_Event_Parm_Voltage_B: 0,
    Single_Block_Parm_Voltage_His: 0,
    Single_Block_Parm_Voltage_Y_His: 0,
    Single_Block_Parm_Voltage_B_His: 0,
    Single_Event_Parm_Voltage_His: 0,
    Single_Event_Parm_Voltage_Y_His: 0,
    Single_Event_Parm_Voltage_B_His: 0,
    
    Single_Block_Parm_Current: 0,
    Single_Block_Parm_Current_Y: 0,
    Single_Block_Parm_Current_B: 0,

    Single_Event_Parm_Current: 0,
    Single_Event_Parm_Current_Y: 0,
    Single_Event_Parm_Current_B: 0,

    Single_Block_Parm_Current_His: 0,
    Single_Block_Parm_Current_Y_His: 0,
    Single_Block_Parm_Current_B_His: 0,

    Single_Event_Parm_Current_His: 0,
    Single_Event_Parm_Current_Y_His: 0,
    Single_Event_Parm_Current_B_His: 0,

    Single_Event_Parm_Power_Factor: 0,
    Single_Event_Parm_Power_Factor_Y: 0,
    Single_Event_Parm_Power_Factor_B: 0,
    Single_Event_Parm_Power_Factor_His: 0,
    Single_Event_Parm_Power_Factor_Y_His: 0,
    Single_Event_Parm_Power_Factor_B_His: 0,
    Single_Daily_Parm_Active_Energy_Imp: 0,
    Single_Block_Parm_Active_Energy_Imp: 0,
    Single_Event_Parm_Active_Energy_Imp: 0,
    Single_Daily_Parm_Active_Energy_Imp_His: 0,
    Single_Block_Parm_Active_Energy_Imp_His: 0,
    Single_Event_Parm_Active_Energy_Imp_His: 0,
    Single_Daily_Parm_Active_Energy_Expo: 0,
    Single_Block_Parm_Active_Energy_Expo: 0,
    Single_Daily_Parm_Active_Energy_Expo_His: 0,
    Single_Block_Parm_Active_Energy_Expo_His: 0,
    Single_Daily_Parm_Apperant_Energy_Imp: 0,
    Single_Block_Parm_Apperant_Energy_Imp: 0,
    Single_Event_Parm_Apperant_Energy_Imp: 0,
    Single_Daily_Parm_Apperant_Energy_Imp_His: 0,
    Single_Block_Parm_Apperant_Energy_Imp_His: 0,
    Single_Event_Parm_Apperant_Energy_Imp_His: 0,
    Single_Daily_Parm_Apperant_Energy_Expo: 0,
    Single_Block_Parm_Apperant_Energy_Expo: 0,
    Single_Daily_Parm_Apperant_Energy_Expo_His: 0,
    Single_Block_Parm_Apperant_Energy_Expo_His: 0,
    Single_Event_Parm_Event_Count_Imp: 0,
    Single_Event_Parm_Event_Count_Imp_His: 0,
    Single_Event_Parm_Event_Type_Imp: 0,
    Single_Event_Parm_Event_Type_Imp_His: 0,
    Single_Daily_Parm_Server_Datetime: 0,
    Single_Block_Parm_Server_Datetime: 0,
    Single_Event_Parm_Server_Datetime: 0,
    Single_Daily_Parm_Server_Datetime_His: 0,
    Single_Block_Parm_Server_Datetime_His: 0,
    Single_Event_Parm_Server_Datetime_His: 0,
    Single_Daily_Parm_Authority: 0,
    Single_Block_Parm_Authority: 0,
    Single_Event_Parm_Authority: 0,
    Single_Daily_Parm_Authority_His: 0,
    Single_Block_Parm_Authority_His: 0,
    Single_Event_Parm_Authority_His: 0,

    Single_Billing_Parm_Cons_Account_No: 0,
    Single_Billing_Parm_Meter_Unque_Id: 0,
    Single_Billing_Parm_Flat_No_Id: 0,
    Single_Billing_Parm_Meter_Id: 0,
    Single_Billing_Parm_RTC: 0,
    Single_Billing_Avg_Power_Factor: 0,
    Single_Billing_Cum_Active_Energy_EB: 0,
    Single_Billing_Consumed_Active_Energy: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone1: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone2: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone3: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone4: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone5: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone6: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone7: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone8: 0,
    Single_Billing_Cum_Apperant_Energy_EB: 0,
    Single_Billing_Consumed_Apparent_Energy: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone1: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone2: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone3: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone4: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone5: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone6: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone7: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone8: 0,
    Single_Billing_MD_Active_Power_Value: 0,
    Single_Billing_MD_Active_Power_Datetime: 0,
    Single_Billing_MD_Active_Power_Value_Zone1: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone1: 0,
    Single_Billing_MD_Active_Power_Value_Zone2: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone2: 0,
    Single_Billing_MD_Active_Power_Value_Zone3: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone3: 0,
    Single_Billing_MD_Active_Power_Value_Zone4: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone4: 0,
    Single_Billing_MD_Active_Power_Value_Zone5: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone5: 0,
    Single_Billing_MD_Active_Power_Value_Zone6: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone6: 0,
    Single_Billing_MD_Active_Power_Value_Zone7: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone7: 0,
    Single_Billing_MD_Active_Power_Value_Zone8: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone8: 0,
    Single_Billing_MD_Apparent_Power_Value: 0,
    Single_Billing_MD_Apparent_Power_Datetime: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone1: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone1: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone2: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone2: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone3: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone3: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone4: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone4: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone5: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone5: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone6: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone6: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone7: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone7: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone8: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone8: 0,
    Single_Billing_Tod_Script_1: 0,
    Single_Billing_Tod_Script_2: 0,
    Single_Billing_Tod_Script_3: 0,
    Single_Billing_Tod_Script_4: 0,
    Single_Billing_Tod_Script_5: 0,
    Single_Billing_Tod_Script_6: 0,
    Single_Billing_Tod_Script_7: 0,
    Single_Billing_Tod_Script_8: 0,
    Single_Billing_Tod_Timing_TZ_1: 0,
    Single_Billing_Tod_Timing_TZ_2: 0,
    Single_Billing_Tod_Timing_TZ_3: 0,
    Single_Billing_Tod_Timing_TZ_4: 0,
    Single_Billing_Tod_Timing_TZ_5: 0,
    Single_Billing_Tod_Timing_TZ_6: 0,
    Single_Billing_Tod_Timing_TZ_7: 0,
    Single_Billing_Tod_Timing_TZ_8: 0,
    Single_Billing_Md_Reset_Type: 0,
    Single_Billing_Active_Energy_Export: 0,
    Single_Billing_Apperant_Energy_Export: 0,
    Single_Billing_Bill_PowerOn_Hrs_EB: 0,
    Single_Billing_Load_Limit_Grid: 0,
    Single_Billing_Billing_Mode_EB: 0,
    Single_Billing_Bill_Type: 0,
    Single_Billing_Previous_Bill_Date_Time: 0,
    Single_Billing_Bill_Sent_Datetime: 0,
    Single_Billing_Server_Date_Time: 0
    Single_Billing_Auth_Unique_Id: 0,

    Single_Billing_Parm_Cons_Account_No_His: 0,
    Single_Billing_Parm_Meter_Unque_Id_His: 0,
    Single_Billing_Parm_Flat_No_Id_His: 0,
    Single_Billing_Parm_Meter_Id_His: 0,
    Single_Billing_Parm_RTC_His: 0,
    Single_Billing_Avg_Power_Factor_His: 0,
    Single_Billing_Cum_Active_Energy_EB_His: 0,
    Single_Billing_Consumed_Active_Energy_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone1_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone2_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone3_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone4_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone5_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone6_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone7_His: 0,
    Single_Billing_Cum_Active_Energy_EB_Zone8_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_His: 0,
    Single_Billing_Consumed_Apparent_Energy_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone1_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone2_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone3_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone4_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone5_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone6_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone7_His: 0,
    Single_Billing_Cum_Apperant_Energy_EB_Zone8_His: 0,
    Single_Billing_MD_Active_Power_Value_His: 0,
    Single_Billing_MD_Active_Power_Datetime_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone1_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone1_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone2_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone2_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone3_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone3_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone4_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone4_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone5_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone5_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone6_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone6_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone7_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone7_His: 0,
    Single_Billing_MD_Active_Power_Value_Zone8_His: 0,
    Single_Billing_MD_Active_Power_Datetime_Zone8_His: 0,
    Single_Billing_MD_Apparent_Power_Value_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone1_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone1_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone2_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone2_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone3_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone3_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone4_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone4_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone5_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone5_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone6_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone6_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone7_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone7_His: 0,
    Single_Billing_MD_Apparent_Power_Value_Zone8_His: 0,
    Single_Billing_MD_Apparent_Power_Datetime_Zone8_His: 0,
    Single_Billing_Tod_Script_1_His: 0,
    Single_Billing_Tod_Script_2_His: 0,
    Single_Billing_Tod_Script_3_His: 0,
    Single_Billing_Tod_Script_4_His: 0,
    Single_Billing_Tod_Script_5_His: 0,
    Single_Billing_Tod_Script_6_His: 0,
    Single_Billing_Tod_Script_7_His: 0,
    Single_Billing_Tod_Script_8_His: 0,
    Single_Billing_Tod_Timing_TZ_1_His: 0,
    Single_Billing_Tod_Timing_TZ_2_His: 0,
    Single_Billing_Tod_Timing_TZ_3_His: 0,
    Single_Billing_Tod_Timing_TZ_4_His: 0,
    Single_Billing_Tod_Timing_TZ_5_His: 0,
    Single_Billing_Tod_Timing_TZ_6_His: 0,
    Single_Billing_Tod_Timing_TZ_7_His: 0,
    Single_Billing_Tod_Timing_TZ_8_His: 0,
    Single_Billing_Md_Reset_Type_His: 0,
    Single_Billing_Active_Energy_Export_His: 0,
    Single_Billing_Apperant_Energy_Export_His: 0,
    Single_Billing_Bill_PowerOn_Hrs_EB_His: 0,
    Single_Billing_Load_Limit_Grid_His: 0,
    Single_Billing_Billing_Mode_EB_His: 0,
    Single_Billing_Bill_Type_His: 0,
    Single_Billing_Previous_Bill_Date_Time_His: 0,
    Single_Billing_Bill_Sent_Datetime_His: 0,
    Single_Billing_Server_Date_Time_His: 0,
    Single_Billing_Auth_Unique_Id_His: 0

  };
  loading: boolean = false;
  message: string = '';
  consumerData: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs: generalRequestsService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.getAuthPerms();
    this.addAuthorityForm = this.formBuilder.group({
      Active_All_Permission_Access: ['', Validators.required],
      Single_Inst_Parm_Cons_Account_No: [''],
      Single_Inst_Parm_Meter_Unque_Id: [''],
      Single_Inst_Parm_Flat_No_Id: [''],
      Single_Inst_Parm_Meter_Id: [''],
      Single_Inst_Parm_RTC: [''],
      Single_Inst_Parm_Current: [''],
      Single_Inst_Parm_Voltage: [''],
      Single_Inst_Parm_Neutral_Current: [''],
      Single_Inst_Parm_Power_Factor: [''],
      Single_Inst_Parm_Frequency: [''], 
      Single_Inst_Parm_Apperant_Power: [''],
      Single_Inst_Parm_Active_Power: [''],
      Single_Inst_Parm_Active_Energy_import: [''],
      Single_Inst_Parm_Apperant_Energy_import: [''],
      Single_Inst_Parm_Active_Energy_Export: [''],
      Single_Inst_Parm_Apperant_Energy_Export: [''],
      Single_Inst_Parm_Md_kW: [''],
      Single_Inst_Parm_Md_kW_datetime: [''],
      Single_Inst_Parm_Md_kVA: [''],
      Single_Inst_Parm_Md_kVA_datetime: [''],
      Single_Inst_Com_Power_On_Minute: [''],
      Single_Inst_Com_Tamper_Count: [''],
      Single_Inst_Com_Billing_Count: [''],
      Single_Inst_Com_Programming_Count: [''],
      Single_Inst_Disconnect_Control: [''],
      Single_Inst_LL_Thrash_Hold: [''],

      Single_Inst_Parm_Cons_Account_No_His: [''],
      Single_Inst_Parm_Meter_Unque_Id_His: [''],
      Single_Inst_Parm_Flat_No_Id_His: [''],
      Single_Inst_Parm_Meter_Id_His: [''],
      Single_Inst_Parm_RTC_His: [''],
      Single_Inst_Parm_Current_His: [''],
      Single_Inst_Parm_Voltage_His: [''],
      Single_Inst_Parm_Neutral_Current_His: [''],
      Single_Inst_Parm_Power_Factor_His: [''],
      Single_Inst_Parm_Frequency_His: [''], 
      Single_Inst_Parm_Apperant_Power_His: [''],
      Single_Inst_Parm_Active_Power_His: [''],
      Single_Inst_Parm_Active_Energy_import_His: [''],
      Single_Inst_Parm_Apperant_Energy_import_His: [''],
      Single_Inst_Parm_Active_Energy_Export_His: [''],
      Single_Inst_Parm_Apperant_Energy_Export_His: [''],
      Single_Inst_Parm_Md_kW_His: [''],
      Single_Inst_Parm_Md_kW_datetime_His: [''],
      Single_Inst_Parm_Md_kVA_His: [''],
      Single_Inst_Parm_Md_kVA_datetime_His: [''],
      Single_Inst_Com_Power_On_Minute_His: [''],
      Single_Inst_Com_Tamper_Count_His: [''],
      Single_Inst_Com_Billing_Count_His: [''],
      Single_Inst_Com_Programming_Count_His: [''],
      Single_Inst_Disconnect_Control_His: [''],
      Single_Inst_LL_Thrash_Hold_His: [''],


      Single_Daily_Parm_Cons_Account_No: [''],
      Single_Block_Parm_Cons_Account_No: [''],
      Single_Event_Parm_Cons_Account_No: [''],
      Single_Daily_Parm_Cons_Account_No_His: [''],
      Single_Block_Parm_Cons_Account_No_His: [''],
      Single_Event_Parm_Cons_Account_No_His: [''],
      Single_Daily_Parm_Meter_Unque_Id: [''],
      Single_Block_Parm_Meter_Unque_Id: [''],
      Single_Event_Parm_Meter_Unque_Id: [''],
      Single_Daily_Parm_Meter_Unque_Id_His: [''], 
      Single_Block_Parm_Meter_Unque_Id_His: [''], 
      Single_Event_Parm_Meter_Unque_Id_His: [''],
      Single_Daily_Parm_Flat_No_Id: [''],
      Single_Block_Parm_Flat_No_Id: [''],
      Single_Event_Parm_Flat_No_Id: [''],
      Single_Daily_Parm_Flat_No_Id_His: [''],
      Single_Block_Parm_Flat_No_Id_His: [''],
      Single_Event_Parm_Flat_No_Id_His: [''],
      Single_Daily_Parm_Meter_Id: [''],
      Single_Block_Parm_Meter_Id: [''],
      Single_Event_Parm_Meter_Id: [''],
      Single_Daily_Parm_Meter_Id_His: [''],
      Single_Block_Parm_Meter_Id_His: [''],
      Single_Event_Parm_Meter_Id_His: [''],
      Single_Daily_Parm_RTC: [''],
      Single_Block_Parm_RTC: [''],
      Single_Event_Parm_RTC: [''],
      Single_Daily_Parm_RTC_His: [''],
      Single_Block_Parm_RTC_His: [''],
      Single_Event_Parm_RTC_His: [''],
      Single_Block_Parm_Voltage: [''],
      Single_Block_Parm_Voltage_Y: [''],
      Single_Block_Parm_Voltage_B: [''],
      Single_Event_Parm_Voltage: [''],
      Single_Event_Parm_Voltage_Y: [''],
      Single_Event_Parm_Voltage_B: [''],
      Single_Block_Parm_Voltage_His: [''],
      Single_Block_Parm_Voltage_Y_His: [''],
      Single_Block_Parm_Voltage_B_His: [''],
      Single_Event_Parm_Voltage_His: [''],
      Single_Event_Parm_Voltage_Y_His: [''],
      Single_Event_Parm_Voltage_B_His: [''],
      Single_Block_Parm_Current: [''],
      Single_Block_Parm_Current_Y: [''],
      Single_Block_Parm_Current_B: [''],
      Single_Event_Parm_Current: [''],
      Single_Event_Parm_Current_Y: [''],
      Single_Event_Parm_Current_B: [''],
      Single_Block_Parm_Current_His: [''],
      Single_Block_Parm_Current_Y_His: [''],
      Single_Block_Parm_Current_B_His: [''],
      Single_Event_Parm_Current_His: [''],
      Single_Event_Parm_Current_Y_His: [''],
      Single_Event_Parm_Current_B_His: [''],
      Single_Event_Parm_Power_Factor: [''],
      Single_Event_Parm_Power_Factor_Y: [''],
      Single_Event_Parm_Power_Factor_B: [''],
      Single_Event_Parm_Power_Factor_His: [''],
      Single_Event_Parm_Power_Factor_Y_His: [''],
      Single_Event_Parm_Power_Factor_B_His: [''],
      Single_Daily_Parm_Active_Energy_Imp: [''],
      Single_Block_Parm_Active_Energy_Imp: [''],
      Single_Event_Parm_Active_Energy_Imp: [''],
      Single_Daily_Parm_Active_Energy_Imp_His: [''],
      Single_Block_Parm_Active_Energy_Imp_His: [''],
      Single_Event_Parm_Active_Energy_Imp_His: [''],
      Single_Daily_Parm_Active_Energy_Expo: [''],
      Single_Block_Parm_Active_Energy_Expo: [''],
      Single_Daily_Parm_Active_Energy_Expo_His: [''],
      Single_Block_Parm_Active_Energy_Expo_His: [''],
      Single_Daily_Parm_Apperant_Energy_Imp: [''],
      Single_Block_Parm_Apperant_Energy_Imp: [''],
      Single_Event_Parm_Apperant_Energy_Imp: [''],
      Single_Daily_Parm_Apperant_Energy_Imp_His: [''],
      Single_Block_Parm_Apperant_Energy_Imp_His: [''],
      Single_Event_Parm_Apperant_Energy_Imp_His: [''],
      Single_Daily_Parm_Apperant_Energy_Expo: [''],
      Single_Block_Parm_Apperant_Energy_Expo: [''],
      Single_Daily_Parm_Apperant_Energy_Expo_His: [''],
      Single_Block_Parm_Apperant_Energy_Expo_His: [''],
      Single_Event_Parm_Event_Count_Imp: [''],
      Single_Event_Parm_Event_Count_Imp_His: [''],
      Single_Event_Parm_Event_Type_Imp: [''],
      Single_Event_Parm_Event_Type_Imp_His: [''],
      Single_Daily_Parm_Server_Datetime: [''],
      Single_Block_Parm_Server_Datetime: [''],
      Single_Event_Parm_Server_Datetime: [''],
      Single_Daily_Parm_Server_Datetime_His: [''],
      Single_Block_Parm_Server_Datetime_His: [''],
      Single_Event_Parm_Server_Datetime_His: [''],
      Single_Daily_Parm_Authority: [''],
      Single_Block_Parm_Authority: [''],
      Single_Event_Parm_Authority: [''],
      Single_Daily_Parm_Authority_His: [''],
      Single_Block_Parm_Authority_His: [''],
      Single_Event_Parm_Authority_His: [''],

    Single_Billing_Parm_Cons_Account_No: [''],
    Single_Billing_Parm_Meter_Unque_Id: [''],
    Single_Billing_Parm_Flat_No_Id: [''],
    Single_Billing_Parm_Meter_Id: [''],
    Single_Billing_Parm_RTC: [''],
    Single_Billing_Avg_Power_Factor: [''],
    Single_Billing_Cum_Active_Energy_EB: [''],
    Single_Billing_Consumed_Active_Energy: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone1: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone2: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone3: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone4: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone5: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone6: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone7: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone8: [''],
    Single_Billing_Cum_Apperant_Energy_EB: [''],
    Single_Billing_Consumed_Apparent_Energy: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone1: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone2: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone3: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone4: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone5: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone6: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone7: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone8: [''],
    Single_Billing_MD_Active_Power_Value: [''],
    Single_Billing_MD_Active_Power_Datetime: [''],
    Single_Billing_MD_Active_Power_Value_Zone1: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone1: [''],
    Single_Billing_MD_Active_Power_Value_Zone2: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone2: [''],
    Single_Billing_MD_Active_Power_Value_Zone3: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone3: [''],
    Single_Billing_MD_Active_Power_Value_Zone4: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone4: [''],
    Single_Billing_MD_Active_Power_Value_Zone5: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone5: [''],
    Single_Billing_MD_Active_Power_Value_Zone6: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone6: [''],
    Single_Billing_MD_Active_Power_Value_Zone7: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone7: [''],
    Single_Billing_MD_Active_Power_Value_Zone8: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone8: [''],
    Single_Billing_MD_Apparent_Power_Value: [''],
    Single_Billing_MD_Apparent_Power_Datetime: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone1: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone1: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone2: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone2: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone3: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone3: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone4: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone4: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone5: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone5: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone6: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone6: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone7: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone7: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone8: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone8: [''],
    Single_Billing_Tod_Script_1: [''],
    Single_Billing_Tod_Script_2: [''],
    Single_Billing_Tod_Script_3: [''],
    Single_Billing_Tod_Script_4: [''],
    Single_Billing_Tod_Script_5: [''],
    Single_Billing_Tod_Script_6: [''],
    Single_Billing_Tod_Script_7: [''],
    Single_Billing_Tod_Script_8: [''],
    Single_Billing_Tod_Timing_TZ_1: [''],
    Single_Billing_Tod_Timing_TZ_2: [''],
    Single_Billing_Tod_Timing_TZ_3: [''],
    Single_Billing_Tod_Timing_TZ_4: [''],
    Single_Billing_Tod_Timing_TZ_5: [''],
    Single_Billing_Tod_Timing_TZ_6: [''],
    Single_Billing_Tod_Timing_TZ_7: [''],
    Single_Billing_Tod_Timing_TZ_8: [''],
    Single_Billing_Md_Reset_Type: [''],
    Single_Billing_Active_Energy_Export: [''],
    Single_Billing_Apperant_Energy_Export: [''],
    Single_Billing_Bill_PowerOn_Hrs_EB: [''],
    Single_Billing_Load_Limit_Grid: [''],
    Single_Billing_Billing_Mode_EB: [''],
    Single_Billing_Bill_Type: [''],
    Single_Billing_Previous_Bill_Date_Time: [''],
    Single_Billing_Bill_Sent_Datetime: [''],
    Single_Billing_Server_Date_Time: [''],
    Single_Billing_Auth_Unique_Id: [''],

    Single_Billing_Parm_Cons_Account_No_His: [''],
    Single_Billing_Parm_Meter_Unque_Id_His: [''],
    Single_Billing_Parm_Flat_No_Id_His: [''],
    Single_Billing_Parm_Meter_Id_His: [''],
    Single_Billing_Parm_RTC_His: [''],
    Single_Billing_Avg_Power_Factor_His: [''],
    Single_Billing_Cum_Active_Energy_EB_His: [''],
    Single_Billing_Consumed_Active_Energy_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone1_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone2_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone3_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone4_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone5_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone6_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone7_His: [''],
    Single_Billing_Cum_Active_Energy_EB_Zone8_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_His: [''],
    Single_Billing_Consumed_Apparent_Energy_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone1_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone2_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone3_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone4_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone5_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone6_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone7_His: [''],
    Single_Billing_Cum_Apperant_Energy_EB_Zone8_His: [''],
    Single_Billing_MD_Active_Power_Value_His: [''],
    Single_Billing_MD_Active_Power_Datetime_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone1_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone1_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone2_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone2_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone3_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone3_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone4_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone4_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone5_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone5_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone6_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone6_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone7_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone7_His: [''],
    Single_Billing_MD_Active_Power_Value_Zone8_His: [''],
    Single_Billing_MD_Active_Power_Datetime_Zone8_His: [''],
    Single_Billing_MD_Apparent_Power_Value_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone1_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone1_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone2_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone2_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone3_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone3_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone4_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone4_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone5_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone5_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone6_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone6_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone7_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone7_His: [''],
    Single_Billing_MD_Apparent_Power_Value_Zone8_His: [''],
    Single_Billing_MD_Apparent_Power_Datetime_Zone8_His: [''],
    Single_Billing_Tod_Script_1_His: [''],
    Single_Billing_Tod_Script_2_His: [''],
    Single_Billing_Tod_Script_3_His: [''],
    Single_Billing_Tod_Script_4_His: [''],
    Single_Billing_Tod_Script_5_His: [''],
    Single_Billing_Tod_Script_6_His: [''],
    Single_Billing_Tod_Script_7_His: [''],
    Single_Billing_Tod_Script_8_His: [''],
    Single_Billing_Tod_Timing_TZ_1_His: [''],
    Single_Billing_Tod_Timing_TZ_2_His: [''],
    Single_Billing_Tod_Timing_TZ_3_His: [''],
    Single_Billing_Tod_Timing_TZ_4_His: [''],
    Single_Billing_Tod_Timing_TZ_5_His: [''],
    Single_Billing_Tod_Timing_TZ_6_His: [''],
    Single_Billing_Tod_Timing_TZ_7_His: [''],
    Single_Billing_Tod_Timing_TZ_8_His: [''],
    Single_Billing_Md_Reset_Type_His: [''],
    Single_Billing_Active_Energy_Export_His: [''],
    Single_Billing_Apperant_Energy_Export_His: [''],
    Single_Billing_Bill_PowerOn_Hrs_EB_His: [''],
    Single_Billing_Load_Limit_Grid_His: [''],
    Single_Billing_Billing_Mode_EB_His: [''],
    Single_Billing_Bill_Type_His: [''],
    Single_Billing_Previous_Bill_Date_Time_His: [''],
    Single_Billing_Bill_Sent_Datetime_His: [''],
    Single_Billing_Server_Date_Time_His: [''],
    Single_Billing_Auth_Unique_Id_His: ['']

    });
  }

  get pf() { return this.addAuthorityForm.controls; }
  onSubmitAddConsumer() {
    //console.log("1");
    let seletedPerms = [];
    if (this.pf.Active_All_Permission_Access.value == true) {
      seletedPerms.push('Single_Inst_Parm_Cons_Account_No');
      seletedPerms.push('Single_Inst_Parm_Meter_Unque_Id');
      seletedPerms.push('Single_Inst_Parm_Flat_No_Id');
      seletedPerms.push('Single_Inst_Parm_Meter_Id');
      seletedPerms.push('Single_Inst_Parm_RTC');
      seletedPerms.push('Single_Inst_Parm_Current');
      seletedPerms.push('Single_Inst_Parm_Voltage');
      seletedPerms.push('Single_Inst_Parm_Neutral_Current');
      seletedPerms.push('Single_Inst_Parm_Power_Factor');
      seletedPerms.push('Single_Inst_Parm_Frequency'); 
      seletedPerms.push('Single_Inst_Parm_Apperant_Power');
      seletedPerms.push('Single_Inst_Parm_Active_Power');
      seletedPerms.push('Single_Inst_Parm_Active_Energy_import');
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_import');
      seletedPerms.push('Single_Inst_Parm_Active_Energy_Export');
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_Export');
      seletedPerms.push('Single_Inst_Parm_Md_kW');
      seletedPerms.push('Single_Inst_Parm_Md_kW_datetime');
      seletedPerms.push('Single_Inst_Parm_Md_kVA');
      seletedPerms.push('Single_Inst_Parm_Md_kVA_datetime');
      seletedPerms.push('Single_Inst_Com_Power_On_Minute');
      seletedPerms.push('Single_Inst_Com_Tamper_Count');
      seletedPerms.push('Single_Inst_Com_Billing_Count');
      seletedPerms.push('Single_Inst_Com_Programming_Count');
      seletedPerms.push('Single_Inst_Disconnect_Control');
      seletedPerms.push('Single_Inst_LL_Thrash_Hold');

      seletedPerms.push('Single_Inst_Parm_Cons_Account_No_His');
      seletedPerms.push('Single_Inst_Parm_Meter_Unque_Id_His');
      seletedPerms.push('Single_Inst_Parm_Flat_No_Id_His');
      seletedPerms.push('Single_Inst_Parm_Meter_Id_His');
      seletedPerms.push('Single_Inst_Parm_RTC_His');
      seletedPerms.push('Single_Inst_Parm_Current_His');
      seletedPerms.push('Single_Inst_Parm_Voltage_His');
      seletedPerms.push('Single_Inst_Parm_Neutral_Current_His');
      seletedPerms.push('Single_Inst_Parm_Power_Factor_His');
      seletedPerms.push('Single_Inst_Parm_Frequency_His'); 
      seletedPerms.push('Single_Inst_Parm_Apperant_Power_His');
      seletedPerms.push('Single_Inst_Parm_Active_Power_His');
      seletedPerms.push('Single_Inst_Parm_Active_Energy_import_His');
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_import_His');
      seletedPerms.push('Single_Inst_Parm_Active_Energy_Export_His');
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_Export_His');
      seletedPerms.push('Single_Inst_Parm_Md_kW_His');
      seletedPerms.push('Single_Inst_Parm_Md_kW_datetime_His');
      seletedPerms.push('Single_Inst_Parm_Md_kVA_His');
      seletedPerms.push('Single_Inst_Parm_Md_kVA_datetime_His');
      seletedPerms.push('Single_Inst_Com_Power_On_Minute_His');
      seletedPerms.push('Single_Inst_Com_Tamper_Count_His');
      seletedPerms.push('Single_Inst_Com_Billing_Count_His');
      seletedPerms.push('Single_Inst_Com_Programming_Count_His');
      seletedPerms.push('Single_Inst_Disconnect_Control_His');
      seletedPerms.push('Single_Inst_LL_Thrash_Hold_His');
      
      seletedPerms.push('Single_Daily_Parm_Cons_Account_No');
      seletedPerms.push('Single_Block_Parm_Cons_Account_No');
      seletedPerms.push('Single_Event_Parm_Cons_Account_No');
      seletedPerms.push('Single_Daily_Parm_Cons_Account_No_His');
      seletedPerms.push('Single_Block_Parm_Cons_Account_No_His');
      seletedPerms.push('Single_Event_Parm_Cons_Account_No_His');
      seletedPerms.push('Single_Daily_Parm_Meter_Unque_Id');
      seletedPerms.push('Single_Block_Parm_Meter_Unque_Id');
      seletedPerms.push('Single_Event_Parm_Meter_Unque_Id');
      seletedPerms.push('Single_Daily_Parm_Meter_Unque_Id_His'); 
      seletedPerms.push('Single_Block_Parm_Meter_Unque_Id_His'); 
      seletedPerms.push('Single_Event_Parm_Meter_Unque_Id_His');
      seletedPerms.push('Single_Daily_Parm_Flat_No_Id');
      seletedPerms.push('Single_Block_Parm_Flat_No_Id');
      seletedPerms.push('Single_Event_Parm_Flat_No_Id');
      seletedPerms.push('Single_Daily_Parm_Flat_No_Id_His');
      seletedPerms.push('Single_Block_Parm_Flat_No_Id_His');
      seletedPerms.push('Single_Event_Parm_Flat_No_Id_His');
      seletedPerms.push('Single_Daily_Parm_Meter_Id');
      seletedPerms.push('Single_Block_Parm_Meter_Id');
      seletedPerms.push('Single_Event_Parm_Meter_Id');
      seletedPerms.push('Single_Daily_Parm_Meter_Id_His');
      seletedPerms.push('Single_Block_Parm_Meter_Id_His');
      seletedPerms.push('Single_Event_Parm_Meter_Id_His');
      seletedPerms.push('Single_Daily_Parm_RTC');
      seletedPerms.push('Single_Block_Parm_RTC');
      seletedPerms.push('Single_Event_Parm_RTC');
      seletedPerms.push('Single_Daily_Parm_RTC_His');
      seletedPerms.push('Single_Block_Parm_RTC_His');
      seletedPerms.push('Single_Event_Parm_RTC_His');
      seletedPerms.push('Single_Block_Parm_Voltage');
      seletedPerms.push('Single_Block_Parm_Voltage_Y');
      seletedPerms.push('Single_Block_Parm_Voltage_B');
      seletedPerms.push('Single_Event_Parm_Voltage');
      seletedPerms.push('Single_Event_Parm_Voltage_Y');
      seletedPerms.push('Single_Event_Parm_Voltage_B');
      seletedPerms.push('Single_Block_Parm_Voltage_His');
      seletedPerms.push('Single_Block_Parm_Voltage_Y_His');
      seletedPerms.push('Single_Block_Parm_Voltage_B_His');
      seletedPerms.push('Single_Event_Parm_Voltage_His');
      seletedPerms.push('Single_Event_Parm_Voltage_Y_His');
      seletedPerms.push('Single_Event_Parm_Voltage_B_His');
      seletedPerms.push('Single_Block_Parm_Current');
      seletedPerms.push('Single_Block_Parm_Current_Y');
      seletedPerms.push('Single_Block_Parm_Current_B');
      seletedPerms.push('Single_Event_Parm_Current');
      seletedPerms.push('Single_Event_Parm_Current_Y');
      seletedPerms.push('Single_Event_Parm_Current_B');
      seletedPerms.push('Single_Block_Parm_Current_His');
      seletedPerms.push('Single_Block_Parm_Current_Y_His');
      seletedPerms.push('Single_Block_Parm_Current_B_His');
      seletedPerms.push('Single_Event_Parm_Current_His');
      seletedPerms.push('Single_Event_Parm_Current_Y_His');
      seletedPerms.push('Single_Event_Parm_Current_B_His');

      seletedPerms.push('Single_Event_Parm_Power_Factor');
      seletedPerms.push('Single_Event_Parm_Power_Factor_Y');
      seletedPerms.push('Single_Event_Parm_Power_Factor_B');
      seletedPerms.push('Single_Event_Parm_Power_Factor_His');
      seletedPerms.push('Single_Event_Parm_Power_Factor_Y_His');
      seletedPerms.push('Single_Event_Parm_Power_Factor_B_His');
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Imp');
      seletedPerms.push('Single_Block_Parm_Active_Energy_Imp');
      seletedPerms.push('Single_Event_Parm_Active_Energy_Imp');
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Imp_His');
      seletedPerms.push('Single_Block_Parm_Active_Energy_Imp_His');
      seletedPerms.push('Single_Event_Parm_Active_Energy_Imp_His');
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Expo');
      seletedPerms.push('Single_Block_Parm_Active_Energy_Expo');
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Expo_His');
      seletedPerms.push('Single_Block_Parm_Active_Energy_Expo_His');
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Imp');
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Imp');
      seletedPerms.push('Single_Event_Parm_Apperant_Energy_Imp');
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Imp_His');
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Imp_His');
      seletedPerms.push('Single_Event_Parm_Apperant_Energy_Imp_His');
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Expo');
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Expo');
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Expo_His');
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Expo_His');
      seletedPerms.push('Single_Event_Parm_Event_Count_Imp');
      seletedPerms.push('Single_Event_Parm_Event_Count_Imp_His');
      seletedPerms.push('Single_Event_Parm_Event_Type_Imp');
      seletedPerms.push('Single_Event_Parm_Event_Type_Imp_His');
      seletedPerms.push('Single_Daily_Parm_Server_Datetime');
      seletedPerms.push('Single_Block_Parm_Server_Datetime');
      seletedPerms.push('Single_Event_Parm_Server_Datetime');
      seletedPerms.push('Single_Daily_Parm_Server_Datetime_His');
      seletedPerms.push('Single_Block_Parm_Server_Datetime_His');
      seletedPerms.push('Single_Event_Parm_Server_Datetime_His');
      seletedPerms.push('Single_Daily_Parm_Authority');
      seletedPerms.push('Single_Block_Parm_Authority');
      seletedPerms.push('Single_Event_Parm_Authority');
      seletedPerms.push('Single_Daily_Parm_Authority_His');
      seletedPerms.push('Single_Block_Parm_Authority_His');
      seletedPerms.push('Single_Event_Parm_Authority_His');

        
      seletedPerms.push('Single_Billing_Parm_Cons_Account_No');
      seletedPerms.push('Single_Billing_Parm_Meter_Unque_Id');
      seletedPerms.push('Single_Billing_Parm_Flat_No_Id');
      seletedPerms.push('Single_Billing_Parm_Meter_Id');
      seletedPerms.push('Single_Billing_Parm_RTC');
      seletedPerms.push('Single_Billing_Avg_Power_Factor');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB');
      seletedPerms.push('Single_Billing_Consumed_Active_Energy');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone1');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone2');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone3');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone4');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone5');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone6');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone7');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone8');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB');
      seletedPerms.push('Single_Billing_Consumed_Apparent_Energy');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone1');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone2');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone3');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone4');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone5');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone6');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone7');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone8');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone1');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone1');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone2');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone2');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone3');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone3');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone4');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone4');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone5');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone5');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone6');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone6');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone7');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone7');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone8');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone8');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone1');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone1');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone2');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone2');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone3');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone3');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone4');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone4');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone5');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone5');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone6');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone6');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone7');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone7');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone8');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone8');
      seletedPerms.push('Single_Billing_Tod_Script_1');
      seletedPerms.push('Single_Billing_Tod_Script_2');
      seletedPerms.push('Single_Billing_Tod_Script_3');
      seletedPerms.push('Single_Billing_Tod_Script_4');
      seletedPerms.push('Single_Billing_Tod_Script_5');
      seletedPerms.push('Single_Billing_Tod_Script_6');
      seletedPerms.push('Single_Billing_Tod_Script_7');
      seletedPerms.push('Single_Billing_Tod_Script_8');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_1');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_2');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_3');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_4');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_5');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_6');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_7');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_8');
      seletedPerms.push('Single_Billing_Md_Reset_Type');
      seletedPerms.push('Single_Billing_Active_Energy_Export');
      seletedPerms.push('Single_Billing_Apperant_Energy_Export');
      seletedPerms.push('Single_Billing_Bill_PowerOn_Hrs_EB');
      seletedPerms.push('Single_Billing_Load_Limit_Grid');
      seletedPerms.push('Single_Billing_Billing_Mode_EB');
      seletedPerms.push('Single_Billing_Bill_Type');
      seletedPerms.push('Single_Billing_Previous_Bill_Date_Time');
      seletedPerms.push('Single_Billing_Bill_Sent_Datetime');
      seletedPerms.push('Single_Billing_Server_Date_Time');
      seletedPerms.push('Single_Billing_Auth_Unique_Id');

      seletedPerms.push('Single_Billing_Parm_Cons_Account_No_His');
      seletedPerms.push('Single_Billing_Parm_Meter_Unque_Id_His');
      seletedPerms.push('Single_Billing_Parm_Flat_No_Id_His');
      seletedPerms.push('Single_Billing_Parm_Meter_Id_His');
      seletedPerms.push('Single_Billing_Parm_RTC_His');
      seletedPerms.push('Single_Billing_Avg_Power_Factor_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_His');
      seletedPerms.push('Single_Billing_Consumed_Active_Energy_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone1_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone2_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone3_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone4_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone5_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone6_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone7_His');
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone8_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_His');
      seletedPerms.push('Single_Billing_Consumed_Apparent_Energy_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone1_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone2_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone3_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone4_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone5_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone6_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone7_His');
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone8_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone1_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone1_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone2_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone2_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone3_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone3_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone4_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone4_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone5_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone5_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone6_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone6_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone7_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone7_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone8_His');
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone8_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone1_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone1_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone2_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone2_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone3_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone3_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone4_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone4_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone5_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone5_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone6_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone6_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone7_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone7_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone8_His');
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone8_His');
      seletedPerms.push('Single_Billing_Tod_Script_1_His');
      seletedPerms.push('Single_Billing_Tod_Script_2_His');
      seletedPerms.push('Single_Billing_Tod_Script_3_His');
      seletedPerms.push('Single_Billing_Tod_Script_4_His');
      seletedPerms.push('Single_Billing_Tod_Script_5_His');
      seletedPerms.push('Single_Billing_Tod_Script_6_His');
      seletedPerms.push('Single_Billing_Tod_Script_7_His');
      seletedPerms.push('Single_Billing_Tod_Script_8_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_1_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_2_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_3_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_4_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_5_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_6_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_7_His');
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_8_His');
      seletedPerms.push('Single_Billing_Md_Reset_Type_His');
      seletedPerms.push('Single_Billing_Active_Energy_Export_His');
      seletedPerms.push('Single_Billing_Apperant_Energy_Export_His');
      seletedPerms.push('Single_Billing_Bill_PowerOn_Hrs_EB_His');
      seletedPerms.push('Single_Billing_Load_Limit_Grid_His');
      seletedPerms.push('Single_Billing_Billing_Mode_EB_His');
      seletedPerms.push('Single_Billing_Bill_Type_His');
      seletedPerms.push('Single_Billing_Previous_Bill_Date_Time_His');
      seletedPerms.push('Single_Billing_Bill_Sent_Datetime_His');
      seletedPerms.push('Single_Billing_Server_Date_Time_His');
      seletedPerms.push('Single_Billing_Auth_Unique_Id_His');
    }

    if (this.pf.Single_Inst_Parm_Cons_Account_No.value == true) {
      seletedPerms.push('Single_Inst_Parm_Cons_Account_No');
    }
    if (this.pf.Single_Inst_Parm_Meter_Unque_Id.value == true) {
      seletedPerms.push('Single_Inst_Parm_Meter_Unque_Id');
    }
    if (this.pf.Single_Inst_Parm_Flat_No_Id.value == true) {
      seletedPerms.push('Single_Inst_Parm_Flat_No_Id');
    }
    if (this.pf.Single_Inst_Parm_Meter_Id.value == true) {
      seletedPerms.push('Single_Inst_Parm_Meter_Id');
    }
    if (this.pf.Single_Inst_Parm_RTC.value == true) {
      seletedPerms.push('Single_Inst_Parm_RTC');
    }
    if (this.pf.Single_Inst_Parm_Current.value == true) {
      seletedPerms.push('Single_Inst_Parm_Current');
    }
    if (this.pf.Single_Inst_Parm_Voltage.value == true) {
      seletedPerms.push('Single_Inst_Parm_Voltage');
    }
    if (this.pf.Single_Inst_Parm_Neutral_Current.value == true) {
      seletedPerms.push('Single_Inst_Parm_Neutral_Current');
    }
    if (this.pf.Single_Inst_Parm_Power_Factor.value == true) {
      seletedPerms.push('Single_Inst_Parm_Power_Factor');
    }
    if (this.pf.Single_Inst_Parm_Frequency.value == true) {
      seletedPerms.push('Single_Inst_Parm_Frequency');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Power.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Power');
    }
    if (this.pf.Single_Inst_Parm_Active_Power.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Power');
    }
    if (this.pf.Single_Inst_Parm_Active_Energy_import.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Energy_import');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Energy_import.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_import');
    }
		if (this.pf.Single_Inst_Parm_Active_Energy_Export.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Energy_Export');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Energy_Export.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_Export');
    }
    if (this.pf.Single_Inst_Parm_Md_kW.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kW');
    }
    if (this.pf.Single_Inst_Parm_Md_kW_datetime.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kW_datetime');
    }
    if (this.pf.Single_Inst_Parm_Md_kVA.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kVA');
    }
    if (this.pf.Single_Inst_Parm_Md_kVA_datetime.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kVA_datetime');
    }
    if (this.pf.Single_Inst_Com_Power_On_Minute.value == true) {
      seletedPerms.push('Single_Inst_Com_Power_On_Minute');
    }
    if (this.pf.Single_Inst_Com_Tamper_Count.value == true) {
      seletedPerms.push('Single_Inst_Com_Tamper_Count');
    }
    if (this.pf.Single_Inst_Com_Billing_Count.value == true) {
      seletedPerms.push('Single_Inst_Com_Billing_Count');
    }
    if (this.pf.Single_Inst_Com_Programming_Count.value == true) {
      seletedPerms.push('Single_Inst_Com_Programming_Count');
    }
    if (this.pf.Single_Inst_Disconnect_Control.value == true) {
      seletedPerms.push('Single_Inst_Disconnect_Control');
    }
    if (this.pf.Single_Inst_LL_Thrash_Hold.value == true) {
      seletedPerms.push('Single_Inst_LL_Thrash_Hold');
    }

    if (this.pf.Single_Inst_Parm_Cons_Account_No_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Cons_Account_No_His');
    }
    if (this.pf.Single_Inst_Parm_Meter_Unque_Id_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Meter_Unque_Id_His');
    }
    if (this.pf.Single_Inst_Parm_Flat_No_Id_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Flat_No_Id_His');
    }
    if (this.pf.Single_Inst_Parm_Meter_Id_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Meter_Id_His');
    }
    if (this.pf.Single_Inst_Parm_RTC_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_RTC_His');
    }
    if (this.pf.Single_Inst_Parm_Current_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Current_His');
    }
    if (this.pf.Single_Inst_Parm_Voltage_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Voltage_His');
    }
    if (this.pf.Single_Inst_Parm_Neutral_Current_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Neutral_Current_His');
    }
    if (this.pf.Single_Inst_Parm_Power_Factor_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Power_Factor_His');
    }
    if (this.pf.Single_Inst_Parm_Frequency_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Frequency_His');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Power_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Power_His');
    }
    if (this.pf.Single_Inst_Parm_Active_Power_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Power_His');
    }
    if (this.pf.Single_Inst_Parm_Active_Energy_import_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Energy_import_His');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Energy_import_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_import_His');
    }
		if (this.pf.Single_Inst_Parm_Active_Energy_Export_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Active_Energy_Export_His');
    }
    if (this.pf.Single_Inst_Parm_Apperant_Energy_Export_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Apperant_Energy_Export_His');
    }
    if (this.pf.Single_Inst_Parm_Md_kW_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kW_His');
    }
    if (this.pf.Single_Inst_Parm_Md_kW_datetime_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kW_datetime_His');
    }
    if (this.pf.Single_Inst_Parm_Md_kVA_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kVA_His');
    }
    if (this.pf.Single_Inst_Parm_Md_kVA_datetime_His.value == true) {
      seletedPerms.push('Single_Inst_Parm_Md_kVA_datetime_His');
    }
    if (this.pf.Single_Inst_Com_Power_On_Minute_His.value == true) {
      seletedPerms.push('Single_Inst_Com_Power_On_Minute_His');
    }
    if (this.pf.Single_Inst_Com_Tamper_Count_His.value == true) {
      seletedPerms.push('Single_Inst_Com_Tamper_Count_His');
    }
    if (this.pf.Single_Inst_Com_Billing_Count_His.value == true) {
      seletedPerms.push('Single_Inst_Com_Billing_Count_His');
    }
    if (this.pf.Single_Inst_Com_Programming_Count_His.value == true) {
      seletedPerms.push('Single_Inst_Com_Programming_Count_His');
    }
    if (this.pf.Single_Inst_Disconnect_Control_His.value == true) {
      seletedPerms.push('Single_Inst_Disconnect_Control_His');
    }
    if (this.pf.Single_Inst_LL_Thrash_Hold_His.value == true) {
      seletedPerms.push('Single_Inst_LL_Thrash_Hold_His');
    }

    if (this.pf.Single_Daily_Parm_Cons_Account_No.value == true) {
      seletedPerms.push('Single_Daily_Parm_Cons_Account_No');
    }
    if (this.pf.Single_Block_Parm_Cons_Account_No.value == true) {
      seletedPerms.push('Single_Block_Parm_Cons_Account_No');
    }
    if (this.pf.Single_Event_Parm_Cons_Account_No.value == true) {
      seletedPerms.push('Single_Event_Parm_Cons_Account_No');
    }
    if (this.pf.Single_Daily_Parm_Cons_Account_No_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Cons_Account_No_His');
    }
    if (this.pf.Single_Block_Parm_Cons_Account_No_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Cons_Account_No_His');
    }
    if (this.pf.Single_Event_Parm_Cons_Account_No_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Cons_Account_No_His');
    }
    if (this.pf.Single_Daily_Parm_Meter_Unque_Id.value == true) {
      seletedPerms.push('Single_Daily_Parm_Meter_Unque_Id');
    }
    if (this.pf.Single_Block_Parm_Meter_Unque_Id.value == true) {
      seletedPerms.push('Single_Block_Parm_Meter_Unque_Id');
    }
    if (this.pf.Single_Event_Parm_Meter_Unque_Id.value == true) {
      seletedPerms.push('Single_Event_Parm_Meter_Unque_Id');
    }
    if (this.pf.Single_Daily_Parm_Meter_Unque_Id_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Meter_Unque_Id_His');
    } 
    if (this.pf.Single_Block_Parm_Meter_Unque_Id_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Meter_Unque_Id_His');
    } 
    if (this.pf.Single_Event_Parm_Meter_Unque_Id_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Meter_Unque_Id_His');
    }
    if (this.pf.Single_Daily_Parm_Flat_No_Id.value == true) {
      seletedPerms.push('Single_Daily_Parm_Flat_No_Id');
    }
    if (this.pf.Single_Block_Parm_Flat_No_Id.value == true) {
      seletedPerms.push('Single_Block_Parm_Flat_No_Id');
    }
    if (this.pf.Single_Event_Parm_Flat_No_Id.value == true) {
      seletedPerms.push('Single_Event_Parm_Flat_No_Id');
    }
    if (this.pf.Single_Daily_Parm_Flat_No_Id_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Flat_No_Id_His');
    }
    if (this.pf.Single_Block_Parm_Flat_No_Id_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Flat_No_Id_His');
    }
    if (this.pf.Single_Event_Parm_Flat_No_Id_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Flat_No_Id_His');
    }
    if (this.pf.Single_Daily_Parm_Meter_Id.value == true) {
      seletedPerms.push('Single_Daily_Parm_Meter_Id');
    }
    if (this.pf.Single_Block_Parm_Meter_Id.value == true) {
      seletedPerms.push('Single_Block_Parm_Meter_Id');
    }
    if (this.pf.Single_Event_Parm_Meter_Id.value == true) {
      seletedPerms.push('Single_Event_Parm_Meter_Id');
    }
    if (this.pf.Single_Daily_Parm_Meter_Id_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Meter_Id_His');
    }
    if (this.pf.Single_Block_Parm_Meter_Id_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Meter_Id_His');
    }
    if (this.pf.Single_Event_Parm_Meter_Id_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Meter_Id_His');
    }
    if (this.pf.Single_Daily_Parm_RTC.value == true) {
      seletedPerms.push('Single_Daily_Parm_RTC');
    }
    if (this.pf.Single_Block_Parm_RTC.value == true) {
      seletedPerms.push('Single_Block_Parm_RTC');
    }
    if (this.pf.Single_Event_Parm_RTC.value == true) {
      seletedPerms.push('Single_Event_Parm_RTC');
    }
    if (this.pf.Single_Daily_Parm_RTC_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_RTC_His');
    }
    if (this.pf.Single_Block_Parm_RTC_His.value == true) {
      seletedPerms.push('Single_Block_Parm_RTC_His');
    }
    if (this.pf.Single_Event_Parm_RTC_His.value == true) {
      seletedPerms.push('Single_Event_Parm_RTC_His');
    }
    if (this.pf.Single_Block_Parm_Voltage.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage');
    }

    if (this.pf.Single_Block_Parm_Voltage_Y.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage_Y');
    }
    if (this.pf.Single_Block_Parm_Voltage_B.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage_B');
    }
    if (this.pf.Single_Event_Parm_Voltage.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage');
    }
    if (this.pf.Single_Event_Parm_Voltage_Y.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage_Y');
    }
    if (this.pf.Single_Event_Parm_Voltage_B.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage_B');
    }
    if (this.pf.Single_Block_Parm_Voltage_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage_His');
    }
    if (this.pf.Single_Block_Parm_Voltage_Y_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage_Y_His');
    }
    if (this.pf.Single_Block_Parm_Voltage_B_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Voltage_B_His');
    }
    if (this.pf.Single_Event_Parm_Voltage_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage_His');
    }
    if (this.pf.Single_Event_Parm_Voltage_Y_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage_Y_His');
    }
    if (this.pf.Single_Event_Parm_Voltage_B_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Voltage_B_His');
    }
    if (this.pf.Single_Block_Parm_Current.value == true) {
      seletedPerms.push('Single_Block_Parm_Current');
    }
    if (this.pf.Single_Block_Parm_Current_Y.value == true) {
      seletedPerms.push('Single_Block_Parm_Current_Y');
    }
    if (this.pf.Single_Block_Parm_Current_B.value == true) {
      seletedPerms.push('Single_Block_Parm_Current_B');
    }
    if (this.pf.Single_Event_Parm_Current.value == true) {
      seletedPerms.push('Single_Event_Parm_Current');
    }
    if (this.pf.Single_Event_Parm_Current_Y.value == true) {
      seletedPerms.push('Single_Event_Parm_Current_Y');
    }
    if (this.pf.Single_Event_Parm_Current_B.value == true) {
      seletedPerms.push('Single_Event_Parm_Current_B');
    }
    if (this.pf.Single_Block_Parm_Current_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Current_His');
    }
    if (this.pf.Single_Block_Parm_Current_Y_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Current_Y_His');
    }
    if (this.pf.Single_Block_Parm_Current_B_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Current_B_His');
    }
    if (this.pf.Single_Event_Parm_Current_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Current_His');
    }
    if (this.pf.Single_Event_Parm_Current_Y_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Current_Y_His');
    }
    if (this.pf.Single_Event_Parm_Current_B_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Current_B_His');
    }
    if (this.pf.Single_Event_Parm_Power_Factor.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor');
    }
    if (this.pf.Single_Event_Parm_Power_Factor_Y.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor_Y');
    }
    if (this.pf.Single_Event_Parm_Power_Factor_B.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor_B');
    }
    if (this.pf.Single_Event_Parm_Power_Factor_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor_His');
    }
    if (this.pf.Single_Event_Parm_Power_Factor_Y_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor_Y_His');
    }
    if (this.pf.Single_Event_Parm_Power_Factor_B_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Power_Factor_B_His');
    }
    if (this.pf.Single_Daily_Parm_Active_Energy_Imp.value == true) {
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Imp');
    }
    if (this.pf.Single_Block_Parm_Active_Energy_Imp.value == true) {
      seletedPerms.push('Single_Block_Parm_Active_Energy_Imp');
    }
    if (this.pf.Single_Event_Parm_Active_Energy_Imp.value == true) {
      seletedPerms.push('Single_Event_Parm_Active_Energy_Imp');
    }
    if (this.pf.Single_Daily_Parm_Active_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Imp_His');
    }
    if (this.pf.Single_Block_Parm_Active_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Active_Energy_Imp_His');
    }
    if (this.pf.Single_Event_Parm_Active_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Active_Energy_Imp_His');
    }
    if (this.pf.Single_Daily_Parm_Active_Energy_Expo.value == true) {
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Expo');
    }
    if (this.pf.Single_Block_Parm_Active_Energy_Expo.value == true) {
      seletedPerms.push('Single_Block_Parm_Active_Energy_Expo');
    }
    if (this.pf.Single_Daily_Parm_Active_Energy_Expo_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Active_Energy_Expo_His');
    }
    if (this.pf.Single_Block_Parm_Active_Energy_Expo_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Active_Energy_Expo_His');
    }
    if (this.pf.Single_Daily_Parm_Apperant_Energy_Imp.value == true) {
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Imp');
    }
    if (this.pf.Single_Block_Parm_Apperant_Energy_Imp.value == true) {
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Imp');
    }
    if (this.pf.Single_Event_Parm_Apperant_Energy_Imp.value == true) {
      seletedPerms.push('Single_Event_Parm_Apperant_Energy_Imp');
    }
    if (this.pf.Single_Daily_Parm_Apperant_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Imp_His');
    }
    if (this.pf.Single_Block_Parm_Apperant_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Imp_His');
    }
    if (this.pf.Single_Event_Parm_Apperant_Energy_Imp_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Apperant_Energy_Imp_His');
    }
    if (this.pf.Single_Daily_Parm_Apperant_Energy_Expo.value == true) {
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Expo');
    }
    if (this.pf.Single_Block_Parm_Apperant_Energy_Expo.value == true) {
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Expo');
    }
    if (this.pf.Single_Daily_Parm_Apperant_Energy_Expo_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Apperant_Energy_Expo_His');
    }
    if (this.pf.Single_Block_Parm_Apperant_Energy_Expo_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Apperant_Energy_Expo_His');
    }
    if (this.pf.Single_Event_Parm_Event_Count_Imp.value == true) {
      seletedPerms.push('Single_Event_Parm_Event_Count_Imp');
    }
    if (this.pf.Single_Event_Parm_Event_Count_Imp_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Event_Count_Imp_His');
    }
    if (this.pf.Single_Event_Parm_Event_Type_Imp.value == true) {
      seletedPerms.push('Single_Event_Parm_Event_Type_Imp');
    }
    if (this.pf.Single_Event_Parm_Event_Type_Imp_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Event_Type_Imp_His');
    }
    if (this.pf.Single_Daily_Parm_Server_Datetime.value == true) {
      seletedPerms.push('Single_Daily_Parm_Server_Datetime');
    }
    if (this.pf.Single_Block_Parm_Server_Datetime.value == true) {
      seletedPerms.push('Single_Block_Parm_Server_Datetime');
    }
    if (this.pf.Single_Event_Parm_Server_Datetime.value == true) {
      seletedPerms.push('Single_Event_Parm_Server_Datetime');
    }
    if (this.pf.Single_Daily_Parm_Server_Datetime_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Server_Datetime_His');
    }
    if (this.pf.Single_Block_Parm_Server_Datetime_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Server_Datetime_His');
    }
    if (this.pf.Single_Event_Parm_Server_Datetime_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Server_Datetime_His');
    }
    if (this.pf.Single_Daily_Parm_Authority.value == true) {
      seletedPerms.push('Single_Daily_Parm_Authority');
    }
    if (this.pf.Single_Block_Parm_Authority.value == true) {
      seletedPerms.push('Single_Block_Parm_Authority');
    }
    if (this.pf.Single_Event_Parm_Authority.value == true) {
      seletedPerms.push('Single_Event_Parm_Authority');
    }
    if (this.pf.Single_Daily_Parm_Authority_His.value == true) {
      seletedPerms.push('Single_Daily_Parm_Authority_His');
    }
    if (this.pf.Single_Block_Parm_Authority_His.value == true) {
      seletedPerms.push('Single_Block_Parm_Authority_His');
    }
    if (this.pf.Single_Event_Parm_Authority_His.value == true) {
      seletedPerms.push('Single_Event_Parm_Authority_His');
    }

    
    if (this.pf.Single_Billing_Parm_Cons_Account_No.value == true) {
      seletedPerms.push('Single_Billing_Parm_Cons_Account_No');
    }
    if (this.pf.Single_Billing_Parm_Meter_Unque_Id.value == true) {
      seletedPerms.push('Single_Billing_Parm_Meter_Unque_Id');
    }
    if (this.pf.Single_Billing_Parm_Flat_No_Id.value == true) {
      seletedPerms.push('Single_Billing_Parm_Flat_No_Id');
    }
    if (this.pf.Single_Billing_Parm_Meter_Id.value == true) {
      seletedPerms.push('Single_Billing_Parm_Meter_Id');
    }
    if (this.pf.Single_Billing_Parm_RTC.value == true) {
      seletedPerms.push('Single_Billing_Parm_RTC');
    }
    if (this.pf.Single_Billing_Avg_Power_Factor.value == true) {
      seletedPerms.push('Single_Billing_Avg_Power_Factor');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB');
    }
    if (this.pf.Single_Billing_Consumed_Active_Energy.value == true) {
      seletedPerms.push('Single_Billing_Consumed_Active_Energy');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone1.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone1');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone2.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone2');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone3.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone3');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone4.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone4');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone5.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone5');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone6.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone6');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone7.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone7');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone8.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone8');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB');
    }
    if (this.pf.Single_Billing_Consumed_Apparent_Energy.value == true) {
      seletedPerms.push('Single_Billing_Consumed_Apparent_Energy');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone1.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone1');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone2.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone2');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone3.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone3');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone4.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone4');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone5.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone5');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone6.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone6');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone7.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone7');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone8.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone8');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone1.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone1');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone1.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone1');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone2.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone2');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone2.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone2');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone3.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone3');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone3.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone3');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone4.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone4');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone4.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone4');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone5.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone5');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone5.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone5');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone6.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone6');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone6.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone6');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone7.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone7');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone7.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone7');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone8.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone8');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone8.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone8');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone1.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone1');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone1.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone1');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone2.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone2');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone2.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone2');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone3.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone3');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone3.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone3');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone4.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone4');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone4.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone4');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone5.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone5');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone5.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone5');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone6.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone6');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone6.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone6');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone7.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone7');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone7.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone7');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone8.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone8');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone8.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone8');
    }
    if (this.pf.Single_Billing_Tod_Script_1.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_1');
    }
    if (this.pf.Single_Billing_Tod_Script_2.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_2');
    }
    if (this.pf.Single_Billing_Tod_Script_3.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_3');
    }
    if (this.pf.Single_Billing_Tod_Script_4.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_4');
    }
    if (this.pf.Single_Billing_Tod_Script_5.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_5');
    }
    if (this.pf.Single_Billing_Tod_Script_6.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_6');
    }
    if (this.pf.Single_Billing_Tod_Script_7.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_7');
    }
    if (this.pf.Single_Billing_Tod_Script_8.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_8');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_1.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_1');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_2.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_2');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_3.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_3');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_4.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_4');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_5.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_5');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_6.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_6');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_7.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_7');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_8.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_8');
    }
    if (this.pf.Single_Billing_Md_Reset_Type.value == true) {
      seletedPerms.push('Single_Billing_Md_Reset_Type');
    }
    if (this.pf.Single_Billing_Active_Energy_Export.value == true) {
      seletedPerms.push('Single_Billing_Active_Energy_Export');
    }
    if (this.pf.Single_Billing_Apperant_Energy_Export.value == true) {
      seletedPerms.push('Single_Billing_Apperant_Energy_Export');
    }
    if (this.pf.Single_Billing_Bill_PowerOn_Hrs_EB.value == true) {
      seletedPerms.push('Single_Billing_Bill_PowerOn_Hrs_EB');
    }
    if (this.pf.Single_Billing_Load_Limit_Grid.value == true) {
      seletedPerms.push('Single_Billing_Load_Limit_Grid');
    }
    if (this.pf.Single_Billing_Billing_Mode_EB.value == true) {
      seletedPerms.push('Single_Billing_Billing_Mode_EB');
    }
    if (this.pf.Single_Billing_Bill_Type.value == true) {
      seletedPerms.push('Single_Billing_Bill_Type');
    }
    if (this.pf.Single_Billing_Previous_Bill_Date_Time.value == true) {
      seletedPerms.push('Single_Billing_Previous_Bill_Date_Time');
    }
    if (this.pf.Single_Billing_Bill_Sent_Datetime.value == true) {
      seletedPerms.push('Single_Billing_Bill_Sent_Datetime');
    }
    if (this.pf.Single_Billing_Server_Date_Time.value == true) {
      seletedPerms.push('Single_Billing_Server_Date_Time');
    }

    if (this.pf.Single_Billing_Auth_Unique_Id.value == true) {
      seletedPerms.push('Single_Billing_Auth_Unique_Id');
    }

    if (this.pf.Single_Billing_Parm_Cons_Account_No_His.value == true) {
      seletedPerms.push('Single_Billing_Parm_Cons_Account_No_His');
    }
    if (this.pf.Single_Billing_Parm_Meter_Unque_Id_His.value == true) {
      seletedPerms.push('Single_Billing_Parm_Meter_Unque_Id_His');
    }
    if (this.pf.Single_Billing_Parm_Flat_No_Id_His.value == true) {
      seletedPerms.push('Single_Billing_Parm_Flat_No_Id_His');
    }
    if (this.pf.Single_Billing_Parm_Meter_Id_His.value == true) {
      seletedPerms.push('Single_Billing_Parm_Meter_Id_His');
    }
    if (this.pf.Single_Billing_Parm_RTC_His.value == true) {
      seletedPerms.push('Single_Billing_Parm_RTC_His');
    }
    if (this.pf.Single_Billing_Avg_Power_Factor_His.value == true) {
      seletedPerms.push('Single_Billing_Avg_Power_Factor_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_His');
    }
    if (this.pf.Single_Billing_Consumed_Active_Energy_His.value == true) {
      seletedPerms.push('Single_Billing_Consumed_Active_Energy_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone1_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone2_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone3_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone4_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone5_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone6_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone7_His');
    }
    if (this.pf.Single_Billing_Cum_Active_Energy_EB_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Active_Energy_EB_Zone8_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_His');
    }
    if (this.pf.Single_Billing_Consumed_Apparent_Energy_His.value == true) {
      seletedPerms.push('Single_Billing_Consumed_Apparent_Energy_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone1_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone2_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone3_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone4_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone5_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone6_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone7_His');
    }
    if (this.pf.Single_Billing_Cum_Apperant_Energy_EB_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_Cum_Apperant_Energy_EB_Zone8_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone1_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone1_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone2_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone2_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone3_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone3_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone4_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone4_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone5_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone5_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone6_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone6_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone7_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone7_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Value_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Value_Zone8_His');
    }
    if (this.pf.Single_Billing_MD_Active_Power_Datetime_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Active_Power_Datetime_Zone8_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone1_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone1_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone1_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone2_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone2_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone2_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone3_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone3_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone3_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone4_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone4_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone4_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone5_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone5_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone5_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone6_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone6_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone6_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone7_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone7_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone7_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Value_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Value_Zone8_His');
    }
    if (this.pf.Single_Billing_MD_Apparent_Power_Datetime_Zone8_His.value == true) {
      seletedPerms.push('Single_Billing_MD_Apparent_Power_Datetime_Zone8_His');
    }
    if (this.pf.Single_Billing_Tod_Script_1_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_1_His');
    }
    if (this.pf.Single_Billing_Tod_Script_2_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_2_His');
    }
    if (this.pf.Single_Billing_Tod_Script_3_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_3_His');
    }
    if (this.pf.Single_Billing_Tod_Script_4_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_4_His');
    }
    if (this.pf.Single_Billing_Tod_Script_5_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_5_His');
    }
    if (this.pf.Single_Billing_Tod_Script_6_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_6_His');
    }
    if (this.pf.Single_Billing_Tod_Script_7_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_7_His');
    }
    if (this.pf.Single_Billing_Tod_Script_8_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Script_8_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_1_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_1_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_2_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_2_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_3_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_3_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_4_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_4_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_5_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_5_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_6_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_6_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_7_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_7_His');
    }
    if (this.pf.Single_Billing_Tod_Timing_TZ_8_His.value == true) {
      seletedPerms.push('Single_Billing_Tod_Timing_TZ_8_His');
    }
    if (this.pf.Single_Billing_Md_Reset_Type_His.value == true) {
      seletedPerms.push('Single_Billing_Md_Reset_Type_His');
    }
    if (this.pf.Single_Billing_Active_Energy_Export_His.value == true) {
      seletedPerms.push('Single_Billing_Active_Energy_Export_His');
    }
    if (this.pf.Single_Billing_Apperant_Energy_Export_His.value == true) {
      seletedPerms.push('Single_Billing_Apperant_Energy_Export_His');
    }
    if (this.pf.Single_Billing_Bill_PowerOn_Hrs_EB_His.value == true) {
      seletedPerms.push('Single_Billing_Bill_PowerOn_Hrs_EB_His');
    }
    if (this.pf.Single_Billing_Load_Limit_Grid_His.value == true) {
      seletedPerms.push('Single_Billing_Load_Limit_Grid_His');
    }
    if (this.pf.Single_Billing_Billing_Mode_EB_His.value == true) {
      seletedPerms.push('Single_Billing_Billing_Mode_EB_His');
    }
    if (this.pf.Single_Billing_Bill_Type_His.value == true) {
      seletedPerms.push('Single_Billing_Bill_Type_His');
    }
    if (this.pf.Single_Billing_Previous_Bill_Date_Time_His.value == true) {
      seletedPerms.push('Single_Billing_Previous_Bill_Date_Time_His');
    }
    if (this.pf.Single_Billing_Bill_Sent_Datetime_His.value == true) {
      seletedPerms.push('Single_Billing_Bill_Sent_Datetime_His');
    }
    if (this.pf.Single_Billing_Server_Date_Time_His.value == true) {
      seletedPerms.push('Single_Billing_Server_Date_Time_His');
    }
    if (this.pf.Single_Billing_Auth_Unique_Id_His.value == true) {
      seletedPerms.push('Single_Billing_Auth_Unique_Id_His');
    }
   
    this.isloading=true;
      this.message ='';
      let body = new URLSearchParams();
      body.set('seletedPerms', seletedPerms.toString());
      body.set('authUniqueId', this.authorityData.aID);
      body.set('authToken', this.currentUser.authToken);
      this.genReqs.postReq('/setReportParmPermissions', body).subscribe(
        (res) => {
          this.message = res.message;
          //this.loading = false;
         
          if (res.success) {
            this.isloading=false;
            this.thumb = true;
            //this.addAuthMessage = this.responseData.data.message;
          }else{
              this.thumb = false;
          }

        },
        (err) => {
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.loading = false;
        }
      );
  }

  onDragChange() {
    //console.log('hello');
  }

  getAuthPerms() {
    this.loading = true;
    this.message ='';
    let body = new URLSearchParams();
    body.set('authId', this.authorityData.aID);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getAuthReportPermission', body).subscribe((result)=>{
      this.responseData = result;
      if(this.responseData.success){
        this.loading = false;
         this.authorityPerms = this.responseData.data;
         this.addAuthorityForm.patchValue(this.authorityPerms);
      }else{
        this.message = this.responseData.message;//this.responseData.isresponse;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.message = 'Unable to process yours request!';
    });
  }

}
