import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';

@Component({
  selector: 'app-authority-profile',
  templateUrl: './authority-profile.component.html',
  styleUrls: ['./authority-profile.component.scss']
})
export class AuthorityProfileComponent implements OnInit {

  addForm: UntypedFormGroup;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  userData:any = {
    'name': "",
    'username': "",
    'password': "",
    'email': "",
    'lanlineNo': "",
    'mobile': "",
    'homeadd1': "",
    'homeadd2': "",
    'officeadd1': "",
    'officeadd2': "",
    'dob': ""
    //'rdatetime': "2019-11-13 12:23:13"
  }
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.userData = this.currentUser.data.userData;
  }

}
