<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Add Authority
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Authority Level" name="authLevel" formControlName="authLevel"
                                (selectionChange)="authLevelChanged($event)" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authLevel.errors}" required>
                                <mat-option *ngFor="let auth of authLevelData" [value]="auth.id">
                                    {{auth.text}}
                                </mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Authority Level</label>
                            <div *ngIf="submitted && aD.authLevel.errors" class="invalid-feedback">
                                <div *ngIf="aD.authLevel.errors.required">
                                    Code is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Authority Full Name" name="authFullName" formControlName="authFullName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authFullName.errors}"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Authority Full Name</label>
                            <div *ngIf="submitted && aD.authFullName.errors" class="invalid-feedback">
                                <div *ngIf="aD.authFullName.errors.required">
                                    Auth Full Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="User Name" name="authUserName" formControlName="authUserName"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authUserName.errors}"
                                required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>User Name</label>
                            <div *ngIf="submitted && aD.authUserName.errors" class="invalid-feedback">
                                <div *ngIf="aD.authUserName.errors.required">
                                    Auth User Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="password" placeholder="Authority Password" name="authPassword"
                                formControlName="authPassword" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authPassword.errors}" required>
                            <i class="fa fa-lock icon_innertxtbox"></i>
                            <label>Authority Password</label>
                            <div *ngIf="submitted && aD.authPassword.errors" class="invalid-feedback">
                                <div *ngIf="aD.authPassword.errors.required">
                                    Auth Password is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="email" placeholder="Authority Email Id" name="authEmailId"
                                formControlName="authEmailId"
                                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authEmailId.errors}" required>
                            <i class="fa fa-envelope icon_innertxtbox"></i>
                            <label>Authority Email Id</label>
                            <div *ngIf="submitted && aD.authEmailId.errors" class="invalid-feedback">
                                <div *ngIf="aD.authEmailId.errors.required">
                                    Auth Email Id is required
                                </div>
                                <div *ngIf="aD.authEmailId.hasError('email')">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Authority Landline No" name="authLandlineNo"
                                formControlName="authLandlineNo" pattern="[0-9 -()+]+$" minlength="10" maxlength="10"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authLandlineNo.errors}"
                                required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Authority Landline No</label>
                            <div *ngIf="submitted && aD.authLandlineNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.authLandlineNo.errors.required">
                                    Auth Landline is required
                                </div>
                                <div *ngIf="aD.authLandlineNo.hasError('pattern')">
                                    Auth Landline number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input type="tel" placeholder="Authority Mobile No" name="authMobileNo"
                                formControlName="authMobileNo" pattern="^[6-9]\d{9}$" minlength="10" maxlength="10"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authMobileNo.errors}"
                                required>
                            <i class="fa fa-phone icon_innertxtbox"></i>
                            <label>Authority Mobile No</label>
                            <div *ngIf="submitted && aD.authMobileNo.errors" class="invalid-feedback">
                                <div *ngIf="aD.authMobileNo.errors.required">
                                    Auth Mobile No is required
                                </div>
                                <div *ngIf="aD.authMobileNo.hasError('pattern')">
                                    Mobile number not valid.
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Auth Home Address Line 1" name="authHomeAddressline1"
                                formControlName="authHomeAddressline1" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authHomeAddressline1.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Auth Home Address Line 1</label>
                            <div *ngIf="submitted && aD.authHomeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.authHomeAddressline1.errors.required">
                                    Auth Home Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Auth Home Address Line 2" name="authHomeAddressline2"
                                formControlName="authHomeAddressline2" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authHomeAddressline2.errors}" required>
                            <i class="fa fa-home icon_innertxtbox"></i>
                            <label>Auth Home Address Line 2</label>
                            <div *ngIf="submitted && aD.authHomeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.authHomeAddressline2.errors.required">
                                    Auth Home Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Auth Office Address Line 1" name="authOfficeAddressline1"
                                formControlName="authOfficeAddressline1" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authOfficeAddressline1.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Auth Office Address Line 1</label>
                            <div *ngIf="submitted && aD.authOfficeAddressline1.errors" class="invalid-feedback">
                                <div *ngIf="aD.authOfficeAddressline1.errors.required">
                                    Auth Office Address line1 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Auth Office Address Line 2" name="authOfficeAddressline2"
                                formControlName="authOfficeAddressline2" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authOfficeAddressline2.errors}" required>
                            <i class="fa fa-building icon_innertxtbox"></i>
                            <label>Auth Office Address Line 2</label>
                            <div *ngIf="submitted && aD.authOfficeAddressline2.errors" class="invalid-feedback">
                                <div *ngIf="aD.authOfficeAddressline2.errors.required">
                                    Auth Office Address line2 is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <mat-select placeholder="Avtive Status" name="authorityActiveStatus"
                                formControlName="authorityActiveStatus" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authorityActiveStatus.errors}" required>
                                <mat-option value="Y">Active</mat-option>
                                <mat-option value="N">In-Active</mat-option>
                            </mat-select>
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label>Avtive Status</label>
                            <div *ngIf="submitted && aD.authorityActiveStatus.errors" class="invalid-feedback">
                                <div *ngIf="aD.authorityActiveStatus.errors.required">
                                    Authority Active Status is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && aD.authorityDOB.errors}" required>
                            <i class="fa fa-calendar icon_innertxtbox"></i>
                            <label>Authority DOB</label>
                            <div *ngIf="submitted && aD.authorityDOB.errors" class="invalid-feedback">
                                <div *ngIf="aD.authorityDOB.errors.required">
                                    Authority DOB is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="status ">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="Authority Type" name="authType"
                                    formControlName="authType" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && aD.authType.errors}"
                                    required>
                                    <mat-option value="I" *ngIf="this.currentUser.data.userData.authorityType=='I'">XEN</mat-option>
                                    <mat-option value="Y" *ngIf="this.currentUser.data.userData.authorityType=='I'">JE</mat-option>
                                    <mat-option value="N">Meter Installer</mat-option>
                                </mat-select>
                                <i class="fa fa-envelope icon_innertxtbox"></i>
                                <label>Authority Type</label>
                                <div *ngIf="submitted && aD.authType.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authType.errors.required">
                                        Authority Type is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!status">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="SMS Receive Status" name="authoritySMSReceiveStatus"
                                    formControlName="authoritySMSReceiveStatus" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && aD.authoritySMSReceiveStatus.errors}"
                                    required>
                                    <mat-option value="Y">Active</mat-option>
                                    <mat-option value="N">In-Active</mat-option>
                                </mat-select>
                                <i class="fa fa-envelope icon_innertxtbox"></i>
                                <label>SMS Receive Status</label>
                                <div *ngIf="submitted && aD.authoritySMSReceiveStatus.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authoritySMSReceiveStatus.errors.required">
                                        Authority SMS Receive Status is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Grid row -->
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && message.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->