import { Component, OnInit, ViewChild ,Input} from '@angular/core';

import { formatDate } from '@angular/common';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-meterteriff1phdomestic',
  templateUrl: './meterteriff1phdomestic.component.html',
  styleUrls: ['./meterteriff1phdomestic.component.scss']
})
export class Meterteriff1phdomesticComponent  implements OnInit {
  tagValue = 'col-sm-8 text-right';
  @ViewChild('picker') picker: any;
  faSpinner = faSpinner;
  public today = new Date();
  message:string = '';
  button = 'Update';
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  status:boolean = true;
  actionForm: UntypedFormGroup;
  isLoadingz = false;
  formName1="1-PH Teriff (Domastic)";
  formName2="1-PH Teriff Setting (Domastic)";
  isLoading = false;
  actionForm15: UntypedFormGroup;
  
  meterDateTime:string='';
  timeDiffrence:string='';
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  selectedSubDivision:any = 0;
  AddProcess:boolean = false;
  responseData: any;
  submitted: boolean = false;
  //meterDetail:any = {};
  @Input() meterDetail:any = {
    muID:0,
    mID: "All",
    cACNO:0,
    sdID:this.selectedSubDivision
  };
  numberReturn(length){
    return new Array(length);
  }

  //serverDateTime = new Date();
  serverDateTime = "0000-00-00 00:00:00";
  data = true;
  /*capture: any = [
    { 'id': 0, 'Name': 'Select Capture Period' },
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];*/
  capture: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' },
    { 'id': 3600, 'Name': '60 Minutes' }
  ];
  /*demand: any = [
    { 'id': 0, 'Name': 'Select Capture Period' },
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  
  ];*/

  demand: any = [
    { 'id': 900, 'Name': '15 Minutes' },
    { 'id': 1800, 'Name': '30 Minutes' }
  ];
  cmdformData=1;
  loadLimitValue=0;
  lockOutPeriodValue=0;
  relayConnDisvalue=0;
  myDate = new Date();
  currentMonth= new Date();
  cValue = formatDate(this.myDate, 'MM/dd/yyyy', 'en-US');
  nextMonthStartDate='';
  startDate = '';
 
  thumb:boolean = false;
  servantmode:any;
  getThrough: Access;

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'MM/YYYY', 
    minMode: 'month',
    //dateInputFormat: 'DD/MM/YYYY', // Customize the date format if needed
    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Set the minimum date to the current date
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService

  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {

    

    this.calculateNextMonthStartDate();
    this.actionForm = this.formBuilder.group({
      consCategory: ['', Validators.required],
      teriffName: ['', Validators.required],
      //monthlyConsumption: ['', Validators.required],
      emergencyCreditLimit: ['', Validators.required],
      alarm: ['', Validators.required],
      ebUnitRate: ['', Validators.required],
      mvcaCharges: ['', Validators.required],
      fixedCharges: ['', Validators.required],
      meterRent: ['', Validators.required],
      electDutyCharges1: ['', Validators.required],
      electDutyCharges2: ['', Validators.required],
      schedularStartEndDate: [this.myDate, Validators.required],
      pastTeriffDate: [{value:'', disabled: true}, Validators.required],
    });
   
    this.getConfiguratoin();
    if(this.meterDetail.meterType=='1'){
      this.formName1="1-PH Tariff (Domastic)";
      this.formName2="1-PH Tariff Setting (Domastic)";
    }
    if(this.meterDetail.meterType=='3'){
      this.formName1="3-PH Tariff (Domastic)";
      this.formName2="3-PH Tariff Setting (Domastic)";
    }
  }

  getDateFromRange(pickedDate = new Date()) {
   //alert(pickedDate);
    if (pickedDate) {
        let emonth = pickedDate.getMonth()+1; //always move 1 value up
        let eday = pickedDate.getDate();
        let eyear = pickedDate.getFullYear();
        this.startDate = eyear + '-' + emonth + '-' + eday;
    }
  }

  calculateNextMonthStartDate() {
    const currentDate = new Date();
    const currentDate1 = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(1); // Set the day to the 1st day of the next month
    
    this.myDate = currentDate;

    currentDate1.setMonth(currentDate1.getMonth()-2);
    currentDate1.setDate(1); 
    this.currentMonth = currentDate1;
    
    let emonth = this.myDate.getMonth(); //always move 1 value up
    let eday = this.myDate.getDate();
    let eyear = this.myDate.getFullYear();
    this.startDate = eyear + '-' + '11' + '-' + eday;
    
  }


  getConfiguratoin() {
    let body = new URLSearchParams();
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('subDivDatabase', this.meterDetail.subDivDatabase);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getBillingTeriffIPCL', body).subscribe((result) => {
        this.responseData = result;
        if (this.responseData.success) {
            this.aD.consCategory.setValue(this.responseData.data.consCategory);
            this.aD.teriffName.setValue(this.responseData.data.teriffName);
            //this.aD.monthlyConsumption.setValue(this.responseData.data.monthlyConsumption);
            this.aD.emergencyCreditLimit.setValue(this.responseData.data.emergCreditLimit.toString());
            this.aD.alarm.setValue(this.responseData.data.alarmLevel.toString());
            this.aD.ebUnitRate.setValue(this.responseData.data.eBUnitRate.toString());
            this.aD.mvcaCharges.setValue(this.responseData.data.mvcaRate.toString());
            this.aD.fixedCharges.setValue(this.responseData.data.eBFixCharges.toString());
            this.aD.meterRent.setValue(this.responseData.data.vendingRate.toString());
            this.aD.electDutyCharges1.setValue(this.responseData.data.ed1.toString());
            this.aD.electDutyCharges2.setValue(this.responseData.data.ed2.toString());
            this.aD.pastTeriffDate.setValue(new Date(this.responseData.data.dateTime.toString()));
        } else {
            //console.log(this.responseData.message);//this.responseData.isresponse;
        }
    }, (err) => {
        if (err.status === 401) {
            this.authenticationService.logout();
        }
    });
  }
  
  get aD() { return this.actionForm.controls; }

  ngOnDestroy() {
    //this.modalService.dismissAll();
  }

  submitForm(){
    console.log("<<<<<<<<<<<<"+this.startDate);
    //alert(number);
    this.AddProcess = true;
    this.message='';
    this.button="Process";
    this.isLoadingz = true;
    this.isLoading = true;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('dcuId', this.meterDetail.dCUID);
    body.set('meterType', this.meterDetail.meterType);
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    
    body.set('consCategory',this.aD.consCategory.value);
    body.set('teriffName',this.aD.teriffName.value);
    //body.set('monthlyConsumption',this.aD.monthlyConsumption.value);
    body.set('emergencyCreditLimit',this.aD.emergencyCreditLimit.value);
    body.set('alarm',this.aD.alarm.value);
    body.set('ebUnitRate',this.aD.ebUnitRate.value);
    body.set('mvcaCharges',this.aD.mvcaCharges.value);
    body.set('fixedCharges',this.aD.fixedCharges.value);
    body.set('meterRent',this.aD.meterRent.value);
    body.set('electDutyCharges1',this.aD.electDutyCharges1.value);
    body.set('electDutyCharges2',this.aD.electDutyCharges2.value);
    body.set('schedularStartTime', this.startDate);
    body.set('cmdFrom',  this.cmdformData.toString());
    body.set('dedTypeId', '1');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/configBillingTeriffIpcl', body).subscribe((result)=>{
      this.responseData = result;
      this.AddProcess = false;
      this.button="Update";
      this.isLoadingz = false;
      this.isLoading = false;
      if (this.responseData.success) {
        this.thumb = true;
        this.message = this.responseData.data.message;
        if(this.message.includes("Not") || this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Unsuccessful")|| this.message.includes("Unsuccessfully") || this.message.includes("Under Progress")){
          this.thumb = false;
        }else{
          this.thumb = true;
        }
        //this.modal.close('done');
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
    });
  }
}
