<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
      Modify Meter : {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body ">
    <!--<div class="alert alert-secondary" role="alert" *ngIf="isMessage" >{{responseMessage}}</div>
      -->
    <div class="form-groupp">
      <!-- Grid row -->
      <div class="row">
        <div class="col-md-6" *ngIf="meterCommMode!='2'">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter GPRS Sim No" name="meterGPRSSimNo" formControlName="meterGPRSSimNo"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.meterGPRSSimNo.errors }" required>
              <i class="fa fa-inr icon_innertxtbox"></i>
              <label>Meter GPRS Sim No</label>
              <div *ngIf="submitted && aF.meterGPRSSimNo.errors" class="invalid-feedback">
                <div *ngIf="aF.meterGPRSSimNo.errors.required">
                  Meter GPRS Sim No is required.
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="col-md-6" *ngIf="meterCommMode!='2'">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="Meter Contact No" name="meterGPRSSimIMEINo" formControlName="meterGPRSSimIMEINo"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.meterGPRSSimIMEINo.errors }" required>
              <i class="fa fa-phone icon_innertxtbox"></i>
              <label>Meter Contact No</label>
              <div *ngIf="submitted && aF.meterGPRSSimIMEINo.errors" class="invalid-feedback">
                <div *ngIf="aF.meterGPRSSimIMEINo.errors.required">
                  Meter GPRS Sim IMEI No is required.
                </div>
              </div>
            </span>
          </div>
        </div>
      <!--</div>-->


      <!--<mat-form-field class="col-md-6" *ngIf="meterCommMode!='2'">
              <input matInput placeholder="Meter Installed Address 1" name="meterInstalledLocationAddress1" formControlName="meterInstalledLocationAddress1">
              <mat-icon matPrefix class="d-c10">library_books</mat-icon>
              <mat-error *ngIf="aF.meterInstalledLocationAddress1.hasError('required')">
                Meter Installed Location Address Line 1 is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6" *ngIf="meterCommMode!='2'">
              <input matInput placeholder="Meter Installed Address 2" name="meterInstalledLocationAddress2" formControlName="meterInstalledLocationAddress2">
              <mat-icon matPrefix class="d-c10">library_books</mat-icon>
              <mat-error *ngIf="aF.meterInstalledLocationAddress2.hasError('required')">
                Meter Installed Location Address Line 2 is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->
      <!--<div class="row">-->
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="EB kWH Start Units" name="eBkWHStartUnits" formControlName="eBkWHStartUnits"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.eBkWHStartUnits.errors }" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>EB kWH Start Units</label>
              <div *ngIf="submitted && aF.eBkWHStartUnits.errors" class="invalid-feedback">
                <div *ngIf="aF.eBkWHStartUnits.errors.required">
                  Grid kWH Start Units is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="meterCommMode!='2' && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input placeholder="DG kWH Start Units" name="dgkWHStartUnits" formControlName="dgkWHStartUnits"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgkWHStartUnits.errors }" required>
              <i class="fa fa-database icon_innertxtbox"></i>
              <label>DG kWH Start Units</label>
              <div *ngIf="submitted && aF.dgkWHStartUnits.errors" class="invalid-feedback">
                <div *ngIf="aF.dgkWHStartUnits.errors.required">
                  DG kWH Start Units is required
                </div>
              </div>
            </span>
          </div>
        </div>
      <!--</div>
      <div class="row">-->
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input matInput placeholder="Meter Start Balance" name="meterStartBalance"
                formControlName="meterStartBalance" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterStartBalance.errors }" required>
              <i class="fa fa-inr icon_innertxtbox"></i>
              <label>Meter Start Balance</label>
              <div *ngIf="submitted && aF.meterStartBalance.errors" class="invalid-feedback">
                <div *ngIf="aF.meterStartBalance.errors.required">
                  Meter Start Balance is required
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group input-group">
            <span class="has-float-label">
              <input matInput placeholder="Meter Installation Date & Time" name="meterInstalletionDateTime"
                formControlName="meterInstalletionDateTime" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && aF.meterInstalletionDateTime.errors }" required>
              <i class="fa fa-clock-o icon_innertxtbox"></i>
              <label>Meter Installation Date & Time</label>
              <div *ngIf="submitted && aF.meterInstalletionDateTime.errors" class="invalid-feedback">
                <div *ngIf="aF.meterInstalletionDateTime.errors.required">
                  Meter Installation Date & Time is required
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>



      <!--<mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Server Ip Address" name="meterServerIpAddress" formControlName="meterServerIpAddress">
              <mat-icon matPrefix class="d-c7">graphic_eq</mat-icon>
              <mat-error *ngIf="aF.meterServerIpAddress.hasError('required')">
                Meter Server Ip Address is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <input matInput placeholder="Meter Server Port No" name="meterServerPortNo" formControlName="meterServerPortNo">
              <mat-icon matPrefix class="d-c8">storage</mat-icon>
              <mat-error *ngIf="aF.meterServerPortNo.hasError('required')">
                Meter Server Port No is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->
      <!-- <div class="col-md-6" *ngIf="meterCommMode=='2'">
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input  placeholder="HES Id" name="hesId" formControlName="hesId"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.hesId.errors }" required>
                  <i class="fa fa-inr icon_innertxtbox"></i>
                  <label>EB kWH Start Units</label>
                  <div *ngIf="submitted && aF.hesId.errors" class="invalid-feedback">
                    <div *ngIf="aF.hesId.errors.required">
                      Meter HES Id is required
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div> -->

      <mat-form-field class="col-md-6" *ngIf="meterCommMode=='2'">
        <input matInput placeholder="HES Id" name="hesId" formControlName="hesId">
        <mat-icon matPrefix class="d-c8">storage</mat-icon>
        <mat-error *ngIf="aF.hesId.hasError('required')">
          Meter HES Id is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <div class="col-md-6" 
      *ngIf="meterCommMode!='2' && getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
        <div class="form-group input-group">
          <span class="has-float-label">
            <mat-select placeholder="Backup Status" name="backUpStatus" formControlName="backUpStatus"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.backUpStatus.errors }" required>
              <mat-option value='Y'>Active</mat-option>
              <mat-option value='N'>In-Active</mat-option>
            </mat-select>
            <i class="fa fa-toggle-on icon_innertxtbox"></i>
            <label>Backup Status</label>
            <div *ngIf="submitted && aF.backUpStatus.errors" class="invalid-feedback">
              <div *ngIf="aF.backUpStatus.errors.required">
                Backup Status is required
              </div>
            </div>
          </span>
        </div>
      </div>



      <!--<mat-form-field class="col-md-6">-->
      <!--<input matInput placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">-->
      <!-- <mat-select placeholder="Meter Firmware Code Version" name="meterFirmwareCodeVersion" formControlName="meterFirmwareCodeVersion">
                <mat-option *ngFor="let td of firmWareVersion" [value]="td.id">
                    {{td.Name}}
                </mat-option>
              </mat-select>
              <mat-icon matPrefix class="d-c9">report_problem</mat-icon>
              <mat-error *ngIf="aF.meterFirmwareCodeVersion.hasError('required')">
                Meter Firmware Code Version is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
              <mat-select placeholder="Disabled Status" name="meterActiveStatus" formControlName="meterActiveStatus">
                <mat-option *ngFor="let td of activeStatus" [value]="td.id">
                    {{td.Name}}
                </mat-option>
              </mat-select>
              <mat-icon matPrefix class="d-c3">settings_input_antenna</mat-icon>
              <mat-error *ngIf="aF.meterActiveStatus.hasError('required')">
                Disabled Status is <strong>required</strong>
              </mat-error>
            </mat-form-field>-->




      <!-- Grid row -->
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-sm-12 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
          </ng-container>
          <div role="alert" *ngIf="message.length > 0">
            <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
              <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
            </div>
            <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
              <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
            </div>
          </div>

        </div>
        <div class="col-sm-12 text-right">
          <button type="submit" class="dilog dilog1">
            <mat-icon>add</mat-icon>
            Submit
          </button>
          <button type="button" (click)="modal.close('Ok click')" class="dilog2 dilog3">
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>

          <!--<button type="submit" [disabled]="AddProcess"  class="dilog-button dilog-button1">
                <mat-icon matPrefix>update</mat-icon>
                Update
              </button>
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon matPrefix>cancel</mat-icon>
                Cancel
              </button>-->
        </div>
      </div>
    </div>
  </div>
</form>