<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
        data-dismiss="modal">&times;</button>
        Recent Recharges: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
  <ng-container>
    <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
      <fa name="spinner" animation="spin" size="4x"></fa>
    </ng-container>

    <div class="row mx-auto form-wrapper table-responsive" *ngIf="isData">
      <table class="table-bordered table-sm table-hover">
       <thead class="tablebghead">
          <tr>
            <td>
              <div class="d_title">
                Sr. No.
            </div>
            </td>
            <td>
              <div class="d_title">
                Meter Id
            </div>
            </td>
            <td>
              <div class="d_title ">
                Payment Mode
            </div>
            </td>
            <td>
              <div class="d_title ">
                Recharge Amount
            </div>
            </td>
            <td>
              <div class="d_title ">
                Recharge Status
            </div>
            </td>
            <td>
              <div class="d_title1">
                Transaction Id
            </div>
            </td>
            <td>
              <div class="d_title ">
                Server Date & time
            </div>
            </td>
            <td>
              <div class="d_title">
                Remark
            </div>
            </td>
          </tr>
        </thead>
        <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.meterId}}</p>
              </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.paymentFrom}}</p>
            </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.rechargeAmount}}</p>
            </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.rechargeStatus}}</p>
            </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.tranNo}}</p>
            </div>
            </td>
            <td>
              <div class="d_content d_content_W">
                <p>{{item.rechargeDateTime}}</p>
            </div>
            </td>
              <td>
                <div class="d_content d_content_W">
                  <p>{{item.rechargeRemark}}</p>
              </div>
              </td>    
              </tr>
            </tbody>
            </table>
            <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                        <div class="d_title {{item.bold_class}}">
                            {{item.title}}
                        </div>
                        <div class="d_content">
                            <p>{{item.content}}</p>
                        </div>
                        <hr>
            </div>           
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
                <mat-icon class="d-c3">cancel</mat-icon>
                Exit
              </button>
            </div>-->
          </div>

          <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
          <div class="col-sm-12">
            <img class="center" src="assets/images/Nodata.png">
          </div>
            <div class="col-12 text-right">
              <button type="button"  (click)="modal.close('Ok click')"  class="dilog2 dilog3">
                <mat-icon class="d-c3">cancel</mat-icon>
                Cancel
              </button>
            </div>
          </div>
          
        </ng-container>
     <ng-container col-sm-3>
     </ng-container>
 </div>
 </div>  