import { Component, AfterViewInit,ViewChild } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { Access } from 'src/app/_models/access';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from '../TableUtil';

@Component({
  selector: 'app-meter-install-desh-other',
  templateUrl: './meter-install-desh-other.component.html',
  styleUrls: ['./meter-install-desh-other.component.scss']
})
export class MeterInstallDeshOtherComponent implements AfterViewInit  {
  levelSearch: string = '';
 
  displayedColumns= [];
 
  totalProposed1Ph: number = 0;
  totalReplaced1Ph: number = 0;
  totalToday1Ph: number = 0;
  totalBalanced1Ph: number = 0;
  totalProposed3Ph: number = 0;
  totalReplaced3Ph: number = 0;
  totalToday3Ph: number = 0;
  totalBalanced3Ph: number = 0;
  totalProposedLTCT: number = 0;
  totalReplacedLTCT: number = 0;
  totalTodayLTCT: number = 0;
  totalBalanceLTCT: number = 0;

  dataSource = new MatTableDataSource();
  currentUserSubscription: Subscription;
  currentUser: User;
  getThrough: Access;
  loading: boolean = false;
  defaultCircle: number = 0;
  defaultDivision: number = 0;
  defaultDC: number = 0;
  defaultFeeder: number = 0;
  defaultGr: number = 0;
  defaultRd: number = 0;
  defaultAuth: number = 0;
  limit: number = 20;
  skip: number = 0;
  filter = '';
  responseData: any;
  length = 10;
  loadingMessage: any = '';
  loadingFirstTime: boolean = true;
  levelColumnsToDisplay= [];
  faSpinner = faSpinner;
  shorting: any = '';
  subDIv: any = '';
  checkStatus: boolean = true;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private modal: NgbActiveModal,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      });
       this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
    }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
  
    this.subDIv=this.currentUser.data.userData.subdivision;

    this.displayedColumns.push('srNo');
    this.displayedColumns.push('conCircle');
      if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Single_Ph_Show){
        this.displayedColumns.push('total1PhProposed');
        this.displayedColumns.push('total1MeterCount');
        this.displayedColumns.push('dailyTotal1MeterCount');
        this.displayedColumns.push('total1PhBalance');
      }
      
      if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Three_Ph_Show){
            this.displayedColumns.push('total3PhProposed');
            this.displayedColumns.push('totalMeterCount');
            this.displayedColumns.push('dailyTotalMeterCount');
            this.displayedColumns.push('total3PhBalance');
        }
        if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_LTCT_Ph_Show){
            this.displayedColumns.push('totalLTCTProposed');
            this.displayedColumns.push('totalMeterCountLTCT');
            this.displayedColumns.push('dailyTotalMeterCountLTCT');
            this.displayedColumns.push('totalLTCTBalance');
        }
        //console.log(this.displayedColumns);

    /*if(this.subDIv==96){
      this.checkStatus=true;
      this.displayedColumns = [
        'srNo', 
        'conCircle',
        'total3PhProposed', 
        'totalMeterCount',
        'dailyTotalMeterCount', 
        'total3PhBalance', 
        'totalLTCTProposed', 
        'totalMeterCountLTCT', 
        'dailyTotalMeterCountLTCT', 
        'totalLTCTBalance'];
    }else  if(this.subDIv==102){
      this.checkStatus=false;
      this.displayedColumns= [
        'srNo', 
        'conCircle',
        'total3PhProposed', 
        'totalMeterCount',
        'dailyTotalMeterCount', 
        'total3PhBalance' 
        ];
    }*/
    //this.levelColumnsToDisplay= this.levelColumnsToDisplayCircle;
    this.getMeterCommand();
  }

  /** Announce the change in sort state for assistive technology. */
 

  getMeterCommand() {
    let body = new URLSearchParams();
    this.loading = true;
    body.set('meterSubDivision', this.currentUser.data.userData.subdivision);
    body.set('defaultCircle', this.defaultCircle.toString());
    body.set('defaultDivision', this.defaultDivision.toString());
    body.set('defaultDC', this.defaultDC.toString());
    body.set('defaultFeeder', this.defaultFeeder.toString());
    body.set('defaultGr', this.defaultGr.toString());
    body.set('defaultRd', this.defaultRd.toString());
    body.set('defaultAuth', this.defaultAuth.toString());

    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getInstalledMeter', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      this.loadingFirstTime = false;
      console.log(this.responseData.data);
      if (this.responseData.success) {
       
        this.dataSource.data = this.responseData.data;
        this.dataSource.data.pop();
        this.dataSource.sort = this.sort;
        if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Single_Ph_Show){
          this.totalProposed1Ph=this.responseData.datalimit.totals.totalProposed1PH;
          this.totalReplaced1Ph=this.responseData.datalimit.totals.totalReplaced1Ph;
          this.totalToday1Ph=this.responseData.datalimit.totals.today1PH;
          this.totalBalanced1Ph=this.responseData.datalimit.totals.totalBalance1Ph;
        }
        if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_Three_Ph_Show){
          this.totalProposed3Ph=this.responseData.datalimit.totals.totalProposed3PH;
          this.totalReplaced3Ph=this.responseData.datalimit.totals.totalReplaced3Ph;
          this.totalToday3Ph=this.responseData.datalimit.totals.today3PH;
          this.totalBalanced3Ph=this.responseData.datalimit.totals.totalBalance3Ph;
        }
        if(this.getThrough.authority && this.getThrough.authority.Auth_All_Meter_LTCT_Ph_Show){
          this.totalProposedLTCT=this.responseData.datalimit.totals.totalLTCTPerposed;
          this.totalReplacedLTCT=this.responseData.datalimit.totals.totalReplacedLTCT;
          this.totalTodayLTCT=this.responseData.datalimit.totals.totalTodayLTCT;
          this.totalBalanceLTCT=this.responseData.datalimit.totals.totalBalanceLTCT;
        }
        this.length = this.responseData.datalimit.totalResult;
        if (this.length < 10) {
          this.limit = this.length;
        }

      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
   
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable", "Progress Summery");
  }
 
}
