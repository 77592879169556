import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, generalRequestsService, UserService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from 'src/app/_services/modal.service';
import { take } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-viewmeters',
  templateUrl: './viewmeters.component.html',
  styleUrls: ['./viewmeters.component.scss']
})
export class ViewmetersComponent implements OnInit {
  messageResult:any;
  faSpinner = faSpinner;
  loading:boolean = false;
  messageUtilitys = 'No Utility List Found';
  reportForm: UntypedFormGroup;
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData: any;
  isLoading:boolean = false;
  isData:boolean = false;
  totalMeters:any;
  removeProcess:boolean = false;
  meterNo:any = {};

  letviewis = [{
    'title':'No Data Available',
    'content':'',
    'bold_class':'b',
  },{
    'title':'',
    'content':'',
    'bold_class':'',
  }];

  constructor(
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService,
    private userService: UserService,
    private modalService: ModalService,
    private confirmationDialogService: ConfirmationDialogService,
    public modal: NgbActiveModal
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }

  ngOnInit() {
    //console.log(this.meterDetail);
    this.getRealTimeStatus();
   
  }

 

  getRealTimeStatus() {
    let body = new URLSearchParams();
    this.isLoading = true;
    body.set('orderId', this.meterNo.id);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getSmartMeters', body).subscribe((result)=>{
      this.responseData = result;
      this.isLoading = false;
      if(this.responseData.success){
        if(this.responseData.data.length!=0){
          this.isData = true;
        }else{
          this.isData = false;
        }
        this.letviewis = this.responseData.data;
        this.totalMeters=this.responseData.datalimit.totalResult;
      }else{  
        this.isData = false;
      }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.isLoading = false;
      this.messageUtilitys = 'Unable to process yours request!';
    });
  }

  edit(row){
    this.modalService.viewSmartMeterNo(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.getRealTimeStatus();
      }
        //console.log({ messageResult: result });
        this.messageResult = result;
      });
  }


  // remove
  removeEntry(row){
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove '+row.companyName+' ?')
    .then((confirmed) => {
      if(confirmed) {
        this.removeProcess = true;
        let body = new URLSearchParams();
        body.set('id', row.id);
        body.set('companyName', row.companyName);
        body.set('authToken', this.currentUser.authToken);
        this.genReqs.postReq('/deleteDispatchedMeter', body).subscribe((result)=>{
          this.responseData = result;
          this.removeProcess = false;
          this.messageResult = this.responseData.message;
          this.getRealTimeStatus();
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.messageResult = 'Unable to process yours request!';
        });
      }
    })
    .catch(() => console.log('no response or closed'));
  }
}
