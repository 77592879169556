<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
        data-dismiss="modal">&times;</button>
       Add DG
    </h4>
  </div>
  <div class="modal-body">
      <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
      <div class="form-groupp">
          <!-- Grid row -->
        <div class="row">
          <div class="col-md-6">
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input placeholder="DG Device ID" name="dgName" formControlName="dgName" 
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgName.errors}" required>
                  <i class="fa fa-database icon_innertxtbox"></i>
                    <label> DG Device</label>
                    <div *ngIf="submitted && aF.dgName.errors" class="invalid-feedback">
                        <div *ngIf="aF.dgName.errors.required">
                          DG Id is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
             <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="EB Device Id" name="ebName" formControlName="ebName"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.ebName.errors}" required>
                    <i class="fa fa-id-badge icon_innertxtbox"></i>
                    <label>EB Device Id</label>
                    <div *ngIf="submitted && aF.ebName.errors" class="invalid-feedback">
                        <div *ngIf="aF.ebName.errors.required">
                          EB Id is required
                        </div>
                    </div>
                  </span>
                </div>
              </div>
             <div class="col-md-6">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <input matInput placeholder="DG Device Name" name="dgNameData" formControlName="dgNameData"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.dgNameData.errors}" required>
                    <i class="fa fa-id-card icon_innertxtbox"></i>
                          <label>DG Device Name</label>
                          <div *ngIf="submitted && aF.dgNameData.errors" class="invalid-feedback">
                              <div *ngIf="aF.dgNameData.errors.required">
                                DG Name is required
                              </div>
                          </div>
                        </span>
                      </div>
                    </div>
          </div>
          
          <!-- Grid row -->
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                </ng-container>
                  <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0" >
                    <div class="alert alert-success" role="alert" *ngIf="thumb">
                      <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                    <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                      <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                    </div>
                  </div>
                
            </div>
             
            <div class="col-sm-12 text-right">
              <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Submit
              </button>
              <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                <mat-icon >cancel</mat-icon>
                Cancel
              </button>

            </div>
        </div>
      </div>
  </div>
  </form>