<form [formGroup]="configMeter" (ngSubmit)="onSubmitConfigMeter()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Start Date (MM/DD/YYYY)" name="schedularStartEndDate" autocomplete="off"
                        formControlName="schedularStartEndDate" (bsValueChange)="getDateFromRange($event)" bsDatepicker
                        [bsConfig]="{ adaptivePosition: true }" class="form-control">
                    <i class="fa fa-calendar icon_innertxtbox"></i>
                    <label>Select Date</label>
                </span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Billing Mode For Grid" name="bMFEB" formControlName="bMFEB"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.bMFEB.errors }" required>
                        <mat-option *ngFor="let td of billingModeGrid" [value]="td.id">
                            {{td.Name}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Billing Mode For Grid</label>
                    <div *ngIf="submitted && cF.bMFEB.errors" class="invalid-feedback">
                        <div *ngIf="cF.bMFEB.errors.required">
                            Billing Mode For EB is required
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Billing Mode For DG" name="bMFDG" formControlName="bMFDG"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.bMFDG.errors }" required>
                        <mat-option *ngFor="let td of billingModeDG" [value]="td.id">
                            {{td.Name}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-layers icon_innertxtbox"></i>
                    <label>Billing Mode For DG</label>
                    <div *ngIf="submitted && cF.bMFDG.errors" class="invalid-feedback">
                        <div *ngIf="cF.bMFDG.errors.required">
                            Billing Mode For DG is required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Grid (0.05kW-65.00kW)" type="text" formControlName="gslu"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.gslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Grid (0.05kW-65.00kW)</label>
                    <div *ngIf="submitted && cF.gslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.gslu.errors.required">
                            Grid Supply Load Limit required
                        </div>
                        <div *ngIf="cF.gslu.hasError('min')">
                            Grid Supply Load Limit is lower than the minimum
                        </div>
                        <div *ngIf="cF.gslu.hasError('max')">
                            Grid Supply Load Limit is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL DG (0.05kW-65.00kW)" type="text" formControlName="bslu" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.bslu.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL DG (0.05kW-65.00kW)</label>
                    <div *ngIf="submitted && cF.bslu.errors" class="invalid-feedback">
                        <div *ngIf="cF.bslu.errors.required">
                            DG Supply Load Limit required
                        </div>
                        <div *ngIf="cF.bslu.hasError('min')">
                            DG Supply Load Limit is lower than the minimum
                        </div>
                        <div *ngIf="cF.bslu.hasError('max')">
                            DG Supply Load Limit is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving Grid (0.05kW-65.00kW)" type="text" formControlName="eblusm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.eblusm.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Power Saving Grid</label>
                    <div *ngIf="submitted && cF.eblusm.errors" class="invalid-feedback">
                        <div *ngIf="cF.eblusm.errors.required">
                            Grid Load Limit For Power Saving required
                        </div>
                        <div *ngIf="cF.eblusm.hasError('min')">
                            Grid Load Limit For Power Saving is lower than the minimum
                        </div>
                        <div *ngIf="cF.eblusm.hasError('max')">
                            Grid Load Limit For Power Saving is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="LL Power Saving DG (0.05kW-65.00kW)" type="text" formControlName="dglsm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.dglsm.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>LL Power Saving DG</label>
                    <div *ngIf="submitted && cF.dglsm.errors" class="invalid-feedback">
                        <div *ngIf="cF.dglsm.errors.required">
                            DG Load Limit For Power Saving required
                        </div>
                        <div *ngIf="cF.dglsm.hasError('min')">
                            DG Load Limit For Power Saving is lower than the minimum
                        </div>
                        <div *ngIf="cF.dglsm.hasError('max')">
                            DG Load Limit For Power Saving is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration For Grid  (min 30 sec)" type="number"
                        formControlName="lrdgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrdgs.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Duration For Grid</label>
                    <div *ngIf="submitted && cF.lrdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdgs.errors.required">
                            Load Restart Duration For Grid Supply required
                        </div>
                        <div *ngIf="cF.lrdgs.hasError('min')">
                            Load Restart Duration For Grid Supply is lower than the minimum
                        </div>
                        <div *ngIf="cF.lrdgs.hasError('max')">
                            Load Restart Duration For Grid Supply is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration For DG  (min 30 sec)" type="number"
                        formControlName="lrddgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrddgs.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Duration For DG </label>
                    <div *ngIf="submitted && cF.lrddgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrddgs.errors.required">
                            Load Restart Duration For DG Supply required
                        </div>
                        <div *ngIf="cF.lrddgs.hasError('min')">
                            Load Restart Duration For DG Supply is lower than the minimum
                        </div>
                        <div *ngIf="cF.lrddgs.hasError('max')">
                            Load Restart Duration For DG Supply is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration PS Grid(>30sec)" formControlName="lrdsmgs" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrdsmgs.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Duration PS Grid</label>
                    <div *ngIf="submitted && cF.lrdsmgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdsmgs.errors.required">
                            Load Restart Duration For Power Saving Grid Supply required
                        </div>
                        <div *ngIf="cF.lrdsmgs.hasError('min')">
                            Load Restart Duration For Power Saving Grid Supply is lower than the minimum
                        </div>
                        <div *ngIf="cF.lrdsmgs.hasError('max')">
                            Load Restart Duration For Power Saving Grid Supply is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Load Restart Duration Power Saving DG  (min 30 sec)" formControlName="lrdsmdgs"
                        type="number" class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrdsmdgs.errors }"
                        required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Duration Power Saving DG (min 30 sec)</label>
                    <div *ngIf="submitted && cF.lrdsmdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrdsmdgs.errors.required">
                            Load Restart Duration For Power Saving DG Supply required
                        </div>
                        <div *ngIf="cF.lrdsmdgs.hasError('min')">
                            Load Restart Duration For Power Saving DG Supply is lower than the minimum
                        </div>
                        <div *ngIf="cF.lrdsmdgs.hasError('max')">
                            Load Restart Duration For Power Saving DG Supply is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For Grid" name="lrcgs" formControlName="lrcgs"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrcgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Counter For Grid</label>
                    <div *ngIf="submitted && cF.lrcgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcgs.errors.required">
                            Load Restart Counter For Grid Supply required
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4 " *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For DG" name="lrcdgs" formControlName="lrcdgs"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lrcdgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Counter For DG</label>
                    <div *ngIf="submitted && cF.lrcdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcdgs.errors.required">
                            Load Restart Counter For Grid Supply required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter Power Saving Grid" name="lrcsmgs"
                        formControlName="lrcsmgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrcsmgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Counter Power Saving Grid</label>
                    <div *ngIf="submitted && cF.lrcsmgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcsmgs.errors.required">
                            Load Restart Counter For Power Saving Grid Supply required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4 " *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Load Restart Counter For Power Saving DG" name="lrcsmdgs"
                        formControlName="lrcsmdgs" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.lrcsmdgs.errors }" required>
                        <mat-option *ngFor="let td of loadResCount" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Load Restart Counter For Power Saving DG</label>
                    <div *ngIf="submitted && cF.lrcsmdgs.errors" class="invalid-feedback">
                        <div *ngIf="cF.lrcsmdgs.errors.required">
                            Load Restart Counter For Power Saving DG Supply required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving On Hour" name="ssmonh" formControlName="ssmonh"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmonh.errors }" required>
                        <mat-option *ngFor="let td of smOnHour" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Schedule Power Saving On Hour</label>
                    <div *ngIf="submitted && cF.ssmonh.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmonh.errors.required">
                            Schedule Power Saving On Hour required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving On Minute" name="ssmonm" formControlName="ssmonm"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmonm.errors }" required>
                        <mat-option *ngFor="let td of smOnMinute" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Schedule Power Saving On Minute</label>
                    <div *ngIf="submitted && cF.ssmonm.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmonm.errors.required">
                            Schedule Power Saving On Minute required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving Off Hour" name="ssmtoffh" formControlName="ssmtoffh"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ssmtoffh.errors }" required>
                        <mat-option *ngFor="let td of smOnHour" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Schedule Power Saving Off Hour</label>
                    <div *ngIf="submitted && cF.ssmtoffh.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmtoffh.errors.required">
                            Schedule Power Saving Off Hour required
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <mat-select placeholder="Schedule Power Saving Off Minute" name="ssmtoffm"
                        formControlName="ssmtoffm" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && cF.ssmtoffm.errors }" required>
                        <mat-option *ngFor="let td of smOnMinute" [value]="td.id">
                            {{td.id}}
                        </mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Schedule Power Saving On Minute</label>
                    <div *ngIf="submitted && cF.ssmtoffm.errors" class="invalid-feedback">
                        <div *ngIf="cF.ssmtoffm.errors.required">
                            Schedule Power Saving Off Minute required
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Overvoltage Thresholds (245-440)" formControlName="ovthrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.ovthrasholds.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Overvoltage Thresholds (245-440)</label>
                    <div *ngIf="submitted && cF.ovthrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.ovthrasholds.errors.required">
                            Overvoltage Thresholds required
                        </div>
                        <div *ngIf="cF.ovthrasholds.hasError('min')">
                            Overvoltage Thresholds is lower than the minimum
                        </div>
                        <div *ngIf="cF.ovthrasholds.hasError('max')">
                            Overvoltage Thresholds is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Low Voltage Thresholds (144-220)" formControlName="lvthrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.lvthrasholds.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Low Voltage Thresholds (144-220)</label>
                    <div *ngIf="submitted && cF.lvthrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.lvthrasholds.errors.required">
                            Low Voltage Thresholds required
                        </div>
                        <div *ngIf="cF.lvthrasholds.hasError('min')">
                            Low Voltage Thresholds is lower than the minimum
                        </div>
                        <div *ngIf="cF.lvthrasholds.hasError('max')">
                            Low Voltage Thresholds is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>


        <div class="col-md-4" *ngIf="!meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Over Current Thresholds (1-63)" formControlName="octhrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.octhrasholds.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Over Current Thresholds (1-63)</label>
                    <div *ngIf="submitted && cF.octhrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.octhrasholds.errors.required">
                            Over Current Thresholds required
                        </div>
                        <div *ngIf="cF.octhrasholds.hasError('min')">
                            Over Current Thresholds is lower than the minimum
                        </div>
                        <div *ngIf="cF.octhrasholds.hasError('max')">
                            Over Current Thresholds is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="col-md-4 " *ngIf="meterTypeCheck">
            <div class="form-group input-group">
                <span class="has-float-label">
                    <input placeholder="Over Current Thresholds (1-80)" formControlName="octhrasholds" type="number"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && cF.octhrasholds.errors }" required>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Over Current Thresholds (1-80)</label>
                    <div *ngIf="submitted && cF.octhrasholds.errors" class="invalid-feedback">
                        <div *ngIf="cF.octhrasholds.errors.required">
                            Over Current Thresholds required
                        </div>
                        <div *ngIf="cF.octhrasholds.hasError('min')">
                            Over Current Thresholds is lower than the minimum
                        </div>
                        <div *ngIf="cF.octhrasholds.hasError('max')">
                            Over Current Thresholds is greater than the maximum
                        </div>
                    </div>
                </span>
            </div>
        </div>


    </div>
    
    <div class="row">
        <div class="{{tagValue}}">
            <ng-container *ngIf="isLoading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
            </ng-container>
           
            <div role="alert" *ngIf="message.length > 0">
                <div class="alert alert-success {{responseClass}}" role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                </div>
                <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 text-right" *ngIf="cmdformData==0">
            <button type="button" (click)="onSubmitConfigPendingMeter()" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Pending
            </button>
        </div>
        <div class="col-sm-2 text-right">
            <!--<button type="submit" [disabled]="AddProcess" class="dilog dilog1">-->
            <button type="submit" class="dilog dilog1">
                <mat-icon>add</mat-icon>
                Set
            </button>
        </div>
    </div>

</form>