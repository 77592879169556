import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-meterconfigserverdetails',
  templateUrl: './meterconfigserverdetails.component.html',
  styleUrls: ['./meterconfigserverdetails.component.scss']
})
export class MeterconfigserverdetailsComponent implements OnInit {
  addForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      sip: ['', Validators.required],
      spno: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }


}
