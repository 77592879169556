import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService, generalRequestsService } from 'src/app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_models';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-adddg',
  templateUrl: './adddg.component.html',
  styleUrls: ['./adddg.component.scss']
})
export class AdddgComponent implements OnInit {
  faSpinner = faSpinner;
  currentUser: User;
  currentUserSubscription: Subscription;
  addForm: UntypedFormGroup;
  responseMessage:string = '';
  responseData: any;
  AddProcess:boolean = false;
  apiMessage:string = '';
  subDivisionId:any = 0;
  isLoading:boolean = false;
  thumb:boolean = false;
  addAuthMessage:string = '';
   submitted: boolean = false;


  constructor( 
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() { 
    this.addForm = this.formBuilder.group({
      dgName: ['', Validators.required],
      ebName: ['', Validators.required],
      dgNameData: ['', Validators.required]
    });
  }
  ngAfterViewInit() {
     
  }
  // ngOnDestroy() {
  //   this.modalService.dismissAll();
  // }

  get aF() { return this.addForm.controls; }
   onSubmitAddForm(){
      if (this.addForm.invalid) {
         this.submitted = true;
        return;
      }
      this.isLoading = true;
      let body = new URLSearchParams();
      body.set('subdivId', this.subDivisionId);
      body.set('dgName', this.aF.dgName.value);
      body.set('ebName', this.aF.ebName.value);
      body.set('dgNameData', this.aF.dgNameData.value);
      body.set('authToken', this.currentUser.authToken);
      this.AddProcess = true;
      this.genReqs.postReq('/addDGData', body).subscribe((result)=>{
        this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            if (this.responseData.success) {
                this.thumb = true;
                this.addAuthMessage = this.responseData.data.message;
                setTimeout(() => 
                {
                 this.modal.close('done');
                },
                2000);
            }else{
                this.thumb = false;
            }
      },(err)=>{
        if (err.status === 401) {
          this.authenticationService.logout();
        }
        this.apiMessage = 'Unable to process yours request!';
      });
    }

    resetForm(form: UntypedFormGroup) {

      form.reset();
  
      Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
      });
  }
}
