
<form [formGroup]="addAuthorityForm" (ngSubmit)="onSubmitAddAuthority()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
          <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
            data-dismiss="modal">&times;</button>
            Edit Payment Details
        </h4>
    </div>
    <div class="modal-body">
        <div class="form-groupp">
            <div class="row">
                
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Contractor Name" name="contractorName"
                            formControlName="contractorName" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.contractorName.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Contractor Name</label>
                            <div *ngIf="submitted && aD.contractorName.errors" class="invalid-feedback">
                                <div *ngIf="aD.contractorName.errors.required">
                                    Contractor Name is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <!--<div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input placeholder="1-Ph Meters" name="singlePhMeters" formControlName="singlePhMeters" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.singlePhMeters.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>1-Ph meters</label>
                            <div *ngIf="submitted && aD.singlePhMeters.errors" class="invalid-feedback">
                                <div *ngIf="aD.singlePhMeters.errors.required">
                                    1-Ph Meters required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                        <input placeholder="1-Ph Meters Amount" name="singlePhMetersAmount"
                            formControlName="singlePhMetersAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.singlePhMetersAmount.errors}" required>
                            <i class="fa fa-lock icon_innertxtbox"></i>
                            <label>1-Ph Meters Amount</label>
                            <div *ngIf="submitted && aD.singlePhMetersAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.singlePhMetersAmount.errors.required">
                                    1-Ph Meters Amount is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>-->
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="3-Ph Meters" name="threePhMeters"
                            formControlName="threePhMeters" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.threePhMeters.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>3-Ph Meters Installed</label>
                            <div *ngIf="submitted && aD.threePhMeters.errors" class="invalid-feedback">
                                <div *ngIf="aD.threePhMeters.errors.required">
                                    3-Ph Meters is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="3-Ph Meters Amount" name="threePhMetersAmount"
                            formControlName="threePhMetersAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.threePhMetersAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>3-Ph Meters Amount</label>
                            <div *ngIf="submitted && aD.threePhMetersAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.threePhMetersAmount.errors.required">
                                    3-Ph Meters Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="LTCT Meters" name="ltctMeters"
                            formControlName="ltctMeters" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ltctMeters.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>LTCT Meters Installed</label>
                            <div *ngIf="submitted && aD.ltctMeters.errors" class="invalid-feedback">
                                <div *ngIf="aD.ltctMeters.errors.required">
                                    LTCT Meters is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="LTCT Meters Amount" name="ltctMetersAmount"
                            formControlName="ltctMetersAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.ltctMetersAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>LTCT Meters Amount</label>
                            <div *ngIf="submitted && aD.ltctMetersAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.ltctMetersAmount.errors.required">
                                    LTCT Meters Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Total Amount" name="totalAmount"
                            formControlName="totalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.totalAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Total Amount without GST</label>
                            <div *ngIf="submitted && aD.totalAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.totalAmount.errors.required">
                                    Total Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="GST Amount" name="gstAmount"
                            formControlName="gstAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.gstAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>GST Amount</label>
                            <div *ngIf="submitted && aD.gstAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.gstAmount.errors.required">
                                    GST Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Net Amount" name="netAmount"
                            formControlName="netAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.netAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Total Amount</label>
                            <div *ngIf="submitted && aD.netAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.netAmount.errors.required">
                                    Total Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Current Amount Paid" name="amountPaid"
                            formControlName="amountPaid" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.amountPaid.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Current Amount Paid</label>
                            <div *ngIf="submitted && aD.amountPaid.errors" class="invalid-feedback">
                                <div *ngIf="aD.amountPaid.errors.required">
                                    Current Amount Paid is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Total Amount Paid" name="totalAmountPaid"
                            formControlName="totalAmountPaid" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.totalAmountPaid.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Total Amount Paid</label>
                            <div *ngIf="submitted && aD.totalAmountPaid.errors" class="invalid-feedback">
                                <div *ngIf="aD.totalAmountPaid.errors.required">
                                    Total Amount Paid is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Pending Amount" name="pendingAmount"
                            formControlName="pendingAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.pendingAmount.errors}" required>
                            <i class="fa fa-user icon_innertxtbox"></i>
                            <label>Pending Amount</label>
                            <div *ngIf="submitted && aD.pendingAmount.errors" class="invalid-feedback">
                                <div *ngIf="aD.pendingAmount.errors.required">
                                    Pending Amount is required
                                </div>
                            </div> 
                        </span>
                    </div>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-md-6">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input  placeholder="Authority DOB (MM/DD/YYYY)" name="authorityDOB"
                                formControlName="authorityDOB" (bsValueChange)="getDOB($event)" bsDatepicker
                                [bsConfig]="{ adaptivePosition: true }" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authorityDOB.errors}" required>
                                <i class="fa fa-calendar icon_innertxtbox"></i>
                                <label>Authority DOB</label>
                                <div *ngIf="submitted && aD.authorityDOB.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authorityDOB.errors.required">
                                        Authority DOB is required
                                   </div>
                            </div>
                        </span>
                    </div>
                </div>
                    <div class="col-md-6">
                        <div class="form-group input-group">
                            <span class="has-float-label">
                                <mat-select placeholder="SMS Receive Status" name="authoritySMSReceiveStatus"
                                    formControlName="authoritySMSReceiveStatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && aD.authoritySMSReceiveStatus.errors}" required>
                                    <mat-option value="Y">Active</mat-option>
                                    <mat-option value="N">In-Active</mat-option>
                                </mat-select>
                                <i class="fa fa-envelope icon_innertxtbox"></i>
                                <label>SMS Receive Status</label>
                                <div *ngIf="submitted && aD.authoritySMSReceiveStatus.errors" class="invalid-feedback">
                                    <div *ngIf="aD.authoritySMSReceiveStatus.errors.required">
                                        Authority SMS Receive Status is required
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>-->
           
            <!-- Grid row -->
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                      <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                      <div role="alert" *ngIf="!isLoading && message.length > 0" >
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                          <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                          <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                        </div>
                      </div>
                    
                </div>
                 
                <div class="col-sm-12 text-right">
                  <button type="submit" class="dilog dilog1">
                    <mat-icon>edit</mat-icon>
                    Submit
                  </button>
                  <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                    <mat-icon >cancel</mat-icon>
                    Cancel
                  </button>
    
                </div>
            </div>
        </div>
    </div>
</form>
<!----add authority end-->