import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from '../../_models';
import { UserService, AuthenticationService, generalRequestsService } from '../../_services';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-consumer-set-pass',
  templateUrl: './consumer-set-pass.component.html',
  styleUrls: ['./consumer-set-pass.component.scss']
})
export class ConsumerSetPassComponent implements OnInit {
  faSpinner = faSpinner;
  addForm: UntypedFormGroup;
  isLoading = false;
  responseMessage:string = '';
  currentUser: User;
  currentUserSubscription: Subscription;
  responseData:any;
  AddProcess:boolean = false;
  old:boolean = true;
  npass:boolean = true;
  ncpass:boolean = true;
  responseClass = '';
  thumb:boolean = false;
  set:any;
  setcurrentuser:any;
  submitted: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private genReqs:generalRequestsService
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
   // console.log(this.setcurrentuser, this.currentUser);
  }

  ngOnInit() {
    
    if(this.set==0){
     this.addForm = this.formBuilder.group({
      currentpass: ['', Validators.required],
      newpass: ['', Validators.required],
      confirmpass: ['', Validators.required]
      }, 
      {
        validator: MustMatch('newpass', 'confirmpass')
      });
     
    }else{
      this.addForm = this.formBuilder.group({
        newpass: ['', Validators.required],
        confirmpass: ['', Validators.required]
        }, 
        {
          validator: MustMatch('newpass', 'confirmpass')
        });
    }
  }

  get aF() { return this.addForm.controls; }
  onSubmitAddForm(){
     if (this.addForm.invalid) {
         this.submitted = true;
       return;
     }
     this.isLoading = true;
     //this.responseClass = '';
     this.responseMessage ='';
     const formData = new FormData();
     if(this.set==0){
      formData.append('resetType', this.set);
      formData.append('currentpass', this.aF.currentpass.value);
      formData.append('newpass', this.aF.newpass.value);
      formData.append('confirmpass', this.aF.confirmpass.value);
     }else{
      formData.append('resetType', this.set);
      formData.append('currentpass', '0');
      formData.append('newpass', this.aF.newpass.value);
      formData.append('confirmpass', this.aF.confirmpass.value);
     }
     formData.append('authToken', this.currentUser.authToken);
     this.genReqs.formDataPost('/resetConsumerPassword', formData).subscribe((result)=>{
     this.responseData = result;
     this.responseMessage = this.responseData.message;
     this.isLoading = false;
     if (this.responseData.success) {
      this.thumb=true;
      setTimeout(() => 
      {
        this.authenticationService.logout();
      },
      3000);
     }
   },(err)=>{
     if (err.status === 401) {
       this.authenticationService.logout();
     }
     this.isLoading = false;
     //this.responseClass = 'alert-danger';
     this.thumb=false;
     this.responseMessage = (err.error.message)?err.error.message:'Unable to process yours request!';
   });
   }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
