<form [formGroup]="addForm" (ngSubmit)="onSubmitAddForm()">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Cancel')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Add Smart Meters Order
        </h4>
    </div>
    <div class="modal-body">
        <!--<div class="alert alert-secondary" role="alert" *ngIf="apiMessage.length > 0" >{{apiMessage}}</div>-->
        <div class="form-groupp">
            <!-- Grid row -->
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                           <input placeholder="Company Name"  list="companyNames" autocapitalize="off" autocomplete="off" autocorrect="off"
                            spellcheck="false" name="companyName" formControlName="companyName" (change)="saveComapnyId($event)"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.companyName.errors}"
                                required>
                            <datalist id="companyNames">
                                   <option *ngFor="let opt of projectCodeData" [value]="opt.text">{{opt.text}}</option>
                            </datalist>    
                            <i class="fa fa-database icon_innertxtbox"></i>
                            <label> Company Name</label>
                            <div *ngIf="submitted && aF.companyName.errors" class="invalid-feedback">
                                <div *ngIf="aF.companyName.errors.required">
                                    Company Name is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Company Address" name="companyAddress" formControlName="companyAddress"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.companyAddress.errors}"
                                required>
                            <i class="fa fa-id-badge icon_innertxtbox"></i>
                            <label>Company Address</label>
                            <div *ngIf="submitted && aF.companyAddress.errors" class="invalid-feedback">
                                <div *ngIf="aF.companyAddress.errors.required">
                                    Company Address is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="GST Number" name="gstNo" formControlName="gstNo"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.gstNo.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>GST Number</label>
                            <div *ngIf="submitted && aF.gstNo.errors" class="invalid-feedback">
                                <div *ngIf="aF.gstNo.errors.required">
                                    GST Number is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="1-Ph Meter Rate" name="singlePhMeterRate" formControlName="singlePhMeterRate"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.singlePhMeterRate.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>1-Ph Meter Rate</label>
                            <div *ngIf="submitted && aF.singlePhMeterRate.errors" class="invalid-feedback">
                                <div *ngIf="aF.singlePhMeterRate.errors.required">
                                   1-Ph Meter Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="1-Ph Meter Qty" name="singlePhphMeterQty" formControlName="singlePhphMeterQty"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.singlePhphMeterQty.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>1-Ph Meter Qty</label>
                            <div *ngIf="submitted && aF.singlePhphMeterQty.errors" class="invalid-feedback">
                                <div *ngIf="aF.singlePhphMeterQty.errors.required">
                                    1-Ph Meter Qty is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="3-Ph Meter Rate" name="threePhMeterRate" formControlName="threePhMeterRate"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.threePhMeterRate.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>3-Ph Meter Rate</label>
                            <div *ngIf="submitted && aF.threePhMeterRate.errors" class="invalid-feedback">
                                <div *ngIf="aF.threePhMeterRate.errors.required">
                                    3-Ph Meter Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="3-Ph Meter Qty" name="threePhMeterQty" formControlName="threePhMeterQty"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.threePhMeterQty.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>3-Ph Meter Qty</label>
                            <div *ngIf="submitted && aF.threePhMeterQty.errors" class="invalid-feedback">
                                <div *ngIf="aF.threePhMeterQty.errors.required">
                                    3-Ph Meter Qty is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="Ltct Meter Rate" name="ltctMeterRate" formControlName="ltctMeterRate"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.ltctMeterRate.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>Ltct Meter Rate</label>
                            <div *ngIf="submitted && aF.ltctMeterRate.errors" class="invalid-feedback">
                                <div *ngIf="aF.ltctMeterRate.errors.required">
                                    Ltct Meter Rate is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group input-group">
                        <span class="has-float-label">
                            <input matInput placeholder="LTCT Meter Qty" name="ltctMeterQty" formControlName="ltctMeterQty"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && aF.ltctMeterQty.errors}"
                                required>
                            <i class="fa fa-id-card icon_innertxtbox"></i>
                            <label>LTCT Meter Qty</label>
                            <div *ngIf="submitted && aF.ltctMeterQty.errors" class="invalid-feedback">
                                <div *ngIf="aF.ltctMeterQty.errors.required">
                                    LTCT Meter Qty is required
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Grid row -->
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-sm-12 text-left">
                    <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                    </ng-container>
                    <div role="alert" *ngIf="!isLoading && addAuthMessage.length > 0">
                        <div class="alert alert-success" role="alert" *ngIf="thumb">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                        <div class="alert alert-secondary" role="alert" *ngIf="!thumb">
                            <mat-icon>thumb_down</mat-icon>&nbsp;{{addAuthMessage}}
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 text-right">
                    <button type="submit" class="dilog dilog1">
                        <mat-icon>add</mat-icon>
                        Submit
                    </button>
                    <button type="button" (click)="modal.close('Cancel')" class="dilog dilog3">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</form>