import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dcuconfigschedular',
  templateUrl: './dcuconfigschedular.component.html',
  styleUrls: ['./dcuconfigschedular.component.scss']
})
export class DcuconfigschedularComponent implements OnInit {
  addForm: UntypedFormGroup;
  commandType = [
    {name: 'Download Meter Router Id'},
    {name: 'Download Meters'}
  ];
  dateTime = '';
  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      commType: ['', Validators.required],
      dateTime: ['', Validators.required],
    });
  }

  get cF() { return this.addForm.controls; }

  onSubmitAddForm() {
    //To Do #to be removed
  }
}
