<div class="modal-content-new">
  <div class="modal-header subpage">
    <h4 class="modal-title" id="modal-basic-title">
      <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
      Load limits Config From Meter: {{meterDetail.mID}}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container>
      <ng-container class="allutilitysloader col-12" *ngIf="isLoading">
        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
      </ng-container>

      <div class="row mx-auto form-wrapper table-responsive"  *ngIf="isData">
        <table class="table table-bordered table-sm table-hover">
          <thead class="tablebghead">
            <tr>
              <td>
                <div class="d_title">
                  <span></span>Sr. No.
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Meter Id
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Activation Date & Time
              </div>
              </td>
              <td>
                <div class="d_title">
                  <span></span>Server Date & Time
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Billing Mode Grid Active
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>Billing Mode DG Active
              </div>
              </td>
              <td >
                <div class="d_title ">
                  <span></span>Billing Mode Grid Passive
              </div>
              </td>
              <td  *ngIf="view=='Y'"> 
                <div class="d_title ">
                  <span></span>Billing Mode DG Passive
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Grid LoadLimit
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>DG LoadLimit
              </div>
              </td>
              <td *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>Grid Loadlimit Powersaving
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>DG Loadlimit Powersaving
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>load Restart Duration Grid
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>load Restart Count Grid
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Duration DG
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Count DG
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Duration PowerSaving Grid
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Count PowerSaving Grid
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Duration PowerSaving DG
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>load Restart Count PowerSaving DG
              </div>
              </td>
              
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>PowerSaving Start Time
              </div>
              </td>
              <td  *ngIf="view=='Y'">
                <div class="d_title ">
                  <span></span>PowerSaving End Time
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Over Voltage Threshold
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Low Voltage Threshold
              </div>
              </td>
              <td>
                <div class="d_title ">
                  <span></span>Over Current Threshold
              </div>
              </td>
             
            </tr>
          </thead>
         <tbody class="tbbodystyle">
          <tr class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis; index as i">
            <td>
              <div class="d_content d_content_W">
                <p>{{i+1}}</p>
              </div>
            </td>
<td>
<div class="d_content d_content_W">
  <p>{{item.meterId}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.activedateTime}}</p>
</div>
</td>

<td>
<div class="d_content d_content_W">
  <p>{{item.dateTime}}</p>
</div>
</td>


<td>
<div class="d_content d_content_W">
  <p>{{item.billingModeGridActive}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.billingModeDGActive}}</p>
</div>
</td>

<td>
  <div class="d_content d_content_W">
    <p>{{item.billingModeGridPassive}}</p>
  </div>
  </td>
  <td  *ngIf="view=='Y'">
  <div class="d_content d_content_W">
    <p>{{item.billingModeDGPassive}}</p>
  </div>
  </td>
  
<td>
<div class="d_content d_content_W">
  <p>{{item.gridLoadLimit}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.dgloadLimit}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.gridLoadLimitPS}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.dgloadLimitPS}}</p>
</div>
</td>

<td >
<div class="d_content d_content_W">
  <p>{{item.loadResDurGrid}}</p>
</div>
</td>
<td>
<div class="d_content d_content_W">
  <p>{{item.loadResCountGrid}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResDurDG}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResCountDG}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResDurPSGrid}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResCountPSGrid}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResDurPSDG}}</p>
</div>
</td>
<td  *ngIf="view=='Y'">
<div class="d_content d_content_W">
  <p>{{item.loadResCountPSDG}}</p>
</div>
</td>

<td  *ngIf="view=='Y'">
  <div class="d_content d_content_W">
    <p>{{item.powerSavingStartTime}}</p>
  </div>
  </td>

  <td  *ngIf="view=='Y'">
    <div class="d_content d_content_W">
      <p>{{item.powerSavingEndTime}}</p>
    </div>
    </td>

    <td>
      <div class="d_content d_content_W">
        <p>{{item.overVoltThrash}}</p>
      </div>
      </td>

      <td>
        <div class="d_content d_content_W">
          <p>{{item.lowVoltThrash}}</p>
        </div>
        </td>

        <td>
          <div class="d_content d_content_W">
            <p>{{item.overCurrentThrash}}</p>
          </div>
          </td>

          

</tr>
</tbody>
</table>
        <!--<div class="col-sm-12 col-md-6 o_b" *ngFor="let item of letviewis">
                    <div class="d_title {{item.bold_class}}">
                        <span></span>{{item.title}}
                    </div>
                    <div class="d_content">
                        <p>{{item.content}}</p>
                    </div>
                    <hr>
        </div>           
        <div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->
      </div>

      <div class="row mx-auto form-wrapper" *ngIf="!isData && !isLoading">
        <div class="col-sm-12">
          <img class="center" src="assets/images/Nodata.png">
        </div>
        <!--<div class="col-12 text-right">
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon class="d-c3">cancel</mat-icon>
            Exit
          </button>
        </div>-->
      </div>
      
    </ng-container>
<ng-container col-sm-3>
</ng-container>
</div>
</div>
