<div class="modal-header">
  <mat-icon>bug_report</mat-icon><h3>Reporting From Meter</h3>
  <button type="button"  aria-label="Close" (click)="modal.close('Ok click')">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa name="spinner" animation="spin" size="4x"></fa>
  </ng-container>
  <ng-container>
          <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
            
                <form [formGroup]="reportForm" (ngSubmit)="onSubmitAddForm()" >
                      <mat-radio-group name="reporting" formControlName="reporting">
                        <div>
                          <mat-icon class="d-c4">bug_report</mat-icon><mat-radio-button value="1">Get Instantaneous Data</mat-radio-button>
                        </div>
                        <div>
                          <mat-icon class="d-c5">bug_report</mat-icon><mat-radio-button value="2">Get Last Night Midnight Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c6">bug_report</mat-icon><mat-radio-button value="3">Get Complete Midnight Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c7">bug_report</mat-icon><mat-radio-button value="4">Get Latest Billing Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c8">bug_report</mat-icon><mat-radio-button value="5">Get Full Billing Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c9">bug_report</mat-icon><mat-radio-button value="6">Get Latest Event Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c10">bug_report</mat-icon><mat-radio-button value="7">Get Full Event Data</mat-radio-button>
                        </div>  
                        <div>
                          <mat-icon class="d-c11">bug_report</mat-icon><mat-radio-button value="8">Get Current Load Survey Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c12">bug_report</mat-icon><mat-radio-button value="9">Get All Load Survey Data</mat-radio-button>
                        </div> 
                        <div>
                          <mat-icon class="d-c13">bug_report</mat-icon><mat-radio-button value="10">Get Meter Configuration Data</mat-radio-button>
                        </div> 
                      </mat-radio-group>
                      <ng-container *ngIf="isLoading">
                        <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                      </ng-container>
  
                      <ng-container *ngIf="!isLoading && message.length>0">
                        <div class="alert alert-secondary" role="alert" *ngIf="message.length > 0" >{{message}}</div>
                      </ng-container>
                  
                    <div class="col-12 text-right">
                      <button [disabled]="AddProcess" type="submit" class="dilog-button dilog-button1" >
                        <mat-icon >report</mat-icon>
                        Report
                      </button>
                      <button type="button" (click)="modal.close('Ok click')" mat-button class="dilog-button dilog-button1">
                        <mat-icon>cancel</mat-icon>
                        Cancel
                      </button>
                    </div>
                  

                </form>
              
          </div>
  </ng-container>
</div>