<div class="container-fluid innerpage_top page-content-wrapperr">
    <div class="content-viewport">
      <div class="page-content-wrapper-inner outerbox_shadow">
        <ng-container>
          <div class="boxflexEll row">
            <div class="col-md-5 col-sm-12">
              <h1>Billing Information
                <span class="info_icntop ml-1">
                  <i data-toggle="tooltip" data-placement="top" title="Billing Information"
                    class="fa fa-info tabula-tooltip "></i>
                </span>
              </h1>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="flex_auth">
                <div class="authsearch">
                  <div class="search">
                    <input class="search-txt searchplaceholder" type="text"
                      placeholder="Search by Meter No" value="{{levelSearch}}"
                      (input)="onLevelSearchChange($event.target.value)" autocomplete="off">
                    <div class="search-btn nobg">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="length!=0">
                  <button type="button" [matMenuTriggerFor]="dcuListMenu" mat-button mat-button-base mat-primary
                    style="color: #000;" class="npMi circlebtn">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #dcuListMenu="matMenu" xPosition="before"
                    class="notifications-dropdown modal-contentradius gridinnerpage">
                    <div class="topspacemodelgrip">
                      <button type="button" class="dilog4 close_popupbtn">
                        <mat-icon matPrefix>close</mat-icon>
                      </button>
                      <div class="add_rowEgrid">Add Details</div>
                    </div>
                    <mat-list role="list" *ngFor="let item of levelHeadsCols" class="listaddinfogridfull">
                      <mat-list-item role="listitem" (click)="$event.stopPropagation();">
                        <mat-checkbox [checked]="item.checked" (change)="updateLevelChange($event, i, item)">
                          {{item.label}}</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
                <div>
                  <!-- <button type="button" class="npMi editbordr_circlttop  mr-3" (click)="openaddlevel()"
                    *ngIf="getThrough.authority.Admin_User_Remark_Revert_ADD_Button">
                    <i class="fa fa-plus"></i>
                  </button>
                  <button mat-flat-button (click)="whichSubDomain()" class="cPbg" *ngIf="enableSocietyChange">
                      <mat-icon>reply</mat-icon>
                    </button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid inner-page-content">
            <div class="roww col-12">
              <ng-container class="col-sm-12" *ngIf="loading">
                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
              </ng-container>
  
              <div *ngIf="length!=0 && !loading" class="table-responsivee table-responsive_fixed">
                <table id="ExampleMaterialTable" mat-table [dataSource]="dataLevelSource"
                  class="table-bordered table-sm table-hover" *ngIf="dataLevelSource.length > 0" matSort>
  
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="action_iconbtn">
                        <button class="btn_outlineaction1" type="button" (click)="checkMeter(row)">
                          <span><i class="fa fa-edit"></i></span>
                        </button>
                        <button class="btn_outlineaction1" (click)="removelevel(row)" type="button"
                        *ngIf="getThrough.authority && getThrough.authority.Admin_User_Remark_Remove_Button">
                          <span><i class="fa fa-trash"></i></span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="srNo">
                    <th mat-header-cell *matHeaderCellDef>Sr. No</th>
                    <td mat-cell *matCellDef="let element; let j = index;" style="text-align: center;" >
                      {{ (j+1) + (meterpaginator.pageIndex * meterpaginator.pageSize) }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="meterMake">
                    <th mat-header-cell *matHeaderCellDef> Meter Make </th>
                    <td class="d_title"  mat-cell *matCellDef="let element">
                      {{element.meterMake}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="meterId">
                    <th mat-header-cell *matHeaderCellDef> Meter No </th>
                    <td class="d_title"  mat-cell *matCellDef="let element">
                      {{element.meterId}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="meterType">
                    <th mat-header-cell *matHeaderCellDef> Meter Type </th>
                    <td class="d_title"  mat-cell *matCellDef="let element">
                      {{element.meterType}}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="d_title_bold">Status</th>
                    <td  mat-cell *matCellDef="let element">
                      {{element.status}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="remark">
                    <th mat-header-cell *matHeaderCellDef class="d_title_bold">Remark</th>
                    <td  mat-cell *matCellDef="let element">
                      {{element.remark}}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef class="d_title_bold">Location</th>
                    <!--<td  mat-cell *matCellDef="let element">
                      {{element.location}}
                    </td>-->
                    <td mat-cell *matCellDef="let element" class="third">
                        <a href="https://www.google.com/maps/dir/?api=1&origin=&destination={{element.location}}&travelmode=driving"
                            target="_blank">
                            <img name="VodaPhone" class="blink-image"
                                src="assets/images/location.png" style="width: 14px;"
                                data-toggle="tooltip" data-placement="top" title="{{element.location}}">
                        </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dateTime">
                    <th mat-header-cell *matHeaderCellDef class="d_title_bold">Date & Time</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.dateTime}}
                    </td>
                  </ng-container>
                  
  
                  <tr mat-header-row *matHeaderRowDef="levelColumnsToDisplay" class="tableHeader"></tr>
                  <tr class="tableColumns" mat-row *matRowDef="let element; columns: levelColumnsToDisplay;">
                  </tr>
                </table>
              </div>
              <div *ngIf="!loading">
                <div *ngIf="length==0">
                  <div>
                    <img class="center" src="assets/images/NodataBlue.png">
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
        </ng-container>
      </div>
  
      <mat-card-footer>
        <div class="row">
          <div class="col-12">
            <mat-paginator #meterpaginator [pageSize]="limit" [pageSizeOptions]="getPageSizeOptions()" [length]="length"
              (page)="changeMeterPage($event)" showFirstLastButtons>
            </mat-paginator>
          </div>
          <!--<div class="col-1 text-left1">
            <div *ngIf="length!=0">
              <button style="border:0;background: azure;" title="Export To Excel" (click)="exportTable()"><i
                  class="fa fa-file-excel-o" style="font-size:25px;color:rgb(0, 132, 255)"></i></button>
            </div>
          </div>-->
        </div>
  
      </mat-card-footer>
  
    </div>
  
  </div>