<form [formGroup]="configForm" (ngSubmit)="onSubmitConfigMeter()">
	
	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="Start Hour" formControlName="startHour">
			<mat-option>None</mat-option>
			<mat-option *ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.startHour.hasError('required')">
			Start Hour <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="Start Minute" formControlName="startMinute">
			<mat-option>None</mat-option>
			<mat-option
				*ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.startMinute.hasError('required')">
			Start Minute <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="End Hour" formControlName="endHour">
			<mat-option>None</mat-option>
			<mat-option *ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.endHour.hasError('required')">
			End Hour <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="End Minute" formControlName="endMinute">
			<mat-option>None</mat-option>
			<mat-option
				*ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.endMinute.hasError('required')">
			End Minute <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="Holiday Month" formControlName="holidayMonth">
			<mat-option>None</mat-option>
			<mat-option *ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.holidayMonth.hasError('required')">
			Holiday Month <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="Holiday Date" formControlName="holidayDate">
			<mat-option>None</mat-option>
			<mat-option
				*ngFor="let key of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.holidayDate.hasError('required')">
			Holiday Date <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-md-3 form-group input-rounded">
		<mat-icon matPrefix class="d-c12"> add_box </mat-icon>
		<mat-select placeholder="Week Day" formControlName="weekDay">
			<mat-option>None</mat-option>
			<mat-option *ngFor="let key of [1,2,3,4,5,6,7]" [value]="key">
				{{key}}
			</mat-option>
		</mat-select>
		<mat-error *ngIf="cF.weekDay.hasError('required')">
			Week Day <strong>required</strong>
		</mat-error>
	</mat-form-field>
	<div class="col-sm-12 row">
        <div class="col-sm-6 text-left">
          <ng-container *ngIf="isLoading">
            <fa-icon [icon]="faSpinner" [spin]="true" size="2x" class="makeCenterNew"></fa-icon>
          </ng-container>
            <div class="alert alert-secondary {{responseClass}}" role="alert" *ngIf="message.length > 0" >
              <mat-icon *ngIf="thumb">thumb_up</mat-icon><mat-icon *ngIf="!thumb">thumb_down</mat-icon>{{message}}
            </div>
          
        </div>
        <div class="col-sm-6 text-right">
          <button type="button" [disabled]="AddProcess" (click)="onSubmitConfigMeter()" class="dilog-button dilog-button1">
            <mat-icon>add</mat-icon>
            Add
          </button>
          <button type="button"  (click)="modal.close('Ok click')" class="dilog-button dilog-button1">
            <mat-icon >cancel</mat-icon>
            Exit
          </button>
        </div>
      </div>
	<!--<button mat-raised-button color="primary" type="submit">Add</button>-->
</form>